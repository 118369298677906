import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import FinancialTransactionGrid from './FinancialTransactionGrid';
import FinancialTransactionDetailsGrid from './FinancialTransactionDetailsGrid';

class PageTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-view-transactions',
          content: <FinancialTransactionGrid />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'details':
        const { TransactionId, Hist } = dataItem;
        tabs.push({
          title: `${translate('page.dashboard.tab-transaction-details')}`,
          content: <FinancialTransactionDetailsGrid itemTest={dataItem} hist={Hist} transactionId={TransactionId} />,
          closable: true,
          key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageTransactions);
