import React, { PureComponent } from 'react';
import { Tabs } from 'antd';

import GeneralProps from './GeneralProps';
import ServiceSupplier from './ServiceSupplier';
import SupplierCommissions from './SupplierCommissions';
import ClientCommissions from './ClientCommissions';
import OwnerCommissions from './OwnerCommissions';
import { withTranslate } from '../../../../contexts/localContext';
import { StorageService } from '../../../../services';
import { TITLE_BY_STEP } from './contants';
import BankDetails from './BankDetails';
import ServiceMainProps from './ServiceMainProps';

import './StepServiceCreation.styled.scss';

const { TabPane } = Tabs;

class StepServiceCreation extends PureComponent {
  state = {
    currentStep: (this.props.selectedService && this.props.selectedService.lastStep) || '1',
    serviceId: (this.props.selectedService && this.props.selectedService.serviceId) || null,
  };

  getServiceInfoByStorage = () => {
    const { serviceId: currentServiceId } = this.state;
    const services = StorageService.getItem('waitingFilledStepServices');

    return { services, currentService: services && services.find(({ serviceId }) => serviceId === currentServiceId) };
  };

  handleTabChange = (currentStep) => {
    const { currentService } = this.getServiceInfoByStorage();

    if (currentService) {
      this.setState({ currentStep });
    }
  };

  handleUpdateActiveKey = (currentStep) => {
    const { serviceId: currentServiceId } = this.state;

    const { currentService, services } = this.getServiceInfoByStorage();

    if (!currentService) {
      return;
    }

    const nextStep = `${+currentStep + 1}`;

    StorageService.setItem('waitingFilledStepServices', [
      ...services.filter(({ serviceId }) => serviceId !== currentServiceId),
      {
        ...currentService,
        lastStep: currentService.lastStep > nextStep ? currentService.lastStep : nextStep,
      },
    ]);

    this.setState({ currentStep: nextStep });
  };

  handleEndCreation = () => {
    const { serviceId: currentServiceId } = this.state;
    const { services } = this.getServiceInfoByStorage();

    StorageService.setItem(
      'waitingFilledStepServices',
      services.filter(({ serviceId }) => serviceId !== currentServiceId),
    );

    window.location.reload();
  };

  handleInitialUpdateActiveKey = (currentServiceId) => {
    const { currentService, services } = this.getServiceInfoByStorage();

    if (!currentService) {
      StorageService.setItem('waitingFilledStepServices', [
        ...(services || []),
        {
          serviceId: currentServiceId,
          lastStep: '2',
        },
      ]);
    }

    this.setState({ currentStep: '2', serviceId: currentServiceId });
  };

  getIsCompleted = (step) => {
    const { currentService } = this.getServiceInfoByStorage();

    return currentService && +currentService.lastStep > +step;
  };

  getTitle = (step) => {
    const { translate } = this.props;

    return `${translate('page.servicesBigTable.step')} ${step} - ${translate(TITLE_BY_STEP[step])} ${
      this.getIsCompleted(step) ? '✓' : ''
    }`;
  };

  getIsDisabled = (step) => {
    const { currentService } = this.getServiceInfoByStorage();

    return currentService ? step !== currentService.lastStep : step !== '1';
  };

  render() {
    const { serviceId, currentStep } = this.state;

    return (
      <Tabs activeKey={currentStep} onChange={this.handleTabChange}>
        <TabPane
          tab={this.getTitle('1')}
          key={'1'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('1')}
        >
          <GeneralProps currentServiceId={serviceId} handleInitialUpdateActiveKey={this.handleInitialUpdateActiveKey} />
        </TabPane>

        <TabPane
          tab={this.getTitle('2')}
          key={'2'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('2')}
        >
          <ServiceSupplier
            currentServiceId={serviceId}
            isCompleted={this.getIsCompleted('2')}
            handleUpdateTabPane={() => {
              this.handleUpdateActiveKey('2');
            }}
          />
        </TabPane>

        <TabPane
          tab={this.getTitle('3')}
          key={'3'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('3')}
        >
          <SupplierCommissions
            currentServiceId={serviceId}
            isCompleted={this.getIsCompleted('3')}
            handleUpdateTabPane={() => {
              this.handleUpdateActiveKey('3');
            }}
          />
        </TabPane>

        <TabPane
          tab={this.getTitle('4')}
          key={'4'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('4')}
        >
          <OwnerCommissions
            currentServiceId={serviceId}
            handleUpdateTabPane={() => {
              this.handleUpdateActiveKey('4');
            }}
          />
        </TabPane>

        <TabPane
          tab={this.getTitle('5')}
          key={'5'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('5')}
        >
          <BankDetails
            currentServiceId={serviceId}
            handleUpdateTabPane={() => {
              this.handleUpdateActiveKey('5');
            }}
          />
        </TabPane>

        <TabPane
          tab={this.getTitle('6')}
          key={'6'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('6')}
        >
          <ServiceMainProps
            currentServiceId={serviceId}
            handleUpdateTabPane={(isClientCommission) => {
              if (isClientCommission) {
                this.handleUpdateActiveKey('6');
                return;
              }

              this.handleEndCreation();
            }}
          />
        </TabPane>

        <TabPane
          tab={this.getTitle('7')}
          key={'7'}
          className="StepServiceCreation-tab"
          disabled={this.getIsDisabled('7')}
        >
          <ClientCommissions currentServiceId={serviceId} handleUpdateTabPanel={this.handleEndCreation} />
        </TabPane>
      </Tabs>
    );
  }
}

export default withTranslate(StepServiceCreation);
