import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { Button, Col, Row } from 'antd';
import Grid from '../../components/grids/baseGrid';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import ModalDealerServiceStatus from './ModalDealerServiceStatus/ModalDealerServiceStatus';
import {
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
} from '../../core/constants/configs.const';
import { GridColumn } from '@progress/kendo-react-grid';
import CellBoolean from '../account/cellBoolean';

import './DealerCommissionsServices.scss';

import { getTransformDateString } from '../../utils/getTransformDateString';
import { tabService } from '../../services';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const WrappedCellBoolean = (props) => <CellBoolean {...props} className="DealerCommissionsServices-centredCell" />;
class DealerCommissionsServices extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('dealerCommissionsServices');
    this.state = {
      visible: false,
      dataItem: [],
      selectedItems: [],
      modalType: '',
    };
  }

  isDisable = () => {
    const { currentOwners, selectPaymentTypes, selectServicesList } = this.props;
    return !(currentOwners.length && selectPaymentTypes.length && selectServicesList.length);
  };

  showModal = (dataItem, type) => {
    this.setState({
      dataItem,
      modalType: type,
    });
  };

  closeModal = () => {
    this.setState({ modalType: '' });
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  createDynamicTabs = (type, dataItem, ...otherProps) => {
    return tabService.addTab({
      type,
      dataItem,
      ...otherProps,
    });
  };
  CellSetBgColor = (props) => {
    const { dataItem } = props;
    // console.log('ServiceStatusName', ServiceStatusName);
    let bgColor = '';

    switch (dataItem.ServiceStatusName) {
      case 'Активный':
        bgColor = '#28a745';
        break;
      case 'Неактивный':
        bgColor = '#ff6358';
        break;
      default:
        break;
    }

    return (
      <td
        style={{ backgroundColor: bgColor }}
        className="DealerCommissionsServices-statusCell"
        onClick={(props) => this.showModal(dataItem, 'serviceStatusName')}
      >
        {dataItem.ServiceStatusName}
      </td>
    );
  };

  buildToolbar = () => {
    const {
      allOwners,
      currentOwners,
      paymentTypes,
      selectPaymentTypes,
      onPaymentTypesSave,
      fetchServiceCommissionsList,
      handleSelectOwners,
      servicesList,
      selectServicesList,
      onServiceSave,
      translate,
    } = this.props;

    const { selectedItems } = this.state;

    return (
      <Row type="flex">
        <Col span={24} md={24} lg={24} className="DealerCommissionsServices-toolbarWrapp">
          <Col className="DealerCommissionsServices-selects">
            <Col className="DealerCommissionsServices-row">
              <Col className="DealerCommissionsServices-label">{translate('page.commissionsServices.dealer')}</Col>
              <Col className="DealerCommissionsServices-dropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeOwners(allOwners)}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={currentOwners}
                  onSave={handleSelectOwners}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  isGetDefaultSelectItemsFromStorage
                />
              </Col>
            </Col>
            <Col className="DealerCommissionsServices-row">
              <Col className="DealerCommissionsServices-label">{translate('page.commissionsServices.paymentType')}</Col>
              <Col className="DealerCommissionsServices-dropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  selectItems={selectPaymentTypes}
                  onSave={onPaymentTypesSave}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                />
              </Col>
            </Col>
            <Col className="DealerCommissionsServices-row">
              <Col className="DealerCommissionsServices-label">{translate('page.commissionsServices.service')}</Col>
              <Col className="DealerCommissionsServices-dropdown">
                <GridDropdown
                  data={servicesList}
                  colConfig={SERVICES_COLUMN_CONFIG}
                  selectItems={selectServicesList}
                  onSave={onServiceSave}
                  isSingle
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                />
              </Col>
            </Col>
          </Col>

          <Col>
            {checkElement('dealerCommissionsServices-btnSearch', this.currentArray) && (
              <Button
                type="primary"
                disabled={this.isDisable()}
                onClick={fetchServiceCommissionsList}
                className="DealerCommissionsServices-searchBtn"
              >
                {translate('core.buttonTitles.search')}
              </Button>
            )}
          </Col>
        </Col>

        <Col className="DealerCommissionsServices-controlBtn">
          {checkElement('dealerCommissionsServices-btnCopyCommission', this.currentArray) && (
            <Button type="primary" onClick={() => this.createDynamicTabs('copyCommissions')}>
              {translate('core.buttonTitles.copyCommission')}
            </Button>
          )}

          {checkElement('dealerCommissionsServices-btnTurnOnTurnOff', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => this.createDynamicTabs('changeCommissions', selectedItems)}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}
          {checkElement('dealerCommissionsServices-btnTurnOnTurnOff', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={(props) => this.showModal(props, 'serviceStatusInactiveOrActive')}
            >
              {translate('core.buttonTitles.turnOn')}/{translate('core.buttonTitles.turnOff')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };
  render = () => {
    const { dataItem, modalType, selectedItems } = this.state;
    const { isLoading, dealerCommissionsList, fetchServiceCommissionsList } = this.props;
    if (!checkElement('dealerCommissionsServices-content', this.currentArray)) return null;
    return (
      <>
        <Grid
          data={dealerCommissionsList}
          name="DealerCommissionsGrid"
          toolbar={this.buildToolbar()}
          onRefresh={fetchServiceCommissionsList}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItems}
          multiSelected
          isLoading={isLoading}
        >
          <GridColumn field="selected" width="50px" filterable={false} columnMenu={false} showAllSelected />
          <GridColumn
            field="OwnerId"
            title="grids.dealerCommissionServices.column-dealerId"
            width="200px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn field="OwnerName" title="grids.dealerCommissionServices.column-dealerName" width="220px" />
          <GridColumn
            field="ServiceId"
            title="grids.dealerCommissionServices.column-serviceId"
            width="250px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn field="ServiceName" title="grids.dealerCommissionServices.column-serviceName" width="250px" />
          <GridColumn
            field="ServiceStatusName"
            title="grids.dealerCommissionServices.column-service-status-dealer"
            width="250px"
            onClick={(props) => this.showModal(props, 'serviceStatusName')}
            cell={(props) => this.CellSetBgColor(props)}
          />
          <GridColumn field="PaymentType" title="grids.dealerCommissionServices.column-payment-type" width="200px" />
          <GridColumn
            field="ServiceGroupName"
            title="grids.dealerCommissionServices.column-service-group"
            width="250px"
          />
          <GridColumn field="SupplierName" title="grids.dealerCommissionServices.column-supplier" width="200px" />
          <GridColumn
            field="ServiceRule"
            title="grids.dealerCommissionServices.column-service-description-rule"
            width="250px"
          />
          <GridColumn
            field="DateStart"
            title="grids.dealerCommissionServices.column-commission-start-date"
            width="270px"
            cell={({ dataItem: { DateStart } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateStart)}</td>
            )}
            formatFilterCellData={(DateStart) => getTransformDateString(DateStart)}
          />
          <GridColumn
            field="TransitService"
            title="grids.dealerCommissionServices.column-transit-feature"
            width="250px"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="СommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="MinСommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission-min"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="MaxСommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission-max"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="DealerCommissionClient"
            title="grids.dealerCommissionServices.column-commission-client"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="DealerСommissionClientMin"
            title="grids.dealerCommissionServices.column-commission-client-min"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="DealerСommissionClientMax"
            title="grids.dealerCommissionServices.column-commission-client-max"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommission"
            title="grids.dealerCommissionServices.column-ps-dealer-commission"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionMin"
            title="grids.dealerCommissionServices.column-ps-dealer-commission-min"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionMax"
            title="grids.dealerCommissionServices.column-ps-dealer-commission-max"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemCommissionClient"
            title="grids.dealerCommissionServices.column-ps-commission-client"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMin"
            title="grids.dealerCommissionServices.column-ps-commission-client-min"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMax"
            title="grids.dealerCommissionServices.column-ps-commission-client-max"
            width="270px"
            className="DealerCommissionsServices-centredCell"
          />
          <GridColumn
            field="DatePost"
            title="grids.dealerCommissionServices.column-commission-change-date"
            width="250px"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn field="UserPost" title="grids.dealerCommissionServices.column-performer" width="270px" />
        </Grid>
        {modalType && (
          <ModalDealerServiceStatus
            modalType={modalType}
            closeModal={this.closeModal}
            dataItem={dataItem}
            selectedItems={selectedItems}
            update={fetchServiceCommissionsList}
            isLoding={isLoading}
          />
        )}
      </>
    );
  };
}
export default withTranslate(DealerCommissionsServices);
