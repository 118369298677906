import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const aggregate = [
  { field: 'Quantity', aggregate: 'sum', format: 'n0' },
  { field: 'QuantityActually', aggregate: 'sum', format: 'n0' },
  { field: 'QuantityBills', aggregate: 'sum', format: 'n0' },
];

class GridEncashmentDetailsList extends PureComponent {
  state = {
    isLoading: false,
    data: [],
  };

  static propTypes = {
    dataItem: PropTypes.array.isRequired,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    const { dataItem } = this.props;
    if (dataItem.length < 1) return;
    try {
      this.toggleLoader(true);
      const data = await api.postEncashmentDetailsList(dataItem);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  renderToolbar = () => {
    // const { collectionId, translate } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {/* <div className="issued-certificates--toolbar">
          <p>{translate('grids.allEncashments.column-collectionId')}</p>
          <Input value={collectionId} disabled />
        </div> */}
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        aggregate={aggregate}
        isLoading={isLoading}
        toolbar={this.renderToolbar()}
        name="encashmentDetailsList"
        permissionName="allEncashments"
      >
        <GridColumn field="Currency" title="grids.encashmentDetailsList.column-currency" width="150" />
        <GridColumn field="Nominal" title="grids.encashmentDetailsList.column-nominal" width="150" filter="numeric" />

        <GridColumn field="Quantity" title="grids.encashmentDetailsList.column-quantity" width="240" filter="numeric" />

        <GridColumn
          field="QuantityActually"
          title="grids.encashmentDetailsList.column-quantityActually"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="QuantityBills"
          title="grids.encashmentDetailsList.column-quantitybills"
          width="300"
          filter="numeric"
        />

        <GridColumn
          field="IsCash"
          title="grids.encashmentDetailsList.column-isCash"
          width="150"
          filter="boolean"
          cell={WrappedCellBoolean}
        />
      </Grid>
    );
  };
}

export default withTranslate(GridEncashmentDetailsList);
