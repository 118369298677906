import { MAX_PERCENT, MAX_VAlUE, MIN_PERCENT, MIN_VAlUE } from './constants';

class DealerCommissionFormService {
  validateNumberFormat = (translate) => (value) => {
    if (`${value}`.match(/[^0-9.,-]/)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }
    return '';
  };

  validateStartingWithZero = (translate) => (value) => {
    if (value.toString() === '0') {
      return '';
    }

    if (value.toString()[0] === '0' && value.toString()[1] !== '.' && value.toString()[1] !== ',') {
      return translate('page.dealerCommissionsChange.error-starting-with-zero');
    }

    return '';
  };

  validateFieldsLength = (translate) => (value) => {
    const startingWithZero = this.validateStartingWithZero(translate)(value);

    const regexp = new RegExp(`^-?(0|[1-9]\\d*)(\[\.,]\\d{0,4})?$`);

    if (startingWithZero) return startingWithZero;

    if (!(regexp.test(value) || value === '')) {
      return translate('page.dealerCommissionsChange.error-fields-length');
    }
  };

  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  validateFieldsPercent = (translate) => (value) => {
    const isNumberFormatValid = this.validateNumberFormat(translate)(value);

    if (isNumberFormatValid) {
      return isNumberFormatValid;
    }

    const isValid = this.validateFieldsLength(translate)(value);
    if (isValid) return isValid;

    return !(this.getParsedValue(value) >= MIN_PERCENT && this.getParsedValue(value) <= MAX_PERCENT)
      ? translate('page.dealerCommissionsChange.error-min-max-percent')
      : '';
  };

  validateFieldsNumber = (translate) => (value) => {
    const isNumberFormatValid = this.validateNumberFormat(translate)(value);

    if (isNumberFormatValid) {
      return isNumberFormatValid;
    }

    const isValid = this.validateFieldsLength(translate)(value);
    if (isValid) return isValid;

    if (!(this.getParsedValue(value) >= MIN_VAlUE && this.getParsedValue(value) <= MAX_VAlUE)) {
      return translate('page.dealerCommissionsChange.error-min-max-value');
    }
  };

  globalValidate = (translate) => (formState) => {
    const {
      DealerCommissionMin,
      DealerCommissionMax,
      DealerCommissionClientMin,
      DealerCommissionClientMax,
      PaymentSystemCommissionMin,
      PaymentSystemCommissionMax,
      PaymentSystemCommissionClientMin,
      PaymentSystemCommissionClientMax,
    } = formState;

    // if (+DealerCommissionMin > +DealerCommissionMax) {
    //   return `${translate('grids.dealerCommissionsChange.field-dealer-commission-min')} ${translate(
    //     'page.dealerCommissionsChange.error-min-max',
    //   )} ${translate('grids.dealerCommissionsChange.field-dealer-commission-max')}`;
    // }

    if (this.getParsedValue(DealerCommissionClientMin) > this.getParsedValue(DealerCommissionClientMax)) {
      return `${translate('grids.dealerCommissionsChange.field-client-commission-min')} ${translate(
        'page.dealerCommissionsChange.error-min-max',
      )} ${translate('grids.dealerCommissionsChange.field-client-commission-max')}`;
    }
    if (this.getParsedValue(PaymentSystemCommissionMin) > this.getParsedValue(PaymentSystemCommissionMax)) {
      return `${translate('grids.dealerCommissionsChange.field-dealer-commission-min')} ${translate(
        'page.dealerCommissionsChange.error-min-max',
      )} ${translate('grids.dealerCommissionsChange.field-dealer-commission-max')}`;
    }

    if (this.getParsedValue(PaymentSystemCommissionClientMin) > this.getParsedValue(PaymentSystemCommissionClientMax)) {
      return `${translate('grids.dealerCommissionsChange.field-client-commission-min')} ${translate(
        'page.dealerCommissionsChange.error-min-max',
      )} ${translate('grids.dealerCommissionsChange.field-client-commission-max')}`;
    }
  };

  getValidators = (translate) => {
    return {
      DealerCommissionPercent: this.validateFieldsPercent(translate),
      DealerCommissionClientPercent: this.validateFieldsPercent(translate),
      PaymentSystemCommissionPercent: this.validateFieldsPercent(translate),
      PaymentSystemCommissionClientPercent: this.validateFieldsPercent(translate),
      DealerCommissionMin: this.validateFieldsNumber(translate),
      DealerCommissionMax: this.validateFieldsNumber(translate),
      DealerCommissionClientMin: this.validateFieldsNumber(translate),
      DealerCommissionClientMax: this.validateFieldsNumber(translate),
      PaymentSystemCommissionMin: this.validateFieldsNumber(translate),
      PaymentSystemCommissionMax: this.validateFieldsNumber(translate),
      PaymentSystemCommissionClientMin: this.validateFieldsNumber(translate),
      PaymentSystemCommissionClientMax: this.validateFieldsNumber(translate),
    };
  };
}

export default new DealerCommissionFormService();
