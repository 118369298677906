import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';

const api = new ApiService();

class UpdateServiceStatusModal extends PureComponent {
  state = {
    isLoading: false,
    isServiceUpdateFailed: false,
    processNumber: 0,
    updatedServices: [],
  };

  handleUpdateServiceListStatus = async () => {
    const { dialogVariant, selectedItems, closeModal, translate, onRefresh } = this.props;
    await this.setState({ isLoading: true, isServiceUpdateFailed: false, processNumber: 0 });

    const serviceIdList = selectedItems.map(({ ServiceId }) => ServiceId);
    const updateMethod = dialogVariant === 'turnOnService' ? api.serviceOn : api.serviceOff;

    for (const serviceId of serviceIdList) {
      try {
        await updateMethod(serviceId);
        await this.setState(({ updatedServices }) => ({ updatedServices: [...updatedServices, serviceId] }));
      } catch (e) {
        const { showError } = this.props;

        const successfulUpdateServicesMessage = this.state.updatedServices.length
          ? `${translate(
              `page.servicesBigTable.${
                dialogVariant === 'turnOnService' ? 'successfullyTurnOnServiceList' : 'successfullyTurnOffServiceList'
              }`,
            )} ${this.state.updatedServices.join(',')} <br/>`
          : '';

        showError({
          ...e,
          data: e.data
            ? {
                ...e.data,
                Message: e.data.Message
                  ? `${successfulUpdateServicesMessage}${translate(
                      `page.servicesBigTable.serviceError`,
                    )} ${serviceId}: ${e.data.Message}`
                  : '',
              }
            : null,
        });

        this.setState({ isLoading: false, isServiceUpdateFailed: true });
        break;
      }
    }

    if (!this.state.isServiceUpdateFailed) {
      await this.setState({ isLoading: false });
      message.success(
        translate(
          `page.servicesBigTable.${
            dialogVariant === 'turnOnService' ? 'successfullyTurnOnService' : 'successfullyTurnOffService'
          }`,
        ),
        2,
      );
      closeModal();
      onRefresh();
    }
  };

  render() {
    const { dialogVariant, translate, visible, closeModal, selectedItems } = this.props;
    const { isLoading, updatedServices } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate(`page.servicesBigTable.${dialogVariant === 'turnOnService' ? 'onService' : 'offService'}`)}
        onCancel={isLoading ? () => {} : closeModal}
        footer={[
          <Button type="primary" key="add" onClick={this.handleUpdateServiceListStatus} loading={isLoading}>
            {translate(`page.servicesBigTable.${dialogVariant === 'turnOnService' ? 'turnOn' : 'turnOff'}`)}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="UpdateServiceStatusModal-content">
          {isLoading ? (
            <>
              {translate(`page.routingManagement.processCompletedAt`)}{' '}
              <b>{Math.round((updatedServices.length / selectedItems.length) * 100)}%</b>
            </>
          ) : (
            translate(
              `page.servicesBigTable.${
                dialogVariant === 'turnOnService' ? 'areYouSureTurnOnService' : 'areYouSureTurnOffService'
              }`,
            )
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(UpdateServiceStatusModal);
