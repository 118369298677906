import React, { PureComponent } from 'react';
import { Select } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import ModalApprove from './modalApprove';
import ModalSystemEncashment from './modalSystemEncashment';
import './remoteCommands.scss';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
const { Option } = Select;

class RemoteCommands extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray(props.permissionName);
    const { gridName } = this.props;

    this.remoteCommands = [
      {
        command: '0',
        title: 'page.dashboard.btn-commands',
        show: true,
      },
      {
        command: '1',
        title: 'page.dashboard.terminal-reboot',
        show: checkElement(`${gridName}-terminal-reboot`, this.currentArray),
        // show: true
      },
      {
        command: '2',
        title: 'page.dashboard.program-reload',
        show: checkElement(`${gridName}-program-reload`, this.currentArray),
        // show: true
      },
      {
        command: '3',
        title: 'page.dashboard.terminal-shutdown',
        show: checkElement(`${gridName}-terminal-shutdown`, this.currentArray),
        // show: true
      },
      {
        command: '4',
        title: 'page.dashboard.terminal-turnon',
        show: checkElement(`${gridName}-terminal-turnon`, this.currentArray),
        // show: true
      },
      {
        command: '5',
        title: 'page.dashboard.terminal-poweroff',
        show: checkElement(`${gridName}-terminal-poweroff`, this.currentArray),
        // show: true
      },
      {
        command: '6',
        title: 'page.dashboard.terminals-cashrecalculate',
        show: checkElement(`${gridName}-terminals-cashrecalculate`, this.currentArray),
        // show: true
      },
      {
        command: '7',
        title: 'page.dashboard.terminals-unlockBillAcceptor',
        show: checkElement(`${gridName}-terminals-unlockBillAcceptor`, this.currentArray),
        // show: true
      },
    ];

    this.state = {
      chosen: this.remoteCommands[0].command,
      showModal: false,
      encashmentModal: false,
    };
  }

  showModal = () => {
    const { setVisible } = this.props;
    const { showModal } = this.state;
    this.setState({ showModal: !showModal }, setVisible());
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closeEncashmentModal = () => {
    this.setState({ encashmentModal: false });
  };

  showEncashmentModal = () => {
    const { setVisible } = this.props;
    const { encashmentModal } = this.state;
    this.setState({ encashmentModal: !encashmentModal }, setVisible());
  };

  switchCommand = (value) => {
    switch (value) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '7':
        return this.showModal();
      case '6':
        return this.showEncashmentModal();
      default:
        return;
    }
  };

  getContent = (value) => {
    const {
      translate,
      dataItem: { TerminalId },
    } = this.props;
    switch (value) {
      case '1':
        return `${translate('page.dashboard.confirm-terminal-reboot')} ${translate(
          'page.dashboard.confirmation-text-1',
        )} ${TerminalId}?`;
      case '2':
        return `${translate('page.dashboard.confirm-program-reload')} ${translate(
          'page.dashboard.confirmation-text-2',
        )} ${TerminalId}?`;
      case '3':
        return `${translate('page.dashboard.confirm-shutdown')} ${translate(
          'page.dashboard.confirmation-text-1',
        )} ${TerminalId}?`;
      case '4':
        return `${translate('page.dashboard.confirm-terminal-turnon')} ${translate(
          'page.dashboard.confirmation-text-1',
        )} ${TerminalId}?`;
      case '5':
        return `${translate('page.dashboard.confirm-shutdown')} ${translate(
          'page.dashboard.confirmation-text-1',
        )} ${TerminalId}? ${translate('page.dashboard.confirm-terminal-poweroff')}`;
      case '7':
        return `${translate('page.dashboard.confirm-terminal-unlockBillAcceptor')} ${translate(
          'page.dashboard.confirmation-text-2',
        )} ${TerminalId}?`;
      default:
        return;
    }
  };

  onSelectChange = (chosen) => {
    this.setState({ chosen }, () => this.switchCommand(chosen));
  };

  render() {
    const { translate, dataItem } = this.props;
    const { chosen, showModal, encashmentModal } = this.state;
    return (
      <>
        <Select onChange={this.onSelectChange} value={chosen} className="remote-commands-select">
          {this.remoteCommands.map(
            (item, i) =>
              item.show && (
                <Option key={`remote-command-${i.toString()}`} value={item.command}>
                  {`${translate(item.title)}`}
                </Option>
              ),
          )}
        </Select>
        {showModal && (
          <ModalApprove
            dataItem={dataItem}
            chosen={chosen}
            visible={showModal}
            showModal={this.showModal}
            closeModal={this.closeModal}
            content={this.getContent(chosen)}
          />
        )}
        {encashmentModal && (
          <ModalSystemEncashment
            dataItem={dataItem}
            visible={encashmentModal}
            showModal={this.showEncashmentModal}
            closeModal={this.closeEncashmentModal}
          />
        )}
      </>
    );
  }
}

export default withTranslate(RemoteCommands);
