const BlockedTransactions = (translate, value) => {
  switch (value) {
    case 'blockedTransactions-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'blockedTransactions-searchInLists':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.search-lists`)}`;
    case 'blockedTransactions-SUOList':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`page.blockedTransactions.SUOList`)}`;
    case 'blockedTransactions-addSUO':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.blockedTransactions.SUOList')}:
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.add')}`;
    case 'blockedTransactions-deleteSUO':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.blockedTransactions.SUOList')}:
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.delete')}`;
    default:
      return value;
  }
};

export default BlockedTransactions;
