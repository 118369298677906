import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { withTranslate } from '../../contexts/localContext';

import './gridAllEncashments.styled.scss';

const api = new ApiService();

class SendEncashmentModal extends PureComponent {
  state = {
    inputValue: '',
    err: '',
    isLoading: false,
    errorStack: {
      failedChecks: [],
      errorMessage: '',
    },
  };

  onHandleChangeValue = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value });
  };

  isValidEmail = (email) => {
    const { translate } = this.props;

    const checkEmails = email.includes(' ');

    if (checkEmails && !email.includes(';')) {
      this.setState({ err: `${translate('page.encashment.err-not-comma')}` });
      return;
    }

    const emails = email.split(';');
    for (const item of emails) {
      const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(item.trim());
      if (!isValid) {
        this.setState({ err: `${translate('page.encashment.err-not-valid')}` });
        return isValid;
      }
    }
    return null;
  };

  onHandleSubmit = () => {
    const { inputValue } = this.state;
    const validEmail = this.isValidEmail(inputValue);
    if (validEmail === null) {
      this.sendCollectionReceiptsToEmail();
    }
  };

  iterateSend = async ([{ CollectionId: collectionId, TerminalId: terminalId }, ...restItem], emailString) => {
    try {
      await api.sendCollectionReceiptsToEmail({
        collectionId,
        terminalId: terminalId,
        emails: emailString,
      });

      if (restItem && restItem.length) {
        await this.iterateSend(restItem, emailString);
      }
    } catch (e) {
      this.setState(({ errorStack: { failedChecks, errorMessage } }) => ({
        errorStack: {
          failedChecks: [...failedChecks, collectionId],
          errorMessage: errorMessage || (e.data && e.data.Message) || '',
        },
      }));

      if (restItem && restItem.length) {
        await this.iterateSend(restItem, emailString);
      }
    }
  };

  sendCollectionReceiptsToEmail = async () => {
    const { inputValue } = this.state;
    const { onClose, selectedItems, translate, showError } = this.props;

    this.setState({ isLoading: true });
    await this.iterateSend(
      selectedItems,
      inputValue
        .split(';')
        .map((value) => value.trim())
        .join(','),
    );

    if (this.state.errorStack.failedChecks.length) {
      showError({
        data: {
          Message: `${translate('page.encashment.errorSendToEmail')} ${this.state.errorStack.failedChecks.join(
            ',',
          )} <br/> ${this.state.errorStack.errorMessage} `,
        },
      });
    } else {
      this.setState({
        errorStack: {
          failedChecks: [],
          errorMessage: '',
        },
      });
      message.success(translate('page.encashment.successfulMultiSendCheck'), 2);
    }

    this.setState({ isLoading: false });
    await onClose();
  };

  componentDidUpdate(prevProps, prevState) {
    const { inputValue } = this.state;
    const { inputValue: prevInputValue } = prevState;
    if (inputValue !== prevInputValue) {
      this.setState({ err: '' });
    }
  }

  render() {
    const { inputValue, err, isLoading } = this.state;
    const { translate, visible, onClose } = this.props;
    return (
      <>
        <Modal
          title={translate('page.encashment.tab-encashment-check-email')}
          visible={visible}
          onCancel={() => onClose()}
          footer={[
            <Button type="primary" disabled={!!err.length} onClick={this.onHandleSubmit}>
              {translate('core.buttonTitles.send-check')}
            </Button>,
            <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          <div className="ModalEncashmentCheck-row">
            <div className="ModalEncashmentCheck-input">
              <div>{translate('page.encashment.enterTheEmailAddresses')}</div>
              <TextArea
                value={inputValue}
                onChange={this.onHandleChangeValue}
                className={err && err.length ? 'input-err' : ''}
              />
              {err && err.length && <div className="ModalEncashmentCheck-err-msg">{err}</div>}
            </div>
          </div>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SendEncashmentModal);
