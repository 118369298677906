import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import ApiService from '../../services/apiService';
import { withTranslate } from '../../contexts/localContext';
import './login.scss';

const api = new ApiService();

// initialization of ant sub components
const { Item } = Form;

class RestorePassword extends PureComponent {
  static propTypes = {
    onAction: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    loading: false,
  };

  setSpinner = (value) => {
    this.setState({ loading: value });
  };

  handleLoginSubmit = (e) => {
    const { form, translate } = this.props;
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        console.log('Received error from form: ', err);
        return;
      }

      try {
        this.setSpinner(false);
        const { login, email } = values;
        await api.restorePassword(login, email);
        message.success(`${translate('login.restore-success')}`, 6);
      } catch (error) {
        const { showError } = this.props;
        showError(error);
      } finally {
        this.setSpinner(false);
      }
    });
  };

  render = () => {
    const { form, onAction, translate } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <>
        <h2>{translate('login.reset')}</h2>
        <Form onSubmit={this.handleLoginSubmit}>
          <Item>
            {getFieldDecorator('login', {
              rules: [{ required: true, message: translate('errorMessages.pleaseInputYourUsername') }],
            })(<Input prefix={<span className="k-icon k-i-user" />} placeholder={translate('login.username')} />)}
          </Item>
          <Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: translate('errorMessages.pleaseInputYourEmail') }],
            })(
              <Input
                prefix={<span className="k-icon k-i-email" />}
                type="email"
                placeholder={translate('login.mail')}
              />,
            )}
          </Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {translate('login.reset_password')}
          </Button>
          <Button type="link" onClick={onAction}>
            {translate('login.sign_in')}
          </Button>
        </Form>
      </>
    );
  };
}

export default withRouter(withTranslate(Form.create({ name: 'restorepass-form' })(RestorePassword)));
