import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Select, Row, Input, Alert, Checkbox } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import '../../../components/TerminalsCommissionsGrid/ModalChangeProfile/modalChangeProfile.scss';
import '../../screenControl(terminalsControl)/settingsAndEvents/remoteSettings/cellValue/modalCellValue.scss';
import '../modalAddCheckSending/modalAddCheckSending.scss';

const api = new ApiService();

const { Option } = Select;

const { TextArea } = Input;

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class ModalAddCheckSending extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    dataItemEditModal: PropTypes.any,
  };

  state = {
    loading: false,
    isLoading: false,
    collectorsData: [],
    collectorValue: null,
    emailValue: '',
    errorText: null,
    checkboxValue: false,
  };

  componentDidMount() {
    this.onInit();
  }

  onInit = async () => {
    const {
      showError,
      dataItemEditModal: { TerminalId, UserId, Emails, Active },
    } = this.props;
    try {
      this.toggleLoader(true);
      const collectorsData = await api.getCollectors(TerminalId);
      this.setState({
        collectorsData: collectorsData.Collectors ? collectorsData.Collectors : [],
        collectorValue: UserId,
        emailValue: Emails,
        checkboxValue: Active,
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onChangeEmailValue = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  renderTerminalsInput = () => {
    const {
      dataItemEditModal: { TerminalId },
    } = this.props;
    return <Input disabled value={TerminalId} />;
  };

  onChangeCollectorsSelect = (collectorValue) => {
    this.setState({ collectorValue });
  };

  renderCollectorsSelect = () => {
    const { collectorsData, collectorValue } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={collectorValue}
        onChange={this.onChangeCollectorsSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {collectorsData.map(({ Id }) => (
          <Option value={Id} key={`modalAddCheck-collector-${Id}`}>
            {Id}
          </Option>
        ))}
      </Select>
    );
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  checkEmail = (emailValue) => {
    const { translate } = this.props;
    const emailArr = emailValue.split(/[;]+/);
    let errorText = null;
    emailArr.forEach((el) => {
      if (el && el !== '' && !validateEmail(el)) {
        errorText = `${translate('page.reportPeriod.error-msg-incorrect-email')}`;
      }
    });
    this.setState({ errorText });
  };

  onSubmit = async () => {
    const { emailValue, collectorValue, checkboxValue } = this.state;
    const {
      toggleModal,
      showError,
      update,
      dataItemEditModal: { TerminalId, Id },
    } = this.props;
    await this.checkEmail(emailValue);
    if (this.state.errorText !== null) return;
    else {
      try {
        const trimmedElemValue = emailValue
          .split(/[;]+/)
          .filter((el) => el !== '')
          .map((el) => el.trim())
          .join(';');
        const model = {
          Id,
          TerminalId,
          UserId: collectorValue,
          Emails: trimmedElemValue,
          Active: checkboxValue,
        };
        const request = await api.collectionReceiptSendManage(model);
        toggleModal();
        update();
        return request;
      } catch (error) {
        showError(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  onChangeCheckbox = (e) => {
    this.setState({ checkboxValue: e.target.checked });
  };

  renderCheckbox = () => {
    const { checkboxValue } = this.state;
    return <Checkbox checked={checkboxValue} onChange={this.onChangeCheckbox} />;
  };
  render() {
    const { translate, visible, toggleModal } = this.props;
    const { loading, isLoading, collectorValue, emailValue, errorText } = this.state;
    const isDisabled = !collectorValue || !emailValue;
    return (
      <>
        <Modal
          title={translate('page.screenControlBusinessServices.button-edit')}
          visible={visible}
          centered
          onCancel={toggleModal}
          className="modal-system-encashment addSets-modal-cellVal"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.onSubmit} disabled={isDisabled}>
              {translate('page.screenControlBusinessServices.button-save')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.terminalsMainProfileList.column-terminalId')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderTerminalsInput()}
            </Col>
          </Row>

          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.allEncashments.column-userId')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderCollectorsSelect()}
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('page.profile.label-email')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <TextArea value={emailValue} onChange={this.onChangeEmailValue} rows={4} disabled={!collectorValue} />
              <p className="input-warning">{translate('grids.encashmentSendingCheck.email-warning')}</p>
              {errorText && <Alert message={errorText} type="error" style={{ marginTop: '10px' }} showIcon />}
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.encashmentSendingCheck.checkbox-auto')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderCheckbox()}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalAddCheckSending);
