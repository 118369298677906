import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import { ApiService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
// import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import CellPhotos from '../adminStockPhotos/cellPhotos';
import CellStockStatus from '../adminStockPhotos/cellStockStatus';
import '../adminStockPhotos/gridAdminStockPhotos.scss';
import './gridOwnerStockPhotos.scss';

const api = new ApiService();

class GridOwnerStockPhotos extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('adminStockPhotos');
  }

  photosStatuses = [
    {
      key: 'All',
      value: 'grids.adminStockPhotos.stockStatus-all',
    },
  ];

  state = {
    isLoading: false,
    data: [],
    owners: [],
    visible: false,
    selectedPhotoStatus: this.photosStatuses[0].key,
    currentOwner: null,
    headerData: {
      TerminalsWithPhotoCount: 0,
      TerminalsCount: 0,
      NotConsideredCount: 0,
      BrandedByStandartCount: 0,
      BrandedCount: 0,
      NotBrandedCount: 0,
      TerminalsNotConsideredCount: 0,
      BrandedPercent: 0,
      BrandedByStandartPercent: 0,
    },
  };

  componentDidMount() {
    const userInfo = StorageService.getItem('userInfo');

    if (userInfo.OwnerId || userInfo.OwnerId === 0) {
      this.makeQuery();
    }
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  statusToColor = (arr, terminalStatus, isAlt) => {
    const opacity = isAlt ? '1' : '0.8';
    if (terminalStatus !== 'Active') return { backgroundColor: `rgba(187,187,187,${opacity})` };
    else return;
  };

  rowRender = (element, props) => {
    const arr = props.dataItem.Photos;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(arr, props.dataItem.oldStatusActivity, props.isAltRow);
    const style = { ...color, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  makeQuery = async () => {
    const { selectedPhotoStatus, headerData: prevStateHeaderData } = this.state;
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo.OwnerId && userInfo.OwnerId !== 0) return;
    else {
      try {
        this.toggleLoader(true);
        const model = {
          Owners: [userInfo.OwnerId],
          DropDownTerminalEstimateStatus: selectedPhotoStatus,
        };
        const headerModel = {
          OwnerId: userInfo.OwnerId,
        };
        let headerData = await api.terminalsPhotosHeader(headerModel);
        if (!headerData.TerminalsCount) {
          headerData = prevStateHeaderData;
        }
        await this.getOwnerId();
        const data = await api.terminalsPhotosEstimate(model);
        const renderData = this.renderData(data);
        this.setState({ data: renderData, headerData });
      } catch (error) {
        const { showError } = this.props;
        showError(error);
      } finally {
        this.toggleLoader(false);
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  onSelectChange = (selectedPhotoStatus) => {
    this.setState({ selectedPhotoStatus }, this.makeQuery);
  };

  getOwnerId = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo.OwnerId && userInfo.OwnerId !== 0) {
      this.setState({ currentOwner: null });
    }

    try {
      const response = await api.owners();

      this.setState({
        currentOwner:
          response && response.length ? response.find(({ OwnerId }) => OwnerId === userInfo.OwnerId) || null : null,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const {
      headerData: {
        TerminalsWithPhotoCount,
        TerminalsCount,
        TerminalsNotConsideredCount,
        BrandedByStandartCount,
        BrandedCount,
        NotBrandedCount,
        BrandedByStandartPercent,
        BrandedPercent,
      },
      currentOwner,
    } = this.state;
    if (!currentOwner) return;
    const { OwnerName } = currentOwner;
    // const BrandedByStandartPercent = BrandedByStandartCount !== 0 && TerminalsCount !== 0 ?
    //   Math.floor((BrandedByStandartCount * 100) / TerminalsCount) : 0;
    // const BrandedCountPercent = BrandedCount !== 0 && TerminalsCount !== 0 ?
    //   Math.floor((BrandedCount * 100) / TerminalsCount) : 0;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="gridAdminStockPhotos-toolbar-row">
          <h2 className="gridAdminStockPhotos-toolbar-h2">{translate('page.adminStockPhotos.toolbar-h2')}:</h2>
          <h2 className="gridAdminStockPhotos-toolbar-h2 owner">{OwnerName}</h2>
        </div>
        <div className="gridAdminStockPhotos-toolbar-row space-between mt-10">
          <div className="sub-row left">
            <p>{translate('grids.adminStockPhotos.span-terminals-with-photo')}:</p>
            <div className="block-with-photos">
              {TerminalsWithPhotoCount}/{TerminalsCount}
            </div>
          </div>
          <div className="sub-row right">
            <div className="info-block">
              <div className="info-wrapper brandedByStandart">
                <span>{translate('grids.adminStockPhotos.grid-modal-slider-byStandart')}:</span>
                <span className="second bold">
                  {BrandedByStandartCount}/{TerminalsCount}
                </span>
                <span className="third info-percent">{BrandedByStandartPercent}%</span>
              </div>
              <div className="info-wrapper branded">
                <span>{translate('grids.adminStockPhotos.grid-status-value-branded')}:</span>
                <span className="second bold">
                  {BrandedCount}/{TerminalsCount}
                </span>
                <span className="third info-percent">{BrandedPercent}%</span>
              </div>
            </div>
            <div className="info-block">
              <div className="info-wrapper second notBranded">
                <span>{translate('grids.adminStockPhotos.grid-status-value-notBranded')}:</span>
                {/* <span className="second bold">60/305</span> */}
                <span className="third info-percent">{NotBrandedCount}</span>
              </div>
              <div className="info-wrapper second underConsideration">
                <span>{translate('grids.adminStockPhotos.grid-status-value-underConsideration')}:</span>
                {/* <span className="second bold">60/305</span> */}
                <span className="third info-percent">{TerminalsNotConsideredCount}</span>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  renderData = (data) => {
    return data.map((el) => {
      const oldElem = { ...el };
      return {
        ...el,
        initialTerminalStatusName: oldElem.TerminalEstimateStatus,
        oldStatusActivity: oldElem.TerminalStatus,
        TerminalStatus: this.statusActivityToGridValue(oldElem.TerminalStatus),
        TerminalEstimateStatus: this.statusToGridValue(oldElem.TerminalEstimateStatus),
      };
    });
  };

  statusToGridValue = (status) => {
    const { translate } = this.props;
    switch (status) {
      case 'NotBranded':
        return `${translate('grids.adminStockPhotos.grid-status-value-notBranded')}`;
      case 'BrandedByStandart':
        return `${translate('grids.adminStockPhotos.grid-status-value-brandedByStandart')}`;
      case 'UnderConsideration':
        return `${translate('grids.adminStockPhotos.grid-status-value-underConsideration')}`;
      case 'Branded':
        return `${translate('grids.adminStockPhotos.grid-status-value-branded')}`;
      default:
        return status;
    }
  };

  statusActivityToGridValue = (status) => {
    const { translate } = this.props;
    switch (status) {
      case 'Active':
        return `${translate('grids.adminStockPhotos.grid-status-value-active')}`;
      case 'NotActive':
        return `${translate('grids.adminStockPhotos.grid-status-value-notActive')}`;
      case 'Disabled':
        return `${translate('grids.adminStockPhotos.grid-status-value-disabled')}`;
      case 'Faulty':
        return `${translate('grids.adminStockPhotos.grid-status-value-faulty')}`;
      case 'Arrested':
        return `${translate('grids.adminStockPhotos.grid-status-value-arrested')}`;
      default:
        return status;
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        rowHeight={40}
        onRefresh={this.makeQuery}
        rowRender={this.rowRender}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="ownerStockPhotos"
        permissionName="ownerStockPhotos"
        selectedField="selected"
      >
        <GridColumn field="TerminalId" title="grids.adminStockPhotos.column-terminalId" width="180" filter="numeric" />

        <GridColumn field="CityName" title="grids.adminStockPhotos.column-city" width="270" />

        <GridColumn field="RegionName" title="grids.adminStockPhotos.column-region" width="150" />

        <GridColumn field="Address" title="grids.adminStockPhotos.column-address" width="150" />
        <GridColumn
          field="Photos"
          title="grids.adminStockPhotos.column-photo"
          width="150"
          columnMenu={false}
          cell={(props) => <CellPhotos update={this.makeQuery} isOwnerPhotos={true} {...props} />}
          isPhotoCell={true}
        />

        <GridColumn
          field="TerminalEstimateStatus"
          title="grids.adminStockPhotos.column-status"
          width="300"
          cell={(props) => <CellStockStatus isCellStockStatus={true} isTooltip={true} {...props} />}
          tooltip={true}
          isTooltip={true}
        />

        <GridColumn field="TerminalStatus" title="grids.adminPanel.column-active" width="300" />
      </Grid>
    );
  }
}

export default withTranslate(GridOwnerStockPhotos);
