import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { ApiService, StorageService, tabService } from '../../../../services';
import Grid from '../../../../components/grids/baseGrid';
import { ALL_COLUMNS_GRID, HANDLERS } from '../constants';
import CellBoolean from '../../../account/cellBoolean';
import findArray from '../../../../utils/findArrForCurrentPage';
import checkElement from '../../../../utils/checkElement';
import GqlService from '../../../../components/grids/gql.service';
import ServicesBigTableService from '../servicesBigTable.service';
import { lowerCaseFirstLetter } from '../../../../utils/lowerCaseFirstLetter';
import { getTransformDateString } from '../../../../utils/getTransformDateString';
import UpdateServiceStatusModal from './UpdateServiceStatusModal';
import CloneServiceModal from './CloneServiceModal';
import StepServiceCreationModal from './StepServiceCreationModal';

import './Services.scss';

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const api = new ApiService();

class Services extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('serviceManagement');
  }

  state = {
    servicesList: [],
    withDeleted: false,
    loadingStack: [],
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    selectedItems: [],
    dialogVariant: '',
  };

  updateLoadingStack = async (isIncrement = true) => {
    await this.setState(({ loadingStack }) => ({
      loadingStack: isIncrement
        ? [...loadingStack, true]
        : loadingStack.length
        ? [...loadingStack].slice(1)
        : loadingStack,
    }));
  };
  fetchAllServicesGQL = async (props, skip = 0, allItems = []) => {
    const { filtersString, sortString, withDeleted } = this.state;
    const take = 3000;
    const currentFilterString =
      (!props && withDeleted) || (props && !props.selectIdsString && withDeleted)
        ? filtersString
        : `{and:[${filtersString}${
            props && props.selectIdsString ? `, {serviceId:{in:[${props.selectIdsString}]}}` : ``
          }${withDeleted ? '' : `,{statusId:{neq:4}}`}]}`;
    try {
      await this.updateLoadingStack();
      const storageColumns = StorageService.getItem('servicesBigTableGrid');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_COLUMNS_GRID);

      const argumentsString = `take:${take}, skip:${skip}${
        sortString ? ` ,order: ${sortString || '{serviceId: ASC}'}` : ''
      }${filtersString || currentFilterString ? `,where:{and:[${filtersString}, ${currentFilterString}]}` : ''}`;

      const query = `servicesAll(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      if (data && data.data.servicesAll.items && data.data.servicesAll.items.length > 0) {
        allItems = [...allItems, ...data.data.servicesAll.items];
        return data.data.servicesAll.pageInfo.hasNextPage
          ? await this.fetchAllServicesGQL(props, skip + take, allItems)
          : ServicesBigTableService.getAllItemsGQLResponse(allItems);
      } else {
        await this.updateLoadingStack(false);
        return [];
      }
    } catch (e) {
      const { showError } = this.props;
      await this.updateLoadingStack(false);
      showError(e);
    } finally {
      await this.updateLoadingStack(false);
    }
  };

  fetchServicesGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString, withDeleted } = this.state;
    const currentFilterString =
      (!props && withDeleted) || (props && !props.selectIdsString && withDeleted)
        ? filtersString
        : `{and:[${filtersString}${
            props && props.selectIdsString ? `, {serviceId:{in:[${props.selectIdsString}]}}` : ``
          }${withDeleted ? '' : `,{statusId:{neq:4}}`}]}`;

    try {
      await this.updateLoadingStack();

      const storageColumns = StorageService.getItem('servicesBigTableGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_COLUMNS_GRID);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}${
        sortString ? ` ,order: ${sortString || '{serviceId: ASC}'}` : ''
      }${filtersString || currentFilterString ? `,where:{and:[${filtersString}, ${currentFilterString}]}` : ''}`;

      const query = `services(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);
      const { servicesList, hasNextPage, hasPreviousPage } = ServicesBigTableService.getGQLResponse(data);
      await this.setState({ servicesList, hasNextPage, hasPreviousPage }, () => {
        this.updateLoadingStack(false);
      });
    } catch (e) {
      const { showError } = this.props;
      await this.updateLoadingStack(false);
      showError(e);
    }
  };

  showRemoteCallback = () => {
    this.setState(
      ({ withDeleted }) => ({ withDeleted: !withDeleted, pageValue: 1 }),
      () => {
        this.fetchServicesGQL();
      },
    );
  };

  getHandlers = () => {
    const { servicesList } = this.state;

    return HANDLERS.map(({ title, type, dropdown, webTag }) => ({
      title,
      action: (props) => tabService.addTab({ type, dataItem: { selectItem: props.dataItem, servicesList } }),
      dropdown: dropdown,
      show: checkElement(webTag, this.currentArray),
      // show: true,
    }));
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchServicesGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchServicesGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchServicesGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  getHandleExelExportDate = async () => {
    const { selectIdsString } = this.state;
    try {
      return await this.fetchAllServicesGQL({ selectIdsString });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col>
        <div className="ServiceBigTable-actions">
          {checkElement('serviceManagement-addService', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({ type: 'createService' });
                }}
              >
                {translate('page.servicesBigTable.addService')}
              </Button>
            </div>
          )}

          {checkElement('serviceManagement-turnOnService', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                  this.setState({ dialogVariant: 'turnOnService' });
                }}
              >
                {translate('page.servicesBigTable.onService')}
              </Button>
            </div>
          )}

          {checkElement('serviceManagement-turnOffService', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                  this.setState({ dialogVariant: 'turnOffService' });
                }}
              >
                {translate('page.servicesBigTable.offService')}
              </Button>
            </div>
          )}

          {checkElement('serviceManagement-cloneService', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={selectedItems.length !== 1}
                onClick={() => {
                  this.setState({ dialogVariant: 'cloneService' });
                }}
              >
                {translate('page.servicesBigTable.cloneService')}
              </Button>
            </div>
          )}

          {checkElement('serviceManagement-serviceCreation', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dialogVariant: 'stepServiceCreation' });
                }}
              >
                {translate('page.servicesBigTable.stepServiceCreation')}
              </Button>
            </div>
          )}
        </div>
      </Col>
    );
  };

  componentDidMount() {
    this.fetchServicesGQL();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }
      await this.setState({
        selectedItems: [],
      });

      await this.fetchServicesGQL({ selectIdsString });
    }
  }

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  setColorStatus = (statusName) => {
    switch (statusName) {
      case 'Неактивный':
        return { backgroundColor: 'rgba(255, 0, 0,1)', color: 'black', borderBottom: '1px solid grey' };
      case 'Активный':
        return { backgroundColor: 'rgba(92, 184, 92, 1)', color: 'black', borderBottom: '1px solid grey' };
      case 'Временно отключен':
        return {};
      case 'Удален':
        return { backgroundColor: 'rgba(255, 165, 0, 1)', color: 'black', borderBottom: '1px solid grey' };
      default:
        break;
    }
  };

  render() {
    const {
      loadingStack,
      withDeleted,
      servicesList,
      pageValue,
      hasNextPage,
      hasPreviousPage,
      dialogVariant,
      selectedItems,
      selectIdsString,
    } = this.state;

    if (!checkElement('serviceManagement-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={servicesList}
          onRefresh={() => {
            this.fetchServicesGQL({ selectIdsString });
          }}
          name="servicesBigTableGrid"
          toolbar={this.buildToolbar()}
          fieldForSelection="ServiceId"
          isShowSpecificFilterByFieldName="ServiceId"
          showRemoteLabel="showRemoteServices"
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={withDeleted}
          isLoading={loadingStack.length}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          pageChange={this.getStateSetterByName('pageValue')}
          setSortingString={this.getStateSetterByName('sortString')}
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
          onFieldsConfigChange={this.onFieldsConfigChange}
          onSelect={this.handleSelectionChange}
          getHandleExelExportDate={this.getHandleExelExportDate}
          multiSelected
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            title="grids.servicesBigTable.column-serviceId"
            field="ServiceId"
            handlers={this.getHandlers()}
            dropdown={checkElement('serviceManagement-dropDownServiceId', this.currentArray)}
            // groupDropdown={true}
            // groupList={GROUP_LIST}
            width="150px"
            filter="numeric"
          />
          <GridColumn title="grids.servicesBigTable.column-name" field="Name" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-group" field="GroupName" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-type" field="TypeName" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-supplier" field="SupplierName" width="150px" />
          <GridColumn
            title="grids.servicesBigTable.column-status"
            field="StatusName"
            width="150px"
            cell={({ dataItem: { StatusName } }) => <td style={this.setColorStatus(StatusName)}>{StatusName}</td>}
          />
          <GridColumn
            title="grids.servicesBigTable.column-original-status"
            field="OriginalStatusName"
            width="250px"
            cell={({ dataItem: { OriginalStatusName } }) => (
              <td style={this.setColorStatus(OriginalStatusName)}>{OriginalStatusName}</td>
            )}
          />
          <GridColumn
            title="grids.servicesBigTable.column-rootService"
            field="RootServiceId"
            width="200px"
            filter="numeric"
          />
          <GridColumn title="grids.servicesBigTable.column-baseService" field="RefId" width="150px" filter="numeric" />
          <GridColumn title="grids.servicesBigTable.column-code" field="F108" width="250px" />
          <GridColumn
            title="grids.servicesBigTable.column-commissionClient"
            field="CommissionClient"
            cell={WrappedCellBoolean}
            width="150px"
            filter="boolean"
          />
          <GridColumn
            title="grids.servicesBigTable.column-risky"
            field="ServiceCharactId"
            cell={WrappedCellBoolean}
            width="120px"
            filter="boolean"
          />
          <GridColumn title="grids.servicesBigTable.column-ownerService" field="SodName" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-categoryManager" field="ManagerName" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-acquisitionManager" field="SalesManagerName" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-supportManager" field="SupportManagerName" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-minimumAmount" field="AmountMin" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-maxAmount" field="AmountMax" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-purposePayment" field="F108Description" width="220px" />
          <GridColumn
            title="grids.servicesBigTable.column-firstTransaction"
            field="DateFirstTransaction"
            width="150px"
            cell={({ dataItem: { DateFirstTransaction } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateFirstTransaction, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(DateFirstTransaction) => getTransformDateString(DateFirstTransaction, 'DD.MM.YYYY')}
          />
          <GridColumn
            title="grids.servicesBigTable.column-lastTransaction"
            field="DateLastTransaction"
            width="180px"
            cell={({ dataItem: { DateLastTransaction } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastTransaction, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(DateLastTransaction) => getTransformDateString(DateLastTransaction, 'DD.MM.YYYY')}
          />
          <GridColumn title="grids.servicesBigTable.column-check" field="PsAccountName" width="150px" />
          <GridColumn
            title="grids.servicesBigTable.column-transition"
            field="TransitService"
            cell={WrappedCellBoolean}
            width="150px"
            filter="boolean"
          />
          <GridColumn
            title="grids.servicesBigTable.column-scaleCommission"
            field="IsScale"
            cell={WrappedCellBoolean}
            width="150px"
            filter="boolean"
          />
        </Grid>

        {(dialogVariant === 'turnOnService' || dialogVariant === 'turnOffService') && (
          <UpdateServiceStatusModal
            visible={dialogVariant === 'turnOnService' || dialogVariant === 'turnOffService'}
            dialogVariant={dialogVariant}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.fetchServicesGQL}
          />
        )}

        {dialogVariant === 'cloneService' && (
          <CloneServiceModal
            visible={dialogVariant === 'cloneService'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedItem={selectedItems[0]}
            onRefresh={this.fetchServicesGQL}
          />
        )}

        {dialogVariant === 'stepServiceCreation' && (
          <StepServiceCreationModal
            visible={dialogVariant === 'stepServiceCreation'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
          />
        )}
      </>
    );
  }
}
export default withTranslate(Services);
