import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';

import './UpdateMenuProfileModal.scss';

const api = new ApiService();

class UpdateMenuProfile extends PureComponent {
  state = {
    isLoading: false,
    inputValue: '',
  };

  componentDidMount() {
    const { profileStaffForUpdating } = this.props;

    if (!profileStaffForUpdating) {
      return;
    }

    const { variant, ...restProfileStaffForUpdating } = profileStaffForUpdating;

    if (variant === 'changeName') {
      this.setState({ inputValue: restProfileStaffForUpdating.currentProfileName });

      return;
    }
  }

  getStuffByVariant = () => {
    const {
      profileStaffForUpdating: { variant, ...restProfileStaffForUpdating },
      translate,
    } = this.props;
    const { inputValue } = this.state;

    if (variant === 'changeName') {
      return {
        title: translate('page.profilesMenu.modal-title-change'),
        buttonTitle: 'core.buttonTitles.change',
        method: api.menuProfilesUpdate,
        successfulMessage: 'page.profilesMenu.profileNameChangedSuccessfully',
        inputLabel: 'page.profilesMenu.newProfileName',
        methodModel: {
          profileId: restProfileStaffForUpdating.profileId,
          name: inputValue,
        },
      };
    }

    if (variant === 'insertProfile') {
      return {
        title: `${translate('page.profilesMenu.modal-title-add')} ${restProfileStaffForUpdating.currentProfileName}`,
        buttonTitle: 'core.buttonTitles.add',
        method: api.menuProfilesInsert,
        successfulMessage: 'page.profilesMenu.success-add',
        inputLabel: 'page.profilesMenu.profileName',
        methodModel: {
          parentId: restProfileStaffForUpdating.profileId,
          name: inputValue,
        },
      };
    }

    return {
      title: `${translate('page.profilesMenu.modal-title-delete')} ${restProfileStaffForUpdating.currentProfileName}`,
      buttonTitle: 'core.buttonTitles.delete',
      method: api.menuProfilesDelete,
      successfulMessage: 'page.profilesMenu.success-deleted',
      methodModel: restProfileStaffForUpdating.profileId,
    };
  };

  renderModalContent = () => {
    const { inputValue } = this.state;
    const { profileStaffForUpdating, translate } = this.props;
    const { inputLabel } = this.getStuffByVariant();

    if (!profileStaffForUpdating) {
      return <></>;
    }

    const { variant } = profileStaffForUpdating;

    if (variant === 'deleteProfile') {
      return (
        <div className="UpdateMenuProfileModal-deleteContent">
          {translate('page.profilesMenu.areYouSureDeleteSelectProfile')}
        </div>
      );
    }

    return (
      <>
        <div className="UpdateMenuProfileModal-inputLabel">{translate(inputLabel)}</div>
        <Input
          value={inputValue}
          onChange={({ target: { value: inputValue } }) => this.setState({ inputValue })}
          className="MenuProfilesModal-input"
        />
      </>
    );
  };

  handleActionSubmit = async () => {
    const { inputValue } = this.state;
    const {
      profileStaffForUpdating: { onSuccessfulUpdateCallback, variant },
      closeDialog,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const { method, methodModel, successfulMessage } = this.getStuffByVariant();
      const methodResponse = await method(methodModel);
      onSuccessfulUpdateCallback(variant === 'insertProfile' ? methodResponse : inputValue);
      message.success(translate(successfulMessage), 2.5);
      closeDialog();
      this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const {
      closeDialog,
      translate,
      visible,
      profileStaffForUpdating: { variant },
    } = this.props;

    const { isLoading, inputValue } = this.state;
    const { title, buttonTitle } = this.getStuffByVariant();

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            disabled={!inputValue && variant !== 'deleteProfile'}
            onClick={this.handleActionSubmit}
          >
            {translate(buttonTitle)}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderModalContent()}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateMenuProfile);
