import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';

class PrintCheckModal extends PureComponent {
  render() {
    const { translate, printCheckResult, closeModal } = this.props;

    return (
      <Modal
        visible={!!printCheckResult}
        title={translate('page.transactions.resultPrintCheck')}
        onCancel={closeModal}
        footer={[
          <Button style={{ margin: '0 auto' }} onClick={closeModal}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        {Object.entries(printCheckResult).map(([key, value]) => (
          <div>
            <b>{translate(`page.transactions.${lowerCaseFirstLetter(key)}`)}:</b> {value}
          </div>
        ))}
      </Modal>
    );
  }
}

export default withTranslate(PrintCheckModal);
