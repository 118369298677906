import React, { PureComponent } from 'react';
import { Button } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { tabService } from '../../services';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

import './EasyPayUsers.styled.scss';

class EasyPayUsers extends PureComponent {
  currentArray = findArray('easyPayUsers');

  render() {
    const { translate } = this.props;

    if (!checkElement('easyPayUsers-container', this.currentArray)) {
      return null;
    }

    return (
      <div className="EasyPayUsersContainer">
        {checkElement('easyPayUsers-easyCardBalance', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              tabService.addTab({
                type: 'easyPayBalance',
              });
            }}
          >
            {translate('page.easyPayUsers.easyCardBalance')}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslate(EasyPayUsers);
