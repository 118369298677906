export const INITIAL_VALUES = {
  paymentId: 0,
  amount: 0,
  aAccountId: [],
  bMfo: [],
  aName: [],
  bAccount: 0,
  bName: '',
  bIdClient: 0,
  number: '',
  narrative: '',
  datePost: '',
  approve: false,
  signature: '',
  autoNumber: true,
  supplierId: 0,
  comments: '',
  typeId: 0,
  extendedData: '',
  payerId: '',
  payerName: '',
  budgetCode: '',
  iban: '',
};

export const translateSlugsSelect = {
  bMfo: 'page.externalOutgoingPayments.bMfo-select',
  aName: 'page.externalOutgoingPayments.aName-select',
  aAccountId: 'page.externalOutgoingPayments.aAccountId-select',
};

export const translateSlugs = {
  paymentId: 'page.externalOutgoingPayments.paymentId', //Id платежа
  amount: 'page.externalOutgoingPayments.amount', //сумма (десятичное число 00 после заяпятой может быть отрецатильным )
  aName: 'page.externalOutgoingPayments.aName', //Найменування
  aAccountId: 'page.externalOutgoingPayments.aAccountId', //номер рахунку відправника
  bMfo: 'page.externalOutgoingPayments.bMfo', //МФО
  bAccount: 'page.externalOutgoingPayments.bAccount', //номер рахунку отримувача
  bName: 'page.externalOutgoingPayments.bName', //Найменування (може бути налл) відправника без латинський символів і ком текст ериа на одну строку!
  bIdClient: 'page.externalOutgoingPayments.bIdClient', //ОКПО (може бути налл)
  number: 'page.externalOutgoingPayments.number', //№ платіжного доручення(документа) (може бути налл) залочити якщо autoNumber тру (в режиме редактирования лочим (нельзя редактировать))
  narrative: 'page.externalOutgoingPayments.narrative', //призначення платежу  текст ериа на одну строку!
  datePost: 'page.externalOutgoingPayments.datePost', //дата
  approve: 'page.externalOutgoingPayments.approve', //Пітвердження (непонятний ключ). Отвечает за подтверждение платежа
  signature: 'page.externalOutgoingPayments.signature', //signature (непонятний ключ) (може бути налл)
  autoNumber: 'page.externalOutgoingPayments.autoNumber', //присвоить автоматически (в редактировании не выводим на экран)
  supplierId: 'page.externalOutgoingPayments.supplierId', //Id постачальника (непонятний ключ)
  comments: 'page.externalOutgoingPayments.comments', //Коментар   текст ериа на одну строку!
  typeId: 'page.externalOutgoingPayments.typeId', //Id Типу (непонятний ключ)
  extendedData: 'page.externalOutgoingPayments.extendedData', //Додаткові данні  (може бути налл)  текст ериа на одну строку!
  payerId: 'page.externalOutgoingPayments.payerId', //РНОКПП фактичного платника  (може бути налл) 8 або 10
  payerName: 'page.externalOutgoingPayments.payerName', //ПІБ фактичного платника  (може бути налл)  текст ериа на одну строку!
  budgetCode: 'page.externalOutgoingPayments.budgetCode', //Код виду сплати,  (може бути налл)
  iban: 'page.externalOutgoingPayments.iban', //IBAN
};

export const aNameConfig = [
  {
    fieldName: 'Id',
    title: '#',
    width: '100',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.externalOutgoingPayments.customerName',
    width: '345',
    isShowOnMobile: true,
  },
];
export const bMfoConfig = [
  {
    fieldName: 'Id',
    title: '#',
    width: '100',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.externalOutgoingPayments.customerName',
    width: '345',
    isShowOnMobile: true,
  },
];
export const aAccountIdConfig = [
  {
    fieldName: 'Id',
    title: '#',
    width: '40',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.externalOutgoingPayments.customerName',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'BankAccount',
    titleTranslateSlug: 'page.easyPayUsers.accountNumber',
    width: '130',
    isShowOnMobile: true,
  },
  {
    fieldName: 'BankMfo',
    titleTranslateSlug: 'page.externalOutgoingPayments.bMfo',
    width: '100',
    isShowOnMobile: true,
  },
];

export const CONFIGS = {
  aName: aNameConfig,
  bMfo: bMfoConfig,
  aAccountId: aAccountIdConfig,
};
