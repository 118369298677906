import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, message, Card } from 'antd';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import Grid from '../../../components/grids/baseGrid';
import UpdateSupplierUserModal from './UpdateSupplierUserModal';
import FormStateContainer from '../../../components/FormStateContainer';
import { INITIAL_VALUES } from './constants';
import LoadingPanel from '../../../components/loader';
import AddSupplierModal from './AddSupplierModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

import './SuppliersUsers.styled.scss';
import '../ServiceSuppliersGrid/ServiceSuppliersGrid.styled.scss';

const api = new ApiService();

class SuppliersUsers extends PureComponent {
  state = {
    isLoading: false,
    users: [],
    relatedSuppliers: [],
    modalVariant: '',
    selectedAccounts: [],
    selectedRelatedSuppliers: [],
  };

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    const {
      dataItem: { supplierId },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const users = await api.getProviderPortalAccount(supplierId);
      this.setState({ users, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  fetchRelatedSuppliers = async () => {
    const { selectedAccounts } = this.state;

    if (!selectedAccounts.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const relatedSuppliers = await api.getProviderPortalAccountSupplier(selectedAccounts[0].AccountId);

      this.setState({ relatedSuppliers, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  buildToolbar = (variant) => {
    const { translate } = this.props;
    const { selectedAccounts, selectedRelatedSuppliers } = this.state;

    return (
      <Col className="ServiceSuppliersGrid-toolbar">
        <div>
          <Button
            type="primary"
            disabled={variant !== 'users' && !selectedAccounts.length}
            onClick={() => {
              this.setState({ modalVariant: variant === 'users' ? 'addUser' : 'addSupplies' });
            }}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        </div>

        {variant === 'users' && (
          <div>
            <Button
              type="primary"
              disabled={!selectedAccounts.length}
              onClick={() => {
                this.setState({ modalVariant: 'editUser' });
              }}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          </div>
        )}

        <div>
          <Button
            type="primary"
            disabled={variant === 'users' ? !selectedAccounts.length : !selectedRelatedSuppliers.length}
            onClick={() => {
              this.setState({ modalVariant: variant === 'users' ? 'deleteAccount' : 'deleteSupplier' });
            }}
          >
            {translate('core.buttonTitles.delete')}
          </Button>
        </div>
      </Col>
    );
  };

  handleSelectedAccounts = (selectedAccounts) => {
    this.setState({ selectedAccounts }, () => {
      if (selectedAccounts.length) {
        this.fetchRelatedSuppliers();
      } else {
        this.setState({ relatedSuppliers: [] });
      }
    });
  };

  onSupplierDelete = async () => {
    const { selectedAccounts, selectedRelatedSuppliers } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.providerPortalAccountRefDelete({
        accountId: selectedAccounts[0].AccountId,
        supplierId: selectedRelatedSuppliers[0].SupplierId,
      });
      this.setState({ modalVariant: '' });
      message.success(translate(`page.serviceSupplier.relatedSupplierSuccessfulDeleted`), 2.5);
      this.fetchRelatedSuppliers();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  onAccountDelete = async () => {
    const { selectedAccounts } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.providerPortalAccountDelete(selectedAccounts[0].AccountId);
      this.setState({ modalVariant: '' });
      message.success(translate(`page.serviceSupplier.accountSuccessfulDeleted`), 2.5);
      this.fetchUsers();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, dataItem } = this.props;
    const { users, isLoading, modalVariant, selectedAccounts, relatedSuppliers } = this.state;

    return (
      <div className="SuppliersUsers">
        <Card type="inner" title={translate('page.serviceSupplier.accounts')}>
          <Grid
            data={users}
            onRefresh={this.fetchUsers}
            name="ServiceSuppliersAccountsGrid"
            onSelect={this.handleSelectedAccounts}
            toolbar={this.buildToolbar('users')}
            fieldForSelection="AccountId"
          >
            <GridColumn
              field="selected"
              width="50px"
              filterable={false}
              sortable={false}
              showAllSelected={false}
              columnMenu={false}
            />
            <GridColumn field="AccountId" title="page.serviceSupplier.col-accountId" width="100" filter="numeric" />
            <GridColumn field="Email" title="page.serviceSupplier.col-email" width="200" />
            <GridColumn
              field="TerminationDate"
              title="page.serviceSupplier.col-terminationDate"
              width="160"
              cell={({ dataItem: { TerminationDate } }) => (
                <td style={{ textAlign: 'center' }}>{getTransformDateString(TerminationDate)}</td>
              )}
            />
            <GridColumn field="Phone" title="page.serviceSupplier.col-mobPhone" width="160" />
            <GridColumn
              field="DatePost"
              title="page.serviceSupplier.col-accountDate"
              width="160"
              cell={({ dataItem: { DatePost } }) => (
                <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
              )}
            />
          </Grid>
        </Card>

        <Card type="inner" title={translate('page.serviceSupplier.relatedSuppliers')}>
          <Grid
            data={relatedSuppliers}
            onRefresh={this.fetchRelatedSuppliers}
            onSelect={(selectedRelatedSuppliers) => {
              this.setState({ selectedRelatedSuppliers });
            }}
            name="ServiceRelatedSuppliersGrid"
            toolbar={this.buildToolbar('suppliers')}
            fieldForSelection="SupplierId"
          >
            <GridColumn
              field="selected"
              width="50px"
              filterable={false}
              sortable={false}
              showAllSelected={false}
              columnMenu={false}
            />
            <GridColumn field="AccountId" title="page.serviceSupplier.col-accountId" width="100" filter="numeric" />
            <GridColumn field="Email" title="page.serviceSupplier.col-email" width="200" />
            <GridColumn field="Phone" title="page.serviceSupplier.col-mobPhone" width="160" />
            <GridColumn field="SupplierId" title="page.serviceSupplier.col-number" width="160" filter="numeric" />
            <GridColumn
              field="SupplierName"
              title="page.serviceSupplier.col-supplierName"
              width="240"
              filter="numeric"
            />
            <GridColumn
              field="DatePost"
              title="page.serviceSupplier.col-accountDate"
              width="160"
              cell={({ dataItem: { DatePost } }) => (
                <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
              )}
            />
          </Grid>
        </Card>

        {(modalVariant === 'addUser' || modalVariant === 'editUser') && (
          <FormStateContainer
            initialValues={
              modalVariant === 'editUser'
                ? {
                    accountId: selectedAccounts[0].AccountId,
                    email: selectedAccounts[0].Email,
                    phone: selectedAccounts[0].Phone,
                    passwordText: null,
                    supplier: `${dataItem.supplierId}-${dataItem.name}`,
                  }
                : { ...INITIAL_VALUES, supplier: `${dataItem.supplierId}-${dataItem.name}` }
            }
          >
            <UpdateSupplierUserModal
              onRefresh={this.fetchUsers}
              closeModal={() => {
                this.setState({ modalVariant: '' });
              }}
              supplierId={dataItem.supplierId}
              variant={modalVariant}
            />
          </FormStateContainer>
        )}

        {modalVariant === 'addSupplies' && (
          <AddSupplierModal
            visible={modalVariant === 'addSupplies'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedAccount={selectedAccounts[0].AccountId}
            onRefresh={this.fetchRelatedSuppliers}
          />
        )}

        {(modalVariant === 'deleteAccount' || modalVariant === 'deleteSupplier') && (
          <DeleteModal
            visible={modalVariant === 'deleteAccount' || modalVariant === 'deleteSupplier'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={modalVariant === 'deleteAccount' ? this.onAccountDelete : this.onSupplierDelete}
            deleteContent={translate(
              `page.serviceSupplier.${
                modalVariant === 'deleteAccount' ? 'areYouSureDeleteAccount' : 'areYouSureDeleteRelatedSupplier'
              }`,
            )}
          />
        )}

        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(SuppliersUsers);
