import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Input, InputNumber, Select, Checkbox } from 'antd';
import { withTranslate } from '../../../../../contexts/localContext';
import '../../../profiles/modalProfile.scss';
import '../../remoteSettings/cellValue/modalCellValue.scss';
import LoadingPanel from '../../../../../components/loader';

const { TextArea } = Input;

const { Option } = Select;

const mockData1 = [
  {
    Property: 'skin1',
    Type: 'tb_terminals_skins[key_name](key_key)',
    Description: 'Скин1',
    Value: 'blue1',
  },
  {
    Property: 'allow. paymentmasterpassqr1',
    Type: 'bool',
    Description: 'Возможность оплаты MasterpassQR1',
    Value: false,
  },
  {
    Property: 'bill.denomination1',
    Type: 'string',
    Description: 'Доступні номінали купюр1',
    Value: '1,2,5,10,20,50,100,200,5001',
  },
  {
    Property: 'receipt_header1',
    Type: 'memo',
    Description: 'Верхний колонтитул1',
    Value: 'some text...1',
  },
  {
    Property: 'Big.Button1',
    Type: 'int',
    Description: 'Большая кнопка на терминале1',
    Value: 0,
  },
];

const stringToBoolean = (string) => {
  if (typeof string === 'boolean') return string;
  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

class ModalChooseSettings extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    noSelected: false,
    // isSkinSelect: Property && Property === 'skin',
    selectData: [
      {
        key: 'blue',
        name: 'Синий',
      },
      {
        key: 'classicDark',
        name: 'КлассическийТёмный',
      },
      {
        key: 'classicLight',
        name: 'КлассическийСероБуроМалиновый',
      },
    ],
    selectedProp: {
      Property: null,
      Type: null,
    },
    propValue: null,
  };

  componentDidMount() {
    const { Property, Type, Value } = mockData1[0];
    this.setState({
      selectedProp: {
        Property,
        Type,
      },
      propValue: Value,
    });
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  onChangeCheckBox = (e) => {
    this.setState({ propValue: e.target.checked });
  };

  onChangeInput = (e) => {
    this.setState({ propValue: e.target.value });
  };

  onChangeInputNumberSelect = (propValue) => {
    this.setState({ propValue });
  };

  onChangeSelectPropName = (Property) => {
    const elem = mockData1.find((el) => el.Property === Property);
    this.setState({
      selectedProp: {
        Property,
        Type: elem.Type,
      },
    });
  };

  renderSelectPropName = () => {
    const {
      selectedProp: { Property },
    } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={Property}
        onChange={this.onChangeSelectPropName}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {mockData1.map(({ Property }) => (
          <Option value={Property} key={`modalchooseSets-number-${Property}`}>
            {Property}
          </Option>
        ))}
      </Select>
    );
  };

  renderSelect = () => {
    const { propValue, selectData } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={propValue}
        onChange={this.onChangeInputNumberSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {selectData.map(({ key, name }) => (
          <Option value={key} key={`cellValue-number-${key}-${name}`}>
            {name}
          </Option>
        ))}
      </Select>
    );
  };

  renderBlock = () => {
    const {
      selectedProp: { Property, Type },
      propValue,
    } = this.state;
    if (Property === 'skin' || Property === 'skin1') {
      // return <DropdownSkin openModal={openModal} />;
      return this.renderSelect();
    }
    switch (Type) {
      case 'bool':
        return <Checkbox checked={stringToBoolean(propValue)} onChange={this.onChangeCheckBox} />;
      case 'string':
        return <Input value={propValue} onChange={this.onChangeInput} />;
      case 'memo':
        return <TextArea rows={4} value={propValue} onChange={this.onChangeInput} />;
      case 'int':
        return <InputNumber min={0} value={propValue} onChange={this.onChangeInputNumberSelect} />;
      default:
        return <p />;
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  clickHandler = () => {
    const {
      selectedProp: { Property },
      propValue,
    } = this.state;
    const { closeModal, onChangeData } = this.props;
    const elem = mockData1.find((el) => el.Property === Property);
    const newElem = { ...elem, Value: propValue };
    onChangeData(newElem);
    closeModal();
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    const { loading, isLoading } = this.state;
    return (
      <>
        <Modal
          title={translate('page.addRemoteSettings.choose-setting')}
          visible={visible}
          centered
          // onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              // disabled={!item}
              loading={loading}
              onClick={this.clickHandler}
            >
              {translate('page.screenControlAddSets.btn-choose')}
            </Button>,
          ]}
        >
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-name')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderSelectPropName()}
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-value')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderBlock()}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChooseSettings);
