const FiscalizedTransactionsOwnersDaysReport = (translate, value) => {
  switch (value) {
    case 'fiscalizedTransactionsOwnersDaysReport-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    default:
      return value;
  }
};

export default FiscalizedTransactionsOwnersDaysReport;
