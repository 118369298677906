export const BASE_FIELDS = [
  'ownerName',
  'transactionId',
  'requestId',
  'terminalId',
  'serviceId',
  'serviceName',
  'amountCashbackClient',
  'amountCashbackDealer',
  'amountTotal',
  'amountOriginal',
  'commissionProvider',
  'commissionClient',
  'dateAccepted',
  'account',
  'comment',
  'paymentType',
];
