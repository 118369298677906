import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';

class OTPHistoryService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.servicesOtpHistory) {
      return { terminalListActivity: [], hasNextPage: false, hasPreviousPage: false };
    }
    const servicesOtpHistory = data.data.servicesOtpHistory;
    const pageInfo = servicesOtpHistory.pageInfo;

    const resultServicesOtpHistory =
      servicesOtpHistory.items && servicesOtpHistory.items.length ? servicesOtpHistory.items : [];

    return {
      history: resultServicesOtpHistory,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };

  getMinimumSelectedDate = () =>
    StandardizeTimeForDate(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()), true);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    if (minimumSelectedDate - startDate > 0 || minimumSelectedDate - endDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new OTPHistoryService();
