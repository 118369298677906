import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import FormStateContainer from '../../../components/FormStateContainer';
import { withTranslate } from '../../../contexts/localContext';
import findArray from '../../../utils/findArrForCurrentPage';
import { ApiService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import Grid from '../../../components/grids/baseGrid';

import './TechnicalPartnersGrid.styled.scss';
import { INITIAL_VALUES } from './constants';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import RemoteTerminalSettingsAddDialog from './RemoteTerminalSettingsAddDialog';

const api = new ApiService();

class RemoteTerminalSettingsGrid extends PureComponent {
  currentArray = findArray('remoteTerminalSettings');

  state = {
    isLoading: false,
    terminalSettingsExtendedKeysList: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchTerminalSettingsExtendedKeysList();
  }

  buildToolbar = () => {
    const { translate } = this.props;

    return (
      <Col className="TechnicalPartnersGrid-toolbar">
        {checkElement('remoteTerminalSettings-terminalSettingsExtendedKeyAdd', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'add' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  fetchTerminalSettingsExtendedKeysList = async () => {
    try {
      await this.setState({ isLoading: true });
      const terminalSettingsExtendedKeysList = await api.getTerminalSettingsExtendedKeysList();
      this.setState({ terminalSettingsExtendedKeysList, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, terminalSettingsExtendedKeysList, modalVariant } = this.state;

    if (!checkElement('remoteTerminalSettings-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={terminalSettingsExtendedKeysList}
          isLoading={isLoading}
          onRefresh={this.fetchTerminalSettingsExtendedKeysList}
          name="RemoteTerminalSettings"
          toolbar={this.buildToolbar()}
        >
          <GridColumn field="Key" title="page.remoteTerminalSettings.col-key" width="300" />
          <GridColumn field="Name" title="page.remoteTerminalSettings.col-name" width="260" />
          <GridColumn field="Type" title="page.remoteTerminalSettings.col-type" width="240" />
          <GridColumn field="DefaultValue" title="page.remoteTerminalSettings.col-defaultValue" width="170" />
        </Grid>

        {modalVariant === 'add' && (
          <FormStateContainer initialValues={INITIAL_VALUES}>
            <RemoteTerminalSettingsAddDialog
              variant={modalVariant}
              closeModal={() => {
                this.setState({ modalVariant: '' });
              }}
              onRefresh={this.fetchTerminalSettingsExtendedKeysList}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(RemoteTerminalSettingsGrid);
