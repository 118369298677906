import React, { PureComponent } from 'react';

import { INITIAL_VALUES } from './initialValues';
import ServiceMainPropsContainer from './ServiceMainPropsContainer';
import FormStateContainer from '../../../../components/FormStateContainer/FormStateContainer';

class ServiceMainPropsFormHOC extends PureComponent {
  render() {
    const { dataItem, onSave, isStepServiceCreation } = this.props;
    return (
      <FormStateContainer initialValues={INITIAL_VALUES}>
        <ServiceMainPropsContainer dataItem={dataItem} onSave={onSave} isStepServiceCreation={isStepServiceCreation} />
      </FormStateContainer>
    );
  }
}

export default ServiceMainPropsFormHOC;
