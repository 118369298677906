import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridAdminStockPhotos from './gridAdminStockPhotos';
import PageTabs from '../../components/tabber/tabber';

class PageAdminStockPhotos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.adminStockPhotos.tab-main',
          content: <GridAdminStockPhotos />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageAdminStockPhotos);
