import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import Grid from '../../../components/grids/baseGrid';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import TreatiesUpdateModal from './TreatiesUpdateModal';

import '../ServiceSuppliersGrid/ServiceSuppliersGrid.styled.scss';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const api = new ApiService();

class TreatiesGrid extends PureComponent {
  state = {
    isLoading: false,
    treatiesList: [],
    modalVariant: '',
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchSuppliersTreaties();
  }

  fetchSuppliersTreaties = async () => {
    const {
      dataItem: { supplierId },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const treatiesList = await api.getServiceSuppliersTreaties(supplierId);
      await this.setState({ isLoading: false, treatiesList });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="ServiceSuppliersGrid-toolbar">
        <div>
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'add' });
            }}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        </div>

        <div>
          <Button
            type="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ modalVariant: 'edit' });
            }}
          >
            {translate('core.buttonTitles.edit')}
          </Button>
        </div>

        <div>
          <Button
            type="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ modalVariant: 'delete' });
            }}
          >
            {translate('core.buttonTitles.delete')}
          </Button>
        </div>
      </Col>
    );
  };

  onDelete = async () => {
    const {
      selectedItems: [{ TreatyId }],
    } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.suppliersTreatiesDelete({
        treatyId: TreatyId,
      });
      this.setState({ modalVariant: '' });
      message.success(translate(`page.serviceSupplier.treatiesSuccessfulDeleted`), 2.5);
      this.fetchSuppliersTreaties();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { treatiesList, isLoading, modalVariant, selectedItems } = this.state;
    const {
      dataItem: { supplierId },
      translate,
    } = this.props;

    return (
      <>
        <Grid
          data={treatiesList}
          isLoading={isLoading}
          onRefresh={this.fetchSuppliersTreaties}
          name="ServiceSuppliersTreatiesGrid"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          toolbar={this.buildToolbar()}
          fieldForSelection="TreatyId"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="TreatyNumber" title="page.serviceSupplier.col-treatiesId" width="120" filter="numeric" />
          <GridColumn
            field="TreatyDate"
            title="page.serviceSupplier.col-dateTreaties"
            width="160"
            cell={({ dataItem: { TreatyDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(TreatyDate, 'DD.MM.YYYY')}</td>
            )}
            columnMenu={false}
          />
          <GridColumn
            field="TerminationDate"
            title="page.serviceSupplier.col-terminationDate"
            width="160"
            cell={({ dataItem: { TerminationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(TerminationDate)}</td>
            )}
            columnMenu={false}
          />
          <GridColumn field="UserNamePost" title="page.serviceSupplier.col-executor" width="120" />
          <GridColumn
            field="DatePost"
            title="page.serviceSupplier.changeDate"
            width="160"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            columnMenu={false}
          />
        </Grid>

        {(modalVariant === 'add' || modalVariant === 'edit') && (
          <TreatiesUpdateModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItem={selectedItems[0]}
            onRefresh={this.fetchSuppliersTreaties}
            supplierId={supplierId}
          />
        )}

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.onDelete}
            deleteContent={translate('page.serviceSupplier.areYouSureDeleteTreaties')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TreatiesGrid);
