import React, { Component } from 'react';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../../components/loader';
import CellWithCheckBox from './CellCheckBoxElements';

import './ElementsMatrixGrid.scss';

class GridElements extends Component {
  state = {
    dataState: {
      take: 40,
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
    },
    skip: 0,
    view: [],
    data: [],
    isLoading: false,
  };

  async componentDidUpdate(prevProps) {
    const { formatElementList } = this.props;
    const { dataState } = this.state;

    try {
      if (prevProps.formatElementList !== formatElementList && formatElementList !== []) {
        await this.onInit();
        this.onDataStateChange({ data: dataState });
      }
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    }
  }

  onDataStateChange = ({ data: dataState }) => {
    const { data } = this.state;
    this.setState({ dataState, view: filterBy(orderBy(data, dataState.sort), dataState.filter) });
  };

  checkFilterActivity = (field, dataState) => {
    const { filter } = dataState;

    if (!filter || !filter.filters.length) {
      return '';
    }

    const isElementActiveByFilters = filter.filters.find((element) => {
      return element.field === field;
    });

    return isElementActiveByFilters || GridColumnMenuFilter.active(field, dataState.filter) ? 'active' : '';
  };

  onInit = () => {
    const { formatElementList } = this.props;

    this.setState({ data: formatElementList.sort((a, b) => a.ElementName.localeCompare(b.ElementName)) });
  };

  onRowClickCallback = (dataItem) => {
    const { onElementsGridRowClick } = this.props;
    const { ElementName } = dataItem;
    this.setState(
      ({ data }) => {
        return {
          data: data.map(({ ElementName: currentElementName, checked, ...otherItemProperties }) => ({
            ...otherItemProperties,
            ElementName: currentElementName,
            checked: currentElementName === ElementName ? !checked : checked,
          })),
        };
      },
      () => {
        const { dataState } = this.state;
        this.onDataStateChange({ data: dataState });
        onElementsGridRowClick(dataItem);
      },
    );
  };

  render() {
    const { dataState, view, isMobile, isLoading } = this.state;
    const { translate } = this.props;

    return (
      <>
        <Grid
          data={view}
          resizable
          pageSize={dataState.take}
          filterable
          sortable
          rowHeight={40}
          filter={dataState.filter}
          sort={dataState.sort}
          total={view.length}
          onRowClick={this.onRowClickCallback}
          onDataStateChange={this.onDataStateChange}
          className={`dropdown-owner city ${isMobile ? 'mobileMode' : ''} ElementsGrid`}
        >
          <GridColumn
            field="translatedElementName"
            title={translate('grids.adminPanel.column-element')}
            width={window.innerWidth > 430 ? 'auto' : 450}
            filter="text"
            headerClassName={this.checkFilterActivity('translatedElementName', dataState)}
            cell={(props) => (
              <CellWithCheckBox
                onRowClickCallback={this.onRowClickCallback}
                onRowCheckboxClickCallback={this.onRowClickCallback}
                {...props}
              />
            )}
          />

          <GridColumn
            field="ElementTypeName"
            title={translate('owner.column-type')}
            width="150"
            filter="text"
            headerClassName={this.checkFilterActivity('translatedElementName', dataState)}
          />
        </Grid>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(GridElements);
