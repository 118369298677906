import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { GridCell } from '@progress/kendo-react-grid';
import './CellCheckBoxElements.scss';

export default class CellWithCheckBoxElements extends GridCell {
  onCheckboxChange = () => {
    const { dataItem, onRowClickCallback, onRowCheckboxClickCallback } = this.props;
    const changeCallback = onRowCheckboxClickCallback || onRowClickCallback;
    changeCallback(dataItem);
  };

  onRowChange = () => {
    const { dataItem, onRowClickCallback } = this.props;

    onRowClickCallback(dataItem);
  };

  static propTypes = {
    onRowClickCallback: PropTypes.func.isRequired,
  };

  render() {
    const { field, dataItem, className, style } = this.props;
    const value = dataItem[field];
    const { checked } = dataItem;
    return (
      <td className={`${className} lg-p-0 CellCheckBoxElements`} style={{ ...style }}>
        <Checkbox checked={checked} onChange={this.onCheckboxChange} />
        <span className="CellCheckBoxElements-value" onClick={this.onRowChange}>
          {value}
        </span>
      </td>
    );
  }
}
