import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class ModalDeleteAgent extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    item: PropTypes.objectOf(PropTypes.any),
    updateGrid: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  state = {
    isLoading: false,
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  deleteAgent = async () => {
    const { showError, item, toggleModal, updateGrid } = this.props;
    try {
      this.toggleLoader();
      const response = await api.dictionaryAgentDelete(item);
      toggleModal();
      updateGrid();
      return response;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const { translate, visible, toggleModal } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal
        title={translate('page.profile.label-confirm')}
        visible={visible}
        centered
        onOk={toggleModal}
        onCancel={toggleModal}
        // className='modal-add-agent'
        zIndex={'1053'}
        footer={[
          <Button key="back" onClick={toggleModal}>
            {translate('owner.cancel')}
          </Button>,
          <Button
            // style={{ width: '100%' }}
            key="submit"
            type="primary"
            onClick={this.deleteAgent}
          >
            {translate('grids.profilesMainProfileList.modal-delete-profile-btn-submit')}
          </Button>,
        ]}
      >
        <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <p style={{ marginBottom: 0 }}>{translate('grids.dictionaryAgentsGet.modal-delete-mediator')}</p>
          </Col>
        </Row>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ModalDeleteAgent);
