import React, { PureComponent } from 'react';
import { Button, Card, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../../components/Input/Input';
import LoadingPanel from '../../../components/loader';

import { ApiService } from '../../../services';

import { CONFIGS, translateSlugs, translateSlugsSelect } from './constants';
import AddPaymentService from './AddPayment.service';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import '../ExternalOutgoingPaymentsUsers.styled.scss';

const api = new ApiService();

class AddPayment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      aNameList: [],
      aAccountIdList: [],
      bMfoList: [],
      isCanEdit: !props.isEdit,
    };
  }

  componentDidMount() {
    this.initializeCallback();
  }

  initializeCallback = async () => {
    await this.setState({ isLoading: true });
    const { formState } = this.props;
    const { aName } = formState;
    await this.fetchCustomersTypes();
    await this.fetchMFO();
    if (!!aName[0]) {
      await this.fetchCustomers(aName[0]);
    }

    await this.setState({ isLoading: false });
  };

  fetchCustomersTypes = async () => {
    try {
      const customersTypes = await api.getPaymentCustomers();

      this.setState({ aNameList: AddPaymentService.normalizeSelectData(customersTypes, 'CustomerId', 'CustomerName') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchMFO = async () => {
    try {
      const mfoData = await api.getPaymentMfoInfo();

      this.setState({ bMfoList: AddPaymentService.normalizeSelectData(mfoData, 'Id', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchCustomers = async (customerID) => {
    try {
      this.setState({ isLoading: true });

      const customers = await api.getPaymentAccounts(customerID);
      this.setState({
        aAccountIdList: AddPaymentService.normalizeSelectDataAccountId(
          customers,
          'AccountId',
          'AccounName',
          'BankAccount',
          'BankMfo',
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    const { onChangeFormState } = this.props;

    if (fieldName === 'bMfo' && selectItems.length !== 0) onChangeFormState('iban', '');

    if (fieldName === 'aName' && selectItems.length !== 0) this.fetchCustomers(selectItems[0]);

    onChangeFormState(fieldName, selectItems);
  };

  renderInput = ({ name, isShortLabel, disabled = false, type }) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;
    const { isCanEdit } = this.state;

    return (
      <div className="AddPaymentByBigTable-inputRow">
        {translateSlugs[name] && (
          <div className={`${isShortLabel ? 'AddPaymentByBigTable-shortLabel' : 'AddPaymentByBigTable-label'}`}>
            {translate(translateSlugs[name])}
          </div>
        )}
        <div className="AddPaymentByBigTable-input">
          <CustomInput
            type={type ? type : name === 'autoNumber' ? 'checkbox' : 'default'}
            formState={formState}
            onChangeFormState={(name, value) => {
              if (name === 'bAccount') {
                onChangeFormState('iban', '');
              }
              onChangeFormState(name, value);
            }}
            formErrors={formErrors}
            setError={onError}
            rows={1}
            disabled={disabled || !isCanEdit}
            disableLineBreaks
            validators={AddPaymentService.getValidators(translate)}
            name={name}
          />
        </div>
      </div>
    );
  };

  renderSelect = ({ name, isDisabled }) => {
    const { formState, translate } = this.props;

    return (
      <div className="AddPaymentByBigTable-inputRow">
        <div className="AddPaymentByBigTable-label">{translate(translateSlugs[name])}</div>
        <div className="AddPaymentByBigTable-input grid-input">
          <GridDropdown
            data={this.state[`${name}List`]}
            colConfig={CONFIGS[name]}
            selectItems={formState[name]}
            onSave={this.getSelectChangeCallback(name)}
            defaultTitle={translate(translateSlugsSelect[name])}
            disabled={isDisabled || !this.state.isCanEdit}
            isSingle
          />
        </div>
      </div>
    );
  };

  handleCheckIban = async () => {
    const {
      formState: { bMfo, bAccount },
    } = this.props;
    try {
      this.setState({ isLoading: true });
      const iban = await api.getIban({
        account: bAccount,
        mfo: bMfo[0],
      });
      if (!iban) return;

      const { onChangeFormState } = this.props;

      onChangeFormState('iban', iban);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleAddPayment = async () => {
    const {
      formState: {
        amount,
        aAccountId,
        bMfo,
        paymentId,
        bAccount,
        bName,
        bIdClient,
        number,
        narrative,
        autoNumber,
        comments,
        extendedData,
        payerId,
        payerName,
        budgetCode,
        supplierId,
        signature,
      },
      translate,
      isEdit,
    } = this.props;
    const currentDate = new Date();
    const isoString = currentDate.toISOString();

    function convertStringToNumber(input) {
      if (!input) return input;
      if (Number.isInteger(input)) {
        return input;
      }
      // Заменяем запятую на точку
      const stringInput = `${input}`;
      const formattedString = stringInput.replace(',', '.');
      // Преобразуем строку в число
      const number = parseFloat(formattedString);
      return number;
    }

    const requestData = {
      paymentId: paymentId,
      amount: convertStringToNumber(amount),
      aAccountId: aAccountId[0],
      bMfo: bMfo[0],
      bAccount: bAccount,
      bName: bName.trim(),
      bIdClient: `${bIdClient}`,
      number: number,
      narrative: narrative.trim(),
      datePost: isoString,
      approve: false,
      signature: signature,
      autoNumber: autoNumber,
      supplierId: supplierId,
      comments: comments.trim(),
      typeId: 0,
      extendedData: extendedData,
      payerId: payerId,
      payerName: payerName ? payerName.trim() : null,
      budgetCode: budgetCode ? budgetCode.trim() : null,
    };

    this.setState({ isLoading: true });

    try {
      const data = !isEdit
        ? await api.postPaymentsExternalInserted(requestData)
        : await api.postPaymentsExternalUpdated(requestData);
      isEdit
        ? await message.success(`${translate('page.externalOutgoingPayments.message-savePayment')}`, 2.5)
        : await message.success(`${translate('page.externalOutgoingPayments.message-copyPayment')} ${data}`, 2.5);
      window.location.reload();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, isCanEdit } = this.state;
    const { translate, formState, formErrors, isEdit } = this.props;
    const { aName, autoNumber, bMfo, bAccount, StatusId } = formState;
    const disableAccountId = aName.length === 0;
    const disableNumber = isEdit || autoNumber;
    const EnableToEditStatusId = 1;

    const disableIbanButton = bMfo.length === 0 || bAccount === 0;

    const validInputs = ['amount', 'aAccountId', 'bMfo', 'bAccount', 'comments', 'narrative', 'aName'];
    const isErrorExist = Object.values(formErrors).some((errorMessage) => !!errorMessage);

    const checkValue = (value) => {
      if (!value) return true;
      if (`${value}`.trim().length === 0) return true;
      return false;
    };

    const isValueEmpty = Object.entries(formState).some(([key, value]) => {
      if (validInputs.includes(key)) {
        if (Array.isArray(value)) {
          return value.length === 0;
        }

        return checkValue(value);
      }
    });

    return (
      <div className="AddPaymentByBigTable">
        <div className="AddPaymentByBigTable-ButtonWrapper">
          {isCanEdit ? (
            <Button type="primary" disabled={isErrorExist || isValueEmpty} onClick={this.handleAddPayment}>
              {translate('core.buttonTitles.save')}
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={StatusId !== EnableToEditStatusId}
              onClick={() => this.setState({ isCanEdit: true })}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          )}
        </div>

        <div className="AddPaymentWrapper">
          <div className="flex-col">
            <Card
              type="inner"
              className="AddPaymentByBigTable-card"
              title={translate('page.externalOutgoingPayments.card-title-sender')}
            >
              {this.renderSelect({ name: 'aName' })}
              {this.renderSelect({ name: 'aAccountId', isDisabled: disableAccountId })}
            </Card>
            <Card
              type="inner"
              className="AddPaymentByBigTable-card"
              title={translate('page.externalOutgoingPayments.card-title-recipient')}
            >
              {this.renderSelect({ name: 'bMfo', type: 'number' })}
              {this.renderInput({ name: 'bAccount', type: 'number' })}
              <div className="AddPaymentContainerFlex">
                {this.renderInput({ name: 'iban', disabled: true })}
                <Button
                  type="primary"
                  className="iban-button"
                  disabled={disableIbanButton}
                  onClick={this.handleCheckIban}
                >
                  {translate('page.externalOutgoingPayments.button-check-iban')}
                </Button>
              </div>

              {this.renderInput({ name: 'bIdClient', type: 'number' })}
              {this.renderInput({ name: 'bName', type: 'textArea' })}
            </Card>
          </div>
          <div className="flex-col">
            <Card
              type="inner"
              className="AddPaymentByBigTable-card"
              title={translate('page.externalOutgoingPayments.card-title-information')}
            >
              <div className="AddPaymentContainerFlex">
                {this.renderInput({ name: 'number', disabled: disableNumber })}
                {!this.props.isEdit && this.renderInput({ name: 'autoNumber' })}
              </div>
              {this.renderInput({ name: 'amount' })}
              {this.renderInput({ name: 'narrative', type: 'textArea' })}
              {this.renderInput({ name: 'extendedData', type: 'textArea' })}
              {this.renderInput({ name: 'comments', type: 'textArea' })}
            </Card>
            <Card
              type="inner"
              className="AddPaymentByBigTable-card break-spaces"
              title={translate('page.externalOutgoingPayments.card-title-information-of-actual-payer')}
            >
              {this.renderInput({ name: 'payerId', type: 'number' })}
              {this.renderInput({ name: 'payerName', type: 'textArea' })}
              {this.renderInput({ name: 'budgetCode' })}
            </Card>
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(AddPayment);
