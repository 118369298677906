import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';

class MenuAuditService {
  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const standardizeStartDate = StandardizeTimeForDate(startDate, true);
    const standardizeEndDate = StandardizeTimeForDate(startDate);

    if (standardizeStartDate - standardizeEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    if (
      new Date(2007, 0) - standardizeStartDate > 0 ||
      new Date(2007, 0) - standardizeEndDate > 0 ||
      standardizeStartDate - new Date() > 0 ||
      standardizeEndDate - new Date() > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new MenuAuditService();
