const AuthorizationsDictionary = (translate, value) => {
  switch (value) {
    case 'authorizationsDictionary-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'authorizationsDictionary-saveEditRow':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.save`)}`;
    case 'authorizationsDictionary-addEditRow':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.add`)}`;
    default:
      return value;
  }
};

export default AuthorizationsDictionary;
