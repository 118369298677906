import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Input } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import '../../../components/TerminalsCommissionsGrid/ModalChangeProfile/modalChangeProfile.scss';
import './modalCellComment.scss';

const api = new ApiService();

const { TextArea } = Input;

class ModalCellComment extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    item: PropTypes.objectOf(PropTypes.any),
    toggleModal: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    updateGrid: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: false,
      value: props.value,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  onChangeInput = (e) => {
    this.setState({ value: e.target.value });
  };

  getCurrentApiCall = (name) => {
    const { value } = this.state;
    const { item } = this.props;
    const model = {
      TransactionId: item.TransactionId,
      Comment: value,
    };
    let apiMethod;
    switch (name) {
      case 'reportUsersInStopListGet':
        apiMethod = api.reportUsersInStopListCommentAdd(model);
        break;
      case 'reportOverlimitTransactionsGet':
        apiMethod = api.reportOverlimitTransactionsCommentAdd(model);
        break;
      default:
        return;
    }
    return apiMethod;
  };

  onSaveProp = async () => {
    const { showError, updateGrid, name } = this.props;
    try {
      this.toggleLoader();
      await this.getCurrentApiCall(name);
      updateGrid();
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const { translate, visible, toggleModal } = this.props;
    const { loading, isLoading, isDisabled, value } = this.state;
    return (
      <>
        <Modal
          title={translate('grids.transactionDeatails.column-name')}
          visible={visible}
          centered
          // onOk={this.onSaveProp}
          onCancel={toggleModal}
          className="modal-system-encashment addSets-modal-cellVal"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.onSaveProp} disabled={isDisabled}>
              {translate('page.screenControlBusinessServices.button-save')}
            </Button>,
          ]}
        >
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-value')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <TextArea rows={4} value={value} onChange={this.onChangeInput} />
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCellComment);
