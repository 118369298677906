import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../../contexts/localContext';
import { Button, Col, Modal, Row, Input as NativeInput, message } from 'antd';
import GridDropdown from '../../../../../components/GridDropdown/GridDropdown';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../../../../core/constants/configs.const';
import DropdownNormalizersService from '../../../../../core/normalizers/dropdownNormalizers.service';
import Validators from '../validators';
import { ApiService } from '../../../../../services';
import Input from '../../../../../components/Input';

import './ModalAdditionalLimits.scss';
import LoadingPanel from '../../../../../components/loader';

const api = new ApiService();
class ModalAdditionalLimits extends PureComponent {
  state = {
    paymentTypes: [],
    selectedPaymentTypes: [],
    isLoading: false,
  };

  onPaymentTypesSave = (selectedPaymentTypes) => {
    this.setState({ selectedPaymentTypes });
  };

  checkGlobal = () => {
    const { selectedPaymentTypes } = this.state;
    const { gridData, formState, translate } = this.props;

    if (gridData.find(({ Code }) => Code === selectedPaymentTypes[0])) {
      return translate('page.additionalLimits.error-duplicate');
    }

    return Validators.validationIsBiggest(translate)(formState);
  };

  fetchPaymentTypes = async () => {
    try {
      const data = await api.getPaymentTypeList();
      await this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.setState({ isLoading: false });
    }
  };

  additionalServiceLimitMerge = async () => {
    const {
      serviceId,
      formState: { AmountMin, AmountMax },
      closeModal,
      update,
      translate,
      onError,
    } = this.props;
    const { selectedPaymentTypes } = this.state;

    const globalError = this.checkGlobal();

    if (globalError) {
      onError('global', globalError);
      return;
    }

    try {
      const model = {
        serviceId,
        code: selectedPaymentTypes[0],
        amountMin: Validators.getParsedValue(AmountMin),
        amountMax: Validators.getParsedValue(AmountMax),
      };
      this.setState({ isLoading: true });
      await api.additionalServiceLimitMerge(model);
      this.setState({ isLoading: false });
      closeModal();
      message.success(`${translate('page.additionalLimits.successfulAdd')}`, 2);
      await update();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  additionalServiceLimitChange = async () => {
    const {
      serviceId,
      formState: { AmountMin, AmountMax },
      selectedItem,
      update,
      closeModal,
      translate,
      onError,
    } = this.props;
    const { Code } = selectedItem;

    const globalError = this.checkGlobal();

    if (globalError) {
      onError('global', globalError);
      return;
    }

    try {
      const model = {
        serviceId,
        code: Code,
        amountMin: Validators.getParsedValue(AmountMin),
        amountMax: Validators.getParsedValue(AmountMax),
      };

      this.setState({ isLoading: true });
      await api.additionalServiceLimitMerge(model);
      this.setState({ isLoading: false });
      closeModal();
      message.success(`${translate('page.additionalLimits.successfulChange')}`, 2);
      await update();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  renderButton = (typeModal) => {
    const { selectedPaymentTypes } = this.state;
    const { translate, formErrors, formState } = this.props;
    const isFormErrors = !!Object.values(formErrors).filter((item) => item).length;
    const isDataEmpty =
      (typeModal === 'addLimits' && !selectedPaymentTypes.length) || Object.values(formState).some((value) => !value);

    switch (typeModal) {
      case 'addLimits':
        return (
          <Button
            key={typeModal}
            type="primary"
            disabled={isDataEmpty || isFormErrors}
            onClick={this.additionalServiceLimitMerge}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        );

      case 'changeLimits':
        return (
          <Button
            key={typeModal}
            type="primary"
            disabled={isDataEmpty || isFormErrors}
            onClick={this.additionalServiceLimitChange}
          >
            {translate('core.buttonTitles.change')}
          </Button>
        );
    }
  };

  getModalTitle = () => {
    const { translate, typeModal } = this.props;

    return translate(`page.additionalLimits.modalTitle-${typeModal}`);
  };

  renderModalContent = () => {
    const { translate, typeModal, selectedItem, formState, onChangeFormState, formErrors, onError } = this.props;
    const { paymentTypes, selectedPaymentTypes } = this.state;

    return (
      <>
        <Row className="ModalServiceAdditionalLimits-row">
          {typeModal === 'addLimits' ? (
            <>
              <Col span={6} className="ModalServiceAdditionalLimits-label">
                {translate('page.commissionsServices.paymentType')}
              </Col>
              <Col span={18} className="ModalServiceAdditionalLimits-gridDropdown">
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  selectItems={selectedPaymentTypes}
                  onSave={this.onPaymentTypesSave}
                  isSingle
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                />
              </Col>
            </>
          ) : typeModal === 'changeLimits' ? (
            <>
              <Col span={6} className="ModalServiceAdditionalLimits-label">
                {translate('page.commissionsServices.paymentType')}
              </Col>
              <Col span={18}>
                <NativeInput
                  name="PaymentType"
                  disabled
                  value={selectedItem.Name}
                  className="ModalServiceAdditionalLimits-input"
                />
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
        <Row type="flex" align="middle" className="ModalServiceAdditionalLimits-row">
          <Col span={6}>
            <label>{translate('grids.additionalLimits.column-min')}</label>
          </Col>
          <Col span={18}>
            <Input
              name="AmountMin"
              type="text"
              formState={formState}
              onChangeFormState={onChangeFormState}
              formErrors={formErrors}
              setError={onError}
              validators={Validators.getObjectValidation(translate)}
              className="AdditionalLimits-input"
            />
          </Col>
        </Row>
        <Row type="flex" align="middle" className="ModalServiceAdditionalLimits-row">
          <Col span={6}>
            <label>{translate('grids.additionalLimits.column-max')}</label>
          </Col>
          <Col span={18}>
            <Input
              name="AmountMax"
              type="text"
              formState={formState}
              onChangeFormState={onChangeFormState}
              formErrors={formErrors}
              setError={onError}
              validators={Validators.getObjectValidation(translate)}
              className="AdditionalLimits-input"
            />
          </Col>
        </Row>

        {formErrors.global && <div className="ModalServiceAdditionalLimits-globalError">{formErrors.global}</div>}
      </>
    );
  };

  componentDidMount() {
    this.fetchPaymentTypes();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedPaymentTypes } = this.state;
    const { selectedPaymentTypes: prevSelectedPaymentTypes } = prevState;
    const { formState, onError } = this.props;
    const { formState: prevFormState } = prevProps;

    const isDataChanged =
      !prevFormState || formState !== prevFormState || selectedPaymentTypes !== prevSelectedPaymentTypes;

    if (isDataChanged) {
      onError('global', '');
    }
  };

  render() {
    const { isLoading } = this.state;
    const { translate, closeModal, typeModal } = this.props;
    return (
      <>
        <Modal
          visible={typeModal}
          type="inner"
          title={this.getModalTitle()}
          onCancel={closeModal}
          className="ModalServiceAdditionalLimits"
          footer={[
            this.renderButton(typeModal),
            <Button key="back" onClick={() => closeModal(false)}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
          ]}
        >
          <Col>{this.renderModalContent()}</Col>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(ModalAdditionalLimits);
