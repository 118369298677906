import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Input, Row, Button } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import fishkaServiceHistoryGridService from './FishkaServiceHistoryGrid.service';
import { withTranslate } from '../../../contexts/localContext';

import './FishkaServiceHistoryGrid.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class FishkaServiceHistoryGrid extends PureComponent {
  state = {
    isLoading: false,
    fishkaHistoryList: [],
    errorMessage: '',
    dateStart: new Date(),
    dateEnd: new Date(),
  };

  currentArray = findArray('fishkaService');

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: fishkaServiceHistoryGridService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { dateStart, dateEnd, errorMessage } = this.state;

    return (
      <Row className="FishkaServiceHistoryGrid-toolbar">
        <Col span={24} sm={12} md={8} className="FishkaServiceHistoryGrid-dateRow">
          <Col span={6}>{translate('page.changingProfileHistory.dateFrom')}</Col>
          <Col span={18}>
            <DatePicker
              format="yyyy.MM.dd"
              className="FishkaServiceHistoryGrid-datePicker"
              onChange={this.getDateChangeCallback('dateStart')}
              value={dateStart}
              min={fishkaServiceHistoryGridService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>
        <Col span={24} sm={12} md={8} className="FishkaServiceHistoryGrid-dateRow">
          <Col span={6}>{translate('page.changingProfileHistory.dateTo')}</Col>
          <Col span={18}>
            <DatePicker
              format="yyyy.MM.dd"
              className="FishkaServiceHistoryGrid-datePicker"
              onChange={this.getDateChangeCallback('dateEnd')}
              value={dateEnd}
              min={fishkaServiceHistoryGridService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>
        <Col span={24} md={8} className="FishkaServiceHistoryGrid-ActionRow">
          {checkElement('fishkaService-historyTabBtnSearch', this.currentArray) && (
            <Button type="primary" onClick={this.getHistory} disabled={!!errorMessage}>
              {translate('page.changingProfileHistory.btn-view')}
            </Button>
          )}
        </Col>
        <Col span={24}>{errorMessage && <div className="FishkaServiceHistoryGrid-error">{errorMessage}</div>}</Col>
      </Row>
    );
  };

  getHistory = async () => {
    const { dateStart, dateEnd } = this.state;

    try {
      await this.setState({ isLoading: true });
      const formatedDateStart = new Date(
        new Date(dateStart).getFullYear(),
        new Date(dateStart).getMonth(),
        new Date(dateStart).getDate(),
        0,
        0,
        0,
      );

      const formatedDateEnd = new Date(
        new Date(dateEnd).getFullYear(),
        new Date(dateEnd).getMonth(),
        new Date(dateEnd).getDate(),
        23,
        59,
        59,
        999,
      );
      const fishkaHistoryList = await api.getCashbackFishkaHistory({
        dateStart: getDataByTimezoneOffset(formatedDateStart),
        dateEnd: getDataByTimezoneOffset(formatedDateEnd),
      });

      await this.setState({
        fishkaHistoryList: fishkaHistoryList && fishkaHistoryList.length ? fishkaHistoryList : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.getHistory();
  }

  render() {
    const { fishkaHistoryList, isLoading } = this.state;

    return (
      <Grid
        data={fishkaHistoryList}
        toolbar={this.buildToolbar()}
        onRefresh={this.getHistory}
        name="fishkaHistoryServiceGrid"
        isLoading={isLoading}
      >
        <GridColumn field="Id" title="page.fishkaService.col-recordNumber" width="150" filter="numeric" />
        <GridColumn field="ServiceId" title="page.fishkaService.col-serviceID" width="150" filter="numeric" />
        <GridColumn field="ServiceName" title="page.fishkaService.col-serviceName" width="240" />
        <GridColumn field="ServiceGroup" title="page.fishkaService.col-serviceGroup" width="240" />
        <GridColumn field="Status" title="page.fishkaService.col-serviceStatus" width="180" />
        <GridColumn field="CashbackPercent" title="page.fishkaService.col-cashback" width="150" />
        <GridColumn field="UserNamePost" title="page.fishkaService.col-adder" width="240" />
        <GridColumn
          field="DatePost"
          title="page.fishkaService.col-addDate"
          width="200"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="UserNameDelete" title="page.fishkaService.col-remover" width="200" />
        <GridColumn
          field="DateDelete"
          title="page.fishkaService.col-deleteDate"
          width="200"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(FishkaServiceHistoryGrid);
