import React, { PureComponent } from 'react';
import { Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './CheckCardRouting.styled.scss';

class ProcessDialog extends PureComponent {
  render() {
    const { translate, variant, progress } = this.props;
    return (
      <Modal
        title={translate(`page.routingManagement.${variant === 'check' ? 'checking' : 'saving'}`)}
        visible={variant === 'update' || variant === 'check'}
        footer={[]}
      >
        <div className="CheckCardRoutingProcessDialog-content">
          {translate(`page.routingManagement.processCompletedAt`)} <b>{progress}%</b>
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ProcessDialog);
