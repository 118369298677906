const OTPServices = (translate, value) => {
  switch (value) {
    case 'OTPServices-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'OTPServices-saveEdit':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.save`)}`;
    case 'OTPServices-deleteEdit':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    case 'OTPServices-addEdit':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.add`)}`;
    case 'OTPServices-history':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`page.OTPServices.history`)}`;
    default:
      return value;
  }
};

export default OTPServices;
