import React, { PureComponent } from 'react';
import { Card, Row, Col, Icon, Input, Checkbox } from 'antd';

import CustomInput from '../../Input';
import CustomPicker from '../../Picker';
import { withTranslate } from '../../../contexts/localContext';
import FieldRow from '../FieldRow';
import { MIN_VALID_DATE, MAX_VALID_DATE } from '../initialValues';
import ValidationService from '../services/validation.service';
import CommissionDetailsFormService from '../services/commissionDetailsFormService';

import './CommisionDetailsForm.scss';

class CommissionDetailsForm extends PureComponent {
  renderInput = (name, type, isHandleDisabled = false, isOnlyInteger) => {
    const { translate, isFormDisabled, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <CustomInput
        type={type}
        formState={formState}
        disabled={isFormDisabled || isHandleDisabled}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        validators={ValidationService.getValidators(translate)}
        name={name}
        isOnlyInteger={isOnlyInteger}
      />
    );
  };

  renderPicker = (name, type, isHandleDisabled) => {
    const { isFormDisabled, formState, onChangeFormState } = this.props;

    return (
      <CustomPicker
        name={name}
        type={type}
        min={MIN_VALID_DATE}
        max={MAX_VALID_DATE}
        disabled={isFormDisabled || isHandleDisabled}
        formState={formState}
        onChangeFormState={onChangeFormState}
      />
    );
  };

  renderConditionsFieldsCard = () => {
    const { translate, formState, isFormDisabled } = this.props;
    const commissionName = CommissionDetailsFormService.getCommissionName(formState, isFormDisabled);

    return (
      <Card type="inner" title={translate('grids.comission-sub.comission-conditions')}>
        <Col span={24}>
          <Row>
            <Col span={24} xl={5}>
              {translate('grids.comission-sub.comission-name')}
            </Col>
            <Col span={24} xl={18}>
              <Input className="CommissionDetails-input" value={commissionName} disabled />
            </Col>
          </Row>
        </Col>

        <Col span={24} xl={12}>
          <Col span={24}>
            <FieldRow translateLabelSlag="grids.comission-sub.comission-date-start">
              {this.renderPicker('TimeFrom', 'time')}
            </FieldRow>
          </Col>

          <Col span={24}>
            <FieldRow translateLabelSlag="grids.comission-sub.comission-date-end">
              {this.renderPicker('TimeTo', 'time')}
            </FieldRow>
          </Col>
        </Col>

        <Col span={24} xl={12}>
          <Col span={24}>
            <FieldRow translateLabelSlag="grids.comission-sub.comission-sum-start">
              {this.renderInput('AmountFrom', 'number')}
            </FieldRow>
          </Col>

          <Col span={24}>
            <FieldRow translateLabelSlag="grids.comission-sub.comission-sum-end">
              {this.renderInput('AmountTo', 'number')}
            </FieldRow>
          </Col>
        </Col>
      </Card>
    );
  };

  renderResultFieldsCard = () => {
    const { translate, formState } = this.props;

    return (
      <Card type="inner" title={translate('grids.comission-sub.result-header')}>
        <Col span={24} xl={12} className="CommissionDetails-radioWrapper">
          <Col span={10} className="CommissionDetails-radioLabel">
            {translate('grids.comission-sub.result-fixed')}:
          </Col>

          <Col span={13} offset={1}>
            {this.renderInput('ResultFixed', 'number')}
          </Col>
        </Col>

        <Col span={24} xl={12} className="CommissionDetails-radioWrapper">
          <Col span={10} className="CommissionDetails-radioLabel">
            {translate('grids.comission-sub.result-percent')}:
          </Col>

          <Col span={13} offset={1}>
            {this.renderInput('ResultPercent', 'number')}
          </Col>
        </Col>
      </Card>
    );
  };

  renderRestrictionsFieldsCard = () => {
    const { translate, formState } = this.props;

    return (
      <Card type="inner" title={translate('grids.comission-sub.restrictions-header')}>
        <Col span={24} xl={12}>
          <FieldRow translateLabelSlag="grids.comission-sub.restrictions-min">
            {this.renderInput('CommissionMin', 'number', !formState.ResultPercent)}
          </FieldRow>
        </Col>

        <Col span={24} xl={12}>
          <FieldRow translateLabelSlag="grids.comission-sub.restrictions-max">
            {this.renderInput('CommissionMax', 'number', !formState.ResultPercent)}
          </FieldRow>
        </Col>
      </Card>
    );
  };

  renderValidityPeriodFieldsCard = () => {
    const { translate } = this.props;

    return (
      <Card type="inner" title={translate('grids.comission-sub.validity-period')}>
        <Col span={24} xl={12}>
          <FieldRow translateLabelSlag="grids.comission-sub.validity-period-date-start">
            {this.renderPicker('ValidDateFrom')}
          </FieldRow>
        </Col>

        <Col span={24} xl={12}>
          <FieldRow translateLabelSlag="grids.comission-sub.validity-period-date-end">
            {this.renderPicker('ValidDateTo')}
          </FieldRow>
        </Col>
      </Card>
    );
  };

  renderPriorityFieldsCard = () => {
    const { translate, isFormDisabled, formState, handleCheckboxChange } = this.props;

    return (
      <Card type="inner" title={translate('grids.comission-sub.priority-header')}>
        <Col span={24} xl={12}>
          <Col span={24} className="CommissionDetails-priorityField">
            <Col span={8} className="CommissionDetails-priorityLabel">
              <p>{translate('grids.comission-sub.priority-header')}</p>
            </Col>

            <Col span={8} offset={1}>
              {this.renderInput('Priority', 'number', false, true)}
            </Col>

            <Col span={6} offset={1} className="CommissionDetails-priorityRangeLabel">
              <p className="p-width-auto first">1</p>
              <Icon type="arrow-up" />
              <p className="p-width-auto">...</p>
              <p className="p-width-auto">100</p>
              <Icon type="arrow-down" />
            </Col>
          </Col>

          {formState.UserNamePost && (
            <Col span={24}>
              <FieldRow translateLabelSlag="grids.comission-sub.priority-performer">
                {this.renderInput('UserNamePost', 'string', true)}
              </FieldRow>
            </Col>
          )}
        </Col>

        <Col span={24} xl={12}>
          <Col className="CommissionDetails-activityInfoField" span={24}>
            <Checkbox
              defaultChecked={formState.Active}
              checked={formState.Active}
              disabled={isFormDisabled}
              onChange={handleCheckboxChange}
            />
            <p className="CommissionDetails-activityInfoLabel">{translate('grids.comission-sub.priority-enabled')}</p>
          </Col>

          {formState.DatePost && (
            <Col span={24}>
              <FieldRow translateLabelSlag="grids.comission-sub.priority-date">
                {this.renderPicker('DatePost', 'date', true)}
              </FieldRow>
            </Col>
          )}
        </Col>
      </Card>
    );
  };

  render() {
    return (
      <div>
        <Row gutter={24} className="CommissionDetails-commissionItems">
          <Col span={24} md={12}>
            <Row gutter={[0, 24]} className="CommissionDetails-cardContent">
              <Col span={24}>{this.renderConditionsFieldsCard()}</Col>
              <Col span={24}>{this.renderPriorityFieldsCard()}</Col>
            </Row>
          </Col>

          <Col span={24} md={12}>
            <Row gutter={[0, 24]} className="CommissionDetails-cardContent">
              <Col span={24}>{this.renderResultFieldsCard()}</Col>
              <Col span={24}>{this.renderRestrictionsFieldsCard()}</Col>
              <Col span={24}>{this.renderValidityPeriodFieldsCard()}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslate(CommissionDetailsForm);
