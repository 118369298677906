class BlockedTransactionsPageService {
  getMinimumSelectedDate = () =>
    new Date(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0));

  getMaxSelectedDate = () =>
    new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999));

  geStartValue = (date) =>
    new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 0, 0, 0, 0);

  getEndValue = (date) =>
    new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 23, 59, 59, 999);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();
    const maxSelectedDate = this.getMaxSelectedDate();

    if (
      minimumSelectedDate - startDate > 0 ||
      minimumSelectedDate - endDate > 0 ||
      startDate - maxSelectedDate > 0 ||
      endDate - maxSelectedDate > 0
    ) {
      console.log('1');
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    const YEAR_AGO_BY_END_DATE = new Date(
      new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate()) -
        366 * 24 * 60 * 60 * 1000,
    );

    if (YEAR_AGO_BY_END_DATE - startDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  validateEmail = (value, translate) => {
    if (!value) {
      return translate('page.commissionsServices.dropdownRequiredError');
    }

    const emailValidator =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!value.split(';').every((email) => emailValidator.test(email.trim()))) {
      return translate('page.blockedTransactions.emailError');
    }

    return '';
  };
}

export default new BlockedTransactionsPageService();
