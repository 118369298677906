import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridAccount from './gridAccount';
import GridAccountTurn from './gridAccountTurn';
import GridAccountDetails from './gridAccountDetails';
import GridAccountLimit from './gridAccountLimit';
import PageTabs from '../../components/tabber/tabber';

class PageAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [{ title: 'page.account.tab-accounts', content: <GridAccount />, closable: false }],
    };
  }

  onAddTab = ({ type, dataItem, accInfo }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'accountactivity':
        const { AccountId, OwnerName, OwnerId } = dataItem;
        tabs.push({
          title: 'page.account.tab-activity',
          content: <GridAccountTurn accountId={AccountId} ownerId={OwnerId} ownerName={OwnerName} />,
          closable: true,
          key,
        });
        break;

      case 'accountdetails':
        // const { accountId, ownerName, ownerId } = accInfo;
        tabs.push({
          title: 'page.account.tab-details',
          content: <GridAccountDetails data={dataItem} accInfo={accInfo} />,
          closable: true,
          key,
        });
        break;

      case 'dynamiclimits':
        tabs.push({
          title: 'page.account.tab-accountsLimit',
          content: <GridAccountLimit item={dataItem} />,
          closable: true,
          key,
        });
        break;

      default:
        return null;
    }
    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageAccount);
