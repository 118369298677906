class ValidatorService {
  validateInput = (translate) => (value) => {
    const regexp = new RegExp(/^-?\d*\.?\d*$/);
    if (!regexp.test(value)) {
      return false;
    }
    if (value.includes('.') && value.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }
    if (value < 0) {
      return translate('page.finMonLimits.error-negative-value');
    }
  };
}
export default new ValidatorService();
