import React, { PureComponent } from 'react';
import GroupServicesGrid from './GroupServicesGrid';
import PageTabs from '../../../../components/tabber/tabber';

class GroupServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-groupServices',
          content: <GroupServicesGrid />,
          closable: false,
        },
      ],
    };
  }

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default GroupServices;
