import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import findArray from '../../utils/findArrForCurrentPage';
import { ApiService, StorageService, tabService } from '../../services';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS, BASE_GRID_COLUMNS } from './constants';
import ManageMerchantCallbacksService from './ManageMerchantCallbacks.service';
import Grid from '../../components/grids/baseGrid';
import { Button, Row } from 'antd';
import checkElement from '../../utils/checkElement';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { withTranslate } from '../../contexts/localContext';
import { getTransformDateString } from '../../utils/getTransformDateString';
import DeleteModal from './DeleteModal';
import EditableGridInput from '../../components/grids/EditableGridInput';

import './ManageMerchantCallbacksGrid.styled.scss';

const api = new ApiService();

class ManageMerchantCallbacksGrid extends PureComponent {
  currentArray = findArray('manageMerchantCallbacksGrid');

  state = {
    callbacks: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    currentEditRow: null,
    selectedItems: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchCallbackGQL();

    document.addEventListener('keyup', this.handleOnDisabled);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleOnDisabled);
  }

  handleOnDisabled = (e) => {
    const { keyCode, key } = e;

    if (keyCode === 27 || key === 'Escape') {
      this.setState(({ callbacks }) => ({
        currentEditRow: null,
        callbacks: callbacks.filter(({ editId }) => editId !== '-1'),
      }));
    }
  };

  fetchCallbackGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString } = this.state;

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('manageMerchantCallbacksGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{partnerKey: ASC}'
      }${filtersString ? `,where:{and:[${filtersString}]}` : ''}`;

      const query = `merchantCallbackRules(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { callbacks, hasPreviousPage, hasNextPage } = ManageMerchantCallbacksService.getGQLResponse(data);

      this.setState({ callbacks, hasPreviousPage, hasNextPage, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
        currentEditRow: null,
      });

      await this.fetchCallbackGQL();
    }
  }

  handleAdd = () => {
    const { callbacks } = this.state;
    const emptyItem = {
      type: '',
      partnerKey: null,
      serviceKey: '',
      retryCount: null,
      status: null,
      isActive: true,
      editId: '-1',
    };

    this.setState({
      callbacks: [emptyItem, ...callbacks],
      currentEditRow: emptyItem,
    });
  };

  handleDelete = () => {
    const { selectedItems } = this.state;

    this.setState({
      modalVariant: selectedItems.find(({ editId }) => editId === '-1') ? 'forbiddenDelete' : 'allowDelete',
    });
  };

  updateCallbacks = async () => {
    const { currentEditRow } = this.state;
    const { editId, ...restItems } = currentEditRow;

    const columnsList =
      editId === '-1' ? BASE_GRID_COLUMNS : ALL_GRID_COLUMNS.filter((column) => column !== 'datePost');

    const queryString = Object.entries(restItems)
      .filter(([key, _]) => columnsList.includes(key))
      .reduce(
        (acc, [key, value]) =>
          `${acc}${key}:${key === 'serviceKey' ? `"${value}"` : key === 'isActive' ? value : value || null},`,
        '',
      );

    const query = `${
      editId === '-1' ? 'merchantCallbackRuleEntityAdd' : 'merchantCallbackRuleEntityUpdate'
    }(input: { ruleEntity:{${queryString}}}){merchantCallbackRule { ${columnsList.join()} }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchCallbackGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  handleGridTabOpen = (selectedItems) => {
    tabService.addTab({
      type: 'manageMerchantCallbacksDetail',
      callbackId: selectedItems[0].id,
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow, selectedItems } = this.state;

    return (
      <Row className="ManageMerchantCallbacksGrid-toolbar">
        {checkElement('manageMerchantCallbacksGrid-addEdit', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button type="primary" onClick={this.handleAdd} disabled={currentEditRow || selectedItems.length}>
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('manageMerchantCallbacksGrid-delete', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button type="primary" disabled={!selectedItems.length} onClick={this.handleDelete}>
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}

        {checkElement('manageMerchantCallbacksGrid-saveEdit', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button type="primary" disabled={!currentEditRow || selectedItems.length} onClick={this.updateCallbacks}>
              {translate('core.buttonTitles.save')}
            </Button>
          </div>
        )}
        {checkElement('manageMerchantCallbacksGrid-callbacksProps', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button
              type="primary"
              disabled={selectedItems.length !== 1}
              onClick={() => this.handleGridTabOpen(selectedItems)}
            >
              {translate('page.manageMerchantCallbacks.detailsTab')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchCallbackGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return checkElement('manageMerchantCallbacksGrid-saveEdit', this.currentArray) ? (
      <td align="center" style={{ textAlign: 'center' }}>
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.editId !== dataItem.editId) || selectedItems.length}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    ) : (
      <td>{currentDataItem[field]}</td>
    );
  };

  getCheckboxCell = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return (
      <td align="center" style={{ textAlign: 'center' }} className="ManageMerchantCallbacksGrid-checkboxCell">
        <EditableGridInput
          type={'checkbox'}
          disabled={
            (currentEditRow && currentEditRow.editId !== dataItem.editId) ||
            selectedItems.length ||
            !checkElement('manageMerchantCallbacksGrid-saveEdit', this.currentArray)
          }
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    );
  };

  render() {
    const { callbacks, isLoading, pageValue, hasNextPage, hasPreviousPage, modalVariant, selectedItems } = this.state;

    if (!checkElement('manageMerchantCallbacksGrid-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={callbacks}
          onRefresh={this.fetchCallbackGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="manageMerchantCallbacksGrid"
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          fieldForSelection="editId"
          onSelect={this.handleSelectionChange}
          multiSelected
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            className="manageMerchantCallbacksGrid"
          />
          <GridColumn field="id" title="Id" width="150" filter="numeric" />
          <GridColumn field="type" title="Type" width="150" cell={this.cellInput} />
          <GridColumn field="partnerKey" title="PartnerKey" width="150" filter="numeric" cell={this.cellInput} />
          <GridColumn field="serviceKey" title="ServiceKey" width="150" filter="numeric" cell={this.cellInput} />
          <GridColumn field="status" title="Status" width="150" cell={this.cellInput} />
          <GridColumn
            field="datePost"
            title="DatePost"
            width="150"
            cell={({ dataItem: { datePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(datePost)}</td>
            )}
            formatFilterCellData={(datePost) => getTransformDateString(datePost)}
          />
          <GridColumn field="retryCount" title="RetryCount" width="150" filter="numeric" cell={this.cellInput} />
          <GridColumn field="isActive" title="IsActive" width="150" cell={this.getCheckboxCell} />
        </Grid>

        {modalVariant && (
          <DeleteModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchCallbackGQL}
            selectedItems={selectedItems}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ManageMerchantCallbacksGrid);
