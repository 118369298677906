export const DROPDOWNS_ORDERS = {
  SERVICE_TO_SERVICE_TYPE: {
    profileDropdown: 'first',
    serviceDropdown: 'second',
    paymentTypeDropdown: 'third',
  },
  PROFILE_TO_PROFILE_TYPE: {
    profileDropdown: 'second',
    serviceDropdown: 'first',
    paymentTypeDropdown: 'third',
  },

  PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE: {
    profileDropdown: 'second',
    serviceDropdown: 'third',
    paymentTypeDropdown: 'first',
  },
};

export const DROPDOWNS_MULTI_CHOICE_AVAILABILITY = {
  SOURCE_DROPDOWNS: {
    SERVICE_TO_SERVICE_TYPE: {
      profileDropdown: true,
      serviceDropdown: false,
      paymentTypeDropdown: false,
    },

    PROFILE_TO_PROFILE_TYPE: {
      profileDropdown: false,
      serviceDropdown: true,
      paymentTypeDropdown: true,
    },

    PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE: {
      profileDropdown: true,
      serviceDropdown: true,
      paymentTypeDropdown: true,
    },
  },

  TARGET_DROPDOWNS: {
    SERVICE_TO_SERVICE_TYPE: {
      profileDropdown: false,
      serviceDropdown: false,
      paymentTypeDropdown: false,
    },
    PROFILE_TO_PROFILE_TYPE: {
      profileDropdown: false,
      serviceDropdown: false,
      paymentTypeDropdown: true,
    },

    PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE: {
      profileDropdown: true,
      serviceDropdown: true,
      paymentTypeDropdown: false,
    },
  },
};

export const DISABLE_AVAILABILITY = {
  SERVICE_TO_SERVICE_TYPE: {
    profileDropdown: false,
    serviceDropdown: true,
    paymentTypeDropdown: true,
  },
  PROFILE_TO_PROFILE_TYPE: {
    profileDropdown: true,
    serviceDropdown: false,
    paymentTypeDropdown: true,
  },

  PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE: {
    profileDropdown: true,
    serviceDropdown: true,
    paymentTypeDropdown: false,
  },
};

export const INITIAL_VALUES = {
  copyCommissionType: 'SERVICE_TO_SERVICE_TYPE',
  sourceProfileDropdown: [],
  sourceServiceDropdown: [],
  sourcePaymentTypeDropdown: [],
  targetProfileDropdown: [],
  targetServiceDropdown: [],
  targetPaymentTypeDropdown: [],
  isOldCommissionDelete: false,
};

export const MOBILE_BREAKPOINT = 992;
