import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService, StorageService, tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import ModalDeleteBusinessServices from './modalDeleteBusinessService';
import ModalCreateBusinessService from './modalCreateBusinessService';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

const api = new ApiService();

class GridScreenControlBusinessServices extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlBusinessServices');
  }

  state = {
    isLoading: false,
    data: [],
    visibleDeleteBusinessServices: false,
    visibleCreateBusinessService: false,
    selectedItems: [],
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-screenControlBusinessServices');
  };

  closeModalDeleteBusinessServices = () => {
    this.setState({ visibleDeleteBusinessServices: false });
  };

  toggleModalDeleteBusinessServices = () => {
    this.setState(({ visibleDeleteBusinessServices }) => {
      return {
        visibleDeleteBusinessServices: !visibleDeleteBusinessServices,
      };
    });
  };

  toggleModalCreateBusinessService = () => {
    this.setState(({ visibleCreateBusinessService }) => {
      return {
        visibleCreateBusinessService: !visibleCreateBusinessService,
      };
    });
  };

  closeModalCreateBusinessService = () => {
    this.setState({ visibleCreateBusinessService: false });
  };

  createAssignBusinessServiceTab = () => {
    const { data } = this.state;
    tabService.addTab({
      type: 'assignBusinessService',
      dataItem: { businessServices: data },
    });
  };

  makeQuery = async () => {
    try {
      this.toggleLoader(true);
      const data = await api.getMainProfileHoldList();
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {checkElement('screenControlBusinessServices-btn-delete', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModalDeleteBusinessServices}
            disabled={!selectedItems.length}
          >
            {translate('grids.profilesMainProfileList.modal-delete-template-title')}
          </Button>
        )}
        {checkElement('screenControlBusinessServices-btn-create', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.toggleModalCreateBusinessService}>
            {translate('grids.profilesMainProfileList.modal-create-template-title')}
          </Button>
        )}
        {checkElement('screenControlBusinessServices-btn-assign', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.createAssignBusinessServiceTab}>
            {translate('grids.profilesMainProfileList.modal-assign-template-title')}
          </Button>
        )}
      </Col>
    );
  };

  handleOnclickProperty = (props) => {
    return tabService.addTab({
      type: 'editBusinessService',
      dataItem: { ...props.dataItem },
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const value = checkElement('screenControlBusinessServices-link-edit', this.currentArray);
    const { isLoading, data, visibleDeleteBusinessServices, visibleCreateBusinessService, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlBusinessServices"
          permissionName="screenControlBusinessServices"
          selectedField="selected"
          onSelect={this.handleSelectionChange}
          fieldForSelection="MainProfileHoldId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="MainProfileCount"
            title="grids.businessServices.column-mainProfileCount"
            width="180"
            filter="numeric"
          />

          <GridColumn
            field="MainProfileHoldName"
            title="grids.businessServices.column-mainProfileHoldName"
            width="250"
            onClick={value ? this.handleOnclickProperty : null}
            // onClick={(props) => this.handleOnclickProperty(props)}
          />

          <GridColumn
            field="MainProfileHoldVersion"
            title="grids.businessServices.column-mainProfileHoldVersion"
            width="200"
          />
          {/*
          <GridColumn
            field="UserIdPost"
            title="grids.businessServices.column-userIdPost"
            width="250"
          /> */}

          <GridColumn field="UserIdName" title="grids.businessServices.column-userIdName" width="250" />

          <GridColumn
            field="DatePost"
            title="grids.businessServices.column-datePost"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="MainProfileHoldId"
            title="grids.businessServices.column-mainProfileHoldId"
            width="250"
            filter="numeric"
          />
        </Grid>
        {visibleDeleteBusinessServices && (
          <ModalDeleteBusinessServices
            item={selectedItems.map(({ MainProfileHoldId }) => MainProfileHoldId)}
            name="screenControlBusinessServices"
            visible={visibleDeleteBusinessServices}
            closeModal={this.closeModalDeleteBusinessServices}
            toggleModal={this.toggleModalDeleteBusinessServices}
            update={this.makeQuery}
          />
        )}
        {visibleCreateBusinessService && (
          <ModalCreateBusinessService
            name="screenControlBusinessServices"
            data={data}
            visible={visibleCreateBusinessService}
            closeModal={this.toggleModalCreateBusinessService}
            update={this.makeQuery}
            fieldsForSelection={{
              Id: 'MainProfileHoldId',
              Name: 'MainProfileHoldName',
              Version: 'MainProfileHoldVersion',
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridScreenControlBusinessServices);
