const nonRiskLimits = (translate, value) => {
  switch (value) {
    case 'nonRiskLimits-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'nonRiskLimits-buttonChange':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.change')}`;
    case 'nonRiskLimits-nonRiskServicesTabButtonLink':
      return `${translate('page.adminPanel.type-button')}: ${translate('page.nonRiskLimitsGrid.tab-nonRiskServices')}`;
    case 'nonRiskLimits-nonRiskServicesButtonAdd':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate(
        'page.nonRiskLimitsGrid.tab-nonRiskServices',
      )}: ${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.add')}`;
    case 'nonRiskLimits-nonRiskServicesButtonDelete':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate(
        'page.nonRiskLimitsGrid.tab-nonRiskServices',
      )}: ${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.delete')}`;
  }
};

export default nonRiskLimits;
