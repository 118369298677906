import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService, StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import ModalChangeProfile from './modalChangeProfile';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import CellBoolean from '../../account/cellBoolean';
import ModalProfileDisableChange from './modalProfileDisableChange';
import getCheckedItems from '../../../utils/getCheckedItems';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean inverted {...props} />;

class GridScreenControlTerminals extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlTerminals');
  }
  state = {
    isLoading: false,
    data: [],
    owners: [],
    visible: false,
    isVisibleModalDisableChange: false,
    selectedItems: [],
    allOwners: [],
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.fetchOwners();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-screenControlTerminals');
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  toggleModalDisableChange = () => {
    this.setState(({ isVisibleModalDisableChange }) => {
      return {
        isVisibleModalDisableChange: !isVisibleModalDisableChange,
      };
    });
  };

  closeModalDisableChange = () => {
    this.setState({ isVisibleModalDisableChange: false });
  };

  makeQuery = async () => {
    const { owners } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const data = await api.terminalsMainProfileList(owners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, allOwners, owners } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={owners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('screenControlTerminals-btn-change-profile', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModal}
            disabled={!selectedItems.length}
          >
            {translate('page.commission.btn-toggle')}
          </Button>
        )}
        {checkElement('screenControlTerminals-btn-disableChangeProfile', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModalDisableChange}
            disabled={!selectedItems.length}
          >
            {translate('grids.terminalsMainProfileList.btn-disableChangeProfile')}
          </Button>
        )}
      </Col>
    );
  };

  getItem = () => {
    const result = StorageService.getItem('selectionListForGrid-screenControlTerminals');
    if (result !== null) return result;
    else return [];
  };

  onSelect = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { isLoading, data, visible, owners, isVisibleModalDisableChange, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlTerminals"
          permissionName="screenControlTerminals"
          selectedField="selected"
          fieldForSelection="TerminalId"
          onSelect={this.onSelect}
          isShowSpecificFilterByFieldName="TerminalId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="TerminalId"
            title="grids.terminalsMainProfileList.column-terminalId"
            width="180"
            filter="numeric"
          />

          <GridColumn
            field="TerminalName"
            title="grids.terminalsMainProfileList.column-terminalName"
            width="270"
            // onClick={(props) => tabService.addTab({ type: 'profile', dataItem: { ...props.dataItem, currentOwnerId: +selectedItem } })}
          />

          <GridColumn field="DesignTypeName" title="grids.createTerminal.column-designType" width="150" />

          <GridColumn field="CityName" title="grids.terminalsMainProfileList.column-cityName" width="150" />

          <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />

          <GridColumn
            field="TerminalAddress"
            title="grids.terminalsMainProfileList.column-terminalAddress"
            width="150"
          />

          <GridColumn field="StatusName" title="grids.terminalsMainProfileList.column-statusName" width="150" />

          <GridColumn
            field="MainProfileId"
            title="grids.profilesMainProfileList.column-profileId"
            width="200"
            filter="numeric"
          />

          <GridColumn field="MainProfileName" title="grids.terminalsMainProfileList.column-mainProfileId" width="150" />

          <GridColumn
            field="IsMainProfileDisabled"
            title="grids.terminalsMainProfileList.column-isMainProfileDisabled"
            filter="boolean"
            width="150"
            cell={WrappedCellBoolean}
          />

          <GridColumn field="Branch" title="grids.terminals.column-branch" filter="text" width="150" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="145" />
        </Grid>
        {visible && (
          <ModalChangeProfile
            item={selectedItems.map(({ TerminalId }) => TerminalId)}
            name="screenControlTerminals"
            owners={owners}
            visible={visible}
            closeModal={this.closeModal}
            toggleModal={this.toggleModal}
            update={this.makeQuery}
          />
        )}
        {isVisibleModalDisableChange && (
          <ModalProfileDisableChange
            item={selectedItems.map(({ TerminalId }) => TerminalId)}
            name="screenControlTerminals"
            visible={isVisibleModalDisableChange}
            data={selectedItems}
            closeModal={this.closeModalDisableChange}
            toggleModal={this.toggleModalDisableChange}
            update={this.makeQuery}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridScreenControlTerminals);
