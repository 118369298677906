import React, { PureComponent } from 'react';
import { Button, Card, Col, Input, Row } from 'antd';

import FormStateContainer from '../../../components/FormStateContainer';
import { MODAL_INITIAL_VALUE } from './initialValues';
import { renderUserPropertiesInput } from './renderUserPropertiesInput';
import CodeCollectorsModal from './CodeCollectorsModal';
import LoadingPanel from '../../../components/loader';
import { withTranslate } from '../../../contexts/localContext';

import './UserProperties.scss';
import { ADDITIONAL_INFO_PROPS, BASE_INFO_PROPS } from './constant';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

class UserProperties extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('users');
  }
  renderUserPropertiesInputByProps = (name, label, type, isDisabled = false, span = 24, md = 16, maxLength) => {
    const { formState, onChangeFormState, formErrors, onError, translate, validators } = this.props;
    return renderUserPropertiesInput(
      name,
      label,
      type,
      isDisabled,
      span,
      md,
      maxLength,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      translate,
      validators,
    );
  };

  renderBaseInfoFields = () => {
    const { isEditing, translate, setDialogVisible } = this.props;

    return BASE_INFO_PROPS.map(
      ({ name, label, isDisabled, type, inputProps = { span: 24, md: 16 }, rowProps = {}, maxLength }, index) => {
        return (
          <Row className="UserProperties-row" {...rowProps} key={index}>
            {this.renderUserPropertiesInputByProps(
              name,
              label,
              type || 'text',
              isDisabled || !isEditing,
              inputProps.span,
              inputProps.md,
              maxLength,
            )}
            {name === 'CollectorCode' && (
              <Col span={5} md={2}>
                {checkElement('users-userPropertiesBtnEditCollectorCode', this.currentArray) && (
                  <Button
                    type="primary"
                    className="UserProperties-btnChange"
                    onClick={() => {
                      setDialogVisible(true);
                    }}
                  >
                    {translate('core.buttonTitles.change')}
                  </Button>
                )}
              </Col>
            )}
          </Row>
        );
      },
    );
  };

  renderAdditionalFields = () => {
    const { isEditing } = this.props;

    return ADDITIONAL_INFO_PROPS.map(({ name, label, type, maxLength }, index) => {
      return (
        <Row className="UserProperties-row" key={index}>
          {this.renderUserPropertiesInputByProps(name, label, type || 'text', !isEditing, 24, 16, maxLength)}
        </Row>
      );
    });
  };

  render = () => {
    const {
      translate,
      userId,
      userName,
      onChangeFormState,
      formErrors,
      setDialogVisible,
      setShowAdditionalInfo,
      showAdditionalInfo,
      isEditing,
      onEditingPermitted,
      dialogVisible,
      isLoading,
      setUserProperties,
      currentCollectorsCode,
    } = this.props;

    const isDisabledByErrors = !!Object.values(formErrors).filter((item) => item).length;

    return (
      <>
        <Col span={18} className="UserProperties-form">
          <Row type="flex" align="middle" className="UserProperties-row">
            <Row type="flex" align="middle" className="UserProperties-header">
              <p>{translate('grids.userProperties.userId')}</p>
              <Input value={userId} disabled className="UserProperties-headerInput" />
              {checkElement('users-userPropertiesBtnEdit', this.currentArray) && (
                <Button
                  type="primary"
                  onClick={isEditing ? setUserProperties : onEditingPermitted}
                  disabled={isDisabledByErrors}
                  className="UserProperties-headerBtnSave"
                >
                  {!isEditing ? translate('core.buttonTitles.edit') : translate('core.buttonTitles.save')}
                </Button>
              )}
            </Row>
          </Row>

          <Row className="UserProperties-row">
            <Col xs={24} sm={24} md={24} lg={16}>
              <Card
                type="inner"
                title={translate('grids.userProperties.mainProperties')}
                className="UserProperties-cardMainProperties"
              >
                {this.renderBaseInfoFields()}
              </Card>
              <Row type="flex" className="UserProperties-row">
                {checkElement('users-userPropertiesBtnShowAdditionalInfo', this.currentArray) && (
                  <Button
                    type="primary"
                    className="UserProperties-btnShowAdditionalInfo"
                    onClick={setShowAdditionalInfo}
                  >
                    {!showAdditionalInfo
                      ? translate('core.buttonTitles.showAdditionalProperties')
                      : translate('core.buttonTitles.hiddenAdditionalProperties')}
                  </Button>
                )}
              </Row>
            </Col>
          </Row>

          {showAdditionalInfo && (
            <Col xs={24} sm={24} md={24} lg={16}>
              <Card type="inner" title={translate('grids.userProperties.additionalInformation')}>
                {this.renderAdditionalFields()}
              </Card>
            </Col>
          )}
        </Col>

        <FormStateContainer initialValues={MODAL_INITIAL_VALUE}>
          <CodeCollectorsModal
            visible={dialogVisible}
            onClose={() => {
              setDialogVisible(false);
            }}
            setNewCollectorCodeForUser={() => {}}
            userId={userId}
            oldCollectorCode={currentCollectorsCode}
          />
        </FormStateContainer>
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(UserProperties);
