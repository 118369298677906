import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { tabService } from '../../../services';
import '../../screenControl(terminalsControl)/profiles/modalProfile.scss';
import LoadingPanel from '../../../components/loader';

class ModalCloneApplication extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    noSelected: false,
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  cloneApplication = () => {
    return tabService.addTab({
      type: 'cloneApplication',
      dataItem: this.props.selectedItem,
    });
  };

  render() {
    const { translate, visible, toggleModal } = this.props;
    const { loading, isLoading, noSelected } = this.state;
    return (
      <>
        <Modal
          title={translate('grids.addingAddressedPaymentApplications.btn-clone-app')}
          visible={visible}
          centered
          onCancel={toggleModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('grids.certificates-issued.onCancelText')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                this.cloneApplication();
                toggleModal();
              }}
            >
              {translate('grids.certificates-issued.onOkText')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={24}>
              {noSelected && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCloneApplication);
