class FieldsValidationService {
  getIntegerNumberValidation =
    (translate) =>
    (value, withoutNull = false) => {
      if (!value || (value === 'null' && !withoutNull)) {
        return '';
      }

      if (isNaN(+value)) {
        return `${translate('page.serviceMainProps.invalidValueFormat')}`;
      }

      if (isNaN(+value) || !Number.isInteger(+value) || `${value}`.includes('.')) {
        return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
      }

      return '';
    };

  getParsedValue = (value) => {
    if (value === 'null') {
      return null;
    }

    return value || value === 0 ? +`${value}`.replace(',', '.') : null;
  };

  getDropdownValue = (valuesList) => (!valuesList.length || valuesList[0] === 'null' ? null : valuesList[0]);

  getNumberValidation = (translate) => (value) => {
    if (!value || value === 'null') {
      return '';
    }

    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${normalizedValue}`.includes('.') && `${normalizedValue}`.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }

    return '';
  };

  validatePriority = (translate) => (value) => {
    const validateIntegerError = this.getIntegerNumberValidation(translate)(value, true);

    if (validateIntegerError || value === 'null') {
      return validateIntegerError;
    }

    if (value < -5 || value > 5) {
      return `${translate('page.routingManagement.priorityLengthError')}`;
    }

    return '';
  };

  validateWeight = (translate) => (value) => {
    if (!value && value !== 0) {
      return '';
    }

    const validateIntegerError = this.getIntegerNumberValidation(translate)(value);

    if (validateIntegerError) {
      return validateIntegerError;
    }

    if (value < 1 || value > 99) {
      return `${translate('page.routingManagement.weightLengthError')}`;
    }

    return '';
  };
}

export default FieldsValidationService;
