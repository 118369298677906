import React, { PureComponent } from 'react';
import { Button, Modal, Row, Col } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';
import GridDropdownWithDeletedOwners from '../../GridDropdown/GridDropdownWithDeletedOwners';

import './MigrateInventoryNumberModal.scss';

const api = new ApiService();

class MigrateInventoryNumberModal extends PureComponent {
  state = {
    selectedItem: null,
    selectOwners: [1],
    owners: [],
    isLoading: false,
    terminalList: [],
    isInventoryNumberSuccessfullyMigrated: false,
  };

  async componentDidMount() {
    await this.fetchOwners();
    await this.getTerminals();
  }

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({ owners });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getTerminals = async () => {
    const { selectOwners } = this.state;

    this.setState({ isLoading: true });
    try {
      const terminalList = await api.terminalsWithDeleteStatusGet(selectOwners);
      this.setState({ terminalList });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onDropdownSave = (selectOwners) => {
    this.setState({ selectOwners }, this.getTerminals);
  };

  handleMigrateInventory = async () => {
    const { migrateInventoryNumber, changeInventoryNumber } = this.props;
    const {
      selectedItem: [{ TerminalId, InventoryNumber }],
    } = this.state;

    this.setState({ isLoading: true });

    try {
      await migrateInventoryNumber(TerminalId);
      changeInventoryNumber(InventoryNumber);
      this.setState({ isInventoryNumberSuccessfullyMigrated: true });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderHeader = () => {
    const { translate } = this.props;
    const { selectedItem, isInventoryNumberSuccessfullyMigrated } = this.state;

    return (
      <div className="MigrateInventoryNumberModal-header">
        <div className="MigrateInventoryNumberModal-headerTitle">
          {isInventoryNumberSuccessfullyMigrated
            ? translate('page.terminalSettings.inventoryNumberMigrationSuccessful')
            : translate('page.terminalSettings.chooseTerminal')}
        </div>

        <Row className="MigrateInventoryNumberModal-headerAction">
          {isInventoryNumberSuccessfullyMigrated ? (
            <Col offset={8} span={8} className="MigrateInventoryNumberModal-actionHeaderCol">
              <Button key="submit" type="primary" onClick={this.handleCloseModal}>
                {translate('core.buttonTitles.ok')}
              </Button>
            </Col>
          ) : (
            <>
              <Col span={12} className="MigrateInventoryNumberModal-actionHeaderCol">
                <Button key="submit" type="primary" onClick={this.handleMigrateInventory} disabled={!selectedItem}>
                  {translate('core.buttonTitles.migrate')}
                </Button>
              </Col>

              <Col span={12} className="MigrateInventoryNumberModal-actionHeaderCol">
                <Button key="submit" type="primary" onClick={this.handleCloseModal}>
                  {translate('core.buttonTitles.refuse')}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  };

  handleSelectionChange = (selectedItem) => {
    this.setState({ selectedItem });
  };

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.setState({ isInventoryNumberSuccessfullyMigrated: false });
    closeModal();
  };

  renderModalContent = () => {
    const { selectOwners, owners, terminalList } = this.state;

    return (
      <>
        <Row className="MigrateInventoryNumberModal-ownerDropdown">
          <Col span={16}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(owners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              onSave={this.onDropdownSave}
              isSingle
            />
          </Col>
        </Row>
        <Grid
          data={terminalList}
          name="сommissionManagementProfiles-grid"
          className="MigrateInventoryNumberModal-grid"
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="TerminalId" title="grids.createTerminal.column-terminalId" width="150" filter="numeric" />
          <GridColumn field="TerminalName" title="grids.terminalMaintenance.column-terminalName" width="150" />
          <GridColumn
            field="OwnerId"
            title="grids.profilesMainProfileList.column-ownerId"
            width="120"
            filter="numeric"
          />
          <GridColumn field="OwnerName" title="grids.profilesMainProfileList.column-ownerName" width="150" />
          <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="180" />
          <GridColumn field="CityName" title="grids.terminalsMainProfileList.column-cityName" width="120" />
          <GridColumn
            field="TerminalAddress"
            title="grids.terminalsMainProfileList.column-terminalAddress"
            width="200"
          />
          <GridColumn
            field="InventoryNumber"
            title="page.terminalSettings.field-inventoryNumber"
            width="180"
            filter="numeric"
          />
        </Grid>
      </>
    );
  };

  render() {
    const { selectedItem, isLoading, isInventoryNumberSuccessfullyMigrated } = this.state;
    const { terminalId, translate } = this.props;

    return (
      <Modal
        visible
        onCancel={this.handleCloseModal}
        title={this.renderHeader()}
        zIndex="1053"
        footer={[]}
        className="MigrateInventoryNumberModal"
        centered={false}
      >
        {isInventoryNumberSuccessfullyMigrated ? (
          <div className="MigrateInventoryNumberModal-successfullyAssignedTerminalContent">
            {`${translate('page.terminalSettings.field-inventoryNumber')} ${selectedItem.InventoryNumber} ${translate(
              'page.terminalSettings.successfullyAssignedTerminal',
            )}${terminalId}`}
          </div>
        ) : (
          this.renderModalContent()
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(MigrateInventoryNumberModal);
