const FiscalizedTransactionsDaysReport = (translate, value) => {
  switch (value) {
    case 'fiscalizedTransactionsDaysReport-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    default:
      return value;
  }
};

export default FiscalizedTransactionsDaysReport;
