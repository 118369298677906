import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Row } from 'antd';
import Grid from '../../components/grids/baseGrid';
import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import DateRange from '../../components/daterange';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

const convertToShortISOFormat = (date) => formatDate(date, 'dd.MM.yyyy');

class GridAccountTurn extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('accountmanager');
  }

  state = {
    data: [],
    isLoading: false,
    range: {
      start: null,
      end: null,
    },
  };

  onRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  componentDidMount() {
    this.makeQuery();
  }

  buildToolbar = () => {
    const { translate, accountId, ownerName } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <div>
              {`${translate('grids.accountsTurn.toolbar-bill')}`} #{accountId} - {ownerName}
            </div>
            <DateRange onRange={this.onRange} />
          </Col>
          {checkElement('accountTurn-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button
                  className="sm-w-100"
                  icon="search"
                  style={{ marginTop: '10px' }}
                  primary
                  onClick={this.makeQuery}
                >
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { range } = this.state;
    if (!range) return;
    const { accountId } = this.props;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);

    try {
      this.toggleLoader(true);
      const model = {
        AccountId: accountId,
        DateStart: start,
        DateEnd: end,
      };
      const data = await api.accountTurn(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  renderAccountdetailsOnClick = (props) => {
    const { accountId } = this.props;
    if (checkElement('accountTurn-workDateOnclick', this.currentArray))
      return tabService.addTab({
        type: 'accountdetails',
        dataItem: props.dataItem.Movements,
        accInfo: {
          accountId,
          date: convertToShortISOFormat(props.dataItem.WorkDate),
        },
      });
    else return null;
  };

  render = () => {
    const { data, isLoading } = this.state;
    return (
      <Grid
        data={data}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        onRefresh={this.makeQuery}
        name="accountTurn"
        permissionName="accountmanager"
      >
        <GridColumn
          field="WorkDate"
          title="grids.accountsTurn.column-date"
          filter="date"
          width="200"
          format="dd.MM.yyyy"
          onClick={(props) => this.renderAccountdetailsOnClick(props)}
          // onClick={(props) => tabService.addTab({
          //   type: 'accountdetails',
          //   dataItem: props.dataItem.Movements
          // })}
        />
        <GridColumn field="Account" title="grids.account.column-account" width="150" />
        <GridColumn field="AccountName" title="grids.accountsTurn.column-name" width="150" />
        <GridColumn
          field="Saldo"
          title="grids.accountsTurn.column-remainder"
          filter="numeric"
          width="150"
          format="{0:n2}"
        />
        {/* PRCC-4589 - changing titles!!! */}
        {/* grids.accountsTurn.column-profit */}
        <GridColumn
          field="DbTurn"
          title="grids.accountsTurn.column-costs"
          filter="numeric"
          width="150"
          format="{0:n2}"
        />
        {/* grids.accountsTurn.column-costs */}
        <GridColumn
          field="CrTurn"
          title="grids.accountsTurn.column-profit"
          filter="numeric"
          width="150"
          format="{0:n2}"
        />
        <GridColumn field="Currency" title="grids.collectionDetails.column-currency" width="150" />
      </Grid>
    );
  };
}

export default withTranslate(GridAccountTurn);
