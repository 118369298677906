import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import * as XLSX from 'xlsx';

import CustomInput from '../../../../components/Input/Input';
import AddModalService from './AddModal.service';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { CONFIGS, REQUIREMENT_LIST } from '../../constants';
import { withTranslate } from '../../../../contexts/localContext';

import './AddModal.styled.scss';
import UpdateModalService from '../../UpdateRouteModal/UpdateModal.service';
import { tabService } from '../../../../services';

class AddModal extends PureComponent {
  state = {
    routesListFromFile: [],
    fileName: '',
    isLoading: false,
    isFileReadError: false,
    isDataValidError: false,
  };

  renderInputRow = ({ label, inputComponent }) => {
    return (
      <div className="CardRoutingAddModal-inputRow">
        <div className="CardRoutingAddModal-label">{label}</div>
        {inputComponent}
      </div>
    );
  };

  renderInput = ({ name }) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;

    return (
      <div className="CardRoutingAddModal-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={AddModalService.getValidators(translate)}
          name={name}
        />
      </div>
    );
  };

  getSelectChangeCallback = (name) => (selectItems) => {
    const { onChangeFormState } = this.props;

    onChangeFormState(name, selectItems);
  };

  renderSelect = ({ name, label, isShort }) => {
    const { formState, dropdownsData, translate } = this.props;

    return (
      <div className={`ServiceMainProps-input ${isShort ? 'ServiceMainProps-shortSelect' : ''}`}>
        <GridDropdown
          data={dropdownsData[name]}
          colConfig={CONFIGS[name]}
          selectItems={formState[name]}
          onSave={this.getSelectChangeCallback(name)}
          defaultTitle={`${translate('page.routingManagement.select')} ${label}`}
          isSingle
          isAllowEmptyList
        />
      </div>
    );
  };

  onUploadFile = async ({ target: { files } }) => {
    await this.setState({ isFileReadError: false, isDataValidError: false });

    if (!files[0]) {
      await this.setState({ isFileReadError: true });
      return;
    }

    try {
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]);

      reader.onload = ({ target: { result } }) => {
        const workbook = XLSX.read(result, { type: 'binary' });

        if (!workbook.Sheets[workbook.SheetNames[0]]) {
          this.setState({ isFileReadError: true });
          return;
        }

        const rawDate = workbook.Sheets[workbook.SheetNames[0]];

        const rawDateLength = Object.keys(rawDate).filter((key) => key.includes('A')).length;

        const parsedData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          range: `A1:N${rawDateLength}`,
          header: 1,
        });

        const [headRow, ...dataRows] = parsedData;

        const isEmptyValueExist = dataRows.some((row) =>
          JSON.parse(JSON.stringify(row)).some(
            (value) => value === undefined || value === null || (value.trim && value.trim() === ''),
          ),
        );

        if (isEmptyValueExist) {
          this.setState({ isDataValidError: true });
          return;
        }

        this.setState({
          routesListFromFile: dataRows.map((row) =>
            row
              .map((value, index) => ({
                [headRow[index]]: value !== 0 && (!value || `${value}`.toUpperCase() === 'NULL') ? null : value,
              }))
              .reduce((acc, item) => ({ ...acc, ...item }), {}),
          ),
          fileName: files[0].name && files[0].name.length > 24 ? `${files[0].name.slice(0, 20)}...` : files[0].name,
        });
      };
    } catch (e) {
      console.log('e', e);
      this.setState({ isLoading: false, isFileReadError: true });
    }
  };

  addRedirect = () => {
    const {
      closeModal,
      dropdownsData,
      formState: {
        bins,
        systemTypes,
        panTypes,
        incomingServiceList,
        outcomingServiceList,
        banks,
        rangeFrom,
        rangeTo,
        amountMax,
        priority,
        weight,
      },
    } = this.props;

    const { routesListFromFile } = this.state;

    closeModal();

    const valuesListWithNativeKeys = {
      systemType: UpdateModalService.getDropdownValue(systemTypes),
      panType: UpdateModalService.getDropdownValue(panTypes),
      incomingServiceId: UpdateModalService.getDropdownValue(incomingServiceList),
      outcomingServiceId: UpdateModalService.getDropdownValue(outcomingServiceList),
      bankName: UpdateModalService.getDropdownValue(banks),
      rangeFrom: UpdateModalService.getParsedValue(rangeFrom),
      rangeTo: UpdateModalService.getParsedValue(rangeTo),
      amountMax: UpdateModalService.getParsedValue(amountMax),
      priority,
      weight: UpdateModalService.getParsedValue(weight),
      last: false,
      active: true,
    };

    if (!routesListFromFile || !routesListFromFile.length) {
      tabService.addTab({
        dropdownsData: dropdownsData,
        type: 'updateRoutingValidationResult',
        dataItem: {
          dropdownsData: dropdownsData,
          type: 'add',
          changedList: bins.length
            ? bins
                .split(',')
                .map((bin) => bin.trim())
                .filter((bin) => !!bin)
                .map((bin) => ({ bin: bin === 'null' ? null : bin, ...valuesListWithNativeKeys, id: 0 }))
            : [{ bin: null, ...valuesListWithNativeKeys, id: 0 }],
        },
      });

      return;
    }

    tabService.addTab({
      type: 'updateRoutingValidationResult',
      dataItem: {
        dropdownsData: dropdownsData,
        type: 'add',
        changedList: routesListFromFile.map(({ bin, priority, weight, ...otherProps }) => ({
          id: 0,
          bin,
          priority,
          weight,
          last: false,
          active: true,
          rangeFrom: otherProps.range_from,
          rangeTo: otherProps.range_to,
          systemType: otherProps.system_type,
          incomingServiceId: otherProps.incoming_service_id,
          outcomingServiceId: otherProps.outcoming_service_id,
          amountMax: otherProps.amount_max,
          panType: otherProps.pan_type,
          bankName: otherProps.bank_name,
        })),
      },
    });
  };

  render() {
    const { visible, closeModal, formErrors, formState, translate } = this.props;
    const { routesListFromFile, fileName, isFileReadError, isDataValidError } = this.state;

    const isFormErrorsExist = Object.values(formErrors).some((value) => !!value);
    const isRequiredFieldsEmpty =
      (!formState.priority && formState.priority !== 0) || !formState.outcomingServiceList.length;

    const isActionDisabled =
      routesListFromFile && !routesListFromFile.length && (isRequiredFieldsEmpty || isFormErrorsExist);

    return (
      <Modal
        title={translate('page.routingManagement.addRoute')}
        visible={visible}
        onCancel={closeModal}
        className="CardRoutingAddModal"
        footer={[
          <Button type="primary" key="add" disabled={isActionDisabled} onClick={this.addRedirect}>
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="CardRoutingAddModal-form">
          {!!routesListFromFile && !!routesListFromFile.length && <div className="CardRoutingAddModal-formOverLayer" />}

          <div className="CardRoutingAddModal-blockTitle">{translate('page.routingManagement.addByForm')}</div>
          {this.renderInputRow({ label: 'bins', inputComponent: this.renderInput({ name: 'bins' }) })}
          {this.renderInputRow({
            label: 'system_types',
            inputComponent: this.renderSelect({ name: 'systemTypes', label: 'system_types' }),
          })}
          {this.renderInputRow({
            label: 'pan_types',
            inputComponent: this.renderSelect({ name: 'panTypes', label: 'pan_types' }),
          })}
          {this.renderInputRow({
            label: 'incoming_service_list',
            inputComponent: this.renderSelect({ name: 'incomingServiceList', label: 'incoming_service_list' }),
          })}
          {this.renderInputRow({
            label: 'outcoming_service_list',
            inputComponent: this.renderSelect({ name: 'outcomingServiceList', label: 'outcoming_service_list' }),
          })}
          {this.renderInputRow({
            label: 'banks',
            inputComponent: this.renderSelect({ name: 'banks', label: 'banks', isShort: true }),
          })}
          <div className="CardRoutingAddModal-flexRow">
            {this.renderInputRow({ label: 'range_from', inputComponent: this.renderInput({ name: 'rangeFrom' }) })}
            {this.renderInputRow({ label: 'range_to', inputComponent: this.renderInput({ name: 'rangeTo' }) })}
          </div>
          <div className="CardRoutingAddModal-flexRow">
            {this.renderInputRow({ label: 'priority', inputComponent: this.renderInput({ name: 'priority' }) })}
            {this.renderInputRow({ label: 'weight', inputComponent: this.renderInput({ name: 'weight' }) })}
          </div>
          <div className="CardRoutingAddModal-shortRow">
            {this.renderInputRow({ label: 'amount_max', inputComponent: this.renderInput({ name: 'amountMax' }) })}
          </div>
        </div>

        <div className="CardRoutingAddModal-blockTitle">{translate('page.routingManagement.addByFile')}</div>
        <div style={{ position: 'relative' }}>
          <Button type="primary">{fileName || translate('page.routingManagement.chooseFile')}</Button>
          {!!isFileReadError && (
            <div className="CardRoutingAddModal-error">{translate('page.routingManagement.readFileError')}</div>
          )}
          {!!isDataValidError && (
            <div className="CardRoutingAddModal-error">{translate('page.routingManagement.fileDataNotValidError')}</div>
          )}

          <input
            style={{ opacity: '0', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0' }}
            id="uploadRouteList"
            accept=".xlsx"
            onChange={this.onUploadFile}
            type="file"
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslate(AddModal);
