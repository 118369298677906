export const ALL_GRID_COLUMNS = [
  'ownerName',
  'serviceId',
  'serviceGroup',
  'serviceName',
  'paymentType',
  'dateStart',
  'commissionDealer',
  'minCommissionDealer',
  'maxCommissionDealer',
  'commissionClient',
  'minCommissionClient',
  'maxCommissionClient',
  'dateOfChange',
  'executor',
  'status',
];

export const NORMALIZE_FIELD_MAP = new Map([
  ['СommissionDealer', 'commissionDealer'],
  ['MinСommissionDealer', 'minCommissionDealer'],
  ['MaxСommissionDealer', 'maxCommissionDealer'],
  ['MinСommissionClient', 'minCommissionClient'],
  ['MaxСommissionClient', 'maxCommissionClient'],
]);

export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([
  ['commissionDealer', 'СommissionDealer'],
  ['minCommissionDealer', 'MinСommissionDealer'],
  ['maxCommissionDealer', 'MaxСommissionDealer'],
  ['minCommissionClient', 'MinСommissionClient'],
  ['maxCommissionClient', 'MaxСommissionClient'],
]);
