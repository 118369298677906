import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Row } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';

import './SuspendedFinancialTransactionsGrid.scss';
import SuspendedFinancialTransactionsGridService from './SuspendedFinancialTransactionsGrid.service';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ApiService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import CommentHistoryDialog from './CommentHistoryDialog';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

class UnlocksHistoryGrid extends PureComponent {
  state = {
    unlockHistory: [],
    isLoading: false,
    errorMessage: '',
    commentsHistory: null,
    currentPaymentId: null,
  };

  componentDidMount() {
    this.fetchOperationsHistory();
  }

  fetchOperationsHistory = async () => {
    const { paymentIds } = this.props;

    try {
      await this.setState({ isLoading: true });
      const unlockHistory = await api.postActionReportWithPayments({
        paymentIds: paymentIds,
      });
      await this.setState({
        unlockHistory: unlockHistory && unlockHistory.length ? unlockHistory : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  getCommentsHistory = async ({ dataItem: { PaymentId } }) => {
    try {
      await this.setState({ isLoading: true });
      const commentsHistory = await api.postPaymentComments(PaymentId);

      await this.setState({
        commentsHistory: commentsHistory && commentsHistory.length ? commentsHistory : [],
        isLoading: false,
        currentPaymentId: PaymentId,
      });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { unlockHistory, isLoading, commentsHistory, currentPaymentId } = this.state;

    return (
      <>
        <Grid
          onRefresh={this.fetchOperationsHistory}
          data={unlockHistory}
          toolbar={<></>}
          isLoading={isLoading}
          name="unlocksSuspendedOperationsHistoryGrid"
        >
          <GridColumn
            field="PaymentId"
            title="page.suspendedFinancialTransactions.col-paymentID"
            width="120"
            filter="numeric"
            onClick={this.getCommentsHistory}
          />
          <GridColumn
            field="PaymentNumber"
            title="page.suspendedFinancialTransactions.col-paymentId"
            width="120"
            filter="numeric"
          />
          <GridColumn
            field="WorkDate"
            title="page.suspendedFinancialTransactions.col-dateOperation"
            width="160"
            cell={({ dataItem: { WorkDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(WorkDate)}</td>
            )}
            formatFilterCellData={(WorkDate) => getTransformDateString(WorkDate)}
          />
          <GridColumn
            field="BlockDate"
            title="page.suspendedFinancialTransactions.col-dateBlock"
            width="180"
            cell={({ dataItem: { BlockDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(BlockDate)}</td>
            )}
            formatFilterCellData={(BlockDate) => getTransformDateString(BlockDate)}
          />
          <GridColumn
            field="UnlockDate"
            title="page.suspendedFinancialTransactions.col-dateUnlock"
            width="200"
            cell={({ dataItem: { UnlockDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(UnlockDate)}</td>
            )}
            formatFilterCellData={(UnlockDate) => getTransformDateString(UnlockDate)}
          />
          <GridColumn
            field="RejectionDate"
            title="page.suspendedFinancialTransactions.col-refusalDate"
            width="160"
            cell={({ dataItem: { RejectionDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(RejectionDate)}</td>
            )}
            formatFilterCellData={(RejectionDate) => getTransformDateString(RejectionDate)}
          />
          <GridColumn field="userNamePost" title="page.suspendedFinancialTransactions.col-performer" width="160" />
        </Grid>
        {commentsHistory && (
          <CommentHistoryDialog
            visible={commentsHistory}
            commentsHistory={commentsHistory}
            onClose={() => {
              this.setState({ commentsHistory: null, currentPaymentId: null });
            }}
            currentPaymentId={currentPaymentId}
          />
        )}
      </>
    );
  }
}

export default withTranslate(UnlocksHistoryGrid);
