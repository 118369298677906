import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
const { TabPane } = Tabs;

class PageTabs extends PureComponent {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.any).isRequired,
    onAddTab: PropTypes.func,
    onRemoveTab: PropTypes.func,
  };

  state = {
    activeKey: '0',
  };

  // newTabIndex = this.props.tabs.length - 1;

  componentDidMount() {
    this.subscription = tabService.getTabs().subscribe((tab) => {
      const { onAddTab } = this.props;

      const activeKey = crypto.randomUUID();

      onAddTab(tab, `${activeKey}`);
      this.setState({ activeKey: `${activeKey}` });
      window.scrollTo(0, 0);
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.tabs !== this.props.tabs) {
  //     if (this.props.tabs.length < prevProps.tabs.length) {
  //       this.newTabIndex -= 1;
  //     }
  //   }
  // }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  // remove = targetKey => {
  //   const {onRemoveTab, tabs} = this.props
  //   let { activeKey } = this.state;
  //   let lastIndex;

  //   tabs.forEach((pane, i) => {
  //     if (pane.key === targetKey) {
  //       lastIndex = i - 1;
  //     }
  //   });

  //   const panes = tabs.filter(pane => pane.key !== targetKey);
  //   if (panes.length && activeKey === targetKey) {
  //     if (lastIndex >= 0) {
  //       activeKey = panes[lastIndex].key;
  //     } else {
  //       activeKey = panes[0].key;
  //     }
  //   }

  //   this.setState({ activeKey });
  //   onRemoveTab(panes);
  // };

  remove = (targetKey) => {
    let { activeKey } = this.state;
    const { tabs, onRemoveTab } = this.props;

    if (activeKey !== targetKey) {
      onRemoveTab(tabs.filter((pane) => pane.key !== targetKey));
      return;
    }

    const removeItemIndex = tabs.map(({ key }) => key).indexOf(targetKey);
    const currentActiveItemIndex = removeItemIndex === -1 || removeItemIndex === 0 ? 0 : removeItemIndex - 1;
    const filteredTabs = tabs.filter((pane) => pane.key !== targetKey);
    const currentActiveKey = filteredTabs[currentActiveItemIndex].key;

    this.setState({ activeKey: currentActiveKey || '0' });
    onRemoveTab(filteredTabs);
  };

  render() {
    const { tabs, translate } = this.props;
    const { activeKey } = this.state;

    return (
      <Tabs hideAdd onChange={this.onChange} activeKey={activeKey} type="editable-card" onEdit={this.onEdit}>
        {tabs.map((pane, i) => {
          return (
            <TabPane closable={pane.closable} tab={translate(pane.title)} key={pane.key || '0'} forceRender>
              {pane.content}
            </TabPane>
          );
        })}
      </Tabs>
    );
  }
}

export default withTranslate(PageTabs);
