import React, { PureComponent } from 'react';
import { Button, Card, Col, Modal, Row } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';

import { DEALER_FIELDS, PAYMENT_SYSTEM_FIELDS } from '../constants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../../../core/constants/configs.const';
import { renderGlobalError, renderInputWithLabel } from '../utils';
import LoadingPanel from '../../../../components/loader';

import { validateDifferent } from '../validator';

import './ModalRecalculationTransactions.scss';
import DataContext from '../DataContext';

class ModalRecalculationTransactions extends PureComponent {
  static contextType = DataContext;
  onSubmitValidate = () => {
    const { formState, onError, translate } = this.props;

    const fieldsToValidate = [
      { field: 'DealerProviderMin', translateKey: 'grids.dealerCommissionsChange.field-dealer-commission-min' },
      { field: 'DealerProviderMax', translateKey: 'grids.dealerCommissionsChange.field-dealer-commission-max' },
      { field: 'DealerClientMin', translateKey: 'grids.dealerCommissionsChange.field-client-commission-min' },
      { field: 'DealerClientMax', translateKey: 'grids.dealerCommissionsChange.field-client-commission-max' },
      { field: 'PaymentSystemProviderMin', translateKey: 'grids.dealerCommissionsChange.field-dealer-commission-min' },
      { field: 'PaymentSystemProviderMax', translateKey: 'grids.dealerCommissionsChange.field-dealer-commission-max' },
      { field: 'PaymentSystemClientMin', translateKey: 'grids.dealerCommissionsChange.field-client-commission-min' },
      { field: 'PaymentSystemClientMax', translateKey: 'grids.dealerCommissionsChange.field-client-commission-max' },
    ];

    return Object.entries(formState).reduce((acc, [key, value]) => {
      const currentValidator = validateDifferent(translate, fieldsToValidate);
      if (!currentValidator) return;
      const errorMessage = currentValidator(formState);
      onError('modal', errorMessage);
      return acc || errorMessage;
    }, '');
  };

  onSubmit = () => {
    const { formState } = this.props;
    const { dispatch, upDateGrid } = this.context;
    const formError = this.onSubmitValidate();
    if (formError) return;
    dispatch({ type: 'UPDATE_DATA', payload: formState });
    upDateGrid(formState);
  };

  componentDidUpdate = (prevProps) => {
    const { formState, formErrors, setErrorState } = this.props;
    const { formState: prevFormState } = prevProps;

    if (!prevFormState || formState !== prevFormState) {
      setErrorState({
        ...formErrors,
        modal: '',
      });
    }
  };

  render() {
    const {
      visible,
      closeModal,
      translate,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      onPaymentTypesSave,
      paymentTypes,
      selectPaymentTypes,
      isLoading,
    } = this.props;

    const isDisabledByErrors = !!Object.values(formErrors).filter((item) => item).length;
    return (
      <>
        <Modal
          visible={visible}
          title={translate('page.dealerCommissionsChange.modal-title')}
          onCancel={() => closeModal(false)}
          footer={[
            <Button
              type="primary"
              disabled={!selectPaymentTypes.length || isDisabledByErrors}
              key="add"
              onClick={() => this.onSubmit(formState, onError)}
            >
              {translate('core.buttonTitles.add')}
            </Button>,
            <Button key="back" onClick={() => closeModal(false)}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
          ]}
          className="ModalRecalculationTransactions"
        >
          <Row type="flex" className="ModalRecalculationTransactions-container">
            <Col span={24} md={24} lg={12}>
              <Card
                type="inner"
                title={translate('page.commissionsServices.dealer') + ':'}
                className="ModalRecalculationTransactions-card"
              >
                <Col>
                  {DEALER_FIELDS.map(({ label, fieldName, minValue, maxValue }) => {
                    return renderInputWithLabel(
                      formState,
                      onChangeFormState,
                      formErrors,
                      onError,
                      label,
                      fieldName,
                      minValue,
                      maxValue,
                      translate,
                    );
                  })}
                </Col>
              </Card>
            </Col>
            <Col span={24} md={24} lg={12}>
              <Card
                type="inner"
                title={translate('page.dealerCommissionsChange.card-title')}
                className="ModalRecalculationTransactions-card"
              >
                <Col>
                  {PAYMENT_SYSTEM_FIELDS.map(({ label, fieldName, minValue, maxValue }) => {
                    return renderInputWithLabel(
                      formState,
                      onChangeFormState,
                      formErrors,
                      onError,
                      label,
                      fieldName,
                      minValue,
                      maxValue,
                      translate,
                    );
                  })}
                </Col>
              </Card>
            </Col>
          </Row>
          {renderGlobalError(formErrors, 'modal')}
          <Col span={24} className="ModalRecalculationTransactions-selectRow">
            <Col className="ModalRecalculationTransactions-label">
              {translate('page.commissionsServices.paymentType')}
            </Col>
            <Col className="ModalRecalculationTransactions-select">
              <GridDropdown
                data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                selectItems={selectPaymentTypes}
                onSave={onPaymentTypesSave('selectPaymentTypes')}
                defaultTitle={translate('page.commissionsServices.choosePaymentType')}
              />
            </Col>
          </Col>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(ModalRecalculationTransactions);
