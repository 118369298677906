import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import findArray from '../../utils/findArrForCurrentPage';
import ModalWhiteListDelete from './modalWhiteListDelete';

const api = new ApiService();

class GridWhiteListAdminDelete extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('whiteListAdmin');
  }
  state = {
    isLoading: false,
    data: [],
    visible: false,
    selectedItems: [],
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
  }

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-whiteListAdminDelete');
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    try {
      const {
        item: { owners },
      } = this.props;
      if (!owners) return;
      const model = {
        OwnerId: owners,
      };
      this.toggleLoader(true);
      const data = await api.terminalsWhitelistAccountsList(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {
          // checkElement('whiteListAdminDelete-btn-disableChangeProfile',
          //   this.currentArray) &&
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModal}
            disabled={!selectedItems.length}
          >
            {translate('grids.profilesMainProfileList.modal-delete-profile-btn-submit')}
          </Button>
        }
      </Col>
    );
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  handleSelect = (selectedItems) => {
    console.log('selectedItems', selectedItems);
    this.setState({ selectedItems });
  };

  render() {
    const { isLoading, data, initialSort, visible, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="whiteListAdminDelete"
          permissionName="whiteListAdmin"
          selectedField="selected"
          fieldForSelection="WhitelistId"
          initialSort={initialSort}
          onSelect={this.handleSelect}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="TerminalId"
            title="grids.terminalsMainProfileList.column-terminalId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="180" filter="numeric" />

          <GridColumn field="ServiceName" title="grids.comission-services.column-service-name" width="200" />

          <GridColumn field="PhoneAccounts" title="grids.whiteListAdmin.column-phoneAccounts" width="270" />

          <GridColumn field="CardAccounts" title="grids.whiteListAdmin.column-cardAccounts" width="270" />

          <GridColumn field="OwnerId" title="grids.account.column-ownerId" width="150" filter="numeric" />

          <GridColumn field="OwnerName" title="grids.allEncashments.column-ownername" width="150" />

          {/* <GridColumn
            field="DateExec"
            title='grids.encashment.column-date'
            width="150"
            filter="date"
            format='dd.MM.yyyy HH:mm:ss'
          /> */}

          {/* <GridColumn
            field="OperationType"
            title='grids.whiteListAdmin.column-operationType'
            width="150"
          /> */}
          {/*
          <GridColumn
            field="UserIdName"
            title='grids.profilesMainProfileList.column-executor'
            width="150"
          /> */}

          <GridColumn field="Comment" title="grids.transactions.column-comment" width="150" />
        </Grid>
        {visible && (
          <ModalWhiteListDelete
            visible={visible}
            closeModal={this.closeModal}
            update={this.makeQuery}
            selectedItems={selectedItems}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridWhiteListAdminDelete);
