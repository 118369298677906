import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import TerminalsByDays from './TerminalsByDay';
import PageTabs from '../../components/tabber/tabber';
import ReportTerminalsByDays from './ReportTerminalsByDays';

class TerminalsByDayPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.dashboard.tab-terminalsByDays',
        content: <TerminalsByDays />,
        closable: false,
      },
    ],
  };
  onAddTab = ({ type, dayInfo }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    const { ownerId, terminalId, dateStart, dateEnd } = dayInfo;
    switch (type) {
      case 'terminalsByDays':
        tabs.push({
          title: `${translate('page.dashboard.tab-reportTerminalsByDays')}`,
          content: (
            <ReportTerminalsByDays ownerId={ownerId} terminalId={terminalId} dateStart={dateStart} dateEnd={dateEnd} />
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }
  };
  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}
export default withTranslate(TerminalsByDayPage);
