class TechnicalPartnersService {
  getERDPOValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (isNaN(+value) || !Number.isInteger(+value)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (value.length !== 8 && value.length !== 10) {
      return `${translate('page.suspendedFinancialTransactions.EDRPOUCodeError')}`;
    }

    return '';
  };

  getValidators = (translate) => ({
    Edrpo: this.getERDPOValidation(translate),
    Iban: (value) => '',
  });
}

export default new TechnicalPartnersService();
