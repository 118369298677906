const encashment = (translate, value) => {
  switch (value) {
    case 'encashment-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'encashment-singleCollectionRecalculation':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentHistory')}: ${translate('page.adminPanel.dropdown-button')}:
              ${translate('page.encashment.tab-singleEncashmentRecalc')}`;
    case 'encashment-printCheck':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentHistory')}: ${translate('page.adminPanel.dropdown-button')}:
              ${translate('grids.encashment.collectionCheck')}`;
    case 'encashment-printCheckEmail':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentHistory')}: ${translate('page.adminPanel.dropdown-button')}:
              ${translate('page.encashment.tab-encashment-check-email-cyrillic')}`;
    case 'encashmentHistory-btn-submit':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentHistory')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('owner.search')}`;
    case 'encashmentHistory-isShowDropdown':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentHistory')},
              ${translate(`page.adminPanel.dropdown-show`)}:
              ${translate('grids.allEncashments.column-collectionId')}`;
    case 'terminalsEncashment-isShowDropdown':
      return `${translate(`page.adminPanel.dropdown-show`)}:
              ${translate('grids.transactions.column-terminalId')}`;
    case 'terminalsEncashment-tab-encashmentHistory':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.encashment.tab-encashmentHistory')}`;
    case 'terminalsEncashment-tab-terminalCashList':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.encashment.tab-terminalCashList')}`;
    case 'terminalsEncashment-terminalRecalculation':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.encashment.tab-encashmentRecalcTernminal')}`;
    // case 'encashmentHistory-Active':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-active')}`;
    // case 'encashmentHistory-Address':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-address')}`;
    // case 'encashmentHistory-Amount':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-amount')}`;
    // case 'encashmentHistory-AmountActually':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-amountActually')}`;
    // case 'encashmentHistory-AmountBills':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-amountBills')}`;
    // case 'encashmentHistory-AmountDifference':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-amountDifference')}`;
    // case 'encashmentHistory-City_name':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-cityName')}`;
    // case 'encashmentHistory-City_name':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-cityName')}`
    // case 'encashmentHistory-CollectionId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-collectionId')}`;
    // case 'encashmentHistory-CountCash':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-countCash')}`;
    // case 'encashmentHistory-Currency':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-currency')}`;
    // case 'encashmentHistory-DatePost':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate('page.adminPanel.type-column')}:
    //           ${translate('grids.allEncashments.column-datePostNoUTC')}`;
    // case 'encashmentHistory-OwnerName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-ownername')}`;
    // case 'encashmentHistory-RegionName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-regionName')}`;
    // case 'encashmentHistory-TerminalId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-terminalId')}`;
    // case 'encashmentHistory-TerminalName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-terminalName')}`;
    // case 'encashmentHistory-UserId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-userId')}`;
    // case 'encashmentHistory-UserName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-userName')}`;
    // case 'encashmentHistory-UserNameClose':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-userNameClose')}`;
    // case 'encashmentHistory-UserNameExec':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-encashmentHistory')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.allEncashments.column-userNameExec')}`;
    // case 'terminalCashList-Amount':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-amount')}`;
    // case 'terminalCashList-CashBoxUsed':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-cashBoxUsed')}`;
    // case 'terminalCashList-IsCash':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashmentDetailsList.column-isCash')}`;
    // case 'terminalCashList-Currency':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-currency')}`;
    // case 'terminalCashList-Nominal':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-nominal')}`;
    // case 'terminalCashList-Quantity':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-quantity')}`;
    // case 'terminalCashList-TerminalId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.encashment.tab-terminalCashList')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminalCashList.column-quantity')}`;
    // case 'terminalsEncashment-Branch':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-branch')}`;
    // case 'terminalsEncashment-CashBoxCapacity':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-capacity')}`;
    // case 'terminalsEncashment-CoinBoxUsed':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-coinBoxUsed')}`;
    // case 'terminalsEncashment-CoinBoxRatio':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-coinBoxRatio')}`;
    // case 'terminalsEncashment-CashBoxQuantity':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-cashQuantity')}`;
    // case 'terminalsEncashment-CoinBoxQuantity':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-coinBoxQuantity')}`;
    // case 'terminalsEncashment-CashBoxUsed':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-сashBoxUsed')}`;
    // case 'terminalsEncashment-CityName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-city')}`;
    // case 'terminalsEncashment-CollectorName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-collector')}`;
    // case 'terminalsEncashment-Note':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('page.terminalSettings.field-notes')}`;
    // case 'terminalsEncashment-LastCollection':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-lastEncashment')}`;
    // case 'terminalsEncashment-OwnerName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.accountsLimit.column-owner')}`;
    // case 'terminalsEncashment-RegionName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.encashment.column-region')}`;
    // case 'terminalsEncashment-StatusName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('owner.column-status')}`;
    // case 'terminalsEncashment-TerminalAddress':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-street')}`;
    // case 'terminalsEncashment-TerminalId':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.transactions.column-terminalId')}`;
    // case 'terminalsEncashment-TerminalName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-name')}`;
    // case 'terminalsEncashment-TypeName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.wallets.column-type')}`;
    default:
      return value;
  }
};

export default encashment;
