import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { Modal, Button, Row, Col, message } from 'antd';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import './addLimit.scss';
import DateRange from '../../components/daterange';

const api = new ApiService();

class ModalAddLimit extends PureComponent {
  constructor(props) {
    super(props);
    const {
      item: { AccountId, PossibleDynamicLimitPercent },
    } = this.props;
    this.state = {
      visible: false,
      loading: false,
      range: {
        from: '',
        to: '',
      },
      accountId: AccountId,
      percent: PossibleDynamicLimitPercent,
      limit: 0.0,
    };
    if (!AccountId || !PossibleDynamicLimitPercent) return;
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async () => {
    let reqLimit = {};
    const {
      accountId,
      range: { from, to },
      percent,
      limit,
    } = this.state;
    const { translate } = this.props;

    try {
      this.setState({ loading: true });
      if (!reqLimit) return;
      reqLimit = {
        AccountId: accountId,
        DateEnd: to,
        DateStart: from,
        Percent: percent,
        Limit: limit,
      };
      const response = await api.addLimit(reqLimit);
      this.setState({ loading: false, visible: false });
      message.success(`${translate('grids.accountsLimit.success')}`, 2.5);
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  setRange = async ({ from, to }) => {
    this.setState({ visible: true });
    const { range } = this.state;
    range.from = from;
    range.to = to;
    await this.setState({ range });
  };

  onChangeLimit = (e) => {
    this.setState({ limit: e.target.value });
  };

  onChangePercent = (e) => {
    this.setState({ percent: e.target.value });
  };

  render = () => {
    const { visible, loading, percent, limit } = this.state;
    const {
      translate,
      item: { TypeName, OwnerName, PossibleDynamicLimit, PossibleDynamicLimitPercent },
    } = this.props;
    return (
      <div className="modal--add-limit">
        <Button type="primary" onClick={this.showModal}>
          {translate('grids.accountsLimit.button-setLimit')}
        </Button>
        <Modal
          title={translate('grids.accountsLimit.dialog-title')}
          visible={visible}
          centered
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" className="modal--add-limit-cancel" onClick={this.handleCancel}>
              {translate('grids.accountsLimit.button-cancel')}
            </Button>,
            <Button
              key="submit"
              className="modal--add-limit-set"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              {translate('grids.accountsLimit.button-set')}
            </Button>,
          ]}
        >
          <Row type="flex">
            <Col xs={24} md={24}>
              <p>
                {translate('grids.accountsLimit.partner')}: {OwnerName}, {TypeName}
              </p>
            </Col>
            <Col xs={24} md={24}>
              <p>
                {translate('grids.accountsLimit.possible-dynamic-limit')}: {PossibleDynamicLimit}
              </p>
            </Col>
            <Col md={24}>
              <p>
                {translate('grids.accountsLimit.possible-dynamic-limit-percent')}: {PossibleDynamicLimitPercent}
              </p>
            </Col>
            <Col className="popup-textbox-wrapper" sm={24} md={12}>
              <p>{translate('grids.accountsLimit.label-percent')}</p>
              {/* max={100} */}
              <NumericTextBox format="n2" value={percent} onChange={this.onChangePercent} />
            </Col>
            <Col className="popup-textbox-wrapper last" sm={24} md={12}>
              <p>{translate('grids.accountsLimit.label-limit')}</p>
              <NumericTextBox format="n2" onChange={this.onChangeLimit} value={limit} />
            </Col>
          </Row>
          <DateRange popup={true} shortFormat onRange={this.setRange} showDropdown={false} />
        </Modal>
      </div>
    );
  };
}

export default withTranslate(ModalAddLimit);
