import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../../contexts/localContext';
import { Button, message, Modal } from 'antd';
import { ApiService } from '../../../../../services';

const api = new ApiService();

class ModalCommissionsTechPartnersDelete extends PureComponent {
  state = {
    isLoading: false,
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  commissionsTechnicalPartnersDelete = async () => {
    const { translate, commissionsTechnicalPartners } = this.props;
    try {
      const { dataItem, onClose } = this.props;
      const model = {
        id: dataItem[0].Id,
        serviceId: dataItem[0].ServiceId,
        technicalPartnerId: dataItem[0].TechnicalPartnerId,
        constAmount: dataItem[0].ConstAmount,
        percent: dataItem[0].Percent,
        minAmount: dataItem[0].MinAmount,
        maxAmount: dataItem[0].MaxAmount,
        sodId: dataItem[0].SodId,
        psAccountId: dataItem[0].PsAccountId,
      };
      await this.toggleLoader(true);
      await api.commissionsTechnicalPartnersDelete(model);
      await this.toggleLoader(false);
      await onClose();
      await message.success(`${translate('page.commissionsTechnicalPartner.msg-successes-delete')}`, 2.5);
      await commissionsTechnicalPartners();
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      message.error(`${translate('page.commissionsTechnicalPartner.msg-error-update')}`, 2.5);
      showError(e);
    }
  };

  render() {
    const { translate, visible, onClose } = this.props;
    return (
      <Modal
        title={`${translate('page.commissionsTechnicalPartner.modal-title-delete')}`}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button type="danger" ghost onClick={this.commissionsTechnicalPartnersDelete}>
            {translate('core.buttonTitles.delete')}
          </Button>,
          <Button onClick={() => onClose()}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
        className="ModalCommissionsTechPartnersDelete"
      >
        <div className="ModalCommissionsTechPartnersDelete-container">
          {translate('page.commissionsTechnicalPartner.delete-techPartners')}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ModalCommissionsTechPartnersDelete);
