export const getRequiredValidators = (translate) => (value) => {
  return value ? '' : translate('page.commissionsServices.dropdownRequiredError');
};

export const getEmailValidator = (translate) => (email) => {
  const requiredError = getRequiredValidators(translate)(email);

  if (requiredError) return requiredError;

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase()) ? '' : translate('page.userProperties.emailValidateFormat');
};

export const getPhoneValidate = (translate) => (phone) => {
  const requiredError = getRequiredValidators(translate)(phone);
  if (requiredError) return requiredError;

  return phone.replace(/\D+/g, '').length < 10 ? translate('page.userProperties.phoneValidate') : '';
};
export const getValidateFieldLength = (translate) => (value) => {
  return value && value.length >= 4 && value.length <= 9 ? '' : translate('page.userProperties.fieldLength');
};

export const getCodeCollectorValidate = (translate) => (collectorCode) => {
  const requiredError = getRequiredValidators(translate)(collectorCode);
  const checkFieldValueLength = getValidateFieldLength(translate)(collectorCode);
  const checkingRegExp = /^[^.]\d{0,}$/g;
  if (requiredError) return requiredError;

  if (!collectorCode.match(checkingRegExp)) return translate('page.userProperties.checkIsNumber');

  if (checkFieldValueLength) return checkFieldValueLength;
};
