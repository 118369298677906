import React, { PureComponent } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Modal, Row } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { renderUserPropertiesInput } from './renderUserPropertiesInput';
import { getCodeCollectorValidate, getRequiredValidators } from './validators';
import { ApiService } from '../../../services';
import { COLLECTOR_FIELDS, COLLECTOR_GLOBAL_ERRORS } from './constant';
import { MODAL_INITIAL_VALUE } from './initialValues';

const api = new ApiService();

class CodeCollectorsModal extends PureComponent {
  renderUserPropertiesInputByProps = (name, label, type, isDisabled = false, span = 16, md = 16, maxLength = 9) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;
    return renderUserPropertiesInput(
      name,
      label,
      type,
      isDisabled,
      span,
      md,
      maxLength,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      translate,
      this.getValidators(),
    );
  };

  componentDidUpdate = (prevProps) => {
    const { formState, setErrorState, formErrors } = this.props;
    const { formState: prevFormState } = prevProps;

    if (!prevFormState || formState !== prevFormState) {
      setErrorState({
        ...formErrors,
        oldCodeCollectorMustNotMatchNewCodeCollector: '',
        newCodeCollectorsNotMatchConfirmed: '',
        newCodeCollectorsMoreThanEight: '',
      });
    }
  };

  validateFieldOnSubmit = () => {
    const { formState, onError, translate } = this.props;
    const { NewCodeCollector, RepeatCodeCollector } = formState;
    if (+NewCodeCollector !== +RepeatCodeCollector) {
      onError(
        'newCodeCollectorsNotMatchConfirmed',
        translate('page.userProperties.newCodeCollectorsNotMatchConfirmed'),
      );
      return translate('page.userProperties.newCodeCollectorsAndRepeatCodeCollectors');
    }
    return Object.entries(formState).reduce((acc, [key, value]) => {
      const currentValidator = getRequiredValidators(translate);
      const errorMessage = currentValidator(value);
      onError(key, errorMessage);
      return acc || errorMessage;
    }, '');
  };

  getValidators = () => {
    const { translate } = this.props;

    return {
      NewCodeCollector: getCodeCollectorValidate(translate),
      RepeatCodeCollector: getCodeCollectorValidate(translate),
    };
  };

  refuse = () => {
    const { onClose, setErrorState, setFormState } = this.props;
    setErrorState({});
    setFormState({ ...MODAL_INITIAL_VALUE });
    onClose();
  };

  onSubmit = async () => {
    const {
      setNewCollectorCodeForUser,
      formState: { NewCodeCollector },
      userId,
    } = this.props;

    const formError = this.validateFieldOnSubmit();
    if (formError) return;
    try {
      const model = {
        UserId: userId,
        OldCode: '',
        NewCode: NewCodeCollector.toString(),
      };

      await api.userCollectorCode(model);
      await setNewCollectorCodeForUser(NewCodeCollector);
      await this.refuse();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.refuse();
    }
  };

  renderFields = () =>
    COLLECTOR_FIELDS.map(({ name, label, inputProps = { span: 24, md: 24 } }, index) => (
      <Row className="CoderCollector-input" key={index}>
        {this.renderUserPropertiesInputByProps(name, label, 'hiddenPassword', '', inputProps.span, inputProps.md)}
      </Row>
    ));

  renderGlobalErrors = () => {
    const { formErrors } = this.props;

    return COLLECTOR_GLOBAL_ERRORS.map(
      (error) => formErrors[error] && <div className="CoderCollector-error">{formErrors[error]}</div>,
    );
  };

  render = () => {
    const { translate, visible, onClose, formErrors } = this.props;
    return (
      <Modal
        title={translate('grids.userProperties.modalChangeCodeCollector.title')}
        visible={visible}
        centered
        onCancel={this.refuse}
        footer={[
          <Button
            primary
            key="submit"
            onClick={this.onSubmit}
            disabled={!!Object.values(formErrors).filter((item) => item).length}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button primary key="back" onClick={this.refuse}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderFields()}
        {this.renderGlobalErrors()}
      </Modal>
    );
  };
}
export default withTranslate(CodeCollectorsModal);
