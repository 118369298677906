import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, message, Select, Row, Alert } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';
import './modalChangeProfile.scss';

const api = new ApiService();

const { Option } = Select;

class ModalChangeProfile extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    firstValue: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    profiles: [],
    selected: null,
    changeProfileErrorMessage: '',
  };

  componentDidMount() {
    this.getOwnerProfiles();

    // const changeProfileErrorMessage = this.checkByGoodPaydesk();
    //
    // console.log('changeProfileErrorMessage', changeProfileErrorMessage);
    // if (!changeProfileErrorMessage) {
    // }
    //
    // this.setState({ changeProfileErrorMessage });
  }

  getOwnerProfiles = async () => {
    const { selectedOwners, showError } = this.props;
    if (!selectedOwners.length) return;
    try {
      this.toggleLoader();
      const response = await api.getOwnerProfiles(selectedOwners[0]);
      this.setState({ loading: false, profiles: response });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  changeProfile = async () => {
    const { selected } = this.state;
    const { selectedTerminals, translate, closeModal, update, isIgnoreChangeCommissionEnabled } = this.props;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: selectedTerminals
          .filter(({ IsChangeCommissionEnabled }) => IsChangeCommissionEnabled || isIgnoreChangeCommissionEnabled)
          .map(({ TerminalId }) => TerminalId)
          .join(),
        ProfileId: +selected,
      };
      const response = await api.setCommissionsClientProfile(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('grids.commission-terminals.success')}`, 2.5);
      update();
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onSelectChange = (value) => {
    this.setState({ selected: value });
  };

  checkByGoodPaydesk = () => {
    const { selectedTerminals, translate } = this.props;
    console.log('selectedTerminals', selectedTerminals);

    const terminalsWithUnchangeableCommissionProfiles = selectedTerminals
      .filter(({ BrandId }) => BrandId !== 0)
      .map(({ TerminalId }) => TerminalId);
    if (terminalsWithUnchangeableCommissionProfiles.length) {
      return `${translate(
        'page.commission.changeProfileErrorMessagePrefix',
      )} ${terminalsWithUnchangeableCommissionProfiles.join(', ')} ${translate(
        'page.commission.changeProfileErrorMessagePostfix',
      )}`;
    }
    console.log('terminalsWithUnchangeableCommissionProfiles', terminalsWithUnchangeableCommissionProfiles);

    return '';
  };

  render() {
    const { translate, visible, closeModal, selectedTerminals, isIgnoreChangeCommissionEnabled } = this.props;
    const { loading, profiles, isLoading, changeProfileErrorMessage, selected } = this.state;

    const isDisabledByCommissionEnabled =
      selectedTerminals.every(({ IsChangeCommissionEnabled }) => !IsChangeCommissionEnabled) &&
      !isIgnoreChangeCommissionEnabled;

    const disabledForChangeTerminals = selectedTerminals.filter(
      ({ IsChangeCommissionEnabled }) => !IsChangeCommissionEnabled,
    );

    return (
      <>
        <Modal
          title={translate('page.commission.change-profile')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.changeProfile}
              disabled={changeProfileErrorMessage || !selected || isDisabledByCommissionEnabled}
            >
              {translate('page.commission.btn-submit')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>{translate('page.commission.change-profile-text')}</p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                defaultValue={translate('page.commission.withoutCommission')}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                showSearch
              >
                {profiles.map((profile, i) => (
                  <Option value={`${profile.ProfileId}`} key={`collector-number-${i.toString()}`}>
                    {`${profile.ProfileId} - ${profile.ProfileName}`}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24}>
              {changeProfileErrorMessage && (
                <p style={{ color: 'red', marginBottom: 0 }}>{changeProfileErrorMessage}!</p>
              )}
            </Col>

            {disabledForChangeTerminals.length && !isDisabledByCommissionEnabled && !isIgnoreChangeCommissionEnabled && (
              <div className="TerminalsCommissionChangeProfileModal-alertWrapper">
                <Alert
                  message={`${translate('page.commissionTerminals.forTerminals')} ${disabledForChangeTerminals
                    .map(({ TerminalId }) => TerminalId)
                    .join(',')} ${translate('page.commissionTerminals.profileChangeProhibited')}`}
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChangeProfile);
