import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import { withTranslate } from '../../../contexts/localContext';

class CellHour extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  getColor = () => {
    const { field, dataItem } = this.props;
    const value = dataItem[field];
    if (value >= 0 && value < 54) return '#C00000';
    if (value >= 55 && value <= 59) return '#FFA500';
    if (value === 60) return '#0000FF';

    return '';
  };

  getTime = (hour) => {
    if (hour === 23) {
      return `${hour} - 00`;
    }
    if (hour > 10) {
      return `${hour} - ${hour + 1}`;
    }

    return `0${hour} - 0${hour + 1}`;
  };

  render() {
    const { field, dataItem, mobile, className, style, handleClick, workDay, hour } = this.props;
    const value = dataItem[field];
    const { TerminalId } = dataItem;

    if (mobile) {
      return <>{value}</>;
    }

    return (
      <td
        className={className}
        style={{
          ...style,
          color: this.getColor(),
          zIndex: 4,
          cursor: 'pointer',
        }}
        onClick={() => {
          handleClick({ workDay, TerminalId, hour, time: this.getTime(hour) });
        }}
      >
        {value}
      </td>
    );
  }
}

export default withTranslate(CellHour);
