import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import Grid from '../../../components/grids/baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import CellBoolean from '../../account/cellBoolean';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import UpdateMFOModal from './UpdateMFOModal';

import './MFOGrid.scss';

const api = new ApiService();

class MFOGrid extends PureComponent {
  currentArray = findArray('MFODictionary');

  state = {
    isLoading: false,
    MFOList: [],
    modalVariant: '',
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchMFOList();
  }

  fetchMFOList = async () => {
    try {
      await this.setState({ isLoading: true });
      const MFOList = await api.getMFOs();
      this.setState({ MFOList, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="MFOGrid-toolbar">
        {checkElement('MFODictionary-add', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'add' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('MFODictionary-edit', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'edit' });
              }}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          </div>
        )}

        {checkElement('MFODictionary-importByNBU', this.currentArray) && (
          <div>
            <Button type="primary" onClick={this.handleDictionaryUpdate}>
              {translate('page.MFO.importByNBU')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  handleDictionaryUpdate = async () => {
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.MFORefresh();
      const MFOList = await api.getMFOs();
      message.success(`${translate(`page.MFO.dictionaryUpdatedSuccessfully`)}`, 2.5);
      this.setState({ MFOList, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, MFOList, modalVariant, selectedItems } = this.state;

    if (!checkElement('MFODictionary-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={MFOList}
          isLoading={isLoading}
          onRefresh={this.fetchMFOList}
          name="MFOList"
          toolbar={this.buildToolbar()}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          fieldForSelection="Id"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="Id" title="page.MFO.col-MFO" width="90" filter="numeric" />
          <GridColumn field="Name" title="page.MFO.col-name" width="400" />
          <GridColumn
            field="Active"
            title="page.MFO.col-activity"
            width="110"
            cell={(props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />}
          />
          <GridColumn field="PostIndex" title="page.MFO.col-ZIPCode" width="150" />
          <GridColumn field="Region" title="page.MFO.col-region" width="150" />
          <GridColumn field="FullAddress" title="page.MFO.col-address" width="400" />
          <GridColumn field="UserName" title="page.MFO.col-performer" width="160" />
          <GridColumn
            field="DatePost"
            title="page.MFO.col-changeDate"
            width="160"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
        </Grid>

        {modalVariant && (
          <UpdateMFOModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchMFOList}
            selectedItem={selectedItems[0]}
          />
        )}
      </>
    );
  }
}

export default withTranslate(MFOGrid);
