class ServiceSuppliersService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.suppliers) {
      return { supplierList: [], hasNextPage: false, hasPreviousPage: false };
    }
    const suppliers = data.data.suppliers;

    const supplierList = suppliers.items && suppliers.items.length ? suppliers.items : [];

    const pageInfo = suppliers.pageInfo;

    return {
      supplierList,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new ServiceSuppliersService();
