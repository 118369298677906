import React, { PureComponent } from 'react';

import CommissionsServicesGrid from './CommissionsServicesGrid';
import PageTabs from '../../components/tabber/tabber';
import CommissionFormMethodsContainer from '../../components/CommisionDetailsForm/CommissionFormMethodsContainer';
import ChoiceCommission from './ChoiceCommission';
import CommissionActionResults from './CommissionActionResults';
import FormStateContainer from '../../components/FormStateContainer';
import { INITIAL_VALUES } from './CopyCommission/copyCommission.const';
import CopyCommission from './CopyCommission';

class CommissionsServicesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.managementExternalCommissions.services',
          content: <CommissionsServicesGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'choiceCommission':
        tabs.push({
          title: 'page.commissionsServices.choiceCommissionsClient',
          content: (
            <CommissionFormMethodsContainer>
              <ChoiceCommission {...dataItem} />
            </CommissionFormMethodsContainer>
          ),
          closable: true,
          key: key,
        });
        break;

      case 'copyCommission':
        tabs.push({
          title: 'page.commissionsServices.copyingCommissions',
          content: (
            <FormStateContainer initialValues={INITIAL_VALUES}>
              <CopyCommission {...dataItem} />
            </FormStateContainer>
          ),
          closable: true,
          key: key,
        });
        break;

      case 'commissionActionResults':
        tabs.push({
          title: 'page.commissionsServices.commissionActionResults',
          content: <CommissionActionResults {...dataItem} />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default CommissionsServicesPage;
