import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import DealerCommissionsHistoryGrid from './DealerCommissionsHistoryGrid';
import PageTabs from '../../components/tabber/tabber';

class DealerCommissionsHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-dealerCommissionsHistory',
          content: <DealerCommissionsHistoryGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default withTranslate(DealerCommissionsHistory);
