export const MAKE_PAYMENT_DAY_OPTIONS = [
  { label: -1, value: -1 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
];

export const requiredFields = [
  {
    field: 'selectRecipient',
    selectType: true,
  },
  {
    field: 'selectContract',
    selectType: true,
  },
  {
    field: 'selectPaymentSystem',
    selectType: true,
  },
  {
    field: 'selectServiceOwner',
    selectType: true,
  },
  {
    field: 'SupplierNarrative',
  },
  {
    field: 'Iban',
  },
  {
    field: 'SupplierSettlementType',
  },
  {
    field: 'selectCodes',
    selectType: true,
  },
];

export const requiredFieldTranslations = {
  selectServiceOwner: 'page.bankDetails.service-owner',
  selectRecipient: 'page.bankDetails.recipient',
  selectContract: 'page.bankDetails.treaty',
  selectPaymentSystem: 'page.bankDetails.payment-system',
  SupplierNarrative: 'page.bankDetails.purpose-payment',
  Iban: 'page.bankDetails.iban',
  selectCodes: 'page.bankDetails.payment-code',
  SupplierSettlementType: 'page.bankDetails.type-settlement',
};
