import React, { PureComponent } from 'react';
import { TimePicker as CoreTimePicker, DateTimePicker as CoreDateTimePicker } from '@progress/kendo-react-dateinputs';

import './Picker.scss';

class Picker extends PureComponent {
  onHandleChange = (e) => {
    const { onChangeFormState, name } = this.props;
    const { value } = e.target;

    onChangeFormState(name, value);
  };

  render() {
    const { formState, name, type, ...restPickerProps } = this.props;

    const { [name]: value } = formState;

    const PickerComponent = type === 'time' ? CoreTimePicker : CoreDateTimePicker;

    return (
      <div className="CustomPicker">
        <PickerComponent
          value={value}
          className="CustomPicker-field"
          onChange={this.onHandleChange}
          {...restPickerProps}
        />
      </div>
    );
  }
}

export default Picker;
