import React, { PureComponent } from 'react';
import { formatDate } from '@telerik/kendo-intl';

import { withTranslate } from '../../../contexts/localContext';
import GridTerminalsOperabilityChoose from './gridTerminalsOperabilityChoose';
import PageTabs from '../../../components/tabber/tabber';
import GridTerminalsOperabilityDay from './gridTerminalOperabilityDay';
import GridTerminalsOperabilityEvents from '../terminalOperabilityDay/GridTerminalOperabilityEvents';
import GridCommunicationTerminalsPerDay from '../terminalOperabilityDay/gridCommunicationTerminalsPerDay';
import CommunicationMonitoringPerHour from '../terminalOperabilityDay/CommunicationMonitoringPerHour';
import GridTerminalsPeriod from './GridTerminalsPeriod';

const convertToISOFormat = (date) => formatDate(date, 'dd.MM.yyyy');

class PageTerminalsOperabilityPeriod extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = props;
    this.state = {
      tabs: [
        {
          title: `${translate('page.terminalOperabilityPeriod.tab-operability-choose')}`,
          content: <GridTerminalsOperabilityChoose />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = (itemProps, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    const { type } = itemProps;

    switch (type) {
      case 'terminalsPeriod':
        const { terminalsPeriodProps } = itemProps;
        const { currentMonth } = terminalsPeriodProps;
        tabs.push({
          title: `${translate('page.terminalOperabilityPeriod.tab-operaility-main')}
           ${currentMonth.toLowerCase()}`,
          content: <GridTerminalsPeriod {...terminalsPeriodProps} />,
          closable: true,
          key: key,
        });
        break;
      case 'day':
        const { model: dayModal, workDate: dayWorkDate, dataItem } = itemProps;
        tabs.push({
          title: `${translate('page.terminalOperabilityPeriod.tab-operaility-main')}
           ${convertToISOFormat(new Date(dayWorkDate))}`,
          content: <GridTerminalsOperabilityDay dataItem={dataItem} model={dayModal} WorkDate={dayWorkDate} />,
          closable: true,
          key: key,
        });
        break;
      case 'events':
        const { itemProps: eventProps } = itemProps;

        const { TerminalId } = eventProps;
        tabs.push({
          title: `${translate('page.dashboard.tab-events')} #${TerminalId}`,
          content: (
            <GridTerminalsOperabilityEvents
              itemProps={eventProps}
              permissionName="terminalOperabilityPeriod"
              gridPermissionName="terminalOperabilityPeriodEvents"
              searchButtonPerrmissionName="terminalOperabilityPeriodEvents-btn-submit"
            />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'monitoringDay':
        const {
          dayInfo: { fullDate, ownerId: OwnerId, terminalList: TerminalList },
        } = itemProps;
        const date = formatDate(fullDate, 'yyyy.MM.dd');
        tabs.push({
          title: `${translate('page.dashboard.tab-communicationMonitoring')} - ${date}`,
          content: (
            <GridCommunicationTerminalsPerDay
              name="communicationTerminalsPeriodPerDay-grid"
              permissionName="terminalOperabilityPeriod"
              ownerId={OwnerId}
              terminalList={TerminalList}
              dateStart={date}
            />
          ),

          closable: true,
          key: key,
        });
        break;
      case 'minutes':
        const {
          hourInfo: { workDay, TerminalId: terminalId, hour, time },
        } = itemProps;

        tabs.push({
          title: `${translate('page.dashboard.tab-communicationMonitoring')} ${time}`,
          content: <CommunicationMonitoringPerHour workDay={workDay} terminalId={terminalId} hour={hour} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageTerminalsOperabilityPeriod);
