import React, { PureComponent } from 'react';
import { Row, Col, Button } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import CommissionsService from '../../commission/Commission/commissions.service';
import { COMMISSIONS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { tabService } from '../../../services';
import CommissionDetailsForm from '../../../components/CommisionDetailsForm/CommisionDetailsForm/CommisionDetailsForm';

import './СhoiceCommission.scss';

const api = new ApiService();

class ChoiceCommissionModal extends PureComponent {
  state = {
    selectedPaymentTypes: [1],
    currentCommissionIds: [],
    isLoading: false,
    commissionsList: [],
    selectedCommission: null,
  };

  componentDidMount() {
    this.fetchCommissionsList();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchCommissionsList = async () => {
    await this.toggleLoader(true);
    const commissionsList = await CommissionsService.fetchCommissionsList();

    this.setState({ commissionsList });

    if (commissionsList && commissionsList.length) {
      this.setState({ currentCommissionIds: [commissionsList[0].CommissionId] }, () => {
        this.getCommissionDetailsById(commissionsList[0].CommissionId);
      });
    }

    await this.toggleLoader(false);
  };

  onCommissionsChange = (currentCommissionIds) => {
    this.setState({ currentCommissionIds }, () => {
      this.getCommissionDetailsById(currentCommissionIds[0]);
    });
  };

  getCommissionDetailsById = async (CommissionId) => {
    try {
      this.toggleLoader(true);
      const data = await api.getComissionServiceDetails(CommissionId);

      this.setState({ selectedCommission: data.length ? data[0] : null });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  onCommissionsClientSubmit = async () => {
    const { selectedServices, selectProfiles, paymentTypes } = this.props;
    const { selectedPaymentTypes, currentCommissionIds } = this.state;

    try {
      this.toggleLoader(true);

      const data = await api.postCommissionsClientReferenceSet({
        commissionId: currentCommissionIds[0],
        servicesList: selectedServices.filter(
          (service, index, selectedServices) => selectedServices.indexOf(service) === index,
        ),
        profilesList: selectProfiles,
        paymentTypesList: selectedPaymentTypes,
      });

      tabService.addTab({
        type: 'commissionActionResults',
        dataItem: {
          commissionActionResults: data,
          paymentTypes: paymentTypes,
        },
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  onPaymentTypesSave = (selectedPaymentTypes) => {
    this.setState({ selectedPaymentTypes });
  };

  renderToolbar = () => {
    const { paymentTypes, translate } = this.props;
    const { selectedPaymentTypes, currentCommissionIds, commissionsList } = this.state;
    const isAddButtonActive = currentCommissionIds.length && selectedPaymentTypes.length;

    return (
      <>
        <Row className="ChoiceCommission-toolbar">
          <Col span={24} md={16}>
            <Col span={24} className="ChoiceCommission-dropdown">
              <Col span={4} className="ChoiceCommission-label">
                {translate('page.commissionsServices.paymentType')}
              </Col>

              <Col span={18} offset={1}>
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  selectItems={selectedPaymentTypes}
                  onSave={this.onPaymentTypesSave}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                />
              </Col>
            </Col>

            <Col span={24}>
              <Col span={4} className="CommissionsServices-label">
                {translate('page.commissionsServices.commission')}
              </Col>

              <Col span={18} offset={1}>
                <GridDropdown
                  data={CommissionsService.normalizeCommissions(commissionsList)}
                  colConfig={COMMISSIONS_COLUMN_CONFIG}
                  selectItems={currentCommissionIds}
                  onSave={this.onCommissionsChange}
                  defaultTitle={translate('page.commission.selectCommission')}
                  isSingle
                />
              </Col>
            </Col>
          </Col>

          <Col span={24} md={8} className="ChoiceCommission-actions">
            <Button
              className="ChoiceCommission-button"
              type="primary"
              disabled={!isAddButtonActive}
              onClick={this.onCommissionsClientSubmit}
            >
              {translate('core.buttonTitles.add')}
            </Button>
            <Button
              className="ChoiceCommission-button"
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              {translate('core.buttonTitles.refuse')}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { isLoading, selectedCommission } = this.state;
    const { formState, ...rest } = this.props;

    return (
      <>
        <div className="ChoiceCommission">
          {this.renderToolbar()}{' '}
          <CommissionDetailsForm
            {...rest}
            formState={selectedCommission ? CommissionsService.normalizeCommission(selectedCommission) : formState}
            isFormDisabled
          />
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ChoiceCommissionModal);
