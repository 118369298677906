import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import LoadingPanel from '../../components/loader';
import { withTranslate } from '../../contexts/localContext';

import './DeleteModal.css';

class DeleteProfilesModal extends PureComponent {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    const {
      submitCallback,
      showError,
      messageSuccess,
      isReloadAfterSubmit,
      closeModal,
      hideSuccessfulMessage = true,
    } = this.props;
    await this.setState({ isLoading: true });

    try {
      await closeModal();
      await submitCallback();

      if (!hideSuccessfulMessage) {
        await message.success(messageSuccess, 2);
      }

      if (isReloadAfterSubmit) {
        window.location.reload();
      }
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, deleteContent, modalTitle } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        {...(modalTitle ? { title: modalTitle } : {})}
        zIndex="1053"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>,

          <Button key="submit" type="danger" ghost loading={isLoading} onClick={this.handleSubmit}>
            {translate('page.commission.btn-delete')}
          </Button>,
        ]}
      >
        <div className="DeleteModal-content">{deleteContent}</div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteProfilesModal);
