import { uppercaseFirstLetter } from '../../../../utils/uppercaseFirstLetter';
import { NORMALIZE_RESPONSE_FIELD_MAP } from './constans';

class SettingsAndEventsService {
  normalizeSettingsAndEventsService(events) {
    return events.length
      ? events.map((item) =>
          Object.entries(item).reduce(
            (acc, [key, val]) => ({
              ...acc,
              [NORMALIZE_RESPONSE_FIELD_MAP.get(key) || uppercaseFirstLetter(key)]: val,
            }),
            {},
          ),
        )
      : [];
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.terminalManagementList) {
      return { terminalEventListService: [], hasNextPage: false, hasPreviousPage: false };
    }
    const terminalEventList = data.data.terminalManagementList;
    const terminalEventListService =
      terminalEventList.items && terminalEventList.items.length
        ? this.normalizeSettingsAndEventsService(terminalEventList.items)
        : [];

    const pageInfo = terminalEventList.pageInfo;

    return {
      terminalEventListService,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}
export default new SettingsAndEventsService();
