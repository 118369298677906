export const translateSlugs = {
  commissionPercent: 'page.recalculationTransactions.field-suppliersCommissionPercent',
  commissionMin: 'page.recalculationTransactions.field-suppliersCommissionMin',
  commissionMax: 'page.recalculationTransactions.field-suppliersCommissionMax',
  commissionCalculationBase: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
  commissionCardPercent: 'page.recalculationTransactions.field-suppliersCommissionPercent',
  commissionCardMin: 'page.recalculationTransactions.field-suppliersCommissionMin',
  commissionCardMax: 'page.recalculationTransactions.field-suppliersCommissionMax',
  commissionCardCalculationBase: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
};
