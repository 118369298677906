class SuppliersUsersService {
  validateEmail = (translate) => (email) =>
    !email || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
      ? ''
      : translate('page.serviceSupplier.invalidEmail');

  getPurePhone = (phone) =>
    phone
      ? phone
          .split('')
          .filter((char) => !isNaN(+char) && char !== ' ')
          .join('')
      : '';

  validatePhone = (translate) => (phone) => {
    if (!phone) {
      return '';
    }

    const purePhone = this.getPurePhone(phone);

    if (purePhone.length !== 12 || purePhone.slice(0, 3) !== '380') {
      return translate('page.serviceSupplier.invalidPhone');
    }

    return '';
  };

  getValidators = (translate) => ({
    email: this.validateEmail(translate),
    phone: this.validatePhone(translate),
  });
}

export default new SuppliersUsersService();
