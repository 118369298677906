import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Modal } from 'antd';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class ModalDealerServiceStatus extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  getTypeButtonsGroup = () => {
    const { translate, dataItem, closeModal, modalType } = this.props;

    if (modalType === 'serviceStatusName') {
      const { ServiceStatusName } = dataItem;
      const buttonContent =
        ServiceStatusName === 'Неактивный' ? 'turnOn' : ServiceStatusName === 'Активный' ? 'turnOff' : '';
      return (
        <>
          <Button type="primary" key={buttonContent} onClick={this.updateServiceCommissionStatus}>
            {translate(`core.buttonTitles.${buttonContent}`)}
          </Button>
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>
        </>
      );
    }
    if (modalType === 'serviceStatusInactiveOrActive') {
      return (
        <>
          <Button type="primary" key="turnOn" onClick={() => this.changeStatusToInactiveOrActive('active')}>
            {translate('core.buttonTitles.turnOn')}
          </Button>
          <Button type="danger" key="turnOff" onClick={() => this.changeStatusToInactiveOrActive('inactive')}>
            {translate('core.buttonTitles.turnOff')}
          </Button>
        </>
      );
    }
  };
  renderButton = () => {
    return this.getTypeButtonsGroup();
  };

  changeStatusToInactiveOrActive = async (eventType) => {
    const { closeModal, update } = this.props;

    try {
      this.setState({ isLoading: true });
      const model = {
        ownerSeviceList: [...this.handlerChaneStatus()],
        serviceStatus: eventType === 'active' ? 1 : eventType === 'inactive' ? 2 : null,
      };
      await api.serviceCommissionChangeStatus(model);
      this.setState({ isLoading: false });
      update();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  changeStatusId = (statusId) => {
    return statusId === 1 ? 2 : statusId === 2 ? 1 : null;
  };

  handlerChaneStatus = () => {
    const { selectedItems } = this.props;
    return selectedItems.map(({ OwnerId, ServiceId, PaymentTypeId }) => ({
      ownerId: OwnerId,
      serviceId: ServiceId,
      paymentType: PaymentTypeId,
    }));
  };

  updateServiceCommissionStatus = async () => {
    const {
      dataItem: { OwnerId, ServiceStatusId, ServiceId, PaymentTypeId },
      closeModal,
      update,
    } = this.props;
    try {
      this.setState({ isLoading: true });
      const model = {
        ownerSeviceList: [
          {
            ownerId: OwnerId,
            serviceId: ServiceId,
            paymentType: PaymentTypeId,
          },
        ],
        serviceStatus: this.changeStatusId(ServiceStatusId),
      };
      await api.serviceCommissionChangeStatus(model);
      this.setState({ isLoading: false });
      update();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    const { modalType, closeModal, translate } = this.props;

    return (
      <>
        <Modal
          title={translate('page.modalDealerServiceStatus.modalTitle')}
          visible={modalType}
          onCancel={closeModal}
          footer={[this.renderButton()]}
        ></Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalDealerServiceStatus);
