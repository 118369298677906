import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, message, Modal } from 'antd';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import { MENU_PROFILES } from '../../../core/constants/configs.const';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();

class ModalTerminalsProfile extends PureComponent {
  state = {
    isLoading: false,
    menuProfilesTree: [],
    selectMenuProfile: [],
  };

  terminalsProfilesUpdate = async () => {
    const { selectMenuProfile } = this.state;
    const { terminalId, onClose, translate, reFetchData } = this.props;
    try {
      const model = [
        ...terminalId.map((terminalId) => ({
          terminalId,
          menuProfileId: selectMenuProfile[0],
        })),
      ];
      await this.setState({ isLoading: true });
      await api.terminalsProfilesUpdate(model);
      await this.setState({ isLoading: false });
      await onClose();
      await message.success(`${translate('page.terminalProfiles.msg-success')}`, 2.5);
      reFetchData();
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchMenuProfilesTreeGQL = async () => {
    try {
      await this.setState({ isLoading: true });
      const data = await api.getByGraphQl(
        `menuProfilesTree(order:{ profileId:ASC}){profileId, name, parentId, level, userId, userName, datePost, version}`,
      );
      if (data && data.data.menuProfilesTree.length) {
        const menuProfilesTree = data.data.menuProfilesTree;
        await this.setState({
          menuProfilesTree: menuProfilesTree,
          isLoading: false,
        });
      }
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  dropDownChangeCallback = (selectMenuProfile) => {
    this.setState({ selectMenuProfile });
  };

  componentDidMount() {
    this.fetchMenuProfilesTreeGQL();
  }

  render() {
    const { isLoading, menuProfilesTree, selectMenuProfile } = this.state;
    const { translate, visible, onClose } = this.props;

    return (
      <>
        <Modal
          title={translate('page.terminalProfiles.modal-title')}
          visible={visible}
          onCancel={() => onClose()}
          footer={[
            <Button type="primary" disabled={!selectMenuProfile.length} onClick={this.terminalsProfilesUpdate}>
              {translate('core.buttonTitles.change')}
            </Button>,
            <Button onClick={() => onClose()}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          <GridDropdown
            data={DropdownNormalizersService.normalizeMenuProfiles(menuProfilesTree)}
            colConfig={MENU_PROFILES}
            selectItems={selectMenuProfile}
            onSave={this.dropDownChangeCallback}
            defaultTitle={translate('page.terminalProfiles.dropdown-title')}
            isSingle
          />
        </Modal>

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalTerminalsProfile);
