import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Card, Checkbox, Col, message } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { BASE_SERVICES_COLUMN_CONFIG, OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';

import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import ModalRecalculationTransactions from './ModalRecalculationTransactions/ModalRecalculationTransactions';
import SupplierCommissionsForm from './SupplierCommissionsForm';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

import DataContext from './DataContext';

import './RecalculationTransactions.scss';
import { renderGlobalError } from './utils';
import { validateDifferent } from './validator';
import { INITIAL_VALUES_RECALCULATION_COMMISSIONS, RESET_FIELDS_SUPPLIER_COMMISSIONS } from './constants';
import { getCustomDate } from '../../../utils/getCustomDate';

class RecalculationTransactions extends PureComponent {
  static contextType = DataContext;
  constructor(props) {
    super(props);

    this.currentArray = findArray('recalculationTransactions');
  }

  state = {
    maxDate: new Date(),
    minDate: getCustomDate({ customDateMonth: new Date().getMonth() - 1, customDayOfMonth: 1 }),
    DealerCommissions: true,
    SuppliersCommissions: false,
  };

  onHandleChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  showMassageSuccessfully = (translate) =>
    message.success(`${translate('page.recalculationTransactions.submitSuccess')}`);

  showErrorMassage = () => {
    const { translate } = this.props;
    return message.error(`${translate('page.recalculationTransactions.error-is-enabled')}`);
  };

  onHandleClickSubmit = async () => {
    const { DealerCommissions, SuppliersCommissions } = this.state;
    const {
      recalculationDealerTransaction,
      recalculateSupplierCommissions,
      translate,
      setFormState,
      formState,
      isEnabled,
      toggleLoader,
      recalculateSideOperations,
    } = this.props;

    if (DealerCommissions && SuppliersCommissions) {
      try {
        if (!isEnabled) return this.showErrorMassage();
        await toggleLoader(true);
        await recalculationDealerTransaction();
        await recalculateSupplierCommissions();
        await recalculateSideOperations();
        await this.showMassageSuccessfully(translate);
        await setFormState({ ...INITIAL_VALUES_RECALCULATION_COMMISSIONS });
      } catch (e) {
        const { showError } = this.props;
        toggleLoader(false);
        showError(e);
      }
    } else if (DealerCommissions) {
      try {
        if (!isEnabled) return this.showErrorMassage();
        await toggleLoader(true);
        await recalculationDealerTransaction();
        await recalculateSideOperations(true);
        await this.showMassageSuccessfully(translate);
      } catch (e) {
        const { showError } = this.props;
        toggleLoader(false);
        showError(e);
      }
    } else if (SuppliersCommissions) {
      const isEmpty = this.handleSubmitRecalculateSupplierCommissions();
      if (isEmpty) return isEmpty;
      try {
        if (!isEnabled) return this.showErrorMassage();
        await toggleLoader(true);
        await recalculateSupplierCommissions();
        await recalculateSideOperations();
        await this.showMassageSuccessfully(translate);
        await setFormState({
          ...formState,
          ...RESET_FIELDS_SUPPLIER_COMMISSIONS,
        });
      } catch (e) {
        const { showError } = this.props;
        toggleLoader(false);
        showError(e);
      }
    }

    toggleLoader(false);
  };

  onHandleClick = (e) => {
    const { handleToggleModal } = this.props;
    const { name } = e.target;

    switch (name) {
      case 'add':
        return handleToggleModal(true);
      case 'delete':
        return this.removeDealerCommission();
      default:
        return;
    }
  };

  onSelectItems = (selectedItem) => {
    this.setState({ selectedItem });
  };
  removeDealerCommission = () => {
    const { selectedItem } = this.state;
    const { dataForGrid, dispatch } = this.context;
    dispatch({
      type: 'UPDATE_DATA',
      payload: dataForGrid.filter(
        (item) => !selectedItem.some((selected) => selected.PaymentType === item.PaymentType),
      ),
    });
  };

  componentDidUpdate = (prevProps) => {
    const { formState, formErrors, setErrorState } = this.props;
    const { formState: prevFormState } = prevProps;

    if (!prevFormState || formState !== prevFormState) {
      setErrorState({
        ...formErrors,
        supplier: '',
      });
    }
  };

  handleSubmitRecalculateSupplierCommissions = () => {
    const { formState, onError, translate } = this.props;
    const fieldsToValidate = [
      { field: 'SuppliersProviderMin', translateKey: 'page.recalculationTransactions.field-suppliersCommissionMin' },
      { field: 'SuppliersProviderMax', translateKey: 'page.recalculationTransactions.field-suppliersCommissionMax' },
      {
        field: 'SuppliersProviderMinForCards',
        translateKey: 'page.recalculationTransactions.field-suppliersCommissionMin',
      },
      {
        field: 'SuppliersProviderMaxForCards',
        translateKey: 'page.recalculationTransactions.field-suppliersCommissionMax',
      },
    ];

    return Object.entries(formState).reduce((acc, [key, value]) => {
      const currentValidator = validateDifferent(translate, fieldsToValidate);
      if (!currentValidator) return acc;
      const errorMessage = currentValidator(formState);
      onError('supplier', errorMessage);
      return acc || errorMessage;
    }, '');
  };

  setErrorsDatePicker = (state) => {
    const { onError, onChangeFormState, translate } = this.props;
    const { minDate, maxDate } = this.state;

    Object.entries(state).reduce((acc, [key, value]) => {
      if (key === 'DealerCommissionsDateStart' || key === 'SuppliersCommissionsDateStart') {
        onError(key, null);
        onChangeFormState(key, value);

        if (new Date(value) > new Date(maxDate)) {
          onError(key, `${translate('page.recalculationTransactions.error-date-start')}`);
        }

        if (new Date(value) < minDate) {
          onError(key, `${translate('page.recalculationTransactions.error-date-end')}`);
        }
      }

      if (key === 'DealerCommissionsDateEnd' || key === 'SuppliersCommissionsDateEnd') {
        onError(key, null);
        onChangeFormState(key, value);
        if (new Date(value) > maxDate) {
          onError(key, `${translate('page.recalculationTransactions.error-date-start')}`);
        }
        if (new Date(value) < minDate) {
          onError(key, `${translate('page.recalculationTransactions.error-date-end')}`);
        }
      }

      return acc;
    }, '');
  };

  checkRenderContent = (...props) => {
    const [DealerCommissions, SuppliersCommissions] = props;
    return DealerCommissions || (DealerCommissions && SuppliersCommissions) || SuppliersCommissions;
  };

  buildToolbar = () => {
    const { translate, formErrors, onHandleChangeItems, formState, services, selectServices, allOwners, selectOwners } =
      this.props;
    const { dataForGrid } = this.context;

    const { DealerCommissions, SuppliersCommissions, maxDate, minDate, selectedItem } = this.state;

    const isDisabledByErrors = !!Object.values(formErrors).some((item) => !!item);
    const isDisabledButton =
      (SuppliersCommissions && !(selectOwners.length && selectServices.length)) ||
      isDisabledByErrors ||
      (!SuppliersCommissions && !DealerCommissions) ||
      (DealerCommissions &&
        (!(dataForGrid.length && selectOwners.length && selectServices.length) || isDisabledByErrors));

    return (
      <Col className="RecalculationTransactions-toolbar">
        <div className="RecalculationTransactions-col">
          <div className="RecalculationTransactions-row">
            <Checkbox name="DealerCommissions" checked={DealerCommissions} onChange={this.onHandleChange}>
              {translate('page.recalculationTransactions.label-dealer-commissions')}
            </Checkbox>
          </div>
          <div className="RecalculationTransactions-row">
            <Checkbox name="SuppliersCommissions" checked={SuppliersCommissions} onChange={this.onHandleChange}>
              {translate('page.recalculationTransactions.label-suppliers-commissions')}
            </Checkbox>
          </div>
          {this.checkRenderContent(DealerCommissions, SuppliersCommissions) && (
            <div className="RecalculationTransactions-card">
              <div className="RecalculationTransactions-row justify-between">
                <div className="RecalculationTransactions-col RecalculationTransactions-col--datepicker">
                  <div className="RecalculationTransactions-row">
                    <div className="RecalculationTransactions-label">
                      {translate('page.recalculationTransactions.date-start')}
                    </div>
                    <div className="RecalculationTransactions-datepicker">
                      <DatePicker
                        format="yyyy.MM.dd"
                        value={formState.DealerCommissionsDateStart}
                        max={maxDate}
                        min={minDate}
                        name="DealerCommissionsDateStart"
                        onChange={(e) => this.setErrorsDatePicker({ ...formState, [e.target.name]: e.target.value })}
                      />
                      {formErrors.DealerCommissionsDateStart && (
                        <div className="RecalculationTransactions-error">{formErrors.DealerCommissionsDateStart}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="RecalculationTransactions-col RecalculationTransactions-col--datepicker">
                  <div className="RecalculationTransactions-row">
                    <div className="RecalculationTransactions-label">
                      {translate('page.recalculationTransactions.date-end')}
                    </div>
                    <div className="RecalculationTransactions-datepicker">
                      <DatePicker
                        value={formState.DealerCommissionsDateEnd}
                        format="yyyy.MM.dd"
                        max={maxDate}
                        min={minDate}
                        name="DealerCommissionsDateEnd"
                        onChange={(e) => this.setErrorsDatePicker({ ...formState, [e.target.name]: e.target.value })}
                      />
                      {formErrors.DealerCommissionsDateEnd && (
                        <div className="RecalculationTransactions-error">{formErrors.DealerCommissionsDateEnd}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="RecalculationTransactions-row">
                <div className="RecalculationTransactions-label">{translate('page.commissionsServices.service')}</div>
                <div className="RecalculationTransactions-dropDown">
                  <GridDropdown
                    data={DropdownNormalizersService.normalizeServices(services)}
                    colConfig={BASE_SERVICES_COLUMN_CONFIG}
                    selectItems={selectServices}
                    onSave={onHandleChangeItems('selectServices')}
                    isSingle
                    defaultTitle={translate('page.commissionsServices.chooseService')}
                  />
                </div>
              </div>
              {DealerCommissions && (
                <div className="RecalculationTransactions-row">
                  <div className="RecalculationTransactions-label">{translate('page.commissionsServices.dealer')}</div>
                  <div className="RecalculationTransactions-dropDown">
                    <GridDropdown
                      data={DropdownNormalizersService.normalizeOwners(allOwners)}
                      colConfig={OWNERS_COLUMN_CONFIG}
                      selectItems={selectOwners}
                      onSave={onHandleChangeItems('selectOwners')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {checkElement('recalculationTransactions-btnCreateCommission', this.currentArray) && (
          <Button type="primary" name="create" disabled={isDisabledButton} onClick={this.onHandleClickSubmit}>
            {translate('core.buttonTitles.create-transaction')}
          </Button>
        )}
      </Col>
    );
  };
  renderContent = () => {
    const { SuppliersCommissions } = this.state;
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    const formProps = {
      formState,
      onChangeFormState,
      formErrors,
      onError,
    };
    return (
      <>
        {SuppliersCommissions && (
          <div className="RecalculationTransactions-card">
            <div className="RecalculationTransactions-card-title">
              {translate('page.recalculationTransactions.modal-title-supplier-commissions')}
            </div>
            <div className="RecalculationTransactions-col SupplierCommissions-form">
              <SupplierCommissionsForm {...formProps} />
              {renderGlobalError(formErrors, 'supplier')}
            </div>
          </div>
        )}
      </>
    );
  };
  render() {
    const {
      translate,
      paymentTypes,
      selectPaymentTypes,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      setErrorState,
      onHandleChangeItems,
      handleToggleModal,
    } = this.props;

    const { isLoading, DealerCommissions, selectedItem } = this.state;
    const { visible } = this.props;
    const { dataForGrid, upDateGrid } = this.context;

    const formProps = {
      formState,
      onChangeFormState,
      formErrors,
      onError,
      setErrorState,
    };

    return (
      <>
        <div className="RecalculationTransactions-content">
          {this.buildToolbar()}
          {this.renderContent()}
        </div>
        {DealerCommissions && (
          <>
            <div className="RecalculationTransactions-dealerCommissions-title">
              {translate('page.recalculationTransactions.modal-title-dealer-commissions')}
            </div>
            <div className="RecalculationTransactions-btnGroup">
              <Button.Group onClick={this.onHandleClick}>
                {checkElement('recalculationTransactions-btnAdd', this.currentArray) && (
                  <Button type="primary" name="add">
                    {translate('core.buttonTitles.add')}
                  </Button>
                )}
                {checkElement('recalculationTransactions-btnDelete', this.currentArray) && (
                  <Button type="primary" name="delete" disabled={!(selectedItem && selectedItem.length)}>
                    {translate('core.buttonTitles.delete')}
                  </Button>
                )}
              </Button.Group>
            </div>
          </>
        )}

        {DealerCommissions && checkElement('recalculationTransactions-content', this.currentArray) ? (
          <Grid
            data={dataForGrid}
            name="RecalculationTransactionsGrid"
            toolbar={<></>}
            onRefresh={() => {}}
            fieldForSelection="PaymentType"
            onSelect={this.onSelectItems}
            isLoading={isLoading}
            multiSelected
          >
            <GridColumn field="selected" showAllSelected={true} width="50px" filterable={false} columnMenu={false} />
            <GridColumn field="PaymentType" title="grids.dealerCommissionsChange.column-payment-type" width="150px" />
            <GridColumn
              field="DealerProviderPercent"
              title="grids.dealerCommissionsChange.column-dealer-commission"
              width="250px"
            />
            <GridColumn
              field="DealerProviderMin"
              title="grids.dealerCommissionsChange.column-dealer-commission-min"
              width="250px"
            />
            <GridColumn
              field="DealerProviderMax"
              title="grids.dealerCommissionsChange.column-dealer-commission-max"
              width="250px"
            />
            <GridColumn
              field="DealerClientPercent"
              title="grids.dealerCommissionsChange.column-commission-per-client"
              width="250px"
            />
            <GridColumn
              field="DealerClientMin"
              title="grids.dealerCommissionsChange.column-commission-per-client-min"
              width="250px"
            />
            <GridColumn
              field="DealerClientMax"
              title="grids.dealerCommissionsChange.column-commission-per-client-max"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemProviderPercent"
              title="grids.dealerCommissionsChange.column-ps-dealer-commission"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemProviderMin"
              title="grids.dealerCommissionsChange.column-ps-dealer-commission-min"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemProviderMax"
              title="grids.dealerCommissionsChange.column-ps-dealer-commission-max"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemClientPercent"
              title="grids.dealerCommissionsChange.column-ps-commission-per-client"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemClientMin"
              title="grids.dealerCommissionsChange.column-ps-commission-per-client-min"
              width="250px"
            />
            <GridColumn
              field="PaymentSystemClientMax"
              title="grids.dealerCommissionsChange.column-ps-commission-per-client-max"
              width="250px"
            />
          </Grid>
        ) : null}

        {visible && (
          <DataContext.Consumer>
            {({ dataForGrid, dispatch, upDateGrid }) => (
              <ModalRecalculationTransactions
                {...formProps}
                visible={visible}
                paymentTypes={paymentTypes}
                selectPaymentTypes={selectPaymentTypes}
                closeModal={handleToggleModal}
                onPaymentTypesSave={onHandleChangeItems}
                upDateGrid={this.upDateGrid}
                key="ModalRecalculationTransactions"
              />
            )}
          </DataContext.Consumer>
        )}
      </>
    );
  }
}

export default withTranslate(RecalculationTransactions);
