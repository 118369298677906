import React, { PureComponent } from 'react';
import { Col, Input, Row } from 'antd';

import { ApiService } from '../../../services';
import CommissionDetailsForm from '../../../components/CommisionDetailsForm';
import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import '../../dashboard/dashboard.scss';
import './CommissionServiceDetails.scss';

const api = new ApiService();

class CommissionServiceDetails extends PureComponent {
  state = {
    isLoading: false,
    data: null,
  };

  toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    try {
      const {
        item: { CommissionId },
      } = this.props;
      if (!CommissionId) return;
      this.toggleLoader(true);
      const [data] = await api.getComissionServiceDetails(CommissionId);
      const { TimeFrom, TimeTo, ValidDateFrom, ValidDateTo, DatePost, ResultPercent, ...restData } = data;
      this.setState({
        data: {
          TimeFrom: new Date(TimeFrom),
          TimeTo: new Date(TimeTo),
          ValidDateFrom: new Date(ValidDateFrom),
          ValidDateTo: new Date(ValidDateTo),
          DatePost: new Date(DatePost),
          ResultPercent,
          ...restData,
          UserNamePost: '',
        },
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  render() {
    const { translate, formState, ...restProps } = this.props;
    const { data, isLoading } = this.state;

    return (
      <>
        <div className="CommissionServiceDetails dashboard-wrapper service-details-wrapper">
          <Row className="CommissionDetails-commissionNameRow">
            <Col span={8} xs={24} lg={12} className="CommissionDetails-commissionNameField">
              <Col span={24} xl={3} className="CommissionDetails-commissionNameLabel">
                {translate('page.comissions.comission')}
              </Col>
              <Col span={24} xl={20}>
                <Input value={data ? data.CommissionName : ''} disabled />
              </Col>
            </Col>
          </Row>

          <CommissionDetailsForm {...restProps} formState={data || formState} isFormDisabled />
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(CommissionServiceDetails);
