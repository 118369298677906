import React, { PureComponent } from 'react';
import { Button, Modal, Select } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../loader';

import './AdditionalTransactionsPropsModal.styled.scss';
import { ApiService } from '../../services';

const { Option } = Select;
const api = new ApiService();

class AdditionalTransactionsPropsModal extends PureComponent {
  state = {
    isLoading: false,
    transactionsProps: [],
    selectedTransactionProps: [],
  };
  fetchAllTransactionsProps = async () => {
    try {
      await this.setState({ isLoading: true });
      const allProps = await api.getTransactionItemsNames();

      await this.setState({
        isLoading: false,
        transactionsProps: allProps.map(({ Name }) => Name),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchAllTransactionsProps();
  }

  render() {
    const { translate, visible, closeModal, onSave } = this.props;
    const { isLoading, transactionsProps, selectedTransactionProps } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate('page.transactions.transactionWithAdditionalProps')}
        onCancel={closeModal}
        className="AdditionalTransactionsPropsModal"
        footer={[
          <Button
            onClick={() => {
              closeModal();
              onSave(selectedTransactionProps);
            }}
            type="primary"
            disabled={!selectedTransactionProps.length}
          >
            {translate('core.buttonTitles.select')}
          </Button>,
          <Button onClick={closeModal}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div className="AdditionalTransactionsPropsModal-row">
          <div>{translate('page.transactions.props')}</div>

          <Select
            value={selectedTransactionProps}
            placeholder={translate('page.transactions.selectProps')}
            mode="multiple"
            onChange={(selectedTransactionProps) => {
              this.setState({ selectedTransactionProps });
            }}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            optionFilterProp="children"
            showSearch
          >
            {transactionsProps.map((prop) => (
              <Option value={prop} key={prop}>
                {prop}
              </Option>
            ))}
          </Select>
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AdditionalTransactionsPropsModal);
