import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';

import { ApiService, tabService } from '../../../services';
import CellProgress from '../../monitorActivity/cellProgress';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import './gridTerminalOperabilityDay.scss';
import { REQUEST_METHOD_BY_REPORT_TYPE_MAP } from '../terminalsOperabilityPeriod/gridTerminalOperabilityDay/constants';

const api = new ApiService();

const WrappedCellProgress = (props) => <CellProgress {...props} />;

class GridTerminalsOperabilityDay extends Component {
  currentArray = findArray('terminalOperabilityDay');

  state = {
    isLoading: false,
    data: [],
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    try {
      this.toggleLoader(true);
      const {
        model: { OwnerId, ReportType, TerminalId, WorkDate },
      } = this.props;
      const requestMethod = REQUEST_METHOD_BY_REPORT_TYPE_MAP.get(ReportType) || api.terminalsEfficiencyByDay;

      const data = await requestMethod({
        OwnerId: OwnerId,
        TerminalId: TerminalId,
        WorkDate,
      });
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}></Col>;

  getWorkDates = (field, workDate) => {
    const convertedField = parseInt(field.match(/\d+/), 10);
    const WorkDate = new Date(workDate);
    const DateStart = new Date(WorkDate.setHours(convertedField, 0, 0, 0));
    const DateEnd = new Date(WorkDate.setHours(convertedField + 1, 0, 0, 0));
    return { DateStart, DateEnd };
  };

  createEventsTab = (props) => {
    const {
      model,
      model: { WorkDate },
    } = this.props;
    const { DateStart, DateEnd } = this.getWorkDates(props.field, WorkDate);
    if (!checkElement('terminalOperabilityDay-createEventsTab', this.currentArray)) return null;

    const itemProps = {
      model,
      DateStart,
      DateEnd,
      dataItem: props.dataItem,
    };
    return tabService.addTab({
      type: 'events',
      itemProps,
    });
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        selectedField="selected"
        toggleLoader={this.toggleLoader}
        name="terminalOperabilityDay"
        permissionName="terminalOperabilityDay"
      >
        <GridColumn
          field="TerminalId"
          title="grids.terminalOperabilityChoose.column-terminalId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="TerminalName" title="grids.terminalOperabilityChoose.column-name" width="250" />

        <GridColumn field="Address" title="grids.terminalOperabilityChoose.column-address" width="250" />

        <GridColumn field="StatusName" title="grids.terminalOperabilityChoose.column-status" width="200" />

        <GridColumn field="CityName" title="grids.terminalOperabilityChoose.column-city" width="250" />

        <GridColumn field="Branch" title="grids.terminals.column-branch" width="200" />

        <GridColumn field="Note" title="page.terminalSettings.field-notes" width="200" />

        <GridColumn
          field="Hour00"
          title="00-01"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour01"
          title="01-02"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour02"
          title="02-03"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour03"
          title="03-04"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour04"
          title="04-05"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour05"
          title="05-06"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour06"
          title="06-07"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour07"
          title="07-08"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour08"
          title="08-09"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour09"
          title="09-10"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour10"
          title="10-11"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour11"
          title="11-12"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour12"
          title="12-13"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour13"
          title="13-14"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour14"
          title="14-15"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour15"
          title="15-16"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour16"
          title="16-17"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour17"
          title="17-18"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour18"
          title="18-19"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour19"
          title="19-20"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour20"
          title="20-21"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour21"
          title="21-22"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour22"
          title="22-23"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn
          field="Hour23"
          title="23-00"
          width="80"
          filter="numeric"
          onClick={(props) => this.createEventsTab(props)}
        />

        <GridColumn field="NaTotal" title="grids.terminalOperabilityDay.totalRegime" width="150" filter="numeric" />

        <GridColumn
          field="WorkTotal"
          title="grids.terminalOperabilityChoose.operability"
          width="150"
          filter="numeric"
          cell={WrappedCellProgress}
        />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalsOperabilityDay);
