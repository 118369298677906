import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Checkbox, Modal, Radio } from 'antd';

import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import {
  ALL_SERVICES_WITHOUT_DELETE,
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_OWNER_COL_CONFIG,
  TERMINALS_LIST,
} from '../../../core/constants/configs.const';

import './ModalManagementPaymentTypes.scss';
import { ApiService } from '../../../services';
import Normalizer from '../normalizer';
import LoadingPanel from '../../../components/loader';

const statusRules = {
  name: 'StatusRules',
  items: [
    {
      label: '',
      val: 'paymentActivity',
    },
  ],
};

const api = new ApiService();

class ModalManagementPaymentTypes extends PureComponent {
  state = {
    allOwners: [],
    paymentTypes: [],
    services: [],
    terminals: [],
    selectPaymentTypes: [],
    selectServices: [],
    selectOwners: [1],
    selectTerminals: [],
    Allow: '',
    Deny: '',
    radioValue: '',
    StatusRules: false,
    checked: false,
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  handleErrors = (err) => {
    const { showError } = this.props;
    this.toggleLoader(false);
    showError(err);
  };

  fetchOwners = async () => {
    try {
      const allOwners = await api.owners();
      this.setState({ allOwners: Normalizer.normalizeData({ OwnerId: 0, ShortName: 'Всі дилери' }, allOwners) });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchServicesList = async () => {
    try {
      await this.toggleLoader({ isLoading: true });
      const services = await api.managementServices({ withDeleted: false });

      const normalizedServices = DropdownNormalizersService.normalizeServices(
        Normalizer.normalizedServicesWithParams({ ServiceId: -1, Name: 'Всі сервіси' }, services),
      );
      this.setState({
        services: normalizedServices,
      });
      await this.toggleLoader({ isLoading: false });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchPaymentType = async () => {
    const { selectOwners } = this.state;

    try {
      await this.toggleLoader(true);
      const paymentTypes = await api.ownersPaymentTypeList(selectOwners);

      await this.setState({ paymentTypes: DropdownNormalizersService.normalizePaymentTypeCode(paymentTypes) });
      await this.toggleLoader(false);
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchTerminalsByOwners = async () => {
    const { selectOwners } = this.state;

    try {
      const model = {
        Owners: selectOwners,
        HideDeleted: true,
      };
      const terminals = await api.terminalList(model);
      await this.setState({ terminals });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchAllData = async () => {
    await this.toggleLoader(true);
    await this.fetchOwners();
    await this.fetchTerminalsByOwners();
    await this.fetchPaymentType();
    await this.fetchServicesList();
    await this.toggleLoader(false);
  };

  async componentDidMount() {
    await this.fetchAllData();
  }

  handleChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  handleChangeCheckbox = (e, labelName = '') => {
    const { checked } = e.target;
    if (labelName === 'StatusRules') {
      this.setState({ StatusRules: checked });
    }
  };

  renderCheckbox = (translate, config) => (labelName) => {
    const { Allow, Deny, checked, StatusRules } = this.state;
    const { items, name } = config;

    return (
      <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center relative">
        <div className="ModalManagementPaymentTypes-label--checkbox">{translate(`${labelName}`)}</div>
        <div className="ModalManagementPaymentTypes-checkbox">
          {items.map(({ label, val }) => (
            <Checkbox name={label} value={val} onChange={(e) => this.handleChangeCheckbox(e, name)}>
              {label}
            </Checkbox>
          ))}
        </div>
      </div>
    );
  };

  dropDownChangeCallback = (name) => (value) => {
    this.setState({ [name]: value }, async () => {
      if (name === 'selectOwners') {
        this.setState({ selectServices: [], selectPaymentTypes: [] });
        await this.fetchPaymentType();
        await this.fetchTerminalsByOwners();
      }
    });
  };

  add = async () => {
    const { handleErrors, closeModal, showMessage } = this.props;
    const { selectPaymentTypes, selectServices, selectOwners, selectTerminals, radioValue, StatusRules } = this.state;
    try {
      const model = {
        RuleId: 0,
        OwnerId: selectOwners[0],
        TerminalId: selectTerminals.length ? selectTerminals[0] : 0,
        ServiceId: selectServices[0],
        PaymentType: selectPaymentTypes[0],
        Allowed: radioValue === 'Allow' ? true : radioValue === 'Deny' ? false : '',
        Enabled: StatusRules,
      };
      await api.terminalsServicesPaymentTypesAdd(model);
      await showMessage('msg-successfully-add');
      await closeModal(false);
    } catch (e) {
      handleErrors(e);
    }
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    const {
      selectPaymentTypes,
      selectServices,
      selectOwners,
      selectTerminals,
      allOwners,
      services,
      paymentTypes,
      terminals,
      isLoading,
      radioValue,
    } = this.state;
    return (
      <>
        <Modal
          title={translate('page.managementPaymentTypes.modal-title')}
          visible={visible}
          onCancel={() => closeModal(false)}
          footer={[
            <Button
              type="primary"
              onClick={this.add}
              disabled={
                !(selectOwners.length && selectServices.length && selectPaymentTypes.length && radioValue.length)
              }
            >
              {translate('core.buttonTitles.add')}
            </Button>,
            <Button onClick={() => closeModal(false)}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
          className="ModalManagementPaymentTypes"
        >
          <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
            <div className="ModalManagementPaymentTypes-label">{translate('page.commissionsServices.dealer')}</div>
            <div className={`ModalManagementPaymentTypes-dropDown`}>
              <GridDropdown
                data={DropdownNormalizersService.normalizeOwners(allOwners)}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={selectOwners}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
                onSave={this.dropDownChangeCallback('selectOwners')}
                isSingle
              />
            </div>
          </div>
          <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
            <div className="ModalManagementPaymentTypes-label">{translate('page.commissionsServices.service')}</div>
            <div className={`ModalManagementPaymentTypes-dropDown`}>
              <GridDropdown
                data={services}
                colConfig={ALL_SERVICES_WITHOUT_DELETE}
                selectItems={selectServices}
                defaultTitle={translate('page.commissionsServices.chooseService')}
                onSave={this.dropDownChangeCallback('selectServices')}
                isSingle
              />
            </div>
          </div>
          <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
            <div className="ModalManagementPaymentTypes-label">{translate('page.commissionsServices.paymentType')}</div>
            <div className={`ModalManagementPaymentTypes-dropDown`}>
              <GridDropdown
                data={paymentTypes}
                colConfig={PAYMENT_TYPES_OWNER_COL_CONFIG}
                selectItems={selectPaymentTypes}
                defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                onSave={this.dropDownChangeCallback('selectPaymentTypes')}
                isSingle
              />
            </div>
          </div>
          <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
            <div className="ModalManagementPaymentTypes-label">
              {translate('page.managementPaymentTypes.grid-dropdown-title')}
            </div>
            <div className="ModalManagementPaymentTypes-dropDown">
              <GridDropdown
                data={DropdownNormalizersService.normalizeTerminalList(terminals)}
                colConfig={TERMINALS_LIST}
                selectItems={selectTerminals}
                defaultTitle={translate('page.managementPaymentTypes.grid-dropdown-choose')}
                onSave={this.dropDownChangeCallback('selectTerminals')}
                isSingle
              />
            </div>
          </div>
          <div className="ModalManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center relative">
            <div className="ModalManagementPaymentTypes-label--checkbox">
              {translate('page.managementPaymentTypes.col-payment-activity')}
            </div>
            <div className="ModalManagementPaymentTypes-radioGroup">
              <Radio.Group value={radioValue} onChange={this.handleChangeRadio}>
                <Radio value={'Allow'} className="ModalManagementPaymentTypes-radio">
                  Allow
                </Radio>
                <Radio value={'Deny'} className="ModalManagementPaymentTypes-radio">
                  Deny
                </Radio>
              </Radio.Group>
            </div>
          </div>

          {this.renderCheckbox(translate, statusRules)('page.managementPaymentTypes.col-status-rules')}
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalManagementPaymentTypes);
