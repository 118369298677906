const whiteList = (translate, value) => {
  // ${translate('grids.whiteListAdmin.btn-control')}`)
  switch (value) {
    case 'whiteListAdmin-btn-control':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.whiteListAdmin.btn-control')}`;
    case 'whiteListAdmin-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'whiteListAdmin-ServiceId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.transactions.column-serviceId')}`;
    case 'whiteListAdmin-ServiceName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-service-name')}`;
    case 'whiteListAdmin-PhoneAccounts':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.whiteListAdmin.column-phoneAccounts')}`;
    case 'whiteListAdmin-CardAccounts':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.whiteListAdmin.column-cardAccounts')}`;
    case 'whiteListAdmin-OwnerId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.account.column-ownerId')}`;
    case 'whiteListAdmin-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-ownername')}`;
    case 'whiteListAdmin-DateExec':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashment.column-date')}`;
    case 'whiteListAdmin-OperationType':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.whiteListAdmin.column-operationType')}`;
    case 'whiteListAdmin-UserIdName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-executor')}`;
    case 'whiteListAdmin-Comment':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.transactions.column-comment')}`;
    case 'whiteListAdmin-btn-delete':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.whiteListAdmin.modal-control-h2')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.whiteListAdmin.modal-control-btn-delete')}`;
    case 'whiteListAdmin-btn-add':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.whiteListAdmin.modal-control-h2')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.whiteListAdmin.modal-control-btn-add')}`;

    // case 'whiteListAdminAdd-selected':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`page.adminPanel.type-column-selected`)}`;

    // case 'whiteListAdminAdd-OwnerName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-ownerName`)}`;

    // case 'whiteListAdminAdd-TerminalId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-terminalId`)}`;

    // case 'whiteListAdminAdd-TerminalName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-name`)}`;

    // case 'whiteListAdminAdd-NetworkContractName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-networkContract`)}`;

    // case 'whiteListAdminAdd-RegionName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-region`)}`;

    // case 'whiteListAdminAdd-CityName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-city`)}`;

    // case 'whiteListAdminAdd-TerminalAddress':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-address`)}`;

    // case 'whiteListAdminAdd-StatusName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-add')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalOperabilityChoose.column-status`)}`;

    // case 'whiteListAdminDelete-selected':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`page.adminPanel.type-column-selected`)}`;

    // case 'whiteListAdminDelete-TerminalId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.terminalsMainProfileList.column-terminalId`)}`;

    // case 'whiteListAdminDelete-ServiceId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.transactions.column-serviceId`)}`;

    // case 'whiteListAdminDelete-ServiceName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.comission-services.column-service-name`)}`;

    // case 'whiteListAdminDelete-PhoneAccounts':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.whiteListAdmin.column-phoneAccounts`)}`;

    // case 'whiteListAdminDelete-CardAccounts':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.whiteListAdmin.column-cardAccounts`)}`;

    // case 'whiteListAdminDelete-OwnerId':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.account.column-ownerId`)}`;

    // case 'whiteListAdminDelete-OwnerName':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.allEncashments.column-ownername`)}`;

    // case 'whiteListAdminDelete-Comment':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.whiteList.tab-delete')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate(`grids.transactions.column-comment`)}`;

    default:
      return value;
  }
};

export default whiteList;
