import React, { Component } from 'react';
import { Input, Row, Button, Select, Col, message, InputNumber, Radio } from 'antd';
import MaskedInput from 'antd-mask-input';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService, StorageService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import FormRow from '../FormRow/FormRow';
import { PHONE_CODE } from '../common.const';

import './createApplication.scss';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';

const api = new ApiService();
const { Option } = Select;
const { TextArea } = Input;
const EMAIL_REG_EXP = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

function checkLatinSymbols(str) {
  return /[a-zA-Z]/.test(str);
}

class CreateApplication extends Component {
  state = {
    isLoading: false,
    requestNumber: null,
    serviceName: null,
    narrative: null,
    amount: null,
    iban: null,
    isErrorIBAN: false,
    edrpou: null,
    isErrorEDRPOU: false,
    inn: null,
    isErrorInn: false,
    radioValue: 'edrpou',
    supplierName: null,
    supplierBank: null,
    dataPayment: null,
    submenuName: '',
    selectedOwners: [],
    selectOwnerData: [],
    selectedTerminals: null,
    selectTerminalData: [],
    screenPlacement: null,
    additionalInfo: null,
    isSuccess: false,
    userPhone: null,
    userEmail: null,
    allOwners: [],
    owners: [],
  };

  universalErrorMessage = (field) => {
    const { translate } = this.props;

    return `${translate('page.terminalSettings.universalErrorMessage-field')}
    ${field} ${translate('page.terminalSettings.universalErrorMessage-first')}
    ${translate('page.terminalSettings.universalErrorMessage-second')}.`;
  };

  onSubmitHandler = async () => {
    const {
      serviceName,
      iban,
      isErrorIBAN,
      edrpou,
      isErrorEDRPOU,
      inn,
      isErrorInn,
      submenuName,
      screenPlacement,
      requestNumber,
      narrative,
      amount,
      supplierName,
      supplierBank,
      dataPayment,
      selectedOwners,
      selectedTerminals,
      additionalInfo,
      userPhone,
      userEmail,
    } = this.state;
    const { showError, translate } = this.props;

    if (checkLatinSymbols(serviceName)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-serviceName-label'))}`,
        2,
      );
    }

    if (iban.length < 29 || isErrorIBAN) {
      return message.error(
        `${this.universalErrorMessage(translate('grids.addingAddressedPaymentApplications.column-iban'))}`,
        2,
      );
    }

    if ((edrpou && String(edrpou).length < 8) || (edrpou && isErrorEDRPOU)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-radio-EDRPOU-label'))}`,
        2,
      );
    }

    if ((inn && String(inn).length < 10) || (inn && isErrorInn)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-radio-INN-label'))}`,
        2,
      );
    }

    if (submenuName && checkLatinSymbols(submenuName)) {
      return message.error(
        `${this.universalErrorMessage(translate('grids.addingAddressedPaymentApplications.column-submenuName'))}`,
        2,
      );
    }

    if (submenuName && checkLatinSymbols(submenuName)) {
      return message.error(
        `${this.universalErrorMessage(translate('grids.addingAddressedPaymentApplications.column-submenuName'))}`,
        2,
      );
    }

    if (screenPlacement && checkLatinSymbols(screenPlacement)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-screenPlacement-label'))}`,
        2,
      );
    }

    if (userPhone.length !== 10) {
      return message.error(`${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-userPhone'))}`, 2);
    }

    if (!EMAIL_REG_EXP.test(userEmail)) {
      return message.error(`${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-userEmail'))}`, 2);
    }

    try {
      const okpo = inn || edrpou;

      this.toggleLoader();
      const model = {
        RequestId: null,
        RequestNumber: requestNumber,
        Name: serviceName,
        SupplierNarrative: narrative,
        Amount: amount,
        CommissionClient: '',
        CommissionDealer: 0,
        Okpo: okpo,
        Iban: iban,
        SupplierName: supplierName,
        SupplierBank: supplierBank,
        AdditionalParameters: dataPayment,
        SubmenuName: submenuName,
        Owners: selectedOwners && selectedOwners.length ? selectedOwners : [],
        Terminals: selectedTerminals && selectedTerminals.length ? selectedTerminals : [],
        ServiceReplaceName: screenPlacement,
        AdditionalInfo: additionalInfo,
        Commissions: [],
        Phone: `${PHONE_CODE}${userPhone}`,
        Email: userEmail,
      };
      await api.servicesCRMRequestAdd(model);
      await message.success(`${translate('page.adressedPaymentCreate.modal-create-success')}`, 3);
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({
      isLoading: !isLoading,
    }));
  };

  onChangeEventInput = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  onChangeRequestNumber = (requestNumber) => {
    this.setState({ requestNumber });
  };

  onChangeAmount = (amount) => {
    this.setState({ amount });
  };

  onChangeIban = (e) => {
    this.setState({ iban: e.target.value }, this.validateIBAN);
  };

  onChangeEDRPOU = (edrpou) => {
    this.setState({ edrpou }, this.validateEDRPOU);
  };

  onChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value }, () => this.nullifyPrevRadioInput(e.target.value));
  };

  nullifyPrevRadioInput = (value) => {
    const key = value === 'inn' ? 'edrpou' : 'inn';

    this.setState({ [key]: null });
  };

  onChangeINN = (inn) => {
    this.setState({ inn }, this.validateINN);
  };

  onChangeUserPhone = (e) => {
    this.setState({ userPhone: e.target.value.replace(/\D+/g, '') });
  };

  validateINN = async () => {
    const { showError } = this.props;
    const { inn } = this.state;
    if (String(inn).length !== 10) return;
    try {
      this.toggleLoader();
      const request = await api.validateInn(inn);
      this.setState({ isErrorInn: false });
      return request;
    } catch (error) {
      showError(error);
      this.setState({ isErrorInn: true });
    } finally {
      this.toggleLoader();
    }
  };

  validateEDRPOU = async () => {
    const { showError } = this.props;
    const { edrpou } = this.state;

    if (String(edrpou).length !== 8) return;

    try {
      this.toggleLoader();
      const request = await api.validateInn(edrpou);
      this.setState({ isErrorEDRPOU: false });
      return request;
    } catch (error) {
      showError(error);
      this.setState({ isErrorEDRPOU: true });
    } finally {
      this.toggleLoader();
    }
  };

  validateIBAN = async () => {
    const { showError } = this.props;
    const { iban } = this.state;

    if (iban.length !== 29) return;

    try {
      this.toggleLoader();
      const request = await api.validateIban(iban);
      this.setState({ isErrorIBAN: false });
      return request;
    } catch (error) {
      showError(error);
      this.setState({ isErrorIBAN: true });
    } finally {
      this.toggleLoader();
    }
  };

  getTerminals = async () => {
    const { selectedOwners } = this.state;
    const { showError } = this.props;

    if (!selectedOwners || !selectedOwners.length) return;

    try {
      this.toggleLoader();
      const model = {
        Owners: selectedOwners,
        HideDeleted: true,
      };
      const selectTerminalData = await api.getTerminalsByOwner(model);
      this.setState({
        selectTerminalData: selectTerminalData.map((el) => {
          return el.TerminalId;
        }),
        selectedTerminals: null,
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  onChangeSelectedTerminals = (selectedTerminals) => {
    this.setState({ selectedTerminals });
  };

  onOwner = (selectedOwners) => {
    this.setState({ selectedOwners }, this.getTerminals);
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  renderRadioWidthNumberInput = ({ radioProps, numberInoutProps }) => {
    const { translate } = this.props;

    return (
      <Col sm={24} lg={12}>
        <Row className="createApplication-radio-group-subrow" align="middle" type="flex" gutter={24}>
          <Radio value={radioProps.value}>
            <p className="createApplication-elem-label">{translate(radioProps.labelTranslateSlug)}:</p>
          </Radio>

          <InputNumber {...numberInoutProps} />
        </Row>
      </Col>
    );
  };

  render() {
    const {
      isLoading,
      requestNumber,
      serviceName,
      narrative,
      amount,
      iban,
      edrpou,
      inn,
      radioValue,
      supplierName,
      supplierBank,
      dataPayment,
      submenuName,
      selectedTerminals,
      selectTerminalData,
      screenPlacement,
      additionalInfo,
      userPhone,
      userEmail,
      allOwners,
      selectedOwners,
    } = this.state;
    const { translate } = this.props;

    const isPaymentExist = edrpou || inn;
    const isButtonActive =
      serviceName &&
      narrative &&
      iban &&
      isPaymentExist &&
      supplierName &&
      supplierBank &&
      dataPayment &&
      userPhone &&
      userEmail;

    const radioWidthNumberInputProps = [
      {
        radioProps: {
          value: 'edrpou',
          labelTranslateSlug: 'page.adressedPaymentCreate.field-radio-EDRPOU-label',
        },
        numberInoutProps: {
          placeholder: translate('page.adressedPaymentCreate.field-radio-EDRPOU-placeholder'),
          maxLength: 8,
          value: edrpou,
          onChange: this.onChangeEDRPOU,
          disabled: radioValue === 'inn',
        },
      },
      {
        radioProps: {
          value: 'inn',
          labelTranslateSlug: 'page.adressedPaymentCreate.field-radio-INN-label',
        },
        numberInoutProps: {
          placeholder: translate('page.adressedPaymentCreate.field-radio-INN-placeholder'),
          maxLength: 10,
          value: inn,
          onChange: this.onChangeINN,
          disabled: radioValue === 'edrpou',
        },
      },
    ];

    return (
      <>
        <div className="createApplication--wrapper">
          <div className="createApplication--subwrapper">
            <Row
              className="createApplication--header-row no-margin"
              type="flex"
              align="middle"
              gutter={24}
              style={{ flexWrap: 'wrap', marginTop: '10px' }}
            >
              {
                <Button
                  type="primary"
                  className="createApplication--btn-save"
                  onClick={this.onSubmitHandler}
                  disabled={!isButtonActive}
                >
                  {translate('cell-dropdown.create')}
                </Button>
              }
            </Row>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-requestNumber-label"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-requestNumber-tooltip"
            >
              <InputNumber
                placeholder={translate('page.adressedPaymentCreate.field-requestNumber-placeholder')}
                className="createApplication-elem-with-tooltip"
                value={requestNumber}
                onChange={this.onChangeRequestNumber}
                maxLength={4}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-serviceName-label"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-serviceName-tooltip"
              isFieldRequired
            >
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-serviceName-placeholder')}
                value={serviceName}
                name="serviceName"
                onChange={this.onChangeEventInput}
                maxLength={120}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-narrative-label"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-narrative-tooltip"
              isFieldRequired
            >
              <TextArea
                placeholder={translate('page.adressedPaymentCreate.field-narrative-placeholder')}
                value={narrative}
                name="narrative"
                onChange={this.onChangeEventInput}
                maxLength={4000}
                rows={4}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-amount-label"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-amount-tooltip"
            >
              <InputNumber
                placeholder={translate('page.adressedPaymentCreate.field-amount-placeholder')}
                value={amount}
                onChange={this.onChangeAmount}
                min={0.01}
                max={4999}
                step="0.01"
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="grids.addingAddressedPaymentApplications.column-iban"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-iban-tooltip"
              isFieldRequired
            >
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-iban-placeholder')}
                value={iban}
                name="iban"
                onChange={this.onChangeIban}
                maxLength={29}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.radio-label"
              labelColumnLarge={8}
              fieldColumnLarge={16}
              isFieldRequired
            >
              <Radio.Group value={radioValue} onChange={this.onChangeRadio} className="createApplication-radio-group">
                <Row className="createApplication-radio-group-row" align="middle" type="flex" gutter={24}>
                  {radioWidthNumberInputProps.map(this.renderRadioWidthNumberInput)}
                </Row>
              </Radio.Group>
            </FormRow>

            <FormRow labelTranslateSlug="page.adressedPaymentCreate.field-supplierName-label" isFieldRequired>
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-supplierName-placeholder')}
                value={supplierName}
                name="supplierName"
                onChange={this.onChangeEventInput}
                maxLength={38}
              />
            </FormRow>

            <FormRow labelTranslateSlug="page.adressedPaymentCreate.field-supplierBank-label" isFieldRequired>
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-supplierBank-placeholder')}
                value={supplierBank}
                name="supplierBank"
                onChange={this.onChangeEventInput}
                maxLength={73}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-dataPayment-label"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-dataPayment-placeholder"
              isFieldRequired
            >
              <TextArea
                placeholder={translate('page.adressedPaymentCreate.field-dataPayment-placeholder')}
                value={dataPayment}
                rows={3}
                name="dataPayment"
                onChange={this.onChangeEventInput}
                maxLength={4000}
              />
            </FormRow>

            <FormRow labelTranslateSlug="page.adressedPaymentCreate.field-submenuName-label">
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-submenuName-placeholder')}
                value={submenuName}
                name="submenuName"
                onChange={this.onChangeEventInput}
                maxLength={120}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-userEmailLabel"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-userEmail-tooltip"
              isFieldRequired
            >
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-userEmail-placeholder')}
                value={userEmail}
                name="userEmail"
                onChange={this.onChangeEventInput}
                maxLength={255}
              />
            </FormRow>

            <FormRow
              labelTranslateSlug="page.adressedPaymentCreate.field-userPhoneLabel"
              tooltipTitleTranslateSlug="page.adressedPaymentCreate.field-userPhone-tooltip"
              isFieldRequired
            >
              <MaskedInput
                mask="( 000 ) - 000 - 00 - 00"
                addonBefore={`+${PHONE_CODE}`}
                className="createApplication-elem-with-tooltip"
                value={userPhone}
                onChange={this.onChangeUserPhone}
              />
            </FormRow>

            <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
              <p className="createApplication-additionalParams-title">
                {translate('page.adressedPaymentCreate.additionalParams-title')}
              </p>
            </Row>

            <FormRow labelTranslateSlug="grids.allEncashments.column-ownername">
              <GridDropdown
                data={DropdownNormalizersService.normalizeOwners(allOwners)}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={selectedOwners}
                onSave={this.onOwner}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
              />
            </FormRow>

            <FormRow labelTranslateSlug="dropdowns.terminal.label-title">
              <Select
                className="createApplication--select"
                {...(selectedTerminals && selectedTerminals.length ? { value: selectedTerminals } : {})}
                onChange={this.onChangeSelectedTerminals}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                filterOption={(input, option) =>
                  JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="children"
                showSearch
                mode="multiple"
              >
                {selectTerminalData.map((el) => {
                  return (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  );
                })}
              </Select>
            </FormRow>

            <FormRow labelTranslateSlug="page.adressedPaymentCreate.field-screenPlacement-label">
              <Input
                placeholder={translate('page.adressedPaymentCreate.field-screenPlacement-placeholder')}
                value={screenPlacement}
                name="screenPlacement"
                onChange={this.onChangeEventInput}
                maxLength={120}
              />
            </FormRow>

            <FormRow labelTranslateSlug="page.adressedPaymentCreate.field-additionalInfo-label">
              <TextArea
                placeholder={translate('page.adressedPaymentCreate.field-additionalInfo-placeholder')}
                value={additionalInfo}
                name="additionalInfo"
                onChange={this.onChangeEventInput}
                rows={8}
              />
            </FormRow>
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(CreateApplication);
