const TemplateTerminals = (translate, value) => {
  switch (value) {
    case 'templateTerminals-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'templateTerminals-editItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.save`)}`;
    case 'templateTerminals-addItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.add`)}`;
    case 'templateTerminals-deleteItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    default:
      return value;
  }
};

export default TemplateTerminals;
