import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService } from '../../../../services';
import { Button, Input, Row } from 'antd';

import ModalCommissionsTechPartners from './Modals/ModalCommissionsTechPartnerAdd';
import FormStateContainer from '../../../../components/FormStateContainer';
import { INITIAL_VALUES_TECH_PARTNERS } from './Modals/constants';
import ModalCommissionsTechPartnersChange from './Modals/ModalCommissionsTechPartnersChange';

import './CommissionsTechnicalPartner.scss';
import ModalCommissionsTechPartnersDelete from './Modals/ModalCommissionsTechPartnersDelete';

const api = new ApiService();
const { TextArea } = Input;

class CommissionsTechnicalPartner extends PureComponent {
  state = {
    commissionsTechnicalPartner: [],
    isVisible: '',
    selectedItems: [],
    isLoading: false,
  };

  commissionsTechnicalPartners = async () => {
    const {
      dataItem: { ServiceId },
    } = this.props;
    try {
      await this.setState({ isLoading: true });
      const commissionsTechnicalPartner = await api.commissionsTechnicalPartners(ServiceId);
      await this.setState({ commissionsTechnicalPartner });
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidMount() {
    this.commissionsTechnicalPartners();
  }
  onHandleClickOpenModal = (e) => {
    const { name } = e.target;

    switch (name) {
      case 'add':
        this.setState({ isVisible: name });
        break;
      case 'change':
        this.setState({ isVisible: 'change' });
        break;
      case 'delete':
        this.setState({ isVisible: 'delete' });
        break;
    }
  };
  onCloseModal = () => {
    this.setState({ isVisible: '' });
  };
  createToolbar = () => {
    const { selectedItems } = this.state;
    const { dataItem, translate } = this.props;

    return (
      <Row className="CommissionsTechnicalPartner-toolbar">
        <div className="CommissionsTechnicalPartner-row">
          <div className="CommissionsTechnicalPartner-label">{translate('page.supplierCommissions.serviceId')}</div>
          <Input disabled value={dataItem.ServiceId} />
        </div>
        <div className="CommissionsTechnicalPartner-row">
          <div className="CommissionsTechnicalPartner-label">{translate('page.supplierCommissions.service-name')}</div>
          <TextArea disabled value={dataItem.Name} autoSize />
        </div>
        <Button.Group className="CommissionsTechnicalPartner-btnGroup" onClick={this.onHandleClickOpenModal}>
          <Button type="primary" name="add">
            {translate('core.buttonTitles.add')}
          </Button>
          <Button type="primary" name="change" disabled={!selectedItems.length}>
            {translate('core.buttonTitles.change')}
          </Button>
          <Button type="primary" name="delete" disabled={!selectedItems.length}>
            {translate('core.buttonTitles.delete')}
          </Button>
        </Button.Group>
      </Row>
    );
  };
  onSelectItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { commissionsTechnicalPartner, isLoading, isVisible, selectedItems } = this.state;
    const { dataItem } = this.props;

    return (
      <>
        <Grid
          toolbar={this.createToolbar()}
          data={commissionsTechnicalPartner}
          name="commissionsTechnicalPartnerGrid"
          fieldForSelection="TechnicalPartnerId"
          onSelect={this.onSelectItems}
          isLoading={isLoading}
        >
          <GridColumn field="selected" width="50px" filterable={false} columnMenu={false} />
          <GridColumn
            field="TechnicalPartnerName"
            width="200px"
            title="grids.commissionsTechnicalPartner.col-tech-partner"
          />
          <GridColumn field="ConstAmount" width="150px" title="grids.commissionsTechnicalPartner.col-fixed-amount" />
          <GridColumn field="Percent" width="150px" title="grids.commissionsTechnicalPartner.col-percent" />
          <GridColumn field="MinAmount" width="150px" title="grids.commissionsTechnicalPartner.col-minimum-amount" />
          <GridColumn field="MaxAmount" width="150px" title="grids.commissionsTechnicalPartner.col-maximum-amount" />
          <GridColumn field="SodName" width="150px" title="grids.commissionsTechnicalPartner.col-service-owner" />
          <GridColumn field="PsAccount" width="150px" title="grids.commissionsTechnicalPartner.col-account-PS" />
        </Grid>

        {isVisible === 'add' && (
          <FormStateContainer initialValues={INITIAL_VALUES_TECH_PARTNERS}>
            <ModalCommissionsTechPartners
              visible={!!isVisible.length}
              onClose={this.onCloseModal}
              dataItem={dataItem}
              commissionsTechnicalPartners={this.commissionsTechnicalPartners}
            />
          </FormStateContainer>
        )}
        {isVisible === 'change' && (
          <FormStateContainer initialValues={INITIAL_VALUES_TECH_PARTNERS}>
            <ModalCommissionsTechPartnersChange
              visible={!!isVisible.length}
              onClose={this.onCloseModal}
              dataItem={selectedItems}
              commissionsTechnicalPartners={this.commissionsTechnicalPartners}
            />
          </FormStateContainer>
        )}

        {isVisible === 'delete' && (
          <ModalCommissionsTechPartnersDelete
            visible={!!isVisible.length}
            onClose={this.onCloseModal}
            dataItem={selectedItems}
            commissionsTechnicalPartners={this.commissionsTechnicalPartners}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CommissionsTechnicalPartner);
