class SuspendedFinancialTransactionsGridService {
  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate > 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = new Date(2007, 0, 1);

    if (minimumSelectedDate - startDate > 0 || minimumSelectedDate - endDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    const FOUR_MONTH_AGO_BY_END_DATE = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth() - 4,
      new Date(endDate).getDate(),
    );

    if (FOUR_MONTH_AGO_BY_END_DATE - startDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  getAccountIdValidator = (translate) => (value) => {
    if (!value) {
      return null;
    }

    if (!Number.isInteger(+value) || `${value}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    if (value < 1000 || value > 9999999999999999) {
      return `${translate('page.suspendedFinancialTransactions.accountIdError')}`;
    }

    return null;
  };

  getEDRPOUValidator = (translate) => (value) => {
    if (!value) {
      return null;
    }

    if (!Number.isInteger(value) || `${value}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    const valueLength = `${value}`.length;

    if (valueLength !== 8 && valueLength !== 10) {
      return `${translate('page.suspendedFinancialTransactions.EDRPOUCodeError')}`;
    }

    return null;
  };

  getIBANValidator = (translate) => (value) => {
    if (!value) {
      return null;
    }

    if (!value || value.length !== 29) {
      return `${translate('page.suspendedFinancialTransactions.IBanAmountError')}`;
    }

    if (value.slice(0, 2) !== 'UA' || !Number.isInteger(+value.slice(2)) || `${value.slice(2)}`.indexOf('.') > -1) {
      return `${translate('page.suspendedFinancialTransactions.IBanFormatError')}`;
    }

    return null;
  };

  getValidators = (translate) => {
    return {
      accountId: this.getAccountIdValidator(translate),
      EDRPOU: this.getEDRPOUValidator(translate),
      IBAN: this.getIBANValidator(translate),
    };
  };
}

export default new SuspendedFinancialTransactionsGridService();
