class BanknotesDropdownNormalizersService {
  normalizeService(services, translate, isSearch=false) {
    if (!services) {
      return [];
    }

    const riskService = {
      ServiceId: 0,
      ServiceGroup: '',
      ServiceName: translate('page.banknotesRestrictions.riskService'),
      Status: '',
      Provider: '',
      Rules: '',
      Id: 0,
      Name: translate('page.banknotesRestrictions.riskService'),
    };

    const allDataService = services.map((service) => ({
      ...service,
      Id: service.ServiceId,
      Name: service.ServiceName,
    }));

    const returnData = [riskService, ...allDataService];

    return isSearch ? returnData : allDataService;
  }
}

export default new BanknotesDropdownNormalizersService();
