import React, { PureComponent } from 'react';
import { Button, Input, message, Modal } from 'antd';

import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

import './MoveMenuElementModal.styled.scss';

const api = new ApiService();

class MoveMenuElementModal extends PureComponent {
  state = {
    parentId: '',
    error: '',
    isLoading: false,
  };

  handleChange = ({ target: { value } }) => {
    this.setState({
      parentId: value,
      error: Number.isInteger(+value) ? '' : 'page.profilesMenu.invalidParentElement',
    });
  };

  moveMenuElement = async () => {
    const {
      profileStaffForUpdating: { name, menuId, profileId },
      fetchParentMenuElements,
      translate,
      closeDialog,
    } = this.props;
    const { parentId } = this.state;
    try {
      await this.setState({ isLoading: true });
      await api.menuParentUpdate({ profileId, menuId, name, parentId });
      await message.success(`${translate('page.profilesMenu.elementSuccessfullyMoved')}`, 2);
      await this.setState({ isLoading: false });
      closeDialog();
      fetchParentMenuElements();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const {
      closeDialog,
      translate,
      visible,
      profileStaffForUpdating: { name },
    } = this.props;
    const { parentId, error, isLoading } = this.state;

    return (
      <Modal
        title={`${translate('page.profilesMenu.moveElement')} ${name}`}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" loading={isLoading} disabled={!parentId || !!error} onClick={this.moveMenuElement}>
            {translate('page.profilesMenu.move')}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <>
          <div className="UpdateMenuElementModal-inputLabel">{translate('page.profilesMenu.parentElementNumber')}</div>
          <Input className="UpdateMenuElementModal-input" value={parentId} onChange={this.handleChange} />
          {!!error && <div className="UpdateMenuElementModal-error">{translate(error)}</div>}
          {isLoading && <LoadingPanel />}
        </>
      </Modal>
    );
  }
}

export default withTranslate(MoveMenuElementModal);
