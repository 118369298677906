import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col } from 'antd';
import Grid from '../../components/grids/baseGrid';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import findArray from '../../utils/findArrForCurrentPage';
import ModalAddRefusalAgent from './modalAddRefusalAgent';
import ModalEditRefusalAgent from './modalEditRefusalAgent';
import ModalDeleteRefusalAgent from './modalDeleteRefusalAgent';

const api = new ApiService();

class GridDictionaryRefusedAgentsGet extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('monitoringCabinet');
  }

  state = {
    data: [],
    isLoading: false,
    visible: false,
    visibleEdit: false,
    clickedElem: {},
    visibleDelete: false,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleModalDelete = () => {
    this.setState(({ visibleDelete }) => {
      return {
        visibleDelete: !visibleDelete,
      };
    });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  makeQuery = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const data = await api.dictionaryRefusedAgentsGet();
      this.setState({ data });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Button type="primary" onClick={this.toggleModal}>
          {translate('grids.dictionaryAgentsGet.btn-add-refusal')}
        </Button>
      </Col>
    );
  };

  getHandlers = () => {
    return [
      {
        title: 'page.screenControlBusinessServices.button-edit',
        action: (props) => {
          this.toggleEditModal();
          this.setState({ clickedElem: props.dataItem });
        },
        dropdown: false,
        // show: this.getShowProperty("terminalsEncashment-tab-encashmentHistory")
        show: true,
      },
      {
        title: 'grids.profilesMainProfileList.modal-delete-profile-btn-submit',
        action: (props) => {
          this.toggleModalDelete();
          this.setState({ clickedElem: props.dataItem });
        },
        dropdown: false,
        // show: this.getShowProperty("terminalsEncashment-tab-terminalCashList")
        show: true,
      },
    ];
  };

  toggleEditModal = () => {
    this.setState(({ visibleEdit }) => {
      return {
        visibleEdit: !visibleEdit,
      };
    });
  };

  render = () => {
    const { isLoading, data, visible, visibleEdit, clickedElem, visibleDelete } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="dictionaryRefusedAgentsGet"
          permissionName="monitoringCabinet"
          withOutBaseButtons
        >
          <GridColumn
            field="AgentId"
            title="grids.dictionaryAgentsGet.column-agentId"
            width="150"
            filter="numeric"
            dropdown={true}
            remoteCommands={false}
            handlers={this.getHandlers()}
          />

          <GridColumn field="Name" title="grids.dictionaryAgentsGet.column-name" width="250" />

          <GridColumn field="EDRPO" title="page.adressedPaymentCreate.radio-label" width="250" />

          <GridColumn field="Address" title="grids.tech-inform.column-street" width="150" />

          <GridColumn field="BossName" title="grids.dictionaryAgentsGet.column-bossName" width="150" />

          <GridColumn field="Comment" title="grids.transactions.column-comment" width="150" />

          <GridColumn
            field="DateRefused"
            title="grids.dictionaryAgentsGet.column-dateRefused"
            width="150"
            filter="date"
            format="dd.MM.yyyy"
          />
        </Grid>
        {visibleDelete && (
          <ModalDeleteRefusalAgent
            visible={visibleDelete}
            toggleModal={this.toggleModalDelete}
            item={clickedElem}
            updateGrid={this.makeQuery}
          />
        )}
        {visible && (
          <ModalAddRefusalAgent visible={visible} toggleModal={this.toggleModal} updateGrid={this.makeQuery} />
        )}
        {visibleEdit && (
          <ModalEditRefusalAgent
            visible={this.state.visibleEdit}
            toggleModal={this.toggleEditModal}
            item={clickedElem}
            updateGrid={this.makeQuery}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridDictionaryRefusedAgentsGet);
