import React, { PureComponent } from 'react';
import TagsGrid from './TagsGrid';
import GridElements from './ElementsGrid';
import './ElementsMatrixGrid.scss';

class ElementsMatrixGrid extends PureComponent {
  render() {
    const {
      formatWebTagList,
      formatElementList,
      onChangeSelectedWebTag,
      onElementsGridRowClick,
      onWebtagCheckboxClick,
      withTagsReleaseInfo,
    } = this.props;

    return (
      <div className="ElementsMatrixGrid">
        <div className="ElementsMatrixGrid-tags">
          <TagsGrid
            onHandleRowClick={onChangeSelectedWebTag}
            webTagList={formatWebTagList}
            onWebtagCheckboxClick={onWebtagCheckboxClick}
            withTagsReleaseInfo={withTagsReleaseInfo}
          />
        </div>
        <div className="ElementsMatrixGrid-elements">
          <GridElements onElementsGridRowClick={onElementsGridRowClick} formatElementList={formatElementList} />
        </div>
      </div>
    );
  }
}

export default ElementsMatrixGrid;
