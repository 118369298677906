import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import GridScreenControlProfiles from './gridScreenControlProfiles';
import PageTabs from '../../../components/tabber/tabber';
import NewProfile from './newProfile';
import EditProfile from './editProfile';

class PageScreenControlProfiles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.screenControlTerminals.tab-profiles',
          content: <GridScreenControlProfiles />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { selected, OwnerId, MainProfileName, MainProfileHoldId, RegionId, isAdminRule, MainProfileReserveId } =
      dataItem;
    switch (type) {
      case 'newProfile':
        tabs.push({
          title: 'page.screenControlBusinessServices.tab-new-profile',
          content: (
            <NewProfile
              mainProfileName={MainProfileName}
              ownerId={OwnerId}
              isAdminRule={isAdminRule}
              mainProfileId={selected}
              mainProfileHoldId={MainProfileHoldId}
              regionId={RegionId}
              mainProfileReserveId={MainProfileReserveId}
            />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'editProfile':
        tabs.push({
          title: 'page.screenControlBusinessServices.tab-edit-profile',
          content: <EditProfile item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageScreenControlProfiles);
