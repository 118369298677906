class ManagementErrorCodesService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.errorCodes) {
      return { errorCodes: [], hasNextPage: false, hasPreviousPage: false };
    }
    const errorCodes = data.data.errorCodes;
    const pageInfo = errorCodes.pageInfo;

    return {
      errorCodes:
        errorCodes.items && errorCodes.items.length
          ? errorCodes.items.map((error) => ({
              ...Object.entries(error).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value === null ? 'null' : value }),
                {},
              ),
              editId: error.code,
            }))
          : [],
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new ManagementErrorCodesService();
