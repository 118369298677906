import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { StorageService, tabService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';

import './Services.scss';

class StepServiceCreationModal extends PureComponent {
  state = {
    selectedService: null,
    services: StorageService.getItem('waitingFilledStepServices') || [],
  };

  onRowClick = ({ dataItem }) => {
    this.setState(({ selectedService }) => ({
      selectedService: selectedService && selectedService.serviceId === dataItem.serviceId ? null : dataItem,
    }));
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    const { selectedService, services } = this.state;

    return (
      <Modal
        className="StepServiceCreationModal"
        visible={visible}
        title={translate('page.servicesBigTable.stepServiceCreation')}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={() => {
              tabService.addTab({ type: 'stepServiceCreation', dataItem: selectedService });
              closeModal();
            }}
          >
            {translate(selectedService ? 'core.buttonTitles.select' : 'page.servicesBigTable.createNewService')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <Grid
          data={services.map((service) => ({
            ...service,
            selected: selectedService && selectedService.serviceId === service.serviceId,
          }))}
          onRowClick={this.onRowClick}
          onSelectionChange={this.onRowClick}
          selectedField="selected"
        >
          <GridColumn field="selected" width="50px" />
          <GridColumn field="serviceId" title={translate('grids.servicesBigTable.column-serviceId')} />
          <GridColumn field="lastStep" title={translate('page.servicesBigTable.step')} />
        </Grid>
      </Modal>
    );
  }
}

export default withTranslate(StepServiceCreationModal);
