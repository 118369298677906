import React, { Component } from 'react';

import PageTabs from '../../components/tabber/tabber';
import { withTranslate } from '../../contexts/localContext';
import GridReportTerminalsMaintenance from './GridReportTerminalsMaintenance/GridReportTerminalsMaintenance';

class TerminalsMaintenance extends Component {
  constructor(props) {
    super(props);

    const { translate } = props;
    this.state = {
      tabs: [
        {
          title: `${translate('page-nav.nav-terminalMaintenance')}`,
          content: <GridReportTerminalsMaintenance />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}

export default withTranslate(TerminalsMaintenance);
