import React, { PureComponent } from 'react';
import { Button } from 'antd';

import SupplierCommissions from '../../SimpleSupplierСommissionsForm';
import { withTranslate } from '../../../../../contexts/localContext';
import ServiceIdField from '../ServiceIdField';

import './SupplierCommissions.styled.scss';

class SupplierCommissionsWrapper extends PureComponent {
  render() {
    const { formState, onChangeFormState, formErrors, onError, translate, saveSupplierCommissions, currentServiceId } =
      this.props;

    const isError = Object.values(formErrors).some(Boolean);
    const isEmpty = Object.values(formState).some((value) => !value && value !== 0);

    return (
      <div className="StepServiceCreationSupplierCommission">
        <ServiceIdField serviceId={currentServiceId} />

        <SupplierCommissions
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          onError={onError}
        />

        <Button
          type="primary"
          onClick={() => {
            saveSupplierCommissions(formState);
          }}
          style={{ marginTop: '16px' }}
          disabled={isError || isEmpty}
        >
          {translate('page.servicesBigTable.saveAndNext')}
        </Button>
      </div>
    );
  }
}

export default withTranslate(SupplierCommissionsWrapper);
