const changingCommissionProfileHistory = (translate, value) => {
  switch (value) {
    case 'changingManagementCommissionProfileHistory-search':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.changingProfileHistory.btn-view')}`;
    case 'changingManagementCommissionProfileHistory-grid-ProfileId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-profileId')}`;
    case 'changingManagementCommissionProfileHistory-grid-ProfileName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-profile')}`;
    case 'changingManagementCommissionProfileHistory-grid-ServiceId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-serviceId')}`;
    case 'changingManagementCommissionProfileHistory-grid-ServiceName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-service')}`;
    case 'changingManagementCommissionProfileHistory-grid-CommissionId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-commissionId',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-CommissionName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-commissionName',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-ServiceRules':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-rule')}`;
    case 'changingManagementCommissionProfileHistory-grid-DateExec':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-profileModificationDate',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-UserExec':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-executor')}`;
    case 'changingManagementCommissionProfileHistory-grid-AmountTo':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-paymentAmountUpTo',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-ResultFixed':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-fixed')}`;
    case 'changingManagementCommissionProfileHistory-grid-ResultPercent':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-percent')}`;
    case 'changingManagementCommissionProfileHistory-grid-CommissionMin':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-commissionMin',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-CommissionMax':
      return `${translate(`page.adminPanel.type-column`)}: ${translate(
        'page.changingProfileHistory.col-commissionMax',
      )}`;
    case 'changingManagementCommissionProfileHistory-grid-Priority':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-priority')}`;
    case 'changingManagementCommissionProfileHistory-grid-PaymentTypeName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('page.changingProfileHistory.col-paymentType')}`;
    default:
      return value;
  }
};

export default changingCommissionProfileHistory;
