class ElementPropsService {
  getNormalizedNumberValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  decimalValidate = (value) => {
    const normalizedValue = this.getNormalizedNumberValue(value);

    if (isNaN(normalizedValue)) {
      return 'page.serviceMainProps.invalidValueFormat';
    }

    if (`${normalizedValue}`.includes('.') && `${normalizedValue}`.split('.')[1].length > 2) {
      return 'page.finMonLimits.error-two-characters';
    }

    return '';
  };

  getValueByType = (value, valueType) => {
    if (valueType === 'bool') {
      return !!value;
    }

    if (valueType === 'int' || valueType === 'decimal') {
      return this.getNormalizedNumberValue(value);
    }

    return value;
  };
}

export default new ElementPropsService();
