import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Col, Row, Radio } from 'antd';

import './CopyDealerCommissions.scss';
import CopyCommissionsFromService from './CopyCommissionsFromService';
import CopyCommissionsFromDealer from './CopyCommissionsFromDealer';

class CopyDealerCommissions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRadio: 'service',
    };
  }

  handleChangeRadio = (e) => {
    this.setState({ selectedRadio: e.target.value });
  };

  render = () => {
    const { translate } = this.props;
    const { selectedRadio } = this.state;
    return (
      <div className="CopyDealerCommissions-container">
        <div className="CopyDealerCommissions-header">
          <Radio.Group defaultValue={selectedRadio} onChange={this.handleChangeRadio}>
            <Row type="flex" justify="space-between" className="CopyDealerCommissions-row">
              <Col className="CopyDealerCommissions-label">
                <Radio value="service" checked={selectedRadio === 'service'}>
                  {translate('page.dealerCommissionsCopy.labelInput-service')}
                </Radio>
              </Col>
              <Col className="CopyDealerCommissions-label">
                <Radio value="dealer" checked={selectedRadio === 'dealer'}>
                  {translate('page.dealerCommissionsCopy.labelInput-dealer')}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </div>
        <div className="CopyDealerCommissions-content">
          {selectedRadio === 'service' && <CopyCommissionsFromService />}
          {selectedRadio === 'dealer' && <CopyCommissionsFromDealer />}
        </div>
      </div>
    );
  };
}
export default withTranslate(CopyDealerCommissions);
