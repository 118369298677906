import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import DateRange from '../../../components/daterange';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

const api = new ApiService();

const aggregate = [
  { field: 'PaymentTypeCode', aggregate: 'count', format: 'n0' },
  { field: 'PaymentCount', aggregate: 'sum', format: 'n0' },
  { field: 'PaymentAmount', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionClient', aggregate: 'sum', format: 'n2' },
];

class GridReportServices extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('reportByPaymentTypes');
  }

  state = {
    data: [],
    isLoading: false,
    range: undefined,
  };

  makeQuery = async () => {
    const { range } = this.state;
    if (!range) return;

    try {
      this.setState({ isLoading: true });
      const model = {
        dateStart: range.from,
        dateEnd: range.to,
      };

      const data = await api.servicesPaymentType(model);
      this.setState({ data, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { range } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <DateRange
              onRange={(range) => {
                this.setState({ range, data: [] });
              }}
            />
          </Col>

          <Col className="md-mt-10" md={24} lg={4}>
            <Row className="md-daterange--row" type="flex" justify="center">
              <Button
                className="sm-w-100"
                icon="search"
                primary
                onClick={this.makeQuery}
                disabled={!range || !range.from || !range.to}
              >
                {translate('owner.search')}
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;

    if (!checkElement('reportByPaymentTypes-content', this.currentArray)) {
      return <></>;
    }

    return (
      <Grid
        data={data}
        aggregate={aggregate}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="reportByPaymentTypesGrid"
      >
        <GridColumn
          field="PaymentTypeCode"
          title="page.reportByPaymentTypes.col-paymentTypeNumber"
          width="140"
          filter="numeric"
          format="{0:0}"
        />

        <GridColumn field="PaymentTypeName" title="page.reportByPaymentTypes.col-name" width="225" />

        <GridColumn field="SystemName" title="page.reportByPaymentTypes.col-systemCode" filter="numeric" width="200" />

        <GridColumn
          field="PaymentCount"
          title="page.reportByPaymentTypes.col-paymentCount"
          width="185"
          filter="numeric"
        />

        <GridColumn
          field="PaymentAmount"
          title="page.reportByPaymentTypes.col-paymentAmount"
          width="185"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionProvider"
          title="page.reportByPaymentTypes.col-commissionProvider"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionClient"
          title="page.reportByPaymentTypes.col-commissionClient"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridReportServices);
