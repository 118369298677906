import React, { PureComponent } from 'react';

import PaymentTypesDictionaryGrid from './PaymentTypesDictionaryGrid';
import PageTabs from '../../components/tabber/tabber';

class PaymentTypesDictionary extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-paymentTypesDictionary',
        content: <PaymentTypesDictionaryGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} />;
  }
}

export default PaymentTypesDictionary;
