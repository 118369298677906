const TemplateServices = (translate, value) => {
  switch (value) {
    case 'servicesTemplate-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'servicesTemplate-deleteButton':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    case 'servicesTemplate-updateButton':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.change`)}`;
    default:
      return value;
  }
};

export default TemplateServices;
