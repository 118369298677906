import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import LoadingPanel from '../../components/loader';
import { withTranslate } from '../../contexts/localContext';
import './EncashmentTerminals.styled.scss';

class RecalculationModal extends PureComponent {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    const { submitCallback } = this.props;

    await this.setState({ isLoading: true });

    try {
      await submitCallback();
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { translate, visible, closeModal, deleteContent, modalTitle } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        closable={false}
        {...(modalTitle ? { title: modalTitle } : {})}
        zIndex="1053"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.no')}
          </Button>,

          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            {translate('core.buttonTitles.yes')}
          </Button>,
        ]}
      >
        <div className="RecalculationModal-content">{deleteContent}</div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(RecalculationModal);
