import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

import './gridTransactions.scss';

const api = new ApiService();
const { TextArea } = Input;

class PaymentInstructionModal extends PureComponent {
  state = { isLoading: false, emails: '', error: false };

  isEmailValid = (email) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

  sendEmails = async () => {
    const { closeModal, translate, transactionId } = this.props;

    const { emails } = this.state;

    const isUnValid = emails.split(';').some((email) => !this.isEmailValid(email));

    if (isUnValid) {
      this.setState({ error: true });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      await api.sendReceiptsToEmail({
        transactionId,
        receiptType: 'PaymentInstruction',
        emails,
      });
      await this.setState({ isLoading: false });
      message.success(translate('page.transactions.successSendPaymentInstruction'), 2);
      closeModal();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { closeModal, translate, visible } = this.props;
    const { isLoading, emails, error } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate('page.transactions.sendPaymentInstruction')}
        onCancel={closeModal}
        footer={[
          <Button type="primary" key="add" disabled={!emails || !!error} onClick={this.sendEmails}>
            {translate('page.transactions.send')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>{translate('page.transactions.enterTheEmailAddresses')}</div>
        <TextArea
          onChange={({ target: { value: emails } }) => this.setState({ emails, error: false })}
          value={emails}
          onPressEnter={(e) => {
            e.preventDefault();
          }}
        />
        {error && (
          <div className="PaymentInstructionModal-error">{translate('page.transactions.invalidValueFormat')}</div>
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(PaymentInstructionModal);
