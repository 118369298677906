import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './specificTerminalIdFilter.scss';

class SpecificTerminalIdFilter extends PureComponent {
  state = {
    inputValue: null,
    isShow: false,
  };

  showWindow = () => {
    const { onClear, isGQL } = this.props;
    const { isShow } = this.state;

    if (isShow && isGQL) {
      onClear();
    }
    this.setState({ isShow: !isShow });
  };

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    serverData: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onClickHandler = () => {
    const { onChange, fieldName} = this.props;
    const { inputValue } = this.state;
    onChange(inputValue, fieldName);
  };

  onChangeInputValue = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  onClearHandler = () => {
    const { onClear } = this.props;
    this.setState({ inputValue: null });
    onClear();
  };

  render() {
    const { inputValue, isShow } = this.state;
    const { translate } = this.props;
    return (
      <div className="specific-terminalId-filter--wrapper">
        <input className="open" id="top-box" type="checkbox" hidden />
        <label className="btn" htmlFor="top-box" onClick={this.showWindow}>
          <span className="k-icon k-i-filter"></span>
        </label>
        {isShow && (
          <div className="top-panel">
            <div className="message">
              <Input value={inputValue} onChange={this.onChangeInputValue} />
              <Button type="primary" onClick={this.onClickHandler}>
                {translate('owner.save')}
              </Button>
              <Button type="primary" onClick={this.onClearHandler}>
                {translate('owner.clean')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslate(SpecificTerminalIdFilter);
