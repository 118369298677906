import React, { PureComponent } from 'react';
import { Button, message, Modal, Input } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../loader';
import { ApiService } from '../../services';

import './AddSupplierModal.styled.scss';

const api = new ApiService();

class AddSupplierModal extends PureComponent {
  state = {
    isLoading: false,
    name: '',
  };

  addSupplier = async () => {
    const { onRefresh, closeModal, translate } = this.props;
    const { name } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.supplierAdd({
        name,
      });
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate(`page.serviceSupplier.technicalPartnerSuccessfulAdded`), 2.5);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    const { name, isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={translate(`page.serviceSupplier.createSupplier`)}
        footer={[
          <Button key="back" type="primary" onClick={this.addSupplier} disabled={!name}>
            {translate(`core.buttonTitles.create`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="AddSupplierModal-inputRow">
          <div className="AddSupplierModal-label">{translate(`page.serviceSupplier.col-name`)}</div>
          <Input
            value={name}
            onChange={({ target: { value: name } }) => {
              this.setState({ name });
            }}
          />
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddSupplierModal);
