import React from 'react';
import { Col, Tooltip } from 'antd';
import Input from '../../../components/Input';
import { PHONE_CODE } from '../UserProperties/constant';

export const renderInputAddNewUser = (
  name,
  label,
  type,
  translate,
  maxLength = 150,
  formState,
  onChangeFormState,
  formErrors,
  onError,
  validators = {},
) => {
  return (
    <>
      <Col span={24} md={8}>
        {translate(`page.addNewUser.column-${label}`)}
      </Col>
      <Col span={24} md={16}>
        <Input
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          name={name}
          type={type}
          checked={formState.Status}
          validators={validators}
          mask="( 000 ) - 000 - 00 - 00"
          {...(type === 'phone' ? { addonBefore: `+${PHONE_CODE}` } : {})}
          maxLength={maxLength}
          {...(name === 'Login' ? { tooltipTitle: translate('page.addNewUser.loginTooltip') } : {})}
        />
      </Col>
    </>
  );
};
