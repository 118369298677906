/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Dropdown, Menu, Button } from 'antd';
// import { StorageService } from '../../../../services'
import { withTranslate } from '../../../../contexts/localContext';
import './sortableItemUi.scss';
import ModalChangeImage from './modalChangeImage';

class SortableItemUI extends Component {
  state = {
    visible: false,
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  getBaseItemStyle = (isActive, isEditing) => ({
    height: 110,
    lineHeight: '108px',
    fontSize: '16px',
    textAlign: 'center',
    outline: 'none',
    border: '1px solid',
    cursor: 'move',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: !isEditing ? 'rgba(170, 169, 173, 0.4)' : isActive ? '#27aceb' : '#bfe7f9',
    color: isActive ? '#fff' : '#1494d0',
    borderColor: isActive ? '#27aceb' : '#fff',
    width: '20%',
    position: 'relative',
  });

  toggleModal = () => {
    this.setState((state) => {
      return {
        visible: !state.visible,
      };
    });
  };

  sortItemOverlay = () => {
    const { dropImage, dataItem, isSuperUser, toggleElementLock, translate } = this.props;
    return (
      <Menu>
        <Menu.Item key="0">
          <Button type="default" onClick={this.toggleModal}>
            {translate('page.screenControlBusinessServices.sortable-item-btn-set')}
          </Button>
        </Menu.Item>
        <Menu.Item key="1">
          <Button type="default" onClick={() => dropImage(dataItem.RowIndex)}>
            {translate('page.screenControlBusinessServices.sortable-item-btn-clear')}
          </Button>
        </Menu.Item>
        {isSuperUser && (
          <Menu.Item key="2">
            <Button type="default" onClick={() => toggleElementLock(dataItem.MenuId)}>
              {dataItem.disabled
                ? translate('page.screenControlBusinessServices.sortable-item-btn-unblock')
                : translate('page.screenControlBusinessServices.sortable-item-btn-block')}
            </Button>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  render() {
    const {
      isDisabled,
      isActive,
      style,
      attributes,
      dataItem,
      forwardRef,
      changeImage,
      isSuperUser,
      ownerId,
      isEditing,
      item,
      translate,
    } = this.props;
    const { visible } = this.state;
    const classNames = ['col-xs-6 col-sm-3'];
    if (isDisabled) {
      classNames.push('k-state-disabled');
    }

    return (
      <>
        <Dropdown overlay={this.sortItemOverlay()} trigger={['click']}>
          <div
            ref={forwardRef}
            {...attributes}
            style={{
              ...this.getBaseItemStyle(isActive, isEditing),
              ...style,
            }}
            className={classNames.join(' ')}
            // onClick={this.toggleModal}
          >
            {dataItem.MenuId && (
              <div className="super-user-abs-disabled">
                <span className="super-user-abs-disabled--span">
                  {translate('page.screenControlProfiles.abs-block-text')}:{dataItem.MenuId}
                </span>
              </div>
            )}
            {dataItem.IconUrl ? (
              <div className="sortable-item-sub-wrap">
                <img className="sortable-item-img" src={dataItem.IconUrl} />
                <p>{dataItem.Caption} </p>
              </div>
            ) : (
              dataItem.Caption
            )}
          </div>
        </Dropdown>
        {visible && (
          <ModalChangeImage
            visible={visible}
            ownerId={ownerId}
            closeModal={this.closeModal}
            toggleModal={this.toggleModal}
            dataItem={dataItem}
            item={item}
            isSuperUser={isSuperUser}
            changeImage={changeImage}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SortableItemUI);
