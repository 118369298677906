import React, { PureComponent } from 'react';
import { Button, Input, message, Modal } from 'antd';
import MaskedInput from 'antd-mask-input';

import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class ViberReceiptModal extends PureComponent {
  state = {
    isLoading: false,
    error: false,
    phone: '',
  };

  getPurePhone = (phone) =>
    phone
      ? phone
          .split('')
          .filter((char) => !isNaN(+char) && char !== ' ')
          .join('')
      : '';

  validatePhone = (phone) => {
    if (!phone) {
      return '';
    }

    const purePhone = this.getPurePhone(phone);

    return purePhone.length !== 12 || purePhone.slice(0, 3) !== '380';
  };

  sendReceipt = async () => {
    const { closeModal, translate, transactionId } = this.props;
    const { phone } = this.state;

    if (this.validatePhone(phone)) {
      this.setState({ error: true });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      await api.sendReceiptsToViber({ transactionId, phone: this.getPurePhone(phone) });

      await this.setState({ isLoading: false });
      message.success(translate('page.transactions.successSendReceiptPhone'), 2);
      closeModal();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { translate, visible, closeModal, transactionId } = this.props;
    const { isLoading, phone, error } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate('page.transactions.sendingViberReceipt')}
        onCancel={closeModal}
        footer={[
          <Button onClick={this.sendReceipt} type="primary" disabled={!phone || !!error}>
            {translate('page.transactions.send')}
          </Button>,
          <Button onClick={closeModal}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div>{translate('grids.transactions.column-transactionId')}</div>
        <Input value={transactionId} disabled className="EmailReceiptsModal-transactionInput" />

        <div>{translate('page.serviceSupplier.col-mobPhone')}</div>
        <MaskedInput
          onChange={({ target: { value: phone } }) => this.setState({ phone, error: false })}
          // value={phone}
          mask="38 (000) - 000 - 00 - 00"
          // onPressEnter={(e) => {
          //   e.preventDefault();
          // }}
        />
        {error && <div className="EmailReceiptsModal-error">{translate('page.serviceSupplier.invalidPhone')}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ViberReceiptModal);
