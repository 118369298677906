import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import GridSettingsAndEvents from './GridSettingsAndEvents';
import GridControlTerminalEvents from './gridControlTerminalEvents';
import TerminalDetails from '../../../components/TerminalDetails';
import AdditionalSettings from './additionalSettings';
import RemoteSettings from './remoteSettings';
import AddRemoteSettings from './addRemoteSettings';
import PageTabs from '../../../components/tabber/tabber';

class PageSettingsAndEvents extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = this.props;
    this.state = {
      tabs: [
        {
          title: `${translate('page.screenControlAddSets.tab-main')}`,
          content: <GridSettingsAndEvents />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'propDetails':
        tabs.push({
          title: 'page.newTerminals.tab-details',
          content: (
            <TerminalDetails
              item={dataItem}
              permissionSlugs={{
                permissionName: 'screenControlSettings',
                showAdditionalSettingsButton: 'terminalDetails-btn-showAdditional',
                updateSettingsButton: 'terminalDetails-btn-updateSettings',
                fiscalizationInventory: 'screenControlSettings-fiscalizationInventory',
                fiscalNumberInput: 'screenControlSettings-fiscalNumberInput',
                businessUnitInput: 'screenControlSettings-businessUnitInput',
                inventoryNumberInput: 'screenControlSettings-inventoryNumberInput',
              }}
            />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'addtionalSettings':
        tabs.push({
          title: `${translate('page.terminalSettings.addtionalSettings-title')}`,
          content: <AdditionalSettings item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'remoteSettings':
        tabs.push({
          title: `${translate('page.terminalSettings.remoteSettings-title')}`,
          content: <RemoteSettings item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'addRemoteSettings':
        tabs.push({
          title: `Терминалы-Добавление удалённых настроек`,
          content: <AddRemoteSettings item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'events':
        const { TerminalId: terminalId } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-events',
          content: <GridControlTerminalEvents terminalId={terminalId} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageSettingsAndEvents);
