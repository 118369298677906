import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Input, Button, message } from 'antd';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import ModalUnholdTransaction from './modalUnholdTransaction';
import ModalDeclineTransaction from './modalDeclineTransaction';
import Grid from '../baseGrid';
import PaymentInstructionModal from './PaymentInstructionModal';
import PrintCheckModal from './PrintCheckModal';

import './gridTransactions.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

import './gridTransactions.scss';

const api = new ApiService();

class GridTransactionDetails extends PureComponent {
  currentArray = findArray('searchtransactions');

  state = {
    data: [],
    popupBlock: false,
    popupUnblock: true,
    isPaymentInstructionModalOpened: false,
    printCheckResult: null,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const {
      dataItem: { Hist, transactionId },
    } = this.props;
    if (!Hist && !transactionId) return;
    try {
      this.toggleLoader(true);
      const value = {
        transactionId,
        Hist: true,
      };
      const data = await api.transactionDetails(value);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  toggleUnblockPopup = () => {
    const { popupUnblock } = this.state;
    this.setState({ popupUnblock: !popupUnblock });
  };

  onCancelBlockPopup = () => {
    this.setState({ popupBlock: false });
  };

  handleRequestPrintCheck = async () => {
    const {
      dataItem: { transactionId },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const printCheckResult = await api.generateReceipts({
        transactionId: transactionId,
      });
      await this.setState({ isLoading: false, printCheckResult });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  reSendCallback = async () => {
    const { data } = this.state;
    const {
      translate,
      dataItem: {
        transactionId,
        requestId,
        statusId,
        terminalId,
        serviceId,
        systemId,
        dateAccepted,
        dateDeclined,
        paymentTypeId,
        amountTotal,
        commissionClient,
      },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.callbackResend({
        requestId: requestId,
        transactionID: transactionId,
        status: statusId,
        objectId: terminalId,
        serviceId: serviceId,
        system: systemId,
        dateAccepted: dateAccepted,
        dateDeclined: dateDeclined,
        paymentType: paymentTypeId,
        amount: amountTotal,
        commission: commissionClient,
        items:
          data && data.length
            ? data.map(({ Name, Value }) => ({
                type: '',
                name: Name,
                value: Value,
              }))
            : [],
      });
      message.success(translate('page.transactions.transactionQueued'), 3);
      await this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  renderToolbar = () => {
    const { translate, dataItem, withoutActions } = this.props;

    if (withoutActions) {
      return (
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridTransactionsDetail-toolbar">
          <div className="issued-certificates--toolbar">
            <p>{translate('grids.terminal-events.column-transactionId')}</p>
            <Input value={dataItem.transactionId} disabled />
          </div>
        </Col>
      );
    }

    if (dataItem && dataItem.statusName && dataItem.statusName === 'заблокирован') {
      return (
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridTransactionsDetail-toolbar">
          <div>
            <ModalDeclineTransaction item={dataItem} />
          </div>
          <div>
            <ModalUnholdTransaction item={dataItem} />
          </div>

          {checkElement('searchtransactions-paymentInstruction', this.currentArray) &&
            dataItem.statusName !== 'удален' && (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ isPaymentInstructionModalOpened: true });
                  }}
                >
                  {translate('page.transactions.paymentInstruction')}
                </Button>
              </div>
            )}

          {checkElement('searchtransactions-printCheckRequest', this.currentArray) && (
            <div>
              <Button type="primary" onClick={this.handleRequestPrintCheck}>
                {translate('page.transactions.requestPrintCheck')}
              </Button>
            </div>
          )}

          {checkElement('searchtransactions-reSendingCallback', this.currentArray) && (
            <div>
              <Button type="primary" onClick={this.reSendCallback}>
                {translate('page.transactions.reSendingCallback')}
              </Button>
            </div>
          )}
        </Col>
      );
    } else
      return (
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridTransactionsDetail-toolbar">
          <div className="issued-certificates--toolbar">
            <p>{translate('grids.terminal-events.column-transactionId')}</p>
            <Input value={dataItem.transactionId} disabled />
          </div>

          {checkElement('searchtransactions-paymentInstruction', this.currentArray) &&
            dataItem.statusName !== 'удален' && (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ isPaymentInstructionModalOpened: true });
                  }}
                >
                  {translate('page.transactions.paymentInstruction')}
                </Button>
              </div>
            )}

          {checkElement('searchtransactions-printCheckRequest', this.currentArray) && (
            <div>
              <Button type="primary" onClick={this.handleRequestPrintCheck}>
                {translate('page.transactions.requestPrintCheck')}
              </Button>
            </div>
          )}

          {checkElement('searchtransactions-reSendingCallback', this.currentArray) && (
            <div>
              <Button type="primary" onClick={this.reSendCallback}>
                {translate('page.transactions.reSendingCallback')}
              </Button>
            </div>
          )}
        </Col>
      );
  };

  render = () => {
    const { isLoading, data, isPaymentInstructionModalOpened, printCheckResult } = this.state;
    const {
      dataItem: { transactionId },
    } = this.props;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          isLoading={isLoading}
          toolbar={this.renderToolbar()}
          name="transactionDetails"
          permissionName="searchtransactions"
        >
          <GridColumn field="Name" title="grids.transactionDeatails.column-name" />
          <GridColumn field="Value" title="grids.transactionDeatails.column-value" />
        </Grid>
        {isPaymentInstructionModalOpened && (
          <PaymentInstructionModal
            transactionId={transactionId}
            visible={isPaymentInstructionModalOpened}
            closeModal={() => {
              this.setState({ isPaymentInstructionModalOpened: false });
            }}
          />
        )}

        {printCheckResult && (
          <PrintCheckModal
            printCheckResult={printCheckResult}
            closeModal={() => {
              this.setState({ printCheckResult: null });
            }}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridTransactionDetails);
