import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DealerCommissionsService from '../../DealerCommissions/dealerCommissions.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, StorageService, tabService } from '../../../services';

import './DispenserTerminalsGrid.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

class DispenserTerminalsGrid extends PureComponent {
  currentArray = findArray('dispenserCollection');

  state = {
    isLoading: false,
    selectOwners: [1],
    terminalList: [],
    allOwners: [],
  };

  componentDidMount = async () => {
    const userInfo = StorageService.getItem('userInfo');
    await this.setState({ selectOwners: userInfo && userInfo.OwnerId ? [userInfo.OwnerId] : [1] });
    await this.fetchOwners();
    await this.fetchTerminalsWithDispenser();
  };

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const allOwners = await api.owners();
      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchTerminalsWithDispenser = async () => {
    const { selectOwners } = this.state;
    this.setState({ isLoading: true });

    try {
      const terminalList = await api.terminalsWithDispenser(selectOwners);
      this.setState({ terminalList: terminalList && terminalList.length ? terminalList : [] });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  changeSelectedDealers = (selectOwners) => {
    this.setState({ selectOwners }, () => {
      this.fetchTerminalsWithDispenser();
    });
  };

  buildToolbar = () => {
    const { allOwners, selectOwners } = this.state;
    const { translate } = this.props;

    return (
      <Row className="DispenserTerminalsGrid-toolbar">
        <Col span={24} sm={18} md={12}>
          <GridDropdown
            data={DealerCommissionsService.normalizeDealerCommissions(allOwners)}
            colConfig={OWNERS_COLUMN_CONFIG}
            selectItems={selectOwners}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            onSave={this.changeSelectedDealers}
            isGetDefaultSelectItemsFromStorage
          />
        </Col>
      </Row>
    );
  };

  getAddTabCallback =
    (type) =>
    ({ dataItem: { TerminalId } }) => {
      tabService.addTab({ type, dataItem: TerminalId });
    };

  getHandlers = () => {
    return [
      {
        title: 'page.dispenserCollection.dispenserCollectionHistory-tab',
        action: this.getAddTabCallback('dispenserCollectionHistory'),
        dropdown: false,
        show: checkElement('dispenserCollection-dispenserCollectionHistory-link', this.currentArray),
      },
      {
        title: 'page.dispenserCollection.cardsAvailability-tab',
        action: this.getAddTabCallback('cardsAvailability'),
        dropdown: false,
        show: checkElement('dispenserCollection-cardsAvailability-link', this.currentArray),
      },
    ];
  };

  render() {
    const { isLoading, terminalList } = this.state;

    if (!checkElement('dispenserCollection-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={terminalList}
        name="dispenserTerminalsGrid"
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        onRefresh={this.fetchTerminalsWithDispenser}
      >
        <GridColumn
          field="TerminalId"
          title="grids.createTerminal.column-terminalId"
          width="170"
          filter="numeric"
          handlers={this.getHandlers()}
          dropdown={checkElement('dispenserCollection-dropdown', this.currentArray)}
        />

        <GridColumn field="OwnerName" title="grids.terminalOperabilityChoose.column-ownerName" width="270" />

        <GridColumn field="TerminalName" title="grids.createTerminal.column-name" width="170" />

        <GridColumn field="RegionName" title="grids.encashment.column-region" width="170" />

        <GridColumn field="CityName" title="grids.createTerminal.column-city" width="170" />

        <GridColumn field="Address" title="grids.createTerminal.column-address" width="170" />

        <GridColumn field="StatusName" title="grids.createTerminal.column-status" width="170" />

        <GridColumn field="ProjectId" title="grids.createTerminal.column-project" width="170" filter="numeric" />

        <GridColumn
          field="WorkDate"
          title="page.dispenserCollection.col-cardsLastCollectionDate"
          width="300"
          cell={({ dataItem: { WorkDate } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(WorkDate)}</td>
          )}
          formatFilterCellData={(WorkDate) => getTransformDateString(WorkDate)}
        />

        <GridColumn field="Loaded" title="page.dispenserCollection.col-downloadedCards" width="180" filter="numeric" />

        <GridColumn field="UserName" title="page.dispenserCollection.col-collectorsName" width="180" />

        <GridColumn
          field="NetworkContractName"
          title="grids.terminalOperabilityChoose.column-networkContract"
          width="270"
        />
      </Grid>
    );
  }
}

export default withTranslate(DispenserTerminalsGrid);
