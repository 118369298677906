import React, { PureComponent } from 'react';
import { Card, Col, Icon, Row, Statistic } from 'antd';
import { Link } from 'react-router-dom';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../../components/loader';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

import './dashboard.scss';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import ModalAccountAddLimit from './ModalAccountAddLimit';

const api = new ApiService();

const info = {
  Total: {
    ActiveTerminal: 0,
    InactiveTerminal: 0,
    StateBadTerminals: 0,
    StateOffTerminals: 0,
    StateArrestTerminals: 0,
  },
  Acc: {
    CashCount: 0,
    CashSum: 0,
    TransactionCount: 0,
    TransactionSum: 0,
    TransactionCommissionClient: 0,
    TransactionCommissionProvider: 0,
    CollectionCount: 0,
    CollectionEnrollmentSum: 0,
    CollectionSum: 0,
    OwnerBalance: 0,
    OwnerBalanceAvailable: 0,
    OwnerOverdraftLimit: 0,
    OwnerOverdraftLimitDynamic: 0,
  },
  Average: {
    OwnerId: 0,
    Limit: 0,
    TurnDayTransactionAvg: 0,
    Today: '2019-11-18T09:09:14.323Z',
    TodayAmountTotal: 0,
    TodayCommissionTotal: 0,
    TodayCount: 0,
    Yesterday: '2019-11-18T09:09:14.323Z',
    YesterdayAmountTotal: 0,
    YesterdayCommissionTotal: 0,
    YesterdayCount: 0,
  },
  HoldTransactions: 0,
};

class PageDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('dashboard');
  }

  state = {
    isLoading: false,
    data: {},
    selectedOwners: [],
    owners: [],
    accounts: [],
    isVisible: false,
  };

  toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  async componentDidMount() {
    await this.fetchOwners();
  }

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({ owners: DropdownNormalizersService.normalizeOwners(owners), isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchAccounts = async () => {
    const { selectedOwners } = this.state;
    try {
      this.toggleLoader(true);
      const accounts = await api.accounts(selectedOwners);
      this.setState({ accounts });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  makeQuery = async () => {
    try {
      const { selectedOwners } = this.state;
      if (!selectedOwners.length) return;
      this.toggleLoader(true);
      const data = await api.terminalInfo(selectedOwners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwnersSelect = (selectedOwners) => {
    this.setState({ selectedOwners }, async () => {
      await this.makeQuery();
      await this.fetchAccounts();
    });
  };

  mergeData = () => {
    const { data } = this.state;
    return { ...info, ...data };
  };

  handleClickDynamicLimit = () => {
    this.onHandleModal();
  };

  onHandleModal = () => {
    this.setState({ isVisible: true });
  };
  onHandleClose = () => {
    this.setState({ isVisible: false });
  };

  renderTopRow = (data) => {
    const { translate } = this.props;
    const { selectedOwners, owners } = this.state;

    if (!owners || !owners.length) {
      return <></>;
    }

    return (
      <Row gutter={24}>
        <Col className="dashboard-select">
          <GridDropdown
            data={owners}
            colConfig={OWNERS_COLUMN_CONFIG}
            selectItems={selectedOwners}
            onSave={this.onOwnersSelect}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isGetDefaultSelectItemsFromStorage
            isSingle
          />
        </Col>
        {checkElement('dashboard-terminals-block', this.currentArray) && (
          <Col span={24}>
            <Card
              type="inner"
              title={
                <Link className="dashboard-link" to={`/pages/monitorActivity?main&ownerId=${selectedOwners[0]}`}>
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.terminals')}
                </Link>
              }
            >
              <Row type="flex" style={{ justifyContent: 'space-between' }}>
                <Link to={`/pages/monitorActivity?main&status=active&ownerId=${selectedOwners[0]}`}>
                  <Statistic
                    title={translate('page.main.terminals-active')}
                    value={data.Total.ActiveTerminal}
                    className="statistic-iconer dashboard-statistic"
                    precision={0}
                    valueStyle={{
                      // color: 'rgb(120,211,121)'
                      color: 'rgb(7,163,8)',
                    }}
                    prefix={<Icon type="cluster" />}
                  />
                </Link>
                <Link to={`/pages/monitorActivity?main&status=inactive&ownerId=${selectedOwners[0]}`}>
                  <Statistic
                    title={translate('page.main.terminals-inactive')}
                    value={data.Total.InactiveTerminal}
                    precision={0}
                    valueStyle={{
                      // color: 'rgb(240,151,59)'
                      color: 'rgb(250, 130, 5)',
                    }}
                    prefix={<Icon type="cluster" />}
                    className="statistic-iconer dashboard-statistic"
                  />
                </Link>
                <Link to={`/pages/monitorActivity?main&status=defective&ownerId=${selectedOwners[0]}`}>
                  <Statistic
                    title={translate('page.main.terminals-defective')}
                    value={data.Total.StateBadTerminals}
                    precision={0}
                    valueStyle={{
                      // color: 'rgb(142,141,146)'
                      color: 'rgb(83, 82, 87)',
                    }}
                    prefix={<Icon type="cluster" />}
                    className="statistic-iconer dashboard-statistic"
                  />
                </Link>
                <Link to={`/pages/monitorActivity?main&status=disconnected&ownerId=${selectedOwners[0]}`}>
                  <Statistic
                    title={translate('page.main.terminals-disconnected')}
                    value={data.Total.StateOffTerminals}
                    precision={0}
                    valueStyle={{ color: 'rgb(55,102,179)' }}
                    prefix={<Icon type="cluster" />}
                    className="statistic-iconer dashboard-statistic"
                  />
                </Link>
                <Link to={`/pages/monitorActivity?main&status=arrested&ownerId=${selectedOwners[0]}`}>
                  <Statistic
                    title={translate('page.main.terminals-arrested')}
                    value={data.Total.StateArrestTerminals}
                    precision={0}
                    valueStyle={{ color: 'rgb(233,49,59)' }}
                    prefix={<Icon type="cluster" />}
                    className="statistic-iconer dashboard-statistic"
                  />
                </Link>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  renderMiddleRow = (data) => {
    const { translate } = this.props;
    const { selectedOwners } = this.state;

    return (
      <Row className="row-wrapper" style={{ marginTop: '50px' }} gutter={24}>
        {checkElement('dashboard-encashmentTerminals-block', this.currentArray) && (
          <Col xs={24} sm={24} md={8} span={8}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/encashmentTerminals?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.collection')}
                </Link>
              }
            >
              <Row type="flex" style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={24}>
                  <Statistic
                    title={translate('page.main.amount')}
                    value={data.Acc.CollectionCount}
                    precision={0}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Statistic
                    title={translate('page.main.sum')}
                    value={data.Acc.CollectionSum}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {checkElement('dashboard-turnoversYesterday-block', this.currentArray) && (
          <Col className="col-subwrapper" xs={24} sm={24} md={8} span={16}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/monitorActivity?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.turnoversYesterday')}
                </Link>
              }
            >
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.transactions')}
                    value={data.Average.YesterdayCount}
                    precision={0}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.earning')}
                    value={data.Average.YesterdayCommissionTotal}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={12} span={12}>
                  <Statistic
                    title={translate('page.main.turnover')}
                    value={data.Average.YesterdayAmountTotal}
                    precision={0}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {checkElement('dashboard-turnoversToday-block', this.currentArray) && (
          <Col className="col-subwrapper" xs={24} sm={24} md={8} span={16}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/monitorActivity?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.turnoversToday')}
                </Link>
              }
            >
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.transactions')}
                    value={data.Average.TodayCount}
                    precision={0}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.earning')}
                    value={data.Average.TodayCommissionTotal}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={12} span={12}>
                  <Statistic
                    title={translate('page.main.turnover')}
                    value={data.Average.TodayAmountTotal}
                    precision={0}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  renderBottomRow = (data) => {
    const { translate } = this.props;
    const { selectedOwners } = this.state;
    return (
      <Row className="row-wrapper" style={{ marginTop: '50px' }} gutter={24}>
        {checkElement('dashboard-cash-block', this.currentArray) && (
          <Col xs={24} sm={24} md={8} span={8}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/encashmentTerminals?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.cash')}
                </Link>
              }
            >
              <Row type="flex" style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={24}>
                  <Statistic
                    title={translate('page.main.amount')}
                    value={data.Acc.CashCount}
                    precision={0}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Statistic
                    title={translate('page.main.sum')}
                    value={data.Acc.CashSum}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {checkElement('dashboard-account-block', this.currentArray) && (
          <Col className="col-subwrapper" xs={24} sm={24} md={16} span={12}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/account?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.account')}
                </Link>
              }
            >
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.balance')}
                    value={data.Acc.OwnerBalance}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Statistic
                    title={translate('page.main.overdraft')}
                    value={data.Acc.OwnerOverdraftLimit}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={12} span={16}>
                  <Statistic
                    title={translate('page.main.balance-extra')}
                    value={data.Acc.OwnerBalanceAvailable}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} span={8}>
                  <div onClick={this.handleClickDynamicLimit}>
                    <Statistic
                      title={translate('page.main.dynamiclimit')}
                      value={data.Acc.OwnerOverdraftLimitDynamic}
                      precision={2}
                      valueStyle={{ color: '#1890ff' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={12} span={8}>
                  <Statistic
                    title={translate('page.main.avgTransaction')}
                    value={data.Average.TurnDayTransactionAvg}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} span={16}>
                  <Statistic
                    title={translate('page.main.encashmentLimit')}
                    value={data.Average.Limit}
                    precision={2}
                    valueStyle={{ color: 'rgb(0,0,0)' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={24} style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={12} span={8}>
                  {checkElement('dashboard-account-collectionEnrollmentSum', this.currentArray) && (
                    <Statistic
                      title={translate('page.main.enrolCollection')}
                      value={data.Acc.CollectionEnrollmentSum}
                      precision={2}
                      valueStyle={{ color: 'rgb(0,0,0)' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} span={16}>
                  {/* <Statistic
                  title={translate('page.main.encashmentLimit')}
                  value={data.Average.Limit}
                  precision={2}
                  valueStyle={{ color: 'rgb(0,0,0)' }}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                /> */}
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {checkElement('dashboard-blockedTransactions-block', this.currentArray) && (
          <Col style={{ marginTop: '25px' }} xs={24} sm={24} md={8} span={8}>
            <Card
              type="inner"
              title={
                <Link
                  to={`/pages/transactions?blocked?main&ownerId=${selectedOwners[0]}`}
                  className="dashboard-link"
                  // style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {window.innerWidth < 992 && <Icon type="arrow-right" />}
                  {translate('page.main.blockedTransactions')}
                </Link>
              }
            >
              <Statistic
                title={translate('page.main.amount')}
                value={data.HoldTransactions}
                precision={0}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                valueStyle={{ color: 'rgb(0,0,0)' }}
              />
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  render() {
    const { isLoading, isVisible, accounts, selectedOwners } = this.state;

    const data = this.mergeData();
    return (
      <div className="dashboard-wrapper" style={{ background: '#f0f2f5', padding: '30px' }}>
        {this.renderTopRow(data)}
        {this.renderMiddleRow(data)}
        {this.renderBottomRow(data)}
        {isVisible && accounts.length && (
          <ModalAccountAddLimit
            item={accounts}
            visible={isVisible}
            onClose={this.onHandleClose}
            fetchData={this.makeQuery}
            // selectedOwners={selectedOwners}
          />
        )}
        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(PageDashboard);
