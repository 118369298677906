import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Select, Row } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService, StorageService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import './modalChangeImage.scss';

const api = new ApiService();

const { Option } = Select;

class ModalChangeImage extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: false,
      regionValue: null,
      categoryValue: null,
      serviceValue: null,
      buttonValue: null,
      regions: [],
      categories: [],
      services: [],
      buttons: [],
    };
  }

  componentDidMount() {
    this.getRegions();
  }

  getRegions = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const regions = await api.getRegions();
      this.setState({ loading: false, regions });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getCategories = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const categories = await api.getCategories();
      this.setState({ loading: false, categories });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getOwnerId = async () => {
    const userInfo = StorageService.getItem('userInfo');
    if (userInfo.OwnerId || userInfo.OwnerId === 0) {
      return userInfo.OwnerId;
    }

    try {
      const response = await api.owners();
      return response && response.length ? response[0].OwnerId : null;
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getServicesByOwner = async () => {
    const { showError } = this.props;
    const { regionValue, categoryValue } = this.state;
    if (!regionValue || !categoryValue) return;
    try {
      this.toggleLoader();
      const OwnerId = await this.getOwnerId();

      const model = {
        RegionId: regionValue,
        CategoryMenuId: categoryValue,
        OwnerId,
      };
      const services = await api.getServicesByOwner(model);
      this.setState({ loading: false, services });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getButtonByCriteria = async () => {
    const { showError } = this.props;
    const { regionValue, categoryValue, serviceValue } = this.state;
    try {
      this.toggleLoader();
      const model = {
        RegionId: regionValue,
        CategoryMenuId: categoryValue,
        ServiceId: serviceValue,
      };
      const buttons = await api.findButtonByCriteria(model);
      this.setState({ loading: false, buttons });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { regionValue, categoryValue, serviceValue, buttonValue } = this.state;
    if (!categoryValue && prevState.regionValue !== regionValue && regionValue !== this.defaultRegionValue()) {
      this.getCategories();
    }

    if (!serviceValue && prevState.categoryValue !== categoryValue && categoryValue !== this.defaultCategoryValue()) {
      this.getServicesByOwner();
    }

    if (!buttonValue && prevState.serviceValue !== serviceValue && serviceValue !== this.defaultServiceValue()) {
      this.getButtonByCriteria();
    }
    // if statement to rebuild query if one of state selected values was changed
    if (regionValue && serviceValue && categoryValue && prevState.regionValue !== regionValue) {
      this.setState(
        {
          buttonValue: this.defaultButtonValue(),
        },
        this.asyncCallback,
      );
    }
    if (regionValue && serviceValue && categoryValue && buttonValue && prevState.serviceValue !== serviceValue) {
      this.setState(
        {
          buttonValue: this.defaultButtonValue(),
        },
        this.getButtonByCriteria,
      );
    }
    if (regionValue && serviceValue && categoryValue && prevState.categoryValue !== categoryValue) {
      this.setState(
        {
          buttonValue: this.defaultButtonValue(),
        },
        this.asyncCallback,
      );
    }
  }

  asyncCallback = async () => {
    await this.getServicesByOwner();
    await this.getButtonByCriteria();
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  defaultRegionValue = () => {
    const { translate } = this.props;
    return translate('page.screenControlBusinessServices.default-region-value');
  };

  defaultCategoryValue = () => {
    const { translate } = this.props;
    return translate('page.screenControlBusinessServices.default-category-value');
  };

  defaultServiceValue = () => {
    const { translate } = this.props;
    return translate('page.screenControlBusinessServices.default-service-value');
  };

  defaultButtonValue = () => {
    const { translate } = this.props;
    return translate('page.screenControlBusinessServices.default-button-value');
  };

  onSelectChangeRegion = (regionValue) => {
    this.setState({ regionValue });
  };

  onSelectChangeCategory = (categoryValue) => {
    this.setState({ categoryValue });
  };

  onSelectChangeService = (serviceValue) => {
    this.setState({ serviceValue });
  };

  onSelectChangeButton = (buttonValue) => {
    this.setState({ buttonValue });
  };

  render() {
    const { translate, visible, closeModal, changeImage, dataItem } = this.props;
    const {
      loading,
      isLoading,
      categoryValue,
      serviceValue,
      buttonValue,
      regionValue,
      regions,
      categories,
      services,
      buttons,
    } = this.state;

    //Constants for cascad visibility of Selects
    const visibleCategorySelect = regionValue && regionValue !== this.defaultRegionValue();
    const visibleServiceSelect = categoryValue && categoryValue !== this.defaultCategoryValue();
    const visibleButtonSelect = serviceValue && serviceValue !== this.defaultServiceValue();
    const isDisabledButton = !buttonValue || buttonValue === this.defaultButtonValue();
    return (
      <>
        <Modal
          title={translate('page.screenControlBusinessServices.modal-set-btn-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.screenControlBusinessServices.modal-set-btn-back-btn')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              disabled={isDisabledButton}
              onClick={() => changeImage(dataItem.RowIndex, buttonValue)}
            >
              {translate('page.screenControlBusinessServices.modal-set-btn-submit-btn')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24}>
              {/* START First Select */}
              <Select
                className="modal-system-encashment--select"
                placeholder={this.defaultRegionValue()}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChangeRegion}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                showSearch
              >
                {regions.map((region) => (
                  <Option value={region.RegionId} key={`region-key-${region.RegionId}-${region.RegionName}`}>
                    {region.RegionName}
                  </Option>
                ))}
              </Select>

              {/* END First Select */}

              {/* START Second Select */}

              {visibleCategorySelect && (
                <Select
                  className="modal-system-encashment--select"
                  placeholder={this.defaultCategoryValue()}
                  style={{ marginTop: '20px' }}
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  onChange={this.onSelectChangeCategory}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                >
                  {categories.map((category) => (
                    <Option value={category.MenuId} key={`category-number-${category.MenuId}-${category.Name}`}>
                      {category.Name}
                    </Option>
                  ))}
                </Select>
              )}

              {/* END Second Select */}

              {/* START Third Select */}

              {visibleServiceSelect && (
                <Select
                  className="modal-system-encashment--select"
                  placeholder={this.defaultServiceValue()}
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  style={{ marginTop: '20px' }}
                  onChange={this.onSelectChangeService}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                >
                  {services.map((service) => (
                    <Option
                      value={service.ServiceId}
                      key={`service-number-${service.ServiceId}-${service.ServiceName}`}
                    >
                      {service.ServiceName}
                    </Option>
                  ))}
                </Select>
              )}

              {/* END Third Select */}

              {/* START Fourth Select */}

              {visibleButtonSelect && (
                <Select
                  disabled={visibleCategorySelect && visibleServiceSelect && visibleButtonSelect && buttons.length < 1}
                  className="modal-system-encashment--select"
                  placeholder={this.defaultButtonValue()}
                  // value={buttonValue}
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  onChange={this.onSelectChangeButton}
                  filterOption={(input, option) =>
                    JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  style={{ marginTop: '20px' }}
                >
                  {buttons.map((button) => {
                    return (
                      <Option value={JSON.stringify(button)} key={`button-number-${button.MenuId}`}>
                        {button.MenuName} - {button.MenuId}
                      </Option>
                    );
                  })}
                </Select>
              )}

              {/* END Fourth Select */}
              {visibleCategorySelect && visibleServiceSelect && visibleButtonSelect && buttons.length < 1 && (
                <p
                  style={{
                    marginBottom: 0,
                    color: 'red',
                  }}
                >
                  {translate('page.screenControlBusinessServices.modal-set-btn-error-noButtons')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChangeImage);
