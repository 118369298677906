export const nominals = [
  { Name: 'page.banknotesRestrictions.nominal1', value: 'Nominal1', Id: 1 },
  { Name: 'page.banknotesRestrictions.nominal2', value: 'Nominal2', Id: 2 },
  { Name: 'page.banknotesRestrictions.nominal5', value: 'Nominal5', Id: 3 },
  { Name: 'page.banknotesRestrictions.nominal10', value: 'Nominal10', Id: 4 },
  { Name: 'page.banknotesRestrictions.nominal20', value: 'Nominal20', Id: 5 },
  { Name: 'page.banknotesRestrictions.nominal50', value: 'Nominal50', Id: 6 },
  { Name: 'page.banknotesRestrictions.nominal100', value: 'Nominal100', Id: 7 },
  { Name: 'page.banknotesRestrictions.nominal200', value: 'Nominal200', Id: 8 },
  { Name: 'page.banknotesRestrictions.nominal500', value: 'Nominal500', Id: 9 },
  { Name: 'page.banknotesRestrictions.nominal1000', value: 'Nominal1000', Id: 10 },
];
export const selectDataNominalsConfig = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.banknotesRestrictions.nominal',
    width: '250',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];

export const selectDataServicesConfig = [
  {
    fieldName: 'ServiceId',
    title: '#',
    width: '50',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'ServiceName',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'ServiceGroup',
    titleTranslateSlug: 'page.commissionsServices.col-group',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Status',
    titleTranslateSlug: 'page.commissionsServices.col-status',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Provider',
    titleTranslateSlug: 'page.commissionsServices.col-provider',
    width: '200',
    isShowOnMobile: true,
  },
  // {
  //   fieldName: 'Rules',
  //   title: 'rules',
  //   width: '250',
  //   isShowOnMobile: true,
  // },
];
