const remoteTerminalSettings = (translate, value) => {
  switch (value) {
    case 'remoteTerminalSettings-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'remoteTerminalSettings-terminalSettingsExtendedKeyAdd':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;

    default:
      return value;
  }
};

export default remoteTerminalSettings;
