import React, { Component } from 'react';
import { Radio, Row, Col, Button, InputNumber, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './changeComission.scss';
import FixedComission from './fixedComission';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import ScalableComission from './scalableComission';
import IntervalComission from './intervalComission';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

/**
 * public enum CommissionType
    {
        [EnumMember]
        Fixed = 1,
        [EnumMember]
        Percent = 2,
        [EnumMember]
        FixedAndPercent = 3
    }
 */

const api = new ApiService();

class ChangeComission extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('adding-addressed-payment');
  }

  state = {
    radioValue: 1,
    isLoading: false,
    fixedData: [],
    clientFixedCommissionAmount: null,
    clientScalableCommissionAmount: null,
    minClientScalableCommissionAmount: 0,
    dealerFixedCommissionAmount: null,
    dealerScalableCommissionAmount: null,
    minDealerScalableCommissionAmount: 0,
    intervalComission: [],
    receivedData: [],
    averagePercentCom: 0,
    averageMinCom: 0,
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  componentDidMount() {
    this.initRequest();
  }

  onSelectChangeFixed = (clientFixedCommissionAmount) => {
    this.setState(() => {
      return {
        clientFixedCommissionAmount,
        dealerFixedCommissionAmount: this.state.fixedData.find(
          (el) => el.ClientCommissionAmount === clientFixedCommissionAmount,
        ).DealerCommissionAmount,
      };
    });
  };

  onSelectChangeScalable = (clientScalableCommissionAmount) => {
    this.setState(() => {
      return {
        clientScalableCommissionAmount,
        dealerScalableCommissionAmount: this.state.fixedData.find(
          (el) => el.ClientCommissionAmount === clientScalableCommissionAmount,
        ).DealerCommissionAmount,
      };
    });
  };

  onChangeByName = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  initRequest = async () => {
    const { showError, item } = this.props;
    try {
      this.toggleLoader();
      const fixedData = await api.fixedCommissionsDictionary();
      // let receivedData = null;
      let mappedReceivedData = [];
      // if (!Array.isArray(item)) {
      const receivedData = await api.servicesCRMRequestCommissionsGet(item);
      // }

      let clientFixedCommissionAmount,
        dealerFixedCommissionAmount,
        clientScalableCommissionAmount,
        dealerScalableCommissionAmount,
        minClientScalableCommissionAmount,
        minDealerScalableCommissionAmount,
        radioValue,
        startElemInterval;

      /*
       * case 1, comission: Fixed already exists
       * or case2: comission: Percent already exists
       */

      if (
        receivedData &&
        receivedData.length > 0 &&
        receivedData.length < 2 &&
        (receivedData[0].CommissionType === 1 || receivedData[0].CommissionType === 2)
      ) {
        const firstElem = receivedData[0];
        clientFixedCommissionAmount = firstElem.FixedCommissionClient;
        dealerFixedCommissionAmount = firstElem.FixedCommissionDealer;
        radioValue = receivedData[0].CommissionType;
        clientScalableCommissionAmount = firstElem.FixedCommissionClient;
        dealerScalableCommissionAmount = firstElem.FixedCommissionDealer;
        minClientScalableCommissionAmount = firstElem.PercentCommissionClient;
        minDealerScalableCommissionAmount = (firstElem.PercentCommissionClient * -0.3).toFixed(1);
        startElemInterval = {
          id: 1,
          from: firstElem.AmountFrom >= 0.01 ? firstElem.AmountFrom : 0.01,
          to: firstElem.AmountTo,
          comClient: minClientScalableCommissionAmount,
          minComClient: clientFixedCommissionAmount,
          comDealer: minDealerScalableCommissionAmount,
          minComDealer: dealerFixedCommissionAmount,
          AmountMin: firstElem.AmountMin,
          AmountMax: firstElem.AmountMax,
        };
      } else if (receivedData && receivedData.length > 0) {
        let id = 1;
        const firstElem = receivedData[0];
        clientFixedCommissionAmount = firstElem.FixedCommissionClient;
        dealerFixedCommissionAmount = firstElem.FixedCommissionDealer;
        radioValue = receivedData[0].CommissionType;
        clientScalableCommissionAmount = firstElem.FixedCommissionClient;
        dealerScalableCommissionAmount = firstElem.FixedCommissionDealer;
        minClientScalableCommissionAmount = firstElem.PercentCommissionClient;
        minDealerScalableCommissionAmount = (firstElem.PercentCommissionClient * -0.3).toFixed(1);
        mappedReceivedData = receivedData.map((el) => {
          return {
            CommissionType: 3,
            MinCommissionClient: el.MinCommissionClient,
            AVGCommissionClient: el.AVGCommissionClient,
            id: id++,
            from: el.AmountFrom,
            to: el.AmountTo,
            comClient: el.PercentCommissionClient,
            minComClient: el.FixedCommissionClient,
            comDealer: (el.PercentCommissionClient * -0.3).toFixed(1),
            minComDealer: el.FixedCommissionDealer,
            AmountMin: el.AmountMin,
            AmountMax: el.AmountMax,
          };
        });
        radioValue = 3;
      } else {
        clientFixedCommissionAmount = fixedData && fixedData.length > 0 ? fixedData[0].ClientCommissionAmount : 0;
        dealerFixedCommissionAmount = fixedData && fixedData.length > 0 ? fixedData[0].DealerCommissionAmount : 0;
        clientScalableCommissionAmount = fixedData && fixedData.length > 0 ? fixedData[0].ClientCommissionAmount : 0;
        dealerScalableCommissionAmount = fixedData && fixedData.length > 0 ? fixedData[0].DealerCommissionAmount : 0;
        startElemInterval = {
          id: 1,
          from: 0.01,
          // to: 4999.99,
          to: 5000,
          comClient: 0,
          minComClient: clientFixedCommissionAmount,
          comDealer: 0,
          minComDealer: dealerFixedCommissionAmount,
          AmountMin: 0.01,
          // AmountMax: 4999.99
          AmountMax: 5000,
        };
        radioValue = 1;
      }

      this.setState(
        () => {
          return {
            fixedData,
            clientFixedCommissionAmount,
            dealerFixedCommissionAmount,
            clientScalableCommissionAmount,
            dealerScalableCommissionAmount,
            intervalComission: radioValue === 3 ? mappedReceivedData : [startElemInterval],
            receivedData,
            radioValue,
            minClientScalableCommissionAmount,
            minDealerScalableCommissionAmount,
          };
        },
        () => {
          this.updateIntervalComission(radioValue === 3 ? [...mappedReceivedData] : [startElemInterval]);
        },
      );
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  onChangeAveragePercentCom = (intervalComission = []) => {
    let initialValue = 0;
    const comDealers = intervalComission.map((el) => (typeof el === 'string' ? +el : +el.comDealer));
    const averagePercentCom = comDealers.reduce((acc, next) => acc + next, initialValue);
    this.setState({
      averagePercentCom: intervalComission.length < 1 ? 0 : averagePercentCom / intervalComission.length,
    });
  };

  onChangeAverageMinCom = (intervalComission = []) => {
    const minComDealers = intervalComission.map((el) => +el.minComDealer);
    const averageMinCom = intervalComission.length < 1 ? 0 : Math.max(...minComDealers);
    this.setState({ averageMinCom });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateIntervalComission = (intervalComission = []) => {
    this.setState({ intervalComission }, () => {
      this.onChangeAveragePercentCom(intervalComission);
      this.onChangeAverageMinCom(intervalComission);
    });
  };

  onSubmit = async () => {
    // case 1
    const {
      radioValue,
      receivedData,
      clientFixedCommissionAmount,
      dealerFixedCommissionAmount,
      clientScalableCommissionAmount,
      dealerScalableCommissionAmount,
      minClientScalableCommissionAmount,
      intervalComission,
    } = this.state;
    const { item, showError, translate } = this.props;
    let model;
    const firstElem = receivedData && receivedData[0];

    switch (radioValue) {
      case 1:
        model = {
          ...firstElem,
          AmountTo: 5000,
          RequestId: item,
          CommissionType: radioValue,
          FixedCommissionClient: clientFixedCommissionAmount,
          FixedCommissionDealer: dealerFixedCommissionAmount,
        };
        break;
      case 2:
        model = {
          ...firstElem,
          AmountTo: 5000,
          RequestId: item,
          CommissionType: radioValue,
          FixedCommissionClient: clientScalableCommissionAmount,
          FixedCommissionDealer: dealerScalableCommissionAmount,
          PercentCommissionClient: minClientScalableCommissionAmount,
        };
        break;

      case 3:
        const modelToCheck = intervalComission.map((el) => {
          return {
            RequestId: item,
            CommissionType: radioValue,
            AmountFrom: el.from,
            AmountTo: el.to,
            // AmountTo: idx === intervalComission.length - 1 ? 5000 : el.to,
            FixedCommissionClient: el.minComClient,
            FixedCommissionDealer: el.minComDealer,
            PercentCommissionClient: +el.comClient.toFixed(1),
            MinCommissionClient: el.MinCommissionClient,
            AVGCommissionClient: el.AVGCommissionClient,
          };
        });
        if (modelToCheck.find((el) => el.AmountFrom > el.AmountTo) !== undefined) {
          message.error(`${translate('page.aap-changeComission.interval-amount-error')}`, 2);
          return;
        }
        model = intervalComission.map((el, idx) => {
          return {
            RequestId: item,
            CommissionType: radioValue,
            AmountFrom: el.from,
            AmountTo: idx === intervalComission.length - 1 ? 5000 : el.to,
            FixedCommissionClient: el.minComClient,
            FixedCommissionDealer: el.minComDealer,
            PercentCommissionClient: +el.comClient.toFixed(1),
            MinCommissionClient: el.MinCommissionClient,
            AvgCommissionClient: el.AvgCommissionClient,
          };
        });
        break;
      default:
        return;
    }

    try {
      this.toggleLoader();
      let arrModel = radioValue !== 3 && !Array.isArray(model) ? [model] : model;
      await api.servicesCRMRequestCommissionsMerge(arrModel);
      window.location.reload(true);
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const {
      radioValue,
      isLoading,
      fixedData,
      clientFixedCommissionAmount,
      dealerFixedCommissionAmount,
      clientScalableCommissionAmount,
      dealerScalableCommissionAmount,
      minClientScalableCommissionAmount,
      minDealerScalableCommissionAmount,
      intervalComission,
      averagePercentCom,
      averageMinCom,
    } = this.state;
    const { translate } = this.props;
    const isDisabledFixed = radioValue === 1 ? '' : 'disabled';
    const isDisabledPercent = radioValue === 2 ? '' : 'disabled';
    const isDisabledInterval = radioValue === 3 ? '' : 'disabled';

    return (
      <div className="changeComission--wrapper">
        <Row
          type="flex"
          align="middle"
          justify="end"
          style={{
            marginTop: '20px',
            padding: '0 24px',
          }}
        >
          {checkElement('aap-changeComission-submit', this.currentArray) && (
            <Button type="primary" onClick={this.onSubmit}>
              {translate('page.screenControlTerminals.btn-submit-dsb')}
            </Button>
          )}
        </Row>
        <Row
          type="flex"
          align="middle"
          style={{
            marginTop: '20px',
            padding: '0 24px',
          }}
        >
          <Col xs={24} md={24} lg={12}>
            <h2 className="changeCom-h2">
              {translate('page.aap-changeComission.field-requestId')}: {this.props.item}
            </h2>
          </Col>
        </Row>
        <Row
          type="flex"
          align="middle"
          style={{
            marginTop: '20px',
            padding: '0 24px',
          }}
        >
          <Col xs={24} md={24} lg={12}>
            <h2 className="changeCom-h2">{translate('page.aap-changeComission.changeCom-h2')}</h2>
          </Col>
        </Row>
        <Radio.Group onChange={this.onChange} value={radioValue} className="aap-change-radio" name="radioValue">
          {/* start first row */}

          <Row type="flex" className="comission-radio-block" align="middle">
            <Col xs={24} md={24} lg={24} xl={8}>
              <Radio value={1}>
                <p className="header-text">{translate('page.aap-changeComission.radio-fixed-text')}</p>
              </Radio>
            </Col>
            <Col xs={24} md={24} lg={24} xl={16}>
              <FixedComission
                data={fixedData}
                isDisabled={isDisabledFixed}
                onSelectChangeFixed={this.onSelectChangeFixed}
                clientFixedCommissionAmount={clientFixedCommissionAmount}
                dealerFixedCommissionAmount={dealerFixedCommissionAmount}
              />
            </Col>
          </Row>

          {/* end first row */}

          {/* start second row */}

          <Row type="flex" className="comission-radio-block">
            <Col xs={24} md={24} lg={8}>
              <Radio value={2}>
                <p className="header-text">{translate('page.aap-changeComission.radio-percent-text')}</p>
              </Radio>
            </Col>
            <Col xs={24} md={24} lg={16}>
              <ScalableComission
                data={fixedData}
                isDisabled={isDisabledPercent}
                onChangeByName={this.onChangeByName}
                onSelectChangeScalable={this.onSelectChangeScalable}
                clientScalableCommissionAmount={clientScalableCommissionAmount}
                dealerScalableCommissionAmount={dealerScalableCommissionAmount}
                minClientScalableCommissionAmount={minClientScalableCommissionAmount}
                minDealerScalableCommissionAmount={minDealerScalableCommissionAmount}
              />
            </Col>
          </Row>

          {/* end second row */}

          {/* start third row */}

          <Row type="flex" className="comission-radio-block">
            <Col xs={24}>
              <Row type="flex" align="middle">
                <Col xs={24} lg={12}>
                  <Radio value={3}>
                    <p className="header-text">{translate('page.aap-changeComission.radio-interval-text')}</p>
                  </Radio>
                </Col>
                <Col xs={24} lg={12} className={`interval-col-dis ${isDisabledInterval}`}>
                  <Row type="flex" align="middle">
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Row type="flex" align="middle">
                        <p>{translate('page.aap-changeComission.interval-avgPerComDealer')}</p>
                        <InputNumber
                          disabled
                          className="fixedComission--select"
                          // !считается с dealerScalableCommissionAmount
                          value={averagePercentCom}
                          precision={1}
                          // step="0.1"
                        />
                        <p className="interval-afterInputText">%</p>
                      </Row>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Row type="flex" align="middle">
                        <p>min</p>
                        <InputNumber disabled value={averageMinCom} className="fixedComission--select" />
                        <p className="interval-afterInputText">
                          {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="interval-comission-col" xs={24}>
              <IntervalComission
                isDisabled={isDisabledInterval}
                data={intervalComission}
                updateIntervalComission={this.updateIntervalComission}
                fixedData={fixedData}
              />
            </Col>
          </Row>

          {/* end third row */}

          {isLoading && <LoadingPanel />}
        </Radio.Group>
      </div>
    );
  }
}

export default withTranslate(ChangeComission);
