class NewScheduleService {
  getDefaultStartDate = () => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 7, 0, 0);
  getDefaultEndDate = () => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 0, 0);
  verifyWeekSchedule = (from, to) => {
    if (!from || !to) {
      return 'page.newTerminals.incorrectDateFormat';
    }

    if (new Date(to) - new Date(from) < 1000 * 60 * 60) {
      return 'page.newTerminals.timeDifference';
    }

    return '';
  };

  verifyDaysSchedule = (daySchedules) => {
    if (daySchedules.find(({ TurnOn, TurnOff }) => !TurnOn || !TurnOff)) {
      return 'page.newTerminals.incorrectDateFormat';
    }

    if (
      daySchedules.find(
        ({ TurnOn, TurnOff, DayOff }) => new Date(TurnOff) - new Date(TurnOn) < 1000 * 60 * 60 && !DayOff,
      )
    ) {
      return 'page.newTerminals.timeDifference';
    }

    return '';
  };
}

export default new NewScheduleService();
