export const SUPPLIERS_COMMISSION_FIELDS = [
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionPercent',
    fieldName: 'SuppliersProviderPercent',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMin',
    fieldName: 'SuppliersProviderMin',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMax',
    fieldName: 'SuppliersProviderMax',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
    fieldName: 'SuppliersProviderAmountTotal',
  },
];
export const SUPPLIERS_CARD_COMMISSION_FIELDS = [
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionPercent',
    fieldName: 'SuppliersProviderPercentForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMin',
    fieldName: 'SuppliersProviderMinForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMax',
    fieldName: 'SuppliersProviderMaxForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
    fieldName: 'SuppliersProviderAmountTotalForCards',
  },
];

export const RANGE_COMMISSIONS_FIELDS = [
  {
    label: 'page.supplierCommissions.amountFrom',
    fieldName: 'AmountFrom',
  },
  {
    label: 'page.supplierCommissions.amountTo',
    fieldName: 'AmountTo',
  },
];

export const MIN_PERCENT = -100;
export const MAX_PERCENT = 100;
export const MIN_VAlUE = -99999.9999;
export const MAX_VAlUE = 99999.9999;

export const INITIAL_VALUES_SUPPLIER_COMMISSIONS = {
  SuppliersProviderPercent: 0,
  SuppliersProviderMin: 0,
  SuppliersProviderMax: 0,
  SuppliersProviderAmountTotal: '[amount_total]',
  SuppliersProviderPercentForCards: 0,
  SuppliersProviderMinForCards: 0,
  SuppliersProviderMaxForCards: 0,
  SuppliersProviderAmountTotalForCards: '[amount_total]',
};

export const INITIAL_VALUES_SUPPLIER_COMMISSIONS_SCALE = {
  SuppliersProviderPercent: 0,
  SuppliersProviderMin: 0,
  SuppliersProviderMax: 0,
  SuppliersProviderAmountTotal: '[amount_total]',
  SuppliersProviderPercentForCards: 0,
  SuppliersProviderMinForCards: 0,
  SuppliersProviderMaxForCards: 0,
  SuppliersProviderAmountTotalForCards: '[amount_total]',
  AmountTo: 0,
  AmountFrom: 0,
};
