import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { Button, Col, Row } from 'antd';
import Grid from '../../components/grids/baseGrid';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';

import './DealerCommissionsPaymentTools.scss';
import { ApiService } from '../../services';
import { GridColumn } from '@progress/kendo-react-grid';
import ModalPaymentTools from './ModalPaymentTools/ModalPaymentTools';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class DealerCommissionsPaymentTools extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('dealerCommissionsPaymentTools');
  }
  state = {
    allOwners: [],
    currentOwners: [],
    ownersPaymentTypeList: [],
    selectedItems: [],
    isLoading: false,
    showModal: '',
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const allOwners = await api.owners();
      await this.setState({ allOwners, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchOwnersOwnersPaymentTypeList = async (currentOwner) => {
    const { currentOwners } = this.state;
    try {
      this.setState({ isLoading: true });
      const ownersPaymentTypeList =
        currentOwner && currentOwner.length
          ? await api.ownersPaymentTypeList(currentOwner)
          : await api.ownersPaymentTypeList([...currentOwners]);
      this.setState({ ownersPaymentTypeList: this.normalizeData(ownersPaymentTypeList), isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  normalizeData = (data) => {
    if (!data && data.length) return [];
    return data.map((item, indx) => ({ Id: `${item.OwnerId}-${indx}`, ...item }));
  };

  componentDidMount() {
    this.fetchOwners();
  }

  showModal = (evtType) => {
    this.setState({ showModal: evtType });
  };
  closeModal = () => {
    this.setState({ showModal: '' });
  };
  handleSelectOwners = (currentOwners) => {
    this.setState({ currentOwners });
  };
  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  buildToolbar = () => {
    const { allOwners, currentOwners, selectedItems } = this.state;
    const { translate } = this.props;
    return (
      <Row xl={17} className="DealerCommissionsPaymentTools-toolbar">
        <Col span={24} sm={14} lg={14} className="DealerCommissionsPaymentTools-row">
          <Col className="DealerCommissionsPaymentTools-dropdown">
            <Col className="DealerCommissionsPaymentTools-label">{translate('page.commissionsServices.dealer')}</Col>
            <Col className="DealerCommissionsPaymentTools-ownerDropdown">
              <GridDropdown
                data={DropdownNormalizersService.normalizeOwners(allOwners)}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={currentOwners}
                onSave={this.handleSelectOwners}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
                isGetDefaultSelectItemsFromStorage
              />
            </Col>
          </Col>

          {checkElement('dealerCommissionsPaymentTools-btnSearch', this.currentArray) && (
            <Button
              type="primary"
              onClick={this.fetchOwnersOwnersPaymentTypeList}
              className="dealerCommissionsPaymentTools-searchButton"
            >
              {translate('core.buttonTitles.search')}
            </Button>
          )}
        </Col>

        <Col span={24}>
          {checkElement('dealerCommissionsPaymentTools-btnAdd', this.currentArray) && (
            <Button type="primary" onClick={() => this.showModal('add')}>
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('dealerCommissionsPaymentTools-btnDelete', this.currentArray) && (
            <Button type="primary" disabled={!selectedItems.length} onClick={() => this.showModal('remove')}>
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { ownersPaymentTypeList, allOwners, isLoading, visible, showModal, selectedItems } = this.state;
    if (!checkElement('dealerCommissionsPaymentTools-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={ownersPaymentTypeList}
          name="DealerCommissionsPaymentToolsGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchOwnersOwnersPaymentTypeList}
          fieldForSelection="Id"
          multiSelected
          onSelect={this.handleSelectedItems}
          isLoading={isLoading}
        >
          <GridColumn
            field="selected"
            title=""
            width="50px"
            filterable={false}
            columnMenu={false}
            sortable={false}
            showAllSelected={true}
          />
          <GridColumn field="OwnerId" title="grids.dealerCommissionsPaymentTools.column-dealer-number" width="150px" />
          <GridColumn field="OwnerName" title="grids.dealerCommissionsPaymentTools.column-dealer-name" width="200px" />
          <GridColumn
            field="PaymentType"
            title="grids.dealerCommissionsPaymentTools.column-payment-type"
            width="200px"
          />
          <GridColumn
            field="PaymentTypeName"
            title="grids.dealerCommissionsPaymentTools.column-payment-type-name"
            width="250px"
          />
          <GridColumn
            field="PaymentTypeDescription"
            title="grids.dealerCommissionsPaymentTools.column-payment-type-description"
            width="200px"
          />
        </Grid>
        {showModal && (
          <ModalPaymentTools
            fetchOwners={this.fetchOwners}
            allOwners={allOwners}
            handleSelectOwners={this.handleSelectOwners}
            closeModal={this.closeModal}
            showModal={showModal}
            update={this.fetchOwnersOwnersPaymentTypeList}
            selectedItems={selectedItems}
          />
        )}
      </>
    );
  }
}
export default withTranslate(DealerCommissionsPaymentTools);
