import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { ApiService, StorageService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import GqlService from '../../components/grids/gql.service';
import { BASE_FIELDS } from './constants';
import TransactionsWithCashbackService from './TransactionsWithCashback.service';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';

import './TransactionsWithCashbackGrid.styled.scss';

const api = new ApiService();

class TransactionsWithCashbackGrid extends PureComponent {
  currentArray = findArray('activitymonitor');
  state = {
    transactions: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    dateStart: new Date(),
    dateEnd: new Date(),
    errorMessage: '',
  };

  getGQLStuff = (props) => {
    const { filtersString, sortString, dateStart, dateEnd } = this.state;
    const storageColumns = StorageService.getItem('transactionsWithCashback');

    const currentColumns =
      (props && props.currentFieldList) ||
      (storageColumns ? GqlService.getColumnsFromStorage(storageColumns) : BASE_FIELDS);

    const formatDateStart = getDataByTimezoneOffset(
      new Date(
        new Date(dateStart).getFullYear(),
        new Date(dateStart).getMonth(),
        new Date(dateStart).getDate(),
        0,
        0,
        0,
        0,
      ),
    ).toISOString();

    const formatDateEnd = getDataByTimezoneOffset(
      new Date(
        new Date(dateEnd).getFullYear(),
        new Date(dateEnd).getMonth(),
        new Date(dateEnd).getDate(),
        23,
        59,
        59,
        59,
      ),
    ).toISOString();

    const argumentsString = `order: ${
      sortString || '{ transactionId: DESC }'
    }, where:{and:[{workDate:{gte: "${formatDateStart}"}}, {workDate:{lte: "${formatDateEnd}"}}, ${
      filtersString || ''
    }]},`;

    return { currentColumns, argumentsString };
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.fetchTransactionsGQL();
    }
  }

  iterateFetchTransactionsGQL = async (skip, transactionList, argumentsString, currentColumns) => {
    try {
      const query = `transactionsCashBack(take: 500,skip:${skip},${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { transactions, hasNextPage } = TransactionsWithCashbackService.getGQLResponse(data);

      return hasNextPage
        ? this.iterateFetchTransactionsGQL(
            skip + 500,
            [...transactionList, ...transactions],
            argumentsString,
            currentColumns,
          )
        : [...transactionList, ...transactions];
    } catch (e) {
      throw e;
    }
  };

  fetchAllTransactionsGQL = async (props) => {
    const { currentColumns, argumentsString } = this.getGQLStuff(props);

    try {
      await this.setState({ isLoading: true });
      const supplierList = await this.iterateFetchTransactionsGQL(0, [], argumentsString, currentColumns);
      await this.setState({ isLoading: false });

      return supplierList;
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchTransactionsGQL = async (props) => {
    const { rowAmount, pageValue } = this.state;
    const { argumentsString, currentColumns } = this.getGQLStuff(props);

    this.setState({ isLoading: true });

    try {
      const query = `transactionsCashBack(${argumentsString} take:${rowAmount}, skip:${
        rowAmount * (pageValue - 1)
      }){items{${currentColumns.join(',')}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { transactions, hasPreviousPage, hasNextPage } = TransactionsWithCashbackService.getGQLResponse(data);

      this.setState({ transactions, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getHandleExelExportDate = async () => {
    return await this.fetchAllTransactionsGQL();
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: TransactionsWithCashbackService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { dateStart, dateEnd, errorMessage } = this.state;

    const { translate } = this.props;
    return (
      <Row className="TransactionsWithCashbackGrid-toolbar">
        <div className="TransactionsWithCashbackGrid-dateRow">
          <div className="TransactionsWithCashbackGrid-dateFiledRow">
            <div className="TransactionsWithCashbackGrid-label">
              {translate('page.changingProfileHistory.dateFrom')}
            </div>

            <div>
              <DatePicker
                format="yyyy.MM.dd"
                className="TransactionsWithCashbackGrid-datePicker"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={TransactionsWithCashbackService.getMinimumSelectedDate()}
              />
            </div>
          </div>

          <div className="TransactionsWithCashbackGrid-dateFiledRow">
            <div className="TransactionsWithCashbackGrid-label">{translate('page.changingProfileHistory.dateTo')}</div>

            <div>
              <DatePicker
                format="yyyy.MM.dd"
                className="TransactionsWithCashbackGrid-datePickerr"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={TransactionsWithCashbackService.getMinimumSelectedDate()}
              />
            </div>
          </div>
        </div>

        <div>
          <Button type="primary" disabled={!!errorMessage} onClick={this.fetchTransactionsGQL}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {errorMessage && <div className="TransactionsWithCashbackGrid-error">{errorMessage}</div>}
      </Row>
    );
  };

  onFieldsConfigChange = (list) => {
    this.fetchTransactionsGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  openTransactionDetailsTab = ({ dataItem }) => {
    tabService.addTab({
      type: 'details',
      dataItem,
    });
  };

  render() {
    const { transactions, isLoading, pageValue, hasNextPage, hasPreviousPage } = this.state;

    const aggregate = [
      { field: 'amountTotal', aggregate: 'sum', format: 'n2' },
      { field: 'amountCashbackClient', aggregate: 'sum', format: 'n2' },
      { field: 'amountCashbackDealer', aggregate: 'sum', format: 'n2' },
      { field: 'amountOriginal', aggregate: 'sum', format: 'n2' },
      { field: 'commissionProvider', aggregate: 'sum', format: 'n2' },
      { field: 'commissionClient', aggregate: 'sum', format: 'n2' },
    ];

    if (!checkElement('monitorActivity-tab-type-transaction', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={transactions}
        isLoading={isLoading}
        name="transactionsWithCashback"
        toolbar={this.buildToolbar()}
        onRefresh={() => {
          this.fetchTransactionsGQL();
        }}
        pageChange={this.getStateSetterByName('pageValue')}
        handleRowAmountChange={this.handleRowAmountChange}
        setFiltersString={this.getStateSetterByName('filtersString')}
        setSortingString={this.getStateSetterByName('sortString')}
        pageValue={pageValue}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onFieldsConfigChange={this.onFieldsConfigChange}
        getHandleExelExportDate={this.getHandleExelExportDate}
        aggregate={aggregate}
        isGQL
      >
        <GridColumn field="ownerName" width="145" title="grids.transactions.column-ownerName" />
        <GridColumn
          field="transactionId"
          title="grids.transactions.column-transactionId"
          width="130"
          filter="numeric"
          {...(checkElement('monitorTerminalTransactions-transactionIdOnclick', this.currentArray)
            ? {
                onClick: this.openTransactionDetailsTab,
              }
            : {})}
        />
        <GridColumn field="requestId" title="grids.transactions.column-requestId" width="112" filter="numeric" />
        <GridColumn field="terminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />
        <GridColumn field="serviceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />
        <GridColumn field="serviceName" title="grids.transactions.column-serviceName" width="93" />
        <GridColumn
          field="amountTotal"
          title="grids.transactions.column-amountTotal"
          width="134"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="amountCashbackClient"
          title="grids.transactions.column-amountCashbackClient"
          width="160"
          filter="numeric"
        />
        <GridColumn
          field="amountCashbackDealer"
          title="grids.transactions.column-amountCashbackDealer"
          width="160"
          filter="numeric"
        />
        <GridColumn
          field="amountOriginal"
          title="grids.transactions.column-amountOriginal"
          width="180"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="commissionProvider"
          title="grids.transactions.column-comissionProvider"
          width="154"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="commissionClient"
          title="grids.transactions.column-comissionClient"
          width="171"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="dateAccepted"
          title="grids.transactions.column-dateAccepted"
          width="142"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="account" title="grids.transactions.column-account" width="112" />
        <GridColumn field="comment" title="grids.transactions.column-comment" width="125" />
        <GridColumn field="paymentType" title="grids.transactions.column-typePayment" width="125" />
      </Grid>
    );
  }
}

export default withTranslate(TransactionsWithCashbackGrid);
