import React, { createContext, Component } from 'react';

const LoginContext = createContext({
  loginError: null,
  loginContentStateIndex: null,
  setError: (error) => {},
  setContentStateIndex: (contentStateIndex) => {},
});

export class LoginContextProviderContainer extends Component {
  state = {
    error: null,
    contentStateIndex: 0,
  };

  handleSetError = (error) => {
    this.setState({ error });
  };

  handleSetContentStateIndex = (contentStateIndex) => {
    this.setState({ contentStateIndex });
  };

  render() {
    const { error, contentStateIndex } = this.state;
    const { children } = this.props;

    const loginContext = {
      loginError: error,
      loginContentStateIndex: contentStateIndex,
      setLoginError: this.handleSetError,
      setLoginContentStateIndex: this.handleSetContentStateIndex,
    };

    return <LoginContext.Provider value={loginContext}>{children}</LoginContext.Provider>;
  }
}

export const widthLoginContext = (Component) => (props) =>
  <LoginContext.Consumer>{(context) => <Component {...props} {...context} />}</LoginContext.Consumer>;
