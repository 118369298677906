class FishkaService {
  normalizeButtons(services) {
    if (!services) {
      return [];
    }

    return services.map((service) => ({
      ...service,
      Id: service.ServiceId,
      Name: service.ServiceName,
    }));
  }

  limitValidation = (translate) => (value) => {
    if (!value) {
      return `${translate('page.fishkaService.emptyValueError')}`;
    }

    if (value < 0) {
      return `${translate('page.fishkaService.enteredMinNumberError')}`;
    }

    if (value > 200) {
      return `${translate('page.fishkaService.enteredMaxNumberError')}`;
    }

    if (!!isNaN(value)) {
      return `${translate('page.fishkaService.invalidDataFormatError')}`;
    }

    if (`${Math.round(value * 1000000000000) / 10000000000}`.indexOf('.') > -1) {
      return `${translate('page.fishkaService.commaPartError')}`;
    }

    return '';
  };
}

export default new FishkaService();
