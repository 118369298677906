export const menuTemplateLists = [
  {
    label: 'Сервіс',
    value: 3,
  },
  {
    label: 'Субменю',
    value: 2,
  },
  {
    label: 'Категорія',
    value: 1,
  },
  {
    label: 'Посилання',
    value: 4,
  },
];
