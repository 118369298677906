import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridTerminalComissions from '../../components/TerminalsCommissionsGrid';
import GridServiceComissions from './gridServiceComissions';
import PageTabs from '../../components/tabber/tabber';
import ComissionServiceDetails from './CommissionServiceDetails/CommissionServiceDetails';
import CommissionFormMethodsContainer from '../../components/CommisionDetailsForm/CommissionFormMethodsContainer';

class PageComissions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.comissions.main',
          content: <GridTerminalComissions permissionName="commissionClient" />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'profile':
        const { ProfileId, ProfileName, currentOwnerId } = dataItem;
        tabs.push({
          title: 'page.comissions.services',
          content: (
            <GridServiceComissions currentOwnerId={currentOwnerId} profileId={ProfileId} profileName={ProfileName} />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'comissionDetails':
        tabs.push({
          title: 'page.comissions.commissions',
          content: (
            <CommissionFormMethodsContainer>
              <ComissionServiceDetails item={dataItem} />
            </CommissionFormMethodsContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageComissions);
