import React, { PureComponent } from 'react';
import { Input, Checkbox } from 'antd';

class EditableGridInput extends PureComponent {
  state = {
    value: '',
  };

  componentDidMount() {
    const { value: valueFromProps } = this.props;

    this.setState({ value: valueFromProps });
  }

  componentDidUpdate(prevProps) {
    const { value: valueFromProps } = this.props;

    if (prevProps.value !== valueFromProps) {
      this.setState({ value: valueFromProps });
    }
  }

  handleChange = ({ target: { value } }) => {
    const { onChange: onChangeFromProps } = this.props;

    this.setState({ value });
    onChangeFromProps(value);
  };

  handleCheckboxChange = () => {
    const { onChange: onChangeFromProps } = this.props;

    this.setState(
      ({ value }) => {
        return {
          value: !value,
        };
      },
      () => {
        onChangeFromProps(this.state.value);
      },
    );
  };

  render() {
    const { disabled, type } = this.props;
    const { value } = this.state;

    const CurrentInput = type === 'checkbox' ? Checkbox : Input;

    return (
      <CurrentInput
        checked={value}
        value={value}
        disabled={disabled}
        onChange={type === 'checkbox' ? this.handleCheckboxChange : this.handleChange}
        style={{
          width: `${type === 'checkbox' ? 'auto' : 'calc(100% + 24px)'}`,
          margin: `${type === 'checkbox' ? 'auto' : '0 -12px'}`,
        }}
      />
    );
  }
}

export default EditableGridInput;
