import React, { PureComponent } from 'react';
import { message } from 'antd';

import ChangeDealerCommissions from '../../components/ChangeDealerCommissions/ChangeDealerCommissions';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';

const api = new ApiService();

class ChangeDealerCommissionsContainer extends PureComponent {
  state = {
    isLoading: false,
  };

  handleApplyChanges = async (operations) => {
    const { dataItem, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      const model = operations.reduce((acc, rules) => {
        return [
          ...acc,
          ...dataItem.map(({ OwnerId, ServiceId, ServiceStatusId }) => ({
            OwnerId,
            ServiceId,
            StatusId: ServiceStatusId,
            ...rules,
          })),
        ];
      }, []);

      await api.serviceCommissionUpdate(model);
      await message.success(`${translate('page.dealerCommissionsChange.message-success-change-commissions')}`, 2.5);
      window.location.reload();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <ChangeDealerCommissions handleApplyChanges={this.handleApplyChanges} />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ChangeDealerCommissionsContainer);
