import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../../contexts/localContext';
import { Button, message, Modal } from 'antd';
import { Input } from '@progress/kendo-react-inputs';

import './ModalGroupServices.scss';
import { ApiService } from '../../../../../services';
import LoadingPanel from '../../../../../components/loader';

const api = new ApiService();

class ModalGroupServices extends PureComponent {
  state = {
    Name: '',
    Description: '',
    error: '',
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: '' });
  };

  handleValidate = () => {
    const { Name: formNameValue } = this.state;

    const { selectedItem, groups } = this.props;

    console.log('formNameValue', formNameValue);

    if (formNameValue === '') {
      return 'page.modalGroupServices.nameRequired';
    }

    if (
      groups.some(({ GroupId, Name }) => Name === formNameValue && (!selectedItem || selectedItem.GroupId !== GroupId))
    ) {
      return 'page.modalGroupServices.alreadyExists';
    }

    return '';
  };

  mergeGroupService = async () => {
    const { Name, Description } = this.state;
    const { name, selectedItem, onClose, toggleLoader, translate, onRefresh } = this.props;
    const groupId = name === 'add' ? 0 : selectedItem.GroupId;
    const error = this.handleValidate();

    if (error) {
      this.setState({ error });
      return;
    }

    try {
      const model = {
        GroupId: groupId,
        Name,
        Description,
      };
      await toggleLoader(true);
      await api.servicesGroupsMerge(model);
      await toggleLoader(false);
      await onClose('');
      onRefresh();
      message.success(
        `${translate(
          name === 'add' ? 'page.modalGroupServices.addedSuccessfully' : 'page.modalGroupServices.changedSuccessfully',
        )}`,
        2.5,
      );
    } catch (e) {
      const { showError } = this.props;
      toggleLoader(false);
      showError(e);
    }
  };

  componentDidMount() {
    const { name } = this.props;

    if (name === 'change') {
      const {
        selectedItem: { Name, Description },
      } = this.props;

      this.setState({ Name, Description });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { Name } = this.state;
    const { Name: prevName } = prevState;

    if (Name !== prevName) {
      this.setState({ isRequired: '' });
    }
  }

  render() {
    const { Name, Description, error } = this.state;
    const { translate, visible, onClose, name, isLoading } = this.props;
    const title = name === 'add' ? 'modal-title-add' : 'modal-title-change';

    return (
      <>
        <Modal
          title={translate(`page.modalGroupServices.${title}`)}
          visible={visible}
          onCancel={() => onClose('')}
          footer={[
            <Button type="primary" onClick={this.mergeGroupService} disabled={!!error}>
              {translate(name === 'add' ? 'core.buttonTitles.add' : 'core.buttonTitles.change')}
            </Button>,
            <Button onClick={() => onClose('')}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          <div className="ModalGroupServices-content">
            <div className="ModalGroupServices-row">
              <div className="ModalGroupServices-label">{translate('page.modalGroupServices.label-name')}</div>
              <div className="ModalGroupServices-input">
                <Input type="text" name="Name" value={Name} onChange={this.onHandleChange} />
              </div>
            </div>
            <div className="ModalGroupServices-row">
              <div className="ModalGroupServices-label">{translate('page.modalGroupServices.label-desc')}</div>
              <div className="ModalGroupServices-input">
                <Input type="text" name="Description" value={Description} onChange={this.onHandleChange} />
              </div>
            </div>

            {error && <div className="ModalGroupServices-error">{translate(error)}</div>}
          </div>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalGroupServices);
