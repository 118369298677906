import { NORMALIZE_RESPONSE_FIELD_MAP } from './constants';
import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';

class DealerCommissionsService {
  normalizeDealerCommissions = (dealers) =>
    dealers.map((dealer) => ({
      ...dealer,
      Id: dealer.OwnerId,
      Name: dealer.OwnerName,
    }));

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.dealerCommissions) {
      return { dealerCommissionsList: [], hasNextPage: false, hasPreviousPage: false };
    }

    const dealerCommissionsData = data.data.dealerCommissions;
    const pageInfo = dealerCommissionsData.pageInfo;

    return {
      dealerCommissionsList:
        dealerCommissionsData.items && dealerCommissionsData.items.length
          ? dealerCommissionsData.items.map((item) =>
              Object.entries(item).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [NORMALIZE_RESPONSE_FIELD_MAP.get(key) || uppercaseFirstLetter(key)]: value,
                }),
                {},
              ),
            )
          : [],
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}
export default new DealerCommissionsService();
