import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Select, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { statuses } from './constants';
import { ApiService, StorageService } from '../../../services';

const api = new ApiService();

class ModalChangeTerminalsStatuses extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    update: PropTypes.func.isRequired,
  };

  state = {
    selected: null,
    isLoading: false,
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  changeStatus = async () => {
    const { data, closeModal, translate, update } = this.props;
    const { selected } = this.state;

    try {
      this.setState({ loading: true });

      const model = {
        terminalList: data.map((terminal) => terminal.TerminalId),
        statusId: selected,
      };

      const response = await api.updateTerminalListStatus(model);
      message.success(`${translate('page.createTerminal.modal-change-statuses-success')}`, 2.5);
      closeModal();
      StorageService.setItem('selectionListForGrid-createTerminal', []);
      update();

      return response;
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, data } = this.props;
    const { isLoading, selected } = this.state;
    const { Option } = Select;

    const footer = [
      <Button key="back" onClick={closeModal}>
        {translate('page.commission.btn-back')}
      </Button>,

      <Button
        key="submit"
        type="primary"
        loading={isLoading}
        disabled={!selected || !data || !data.length}
        onClick={this.changeStatus}
      >
        {translate('page.commission.btn-submit')}
      </Button>,
    ];

    return (
      <Modal
        title={translate('page.createTerminal.changing-status-title')}
        visible={visible}
        onCancel={closeModal}
        footer={footer}
        centered
      >
        <Row>
          <Col xs={24} sm={24} md={8}>
            <p style={{ marginBottom: '0' }}>{translate('page.createTerminal.change-status-text')}</p>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Select
              className="modal-system-encashment--select"
              placeholder={translate('page.createTerminal.default-status-value')}
              style={{ width: '100%' }}
              dropdownStyle={{ zIndex: '1054' }}
              onChange={this.onSelectChange}
            >
              {statuses.map((status) => (
                <Option value={status.id} key={status.value}>
                  {translate(status.title)}
                </Option>
              ))}
            </Select>
            {!data ||
              (!data.length && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              ))}
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default withTranslate(ModalChangeTerminalsStatuses);
