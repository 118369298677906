import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';

class ManagementPaymentTypesService {
  getMinimumSelectedDate = () =>
    new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    if (
      minimumSelectedDate - normalizedStartDate > 0 ||
      minimumSelectedDate - normalizedEndDate > 0 ||
      normalizedStartDate - StandardizeTimeForDate(new Date()) > 0 ||
      normalizedEndDate - StandardizeTimeForDate(new Date()) > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new ManagementPaymentTypesService();
