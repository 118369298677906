export const PROPS_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.profilesMenu.propName',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.profilesMenu.description',
    width: '300',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Type',
    title: 'Type',
    width: '300',
    isShowOnMobile: true,
  },
];

export const LANGUAGES = ['uk', 'ru', 'en', 'hu'];
