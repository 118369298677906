import React, { PureComponent } from 'react';
import { Modal, Button, Col, Row, Alert } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

class ModalEmptyData extends PureComponent {
  render() {
    const { translate, visible, onCloseModal } = this.props;

    return (
      <Modal
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        className="modal-empty-data"
        zIndex={'1053'}
        closable={false}
        title={translate('page.blockedTransactions.paymentFound')}
        footer={[
          <Button style={{ width: '100%' }} key="submit" type="primary" onClick={onCloseModal}>
            {translate('grids.certificates-issued.onOkText')}
          </Button>,
        ]}
      >
        <Alert
          message={translate('grids.wallets.confirm.title')}
          description={translate('page.blockedTransactions.noPaymentWasDetected')}
          type="warning"
          showIcon
        />
      </Modal>
    );
  }
}

export default withTranslate(ModalEmptyData);
