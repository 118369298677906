import { Row, Col } from 'antd';
import React from 'react';

import { withTranslate } from '../../contexts/localContext';

import './TerminalDetails.scss';

const TerminalDetailsField = ({ children, translateSlug, translate }) => (
  <Row className="TerminalDetails-cardRow" type="flex" align="middle">
    <Col xs={24} sm={8} md={6} lg={10} xl={8}>
      <p className="TerminalDetails-inputLabel">{translate(translateSlug)}:</p>
    </Col>
    <Col xs={{ span: 24, offset: 0 }} sm={15} md={17} lg={13} xl={15} offset={1}>
      {React.cloneElement(children, { className: 'TerminalDetails-input' })}
    </Col>
  </Row>
);

export default withTranslate(TerminalDetailsField);
