import PropTypes from 'prop-types';
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Progress } from 'antd';
import './cellProgressEncashment.scss';

export default class CellProgressEncashment extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  render() {
    const { mobile, field, dataItem, className, style } = this.props;
    const value = parseInt(dataItem[field]);
    if (mobile) {
      return <Progress percent={value} size="small" style={{ width: '150px' }} className={`cell-progress`} />;
    }

    return (
      <td className={className} style={{ ...style, zIndex: 4 }}>
        <Progress percent={value} size="small" className="cell-progress" />
      </td>
    );
  }
}
