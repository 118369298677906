const MFODictionary = (translate, value) => {
  switch (value) {
    case 'MFODictionary-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'MFODictionary-add':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;
    case 'MFODictionary-edit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.edit`)}`;
    case 'MFODictionary-importByNBU':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`page.MFO.importByNBU`)}`;
    default:
      return value;
  }
};

export default MFODictionary;
