class UpdateModalService {
  validateLimitByChange = (translate) => (value) => {
    if (!value) {
      return '';
    }

    const normalizedValue = `${value}`.replace(',', '.');

    if (isNaN(+normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (normalizedValue.includes('.') && normalizedValue.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }

    if (+normalizedValue < 0 || +normalizedValue > 100000000) {
      return `${translate('page.servicesAuthorizationLimits.limitRangeError')}`;
    }
    // if (+normalizedValue < 0) {
    //   return translate('page.finMonLimits.error-negative-value');
    // }

    return '';
  };

  validateAmountChange = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (isNaN(value)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${value}`.includes('.')) {
      return translate('grids.comission-sub.errorMessages.integerRequired');
    }

    if (+value < 0 || +value > 1000) {
      return `${translate('page.servicesAuthorizationLimits.amountRangeError')}`;
    }

    return '';
  };

  validateLimitByAdd = (translate) => (value) => {
    if (!value.trim()) {
      return translate('page.commissionsServices.dropdownRequiredError');
    }

    return this.validateLimitByChange(translate)(value);
  };

  getValidatorsByAdd = (translate) => {
    return {
      transactionMaxLimit: this.validateLimitByAdd(translate),
      dailyLimit: this.validateLimitByAdd(translate),
      monthlyLimit: this.validateLimitByAdd(translate),
      transactionMinLimit: this.validateLimitByAdd(translate),
      dailyLimitPayer: this.validateLimitByAdd(translate),
      monthlyLimitPayer: this.validateLimitByAdd(translate),
      transactionMaxLimitPayer: this.validateLimitByAdd(translate),
      dailyTransactionsCountLimit: (value) => {
        if (!value.trim()) {
          return translate('page.commissionsServices.dropdownRequiredError');
        }

        return this.validateAmountChange(translate)(value);
      },
      monthlyLimitWithoutOtp: this.validateLimitByAdd(translate),
    };
  };

  // limitPerMonthWithoutOTP = (translate, formState) => () => {
  //   console.log(3, formState);
  //   const { dailyLimit, monthlyLimit, limitPerMonthWithoutOTP } = formState;
  //   // console.log(+limitPerMonthWithoutOTP > +dailyLimit, +limitPerMonthWithoutOTP > +monthlyLimit);
  //   // console.log(dailyLimit, monthlyLimit, limitPerMonthWithoutOTP);
  //   if (+limitPerMonthWithoutOTP > +dailyLimit || +limitPerMonthWithoutOTP > +monthlyLimit) {
  //     return translate('page.commissionsServices.monthlyLimit');
  //   }
  // };

  getValidatorsByMassChange = (translate) => {
    return {
      transactionMaxLimit: this.validateLimitByChange(translate),
      dailyLimit: this.validateLimitByChange(translate),
      monthlyLimit: this.validateLimitByChange(translate),
      transactionMinLimit: this.validateLimitByChange(translate),
      dailyLimitPayer: this.validateLimitByChange(translate),
      monthlyLimitPayer: this.validateLimitByChange(translate),
      transactionMaxLimitPayer: this.validateLimitByChange(translate),
      dailyTransactionsCountLimit: this.validateAmountChange(translate),
    };
  };
}

export default new UpdateModalService();
