import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';
import { NORMALIZE_RESPONSE_FIELD_MAP } from './constants';

class CommissionsServicesService {
  normalizeCommissionsClientServices(encashments) {
    return encashments.length
      ? encashments.map((item) =>
          Object.entries(item).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [NORMALIZE_RESPONSE_FIELD_MAP.get(key) || uppercaseFirstLetter(key)]: value,
            }),
            {},
          ),
        )
      : [];
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.collectionsPaging) {
      return { commissionsClientServices: [], hasNextPage: false, hasPreviousPage: false };
    }

    const collectionsPaging = data.data.collectionsPaging;
    const commissionsClientServices =
      collectionsPaging.items && collectionsPaging.items.length
        ? this.normalizeCommissionsClientServices(collectionsPaging.items)
        : [];

    const pageInfo = collectionsPaging.pageInfo;

    return {
      commissionsClientServices,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new CommissionsServicesService();
