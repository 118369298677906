import React, { Component } from 'react';
import { Input, Row, Button, Card, Select, Col, message, InputNumber, Radio, Checkbox } from 'antd';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import LoadingPanel from '../../../../components/loader';
import ModalGraphicWorkingHours from './modalGraphicWorkingHours';
import './additionalSettings.scss';
import { getCustomDate } from '../../../../utils/getCustomDate';
import additionalSettingsService from './additionalSettings.service';

const api = new ApiService();
const { Option } = Select;

class AdditionalSettings extends Component {
  constructor(props) {
    super(props);

    const {
      item: { TerminalId },
    } = props;
    this.state = {
      terminalId: TerminalId,
      isEditing: false,
      isLoading: false,
      data: null,
      graphicsValue: null,
      radioValue: null,
      isGraphicModal: false,
      placingInfo: [],
      placingInfoValue: null,
      terminalSchedules: [],
      techUsers: [],
      managers: [],
      collectors: [],
      techUser: null,
      managerUserId: null,
      collectorUserId: null,
      defaultCollectorUserId: null,
      defaulCollectorsData: [],
      isAutoCollection: false,
      renter: null,
      documentNumber: null,
      documentDate: null,
      amount: null,
      currency: 'UAH',
      dailyLimit: null,
      nightlyLimit: null,
      nightTimeStart: null,
      nightTimeEnd: null,
      inputGraphicValue: null,
    };
    this.currentArray = findArray('screenControlSettings');
  }

  componentDidMount() {
    this.initRequest();
  }

  onChangeGraphicsValue = (graphicsValue) => {
    this.setState({ graphicsValue });
  };

  initRequest = async () => {
    const { terminalId } = this.state;

    if (!terminalId) return;

    try {
      this.toggleLoader();
      const techUsers = await api.terminalAvailableTechUsersListGet(terminalId);
      const userInfo = await api.terminalTechUsersGet(terminalId);
      const managers = await api.terminalAvailableManagersListGet(terminalId);
      const collectorsArr = await api.terminalAvailableCollectorsListGet(terminalId);
      const terminalSchedulesDataRequest = await api.terminalScheduleGet(terminalId);
      const defaulCollectorsData = await api.terminalAvailableDefaultCollectorsListGet(terminalId);
      const rentInfo = await api.terminalRentParamsGet(terminalId);
      const limitInfo = await api.terminalLimitsGet(terminalId);

      const terminalSchedules =
        terminalSchedulesDataRequest && terminalSchedulesDataRequest.TerminalSchedules
          ? terminalSchedulesDataRequest.TerminalSchedules
          : [];
      const radioValue = !terminalSchedulesDataRequest.WithoutSchedule;
      const collectors = collectorsArr.map((el) => el.UserName).join(', ');
      const managerUserId =
        managers.findIndex((el) => el.UserId === userInfo.ManagerUserId) !== -1
          ? userInfo.ManagerUserId
          : userInfo.ManagerUserName;
      const defaultCollectorUserId =
        defaulCollectorsData.findIndex((el) => el.UserId === userInfo.DefaultCollectorUserId) !== -1
          ? userInfo.DefaultCollectorUserId
          : userInfo.DefaultCollectorUserName;
      const isAutoCollection = userInfo.AutoCollection;
      const renter = rentInfo.Renter;
      const documentNumber = rentInfo.DocumentNumber;
      const documentDate = rentInfo.DocumentDate;
      const amount = rentInfo.Amount;
      const dailyLimit = limitInfo.DailyLimit;
      const nightlyLimit = limitInfo.NightlyLimit;
      const nightTimeStart = limitInfo.NightTimeStart;
      const nightTimeEnd = limitInfo.NightTimeEnd;
      const graphicsValue = this.props.item.Regime;
      const { TechUserId: UserId, TechUserName: UserName } = userInfo;

      this.setState({
        terminalSchedules,
        techUsers,
        techUser: { UserId, UserName },
        managerUserId,
        defaultCollectorUserId,
        defaulCollectorsData,
        isAutoCollection,
        managers,
        collectors,
        renter,
        documentNumber,
        documentDate,
        amount,
        radioValue,
        dailyLimit,
        nightlyLimit,
        nightTimeStart: new Date(`${nightTimeStart}`),
        nightTimeEnd: new Date(`${nightTimeEnd}`),
        graphicsValue: this.formatGraphicsValue(graphicsValue),
      });
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  formatGraphicsValue = (graphicsValue) => {
    if (!graphicsValue) {
      return '';
    }

    const valueList = graphicsValue.split(';').filter((value) => value);
    return valueList.length ? valueList.join(';') : '';
  };

  onSubmitHandler = async () => {
    const { isEditing, terminalId } = this.state;
    const { showError, translate } = this.props;

    if (!isEditing) {
      this.setState(({ isEditing }) => {
        return {
          isEditing: !isEditing,
        };
      });

      return;
    }

    if (!terminalId) return;

    try {
      this.toggleLoader();
      const { sheduleModel, techUsersModel, terminalRentModel, terminalLimitsModel } =
        additionalSettingsService.getRequestData(this.state);

      await api.terminalScheduleSet(sheduleModel);
      await api.terminalTechUsersSet(techUsersModel);
      await api.terminalRentParamsSet(terminalRentModel);
      await api.terminalLimitsSet(terminalLimitsModel);

      await message.success(`${translate('page.screenControlAddSets.success-message')}`, 2, () =>
        window.location.reload(true),
      );
      this.setState({ isEditing: false });
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onChangeRadioValue = (e) => {
    this.setState({ radioValue: e.target.value }, () => {
      if (e.target.value === true) this.toggleGraphicModal();
    });
  };

  toggleGraphicModal = () => {
    this.setState(({ isGraphicModal }) => {
      return {
        isGraphicModal: !isGraphicModal,
      };
    });
  };

  onChangTimePickerBySlug = (e, slug) => {
    this.setState({
      [slug]: e.target.value,
    });
  };

  updateTerminalSchedules = (terminalSchedules) => {
    this.setState({ terminalSchedules });
  };

  onChangeTechnician = (techUser) => {
    this.setState({ techUser });
  };

  onChangeManager = (managerUserId) => {
    this.setState({ managerUserId });
  };

  onChangeDefaultCollector = (defaultCollectorUserId) => {
    this.setState({ defaultCollectorUserId });
  };

  onChangeAutoCollection = (e) => {
    this.setState({ isAutoCollection: e.target.checked });
  };

  onChangeEventInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeAmount = (amount) => {
    this.setState({ amount });
  };

  onChangeDailyLimit = (dailyLimit) => {
    this.setState({ dailyLimit });
  };

  onChangeNightlyLimit = (nightlyLimit) => {
    this.setState({ nightlyLimit });
  };

  getDateByDayOff = (dayOff, date) => (dayOff ? additionalSettingsService.getDefaultDate() : new Date(date));

  formatTerminalSchedules = (terminalSchedules) =>
    terminalSchedules.map(({ DayOfWeek, TurnOn, TurnOff, DayOff }) => ({
      DayOfWeek,
      DayOff,
      TurnOn: this.getDateByDayOff(DayOff, TurnOn),
      TurnOff: this.getDateByDayOff(DayOff, TurnOff),
    }));

  render() {
    const {
      terminalId,
      isEditing,
      isLoading,
      graphicsValue,
      radioValue,
      isGraphicModal,
      terminalSchedules,
      techUsers,
      techUser,
      managerUserId,
      managers,
      collectors,
      defaultCollectorUserId,
      defaulCollectorsData,
      isAutoCollection,
      renter,
      documentNumber,
      documentDate,
      amount,
      currency,
      dailyLimit,
      nightlyLimit,
      nightTimeEnd,
      nightTimeStart,
    } = this.state;
    const { translate } = this.props;

    const isTimePikersValid = additionalSettingsService.getIsTimePickerValid(nightTimeStart, nightTimeEnd);

    return (
      <>
        <div className="newTerminal--wrapper">
          <Row
            className="newTerminal--header-row no-margin"
            type="flex"
            align="middle"
            gutter={24}
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Row
              className="newTerminal--header-row no-margin secondary"
              type="flex"
              align="middle"
              gutter={24}
              style={{ flexWrap: 'wrap' }}
            >
              <p style={{ marginBottom: '0' }}>{translate('page.terminalSettings.header-field-title')}</p>
              <Input
                onChange={this.onChangeTemplateValue}
                value={terminalId}
                disabled
                className="newTerminal--header-input"
                style={{ width: 'auto' }}
              />
            </Row>
            {checkElement('additionalSettings-btn-updateSettings', this.currentArray) && (
              <Button
                type="primary"
                className="newTerminal--btn-save"
                onClick={this.onSubmitHandler}
                disabled={isEditing && !isTimePikersValid}
              >
                {!isEditing
                  ? translate('page.screenControlBusinessServices.button-edit')
                  : translate('page.screenControlBusinessServices.button-save')}
              </Button>
            )}
          </Row>

          <Row gutter={24}>
            <Col className="addSet-main-col" md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={translate('grids.settingsAndEvents.column-workingHours')}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row
                      className="newTerminal--card-row"
                      type="flex"
                      // align="middle"
                    >
                      <p className="form-text addSets-form-text" style={{ marginTop: '7px' }}>
                        {translate('grids.settingsAndEvents.column-workingHours')}:
                      </p>
                      <div className="addSets--div-wrap">
                        <Row className="addSets--graphics-row">
                          <Input
                            disabled
                            className="newTerminal---additional-media-input-class"
                            value={graphicsValue}
                            onChange={this.onChangeGraphicsValue}
                          />
                          <Button
                            type="ghost"
                            disabled={!isEditing || !radioValue || radioValue === false}
                            onClick={this.toggleGraphicModal}
                          >
                            {translate('grids.settingsAndEvents.field-graphic')}
                          </Button>
                        </Row>

                        <Row className="addSets--graphics-row">
                          <Radio.Group
                            className="addSets--radio"
                            value={radioValue}
                            disabled={!isEditing}
                            onChange={this.onChangeRadioValue}
                          >
                            <Radio value={false}>{translate('page.screenControlAddSets.radio-value-1')}</Radio>
                            <Radio value={true}>{translate('page.screenControlAddSets.radio-value-2')}</Radio>
                          </Radio.Group>
                        </Row>
                      </div>
                    </Row>
                  </Col>
                </Row>

                {isGraphicModal && (
                  <ModalGraphicWorkingHours
                    visible={isGraphicModal}
                    closeModal={this.toggleGraphicModal}
                    data={this.formatTerminalSchedules(terminalSchedules)}
                    updateTerminalSchedules={this.updateTerminalSchedules}
                    onChangeGraphicsValue={this.onChangeGraphicsValue}
                    getDefaultDate={additionalSettingsService.getDefaultDate}
                  />
                )}
              </Card>

              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={translate('page.adminPanel.tab-users')}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('grids.settingsAndEvents.column-technician')}:
                      </p>
                      <Select
                        value={
                          techUser && techUser.UserId
                            ? `${techUser.UserName} | ID: ${techUser.UserId}`
                            : translate('page.screenControlAddSets.tech-user-select-placeholder')
                        }
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '100%', marginLeft: '10px' }}
                        onChange={this.onChangeTechnician}
                        disabled={!isEditing}
                      >
                        {techUsers.map((el) => (
                          <Option key={`technicians-elem-${el.UserId}`} value={el}>
                            {`${el.UserName} | ID: ${el.UserId}`}
                          </Option>
                        ))}
                      </Select>
                    </Row>

                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">{translate('grids.account.column-manager')}:</p>
                      <Select
                        value={managerUserId}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '100%', marginLeft: '10px' }}
                        onChange={this.onChangeManager}
                        disabled={!isEditing}
                      >
                        {managers.map((el) => (
                          <Option key={`managers-elem-${el.UserId}`} value={el.UserId}>
                            {el.UserName}
                          </Option>
                        ))}
                      </Select>
                    </Row>

                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-collectors')}:
                      </p>
                      <Input
                        className="newTerminal---additional-media-input-class"
                        value={collectors}
                        style={{ width: '100%' }}
                        disabled={true}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-defaultCollector')}:
                      </p>
                      <Select
                        value={defaultCollectorUserId}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '100%', marginLeft: '10px' }}
                        onChange={this.onChangeDefaultCollector}
                        disabled={!isEditing}
                      >
                        {defaulCollectorsData.map((el) => (
                          <Option key={`defaultCollectors-elem-${el.UserId}`} value={el.UserId}>
                            {el.UserName}
                          </Option>
                        ))}
                      </Select>
                    </Row>

                    <Row className="newTerminal--card-row mt-10 addSets-mob-checkbox-row" type="flex" align="middle">
                      <Checkbox
                        className="addSets-checkbox-wrapper"
                        checked={isAutoCollection}
                        disabled={!isEditing}
                        onChange={this.onChangeAutoCollection}
                      >
                        {translate('page.screenControlAddSets.field-autoEncashment')}:
                      </Checkbox>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={`${translate('page.screenControlAddSets.card3-title')}`}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-landlord')}:
                      </p>
                      <Input
                        className="newTerminal---additional-media-input-class"
                        value={renter}
                        name="renter"
                        onChange={this.onChangeEventInput}
                        style={{ width: '100%' }}
                        disabled={!isEditing}
                        maxLength={150}
                        minLength={1}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-contractNumber')}:
                      </p>
                      <Input
                        className="newTerminal---additional-media-input-class"
                        value={documentNumber}
                        name="documentNumber"
                        onChange={this.onChangeEventInput}
                        style={{ width: '100%' }}
                        maxLength={150}
                        disabled={!isEditing}
                        minLength={1}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10 addSets-date-picker-wrap" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-contractDate')}:
                      </p>
                      <DatePicker
                        style={{ width: '100%' }}
                        onChange={this.onChangeEventInput}
                        {...(documentDate ? { value: new Date(documentDate) } : {})}
                        name="documentDate"
                        format="dd.MM.yyyy"
                        disabled={!isEditing}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10 spec-row" type="flex" align="middle">
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className="addSets--lease-contract">
                          <p>{translate('grids.terminalCashList.column-amount')}:</p>
                          <InputNumber
                            className="newTerminal---additional-media-input-class"
                            value={amount}
                            onChange={this.onChangeAmount}
                            stringMode
                            step="0.01"
                            disabled={!isEditing}
                            // min={0}
                            precision={2}
                          />
                        </div>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className="addSets--lease-contract">
                          <p>{translate('grids.allEncashments.column-currency')}:</p>
                          <Input
                            className="newTerminal---additional-media-input-class"
                            value={currency}
                            style={{ width: '100%' }}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={`${translate('page.adminPanel.tab-restrictions')}`}
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-paymentsPerDay')}:
                      </p>
                      <InputNumber
                        className="newTerminal---additional-media-input-class"
                        value={dailyLimit}
                        onChange={this.onChangeDailyLimit}
                        stringMode
                        step="0.01"
                        disabled={!isEditing}
                        min={20000.0}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlAddSets.field-paymentsPerNight')}:
                      </p>
                      <InputNumber
                        className="newTerminal---additional-media-input-class"
                        value={nightlyLimit}
                        onChange={this.onChangeNightlyLimit}
                        style={{ width: '100%' }}
                        stringMode
                        step="0.01"
                        disabled={!isEditing}
                        min={5000.0}
                        precision={2}
                      />
                    </Row>

                    <Row className="newTerminal--card-row mt-10 spec-row" type="flex" align="middle">
                      <Col xs={24} sm={24} md={24} lg={12} className="addSets-timepickers-block">
                        <p>{translate('page.screenControlAddSets.field-nightFrom')}:</p>
                        <TimePicker
                          format="HH:mm"
                          value={new Date(nightTimeStart)}
                          onChange={(e) => {
                            this.onChangTimePickerBySlug(e, 'nightTimeStart');
                          }}
                          nowButton={false}
                          cancelButton={false}
                          min={getCustomDate({ customTime: { h: 18 } })}
                          max={getCustomDate({ customTime: { h: 23, m: 59, ms: 999 } })}
                          disabled={!isEditing}
                          className="addSets-time-picker"
                        />
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        className="addSets-timepickers-block"
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <p>{translate('page.screenControlAddSets.field-nightTo')}:</p>
                        <TimePicker
                          format="HH:mm"
                          value={new Date(nightTimeEnd)}
                          onChange={(e) => {
                            this.onChangTimePickerBySlug(e, 'nightTimeEnd');
                          }}
                          nowButton={false}
                          cancelButton={false}
                          min={getCustomDate({ customTime: { h: 0 } })}
                          max={getCustomDate({ customTime: { h: 8, m: 59, ms: 999 } })}
                          disabled={!isEditing}
                          className="addSets-time-picker"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(AdditionalSettings);
