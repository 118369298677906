import React, { PureComponent } from 'react';

import MenuElementPropDictionaryGrid from './MenuElementPropDictionaryGrid';
import PageTabs from '../../components/tabber/tabber';

class MenuElementPropDictionary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-elementPropertyDirectory',
          content: <MenuElementPropDictionaryGrid />,
          closable: false,
        },
      ],
    };
  }

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default MenuElementPropDictionary;
