import React, { PureComponent } from 'react';
import DealerCommissionsTerminalsGrid from './DealerCommissionsTerminalsGrid';
import PageTabs from '../../components/tabber/tabber';

class DealerCommissionsTerminals extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-dealerCommissionsTerminals',
        content: <DealerCommissionsTerminalsGrid />,
        closable: false,
        key: 0,
      },
    ],
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default DealerCommissionsTerminals;
