import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../baseGrid';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import { withTranslate } from '../../../contexts/localContext';

class AdditionalTransactionInfo extends PureComponent {
  render() {
    const {
      dataItem: {
        transactionId,
        requestId,
        terminalId,
        serviceName,
        amountTotal,
        serviceId,
        commissionClient,
        amountOriginal,
        commissionProvider,
        currency,
        dateAccepted,
        dateDeclined,
        dateInput,
        datePost,
        statusName,
        ownerName,
        comment,
        account,
        operationName,
      },
      translate,
    } = this.props;

    const propsList = [
      { prop: translate('grids.transactions.column-transactionId'), value: transactionId },
      { prop: translate('grids.transactions.column-requestId'), value: requestId },
      { prop: translate('grids.transactions.column-terminalId'), value: terminalId },
      { prop: translate('grids.transactions.column-serviceName'), value: serviceName },
      { prop: translate('grids.transactions.column-amountTotal'), value: amountTotal },
      { prop: translate('grids.transactions.column-serviceId'), value: serviceId },
      { prop: translate('grids.transactions.column-comissionClient'), value: commissionClient },
      { prop: translate('grids.transactions.column-amountOriginal'), value: amountOriginal },
      { prop: translate('grids.transactions.column-comissionProvider'), value: commissionProvider },
      { prop: translate('grids.transactions.column-currency'), value: currency },
      { prop: translate('grids.transactions.column-dateAccepted'), value: getTransformDateString(dateAccepted) },
      { prop: translate('grids.transactions.column-dateDeclined'), value: getTransformDateString(dateDeclined) },
      { prop: translate('grids.transactions.column-dateInput'), value: getTransformDateString(dateInput) },
      { prop: translate('grids.transactions.column-datePost'), value: getTransformDateString(datePost) },
      { prop: translate('grids.transactions.column-status'), value: statusName },
      { prop: translate('grids.transactions.column-ownerName'), value: ownerName },
      { prop: translate('grids.transactions.column-comment'), value: comment },
      { prop: translate('page.transactions.operation'), value: operationName },
      { prop: translate('grids.transactions.column-account'), value: account },
    ];

    return (
      <Grid data={propsList} onRefresh={() => {}} toolbar={<></>} name="additionalTransactionInfo" withOutBaseButtons>
        <GridColumn field="prop" width="145" title="page.transactions.prop" />
        <GridColumn field="value" width="500" title="page.transactions.value" />
      </Grid>
    );
  }
}

export default withTranslate(AdditionalTransactionInfo);
