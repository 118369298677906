import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { StorageService, ApiService } from '../../../services';
import './modalWhiteListDelete.scss';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class ModalWhiteListControl extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    noSelected: false,
    isLoading: false,
  };

  componentDidMount() {
    const { selectedItems } = this.props;

    if (!selectedItems || selectedItems.length < 1) {
      this.setState({ noSelected: true });
    }
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onSubmit = async () => {
    const { showError, closeModal, update, selectedItems } = this.props;
    try {
      this.toggleLoader();
      const model = {
        WhiteListId: selectedItems.map(({ WhitelistId }) => WhitelistId),
      };
      const response = await api.terminalsWhitelistAccountsDelete(model);
      closeModal();
      update();
      return response;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const { noSelected, isLoading } = this.state;
    const { translate, visible, closeModal } = this.props;
    return (
      <Modal
        title={translate('grids.whiteListAdmin.modal-delete-title')}
        visible={visible}
        centered
        className="modal-system-encashment"
        zIndex={'1053'}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('grids.certificates-issued.onCancelText')}
          </Button>,
          <Button key="submit" type="primary" disabled={noSelected} onClick={this.onSubmit}>
            {translate('grids.certificates-issued.onOkText')}
          </Button>,
        ]}
      >
        <p>{translate('grids.whiteListAdmin.modal-delete-text')}</p>
        {noSelected && (
          <p style={{ color: 'red', marginBottom: 0 }}>
            {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
          </p>
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ModalWhiteListControl);
