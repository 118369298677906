const screenControlProfiles = (translate, value) => {
  switch (value) {
    case 'screenControlProfiles-btn-create-profile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.profilesMainProfileList.modal-create-profile-title')}`;
    case 'screenControlProfiles-btn-delete-profile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.profilesMainProfileList.modal-delete-profile-title')}`;
    case 'screenControlProfiles-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-dateOfChange')}`;
    case 'screenControlProfiles-ItemsCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-buttonsQuantity')}`;
    case 'screenControlProfiles-link-edit-profile':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.screenControlBusinessServices.tab-edit-profile')}`;
    case 'screenControlProfiles-MainProfileHoldName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-businessServicesTemplate')}`;
    case 'screenControlProfiles-MainProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-profileId')}`;
    case 'screenControlProfiles-MainProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-profileName')}`;
    case 'screenControlProfiles-MainProfileVersion':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-profileVersion')}`;
    case 'screenControlProfiles-OwnerId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-ownerId')}`;
    case 'screenControlProfiles-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-ownerName')}`;
    case 'screenControlProfiles-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'screenControlProfiles-TerminalCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-terminalsQuantity')}`;
    case 'screenControlProfiles-UserIdName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-executor')}`;
    case 'screenControlProfiles-MainProfileHoldId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-mainProfileHoldId')}`;
    case 'screenControlProfilesEditProfile-btn-save':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.screenControlBusinessServices.tab-edit-profile')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}`;
    default:
      return value;
  }
};

export default screenControlProfiles;
