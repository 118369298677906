import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import ChangingProfileHistoryService from './changingProfileHistory.service';
import Grid from '../../components/grids/baseGrid';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';

import './ChangingProfileHistoryPage.scss';
import { BASE_SERVICES_COLUMN_CONFIG, PROFILES_COLUMN_CONFIG } from '../../core/constants/configs.const';

const api = new ApiService();

const DEFAULT_OWNER_LIST_OBJECT = {
  ownerId: [0],
};

const ALL_SERVICES_ITEM = {
  ServiceName: 'Все сервисы',
  ServiceId: -1,
};

class ChangingProfileHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.currentSecurityWabTag = findArray('changingManagementCommissionProfileHistory');
  }

  state = {
    isLoading: false,
    profiles: [],
    services: [],
    selectedProfiles: [0],
    selectedServices: [-1],
    startDate: new Date(),
    endDate: new Date(),
    commissionsClientHistoryData: [],
    isDateError: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  async componentDidMount() {
    await this.getProfileList();
    await this.getServiceList();
    await this.getCommissionsClientHistory();
  }

  getProfileList = async () => {
    const { showError, translate } = this.props;

    await this.toggleLoader(true);

    try {
      const profiles = await api.getProfileDictionary(DEFAULT_OWNER_LIST_OBJECT);

      this.setState({
        profiles: DropdownNormalizersService.normalizeProfiles(profiles, translate),
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getServiceList = async () => {
    const { showError } = this.props;

    await this.toggleLoader(true);

    try {
      const services = await api.getServiceDictionary(DEFAULT_OWNER_LIST_OBJECT);

      this.setState(() => {
        const fullServicesList = [ALL_SERVICES_ITEM, ...services];

        return {
          services: DropdownNormalizersService.normalizeServices(fullServicesList),
        };
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getCommissionsClientHistory = async () => {
    const { showError } = this.props;
    const { selectedProfiles, selectedServices, startDate, endDate } = this.state;
    await this.toggleLoader(true);

    if (!selectedProfiles.length || !selectedServices.length) {
      return;
    }

    try {
      const model = {
        ProfileId: selectedProfiles,
        ServiceId: selectedServices,
        DateFrom: `${ChangingProfileHistoryService.formatDate(startDate)} 00:00:00 AM`,
        DateTo: `${ChangingProfileHistoryService.formatDate(endDate)} 23:59:00 PM`,
      };
      const queryString = Object.entries(model).reduce(
        (acc, [filedName, fieldValue]) => `${acc}&${filedName}=${fieldValue}`,
        '',
      );

      const commissionsClientHistoryData = await api.commissionsClientHistory(queryString.slice(1));

      this.setState({ commissionsClientHistoryData });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getSelectChangeCallback = (fieldName) => (value) => {
    this.setState({ [fieldName]: value });
  };

  getDateChangeCallback = (fieldName) => (e) => {
    this.setState({
      [fieldName]: e.target.value,
      isDateError: ChangingProfileHistoryService.getDifference(e.target.value),
    });
  };

  renderToolbar = () => {
    const { translate } = this.props;
    const { selectedProfiles, selectedServices, profiles, services, startDate, endDate, isDateError } = this.state;

    return (
      <Row className="ChangingProfileHistory-toolbar">
        <Col span={24} sm={18} md={11} xl={9} className="ChangingProfileHistory-filedRow">
          <Col span={6} md={4} className="ChangingProfileHistory-label">
            {translate('page.changingProfileHistory.profile')}
          </Col>

          <Col span={17} md={19} offset={1}>
            <GridDropdown
              data={profiles}
              colConfig={PROFILES_COLUMN_CONFIG}
              selectItems={selectedProfiles}
              onSave={this.getSelectChangeCallback('selectedProfiles')}
              isSingle
            />
          </Col>
        </Col>

        <Col span={24} sm={18} md={11} xl={9} className="ChangingProfileHistory-filedRow">
          <Col span={6} md={4} className="ChangingProfileHistory-label">
            {translate('page.changingProfileHistory.service')}
          </Col>

          <Col span={17} md={19} offset={1}>
            <GridDropdown
              data={services}
              colConfig={BASE_SERVICES_COLUMN_CONFIG}
              selectItems={selectedServices}
              onSave={this.getSelectChangeCallback('selectedServices')}
              isSingle
            />
          </Col>
        </Col>

        <Col xl={7} md={9} sm={12} span={24} className="ChangingProfileHistory-filedRow">
          <Col span={8} md={4} className="ChangingProfileHistory-label">
            {translate('page.changingProfileHistory.dateFrom')}
          </Col>

          <Col span={16} md={18} offset={1}>
            <DatePicker
              format="yyyy.MM.dd"
              className="ChangingProfileHistory-datePicker"
              onChange={this.getDateChangeCallback('startDate')}
              value={startDate}
              min={ChangingProfileHistoryService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>

        <Col xl={7} md={9} sm={12} span={24} className="ChangingProfileHistory-filedRow">
          <Col span={8} md={4} className="ChangingProfileHistory-label">
            {translate('page.changingProfileHistory.dateTo')}
          </Col>

          <Col span={16} md={18} offset={1}>
            <DatePicker
              format="yyyy.MM.dd"
              className="ChangingProfileHistory-datePicker"
              onChange={this.getDateChangeCallback('endDate')}
              value={endDate}
              min={ChangingProfileHistoryService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>

        <Col span={24} md={4} className="ChangingProfileHistory-actionsCol">
          {checkElement('changingManagementCommissionProfileHistory-search', this.currentSecurityWabTag) && (
            <Button
              type="primary"
              onClick={this.getCommissionsClientHistory}
              disabled={!selectedProfiles.length || !selectedServices.length || isDateError}
            >
              {translate('page.changingProfileHistory.btn-view')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { commissionsClientHistoryData, isLoading } = this.state;

    return (
      <Grid
        data={commissionsClientHistoryData}
        toolbar={this.renderToolbar()}
        isLoading={isLoading}
        name="changingManagementCommissionProfileHistory-grid"
        permissionName="changingManagementCommissionProfileHistory"
        onRefresh={this.getCommissionsClientHistory}
      >
        <GridColumn field="ProfileId" title="page.changingProfileHistory.col-profileId" width="110" filter="numeric" />
        <GridColumn field="ProfileName" title="page.changingProfileHistory.col-profile" width="190" />
        <GridColumn field="ServiceId" title="page.changingProfileHistory.col-serviceId" width="105" filter="numeric" />
        <GridColumn field="ServiceName" title="page.changingProfileHistory.col-service" width="180" filter="numeric" />
        <GridColumn
          field="CommissionId"
          title="page.changingProfileHistory.col-commissionId"
          width="100"
          filter="numeric"
        />
        <GridColumn field="CommissionName" title="page.changingProfileHistory.col-commissionName" width="180" />
        <GridColumn field="ServiceRules" title="page.changingProfileHistory.col-rule" width="140" />
        <GridColumn
          field="DateExec"
          title="page.changingProfileHistory.col-profileModificationDate"
          width="165"
          cell={({ dataItem: { DateExec } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DateExec)}</td>
          )}
          formatFilterCellData={(DateExec) => getTransformDateString(DateExec)}
        />
        <GridColumn field="UserExec" title="page.changingProfileHistory.col-executor" width="110" />
        <GridColumn
          field="AmountFrom"
          title="page.changingProfileHistory.col-paymentAmountFrom"
          width="140"
          filter="numeric"
        />
        <GridColumn
          field="AmountTo"
          title="page.changingProfileHistory.col-paymentAmountUpTo"
          width="145"
          filter="numeric"
        />
        <GridColumn field="ResultFixed" title="page.changingProfileHistory.col-fixed" width="100" filter="numeric" />
        <GridColumn field="ResultPercent" title="page.changingProfileHistory.col-percent" width="90" filter="numeric" />
        <GridColumn
          field="CommissionMin"
          title="page.changingProfileHistory.col-commissionMin"
          width="85"
          filter="numeric"
        />
        <GridColumn
          field="CommissionMax"
          title="page.changingProfileHistory.col-commissionMax"
          width="100"
          filter="numeric"
        />
        <GridColumn field="Priority" title="page.changingProfileHistory.col-priority" width="100" filter="numeric" />
        <GridColumn field="PaymentTypeName" title="page.changingProfileHistory.col-paymentType" width="105" />
      </Grid>
    );
  }
}

export default withTranslate(ChangingProfileHistory);
