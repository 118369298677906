import React from 'react';
import { MAX_PERCENT, MAX_VAlUE, MIN_PERCENT, MIN_VAlUE } from './constants';

class Validator {
  validateEmptyString = (translate) => (value) => {
    if (value === '') {
      return `${translate('page.recalculationTransactions.error-empty-string')}`;
    }
  };
  validateNumberFormat = (translate) => (value) => {
    if (`${value}`.match(/[^0-9.-]/)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }
    return '';
  };
  validateStartingWithZero = (translate) => (value) => {
    if (value.toString() === '0') {
      return '';
    }
    if (value.toString()[0] === '0' && value.toString()[1] !== '.') {
      return translate('page.dealerCommissionsChange.error-starting-with-zero');
    }
    return '';
  };
  validateFieldsLength = (translate) => (value) => {
    const startingWithZero = this.validateStartingWithZero(translate)(value);
    const regexp = new RegExp(`^-?(0|[1-9]\\d*)(\\.\\d{0,4})?$`);
    if (startingWithZero) return startingWithZero;

    if (!(regexp.test(value) || value === '')) {
      return translate('page.dealerCommissionsChange.error-fields-length');
    }
  };

  validateFieldsPercent = (translate) => (value) => {
    const isNumberFormatValid = this.validateNumberFormat(translate)(value);

    if (isNumberFormatValid) {
      return isNumberFormatValid;
    }

    const isValid = this.validateFieldsLength(translate)(value);
    if (isValid) return isValid;

    const isEmpty = this.validateEmptyString(translate)(value);
    if (isEmpty) return isEmpty;

    return !(+value >= MIN_PERCENT && +value <= MAX_PERCENT)
      ? translate('page.dealerCommissionsChange.error-min-max-percent')
      : '';
  };

  validateFieldsNumber = (translate) => (value) => {
    const isNumberFormatValid = this.validateNumberFormat(translate)(value);

    if (isNumberFormatValid) {
      return isNumberFormatValid;
    }

    const isValid = this.validateFieldsLength(translate)(value);
    if (isValid) return isValid;

    if (!(+value >= MIN_VAlUE && +value <= MAX_VAlUE)) {
      return translate('page.dealerCommissionsChange.error-min-max-value');
    }
    const isEmpty = this.validateEmptyString(translate)(value);
    if (isEmpty) return isEmpty;
  };

  getValidators = (translate) => {
    return {
      DealerProviderPercent: this.validateFieldsPercent(translate),
      DealerClientPercent: this.validateFieldsPercent(translate),
      PaymentSystemProviderPercent: this.validateFieldsPercent(translate),
      PaymentSystemClientPercent: this.validateFieldsPercent(translate),
      DealerProviderMin: this.validateFieldsNumber(translate),
      DealerProviderMax: this.validateFieldsNumber(translate),
      DealerClientMin: this.validateFieldsNumber(translate),
      DealerClientMax: this.validateFieldsNumber(translate),
      PaymentSystemProviderMin: this.validateFieldsNumber(translate),
      PaymentSystemProviderMax: this.validateFieldsNumber(translate),
      PaymentSystemClientMin: this.validateFieldsNumber(translate),
      PaymentSystemClientMax: this.validateFieldsNumber(translate),
      SuppliersProviderPercent: this.validateFieldsPercent(translate),
      SuppliersProviderPercentForCards: this.validateFieldsPercent(translate),
      SuppliersProviderMin: this.validateFieldsNumber(translate),
      SuppliersProviderMax: this.validateFieldsNumber(translate),
      SuppliersProviderMinForCards: this.validateFieldsNumber(translate),
      SuppliersProviderMaxForCards: this.validateFieldsNumber(translate),
    };
  };
}
export default new Validator();

export const validateDifferent = (translate, fieldsToValidate) => (formState) => {
  return fieldsToValidate.reduce((acc, { field, translateKey }) => {
    const minValue = formState[field.replace(/Max$/, 'Min')];
    const maxValue = formState[field.replace(/Min$/, 'Max')];
    const minValueForCard = formState[field.replace(/MaxForCards$/, 'MinForCards')];
    const maxValueForCard = formState[field.replace(/MinForCards$/, 'MaxForCards')];
    const minTranslateKey = translateKey.replace('-max', '-min');
    const maxTranslateKey = translateKey.replace('-min', '-max');

    if (+minValue > +maxValue || +minValueForCard > +maxValueForCard) {
      return `
      ${translate(minTranslateKey)}
      ${translate('page.dealerCommissionsChange.error-min-max')}
      ${translate(maxTranslateKey)}`;
    }
    return acc;
  }, '');
};
