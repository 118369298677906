import { getTransformDateString } from '../../../utils/getTransformDateString';
import { ApiService } from '../../../services';

const api = new ApiService();

class CommissionsService {
  normalizeCommissions(commissions) {
    return commissions.map((commission) => ({
      ...commission,
      DatePost: getTransformDateString(commission.DatePost),
      Id: commission.CommissionId,
      Name: commission.CommissionName,
    }));
  }

  normalizeCommission(commissions) {
    if (!commissions) {
      return null;
    }

    const { TimeFrom, TimeTo, ValidDateFrom, ValidDateTo, DatePost, ResultPercent, ...restData } = commissions;

    return {
      TimeFrom: new Date(TimeFrom),
      TimeTo: new Date(TimeTo),
      ValidDateFrom: new Date(ValidDateFrom),
      ValidDateTo: new Date(ValidDateTo),
      DatePost: new Date(DatePost),
      ResultPercent,
      ...restData,
    };
  }

  async fetchCommissionsList() {
    const data = await api.getComissionServiceDetails();

    const sortedData =
      data && data.length
        ? data.sort(
            ({ DatePostNoUTC }, { DatePostNoUTC: NextDatePostNoUTC }) =>
              new Date(NextDatePostNoUTC) - new Date(DatePostNoUTC),
          )
        : [];

    return sortedData;
  }
}

export default new CommissionsService();
