import { ApiService } from '../../services';

const api = new ApiService();

class AdditionalTransactionsPropsService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.transactionItems) {
      return { props: [], hasNextPage: false };
    }

    const transactionProps = data.data.transactionItems;

    return {
      transactionProps: transactionProps.items && transactionProps.items.length ? transactionProps.items : [],
      hasNextPage: transactionProps.pageInfo && transactionProps.pageInfo.hasNextPage,
    };
  };

  fetchTransactionsProps = async (skip, propList, fields, where) => {
    try {
      const query = `transactionItems(where:${where}, take:500, skip:${skip}){items{${fields}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { transactionProps, hasNextPage } = this.getGQLResponse(data);

      return hasNextPage
        ? this.fetchTransactionsProps(skip + 500, [...propList, ...transactionProps], fields, where)
        : [...propList, ...transactionProps];
    } catch (e) {
      throw e;
    }
  };

  getTransactionListWithAdditionalProps = (additionalProps, transactions) =>
    transactions.map((transaction) => {
      const additionalPropById = additionalProps
        .filter((additionalProps) => additionalProps.transactionId === transaction.transactionId)
        .reduce((acc, { itemName, itemValue }) => ({ ...acc, [itemName]: itemValue }), {});

      return { ...transaction, ...additionalPropById };
    });
}

export default new AdditionalTransactionsPropsService();
