import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import PageTabs from '../../../components/tabber/tabber';
import ExceptionsFinMonLimit from './ExceptionsFinMonLimit';
import AddNewServices from './AddNewServices';

class ExceptionsFinMonLimitPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-exeptions',
          content: <ExceptionsFinMonLimit />,
          closable: false,
        },
      ],
    };
  }
  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'addNewServices':
        tabs.push({
          title: 'page.finMonLimits.modalAddFinMonLimits',
          content: <AddNewServices />,
          closable: true,
          key: key,
        });
        break;
    }
  };
  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}

export default withTranslate(ExceptionsFinMonLimitPage);
