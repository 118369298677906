import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import CellBoolean from '../../account/cellBoolean';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

const api = new ApiService();
const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class UserAccessGrid extends PureComponent {
  state = {
    isLoading: false,
    availableToUsers: [],
  };

  iterateFetchUsers = async (selectedElements, users, isInitial) => {
    try {
      const [{ elementKey, elementType }, ...restElements] = selectedElements;

      const currentUsers = await api.availableToUsers(elementKey, elementType);

      const uniqUsers =
        currentUsers && currentUsers.length
          ? currentUsers.reduce((acc, user) => {
              return acc.find(({ UserId: accUserId }) => accUserId === user.UserId) ? acc : [...acc, user];
            }, [])
          : [];

      if (isInitial) {
        return restElements.length ? this.iterateFetchUsers(restElements, uniqUsers, false) : uniqUsers;
      }

      const innerJoinedUsers = users.filter(({ UserId: accUserId }) =>
        uniqUsers.find(({ UserId }) => UserId === accUserId),
      );

      return restElements.length ? this.iterateFetchUsers(restElements, innerJoinedUsers, false) : innerJoinedUsers;
    } catch (e) {
      throw e;
    }
  };

  fetchAvailableToUsers = async () => {
    const { selectedElements, showError } = this.props;
    try {
      this.setState({ isLoading: true });

      const availableToUsers = await this.iterateFetchUsers(selectedElements, [], true);

      this.setState({ isLoading: false, availableToUsers: availableToUsers });
    } catch (error) {
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchAvailableToUsers();
  }

  render() {
    const { availableToUsers, isLoading } = this.state;

    return (
      <Grid
        data={availableToUsers}
        name="availableToUsersGrid"
        isLoading={isLoading}
        className="ModalAccessUsers-grid"
        toolbar={<></>}
      >
        <GridColumn field="UserId" title="grids.userList.column-userId" width="150" />
        <GridColumn field="Name" title="grids.userList.column-login" width="150" />
        <GridColumn field="OwnerName" title="grids.userList.column-dealer" width="150" />
        <GridColumn field="FirstName" title="grids.userList.column-firstName" width="150" />
        <GridColumn field="LastName" title="grids.userList.column-lastName" width="150" />
        <GridColumn field="Address" title="grids.userList.column-address" width="150" />
        <GridColumn field="Description" title="grids.userList.column-desc" width="200" />
        <GridColumn
          field="DatePost"
          title="grids.userList.column-dateOfChange"
          width="200"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="Active" title="grids.userList.column-active" width="150" cell={WrappedCellBoolean} />

        <GridColumn
          field="DateLastLogin"
          title="grids.userList.column-dateLastLogin"
          width="200"
          cell={({ dataItem: { DateLastLogin } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastLogin)}</td>
          )}
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(UserAccessGrid);
