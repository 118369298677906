const easyPayUsers = (translate, value) => {
  switch (value) {
    case 'easyPayUsers-container':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'easyPayUsers-easyCardBalance':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.easyPayUsers.easyCardBalanceCyrillic')}`;
    default:
      return value;
  }
};
export default easyPayUsers;
