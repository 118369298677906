class SupplierCommissionsService {
  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  numberValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${normalizedValue}`.indexOf('.') > -1 && `${normalizedValue}`.split('.')[1].length > 4) {
      return `${translate('page.addServiceByBigTable.valueAfterDotError')}`;
    }

    return '';
  };

  getValidators = (translate) => {
    return {
      commissionPercent: this.numberValidation(translate),
      commissionMin: this.numberValidation(translate),
      commissionMax: this.numberValidation(translate),
      commissionCardPercent: this.numberValidation(translate),
      commissionCardMin: this.numberValidation(translate),
      commissionCardMax: this.numberValidation(translate),
    };
  };
}

export default new SupplierCommissionsService();
