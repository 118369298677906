import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import NewTerminal from './newTerminal';
import GridCreateTerminals from './gridCreateTerminal';
import TerminalDetails from '../../components/TerminalDetails';
import NewSchedule from './NewSchedule';
import FormStateContainer from '../../components/FormStateContainer';
import NewScheduleService from './NewSchedule/NewScheduleService';

class PageCreateTerminal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.newTerminals.tab-main',
          content: <GridCreateTerminals />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem, selectItems }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'propDetails':
        tabs.push({
          title: 'page.newTerminals.tab-details',
          content: (
            <TerminalDetails
              item={dataItem}
              permissionSlugs={{
                permissionName: 'createTerminal',
                showAdditionalSettingsButton: 'terminalDetails-show-additional',
                updateSettingsButton: 'terminalDetails-on-submit',
                fiscalizationInventory: 'createTerminal-fiscalizationInventory',
                fiscalNumberInput: 'createTerminal-fiscalNumberInput',
                businessUnitInput: 'createTerminal-businessUnitInput',
                inventoryNumberInput: 'createTerminal-inventoryNumberInput',
                registrationButton: 'terminalDetails-registration',
                unregistrationButton: 'terminalDetails-unregistration',
                getStatusButton: 'terminalDetails-status-application',
              }}
            />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'newTerminal':
        tabs.push({
          title: 'page.newTerminals.tab-createNew',
          content: <NewTerminal item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'newShedule':
        tabs.push({
          title: 'page.newTerminals.tab-createNewSchedule',
          content: (
            <FormStateContainer
              initialValues={{
                scheduleSettings: 'global',
                daysScheduleData: [],
                weeksScheduleData: {
                  from: NewScheduleService.getDefaultStartDate(),
                  to: NewScheduleService.getDefaultEndDate(),
                },
              }}
            >
              <NewSchedule selectItems={selectItems} />
            </FormStateContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageCreateTerminal);
