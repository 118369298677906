import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import ChangeBrandModal from './ChangeBrandModal';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { ApiService, StorageService, tabService } from '../../../services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';

import './SubBrandsTerminalsGrid.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import CellBoolean from '../../account/cellBoolean';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class SubBrandsTerminalsGrid extends PureComponent {
  currentArray = findArray('terminalsSubBrands');

  state = {
    terminals: [],
    isLoading: false,
    selectedTerminals: [],
    isDialogVisible: false,
    owners: [],
    selectedOwners: [],
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = async () => {
    await this.fetchOwners();
  };

  fetchOwners = async () => {
    try {
      this.setState({ isLoading: true });
      const owners = await api.owners();

      await this.setState({
        owners,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchTerminals = async () => {
    const { selectedOwners } = this.state;
    if (selectedOwners.length < 1) return;

    try {
      this.setState({ isLoading: true });
      const terminals = await api.getBrandTerminals();
      this.setState({
        terminals:
          terminals && terminals.length
            ? terminals.filter(({ OwnerlId }) => selectedOwners.some((selectedOwnerId) => selectedOwnerId === OwnerlId))
            : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  selectOwners = (selectedOwners) => {
    this.setState({ selectedOwners }, () => {
      this.fetchTerminals();
    });
  };

  handleSelectionChange = (selectedTerminals) => {
    this.setState({ selectedTerminals });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedOwners, owners, selectedTerminals } = this.state;

    if (!owners || !owners.length) {
      return <></>;
    }

    return (
      <Row>
        <div className="SubBrandsTerminalsGrid-dropdownWrapper">
          <GridDropdown
            data={DropdownNormalizersService.normalizeOwners(owners)}
            colConfig={OWNERS_COLUMN_CONFIG}
            selectItems={selectedOwners}
            onSave={this.selectOwners}
            isGetDefaultSelectItemsFromStorage
          />
        </div>
        {checkElement('terminalsSubBrands-changeBrand', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectedTerminals.length}
            onClick={() => {
              this.setState({ isDialogVisible: true });
            }}
          >
            {translate('page.subBrandsTerminals.changeBrand')}
          </Button>
        )}
        {checkElement('terminalsSubBrands-dictionary', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              tabService.addTab({
                type: 'subBrandsDictionary',
              });
            }}
          >
            {translate('page.subBrandsTerminals.directorySubBrands')}
          </Button>
        )}
      </Row>
    );
  };

  render() {
    const { terminals, isLoading, isDialogVisible, selectedOwners, selectedTerminals } = this.state;

    if (!checkElement('terminalsSubBrands-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={terminals}
          onRefresh={this.fetchTerminals}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="subBrandsTerminalsGrid"
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectionChange}
          isShowSpecificFilterByFieldName="TerminalId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="TerminalId" title="page.subBrandsTerminals.col-terminalId" width="160" filter="numeric" />
          <GridColumn field="OwnerName" title="page.subBrandsTerminals.col-owner" width="160" />
          <GridColumn field="MenuProfileName" title="page.subBrandsTerminals.col-profileMenu" width="180" />
          <GridColumn field="CommissionProfileName" title="page.subBrandsTerminals.col-commissionProfile" width="180" />
          <GridColumn field="BrandName" title="page.subBrandsTerminals.col-brand" width="180" />
          <GridColumn
            field="IsChangeCommissionEnabled"
            title="page.subBrandsTerminals.col-changeProfile"
            width="260"
            cell={WrappedCellBoolean}
          />
        </Grid>
        {isDialogVisible && (
          <ChangeBrandModal
            visible={isDialogVisible}
            onClose={() => {
              this.setState({ isDialogVisible: false });
            }}
            selectedOwners={selectedOwners}
            selectedTerminals={selectedTerminals}
            onRefresh={this.fetchTerminals}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SubBrandsTerminalsGrid);
