export const dealerCommissionsServices = (translate, value) => {
  switch (value) {
    case 'dealerCommissionsServices-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dealerCommissionsServices-btnSearch':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.search')}`;
    case 'dealerCommissionsServices-btnTurnOnTurnOff':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.turnOn')}/${translate(
        'core.buttonTitles.turnOff',
      )}`;
    case 'dealerCommissionsServices-btnCopyCommission':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.copyCommission')}`;
    case 'dealerCommissionsServices-btnChange':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.change')}`;
    default:
      return value;
  }
};
