import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Row } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import StatusCell from './StatusCell';
import { STATUS_COLORS } from './constants';
import { withTranslate } from '../../../contexts/localContext';
import ProcessDialog from './ProcessDialog';

import './CheckCardRouting.styled.scss';
import { ApiService } from '../../../services';
import UpdateModal from '../UpdateRouteModal/UpdateModalContainer';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import CellBoolean from '../../account/cellBoolean';

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;
const NullCellBoolean = ({ dataItem, field }) => <td>{dataItem[field] === null ? 'NULL' : dataItem[field]}</td>;

const api = new ApiService();

class CheckCardRouting extends PureComponent {
  state = {
    updatingRoutesList: [],
    selectedItems: [],
    currentStatusList: [],
    currentProgress: 0,
    dialogVariant: '',
  };

  componentDidMount() {
    const {
      dataItem: { changedList },
    } = this.props;

    this.setState({
      updatingRoutesList: changedList.map((route) => ({
        valuesGUId: `${crypto.randomUUID()}-${Object.values(route).join('-')}`,
        status: 'defaultStatus',
        ...route,
      })),
    });
  }

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  getStaffByProcessType = (type) => {
    if (type === 'check') {
      return {
        method: api.cardRoutingRowValidate,
        failStatus: 'checkFail',
        successStatus: 'checkSuccess',
      };
    }

    return {
      method: type === 'add' ? api.cardRoutingRowAdd : api.cardRoutingRowUpdate,
      failStatus: 'saveFail',
      successStatus: 'saveSuccess',
    };
  };

  processRoute = async (route, listLength, type) => {
    const { index, valuesGUId, ...otherProps } = route;

    const { method, failStatus, successStatus } = this.getStaffByProcessType(type);

    try {
      await method({ ...otherProps });
      this.setState(({ currentStatusList }) => ({
        currentProgress: Math.round(((index + 1) / listLength) * 100),
        currentStatusList: [...currentStatusList, { valuesGUId, status: successStatus }],
      }));
    } catch (e) {
      this.setState(({ currentStatusList }) => ({
        currentProgress: Math.round(((index + 1) / listLength) * 100),
        currentStatusList: [...currentStatusList, { valuesGUId, status: failStatus }],
      }));
    }

    return route;
  };

  getProcessRoutesListCallback = (type) => async () => {
    const { updatingRoutesList } = this.state;
    const {
      dataItem: { type: updateType },
    } = this.props;

    const routesListWithIndex = updatingRoutesList.map((route, index) => ({ index, ...route }));
    await this.setState({ dialogVariant: type === 'check' ? 'check' : 'update' });

    for (const route of routesListWithIndex) {
      await this.processRoute(route, routesListWithIndex.length, type === 'check' ? 'check' : updateType);
    }

    this.setState(
      ({ currentStatusList, updatingRoutesList }) => ({
        dialogVariant: '',
        updatingRoutesList: updatingRoutesList.map(({ valuesGUId, ...otherProps }) => {
          const status = currentStatusList.find(
            ({ valuesGUId: valuesGUIdFromStatusList }) => valuesGUIdFromStatusList === valuesGUId,
          ).status;

          return { ...otherProps, valuesGUId, status };
        }),
      }),
      () => {
        this.setState({ currentStatusList: [] });
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { updatingRoutesList, selectedItems } = this.state;

    const isCheckNotAllowed = updatingRoutesList.some(({ status }) => status === 'checkFail' || status === 'saveFail');
    const isUpdateNotAllowed = updatingRoutesList.some(({ status }) => status !== 'checkSuccess');

    return (
      <Row>
        <div className="CheckCardRouting-status">
          {Array.from(STATUS_COLORS).map(([status, color]) => (
            <div className="CheckCardRouting-statusItem">
              <div
                className="CheckCardRouting-statusColorBlock"
                style={{
                  backgroundColor: color,
                }}
              />

              <div className="CheckCardRouting-statusTitle">- {translate(`page.routingManagement.${status}`)}</div>
            </div>
          ))}
        </div>

        <div className="CheckCardRouting-action">
          {/*<div>*/}
          {/*  <Button*/}
          {/*    type="primary"*/}
          {/*    disabled={selectedItems.length !== 1}*/}
          {/*    onClick={() => {*/}
          {/*      this.setState({ dialogVariant: 'changeByCheckTab' });*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {translate('core.buttonTitles.change')}*/}
          {/*  </Button>*/}
          {/*</div>*/}

          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ dialogVariant: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>

          <div>
            <Button type="primary" disabled={isCheckNotAllowed} onClick={this.getProcessRoutesListCallback('check')}>
              {translate('page.routingManagement.checkAction')}
            </Button>
          </div>

          <div>
            <Button type="primary" disabled={isUpdateNotAllowed} onClick={this.getProcessRoutesListCallback('save')}>
              {translate('page.routingManagement.checkingAndSavingRoutings')}
            </Button>
          </div>
        </div>
      </Row>
    );
  };

  deleteRoutes = () => {
    const { selectedItems } = this.state;

    const selectedItemsIds = selectedItems.map(({ valuesGUId }) => valuesGUId);

    this.setState(({ updatingRoutesList }) => ({
      updatingRoutesList: updatingRoutesList.filter(({ valuesGUId }) => !selectedItemsIds.includes(valuesGUId)),
    }));
  };

  handleChangeSingleRoute = (route) => {
    this.setState(({ updatingRoutesList }) => ({
      updatingRoutesList: [
        { ...route, status: 'defaultStatus' },
        ...updatingRoutesList.filter(({ valuesGUId }) => valuesGUId !== route.valuesGUId),
      ],
    }));
  };

  getHandleExelExportDate = async () => {
    const { updatingRoutesList } = this.state;

    return updatingRoutesList.map((route) =>
      Object.entries(route).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value || value === 0 ? value : 'NULL' }),
        {},
      ),
    );
  };

  render() {
    const { updatingRoutesList, currentProgress, dialogVariant, selectedItems } = this.state;
    const {
      dataItem: { dropdownsData },
      translate,
    } = this.props;

    return (
      <>
        <Grid
          data={updatingRoutesList}
          onRefresh={() => {}}
          name="updateCardRoutingGrid"
          onSelect={this.handleSelectionChange}
          fieldForSelection="valuesGUId"
          toolbar={this.buildToolbar()}
          getHandleExelExportDate={this.getHandleExelExportDate}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="status" title="Статус" width="100" cell={StatusCell} />
          <GridColumn field="bin" title="bin" width="100" cell={NullCellBoolean} />
          <GridColumn field="rangeFrom" title="range_from" width="110" cell={NullCellBoolean} />
          <GridColumn field="rangeTo" title="range_to" width="100" cell={NullCellBoolean} />
          <GridColumn field="systemType" title="system_type" width="120" cell={NullCellBoolean} />
          <GridColumn field="incomingServiceId" title="incoming_service_id" width="180" cell={NullCellBoolean} />
          <GridColumn field="outcomingServiceId" title="outcoming_service_id" width="180" cell={NullCellBoolean} />
          <GridColumn field="amountMax" title="amount_max" width="130" cell={NullCellBoolean} />
          <GridColumn field="panType" title="pan_type" width="100" cell={NullCellBoolean} />
          <GridColumn field="bankName" title="bank_name" width="120" cell={NullCellBoolean} />
          <GridColumn field="priority" title="priority" width="90" cell={NullCellBoolean} />
          <GridColumn field="last" title="last" width="70" cell={WrappedCellBoolean} />
          <GridColumn field="weight" title="weight" width="90" cell={NullCellBoolean} />
          <GridColumn field="active" title="active" width="80" cell={WrappedCellBoolean} />
        </Grid>

        {(dialogVariant === 'update' || dialogVariant === 'check') && (
          <ProcessDialog variant={dialogVariant} progress={currentProgress} />
        )}

        {dialogVariant === 'changeByCheckTab' && (
          <UpdateModal
            variant={dialogVariant}
            selectedItems={selectedItems}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dropdownsData={dropdownsData}
            changeRouteListByCheckTab={this.handleChangeSingleRoute}
          />
        )}

        {dialogVariant === 'delete' && (
          <DeleteModal
            title={translate('page.routingManagement.deleting')}
            visible={dialogVariant === 'delete'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            submitCallback={this.deleteRoutes}
            deleteContent={translate('page.routingManagement.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CheckCardRouting);
