export const directors = (translate, value) => {
  switch (value) {
    case 'directors-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'directors-btnAdd':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'directors-btnChange':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    case 'directors-btnDelete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
  }
};
