import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import CellBoolean from '../../pages/account/cellBoolean';

import Grid from '../../components/grids/baseGrid';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import ServicesService from './services.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class Services extends PureComponent {
  currentArray = findArray('dictionaryServices');
  state = {
    servicesList: [],
    allOwners: [],
    isLoading: false,
    owners: [],
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getServices = async () => {
    const { owners } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        ownerId: owners,
      };
      const servicesList = await api.getServices(model);
      this.setState({ servicesList });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.getServices);
  };

  async componentDidMount() {
    await this.fetchOwners();
  }

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ isLoading: false, allOwners });
      }
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  buildToolbar = () => {
    const { allOwners, owners } = this.state;
    const { translate } = this.props;

    if (!allOwners || !allOwners.length) {
      return <></>;
    }

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdown
              data={ServicesService.normalizeServices(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={owners}
              onSave={this.onOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isSingle
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Row>
      </Col>
    );
  };

  render = () => {
    const { servicesList, isLoading } = this.state;

    if (!checkElement('dictionaryServices-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={servicesList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          selectedField="selected"
          toggleLoader={this.toggleLoader}
          name="services-grid"
          onRefresh={this.getServices}
        >
          <GridColumn field="Supplier" title="grids.services.column-supplier" width="140" />
          <GridColumn field="ServiceId" title="grids.services.column-service-id" width="120" />
          <GridColumn field="ServiceGroup" title="grids.services.column-service-group" width="140" />
          <GridColumn field="ServiceName" title="grids.services.column-service-name" width="90" />
          <GridColumn field="AmountMin" title="grids.services.column-amount-min" width="110" />
          <GridColumn field="AmountMax" title="grids.services.column-amount-max" width="120" />
          <GridColumn field="Transit" title="grids.services.column-transit" cell={WrappedCellBoolean} width="100" />
          <GridColumn field="DealerTransit" title="grids.services.column-dealerTransit" width="200" />
          <GridColumn field="СommissionDealer" title="grids.services.column-сommissionDealer" width="150" />
          <GridColumn field="CommissionClient" title="grids.services.column-commissionClient" width="200" />
          <GridColumn field="ClientCommissionRule" title="grids.services.column-clientCommissionRule" width="210" />
          <GridColumn field="Status" title="grids.dealerCommissions.column-status" width="100" />
          <GridColumn
            field="DateOfChange"
            title="grids.dealerCommissions.column-date-of-change"
            cell={({ dataItem: { DateOfChange } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateOfChange)}</td>
            )}
            formatFilterCellData={(DateOfChange) => getTransformDateString(DateOfChange)}
            width="120"
          />
        </Grid>
      </>
    );
  };
}
export default withTranslate(Services);
