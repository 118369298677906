import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridTerminalComissions from '../../components/TerminalsCommissionsGrid/TerminalsCommissionsGrid';
import PageTabs from '../../components/tabber/tabber';

class CommissionTerminalsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.managementExternalCommissions.terminals',
          content: <GridTerminalComissions permissionName="ManagementCommissionTerminals" />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(CommissionTerminalsPage);
