import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@telerik/kendo-intl';
import { ApiService } from '../../services/';
import { Popconfirm, Button, message } from 'antd';
import { withTranslate } from '../../contexts/localContext';
const api = new ApiService();

class CellRemoveLimit extends PureComponent {
  static propTypes = {
    dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
    // field: PropTypes.string.isRequired
  };

  state = {
    visible: false,
  };

  removeLimit = async () => {
    try {
      const {
        dataItem: { AccountId, WorkDate },
        translate,
      } = this.props;
      const format = 'yyyy-MM-ddTHH:mm:ss';
      const dt = formatDate(WorkDate, format);
      const model = {
        AccountId: AccountId,
        WorkDate: dt,
      };
      const response = await api.removeLimit(model);
      message.success(translate('grids.accountsLimit.deleted'), 2);
      return response;
    } catch (error) {
      this.setState({ visible: false });
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ visible: false });
    }
  };

  onConfirm = () => {
    this.removeLimit();
  };

  toggleVisibility = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { className, style } = this.props;
    return (
      <td className={className} style={style}>
        <Button
          style={{
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
          icon="close"
          onClick={this.toggleVisibility}
        />
        <Popconfirm
          visible={visible}
          title="Вы уверены что хотите удалить лимит?"
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          okText="Да"
          cancelText="Нет"
        />
      </td>
    );
  }
}

export default withTranslate(CellRemoveLimit);
