import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { BASE_SERVICES_COLUMN_CONFIG, OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import Grid from '../../../components/grids/baseGrid';
import DealerCommissionsTerminalsService from './dealerCommissionsTerminals.service';
import UpdateStatusModal from './UpdateStatusModal';

import './DealerCommissionsTerminalsGrid.scss';
import { TERMINALS_COLUMN_CONFIG } from './constants';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import CellBoolean from '../../account/cellBoolean';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class DealerCommissionsTerminalsGrid extends PureComponent {
  currentArray = findArray('dealerCommissionsTerminals');

  state = {
    isLoading: false,
    owners: [],
    selectOwners: [],
    terminals: [],
    selectTerminals: [],
    services: [],
    selectServices: [],
    servicesByTerminals: [],
    isChangeStatusModalShow: false,
    selectedItems: [],
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    await this.fetchServices();
    await this.fetchOwners();
  };

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({ owners: DropdownNormalizersService.normalizeOwners(owners), isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchTerminals = async () => {
    const { selectOwners } = this.state;

    if (selectOwners.length < 1) return;

    try {
      const model = {
        owners: selectOwners,
        HideDeleted: true,
      };

      const terminals = await api.getTerminalsByOwner(model);
      this.setState({ terminals: DealerCommissionsTerminalsService.normalizeTerminals(terminals) });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchServices = async () => {
    try {
      this.setState({ isLoading: true });

      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      await this.setState({ services: DropdownNormalizersService.normalizeServices(services), isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchActiveServicesForTerminals = async () => {
    const { selectTerminals, selectServices } = this.state;

    if (!selectTerminals.length || !selectServices.length) {
      return;
    }

    try {
      this.setState({ isLoading: true, selectedItems: [] }, async () => {
        const servicesByTerminals = await api.getActiveServicesForTerminals({
          services: selectServices,
          terminals: selectTerminals,
        });

        await this.setState({
          servicesByTerminals: DealerCommissionsTerminalsService.normalizeServicesByTerminals(servicesByTerminals),
          isLoading: false,
        });
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getDropdownSaveCallback = (name) => (selectItems) => {
    this.setState({ [name]: selectItems, servicesByTerminals: [] }, async () => {
      if (name === 'selectOwners') {
        await this.setState({ isLoading: true, selectTerminals: [] });
        await this.fetchTerminals();
        await this.setState({ isLoading: false });
      }
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, selectOwners, terminals, selectTerminals, selectServices, services, selectedItems } = this.state;

    if (!owners || !owners.length) {
      return <></>;
    }

    return (
      <Row>
        <div className="DealerCommissionsTerminalsGrid-searchContent">
          <div className="DealerCommissionsTerminalsGrid-selects">
            <div className="DealerCommissionsTerminalsGrid-selectRow">
              <div className="DealerCommissionsTerminalsGrid-selectLabel">
                {translate('grids.dealerCommissions.column-dealer')}
              </div>

              <div className="DealerCommissionsTerminalsGrid-select">
                <GridDropdown
                  data={owners}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={selectOwners}
                  onSave={this.getDropdownSaveCallback('selectOwners')}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  isGetDefaultSelectItemsFromStorage
                />
              </div>
            </div>

            <div className="DealerCommissionsTerminalsGrid-selectRow">
              <div className="DealerCommissionsTerminalsGrid-selectLabel">
                {translate('page.commissionsServices.service')}
              </div>

              <div className="DealerCommissionsTerminalsGrid-select">
                <GridDropdown
                  data={services}
                  colConfig={BASE_SERVICES_COLUMN_CONFIG}
                  selectItems={selectServices}
                  onSave={this.getDropdownSaveCallback('selectServices')}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                />
              </div>
            </div>

            <div className="DealerCommissionsTerminalsGrid-selectRow">
              <div className="DealerCommissionsTerminalsGrid-selectLabel">
                {translate('grids.terminals.column-terminal')}
              </div>

              <div className="DealerCommissionsTerminalsGrid-select">
                <GridDropdown
                  data={terminals}
                  colConfig={TERMINALS_COLUMN_CONFIG}
                  selectItems={selectTerminals}
                  onSave={this.getDropdownSaveCallback('selectTerminals')}
                  defaultTitle={translate('page.dealerCommissionsTerminals.selectTerminal')}
                  disabled={!selectOwners.length}
                />
              </div>
            </div>
          </div>

          <div className="DealerCommissionsTerminalsGrid-searchAction">
            <Button
              type="primary"
              disabled={!selectTerminals.length || !selectOwners.length || !selectServices.length}
              onClick={this.fetchActiveServicesForTerminals}
            >
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>

        {checkElement('dealerCommissionsTerminals-chngAction', this.currentArray) && (
          <div className="DealerCommissionsTerminalsGrid-actions">
            <Button
              type="primary"
              onClick={() => {
                this.setState({ isChangeStatusModalShow: true });
              }}
              disabled={!selectedItems.length}
            >
              {translate('page.servicesAuthorizationLimits.changeStatus')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({
      selectedItems,
    });
  };

  render() {
    const { servicesByTerminals, isLoading, isChangeStatusModalShow, selectedItems } = this.state;

    if (!checkElement('dealerCommissionsTerminals-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={servicesByTerminals}
          name="dealerCommissionsTerminalsGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchActiveServicesForTerminals}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItems}
          isLoading={isLoading}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50"
            filterable={false}
            columnMenu={false}
            sortable={false}
            showAllSelected
          />
          <GridColumn
            field="IsActive"
            title="grids.servicesBigTable.column-status"
            width="90"
            filter="boolean"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="OwnerId"
            title="grids.dealerCommissionServices.column-dealerId"
            width="120"
            filter="numeric"
          />
          <GridColumn field="OwnerName" title="grids.profilesMainProfileList.column-ownerName" width="200" />
          <GridColumn field="TerminalId" title="grids.createTerminal.column-terminalId" width="150" filter="numeric" />
          <GridColumn
            field="ServiceId"
            title="grids.dealerCommissionServices.column-serviceId"
            width="150"
            filter="numeric"
          />
          <GridColumn field="ServiceName" title="grids.dealerCommissionServices.column-serviceName" width="200" />
          <GridColumn
            field="DatePost"
            title="page.dealerCommissionsTerminals.shutdownDate"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn field="UserName" title="grids.dealerCommissionServices.column-performer" width="150" />
        </Grid>

        {isChangeStatusModalShow && (
          <UpdateStatusModal
            visible={isChangeStatusModalShow}
            closeModal={() => {
              this.setState({ isChangeStatusModalShow: false });
            }}
            selectedItems={selectedItems.map(({ Id }) => Id)}
            onRefresh={this.fetchActiveServicesForTerminals}
          />
        )}
      </>
    );
  }
}

export default withTranslate(DealerCommissionsTerminalsGrid);
