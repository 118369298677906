export const TERMINALS_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '70',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'TerminalAddress',
    titleTranslateSlug: 'grids.terminals.column-street',
    width: '200',
    isShowOnMobile: true,
  },
  {
    fieldName: 'StatusName',
    titleTranslateSlug: 'grids.terminals.column-status',
    width: '110',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerId',
    titleTranslateSlug: 'grids.cashDept.column-DealerId',
    width: '100',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerName',
    titleTranslateSlug: 'grids.cashDept.column-DealerName',
    width: '130',
    isShowOnMobile: true,
  },
];
