export const INITIAL_VALUES = {
  services: [],
  nbuCodes: [],
  authorizationTypes: [],
  transactionMaxLimit: '',
  dailyLimit: '',
  monthlyLimit: '',
  transactionMinLimit: '',
  dailyLimitPayer: '',
  monthlyLimitPayer: '',
  transactionMaxLimitPayer: '',
  dailyTransactionsCountLimit: '',
  monthlyLimitWithoutOtp: 0,
  finMonRecipient: undefined,
  finMonPayer: undefined,
  ownAccount: undefined,
  checkLimitPayer: undefined,
};
