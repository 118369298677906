import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';

import TerminalsByServices from './TerminalsByServices';
import PageTabs from '../../components/tabber/tabber';
import ReportTerminalsByServices from './ReportTerminalsByServices';

class TerminalsByServicesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-terminalsByServices',
          content: <TerminalsByServices />,
          closable: false,
        },
      ],
    };
  }
  onAddTab = ({ type, model }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    const { ownerId, terminalId, dateStart, dateEnd } = model;
    switch (type) {
      case 'terminals':
        tabs.push({
          title: `${translate('page.dashboard.tab-reportTerminalsByServices')}`,
          content: (
            <ReportTerminalsByServices
              ownerId={ownerId}
              terminalId={terminalId}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }
  };
  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}
export default withTranslate(TerminalsByServicesPage);
