import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
  GridToolbar,
  GridColumnMenuItemGroup,
} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Drawer } from 'antd';
import BaseFilter from './baseFilter';
import { formatDate } from '@telerik/kendo-intl';
import CellFilter from './cellFilter';
import './filterScroll.scss';
import { withTranslate } from '../../../contexts/localContext';

// constant for middle size ant design/kendo(bootstrap 4) breakpoint
const breakpoint = 992;

function sortGlobal(a, b) {
  if (Number(a) === a && Number(b) === b) return a - b;
  if (String(a) === a && String(b) === b) return a.localeCompare(b);
  if (a instanceof Date && b instanceof Date) return a - b;
}

const distinct = (arr) =>
  arr
    .filter(function (item, pos) {
      return arr.indexOf(item) === pos;
    })
    .sort(sortGlobal);

class FilterVirtualScroll extends GridColumnMenuFilter {
  constructor(props) {
    super(props);
    const { field, data, format } = props;
    let filterData = [];
    data.map((item) => {
      if (field === 'DateAccepted' || field === 'DateDeclined' || field === 'LastCollection') {
        if (item[field] instanceof Date && !isNaN(item[field])) {
          if (
            formatDate(item[field], format) === '01.01.1970 03:00:00' ||
            formatDate(item[field], format) === '01.01.1970 01:00:00'
          ) {
            item[field] = '';
          }
        }
      }

      if (field === 'TurnDayTransactionAvg') {
        if (item[field] === null) {
          item[field] = '';
        }
      }
      return filterData.push(item[field]);
    });

    filterData = distinct(filterData)
      .map((item) => {
        return {
          field: item,
        };
      })
      .sort();

    this.state = {
      skip: 0,
      selectedField: '',
      data: distinct(filterData),
      prevData: distinct(filterData),
    };
  }

  static defaultProps = {
    // single | multiply - одиночный выбор, множественный выбор
    mode: 'single',
  };

  static propTypes = {
    data: PropTypes.any.isRequired,
    mode: PropTypes.string,
  };

  componentDidMount(e) {
    const popup = document.querySelector('.k-grid-columnmenu-popup');
    if (window.innerWidth < breakpoint) {
      popup.parentElement.parentElement.classList = '';
      popup.parentElement.classList = '';
    } else {
      const elem = document.querySelector('.filter-scroll .k-grid-header');
      elem.style.display = 'none';
    }

    popup.style = {
      position: 'fixed',
      top: '0',
      left: '0',
      outline: 'none',
    };
  }

  pageChange = (event) => {
    this.setState({
      orders: this.state.orders,
      skip: event.page.skip,
    });
  };

  getFilterOperator = (type) => {
    switch (type) {
      case 'numeric':
      case 'date':
      case 'boolean':
        return 'eq';
      default:
        return 'eq';
    }
  };

  rowClick = ({ dataItem: { field }, syntheticEvent }) => {
    const {
      field: baseField,
      onFilterChange,
      filter,
      onCloseMenu,
      column: { filter: filterType },
    } = this.props;

    this.setState({ selectedField: field }, () => {
      const filterEq = {
        field: baseField,
        operator: this.getFilterOperator(filterType),
        value: field,
      };

      onFilterChange({ ...filter, filters: [...new Set([...filter.filters, filterEq])], logic: 'and' }, syntheticEvent);
      onCloseMenu();
    });
  };

  onReset = (event) => {
    const {
      filter,
      onFilterChange,
      onCloseMenu,
      column: { field: currentField },
    } = this.props;
    for (const elem of filter.filters) {
      if (elem.filters) {
        for (const childFilter of elem.filters) {
          if (childFilter.field === currentField) {
            elem.filters = [];
            this.forceUpdate();
          }
        }
      }
      if (elem.field === currentField) {
        const findIndex = filter.filters.findIndex((findElem) => findElem.field === currentField);
        filter.filters.splice(findIndex, 1);
        this.forceUpdate();
      }
    }
    onFilterChange(filter, event.syntheticEvent);
    onCloseMenu();
  };

  formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };

  onSearch = (filterValue) => {
    const { prevData } = this.state;

    if (filterValue.trim().length === 0) {
      this.setState({ data: prevData });

      return;
    }

    const filteredData = prevData.filter((item) => {
      if (item.field instanceof Date) {
        const formattedDate = this.formatDate(item.field);
        return formattedDate.includes(String(filterValue));
      } else {
        return String(item.field).toLowerCase().includes(String(filterValue).toLowerCase());
      }
    });

    this.setState({ data: filteredData });
  };

  getGridStyle = () => {
    if (window.innerWidth > breakpoint) {
      return { height: '220px' };
    }

    return { height: '500px' };
  };

  getRowHeight = () => {
    if (window.innerWidth > breakpoint) {
      return 20;
    }
    return 40;
  };

  renderFilter = () => {
    const { skip, data, filterValue } = this.state;
    const { translate, format, formatCellData } = this.props;

    const onChange = (value) => {
      this.onSearch(value);
    };

    return (
      <GridColumnMenuItemGroup>
        <Grid
          style={this.getGridStyle()}
          rowHeight={this.getRowHeight()}
          data={data.slice(skip, skip + 20)}
          pageSize={20}
          total={data.length}
          skip={skip}
          scrollable={'virtual'}
          onPageChange={this.pageChange}
          selectedField="selected"
          onRowClick={(e) => this.rowClick(e)}
          headerCellRender={false}
          className="filter-scroll"
        >
          <GridToolbar>
            <BaseFilter {...this.props} />
            <Button onClick={this.onReset}>{translate('base-filter.reset')}</Button>
          </GridToolbar>
          <GridToolbar>
            <input onChange={(e) => onChange(e.target.value)} style={{ width: '100%' }} />
          </GridToolbar>
          <GridColumn
            format={format}
            cell={(props) => <CellFilter {...props} formatCellData={formatCellData} />}
            field={'field'}
          />
        </Grid>
      </GridColumnMenuItemGroup>
    );
  };

  render() {
    const { onCloseMenu } = this.props;
    if (window.innerWidth > breakpoint) {
      return this.renderFilter();
    }

    return (
      <Drawer
        children={this.renderFilter()}
        title="Filter"
        placement="left"
        closable={false}
        visible
        bodyStyle={{ height: 'auto' }}
        onClose={() => onCloseMenu()}
      />
    );
  }
}

export default withTranslate(FilterVirtualScroll);
