import React, { PureComponent } from 'react';

import CashDebtByCollectorsGrid from './CashDebtByCollectorsGrid';
import PageTabs from '../../components/tabber/tabber';

class CashDebtByCollectors extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-cashDebtByCollectors',
          content: <CashDebtByCollectorsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}

export default CashDebtByCollectors;
