import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button as IconButton } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Button, Checkbox } from 'antd';
import { Input } from '@progress/kendo-react-inputs';

import { withTranslate } from '../../contexts/localContext';
import { MOBILE_BREAKPOINT } from './constants';
import { FilterScroll } from '../grids/filters';

import './GridDropdown.scss';
import { StorageService } from '../../services';
import { getDefaultSelectedOwners } from '../../utils/getDefaultSelectedOwners';

class GridDropdown extends PureComponent {
  constructor(props) {
    super(props);
    const { placeholder, data, selectItems = [] } = props;

    this.buttonWatchOutsideClickRef = React.createRef();
    this.contentWatchOutsideClickRef = React.createRef();
    this.isMobile = window.innerWidth < MOBILE_BREAKPOINT;
    this.state = {
      isDropdownVisible: false,
      data: data,
      skip: 0,
      searchValue: '',
      gridState: {
        take: 40,
        sort: [],
        filter: {
          logic: 'and',
          filters: [],
        },
      },
      title: placeholder || '',
      headerSelected: false,
      currentSelectItems: selectItems,
    };
  }

  getDefaultElem = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    return getDefaultSelectedOwners(this.currentPathName, search).map((id) => +id);
  };

  handleSetValueToStorage = (selectedItems) => {
    const { isGetDefaultSelectItemsFromStorage } = this.props;
    const userInfo = StorageService.getItem('userInfo');

    if (userInfo && userInfo.UserId && this.currentPathName && isGetDefaultSelectItemsFromStorage) {
      StorageService.setItem(
        `${this.currentPathName}--${userInfo.UserId}`,
        selectedItems && selectedItems.length ? selectedItems : [],
      );
    }
  };

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      },
      onSave,
      isGetDefaultSelectItemsFromStorage,
    } = this.props;
    this.currentPathName = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;

    if (isGetDefaultSelectItemsFromStorage) {
      const elementsForStorage = this.getDefaultElem();
      onSave(elementsForStorage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, selectItems } = this.props;
    const { data: prevData, selectItems: prevSelectItems } = prevProps;
    const { data: localData, currentSelectItems, isDropdownVisible, gridState, headerSelected } = this.state;
    const {
      data: prevLocalData,
      currentSelectItems: prevCurrentSelectItems,
      isDropdownVisible: prevIsDropdownVisible,
      gridState: prevGridState,
    } = prevState;

    if (data !== prevData) {
      this.setState({ data, skip: 0 });
    }

    if (selectItems.join() !== prevSelectItems.join()) {
      this.setState({ currentSelectItems: selectItems });
    }

    if (localData !== prevLocalData || currentSelectItems.join() !== prevCurrentSelectItems.join()) {
      this.setState(({ currentSelectItems }) => ({
        headerSelected: localData.reduce((acc, { Id }) => acc && currentSelectItems.includes(Id), true),
      }));
    }

    if (!!isDropdownVisible !== !!prevIsDropdownVisible) {
      this.setState(({ gridState }) => ({
        data: filterBy(orderBy(this.getFilterBySearch(data), gridState.sort), gridState.filter),
      }));
    }

    if (prevGridState && gridState.filter.filters.length > prevGridState.filter.filters.length && headerSelected) {
      this.setState({ currentSelectItems: [], headerSelected: false });
    }
  }

  togglePopupVisibility = () => {
    this.setState((state) => ({
      isDropdownVisible: !state.isDropdownVisible,
    }));
  };

  onDataStateChange = ({ data: gridState }) => {
    const { data } = this.props;
    this.setState({
      data: filterBy(orderBy(this.getFilterBySearch(data), gridState.sort), gridState.filter),
      gridState,
    });
  };

  renderColumn = () => {
    const { colConfig, translate } = this.props;
    const { gridState, data } = this.state;

    return colConfig.map(({ fieldName, title, titleTranslateSlug, width, filter }) => {
      const isColumnFilterActive = GridColumnMenuFilter.active(fieldName, gridState.filter);

      const isCustomFilterActive = !!gridState.filter.filters.find(({ field }) => field === fieldName);

      return (
        <GridColumn
          field={fieldName}
          title={title || translate(titleTranslateSlug)}
          width={width}
          filter={filter || 'text'}
          columnMenu={(props) => <FilterScroll data={data} {...props} field={fieldName} />}
          headerClassName={isColumnFilterActive || isCustomFilterActive ? 'active' : ''}
        />
      );
    });
  };

  onRowClick = (e) => {
    const { isSingle } = this.props;

    this.setState(({ currentSelectItems }) =>
      isSingle
        ? {
            currentSelectItems: currentSelectItems.includes(e.dataItem.Id) ? [] : [e.dataItem.Id],
          }
        : {
            currentSelectItems: this.setCurrentSelectMultiItems(currentSelectItems, e.dataItem.Id),
          },
    );
  };

  setCurrentSelectMultiItems = (currentSelectItems, currentId) => {
    const { maximumNumberSelected } = this.props;

    if (currentSelectItems.includes(currentId)) {
      return currentSelectItems.filter((id) => id !== currentId);
    }

    return maximumNumberSelected && currentSelectItems.length + 1 > maximumNumberSelected
      ? currentSelectItems
      : [...currentSelectItems, currentId];
  };

  renderSearchPanel = () => {
    const { translate } = this.props;
    const { searchValue } = this.state;

    return (
      <GridToolbar className="GridDropdown-gridSearchPanel">
        <div className="GridDropdown-gridSearchPanel">
          <Input
            type="text"
            value={searchValue}
            onChange={(e) => {
              this.setState({ searchValue: e.target.value });
            }}
            className="GridDropdown-gridSearchInput"
          />

          <Button.Group className="GridDropdown-gridSearchActions">
            <Button type="default" onClick={this.filterBySearch} icon="search" className="GridDropdown-button">
              {translate('owner.search')}
            </Button>
            <Button type="default" onClick={this.onClearInput} icon="stop" className="GridDropdown-button">
              {translate('owner.clean')}
            </Button>
          </Button.Group>
        </div>
      </GridToolbar>
    );
  };

  getFilterBySearch = (data) => {
    const { colConfig } = this.props;
    const { searchValue } = this.state;

    if (!data || !data.length) {
      return [];
    }

    const searchValueInLowerCase = searchValue.toLowerCase();

    return data.filter((item) => {
      const isDataItemShow = colConfig.reduce((acc, { fieldName, isShowOnMobile }) => {
        if ((this.isMobile && !isShowOnMobile) || !item[fieldName]) {
          return acc;
        }

        const isIncludeSubstring = item[fieldName].toString().toLowerCase().indexOf(searchValueInLowerCase) >= 0;

        return isIncludeSubstring || acc;
      }, false);

      return isDataItemShow;
    });
  };

  filterBySearch = () => {
    const { data } = this.props;
    const { searchValue, gridState, headerSelected } = this.state;

    if (!searchValue) {
      this.setState({ data: filterBy(orderBy(data, gridState.sort), gridState.filter) });

      return;
    }

    this.setState(({ currentSelectItems }) => ({
      data: filterBy(orderBy(this.getFilterBySearch(data), gridState.sort), gridState.filter),
      currentSelectItems: headerSelected ? [] : currentSelectItems,
    }));
  };

  onClearInput = () => {
    const { data } = this.props;
    const { gridState } = this.state;

    this.setState({ searchValue: '', data: filterBy(orderBy(data, gridState.sort), gridState.filter) });
  };

  onDropdownCancel = () => {
    const { selectItems } = this.props;

    this.setState({
      currentSelectItems: selectItems,
      isDropdownVisible: false,
    });
  };

  onSave = () => {
    const { onSave, isAllowEmptyList } = this.props;
    const { currentSelectItems } = this.state;

    if ((!currentSelectItems || !currentSelectItems.length) && !isAllowEmptyList) {
      return;
    }

    onSave(currentSelectItems);
    this.handleSetValueToStorage(currentSelectItems);
    this.setState({ isDropdownVisible: false });
  };

  deletedBtnHandler = (e) => {
    const { gridState } = this.state;
    const { onCheckboxFilterChange } = this.props;

    this.setState(
      {
        searchValue: '',
        currentSelectItems: [],
        gridState: { ...gridState, sort: [], filter: { filters: [] } },
      },
      () => {
        onCheckboxFilterChange();
      },
    );
  };

  renderFooter = () => {
    const { translate, labelForAdditonalFilterProps, isFilterByCheckboxValue } = this.props;

    return (
      <div className={`${labelForAdditonalFilterProps ? 'GridDropdown-footer-between' : 'GridDropdown-footer'}`}>
        {labelForAdditonalFilterProps && labelForAdditonalFilterProps.length && (
          <Checkbox
            onChange={this.deletedBtnHandler}
            defaultChecked={isFilterByCheckboxValue}
            value={isFilterByCheckboxValue}
          >
            {translate(labelForAdditonalFilterProps)}
          </Checkbox>
        )}
        <Button.Group>
          <Button type="default" onClick={this.onSave} className="GridDropdown-button">
            {translate('owner.save')}
          </Button>

          <Button
            type="default"
            onClick={() => {
              this.setState(this.onDropdownCancel);
            }}
            className="GridDropdown-button"
          >
            {translate('owner.cancel')}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderMobileToolbar = () => {
    const { translate, isSingle, labelForAdditonalFilterProps, isFilterByCheckboxValue } = this.props;
    const { searchValue, headerSelected } = this.state;

    return (
      <GridToolbar>
        <div className="GridDropdown-searchField">
          <Input
            type="text"
            value={searchValue}
            onChange={(e) => {
              this.setState({ searchValue: e.target.value }, this.filterBySearch);
            }}
            className="GridDropdown-gridSearchInput"
          />

          <Button className="GridDropdown-searchCloseButton" icon="close" onClick={this.onClearInput} />
        </div>

        <Button.Group className="GridDropdown-gridToolbarActions">
          <Button type="default" onClick={this.onSave} className="GridDropdown-button">
            {translate('owner.save')}
          </Button>

          <Button
            type="default"
            onClick={() => {
              this.setState(this.onDropdownCancel);
            }}
            className="GridDropdown-button"
          >
            {translate('owner.cancel')}
          </Button>
        </Button.Group>

        {(!isSingle || labelForAdditonalFilterProps) && (
          <div className="GridDropdown-checkBoxActionRow">
            {!isSingle && (
              <>
                <Checkbox
                  checked={headerSelected}
                  onChange={this.handleHeaderSelectionChange}
                  style={{ marginRight: '8px' }}
                />
                {translate('page.gridDropdown.selectAll')}
              </>
            )}

            {labelForAdditonalFilterProps && (
              <Checkbox
                onChange={this.deletedBtnHandler}
                defaultChecked={isFilterByCheckboxValue}
                value={isFilterByCheckboxValue}
              >
                {translate('owner.show-delete')}
              </Checkbox>
            )}
          </div>
        )}
      </GridToolbar>
    );
  };

  getDataByMobile = (data) => {
    const { colConfig } = this.props;

    return data.map((item) => {
      const MobileContentCol = colConfig.reduce(
        (acc, { fieldName, isShowOnMobile }) => (isShowOnMobile ? `${acc} - ${item[fieldName]}` : acc),
        '',
      );

      return { ...item, MobileContentCol: MobileContentCol.slice(2) };
    });
  };

  handleHeaderSelectionChange = () => {
    this.setState(({ headerSelected }) => {
      return {
        headerSelected: !headerSelected,
      };
    }, this.setAllItemsSelected);
  };

  setAllItemsSelected = () => {
    this.setState(({ headerSelected, data }) => ({
      currentSelectItems: headerSelected ? [...data.map(({ Id }) => Id)] : [],
    }));
  };

  renderMobileCol = () => (
    <GridColumn
      field="MobileContentCol"
      cell={({ dataItem }) => {
        const { MobileContentCol, selected } = dataItem;

        return (
          <td>
            <Checkbox
              checked={selected}
              onChange={() => this.onRowClick({ dataItem })}
              style={{ marginRight: '4px' }}
            />
            {MobileContentCol}
          </td>
        );
      }}
    />
  );

  renderGrid = () => {
    const { isSingle, maximumNumberSelected } = this.props;
    const { gridState, data, skip, headerSelected, currentSelectItems } = this.state;
    const dataByWindowWidth = this.isMobile ? this.getDataByMobile(data) : data;

    return (
      <Grid
        className={`GridDropdown-grid ${isSingle || maximumNumberSelected ? 'GridDropdown-disableHeaderCheckbox' : ''}`}
        data={dataByWindowWidth
          .map((item) => ({ ...item, selected: currentSelectItems.includes(item.Id) }))
          .slice(skip, skip + 28)}
        scrollable="virtual"
        selectedField="selected"
        skip={skip}
        pageSize={gridState.take}
        filter={gridState.filter}
        sort={gridState.sort}
        total={data.length}
        rowHeight={40}
        onRowClick={this.onRowClick}
        onDataStateChange={this.onDataStateChange}
        onSelectionChange={this.onRowClick}
        onPageChange={(e) => {
          this.setState({ skip: e.page.skip });
        }}
        onHeaderSelectionChange={this.handleHeaderSelectionChange}
        sortable
        resizable
      >
        {this.isMobile ? this.renderMobileToolbar() : this.renderSearchPanel()}
        {!this.isMobile && <GridColumn field="selected" width="50px" headerSelectionValue={headerSelected} />}
        {this.isMobile ? this.renderMobileCol() : this.renderColumn()}
      </Grid>
    );
  };

  renderOutsideArea = () => {
    const { isDropdownVisible } = this.state;

    return (
      <div
        onClick={() => {
          this.setState({ isDropdownVisible: false });
        }}
        onKeyPress={() => {
          this.setState({ isDropdownVisible: false });
        }}
        className={`GridDropdown-outsideArea ${isDropdownVisible ? 'open' : ''}`}
      />
    );
  };

  getTitle = () => {
    const { defaultTitle, selectItems, data } = this.props;

    const selectItemsEntity = data.filter(({ Id }) => selectItems.includes(Id));

    if (!selectItemsEntity || !selectItemsEntity.length) {
      return defaultTitle;
    }

    return selectItemsEntity
      .slice(0, 25)
      .map(({ Name }) => Name)
      .join(', ');
  };

  render() {
    const { isDropdownVisible } = this.state;
    const title = this.getTitle();

    const { disabled } = this.props;

    return (
      <div
        className={`GridDropdown ${isDropdownVisible ? 'GridDropdown--active' : ''}`}
        ref={this.buttonWatchOutsideClickRef}
      >
        {this.renderOutsideArea()}

        <IconButton
          icon="indent-increase"
          onClick={this.togglePopupVisibility}
          className="GridDropdown-title"
          disabled={disabled}
        >
          <span className="GridDropdown-titleTextWrapper">{title}</span>
        </IconButton>

        <div className="GridDropdown-contentWrapper" ref={this.contentWatchOutsideClickRef}>
          {isDropdownVisible && (
            <div
              className="GridDropdown-content"
              style={{ width: `${this.buttonWatchOutsideClickRef.current.clientWidth || 480}px` }}
            >
              {this.renderGrid()} {!this.isMobile && this.renderFooter()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslate(GridDropdown));
