class SubBrandsDictionaryService {
  normalizeMenuProfiles(menuProfiles) {
    if (!menuProfiles || !menuProfiles.length) {
      return [];
    }

    return menuProfiles.map((menuProfile) => ({
      ...menuProfile,
      Id: menuProfile.ProfileId,
    }));
  }
}

export default new SubBrandsDictionaryService();
