import React, { createContext } from 'react';

export const LocalContext = createContext({
  locale: 'ru',
  translate: (message) => {},
  changeLocale: (message) => {},
  showError: (message) => {},
  isAutoPermitted: false,
  isEnabledAccess: false,
});

export function withTranslate(Component) {
  return function LocalizedComponent(props) {
    return <LocalContext.Consumer>{(context) => <Component {...props} {...context} />}</LocalContext.Consumer>;
  };
}
