import React, { PureComponent } from 'react';
import { Modal, Button, Alert } from 'antd';

import { withTranslate } from '../../contexts/localContext';

class AlertModal extends PureComponent {
  renderDescription = () => {
    const { buttonList } = this.props;

    const templateList = [...new Set(buttonList.map(({ MainProfileId }) => MainProfileId))].map((id) => {
      const { MainProfileName } = buttonList.find(({ MainProfileId }) => MainProfileId === id);
      const buttons = buttonList
        .filter(({ MainProfileId }) => MainProfileId === id)
        .map(({ MenuId, Caption }) => ({ MenuId, Caption }));

      return { profileName: MainProfileName, profileId: id, buttons: buttons };
    });

    return templateList.map(({ profileId, profileName, buttons }) => (
      <div>
        <b>{`${profileId} - ${profileName}`}:</b>{' '}
        {buttons.map(({ Caption, MenuId }) => `${MenuId} - ${Caption}`).join(', ')}
      </div>
    ));
  };

  render() {
    const { translate, visible, closeModal, buttonList } = this.props;

    return (
      <Modal
        title={translate('page.backupButtons.error')}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button type="primary" onClick={closeModal} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        <Alert
          message={translate('page.backupButtons.alreadyExistedBackUpButtons')}
          type="error"
          className="alert-business-service"
          style={{
            marginTop: '15px',
          }}
          description={this.renderDescription()}
          showIcon
        />
      </Modal>
    );
  }
}

export default withTranslate(AlertModal);
