class CashDeptService {
  validateDiffDates = (translate, startDate, endDate) => {
    const diffDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24));
    return diffDays > 31 ? translate('page.cashDebt.period') : '';
  };

  normalizeDate = (date) => {
    const currentMonthString = `${('0' + (new Date(date).getMonth() + 1)).slice(-2)}`;
    const currentDateString = `${('0' + new Date(date).getDate()).slice(-2)}`;

    return `${new Date(date).getFullYear()}${currentMonthString}${currentDateString}`;
  };

  normalizeResponseData = (data) => {
    const dealers = data && data.Companies && data.Companies.length && data.Companies[0].Dealers;
    if (!dealers) {
      return [];
    }

    return dealers.reduce((acc, { DealerCode, DealerName, Periods }) => {
      const currentDealerPeriods = Periods.map(({ Transactions, ...restPeriod }) => ({
        ...restPeriod,
        DealerCode,
        DealerName,
      }));
      return [...acc, ...currentDealerPeriods];
    }, []);
  };
}

export default new CashDeptService();
