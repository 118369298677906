import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, message, Row, Radio } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService, StorageService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import './modalProfileDisableChange.scss';

const api = new ApiService();

class ModalProfileDisableChange extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    item: PropTypes.arrayOf(PropTypes.any),
    data: PropTypes.arrayOf(PropTypes.any),
  };

  state = {
    loading: false,
    isLoading: false,
    noSelected: false,
    radioValue: true,
    isEmptyCheckedProfiles: false,
  };

  componentDidMount() {
    const { item } = this.props;
    if (!item) {
      this.setState({ noSelected: true });
    }
    this.ckeckEmptyCheckedProfiles();
  }

  renderSuccessMessage = (value) => {
    const { translate } = this.props;
    const lastWord = value
      ? `${translate('page.screenControlTerminals.mainScreenState-success-disabled')}`
      : `${translate('page.screenControlTerminals.mainScreenState-success-enabled')}`;
    message.success(`${translate('page.screenControlTerminals.mainScreenState-success')} ${lastWord}!`, 2.5);
  };

  changeProfileScreen = async () => {
    const { item, closeModal, update, name } = this.props;
    const { radioValue } = this.state;
    if (!item) return;
    try {
      this.setState({ loading: true });
      const model = {
        Terminals: [...new Set(item)],
        Disable: radioValue,
      };
      const response = await api.terminalsMainProfileDisableChange(model);
      this.setState({ loading: false }, () => {
        closeModal();
        this.renderSuccessMessage(radioValue);
        StorageService.setItem(`selectionListForGrid-${name}`, []);
        update();
      });
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  ckeckEmptyCheckedProfiles = () => {
    const { data } = this.props;
    this.setState(() => {
      if (data.length > 0 && data.findIndex((el) => el.MainProfileId === null) !== -1) {
        return {
          isEmptyCheckedProfiles: true,
        };
      } else
        return {
          isEmptyCheckedProfiles: false,
        };
    });
  };

  render() {
    const { translate, visible, closeModal, item } = this.props;
    const { loading, isLoading, noSelected, isEmptyCheckedProfiles, radioValue } = this.state;
    return (
      <>
        <Modal
          title={translate('page.screenControlTerminals.mainScreenState-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" disabled={!item} loading={loading} onClick={this.changeProfileScreen}>
              {translate('page.screenControlTerminals.btn-submit-dsb')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Radio.Group value={radioValue} onChange={this.onChangeRadio} className="disableChangeProfile-radio-group">
              <Row
                className="modal-change-profile-row radio-wrapper"
                type="flex"
                style={{
                  flexWrap: 'wrap',
                  marginBottom: '10px',
                  width: '100%',
                }}
                align="middle"
                gutter={24}
                justify="space-between"
              >
                <Col style={{ marginTop: '10px' }} xs={24} lg={12}>
                  <Radio key="radio-turnedOff" value={true}>
                    {translate('page.screenControlTerminals.btn-turnOff')}
                  </Radio>
                </Col>
                <Col className="col-last" style={{ marginTop: '10px' }} xs={24} lg={12}>
                  <Radio key="radio-turnedOn" value={false} disabled={isEmptyCheckedProfiles}>
                    {translate('page.dashboard.confirm-terminal-turnon')}
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
            <Col xs={24} sm={24} md={16}>
              {noSelected && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              )}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {isEmptyCheckedProfiles && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.terminalsMainProfileList.disableChangeProfile-restriction-1')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalProfileDisableChange);
