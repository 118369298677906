import React, { PureComponent } from 'react';
import { Button, Col } from 'antd';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import ModalAssignBackupTemplates from './AssignTempalteModal';

const api = new ApiService();

class TemplateAssignments extends PureComponent {
  state = {
    isLoading: false,
    templatesList: [],
    currentModal: '',
    selectedItems: [],
  };

  componentDidMount() {
    this.getAllTemplates();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Button
          type="primary"
          style={{ marginTop: '10px' }}
          disabled={!selectedItems.length}
          onClick={() => {
            this.setState({ currentModal: 'assignTemplate' });
          }}
        >
          {translate('grids.profilesMainProfileList.modal-assign-template-title')}
        </Button>
      </Col>
    );
  };

  getAllTemplates = async () => {
    const { showError } = this.props;
    try {
      this.setState({ isLoading: true });
      const templatesList = await api.mainProfileListForModalChangeProfile([0]);
      this.setState({ templatesList });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { templatesList, isLoading, currentModal, selectedItems } = this.state;
    const { profileReserveList } = this.props;

    return (
      <>
        <Grid
          data={templatesList}
          isLoading={isLoading}
          onRefresh={this.getAllTemplates}
          toolbar={this.buildToolbar()}
          name="templateAssignmentsGrid"
          fieldForSelection="MainProfileId"
          onSelect={this.handleSelectionChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="OwnerId"
            title="grids.profilesMainProfileList.column-ownerId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="OwnerName" title="grids.profilesMainProfileList.column-ownerName" width="250" />

          <GridColumn field="MainProfileId" title="grids.profilesMainProfileList.column-profileId" width="200" />

          <GridColumn field="MainProfileName" title="grids.profilesMainProfileList.column-profileName" width="250" />

          <GridColumn field="MainProfileReserveName" title="page.backupButtons.col-backupButtonsTemplate" width="250" />
        </Grid>
        {currentModal === 'assignTemplate' && (
          <ModalAssignBackupTemplates
            visible={currentModal === 'assignTemplate'}
            closeModal={() => {
              this.setState({ currentModal: '' });
            }}
            profileReserveList={profileReserveList}
            MainProfileIds={selectedItems.map(({ MainProfileId }) => MainProfileId)}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TemplateAssignments);
