import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Col, Row, Menu, Dropdown, Icon, Button } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService, tabService, StorageService } from '../../services';
import DateRange from '../../components/daterange';
import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constants';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import CommissionsServicesService from './encaghmentAllService';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { NORMALIZE_FIELD_MAP } from './constants';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import SendEncashmentModal from './SendEncashmentModal';

import './gridAllEncashments.styled.scss';

const api = new ApiService();
const { Item: MenuItem } = Menu;

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
const aggregate = [
  { field: 'CollectionId', aggregate: 'count', format: 'n0' },
  { field: 'Amount', aggregate: 'sum', format: 'n2' },
  { field: 'AmountActually', aggregate: 'sum', format: 'n2' },
  { field: 'AmountBills', aggregate: 'sum', format: 'n2' },
];

class GridAllEncashments extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('allEncashments');
  }

  state = {
    isLoading: false,
    data: [],
    owners: [],
    currentOwners: [],
    range: {
      start: null,
      end: null,
    },
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectedItems: [],
    dialogVariant: '',
    downloadErrorStack: {
      failedChecks: [],
      errorMessage: '',
    },
  };

  componentDidMount() {
    this.handleAuthorizeCheck();
    this.fetchOwners();
  }
  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };
  handleAuthorizeCheck = async () => {
    try {
      await api.authorizeCheck();
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.getAllCollectionGQL();
    }
  }

  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-allEncashments');
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  makeQuery = async () => {
    const { range, owners } = this.state;
    if (owners.length < 1 || !range) return;
    try {
      console.log('range.start', range.start);
      const start = convertToISOFormat(range.start);
      const end = convertToISOFormat(range.end);
      const model = {
        OwnerId: owners,
        TerminalId: 0,
        DateStart: start,
        DateEnd: end,
        // Id: 'string',
        Extended: true,
      };
      this.toggleLoader(true);
      const data = await api.allEncashments(model);
      this.setState({ data: data.filter((el) => this.filterByDatePost(el)) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getAllCollectionGQL = async (currentFieldList) => {
    const { currentOwners, range, rowAmount, pageValue, sortString, filtersString } = this.state;

    if (!range.start && !range.end && !currentOwners && !currentOwners.length) {
      return;
    }

    this.toggleLoader(true);

    try {
      const storageColumns = StorageService.getItem('allEncashments');
      const currentColumns =
        currentFieldList ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP) : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{collectionId: ASC}'
      }, where: {and: [{ownerId:{in: [${currentOwners}]}},{datePost:{gte: "${getDataByTimezoneOffset(
        range.start,
      ).toISOString()}"}},{datePost:{lte: "${getDataByTimezoneOffset(range.end).toISOString()}"}}, ${filtersString}]}`;

      const query = `collectionsPaging(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;
      const data = await api.getByGraphQl(query);
      const { commissionsClientServices, hasNextPage, hasPreviousPage } =
        CommissionsServicesService.getGQLResponse(data);

      this.setState({ data: commissionsClientServices, hasNextPage, hasPreviousPage });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }

    return null;
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  // onOwners = (owners) => {
  //   this.setState({ owners });
  // };
  onSelectedItems = (currentOwners) => {
    this.setState({ currentOwners });
  };

  iterateDownload = async ([firstItem, ...restItems]) => {
    try {
      const response = await api.getCollectionReceiptPDF(firstItem.CollectionId);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${response}`;
      downloadLink.download = `CollectionCheck_${firstItem.TerminalId}_${firstItem.CollectionId}.pdf`;
      downloadLink.click();

      if (restItems && restItems.length) {
        await this.iterateDownload(restItems);
      }
    } catch (e) {
      this.setState(({ downloadErrorStack: { failedChecks, errorMessage } }) => ({
        downloadErrorStack: {
          failedChecks: [...failedChecks, firstItem.CollectionId],
          errorMessage: errorMessage || (e.data && e.data.Message) || '',
        },
      }));

      if (restItems && restItems.length) {
        await this.iterateDownload(restItems);
      }
    }
  };

  handleDownload = async () => {
    const { selectedItems } = this.state;
    const { showError, translate } = this.props;

    await this.toggleLoader(true);
    await this.iterateDownload(selectedItems);
    await this.toggleLoader(false);

    if (this.state.downloadErrorStack.failedChecks.length) {
      showError({
        data: {
          Message: `${translate('page.encashment.errorSaveToEmail')} ${this.state.downloadErrorStack.failedChecks.join(
            ',',
          )} <br/> ${this.state.downloadErrorStack.errorMessage} `,
        },
      });
    } else {
      this.setState({
        downloadErrorStack: {
          failedChecks: [],
          errorMessage: '',
        },
      });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, owners, currentOwners } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridAllEncashments-toolbar">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(owners)}
              selectItems={currentOwners}
              onSave={this.onSelectedItems}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              labelForAdditonalFilterProps={translate('owner.show-delete')}
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange onRange={this.setRange} />
          </Col>
          {checkElement('allEncashments-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button
                  icon="search"
                  type="primary"
                  onClick={() => {
                    this.getAllCollectionGQL();
                  }}
                >
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}

          <Col className="md-mt-10 GridAllEncashments-actionRow" md={24}>
            {checkElement('allEncashments-btn-cash', this.currentArray) && (
              <Row>
                <Button
                  className="sm-w-100"
                  type="primary"
                  onClick={this.renderOnclick}
                  disabled={!selectedItems.length}
                >
                  {translate('grids.allEncashments.btn-cash')}
                </Button>
              </Row>
            )}

            {(checkElement('allEncashments-downloadEncashmentCheck', this.currentArray) ||
              checkElement('allEncashments-sendEncashmentCheck', this.currentArray)) && (
              <Row>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {checkElement('allEncashments-downloadEncashmentCheck', this.currentArray) && (
                        <MenuItem onClick={this.handleDownload}>{translate('core.buttonTitles.save')}</MenuItem>
                      )}

                      {checkElement('allEncashments-sendEncashmentCheck', this.currentArray) && (
                        <MenuItem
                          onClick={() => {
                            this.setState({ dialogVariant: 'sendToEmail' });
                          }}
                        >
                          {translate('page.encashment.sendToEmail')}
                        </MenuItem>
                      )}
                    </Menu>
                  }
                  disabled={!selectedItems.length}
                >
                  <Button type="primary">
                    {translate('page.encashment.encashmentCheck')}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  renderOnclick = () => {
    const { selectedItems } = this.state;
    if (checkElement('allEncashments-сollectionIdOnclick', this.currentArray))
      return tabService.addTab({
        type: 'encashmentDetailsList',
        dataItem: selectedItems.map(({ CollectionId }) => CollectionId),
      });
    else return null;
  };

  changeDateSeconds = (formatedDate) => {
    const date = new Date(formatedDate);
    return date;
  };

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    if (el && el.DatePost) {
      const startDate = new Date(start).setMilliseconds(0);
      const endDate = new Date(end).setMilliseconds(0);
      const changedDate = this.changeDateSeconds(el.DatePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  onFieldsConfigChange = (list) => {
    this.getAllCollectionGQL(
      list && list.length
        ? list
            .map((field) => NORMALIZE_FIELD_MAP.get(field) || lowerCaseFirstLetter(field))
            .filter((field) => field !== 'selected')
        : null,
    );
  };

  render = () => {
    const { isLoading, data, pageValue, hasNextPage, hasPreviousPage, dialogVariant, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.getAllCollectionGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="allEncashments"
          permissionName="allEncashments"
          fieldForSelection="CollectionId"
          handleRowAmountChange={this.handleRowAmountChange}
          pageChange={this.pageChange}
          pageValue={pageValue}
          setFiltersString={this.setFiltersString}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          setSortingString={this.setSortingString}
          onSelect={this.handleSelectionChange}
          onFieldsConfigChange={this.onFieldsConfigChange}
          normalizeGQLFieldsMap={NORMALIZE_FIELD_MAP}
          isGQL
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn field="OwnerName" title="grids.allEncashments.column-ownername" width="150" />

          <GridColumn field="TerminalId" title="grids.allEncashments.column-terminalId" width="134" filter="numeric" />

          <GridColumn
            field="CollectionId"
            title="grids.allEncashments.column-collectionId"
            width="150"
            filter="numeric"
            // onClick={(props) => this.renderOnclick(props)}
            // onClick={(props) => tabService.addTab({ type: 'encashmentDetailsList', dataItem: props.dataItem })}
          />

          <GridColumn field="RegionName" title="grids.allEncashments.column-regionName" width="150" />

          <GridColumn field="CityName" title="grids.allEncashments.column-cityName" width="150" />

          <GridColumn field="Branch" title="grids.allEncashments.column-branch" width="150" />

          <GridColumn field="Address" title="grids.allEncashments.column-address" width="150" />

          <GridColumn field="TerminalName" title="grids.allEncashments.column-terminalName" width="240" />

          <GridColumn field="UserName" title="grids.allEncashments.column-userName" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.allEncashments.column-datePostNoUTC"
            width="240"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="Amount"
            title="grids.allEncashments.column-amount"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountActually"
            title="grids.allEncashments.column-amountActually"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountBills"
            title="grids.allEncashments.column-amountBills"
            width="150"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountDifference"
            title="grids.allEncashments.column-amountDifference"
            width="145"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn field="Currency" title="grids.allEncashments.column-currency" width="100" />

          <GridColumn
            field="Active"
            title="grids.allEncashments.column-active"
            width="100"
            cell={WrappedCellBoolean}
            filter="boolean"
          />

          <GridColumn field="UserNameClose" title="grids.allEncashments.column-userNameClose" width="180" />

          <GridColumn field="UserId" title="grids.allEncashments.column-userId" width="150" filter="numeric" />

          <GridColumn field="UserNameExec" title="grids.allEncashments.column-userNameExec" width="260" />

          <GridColumn field="CountCash" title="grids.allEncashments.column-countCash" width="160" filter="numeric" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="145" />

          <GridColumn
            field="QrClose"
            title="grids.allEncashments.column-QRStatus"
            width="160"
            cell={WrappedCellBoolean}
            filter="boolean"
          />
        </Grid>

        {dialogVariant === 'sendToEmail' && (
          <SendEncashmentModal
            visible={dialogVariant === 'sendToEmail'}
            selectedItems={selectedItems}
            onClose={() => {
              this.setState({ dialogVariant: '' });
            }}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridAllEncashments);
