class GridAccessTerminalsNormalizersService {
  normalizeTypeAccess(access, translate) {
    if (!access || !access.length) {
      return [];
    }
    return access.map(({ title, id }) => ({
      Id: id,
      Name: translate(title),
    }));
  }

  getTypeAccessConfig = (translate) => [
    {
      fieldName: 'Id',
      title: '#',
      width: '80',
      filter: 'numeric',
      isShowOnMobile: true,
    },
    {
      fieldName: 'Name',
      title: translate('page.typeAccessTerminals.typeAccess'),
      width: '240',
      isShowOnMobile: true,
    },
  ];

  normalizeTerminals = (terminals) => {
    if (!terminals || !terminals.length) {
      return [];
    }

    return terminals.map(({ OwnerId, OwnerName, ...restFields }) => ({
      Owner: `${OwnerId} - ${OwnerName}`,
      OwnerId: OwnerId,
      ...restFields,
    }));
  };
}
export default new GridAccessTerminalsNormalizersService();
