import { Button } from 'antd';
import React from 'react';

import './CommissionProfiles.scss';

export const getProfileVisibilityCell =
  (handleColClick, translate, isButton) =>
  ({ dataItem }) =>
    (
      <td>
        {isButton ? (
          <Button
            type="link"
            onClick={() => handleColClick({ dataItem }, 'changeProfileVisibilityModal')}
            className="CommissionProfiles-cellButton"
          >
            {dataItem.IsForAllOwners
              ? translate('grids.commission-profiles.visibleEveryone')
              : translate('grids.commission-profiles.limited')}
          </Button>
        ) : (
          <>
            {dataItem.IsForAllOwners
              ? translate('grids.commission-profiles.visibleEveryone')
              : translate('grids.commission-profiles.limited')}
          </>
        )}
      </td>
    );
