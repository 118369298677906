import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './gridTransactions.scss';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class InformationModal extends PureComponent {
  state = {
    isLoading: false,
    transactionsApproved: 0,
    transactionsDenied: 0,
  };

  doUnhold = async () => {
    await this.setState({ isLoading: true });
    const { dialogStuff } = this.props;
    const { dataItem, selectedItems } = dialogStuff;
    const isMultiSelect = selectedItems.length > 0;
    const transactions = isMultiSelect ? selectedItems : [dataItem];
    const requestData = transactions.map((item) => ({
      transactionId: item.transactionId,
      unholdSucceed: true,
      errorCode: '',
    }));

    try {
      const data = await api.transactionDoUnhold(requestData);
      const deniedData = data.filter((item) => item.ErrorCode.length > 0).length;
      const approvedData = requestData.length - deniedData;
      await this.setState({ transactionsApproved: approvedData, transactionsDenied: deniedData });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ transactionsDenied: requestData.length });
      showError(e);
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  doDecline = async () => {
    await this.setState({ isLoading: true });
    const { dialogStuff } = this.props;
    const { dataItem, selectedItems } = dialogStuff;
    const isMultiSelect = selectedItems.length > 0;
    const transactions = isMultiSelect ? selectedItems : [dataItem];
    const requestData = transactions.map((item) => ({
      transactionId: item.transactionId,
      comment: item.comment || '',
    }));

    try {
      const data = await api.transactionDoDecline(requestData);
      const deniedData = data.filter((item) => item.ErrorCode.length > 0).length;
      const approvedData = requestData.length - deniedData;
      await this.setState({ transactionsApproved: approvedData, transactionsDenied: deniedData });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ transactionsDenied: requestData.length });
      showError(e);
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  doTransactionsRepayment = async () => {
    await this.setState({ isLoading: true });
    const { dialogStuff } = this.props;
    const { dataItem, selectedItems } = dialogStuff;
    const isMultiSelect = selectedItems.length > 0;
    const transactions = isMultiSelect ? selectedItems : [dataItem];
    const requestData = transactions.map((item) => item.transactionId);

    try {
      const data = await api.transactionsRepayment(requestData);
      const deniedData = data.filter((item) => item.ErrorCode.length > 0).length;
      const approvedData = requestData.length - deniedData;
      await this.setState({ transactionsApproved: approvedData, transactionsDenied: deniedData });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ transactionsDenied: requestData.length });
      showError(e);
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  returnTranslates = (dialogVariant) => {
    const { translate } = this.props;

    switch (dialogVariant) {
      case 'unlockTransaction':
        return translate('page.transactions.unlockTransaction');
      case 'rejectTransaction':
        return translate('page.transactions.rejectTransaction');
      case 'reProcessingTransaction':
        return translate('page.transactions.reProcessingTransaction');
      default:
        break;
    }
  };

  returnTitleTranslates = (dialogVariant) => {
    const { translate } = this.props;

    switch (dialogVariant) {
      case 'unlockTransaction':
        return translate('page.transactions.unlockTransactionModalTitle');
      case 'rejectTransaction':
        return translate('page.transactions.rejectTransactionModalTitle');
      case 'reProcessingTransaction':
        return translate('page.transactions.reProcessingTransaction');
      default:
        break;
    }
  };

  returnSubmit = (dialogVariant) => {
    switch (dialogVariant) {
      case 'unlockTransaction':
        return this.doUnhold;
      case 'rejectTransaction':
        return this.doDecline;
      case 'reProcessingTransaction':
        return this.doTransactionsRepayment;
      default:
        break;
    }
  };

  render() {
    const { visible, translate, closeModal, modalTitle, dialogVariant } = this.props;
    const { isLoading, transactionsApproved, transactionsDenied } = this.state;

    const handleSubmit = this.returnSubmit(dialogVariant);

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={this.returnTitleTranslates(dialogVariant)}
        onCancel={closeModal}
        footer={[
          <Button disabled={isLoading} type="primary" key="add" onClick={() => handleSubmit()}>
            {this.returnTranslates(dialogVariant)}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="InformationModalContentWrap">
          <div className="InformationModalContentItem">{`${translate(
            'page.transactions.approved',
          )}: ${transactionsApproved}`}</div>
          <div className="InformationModalContentItem">{`${translate(
            'page.transactions.denied',
          )}: ${transactionsDenied}`}</div>
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(InformationModal);
