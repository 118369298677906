import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import Grid from '../../../components/grids/baseGrid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

import DateRange from '../../../components/daterange';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

import './GridReportTurnTerminals.styled.scss';

const api = new ApiService();

const aggregate = [
  { field: 'ManagerName', aggregate: 'count' },
  { field: 'QuantityTotal', aggregate: 'sum' },
  { field: 'AmountTotal', aggregate: 'sum' },
  {
    field: 'AvgPayment',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ AmountTotal, QuantityTotal }) => AmountTotal.sum / QuantityTotal.sum,
  },
  { field: 'CommissionProvider', aggregate: 'sum' },
  { field: 'CommissionClient', aggregate: 'sum' },
  { field: 'CommissionAll', aggregate: 'sum' },
  { field: 'NFCCount', aggregate: 'sum', format: 'n0' },
  { field: 'NFCAmount', aggregate: 'sum', format: 'n2' },
];

class GridReportTurnTerminals extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('reportTurnByTerminals');
  }

  state = {
    data: [],
    isLoading: false,
    owners: [],
    allOwners: [],
    range: undefined,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { range, owners } = this.state;
    if (!range || owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        DateStart: range.from,
        DateEnd: range.to,
      };
      const data = await api.turnTerminalsReport(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };
  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  onRange = (range) => {
    this.setState({ range });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridReportTurnTerminals-toolbar">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              selectItems={owners}
              onSave={this.onOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange onRange={this.onRange} />
          </Col>
          {checkElement('reportTurnTerminals-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery}>
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        aggregate={aggregate}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="reportTurnTerminals"
        permissionName="reportTurnByTerminals"
        specificFieldsToHide={['NFCCount', 'NFCAmount']}
        specificCheckBoxTitle="NFC"
      >
        <GridColumn
          field="TerminalId"
          title="grids.reportTurnByTerminals.column-terminalId"
          width="145"
          filter="numeric"
        />

        <GridColumn field="RegionName" title="grids.reportTurnByTerminals.column-regionName" width="145" />

        <GridColumn field="CityName" title="grids.reportTurnByTerminals.column-сityName" width="145" />

        <GridColumn field="TerminalName" title="grids.reportTurnByTerminals.column-terminalName" width="145" />

        <GridColumn field="Address" title="grids.reportTurnByTerminals.column-address" width="145" />

        <GridColumn field="ManagerName" title="grids.reportTurnByTerminals.column-managerName" width="145" />

        <GridColumn
          field="QuantityTotal"
          title="grids.reportTurnByTerminals.column-quantityTotal"
          width="170"
          filter="numeric"
          format="{0:n0}"
        />

        <GridColumn
          field="NFCCount"
          title="grids.serviceReport.column-paymentCountWithNfc"
          width="240"
          filter="numeric"
        />

        <GridColumn
          field="AvgPayment"
          title="grids.reportTurnByTerminals.column-avgPayment"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="AmountTotal"
          title="grids.reportTurnByTerminals.column-amountTotal"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="NFCAmount"
          title="grids.serviceReport.column-paymentAmountWithNfc"
          width="200"
          filter="numeric"
        />

        <GridColumn
          field="CommissionProvider"
          title="grids.reportTurnByTerminals.column-сommissionProvider"
          width="180"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionClient"
          title="grids.reportTurnByTerminals.column-сommissionClient"
          width="220"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionAll"
          title="grids.reportTurnByTerminals.column-сommissionAll"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn field="Branch" title="grids.terminals.column-branch" width="200" />

        <GridColumn field="Note" title="page.terminalSettings.field-notes" width="200" />
      </Grid>
    );
  };
}

export default withTranslate(GridReportTurnTerminals);
