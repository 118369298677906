export const DEALER_FIELDS = [
  {
    label: 'field-dealer-commission-percent',
    fieldName: 'DealerCommissionPercent',
  },
  {
    label: 'field-dealer-commission-min',
    fieldName: 'DealerCommissionMin',
  },
  {
    label: 'field-dealer-commission-max',
    fieldName: 'DealerCommissionMax',
  },
  {
    label: 'field-client-commission-percent',
    fieldName: 'DealerCommissionClientPercent',
  },
  {
    label: 'field-client-commission-min',
    fieldName: 'DealerCommissionClientMin',
  },
  {
    label: 'field-client-commission-max',
    fieldName: 'DealerCommissionClientMax',
  },
];

export const PAYMENT_SYSTEM_FIELDS = [
  {
    label: 'field-dealer-commission-percent',
    fieldName: 'PaymentSystemCommissionPercent',
  },
  {
    label: 'field-dealer-commission-min',
    fieldName: 'PaymentSystemCommissionMin',
  },
  {
    label: 'field-dealer-commission-max',
    fieldName: 'PaymentSystemCommissionMax',
  },
  {
    label: 'field-client-commission-percent',
    fieldName: 'PaymentSystemCommissionClientPercent',
  },
  {
    label: 'field-client-commission-min',
    fieldName: 'PaymentSystemCommissionClientMin',
  },
  {
    label: 'field-client-commission-max',
    fieldName: 'PaymentSystemCommissionClientMax',
  },
];

export const MIN_PERCENT = -100;
export const MAX_PERCENT = 100;
export const MIN_VAlUE = -99999.9999;
export const MAX_VAlUE = 99999.9999;

export const INITIAL_VALUES = {
  DealerCommissionPercent: 0,
  DealerCommissionMin: 0,
  DealerCommissionMax: 0,
  DealerCommissionClientPercent: 0,
  DealerCommissionClientMin: 0,
  DealerCommissionClientMax: 0,
  PaymentSystemCommissionPercent: 0,
  PaymentSystemCommissionMin: 0,
  PaymentSystemCommissionMax: 0,
  PaymentSystemCommissionClientPercent: 0,
  PaymentSystemCommissionClientMin: 0,
  PaymentSystemCommissionClientMax: 0,
};
