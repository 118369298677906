import React, { PureComponent } from 'react';

import ServiceMainProps from './ServiceMainProps';
import { ApiService, StorageService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import MainPropsService from './MainProps.service';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import { message } from 'antd';
import { requiredFields, RiskErrorMessage, translateSlugs } from './contants';

const api = new ApiService();

class ServiceMainPropsContainer extends PureComponent {
  state = {
    isLoading: false,
    ownersTypes: [],
    serviceTypes: [],
    suppliers: [],
    serviceStatus: [],
    serviceStatuses: [],
    servicesList: [],
    owners: [],
    paymentTypes: [],
    sourceBankingDetails: [],
    managers: [],
    warningDialogType: false,
  };

  componentDidMount() {
    this.initializeFetch();
  }

  initializeFetch = async () => {
    await this.setState({ isLoading: true });
    await this.fetchOwnerTypes();
    await this.fetchServiceTypes();
    await this.fetchSuppliers();
    await this.fetchServicesStatuses();
    await this.fetchOwners();
    await this.getPaymentType();
    await this.fetchServiceList();
    await this.fetchManagers();
    await this.fetchServiceInfo();
    await this.fetchSourceBankingDetails();
    await this.setState({ isLoading: false });
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      await this.setState({
        paymentTypes: DropdownNormalizersService.normalizePaymentTypes(data),
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      const data = await api.owners();
      await this.setState({ owners: DropdownNormalizersService.normalizeOwners(data) });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchSuppliers = async () => {
    try {
      const ownersTypes = await api.getSuppliers();

      this.setState({ suppliers: MainPropsService.normalizeSelectData(ownersTypes, 'SupplierId', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchManagers = async () => {
    const userInfo = StorageService.getItem('userInfo');

    try {
      const managers = await api.getUsersManagers(userInfo ? userInfo.OwnerId : 0);

      this.setState({ managers: MainPropsService.normalizeSelectData(managers, 'UserId', 'UserName') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchSourceBankingDetails = async () => {
    try {
      const sourceBankingDetails = await api.getSourceBankingDetails();

      this.setState({ sourceBankingDetails: MainPropsService.normalizeSelectData(sourceBankingDetails, 'Id', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchOwnerTypes = async () => {
    const { translate } = this.props;

    try {
      const ownersTypes = await api.getOwnerTypes();

      this.setState({
        ownersTypes: MainPropsService.normalizeSelectData(
          [{ TypeId: '-1', Name: translate('page.serviceMainProps.allRestrictionsTypes') }, ...ownersTypes],
          'TypeId',
          'Name',
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchServiceTypes = async () => {
    try {
      const serviceTypes = await api.getServicesTypes();

      this.setState({ serviceTypes: MainPropsService.normalizeSelectData(serviceTypes, 'TypeId', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchServicesStatuses = async () => {
    try {
      const serviceStatuses = await api.getServicesStatuses();

      this.setState({ serviceStatuses: MainPropsService.normalizeSelectData(serviceStatuses, 'StatusId', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchServiceList = async () => {
    const { showError } = this.props;

    try {
      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      this.setState(() => {
        return {
          servicesList: DropdownNormalizersService.normalizeServices(services),
        };
      });
    } catch (error) {
      showError(error);
      this.setState({ isLoading: false });
    }
  };

  checkBeforeSubmit = async () => {
    const { formState, translate } = this.props;

    const sumDifferenceError = MainPropsService.differenceSumValidation(formState, translate);

    if (sumDifferenceError) {
      return sumDifferenceError;
    }

    const requiredErrorList = requiredFields
      .map(({ dependedField, field, selectType }) => {
        const isTargetValueFalsy = selectType ? !formState[field].length : !formState[field] && formState[field] !== 0;

        if (dependedField) {
          return formState[dependedField] && isTargetValueFalsy ? field : '';
        }

        return isTargetValueFalsy ? field : '';
      })
      .filter((item) => item);

    if (requiredErrorList.length) {
      const requiredErrorListString = requiredErrorList.map((slug) => translate(translateSlugs[slug])).join(', ');

      return `${translate('page.serviceMainProps.savingDidNot')} ${translate(
        'page.serviceMainProps.mandatoryFieldsAreNotFilled',
      )} ${requiredErrorListString}`;
    }

    return '';
  };

  onSave = async (saveBy) => {
    const { formState, translate, showError, onSave, isStepServiceCreation } = this.props;
    const { staticValues, ownersTypes } = this.state;

    const checkResultError = await this.checkBeforeSubmit();

    if (checkResultError) {
      await this.setState({ isLoading: false });
      message.error(checkResultError, 3);
      return;
    }

    try {
      await this.setState({ isLoading: true });

      if (formState.isRiskService && !saveBy) {
        const checkResult = await api.serviceNonCharactCheck(formState.serviceId);

        if (!checkResult) {
          this.setState({ warningDialogType: 'riskRule', isLoading: false });
          return;
        }
      }

      if (isStepServiceCreation && !formState.isClientCommission && saveBy !== 'checkClientCommission') {
        this.setState({ warningDialogType: 'checkClientCommission', isLoading: false });
        return;
      }

      await this.setState({ isLoading: true });

      await api.serviceInfoUpdate({
        ServiceId: formState.serviceId,
        Name: formState.serviceName,
        Description: formState.serviceDescription,
        Rules: formState.commissionRules,
        DenyOwnerTypes: formState.limitType.length
          ? formState.limitType.includes('-1')
            ? ownersTypes
                .map(({ TypeId }) => TypeId)
                .filter((id) => id !== '-1')
                .join(',')
            : formState.limitType.join(',')
          : '',
        TypeId: formState.serviceType[0],
        SupplierId: formState.provider[0],
        StatusId: formState.status[0],
        TransitOwnerId: formState.isTransitServiceActive ? formState.transitService[0] : null,
        TransitService: formState.isTransitServiceActive,
        Amount: formState.sum,
        AmountMin: formState.minSum,
        AmountMax: formState.maxSum,
        AmountOrig: formState.orgSum,
        CommissionClient: formState.isClientCommission,
        IsAdressPayments: formState.isAddressPayment,
        HoldTime: formState.isRiskService ? formState.unlockingPeriod : null,
        ServiceCharactId: formState.isRiskService,
        PaymentTypesId: formState.paymentTool.length ? formState.paymentTool.join(',') : '',
        SourceBankingDetails: formState.bankDetails.length ? formState.bankDetails[0] : null,
        RefId: formState.isBasicServiceAllowed ? formState.basicService[0] : null,
        SalesManagerId: formState.engagementManager.length ? formState.engagementManager[0] : null,
        SupportManagerId: formState.supportManager.length ? formState.supportManager[0] : null,
        ManagerId: formState.categoryManager.length ? formState.categoryManager[0] : null,
        DateStartService: formState.createDate ? new Date(formState.createDate) : null,
        ...staticValues,
      });

      await message.success(
        translate(
          isStepServiceCreation ? `page.servicesBigTable.mainPropsSave` : `page.serviceMainProps.successfullyChanged`,
        ),
        2,
      );
      this.setState({ isLoading: false });
      onSave(formState.isClientCommission);
    } catch (error) {
      const isRiskError = error.data && error.data.Message === RiskErrorMessage;
      showError(
        isRiskError
          ? {
              ...error,
              customAcceptCallback: async () => {
                await message.success(translate(`page.serviceMainProps.successfullyChanged`), 2);
                onSave(formState.isClientCommission);
              },
            }
          : error,
      );
      this.setState({ isLoading: false });
    }
  };

  fetchServiceInfo = async () => {
    const {
      dataItem: {
        selectItem: { ServiceId },
      },
      setFormState,
    } = this.props;

    try {
      const {
        Name,
        Description,
        Rules,
        DenyOwnerTypes,
        TypeId,
        SupplierId,
        StatusId,
        TransitService,
        TransitOwnerId,
        Amount,
        AmountMax,
        AmountMin,
        AmountOrig,
        CommissionClient,
        IsAdressPayments,
        HoldTime,
        ServiceCharactId,
        PaymentTypesId,
        SourceBankingDetails,
        RefId,
        SalesManagerId,
        SupportManagerId,
        DateChangeService,
        ManagerId,
        Mnemonics,
        Currency,
        PaymentProfileId,
        MoneyTransfer,
        Country,
        SplitPayments,
        DateStartService,
      } = await api.getServiceInfo(ServiceId);

      this.setState({
        staticValues: {
          Mnemonics,
          Currency,
          PaymentProfileId,
          MoneyTransfer,
          Country,
          SplitPayments,
          DateChangeService,
        },
      });

      setFormState({
        serviceId: ServiceId,
        serviceName: Name,
        serviceDescription: Description,
        commissionRules: Rules,
        limitType: MainPropsService.calcIdsString(DenyOwnerTypes),
        serviceType: [TypeId],
        provider: SupplierId || SupplierId === 0 ? [SupplierId] : [106],
        status: StatusId || StatusId === 0 ? [StatusId] : [],
        transitService: !TransitService || (!TransitOwnerId && TransitOwnerId !== 0) ? [] : [TransitOwnerId],
        isTransitServiceActive: TransitService,
        sum: Amount,
        minSum: AmountMin,
        maxSum: AmountMax,
        orgSum: AmountOrig,
        isClientCommission: CommissionClient,
        isAddressPayment: IsAdressPayments,
        unlockingPeriod: HoldTime,
        isRiskService: ServiceCharactId,
        paymentTool: MainPropsService.calcIdsString(PaymentTypesId),
        bankDetails: SourceBankingDetails || SourceBankingDetails === 0 ? [SourceBankingDetails] : [],
        basicService: !RefId && RefId !== 0 ? [] : [RefId],
        engagementManager: SalesManagerId || SalesManagerId === 0 ? [SalesManagerId] : [],
        supportManager: SupportManagerId || SupportManagerId === 0 ? [SupportManagerId] : [],
        categoryManager: ManagerId || ManagerId === 0 ? [ManagerId] : [],
        createDate: DateStartService ? new Date(DateStartService) : null,
        dateChange: DateChangeService ? new Date(DateChangeService) : null,
        isBasicServiceAllowed: !!RefId || RefId === 0,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  getSelectChangeCallback = (fieldName) => (selectItem) => {
    const { onChangeFormState, formState, setFormState } = this.props;

    if (formState[fieldName] && formState[fieldName].includes('-1') && selectItem.length) {
      return;
    }

    if (selectItem.length && selectItem.includes('-1')) {
      setFormState({ ...formState, [fieldName]: ['-1'] });

      return;
    }

    onChangeFormState(fieldName, typeof selectItem === 'object' ? [...selectItem] : [selectItem]);
  };

  handleChangeFormState = (name, value) => {
    const { onChangeFormState, onError } = this.props;

    if (name === 'isRiskService') {
      onChangeFormState('unlockingPeriod', value ? 24 : 0);

      if (!value) {
        onError('unlockingPeriod', '');
      }
    }

    onChangeFormState(name, value);
  };

  render() {
    const { onChangeFormState, onError, formState, formErrors, customSaveActionTitle, isStepServiceCreation } =
      this.props;

    const {
      isLoading,
      ownersTypes,
      serviceTypes,
      suppliers,
      serviceStatuses,
      owners,
      paymentTypes,
      sourceBankingDetails,
      servicesList,
      managers,
      warningDialogType,
    } = this.state;

    return (
      <>
        <ServiceMainProps
          onChangeFormState={onChangeFormState}
          onError={onError}
          formState={formState}
          formErrors={formErrors}
          dropdownsData={{
            limitType: ownersTypes,
            serviceType: serviceTypes,
            provider: suppliers,
            status: serviceStatuses,
            transitService: owners,
            paymentTool: paymentTypes,
            bankDetails: sourceBankingDetails,
            basicService: servicesList,
            categoryManager: managers,
            supportManager: managers,
            engagementManager: managers,
          }}
          getSelectChangeCallback={this.getSelectChangeCallback}
          handleChangeFormState={this.handleChangeFormState}
          onSave={this.onSave}
          setWarningDialogType={(warningDialogType) => {
            this.setState({ warningDialogType });
          }}
          warningDialogType={warningDialogType}
          customSaveActionTitle={customSaveActionTitle}
          isStepServiceCreation={isStepServiceCreation}
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ServiceMainPropsContainer);
