const DealerCommissionsExpectedChanges = (translate, value) => {
  switch (value) {
    case 'dealerCommissionsExpectedChange-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dealerCommissionsExpectedChange-updateItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.change`)}`;
    case 'dealerCommissionsExpectedChange-deleteItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    default:
      return value;
  }
};

export default DealerCommissionsExpectedChanges;
