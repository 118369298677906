import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import './GridWorkDealers.scss';
import { formatDate } from '@telerik/kendo-intl';

class GridWorkDealers extends PureComponent {
  constructor(props) {
    super(props);
    const { dealers = [] } = this.props;
    this.state = {
      data: dealers,
    };
  }

  componentDidMount() {
    const { setDefaultItems } = this.props;
    setDefaultItems();
  }

  normalizeData = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map(({ DatePost, ...otherProps }) => ({
      DatePost: DatePost ? formatDate(new Date(DatePost), 'yyyy.MM.dd HH:mm:ss') : '',
      ...otherProps,
    }));
  };

  render = () => {
    const { translate, onRowClick, currentSelectItems = [], headerSelected, handleHeaderSelectionChange } = this.props;
    const { data } = this.state;
    return (
      <Grid
        name="workWithDealers-grid"
        data={this.normalizeData(
          data.map((item) => ({ ...item, selected: currentSelectItems.includes(item.OwnerId) })),
        )}
        selectedField="selected"
        total={data.length}
        rowHeight={40}
        onRowClick={onRowClick}
        onSelectionChange={onRowClick}
        fieldForSelection="OwnerId"
        onSelect={onRowClick}
        onHeaderSelectionChange={() => {
          handleHeaderSelectionChange(data);
        }}
        multiSelected
        resizable
      >
        <GridColumn field="selected" width="50px" headerSelectionValue={headerSelected} />
        <GridColumn field="OwnerId" title={translate('page.workDealers.ownerId')} width="100px" filter="numeric" />
        <GridColumn field="ShortName" title={translate('page.workDealers.ownerName')} width="300px" />
        <GridColumn field="OwnerPhone" title={translate('page.workDealers.ownerPhone')} width="240px" />
        <GridColumn field="OwnerFax" title={translate('page.workDealers.ownerFax')} width="150px" />
        <GridColumn field="OwnerAddress" title={translate('page.workDealers.ownerAddress')} width="300px" />
        <GridColumn field="OwnerEmail" title={translate('page.workDealers.ownerEmail')} width="200px" />
        <GridColumn field="OwnerSite" title={translate('page.workDealers.ownerSite')} width="200px" />
        <GridColumn field="UserNameAccessGrantor" title={translate('page.workDealers.userNameAccess')} width="180px" />
        <GridColumn field="DatePost" title={translate('page.workDealers.datePost')} width="200px" />
        <GridColumn field="OwnerInfo" title={translate('page.workDealers.ownerInfo')} width="1200px" />
      </Grid>
    );
  };
}
export default withTranslate(GridWorkDealers);
