import React, { PureComponent } from 'react';
import { Button, Checkbox } from 'antd';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { withTranslate } from '../../../contexts/localContext';
import { tabService } from '../../../services';
import ElementsSubGrid from './ElementsSubGrid';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { FilterScroll } from '../../../components/grids/filters';
import { TRANSLATE_SLUG_BY_KEY } from '../../../components/ElementsMatrixGrid/TagsGrid/constants';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import BaseHeaderCell from '../../../components/grids/baseHeaderCell';

import './ReleasesMatrix.scss';

const SelectionCell = ({ dataItem: { elements, webTag }, setElementSelections }) => {
  const selectedElementAmount = elements.filter(({ selected }) => selected).length;

  return (
    <td align="center">
      {!selectedElementAmount || selectedElementAmount === elements.length ? (
        <Checkbox
          checked={!!selectedElementAmount}
          onClick={() => {
            setElementSelections(webTag, !selectedElementAmount);
          }}
        />
      ) : (
        <div
          className="ElementsMatrixGrid-selectedBlock"
          onClick={() => {
            setElementSelections(webTag, true);
          }}
        >
          <div className="ElementsMatrixGrid-selectedBlockItem" />
        </div>
      )}
    </td>
  );
};

class ReleasesMatrix extends PureComponent {
  state = {
    webTags: [],
    rawWebTags: [],
    currentWebtag: null,
    selectedElements: [],
    selectedWebtags: [],
    listElements: [],
    gridState: {
      sort: [],
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'DefaultFieldName',
            operator: 'neq',
            value: 2,
          },
        ],
      },
    },
  };

  currentArray = findArray('releasePage');

  componentDidMount() {
    const { ElementsListByRelease } = this.props;

    const rawWebTags = this.props.WebTagListByRelease.map((item) => ({
      ...item,
      translatedWebTag: this.handleTranslate(item.webTag),
      DatePost: getTransformDateString(item.DatePost),
      elements: ElementsListByRelease.filter(({ controlTag }) => item.webTag === controlTag).map(
        ({ ElementId, controlTag, ...otherElementProps }) => ({
          ElementId,
          controlTag,
          ...otherElementProps,
        }),
      ),
    })).filter((el) => !this.checkLatinSymbols(el.translatedWebTag));

    this.setState({
      webTags: rawWebTags,
      rawWebTags,
    });
  }

  handleTranslate = (value) => {
    const { translate } = this.props;

    const translateSlugs = TRANSLATE_SLUG_BY_KEY.get(value) || ['unknow_translate_slug'];

    return translateSlugs.reduce((acc, slug) => `${acc}: ${translate(slug)}`, '').slice(2);
  };

  checkLatinSymbols = (str) => /[a-zA-Z]/.test(str);

  getSelectedElements = () => {
    const { webTags } = this.state;

    const tagsByResultElements = webTags
      .filter((webTag) => webTag.elements && webTag.elements.length && webTag.elements.some(({ selected }) => selected))
      .map(({ webTag }) => ({ elementType: 0, elementKey: webTag }));

    const resultElements = webTags.reduce((acc, { webTag, elements }) => {
      const selectedElements = elements && elements.length ? elements.filter(({ selected }) => selected) : [];

      return selectedElements.length
        ? [
            ...acc,
            ...selectedElements.map(({ ElementName, ElementTypeId }) => ({
              elementKey: ElementName,
              elementType: ElementTypeId,
              controlTag: webTag,
            })),
          ]
        : acc;
    }, []);

    return [...resultElements, ...tagsByResultElements];
  };

  pushToChoosingRolesTab = () => {
    const selectedElements = this.getSelectedElements();
    const { DatePost, ReleaseVersion } = this.props;

    tabService.addTab({
      type: 'choosingRoles',
      dataItem: {
        DatePost,
        ReleaseVersion,
        selectedElements: selectedElements,
      },
    });
  };

  onDataStateChange = ({ data: gridState }) => {
    this.setState({
      webTags: filterBy(orderBy(this.state.rawWebTags, gridState.sort), gridState.filter),
      gridState,
    });
  };

  calcIsActive = (gridState, fieldName) =>
    GridColumnMenuFilter.active(fieldName, gridState.filter) ||
    !!gridState.filter.filters.find(({ field }) => field === fieldName);

  expandChange = ({ dataItem }) => {
    this.setState(({ webTags }) => ({
      webTags: webTags.map((webTagItem) =>
        dataItem.webTag === webTagItem.webTag ? { ...webTagItem, expanded: !dataItem.expanded } : webTagItem,
      ),
    }));
  };

  getHandleElementChange = (webTag) => (elementName) => {
    this.setState(({ webTags }) => ({
      webTags: webTags.map((webTagItem) =>
        webTagItem.webTag === webTag
          ? {
              ...webTagItem,
              elements: webTagItem.elements.map((element) =>
                element.ElementName === elementName ? { ...element, selected: !element.selected } : element,
              ),
            }
          : webTagItem,
      ),
    }));
  };

  setElementSelections = (webTag, isSelect) => {
    this.setState(({ webTags }) => ({
      webTags: webTags.map((webTagItem) =>
        webTagItem.webTag === webTag
          ? {
              ...webTagItem,
              elements: webTagItem.elements.map((element) => ({ ...element, selected: isSelect })),
            }
          : webTagItem,
      ),
    }));
  };
  render() {
    const { withTagsReleaseInfo, translate } = this.props;
    const { webTags, gridState } = this.state;
    const isSomeSelected = webTags.some(
      ({ elements }) => elements && elements.length && elements.some(({ selected }) => selected),
    );

    return (
      <>
        <div className="ElementsMatrixGrid-header">
          {checkElement('releasePage-choosingRoles', this.currentArray) && (
            <div>
              <Button type="primary" onClick={this.pushToChoosingRolesTab} disabled={!isSomeSelected}>
                {translate('page.releases.associateSelectedRoles')}
              </Button>
            </div>
          )}
          {checkElement('releasePage-choosingRoles', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({
                    type: 'rolesAccess',
                    dataItem: {
                      selectedElements: this.getSelectedElements(),
                    },
                  });
                }}
                disabled={!isSomeSelected}
              >
                {translate('page.releases.access-role')}
              </Button>
            </div>
          )}

          {checkElement('releasePage-choosingRoles', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({
                    type: 'userAccess',
                    dataItem: {
                      selectedElements: this.getSelectedElements(),
                    },
                  });
                }}
                disabled={!isSomeSelected}
              >
                {translate('page.releases.access-users')}
              </Button>
            </div>
          )}
        </div>

        <div className="ElementsMatrixGrid-container">
          <Grid
            data={webTags}
            filter={gridState.filter}
            sort={gridState.sort}
            onDataStateChange={this.onDataStateChange}
            className="ElementsMatrixGrid"
            detail={({ dataItem: { elements, webTag } }) => (
              <ElementsSubGrid elements={elements} handleElementChange={this.getHandleElementChange(webTag)} />
            )}
            expandField="expanded"
            onExpandChange={this.expandChange}
            sortable
          >
            <GridColumn
              field="selected"
              width="50px"
              headerCell={() => <></>}
              cell={(props) => <SelectionCell {...props} setElementSelections={this.setElementSelections} />}
            />
            <GridColumn
              field="translatedWebTag"
              title={translate('grids.adminPanel.column-page')}
              filter="text"
              width={withTagsReleaseInfo ? '400' : '800'}
              columnMenu={(props) => <FilterScroll data={webTags} {...props} field="translatedWebTag" />}
              headerClassName={this.calcIsActive(gridState, 'translatedWebTag') ? 'active' : ''}
            />
            {withTagsReleaseInfo && (
              <GridColumn
                field="ReleaseVersion"
                title={translate('page.releases.col-releaseVersion')}
                filter="text"
                width="160"
                columnMenu={(props) => <FilterScroll data={webTags} {...props} field="ReleaseVersion" />}
                headerClassName={this.calcIsActive(gridState, 'ReleaseVersion') ? 'active' : ''}
              />
            )}
            {withTagsReleaseInfo && (
              <GridColumn
                field="DatePost"
                title={translate('page.releases.col-dateChange')}
                filter="text"
                warningInfo={translate('page.releases.dateIndicativeMessage')}
                width="180"
                columnMenu={(props) => <FilterScroll data={webTags} {...props} field="DatePost" />}
                headerClassName={this.calcIsActive(gridState, 'DatePost') ? 'active' : ''}
                headerCell={(props) => {
                  return (
                    <BaseHeaderCell
                      {...props}
                      warningInfo={translate('page.releases.dateIndicativeMessage')}
                      filterActivity
                    />
                  );
                }}
              />
            )}
          </Grid>
        </div>
      </>
    );
  }
}

export default withTranslate(ReleasesMatrix);
