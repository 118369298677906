const screenControlTerminalsConfig = (translate, value) => {
  switch (value) {
    case 'screenControlTerminalsConfig-grid':
      return `${translate(`page.adminPanel.pageContent`)}`;
    default:
      return value;
  }
};

export default screenControlTerminalsConfig;
