import React, { PureComponent } from 'react';
import ServiceMainPropsForm from '../../ServiceMainProps';

class ServiceMainProps extends PureComponent {
  render() {
    const { currentServiceId, handleUpdateTabPane } = this.props;

    return (
      <ServiceMainPropsForm
        dataItem={{
          selectItem: { ServiceId: currentServiceId },
        }}
        onSave={handleUpdateTabPane}
        isStepServiceCreation
      />
    );
  }
}

export default ServiceMainProps;
