import { ApiService } from '../../../../services';

const api = new ApiService();

export const PERMISSION_BY_REPORT_TYPE_MAP = new Map([
  ['efficiency', 'terminalOperabilityPeriod'],
  ['technicalEfficiency', 'terminalOperabilityPeriod'],
  ['monitoring', 'communicationTerminalsPeriod-grid'],
]);

export const REQUEST_METHOD_BY_REPORT_TYPE_MAP = new Map([
  ['efficiency', api.terminalsEfficiencyPeriod],
  ['technicalEfficiency', api.terminalsTechnicalEfficiencyPeriod],
  ['monitoring', api.communicationTerminalsPeriod],
]);

export const BASE_GRID_CONFIG = [
  {
    fieldName: 'TerminalId',
    titleSlug: 'grids.terminalOperabilityChoose.column-terminalId',
    width: '140',
  },
  {
    fieldName: 'TerminalName',
    titleSlug: 'grids.terminalOperabilityChoose.column-name',
    width: '250',
    permission: ['efficiency', 'technicalEfficiency'],
  },
  {
    fieldName: 'OwnerName',
    titleSlug: 'grids.terminalOperabilityChoose.column-ownerName',
    width: '250',
  },
  {
    fieldName: 'Address',
    titleSlug: 'grids.terminalOperabilityChoose.column-address',
    width: '200',
  },
  {
    fieldName: 'CityName',
    titleSlug: 'grids.terminalOperabilityChoose.column-city',
    width: '200',
  },
  {
    fieldName: 'NetworkContractName',
    titleSlug: 'grids.terminalOperabilityChoose.column-networkContract',
    width: '250',
  },
  {
    fieldName: 'StatusName',
    titleSlug: 'grids.terminalOperabilityChoose.column-status',
    width: '250',
  },
  {
    fieldName: 'Branch',
    titleSlug: 'grids.terminals.column-branch',
    width: '250',
    permission: ['efficiency', 'technicalEfficiency'],
  },
  {
    fieldName: 'TerminalBranch',
    titleSlug: 'grids.terminals.column-branch',
    width: '250',
    permission: ['monitoring'],
  },
  {
    fieldName: 'Note',
    titleSlug: 'page.terminalSettings.field-notes',
    width: '200',
    permission: ['efficiency', 'technicalEfficiency'],
  },
  {
    fieldName: 'TerminalDescription',
    titleSlug: 'grids.communicationTerminalsPerDay.column-note',
    width: '100',
  },
];

export const AGGREGATE_GRID_CONFIG = [
  {
    fieldName: 'AvgValue',
    titleSlug: 'grids.communicationTerminalsPerDay.column-average-day',
    width: '80',
    permission: ['monitoring'],
  },
  {
    fieldName: 'WorkTotal',
    titleSlug: 'grids.terminalOperabilityChoose.operability',
    width: '150',
    permission: ['efficiency', 'technicalEfficiency'],
  },
];
