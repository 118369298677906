import { getCustomDate } from '../../utils/getCustomDate';

export const MIN_VALID_DATE = getCustomDate({
  customTime: { h: 0, m: 0, s: 0, ms: 0 },
  customDayOfMonth: 1,
  customDateMonth: 0,
  customDateFullYear: 2005,
});
export const MAX_VALID_DATE = getCustomDate({
  customTime: { h: 23, m: 59, s: 59, ms: 0 },
  customDayOfMonth: 1,
  customDateMonth: 0,
  customDateFullYear: 2100,
});

export const INITIAL_VALUES = {
  TimeFrom: getCustomDate(),
  TimeTo: getCustomDate({ customTime: { h: 23, m: 59, s: 59, ms: 0 } }),
  AmountFrom: 0,
  AmountTo: 0,
  ResultFixed: 0,
  ResultPercent: 0,
  CommissionMin: 0,
  CommissionMax: 0,
  ValidDateFrom: MIN_VALID_DATE,
  ValidDateTo: MAX_VALID_DATE,
  Priority: 50,
  Active: true,
  UserNamePost: '',
  DatePost: null,
};
