import React, { PureComponent } from 'react';
import { Card, Col, Row } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import Input from '../Input';
import DealerCommissionFormService from './dealerCommissionForm.service';
import { DEALER_FIELDS, PAYMENT_SYSTEM_FIELDS } from './constants';

import './DealerCommissionForm.scss';

class DealerCommissionForm extends PureComponent {
  componentDidUpdate = (prevProps) => {
    const { formState, setErrorState, formErrors } = this.props;
    const { formState: prevFormState } = prevProps;

    if (!prevFormState || formState !== prevFormState) {
      setErrorState({
        ...formErrors,
        global: '',
      });
    }
  };

  renderInputWithLabel = (
    formState,
    onChangeFormState,
    formErrors,
    onError,
    label,
    fieldName,
    minValue = -99999.9999,
    maxValue = 99999.9999,
    translate,
  ) => {
    return (
      <>
        <Col className="ModalAddCommissions-row" key={fieldName}>
          <Col span={10} md={10} lg={10} className="ModalAddCommissions-label">
            <label>{translate(`grids.dealerCommissionsChange.${label}`)} </label>
          </Col>
          <Col span={14} md={14} lg={14}>
            <Input
              type="text"
              formState={formState}
              onChangeFormState={(name, value) => {
                onChangeFormState(name, value);
              }}
              formErrors={formErrors}
              setError={onError}
              validators={DealerCommissionFormService.getValidators(translate, formState, fieldName)}
              name={fieldName}
              min={minValue}
              max={maxValue}
              key={fieldName}
              className="ModalAddCommissions-input"
            />
          </Col>
        </Col>
      </>
    );
  };

  renderGlobalError = () => {
    const { formErrors } = this.props;

    return formErrors.global && <div className="ModalAddCommissions-globalError">{formErrors.global}</div>;
  };

  render() {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <>
        <Row type="flex">
          <Col span={24} md={24} lg={12}>
            <Card
              type="inner"
              title={translate('page.commissionsServices.dealer') + ':'}
              className="DealerCommissionForm-card"
            >
              <Col>
                {DEALER_FIELDS.map(({ label, fieldName, minValue, maxValue }) => {
                  return this.renderInputWithLabel(
                    formState,
                    onChangeFormState,
                    formErrors,
                    onError,
                    label,
                    fieldName,
                    minValue,
                    maxValue,
                    translate,
                  );
                })}
              </Col>
            </Card>
          </Col>
          <Col span={24} md={24} lg={12}>
            <Card
              type="inner"
              title={translate('page.dealerCommissionsChange.card-title')}
              className="DealerCommissionForm-card"
            >
              <Col>
                {PAYMENT_SYSTEM_FIELDS.map(({ label, fieldName, minValue, maxValue }) => {
                  return this.renderInputWithLabel(
                    formState,
                    onChangeFormState,
                    formErrors,
                    onError,
                    label,
                    fieldName,
                    minValue,
                    maxValue,
                    translate,
                  );
                })}
              </Col>
            </Card>
          </Col>
        </Row>
        {this.renderGlobalError()}
      </>
    );
  }
}

export default withTranslate(DealerCommissionForm);
