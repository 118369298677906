import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { getExistValueValidator } from './validators';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import { createHash } from '../../utils/createHash';
import { ApiService } from '../../services';
import { ExcessTokenLogin } from '../ExpiredPasswordInfoModal/constants';

const api = new ApiService();

class ExpiredPasswordInfoModal extends PureComponent {
  validateFieldOnSubmit = () => {
    const { translate, formState, onError } = this.props;
    const { oldPassword, repeat, newPassword, login } = formState;

    if (login === ExcessTokenLogin) {
      if (repeat && newPassword && repeat !== newPassword) {
        onError(
          'newPasswordNotMatchConfirmed',
          translate('page.expiredPasswordInfoModal.newPasswordNotMatchConfirmed'),
        );

        return translate('page.expiredPasswordInfoModal.oldNewPasswordsMatchError');
      }
      return;
    }

    if (oldPassword && newPassword && oldPassword === newPassword) {
      onError('oldNewPasswordsMatchError', translate('page.expiredPasswordInfoModal.oldNewPasswordsMatchError'));
      return translate('page.expiredPasswordInfoModal.oldNewPasswordsMatchError');
    }

    if (repeat && newPassword && repeat !== newPassword) {
      onError('newPasswordNotMatchConfirmed', translate('page.expiredPasswordInfoModal.newPasswordNotMatchConfirmed'));
      return translate('page.expiredPasswordInfoModal.oldNewPasswordsMatchError');
    }

    return Object.entries(formState).reduce((acc, [key, value]) => {
      const errorMessage = getExistValueValidator(translate)(value);
      onError(key, errorMessage);

      return acc || errorMessage;
    }, '');
  };

  onSubmitForm = async () => {
    const { formState, onCloseModal, history, setIsLoading } = this.props;
    const { oldPassword, newPassword, login } = formState;
    const errorMessage = this.validateFieldOnSubmit();

    if (!errorMessage) {
      try {
        setIsLoading(true);
        if (login === ExcessTokenLogin) {
          await api.changePasswordByUser(JSON.stringify(createHash(newPassword)));
        } else {
          await api.changePasswordByLogin(
            JSON.stringify({ OldHash: oldPassword, NewHash: createHash(newPassword), login }),
          );
        }

        onCloseModal();
        history.push('/login');
      } catch (error) {
        const { showError } = this.props;
        showError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  onDefaultInputChange = () => {
    const { formErrors, onError } = this.props;

    if (formErrors.oldNewPasswordsMatchError) {
      onError('oldNewPasswordsMatchError', '');
    }

    if (formErrors.newPasswordNotMatchConfirmed) {
      onError('newPasswordNotMatchConfirmed', '');
    }
  };

  render() {
    const { translate, onCloseModal, numberDraysBeforePasswordExpires, formState } = this.props;
    const { newPassword, repeat } = formState;

    const isSaveButtonDisabled = !newPassword || !repeat;
    return (
      <Modal
        visible
        closable={!numberDraysBeforePasswordExpires || numberDraysBeforePasswordExpires > 0}
        onCancel={onCloseModal}
        title={translate('page.expiredPasswordInfoModal.passwordChange')}
        zIndex="1053"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => this.onSubmitForm()}
            style={{ margin: '0 auto' }}
            disabled={isSaveButtonDisabled}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button type="primary" onClick={onCloseModal} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>
          <PasswordRecoveryForm {...this.props} onDefaultInputChange={this.onDefaultInputChange} />
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ExpiredPasswordInfoModal);
