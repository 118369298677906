import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import { withTranslate } from '../../contexts/localContext';
import { formatDate } from '@telerik/kendo-intl';
import './cellLastTransaction.scss';

const convertToISOFormat = (date) => formatDate(date, 'dd.MM.yyyy HH:mm:ss');

class CellLastTransaction extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  getColor = () => {
    const { field, dataItem } = this.props;
    const value = dataItem[field];
    const dt = new Date();
    const diff = dt - value;
    const diffToMinutes = parseInt(Math.floor(diff / 60000));
    if (diffToMinutes <= 15) return 'rgb(255,255,255)';
    if (diffToMinutes > 15 && diffToMinutes <= 30)
      //light
      // return 'rgb(255,224,224)';
      //dark
      return 'rgb(248,204,175)';
    if (diffToMinutes > 30 && diffToMinutes <= 60)
      //light
      // return 'rgb(255,192,192)';
      //dark
      return 'rgb(244,178,133)';
    if (diffToMinutes > 60 && diffToMinutes <= 120)
      //light
      // return 'rgb(255,160,160)';
      //dark
      return 'rgb(255,154,102)';
    if (diffToMinutes > 120 && diffToMinutes <= 240)
      // return 'rgb(255,0,0)';
      //light
      // return 'rgb(255,128,128)';
      //dark
      return 'rgb(255,102,0)';
    if (diffToMinutes > 240)
      // return '#ff726f';
      //light
      // return 'rgb(224,64,64)'
      //dark
      return 'rgb(255,0,0)';
    else return '';
  };

  render() {
    const { field, dataItem, mobile, className, style } = this.props;
    const value = dataItem[field];
    const convertedValue = convertToISOFormat(value);
    if (mobile) {
      return <>{convertedValue}</>;
    }
    return (
      <td
        className={className}
        style={{
          ...style,
          backgroundColor: this.getColor(),
          borderBottom: this.getColor() === 'rgb(255,255,255)' ? '1px solid #000000' : '1px solid #ffffff',
          color: '#000000',
          zIndex: 4,
        }}
      >
        {convertedValue}
      </td>
    );
    // return (<td style={{ backgroundColor: this.getColor() }}>{this.renderData()}</td>);
  }
}

export default withTranslate(CellLastTransaction);
