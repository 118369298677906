// Бек зберігає профілі з елементами у вигляді зв'язного списку де кожен профіль має лінку на свій батьківський профіль(по суті це
// лінійне представлення дерева). На елементах профілів існують властивості і їх значення. Якщо з'ясовуєтся що властивості не існує в
// поточному профілі, але вона існує в одному з батьківських профілів - вважаєтся що її значення для поточного профілю успадковуются від батьківського
// профіля в якому воно є. Це є умовною угодою Властивість яка справді існує в бд - вважается перевизначеною, властивість якої не існує в бд - але за
// умовною угодою її значення успадковуется від батьківського профілю вважается не визначеною. Оскільки не визначених валстивостей не має в бд, але користувачу
// іх відображати протрібно - фрон вираховує їх згідно умовної угоди.

class NavigationMenuPropsService {
  calcPropBySelectedElements = (props, selectedElements, selectedProfiles, allDefinedProps) => {
    //props - властивості з шуканим значенням що існують у цільовому елементі і профілі або в його батьк профілях
    //allDefiniteProps - властивості з будь-яким значенням що існують у цільовому елементі і  профілі або в його батьк профілях
    if (!props || !props.length) {
      return [];
    }

    // У нас пошук по певній кількості профілів за певною кількістю єлементів, тут відбувается множення масивів профілів і елементів.
    const resultElements = selectedProfiles.reduce(
      (acc, { profileId, parentHistorySlug, name }) => [
        ...acc,
        ...selectedElements.map((element) => ({
          ...element,
          profileId,
          profileHistorySlug: `${parentHistorySlug},${profileId}`,
          profileName: name,
        })),
      ],
      [],
    );

    return (
      resultElements
        // Знаходимо/калькулюємо згідно умовної угоди массив властивостей для кожної зв'язки(профіль-елемент)
        //В массиві знаходятся властивості елементів та (профілів та батьківських профілів цих профілів)
        .reduce((acc, { profileHistorySlug, menuId, profileId, profileName }) => {
          //profileListSlugList - строка що містить номер профілю та номера всіх батьківських профілів
          const profileListSlugList = profileHistorySlug.split(',');

          const propsByElement = props
            // Відфільтровуємо властивості що належать поточному елементу та (профілю і його батьківським профілям), адже нам треба створювати фантоми -
            //значення яких не має тож вони наслідуются від батьківських профілів за умовною угодою
            .filter(
              ({ menuId: propMenuId, profileId: propProfileId }) =>
                propMenuId === menuId && profileListSlugList.includes(`${propProfileId}`),
            )
            .reduce((acc, currentProp) => {
              //знаходимо дублікати адже властивість може бути визначена у декількох батьківських профілях, ми вибираємо той батьківський профіль,
              //що знаходится найнижче, тобто коли ми не знаходимо значень - ми починаємо підійматись вгору від профіля по дереву батьківських профілів доки не
              //знайдемо профіль де ця властивіть перевизначена
              const duplicate = acc.find(
                ({ language, menuId }) => menuId === currentProp.menuId && language === currentProp.language,
              );

              if (!duplicate) {
                return [...acc, currentProp];
              }
              //якщо ймовірний дублікат має такий же профайл що і ціловий(тобто він не належить батьківським профілям а належить саме профілю
              //по якому ми шукаемо), логічно шо він автоматично попадає в масив унікальних значень
              if (`${duplicate.profileId}` === `${currentProp.profileId}`) {
                return `${currentProp.profileId}` === `${profileId}` ? [...acc, currentProp] : acc;
              }

              //тут відбувается змагання дублікатів profileListSlugList - як вже згадувалось строка з номером профіля і всіх його батьк профілів
              // чим далі профіль в строці тим нижче він у дереві, тобто строка '0,1,7,15' фактично означає 0->1->7->15 де 0 - корньовий
              //логічно що дублікат профіля 7 виграє у дублікату профіля 1, адже він знаходится нижче по дереву і ближче до цільового профіля(в нашому випадку наприклад 15)
              return profileListSlugList.indexOf(`${currentProp.profileId}`) >
                profileListSlugList.indexOf(`${duplicate.profileId}`)
                ? acc.map((item) =>
                    item.menuId === duplicate.menuId && item.language === duplicate.language ? currentProp : item,
                  )
                : [...acc, currentProp];
            }, []);

          return [
            ...acc,
            ...(propsByElement && propsByElement.length
              ? propsByElement.map((prop) => ({
                  ...prop,
                  profileId,
                  profileName,
                  legacyProfileId: prop.profileId,
                  //legacyProfileId - профіль який вийграв у змаганні диблукатів і з якого наслідуется значення
                  isDefinition: `${prop.profileId}` === `${profileId}`,
                  profileLevel: profileListSlugList.length - 2,
                  parentProfileId: profileListSlugList[profileListSlugList.length - 2],
                  profileHistorySlug,
                }))
              : []),
          ];
        }, [])
        .map((item) => ({
          ...item,
          id: `${item.language}-${item.profileId}-${item.menuId}`,
        }))
        //властивість може бути не знайдена не тільки тому що його не має(тобто воно не визначена і тоді шукаємо в батьк профілях) а й тому що воно є -
        // але має інакше значення ніж те - по якому ми шукаємо. Щоб уникнути цього багу ми знаходимо всі перевизначені властивості по вибранним елементам і профілям
        //перевіряемо чи є наша властивіть в списку перевизначених, якшо не має - ми можемо бути точно впевнені - шо ця властивіть не визначена і впевнено його брати з
        //батьківських профілів
        .filter(({ menuId, legacyProfileId, profileHistorySlug }) => {
          const definedList = allDefinedProps.filter(
            ({ profileId: definedProfileId, menuId: definedMenuId }) =>
              profileHistorySlug.includes(`${definedProfileId}`) && menuId === definedMenuId,
          );

          return (
            !definedList.length ||
            !definedList.some(
              ({ profileId }) =>
                profileHistorySlug.indexOf(`${profileId}`) > profileHistorySlug.indexOf(`${legacyProfileId}`),
            )
          );
        })
    );
  };

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.menuExtendedProperties) {
      return { props: [], hasNextPage: false };
    }
    const menuExtendedProperties = data.data.menuExtendedProperties;

    const props =
      menuExtendedProperties.items && menuExtendedProperties.items.length ? menuExtendedProperties.items : [];

    const pageInfo = menuExtendedProperties.pageInfo;

    return {
      props,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };
}
export default new NavigationMenuPropsService();
