import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import DealerCommissions from './DealerCommissions';
import PageTabs from '../../components/tabber/tabber';

class DealerCommissionsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-dealer-commissions',
          content: <DealerCommissions />,
          closable: false,
          key: '0',
        },
      ],
    };
  }
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}
export default withTranslate(DealerCommissionsPage);
