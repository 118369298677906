import PropTypes from 'prop-types';
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Button, Dropdown, Icon, Menu, Divider } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import RemoteCommands from '../../modals/remoteCommands';
import './cellDropdown.scss';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

/**
 * Dropdown for a few actions in the GridCell
 */

class CellDropdown extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    handlers: PropTypes.arrayOf(PropTypes.any),
    mobile: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentArray = findArray(props.permissionName);
  }
  state = {
    visible: false,
  };

  getVisible = (flag) => {
    this.setState({ visible: flag });
  };

  setVisible = (handler) => {
    if (handler) {
      handler.action(this.props);
    }
    this.setState({ visible: false });
  };

  getContent = () => {
    const { handlers, translate, dataItem, remoteCommands, permissionName, gridName } = this.props;
    const { visible } = this.state;
    return (
      <Menu className="BaseGridCellDropdown">
        {handlers &&
          handlers.map((handler, i) => {
            if (handler.type === 'divider') {
              return <Divider />;
            }

            return (
              handler.show && (
                <Menu.Item
                  onClick={() => this.setVisible(handler)}
                  key={`cell-dropdown-menu-item-${i}`}
                  disabled={handler.disabled}
                >
                  {`${translate(handler.title)}`}
                </Menu.Item>
              )
            );
          })}
        {remoteCommands && checkElement(`${gridName}-remoteCommands`, this.currentArray) && (
          <RemoteCommands
            onVisibleChange={this.props.on}
            dataItem={dataItem}
            permissionName={permissionName}
            gridName={gridName}
            setVisible={this.setVisible}
            visible={visible}
          />
        )}
      </Menu>
    );
  };

  renderLink() {
    const { visible } = this.state;
    const { field, dataItem } = this.props;
    const value = dataItem[field];
    return (
      <Dropdown
        overlay={this.getContent()}
        overlayClassName="cell-dropdown"
        trigger={['click']}
        overlayStyle={{ minWidth: '300px' }}
        onVisibleChange={this.getVisible}
        visible={visible}
      >
        <Button className="cell-dropdown-btn" type="primary">
          <span>{value}</span>
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }

  render() {
    const { mobile, className, style, customClassName } = this.props;

    if (mobile) {
      return <>{this.renderLink()}</>;
    }

    return (
      <td className={`${className} ${customClassName} lg-p-0`} style={{ ...style, zIndex: 4 }}>
        {this.renderLink()}
      </td>
    );
  }
}

export default withTranslate(CellDropdown);
