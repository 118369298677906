import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../contexts/localContext';
import { Tooltip } from 'antd';
import IconBranded from './icon-brandedByStandart.svg';
import IconNotBranded from './icon-notBranded.svg';
import './cellStockStatus.scss';

class CellStockStatus extends GridCell {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
    isTooltip: PropTypes.bool,
    dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
    field: PropTypes.string.isRequired,
  };

  getColor = (value) => {
    const { translate } = this.props;
    switch (value) {
      case `${translate('grids.adminStockPhotos.grid-status-value-notBranded')}`:
        return 'notBranded';
      case `${translate('grids.adminStockPhotos.grid-status-value-brandedByStandart')}`:
        return 'brandedByStandart';
      case `${translate('grids.adminStockPhotos.grid-status-value-underConsideration')}`:
        return 'underConsideration';
      case `${translate('grids.adminStockPhotos.grid-status-value-branded')}`:
        return 'branded';
      default:
        return;
    }
  };

  checkImg = (value) => {
    const { translate } = this.props;
    switch (value) {
      case `${translate('grids.adminStockPhotos.grid-status-value-underConsideration')}`:
        return false;
      default:
        return true;
    }
  };

  getImg = (value) => {
    const { translate } = this.props;
    switch (value) {
      case `${translate('grids.adminStockPhotos.grid-status-value-notBranded')}`:
        return IconNotBranded;
      case `${translate('grids.adminStockPhotos.grid-status-value-brandedByStandart')}`:
      case `${translate('grids.adminStockPhotos.grid-status-value-branded')}`:
        return IconBranded;
      default:
        return;
    }
  };

  getTooltip = () => {
    const {
      dataItem: { initialTerminalStatusName },
      translate,
    } = this.props;
    switch (initialTerminalStatusName) {
      case 'NotBranded':
        return translate('grids.adminStockPhotos.tooltip-notBranded');
      case 'Branded':
        return translate('grids.adminStockPhotos.tooltip-branded');
      default:
        return;
    }
  };

  render() {
    const { field, dataItem, className, style, mobile, isTooltip } = this.props;
    const value = dataItem[field];

    if (mobile && isTooltip) {
      return (
        <Tooltip
          title={this.getTooltip()}
          placement="left"
          trigger="click"
          className="tooltip-details"
          // getTooltipContainer={() => this.ref.current}
        >
          <div className="cell-stock-status mobile">
            <span className={`cell-stock-status-span ${this.getColor(value)}`}>
              {/* {checkImg(value) && <img src={getImg(value)} alt="cell-photo" />} */}
              {value}
            </span>
          </div>
          {/* <Button
            type="link"
            className={`lg-p-0 lg-w-100 cell-stock-status-span ${this.getColor(value)}`}
          >
            {value}
          </Button> */}
        </Tooltip>
      );
    } else if (mobile && !isTooltip) {
      return (
        <div className="cell-stock-status mobile">
          <span className={`cell-stock-status-span ${this.getColor(value)}`}>
            {/* {checkImg(value) && <img src={getImg(value)} alt="cell-photo" />} */}
            {value}
          </span>
        </div>
      );
    } else if (!mobile && isTooltip) {
      return (
        <td
          className={`${className} cell-stock-status`}
          style={{ ...style, padding: 0, zIndex: 4 }}
          align="center"
          ref={this.ref}
        >
          <Tooltip
            title={this.getTooltip()}
            placement="left"
            trigger="click"
            className="tooltip-details"
            // getTooltipContainer={() => this.ref.current}
          >
            <span className={`cell-stock-status-span ${this.getColor(value)}`}>
              {this.checkImg(value) && <img src={this.getImg(value)} alt="cell-stock" />}
              {value}
            </span>
          </Tooltip>
        </td>
      );
    } else {
      return (
        <td className={`${className} cell-stock-status`} style={{ ...style, padding: 0, zIndex: 4 }} align="center">
          <span className={`cell-stock-status-span ${this.getColor(value)}`}>
            {this.checkImg(value) && <img src={this.getImg(value)} alt="cell-stock" />}
            {value}
          </span>
        </td>
      );
    }
  }
}

export default withTranslate(CellStockStatus);
