import React, { PureComponent } from 'react';
import DealerCommissionsExpectedChangesGrid from './DealerCommissionsExpectedChangesGrid';
import PageTabs from '../../components/tabber/tabber';

class TemplateServices extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-dealerCommissionsExpectedChanges',
        content: <DealerCommissionsExpectedChangesGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} />;
  }
}

export default TemplateServices;
