import React, { PureComponent } from 'react';
import { Row, Spin, Icon } from 'antd';
import {
  IntlProvider,
  LocalizationProvider,
  LocalizationService,
  load,
  loadMessages,
} from '@progress/kendo-react-intl';
import { BrowserRouter } from 'react-router-dom';

import AppRouting from './appRouting';
import { BaseApiService } from './services';
import { LocalContext } from './contexts/localContext';
import DialogError from './components/modals/error';
import { StorageService } from './services';
import { getIsFunction } from './utils/getIsFunction';
import { LoginContextProviderContainer } from './contexts/LoginContext';
import { ExpiredPasswordDialogContextProviderContainer } from './contexts/ExpiredPasswordDialogContext';
import { ErrorsCodesContextProviderContainer } from './contexts/ErrorCodesContext';

let lService;

const loadTranslation = async (locale) => {
  const api = new BaseApiService();
  const core = await api.get('/i18n/core.json');
  const global = await api.get(`/i18n/global-${locale}.json`);
  const kendoMessages = await api.get(`/i18n/kendo-${locale}.json`);
  const messages = await api.get(`/i18n/${locale}.json`);

  load(...[...core, ...global]);
  loadMessages(kendoMessages.kendo, locale);
  loadMessages(messages, locale);
};

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locale: 'uk',
      isLoaded: false,
      error: null,
      isPermittedAutoLogin: false,
      unreadedCount: 0,
    };
  }

  componentDidMount() {
    let { locale } = this.state;
    let initialLocale = StorageService.getItem('locale');
    const initPermittedAutoLogin = StorageService.getItem('isPermittedAutoLogin');
    if (initialLocale) {
      locale = initialLocale;
    }
    if (initPermittedAutoLogin) {
      this.changePermissionAutoLogin(initPermittedAutoLogin);
    }
    this.changeLocale(locale);
  }

  componentWillUnmount() {
    this.setState({ isLoaded: false });
  }

  translate = (message) => {
    return lService.toLanguageString(message, message);
  };

  changeLocale = (locale) => {
    this.setState({ isLoaded: false }, async () => {
      await loadTranslation(locale);
      lService = new LocalizationService(locale);
      this.setState({ isLoaded: true, locale });
      StorageService.setItem('locale', locale || 'uk');
    });
  };

  changePermissionAutoLogin = (isPermittedAutoLogin) => {
    const devideCode = StorageService.getItem('devideCode');
    const value = devideCode !== null ? isPermittedAutoLogin : false;
    this.setState(
      () => {
        return {
          isPermittedAutoLogin: value,
        };
      },
      () => StorageService.setItem('isPermittedAutoLogin', value),
    );
  };

  changeUnreadedCount = (unreadedCount) => {
    this.setState({ unreadedCount });
  };

  renderWait = () => (
    <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
      <Spin indicator={<Icon type="loading" style={{ fontSize: 60 }} spin />} />
    </Row>
  );

  handleShowError = (errorCore, errorCallback) => {
    this.setState({
      error: {
        core: errorCore,
        callback: () => {
          this.setState({ error: null });

          if (errorCallback && getIsFunction(errorCallback)) {
            errorCallback();
          }
        },
      },
    });
  };

  renderApp = () => {
    const { locale, error, isPermittedAutoLogin, unreadedCount } = this.state;
    const context = {
      locale,
      translate: this.translate,
      changeLocale: this.changeLocale,
      showError: this.handleShowError,
      isPermittedAutoLogin,
      changePermissionAutoLogin: this.changePermissionAutoLogin,
      unreadedCount,
      changeUnreadedCount: this.changeUnreadedCount,
    };

    console.log(
      '%cLast update',
      'color: #066ab7; font-size: 14px; font-weight: 700',
      process.env.REACT_APP_LAST_UPDATE
        ? new Date(process.env.REACT_APP_LAST_UPDATE).toLocaleDateString('uk-UA', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })
        : 'Undefind',
    );

    return (
      <BrowserRouter>
        <LocalContext.Provider value={context}>
          <IntlProvider locale={locale}>
            <LocalizationProvider language={locale}>
              <LoginContextProviderContainer>
                <ExpiredPasswordDialogContextProviderContainer>
                  <ErrorsCodesContextProviderContainer>
                    <AppRouting>{error && <DialogError error={error} />}</AppRouting>
                  </ErrorsCodesContextProviderContainer>
                </ExpiredPasswordDialogContextProviderContainer>
              </LoginContextProviderContainer>
            </LocalizationProvider>
          </IntlProvider>
        </LocalContext.Provider>
      </BrowserRouter>
    );
  };

  render = () => {
    const { isLoaded } = this.state;
    return <>{isLoaded ? this.renderApp() : this.renderWait()}</>;
  };
}
