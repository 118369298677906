import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Tooltip } from 'antd';
import ModalCellValue from './modalCellComment';
import './cellComment.scss';

class CellComment extends GridCell {
  state = {
    isVisible: false,
  };

  toggleIsVisible = () => {
    this.setState(({ isVisible }) => {
      return {
        isVisible: !isVisible,
      };
    });
  };

  cutValue = (str = '') => {
    const arrStr = str.split('');
    let newStr = '';
    if (str && arrStr.length > 15) {
      newStr = arrStr.slice(0, 12).join('');
      return `${newStr} ...`;
    }
    return str;
  };

  render() {
    const { className, style, dataItem, field, updateGrid, name } = this.props;
    const value = dataItem[field];
    const cutVal = this.cutValue(value);
    const { isVisible } = this.state;
    return (
      <>
        <Tooltip trigger={['hover']} title={value} placement="bottom" className="tooltip-details">
          <td className={`${className} lg-p-0`} style={{ ...style }}>
            <Button className="lg-w-100 addSets-cell-value" type="primary" onClick={this.toggleIsVisible}>
              {cutVal}
            </Button>
          </td>
        </Tooltip>
        {isVisible && (
          <ModalCellValue
            visible={isVisible}
            updateGrid={updateGrid}
            item={dataItem}
            value={value}
            name={name}
            toggleModal={this.toggleIsVisible}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CellComment);
