import React, { PureComponent } from 'react';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

const CopyCommissionsContainerHOC = (WrappedComponent) => {
  return class extends PureComponent {
    state = {
      allOwners: [],
      currentOwners: [1],
      fromOwner: [],
      toOwner: [],
      paymentTypes: [],
      servicesList: [],
      dateStart: new Date(new Date().setDate(new Date().getDate() + 1)),
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      fromSelectedServicesList: [],
      fromSelectedPaymentTypes: [],
      toSelectedServicesList: [],
      toSelectedPaymentTypes: [],
      isLoading: false,
    };

    fetchOwners = async () => {
      try {
        const { currentOwners } = this.state;
        await this.toggleLoader(true);
        const allOwners = await api.owners();
        await this.setState({ allOwners });
        await this.toggleLoader(false);
        if (allOwners && allOwners.length) {
          await this.setState({ currentOwners });
        }
      } catch (e) {
        const { showError } = this.props;
        showError(e);
        await this.toggleLoader(false);
      }
    };

    fetchPaymentType = async () => {
      try {
        this.toggleLoader(true);
        const paymentTypes = await api.getPaymentTypeList();
        await this.setState({ paymentTypes });
        await this.toggleLoader(false);
      } catch (error) {
        const { showError } = this.props;
        showError(error);
        await this.toggleLoader(false);
      }
    };

    fetchServices = async () => {
      const { currentOwners } = this.state;
      try {
        this.toggleLoader(true);
        const data = await api.getServiceDictionary({
          ownerId: currentOwners,
        });
        await this.setState({
          servicesList: data && data.length ? data : [],
        });
        await this.toggleLoader(false);
      } catch (e) {
        const { showError } = this.props;
        showError(e);
        await this.toggleLoader(false);
      }
    };

    handleSelectItems = (name) => (selectedItems) => {
      this.setState({ [name]: selectedItems });
    };

    fetchData = async () => {
      await this.fetchOwners();
      await this.fetchServices();
      await this.fetchPaymentType();
    };

    onChangeDate = (e) => {
      this.setState({ dateStart: e.target.value });
    };
    toggleLoader = (value) => {
      this.setState({ isLoading: value });
    };

    componentDidMount() {
      this.fetchData();
    }

    render = () => {
      const { isLoading } = this.state;
      return (
        <>
          <WrappedComponent
            {...this.state}
            {...this.props}
            handleSelectItems={this.handleSelectItems}
            onChangeDate={this.onChangeDate}
            toggleLoader={this.toggleLoader}
          />
          {isLoading && <LoadingPanel />}
        </>
      );
    };
  };
};
export default CopyCommissionsContainerHOC;
