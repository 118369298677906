import React, { PureComponent } from 'react';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { ApiService } from '../../services';

import './TerminalProfiles.scss';
import { Button, Col } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import ModalTerminalsProfile from './ModalTerminalsProfile/ModalTerminalsProfile';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class TerminalProfiles extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('terminalProfiles');
  }
  state = {
    allOwners: [],
    currentOwners: [1],
    terminalsProfilesList: [],
    selectedItems: [],
    isVisible: false,
    isLoading: false,
  };

  toggleLoader = (val) => {
    this.setState({ isLoading: val });
  };
  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const allOwners = await api.owners();
      await this.setState({ allOwners });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  fetchTerminalsProfiles = async () => {
    const { currentOwners } = this.state;
    try {
      await this.toggleLoader(true);
      const terminalsProfilesList = await api.getTerminalsProfiles(currentOwners);
      await this.setState({ terminalsProfilesList });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  handleSelectOwners = (currentOwners) => {
    this.setState({ currentOwners }, this.fetchTerminalsProfiles);
  };

  onSelectItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  onHandleClickOpen = () => {
    this.setState({ isVisible: true });
  };
  onHandleClickClose = () => {
    this.setState({ isVisible: false });
  };

  createToolbar = () => {
    const { allOwners, currentOwners, selectedItems } = this.state;
    const { translate } = this.props;
    return (
      <Col>
        <div className="TerminalProfiles-toolbar">
          <div className="TerminalProfiles-row">
            <div className="TerminalProfiles-label">{translate('page.commissionsServices.dealer')}</div>
            <div className="TerminalProfiles-gridDropdown">
              <GridDropdown
                data={DropdownNormalizersService.normalizeOwners(allOwners)}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={currentOwners}
                onSave={this.handleSelectOwners}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
              />
            </div>
          </div>
          {checkElement('terminalProfiles-btnChange', this.currentArray) && (
            <Button type="primary" disabled={!selectedItems.length} onClick={this.onHandleClickOpen}>
              {translate('page.commission.btn-toggle')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  async componentDidMount() {
    await this.fetchOwners();
    await this.fetchTerminalsProfiles();
  }

  render() {
    const { terminalsProfilesList, isVisible, selectedItems, isLoading } = this.state;
    const terminalId = selectedItems.map(({ TerminalId }) => TerminalId);

    if (!checkElement('terminalProfiles-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={terminalsProfilesList}
          toolbar={this.createToolbar()}
          name="terminalProfilesGrid"
          onSelect={this.onSelectItems}
          fieldForSelection="TerminalId"
          isShowSpecificFilterByFieldName="TerminalId"
          onRefresh={this.fetchTerminalsProfiles}
          isLoading={isLoading}
          multiSelected
        >
          <GridColumn field="selected" showAllSelected={true} width="50px" filterable={false} columnMenu={false} />
          <GridColumn field="OwnerName" width="150px" title="grids.terminalProfiles.col-owner" />
          <GridColumn field="TerminalName" width="150px" title="grids.terminalProfiles.col-name" />
          <GridColumn field="RegionName" width="150px" title="grids.terminalProfiles.col-region" />
          <GridColumn field="CityName" width="150px" title="grids.terminalProfiles.col-city" />
          <GridColumn field="TerminalId" width="150px" title="grids.terminalProfiles.col-terminal-num" />
          <GridColumn field="TypeName" width="150px" title="grids.terminalProfiles.col-type-terminal" />
          <GridColumn field="TerminalAddress" width="150px" title="grids.terminalProfiles.col-address" />
          <GridColumn field="MenuProfileId" width="150px" title="grids.terminalProfiles.col-profile-num" />
          <GridColumn field="MenuProfileName" width="150px" title="grids.terminalProfiles.col-profile-menu" />
          <GridColumn field="StatusName" width="150px" title="grids.terminalProfiles.col-status" />
          <GridColumn field="AppVersion" width="150px" title="grids.terminalProfiles.col-vers-app" />
          <GridColumn field="OsVersion" width="150px" title="grids.terminalProfiles.col-os-vers" />
          <GridColumn field="TerminalName2" width="150px" title="grids.terminalProfiles.col-board" />
          <GridColumn field="TerminalAddress2" width="150px" title="grids.terminalProfiles.col-coordination" />
          <GridColumn
            field="DateLastConnectNoUTC"
            format="dd.MM.yyyy HH:mm:ss"
            filter="date"
            width="150px"
            title="grids.terminalProfiles.col-last-connection"
          />
        </Grid>

        {isVisible && (
          <ModalTerminalsProfile
            visible={isVisible}
            onClose={this.onHandleClickClose}
            terminalId={terminalId}
            reFetchData={this.fetchTerminalsProfiles}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TerminalProfiles);
