import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Row, Input } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import './SuspendedFinancialTransactionsGrid.scss';

const api = new ApiService();

class PaymentActionsGrid extends PureComponent {
  state = {
    isLoading: false,
    paymentActions: [],
  };

  fetchPaymentsActionsList = async () => {
    const { paymentId } = this.props;
    try {
      await this.setState({ isLoading: true });
      const paymentActions = await api.getPaymentsActionsList(paymentId);
      await this.setState({
        isLoading: false,
        paymentActions: paymentActions && paymentActions.length ? paymentActions : [],
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  renderToolbar = () => {
    const { translate, paymentId } = this.props;

    return (
      <Row>
        <div className="UnlocksHistoryGrid-toolbar">
          <div className="PaymentActionsGrid-label">
            {translate('page.suspendedFinancialTransactions.col-paymentID')}
          </div>
          <Input value={paymentId} disabled className="PaymentActionsGrid-input" />
        </div>
      </Row>
    );
  };

  componentDidMount() {
    this.fetchPaymentsActionsList();
  }

  render() {
    const { paymentActions, isLoading } = this.state;

    return (
      <Grid
        data={paymentActions}
        onRefresh={this.fetchPaymentsActionsList}
        toolbar={this.renderToolbar()}
        isLoading={isLoading}
        name="suspendedFinancialTransactionsActionsGrid"
      >
        <GridColumn
          field="ActionId"
          title="page.suspendedFinancialTransactions.col-actionId"
          width="240"
          filter="numeric"
        />
        <GridColumn field="Action" title="page.suspendedFinancialTransactions.col-action" width="240" />
        <GridColumn field="Value" title="page.suspendedFinancialTransactions.col-actionValue" width="240" />
        <GridColumn field="UserNamePost" title="page.suspendedFinancialTransactions.col-performer" width="240" />
        <GridColumn
          field="DatePost"
          title="page.suspendedFinancialTransactions.col-date"
          width="240"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(PaymentActionsGrid);
