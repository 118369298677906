export const INITIAL_VALUES = {
  UserId: '',
  Login: '',
  FirstName: '',
  LastName: '',
  Status: '',
};

export const MODAL_INITIAL_VALUE = {
  NewCodeCollector: null,
  RepeatCodeCollector: null,
};
