import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@telerik/kendo-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { ApiService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';

import './GridTerminalOperabilityEvents.scss';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

class GridTerminalOperabilityEvents extends PureComponent {
  constructor(props) {
    super(props);
    const {
      itemProps: { DateStart, DateEnd },
    } = props;
    this.state = {
      isLoading: false,
      data: [],
      start: DateStart,
      end: DateEnd,
      initialSort: { field: 'DatePost', dir: 'desc' },
    };
    const date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90);
    this.maxDate = new Date().setHours(23, 59, 59, 999);
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  changeDateSeconds = (formatedDate) => {
    const date = new Date(formatedDate);
    return date;
  };

  filterByDatePost = (el) => {
    const { start, end } = this.state;
    if (el && el.DatePost) {
      const startDate = new Date(start).setMilliseconds(0);
      const endDate = new Date(end).setMilliseconds(0);
      const changedDate = this.changeDateSeconds(el.DatePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  makeQuery = async () => {
    const { start, end } = this.state;
    const {
      itemProps: {
        dataItem: { TerminalId },
      },
    } = this.props;
    if (!start || !end || !TerminalId) return;
    const DateStart = convertToISOFormat(start);
    const DateEnd = convertToISOFormat(end);
    try {
      this.toggleLoader(true);
      const model = {
        TerminalId,
        DateStart,
        DateEnd,
      };
      const data = await api.terminalEvents(model);
      // console.log('GridTerminalOperabilityEventsDay::')
      // console.log(data);
      this.setState({ data: data.filter((el) => this.filterByDatePost(el)) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onStart = (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ start: value });
  };

  onEnd = (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ end: value });
  };

  buildToolbar = () => {
    const { translate, permissionName, searchButtonPerrmissionName } = this.props;
    const { start, end } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col className="operability-datepicker-wrap" xs={24} lg={20}>
            <DatePicker
              onClickOutside={false}
              value={start}
              onChange={this.onStart}
              format="yyyy.MM.dd HH:mm:ss"
              min={new Date(this.minDate)}
              max={new Date(this.maxDate)}
            />

            <DatePicker
              onClickOutside={false}
              value={end}
              onChange={this.onEnd}
              format="yyyy.MM.dd HH:mm:ss"
              min={new Date(this.minDate)}
              max={new Date(this.maxDate)}
            />
          </Col>
          {checkElement(searchButtonPerrmissionName, findArray(permissionName)) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery}>
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { permissionName, gridPermissionName } = this.props;
    const { isLoading, data, initialSort } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name={gridPermissionName}
        permissionName={permissionName}
        initialSort={initialSort}
      >
        <GridColumn field="EventId" title="grids.terminal-events.column-eventId" width="150" filter="numeric" />

        <GridColumn field="TypeName" title="grids.terminal-events.column-typeName" width="150" />

        <GridColumn field="Operation" title="grids.terminal-events.column-operation" width="150" />

        <GridColumn field="ServiceId" title="grids.terminal-events.column-serviceId" width="150" filter="numeric" />

        <GridColumn
          field="TransactionId"
          title="grids.terminal-events.column-transactionId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="ObjectName" title="grids.terminal-events.column-objectName" width="150" />

        <GridColumn field="ObjectId" title="grids.terminal-events.column-objectId" width="150" filter="numeric" />

        <GridColumn field="Message" title="grids.terminal-events.column-message" width="150" />

        <GridColumn field="ErrorCode" title="grids.terminal-events.column-errorCode" width="150" filter="numeric" />

        <GridColumn field="Account" title="grids.terminal-events.column-account" width="150" />

        <GridColumn
          field="DateInput"
          title="grids.terminal-events.column-dateInput"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="DatePost"
          title="grids.terminal-events.column-datePost"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalOperabilityEvents);
