class ManageBanknotesGQLService {
  getGQLResponse = (data, selectDataServicesData, translate) => {
    if (!data || !data.data || !data.data.terminalServiceDenomination) {
      return { terminalServiceDenomination: [], hasNextPage: false, hasPreviousPage: false };
    }

    const terminalServiceDenomination = data.data.terminalServiceDenomination;
    const pageInfo = terminalServiceDenomination.pageInfo;

    const result =
      terminalServiceDenomination.items && terminalServiceDenomination.items.length
        ? terminalServiceDenomination.items.map((item) => {
            if (item.serviceId === 0) {
              return {
                ...item,
                customId: `${item.serviceId}${item.terminalId}`,
                serviceName: translate('page.banknotesRestrictions.riskService'),
              };
            }
            return {
              ...item,
              customId: `${item.serviceId}${item.terminalId}`,
              serviceName: selectDataServicesData.find((itemService) => itemService.ServiceId === item.serviceId)
                .ServiceName,
            };
          })
        : [];

    return {
      banknotesData: result,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new ManageBanknotesGQLService();
