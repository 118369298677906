import React, { PureComponent } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';

import './ReleasesMatrix.scss';

class ElementsSubGrid extends PureComponent {
  onSelectionChange = ({ dataItem: { ElementName } }) => {
    const { handleElementChange } = this.props;

    handleElementChange(ElementName);
  };

  render() {
    const { elements, translate } = this.props;

    console.log('elements', elements);

    return (
      <Grid
        className="ElementsSubGrid"
        data={elements}
        selectedField="selected"
        onSelectionChange={this.onSelectionChange}
        onRowClick={this.onSelectionChange}
      >
        <GridColumn field="selected" width="50px" headerCell={() => <></>} />
        <GridColumn field="translatedElementName" title={translate('grids.adminPanel.column-element')} width="600" />
        <GridColumn field="ElementTypeName" title={translate('owner.column-type')} width="150" />
      </Grid>
    );
  }
}

export default withTranslate(ElementsSubGrid);
