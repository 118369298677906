import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button, Popconfirm } from 'antd';
import { ApiService, StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import findArray from '../../../utils/findArrForCurrentPage';
import ModalAssignBusinessService from './modalAssignBusinessService';

const api = new ApiService();

class GridScreenControlAssignBusinessServices extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlBusinessServices');
  }

  static propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    isLoading: false,
    data: [],
    visible: false,
    selectedItems: [],
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
    console.log(this.props.item);
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-screenControlAssignBusinessServices');
  };

  makeQuery = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const data = await api.mainProfileListForModalChangeProfile([0]);
      this.setState({ data });
    } catch (err) {
      showError(err);
    } finally {
      this.toggleLoader();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { showPopupConfirm, selectedItems } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Popconfirm
          placement="right"
          style={{ right: '20px' }}
          title={'Вы уверены что хотите назначить шаблон'}
          visible={showPopupConfirm}
          onConfirm={this.onSubmitHandler}
          onCancel={this.closePopupConfirm}
          okText={translate('grids.certificates-issued.onOkText')}
          cancelText={translate('grids.certificates-issued.onCancelText')}
        >
          {
            // checkElement('screenControlAssignBusinessServices-btn-assign', this.currentArray) &&
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              onClick={this.toggleModal}
              disabled={!selectedItems.length}
            >
              {translate('grids.profilesMainProfileList.modal-assign-template-title')}
            </Button>
          }
        </Popconfirm>
      </Col>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { isLoading, data, visible, selectedItems } = this.state;
    const {
      item: { businessServices },
    } = this.props;
    const MainProfileIds = StorageService.getItem('selectionListForGrid-screenControlAssignBusinessServices');
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlAssignBusinessServices"
          permissionName="screenControlBusinessServices"
          selectedField="selected"
          onSelect={this.handleSelectionChange}
          fieldForSelection="MainProfileId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="OwnerId"
            title="grids.profilesMainProfileList.column-ownerId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="OwnerName" title="grids.profilesMainProfileList.column-ownerName" width="250" />

          <GridColumn field="MainProfileId" title="grids.profilesMainProfileList.column-profileId" width="200" />

          <GridColumn field="MainProfileName" title="grids.profilesMainProfileList.column-profileName" width="250" />

          <GridColumn
            field="MainProfileHoldName"
            title="grids.profilesMainProfileList.column-businessServicesTemplate"
            width="250"
          />
        </Grid>
        {visible && (
          <ModalAssignBusinessService
            visible={visible}
            toggleModal={this.toggleModal}
            closeModal={this.closeModal}
            update={this.makeQuery}
            businessServices={businessServices}
            MainProfileIds={selectedItems.map(({ MainProfileId }) => MainProfileId)}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridScreenControlAssignBusinessServices);
