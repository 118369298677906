import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import FormStateContainer from '../../../../components/FormStateContainer/FormStateContainer';
import { withTranslate } from '../../../../contexts/localContext';
import TemplateServicesGridServices from '../../../TemplateServices/TemplateServicesGrid/templateServicesGrid.services';
import { ApiService } from '../../../../services';
import AddServiceService from '../AddService/AddService.service';
import AddNewServiceType from './AddNewServiceType';
import { ADD_SERVICE_TYPES_INITIAL_VALUES } from './constants';
import AddNewServiceGroup from './AddNewServiceGroup';
import LoadingPanel from '../../../../components/loader';
import AddNewProvider from './AddNewProvider';

import './UpdateServiceTypeModal.styled.scss';

const api = new ApiService();

class UpdateServiceTypeModal extends PureComponent {
  state = {
    dialogVariant: 'addServiceType',
    addActionSlug: '',
    cancelActionSlug: '',
    serviceGroups: [],
    providers: [],
    isLoading: false,
    disableAddAction: false,
  };

  componentDidMount() {
    this.initialFetch();
  }

  initialFetch = async () => {
    await this.setState({ isLoading: true });
    await this.fetchGroupServices();
    await this.fetchProviders();
    await this.setState({ isLoading: false });
  };

  fetchGroupServices = async () => {
    try {
      const servicesGroups = await api.getServicesGroups();

      this.setState({
        serviceGroups: TemplateServicesGridServices.normalizeServicesGroup(servicesGroups),
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchProviders = async () => {
    try {
      const providers = await api.getProviders();

      this.setState({
        providers: AddServiceService.normalizeSelectData(providers, 'ProviderId', 'Name'),
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getTitle = () => {
    const { dialogVariant } = this.state;

    if (dialogVariant === 'newGroup') {
      return 'page.addServiceByBigTable.newGroup';
    }

    if (dialogVariant === 'newProvider') {
      return 'page.addServiceByBigTable.newProvider';
    }

    return 'page.addServiceByBigTable.newServiceType';
  };

  renderContent = () => {
    const { serviceGroups, providers, dialogVariant, addActionSlug, cancelActionSlug } = this.state;
    const { closeModal, serviceTypeList, fetchServiceTypes } = this.props;

    if (dialogVariant === 'newGroup') {
      return (
        <AddNewServiceGroup
          changeAddAction={(isDisabled) => {
            this.setState({ disableAddAction: isDisabled });
          }}
          handleCancel={() => {
            this.setState({ dialogVariant: 'addServiceType' });
          }}
          addActionSlug={addActionSlug}
          cancelActionSlug={cancelActionSlug}
          serviceGroups={serviceGroups.map(({ Name }) => Name)}
          fetchGroupServices={async () => {
            await this.setState({ isLoading: true });
            await this.fetchGroupServices();
            await this.setState({ isLoading: false });
          }}
        />
      );
    }

    if (dialogVariant === 'newProvider') {
      return (
        <AddNewProvider
          changeAddAction={(isDisabled) => {
            this.setState({ disableAddAction: isDisabled });
          }}
          handleCancel={() => {
            this.setState({ dialogVariant: 'addServiceType' });
          }}
          addActionSlug={addActionSlug}
          cancelActionSlug={cancelActionSlug}
          providers={providers.map(({ Name }) => Name)}
          fetchProviders={async () => {
            await this.setState({ isLoading: true });
            await this.fetchProviders();
            await this.setState({ isLoading: false });
          }}
        />
      );
    }

    return (
      <FormStateContainer initialValues={ADD_SERVICE_TYPES_INITIAL_VALUES}>
        <AddNewServiceType
          serviceGroups={serviceGroups}
          changeAddAction={(isDisabled) => {
            this.setState({ disableAddAction: isDisabled });
          }}
          providers={providers}
          dialogVariantChange={(variant) => {
            this.setState({ dialogVariant: variant });
          }}
          handleCancel={closeModal}
          addActionSlug={addActionSlug}
          cancelActionSlug={cancelActionSlug}
          serviceTypeList={serviceTypeList}
          fetchServiceTypes={fetchServiceTypes}
        />
      </FormStateContainer>
    );
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    const { disableAddAction, isLoading } = this.state;

    return (
      <Modal
        className="AddServiceTypeByBigTableModal"
        visible={visible}
        title={translate(this.getTitle())}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={() => {
              this.setState({ addActionSlug: crypto.randomUUID() });
            }}
            disabled={disableAddAction}
          >
            {translate('core.buttonTitles.ok')}
          </Button>,
          <Button
            key="back"
            onClick={() => {
              this.setState({ cancelActionSlug: crypto.randomUUID() });
            }}
          >
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderContent()}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateServiceTypeModal);
