import React, { PureComponent } from 'react';
import { Button, Input, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

import './EmailReceiptsModal.styled.scss';

const { TextArea } = Input;
const api = new ApiService();

class EmailReceiptsModal extends PureComponent {
  state = {
    isLoading: false,
    error: false,
    emails: '',
  };

  sendReceipt = async () => {
    const { closeModal, translate, transactionId } = this.props;

    const { emails } = this.state;

    if (emails.split(',').some((email) => !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email))) {
      this.setState({ error: true });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      await api.sendReceiptsToEmail({
        transactionId,
        receiptType: 'Simple',
        emails,
      });
      await this.setState({ isLoading: false });
      message.success(translate('page.transactions.successSendReceiptEmail'), 2);
      closeModal();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { translate, visible, closeModal, transactionId } = this.props;
    const { isLoading, emails, error } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate('page.transactions.sendingEmailReceipt')}
        onCancel={closeModal}
        footer={[
          <Button onClick={this.sendReceipt} type="primary" disabled={!emails || !!error}>
            {translate('page.transactions.send')}
          </Button>,
          <Button onClick={closeModal}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div>{translate('grids.transactions.column-transactionId')}</div>
        <Input value={transactionId} disabled className="EmailReceiptsModal-transactionInput" />
        <div>{translate('page.transactions.enterTheReceiptEmailAddresses')}</div>
        <TextArea
          onChange={({ target: { value: emails } }) => this.setState({ emails, error: false })}
          value={emails}
          onPressEnter={(e) => {
            e.preventDefault();
          }}
        />
        {error && <div className="EmailReceiptsModal-error">{translate('page.transactions.invalidValueFormat')}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(EmailReceiptsModal);
