import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService, tabService, StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import ModalDeleteProfile from './modalDeleteProfile/index';
import ModalCreateNewProfile from './modalCreateNewProfile/index';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
const api = new ApiService();

class GridScreenControlProfiles extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlProfiles');
  }

  state = {
    isLoading: false,
    data: [],
    owners: [],
    visibleDeleteProfile: false,
    visibleCreateNewProfile: false,
    isAdminRule: false,
    selectedItems: [],
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.checkAdminRule();
    this.fetchOwners();
  }

  checkAdminRule = () => {
    //if OwnerId of first element = 1 then toggle isAdminRule to true
    if (StorageService.getItem('userInfo').OwnerId === 1) this.setState({ isAdminRule: true });
    else return;
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-screenControlTerminals');
  };

  closeModalDeleteProfile = () => {
    this.setState({ visibleDeleteProfile: false });
  };

  closeModalCreateNewProfile = () => {
    this.setState({ visibleCreateNewProfile: false });
  };

  toggleModalDeleteProfile = () => {
    this.setState(({ visibleDeleteProfile }) => {
      return {
        visibleDeleteProfile: !visibleDeleteProfile,
      };
    });
  };

  toggleModalCreateNewProfile = () => {
    this.setState(({ visibleCreateNewProfile }) => {
      return {
        visibleCreateNewProfile: !visibleCreateNewProfile,
      };
    });
  };

  makeQuery = async () => {
    const { owners } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const data = await api.mainProfileListForModalChangeProfile(owners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={owners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('screenControlProfiles-btn-delete-profile', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.toggleModalDeleteProfile}>
            {translate('grids.profilesMainProfileList.modal-delete-profile-title')}
          </Button>
        )}
        {checkElement('screenControlProfiles-btn-create-profile', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.toggleModalCreateNewProfile}>
            {translate('grids.profilesMainProfileList.modal-create-profile-title')}
          </Button>
        )}
      </Col>
    );
  };

  editProfile = (props) => {
    tabService.addTab({
      type: 'editProfile',
      dataItem: { ...props.dataItem },
    });
  };

  onSelect = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { isLoading, data, visibleDeleteProfile, owners, visibleCreateNewProfile, isAdminRule, selectedItems } =
      this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlProfiles"
          permissionName="screenControlProfiles"
          selectedField="selected"
          multiSelected={true}
          fieldForSelection="MainProfileId"
          onSelect={this.onSelect}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="OwnerId"
            title="grids.profilesMainProfileList.column-ownerId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="OwnerName" title="grids.profilesMainProfileList.column-ownerName" width="270" />

          <GridColumn
            field="MainProfileId"
            title="grids.profilesMainProfileList.column-profileId"
            width="200"
            filter="numeric"
          />

          <GridColumn
            field="MainProfileName"
            title="grids.profilesMainProfileList.column-profileName"
            width="250"
            // В таких обработчиках можно ставить условия на работу линки согласно конфигу бэкэнда!
            onClick={
              checkElement('screenControlProfiles-link-edit-profile', this.currentArray)
                ? (props) => this.editProfile(props)
                : null
            }
            // onClick={(props) => this.editProfile(props)}
          />

          <GridColumn
            field="ItemsCount"
            title="grids.profilesMainProfileList.column-buttonsQuantity"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="MainProfileVersion"
            title="grids.profilesMainProfileList.column-profileVersion"
            width="200"
          />

          <GridColumn
            field="MainProfileHoldId"
            title="grids.businessServices.column-mainProfileHoldId"
            width="250"
            filter="numeric"
          />

          <GridColumn
            field="MainProfileHoldName"
            title="grids.profilesMainProfileList.column-businessServicesTemplate"
            width="250"
          />

          <GridColumn
            field="MainProfileReserveId"
            title="grids.profilesMainProfileList.column-mainProfileReserveId"
            width="250"
            filter="numeric"
          />

          <GridColumn
            field="MainProfileReserveName"
            title="grids.profilesMainProfileList.column-mainProfileReserveName"
            width="250"
          />

          <GridColumn
            field="TerminalCount"
            title="grids.profilesMainProfileList.column-terminalsQuantity"
            width="250"
          />

          <GridColumn field="UserIdName" title="grids.profilesMainProfileList.column-executor" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.profilesMainProfileList.column-dateOfChange"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        </Grid>
        {visibleDeleteProfile && (
          <ModalDeleteProfile
            item={selectedItems && selectedItems.length ? selectedItems.map(({ MainProfileId }) => MainProfileId) : []}
            fullData={data}
            name="screenControlProfiles"
            owners={owners}
            visible={visibleDeleteProfile}
            closeModal={this.closeModalDeleteProfile}
            toggleModal={this.toggleModalDeleteProfile}
            update={this.makeQuery}
          />
        )}
        {visibleCreateNewProfile && (
          <ModalCreateNewProfile
            name="screenControlProfiles"
            owners={owners}
            visible={visibleCreateNewProfile}
            isAdminRule={isAdminRule}
            closeModal={this.closeModalCreateNewProfile}
            toggleModal={this.toggleModalCreateNewProfile}
            update={this.makeQuery}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridScreenControlProfiles);
