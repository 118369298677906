import React, { PureComponent } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button, message, Radio } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './SuspendedFinancialTransactionsGrid.scss';
import SuspendedFinancialTransactionsGridService from './SuspendedFinancialTransactionsGrid.service';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { PAYMENT_STATUSES, PAYMENT_STATUSES_DROPDOWN_CONFIG } from './constants';
import CustomInput from '../../../components/Input/Input';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { ApiService, StorageService, tabService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import AddPaymentService from '../AddPayment/AddPayment.service';
import LoadingPanel from '../../../components/loader';
import { CONFIGS } from '../AddPayment/constants';
import { translateSlugs, translateSlugsSelect } from './constants';

const api = new ApiService();

class SuspendedFinancialTransactionsGridToolbar extends PureComponent {
  state = {
    aNameList: [],
    aAccountIdList: [],
    isLoading: false,
  };

  currentArray = findArray('externalOutgoingPayments');

  dateChangeCallback = (fieldName) => (e) => {
    const {
      formState: { dateFrom, dateTo },
      onError,
      onChangeFormState,
      translate,
    } = this.props;

    onChangeFormState(fieldName, e.target.value);
    onError(
      'dateError',
      SuspendedFinancialTransactionsGridService.getSubmitErrorsByDate(
        fieldName === 'dateFrom' ? e.target.value : dateFrom,
        fieldName === 'dateTo' ? e.target.value : dateTo,
        translate,
      ),
    );
  };

  componentDidMount() {
    this.initialData();
  }

  initialData = async () => {
    await this.fetchCustomersTypes();
    const aAccountIdStorageData = await this.getDefaultElem('aAccountId');
    const aNameStorageData = await this.getDefaultElem('aName');
    if (aNameStorageData) {
      await this.fetchCustomers(aNameStorageData[0], aAccountIdStorageData);
    }
  };

  componentDidUpdate(prevProps) {
    const { reloadAttemptSlug, formErrors } = this.props;
    const { reloadAttemptSlug: prevReloadAttemptSlug } = prevProps;

    if (reloadAttemptSlug !== prevReloadAttemptSlug && !Object.values(formErrors).some((value) => !!value)) {
      this.handleSubmit();
    }
  }

  renderInput = (name, type, isOnlyInteger) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <CustomInput
        type={type}
        formState={formState}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        validators={SuspendedFinancialTransactionsGridService.getValidators(translate)}
        name={name}
        isOnlyInteger={isOnlyInteger}
        isErrorMessageAbsolutePositioned={false}
      />
    );
  };

  handleSubmit = async () => {
    const {
      formState: { dateFrom, dateTo, status, EDRPOU, isRecipient, aAccountId, IBAN },
      setOperations,
      setLoading,
    } = this.props;

    try {
      await setLoading(true);
      const operations = await api.getPaymentsExternalList({
        account: aAccountId[0] || null,
        dateStart: getDataByTimezoneOffset(
          new Date(
            new Date(dateFrom).getFullYear(),
            new Date(dateFrom).getMonth(),
            new Date(dateFrom).getDate(),
            0,
            0,
            0,
          ),
        ).toISOString(),
        dateEnd: getDataByTimezoneOffset(
          new Date(new Date(dateTo).getFullYear(), new Date(dateTo).getMonth(), new Date(dateTo).getDate(), 23, 59, 0),
        ).toISOString(),
        inOut: isRecipient,
        iban: IBAN || null,
        edrpo: EDRPOU || null,
        statusId: status || null,
        accountId: aAccountId[0],
      });

      setOperations(operations);
      await setLoading(false);
    } catch (e) {
      const { showError } = this.props;
      await setLoading(false);
      showError(e);
    }
  };

  paymentsExternalCopy = async () => {
    const { selectedItems, setLoading, showError, translate } = this.props;
    try {
      setLoading(true);

      const data = await api.postPaymentsExternalCopy({
        paymentId: selectedItems[0].PaymentId,
        paymentIdCopy: 0,
      });
      await message.success(`${translate('page.externalOutgoingPayments.message-copyPayment')} ${data}`, 2.5);
      this.handleSubmit();
    } catch (error) {
      setLoading(false);
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  paymentsExternalDelete = async () => {
    const { selectedItems, setLoading, showError, translate } = this.props;
    try {
      setLoading(true);

      const data = await api.postPaymentsExternalDelete({
        paymentId: selectedItems[0].PaymentId,
      });
      await message.success(`${translate('page.externalOutgoingPayments.message-deletePayment')}`, 2.5);

      this.handleSubmit();
    } catch (error) {
      setLoading(false);
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  onChangeRadio = (e) => {
    const { onChangeFormState } = this.props;

    onChangeFormState('isRecipient', e.target.value);
  };

  fetchCustomersTypes = async () => {
    await this.setState({ isLoading: true });

    try {
      const customersTypes = await api.getPaymentCustomers();

      this.setState({ aNameList: AddPaymentService.normalizeSelectData(customersTypes, 'CustomerId', 'CustomerName') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchCustomers = async (customerID, storageData) => {
    try {
      this.setState({ isLoading: true });

      const customers = await api.getPaymentAccounts(customerID);
      await this.setState({
        aAccountIdList: AddPaymentService.normalizeSelectDataAccountId(
          customers,
          'AccountId',
          'AccounName',
          'BankAccount',
          'BankMfo',
        ),
      });
      if (storageData) {
        const { onChangeFormState } = this.props;
        onChangeFormState('aAccountId', storageData);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getDefaultElem = (fieldName) => {
    const userInfo = StorageService.getItem('userInfo');

    if (fieldName === 'aAccountId') {
      return SuspendedFinancialTransactionsGridService.getDefaultSelectedOwners(
        `externalOutgoingPayments--aAccountId--${userInfo.UserId}`,
      ).map((id) => +id);
    }
    return SuspendedFinancialTransactionsGridService.getDefaultSelectedOwners(
      `/pages/externalOutgoingPayments--${userInfo.UserId}`,
    ).map((id) => +id);
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    const { onChangeFormState } = this.props;

    if (fieldName === 'aName' && selectItems.length !== 0) {
      this.fetchCustomers(selectItems[0]);
    }
    if (fieldName === 'aAccountId' && selectItems.length !== 0) {
      const userInfo = StorageService.getItem('userInfo');

      StorageService.setItem(
        `externalOutgoingPayments--aAccountId--${userInfo.UserId}`,
        selectItems && selectItems.length ? selectItems : [],
      );
    }

    onChangeFormState(fieldName, selectItems);
  };

  renderSelect = ({ name, isDisabled, isStorageItems }) => {
    const { formState, translate } = this.props;

    return (
      <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
        <div className="SuspendedFinancialTransactionsGridToolbar-statusLabel">{translate(translateSlugs[name])}</div>
        <div className="PaymentSuspendedFinancialTransactionsGridToolbar-status">
          <GridDropdown
            data={this.state[`${name}List`]}
            colConfig={CONFIGS[name]}
            selectItems={formState[name]}
            onSave={this.getSelectChangeCallback(name)}
            defaultTitle={translate(translateSlugsSelect[name])}
            disabled={isDisabled}
            isSingle
            isGetDefaultSelectItemsFromStorage={isStorageItems}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      formState: { dateFrom, dateTo, status, isRecipient, aName },
      formErrors: { dateError },
      translate,
      onChangeFormState,
      formErrors,
      selectedItems,
    } = this.props;
    const { isLoading } = this.state;
    const disableAccountId = aName.length === 0;
    return (
      <div className="PaymentSuspendedFinancialTransactionsGridToolbar-toolbar">
        <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFields">
          {this.renderSelect({ name: 'aName', isStorageItems: true })}
          {this.renderSelect({ name: 'aAccountId', isDisabled: disableAccountId })}
          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarDateFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
              <div className="SuspendedFinancialTransactionsGridToolbar-label">
                {translate('page.changingProfileHistory.dateFrom')}
              </div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="SuspendedFinancialTransactionsGridToolbar-datePicker"
                  onChange={this.dateChangeCallback('dateFrom')}
                  value={dateFrom}
                  min={new Date(2007, 0, 1)}
                />
              </div>
            </div>

            <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
              <div className="SuspendedFinancialTransactionsGridToolbar-label">
                {translate('page.changingProfileHistory.dateTo')}
              </div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="SuspendedFinancialTransactionsGridToolbar-datePicker"
                  onChange={this.dateChangeCallback('dateTo')}
                  value={dateTo}
                  min={new Date(2007, 0, 1)}
                />
              </div>
            </div>

            {dateError && <div className="SuspendedFinancialTransactionsGridToolbar-datePickerError">{dateError}</div>}
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-statusLabel">
              {translate('page.suspendedFinancialTransactions.paymentStatus')}
            </div>

            <div className="PaymentSuspendedFinancialTransactionsGridToolbar-status">
              <GridDropdown
                data={PAYMENT_STATUSES.map((item, index) => ({ Id: index, Name: translate(item) }))}
                colConfig={PAYMENT_STATUSES_DROPDOWN_CONFIG}
                selectItems={[status]}
                onSave={(statuses) => {
                  onChangeFormState('status', statuses[0]);
                }}
                isSingle
              />
            </div>
          </div>

          <div className="PaymentSuspendedFinancialTransactionsGridToolbar-searchAction">
            <Button
              type="primary"
              onClick={this.handleSubmit}
              disabled={Object.values(formErrors).some((value) => !!value)}
            >
              {translate('core.buttonTitles.search')}
            </Button>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarWideFieldsItem">
            <Radio.Group
              value={isRecipient}
              onChange={this.onChangeRadio}
              className="SuspendedFinancialTransactionsGridToolbar-RadioGroup"
            >
              <Radio key="radio-recipient" value={true}>
                {translate('page.suspendedFinancialTransactions.recipient')}
              </Radio>

              <Radio key="radio-sender" value={false}>
                {translate('page.suspendedFinancialTransactions.sender')}
              </Radio>
            </Radio.Group>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-alignTopLabel">
              {translate('page.suspendedFinancialTransactions.EDRPOUCode')}
            </div>
            <div className="SuspendedFinancialTransactionsGridToolbar-input">
              {this.renderInput('EDRPOU', 'number', true)}
            </div>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-alignTopLabel">
              {translate('page.suspendedFinancialTransactions.IBan')}
            </div>
            <div className="SuspendedFinancialTransactionsGridToolbar-wideInput">
              {this.renderInput('IBAN', 'default')}
            </div>
          </div>
        </div>

        <div className="SuspendedFinancialTransactionsGridToolbar-actions">
          {checkElement('externalOutgoingPayments-createPayment', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab({
                  type: 'externalOutgoingPayments',
                });
              }}
            >
              {translate('page.externalOutgoingPayments.createPayment')}
            </Button>
          )}

          {checkElement('externalOutgoingPayments-copy', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length === 0}
              onClick={() => {
                this.paymentsExternalCopy();
              }}
            >
              {translate('core.buttonTitles.copy')}
            </Button>
          )}

          {checkElement('externalOutgoingPayments-delete', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length === 0}
              onClick={() => {
                this.paymentsExternalDelete();
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>
        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(SuspendedFinancialTransactionsGridToolbar);
