class CashDebtByCollectorsService {
  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = new Date(2007, 0, 1);

    if (minimumSelectedDate - startDate > 0 || minimumSelectedDate - endDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    const SIX_MONTH_AGO_BY_END_DATE = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth() - 12,
      new Date(endDate).getDate() - 1,
      23,
      59,
      59,
    );

    if (SIX_MONTH_AGO_BY_END_DATE - startDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  formatDate = (date) => {
    const [day, month, year] = date.split('.');

    return new Date(year, month - 1, day);
  };

  normalizeСashDebtData(cashDeptData) {
    if (!cashDeptData || !cashDeptData.length) {
      return [];
    }

    return cashDeptData.map(({ Date, ...rest }) => ({
      Date: Date.split ? this.formatDate(Date) : null,
      ...rest,
    }));
  }
}

export default new CashDebtByCollectorsService();
