import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Col, Row, Input, Button as AntdButton } from 'antd';
import { Button } from '@progress/kendo-react-buttons';

import { ApiService, StorageService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import DateRange from '../../components/daterange';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import AdditionalTransactionsPropsModal from '../../components/AdditionalTransactions/AdditionalTransactionsPropsModal';

import './gridMonitorActivity.scss';
import {
  DownloadReceiptPDFService,
  EmailReceiptsModal,
  ViberReceiptModal,
} from '../../components/TransactionReceiptActions';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

const aggregate = [
  { field: 'TransactionId', aggregate: 'count', format: 'n0' },
  { field: 'TerminalId', aggregate: 'count', format: 'n0' },
  { field: 'AmountTotal', aggregate: 'sum', format: 'n2' },
  { field: 'AmountOriginal', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionClient', aggregate: 'sum', format: 'n2' },
  { field: 'MoneyTakerAmount', aggregate: 'sum', format: 'n2' },
];

class GridTerminalTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('activitymonitor');
  }

  state = {
    isLoading: false,
    data: [],
    range: {
      start: null,
      end: null,
    },
    isNeedAdditionalFilter: StorageService.getItem('monitorActivityTransactionAdditionalFilter') === 'true',
    selectedItems: [],
    dialogVariant: '',
    dialogStuff: null,
  };

  changeDateSeconds = (formatedDate) => new Date(formatedDate);

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    const startDate = new Date(start).setMilliseconds(0);
    const endDate = new Date(end).setMilliseconds(0);
    if (el && el.DatePost) {
      const changedDate = this.changeDateSeconds(el.DatePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  showRemoteCallback = () => {
    this.setState(
      ({ isNeedAdditionalFilter }) => ({ isNeedAdditionalFilter: !isNeedAdditionalFilter }),
      () => {
        StorageService.setItem('monitorActivityTransactionAdditionalFilter', `${this.state.isNeedAdditionalFilter}`);
        this.setState({ data: this.filterFurther(this.state.rawData) });
      },
    );
  };

  filterFurther = (data) => {
    const { isNeedAdditionalFilter } = this.state;

    return isNeedAdditionalFilter ? data.filter((el) => this.filterByDatePost(el)) : data;
  };

  makeQuery = async () => {
    const { range } = this.state;
    const { terminalId } = this.props;
    if (!range || !terminalId) return;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);
    try {
      this.toggleLoader(true);
      const model = {
        TerminalId: terminalId,
        DateStart: start,
        DateEnd: end,
        DisplayComments: true,
        DisplayUser: true,
        Hist: true,
      };
      const data = await api.terminalTransactionlist(model);
      this.setState({ data: this.filterFurther(data), rawData: data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  buildToolbar = () => {
    const { translate, terminalId } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 16 }}>
        <div className="issued-certificates--toolbar aligned">
          <p>{translate('grids.certificates-issued.col-terminalId')}</p>
          <Input value={terminalId} disabled />
        </div>

        <Row className="gridTerminalTransactions-search">
          <div className="gridTerminalTransactions-fields">
            <div>
              <DateRange onRange={this.setRange} />
            </div>

            {checkElement('activitymonitor-transactionWithAdditionalProps', this.currentArray) && (
              <div className="gridTerminalTransactions-action">
                <AntdButton
                  type="primary"
                  disabled={!selectedItems.length}
                  onClick={() => {
                    this.setState({ dialogVariant: 'additionalProps' });
                  }}
                >
                  {translate('page.transactions.transactionWithAdditionalProps')}
                </AntdButton>
              </div>
            )}
          </div>

          {checkElement('monitorTerminalTransactions-btn-submit', this.currentArray) && (
            <div className="gridTerminalTransactions-action">
              <Button icon="search" primary onClick={this.makeQuery}>
                {translate('owner.search')}
              </Button>
            </div>
          )}
        </Row>
      </Col>
    );
  };

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '1' : '0.6';
    switch (value) {
      case 'принят':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(92, 184, 92, ${opacity}))`;
      case 'отклонен':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(205, 92, 92, ${opacity}))`;
      case 'удален':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(244, 164, 96, ${opacity}))`;
      case 'заблокирован':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(70, 130, 180, ${opacity}))`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.StatusName;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  getHandlers = () => {
    const { showError } = this.props;

    return [
      {
        title: 'grids.transactions.button-details',
        action: (props) => tabService.addTab({ type: 'details', dataItem: props.dataItem }),
        dropdown: false,
        show: checkElement('monitorTerminalTransactions-transactionIdOnclick', this.currentArray),
      },
      {
        title: 'page.transactions.sendingEmailReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingEmailReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: checkElement('monitorTerminalTransactions-sendingEmailReceipt', this.currentArray),
      },
      {
        title: 'page.transactions.sendingViberReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingViberReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: checkElement('monitorTerminalTransactions-sendingViberReceipt', this.currentArray),
      },
      {
        title: 'page.transactions.downloadReceipt',
        action: ({ dataItem }) => {
          DownloadReceiptPDFService.downloadReceipt(
            async (isLoading) => {
              await this.setState({ isLoading });
            },
            showError,
            dataItem.TransactionId,
          );
        },
        dropdown: false,
        show: checkElement('monitorTerminalTransactions-downloadReceipt', this.currentArray),
      },
    ];
  };

  onAdditionalPropSelected = (selectedAdditionalProps) => {
    const { selectedItems } = this.state;
    tabService.addTab({
      type: 'additionalProps',
      dataItem: {
        selectedAdditionalProps,
        selectedItems,
      },
    });
  };

  render = () => {
    const { isLoading, data, isNeedAdditionalFilter, dialogVariant, selectedItems, dialogStuff } = this.state;

    const isDropdownShow = [
      'monitorTerminalTransactions-transactionIdOnclick',
      'monitorTerminalTransactions-sendingEmailReceipt',
      'monitorTerminalTransactions-sendingViberReceipt',
      'monitorTerminalTransactions-downloadReceipt',
    ].some((slug) => checkElement(slug, this.currentArray));

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          rowRender={this.rowRender}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="monitorTerminalTransactions"
          showRemoteLabel="filterByData"
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={isNeedAdditionalFilter}
          fieldForSelection="TransactionId"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="OwnerName" width="145" title="grids.transactions.column-ownerName" />
          <GridColumn
            field="TransactionId"
            title="grids.transactions.column-transactionId"
            width="130"
            handlers={this.getHandlers()}
            dropdown={isDropdownShow}
            filter="numeric"
          />
          <GridColumn field="RequestId" title="grids.transactions.column-requestId" width="112" filter="numeric" />
          <GridColumn field="TerminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />
          <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />
          <GridColumn field="ServiceName" title="grids.transactions.column-serviceName" width="93" />
          <GridColumn
            field="AmountTotal"
            title="grids.transactions.column-amountTotal"
            width="134"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn
            field="AmountOriginal"
            title="grids.transactions.column-amountOriginal"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn
            field="CommissionProvider"
            title="grids.transactions.column-comissionProvider"
            width="154"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn
            field="CommissionClient"
            title="grids.transactions.column-comissionClient"
            width="171"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn field="Currency" title="grids.transactions.column-currency" width="90" />
          <GridColumn
            field="DateInput"
            title="grids.transactions.column-dateInput"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="DatePost"
            title="grids.transactions.column-datePost"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="DateDeclined"
            title="grids.transactions.column-dateDeclined"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
            // cell={CellNullData}
          />
          <GridColumn
            field="DateAccepted"
            title="grids.transactions.column-dateAccepted"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
            // cell={CellNullData}
          />
          <GridColumn field="Account" title="grids.transactions.column-account" width="112" />
          <GridColumn field="Comment" title="grids.transactions.column-comment" width="125" />
          <GridColumn field="StatusName" title="grids.transactions.column-status" width="91" />
          <GridColumn field="PaymentType" title="grids.transactions.column-typePayment" width="120" />
          {/* <GridColumn
          title="grids.transactions.column-details"
          width="91"
        /> */}
        </Grid>

        {dialogVariant === 'additionalProps' && (
          <AdditionalTransactionsPropsModal
            visible={dialogVariant === 'additionalProps'}
            selectedItems={selectedItems}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            onSave={this.onAdditionalPropSelected}
          />
        )}

        {dialogVariant === 'sendingEmailReceipt' && (
          <EmailReceiptsModal
            visible={dialogVariant === 'sendingEmailReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.TransactionId}
          />
        )}

        {dialogVariant === 'sendingViberReceipt' && (
          <ViberReceiptModal
            visible={dialogVariant === 'sendingViberReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.TransactionId}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridTerminalTransactions);
