import PropTypes from 'prop-types';
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Progress } from 'antd';
import './cellProgress.scss';

/**
 * Нормализует входное значение к диапазону от 0 до 100
 * @param value процент
 */
const limitPercent = (value) => {
  return Math.min(Math.max(value, 0), 100);
};

// const getOwnerStockPercent = (value, dataItem) => {
//   console.log(value);
//   console.log(dataItem);
//   const finalValue = value && value !== 0 ?
//     ((value * 100) / dataItem.TerminalsCount).toFixed(1) : 0;
//   return finalValue;
// }

/**
 * Bill acceptor progress Cell
 */
export default class CellProgress extends GridCell {
  static defaultProps = {
    mobile: false,
    small: false,
    shortFormat: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  render() {
    const { mobile, field, dataItem, gridName, small, className, style, shortFormat } = this.props;
    let value;
    if (gridName === 'terminalCashList') {
      value = +Number(limitPercent(dataItem[field]) * 100).toFixed(1);
    }

    if (shortFormat === true) {
      // if (+dataItem[field] === 100) value= 75.5;
      value = +dataItem[field].toFixed(1);
    } else if (gridName !== 'terminalCashList') {
      value = limitPercent(dataItem[field]);
    }

    if (mobile) {
      return (
        <Progress
          percent={value}
          size="small"
          style={{ width: '150px' }}
          className={`cell-progress ${small && 'small'}`}
        />
      );
    }

    return (
      <td className={className} style={{ ...style, zIndex: 4 }}>
        <Progress percent={value} size="small" className="cell-progress" />
      </td>
    );
  }
}
