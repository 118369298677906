import React, { PureComponent } from 'react';
import { Button, Input, message, Modal, Select } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import { ApiService } from '../../../../services';

import './ServiceEndpoints.scss';

const { Option } = Select;
const api = new ApiService();

class UpdateModal extends PureComponent {
  state = {
    isLoading: false,
    operations: [],
    selectedOperation: null,
    endpoint: '',
    secondEndpoint: '',
    Upn: '',
    error: '',
  };

  componentDidMount() {
    const { selectedItem } = this.props;

    if (selectedItem) {
      this.setState({
        selectedOperation: selectedItem.OperationId,
        endpoint: selectedItem.Endpoint,
        secondEndpoint: selectedItem.Endpoint2,
        Upn: selectedItem.Upn,
      });
    }

    this.fetchOperations();
  }

  onSelectChange = (selectedOperation) => {
    const { endpoints, translate } = this.props;
    this.setState({ selectedOperation }, () => {
      console.log('selectedOperation', selectedOperation, endpoints);
      this.setState({
        error: endpoints.some(({ OperationId }) => OperationId === selectedOperation)
          ? translate('page.serviceEndpoints.operationAlreadyAssigned')
          : '',
      });
    });
  };

  fetchOperations = async () => {
    try {
      await this.setState({ isLoading: true });
      const operations = await api.getTransactionsOperations();
      await this.setState({ operations, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  updateOperation = async () => {
    const { serviceId, onRefresh, translate, variant, closeModal } = this.props;
    const { selectedOperation, endpoint, secondEndpoint, Upn } = this.state;

    try {
      this.setState({ isLoading: true });
      const updateMethod = variant === 'addOperation' ? api.addEndpointServices : api.updateEndpointServices;
      await updateMethod({ serviceId, operationId: selectedOperation, endpoint, endpoint2: secondEndpoint, upn: Upn });
      this.setState({ isLoading: false });
      closeModal();
      message.success(
        translate(`page.serviceEndpoints.${variant === 'addOperation' ? 'successfullyAdded' : 'successfullyChanged'}`),
        2,
      );
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { translate, closeModal, variant, selectedItem } = this.props;
    const { isLoading, operations, selectedOperation, endpoint, secondEndpoint, Upn, error } = this.state;

    return (
      <Modal
        visible={variant === 'addOperation' || variant === 'changeOperation'}
        onCancel={closeModal}
        className="UpdateOperationModal"
        title={translate(`page.serviceEndpoints.${variant === 'addOperation' ? 'addingEndpoint' : 'changingEndpoint'}`)}
        footer={[
          <Button
            type="primary"
            key="submit"
            loading={isLoading}
            disabled={!endpoint || !selectedOperation || error}
            onClick={this.updateOperation}
          >
            {translate(`core.buttonTitles.${variant === 'addOperation' ? 'add' : 'change'}`)}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="UpdatePropsModal-row">
          <div className="UpdateOperationModal-label">{translate('page.serviceEndpoints.col-operation')}</div>
          <Select
            className="UpdatePropsModal--select"
            placeholder={selectedItem ? selectedItem.OperationName : translate('page.serviceEndpoints.selectOperation')}
            onChange={this.onSelectChange}
            filterOption={true}
            optionFilterProp="children"
            disabled={variant === 'changeOperation'}
            showSearch
          >
            {operations.map(({ OperationId, Name }) => (
              <Option value={OperationId} key={`${OperationId}-${Name}`}>
                {Name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="UpdatePropsModal-row">
          <div className="UpdateOperationModal-label">{translate('page.serviceEndpoints.col-endpoint')}</div>
          <Input
            value={endpoint}
            onChange={({ target: { value } }) => {
              this.setState({ endpoint: value });
            }}
          />
        </div>

        <div className="UpdateOperationModal-row">
          <div className="UpdateOperationModal-label">{translate('page.serviceEndpoints.col-endpoint2')}</div>
          <Input
            value={secondEndpoint}
            onChange={({ target: { value } }) => {
              this.setState({ secondEndpoint: value });
            }}
          />
        </div>

        <div className="UpdateOperationModal-row">
          <div className="UpdateOperationModal-label">{translate('page.serviceEndpoints.col-UPN')}</div>
          <Input
            value={Upn}
            onChange={({ target: { value } }) => {
              this.setState({ Upn: value });
            }}
          />
        </div>

        {error && <div className="UpdateOperationModal-error">{error}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
