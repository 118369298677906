import React, { PureComponent, lazy, Suspense } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import PageTabs from '../../../components/tabber/tabber';
import Services from './Services';
import AdditionalLimits from './AdditionalLimits/AdditionalLimits';
import ServiceMainProps from './ServiceMainProps';
import BankDetails from './BankDetails/BankDetails';
import ServicesAdditionalProps from './ServicesAdditionalProps';
import ServiceEndpoints from './ServiceEndpoints';
import AddServiceContainer from './AddService';
import SupplierCommission from './SupplierCommission';
import FormStateContainer from '../../../components/FormStateContainer';
import { INITIAL_VALUES_SUPPLIER_COMMISSIONS_SCALE } from './SupplierCommissionForm/constants';
import CommissionsTechnicalPartner from './CommissionsTechnicalPartner/CommissionsTechnicalPartner';
import StepServiceCreation from './StepServiceCreation';

const SupplierCommissionsScale = lazy(() => import('./SupplierCommissionsScale/SupplierCommissionsScale'));
class ServicesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-services-big-table',
          content: <Services />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { translate } = this.props;
    const ServiceId = dataItem && dataItem.selectItem ? dataItem.selectItem.ServiceId : '';

    switch (type) {
      case 'basicProperties':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.tab-basic-properties')} ${ServiceId}`,
              content: (
                <ServiceMainProps
                  dataItem={dataItem}
                  onSave={() => {
                    window.location.reload();
                  }}
                />
              ),
              closable: true,
              key: key,
            },
          ],
        }));
        break;

      case 'bankDetails':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.tab-bank-details')} ${ServiceId}`,
              content: (
                <BankDetails
                  dataItem={dataItem}
                  onSave={() => {
                    window.location.reload();
                  }}
                />
              ),
              closable: true,
              key: key,
            },
          ],
        }));
        break;

      case 'serviceEndpoints':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.serviceEndpoints.title')} ${ServiceId}`,
              content: <ServiceEndpoints dataItem={dataItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;

      case 'additionalProperties':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.tab-additional-properties')} ${ServiceId}`,
              content: <ServicesAdditionalProps dataItem={dataItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;

      case 'commissions':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.tab-commissions')} ${ServiceId}`,
              content: '',
              closable: true,
              key: key,
            },
          ],
        }));
        break;

      case 'additionalLimits':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.tab-additional-limits')} ${ServiceId}`,
              content: <AdditionalLimits dataItem={dataItem.selectItem} />,
              closable: true,
              key: key,
            },
          ],
        }));

        break;

      case 'createService':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: 'page.servicesBigTable.tab-addService',
              content: <AddServiceContainer />,
              closable: true,
              key: key,
            },
          ],
        }));

        break;
      case 'supplierCommissions':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.supplierCommissions.tab-supplierCommissions')} ${ServiceId}`,
              content: <SupplierCommission dataItem={dataItem.selectItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;
      case 'supplierCommissionsScale':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: 'page.supplierCommissions.tab-supplierCommissions-scale',
              content: (
                <FormStateContainer initialValues={INITIAL_VALUES_SUPPLIER_COMMISSIONS_SCALE}>
                  <SupplierCommissionsScale dataItem={dataItem.selectItem} />
                </FormStateContainer>
              ),
              closable: true,
              key: key,
            },
          ],
        }));
        break;
      case 'commissionsTechPartner':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: 'page.supplierCommissions.tab-commissions-tech-partner',
              content: <CommissionsTechnicalPartner dataItem={dataItem.selectItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;
      case 'stepServiceCreation':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.servicesBigTable.stepServiceCreation')} ${dataItem ? dataItem.serviceId : ''}`,
              content: <StepServiceCreation selectedService={dataItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;

    return (
      <Suspense fallback={''}>
        <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
      </Suspense>
    );
  };
}
export default withTranslate(ServicesPage);
