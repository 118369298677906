export const ALL_GRID_COLUMNS = [
  'action',
  'errorMessage',
  'prroFiscalNumberNew',
  'prroId',
  'requestTime',
  'status',
  'uuid',
  'ownerName',
];
