import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridMonitorActivity from './gridMonitorActivity';
import GridTerminalEvents from './gridTerminalEvents';
import GridTransactionDetail from './gridMonitorTransactionDetail';
import GridTerminalTransactions from './gridTerminalTransactions';
import GridIssuedCertificates from './gridIssuedCertificates';
import PageTabs from '../../components/tabber/tabber';
import GridTerminalTransactionWithAdditionalProps from './gridTerminalTransactionWithAdditionalProps';

class PageMonitorActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-terminals',
          content: <GridMonitorActivity />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'transaction':
        const { TerminalId, OwnerId } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-terminal-transactions',
          content: <GridTerminalTransactions ownerId={OwnerId} terminalId={TerminalId} />,
          closable: true,
          key: key,
        });
        break;
      case 'details':
        const { TransactionId } = dataItem;
        tabs.push({
          title: `${translate('page.dashboard.tab-transaction-details')} #${TransactionId}`,
          content: <GridTransactionDetail transactionId={TransactionId} />,
          closable: true,
          key: key,
        });
        break;
      case 'events':
        const { TerminalId: terminalId } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-events',
          content: <GridTerminalEvents terminalId={terminalId} />,
          closable: true,
          key: key,
        });
        break;
      case 'issued':
        const { TerminalId: termId, AppVersion } = dataItem;
        tabs.push({
          title: 'page.certificates.tab-сertificates-issued',
          content: <GridIssuedCertificates currentPage="activitymonitor" version={AppVersion} terminalId={termId} />,
          closable: true,
          key: key,
        });
        break;

      case 'additionalProps':
        tabs.push({
          title: 'page.transactions.transactionWithAdditionalProps',
          content: <GridTerminalTransactionWithAdditionalProps dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageMonitorActivity);
