import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Checkbox } from 'antd';

import CellDropdown from '../grids/cells/cellDropdown';
import { withTranslate } from '../../contexts/localContext';
import { widthUpdateMenuElementsContext } from './UpdateMenuElementsContext';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';

import './ProfileMenuElements.scss';
import { tabService } from '../../services';

const _ = require('lodash');

const getCustomCellCheckbox =
  (updateVisibleProps, isDisable) =>
  ({ dataItem: { isVisible, menuId, profileId, name } }) => {
    return (
      <td>
        <Checkbox
          disabled={isDisable}
          checked={isVisible}
          onChange={() => {
            updateVisibleProps(!isVisible, menuId, profileId, name);
          }}
        />
      </td>
    );
  };

const getSelectCellCheckbox =
  (updateSelected) =>
  ({ dataItem }) => {
    return (
      <td>
        <Checkbox
          checked={dataItem.selected}
          onChange={() => {
            updateSelected(dataItem);
          }}
        />
      </td>
    );
  };

const DetailComponent = (dataItem = {}, handleChangeCurrentLayer) =>
  !!dataItem.details && !!dataItem.details.length
    ? withTranslate(widthUpdateMenuElementsContext(ProfileMenuElementsGrid))({
        data: dataItem.details,
        handleChangeCurrentLayer,
      })
    : null;

class ProfileMenuElementsGrid extends Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.data, this.props.data);
  }

  handleUpdateByFetchedData = ({ profileId, parentId }, fetchedData) => {
    const { data, handleChangeCurrentLayer } = this.props;

    handleChangeCurrentLayer([
      ...data.map((item) =>
        item.menuId === parentId && item.profileId === profileId
          ? {
              ...item,
              expanded: true,
              details: fetchedData.map((element) => ({
                ...element,
                profileHistorySlug: item.profileHistorySlug,
                parentHistorySlug: `${item.parentHistorySlug},${element.menuId}`,
              })),
              loaded: true,
            }
          : item,
      ),
    ]);
  };

  fetchChildMenuElements = async (profileId, parentId) => {
    const { fetchChildMenuElements } = this.props;

    fetchChildMenuElements(profileId, parentId, this.handleUpdateByFetchedData);
  };

  expandChange = async ({ dataItem }) => {
    const { data, handleChangeCurrentLayer } = this.props;

    console.log('dataItem', dataItem);

    if (dataItem.loaded) {
      handleChangeCurrentLayer([
        ...data.map((item) => (item.menuId === dataItem.menuId ? { ...item, expanded: !dataItem.expanded } : item)),
      ]);

      return;
    }

    await this.fetchChildMenuElements(dataItem.profileId, dataItem.menuId);
  };

  rowRender = (element, { dataItem: { hasChild } }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      {
        ...elementStyle,
        className: hasChild ? 'MenuProfilesGrid-rowWithChild' : 'MenuProfilesGrid-rowWithOutChild',
      },
      children,
    );
  };

  getHandleInsertSuccessfulCallback =
    (isInsert, menuId, profileHistorySlug, parentHistorySlug, profileId) => (newMenuItem) => {
      const { handleChangeCurrentLayer: routeHandleChangeCurrentLayer, data } = this.props;

      const normalizedMenuItem = newMenuItem
        ? Object.entries(newMenuItem).reduce(
            (acc, [key, value]) => ({ ...acc, [lowerCaseFirstLetter(key)]: value }),
            {},
          )
        : {};

      const resultData =
        isInsert && !normalizedMenuItem.refId && normalizedMenuItem.refId !== 0
          ? [
              ...data.map((item) =>
                item.menuId === menuId
                  ? {
                      ...item,
                      details: [
                        ...(item.details || []),
                        {
                          ...normalizedMenuItem,
                          profileId,
                          profileHistorySlug,
                          parentHistorySlug: `${parentHistorySlug},${menuId},${normalizedMenuItem.menuId}`,
                        },
                      ],
                      expanded: true,
                    }
                  : item,
              ),
            ]
          : [
              ...data,
              {
                ...normalizedMenuItem,
                profileId,
                profileHistorySlug,
                parentHistorySlug: `${parentHistorySlug},${normalizedMenuItem.menuId}`,
              },
            ];

      routeHandleChangeCurrentLayer(resultData);
    };

  getHandleInsert =
    (isInsert) =>
    ({ dataItem: { menuId, profileId, name: currentMenuName, parentId, profileHistorySlug, parentHistorySlug } }) => {
      const { handleSetProfileStaffForUpdating } = this.props;

      handleSetProfileStaffForUpdating({
        variant: isInsert ? 'insertElement' : 'cloneElement',
        menuId,
        profileId,
        currentMenuName,
        parentId,
        onSuccessfulUpdateCallback: this.getHandleInsertSuccessfulCallback(
          isInsert,
          menuId,
          profileHistorySlug,
          parentHistorySlug,
          profileId,
        ),
      });
    };

  getHandlers = ({ refId }) => {
    const {
      handleChangeCurrentLayer: routeHandleChangeCurrentLayer,
      handleSetProfileStaffForUpdating,
      data,
      handleSetActionWithCheck,
      permissions: {
        updateElementName,
        createElement,
        cloneElement,
        deleteElement,
        moveElement,
        redirectElementPropsTab,
      },
    } = this.props;

    return [
      {
        title: 'page.profilesMenu.change-name-element',
        action: ({ dataItem: { name: currentMenuName, menuId, refId, profileId } }) => {
          handleSetProfileStaffForUpdating({
            variant: 'changeName',
            currentMenuName,
            menuId,
            refId,
            profileId,
            onSuccessfulUpdateCallback: (newMenuName) => {
              routeHandleChangeCurrentLayer([
                ...data.map((item) => (item.menuId === menuId ? { ...item, name: newMenuName } : item)),
              ]);
            },
          });
        },
        show: updateElementName,
      },
      {
        title: 'page.profilesMenu.btn-create-element',
        action: this.getHandleInsert(true),
        show: createElement && !refId && refId !== 0,
      },
      {
        title: 'page.profilesMenu.clone-element',
        action: this.getHandleInsert(),
        show: cloneElement,
      },
      {
        title: 'page.profilesMenu.delete-element',
        action: ({ dataItem: { name: currentMenuName, profileId, menuId } }) => {
          handleSetActionWithCheck({
            variant: 'deleteElement',
            currentMenuName,
            profileId,
            menuId,
            onSuccessfulUpdateCallback: () => {
              routeHandleChangeCurrentLayer([...data.filter((item) => item.menuId !== menuId)]);
            },
          });
        },
        show: deleteElement,
      },
      {
        title: 'page.profilesMenu.moveElement',
        action: ({ dataItem: { name, profileId, menuId } }) => {
          handleSetProfileStaffForUpdating({
            variant: 'moveElement',
            name,
            profileId,
            menuId,
          });
        },
        show: moveElement,
      },
      {
        title: 'page.profilesMenu.elementProps',
        action: ({ dataItem: { name, profileId, menuId, profileHistorySlug, parentHistorySlug } }) => {
          return tabService.addTab({
            type: 'menuElementProps',
            dataItem: { elementName: name, profileId, menuId, profileHistorySlug, parentHistorySlug },
          });
        },
        show: redirectElementPropsTab,
      },
      {
        title: 'page.profilesMenu.showElementPath',
        action: ({ dataItem: { refId, profileId } }) => {
          handleSetProfileStaffForUpdating({
            variant: 'showElementPath',
            refId,
            profileId,
          });
        },
        show: refId || refId === 0,
      },
    ];
  };

  getHandleChangeCurrentLayer = (rootDataItem, data) => (newLayer) => {
    const { handleChangeCurrentLayer: routeHandleChangeCurrentLayer } = this.props;

    routeHandleChangeCurrentLayer([
      ...data.map((item) => (item.menuId === rootDataItem.menuId ? { ...item, details: newLayer } : item)),
    ]);
  };

  handleUpdateGridByVisibleProp = (isVisible, menuId) => {
    const { handleChangeCurrentLayer: routeHandleChangeCurrentLayer, data } = this.props;

    routeHandleChangeCurrentLayer([...data.map((item) => (item.menuId === menuId ? { ...item, isVisible } : item))]);
  };

  handleUpdateVisibleProp = (isVisible, menuId, profileId, currentMenuName) => {
    const { handleSetActionWithCheck } = this.props;

    handleSetActionWithCheck({
      variant: 'setIsVisible',
      profileId,
      menuId,
      isVisible,
      currentMenuName,
      onSuccessfulUpdateCallback: this.handleUpdateGridByVisibleProp,
    });
  };

  handleUpdateSelected = (dataItem) => {
    const {
      handleChangeCurrentLayer: routeHandleChangeCurrentLayer,
      data,
      setSelectedElements: setSelectedElementsByContext,
    } = this.props;

    routeHandleChangeCurrentLayer([
      ...data.map((item) => (item.id === dataItem.id ? { ...item, selected: !dataItem.selected } : item)),
    ]);

    setSelectedElementsByContext(dataItem);
  };

  render() {
    const {
      isRoot,
      data,
      translate,
      permissions: { isShowElementOptions },
      withSelect,
    } = this.props;

    return (
      <Grid
        data={data}
        detail={({ dataItem }) => DetailComponent(dataItem, this.getHandleChangeCurrentLayer(dataItem, data))}
        name="MenuElementsGrid"
        expandField="expanded"
        onExpandChange={this.expandChange}
        rowRender={this.rowRender}
        className={`MenuElementsGrid ${isRoot ? 'MenuElementsGrid--root' : 'MenuElementsGrid--withHidedCellHeaders'}`}
      >
        {withSelect && <GridColumn cell={getSelectCellCheckbox(this.handleUpdateSelected)} width="40px" />}

        <GridColumn
          field="name"
          cell={(props) =>
            isShowElementOptions && !withSelect ? (
              <CellDropdown
                customClassName={`MenuElementsGrid-cellDropdown ${
                  props.dataItem.refId || props.dataItem.refId === 0 ? 'MenuElementsGrid-cellDropdownWithLink' : ''
                }`}
                handlers={this.getHandlers(props.dataItem)}
                {...props}
              />
            ) : (
              <td>{props.dataItem.name}</td>
            )
          }
          title={translate('page.profilesMenu.elementName')}
          width="800px"
        />
        <GridColumn field="menuId" title={translate('page.profilesMenu.elementId')} width="120px" />
        <GridColumn field="profileId" title={translate('page.profilesMenu.profileId')} width="120px" />
        <GridColumn
          field="isVisible"
          cell={getCustomCellCheckbox(this.handleUpdateVisibleProp, withSelect)}
          width="100px"
        />
      </Grid>
    );
  }
}

export default withTranslate(widthUpdateMenuElementsContext(ProfileMenuElementsGrid));
