import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import GridScreenControlBusinessServices from './gridScreenControlBusinessServices';
import NewBusinessService from './newBusinessService';
import PageTabs from '../../../components/tabber/tabber';
import EditBusinessService from './editBusinessService';
import GridAssignBusinessService from './gridAssignBusinessService';

class PageScreenControlProfiles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.screenControlTerminals.tab-businessServices',
          content: <GridScreenControlBusinessServices />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'newTemplate':
        tabs.push({
          title: 'page.screenControlBusinessServices.tab-new-template',
          content: <NewBusinessService item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'editBusinessService':
        tabs.push({
          title: 'page.screenControlBusinessServices.tab-edit-template',
          content: <EditBusinessService item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'assignBusinessService':
        tabs.push({
          title: 'grids.profilesMainProfileList.modal-assign-template-title',
          content: <GridAssignBusinessService item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageScreenControlProfiles);
