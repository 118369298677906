import React, { PureComponent } from 'react';
import { formatDate } from '@telerik/kendo-intl';

import PageTabs from '../../components/tabber/tabber';
import { withTranslate } from '../../contexts/localContext';
import { ReleasesGrid } from './ReleasesGrid';
import ReleasesMatrix from './ReleasesMatrix';
import RolesGrid from './RolesGrid';
import AccessRoles from './ReleasesMatrix/UserAccessGrid';
import AccessRolesGrid from './ReleasesMatrix/AccessRolesGrid';

class Releases extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-releases',
          content: <ReleasesGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;

    switch (type) {
      case 'releaseNewElements':
        tabs.push({
          title: `${translate('page.releases.releaseNewElements')} ${dataItem.ReleaseVersion} ${translate(
            'page.releases.dated',
          )} ${formatDate(new Date(dataItem.DatePost), 'dd.MM.yyyy')}`,
          content: <ReleasesMatrix {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'choosingRoles':
        tabs.push({
          title: `${translate('page.releases.choosingRoles')} ${dataItem.ReleaseVersion} ${translate(
            'page.releases.dated',
          )} ${formatDate(new Date(dataItem.DatePost), 'dd.MM.yyyy')}`,
          content: <RolesGrid {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'userAccess':
        tabs.push({
          title: 'page.releases.access-users',
          content: <AccessRoles {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'rolesAccess':
        tabs.push({
          title: 'page.releases.access-role',
          content: <AccessRolesGrid {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
  }
}

export default withTranslate(Releases);
