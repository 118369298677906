import React, { PureComponent } from 'react';
import { Row, Col, Select } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import {
  renderProfileDealerVisibilityFields,
  renderProfileDescriptionField,
  renderProfileNameField,
} from '../addProfileFields';

import '../CommissionProfiles.scss';

const { Option } = Select;

class AddProfileModal extends PureComponent {
  render() {
    const {
      translate,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      profileList,
      handleSelectChange,
      handleRadioChange,
      handleOwnersChange,
      owners,
    } = this.props;

    return (
      <div className="CommissionProfiles-createProfileForm">
        <Row className="CommissionProfiles-row">
          {renderProfileNameField(formState, onChangeFormState, formErrors, onError, translate)}
        </Row>

        <Row className="CommissionProfiles-row">
          {renderProfileDescriptionField(formState, onChangeFormState, formErrors, onError, translate)}
        </Row>

        <Row className="CommissionProfiles-row">
          <Col span={8} sm={6} className="CommissionProfiles-label">
            {translate('grids.commission-profiles.cloneProfile')}
          </Col>
          <Col span={15} sm={17} offset={1}>
            <Select
              defaultValue={translate('grids.commission-profiles.withoutCommission')}
              dropdownStyle={{ zIndex: '1054' }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              onChange={handleSelectChange}
              showSearch
              className="CommissionProfiles-select"
            >
              {profileList.map(({ ProfileId, ProfileName }) => (
                <Option value={ProfileId} key={`add-profile-modal-${ProfileId}`}>
                  {ProfileName}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          {renderProfileDealerVisibilityFields(formState, handleRadioChange, handleOwnersChange, translate, owners)}
        </Row>
      </div>
    );
  }
}

export default withTranslate(AddProfileModal);
