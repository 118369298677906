import React, { PureComponent } from 'react';

import BankDetailsForm from '../../BankDetails/BankDetails';
import { ApiService } from '../../../../../services';
import LoadingPanel from '../../../../../components/loader';

const api = new ApiService();

class BankDetails extends PureComponent {
  state = {
    isLoading: false,
    baseServiceInfo: null,
  };

  componentDidMount() {
    this.setInitialBaseServiceInfo();
  }

  setInitialBaseServiceInfo = async () => {
    const { currentServiceId } = this.props;

    if (!currentServiceId) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const { Name } = await api.getServiceInfo(currentServiceId);
      await this.setState({ isLoading: false, baseServiceInfo: { Name, ServiceId: currentServiceId } });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { baseServiceInfo, isLoading } = this.state;
    const { handleUpdateTabPane } = this.props;

    return (
      <>
        {baseServiceInfo && (
          <BankDetailsForm
            dataItem={{ selectItem: { ...baseServiceInfo } }}
            onSave={() => {
              handleUpdateTabPane();
            }}
            customSaveActionTitle="page.servicesBigTable.saveAndNext"
          />
        )}
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default BankDetails;
