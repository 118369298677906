import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import CellBoolean from './cellBoolean';
import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class GridAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('accountmanager');
  }

  state = {
    owners: [],
    currentOwners: [],
    data: [],
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.fetchOwners();
  }

  makeQuery = async () => {
    const { currentOwners } = this.state;
    if (currentOwners.length < 1) return;
    try {
      this.toggleLoader(true);
      const data = await api.accounts(currentOwners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  // onOwners = (owners) => {
  //   this.setState({ owners }, this.makeQuery);
  // };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };
  onSelectedItems = (currentOwners) => {
    this.setState({ currentOwners }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, currentOwners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(owners)}
          selectItems={currentOwners}
          onSave={this.onSelectedItems}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
      </Col>
    );
  };

  renderAccountactivityOnClick = (props) => {
    if (checkElement('accountManagement-tab-type-accountactivity', this.currentArray))
      return tabService.addTab({ type: 'accountactivity', dataItem: props.dataItem });
    else return null;
  };

  renderDynamiclimitsOnClick = (props) => {
    if (checkElement('accountManagement-tab-type-dynamiclimits', this.currentArray))
      return tabService.addTab({ type: 'dynamiclimits', dataItem: props.dataItem });
    else return null;
  };

  render = () => {
    const { data, isLoading } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="accountManagement"
        permissionName="accountmanager"
      >
        <GridColumn title="grids.account.column-ownerId" field="OwnerId" width="110" filter="numeric" />

        <GridColumn field="OwnerName" title="grids.accountsLimit.column-owner" width="118" filter="text" />

        <GridColumn
          field="AccountId"
          title="grids.account.column-accountId"
          width="95"
          onClick={(props) => this.renderAccountactivityOnClick(props)}
          // onClick={(props) => tabService.addTab({ type: 'accountactivity', dataItem: props.dataItem })}
          filter="numeric"
        />

        <GridColumn field="TypeName" title="grids.account.column-typeName" width="110" />

        <GridColumn field="Amount" title="grids.account.column-balance" width="150" filter="numeric" format="{0:n2}" />

        <GridColumn field="Limit" title="grids.account.column-limit" width="90" filter="numeric" format="{0:n2}" />

        <GridColumn
          field="Limit2"
          title="grids.account.column-dynamic"
          width="134"
          filter="numeric"
          format="{0:n2}"
          onClick={(props) => this.renderDynamiclimitsOnClick(props)}
          // onClick={(props) => tabService.addTab({ type: 'dynamiclimits', dataItem: props.dataItem })}
        />

        <GridColumn
          field="Limit3"
          title="grids.account.column-encashment"
          width="150"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn field="Currency" title="grids.collectionDetails.column-currency" width="80" />

        <GridColumn
          field="Overdraft"
          title="grids.account.column-overdraft"
          width="110"
          cell={WrappedCellBoolean}
          filter="boolean"
        />
        <GridColumn
          field="Active"
          title="grids.terminals.check-active"
          width="110"
          cell={WrappedCellBoolean}
          filter="boolean"
        />

        <GridColumn
          field="LastDateMovement"
          title="grids.account.column-lastDateMovement"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="TurnDayTransactionAvg"
          title="grids.account.column-turnDayTransAvg"
          width="150"
          filter="numeric"
          format="{0:n2}"
          // cell={CellAvgTurnDayPayment}
        />

        <GridColumn field="UserNamePost" title="grids.account.column-performer" width="150" />

        <GridColumn
          field="DatePost"
          title="grids.account.column-date"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="StatusName" title="grids.account.column-status" width="150" />

        <GridColumn field="Manager" title="grids.account.column-manager" width="150" />
        <GridColumn
          field="AvailableForUse"
          title="grids.account.column-availableForUse"
          width="150"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn field="Comments" title="grids.account.column-comments" width="150" />
      </Grid>
    );
  };
}

export default withTranslate(GridAccount);
