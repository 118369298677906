import React, { createContext, Component } from 'react';

const ExpiredPasswordDialogContext = createContext({
  numberDraysBeforePasswordExpires: null,
  setNumberDraysBeforePasswordExpires: () => {},
});

export class ExpiredPasswordDialogContextProviderContainer extends Component {
  state = {
    numberDraysBeforePasswordExpires: null,
    dialogStuff: null,
  };

  setNumberDraysBeforePasswordExpires = (numberDraysBeforePasswordExpires, dialogStuff) => {
    this.setState({ numberDraysBeforePasswordExpires, dialogStuff });
  };

  render() {
    const { numberDraysBeforePasswordExpires, dialogStuff } = this.state;
    const { children } = this.props;

    return (
      <ExpiredPasswordDialogContext.Provider
        value={{
          numberDraysBeforePasswordExpires,
          dialogStuff,
          setNumberDraysBeforePasswordExpires: this.setNumberDraysBeforePasswordExpires,
        }}
      >
        {children}
      </ExpiredPasswordDialogContext.Provider>
    );
  }
}

export const widthExpiredPasswordDialogContext = (Component) => (props) =>
  (
    <ExpiredPasswordDialogContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </ExpiredPasswordDialogContext.Consumer>
  );
