import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import { ApiService, StorageService } from '../../services';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG, PAYMENT_TYPES_COLUMN_CONFIG } from '../../core/constants/configs.const';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import DealerCommissionsService from './dealerCommissions.service';
import findArray from '../../utils/findArrForCurrentPage';
import { getTransformDateString } from '../../utils/getTransformDateString';
import DealerCommissionsModal from './Modal/DealerCommissionsModal';
import checkElement from '../../utils/checkElement';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS, NORMALIZE_FIELD_MAP } from './constants';

import './DealerCommission.scss';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';

const api = new ApiService();

class DealerCommissions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('dealerCommissions');
  }

  state = {
    dealerCommissionsList: [],
    allOwners: [],
    paymentTypeList: [],
    selectPaymentTypes: [],
    isLoading: false,
    selectOwners: [],
    isDialogVisible: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchDealerCommissions();
    }
  }

  fetchDealerCommissions = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString, selectPaymentTypes, selectOwners } = this.state;

    if (!selectOwners.length || !selectPaymentTypes.length) return;

    if (selectOwners.length > 50) {
      this.setState({ isDialogVisible: true });
      return;
    }

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('dealerCommissions-grid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns
          ? [...GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP)]
          : ALL_GRID_COLUMNS);

      const filterStringByToolbar = `{ownerId:{in: [${selectOwners.join(
        ',',
      )}]}},{paymentTypeCode:{in: [${selectPaymentTypes.join(',')}]}}, ${filtersString} `;

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{serviceId: ASC}'
      }${filterStringByToolbar.trim() ? `,where:{and:[${filterStringByToolbar}]}` : ''}`;

      const query = `dealerCommissions(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { dealerCommissionsList, hasPreviousPage, hasNextPage } = DealerCommissionsService.getGQLResponse(data);

      this.setState({ dealerCommissionsList, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onFieldsConfigChange = (list) => {
    this.fetchRoutingListGQL({
      currentFieldList:
        list && list.length
          ? list
              .map((field) => NORMALIZE_FIELD_MAP.get(field) || lowerCaseFirstLetter(field))
              .filter((field) => field !== 'selected')
          : null,
    });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value, cardRoutingList: [] });
  };

  getPaymentTypeList = async () => {
    try {
      this.toggleLoader(true);
      const paymentTypeList = await api.getPaymentTypeList();
      this.setState({ paymentTypeList: paymentTypeList || [], selectPaymentTypes: paymentTypeList ? [1] : [] });
      this.toggleLoader(false);
    } catch (error) {
      this.toggleLoader(false);
      const { showError } = this.props;
      showError(error);
    }
  };

  getDropDownChangeCallback = (name) => (selectItems) => {
    this.setState({ [name]: selectItems }, this.fetchDealerCommissions);
  };

  async componentDidMount() {
    await this.getPaymentTypeList();
    await this.fetchOwners();
    await this.fetchDealerCommissions();
  }

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      this.toggleLoader(false);
      const { showError } = this.props;
      showError(error);
    }
  };

  buildToolbar = () => {
    const { allOwners, selectOwners, paymentTypeList, selectPaymentTypes } = this.state;

    const { translate } = this.props;
    return (
      <Row className="dealerCommission-toolbar">
        <Col span={24} className="dealerCommission-row">
          <Col className="dealerCommission-label">{translate('page.commissionsServices.dealer')}</Col>
          <Col className="dealerCommission-select">
            <GridDropdown
              data={DealerCommissionsService.normalizeDealerCommissions(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              onSave={this.getDropDownChangeCallback('selectOwners')}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Col>
        <Col span={24} className="dealerCommission-row">
          <Col className="dealerCommission-label">{translate('page.commissionsServices.paymentType')}</Col>
          <Col className="dealerCommission-select">
            <GridDropdown
              data={DropdownNormalizersService.normalizePaymentTypes(paymentTypeList)}
              colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
              defaultTitle={translate('page.commissionsServices.choosePaymentType')}
              selectItems={selectPaymentTypes}
              onSave={this.getDropDownChangeCallback('selectPaymentTypes')}
              isSingle
            />
          </Col>
        </Col>
      </Row>
    );
  };

  render = () => {
    const { dealerCommissionsList, isLoading, isDialogVisible, pageValue, hasNextPage, hasPreviousPage } = this.state;

    if (!checkElement('dealerCommissions-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={dealerCommissionsList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          selectedField="selected"
          toggleLoader={this.toggleLoader}
          name="dealerCommissions-grid"
          multiSelected={true}
          permissionName="dealerCommissions"
          onRefresh={this.fetchDealerCommissions}
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          normalizeGQLFieldsMap={NORMALIZE_FIELD_MAP}
          isGQL
        >
          <GridColumn field="OwnerName" title="grids.dealerCommissions.column-dealer" width="150" />
          <GridColumn field="ServiceId" title="grids.dealerCommissions.column-service" width="150" filter="numeric" />
          <GridColumn field="ServiceGroup" title="grids.dealerCommissions.column-service-group" width="150" />
          <GridColumn field="ServiceName" title="grids.dealerCommissions.column-dealer-name" width="150" />
          <GridColumn field="PaymentType" title="grids.dealerCommissions.column-paymentType" width="150" />
          <GridColumn
            field="DateStart"
            title="grids.dealerCommissions.column-date-of-beginning"
            width="150"
            cell={({ dataItem: { DateStart } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateStart)}</td>
            )}
            formatFilterCellData={(DateStart) => getTransformDateString(DateStart)}
          />
          <GridColumn
            field="СommissionDealer"
            title="grids.dealerCommissions.column-dealer-commission"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MinСommissionDealer"
            title="grids.dealerCommissions.column-min-dealer-commission"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MaxСommissionDealer"
            title="grids.dealerCommissions.column-max-dealer-commission"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="CommissionClient"
            title="grids.dealerCommissions.column-commission-client"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MinСommissionClient"
            title="grids.dealerCommissions.column-min-commission-client"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MaxСommissionClient"
            title="grids.dealerCommissions.column-max-commission-client"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="DateOfChange"
            title="grids.dealerCommissions.column-date-of-change"
            width="150"
            cell={({ dataItem: { DateOfChange } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateOfChange)}</td>
            )}
            formatFilterCellData={(DateOfChange) => getTransformDateString(DateOfChange)}
          />
          <GridColumn field="Executor" title="grids.dealerCommissions.column-executor" width="150" />
          <GridColumn field="Status" title="grids.dealerCommissions.column-status" width="150" />
        </Grid>
        {isDialogVisible && (
          <DealerCommissionsModal
            visible={isDialogVisible}
            closeModal={() => {
              this.setState({ isDialogVisible: false });
            }}
          />
        )}
      </>
    );
  };
}
export default withTranslate(DealerCommissions);
