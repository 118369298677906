import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../../../contexts/localContext';
import { Button } from 'antd';
import ModalCellValue from './modalCellValue';
import './cellValue.scss';

class CellValue extends GridCell {
  state = {
    isVisible: false,
  };

  toggleIsVisible = () => {
    this.setState(({ isVisible }) => {
      return {
        isVisible: !isVisible,
      };
    });
  };

  openModal = () => {
    this.setState({ isVisible: true });
  };

  render() {
    const { className, style, dataItem, field, isEditing, updateGridExample, skinsData, terminalId } = this.props;
    const value = dataItem[field];
    const { isVisible } = this.state;
    return (
      <>
        <td className={`${className} lg-p-0`} style={{ ...style }}>
          <Button
            className="lg-p-0 lg-w-100 addSets-cell-value"
            type="primary"
            onClick={this.toggleIsVisible}
            disabled={!isEditing}
          >
            {value}
          </Button>
        </td>
        {isVisible && (
          <ModalCellValue
            visible={isVisible}
            updateGridExample={updateGridExample}
            item={dataItem}
            toggleModal={this.toggleIsVisible}
            openModal={this.openModal}
            skinsData={skinsData}
            terminalId={terminalId}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CellValue);
