import React, { PureComponent } from 'react';
import { Col, Row, Input, Button } from 'antd';
import GridRoles from '../GridRoles/GridRoles';

import './GroupMembership.scss';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class GroupMembership extends PureComponent {
  constructor(props) {
    super(props);
    const { roleList, defaultSelectItems } = props;
    this.currentArray = findArray('users');
    this.state = {
      data: roleList,
      currentSelectItems: [],
      defaultSelectItems: defaultSelectItems,
      headerSelected: false,
    };
  }

  userRoleSet = async () => {
    const { currentSelectItems, defaultSelectItems } = this.state;
    const { userId, setIsLoading } = this.props;
    const rolesForDelete = defaultSelectItems
      .filter((roleId) => !currentSelectItems.includes(roleId))
      .map((roleId) => ({ roleId, forDelete: true }));

    const model = {
      userId,
      roles: [...currentSelectItems.map((roleId) => ({ roleId, forDelete: false })), ...rolesForDelete],
    };

    try {
      await setIsLoading(true);
      await api.userRoleSet(model);
      await window.location.reload();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const { showError } = this.props;
      showError(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { allUserRoles } = this.props;
    const { currentSelectItems, headerSelected } = this.state;
    const { currentSelectItems: prevCurrentSelectItems } = prevState;

    if (allUserRoles && currentSelectItems.length !== prevCurrentSelectItems.length) {
      this.setState({ headerSelected: allUserRoles.length === currentSelectItems.length });
    }
  }

  onRowClick = (e) => {
    this.setState(({ currentSelectItems }) => ({
      currentSelectItems: currentSelectItems.includes(e.dataItem.RoleId)
        ? currentSelectItems.filter((id) => id !== e.dataItem.RoleId)
        : [...currentSelectItems, e.dataItem.RoleId],
    }));
  };

  setAllItemsSelected = (data) => {
    this.setState(({ headerSelected }) => ({
      currentSelectItems: headerSelected ? [...data.map(({ RoleId }) => RoleId)] : [],
    }));
  };

  handleHeaderSelectionChange = (data) => {
    this.setState(
      ({ headerSelected }) => {
        return {
          headerSelected: !headerSelected,
        };
      },
      () => {
        this.setAllItemsSelected(data);
      },
    );
  };

  setDefaultItems = () => {
    const { defaultSelectItems = [] } = this.props;

    this.setState({ currentSelectItems: defaultSelectItems });
  };

  render = () => {
    const { allUserRoles, defaultSelectItems, translate, userName, LastName } = this.props;
    const { currentSelectItems, headerSelected } = this.state;
    return (
      <Col span={24} md={24} lg={24} xl={24} className="GroupMembership-content">
        <Row type="flex" align="middle" className="GroupMembership-headerRow">
          <Row type="flex" align="middle" className="GroupMembership-headerUserRow">
            <div className="GroupMembership-userLabel">{translate('page.adminPanel.label-user')}</div>
            <Input value={`${userName} ${LastName}`} disabled />
          </Row>
          {checkElement('users-tabGroupMembershipEditButton', this.currentArray) && (
            <Button type="primary" className="GroupMembership-btnOnSave" onClick={this.userRoleSet}>
              {translate('core.buttonTitles.save')}
            </Button>
          )}
        </Row>

        <Row className="GroupMembership-row">
          {defaultSelectItems && allUserRoles && (
            <GridRoles
              roleList={allUserRoles}
              setDefaultItems={this.setDefaultItems}
              currentSelectItems={currentSelectItems}
              onRowClick={this.onRowClick}
              handleHeaderSelectionChange={this.handleHeaderSelectionChange}
              headerSelected={headerSelected}
            />
          )}
        </Row>
      </Col>
    );
  };
}
export default withTranslate(GroupMembership);
