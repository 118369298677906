import React, { PureComponent } from 'react';

import MenuProfilesGrid from './MenuProfileGrid';
import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../loader';
import { widthUpdateMenuProfileContext } from './UpdateMenuProfileContext';
import UpdateMenuProfileModal from './UpdateMenuProfileModal';

import './MenuProfiles.scss';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

class MenuProfile extends PureComponent {
  currentArray = findArray('managementMenuProfiles');

  render() {
    const {
      handleSetProfileStaffForUpdating,
      profileStaffForUpdating,
      isLoading: isLoadingFromProps,
      menuProfilesTree,
      setMenuProfileTree,
    } = this.props;

    if (!checkElement('managementMenuProfiles-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <MenuProfilesGrid
          data={menuProfilesTree}
          handleChangeCurrentLayer={(newLayer) => {
            setMenuProfileTree(newLayer);
          }}
          isRoot
        />
        {profileStaffForUpdating && (
          <UpdateMenuProfileModal
            visible={!!profileStaffForUpdating}
            closeDialog={() => {
              handleSetProfileStaffForUpdating(undefined);
            }}
            profileStaffForUpdating={profileStaffForUpdating}
          />
        )}
        {isLoadingFromProps && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(widthUpdateMenuProfileContext(MenuProfile));
