import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Select, Row, Input, Alert } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import '../../../components/TerminalsCommissionsGrid/ModalChangeProfile/modalChangeProfile.scss';
import '../../screenControl(terminalsControl)/settingsAndEvents/remoteSettings/cellValue/modalCellValue.scss';
import './modalAddCheckSending.scss';

const api = new ApiService();

const { Option } = Select;

const { TextArea } = Input;

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class ModalAddCheckSending extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    owners: PropTypes.arrayOf(PropTypes.any),
    update: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: false,
      terminalsData: [],
      selectedTerminal: null,
      collectorsData: [],
      collectorValue: null,
      emailValue: '',
      errorText: null,
    };
  }

  componentDidMount() {
    this.onInit();
  }

  onDidUpdate = async (selectedTerminal) => {
    const { showError } = this.props;
    try {
      this.toggleLoader(true);
      const collectorsData = await api.getCollectors(selectedTerminal);
      this.setState({
        collectorsData: collectorsData.Collectors ? collectorsData.Collectors : [],
        collectorValue: null,
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onInit = async () => {
    const { showError, owners, currentTerminalData } = this.props;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        HideDeleted: true,
      };
      const terminalsData = await api.getTerminalsByOwner(model);
      const filteredTerminalsData = terminalsData.filter(
        (elem) => currentTerminalData.findIndex((el) => el.TerminalId === elem.TerminalId) === -1,
      );
      const selectedTerminal = (await filteredTerminalsData[0]) ? filteredTerminalsData[0].TerminalId : null;
      const collectorsData = await api.getCollectors(selectedTerminal);
      const collectorValue = collectorsData[0] ? collectorsData[0].Id : null;
      this.setState({
        terminalsData: filteredTerminalsData,
        selectedTerminal,
        collectorsData: collectorsData.Collectors ? collectorsData.Collectors : [],
        collectorValue,
      });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onChangeEmailValue = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  onChangeTerminalsSelect = (value) => {
    const { selectedTerminal } = this.state;
    if (selectedTerminal !== value) {
      this.onDidUpdate(value);
    }
    this.setState({ selectedTerminal: value });
  };

  renderTerminalsSelect = () => {
    const { terminalsData, selectedTerminal } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={selectedTerminal}
        onChange={this.onChangeTerminalsSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {terminalsData.map(({ TerminalId }) => (
          <Option value={TerminalId} key={`modalAddCheck-terminal-${TerminalId}`}>
            {TerminalId}
          </Option>
        ))}
      </Select>
    );
  };

  onChangeCollectorsSelect = (collectorValue) => {
    this.setState({ collectorValue });
  };

  renderCollectorsSelect = () => {
    const { collectorsData, collectorValue } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={collectorValue}
        onChange={this.onChangeCollectorsSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {collectorsData.map(({ Id }) => (
          <Option value={Id} key={`modalAddCheck-collector-${Id}`}>
            {Id}
          </Option>
        ))}
      </Select>
    );
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  checkEmail = (emailValue) => {
    const { translate } = this.props;
    const emailArr = emailValue.split(/[;]+/);
    let errorText = null;
    emailArr.forEach((el) => {
      if (el && el !== '' && !validateEmail(el)) {
        errorText = `${translate('page.reportPeriod.error-msg-incorrect-email')}`;
      }
    });
    this.setState({ errorText });
  };

  onSubmit = async () => {
    const { emailValue, selectedTerminal, collectorValue } = this.state;
    const { toggleModal, showError, update } = this.props;
    await this.checkEmail(emailValue);
    if (this.state.errorText !== null) return null;
    else {
      try {
        const trimmedElemValue = emailValue
          .split(/[;]+/)
          .filter((el) => el !== '')
          .map((el) => el.trim())
          .join(';');
        const model = {
          Id: 0,
          TerminalId: selectedTerminal,
          UserId: collectorValue,
          Emails: trimmedElemValue,
          Active: true,
        };
        const request = await api.collectionReceiptSendManage(model);
        toggleModal();
        update();
        return request;
      } catch (error) {
        showError(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { translate, visible, toggleModal } = this.props;
    const { loading, isLoading, selectedTerminal, collectorValue, emailValue, errorText } = this.state;
    const isDisabled = !selectedTerminal || !collectorValue || !emailValue;
    return (
      <>
        <Modal
          title={translate('grids.availableServices.button-add')}
          visible={visible}
          centered
          onOk={() => {}}
          onCancel={toggleModal}
          className="modal-system-encashment addSets-modal-cellVal"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.onSubmit} disabled={isDisabled}>
              {translate('page.screenControlBusinessServices.button-save')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.terminalsMainProfileList.column-terminalId')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderTerminalsSelect()}
            </Col>
          </Row>

          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.allEncashments.column-userId')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderCollectorsSelect()}
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('page.profile.label-email')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <TextArea
                value={emailValue}
                onChange={this.onChangeEmailValue}
                rows={4}
                disabled={!selectedTerminal || !collectorValue}
              />
              <p className="input-warning">{translate('grids.encashmentSendingCheck.email-warning')}</p>
              {errorText && <Alert message={errorText} type="error" style={{ marginTop: '10px' }} showIcon />}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalAddCheckSending);
