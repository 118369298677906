import React, { PureComponent } from 'react';
import { Row, Col, Button } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { tabService } from '../../../services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { COMMISSIONS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import CommissionsService from './commissions.service';
import CommissionDetailsForm from '../../../components/CommisionDetailsForm/CommisionDetailsForm/CommisionDetailsForm';
import checkElement from '../../../utils/checkElement';
import DeleteProfilesModal from './deleteCommissionModal';

import '../ComissionPage.scss';
import findArray from '../../../utils/findArrForCurrentPage';

class Commission extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('handlingCommissions');
    this.state = {
      isModalVisible: false,
    };
  }

  render() {
    const { translate, commissionsList, currentCommissionIds, onDropdownSave, onCommissionDelete, formState, ...rest } =
      this.props;

    const { isModalVisible } = this.state;

    return (
      <>
        <div className="Commission">
          <div className="Commission-actionPanel">
            {checkElement('handlingСommissions-btn-addCommission', this.currentArray) && (
              <div className="Commission-buttonWrapper">
                <Button type="primary" onClick={() => tabService.addTab({ type: 'createCommission' })}>
                  {translate('page.commission.btn-add')}
                </Button>
              </div>
            )}

            {checkElement('handlingСommissions-btn-deleteCommission', this.currentArray) && (
              <div className="Commission-buttonWrapper">
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ isModalVisible: true });
                  }}
                >
                  {translate('page.commission.btn-delete')}
                </Button>
              </div>
            )}

            {checkElement('handlingСommissions-btn-updateCommission', this.currentArray) && (
              <div className="Commission-buttonWrapper">
                <Button
                  type="primary"
                  onClick={() => tabService.addTab({ type: 'editCommission', dataItem: formState })}
                >
                  {translate('page.commission.btn-edit')}
                </Button>
              </div>
            )}
          </div>

          <div className="Commission-content">
            <Row className="Commission-dropdown">
              <Col span={24} sm={18} lg={14} xl={10}>
                <GridDropdown
                  data={CommissionsService.normalizeCommissions(commissionsList)}
                  colConfig={COMMISSIONS_COLUMN_CONFIG}
                  selectItems={currentCommissionIds}
                  onSave={onDropdownSave}
                  defaultTitle={translate('page.commission.selectCommission')}
                  isSingle
                />
              </Col>
            </Row>

            <div className="Commission-form">
              <CommissionDetailsForm {...rest} formState={formState} isFormDisabled />
            </div>
          </div>
        </div>

        {isModalVisible && (
          <DeleteProfilesModal
            visible={isModalVisible}
            closeModal={() => {
              this.setState({ isModalVisible: false });
            }}
            commissionName={formState.CommissionName}
            onCommissionDelete={onCommissionDelete}
          />
        )}
      </>
    );
  }
}

export default withTranslate(Commission);
