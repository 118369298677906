import React, { PureComponent } from 'react';

import { ApiService } from '../../../services';
import GroupMembership from './GroupMembership';
import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class GroupMembershipContainer extends PureComponent {
  state = {
    isEditing: false,
    isLoading: false,
    allUserRoles: null,
    defaultSelectItems: null,
  };

  onEditingPermitted = async () => {
    this.setState({ isEditing: true });
  };

  getAllRoles = (allUserRoles, userRolesById) => {
    if (!allUserRoles || !allUserRoles.length) return [];

    if (!userRolesById || !userRolesById.length) {
      return allUserRoles;
    }

    return allUserRoles.map((item) => {
      const roleByUser = userRolesById.find(({ RoleId }) => RoleId === item.RoleId);

      return roleByUser || item;
    });
  };

  getDataForGrid = async () => {
    const { userId } = this.props;
    try {
      await this.setState({ isLoading: true });
      const allUserRoles = await api.getUserAvailableRoles();
      const userRolesById = await api.getUserRolesByUserId(userId);
      this.setState({
        defaultSelectItems: userRolesById && userRolesById.length ? userRolesById.map(({ RoleId }) => RoleId) : [],
        allUserRoles: this.getAllRoles(allUserRoles, userRolesById),
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    await this.getDataForGrid();
    // await this.fetchAllUserRoles();
    // await this.userRolesByUserId();
  }

  setIsLoading = (value) => {
    this.setState({ isLoading: value });
  };

  render = () => {
    const { defaultSelectItems, allUserRoles, isLoading } = this.state;
    return (
      <>
        {defaultSelectItems && allUserRoles && (
          <GroupMembership
            {...this.props}
            {...this.state}
            defaultSelectItems={defaultSelectItems}
            allUserRoles={allUserRoles}
            onEditingPermitted={this.onEditingPermitted}
            setIsLoading={this.setIsLoading}
          />
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(GroupMembershipContainer);
