import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService } from '../../services';
import { Button, Col } from 'antd';
import ModalBeneficiaries from './ModalBeneficiares/ModalBeneficiares';
import ButtonGroup from 'antd/es/button/button-group';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

import './Beneficiaries.scss';

const api = new ApiService();

class BeneficiariesGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('beneficiaries');
  }
  state = {
    isLoading: false,
    beneficiaries: [],
    selectedItems: [],
    modalType: '',
  };

  fetchBeneficiaries = async () => {
    try {
      this.setState({ isLoading: true });
      const beneficiaries = await api.getServicesSuppliersBeneficiary();
      this.setState({ beneficiaries, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  onHandleSelected = (selectedItems) => {
    this.setState({ selectedItems });
  };

  showModal = (e) => {
    const { name } = e.target;
    this.setState({ modalType: name });
  };

  createToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;
    return (
      <Col>
        <ButtonGroup className="Beneficiaries-btnGroup" onClick={this.showModal}>
          {checkElement('beneficiaries-btnAdd', this.currentArray) && (
            <Button type="primary" name="add">
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('beneficiaries-btnChange', this.currentArray) && (
            <Button type="primary" name="change" disabled={!selectedItems.length || selectedItems.length > 1}>
              {translate('core.buttonTitles.change')}
            </Button>
          )}
          {checkElement('beneficiaries-btnDelete', this.currentArray) && (
            <Button type="primary" name="delete" disabled={!selectedItems.length}>
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </ButtonGroup>
      </Col>
    );
  };

  componentDidMount() {
    this.fetchBeneficiaries();
  }

  render() {
    const { beneficiaries, isLoading, modalType, selectedItems } = this.state;

    if (!checkElement('beneficiaries-content', this.currentArray)) return null;
    return (
      <>
        <Grid
          data={beneficiaries}
          toolbar={this.createToolbar()}
          name="beneficiariesGrid"
          isLoading={isLoading}
          onRefresh={this.fetchBeneficiaries}
          fieldForSelection="Id"
          multiSelected
          onSelect={this.onHandleSelected}
        >
          <GridColumn field="selected" filterable={false} sortable={false} showAllSelected={true} columnMenu={false} />
          <GridColumn field="Id" title="grids.beneficiaries.col-id" width="150" />
          <GridColumn field="Inn" title="grids.beneficiaries.col-inn" width="150" />
          <GridColumn field="Name" title="grids.beneficiaries.col-fullName" width="350" />
        </Grid>
        {!!modalType.length && (
          <ModalBeneficiaries
            modal={modalType}
            visible={!!modalType.length}
            selectedItems={selectedItems}
            updateData={this.fetchBeneficiaries}
            onClose={() => this.setState({ modalType: '' })}
          />
        )}
      </>
    );
  }
}

export default withTranslate(BeneficiariesGrid);
