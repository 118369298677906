import React, { PureComponent } from 'react';
import { Button, Modal, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class UpdateStatusModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deactivateSelectedLimits = async () => {
    const { translate, onRefresh, closeModal, selectedItems } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.deleteServiceAuthorizationLimits(selectedItems.map(({ Id }) => Id));
      await this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(translate('page.servicesAuthorizationLimits.limitsStatusChangeSuccessfully'), 2);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  activateSelectedLimits = async () => {
    const { translate, onRefresh, closeModal, selectedItems } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.updateServiceAuthorizationLimits(
        selectedItems.map(
          ({
            Id,
            ServiceId,
            AuthorizationId,
            FinmonPayer,
            FinmonRecepient,
            TransactionMaxLimit,
            TransactionMinLimit,
            DailyLimit,
            MonthlyLimit,
            DailyTransactionsCountLimit,
          }) => ({
            Id,
            ServiceId,
            AuthorizationId,
            FinmonPayer,
            FinmonRecepient,
            TransactionMaxLimit,
            TransactionMinLimit,
            DailyLimit,
            MonthlyLimit,
            DailyTransactionsCountLimit,
          }),
        ),
      );
      await this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(translate('page.servicesAuthorizationLimits.limitsStatusChangeSuccessfully'), 2);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, closeModal, visible } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        title={translate('page.servicesAuthorizationLimits.changeStatus')}
        onCancel={() => closeModal(false)}
        className="UpdateAuthorizationLimitModal"
        footer={[
          <Button type="primary" key="activate" onClick={this.activateSelectedLimits}>
            {translate('page.servicesAuthorizationLimits.activate')}
          </Button>,
          <Button key="deactivate" onClick={this.deactivateSelectedLimits}>
            {translate('page.servicesAuthorizationLimits.deactivate')}
          </Button>,
        ]}
      >
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateStatusModal);
