import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../Input';

import './gridTransactions.scss';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class SplittingTransactionModal extends PureComponent {
  state = {
    formState: { parentTransaction: this.props.dialogStuff.amountTotal, childTransaction: 0 },
    formErrors: { parentTransaction: '', childTransaction: '' },
    globalError: '',
    isLoading: false,
  };

  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  numberValidation = (value) => {
    const { translate } = this.props;

    const normalizedValue = this.getParsedValue(value);

    if (isNaN(+normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${normalizedValue}`.includes('.') && `${normalizedValue}`.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }

    return '';
  };

  renderNumberInput = (name) => {
    const { formState, formErrors } = this.state;

    return (
      <div className="GridTransactionsSplittingTransactionModal-input">
        <CustomInput
          formState={formState}
          name={name}
          onChangeFormState={(name, value) => {
            this.setState(({ formState }) => ({ formState: { ...formState, [name]: value }, globalError: '' }));
          }}
          formErrors={formErrors}
          setError={(name, value) => {
            this.setState(({ formErrors }) => ({ formErrors: { ...formErrors, [name]: value } }));
          }}
          validators={{ [name]: this.numberValidation }}
        />
      </div>
    );
  };

  splitTransaction = async () => {
    const {
      dialogStuff: { amountTotal, transactionId },
      translate,
      closeModal,
      onRefresh,
    } = this.props;
    const {
      formState: { parentTransaction, childTransaction },
    } = this.state;

    if (this.getParsedValue(parentTransaction) + this.getParsedValue(childTransaction) !== +amountTotal) {
      this.setState({ globalError: translate('page.transactions.exceedTheTransactionAmount') });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      await api.divideTransaction({
        transactionIdCloneFirst: this.getParsedValue(parentTransaction),
        transactionIdCloneSecond: this.getParsedValue(childTransaction),
        transactionIdParent: transactionId,
      });
      await message.success(translate('page.transactions.transactionSuccessfullySplit'), 2);
      this.setState({ isLoading: false });
      onRefresh();
      closeModal();
    } catch (error) {
      const { showError } = this.props;

      showError(error);
      this.setState({ isLoading: false });
    }

    console.log('REQ!');
  };

  render() {
    const { visible, translate, closeModal, dialogStuff } = this.props;
    const { formErrors, globalError, isLoading } = this.state;

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={translate('page.transactions.addSearchParameters')}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={this.splitTransaction}
            disabled={Object.values(formErrors).some(Boolean) || globalError}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="GridTransactionsExpandSearchOptionModal-row GridTransactionsSplittingTransactionModal-transactionRow">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('grids.transactions.column-transactionId')}
          </div>
          <Input value={dialogStuff.transactionId} disabled />
        </div>

        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.parentTransaction')}
          </div>

          {this.renderNumberInput('parentTransaction')}
        </div>

        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.childTransaction')}
          </div>

          {this.renderNumberInput('childTransaction')}
        </div>

        {globalError && <div className="GridTransactionsExpandSearchOptionModal-error">{globalError}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(SplittingTransactionModal);
