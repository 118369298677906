import React, { PureComponent } from 'react';
import { Button, message, Modal, Input, Checkbox } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

import './MFOGrid.scss';

const api = new ApiService();

class UpdateMFOModal extends PureComponent {
  state = {
    MFO: '',
    name: '',
    active: true,
    isLoading: false,
    MFOError: '',
  };

  renderInputRow = ({ label, inputComponent }) => {
    return (
      <div className="UpdateMFOModal-inputRow">
        <div className="UpdateMFOModal-label">{label}</div>
        {inputComponent}
      </div>
    );
  };

  componentDidMount() {
    const { selectedItem, variant } = this.props;

    if (variant !== 'add') {
      this.setState({
        MFO: selectedItem.Id,
        name: selectedItem.Name,
        active: selectedItem.Active,
      });
    }
  }

  updateMFO = async () => {
    const { variant, translate, closeModal, onRefresh } = this.props;
    const { MFO, name, active } = this.state;
    const isAddAction = variant === 'add';

    if (!Number.isInteger(+MFO) || +MFO > 999999999) {
      this.setState({ MFOError: translate('page.MFO.invalidFormatError') });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const method = isAddAction ? 'addMFO' : 'updateMFO';
      await api[method]({
        id: +MFO,
        name,
        active,
      });

      this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(`${translate(`page.MFO.${isAddAction ? 'MFOAddedSuccessfully' : 'MFOEditSuccessfully'}`)}`, 2.5);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { variant, closeModal, translate } = this.props;
    const { MFO, name, active, isLoading, MFOError } = this.state;
    const isAddAction = variant === 'add';

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.MFO.${isAddAction ? 'addMFO' : 'editMFO'}`)}
        footer={[
          <Button key="back" type="primary" onClick={this.updateMFO} disabled={!MFO || !name}>
            {translate(`core.buttonTitles.${isAddAction ? 'add' : 'edit'}`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>
          {this.renderInputRow({
            label: translate('page.MFO.col-MFO'),
            inputComponent: (
              <Input
                onChange={({ target: { value } }) => {
                  this.setState({ MFO: value, MFOError: '' });
                }}
                value={MFO}
                disabled={!isAddAction}
              />
            ),
          })}

          {this.renderInputRow({
            label: translate('page.MFO.col-name'),
            inputComponent: (
              <Input
                onChange={({ target: { value } }) => {
                  this.setState({ name: value });
                }}
                value={name}
                maxLength={255}
              />
            ),
          })}

          {this.renderInputRow({
            label: translate('page.MFO.col-activity'),
            inputComponent: (
              <Checkbox
                onChange={() => {
                  this.setState(({ active }) => ({ active: !active }));
                }}
                checked={active}
                disabled={isAddAction}
              />
            ),
          })}

          {isLoading && <LoadingPanel />}
          {MFOError && <div className="UpdateMFOModal-errorTitle">{MFOError}</div>}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(UpdateMFOModal);
