import { StandardizeTimeForDate } from '../utils/standardizeTimeForDate';
const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

class FiscalizedTransactionsDaysReportService {
  getMinimumSelectedDate = () => StandardizeTimeForDate(new Date(2003, 6, 1), true);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    if (minimumSelectedDate - normalizedStartDate > 0 || minimumSelectedDate - normalizedEndDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    if (normalizedEndDate - normalizedStartDate > THIRTY_DAYS) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new FiscalizedTransactionsDaysReportService();
