import React, { PureComponent } from 'react';

import CheckTemplateForm from './CheckTemplateForm';
import FormStateContainer from '../../../components/FormStateContainer';
import { INITIAL_VALUES } from './constants';

class CheckTemplateFormContainer extends PureComponent {
  render() {
    const { selectedTemplate, isFormDisabled, isWithoutIdField, onValuesStateChange } = this.props;

    return (
      <FormStateContainer initialValues={INITIAL_VALUES}>
        <CheckTemplateForm
          currentChosenTemplate={selectedTemplate}
          isFormDisabled={isFormDisabled}
          isWithoutIdField={isWithoutIdField}
          onValuesStateChange={onValuesStateChange}
        />
      </FormStateContainer>
    );
  }
}

export default CheckTemplateFormContainer;
