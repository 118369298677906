const CardRouting = (translate, value) => {
  switch (value) {
    case 'cardRouting-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'cardRouting-addItems':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'cardRouting-changeItems':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    case 'cardRouting-deleteItems':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
    case 'cardRouting-banks':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.routingManagement.banks')}`;
    default:
      return value;
  }
};

export default CardRouting;
