import { uppercaseFirstLetter } from './uppercaseFirstLetter';
import { FIRSTLY_LETTER_FOR_I, FIRSTLY_LETTER_FOR_IE } from '../pages/Users/WorkWithDealers/constants';

export const alphabeticalSorting = (prevSortItem, nextSortItem) => {
  if (
    prevSortItem &&
    nextSortItem &&
    uppercaseFirstLetter(prevSortItem).charAt(0) === 'Є' &&
    FIRSTLY_LETTER_FOR_IE.includes(uppercaseFirstLetter(nextSortItem).charAt(0))
  ) {
    return 1;
  }

  if (
    prevSortItem &&
    nextSortItem &&
    uppercaseFirstLetter(nextSortItem).charAt(0) === 'Є' &&
    FIRSTLY_LETTER_FOR_IE.includes(uppercaseFirstLetter(prevSortItem).charAt(0))
  ) {
    return -1;
  }

  if (
    prevSortItem &&
    nextSortItem &&
    uppercaseFirstLetter(prevSortItem).charAt(0) === 'І' &&
    FIRSTLY_LETTER_FOR_I.includes(uppercaseFirstLetter(nextSortItem).charAt(0))
  ) {
    return 1;
  }

  if (
    prevSortItem &&
    nextSortItem &&
    uppercaseFirstLetter(nextSortItem).charAt(0) === 'І' &&
    FIRSTLY_LETTER_FOR_I.includes(uppercaseFirstLetter(prevSortItem).charAt(0))
  ) {
    return -1;
  }

  if (prevSortItem && nextSortItem && prevSortItem.toUpperCase() < nextSortItem.toUpperCase()) {
    return -1;
  }

  if (prevSortItem && nextSortItem && prevSortItem.toUpperCase() > nextSortItem.toUpperCase()) {
    return 1;
  }
  return 0;
};
