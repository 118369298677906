import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridApplications from './gridApplications';
import CreateApplication from './createApplication';
import CloneApplication from './cloneApplication';
import EditApplication from './editApplication';
import ChangeComission from './changeComission';
import PageTabs from '../../components/tabber/tabber';

class PageAddingAdressedPayment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.addAdressPayment.tab-main',
          content: <GridApplications />,
          // content: <ChangeComission item={21} />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'createApplication':
        tabs.push({
          title: 'page.addAdressPayment.tab-create',
          content: <CreateApplication />,
          closable: true,
          key,
        });
        break;
      case 'cloneApplication':
        tabs.push({
          title: 'page.addAdressPayment.tab-create',
          content: <CloneApplication item={dataItem} />,
          closable: true,
          key,
        });
        break;
      case 'editApplication':
        tabs.push({
          title: 'page.addAdressPayment.tab-edit',
          content: <EditApplication item={dataItem} />,
          closable: true,
          key,
        });
        break;
      case 'changeComission':
        tabs.push({
          title: 'page.addAdressPayment.tab-changeComission',
          content: <ChangeComission item={dataItem} />,
          closable: true,
          key,
        });
        break;
      default:
        return null;
    }
    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageAddingAdressedPayment);
