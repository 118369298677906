class DealerCommissionsTerminalsService {
  normalizeTerminals(terminals) {
    if (!terminals) {
      return [];
    }

    return terminals.map((terminal) => ({
      ...terminal,
      Id: terminal.TerminalId,
      Name: `${terminal.TerminalId}`,
    }));
  }

  normalizeServicesByTerminals(servicesByTerminals) {
    if (!servicesByTerminals || !servicesByTerminals.length) {
      return [];
    }

    return servicesByTerminals.map((serviceByTerminal) => ({
      ...serviceByTerminal,
      Id: `${serviceByTerminal.OwnerId}-${serviceByTerminal.TerminalId}-${serviceByTerminal.ServiceId}`,
    }));
  }
}

export default new DealerCommissionsTerminalsService();
