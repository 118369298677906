import React, { PureComponent } from 'react';
import { Button, message, Modal, Row } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { renderProfileDescriptionField } from './addProfileFields';
import LoadingPanel from '../../components/loader';
import { ApiService } from '../../services';

const api = new ApiService();

class ChangeProfileDescriptionModal extends PureComponent {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    const {
      formState: {
        ProfileName,
        ProfileId,
        DatePost,
        Owners,
        ProfileDescription: Description,
        UserName,
        Version,
        IsForAllOwners,
      },
      showError,
      translate,
    } = this.props;

    await this.setState({ isLoading: true });

    try {
      await api.commissionsClientProfileUpdate({
        Name: ProfileName,
        ProfileId,
        DatePost,
        OwnersList: IsForAllOwners ? [] : Owners.split(','),
        Description,
        UserName,
        Version,
        IsForAllOwners,
      });
      await message.success(translate('grids.commission-profiles.responseMessages.successfulUpdateProfile'), 2);
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, formState, onChangeFormState, formErrors, onError } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('grids.commission-profiles.changeProfileDescriptionTitle')}
        visible={visible}
        onCancel={closeModal}
        zIndex="1053"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={this.handleSubmit}
            disabled={!formState.ProfileDescription}
          >
            {translate('page.commission.btn-edit')}
          </Button>,
        ]}
      >
        <Row className="CommissionProfiles-row">
          {renderProfileDescriptionField(formState, onChangeFormState, formErrors, onError, translate)}
        </Row>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ChangeProfileDescriptionModal);
