import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

const { TextArea } = Input;

const api = new ApiService();

class AddCommentDialog extends PureComponent {
  state = {
    isLoading: false,
    comment: '',
    transactionIds: this.props.selectedTransactionIds.join(', '),
  };

  setComment = async ([transactionId, ...restTransactions]) => {
    const { comment } = this.state;

    try {
      await api.transactionCommentAdd({
        transactionId,
        comment,
      });

      if (restTransactions.length) {
        await this.setComment(restTransactions);
      }
    } catch (e) {
      throw e;
    }
  };

  setAllTransactions = async () => {
    const { selectedTransactionIds, onRefresh, closeModal, translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await this.setComment(selectedTransactionIds);
      await message.success(translate('page.transactions.commentWasSuccessfullyChanged'), 2);
      onRefresh();
      closeModal();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { translate, closeModal, visible } = this.props;
    const { comment, isLoading, transactionIds } = this.state;

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={translate('page.transactions.addingComment')}
        onCancel={closeModal}
        footer={[
          <Button type="primary" key="add" onClick={this.setAllTransactions} disabled={!comment}>
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.selectedTransactions')}
          </div>

          <TextArea disabled value={transactionIds} />
        </div>

        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('grids.transactions.column-comment')}
          </div>

          <TextArea
            value={comment}
            onChange={({ target: { value: comment } }) => {
              this.setState({ comment });
            }}
          />
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddCommentDialog);
