import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';

import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { formatDate } from '@telerik/kendo-intl';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import { getTransformDateString } from '../../utils/getTransformDateString';
import { getCustomDate } from '../../utils/getCustomDate';

import './CashCollectors.scss';
import DealerCommissionsService from '../DealerCommissions/dealerCommissions.service';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

class CashCollectors extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('collectorsCashReport');
  }
  state = {
    cashCollectors: [],
    allOwners: [],
    selectOwners: [1],
    isLoading: false,
    start: new Date(),
    end: new Date(),
    isDisabledButton: false,
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchOwners = async () => {
    try {
      this.setState({ isLoading: true });
      const allOwners = await api.owners();
      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };
  getCashCollectorsData = async () => {
    const { selectOwners, start, end } = this.state;
    try {
      const model = {
        owners: selectOwners,
        dateStart: convertToISOFormat(
          getCustomDate({
            customTime: { h: 0, m: 0, s: 0, ms: 0 },
            customDayOfMonth: start.getDate(),
            customDateMonth: start.getMonth(),
            customDateFullYear: start.getFullYear(),
          }),
        ),
        dateEnd: convertToISOFormat(
          getCustomDate({
            customTime: { h: 23, m: 59, s: 59, ms: 999 },
            customDayOfMonth: end.getDate(),
            customDateMonth: end.getMonth(),
            customDateFullYear: end.getFullYear(),
          }),
        ),
      };
      this.toggleLoader(true);
      const cashCollectors = await api.cashCollectors(model);
      this.setState({ cashCollectors });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };
  onDateChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
      isDisabledButton: false,
    });
  };

  dropDownChangeCallback = (name) => (selectItems) => {
    this.setState({ [name]: selectItems }, this.getCashCollectorsData);
  };

  async componentDidMount() {
    await this.fetchOwners();
  }

  buildToolbar = () => {
    const { allOwners, selectOwners, start, end, isDisabledButton } = this.state;
    const { translate } = this.props;

    return (
      <Col span={24} xl={16} xxl={14}>
        <Row>
          <Col span={18} className="CashCollectors-dealerRow">
            <GridDropdown
              data={DealerCommissionsService.normalizeDealerCommissions(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              onSave={this.dropDownChangeCallback('selectOwners')}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Row>

        <Col span={24}>
          <Row type="flex" align="middle" justify="flex-start" className="CashCollectors-dateRow">
            <Col span={24} md={16} lg={8}>
              <Row type="flex" align="middle" justify="space-between">
                <Col span={4} md={6}>
                  <p>{translate('grids.comission-sub.validity-period-date-start')}</p>
                </Col>
                <Col span={20} md={18}>
                  <DatePicker
                    format="yyyy.MM.dd"
                    value={start}
                    onChange={(e) => {
                      this.onDateChange(e, 'start');
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} md={16} lg={8}>
              <Row type="flex" align="middle" className="CashCollectors-dateRow">
                <Col span={4} md={6}>
                  <p>{translate('grids.comission-sub.validity-period-date-end')}</p>
                </Col>
                <Col span={20} md={18}>
                  <DatePicker
                    format="yyyy.MM.dd"
                    value={end}
                    onChange={(e) => {
                      this.onDateChange(e, 'end');
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} lg={4}>
              <Button icon="search" primary disabled={isDisabledButton} onClick={this.getCashCollectorsData}>
                {translate('owner.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  };

  render = () => {
    const { cashCollectors, isLoading } = this.state;

    if (!checkElement('collectorsCashReport-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={cashCollectors}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        onRefresh={this.getCashCollectorsData}
        name="cashCollectors-grid"
      >
        <GridColumn
          field="WorkDate"
          title="grids.cashDept.column-Date"
          cell={({ dataItem: { WorkDate } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(WorkDate)}</td>
          )}
          formatFilterCellData={(WorkDate) => getTransformDateString(WorkDate)}
        />
        <GridColumn field="OwnerId" title="grids.cashDept.column-DealerId" width="120" />
        <GridColumn field="OwnerName" title="grids.cashDept.column-DealerName" width="120" />
        <GridColumn field="DispatcherName" title="grids.cashCollectors.column-dispatchHerName" width="120" />
        <GridColumn field="Nominal" title="grids.cashCollectors.column-nominal" width="120" />
        <GridColumn field="Quantity" title="grids.cashCollectors.column-quantity" width="140" />
        <GridColumn field="Amount" title="grids.cashCollectors.column-amount" width="100" />
      </Grid>
    );
  };
}
export default withTranslate(CashCollectors);
