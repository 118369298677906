const fishkaService = (translate, value) => {
  switch (value) {
    case 'fishkaService-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'fishkaService-BtnDelete':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.delete')}`;
    case 'fishkaService-BtnAdd':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.add')}`;
    case 'fishkaService-historyTabLink':
      return `${translate('page.adminPanel.type-navLink')}: ${translate('page.fishkaService.tab-historyGrid')}`;
    case 'fishkaService-historyTabBtnSearch':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate(`page.fishkaService.tab-historyGrid`)}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('page.changingProfileHistory.btn-view')}`;
  }
};

export default fishkaService;
