export const BUSINESS_TEMPLATE_SELECTED_FIELDS = {
  Id: 'MainProfileHoldId',
  Name: 'MainProfileHoldName',
  Version: 'MainProfileHoldVersion',
};

export const BACKUP_TEMPLATE_SELECTED_FIELDS = {
  Id: 'MainProfileReserveId',
  Name: 'MainProfileReserveName',
  Version: 'MainProfileReserveVersion',
};

export const DEFAULT_WRONG_BACKUP_BUTTONS_STRUCTURE = {
  alreadyExistedBackUpButtons: [],
  alreadyMainProfileButtons: [],
};
