import React, { PureComponent } from 'react';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../../components/Input/Input';
import { INITIAL_VALUES, LABEL_BY_SLUG } from './constants';

import './CheckTemplateForm.scss';

class CheckTemplateForm extends PureComponent {
  resetTemplate = (currentChosenTemplate) => {
    const { setFormState } = this.props;

    if (!currentChosenTemplate) {
      setFormState(INITIAL_VALUES);
      return;
    }

    const { TemplateId, TemplateName, Description, Receipt } = currentChosenTemplate;

    setFormState({
      templateNumber: TemplateId,
      templateName: TemplateName,
      description: Description,
      receipt: Receipt,
    });
  };

  componentDidMount() {
    const { currentChosenTemplate } = this.props;

    this.resetTemplate(currentChosenTemplate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentChosenTemplate, formState, onValuesStateChange } = this.props;
    const { currentChosenTemplate: prevCurrentChosenTemplate, formState: prevFormState } = prevProps;

    if (currentChosenTemplate !== prevCurrentChosenTemplate) {
      this.resetTemplate(currentChosenTemplate);
    }

    if (formState !== prevFormState && onValuesStateChange) {
      onValuesStateChange(formState);
    }
  }

  renderInput = ({ name, type = 'text', isDisabled = false }) => {
    const { translate, isFormDisabled, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="CheckTemplateForm-row">
        <div className="CheckTemplateForm-label">{translate(LABEL_BY_SLUG[name])}:</div>

        <div className={`CheckTemplateForm-input ${type ? 'CheckTemplateForm-areaInput' : ''}`}>
          <CustomInput
            type={type}
            formState={formState}
            disabled={isDisabled || isFormDisabled}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            setError={onError}
            name={name}
            {...(type === 'text' ? { maxLength: 100 } : {})}
          />
        </div>
      </div>
    );
  };

  render() {
    const { isWithoutIdField } = this.props;

    return (
      <div className="CheckTemplateForm">
        {!isWithoutIdField && this.renderInput({ name: 'templateNumber', isDisabled: true })}
        {this.renderInput({ name: 'templateName' })}
        {this.renderInput({ name: 'description' })}
        {this.renderInput({ name: 'receipt', type: 'textArea' })}
      </div>
    );
  }
}

export default withTranslate(CheckTemplateForm);
