export const GQL_GRID_COLUMNS = {
  terminalId: { title: '#', filter: 'numeric' },
  name: { title: 'grids.terminals.column-name', filter: 'text' },
  cityName: { title: 'grids.terminals.column-city', filter: 'text' },
  terminalAddress: { title: 'grids.terminals.column-street', filter: 'text' },
  receiptsUsed: { title: 'grids.terminals.column-receipts', filter: 'numeric' },
  designTypeName: { title: 'grids.createTerminal.column-designType', filter: 'text' },
  dateLastConnectNoUTC: { title: 'grids.terminals.column-lastconnect', filter: 'date' },
  dateLastTransactionNoUTC: { title: 'grids.terminals.column-lasttransaction', filter: 'date' },
  cashBoxUsed: { title: 'grids.terminals.column-сashBoxUsed', filter: 'numeric' },
  cashCount: { title: 'grids.terminals.column-cashCount', filter: 'numeric' },
  cashSum: { title: 'grids.terminals.column-cashSum', filter: 'numeric' },
  coinBoxUsed: { title: 'grids.encashment.column-coinBoxUsed', filter: 'numeric' },
  coinBoxQuantity: { title: 'grids.encashment.column-coinBoxQuantity', filter: 'numeric' },
  fiscalNumber: { title: 'grids.createTerminal.column-fiscalNumber', filter: 'text' },
  prevTransactionAmountSum: { title: 'grids.terminals.column-turnoversYesterday', filter: 'numeric' },
  transactionAmountSum: { title: 'grids.terminals.column-turnoversToday', filter: 'numeric' },
  prevTransactionCount: { title: 'grids.terminals.column-paymentsYesterday', filter: 'numeric' },
  transactionCount: { title: 'grids.terminals.column-paymentsToday', filter: 'numeric' },
  statusState: { title: 'grids.terminals.column-state', filter: 'text' },
  statusDetails: { title: 'grids.terminals.column-details', filter: 'text' },
  branch: { title: 'grids.terminals.column-branch', filter: 'text' },
  note: { title: 'page.terminalSettings.field-notes', filter: 'text' },
  internetTrafficMonth: { title: 'grids.terminals.column-internet-traffic-month', filter: 'numeric' },
  internetTrafficYesterday: { title: 'grids.terminals.column-internet-traffic-yesterday', filter: 'numeric' },
  mobileOperator: { title: 'grids.terminals.column-mobile-operator', filter: 'text' },
  lastScreenTouch: { title: 'grids.terminals.column-activity', filter: 'text' },
  dateLastTerminalMaintenance: { title: 'grids.terminals.column-lastMaintenance', filter: 'text' },
  mobilePhone: { title: 'grids.terminals.column-simCardNumber', filter: 'text' },
  moneyTakerCanceled: { title: 'grids.terminals.column-moneyTakerCanceled', filter: 'numeric' },
};

export const NORMALIZE_FIELD_MAP = new Map([
  ['DateLastConnect', 'dateLastConnectNoUTC'],
  ['DateLastTransaction', 'dateLastTransactionNoUTC'],
  ['DateLastScreenTouch', 'lastScreenTouch'],
]);

export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([
  ['dateLastConnectNoUTC', 'DateLastConnect'],
  ['dateLastTransactionNoUTC', 'DateLastTransaction'],
  ['lastScreenTouch', 'DateLastScreenTouch'],
]);
