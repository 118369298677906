import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridOwnerStockPhotos from './gridOwnerStockPhotos';
import PageTabs from '../../components/tabber/tabber';

class PageOwnerStockPhotos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.adminStockPhotos.tab-main',
          content: <GridOwnerStockPhotos />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      // case 'photos':
      //   tabs.push({
      //     title: "page.adminStockPhotos.tab-main",
      //     content: <SubGridAdminStockPhotos item={dataItem} />,
      //     closable: true,
      //     key: key
      //   });
      //   break;
      default:
        console.warn('Unknown tab type');
        break;
    }
    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageOwnerStockPhotos);
