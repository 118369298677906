export const dealerCommissionsPaymentTools = (translate, value) => {
  switch (value) {
    case 'dealerCommissionsPaymentTools-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dealerCommissionsPaymentTools-btnSearch':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.search')}`;
    case 'dealerCommissionsPaymentTools-btnAdd':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.add')}`;
    case 'dealerCommissionsPaymentTools-btnDelete':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.delete')}`;
    default:
      return value;
  }
};
