export default [
  {
    route: '/pages/',
    text: 'page-nav.nav-main',
    requirePermission: 'dashboard',
  },
  {
    route: '/pages/adminPanel',
    text: 'page-nav.tab-adminPanel',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/releases',
        text: 'page-nav.nav-releases',
        requirePermission: 'releasePage',
      },
      {
        route: '/pages/allElements',
        text: 'page-nav.nav-ElementsOfAllReleases',
        requirePermission: 'releasePage',
      },
      {
        route: '/pages/admin',
        text: 'page-nav.nav-admin',
        requirePermission: 'admin',
      },
    ],
  },

  {
    route: '/pages/mfoTransactionsPage',
    text: 'page-nav.nav-mfoTransactionsPage',
    requirePermission: 'mfoTransactions',
  },

  {
    route: '/pages/monitoringCabinet',
    text: 'page-nav.nav-monitoringCabinet',
    requirePermission: 'monitoringCabinet',
  },
  {
    route: '/pages/suspendedFinancialTransactions',
    text: 'page-nav.nav-suspendedFinancialTransactions',
    requirePermission: 'SuspendedFinancialTransactions',
  },
  {
    route: '/pages/blockedTransactions',
    text: 'page-nav.nav-blockedTransactions',
    requirePermission: 'blockedTransactions',
  },
  {
    route: '/pages/externalOutgoingPayments',
    text: 'page-nav.nav-externalOutgoingPayments',
    requirePermission: 'externalOutgoingPayments',
  },

  {
    route: '/pages/monitorActivity',
    text: 'page-nav.nav-activityMonitor',
    requirePermission: 'activitymonitor',
  },
  {
    route: '/pages/easyPayUsers',
    text: 'page-nav.nav-easyPayUsers',
    requirePermission: 'easyPayUsers',
  },
  {
    route: '/pages/fishkaService',
    text: 'page-nav.nav-fishkaService',
    requirePermission: 'fishkaService',
  },
  {
    route: '/pages/terminal_control',
    text: 'page-nav.nav-terminal-screen-management',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/terminal_control/settings',
        text: 'page-nav.nav-terminal-control-settings',
        requirePermission: 'screenControlSettings',
      },
      {
        route: '/pages/terminal_control/terminals',
        text: 'page-nav.nav-terminal-screen-management-terminals',
        requirePermission: 'screenControlTerminals',
      },
      {
        route: '/pages/terminal_control/profiles',
        text: 'page-nav.nav-terminal-screen-management-profiles',
        requirePermission: 'screenControlProfiles',
      },
      {
        route: '/pages/terminal_control/business-services',
        text: 'page-nav.nav-terminal-screen-management-business-services',
        requirePermission: 'screenControlBusinessServices',
      },
      {
        route: '/pages/terminal_control/backupButtons',
        text: 'page-nav.nav-backupButtons',
        requirePermission: 'backupButtons',
      },
      {
        route: '/pages/terminal_control/configuration',
        text: 'page-nav.nav-config-equipment',
        requirePermission: 'screenControlTerminalsConfig',
      },
      {
        route: '/pages/terminal_control/createTerminal',
        text: 'page-nav.nav-new-terminals',
        requirePermission: 'createTerminal',
      },
      {
        route: '/pages/terminal_control/subBrands',
        text: 'page-nav.nav-subBrandsTerminals',
        requirePermission: 'terminalsSubBrands',
      },
      {
        route: '/pages/terminal_control/previewApplication',
        text: 'page-nav.nav-previewApplication',
        requirePermission: 'previewApplication',
      },
    ],
  },

  {
    route: '/pages/stock/',
    text: 'page-nav.nav-ownerStockPhotos',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/stock/ownerStockAbout',
        text: 'page-nav.nav-ownerStockAbout',
        requirePermission: 'ownerStockGeneralResults',
      },
      {
        route: '/pages/stock/ownerStockGeneralResults',
        text: 'page-nav.nav-ownerStockGeneralResults',
        requirePermission: 'ownerStockGeneralResults',
      },
      {
        route: '/pages/stock/ownerStockPhotos',
        text: 'page-nav.nav-ownerStockPhotos',
        requirePermission: 'ownerStockPhotos',
      },
      {
        route: '/pages/stock/adminGeneralResults',
        text: 'page-nav.nav-adminGeneralResults',
        requirePermission: 'adminGeneralResults',
      },
      {
        route: '/pages/stock/adminStockPhotos',
        text: 'page-nav.nav-adminStockPhotos',
        requirePermission: 'adminStockPhotos',
      },
    ],
  },
  {
    route: '/pages/encashment',
    text: 'page-nav.nav-encashment',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/encashment/cashCollectors',
        text: 'page-nav.nav-cashcollectors',
        requirePermission: 'collectorsCashReport',
      },
      {
        route: '/pages/encashmentTerminals',
        text: 'page-nav.nav-collection',
        requirePermission: 'encashment',
      },
      {
        route: '/pages/encashmentAll',
        text: 'page.encashment.tab-allEncashments',
        requirePermission: 'encashment',
      },
      {
        route: '/pages/encashment/remainingTerminals',
        text: 'page.dashboard.tab-remainingTerminals',
        requirePermission: 'remainingTerminals',
      },
      // {
      //   route: '/pages/encashment/dispenserCollection',
      //   icon: 'lock',
      //   text: 'page.dispenserCollection.mainTab',
      //   requirePermission: 'dispenserCollection',
      // },
    ],
  },
  {
    route: '/pages/about',
    text: 'page-nav.nav-report',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/reports/service',
        text: 'page-nav.nav-reportServices',
        requirePermission: 'reportonservice',
      },
      {
        route: '/pages/reports/byPaymentTypes',
        text: 'page-nav.tab-reportByPaymentTypes',
        requirePermission: 'reportByPaymentTypes',
      },
      {
        route: '/pages/reports/day',
        text: 'page-nav.nav-reportDay',
        requirePermission: 'reportbyday',
      },
      {
        route: '/pages/reports/turnovers',
        text: 'page-nav.nav-reportTurn',
        requirePermission: 'reportTurnByTerminals',
      },
      {
        route: '/pages/reports/period',
        text: 'page-nav.nav-reportPeriod',
        requirePermission: 'reportPeriod',
      },
      {
        route: '/pages/reports/reportOperabilityPeriod',
        text: 'page-nav.nav-operabilityPeriod',
        requirePermission: 'terminalOperabilityPeriod',
      },
      {
        route: '/pages/reports/reportOperabilityDay',
        text: 'page-nav.nav-operabilityDay',
        requirePermission: 'terminalOperabilityDay',
      },
      {
        route: '/pages/reports/terminalMaintenance',
        text: 'page-nav.nav-terminalMaintenance',
        requirePermission: 'terminalMaintenance',
      },
      {
        route: '/pages/reports/cashDebt',
        text: 'page-nav.nav-cashdebt',
        requirePermission: 'CashDept',
      },
      {
        route: '/pages/reports/terminalsByServices',
        text: 'page-nav.nav-terminalsByServices',
        requirePermission: 'terminalsByServices',
      },
      {
        route: '/pages/reports/terminalsByDays',
        text: 'page-nav.nav-terminalsByDays',
        requirePermission: 'terminalsByDays',
      },
      {
        route: '/pages/reports/cashDebtByCollectors',
        text: 'page-nav.nav-cashDebtByCollectors',
        requirePermission: 'cashDebtByCollectors',
      },
      {
        route: '/pages/reports/fiscalizedTransactionsByDays',
        text: 'page-nav.nav-fiscalizedTransactionsDaysReport',
        requirePermission: 'fiscalizedTransactionsDaysReport',
      },
      {
        route: '/pages/reports/fiscalizedTransactionsByDaysByOwners',
        text: 'page-nav.nav-fiscalizedTransactionsOwnersDaysReport',
        requirePermission: 'fiscalizedTransactionsOwnersDaysReport',
      },
      {
        route: '/pages/reports/transactionsWithCashback',
        text: 'page-nav.nav-transactionsWithCashback',
        requirePermission: 'activitymonitor',
      },
    ],
  },
  {
    route: '/pages/account',
    text: 'page-nav.nav-manageAccounts',
    requirePermission: 'accountmanager',
  },
  {
    route: '/pages/nonRiskLimits',
    text: 'page-nav.nav-nonRiskLimits',
    requirePermission: 'nonRiskLimits',
  },
  {
    route: '/pages/transactions',
    text: 'page-nav.nav-viewTransactions',
    requirePermission: 'searchtransactions',
  },
  {
    route: '/pages/financialTransactions',
    text: 'page-nav.nav-viewFinancialTransactions',
    requirePermission: 'financialTransactions',
  },
  {
    route: '/pages/terminalsActiveServices',
    text: 'page-nav.nav-terminalsActiveServices',
    requirePermission: 'TerminalsActiveServices',
  },
  {
    route: '/pages/managementCommissions',
    text: 'page-nav.nav-managementExternalCommissions',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/managementCommissions/commission',
        text: 'page-nav.nav-commission',
        requirePermission: 'handlingCommissions',
      },
      {
        route: '/pages/managementCommissions/profiles',
        text: 'page-nav.nav-profiles',
        requirePermission: 'сommissionManagementProfiles',
      },
      {
        route: '/pages/managementCommissions/changingProfileHistory',
        text: 'page-nav.nav-profileChangeHistory',
        requirePermission: 'changingManagementCommissionProfileHistory',
      },
      {
        route: '/pages/managementCommissions/services',
        text: 'page-nav.nav-commissionsServices',
        requirePermission: 'monitoringCommissionServices',
      },
      {
        route: '/pages/managementCommissions/terminals',
        text: 'page-nav.nav-commissionsTerminals',
        requirePermission: 'ManagementCommissionTerminals',
      },
    ],
  },
  {
    route: '/pages/dictionaryManagement',
    text: 'page-nav.nav-dictionaryManagement',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/dictionaryManagement/authorizations',
        text: 'page-nav.nav-authorizationsDirectory',
        requirePermission: 'authorizationsDictionary',
      },
      {
        route: '/pages/dictionaryManagement/paymentTypes',
        text: 'page-nav.nav-paymentTypesDictionary',
        requirePermission: 'dictionaryPaymentTypes',
      },
      {
        route: '/pages/dictionaryManagement/technicalPartners',
        text: 'page-nav.nav-technicalPartners',
        requirePermission: 'technicalPartners',
      },
      {
        route: '/pages/dictionaryManagement/errorCodes',
        text: 'page-nav.nav-errorCodes',
        requirePermission: 'managementErrorCodes',
      },
      {
        route: '/pages/dictionaryManagement/MFO',
        text: 'page-nav.tab-MFODictionary',
        requirePermission: 'MFODictionary',
      },
      {
        route: '/pages/dictionaryManagement/remoteTerminalSettings',
        text: 'page-nav.tab-remoteTerminalSettings',
        requirePermission: 'remoteTerminalSettings',
      },
    ],
  },
  {
    route: '/pages/dictionary',
    text: 'page-nav.nav-directory',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/dictionary/clientCommissions',
        text: 'page-nav.nav-commissions',
        requirePermission: 'commissionClient',
      },
      {
        route: '/pages/dictionary/dealerCommissions',
        text: 'page-nav.nav-dealer-commissions',
        requirePermission: 'dealerCommissions',
      },
      {
        route: '/pages/dictionary/service',
        text: 'page-nav.nav-dictionary-services',
        requirePermission: 'dictionaryServices',
      },
      {
        route: '/pages/dictionary/whiteListAdmin',
        text: 'page-nav.nav-latWhiteList',
        requirePermission: 'whiteListAdmin',
      },
      {
        route: '/pages/dictionary/cities',
        text: 'page-nav.nav-cities',
        requirePermission: 'dictionaryCities',
      },
      {
        route: '/pages/dictionary/serviceLimits',
        text: 'page-nav.nav-serviceLimits',
        requirePermission: 'serviceLimits',
      },
    ],
  },
  {
    route: '/pages/serviceManagement',
    text: 'page-nav.nav-serviceManagement',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/serviceManagement/groupServices',
        text: 'page-nav.nav-groupServices',
        requirePermission: 'serviceGroupsDictionary',
      },
      {
        route: '/pages/serviceManagement/exceptionsFinMonLimit',
        text: 'page-nav.nav-exceptionsFinMonLimit',
        requirePermission: 'exceptionsFinMonLimit',
      },
      {
        route: '/pages/serviceManagement/services',
        icon: 'table',
        text: 'page-nav.nav-services',
        requirePermission: 'serviceManagement',
      },
      {
        route: '/pages/serviceManagement/paymentTypeNBUCode',
        text: 'page-nav.nav-paymentTypeNBUCode',
        requirePermission: 'NBUServicesRelations',
      },
      {
        route: '/pages/serviceManagement/recalculationTransactions',
        text: 'page-nav.nav-recalculationTransactions',
        requirePermission: 'recalculationTransactions',
      },
      {
        route: '/pages/serviceManagement/paymentTypeManagement',
        text: 'page-nav.nav-managementPaymentTypes',
        requirePermission: 'paymentTypesManagement',
      },
      {
        route: '/pages/serviceManagement/OTPServices',
        text: 'page-nav.nav-OTRServices',
        requirePermission: 'OTPServices',
      },
      {
        route: '/pages/serviceManagement/serviceSupplier',
        text: 'page-nav.nav-serviceSupplier',
        requirePermission: 'serviceSuppliers',
      },
      {
        route: '/pages/serviceManagement/beneficiaries',
        text: 'page-nav.nav-beneficiaries',
        requirePermission: 'beneficiaries',
      },
      {
        route: '/pages/serviceManagement/directors',
        text: 'page-nav.nav-directors',
        requirePermission: 'directors',
      },
    ],
  },
  {
    route: '/pages/managementMenu',
    text: 'page-nav.nav-managementMenu',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/managementMenu/menuProfiles',
        text: 'page-nav.nav-menuProfiles',
        requirePermission: 'managementMenuProfiles',
      },
      {
        route: '/pages/managementMenu/audit',
        text: 'page-nav.nav-menuAudit',
        requirePermission: 'menuAudit',
      },
      {
        route: '/pages/managementMenu/terminalProfiles',
        text: 'page-nav.nav-terminalProfiles',
        requirePermission: 'terminalProfiles',
      },
      {
        route: '/pages/managementMenu/elementPropertyDirectory',
        text: 'page-nav.nav-elementPropertyDirectory',
        requirePermission: 'menuElementPropDictionary',
      },
      {
        route: '/pages/managementMenu/navigationMenuProp',
        text: 'page-nav.nav-menuPropNavigation',
        requirePermission: 'navigationMenuProp',
      },
      {
        route: '/pages/managementMenu/navigationMenuElements',
        text: 'page-nav.nav-menuElementNavigation',
        requirePermission: 'managementMenuProfiles',
      },
    ],
  },
  {
    route: '/pages/checkTemplate',
    text: 'page-nav.nav-checkTemplate',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/checkTemplate/template',
        text: 'page-nav.nav-checkTemplate',
        requirePermission: 'manageTemplate',
      },
      {
        route: '/pages/checkTemplate/services',
        text: 'page-nav.nav-templateServices',
        requirePermission: 'servicesTemplate',
      },
      {
        route: '/pages/checkTemplate/terminals',
        text: 'page-nav.nav-templateTerminals',
        requirePermission: 'templateTerminals',
      },
    ],
  },
  {
    route: '/pages/dealerCommissions',
    text: 'page-nav.nav-commissionsDealer',
    requirePermission: 'ignore',
    children: [
      {
        route: '/pages/dealerCommissions/services',
        text: 'page-nav.nav-commissionsDealer-services',
        requirePermission: 'dealerCommissionsServices',
      },
      {
        route: '/pages/dealerCommissions/paymentTools',
        text: 'page-nav.nav-commissionsDealer-paymentTools',
        requirePermission: 'dealerCommissionsPaymentTools',
      },
      {
        route: '/pages/dealerCommissions/terminals',
        text: 'page-nav.nav-dealerCommissionsTerminals',
        requirePermission: 'dealerCommissionsTerminals',
      },
      {
        route: '/pages/dealerCommissions/expectedChanges',
        text: 'page-nav.nav-dealerCommissionsExpectedChanges',
        requirePermission: 'dealerCommissionsExpectedChanges',
      },
      {
        route: '/pages/dealerCommissions/history',
        text: 'page-nav.nav-dealerCommissionsHistory',
        requirePermission: 'dealerCommissionsHistory',
      },
    ],
  },
  {
    route: '/pages/servicesAuthorizationLimits',
    text: 'page-nav.nav-servicesAuthorizationLimits',
    requirePermission: 'authorizationServicesLimitsGrid',
  },
  {
    route: '/pages/users',
    text: 'page-nav.nav-users',
    requirePermission: 'users',
  },
  {
    route: '/pages/certificates',
    text: 'page-nav.nav-certificates',
    requirePermission: 'certificates',
  },
  {
    route: '/pages/tech-inform',
    text: 'page-nav.nav-techInform',
    requirePermission: 'tech-inform',
  },
  {
    route: '/pages/messages',
    text: 'page-nav.nav-messages',
    requirePermission: 'messages',
  },
  {
    route: '/pages/adding-address-payment',
    text: 'page-nav.nav-adding-addressed-payment',
    requirePermission: 'adding-addressed-payment',
  },
  {
    route: '/pages/suppliersBalance',
    text: 'page-nav.nav-suppliersBalance',
    requirePermission: 'suppliersBalance',
  },
  {
    route: '/pages/manageMerchantCallbacks',
    text: 'page-nav.nav-manageMerchantCallbacks',
    requirePermission: 'manageMerchantCallbacksGrid',
  },
  {
    route: '/pages/cardRouting',
    text: 'page-nav.nav-routingManagement',
    requirePermission: 'cardRouting',
  },
  {
    route: '/pages/partnerInfoSender',
    text: 'page-nav.nav-partnerInfoSender',
    requirePermission: 'partnerInfoSender',
  },
  {
    route: '/pages/contacts',
    text: 'page-nav.nav-contacts',
    requirePermission: 'ignore',
  },
];

/*
{
    route: '/pages/about',
    icon: 'cloud-server',
    text: 'Кошелёк'
  }
*/
