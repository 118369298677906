import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import { ApiService, StorageService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import CellMessagesStatus from './cellMessagesStatus';
import CellThemeMessage from './cellThemeMessage';
import './gridMessages.scss';

const api = new ApiService();

const WrappedCellMessagesStatus = (props) => <CellMessagesStatus {...props} />;

const WrappedCellThemeMessage = (props) => <CellThemeMessage {...props} />;

class GridMessages extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('messages');
  }
  state = {
    isLoading: false,
    data: [],
    initialSort: { field: 'DatePost', dir: 'desc' },
    currentOwner: null,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const userInfo = StorageService.getItem('userInfo');
    try {
      this.toggleLoader(true);
      const data = await api.ownersMessagesList();
      const request = await api.ownersMessagesSetUnRead();
      const { changeUnreadedCount } = this.props;

      if (!userInfo.OwnerId && userInfo.OwnerId !== 0) {
        this.setState({ currentOwner: null });
      } else {
        const response = await api.owners();
        this.setState({
          currentOwner:
            response && response.length ? response.find(({ OwnerId }) => OwnerId === userInfo.OwnerId) || null : null,
        });
      }

      this.setState({ data }, () => changeUnreadedCount(0));
      return request;
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    const { currentOwner } = this.state;

    const currentOwnerName = currentOwner ? currentOwner.OwnerName : '';
    const str = currentOwnerName.split('-').reverse().join(' - ');
    return (
      <Col className="messages-h2" xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <h2>{str}</h2>
      </Col>
    );
  };

  statusToColor = (value) => {
    switch (value) {
      case 1:
      case 2:
        return 'bold';
      default:
        return 'normal';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.StatusId;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value);
    const style = { ...elementStyle, fontWeight: `${color}` };
    return React.cloneElement(element, { style }, children);
  };

  renderOnClick = (props) => {
    if (checkElement('create-tab-singleMessage', this.currentArray))
      return tabService.addTab({
        type: 'singleMessage',
        dataItem: props.dataItem,
      });
    else return null;
  };

  render() {
    const { isLoading, data, initialSort } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          rowRender={this.rowRender}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="messagesList"
          permissionName="messages"
          selectedField="selected"
          initialSort={initialSort}
        >
          <GridColumn field="MessageId" title="grids.messages.column-messageId" width="180" filter="numeric" />

          <GridColumn
            field="Subject"
            title="grids.messages.column-subject"
            width="400"
            // // // cell={CellBoldMessages}
            // onClick={this.renderOnClick}
            cell={WrappedCellThemeMessage}
          />

          <GridColumn
            field="DatePost"
            title="grids.messages.column-datePost"
            width="270"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
            // cell={CellBoldMessages}
          />

          <GridColumn
            field="StatusName"
            title="grids.messages.column-statusName"
            width="270"
            // cell={CellBoldMessages}
            cell={WrappedCellMessagesStatus}
          />
        </Grid>
      </>
    );
  }
}

export default withTranslate(GridMessages);
