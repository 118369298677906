import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import {
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
} from '../../../core/constants/configs.const';
import Grid from '../../../components/grids/baseGrid';
import CellBoolean from '../../account/cellBoolean';
import dealerCommissionsHistoryService from './DealerCommissionsHistory.service';
import AlertModal from './AlertModal';
import { getTransformDateString } from '../../../utils/getTransformDateString';

import './DealerCommissionsHistoryGrid.scss';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class DealerCommissionsHistoryGrid extends PureComponent {
  currentArray = findArray('dealerCommissionsHistory');
  state = {
    isLoading: false,
    owners: [],
    selectedOwners: [],
    services: [],
    selectedServices: [],
    paymentTypes: [],
    selectedPaymentTypes: [],
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
    error: '',
    history: [],
    showAlertModal: false,
  };

  componentDidMount() {
    this.initializeFetch();
  }

  initializeFetch = async () => {
    await this.fetchOwners();
    await this.fetchPaymentTypes();
    await this.fetchServices();
  };

  fetchOwners = async () => {
    try {
      this.setState({ isLoading: true });
      const owners = await api.owners();

      this.setState({
        owners: DropdownNormalizersService.normalizeOwners(owners),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchServices = async () => {
    try {
      this.setState({ isLoading: true });
      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      this.setState({ services: DropdownNormalizersService.normalizeServices(services), isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchPaymentTypes = async () => {
    try {
      this.setState({ isLoading: true });
      const paymentTypes = await api.getPaymentTypeList();

      this.setState({
        paymentTypes: DropdownNormalizersService.normalizePaymentTypes(paymentTypes),
        selectedPaymentTypes: [1],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getSelectCallbackByName = (name) => (selectItems) => {
    this.setState({ [name]: selectItems, history: [] });
  };

  checkErrorParams = () => {
    const { selectedServices, selectedOwners, selectedPaymentTypes } = this.state;

    if (selectedOwners.length > 1 && selectedOwners.length < 11) {
      return selectedServices.length > 200 && selectedPaymentTypes.length !== 1;
    }

    if (selectedOwners.length > 10) {
      return selectedServices.length > 10;
    }

    return false;
  };

  calcDisabled = () => {
    const { selectedServices, selectedOwners, selectedPaymentTypes, error } = this.state;

    return !selectedServices.length || !selectedOwners.length || !selectedPaymentTypes.length || error;
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          error: dealerCommissionsHistoryService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const {
      selectedServices,
      services,
      selectedOwners,
      owners,
      paymentTypes,
      selectedPaymentTypes,
      dateStart,
      dateEnd,
      error,
    } = this.state;

    return (
      <Row className="DealerCommissionsHistoryGrid-toolbar">
        <div className="DealerCommissionsHistoryGrid-fields">
          <div className="DealerCommissionsHistoryGrid-selects">
            <div className="DealerCommissionsHistoryGrid-fieldRow">
              <div className="DealerCommissionsHistoryGrid-label">{translate('page.commissionsServices.dealer')}</div>

              <div className="DealerCommissionsHistoryGrid-select">
                <GridDropdown
                  data={owners}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={selectedOwners}
                  onSave={this.getSelectCallbackByName('selectedOwners')}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  isGetDefaultSelectItemsFromStorage
                />
              </div>
            </div>

            <div className="DealerCommissionsHistoryGrid-fieldRow">
              <div className="DealerCommissionsHistoryGrid-label">
                {translate('page.commissionsServices.paymentType')}
              </div>

              <div className="DealerCommissionsHistoryGrid-select">
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                  selectItems={selectedPaymentTypes}
                  onSave={this.getSelectCallbackByName('selectedPaymentTypes')}
                />
              </div>
            </div>

            <div className="DealerCommissionsHistoryGrid-fieldRow">
              <div className="DealerCommissionsHistoryGrid-label">{translate('page.commissionsServices.service')}</div>

              <div className="DealerCommissionsHistoryGrid-select">
                <GridDropdown
                  data={services}
                  colConfig={SERVICES_COLUMN_CONFIG}
                  selectItems={selectedServices}
                  onSave={this.getSelectCallbackByName('selectedServices')}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                />
              </div>
            </div>
          </div>

          <div className="DealerCommissionsHistoryGrid-dataFields">
            <div className="DealerCommissionsHistoryGrid-fieldRow">
              <div className="DealerCommissionsHistoryGrid-label">
                {translate('page.changingProfileHistory.dateFrom')}
              </div>
              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={dealerCommissionsHistoryService.getMinimumSelectedDate()}
              />
            </div>

            <div className="DealerCommissionsHistoryGrid-fieldRow">
              <div className="DealerCommissionsHistoryGrid-label">
                {translate('page.changingProfileHistory.dateTo')}
              </div>
              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={dealerCommissionsHistoryService.getMinimumSelectedDate()}
              />
            </div>
          </div>

          {error && <div className="DealerCommissionsHistoryGrid-error">{error}</div>}
        </div>

        <div className="DealerCommissionsHistoryGrid-searchAction">
          <Button type="primary" disabled={this.calcDisabled()} onClick={this.fetchHistory}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>
      </Row>
    );
  };

  fetchHistory = async () => {
    const { selectedServices, selectedOwners, selectedPaymentTypes, dateStart, dateEnd } = this.state;

    if (this.calcDisabled()) {
      return;
    }

    if (this.checkErrorParams()) {
      this.setState({ showAlertModal: true });
      return;
    }

    try {
      this.setState({ isLoading: true });
      const history = await api.getServiceCommissionsHistoryList({
        owners: selectedOwners,
        paymentTypes: selectedPaymentTypes,
        services: selectedServices,
        dateFrom: getDataByTimezoneOffset(dateStart).toISOString(),
        dateTo: getDataByTimezoneOffset(dateEnd).toISOString(),
      });

      this.setState({ history: history || [], isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { history, isLoading, showAlertModal } = this.state;

    if (!checkElement('dealerCommissionsHistory-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={history}
          name="dealerCommissionsHistoryGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchHistory}
          isLoading={isLoading}
        >
          <GridColumn field="OwnerId" title="page.workDealers.ownerId" width="110" filter="numeric" />
          <GridColumn field="OwnerName" title="grids.dealerCommissionServices.column-dealerName" width="240" />
          <GridColumn field="ServiceId" title="grids.services.column-service-id" width="110" filter="numeric" />
          <GridColumn field="ServiceName" title="page.commissionsServices.col-serviceName" width="240" />
          <GridColumn field="ServiceGroupName" title="grids.services.column-service-group" width="150" />
          <GridColumn field="SupplierName" title="grids.dealerCommissionServices.column-supplier" width="200" />
          <GridColumn field="ServiceRule" title="page.serviceMainProps.serviceDescription" width="200" />
          <GridColumn
            field="DateStart"
            title="grids.dealerCommissionServices.column-commission-start-date"
            width="200"
            cell={({ dataItem: { DateStart } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateStart)}</td>
            )}
            formatFilterCellData={(DateStart) => getTransformDateString(DateStart)}
          />
          <GridColumn
            field="DateEnd"
            title="page.dealerCommissionsHistory.col-commissionEndDate"
            width="220"
            cell={({ dataItem: { DateEnd } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateEnd)}</td>
            )}
            formatFilterCellData={(DateEnd) => getTransformDateString(DateEnd)}
          />
          <GridColumn
            field="TransitService"
            title="grids.dealerCommissionServices.column-transit-feature"
            width="110"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="СommissionDealer"
            title="grids.dealerCommissionsChange.column-dealer-commission"
            width="250"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="MinСommissionDealer"
            title="grids.dealerCommissionsChange.column-dealer-commission-min"
            width="250"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="MaxСommissionDealer"
            title="grids.dealerCommissionsChange.column-dealer-commission-max"
            width="250"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="DealerCommissionClient"
            title="grids.dealerCommissionServices.column-commission-client"
            width="240"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="DealerСommissionClientMin"
            title="grids.dealerCommissionServices.column-commission-client-min"
            width="240"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="DealerСommissionClientMax"
            title="grids.dealerCommissionServices.column-commission-client-max"
            width="240"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemСommission"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission"
            width="250px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemСommissionMin"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission-min"
            width="250px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemСommissionMax"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission-max"
            width="250px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemCommissionClient"
            title="grids.dealerCommissionServices.column-ps-commission-client"
            width="270px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMin"
            title="grids.dealerCommissionServices.column-ps-commission-client-min"
            width="270px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMax"
            title="grids.dealerCommissionServices.column-ps-commission-client-max"
            width="270px"
            className="DealerCommissionsServices-centredCell"
            filter="numeric"
          />
          <GridColumn field="PaymentType" title="page.commissionsServices.col-paymentType" width="170" />
          <GridColumn
            field="DatePost"
            title="grids.dealerCommissionServices.column-commission-change-date"
            width="250px"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn field="UserPost" title="grids.dealerCommissions.column-executor" width="150" />
          <GridColumn
            field="ServiceStatusName"
            title="grids.dealerCommissionServices.column-service-status-dealer"
            width="250px"
          />
        </Grid>

        {showAlertModal && (
          <AlertModal
            visible={showAlertModal}
            closeModal={() => {
              this.setState({ showAlertModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(DealerCommissionsHistoryGrid);
