class CommissionDetailsFormService {
  getCommissionName = (formData, isFormDisabled) => {
    const { ResultFixed, ResultPercent, AmountFrom, AmountTo, CommissionMin, CommissionMax, Priority, CommissionName } =
      formData;

    if (isFormDisabled) {
      return CommissionName;
    }

    return `${ResultFixed || ''}${ResultFixed && ResultPercent ? '+' : ''}${
      ResultPercent ? `${ResultPercent}%` : ''
    }(${AmountFrom} - ${AmountTo})${ResultPercent ? `[${CommissionMin} - ${CommissionMax}]` : ''}${
      Priority === 50 ? '' : Priority
    }`;
  };
}

export default new CommissionDetailsFormService();
