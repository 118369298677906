import React from 'react';
import Input from '../../../components/Input';
import Validator from './validator';

export const renderInputWithLabel = (
  formState,
  onChangeFormState,
  formErrors,
  onError,
  label,
  fieldName,
  minValue = -99999.9999,
  maxValue = 99999.9999,
  translate,
) => {
  return (
    <>
      <div className="RecalculationTransactions-row RecalculationTransactions-row--nowrapp" key={fieldName}>
        <div className="RecalculationTransactionsCustom-label">
          <label>{translate(label)} </label>
        </div>
        <div className="RecalculationTransactionsCustom-input">
          <Input
            type="text"
            formState={formState}
            onChangeFormState={(name, value) => {
              onChangeFormState(name, value);
            }}
            formErrors={formErrors}
            setError={onError}
            validators={Validator.getValidators(translate, formState, fieldName)}
            name={fieldName}
            min={minValue}
            max={maxValue}
            key={fieldName}
          />
        </div>
      </div>
    </>
  );
};
export const renderGlobalError = (formErrors, key) => {
  return formErrors[key] && <div className="RecalculationTransactions-globalError">{formErrors[key]}</div>;
};
