import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import DealerCommissionsServicesContainer from './DealerCommissionsServicesContainer';
import CopyDealerCommissions from './CopyDealerCommissions/CopyDealerCommissions';
import ChangeDealerCommissions from './ChangeDealerCommissions';

class DealerCommissionsServicesPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.dashboard.tab-dealerCommissions-services',
        content: <DealerCommissionsServicesContainer />,
        closable: false,
        key: 0,
      },
    ],
  };

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'copyCommissions':
        tabs.push({
          title: 'page.dashboard.tab-dealerCommissions-copy',
          content: <CopyDealerCommissions />,
          closable: true,
          key: key,
        });
        break;
      case 'changeCommissions':
        const dataItemChangeCommissions = dataItem.flatMap(({ OwnerId, ServiceId, ServiceStatusId }) => ({
          OwnerId,
          ServiceId,
          ServiceStatusId,
        }));
        tabs.push({
          title: 'page.dashboard.tab-dealerCommissions-change',
          content: <ChangeDealerCommissions dataItem={dataItemChangeCommissions} />,
          closable: true,
          key: key,
        });
        break;

      default:
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}

export default withTranslate(DealerCommissionsServicesPage);
