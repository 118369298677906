import { SERVICES_COLUMN_CONFIG, AUTHORIZATION_TYPE_COLUMN_CONFIG, CODE_NBU_COLUMN_CONFIG } from '../constants';

export const translateSlugs = {
  services: 'page.commissionsServices.service',
  nbuCodes: 'page.servicesAuthorizationLimits.col-nbuCode',
  authorizationTypes: 'page.servicesAuthorizationLimits.authorizationType',
  transactionMaxLimit: 'page.servicesAuthorizationLimits.col-transactionLimit',
  dailyLimit: 'page.servicesAuthorizationLimits.col-dailyLimit',
  monthlyLimit: 'page.servicesAuthorizationLimits.col-monthlyLimit',
  transactionMinLimit: 'page.servicesAuthorizationLimits.col-amountMin',
  finMonRecipient: 'page.servicesAuthorizationLimits.col-finMonRecipient',
  finMonPayer: 'page.servicesAuthorizationLimits.col-finMonPayer',
  dailyTransactionsCountLimit: 'page.servicesAuthorizationLimits.col-dailyTransactionsCountLimit',
  ownAccount: 'page.servicesAuthorizationLimits.col-ownAccount',
  checkLimitPayer: 'page.servicesAuthorizationLimits.col-checkLimitPayer',
  transactionMaxLimitPayer: 'page.servicesAuthorizationLimits.col-transactionLimit',
  dailyLimitPayer: 'page.servicesAuthorizationLimits.col-dailyLimit',
  monthlyLimitPayer: 'page.servicesAuthorizationLimits.col-monthlyLimit',
  monthlyLimitWithoutOtp: 'page.servicesAuthorizationLimits.col-monthlyLimitWithoutOtp',
};

export const configs = {
  services: SERVICES_COLUMN_CONFIG,
  nbuCodes: CODE_NBU_COLUMN_CONFIG,
  authorizationTypes: AUTHORIZATION_TYPE_COLUMN_CONFIG,
};

export const defaultTitleTranslateSlugs = {
  services: 'page.commissionsServices.chooseService',
  nbuCodes: 'page.servicesAuthorizationLimits.selectCode',
  authorizationTypes: 'page.servicesAuthorizationLimits.selectAuthorizationType',
};
