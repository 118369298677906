import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import { BASE_SERVICES_COLUMN_CONFIG } from '../../../../core/constants/configs.const';

const api = new ApiService();

class CloneForServiceModal extends PureComponent {
  state = {
    isLoading: false,
    selectServicesList: [],
    error: '',
    servicesList: [],
  };

  componentDidMount() {
    this.fetchServiceList();
  }

  fetchServiceList = async () => {
    await this.setState({ isLoading: true });

    try {
      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      this.setState({
        servicesList: DropdownNormalizersService.normalizeServices(services),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  onDropdownSave = (selectServicesList) => {
    const { currentServiceId, translate } = this.props;
    this.setState({ selectServicesList }, () => {
      this.setState({
        error: selectServicesList[0] === currentServiceId ? translate('page.additionalServiceProps.unableCopy') : '',
      });
    });
  };

  clonePropsFromService = async () => {
    const { selectServicesList } = this.state;
    const { currentServiceId, translate, onRefresh, closeModal } = this.props;
    try {
      await this.setState({ isLoading: true });

      await api.cloneAdditionalPropertyService({
        serviceIdClone: selectServicesList[0],
        serviceId: currentServiceId,
      });

      await this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(translate(`page.additionalServiceProps.cloningWasSuccessful`), 2);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, closeModal, visible } = this.props;
    const { isLoading, selectServicesList, error, servicesList } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        className="UpdatePropsModal"
        title={translate(`page.additionalServiceProps.cloningByService`)}
        footer={[
          <Button
            type="primary"
            key="submit"
            disabled={!selectServicesList.length || error}
            onClick={this.clonePropsFromService}
          >
            {translate('page.additionalServiceProps.clone')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="UpdatePropsModal-row">
          <div className="UpdatePropsModal-label">{translate('page.additionalServiceProps.col-name')}</div>
          <div className="UpdatePropsModal-select">
            <GridDropdown
              data={servicesList}
              colConfig={BASE_SERVICES_COLUMN_CONFIG}
              selectItems={selectServicesList}
              onSave={this.onDropdownSave}
              defaultTitle={translate('page.commissionsServices.chooseService')}
              isSingle
            />
          </div>
        </div>
        {error && <div className="UpdatePropsModal-error">{error}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(CloneForServiceModal);
