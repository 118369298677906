import {
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
  MANAGER_COLUMN_CONFIG,
} from '../../../../core/constants/configs.const';

const SERVICE_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.serviceMainProps.typeService',
    width: '140',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.serviceMainProps.col-description',
    width: '200',
  },
  {
    fieldName: 'ProviderName',
    titleTranslateSlug: 'page.serviceMainProps.col-provider',
    width: '140',
  },
];

const PROVIDER_COLUMN_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.serviceMainProps.col-provider',
    width: '300',
    isShowOnMobile: true,
  },
];

export const CONFIGS = {
  serviceType: SERVICE_TYPES_COLUMN_CONFIG,
  provider: PROVIDER_COLUMN_CONFIG,
  transitService: OWNERS_COLUMN_CONFIG,
  paymentTool: PAYMENT_TYPES_COLUMN_CONFIG,
  basicService: SERVICES_COLUMN_CONFIG,
  categoryManager: MANAGER_COLUMN_CONFIG,
  supportManager: MANAGER_COLUMN_CONFIG,
  engagementManager: MANAGER_COLUMN_CONFIG,
};

export const translateSlugs = {
  serviceId: 'page.serviceMainProps.serviceNumber',
  serviceName: 'page.serviceMainProps.nameService',
  serviceDescription: 'page.serviceMainProps.serviceDescription',
  commissionRules: 'page.serviceMainProps.commissionRules',
  limitType: 'page.serviceMainProps.restrictionsTypes',
  serviceType: 'page.serviceMainProps.typeService',
  provider: 'page.serviceMainProps.col-provider',
  status: 'page.serviceMainProps.status',
  transitService: 'page.serviceMainProps.transitService',
  sum: 'page.serviceMainProps.sum',
  minSum: 'page.serviceMainProps.minimumAmount',
  maxSum: 'page.serviceMainProps.maximumAmount',
  orgSum: 'page.serviceMainProps.originalSum',
  isClientCommission: 'page.serviceMainProps.clientCommission',
  isAddressPayment: 'page.serviceMainProps.addressPayment',
  unlockingPeriod: 'page.serviceMainProps.unlockingPeriod',
  isRiskService: 'page.serviceMainProps.riskService',
  paymentTool: 'page.serviceMainProps.paymentTool',
  bankDetails: 'page.serviceMainProps.bankDetails',
  basicService: 'page.serviceMainProps.basicService',
  categoryManager: 'page.serviceMainProps.categoryManager',
  supportManager: 'page.serviceMainProps.supportManager',
  engagementManager: 'page.serviceMainProps.engagementManager',
  dateChange: 'page.serviceMainProps.dateChange',
  createDate: 'page.serviceMainProps.createDate',
};

export const defaultTitleTranslateSlugs = {
  limitType: 'page.serviceMainProps.chooseTypeLimit',
  serviceType: 'page.serviceMainProps.selectTypeService',
  provider: 'page.serviceMainProps.chooseProvider',
  status: 'page.serviceMainProps.chooseStatus',
  transitService: 'page.dealerCommissions.selectDealer',
  paymentTool: 'page.commissionsServices.choosePaymentType',
  basicService: 'page.commissionsServices.chooseService',
  categoryManager: 'page.serviceMainProps.chooseManager',
  supportManager: 'page.serviceMainProps.chooseManager',
  engagementManager: 'page.serviceMainProps.chooseManager',
};

export const requiredFields = [
  {
    field: 'serviceName',
  },
  {
    field: 'commissionRules',
  },
  {
    field: 'serviceType',
    selectType: true,
  },
  {
    field: 'provider',
    selectType: true,
  },
  {
    field: 'status',
    selectType: true,
  },
  {
    field: 'transitService',
    selectType: true,
    dependedField: 'isTransitServiceActive',
  },
  {
    field: 'unlockingPeriod',
    dependedField: 'isRiskService',
  },
  {
    field: 'sum',
  },
  {
    field: 'minSum',
  },
  {
    field: 'maxSum',
  },
  {
    field: 'orgSum',
  },
  {
    field: 'bankDetails',
    selectType: true,
  },
  {
    field: 'basicService',
    selectType: true,
    dependedField: 'isBasicServiceAllowed',
  },
];

export const RiskErrorMessage =
  'Увага! Даний сервіс вже додано для налаштування правил "Неризикові денний/нічний ліміти". Даний сервіс видалений з переліку сервісів налаштування ліміту неризикових сервісів (Неризикові сервіси для ліміту).';
