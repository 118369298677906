export const statuses = [
  { title: 'grids.terminals.check-active', checked: true, value: 'активный', id: 1 },
  { title: 'grids.terminals.check-arrested', checked: true, value: 'арестован', id: 5 },
  { title: 'grids.terminals.check-inactive', checked: true, value: 'неактивный', id: 2 },
  { title: 'grids.terminals.check-faulty', checked: true, value: 'неисправен', id: 6 },
  { title: 'grids.terminals.check-disconnected', checked: true, value: 'отключен', id: 4 },
  { title: 'grids.terminals.check-tested', checked: true, value: 'тестируется', id: 3 },
  { title: 'grids.terminals.check-removed', checked: true, value: 'удален', id: 7 },
  { title: 'grids.terminals.check-abducted', checked: true, value: 'похищен', id: 8 },
];
