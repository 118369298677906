import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import Grid from '../../../components/grids/baseGrid';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import './NonRiskLimitsGrid.scss';
import { ApiService, tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import SetLimitsDialog from './SetLimitsDialog';
import FormStateContainer from '../../../components/FormStateContainer';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class NonRiskLimitsGrid extends PureComponent {
  currentArray = findArray('nonRiskLimits');

  state = {
    owners: [],
    selectOwners: [],
    selectedItems: [],
    terminalList: [],
    isLoading: false,
    isSetLimitsDialog: false,
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const owners = await api.owners();

      await this.setState(
        {
          owners: owners && owners.length ? owners : [],
          isLoading: false,
        },
        () => {
          this.getTerminalList();
        },
      );
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  getTerminalList = async () => {
    const { selectOwners } = this.state;

    if (!selectOwners.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const terminalList = await api.terminalsBlockingRules(selectOwners);

      await this.setState({
        terminalList: terminalList && terminalList.length ? terminalList : [],
      });
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onOwners = (selectOwners) => {
    this.setState({ selectOwners }, this.getTerminalList);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectOwners, owners, selectedItems } = this.state;

    return (
      <Row className="NonRiskLimitsGrid-toolbar">
        <Col span={6} md={2} xl={1}>
          {translate('page.commissionsServices.dealer')}
        </Col>
        <Col span={17} md={11}>
          <GridDropdown
            data={DropdownNormalizersService.normalizeNonDeleteOwners(owners)}
            colConfig={OWNERS_COLUMN_CONFIG}
            selectItems={selectOwners}
            onSave={this.onOwners}
            isGetDefaultSelectItemsFromStorage
          />
        </Col>
        <Col span={24} className="NonRiskLimitsGrid-actionsPanel">
          {checkElement('nonRiskLimits-buttonChange', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ isSetLimitsDialog: true });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('nonRiskLimits-nonRiskServicesTabButtonLink', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab('nonRiskServices');
              }}
            >
              {translate('page.nonRiskLimitsGrid.tab-nonRiskServices')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { terminalList, isLoading, isSetLimitsDialog, selectedItems } = this.state;

    if (!checkElement('nonRiskLimits-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={terminalList}
          onRefresh={this.getTerminalList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectionChange}
          name="NonRiskLimitsGrid"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="TerminalId"
            title="grids.commission-terminals.column-terminal"
            width="120"
            filter="numeric"
          />

          <GridColumn
            field="OwnerId"
            title="grids.commission-terminals.column-numberDealer"
            width="120"
            filter="numeric"
          />

          <GridColumn field="OwnerName" title="grids.commission-terminals.column-dealer" width="120" />

          <GridColumn field="StatusName" title="grids.commission-terminals.column-status" width="150" />

          <GridColumn field="LimitTurns" title="page.nonRiskLimitsGrid.col-dailyLimit" width="150" filter="numeric" />

          <GridColumn
            field="LimitTurnsNight"
            title="page.nonRiskLimitsGrid.col-nightLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="NightTimeStart"
            title="page.nonRiskLimitsGrid.col-nightTimeWith"
            width="150"
            filter="date"
            format="HH:mm"
          />

          <GridColumn
            field="NightTimeEnd"
            title="page.nonRiskLimitsGrid.col-nightTimeTo"
            width="150"
            filter="date"
            format="HH:mm"
          />
        </Grid>
        {isSetLimitsDialog && (
          <FormStateContainer
            initialValues={{
              dailyLimit: null,
              nightlyLimit: null,
              nightTimeStart: null,
              nightTimeEnd: null,
            }}
          >
            <SetLimitsDialog
              visible={isSetLimitsDialog}
              closeModal={() => {
                this.setState({ isSetLimitsDialog: false });
              }}
              terminalList={selectedItems.map(({ TerminalId }) => TerminalId)}
              getTerminalList={this.getTerminalList}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(NonRiskLimitsGrid);
