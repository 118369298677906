const commission = (translate, value) => {
  switch (value) {
    case 'clientComissionsByServices-Active':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-active')}`;
    case 'clientComissionsByServices-CommissionName':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-comission-name')}`;
    case 'clientComissionsByServices-commissionNameOnclick':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.comissions.comission')}`;
    case 'clientComissionsByServices-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-date')}`;
    case 'clientComissionsByServices-Priority':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-priority')}`;
    case 'clientComissionsByServices-Rules':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-rule')}`;
    case 'clientComissionsByServices-select-profile':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.comission-services.profile-title')}`;
    case 'clientComissionsByServices-ServiceId':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-serviceId')}`;
    case 'clientComissionsByServices-ServiceName':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-service-name')}`;
    case 'clientComissionsByServices-ValidDateFrom':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-date-start')}`;
    case 'clientComissionsByServices-ValidDateTo':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.comission-services.column-date-end')}`;
    case 'clientComissionsByServices-PaymentTypeName':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.comissions.services')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-paymentType')}`;
    case 'clientComissionsByTerminals-OwnerId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-numberDealer')}`;
    case 'clientComissionsByTerminals-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dealer')}`;
    case 'clientComissionsByTerminals-Address':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-address')}`;
    case 'clientComissionsByTerminals-change-profile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.commission.btn-toggle')}`;
    case 'clientComissionsByTerminals-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-city')}`;
    case 'clientComissionsByTerminals-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dateLastConnect')}`;
    case 'clientComissionsByTerminals-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dateLastTransaction')}`;
    case 'clientComissionsByTerminals-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-date')}`;
    case 'clientComissionsByTerminals-ProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-profile')}`;
    case 'clientComissionsByTerminals-profileNameOnclick':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.comissions.services')}`;
    case 'clientComissionsByTerminals-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-region')}`;
    case 'clientComissionsByTerminals-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'clientComissionsByTerminals-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-status')}`;
    case 'clientComissionsByTerminals-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-terminal')}`;
    case 'clientComissionsByTerminals-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-name')}`;
    case 'clientComissionsByTerminals-TypeName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-type')}`;
    case 'clientComissionsByTerminals-UserNamePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-executor')}`;
    case 'clientComissionsByTerminals-BrandName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.subBrandsTerminals.col-brand')}`;
    case 'clientComissionsByTerminals-IsChangeCommissionEnabled':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.subBrandsTerminals.col-changeProfile')}`;
    default:
      return value;
  }
};

export default commission;
