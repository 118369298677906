import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import aes from 'crypto-js/aes';
import { enc } from 'crypto-js/core';

import { withTranslate } from '../../contexts/localContext';
import { widthExpiredPasswordDialogContext } from '../../contexts/ExpiredPasswordDialogContext';
import { ApiService, StorageService } from '../../services';
import ModalConfirmDeviceCode from './modalConfirmDeviceCode';
import LoadingPanel from '../../components/loader';
import menus from '../menus';

import './login.scss';
import { createHash } from '../../utils/createHash';

const api = new ApiService();

// initialization of ant sub components
const { Item } = Form;

class DialogLogin extends PureComponent {
  static propTypes = {
    onAction: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    loading: false,
    isVisibleModalConfirm: false,
    isLoading: false,
  };

  componentDidMount() {
    // Если есть DeviceCode то выполнить запрос на получение токена;
    const DeviceCode = StorageService.getItem('devideCode');
    const isPermittedAutoLogin = StorageService.getItem('isPermittedAutoLogin');

    if (DeviceCode && isPermittedAutoLogin) {
      const bytes = aes.decrypt(DeviceCode, '123456789');
      const originalCode = bytes.toString(enc.Utf8).replace(/['"«»]/g, '');
      this.onInitRequest(originalCode);
    }
  }

  handleNumberDraysBeforePasswordExpires = (numberDraysBeforePasswordExpires) => {
    const { setNumberDraysBeforePasswordExpires } = this.props;

    setNumberDraysBeforePasswordExpires(
      numberDraysBeforePasswordExpires === 0 ||
        (numberDraysBeforePasswordExpires &&
          0 < numberDraysBeforePasswordExpires &&
          numberDraysBeforePasswordExpires < 6)
        ? numberDraysBeforePasswordExpires
        : null,
    );
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onInitRequest = async (DeviceCode) => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const model = {
        Elements: [],
      };
      const modelDevice = {
        grant_type: 'password',
        DeviceCode,
      };
      const res = await api.tokenDeviceCode(modelDevice);

      if (res) {
        this.handleNumberDraysBeforePasswordExpires(res.NumbeDraysBeforePasswordExpires);
        StorageService.setItem('access_token', res.accessToken);
        StorageService.setItem('refresh_token', res.refreshToken);
        StorageService.setItem('userInfo', res);
      }

      const backEndConfig = await api.getElementsFromBack(model);
      StorageService.setItem('backConfig', backEndConfig);
      this.handleNavigation();
    } catch (error) {
      if (error.Message !== 'TRUSTED_DEVICE_NOT_USE_LIGHT_LOGIN') {
        StorageService.removeItem('devideCode');
      }

      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  // mounted = false;

  checkMobilePhone = async () => {
    try {
      this.toggleLoader();
      await api.usersTrustedDevicesAdd();
      this.setState(({ isVisibleModalConfirm }) => {
        return {
          isVisibleModalConfirm: !isVisibleModalConfirm,
        };
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader();
      StorageService.removeItem('access_token');
    }
  };

  toggleModalConfirmVisibility = async () => {
    const DeviceCode = StorageService.getItem('devideCode');
    if (DeviceCode === null) {
      await this.checkMobilePhone();
    } else this.handleNavigation();
  };

  closeModalConfirm = () => {
    this.setState({ isVisibleModalConfirm: false });
  };

  handleNavigation = () => {
    const { handleNavigate, navigate } = this.props;

    const currentUserInfo = StorageService.getItem('userInfo');
    if (
      currentUserInfo.userPermissionsList.length === 1 &&
      currentUserInfo.userPermissionsList[0].webTag === 'tech-inform'
    ) {
      navigate('/pages/tech-inform');
    } else {
      handleNavigate();
    }
  };

  setSpinner = (value) => {
    this.setState({ loading: value });
  };

  handleLoginSubmit = (e) => {
    const { form, showError, history, setNumberDraysBeforePasswordExpires } = this.props;

    e.preventDefault();
    form.validateFields(async (err, values) => {
      const { username, password } = values;

      if (err) {
        console.log('Received error from form: ', err);
        return;
      }
      try {
        this.setSpinner(true);
        const model = {
          Elements: [],
        };
        let res = await api.token(values.username, values.password);

        if (res) {
          StorageService.setItem('access_token', res.accessToken);
          StorageService.setItem('refresh_token', res.refreshToken);
          StorageService.setItem('userInfo', res);
          this.handleNumberDraysBeforePasswordExpires(res.NumbeDraysBeforePasswordExpires);
        }

        const backEndConfig = await api.getElementsFromBack(model);
        StorageService.setItem('backConfig', backEndConfig);

        this.toggleModalConfirmVisibility();
      } catch (error) {
        const isMessageExist = error && error.response && error.response.data && error.response.data.Message;

        if (isMessageExist && error.response.data.Message === 'PASSWORD_EXPIRED_ERROR') {
          setNumberDraysBeforePasswordExpires(
            -1,
            `${history.location.pathname}?login=${username}&oldPassword=${createHash(password)}`,
          );

          return;
        }

        showError(error);
      } finally {
        this.setSpinner(false);
      }
    });
  };

  render = () => {
    const { form, onAction, translate } = this.props;
    const { loading, isVisibleModalConfirm, isLoading } = this.state;
    const { getFieldDecorator } = form;
    return (
      <>
        <h2>{translate('login.login')}</h2>

        <Form className="dialog-login-wrapper" onSubmit={this.handleLoginSubmit}>
          <Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: translate('errorMessages.pleaseInputYourUsername') }],
            })(<Input prefix={<span className="k-icon k-i-user" />} placeholder={translate('login.username')} />)}
          </Item>
          <Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: translate('errorMessages.pleaseInputYourPassword') }],
            })(
              <Input
                prefix={<span className="k-icon k-i-lock" />}
                type="password"
                placeholder={translate('login.password')}
              />,
            )}
          </Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {translate('login.sign_in')}
          </Button>
          <Button type="link" onClick={onAction}>
            {translate('login.forgot_pass')}
          </Button>
        </Form>

        {isVisibleModalConfirm && (
          <ModalConfirmDeviceCode
            visible={isVisibleModalConfirm}
            closeModal={this.closeModalConfirm}
            handleNavigation={this.handleNavigation}
          />
        )}
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}

export default withRouter(
  widthExpiredPasswordDialogContext(withTranslate(Form.create({ name: 'login-form' })(DialogLogin))),
);
