import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Checkbox } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { getTransformDateString } from '../../../utils/getTransformDateString';

class CommissionActionResults extends PureComponent {
  setColorError = (value) => (value !== null ? `rgba(255, 0, 0, 0.3)` : ``);

  rowRender = (element, props) => {
    const value = props.dataItem.Error;

    const { style: elementStyle, children } = element.props;
    const color = this.setColorError(value);
    const style = { background: `${color}`, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  render() {
    const { commissionActionResults, paymentTypes } = this.props;

    return (
      <Grid
        data={commissionActionResults}
        name="commissionActionResults-grid"
        rowRender={this.rowRender}
        toolbar={<></>}
      >
        <GridColumn field="Action" title="page.commissionsServices.col-action" width="110" />
        <GridColumn field="ProfileName" title="page.commissionsServices.col-commissionProfile" width="180" />
        <GridColumn field="ServiceId" title="page.commissionsServices.col-serviceNumber" width="110" />
        <GridColumn field="ServiceName" title="page.commissionsServices.col-serviceName" width="240" />
        <GridColumn
          field="PaymentType"
          title="page.commissionsServices.col-paymentType"
          width="170"
          cell={({ dataItem: { PaymentType } }) => (
            <td style={{ textAlign: 'center' }}>
              {paymentTypes.find(({ PaymentTypeId }) => PaymentTypeId === PaymentType).PaymentType}
            </td>
          )}
          formatFilterCellData={(PaymentType) =>
            paymentTypes.find(({ PaymentTypeId }) => PaymentTypeId === PaymentType).PaymentType
          }
        />
        <GridColumn field="CommissionId" title="page.commissionsServices.col-commissionNumber" width="150" />
        <GridColumn field="Name" title="page.commissionsServices.col-commissionName" width="240" />
        <GridColumn
          field="DateFrom"
          title="page.commissionsServices.col-beginningDate"
          width="180"
          cell={({ dataItem: { DateFrom } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DateFrom)}</td>
          )}
          formatFilterCellData={(DateFrom) => getTransformDateString(DateFrom)}
        />
        <GridColumn
          field="DateTo"
          title="page.commissionsServices.col-expirationDate"
          width="180"
          cell={({ dataItem: { DateTo } }) => <td style={{ textAlign: 'center' }}>{getTransformDateString(DateTo)}</td>}
          formatFilterCellData={(DateTo) => getTransformDateString(DateTo)}
        />
        <GridColumn
          field="Active"
          title="page.commissionsServices.col-active"
          width="110"
          cell={({ dataItem: { Active } }) => (
            <td style={{ textAlign: 'center' }}>
              <Checkbox defaultChecked={Active} checked={Active} disabled style={{ marginTop: '4px' }} />
            </td>
          )}
        />
        <GridColumn field="RuleName" title="page.commissionsServices.col-rule" width="110" />
        <GridColumn field="Error" title="page.commissionsServices.col-mistake" width="110" />
        <GridColumn
          field="DatePost"
          title="page.commissionsServices.col-addedDate"
          width="160"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
      </Grid>
    );
  }
}

export default CommissionActionResults;
