const reportTurnByTerminals = (translate, value) => {
  switch (value) {
    case 'reportTurnTerminals-Address':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-address')}`;
    case 'reportTurnTerminals-AmountTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-amountTotal')}`;
    case 'reportTurnTerminals-AvgPayment':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-avgPayment')}`;
    case 'reportTurnTerminals-btn-submit':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'reportTurnTerminals-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-сityName')}`;
    case 'reportTurnTerminals-CommissionAll':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-сommissionAll')}`;
    case 'reportTurnTerminals-CommissionClient':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-сommissionClient')}`;
    case 'reportTurnTerminals-CommissionProvider':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-сommissionProvider')}`;
    case 'reportTurnTerminals-ManagerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-managerName')}`;
    case 'reportTurnTerminals-QuantityTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-quantityTotal')}`;
    case 'reportTurnTerminals-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-regionName')}`;
    case 'reportTurnTerminals-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-terminalId')}`;
    case 'reportTurnTerminals-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.reportTurnByTerminals.column-terminalName')}`;
    case 'reportTurnTerminals-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminals.column-branch')}`;
    case 'reportTurnTerminals-Note':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.terminalSettings.field-notes')}`;
    case 'reportTurnTerminals-NFCCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.serviceReport.column-paymentCountWithNfc')}`;
    case 'reportTurnTerminals-NFCAmount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.serviceReport.column-paymentAmountWithNfc')}`;
    default:
      return value;
  }
};

export default reportTurnByTerminals;
