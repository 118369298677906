const dashboard = (translate, value) => {
  switch (value) {
    case 'dashboard-terminals-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.terminals')}`;
    case 'dashboard-encashmentTerminals-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.collection')}`;
    case 'dashboard-turnoversYesterday-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.turnoversYesterday')}`;
    case 'dashboard-turnoversToday-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.turnoversToday')}`;
    case 'dashboard-cash-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.cash')}`;
    case 'dashboard-account-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.account')}`;
    case 'dashboard-account-collectionEnrollmentSum':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.account')},
      ${translate('page.adminPanel.type-field')}: ${translate('page.main.enrolCollection')}`;
    case 'dashboard-blockedTransactions-block':
      return `${translate('page.adminPanel.type-block')}: ${translate('page.main.blockedTransactions')}`;
    default:
      return value;
  }
};

export default dashboard;
