import React, { PureComponent } from 'react';
import { Modal, Button, Col, message } from 'antd';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';

const api = new ApiService();

class ModalUnholdTransaction extends PureComponent {
  constructor(props) {
    super(props);
    const {
      item: { TransactionId },
    } = this.props;
    if (!TransactionId) return;
    this.state = {
      loading: false,
      transactionId: TransactionId,
      visible: false,
    };
  }

  toggleModal = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleOk = async () => {
    const { transactionId } = this.state;
    const { translate } = this.props;
    if (!transactionId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TransactionId: transactionId,
      };
      const response = await api.unholdTransaction(model);
      this.setState({ loading: false, visible: false });
      message.success(
        `${translate('grids.transactionDeatails.transaction')} № ${transactionId} ${translate(
          'grids.transactionDeatails.unhold',
        )}`,
        1.5,
      );
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render = () => {
    const { loading, visible, transactionId } = this.state;
    const { translate } = this.props;
    return (
      <>
        <Button onClick={this.toggleModal}>{translate('grids.transactionDeatails.btn-unblock')}</Button>
        <Modal
          title={translate('grids.transactionDeatails.unhold-title')}
          visible={visible}
          centered
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {translate('grids.transactionDeatails.unhold-cancel')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              {translate('grids.transactionDeatails.btn-unblock')}
            </Button>,
          ]}
        >
          <Col md={24}>
            <p>№: {transactionId}</p>
          </Col>
        </Modal>
      </>
    );
  };
}

export default withTranslate(ModalUnholdTransaction);
