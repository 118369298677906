import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import Grid from '../../../components/grids/baseGrid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

import DateRange from '../../../components/daterange';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

import './GridReportDay.styled.scss';

const api = new ApiService();

const aggregate = [
  { field: 'TerminalCount', aggregate: 'average' },
  { field: 'QuantityTotal', aggregate: 'sum' },
  { field: 'AmountTotal', aggregate: 'sum' },
  { field: 'TerminalAvgTurn', aggregate: 'average' },
  { field: 'TerminalAvgProfit', aggregate: 'average' },
  { field: 'TerminalAvgPaymentCount', aggregate: 'average' },
  { field: 'AvgPayment', aggregate: 'average' },
  { field: 'CommissionProvider', aggregate: 'sum' },
  { field: 'CommissionClient', aggregate: 'sum' },
  {
    field: 'Commission',
    aggregate: 'sum',
  },
];

class GridReportDay extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('reportbyday');
  }

  state = {
    data: [],
    isLoading: false,
    ownerId: [],
    range: undefined,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getAverageSum = (data) =>
    data && data.length
      ? data.map((obj) => ({
          ...obj,
          Commission: +obj.CommissionProvider + +obj.CommissionClient,
        }))
      : [];

  makeQuery = async () => {
    const { range, ownerId } = this.state;
    if (!range || !ownerId) return;
    try {
      this.toggleLoader(true);
      const model = {
        OwnerId: ownerId[0],
        DateStart: range.from,
        DateEnd: range.to,
      };
      const data = await api.dayReport(model);
      this.setState({ data: this.getAverageSum(data) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  onOwner = (ownerId) => {
    // ownerId = +ownerId.join('');
    this.setState({ ownerId }, this.makeQuery);
  };

  onRange = (range) => {
    this.setState({ range });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, ownerId } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridReportDay">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              selectItems={ownerId}
              onSave={this.onOwner}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isSingle
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange onRange={this.onRange} />
          </Col>
          {checkElement('dayReport-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery}>
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        aggregate={aggregate}
        rowRender={this.rowRender}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="dayReport"
        permissionName="reportbyday"
      >
        <GridColumn
          field="WorkDate"
          title="grids.dayReport.column-date"
          width="300"
          filter="date"
          format="dd.MM.yyyy"
        />

        <GridColumn
          field="TerminalCount"
          title="grids.dayReport.column-terminals"
          width="145"
          filter="numeric"
          format="{0:n0}"
        />

        <GridColumn
          field="QuantityTotal"
          title="grids.dayReport.column-payments"
          width="145"
          filter="numeric"
          format="{0:n0}"
        />

        <GridColumn
          field="AmountTotal"
          title="grids.dayReport.column-turn"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="TerminalAvgTurn"
          title="grids.dayReport.column-avgTurn"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="TerminalAvgProfit"
          title="grids.dayReport.column-revenue"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="TerminalAvgPaymentCount"
          title="grids.dayReport.column-payCount"
          width="170"
          filter="numeric"
          format="{0:n0}"
        />

        <GridColumn
          field="AvgPayment"
          title="grids.dayReport.column-pay"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionProvider"
          title="grids.dayReport.column-owners"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionClient"
          title="grids.dayReport.column-clients"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="Commission"
          title="grids.dayReport.column-commission"
          width="145px"
          filter="numeric"
          format="{0:n2}"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridReportDay);
