/* eslint-disable no-unused-vars */
/*
 * задизейблил потому что
 * в определенных случаях нужно
 * посылать строку в запросе
 * а в других случаях объект Date
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Select } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { withTranslate } from '../../contexts/localContext';
import './dateRange.scss';
import { getCustomDate } from '../../utils/getCustomDate';
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

const periods = ['today', 'yesterday', 'lastweek', 'lastmonth', 'quarter', 'custom'];

// https://medium.com/@frontman/алтернатива-switch-case-в-js-php-8a8b9e110c2e
/**
 * Преобразование периода в дни
 */
const offsetToNumber = (typeOffset) =>
  ({
    today: 0,
    yesterday: 1,
    lastweek: 7,
    lastmonth: 30,
    quarter: 90,
    custom: -1,
  }[typeOffset] || 0);

const { Option } = Select;

const getDayOffset = (day, phase = true) => {
  let dt = new Date();
  dt.setTime(dt.getTime() - day * 3600 * 1000 * 24);

  if (phase) dt.setHours(0, 0, 0, 0);
  else dt.setHours(23, 59, 59, 999);

  return dt;
};

class DateRange extends Component {
  static defaultProps = {
    blocked: false,
    showDropdown: true,
  };

  static propTypes = {
    onRange: PropTypes.func.isRequired,
  };

  min = new Date(2007, 0, 1);

  max = new Date(2025, 11, 31);

  minDate = new Date().setHours(0, 0, 0, 0);

  maxDate = new Date().setHours(23, 59, 59, 999);

  constructor(props) {
    super(props);

    const range = {
      start: getCustomDate(),
      end: new Date(this.maxDate),
      from: convertToISOFormat(new Date(this.minDate)),
      to: convertToISOFormat(new Date(this.maxDate)),
    };

    this.state = { range, dropboxValue: periods[0] };
  }

  componentDidMount() {
    // onShow ???
    const { range } = this.state;
    const { onRange } = this.props;
    onRange(range);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dropboxValue } = this.state;
    if (prevState.dropboxValue !== dropboxValue) {
      this.dateDropBoxHandle(dropboxValue);
    }
  }

  /*
   * В зависимости от вида запроса
   * если нужен state: передается параметром onRange(start, end)
   * если нужен state отформатированный: передается параметром onRange(from, to)
   */
  onStart = (e) => {
    const { range } = this.state;
    const { onRange } = this.props;

    const start = e.target.value;
    range.start = start;
    range.from = convertToISOFormat(start);
    this.setState(range);

    onRange(range);
  };

  /*
   * В зависимости от вида запроса
   * если нужен state: передается параметром объект onRange(start, end)
   * если нужен state отформатированный: передается параметром объект onRange(from, to)
   */

  onEnd = (e) => {
    const { range } = this.state;
    const { onRange } = this.props;

    const end = e.target.value;
    range.end = end;
    range.to = convertToISOFormat(end);
    this.setState(range);

    onRange(range);
  };

  onDateDropBoxChange = (value) => {
    this.setState({ dropboxValue: value });
  };

  dateDropBoxHandle = (value) => {
    const { onRange } = this.props;
    const { range } = this.state;

    const offset = offsetToNumber(value);
    if (offset === -1) return;

    range.start = getDayOffset(offset);
    range.from = convertToISOFormat(range.start);
    range.end = getDayOffset(0, false);
    range.to = convertToISOFormat(range.end);

    this.setState({ range });
    onRange(range);
  };

  formatRender = () => {
    const { shortFormat } = this.props;
    if (shortFormat) {
      return 'yyyy.MM.dd';
    }
    return 'yyyy.MM.dd HH:mm:ss';
  };

  render() {
    const {
      range: { start, end },
      dropboxValue,
    } = this.state;
    const { popup, translate, showDropdown, minStartDate, maxStartDate, minEndDate, maxEndDate, isBase } = this.props;
    return (
      <div className={`date-range-wrapper ${popup && 'popup'}`}>
        <div className="date-ranger start">
          {showDropdown && (
            <Select value={dropboxValue} onChange={this.onDateDropBoxChange}>
              <Option value="today">{translate('daterange.today')}</Option>
              <Option value="yesterday">{translate('daterange.yesterday')}</Option>
              <Option value="lastweek">{translate('daterange.lastweek')}</Option>
              {!isBase && <Option value="lastmonth">{translate('daterange.lastmonth')}</Option>}
              {!isBase && <Option value="quarter">{translate('daterange.quarter')}</Option>}
              {!isBase && <Option value="custom">{translate('daterange.custom')}</Option>}
            </Select>
          )}
          {!isBase && (
            <DatePicker
              className="date-picker"
              format={this.formatRender()}
              onClickOutside={false}
              value={start}
              onChange={this.onStart}
              min={minStartDate || this.min}
              max={maxStartDate || this.max}
            />
          )}
        </div>

        {!isBase && (
          <div className="date-ranger end">
            <DatePicker
              className="date-picker"
              value={end}
              format={this.formatRender()}
              onChange={this.onEnd}
              min={minEndDate || this.min}
              max={maxEndDate || this.max}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslate(DateRange);
