import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import RecalculationTransactions from './ReacalculationTransactions';
import { message } from 'antd';
import { ApiService } from '../../../services';
import { formatDate } from '@telerik/kendo-intl';
import LoadingPanel from '../../../components/loader';
import { getCustomDate } from '../../../utils/getCustomDate';

import DataContext, { dataReducer } from './DataContext';
import { RESET_FIELDS_DEALER_COMMISSIONS } from './constants';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class RecalculationTransactionsContainer extends PureComponent {
  state = {
    allOwners: [],
    services: [],
    paymentTypes: [],
    paymentTypesName: null,
    selectOwners: [1],
    selectServices: [],
    selectPaymentTypes: [],
    dataForGrid: [],
    isLoading: false,
    visible: false,
    isEnabled: true,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  handleToggleModal = (value) => {
    this.setState({ visible: value });
  };

  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const allOwners = await api.owners();
      await this.setState({ allOwners });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };
  fetchServicesList = async () => {
    const { selectOwners } = this.state;
    try {
      await this.toggleLoader(true);
      const model = {
        ownerId: selectOwners,
      };
      const services = await api.getServiceDictionary(model);
      this.setState({ services });
      await this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(error);
    }
  };

  fetchPaymentType = async () => {
    try {
      this.toggleLoader(true);
      const paymentTypes = await api.getPaymentTypeList();
      await this.setState({ paymentTypes });
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.toggleLoader(false);
    }
  };

  transactionsRecalculateEnabledCheck = async () => {
    try {
      await this.toggleLoader(true);
      const isEnabled = await api.checkTransactionsRecalculateEnabled();
      await this.setState({ isEnabled });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };
  getIdByPaymentTypeNameGrid = (name) => {
    const { paymentTypes } = this.state;

    return paymentTypes.reduce((acc, { PaymentType, PaymentTypeId }) => {
      return PaymentType === name ? PaymentTypeId : acc;
    }, []);
  };

  getISODate = (date, isDateFrom) => {
    const normalizedDate = new Date(date);

    return getDataByTimezoneOffset(
      getCustomDate({
        customTime: isDateFrom ? undefined : { h: 23, m: 59, s: 59, ms: 59 },
        customDayOfMonth: normalizedDate.getDate(),
        customDateMonth: normalizedDate.getMonth(),
        customDateFullYear: normalizedDate.getFullYear(),
      }),
    ).toISOString();
  };

  recalculationDealerTransaction = async () => {
    const { selectOwners, selectServices, dataForGrid } = this.state;
    const { formState } = this.props;
    for (let index = 0; index < dataForGrid.length; index++) {
      const {
        PaymentType,
        DealerProviderPercent,
        DealerProviderMin,
        DealerProviderMax,
        DealerClientPercent,
        DealerClientMin,
        DealerClientMax,
        PaymentSystemProviderPercent,
        PaymentSystemProviderMin,
        PaymentSystemProviderMax,
        PaymentSystemClientPercent,
        PaymentSystemClientMin,
        PaymentSystemClientMax,
      } = dataForGrid[index];

      const model = {
        DateFrom: this.getISODate(formState.DealerCommissionsDateStart, true),
        DateTo: this.getISODate(formState.DealerCommissionsDateEnd),
        ServiceId: selectServices[0],
        OwnersList: selectOwners,
        PaymentType: this.getIdByPaymentTypeNameGrid(PaymentType),
        DealerProviderPercent,
        DealerProviderMin,
        DealerProviderMax,
        DealerClientPercent,
        DealerClientMin,
        DealerClientMax,
        PaymentSystemProviderPercent,
        PaymentSystemProviderMin,
        PaymentSystemProviderMax,
        PaymentSystemClientPercent,
        PaymentSystemClientMin,
        PaymentSystemClientMax,
      };

      try {
        await api.recalculateDealerCommissions(model);
      } catch (e) {
        const { showError } = this.props;
        await this.toggleLoader(false);
        showError(e);
      } finally {
        await this.setState({ dataForGrid: [] });
      }
    }
  };

  recalculateSupplierCommissions = async () => {
    const { selectServices } = this.state;
    const {
      formState: {
        DealerCommissionsDateStart,
        DealerCommissionsDateEnd,
        SuppliersProviderAmountTotal,
        SuppliersProviderAmountTotalForCards,
        SuppliersProviderMax,
        SuppliersProviderMaxForCards,
        SuppliersProviderMin,
        SuppliersProviderMinForCards,
        SuppliersProviderPercent,
        SuppliersProviderPercentForCards,
      },
    } = this.props;
    try {
      const model = {
        DateFrom: this.getISODate(DealerCommissionsDateStart, true),
        DateTo: this.getISODate(DealerCommissionsDateEnd),
        serviceId: selectServices[0],
        supplierProviderAmountTotal: SuppliersProviderAmountTotal,
        supplierProviderAmountTotalForCards: SuppliersProviderAmountTotalForCards,
        supplierProviderMax: +SuppliersProviderMax,
        supplierProviderMaxForCards: +SuppliersProviderMaxForCards,
        supplierProviderMin: +SuppliersProviderMin,
        supplierProviderMinForCards: +SuppliersProviderMinForCards,
        supplierProviderPercent: +SuppliersProviderPercent,
        supplierProviderPercentForCards: +SuppliersProviderPercentForCards,
      };
      await api.recalculateSupplierCommissions(model);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  recalculateSideOperations = async (isOnlyDealerRecalculation) => {
    const { selectServices, selectOwners } = this.state;
    const {
      formState: { DealerCommissionsDateStart, DealerCommissionsDateEnd },
    } = this.props;

    try {
      await api.recalculateTransactions({
        DateFrom: this.getISODate(DealerCommissionsDateStart, true),
        DateTo: this.getISODate(DealerCommissionsDateEnd),
        ServiceId: selectServices[0],
        OwnersList: isOnlyDealerRecalculation ? selectOwners : [0],
      });

      await api.recalculateReports({
        DateFrom: this.getISODate(DealerCommissionsDateStart, true),
        DateTo: this.getISODate(DealerCommissionsDateEnd),
      });

      await api.recalculateOwnersAccounts({
        DateFrom: this.getISODate(DealerCommissionsDateStart, true),
        DateTo: this.getISODate(DealerCommissionsDateEnd),
        OwnersList: isOnlyDealerRecalculation ? selectOwners : [0],
      });
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  onHandleChangeItems = (name) => (value) => {
    this.setState({ [name]: value }, () => {
      if (name === 'selectPaymentTypes') {
        this.getPaymentTypeNameById(value);
      }
    });
  };

  getPaymentTypeNameById = (paymentTypeIds) => {
    const { paymentTypes } = this.state;
    if (paymentTypeIds.length) {
      const paymentTypesName = paymentTypes.reduce((acc, paymentType) => {
        if (paymentTypeIds.includes(paymentType.PaymentTypeId)) {
          return [...acc, paymentType.PaymentType];
        }
        return acc;
      }, []);
      this.setState({ paymentTypesName });
    }
  };

  fetchRequestData = async () => {
    await this.fetchOwners();
    await this.fetchServicesList();
    await this.fetchPaymentType();
    await this.transactionsRecalculateEnabledCheck();
  };

  componentDidMount() {
    this.fetchRequestData();
  }

  dispatch = (action) => {
    this.setState((prevState) => dataReducer(prevState, action));
  };

  upDateGrid = async (formData) => {
    const { paymentTypesName, dataForGrid } = this.state;
    const { setFormState, formState } = this.props;

    try {
      if (paymentTypesName === null) return;
      await this.toggleLoader(true);
      await this.setState(({ prevDataForGrid }) => {
        return {
          dataForGrid: [
            ...dataForGrid.filter(
              ({ PaymentType: prevPaymentType }) =>
                !paymentTypesName.some((PaymentType) => PaymentType === prevPaymentType),
            ),
            ...paymentTypesName.map((PaymentType) => ({ PaymentType, ...formData })),
          ],
        };
      });
      await this.toggleLoader(false);
      await this.handleToggleModal(false);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    } finally {
      this.setState({ selectPaymentTypes: [] });
      setFormState({
        ...formState,
        ...RESET_FIELDS_DEALER_COMMISSIONS,
      });
    }
  };

  render() {
    const {
      allOwners,
      services,
      paymentTypes,
      paymentTypesName,
      selectOwners,
      selectServices,
      selectPaymentTypes,
      dataForGrid,
      visible,
      isLoading,
      isEnabled,
    } = this.state;
    const { formState, onChangeFormState, setFormState, formErrors, onError, setErrorState } = this.props;

    return (
      <>
        <DataContext.Provider value={{ dataForGrid, dispatch: this.dispatch, upDateGrid: this.upDateGrid }}>
          <RecalculationTransactions
            allOwners={allOwners}
            services={services}
            paymentTypes={paymentTypes}
            paymentTypesName={paymentTypesName}
            selectOwners={selectOwners}
            selectServices={selectServices}
            selectPaymentTypes={selectPaymentTypes}
            toggleLoader={this.toggleLoader}
            onHandleChangeItems={this.onHandleChangeItems}
            handleToggleModal={this.handleToggleModal}
            recalculationDealerTransaction={this.recalculationDealerTransaction}
            recalculateSupplierCommissions={this.recalculateSupplierCommissions}
            formState={formState}
            setFormState={setFormState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            onError={onError}
            setErrorState={setErrorState}
            visible={visible}
            clearDataForGrid={this.clearDataForGrid}
            isEnabled={isEnabled}
            recalculateSideOperations={this.recalculateSideOperations}
          />
        </DataContext.Provider>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(RecalculationTransactionsContainer);
