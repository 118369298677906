import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../contexts/localContext';
import { Button, Col, message, Modal } from 'antd';
import Grid from '../../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService } from '../../../../services';
import ModalGroupServices from './ModalGroupServices/ModalGroupServices';

import './GroupServices.scss';
import findArray from '../../../../utils/findArrForCurrentPage';
import checkElement from '../../../../utils/checkElement';

const api = new ApiService();

class GroupServices extends PureComponent {
  currentArray = findArray('serviceGroupsDictionary');

  state = {
    dataGrid: [],
    selectedItems: [],
    modal: '',
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchGroupServices = async () => {
    try {
      await this.toggleLoader(true);
      const dataGrid = await api.getServicesGroups();
      this.setState({ dataGrid });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(e);
    }
  };

  componentDidMount() {
    this.fetchGroupServices();
  }

  onHandleSelected = (selectedItems) => {
    this.setState({ selectedItems });
  };

  toggleModal = (name) => {
    this.setState({ modal: name });
  };

  onHandleDelete = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.toggleLoader(true);
      await api.servicesGroupsDelete([selectedItems[0].GroupId]);
      this.toggleModal('');
      message.success(`${translate('page.modalGroupServices.deletedSuccessfully')}`, 2.5);
      await this.fetchGroupServices();
    } catch (e) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(e);
    }
  };
  handleClick = (e) => {
    const { name } = e.target;

    switch (name) {
      case 'add':
        return this.toggleModal('add');
      case 'change':
        return this.toggleModal('change');
      case 'delete':
        return this.toggleModal('delete');
      default:
        return;
    }
  };

  buildToolbar = () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;
    return (
      <Col className="GroupServices-toolbar">
        <Button.Group className="GroupServices-btnGroup" onClick={this.handleClick}>
          {checkElement('serviceGroupsDictionary-addItem', this.currentArray) && (
            <Button name="add" type="primary">
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('serviceGroupsDictionary-editItem', this.currentArray) && (
            <Button name="change" type="primary" disabled={!selectedItems.length}>
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('serviceGroupsDictionary-deleteItem', this.currentArray) && (
            <Button name="delete" type="primary" disabled={!selectedItems.length}>
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </Button.Group>
      </Col>
    );
  };

  render() {
    const { translate } = this.props;
    const { dataGrid, modal, isLoading, selectedItems } = this.state;

    if (!checkElement('serviceGroupsDictionary-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={dataGrid}
          name="GroupServicesGrid"
          toolbar={this.buildToolbar()}
          fieldForSelection="GroupId"
          onSelect={this.onHandleSelected}
          onRefresh={this.fetchGroupServices}
          isLoading={isLoading}
        >
          <GridColumn field="selected" columnMenu={false} filterable={false} width="50px" />
          <GridColumn field="GroupId" title="grids.groupServices.column-group" width="150px" filter="numeric" />
          <GridColumn field="Name" title="grids.groupServices.column-name" width="480px" />
          <GridColumn field="Description" title="grids.groupServices.column-description" width="640px" />
          <GridColumn
            field="DatePost"
            title="grids.groupServices.column-date-change"
            width="180px"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        </Grid>
        {(modal === 'add' || modal === 'change') && (
          <ModalGroupServices
            groups={dataGrid}
            visible={modal.length}
            name={modal}
            onClose={this.toggleModal}
            selectedItem={modal === 'change' ? selectedItems[0] : null}
            isLoading={isLoading}
            toggleLoader={this.toggleLoader}
            onRefresh={this.fetchGroupServices}
          />
        )}

        {modal === 'delete' && (
          <Modal
            title={translate('page.modalGroupServices.modal-title-delete')}
            visible={modal.length}
            onCancel={() => this.toggleModal('')}
            footer={[
              <Button type="danger" onClick={this.onHandleDelete}>
                {translate('core.buttonTitles.delete')}
              </Button>,
              <Button type="primary" onClick={() => this.toggleModal('')}>
                {translate('core.buttonTitles.refuse')}
              </Button>,
            ]}
          >
            <div className="GroupServices-deleteModalContent">
              {translate('page.modalGroupServices.modal-delete-content')}
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslate(GroupServices);
