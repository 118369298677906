import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import FiscalizedTransactionsDaysReportGrid from './FiscalizedTransactionsDaysReportGrid';
import PageTabs from '../../components/tabber/tabber';

class FiscalizedTransactionsDaysReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-fiscalizedTransactionsDaysReport',
          content: <FiscalizedTransactionsDaysReportGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default withTranslate(FiscalizedTransactionsDaysReport);
