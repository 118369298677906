class OTPServicesGridService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.servicesOtp) {
      return { terminalListActivity: [], hasNextPage: false, hasPreviousPage: false };
    }
    const NBUCodes = data.data.servicesOtp;
    const pageInfo = NBUCodes.pageInfo;

    const resultNBUCodes =
      NBUCodes.items && NBUCodes.items.length
        ? NBUCodes.items.map((service) => ({ ...service, editId: service.id }))
        : [];

    return {
      NBUCodes: resultNBUCodes,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new OTPServicesGridService();
