import React, { PureComponent } from 'react';

import { AllElementsGrid } from './ReleasesGrid';
import PageTabs from '../../components/tabber/tabber';
import RolesGrid from './RolesGrid/RolesGrid';
import AccessRoles from './ReleasesMatrix/UserAccessGrid';
import AccessRolesGrid from './ReleasesMatrix/AccessRolesGrid';

class AllElements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-ElementsOfAllReleases',
          content: <AllElementsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'choosingRoles':
        tabs.push({
          title: 'page.releases.choosingElements',
          content: <RolesGrid {...dataItem} />,
          closable: true,
          key: key,
        });
        break;

      case 'userAccess':
        tabs.push({
          title: 'page.releases.access-users',
          content: <AccessRoles {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'rolesAccess':
        tabs.push({
          title: 'page.releases.access-role',
          content: <AccessRolesGrid {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default AllElements;
