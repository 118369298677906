import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../contexts/localContext';
import CellDropdown from '../grids/cells/cellDropdown';
import { tabService } from '../../services';
import { widthUpdateMenuProfileContext } from './UpdateMenuProfileContext';
import { getTransformDateString } from '../../utils/getTransformDateString';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { Checkbox } from 'antd';

const getCustomCellCheckbox =
  (updateSelected) =>
  ({ dataItem }) => {
    return (
      <td>
        <Checkbox
          checked={dataItem.selected}
          onChange={() => {
            updateSelected(dataItem);
          }}
        />
      </td>
    );
  };

const _ = require('lodash');

const DetailComponent = (dataItem = {}, handleChangeCurrentLayer) =>
  !!dataItem.details && !!dataItem.details.length
    ? withTranslate(widthUpdateMenuProfileContext(MenuProfilesGrid))({
        data: dataItem.details,
        handleChangeCurrentLayer,
      })
    : null;

class MenuProfilesGrid extends Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.data, this.props.data);
  }

  expandChange = ({ dataItem }) => {
    const { data, handleChangeCurrentLayer } = this.props;

    handleChangeCurrentLayer([
      ...data.map((item) => (item.profileId === dataItem.profileId ? { ...item, expanded: !dataItem.expanded } : item)),
    ]);
  };

  getHandleChangeCurrentLayer = (rootDataItem, data) => (newLayer) => {
    const { handleChangeCurrentLayer: routeHandleChangeCurrentLayer } = this.props;

    routeHandleChangeCurrentLayer([
      ...data.map((item) => (item.profileId === rootDataItem.profileId ? { ...item, details: newLayer } : item)),
    ]);
  };

  handleInsert = ({ dataItem: { name: currentProfileName, profileId, parentHistorySlug } }) => {
    const {
      handleSetProfileStaffForUpdating,
      handleChangeCurrentLayer: routeHandleChangeCurrentLayer,
      data,
    } = this.props;

    handleSetProfileStaffForUpdating({
      variant: 'insertProfile',
      currentProfileName,
      profileId,
      onSuccessfulUpdateCallback: (newProfile) => {
        const normalizedProfile = newProfile
          ? Object.entries(newProfile).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [lowerCaseFirstLetter(key)]: value,
                parentHistorySlug: `${parentHistorySlug},${profileId}`,
              }),
              {},
            )
          : {};

        routeHandleChangeCurrentLayer([
          ...data.map((item) =>
            item.profileId === profileId
              ? {
                  ...item,
                  details: item.details ? [...item.details, normalizedProfile] : [normalizedProfile],
                  expanded: true,
                }
              : item,
          ),
        ]);
      },
    });
  };

  getHandlers = (isChildExist) => {
    const {
      handleSetProfileStaffForUpdating,
      handleUpdateConfiguration,
      handleChangeCurrentLayer: routeHandleChangeCurrentLayer,
      data,
      permissions: {
        updateProfileName,
        addProfile,
        deleteProfile,
        updateProfileConfiguration,
        updateProfilesChildsConfiguration,
        redirectElementTab,
      },
    } = this.props;

    return [
      {
        title: 'page.profilesMenu.btn-change-name',
        action: ({ dataItem: { name: currentProfileName, profileId } }) => {
          handleSetProfileStaffForUpdating({
            variant: 'changeName',
            currentProfileName,
            profileId,
            onSuccessfulUpdateCallback: (newProfileName) => {
              routeHandleChangeCurrentLayer([
                ...data.map((item) => (item.profileId === profileId ? { ...item, name: newProfileName } : item)),
              ]);
            },
          });
        },
        show: updateProfileName,
      },
      {
        title: 'page.profilesMenu.btn-add-profile',
        action: this.handleInsert,
        show: addProfile,
      },
      {
        title: 'page.profilesMenu.btn-delete-profile',
        action: ({ dataItem: { name: currentProfileName, profileId } }) => {
          handleSetProfileStaffForUpdating({
            variant: 'deleteProfile',
            currentProfileName,
            profileId,
            onSuccessfulUpdateCallback: () => {
              routeHandleChangeCurrentLayer([...data.filter((item) => item.profileId !== profileId)]);
            },
          });
        },
        show: deleteProfile,
      },
      {
        title: 'page.profilesMenu.btn-update-configuration',
        action: async ({ dataItem: { profileId } }) => handleUpdateConfiguration(profileId),
        show: updateProfileConfiguration,
      },
      isChildExist
        ? {
            title: 'page.profilesMenu.btn-update-config-child',
            action: async ({ dataItem: { profileId } }) => handleUpdateConfiguration(profileId, true),
            show: updateProfilesChildsConfiguration,
          }
        : {},
      {
        title: 'page.profilesMenu.show-element-menu',
        action: ({ dataItem: { name, profileId, parentHistorySlug } }) => {
          return tabService.addTab({
            type: 'menuProfileElements',
            dataItem: [{ profileName: name, profileId, parentHistorySlug }],
          });
        },
        show: redirectElementTab,
      },
    ];
  };

  rowRender = (element, { dataItem }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      {
        ...elementStyle,
        className:
          dataItem.details && dataItem.details.length
            ? 'MenuProfilesGrid-rowWithChild'
            : 'MenuProfilesGrid-rowWithOutChild',
      },
      children,
    );
  };

  recursiveUpdateSelected = (isSelected, data) =>
    data.map((profile) => ({
      ...profile,
      selected: isSelected,
      details: profile.details && this.recursiveUpdateSelected(isSelected, profile.details),
    }));

  handleUpdateSelected = (dataItem) => {
    const { setSelectedProfiles: setSelectedProfilesByContext, setSingleSelected, isSingle } = this.props;

    if (isSingle) {
      setSingleSelected(dataItem, !dataItem.selected);
      return;
    }

    const dataBySelected = this.recursiveUpdateSelected(!dataItem.selected, [dataItem]);

    setSelectedProfilesByContext(dataBySelected, !dataItem.selected);
  };

  render() {
    const {
      isRoot,
      data,
      translate,
      permissions: { isShowProfileOptions },
      withSelect,
    } = this.props;
    const level = data && data[0] ? data[0].level - 1 : 0;

    return (
      <Grid
        data={data}
        {...this.props}
        detail={({ dataItem }) => DetailComponent(dataItem, this.getHandleChangeCurrentLayer(dataItem, data))}
        expandField="expanded"
        onExpandChange={this.expandChange}
        rowRender={this.rowRender}
        className={`MenuProfilesGrid ${isRoot ? '' : 'MenuProfilesGrid--withHidedCellHeaders'}`}
      >
        {withSelect && <GridColumn cell={getCustomCellCheckbox(this.handleUpdateSelected)} width="40px" />}

        <GridColumn
          title={translate('page.profilesMenu.profileName')}
          field="name"
          width={`${400 - level * 8}px`}
          cell={(props) =>
            isShowProfileOptions && !withSelect ? (
              <CellDropdown
                customClassName="MenuProfilesGrid-cellDropdown"
                handlers={this.getHandlers(props.dataItem.details && props.dataItem.details.length)}
                {...props}
              />
            ) : (
              <td>{props.dataItem.name}</td>
            )
          }
        />
        <GridColumn field="profileId" title={translate('page.profilesMenu.profileId')} width={`${400 - level * 8}px`} />
        <GridColumn field="userId" title={translate('page.profilesMenu.userId')} width={`${400 - level * 8}px`} />
        <GridColumn field="userName" title={translate('page.profilesMenu.userName')} width={`${400 - level * 8}px`} />
        <GridColumn
          field="datePost"
          width={`${400 - level * 8}px`}
          title={translate('page.profilesMenu.datePost')}
          cell={({ dataItem: { datePost } }) => <td>{getTransformDateString(datePost)}</td>}
        />
        <GridColumn field="version" title={translate('page.profilesMenu.version')} width={`${400 - level * 8}px`} />
      </Grid>
    );
  }
}

export default withTranslate(widthUpdateMenuProfileContext(MenuProfilesGrid));
