import { OPERATOR_BY_GQL_MAP } from './constants';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

class GqlService {
  getValueByType = (value) => {
    if (`${value}`.includes('GMT')) {
      return `"${getDataByTimezoneOffset(new Date(value)).toISOString()}"`;
    }

    if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
      return value;
    }

    if (value.includes('"')) {
      value = value.replace(/"/g, '\\"');
    }

    return `"${value}"`;
  };

  getFilterString = ({ field, operator, value }, normalizeMap = new Map([[]])) =>
    `{${normalizeMap.get(field) || lowerCaseFirstLetter(field)}: {${
      OPERATOR_BY_GQL_MAP.get(operator) || operator
    }: ${this.getValueByType(value)}} }`;

  getFilter = (fieldStruct, normalizeMap) => {
    const { filters, logic, field } = fieldStruct;

    if (field) {
      return this.getFilterString(fieldStruct, normalizeMap);
    }

    if (!filters || !filters.length) {
      return '';
    }

    if (filters.length === 1) {
      return filters[0].logic
        ? this.getFilterGroupString(filters[0], normalizeMap)
        : this.getFilterString(filters[0], normalizeMap);
    }

    return `{${logic}: [${filters
      .map((filter) =>
        filter.logic ? this.getFilterGroupString(filter, normalizeMap) : this.getFilterString(filter, normalizeMap),
      )
      .join(',')}]}`;
  };

  getFilterGroupString = ({ filters, logic }, normalizeMap) => {
    if (!filters || !filters.length) {
      return '';
    }

    if (filters.length === 1) {
      return `{${logic}: [${this.getFilter(filters[0], normalizeMap)}]}`;
    }

    return `{${logic}: [${filters.map((filter) => this.getFilter(filter, normalizeMap)).join(',')}]}`;
  };

  getColumnsFromStorage = (columns, normalizeMap = new Map([[]])) =>
    columns
      .filter(({ field, checked }) => field !== 'selected' && checked)
      .map(({ field }) => normalizeMap.get(field) || lowerCaseFirstLetter(field));

  getSortString = (sortList, normalizeMap = new Map([[]])) => {
    if (!sortList || !sortList.length) {
      return '';
    }

    return `{ ${
      normalizeMap.get(sortList[0].field) || lowerCaseFirstLetter(sortList[0].field)
    }: ${sortList[0].dir.toUpperCase()} }`;
  };
}

export default new GqlService();
