import { StorageService } from '../services';

export default (name) => {
  const backConfig = StorageService.getItem('backConfig');
  if (!backConfig) return [];
  let finalArr = [];
  const currentFilteredByName = backConfig.filter((elem) => elem.WebTag === name);
  if (currentFilteredByName.length > 0) {
    finalArr = currentFilteredByName[0].Elements.filter((el) => el.ElementName !== name);
  }
  return finalArr;
};
