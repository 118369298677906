import translateElements from '../../admin/translateElements';

class ReleasesService {
  getReleasesInfoList = (config) =>
    config.reduce((acc, { Elements, ReleaseVersion, DatePost, WebTag, ...otherProps }) => {
      if (!ReleaseVersion || !DatePost) {
        return acc;
      }

      const isElementsExist = Elements && Elements.length;
      const elementReleasesInfoList = isElementsExist
        ? Elements.filter(({ ReleaseVersion, DatePost }) => ReleaseVersion && DatePost).map(
            ({ ElementName, ReleaseVersion, DatePost, ElementId, ElementTypeId, ElementTypeName }) => ({
              ReleaseVersion,
              DatePost,
              ElementName,
              ElementId,
              controlTag: WebTag,
              ElementTypeId,
              ElementTypeName,
            }),
          )
        : [];

      return [...acc, { DatePost, ReleaseVersion, WebTag }, ...elementReleasesInfoList];
    }, []);

  getElementsListWithRelease = (config, translate) =>
    this.getReleasesInfoList(config)
      .filter(({ WebTag }) => !WebTag)
      .map((item) => ({
        ...item,
        translatedElementName: translateElements(translate, item.ElementName, item.controlTag),
      }))
      .filter(({ translatedElementName }) => !/[a-zA-Z]/.test(translatedElementName));

  getElementsListByRelease = (config, releaseVersion, translate) =>
    this.getElementsListWithRelease(config, translate).filter(
      ({ ReleaseVersion }) => ReleaseVersion === releaseVersion,
    );

  getWebTagList = (config) => {
    return this.getReleasesInfoList(config)
      .filter(({ WebTag }) => WebTag)
      .map((item) => ({ ...item, webTag: item.WebTag }));
  };

  getWebTagListWidthRelease = (config, translate) => {
    const elementsListWithRelease = this.getElementsListWithRelease(config, translate);

    return this.getWebTagList(config)
      .filter(({ webTag }) => elementsListWithRelease.find(({ controlTag }) => controlTag === webTag))
      .reduce((acc, item) => (acc.find((accItem) => accItem.webTag === item.webTag) ? [...acc] : [...acc, item]), []);
  };

  getWebTagListByRelease = (config, releaseVersion, translate) => {
    const elementsListByRelease = this.getElementsListByRelease(config, releaseVersion, translate);

    return this.getWebTagList(config)
      .filter(
        ({ ReleaseVersion, webTag }) =>
          ReleaseVersion === releaseVersion || elementsListByRelease.find(({ controlTag }) => controlTag === webTag),
      )
      .reduce((acc, item) => (acc.find((accItem) => accItem.webTag === item.webTag) ? [...acc] : [...acc, item]), []);
  };

  getUniqueReleases = (config) => [
    ...new Set([
      ...this.getReleasesInfoList(config)
        .map(({ ReleaseVersion }) => ReleaseVersion)
        .filter((ReleaseVersion) => ReleaseVersion.includes('.')),
    ]),
  ];

  getDateForReleases = (config, translate) => {
    if (!config) {
      return [];
    }

    const releasesInfoList = this.getReleasesInfoList(config);

    const uniqueReleases = this.getUniqueReleases(config).reduce((acc, UniqueRelease) => {
      const releaseWidthEarliestDate = releasesInfoList
        .filter(({ ReleaseVersion }) => ReleaseVersion === UniqueRelease)
        .sort(({ prevDatePost }, nextDatePost) => new Date(prevDatePost) - new Date(nextDatePost))[0];

      return [...acc, releaseWidthEarliestDate];
    }, []);

    return uniqueReleases.filter(({ ReleaseVersion }) => {
      const elementsListByRelease = this.getElementsListByRelease(config, ReleaseVersion, translate);
      const webTagListByRelease = this.getWebTagListByRelease(config, ReleaseVersion, translate);

      return elementsListByRelease && elementsListByRelease.length && webTagListByRelease && webTagListByRelease.length;
    });
  };
}

export default new ReleasesService();
