const screenControlTerminals = (translate, value) => {
  switch (value) {
    case 'screenControlTerminals-btn-change-profile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.commission.btn-toggle')}`;
    case 'screenControlTerminals-btn-disableChangeProfile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.terminalsMainProfileList.btn-disableChangeProfile')}`;
    case 'screenControlTerminals-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-cityName')}`;
    case 'screenControlTerminals-DesignTypeName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-designType')}`;
    case 'screenControlTerminals-MainProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-profileId')}`;
    case 'screenControlTerminals-MainProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-mainProfileId')}`;
    case 'screenControlTerminals-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-regionName')}`;
    case 'screenControlTerminals-Note':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.terminalSettings.field-notes')}`;
    case 'screenControlTerminals-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'screenControlTerminals-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-statusName')}`;
    case 'screenControlTerminals-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-terminalAddress')}`;
    case 'screenControlTerminals-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'screenControlTerminals-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalsMainProfileList.column-terminalName')}`;
    case 'screenControlTerminals-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminals.column-branch')}`;
    default:
      return value;
  }
};

export default screenControlTerminals;
