import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import { ApiService, tabService } from '../../../services';
import { HANDLERS } from './constants';

import './Users.scss';
import CellBoolean from '../../account/cellBoolean';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();
const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('users');
  }
  state = {
    allOwners: [],
    currentOwners: [],
    userList: [],
    isLoading: false,
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const allOwners = await api.owners();
      this.setState({ allOwners, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getUsersList = async (ownerId) => {
    const { currentOwners } = this.state;

    if (!currentOwners.length && !ownerId.length) return;

    try {
      await this.setState({ isLoading: true });
      const userList = await api.postGetUsersList(ownerId || currentOwners);
      this.setState({ userList, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  handleSelectOwners = (currentOwners) => {
    this.setState({ currentOwners }, () => {
      this.getUsersList(currentOwners);
    });
  };

  getHandlerList = () => {
    const { currentOwners } = this.state;
    return HANDLERS.map(({ title, type, webTag }) => ({
      title,
      dropdown: false,
      action: ({ dataItem }) => tabService.addTab({ type, dataItem, userInfo: { ownerId: currentOwners[0] } }),
      // show: this.getShowProperties(webTag),
      show: checkElement(webTag, this.currentArray),
    }));
  };

  addNewUser = () => {
    return tabService.addTab({
      type: 'addNewUser',
      userInfo: {
        ownerId: null,
      },
      dataItem: {
        UserId: null,
        Name: null,
        LastName: null,
        FirstName: null,
      },
    });
  };

  async componentDidMount() {
    await this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, currentOwners } = this.state;

    return (
      <Row className="Users-toolbar">
        <Row type="flex" align="middle" className="Users-ownerDropdownRow">
          <Col span={6} md={2} xl={1}>
            {translate('page.commissionsServices.dealer')}
          </Col>
          <Col span={17} md={11} className="Users-ownerDropdown">
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              selectItems={currentOwners}
              onSave={this.handleSelectOwners}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>

          <Col span={4} md={4}>
            {checkElement('users-usersBtnAddNewUser', this.currentArray) && (
              <Button type="primary" onClick={this.addNewUser}>
                {translate('core.buttonTitles.addNewUser')}
              </Button>
            )}
          </Col>
        </Row>
      </Row>
    );
  };

  render = () => {
    const { userList, isLoading } = this.state;
    if (!checkElement('users-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={userList}
          toolbar={this.buildToolbar()}
          onRefresh={this.getUsersList}
          name="userList-grid"
          sortable
          isLoading={isLoading}
        >
          <GridColumn
            field="UserId"
            title="grids.userList.column-userId"
            width="150"
            handlers={this.getHandlerList()}
            dropdown={checkElement('users-isDropdownUserId', this.currentArray)}
            checked={false}
          />
          <GridColumn field="Name" title="grids.userList.column-login" width="150" />
          <GridColumn field="OwnerName" title="grids.userList.column-dealer" width="150" />
          <GridColumn field="FirstName" title="grids.userList.column-firstName" width="150" />
          <GridColumn field="LastName" title="grids.userList.column-lastName" width="150" />
          <GridColumn field="Address" title="grids.userList.column-address" width="150" />
          <GridColumn field="Description" title="grids.userList.column-desc" width="200" />
          <GridColumn
            field="DatePost"
            title="grids.userList.column-dateOfChange"
            width="200"
            filter="date"
            format="dd.MM.yyy HH:mm:ss"
          />
          <GridColumn field="Active" title="grids.userList.column-active" width="150" cell={WrappedCellBoolean} />

          <GridColumn
            field="DateLastLogin"
            title="grids.userList.column-dateLastLogin"
            width="200"
            cell={({ dataItem: { DateLastLogin } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastLogin)}</td>
            )}
            filter="date"
            format="dd.MM.yyy HH:mm:ss"
          />
        </Grid>
      </>
    );
  };
}
export default withTranslate(Users);
