import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

const CellComissionDealer = ({ field, dataItem, className, style, mobile, translate }) => {
  const value = dataItem[field];
  // const convertedVal = value === true ?
  // translate('page.aap-changeComission.com-dealer-true') :
  // translate('page.aap-changeComission.com-dealer-false');
  const renderTab = () => {
    return tabService.addTab({
      type: 'changeComission',
      dataItem: dataItem.RequestId,
    });
  };
  if (mobile) {
    return (
      <Button
        type="link"
        onClick={renderTab}
        style={{
          height: 'auto',
          lineHeight: '1',
          userSelect: 'auto',
        }}
        className="lg-p-0"
      >
        <span>{value}</span>
      </Button>
    );
  }
  return (
    <td className={className} style={style} align="center">
      <Button
        type="link"
        onClick={renderTab}
        style={{
          height: 'auto',
          lineHeight: '1',
          userSelect: 'auto',
        }}
        className="lg-p-0"
      >
        <span style={{ marginLeft: '5px' }}>{value}</span>
      </Button>
    </td>
  );
};

CellComissionDealer.propTypes = {
  dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.string.isRequired,
};

export default withTranslate(CellComissionDealer);
