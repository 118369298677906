const terminalsByDays = (translate, value) => {
  switch (value) {
    case 'terminalsByDays-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'terminalsByDays-createReport':
      return `${translate(`page.adminPanel.btnCreateReport`)}`;
    default:
      return value;
  }
};
export default terminalsByDays;
