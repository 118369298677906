import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import ModalSlider from './modalSlider';
import './cellPhotos.scss';
import PhotoIcon from './photoIcon.svg';

function sortArrayByDate(a, b) {
  return new Date(b.DatePost) - new Date(a.DatePost);
}

class CellPhotos extends GridCell {
  static defaultProps = {
    isOwnerPhotos: false,
  };
  static propTypes = {
    dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
    field: PropTypes.string.isRequired,
  };

  state = {
    visible: false,
  };

  /* функция которая соберет массив с начала с
   куска с непроверенными фото, потом с проверенными */
  transformArray(arr, field) {
    if (arr.length < 1) return [];
    const arrWithField = arr.filter((el) => el.Status === field);
    const arrNotWithField = arr.filter((el) => el.Status !== field).sort(sortArrayByDate);
    return [...arrWithField, ...arrNotWithField];
  }

  mapPhotosToIcons = (arr) => {
    return this.transformArray(arr, 'NotConsidered')
      .slice(0, 3)
      .map((el) => {
        return (
          <span key={el.FileName} className={`cell-photo-wrap ${this.getBackgroundClass(el)}`}>
            <img src={PhotoIcon} alt={`cell-view-${el.FileName}`} />
          </span>
        );
      });
  };

  getBackgroundClass = (el) => {
    if (!el) return;
    if (el.Status === 'NotConsidered') return 'red';
    else return 'blue';
  };

  renderIcons = () => {
    const { dataItem, field } = this.props;
    const arr = dataItem[field];
    if (arr.length === 0) return null;
    if (arr.length > 0)
      return (
        <span className="cellPhotos-wrapper" onClick={this.toggleModal}>
          {this.mapPhotosToIcons(arr)}
        </span>
      );
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  render() {
    const { className, style, mobile, dataItem, field, update, isOwnerPhotos } = this.props;
    const { visible } = this.state;
    const arr = dataItem[field];
    const sliderData = isOwnerPhotos
      ? this.transformArray(arr, 'NotConsidered').slice(0, 3)
      : this.transformArray(arr, 'NotConsidered');
    const modalSlider = (
      <ModalSlider
        visible={visible}
        dataItem={dataItem}
        closeModal={this.closeModal}
        toggleModal={this.toggleModal}
        isOwnerPhotos={isOwnerPhotos}
        update={update}
        sliderData={sliderData}
      />
    );
    if (mobile) {
      return (
        <>
          <div>{this.renderIcons()}</div>
          {visible && modalSlider}
        </>
      );
    }
    return (
      <>
        <td className={className} style={style} align="center">
          {this.renderIcons()}
        </td>
        {visible && modalSlider}
      </>
    );
  }
}

export default CellPhotos;
