import React, { PureComponent } from 'react';
import { Modal, Button, Col, Select, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

const api = new ApiService();

const { Option } = Select;

const comments = [
  // translate('modal-decline.choose-comment'),
  // translate('modal-decline.no-bills'),
  // translate('modal-decline.labeled-bills'),
  // translate('modal-decline.opened-terminal')
  '...выберите комментарий...',
  'Мошенничество (нет купюр - рыбалка)',
  'Мошенничество (меченые купюры)',
  'Мошенничество (вскрыт терминал)',
];

class ModalDeclineTransaction extends PureComponent {
  constructor(props) {
    super(props);
    const {
      item: { transactionId },
    } = this.props;
    if (!transactionId) return;
    this.state = {
      loading: false,
      transactionId: transactionId,
      visible: false,
      comment: comments[0],
    };
  }

  toggleModal = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleOk = async () => {
    const { transactionId, comment } = this.state;
    const { translate } = this.props;
    if (!transactionId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TransactionId: transactionId,
        Comment: comment,
      };
      const response = await api.declineTransaction(model);
      this.setState({ loading: false, visible: false });
      message.success(
        `${translate('grids.transactionDeatails.transaction')} № ${transactionId} ${translate(
          'grids.transactionDeatails.declined',
        )}`,
        1.5,
      );
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onChangeComment = (value) => {
    this.setState({ comment: value });
  };

  render = () => {
    const { loading, visible, transactionId, comment } = this.state;
    const { translate } = this.props;
    return (
      <>
        <Button onClick={this.toggleModal}>{translate('grids.transactionDeatails.btn-block')}</Button>
        <Modal
          title={translate('grids.transactionDeatails.decline-title')}
          visible={visible}
          centered
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {translate('grids.transactionDeatails.unhold-cancel')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              {translate('grids.transactionDeatails.btn-block')}
            </Button>,
          ]}
        >
          <Col md={24}>
            <p>№: {transactionId}</p>
          </Col>
          <Col md={24}>
            <p>{translate('grids.transactionDeatails.decline-comment')}</p>
          </Col>
          <Col md={24}>
            <Select value={comment} style={{ width: '300px' }} onChange={(value) => this.onChangeComment(value)}>
              {comments.map((comment, i) => (
                <Option value={comment} key={`comment-${i.toString()}`}>
                  {comment}
                </Option>
              ))}
            </Select>
          </Col>
        </Modal>
      </>
    );
  };
}

export default withTranslate(ModalDeclineTransaction);
