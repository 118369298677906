import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService, StorageService } from '../../services';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constants';
import ManagementErrorCodesService from './ManagementErrorCodes.service';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { withTranslate } from '../../contexts/localContext';
import EditableGridInput from '../../components/grids/EditableGridInput';
import checkElement from '../../utils/checkElement';
import Grid from '../../components/grids/baseGrid';
import findArray from '../../utils/findArrForCurrentPage';
import DeleteModal from './DeleteModal';

import './ManagementErrorCodesGrid.styled.scss';

const api = new ApiService();

class ManagementErrorCodesGrid extends PureComponent {
  currentArray = findArray('managementErrorCodes');

  state = {
    errorCodes: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    currentEditRow: null,
    selectedItems: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchErrorCodesGQL();

    document.addEventListener('keyup', this.handleOnDisabled);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleOnDisabled);
  }

  onFieldsConfigChange = (list) => {
    this.fetchErrorCodesGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  fetchErrorCodesGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString } = this.state;

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('managementErrorCodesGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{code: ASC}'
      }${filtersString ? `,where:{and:[${filtersString}]}` : ''}`;

      const query = `errorCodes(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { errorCodes, hasPreviousPage, hasNextPage } = ManagementErrorCodesService.getGQLResponse(data);

      this.setState({ errorCodes, hasPreviousPage, hasNextPage, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
        currentEditRow: null,
      });

      await this.fetchErrorCodesGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleAdd = () => {
    const { errorCodes } = this.state;
    const emptyItem = {
      editId: '-1',
      code: '',
      nameRu: '',
      nameUk: '',
      nameEn: '',
      nameDe: '',
      typeId: '',
      paymentRedirect: 'false',
      authRedirect: 'false',
    };

    this.setState({
      errorCodes: [emptyItem, ...errorCodes],
      currentEditRow: emptyItem,
    });
  };

  getValueByGQLFormat = (key, value) => {
    const isRedirectKey = key === 'paymentRedirect' || key === 'authRedirect';

    if (isRedirectKey && !value) {
      return `false`;
    }

    return (value && value.trim() === 'null') || isRedirectKey ? value : `"${value ? value.replace(/"/g, '\\"') : ''}"`;
  };

  updateErrorCodes = async () => {
    const {
      currentEditRow: { editId, selected, checked, ...otherErrorCodeProps },
    } = this.state;

    const queryString = Object.entries(otherErrorCodeProps).reduce(
      (acc, [key, value]) => `${acc}${key}:${this.getValueByGQLFormat(key, value)},`,
      '',
    );

    const query = `${
      editId === '-1' ? 'errorCodeEntityAdd' : 'errorCodeEntityUpdate'
    }(input: { errorCodeEntity:{${queryString}}}){errorCode { ${ALL_GRID_COLUMNS.join()} }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchErrorCodesGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  handleDelete = () => {
    const { selectedItems } = this.state;

    this.setState({
      modalVariant: selectedItems.find(({ editId }) => editId === '-1') ? 'forbiddenDelete' : 'allowDelete',
    });
  };

  handleOnDisabled = (e) => {
    const { keyCode, key } = e;

    if (keyCode === 27 || key === 'Escape') {
      this.setState(({ errorCodes }) => ({
        currentEditRow: null,
        errorCodes: errorCodes.filter(({ editId }) => editId !== '-1'),
      }));
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow, selectedItems } = this.state;

    return (
      <Row className="ManagementErrorCodesGrid-toolbar">
        {checkElement('managementErrorCodes-add', this.currentArray) && (
          <div className="ManagementErrorCodesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              onClick={this.handleAdd}
              disabled={currentEditRow || !!selectedItems.length}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('managementErrorCodes-update', this.currentArray) && (
          <div className="ManagementErrorCodesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              disabled={!currentEditRow || !currentEditRow.code || !!selectedItems.length}
              onClick={this.updateErrorCodes}
            >
              {translate('core.buttonTitles.save')}
            </Button>
          </div>
        )}

        {checkElement('managementErrorCodes-delete', this.currentArray) && (
          <div className="ManagementErrorCodesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              disabled={!selectedItems.length}
              onClick={this.handleDelete}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return checkElement('managementErrorCodes-update', this.currentArray) ? (
      <td align="center" style={{ textAlign: 'center' }}>
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.editId !== dataItem.editId) || selectedItems.length}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    ) : (
      <td>{currentDataItem[field]}</td>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { errorCodes, isLoading, pageValue, hasNextPage, hasPreviousPage, modalVariant, selectedItems } = this.state;

    if (!checkElement('managementErrorCodes-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={errorCodes}
          onRefresh={this.fetchErrorCodesGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="managementErrorCodesGrid"
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          fieldForSelection="editId"
          onSelect={this.handleSelectionChange}
          isGQL
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            className="OTPServicesGrid-checkboxCell"
          />
          <GridColumn field="code" title="Code" width="600" cell={this.cellInput} />
          <GridColumn field="nameUk" title="NameUk" width="300" cell={this.cellInput} />
          <GridColumn field="nameRu" title="NameRu" width="300" cell={this.cellInput} />
          <GridColumn field="nameEn" title="NameEn" width="300" cell={this.cellInput} />
          <GridColumn field="nameDe" title="NameDe" width="300" cell={this.cellInput} />
          <GridColumn field="typeId" title="TypeId" width="200" cell={this.cellInput} />
          <GridColumn field="paymentRedirect" title="PaymentRedirect" width="160" cell={this.cellInput} />
          <GridColumn field="authRedirect" title="AuthRedirect" width="160" cell={this.cellInput} />
        </Grid>

        {modalVariant && (
          <DeleteModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.fetchErrorCodesGQL}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ManagementErrorCodesGrid);
