import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../contexts/localContext';
import { SUPPLIERS_CARD_COMMISSION_FIELDS, SUPPLIERS_COMMISSION_FIELDS } from './constants';
import { renderInputWithLabel } from './utils';

import './SupplierCommissionForm.scss';

class SupplierCommissionsForm extends PureComponent {
  render() {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;
    return (
      <div className="SupplierCommissionsForm-row justify-between flex-wrap">
        <div className="SupplierCommissionsForm-card">
          <div className="SupplierCommissionsForm-card-title">
            {translate('page.recalculationTransactions.modal-title-supplier-commissions')}
          </div>

          {SUPPLIERS_COMMISSION_FIELDS.map(({ label, fieldName, minValue, maxValue }) =>
            renderInputWithLabel(
              formState,
              onChangeFormState,
              formErrors,
              onError,
              label,
              fieldName,
              minValue,
              maxValue,
              translate,
            ),
          )}
        </div>
        <div className="SupplierCommissionsForm-card">
          <div className="SupplierCommissionsForm-card-title">
            {translate('page.recalculationTransactions.modal-title-supplier-card-commissions')}
          </div>
          {SUPPLIERS_CARD_COMMISSION_FIELDS.map(({ label, fieldName, minValue, maxValue }) =>
            renderInputWithLabel(
              formState,
              onChangeFormState,
              formErrors,
              onError,
              label,
              fieldName,
              minValue,
              maxValue,
              translate,
            ),
          )}
        </div>
      </div>
    );
  }
}
export default withTranslate(SupplierCommissionsForm);
