import React, { PureComponent } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import './viewOwnerAboutStock.scss';
// import Img from './wrapper.svg';
import Logo from './logo.svg';
import Terminal1 from './terminal-1.svg';
import Terminal2 from './terminal-2.svg';
import Terminal3 from './terminal-3.svg';
import Terminal4 from './terminal-4.svg';
import Terminal5 from './terminal-5.svg';
import LoadingPanel from '../../components/loader';

const api = new ApiService();

class ViewOwnerAboutStock extends PureComponent {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  download = () => {
    axios({
      url: 'https://resources.easypay.ua/Docs/EasyPay_Brand_Marathon_2020.pdf', //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'aboutStock.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  render() {
    const { isLoading } = this.state;
    return (
      <div className="aboutStock--wrapper">
        <div className="aboutStock--header">
          <div className="aboutStock--container">
            <div className="aboutStock--header-top">
              <img className="aboutStock-logo" src={Logo} alt="aboutStock-logo" />
              <h2>EasyPay Бренд Марафон</h2>
            </div>
            <div className="aboutStock--header-bottom">
              <p>
                EasyPay оголошує старт <span className="bold-span">EasyPay Бренд Марафону</span> серед власників мереж
                платіжних терміналів EasyPay.
              </p>
            </div>
          </div>
        </div>
        <div className="aboutStock-section">
          <div className="aboutStock--container">
            <div className="aboutStock-content">
              <div className="aboutStock-content-left">
                <p className="top-text">
                  Отримуйте подарунки та призи за кількість оформлених терміналів <span className="bold">EasyPay</span>{' '}
                  за новими стандартами брендування. Кількість подарунків залежить тільки від ваших досягнень!
                </p>
                <p className="bottom-text">
                  <span className="bold">Тривалість марафону:</span> 1 листопада — 31 грудня 2020.
                </p>
              </div>
              <div className="aboutStock-content-right">
                <div className="block">
                  <p className="block-header">Всі учасники отримають свої подарунки:</p>
                  <div className="sub-block first">
                    <div className="sub-block-img"></div>
                    <p>
                      <span className="bold">Заохочувальний подарунок</span> — за 10 перших правильно оформлених
                      терміналів EasyPay за новими стандартами брендування.
                    </p>
                  </div>
                  <div className="sub-block">
                    <div className="sub-block-img"></div>
                    <p>
                      <span className="bold">Гарантований подарунок</span> — за кожні 25 терміналів за новими
                      стандартами.
                    </p>
                  </div>
                  <div className="sub-block">
                    <div className="sub-block-img"></div>
                    <p>
                      12 найпродуктивніших учасників стануть переможцями марафону та отримають{' '}
                      <span className="bold">Головні призи</span> — за 1, 2 та 3 місце в своїй категорії.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutStock-section-btn">
              <Button className="download-btn" type="primary" onClick={this.download}>
                Умови та призи марафону
              </Button>
            </div>
            <div className="aboutStock-section-terminals">
              <img src={Terminal1} alt="terminal-1" />
              <img src={Terminal2} alt="terminal-2" />
              <img src={Terminal3} alt="terminal-3" />
              <img src={Terminal4} alt="terminal-4" />
              <img src={Terminal5} alt="terminal-5" />
            </div>
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(ViewOwnerAboutStock);

/* START OLD VARIANT */
/* <h2 className="aboutStock-h2">Красива назва акції</h2> */
/* <div className="aboutStock--content">
        <div className="aboutStock--left">
          <img className="aboutStock-img" src={Img} alt="#" />
        </div>
        <div className="aboutStock--right">
          <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>

          <p>Идейные соображения высшего порядка, а также убеждённость некоторых оппонентов
          обеспечивает актуальность как самодостаточных, так и внешне зависимых
          концептуальных решений. Не следует, однако, забывать, что начало повседневной
          работы по формированию позиции позволяет оценить значение анализа существующих
        паттернов поведения.</p>
        <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>

        <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>

          <p>Идейные соображения высшего порядка, а также убеждённость некоторых оппонентов
          обеспечивает актуальность как самодостаточных, так и внешне зависимых
          концептуальных решений. Не следует, однако, забывать, что начало повседневной
          работы по формированию позиции позволяет оценить значение анализа существующих
        паттернов поведения.</p>
        <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>

        <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>

          <p>Идейные соображения высшего порядка, а также убеждённость некоторых оппонентов
          обеспечивает актуальность как самодостаточных, так и внешне зависимых
          концептуальных решений. Не следует, однако, забывать, что начало повседневной
          работы по формированию позиции позволяет оценить значение анализа существующих
        паттернов поведения.</p>
        <p>Имеется спорная точка зрения, гласящая примерно следующее:
          базовые сценарии поведения пользователей являются только методом
          политического участия и в равной степени предоставлены сами себе.
          Противоположная точка зрения подразумевает, что стремящиеся вытеснить
          традиционное производство, нанотехнологии, вне зависимости от их уровня,
        должны быть призваны к ответу.</p>
        </div>
      </div> */
/* END OLD VARIANT */
