export const BUTTON_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    width: '240',
    titleTranslateSlug: 'page.fishkaService.col-serviceName',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];
