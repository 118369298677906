import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';

const api = new ApiService();
const aggregate = [
  {
    field: 'QuantityTotal',
    aggregate: 'sum',
    format: 'n0',
  },
  {
    field: 'AvgPayment',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ QuantityTotal, AmountTotal }) => AmountTotal.sum / QuantityTotal.sum,
  },
  {
    field: 'AmountTotal',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'CommissionProvider',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'CommissionClient',
    aggregate: 'sum',
    format: 'n2',
  },
];
class ReportTerminalsByServices extends PureComponent {
  state = {
    terminalsByServices: [],
    isLoading: false,
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  getReportTerminalsByServices = async () => {
    const { ownerId, terminalId, dateStart, dateEnd } = this.props;
    try {
      const model = {
        ownerId: ownerId,
        terminalId: terminalId,
        dateStart: dateStart,
        dateEnd: dateEnd,
      };
      await this.toggleLoader(true);
      const terminalsByServices = await api.terminalsByServices(model);
      this.setState({ terminalsByServices });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  async componentDidMount() {
    await this.getReportTerminalsByServices();
  }

  render = () => {
    const { isLoading, terminalsByServices } = this.state;

    return (
      <>
        <Grid
          data={terminalsByServices}
          name="reportTerminalsByServices-grid"
          isLoading={isLoading}
          onRefresh={this.getReportTerminalsByServices}
          aggregate={aggregate}
          toolbar={<div></div>}
        >
          <GridColumn field="ServiceId" title="grids.terminalsByServices.column-serviceId" width="180px" />
          <GridColumn field="ServiceName" title="grids.terminalsByServices.column-name" width="180px" />
          <GridColumn field="ServiceGroup" title="grids.terminalsByServices.column-serviceGroup" width="180px" />
          <GridColumn field="QuantityTotal" title="grids.terminalsByServices.column-quantityTotal" width="180px" />
          <GridColumn field="AvgPayment" title="grids.terminalsByServices.column-avgPayment" width="180px" />
          <GridColumn field="AmountTotal" title="grids.terminalsByServices.column-amountTotal" width="180px" />
          <GridColumn
            field="CommissionProvider"
            title="grids.terminalsByServices.column-commissionProvider"
            width="180px"
          />
          <GridColumn
            field="CommissionClient"
            title="grids.terminalsByServices.column-commissionClient"
            width="180px"
          />
        </Grid>
      </>
    );
  };
}
export default withTranslate(ReportTerminalsByServices);
