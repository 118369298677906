import React, { PureComponent } from 'react';
import { Button, Input, message, Modal, Checkbox } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import ServicesAdditionalPropsService from './ServicesAdditionalProps.service';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { PROPS_COLUMN_CONFIG } from './constants';
import LoadingPanel from '../../../../components/loader';
import { ApiService } from '../../../../services';

const api = new ApiService();

class UpdateModal extends PureComponent {
  state = {
    isLoading: false,
    props: [],
    selectedProps: [],
    value: '',
    isSaveForTransaction: false,
    error: '',
  };

  componentDidMount() {
    const { selectedItem, variant } = this.props;
    this.fetchProps();
    if (selectedItem && variant === 'changeProps') {
      this.setState({
        selectedProps: [selectedItem.Name],
        value: selectedItem.Value,
        isSaveForTransaction: selectedItem.SaveToTransaction,
      });
    }
  }

  onDropdownSave = (selectedProps) => {
    const { additionalProps, variant, translate } = this.props;
    this.setState({ selectedProps }, () => {
      this.setState({
        error:
          variant === 'addProps' && additionalProps.some(({ Name }) => Name === selectedProps[0])
            ? translate('page.additionalServiceProps.propertyAlreadyAssigned')
            : '',
      });
    });
  };

  fetchProps = async () => {
    try {
      await this.setState({ isLoading: true });
      const additionalProps = await api.getServicesPropertiesKeys();
      this.setState({ props: ServicesAdditionalPropsService.normalizeProps(additionalProps) });
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  updateProps = async () => {
    const { selectedProps, value, isSaveForTransaction } = this.state;
    const { serviceId, variant, translate, onRefresh, closeModal } = this.props;
    try {
      await this.setState({ isLoading: true });
      const reqCallback =
        variant === 'addProps' ? api.addAdditionalPropertyService : api.updateAdditionalPropertyService;

      await reqCallback({
        name: selectedProps[0],
        value,
        saveToTransaction: isSaveForTransaction,
        serviceId,
      });
      await this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(
        translate(
          `page.additionalServiceProps.${
            variant === 'addProps' ? 'propertyAddedSuccessfully' : 'propertyChangedSuccessfully'
          }`,
        ),
        2,
      );
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, closeModal, variant } = this.props;
    const { isLoading, props, selectedProps, value, isSaveForTransaction, error } = this.state;

    return (
      <Modal
        visible={variant === 'addProps' || variant === 'changeProps'}
        onCancel={closeModal}
        className="UpdatePropsModal"
        title={translate(`page.additionalServiceProps.${variant === 'addProps' ? 'addingProps' : 'changingProps'}`)}
        footer={[
          <Button
            type="primary"
            key="submit"
            loading={isLoading}
            disabled={!value || !selectedProps.length || error}
            onClick={this.updateProps}
          >
            {translate(`core.buttonTitles.${variant === 'addProps' ? 'add' : 'change'}`)}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>
          <div className="UpdatePropsModal-row">
            <div className="UpdatePropsModal-label">{translate('page.additionalServiceProps.col-name')}</div>
            <GridDropdown
              data={props}
              colConfig={PROPS_COLUMN_CONFIG}
              selectItems={selectedProps}
              onSave={this.onDropdownSave}
              disabled={variant !== 'addProps'}
              defaultTitle={translate('page.additionalServiceProps.chosenProps')}
              isSingle
            />
          </div>

          <div className="UpdatePropsModal-row">
            <div className="UpdatePropsModal-label">{translate('page.additionalServiceProps.col-value')}</div>
            <Input
              value={value}
              onChange={({ target: { value } }) => {
                this.setState({ value });
              }}
            />
          </div>

          <div className="UpdatePropsModal-row">
            <div className="UpdatePropsModal-wideLabel">
              {translate('page.additionalServiceProps.col-saveForTransaction')}
            </div>
            <Checkbox
              checked={isSaveForTransaction}
              onChange={() => {
                this.setState(({ isSaveForTransaction }) => {
                  return {
                    isSaveForTransaction: !isSaveForTransaction,
                  };
                });
              }}
            />
          </div>

          {error && <div className="UpdatePropsModal-error">{error}</div>}
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
