import React, { PureComponent } from 'react';
import { Button } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CommissionDetailsForm from '../../../components/CommisionDetailsForm/CommisionDetailsForm/CommisionDetailsForm';
import findArray from '../../../utils/findArrForCurrentPage';

import '../ComissionPage.scss';
import checkElement from '../../../utils/checkElement';

class CreateCommission extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('handlingCommissions');
  }

  render() {
    const { translate, isButtonDisabled, handleUpdateCommission, ...rest } = this.props;

    return (
      <>
        <div className="Commission">
          <div className="Commission-actionPanel">
            <div className="Commission-buttonWrapper">
              {checkElement('handlingСommissions-btn-editCommission', this.currentArray) && (
                <Button type="primary" disabled={isButtonDisabled} onClick={handleUpdateCommission}>
                  {translate('page.commission.btn-save')}
                </Button>
              )}
            </div>
          </div>

          <div className="Commission-content">
            <CommissionDetailsForm {...rest} />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslate(CreateCommission);
