import React, { PureComponent } from 'react';
import { Col, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService } from '../../../services';
import { BANK_LIST_GRID_COLUMNS } from '../constants';
import { withTranslate } from '../../../contexts/localContext';
import EditableGridInput from '../../../components/grids/EditableGridInput';
import Grid from '../../../components/grids/baseGrid';
import FetchBankListService from '../fetchBankList.service';

import './BankListGrid.styled.scss';

const api = new ApiService();

class BankListGrid extends PureComponent {
  state = {
    bankList: [],
    isLoading: false,
    currentEditRow: null,
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchBankListGQL();

    document.addEventListener('keyup', this.handleOnDisabled);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleOnDisabled);
  }

  fetchBankListGQL = async () => {
    try {
      await this.setState({ isLoading: true });
      const bankList = await FetchBankListService.fetchBankListGQL(0, []);
      this.setState({ bankList, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleAdd = () => {
    const emptyItem = {
      editId: '-1',
      bankName: '',
      bankServiceId: '',
    };

    this.setState(({ bankList }) => ({
      bankList: [emptyItem, ...bankList],
      currentEditRow: emptyItem,
    }));
  };

  updateErrorCodes = async () => {
    const {
      currentEditRow: { editId: currentEditId, bankName, bankServiceId },
      bankList,
    } = this.state;

    const previousBankName = bankList.find(({ editId }) => editId === currentEditId).bankName;

    try {
      this.setState({ isLoading: true });
      await api.updateCardRoutingBank({
        previosBankName: previousBankName,
        newBankName: bankName,
        bankServiceId: bankServiceId === 'null' ? null : bankServiceId,
      });
      await this.fetchBankListGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  insertErrorCodes = async () => {
    const {
      currentEditRow: { bankName, bankServiceId },
    } = this.state;

    const query = `cardRoutingBankEntityAdd(input: { bankEntity:{ bankName: "${(bankName || 'null').replace(
      /"/g,
      '\\"',
    )}", bankServiceId: ${bankServiceId || 'null'} }}){cardRoutingBank { ${BANK_LIST_GRID_COLUMNS.join()} }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchBankListGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  handleOnDisabled = (e) => {
    const { keyCode, key } = e;

    if (keyCode === 27 || key === 'Escape') {
      this.setState(({ bankList }) => ({
        currentEditRow: null,
        bankList: bankList.filter(({ editId }) => editId !== '-1'),
      }));
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow } = this.state;

    return (
      <Col className="ManagementErrorCodesGrid-toolbar">
        <Button className="OTPServicesGrid-button" type="primary" onClick={this.handleAdd} disabled={currentEditRow}>
          {translate('core.buttonTitles.add')}
        </Button>

        <Button
          className="OTPServicesGrid-button"
          type="primary"
          disabled={!currentEditRow}
          onClick={currentEditRow && currentEditRow.editId === '-1' ? this.insertErrorCodes : this.updateErrorCodes}
        >
          {translate('core.buttonTitles.save')}
        </Button>
      </Col>
    );
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return (
      <td align="center" style={{ textAlign: 'center' }}>
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.editId !== dataItem.editId) || selectedItems.length}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    );
  };

  render() {
    const { bankList, isLoading } = this.state;

    return (
      <Grid
        data={bankList}
        onRefresh={this.fetchBankListGQL}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="managementErrorCodesGrid"
      >
        <GridColumn field="bankName" title="Bank name" width="300" cell={this.cellInput} />
        <GridColumn field="bankServiceId" title="Bank service id" width="140" cell={this.cellInput} />
      </Grid>
    );
  }
}

export default withTranslate(BankListGrid);
