import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import WorkWithDealers from './WorkWithDealers';
import LoadingPanel from '../../../components/loader';
import { alphabeticalSorting } from '../../../utils/alphabeticalSorting';

const api = new ApiService();

class WorkWithDealersContainer extends PureComponent {
  state = {
    isLoading: false,
    owners: null,
    defaultSelectItems: null,
  };

  getSortedOwners = (owners) => {
    if (!owners || !owners.length) {
      return [];
    }

    return owners.sort(({ ShortName: PrevOwnerName }, { ShortName: NextOwnerName }) =>
      alphabeticalSorting(PrevOwnerName, NextOwnerName),
    );
  };

  getOwnersByUserId = async () => {
    try {
      const { userId } = this.props;
      await this.setState({ isLoading: true });
      const ownersByUserId = await api.getOwnersAcList(userId);
      const owners = await api.owners();
      await this.setState({
        owners:
          owners && owners.length
            ? this.getSortedOwners(owners.filter(({ OwnerStatusId }) => OwnerStatusId !== 2))
            : [],

        defaultSelectItems:
          ownersByUserId && ownersByUserId.length
            ? ownersByUserId.filter(({ HasAccess }) => HasAccess).map(({ OwnerId }) => OwnerId)
            : [],
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    await this.getOwnersByUserId();
  }

  render = () => {
    const { owners, defaultSelectItems, isLoading } = this.state;
    return (
      <>
        {defaultSelectItems && owners && (
          <WorkWithDealers
            {...this.props}
            owners={owners}
            defaultSelectItems={defaultSelectItems}
            setIsLoading={(value) => {
              this.setState({ isLoading: value });
            }}
          />
        )}
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(WorkWithDealersContainer);
