import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import FilterActionBlock from './FilterActionBlock';

import './FilterConstructorModal.styled.scss';
import { generateGUID } from '../../utils/generateGUID';

class FilterConstructorModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterState: this.formatInitialFilter(this.props.filters),
    };
  }

  handleSave = () => {
    const { filterState } = this.state;
    const { onSave } = this.props;

    onSave(this.formatFilterItems(this.filterEmptyItems(filterState)));
  };

  formatInitialFilter = (group) => {
    return {
      ...group,
      filters: group.filters.map((item) => {
        if (item && item.customFilterType === 'nullOperator') {
          return {
            field: item.field,
            operator: item.operator === 'eq' ? 'isnull' : 'isnotnull',
            value: '',
            id: generateGUID(),
          };
        }

        if (item && item.customFilterType === 'nempty') {
          return {
            field: item.filters[0].field,
            operator: 'nempty',
            value: '',
          };
        }

        if (item && item.customFilterType === 'empty') {
          return {
            field: item.filters[0].field,
            operator: 'empty',
            value: '',
          };
        }

        if (item && item.customFilterType === 'anyOf') {
          if (!item.logic) {
            return {
              field: item.field,
              operator: 'anyOf',
              value: item.value,
              id: generateGUID(),
            };
          }

          return {
            field: item.filters[0].field,
            operator: 'anyOf',
            value: item.filters.map(({ value }) => value).join(','),
            id: generateGUID(),
          };
        }

        if (item.logic && item.filters && item.filters.length) {
          return this.formatFilterItems(item);
        }

        return { ...item, id: generateGUID() };
      }),
      id: generateGUID(),
    };
  };

  filterEmptyItems = (group) => ({
    ...group,
    filters: group.filters
      .filter((item) => {
        return item.logic || (item.field && item.operator);
      })
      .map((item) => (item.logic ? this.filterEmptyItems(item) : item))
      .filter((item) => !item.logic || item.filters.length),
  });

  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : NaN);

  getNonEmptyFilters = (field) => ({
    logic: 'and',
    customFilterType: 'nempty',
    filters: [
      {
        field: field,
        operator: 'neq',
        value: null,
      },
      {
        field: field,
        operator: 'neq',
        value: '',
      },
    ],
  });

  getEmptyFilters = (field) => ({
    logic: 'or',
    customFilterType: 'empty',
    filters: [
      {
        field: field,
        operator: 'eq',
        value: null,
      },
      {
        field: field,
        operator: 'eq',
        value: '',
      },
    ],
  });

  formatFilterItems = (group) => ({
    ...group,
    filters: group.filters.map((item) => {
      if (item.logic && item.filters && item.filters.length) {
        return this.formatFilterItems(item);
      }

      if (item && item.operator === 'nempty') {
        return this.getNonEmptyFilters(item.field);
      }

      if (item && item.operator === 'empty') {
        return this.getEmptyFilters(item.field);
      }

      if (item && item.operator === 'anyOf') {
        const { fieldsInfo } = this.props;

        const isNumeric = fieldsInfo[item.field].filter === 'numeric';

        const isMulti = item.value.split(',').length > 1;

        if (!isMulti) {
          return {
            customFilterType: 'anyOf',
            field: item.field,
            operator: 'eq',
            value: isNumeric ? this.getParsedValue(item.value) : item.value.trim(),
          };
        }

        return {
          logic: 'or',
          customFilterType: 'anyOf',
          filters: item.value.split(',').map((value) => ({
            field: item.field,
            operator: 'eq',
            value: isNumeric ? this.getParsedValue(value) : value.trim(),
          })),
        };
      }

      const { fieldsInfo, isGQL } = this.props;

      if (item && (item.operator === 'isnull' || item.operator === 'isnotnull')) {
        const isDate = fieldsInfo[item.field].filter === 'date';
        return {
          customFilterType: 'nullOperator',
          field: item.field,
          operator: item.operator === 'isnull' ? 'eq' : 'neq',
          value: !isDate || isGQL ? null : new Date(null),
          id: item.id,
        };
      }

      return item;
    }),
  });

  render() {
    const { fieldsInfo, translate, open, onClose } = this.props;
    const { filterState } = this.state;

    return (
      <Modal
        className="FilterConstructorModal"
        visible={open}
        title={translate('page.filterConstructorModal.title')}
        onCancel={onClose}
        footer={[
          <Button type="primary" key="add" onClick={this.handleSave}>
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={onClose}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <FilterActionBlock
          data={filterState}
          fieldsInfo={fieldsInfo}
          removeAction={() => {
            this.setState({
              filterState: { logic: 'and', filters: [], id: '0' },
            });
          }}
          updateData={(data) => {
            this.setState({
              filterState: data,
            });
          }}
        />
      </Modal>
    );
  }
}

export default withTranslate(FilterConstructorModal);
