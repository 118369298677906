const ServiceSupplier = (translate, value) => {
  switch (value) {
    case 'serviceSuppliers-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'serviceSuppliers-create':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`page.serviceSupplier.createSupplier`)}`;
    case 'serviceSuppliers-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`page.serviceSupplier.deleteSupplier`)}`;
    case 'serviceSuppliers-props':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.serviceSupplier.supplierProps')}`;
    case 'serviceSuppliers-treaties':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.serviceSupplier.treaties')}`;
    case 'serviceSuppliers-users':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.serviceSupplier.supplierUsers')}`;
    case 'serviceSuppliers-dropdown':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('page.serviceSupplier.col-number')}`;
    default:
      return value;
  }
};

export default ServiceSupplier;
