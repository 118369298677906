import React, { Component } from 'react';
import { Sortable } from '@progress/kendo-react-sortable';
import { withTranslate } from '../../../../contexts/localContext';
import { Button, Input, message, Row, Col } from 'antd';
import { ApiService, StorageService } from '../../../../services';
import './newProfile.scss';
import SortableItemUI from './sortableItemUi';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class NewProfile extends Component {
  constructor(props) {
    super(props);
    const { mainProfileName, ownerId } = props;
    if (!mainProfileName || !ownerId) return;
    this.state = {
      data: [],
      isDisabled: false,
      isSuperUser: false,
      elementsShouldBeDisabled: [],
      isLoading: false,
      templateValue: '',
      isRepeatedElem: false,
      reservedData: [],
    };
  }

  getOwnerId = async () => {
    const userInfo = StorageService.getItem('userInfo');
    if (userInfo.OwnerId || userInfo.OwnerId === 0) {
      return userInfo.OwnerId;
    }

    try {
      await this.setState({ isLoading: true });
      const response = await api.owners();

      this.setState({ isLoading: false });
      return response && response.length ? response[0].OwnerId : null;
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  calculateOwnerId = async () => {
    const { isAdminRule, ownerId } = this.props;

    if (isAdminRule === false) {
      const ownerIdFromReq = await this.getOwnerId();

      return ownerIdFromReq;
    }

    return ownerId;
  };

  componentDidMount() {
    this.initRequest();
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  initRequest = async () => {
    const { showError, mainProfileId, mainProfileName } = this.props;
    try {
      this.toggleLoader();
      const OwnerId = await this.calculateOwnerId();
      const data = await api.getProfileFromProfiles(mainProfileId);
      this.setState(
        {
          loading: false,
          data: data && data.length ? data.filter(({ IsReserve }) => !IsReserve) : [],
          reservedData: data && data.length ? data.filter(({ IsReserve }) => IsReserve) : [],
          templateValue: `${OwnerId}_${mainProfileName}`,
        },
        () => {
          this.renderData();
        },
      );
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.checkDisability();
    }
  }

  onDragOver = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  onNavigate = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  changeImage = (RowIndex, newElem) => {
    const parsedElem = JSON.parse(newElem);
    const { MenuId, MenuName, IconUrl, Icon, Caption } = parsedElem;
    this.setState(({ data }) => {
      const newData = data.map((el) => {
        if (el.RowIndex === RowIndex) {
          el.MenuId = MenuId;
          el.MenuName = MenuName;
          el.IconUrl = IconUrl;
          el.Icon = Icon;
          el.Caption = Caption;
        }
        return el;
      });
      return {
        data: newData,
      };
    });
  };

  toggleElementLock = (MenuId) => {
    this.setState(({ data }) => {
      const newData = data.map((el) => {
        if (el.MenuId === MenuId) {
          el.IsHold = !el.IsHold;
        }
        return el;
      });
      return {
        data: newData,
      };
    });
  };

  dropImage = (RowIndex) => {
    this.setState(({ data }) => {
      let newData;
      if (data.length > 20) {
        newData = data.filter((el) => el.RowIndex !== RowIndex);
      } else {
        newData = data.map((el) => {
          if (el.RowIndex === RowIndex) {
            el.IconUrl = null;
            el.Caption = <p>+</p>;
          }
          return el;
        });
      }
      return {
        data: newData,
      };
    });
  };

  getDisabledFieldInsteadOfRole = () => {
    const { isSuperUser } = this.state;
    if (!isSuperUser) {
      return 'disabled';
    } else {
      return '';
    }
  };

  renderData = () => {
    this.setState((state) => {
      const newData = [...state.data];
      while (newData.length < 20) {
        newData.push({
          MenuId: null,
          Caption: <p>+</p>,
          IconUrl: null,
          RowIndex: newData.length + 1,
        });
      }
      return {
        data: newData,
      };
    });
  };
  checkDisability = () => {
    const { data } = this.state;
    let isDisabled = false;
    let isRepeatedElem = false;
    const arrayofMenuIds = data.filter((el) => el.MenuId !== null).map((el) => el.MenuId);
    const uniqueArray = Array.from(new Set(arrayofMenuIds));
    if (arrayofMenuIds.length !== uniqueArray.length) {
      isRepeatedElem = true;
      isDisabled = true;
    } else {
      isRepeatedElem = false;
      isDisabled = false;
    }

    if (data.filter((el) => el.IconUrl === null).length > 0) {
      isDisabled = true;
      isRepeatedElem = false;
    }

    this.setState({ isDisabled, isRepeatedElem }, () => this.showErrorRepeatedElems(isRepeatedElem));
  };

  showErrorRepeatedElems = (isRepeatedElem) => {
    const { translate } = this.props;
    if (isRepeatedElem === true) {
      return message.error(`${translate('page.screenControlBusinessServices.profile-error-repeated-elements')}`, 2);
    }
  };
  // checkDisability = () => {
  //   const { data, isSuperUser } = this.state;
  //   let isDisabled;
  //   if (isSuperUser) {
  //     const filteredData = data.filter(el => el.disabled);
  //     let findedIndex;
  //     if (filteredData.length > 0) {
  //       isDisabled = false;
  //       data.map((el, index) => {
  //         return filteredData.map(filterElem => {
  //           if (JSON.stringify(el) === JSON.stringify(filterElem)) {
  //             findedIndex = index;
  //           }
  //           return findedIndex;
  //         });
  //       });
  //       if (findedIndex !== -1 &&
  //         data.slice(0, findedIndex + 1)
  //           .filter(el => !el.disabled).length > 0) {
  //         // isDisabled = true;
  //         this.setState(({ data }) => {
  //           const filteredElemsForDisabling = data.slice(0, findedIndex + 1)
  //             .filter(el => !el.disabled);
  //           return {
  //             elementsShouldBeDisabled: filteredElemsForDisabling
  //           }
  //         });
  //       }
  //     }
  //   } else {
  //     isDisabled = false;
  //     if (data.filter(el => el.IconUrl === null).length > 0) {
  //       isDisabled = true;
  //     }
  //   }
  //   this.setState({ isDisabled });
  // }

  onSubmitHandler = async () => {
    const { elementsShouldBeDisabled, data, templateValue, reservedData } = this.state;
    const { showError, mainProfileHoldId, regionId, translate, mainProfileReserveId } = this.props;
    const newFiltered = elementsShouldBeDisabled.filter((el) => !el.disabled);
    this.setState({ elementsShouldBeDisabled: newFiltered });
    if (newFiltered.length > 0) {
      return message.error('page.screenControlBusinessServices.new-profile-error-not-all-disabled', 2);
    } else {
      try {
        await this.toggleLoader();
        const OwnerId = await this.calculateOwnerId();

        const model = {
          OwnerId,
          MainProfileId: 0,
          MainProfileHoldId: mainProfileHoldId,
          MainProfileName: templateValue,
          RegionId: regionId,
          MainProfileReserveId: mainProfileReserveId,
          Items: [...data, ...reservedData].map((el, index) => {
            el.RowIndex = index;
            return el;
          }),
        };

        console.log('model', model);
        const response = await api.postMainProfileManage(model);
        this.setState(
          (state) => {
            const newData = state.data.map((el, index) => {
              el.RowIndex = index;
              return el;
            });
            return {
              loading: false,
              data: newData,
            };
          },
          () =>
            message.success(`${translate('page.screenControlBusinessServices.new-profile-success')}`, 2, () =>
              window.location.reload(true),
            ),
        );
        return response;
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onChangeTemplateValue = (e) => {
    this.setState({ templateValue: e.target.value });
  };

  render() {
    const { data, isDisabled, isSuperUser, isLoading, templateValue, reservedData } = this.state;
    const { ownerId, translate } = this.props;
    return (
      <>
        <div className="scrcontrol--wrapper">
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: 10 }}
            align="middle"
            gutter={24}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={16}>
              <Row
                className="modal-change-profile-row for-sub-input"
                type="flex"
                align="middle"
                gutter={24}
                style={{ flexWrap: 'wrap' }}
              >
                <p style={{ marginBottom: '0' }}>
                  {translate('page.screenControlProfiles.modal-new-profile-input-title')}
                </p>
                <Input className value={templateValue} onChange={this.onChangeTemplateValue} />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Row type="flex" justify="end">
                {data && data.length > 1 && (
                  <Button type="primary" onClick={this.onSubmitHandler} disabled={isDisabled || !templateValue}>
                    {translate('page.screenControlBusinessServices.button-save')}
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
          <Sortable
            idField={'RowIndex'}
            disabledField={'IsHold'}
            data={data.slice(0, 20)}
            style={{ marginTop: 10 }}
            itemUI={(props) => (
              <SortableItemUI
                key={`${props.dataItem.MenuId}-${props.dataItem.RowIndex}`}
                changeImage={this.changeImage}
                ownerId={ownerId}
                toggleModal={this.toggleModal}
                dropImage={this.dropImage}
                toggleElementLock={this.toggleElementLock}
                isSuperUser={isSuperUser}
                {...props}
              />
            )}
            onDragOver={this.onDragOver}
            onNavigate={this.onNavigate}
          />
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(NewProfile);
