import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { withTranslate } from '../../../contexts/localContext';
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import './baseFilter.scss';
import { NOT_ALLOWED_FILTERS_BY_GAL } from './constants';
import { formatFiterOperators } from '../../../utils/formatFiterOperators';
import { FILTER_OPERATORS } from '../../FilterConstructorModal/constants';

class BaseFilter extends PureComponent {
  state = {
    visible: false,
  };

  componentDidMount() {}

  // componentWillUnmount() {
  //   debugger;
  // }

  showModal = () => {
    this.setState({ visible: true });
    const popup = document.querySelector('.k-animation-container');
    if (popup && window.innerWidth > 991) popup.style.display = 'none';
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { translate, filterOperators, isGQL } = this.props;

    return (
      <div className="base-filter-wrapper">
        <Button type="primary" icon="filter" onClick={this.showModal}>
          {translate('filter-column-menu-sub')}
        </Button>
        <Modal
          title={translate('base-filter.title')}
          className="modal-base-filter"
          visible={visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          footer={[]}
        >
          {/* <GridColumnMenuSort {...this.props} /> */}
          <GridColumnMenuFilter
            expanded={visible}
            {...this.props}
            filterOperators={formatFiterOperators(isGQL, FILTER_OPERATORS)}
          />
        </Modal>
      </div>
    );
  }
}

export default withTranslate(BaseFilter);
