export const managementPaymentTypes = (translate, value) => {
  switch (value) {
    case 'paymentTypesManagement-content':
      return `${translate('page.adminPanel.pageContent')}`;
    case 'paymentTypesManagement-btnAdd':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.add')}`;
    case 'paymentTypesManagement-btnDelete':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.delete')}`;
    case 'paymentTypesManagement-btnChange':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.change')}`;
    case 'paymentTypesManagement-history':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate('page.managementPaymentTypes.changesHistory')}`;
    default:
      return;
  }
};
