import React, { PureComponent } from 'react';
import { Button, Modal, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../Input';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';

import './gridTransactions.scss';

const api = new ApiService();

class MergeTransactionsModal extends PureComponent {
  state = {
    formState: {
      firstTransaction: this.props.dialogStuff.firstTransactionId,
      secondTransaction: this.props.dialogStuff.secondTransactionId,
    },
    formErrors: { firstTransaction: '', secondTransaction: '' },
    isLoading: false,
  };

  filedValidation = (value) => {
    const { translate } = this.props;

    if (!Number.isInteger(+value) || value.includes('.')) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    return '';
  };

  renderNumberInput = (name) => {
    const { formState, formErrors } = this.state;

    return (
      <div className="GridTransactionsSplittingTransactionModal-input">
        <CustomInput
          formState={formState}
          name={name}
          onChangeFormState={(name, value) => {
            this.setState(({ formState }) => ({ formState: { ...formState, [name]: value } }));
          }}
          formErrors={formErrors}
          setError={(name, value) => {
            this.setState(({ formErrors }) => ({ formErrors: { ...formErrors, [name]: value } }));
          }}
          validators={{ [name]: this.filedValidation }}
        />
      </div>
    );
  };

  splitTransaction = async () => {
    const { translate, closeModal, onRefresh } = this.props;
    const {
      formState: { secondTransaction, firstTransaction },
    } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.divideTransaction({
        transactionIdFirst: firstTransaction,
        transactionIdSecond: secondTransaction,
        transactionIdUnionResult: 0,
      });
      await message.success(translate('page.transactions.transactionsSuccessfullyMerged'), 2);
      this.setState({ isLoading: false });
      onRefresh();
      closeModal();
    } catch (error) {
      const { showError } = this.props;

      showError(error);
      this.setState({ isLoading: false });
    }

    console.log('REQ!');
  };

  render() {
    const { visible, translate, closeModal } = this.props;
    const { formErrors, formState, isLoading } = this.state;

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={translate('page.transactions.mergingTransactions')}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={this.splitTransaction}
            disabled={Object.values(formErrors).some(Boolean) || !Object.values(formState).every(Boolean)}
          >
            {translate('page.transactions.merge')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.firstTransaction')}
          </div>

          {this.renderNumberInput('firstTransaction')}
        </div>

        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.secondTransaction')}
          </div>

          {this.renderNumberInput('secondTransaction')}
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(MergeTransactionsModal);
