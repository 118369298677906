import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Input, InputNumber, Select, Checkbox, message } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import '../../profiles/modalProfile.scss';
import '../remoteSettings/cellValue/modalCellValue.scss';
import LoadingPanel from '../../../../components/loader';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { nominals, selectDataNominalsConfig, selectDataServicesConfig } from './constants';
import BanknotesDropdownNormalizersService from './helper';
import './style.scss';

const api = new ApiService();

class ModalAddNewBanknotesSettings extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    isRiskService: false,
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  updateModelNominalByIds(ids, model) {
    ids.forEach((id) => {
      const selectedNominal = nominals.find((nominal) => nominal.Id === id);

      if (selectedNominal) {
        const key = selectedNominal.value.toLowerCase();
        if (model.hasOwnProperty(key)) {
          model[key] = true;
        }
      }
    });

    return model;
  }

  mapModels(terminals, services) {
    return terminals.map((terminal) =>
      services.map((service) => ({
        terminalId: terminal,
        serviceId: service,
        nominal1: false,
        nominal2: false,
        nominal5: false,
        nominal10: false,
        nominal20: false,
        nominal50: false,
        nominal100: false,
        nominal200: false,
        nominal500: false,
        nominal1000: false,
      })),
    );
  }

  onSaveProp = async () => {
    const { showError, terminals, closeModal, formState, refetch, translate } = this.props;

    const { selectDataNominalData, selectDataServices } = formState;
    const { isRiskService } = this.state;

    try {
      this.toggleLoader();

      const arrModal = this.mapModels(terminals, isRiskService ? [0] : selectDataServices).flat();

      const updateModel = arrModal.map((item) => this.updateModelNominalByIds(selectDataNominalData, item));

      const response = await api.terminalsServicesDenominationAdd(updateModel);
      message.success(`${translate(`page.banknotesRestrictions.successAdd`)}`, 2.5);

      closeModal();
      refetch();
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    const { onChangeFormState } = this.props;

    onChangeFormState(fieldName, selectItems);
  };

  render() {
    const { translate, visible, closeModal, formState, selectDataServicesData } = this.props;
    const { loading, isLoading, isRiskService } = this.state;

    const disableSave = isRiskService
      ? formState.selectDataNominalData.length === 0
      : formState.selectDataServices.length === 0 || formState.selectDataNominalData.length === 0;

    return (
      <>
        <Modal
          title={translate('page.banknotesRestrictions.addNew')}
          visible={visible}
          centered
          onCancel={closeModal}
          className="banknotesSettingsModal"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} disabled={disableSave} onClick={this.onSaveProp}>
              {translate('core.buttonTitles.save')}
            </Button>,
          ]}
        >
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('page.banknotesRestrictions.riskService')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18} style={{ marginBottom: 10 }}>
              <Checkbox
                value={isRiskService}
                onChange={() => {
                  this.setState({ isRiskService: !isRiskService });
                }}
                disabled={formState.selectDataServices.length > 0}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('page.commissionsServices.chooseService')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18} style={{ marginBottom: 10 }}>
              {selectDataServicesData.length !== 0 && (
                <GridDropdown
                  data={BanknotesDropdownNormalizersService.normalizeService(selectDataServicesData, translate)}
                  colConfig={selectDataServicesConfig}
                  selectItems={formState.selectDataServices}
                  onSave={this.getSelectChangeCallback('selectDataServices')}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                  disabled={isRiskService}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('page.banknotesRestrictions.chooseNominal')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <GridDropdown
                data={nominals.map((item) => ({ ...item, Name: translate(item.Name) }))}
                colConfig={selectDataNominalsConfig}
                selectItems={formState.selectDataNominalData}
                onSave={this.getSelectChangeCallback('selectDataNominalData')}
                defaultTitle={translate('page.banknotesRestrictions.chooseNominal')}
              />
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalAddNewBanknotesSettings);
