export const ALL_GRID_COLUMNS = [
  'profileId',
  'profileName',
  'serviceId',
  'serviceName',
  'commissionId',
  'commissionName',
  'dateStart',
  'dateEnd',
  'active',
  'clientCommissionRule',
  'priority',
  'datePost',
  'paymentType',
  'userNamePost',
];
