const admin = (translate, value) => {
  switch (value) {
    case 'admin-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'admin-limits':
      return `${translate('page.adminPanel.type-navLink')}: ${translate('page.adminPanel.tab-restrictions')}`;
  }
};

export default admin;
