class ServicesAdditionalPropsService {
  normalizeCurrentProps = (additionalProps) => {
    if (!additionalProps || !additionalProps.length) {
      return [];
    }

    return additionalProps.map((prop) => ({ ...prop, Id: `${prop.Name}-${prop.Value}` }));
  };

  normalizeProps = (additionalProps) => {
    if (!additionalProps || !additionalProps.length) {
      return [];
    }

    return additionalProps.map(({ Key, Description }) => ({ Id: Key, Name: Key, Description }));
  };
}

export default new ServicesAdditionalPropsService();
