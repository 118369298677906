import React, { Component } from 'react';
import { Row, Col, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { withTranslate } from '../../../contexts/localContext';
import './changeComission.scss';

const { Option } = Select;

class ScalableComission extends Component {
  static defaultProps = {
    data: [],
  };
  static propTypes = {
    data: PropTypes.array,
    onSelectChangeScalable: PropTypes.func.isRequired,
  };

  onChangeMinClient = (minClientScalableCommissionAmount) => {
    this.props.onChangeByName('minClientScalableCommissionAmount', minClientScalableCommissionAmount);
    const newDealerComission = minClientScalableCommissionAmount * -0.3;
    this.props.onChangeByName('minDealerScalableCommissionAmount', newDealerComission.toFixed(1));
  };

  render() {
    const {
      translate,
      data,
      clientScalableCommissionAmount,
      onSelectChangeScalable,
      dealerScalableCommissionAmount,
      minClientScalableCommissionAmount,
      minDealerScalableCommissionAmount,
      isDisabled,
    } = this.props;
    return (
      <Row
        className={`fixedComission-wrapper ${isDisabled}`}
        type="flex"
        align="middle"
        justify="space-between"
        gutter={24}
      >
        <Col xs={24} lg={12}>
          <Row type="flex" align="middle" justify="space-between" className="row-md-mt-20" gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Row type="flex" align="middle">
                <p>{translate('grids.addingAddressedPaymentApplications.column-commissionClient')}, %</p>
                <InputNumber
                  min={0}
                  className="fixedComission--select"
                  value={minClientScalableCommissionAmount}
                  onChange={this.onChangeMinClient}
                  precision={1}
                  step="0.1"
                />
                {/* <p style={{ marginLeft: '5px' }}>%</p> */}
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Row type="flex" align="middle">
                <p>
                  min,
                  {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                </p>
                <Select
                  value={clientScalableCommissionAmount}
                  className="fixedComission--select"
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  onChange={onSelectChangeScalable}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                >
                  {data.map((el) => {
                    return (
                      <Option
                        key={`fixedComission-client-${el.ClientCommissionAmount}`}
                        value={el.ClientCommissionAmount}
                      >
                        {el.ClientCommissionAmount}
                      </Option>
                    );
                  })}
                </Select>
                {/* <p style={{ marginLeft: '5px' }}>
                  {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                </p> */}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row type="flex" align="middle" justify="space-between" className="row-md-mt-20" gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Row type="flex" align="middle">
                <p>{translate('grids.addingAddressedPaymentApplications.column-commissionDealer')}, %</p>
                <InputNumber
                  // min={0}
                  disabled
                  className="fixedComission--select"
                  value={minDealerScalableCommissionAmount}
                  onChange={this.onChangeMinClient}
                  precision={1}
                  step="0.1"
                />
                {/* <p style={{ marginLeft: '5px' }}>%</p> */}
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Row type="flex" align="middle">
                <p>
                  min,
                  {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                </p>
                <Select
                  value={dealerScalableCommissionAmount}
                  disabled
                  className="fixedComission--select"
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  onChange={onSelectChangeScalable}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                >
                  {data.map((el) => {
                    return (
                      <Option
                        key={`fixedComission-dealer-${el.DealerCommissionAmount}`}
                        value={el.DealerCommissionAmount}
                      >
                        {el.DealerCommissionAmount}
                      </Option>
                    );
                  })}
                </Select>
                {/* <p style={{ marginLeft: '5px' }}>
                  {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                </p> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslate(ScalableComission);
