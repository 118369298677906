import React, { PureComponent } from 'react';
import { Button, message, Modal, Input } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './TechnicalPartnersGrid.styled.scss';
import CustomInput from '../../../components/Input/Input';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { MFO_COLUMN_CONFIG, SETTLEMENT_COLUMN_CONFIG } from './constants';
import TechnicalPartnersService from './TechnicalPartners.service';

const api = new ApiService();
const { TextArea } = Input;

class TechnicalPartnersUpdateDialog extends PureComponent {
  state = {
    isLoading: false,
    settlementTypes: [],
    MFOList: [],
  };

  fetchDropdownsInfo = async () => {
    try {
      await this.setState({ isLoading: true });
      const settlementTypes = await api.getTechnicalPartnerSettlementTypes();
      const MFOList = await api.getMFOs();
      this.setState({ settlementTypes, MFOList, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchDropdownsInfo();
  }

  renderInput = (name, isDisabled = false) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="TechnicalPartnersGrid-input">
        <CustomInput
          formState={formState}
          disabled={isDisabled}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={TechnicalPartnersService.getValidators(translate)}
          name={name}
        />
      </div>
    );
  };

  renderInputRow = ({ label, inputComponent, className }) => {
    const { translate } = this.props;

    return (
      <div className={`TechnicalPartnersGrid-inputRow ${className || ''}`}>
        <div className="TechnicalPartnersGrid-label">{translate(label)}</div>
        {inputComponent}
      </div>
    );
  };

  updateTechnicalPartner = async () => {
    const {
      formState: { Id, Name, SettlementType, Contract, Edrpo, Mfo, Iban, Narrative },
      variant,
      onRefresh,
      closeModal,
      onError,
      translate,
    } = this.props;

    const isAddAction = variant === 'add';

    try {
      await this.setState({ isLoading: true });
      await api.validateIban(Iban);

      await api.technicalPartnerMerge({
        Id: isAddAction ? null : Id,
        Name,
        Contract,
        Edrpo,
        Mfo: Mfo[0],
        Iban,
        Narrative,
        SettlementType: SettlementType[0],
        forDelete: false,
      });

      this.setState({ isLoading: false });
      closeModal();
      message.success(
        translate(
          `page.technicalPartners.${
            variant === 'add' ? 'technicalPartnerSuccessfulAdded' : 'technicalPartnerSuccessfulEdited'
          }`,
        ),
        2.5,
      );
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });

      if (error.data && error.data.Message === 'INVALID_IBAN') {
        onError('Iban', translate('page.bankDetails.error-iban'));
        return;
      }

      showError(error);
    }
  };

  render() {
    const { variant, closeModal, translate, formState, onChangeFormState, formErrors } = this.props;
    const { isLoading, settlementTypes, MFOList } = this.state;
    const isAddAction = variant === 'add';
    const isEmptyValueExist = Object.values(formState).some((value) => (Array.isArray(value) ? !value.length : !value));
    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.technicalPartners.${isAddAction ? 'addTechnicalPartner' : 'editTechnicalPartner'}`)}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={this.updateTechnicalPartner}
            disabled={isEmptyValueExist || isErrorExist}
          >
            {translate(`core.buttonTitles.${isAddAction ? 'add' : 'edit'}`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        className="TechnicalPartnersGrid"
      >
        <div>
          {!isAddAction &&
            this.renderInputRow({
              label: 'page.technicalPartners.col-id',
              inputComponent: this.renderInput('Id', true),
            })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-name',
            inputComponent: this.renderInput('Name'),
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-calculation',
            inputComponent: (
              <GridDropdown
                data={settlementTypes}
                colConfig={SETTLEMENT_COLUMN_CONFIG}
                selectItems={formState.SettlementType}
                onSave={(selectedItems) => {
                  onChangeFormState('SettlementType', selectedItems);
                }}
                defaultTitle={translate('page.technicalPartners.selectCalculation')}
                isSingle
              />
            ),
            className: 'TechnicalPartnersGrid-settlementTypeSelect',
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-contractNumber',
            inputComponent: this.renderInput('Contract'),
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-EDRPOU',
            inputComponent: this.renderInput('Edrpo'),
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-MFO',
            inputComponent: (
              <GridDropdown
                data={MFOList}
                colConfig={MFO_COLUMN_CONFIG}
                selectItems={formState.Mfo}
                onSave={(selectedItems) => {
                  onChangeFormState('Mfo', selectedItems);
                }}
                defaultTitle={translate('page.technicalPartners.selectMFO')}
                isSingle
              />
            ),
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-IBAN',
            inputComponent: this.renderInput('Iban'),
          })}
          {this.renderInputRow({
            label: 'page.technicalPartners.col-purposePayment',
            inputComponent: (
              <TextArea
                onChange={({ target: { value } }) => onChangeFormState('Narrative', value)}
                value={formState.Narrative}
                onPressEnter={(e) => {
                  e.preventDefault();
                }}
              />
            ),
          })}

          {isLoading && <LoadingPanel />}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(TechnicalPartnersUpdateDialog);
