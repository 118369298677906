import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { Button, message, Row } from 'antd';
import checkElement from '../../../utils/checkElement';
import LimitsAddDialog from './LimitsAddDialog';
import ChangeLimitDialog from './ChangeLimitDialog';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';

const api = new ApiService();

class ServiceLimitsGrid extends PureComponent {
  currentArray = findArray('serviceLimits');

  state = {
    isLoading: false,
    serviceLimits: [],
    selectedItems: [],
    currentVisibleDialog: '',
  };

  componentDidMount() {
    this.getLimits();
  }

  getLimits = async () => {
    try {
      await this.setState({ isLoading: true });
      const serviceLimits = await api.getServicesLimits();
      await this.setState({
        serviceLimits: serviceLimits && serviceLimits.length ? serviceLimits : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  renderToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row>
        <div className="ServiceLimitsGrid">
          {checkElement('serviceLimits-addLimit', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ currentVisibleDialog: 'addLimits' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('serviceLimits-changeLimit', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ currentVisibleDialog: 'changeLimits' });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}
          {checkElement('serviceLimits-deleteLimit', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length !== 1}
              onClick={() => {
                this.setState({ currentVisibleDialog: 'deleteLimits' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handleDelete = async () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.deleteServiceLimit(selectedItems[0].ServiceId);
      await this.setState({
        isLoading: false,
      });
      this.setState({ currentVisibleDialog: '' });
      message.success(translate('page.limitsService.successfullyDeleted'), 2);
      this.getLimits();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { translate } = this.props;
    const { serviceLimits, isLoading, currentVisibleDialog, selectedItems } = this.state;

    if (!checkElement('serviceLimits-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={serviceLimits}
          onRefresh={this.getLimits}
          toolbar={this.renderToolbar()}
          isLoading={isLoading}
          name="serviceLimitsGrid"
          fieldForSelection="ServiceId"
          onSelect={this.handleSelectionChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            showAllSelected
          />
          <GridColumn field="ServiceId" title="page.limitsService.col-serviceID" width="120" filter="numeric" />
          <GridColumn field="ServiceName" title="page.limitsService.col-serviceName" width="360" />
          <GridColumn field="DailyLimit" title="page.limitsService.col-dailyLimit" width="140" filter="numeric" />
          <GridColumn field="UserName" title="page.limitsService.col-performer" width="140" />
          <GridColumn
            field="DatePost"
            title="page.limitsService.col-changeDate"
            width="180"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        </Grid>
        {currentVisibleDialog === 'addLimits' && (
          <LimitsAddDialog
            visible={currentVisibleDialog === 'addLimits'}
            onClose={() => {
              this.setState({ currentVisibleDialog: '' });
            }}
            onRefresh={this.getLimits}
          />
        )}
        {currentVisibleDialog === 'changeLimits' && (
          <ChangeLimitDialog
            visible={currentVisibleDialog === 'changeLimits'}
            onClose={() => {
              this.setState({ currentVisibleDialog: '' });
            }}
            onRefresh={this.getLimits}
            selectServices={selectedItems}
          />
        )}
        {currentVisibleDialog === 'deleteLimits' && (
          <DeleteModal
            visible={currentVisibleDialog === 'deleteLimits'}
            closeModal={() => {
              this.setState({ currentVisibleDialog: '' });
            }}
            submitCallback={this.handleDelete}
            deleteContent={translate('page.limitsService.youAreSureDelete')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ServiceLimitsGrid);
