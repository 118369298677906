const backupButtons = (translate, value) => {
  switch (value) {
    case 'backupButtons-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'backupButtons-editTemplate':
      return `${translate('page.adminPanel.dropdown-button')}:
                        ${translate('page.backupButtons.col-backupButtonTemplate')}`;
    case 'backupButtons-deleteTemplate':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('grids.profilesMainProfileList.modal-delete-template-title')}`;
    case 'backupButtons-assignTemplate':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('grids.profilesMainProfileList.modal-assign-template-title')}`;
    case 'backupButtons-createTemplate':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('grids.profilesMainProfileList.modal-create-template-title')}`;
    case 'backupButtons-changeTemplate':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.backupButtons.tab-edit-template')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}`;
    default:
      return value;
  }
};
export default backupButtons;
