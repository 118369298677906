class ManageMerchantCallbacksService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.merchantCallbackRules) {
      return { callbacks: [], hasNextPage: false, hasPreviousPage: false };
    }

    const callbacks = data.data.merchantCallbackRules;
    const pageInfo = callbacks.pageInfo;

    const resultCallbacks =
      callbacks.items && callbacks.items.length
        ? callbacks.items.map((callback) => ({ ...callback, editId: callback.id, id: callback.id }))
        : [];

    return {
      callbacks: resultCallbacks,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };

  getGQLResponseItems = (data) => {
    if (!data || !data.data || !data.data.merchantCallbackItems) {
      return { callbacks: [] };
    }

    return {
      callbacks: data.data.merchantCallbackItems.items,
    };
  };

  getGQLResponseItemsSets = (data) => {
    if (!data || !data.data || !data.data.merchantCallbackParamSets) {
      return { setsItems: [] };
    }

    const items = data.data.merchantCallbackParamSets;

    return {
      setsItems: items.items,
    };
  };
}

export default new ManageMerchantCallbacksService();
