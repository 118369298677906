import React, { createContext, Component } from 'react';

import { ApiService } from '../services';

const api = new ApiService();

const ErrorsCodesContext = createContext({
  errorsCodes: [],
});

export class ErrorsCodesContextProviderContainer extends Component {
  state = {
    errorsCodes: [],
  };

  componentDidMount() {
    this.fetchErrorCodes();
  }

  fetchErrorCodes = async () => {
    try {
      const errorsCodes = await api.getErrorCodes(null);

      if (errorsCodes.length) {
        this.setState({ errorsCodes });
      }
    } catch (error) {
      console.log('Error in ErrorCodeRequest');
    }
  };

  render() {
    const { errorsCodes } = this.state;
    const { children } = this.props;

    const errorsCodesContextValue = {
      errorsCodes,
    };

    return <ErrorsCodesContext.Provider value={errorsCodesContextValue}>{children}</ErrorsCodesContext.Provider>;
  }
}

export const widthErrorsCodesContext = (Component) => (props) =>
  <ErrorsCodesContext.Consumer>{(context) => <Component {...props} {...context} />}</ErrorsCodesContext.Consumer>;
