class AddPaymentService {
  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  integerNumberValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue) || this.checkValue(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    return '';
  };

  bAccountValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue) || this.checkValue(normalizedValue)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }
    const bAccountRegex = /^\d{5,19}$/;
    if (!bAccountRegex.test(value)) return `${translate('page.externalOutgoingPayments.valid-bAccount')}`;
  };

  bIdClientValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue) || this.checkValue(normalizedValue)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }
    const regex = /^\d{8}$|^\d{10}$/;
    if (!regex.test(value)) return `${translate('page.externalOutgoingPayments.valid-bIdClient')}`;
  };

  numberValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }
    if (this.checkValue(normalizedValue)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }
    const regex = /^\d{10}$/;
    if (!regex.test(value)) return `${translate('page.externalOutgoingPayments.valid-number')}`;
  };

  checkValue = (value) => {
    if (!value) return true;
    if (`${value}`.trim().length === 0) return true;
    return false;
  };

  numberFloatValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue) || this.checkValue(normalizedValue)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }

    if (`${normalizedValue}`.indexOf('.') > -1 && `${normalizedValue}`.split('.')[1].length > 2) {
      return `${translate('page.addServiceByBigTable.valueAfterDotErrorTwo')}`;
    }

    return '';
  };

  bNameValidation = (translate) => (value) => {
    if (this.checkValue(value)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }

    const regex = /[a-zA-Z,]/;
    if (regex.test(value)) return `${translate('page.externalOutgoingPayments.valid-bName')}`;
    if (value.length > 38) {
      return `${translate('page.externalOutgoingPayments.valid-bName-max-length')}`;
    }
  };

  narrativeValidation = (translate) => (value) => {
    if (this.checkValue(value)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }
    if (value.length > 160) {
      return `${translate('page.externalOutgoingPayments.valid-narrative-max-length')}`;
    }
  };

  narrativeValidation = (translate) => (value) => {
    if (this.checkValue(value)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }
    if (value.length > 160) {
      return `${translate('page.externalOutgoingPayments.valid-narrative-max-length')}`;
    }
  };

  textValidation = (translate) => (value) => {
    if (this.checkValue(value)) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }
  };
  payerIdValidation = (translate) => (value) => {
    if (!value) return;
    const regex = /^\d{8}$|^\d{10}$/;
    if (!regex.test(value)) return `${translate('page.externalOutgoingPayments.valid-bIdClient')}`;
  };
  getValidators = (translate) => {
    return {
      paymentId: this.integerNumberValidation(translate),
      amount: this.numberFloatValidation(translate),
      bAccount: this.bAccountValidation(translate), //5-19 символів
      aAccountId: this.integerNumberValidation(translate),
      bIdClient: this.bIdClientValidation(translate), //8 aбо 10 символів
      number: this.numberValidation(translate), // 10 символів
      bName: this.bNameValidation(translate),
      payerId: this.payerIdValidation(translate),
      narrative: this.narrativeValidation(translate),
      comments: this.textValidation(translate),
      bMfo: this.textValidation(translate),
    };
  };

  normalizeSelectDataAccountId = (data, fieldId, fieldName, fieldAccount, fieldMfo) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      Id: dataItem[fieldId],
      Name: dataItem[fieldName],
      BankAccount: dataItem[fieldAccount],
      BankMfo: dataItem[fieldMfo],
    }));
  };

  normalizeSelectData = (data, fieldId, fieldName) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      Id: dataItem[fieldId],
      Name: dataItem[fieldName],
    }));
  };
}

export default new AddPaymentService();
