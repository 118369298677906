import accountManagement from './translations/accountManagement';
import activityMonitor from './translations/activityMonitor';
import dashboard from './translations/dashboard';
import allEncashments from './translations/allEncashments';
import certificates from './translations/certificates';
import commission from './translations/commission';
import createTerminal from './translations/createTerminal';
import encashment from './translations/encashment';
import reportbyday from './translations/reportbyday';
import reportonservice from './translations/reportonservice';
import reportTurnByTerminals from './translations/reportTurnByTerminals';
import screenControlBusinessServices from './translations/screenControlBusinessServices';
import screenControlProfiles from './translations/screenControlProfiles';
import screenControlTerminals from './translations/screenControlTerminals';
import searchtransactions from './translations/searchtransactions';
import techInform from './translations/tech-inform';
import terminalOperabilityPeriod from './translations/terminalOperabilityPeriod';
import whiteList from './translations/whiteList';
import messages from './translations/messages';
import addingAdressedPayment from './translations/addingAdressedPayment';
import screenControlSettings from './translations/screenControlSettings';
import screenControlTerminalsConfig from './translations/screenControlTerminalsConfig';
import terminalsMaintenance from './translations/terminalsMaintenance';
import handlingCommissions from './translations/handlingCommissions';
import commissionProfiles from './translations/commissionProfiles';
import cashDept from './translations/cashDept';
import dictionaryCities from './translations/dictionaryCities';
import changingCommissionProfileHistory from './translations/changingProfileHistory';
import menegmentCommissionTerminals from './translations/menegmentCommissionTerminals';
import monitoringCommissionServices from './translations/monitoringCommissionServices';
import dealerCommission from './translations/dealerCommission';
import dictionaryService from './translations/dictionaryServices';
import cashCollectors from './translations/cashCollectors';
import terminalsByServices from './translations/terminalsByServices';
import remainingTerminals from './translations/remainingTerminals';
import terminalsByDays from './translations/terminalsByDays';
import dispenserCollection from './translations/dispenserCollection';
import releases from './translations/releases';
import users from './translations/users';
import backupButtons from './translations/backupButtons';
import nonRiskLimits from './translations/nonRiskLimits';
import fishkaService from './translations/fishka';
import cashDebtByCollectors from './translations/cashDebtByCollectors';
import serviceManagement from './translations/serviceManagement';
import { dealerCommissionsServices } from './translations/dealerCommissionsServices';
import { dealerCommissionsPaymentTools } from './translations/dealerCommissionsPaymentTools';
import suspendedFinancialTransactions from './translations/suspendedFinancialTransactions';
import { exceptionFinMonLimits } from './translations/exceptionFinMonLimits';
import NBUServicesRelations from './translations/NBUServicesRelations';
import ServiceLimits from './translations/ServiceLimits';
import SubBrandsTerminals from './translations/subBrandsTerminals';
import admin from './translations/admin';
import BlockedTransactions from './translations/BlockedTransactions';
import financialTransactions from './translations/financialTransactions';
import monitoringCabinet from './translations/monitoringCabinet';
import suppliersBalance from './translations/suppliersBalance';
import { recalculationTransaction } from './translations/recalculationTransaction';
import ServicesAuthorizationLimits from './translations/servicesAuthorizationLimits';
import { managementPaymentTypes } from './translations/managmentPaymentTypes';
import dalerCommissionsTerminals from './translations/dealerCommissionsTerminals';
import dealerCommissionsHistory from './translations/dealerCommissionsHistory';
import authorizationsDictionary from './translations/authorizationsDictionary';
import OTPServices from './translations/otpServices';
import checkTemplateAction from './translations/checkTemplateAction';
import templateServices from './translations/templateServices';
import templateTerminals from './translations/templateTerminals';
import dealerCommissionsExpectedChanges from './translations/dealerCommissionsExpectedChanges';
import paymentTypesDictionaryGrid from './translations/paymentTypesDictionaryGrid';
import serviceGroupsDictionary from './translations/serviceGroupsDictionary';
import cardRouting from './translations/cardRouting';
import managementMenuProfiles from './translations/managementMenuProfiles';
import { terminalProfiles } from './translations/terminalProfiles';
import menuAudit from './translations/menuAudit';
import menuElementPropDictionary from './translations/menuElementPropDictionary';
import navigationMenuPropGrid from './translations/navigationMenuPropGrid';
import easyPayUsers from './translations/easyPayUsers';
import managementErrorCodes from './translations/managementErrorCodes';
import partnerInfoSender from './translations/partnerInfoSender';
import previewApplication from './translations/previewApplication';
import manageMerchantCallbacks from './translations/manageMerchantCallbacksGrid';
import reportByPaymentTypes from './translations/reportByPaymentTypes';
import MFODictionary from './translations/MFODictionary';
import technicalPartners from './translations/technicalPartners';
import serviceSupplier from './translations/serviceSupplier';
import { beneficiaries } from './translations/benefeciaries';
import { directors } from './translations/directors';
import fiscalizedTransactionsDaysReport from './translations/fiscalizedTransactionsDaysReport';
import FiscalizedTransactionsOwnersDaysReport from './translations/fiscalizedTransactionsOwnersDaysReport';
import externalOutgoingPayments from './translations/externalOutgoingPayments';
import terminalsActiveServices from './translations/terminalsActiveServices';
import remoteTerminalSettings from './translations/remoteTerminalSettings';
import mfoTransactions from './translations/mfoTransactions';

const translateElements = (translate, value, webTag) => {
  switch (webTag) {
    case 'accountmanager':
      return accountManagement(translate, value, webTag);

    case 'dashboard':
      return dashboard(translate, value);

    case 'activitymonitor':
      return activityMonitor(translate, value);

    case 'allEncashments':
      return allEncashments(translate, value);

    case 'certificates':
      return certificates(translate, value);

    case 'commissionClient':
      return commission(translate, value);

    case 'createTerminal':
      return createTerminal(translate, value);

    case 'encashment':
      return encashment(translate, value);

    case 'reportbyday':
      return reportbyday(translate, value);

    case 'reportonservice':
      return reportonservice(translate, value);

    case 'reportTurnByTerminals':
      return reportTurnByTerminals(translate, value);

    case 'screenControlBusinessServices':
      return screenControlBusinessServices(translate, value);

    case 'screenControlProfiles':
      return screenControlProfiles(translate, value);

    case 'screenControlTerminals':
      return screenControlTerminals(translate, value);

    case 'searchtransactions':
      return searchtransactions(translate, value);

    case 'tech-inform':
      return techInform(translate, value);

    case 'terminalOperabilityPeriod':
    case 'terminalOperabilityDay':
      return terminalOperabilityPeriod(translate, value);

    case 'whiteListAdmin':
      return whiteList(translate, value);

    case 'messages':
      return messages(translate, value);

    case 'adding-addressed-payment':
      return addingAdressedPayment(translate, value);

    case 'screenControlSettings':
      return screenControlSettings(translate, value);

    case 'screenControlTerminalsConfig':
      return screenControlTerminalsConfig(translate, value);

    case 'terminalMaintenance':
      return terminalsMaintenance(translate, value);

    case 'handlingCommissions':
      return handlingCommissions(translate, value);

    case 'сommissionManagementProfiles':
      return commissionProfiles(translate, value);

    case 'CashDept':
      return cashDept(translate, value);

    case 'changingManagementCommissionProfileHistory':
      return changingCommissionProfileHistory(translate, value);

    case 'dictionaryCities':
      return dictionaryCities(translate, value);

    case 'ManagementCommissionTerminals':
      return menegmentCommissionTerminals(translate, value);

    case 'monitoringCommissionServices':
      return monitoringCommissionServices(translate, value);

    case 'dealerCommissions':
      return dealerCommission(translate, value);

    case 'dictionaryServices':
      return dictionaryService(translate, value);

    case 'collectorsCashReport':
      return cashCollectors(translate, value);
    case 'terminalsByServices':
      return terminalsByServices(translate, value);

    case 'remainingTerminals':
      return remainingTerminals(translate, value);

    case 'terminalsByDays':
      return terminalsByDays(translate, value);

    case 'dispenserCollection':
      return dispenserCollection(translate, value);

    case 'releasePage':
      return releases(translate, value);

    case 'users':
      return users(translate, value);

    case 'backupButtons':
      return backupButtons(translate, value);

    case 'nonRiskLimits':
      return nonRiskLimits(translate, value);

    case 'fishkaService':
      return fishkaService(translate, value);

    case 'cashDebtByCollectors':
      return cashDebtByCollectors(translate, value);

    case 'serviceManagement':
      return serviceManagement(translate, value);

    case 'dealerCommissionsServices':
      return dealerCommissionsServices(translate, value);

    case 'dealerCommissionsPaymentTools':
      return dealerCommissionsPaymentTools(translate, value);

    case 'exceptionsFinMonLimit':
      return exceptionFinMonLimits(translate, value);

    case 'SuspendedFinancialTransactions':
      return suspendedFinancialTransactions(translate, value);

    case 'NBUServicesRelations':
      return NBUServicesRelations(translate, value);

    case 'serviceLimits':
      return ServiceLimits(translate, value);

    case 'terminalsSubBrands':
      return SubBrandsTerminals(translate, value);

    case 'blockedTransactions':
      return BlockedTransactions(translate, value);

    case 'financialTransactions':
      return financialTransactions(translate, value);

    case 'monitoringCabinet':
      return monitoringCabinet(translate, value);

    case 'admin':
      return admin(translate, value);

    case 'suppliersBalance':
      return suppliersBalance(translate, value);

    case 'recalculationTransactions':
      return recalculationTransaction(translate, value);

    case 'authorizationServicesLimitsGrid':
      return ServicesAuthorizationLimits(translate, value);

    case 'dealerCommissionsTerminals':
      return dalerCommissionsTerminals(translate, value);

    case 'dealerCommissionsHistory':
      return dealerCommissionsHistory(translate, value);

    case 'authorizationsDictionary':
      return authorizationsDictionary(translate, value);

    case 'OTPServices':
      return OTPServices(translate, value);

    case 'manageTemplate':
      return checkTemplateAction(translate, value);

    case 'servicesTemplate':
      return templateServices(translate, value);

    case 'templateTerminals':
      return templateTerminals(translate, value);

    case 'paymentTypesManagement':
      return managementPaymentTypes(translate, value);

    case 'dealerCommissionsExpectedChanges':
      return dealerCommissionsExpectedChanges(translate, value);

    case 'dictionaryPaymentTypes':
      return paymentTypesDictionaryGrid(translate, value);

    case 'serviceGroupsDictionary':
      return serviceGroupsDictionary(translate, value);

    case 'cardRouting':
      return cardRouting(translate, value);

    case 'managementMenuProfiles':
      return managementMenuProfiles(translate, value);

    case 'menuAudit':
      return menuAudit(translate, value);

    case 'terminalProfiles':
      return terminalProfiles(translate, value);

    case 'menuElementPropDictionary':
      return menuElementPropDictionary(translate, value);

    case 'navigationMenuProp':
      return navigationMenuPropGrid(translate, value);

    case 'easyPayUsers':
      return easyPayUsers(translate, value);

    case 'managementErrorCodes':
      return managementErrorCodes(translate, value);

    case 'partnerInfoSender':
      return partnerInfoSender(translate, value);

    case 'previewApplication':
      return previewApplication(translate, value);

    case 'manageMerchantCallbacksGrid':
      return manageMerchantCallbacks(translate, value);

    case 'reportByPaymentTypes':
      return reportByPaymentTypes(translate, value);

    case 'MFODictionary':
      return MFODictionary(translate, value);

    case 'technicalPartners':
      return technicalPartners(translate, value);

    case 'remoteTerminalSettings':
      return remoteTerminalSettings(translate, value);

    case 'serviceSuppliers':
      return serviceSupplier(translate, value);

    case 'beneficiaries':
      return beneficiaries(translate, value);

    case 'directors':
      return directors(translate, value);

    case 'fiscalizedTransactionsDaysReport':
      return fiscalizedTransactionsDaysReport(translate, value);

    case 'fiscalizedTransactionsOwnersDaysReport':
      return FiscalizedTransactionsOwnersDaysReport(translate, value);

    case 'externalOutgoingPayments':
      return externalOutgoingPayments(translate, value);

    case 'TerminalsActiveServices':
      return terminalsActiveServices(translate, value);

    case 'mfoTransactions':
      return mfoTransactions(translate, value);

    default:
      return value;
  }
};

export default translateElements;
