export const INITIAL_VALUES = {
  dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
  dateTo: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999),
  status: 0,
  EDRPOU: '',
  isRecipient: true,
  accountId: '',
  IBAN: '',
  aName: [],
  aAccountId: [],
};

export const translateSlugsSelect = {
  aName: 'page.suspendedFinancialTransactions.aName-select',
  aAccountId: 'page.suspendedFinancialTransactions.aAccountId-select',
};
export const translateSlugs = {
  aName: 'page.suspendedFinancialTransactions.aName',
  aAccountId: 'page.suspendedFinancialTransactions.aAccountId',
};

export const PAYMENT_STATUSES = [
  'page.suspendedFinancialTransactions.allStatuses',
  'page.suspendedFinancialTransactions.entered',
  'page.suspendedFinancialTransactions.sent',
  'page.suspendedFinancialTransactions.accepted',
  'page.suspendedFinancialTransactions.rejected',
  'page.suspendedFinancialTransactions.deleted',
  'page.suspendedFinancialTransactions.blocked',
];

export const PAYMENT_STATUSES_DROPDOWN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.suspendedFinancialTransactions.col-status',
    width: '240',
    isShowOnMobile: true,
  },
];
