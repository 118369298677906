import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';

class PreviewApplicationService {
  normalizeStatusApplications(applications) {
    return applications.length
      ? applications.map((item) =>
          Object.entries(item).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [uppercaseFirstLetter(key)]: value,
            }),
            {},
          ),
        )
      : [];
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.terminalRegistrationApplications) {
      return { terminalRegistrationApplicationsService: [], hasNextPage: false, hasPreviousPage: false };
    }
    const terminalRegistrationApplications = data.data.terminalRegistrationApplications;
    const terminalRegistrationApplicationsService =
      terminalRegistrationApplications.items && terminalRegistrationApplications.items.length
        ? this.normalizeStatusApplications(terminalRegistrationApplications.items)
        : [];

    const pageInfo = terminalRegistrationApplications.pageInfo;

    return {
      terminalRegistrationApplicationsService,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new PreviewApplicationService();
