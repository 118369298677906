import React, { Component } from 'react';
import { Input, Row, Button, Card, Select, Col, message, InputNumber } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import LoadingPanel from '../../../../components/loader';
import GridRemoteSettings from './gridRemoteSettings';
import '../additionalSettings/additionalSettings.scss';
import './remoteSettings.scss';

const api = new ApiService();

const { Option } = Select;

const { TextArea } = Input;

class RemoteSettings extends Component {
  constructor(props) {
    super(props);
    const {
      item: { TerminalId },
    } = props;
    this.state = {
      terminalId: TerminalId,
      isEditing: false,
      isLoading: false,
      data: null,
      skinsData: [],
      skinValue: null,
      receiptHeader: null,
      receiptFooter: null,
      collectionKey: null,
      schedulerFrequencyConnect: null,
      schedulerFrequencyUpdate: null,
    };
    this.currentArray = findArray('screenControlSettings');
  }

  componentDidMount() {
    this.initRequest();
  }

  onChangeGraphicsValue = (graphicsValue) => {
    this.setState({ graphicsValue });
  };

  initRequest = async () => {
    const { terminalId } = this.state;
    const { showError } = this.props;
    if (!terminalId) return;
    try {
      this.toggleLoader();
      const data = await api.terminalSettingsGet(terminalId);
      const skinsData = await api.terminalSkins();
      const {
        TerminalSkin: skinValue,
        ReceiptHeader: receiptHeader,
        ReceiptFooter: receiptFooter,
        CollectionKey: collectionKey,
        SchedulerFrequencyConnect: schedulerFrequencyConnect,
        SchedulerFrequencyUpdate: schedulerFrequencyUpdate,
        TerminalSettingsExtended: terminalSettingsExtended,
      } = data;

      this.setState({
        skinsData,
        skinValue,
        receiptHeader,
        receiptFooter,
        collectionKey,
        schedulerFrequencyConnect,
        schedulerFrequencyUpdate,
        terminalSettingsExtended,
      });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSubmitHandler = async () => {
    const {
      isEditing,
      terminalId,
      skinValue,
      receiptHeader,
      receiptFooter,
      collectionKey,
      schedulerFrequencyConnect,
      schedulerFrequencyUpdate,
      terminalSettingsExtended,
    } = this.state;
    const { showError, translate } = this.props;
    if (!isEditing) {
      this.setState(({ isEditing }) => {
        return {
          isEditing: !isEditing,
        };
      });
    } else {
      try {
        this.toggleLoader();
        const model = {
          TerminalId: terminalId,
          TerminalSkin: skinValue,
          ReceiptHeader: receiptHeader,
          ReceiptFooter: receiptFooter,
          CollectionKey: collectionKey,
          SchedulerFrequencyConnect: schedulerFrequencyConnect,
          SchedulerFrequencyUpdate: schedulerFrequencyUpdate,
          TerminalSettingsExtended: terminalSettingsExtended,
        };
        const response = await api.terminalSettingsSet(model);

        if (response) {
          await message.success(`${translate('page.screenControlRemSets.message-success')}`, 3, () =>
            window.location.reload(true),
          );
        } else {
          await message.error(`${translate('page.screenControlRemSets.message-failed')}`, 3);
        }

        this.setState({ isEditing: false });
        return response;
      } catch (error) {
        showError(error);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onChangeRadioValue = (e) => {
    this.setState({ radioValue: e.target.value }, () => {
      if (e.target.value === true) this.toggleGraphicModal();
    });
  };

  toggleGraphicModal = () => {
    this.setState(({ isGraphicModal }) => {
      return {
        isGraphicModal: !isGraphicModal,
      };
    });
  };

  onChangeEventInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateStateBySlug = (value, slug) => {
    this.setState({ [slug]: value });
  };

  render() {
    const {
      terminalId,
      isEditing,
      isLoading,
      skinValue,
      skinsData,
      receiptFooter,
      receiptHeader,
      schedulerFrequencyConnect,
      schedulerFrequencyUpdate,
      collectionKey,
      terminalSettingsExtended,
    } = this.state;
    const { translate } = this.props;
    return (
      <>
        <div className="newTerminal--wrapper">
          <Row
            className="newTerminal--header-row no-margin"
            type="flex"
            align="middle"
            gutter={24}
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Row
              className="newTerminal--header-row no-margin secondary"
              type="flex"
              align="middle"
              gutter={24}
              style={{ flexWrap: 'wrap' }}
            >
              <p style={{ marginBottom: '0' }}>{translate('page.terminalSettings.header-field-title')}</p>
              <Input
                onChange={this.onChangeTemplateValue}
                value={terminalId}
                disabled
                className="newTerminal--header-input"
                style={{ width: 'auto' }}
              />
            </Row>
            {checkElement('remoteSettings-btn-updateSettings', this.currentArray) && (
              <Button type="primary" className="newTerminal--btn-save" onClick={this.onSubmitHandler}>
                {!isEditing
                  ? translate('page.screenControlBusinessServices.button-edit')
                  : translate('page.screenControlBusinessServices.button-save')}
              </Button>
            )}
          </Row>
          <Row gutter={24}>
            {/* START MAIN ROW */}
            <Col className="addSet-main-col" md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
              {/* START SECOND CARD */}
              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={`${translate('dropdowns.terminal.label-title')}`}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlRemSets.field-skin')}:
                      </p>
                      <Select
                        value={skinValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '100%', marginLeft: '10px' }}
                        onChange={(value) => {
                          this.updateStateBySlug(value, 'skinValue');
                        }}
                        disabled={!isEditing}
                      >
                        {skinsData.map((el) => (
                          <Option key={`skinsData-elem-${el.Key}`} value={el.Key}>
                            {el.Value}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
              </Card>
              {/* END SECOND CARD */}

              {/* START THIRD CARD */}
              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={translate('page.screenControlRemSets.printReceipt-title')}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlRemSets.field-receiptHeader')}
                      </p>
                      <TextArea
                        className="newTerminal---additional-media-input-class"
                        value={receiptHeader}
                        name="receiptHeader"
                        onChange={this.onChangeEventInput}
                        style={{ width: '100%' }}
                        disabled={!isEditing}
                        maxLength={256}
                        size="large"
                      />
                    </Row>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text addSets-form-text">
                        {translate('page.screenControlRemSets.field-receiptFooter')}
                      </p>
                      <TextArea
                        className="newTerminal---additional-media-input-class"
                        value={receiptFooter}
                        name="receiptFooter"
                        onChange={this.onChangeEventInput}
                        style={{ width: '100%' }}
                        disabled={!isEditing}
                        maxLength={256}
                        size="large"
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
              {/* END THIRD CARD */}

              {/* START FOURTH CARD */}
              <Card
                type="inner"
                className="newTerminal--card w-50 newTerminal--columnFieldsGridForMobile"
                title={translate('page.screenControlRemSets.props-title')}
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row mt-10 row-text-input-text" type="flex" align="middle">
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text">
                          {translate('page.screenControlRemSets.field-collectionKey-title')}
                        </p>
                      </Col>
                      <Col xs={24} md={8}>
                        <InputNumber
                          className="newTerminal---additional-media-input-class"
                          value={collectionKey}
                          stringMode
                          onChange={(value) => {
                            this.updateStateBySlug(value, 'collectionKey');
                          }}
                          disabled={!isEditing}
                          maxLength={9}
                          // style={{ width: '30%' }}
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text last">
                          ({translate('page.screenControlRemSets.field-collectionKey-tooltip')})
                        </p>
                      </Col>
                    </Row>
                    <Row className="newTerminal--card-row mt-10 row-text-input-text" type="flex" align="middle">
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text">
                          {translate('page.screenControlRemSets.field-schedulerFrequencyConnect-title')}
                        </p>
                      </Col>
                      <Col xs={24} md={8}>
                        <InputNumber
                          className="newTerminal---additional-media-input-class"
                          value={schedulerFrequencyConnect}
                          onChange={(value) => {
                            this.updateStateBySlug(value, 'schedulerFrequencyConnect');
                          }}
                          stringMode
                          disabled={!isEditing}
                          max={60}
                          min={0}
                          // style={{ width: '30%' }}
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text last">
                          {translate('page.screenControlRemSets.field-schedulerFrequencyConnect-tooltip')}
                        </p>
                      </Col>
                    </Row>
                    <Row className="newTerminal--card-row mt-10 row-text-input-text" type="flex" align="middle">
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text">
                          {translate('page.screenControlRemSets.field-schedulerFrequencyUpdate-title')}
                        </p>
                      </Col>
                      <Col xs={24} md={8}>
                        <InputNumber
                          className="newTerminal---additional-media-input-class"
                          value={schedulerFrequencyUpdate}
                          onChange={(value) => {
                            this.updateStateBySlug(value, 'schedulerFrequencyUpdate');
                          }}
                          stringMode
                          disabled={!isEditing}
                          max={60}
                          min={0}
                          // style={{ width: '30%' }}
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <p className="form-text addSets-form-text last">
                          {translate('page.screenControlRemSets.field-schedulerFrequencyConnect-tooltip')}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              {/* END FOURTH CARD */}
              {/* <DropdownSkin /> */}
              <GridRemoteSettings
                skinsData={skinsData}
                isEditing={isEditing}
                terminalId={terminalId}
                terminalSettingsExtended={terminalSettingsExtended}
                updateTerminalsSettings={(value) => {
                  this.updateStateBySlug(value, 'terminalSettingsExtended');
                }}
              />
            </Col>
            {/* END MAIN ROW */}
          </Row>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(RemoteSettings);
