import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import TerminalsActiveServicesGrid from './TerminalsActiveServicesGrid';
import PageTabs from '../../components/tabber/tabber';

class TerminalsActiveServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-terminalsActiveServices',
          content: <TerminalsActiveServicesGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default withTranslate(TerminalsActiveServices);
