import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../contexts/localContext';
import SupplierCommissionsForm from '../SupplierCommissionForm/SupplierCommissionForm';
import { Button, Input, message } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ApiService } from '../../../../services';

import './SupplierCommissions.scss';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();
const { TextArea } = Input;

class SupplierCommission extends PureComponent {
  state = {
    isLoading: false,
    dateStart: new Date().setDate(new Date().getDate() + 1),
  };

  onHandleChangeDatePicker = ({ target: { value: dateStart } }) => {
    this.setState({ dateStart });
  };

  onHandlerSupplierCommissionAdd = async () => {
    const { dateStart } = this.state;
    const { formState, dataItem, translate } = this.props;
    try {
      const model = {
        ServiceId: dataItem.ServiceId,
        DateStart: new Date(dateStart),
        SupplierProviderAmountTotal: formState.SuppliersProviderAmountTotal,
        SupplierProviderPercent: +formState.SuppliersProviderPercent,
        SupplierProviderMin: +formState.SuppliersProviderMin,
        SupplierProviderMax: +formState.SuppliersProviderMax,
        SupplierCardProviderAmountTotal: formState.SuppliersProviderAmountTotalForCards,
        SupplierCardProviderPercent: +formState.SuppliersProviderPercentForCards,
        SupplierCardProviderMin: +formState.SuppliersProviderMinForCards,
        SupplierCardProviderMax: +formState.SuppliersProviderMaxForCards,
      };
      await this.setState({ isLoading: true });
      await api.serviceSupplierCommissionInsert(model);
      await this.setState({ isLoading: false });
      await message.success(`${translate('page.supplierCommissionsScale.msg-successful')}`);
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      message.error(`${translate('page.supplierCommissionsScale.msg-error')}`);
      showError(e);
    }
  };

  render() {
    const { dateStart, isLoading } = this.state;
    const { translate, dataItem, formState, onChangeFormState, formErrors, onError } = this.props;

    const isDisabled = !!Object.values(formErrors).filter((item) => item).length;

    return (
      <>
        <div className="SupplierCommissions-toolbar">
          <div className="SupplierCommissions-row">
            <div className="SupplierCommissions-col">
              <div className="SupplierCommissions-row">
                <label className="SupplierCommissions-label">{translate('page.supplierCommissions.serviceId')}</label>
                <Input disabled value={dataItem.ServiceId} className="SupplierCommissions-input" />
              </div>
              <div className="SupplierCommissions-row">
                <label className="SupplierCommissions-label">
                  {translate('page.supplierCommissions.service-name')}
                </label>
                <TextArea disabled value={dataItem.Name} className="SupplierCommissions-input" autoSize />
              </div>
            </div>

            <Button
              type="primary"
              disabled={isDisabled}
              onClick={this.onHandlerSupplierCommissionAdd}
              className="SupplierCommissions-btn"
            >
              {translate('core.buttonTitles.save')}
            </Button>
          </div>
        </div>
        <div className="SupplierCommissions-container">
          <SupplierCommissionsForm
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            onError={onError}
          />
          <div className="SupplierCommissionsForm-card">
            <div className="SupplierCommissionsForm-card-title">{translate('page.supplierCommissions.card-title')}</div>
            <div className="SupplierCommissions-row">
              <label className="SupplierCommissions-label">{translate('page.supplierCommissions.date-start')}</label>
              <DatePicker
                onChange={this.onHandleChangeDatePicker}
                value={new Date(dateStart)}
                min={new Date(dateStart)}
              />
            </div>
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SupplierCommission);
