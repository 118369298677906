import React, { PureComponent } from 'react';
import { Modal, Button, Row, Col, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class UpdateModal extends PureComponent {
  state = {
    isLoading: false,
    lastUpdateDate: null,
  };

  componentDidMount() {
    this.getUpdateDate();
  }

  getTitle = () => {
    const { variant, translate } = this.props;

    if (variant === 'juridicPersons') {
      return translate('page.blockedTransactions.updateJuridicPersons');
    }

    if (variant === 'individuals') {
      return translate('page.blockedTransactions.updateIndividuals');
    }

    return translate('page.blockedTransactions.updateTerrorists');
  };

  getPrefix = () => {
    const { variant, translate } = this.props;

    if (variant === 'juridicPersons') {
      return translate('page.blockedTransactions.updateJuridicPersonsList');
    }

    if (variant === 'individuals') {
      return translate('page.blockedTransactions.updateIndividualsList');
    }

    return translate('page.blockedTransactions.updateTerroristsList');
  };

  getSuccessMessage = () => {
    const { variant, translate } = this.props;

    if (variant === 'juridicPersons') {
      return translate('page.blockedTransactions.requestUpdateJuridicPersons');
    }

    if (variant === 'individuals') {
      return translate('page.blockedTransactions.requestUpdateIndividuals');
    }

    return translate('page.blockedTransactions.requestUpdateTerrorists');
  };

  getUpdateDate = async () => {
    const updateId = this.getUpdateId();
    try {
      await this.setState({ isLoading: true });
      const updateTime = await api.getUpdateSanction(updateId);
      await this.setState({
        lastUpdateDate: new Date(updateTime).toLocaleDateString('uk-UA', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
      });
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  getUpdateId = () => {
    const { variant } = this.props;

    if (variant === 'juridicPersons') {
      return 2;
    }

    if (variant === 'individuals') {
      return 1;
    }

    return 3;
  };

  getUpdateCallback = () => {
    const { variant } = this.props;

    if (variant === 'juridicPersons') {
      return api.legalEntitiesSanctionListUpdate;
    }

    if (variant === 'individuals') {
      return api.individualsSanctionListUpdate;
    }

    return api.terroristsSanctionListUpdate;
  };

  handleUpdate = async () => {
    const { closeModal } = this.props;

    const updateCallback = this.getUpdateCallback();

    try {
      await this.setState({ isLoading: true });
      await updateCallback();
      this.setState({ isLoading: false });
      closeModal();
      message.success(this.getSuccessMessage(), 2);
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { variant, closeModal, translate } = this.props;
    const { isLoading, lastUpdateDate } = this.state;

    return (
      <>
        <Modal
          title={this.getTitle()}
          visible={variant}
          onCancel={closeModal}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={this.handleUpdate}>
              {translate('core.buttonTitles.update')}
            </Button>,
          ]}
        >
          {lastUpdateDate && <div style={{ textAlign: 'center' }}>{`${this.getPrefix()} - ${lastUpdateDate}`}</div>}
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(UpdateModal);
