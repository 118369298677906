const ServicesAuthorizationLimits = (translate, value) => {
  switch (value) {
    case 'authorizationServicesLimitsGrid-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'authorizationServicesLimitsGrid-addItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.add`)}`;
    case 'authorizationServicesLimitsGrid-changeItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.change`)}`;
    case 'authorizationServicesLimitsGrid-changeStatusItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(
        `page.servicesAuthorizationLimits.changeStatus`,
      )}`;
    case 'authorizationServicesLimitsGrid-deleteItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    default:
      return value;
  }
};

export default ServicesAuthorizationLimits;
