import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button, Select } from 'antd';
import { ApiService, StorageService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import findArray from '../../../../utils/findArrForCurrentPage';
import ModalDeleteSettings from './modalDeleteSettings';
import ModalApplySettings from './modalApplySettings';
import ModalChooseSetting from './modalChooseSetting';
import './gridAddRemoteSettings.scss';

const api = new ApiService();

const { Option } = Select;

const mockData = [
  {
    Property: 'skin',
    Type: 'tb_terminals_skins[key_name](key_key)',
    Description: 'Скин',
    Value: 'blue',
  },
  {
    Property: 'allow. paymentmasterpassqr',
    Type: 'bool',
    Description: 'Возможность оплаты MasterpassQR',
    Value: false,
  },
  {
    Property: 'bill.denomination',
    Type: 'string',
    Description: 'Доступні номінали купюр',
    Value: '1,2,5,10,20,50,100,200,500',
  },
  {
    Property: 'receipt_header',
    Type: 'memo',
    Description: 'Верхний колонтитул',
    Value: 'some text...',
  },
  {
    Property: 'Big.Button',
    Type: 'int',
    Description: 'Большая кнопка на терминале',
    Value: 0,
  },
];

class GridAddRemoteSettings extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControl');
  }

  state = {
    isLoading: false,
    data: mockData,
    isModalDelete: false,
    isModalApply: false,
    isModalChoose: false,
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-addRemoteSettings');
  };

  onChangeData = (elem) => {
    this.setState(({ data }) => {
      return {
        data: [...data, elem],
      };
    });
  };

  makeQuery = async () => {
    try {
      this.toggleLoader(true);
      // const terminalsData = await api.terminalsMainProfileList(owners);
      this.setState({
        data: mockData,
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  onChangeSelect = (terminalsValue) => {
    this.setState({ terminalsValue });
  };

  renderSelectTerminals = () => {
    const { terminalsValue, terminalsData } = this.state;
    return (
      <Select
        className="addRemSets--select"
        dropdownStyle={{ zIndex: '1054', width: '100%', marginTop: '15px' }}
        value={terminalsValue}
        onChange={this.onChangeSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {terminalsData.map((el) => (
          <Option value={el} key={`addRemSets-term-${el}`}>
            {el}
          </Option>
        ))}
      </Select>
    );
  };

  toggleModalDelete = () => {
    this.setState(({ isModalDelete }) => {
      return {
        isModalDelete: !isModalDelete,
      };
    });
  };

  closeModalDelete = () => {
    this.setState({ isModalDelete: false });
  };

  toggleModalApply = () => {
    this.setState(({ isModalApply }) => {
      return {
        isModalApply: !isModalApply,
      };
    });
  };

  closeModalApply = () => {
    this.setState({ isModalDelete: false });
  };

  toggleModalChoose = () => {
    this.setState(({ isModalChoose }) => {
      return {
        isModalChoose: !isModalChoose,
      };
    });
  };

  closeModalChoose = () => {
    this.setState({ isModalChoose: false });
  };

  buildToolbar = () => {
    const { item } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 18 }}>
        <p
          style={{
            width: '100%',
            marginLeft: '10px',
            marginBottom: '10px',
          }}
        >
          Выбранные терминалы: {item.join(' ')}
        </p>
        <Button type="primary" onClick={this.toggleModalApply}>
          Применить удалённые настройки
        </Button>
        <Button type="primary" className="second-btn" onClick={this.toggleModalChoose}>
          Выбрать настройку
        </Button>
        <Button className="last-btn" type="primary" onClick={this.toggleModalDelete}>
          Удалить настройки
        </Button>
      </Col>
    );
  };

  render() {
    const { isLoading, data, isModalDelete, isModalApply, isModalChoose } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="addRemoteSettings"
          permissionName="screenControl"
          selectedField="selected"
          multiSelected={true}
          fieldForSelection="Property"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn field="Property" title="grids.transactionDeatails.column-name" width="200" filter="text" />

          <GridColumn field="Description" title="grids.serviceReport.column-name" width="270" />

          <GridColumn field="Type" title="grids.terminal-events.column-typeName" width="200" />

          <GridColumn field="Value" title="grids.transactionDeatails.column-value" width="250" />
        </Grid>
        {isModalDelete && (
          <ModalDeleteSettings
            visible={isModalDelete}
            closeModal={this.closeModalDelete}
            toggleModal={this.toggleModalDelete}
            item={StorageService.getItem('selectionListForGrid-addRemoteSettings')}
          />
        )}
        {isModalApply && (
          <ModalApplySettings
            visible={isModalApply}
            closeModal={this.closeModalApply}
            toggleModal={this.toggleModalApply}
            item={StorageService.getItem('selectionListForGrid-addRemoteSettings')}
          />
        )}
        {isModalChoose && (
          <ModalChooseSetting
            visible={isModalChoose}
            closeModal={this.closeModalChoose}
            toggleModal={this.toggleModalChoose}
            onChangeData={this.onChangeData}
          />
        )}
      </>
    );
  }
}

export default withTranslate(GridAddRemoteSettings);
