import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import ModalWhiteListControl from './modalWhiteListControl';
import CellEventType from './cellEventType';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
const api = new ApiService();

const WrappedCellEventType = (props) => <CellEventType {...props} />;

class GridWhiteListAdmin extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('whiteListAdmin');
  }
  state = {
    isLoading: false,
    data: [],
    owners: [],
    allOwners: [],
    visible: false,
    initialSort: { field: 'DateExec', dir: 'desc' },
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    try {
      const { owners } = this.state;
      const model = {
        OwnerId: owners,
      };
      this.toggleLoader(true);
      const data = await api.terminalsWhitelistAccountsAuditList(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={owners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('whiteListAdmin-btn-control', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.toggleModal}>
            {translate('grids.whiteListAdmin.btn-control-1')}
          </Button>
        )}
      </Col>
    );
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  render() {
    const { isLoading, data, initialSort, visible, owners } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="whiteListAdmin"
          permissionName="whiteListAdmin"
          selectedField="selected"
          initialSort={initialSort}
        >
          <GridColumn
            field="TerminalId"
            title="grids.terminalsMainProfileList.column-terminalId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="180" filter="numeric" />

          <GridColumn field="ServiceName" title="grids.comission-services.column-service-name" width="200" />

          <GridColumn field="PhoneAccounts" title="grids.whiteListAdmin.column-phoneAccounts" width="270" />

          <GridColumn field="CardAccounts" title="grids.whiteListAdmin.column-cardAccounts" width="270" />

          <GridColumn field="OwnerId" title="grids.account.column-ownerId" width="150" filter="numeric" />

          <GridColumn field="OwnerName" title="grids.allEncashments.column-ownername" width="150" />

          <GridColumn
            field="DateExec"
            title="grids.encashment.column-date"
            width="150"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="OperationType"
            title="grids.whiteListAdmin.column-operationType"
            width="150"
            cell={WrappedCellEventType}
          />

          <GridColumn field="UserIdName" title="grids.profilesMainProfileList.column-executor" width="150" />

          <GridColumn field="Comment" title="grids.transactions.column-comment" width="150" />
        </Grid>
        {visible && <ModalWhiteListControl visible={visible} closeModal={this.closeModal} owners={owners} />}
      </>
    );
  }
}

export default withTranslate(GridWhiteListAdmin);
