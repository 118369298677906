const DEFAULT_CUSTOM_TIME = { h: 0, m: 0, s: 0, ms: 0 };

export const getCustomDate = ({
  customTime = DEFAULT_CUSTOM_TIME,
  customDayOfMonth,
  customDateMonth,
  customDateFullYear,
} = {}) => {
  const { h: userHours, m: userMinutes, s: userSeconds, ms: userMilliSeconds } = customTime;
  const nowDate = new Date();
  const nowDateFullYear = nowDate.getFullYear();
  const nowDateMonth = nowDate.getMonth();
  const nowDayOfMonth = nowDate.getDate();

  return new Date(
    Number.isInteger(customDateFullYear) ? customDateFullYear : nowDateFullYear,
    Number.isInteger(customDateMonth) ? customDateMonth : nowDateMonth,
    Number.isInteger(customDayOfMonth) ? customDayOfMonth : nowDayOfMonth,
    userHours || 0,
    userMinutes || 0,
    userSeconds || 0,
    userMilliSeconds || 0,
  );
};
