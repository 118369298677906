import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Row, Col, Input, Select, Checkbox, Button as AntdButton, message } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { withRouter } from 'react-router-dom';

import { ApiService, tabService, StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import DateRange from '../../daterange';
import Grid from '../baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import AlertModal from './AlertModal';
import AdditionalTransactionsPropsModal from '../../AdditionalTransactions/AdditionalTransactionsPropsModal';
import { EmailReceiptsModal, ViberReceiptModal, DownloadReceiptPDFService } from '../../TransactionReceiptActions';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import GridDropdownWithDeletedOwners from '../../GridDropdown/GridDropdownWithDeletedOwners';
import ExpandSearchOptionsDialog from './ExpandSearchOptionsDialog';
import SplittingTransactionModal from './SplittingTransactionModal';
import MergeTransactionsModal from './MergeTransactionsModal';
import { TRANSACTION_PROPS, TRANSACTION_FIELDS_INFO, ADDITIONAL_TRANSACTION_PROPS } from './constants';
import AddCommentDialog from './AddCommentDialog';

import ChequeNumberMaskedInput from './СhequeNumberMaskedInput';

import './gridTransactions.scss';
import './gridTransactions.scss';
import EditTransactionModal from './EditTransactionModal';
import InformationModal from './InformationModal';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';

const { Option } = Select;
const api = new ApiService();
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
const aggregate = [
  { field: 'transactionId', aggregate: 'count', format: 'n0' },
  { field: 'terminalId', aggregate: 'count', format: 'n0' },
  { field: 'amountTotal', aggregate: 'sum', format: 'n2' },
  { field: 'amountOriginal', aggregate: 'sum', format: 'n2' },
  { field: 'commissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'commissionClient', aggregate: 'sum', format: 'n2' },
  { field: 'moneyTakerAmount', aggregate: 'sum', format: 'n2' },
];

const statuses = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];
class GridTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('searchtransactions');
    this.state = {
      owners: [],
      currentOwners: [],
      isLoading: false,
      data: [],
      rawData: [],
      statusValue: this.getInitialStatus(),
      transactionValue: TRANSACTION_PROPS[0],
      range: {
        start: null,
        end: null,
      },
      chequeNumber: '',
      transactionDetail: '',
      chequeNumberError: false,
      isNeedAdditionalFilter: StorageService.getItem('searchTransactionAdditionalFilter') === 'true',
      isSearchInHistory: StorageService.getItem('searchTransactionWithHistory') === 'true',
      selectedItems: [],
      dialogVariant: '',
      dialogStuff: null,
      isTransactionAmountLimitExceeded: false,
      expandSearchOptions: {
        additionalDetailName: null,
        additionalDetailValue: null,
        paymentTypes: '',
        operationTypes: '',
        terminalTypes: '',
        hasComment: null,
      },
    };
  }

  componentDidMount() {
    this.updateByLocalStorage();
    this.handleAuthorizeCheck();
    this.fetchOwners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.transactionValue !== prevState.transactionValue) {
      this.updateByLocalStorage();
    }
  }

  updateByLocalStorage = () => {
    const { transactionValue } = this.state;

    const propsByStorageJSON = StorageService.getItem('transactionsExpandSearchOptions');

    if (!propsByStorageJSON) {
      return;
    }

    const { additionalDetailName, additionalDetailValue, paymentTypes, operationTypes, terminalTypes, hasComment } =
      JSON.parse(propsByStorageJSON);

    const isCurrentTransactionPropDisabled = !transactionValue || transactionValue === ADDITIONAL_TRANSACTION_PROPS[0];

    this.setState({
      expandSearchOptions: {
        additionalDetailName: isCurrentTransactionPropDisabled
          ? ADDITIONAL_TRANSACTION_PROPS[0]
          : additionalDetailName || ADDITIONAL_TRANSACTION_PROPS[0],
        additionalDetailValue: isCurrentTransactionPropDisabled ? '' : additionalDetailValue || '',
        paymentTypes: paymentTypes ? paymentTypes : '',
        operationTypes: operationTypes || '',
        terminalTypes: terminalTypes || '',
        hasComment: hasComment,
      },
    });
  };

  handleAuthorizeCheck = async () => {
    try {
      await api.authorizeCheck();
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };
  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  getInitialStatus = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    const storageValue = StorageService.getItem('searchtransactions-dropdown-status');
    if (val === 'blocked') return statuses[6];
    else if (storageValue !== null && checkElement('searchtransactions-dropdown-status', this.currentArray))
      return storageValue;
    else return statuses[2];
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '0.4' : '0.35';
    switch (value) {
      case 'принят':
        return `rgba(92, 184, 92, ${opacity})`;
      case 'отклонен':
        return `rgba(205, 92, 92, ${opacity})`;
      case 'удален':
        return `rgba(244, 164, 96, ${opacity})`;
      case 'заблокирован':
        return `rgba(70, 130, 180, ${opacity})`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.statusName;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle, color: 'black' };
    return React.cloneElement(element, { style }, children);
  };

  changeDateSeconds = (formatedDate) => new Date(formatedDate);

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    const startDate = new Date(start).setMilliseconds(0);
    const endDate = new Date(end).setMilliseconds(0);
    if (el && el.datePost) {
      const changedDate = this.changeDateSeconds(el.datePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  filterFurther = (data) => {
    const { isNeedAdditionalFilter } = this.state;

    return isNeedAdditionalFilter ? data.filter((el) => this.filterByDatePost(el)) : data;
  };

  checkChequeNumber = (chequeNumber) => {
    if (chequeNumber.replaceAll('_', '').replaceAll('-', '') === '') {
      return false;
    }

    return (chequeNumber || chequeNumber === 0) && !/^\b\d{5,7}\b-\b\d{1,9}\b-\b\d{1,9}\b$/.test(chequeNumber);
  };

  fetchTransactionInfo = async (transactionList) => {
    try {
      const data = await api.getByGraphQl(
        `transactions(order: {transactionId: ASC}, where:{and: [ {transactionId: {in: [${transactionList.join(
          ',',
        )}]}}]}){${Object.keys(TRANSACTION_FIELDS_INFO).join()}} `,
      );

      this.setState({
        data:
          data && data.data && data.data.transactions && data.data.transactions.length
            ? this.filterFurther(data.data.transactions)
            : [],
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchTransactionList = async () => {
    const {
      currentOwners,
      statusValue,
      transactionValue,
      range,
      chequeNumber,
      transactionDetail,
      isSearchInHistory,
      expandSearchOptions: {
        additionalDetailName,
        additionalDetailValue,
        paymentTypes,
        operationTypes,
        terminalTypes,
        hasComment,
      },
    } = this.state;

    if (currentOwners.length < 1) return;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);
    const isChequeNumberUnValid = this.checkChequeNumber(chequeNumber);

    this.setState({ chequeNumberError: isChequeNumberUnValid });

    if (isChequeNumberUnValid) {
      return;
    }

    try {
      await this.setState({ isTransactionAmountLimitExceeded: false, isLoading: true });

      if (isSearchInHistory) {
        const model = {
          Owners: currentOwners,
          StatusId: Number(statusValue),
          ChequeNumber: chequeNumber.replaceAll('_', '').replaceAll('-', '') === '' ? '' : chequeNumber,
          DetailName: TRANSACTION_PROPS[0] === transactionValue ? '' : transactionValue,
          DetailValue: transactionDetail,
          DateStart: start,
          DateEnd: end,
          DisplayComments: true,
          DisplayUser: true,
          Hist: true,
        };

        const data = await api.transactions(model);

        this.setState({
          data:
            data && data.length
              ? this.filterFurther(
                  data.map((item) =>
                    Object.entries(item).reduce(
                      (acc, [key, value]) => ({ ...acc, [lowerCaseFirstLetter(key)]: value }),
                      {},
                    ),
                  ),
                )
              : [],
          isLoading: false,
        });

        return;
      }

      if (transactionValue === 'TransactionId' && transactionDetail) {
        await this.fetchTransactionInfo([transactionDetail]);

        return;
      }

      const model = {
        Owners: currentOwners,
        StatusId: Number(statusValue),
        ChequeNumber: chequeNumber.replaceAll('_', '').replaceAll('-', '') === '' ? '' : chequeNumber,
        DetailName: TRANSACTION_PROPS[0] === transactionValue ? '' : transactionValue,
        DetailValue: transactionDetail,
        DateStart: start,
        DateEnd: end,
        DisplayComments: true,
        DisplayUser: true,
        Hist: isSearchInHistory,
        additionalDetailName: TRANSACTION_PROPS[0] === additionalDetailName ? '' : additionalDetailName,
        additionalDetailValue,
        paymentTypes,
        operationTypes,
        terminalTypes,
        hasComment,
      };

      const transactionList = await api.findTransactionForGraph(model);

      if (!transactionList.length) {
        this.setState({ data: [] });
        this.toggleLoader(false);
        return;
      }

      this.setState({ isTransactionAmountLimitExceeded: transactionList.length >= 5000 });
      await this.fetchTransactionInfo(transactionList.map(({ TransactionId }) => TransactionId));
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  checkNullDate = (date) => date && new Date(date).getFullYear() === 1970;

  formatDate = (data) => {
    if (!data) {
      return [];
    }

    return data.map(
      ({
        dateAccepted,
        dateAcceptedNoUTC,
        dateDeclined,
        dateDeclinedNoUTC,
        dateInput,
        dateInputNoUTC,
        datePost,
        datePostNoUTC,
        ...restFields
      }) => ({
        ...restFields,
        dateAccepted: this.checkNullDate(dateAccepted) ? null : dateAccepted,
        dateAcceptedNoUTC: this.checkNullDate(dateAcceptedNoUTC) ? null : dateAcceptedNoUTC,
        dateDeclined: this.checkNullDate(dateDeclined) ? null : dateDeclined,
        dateDeclinedNoUTC: this.checkNullDate(dateDeclinedNoUTC) ? null : dateDeclinedNoUTC,
        dateInput: this.checkNullDate(dateInput) ? null : dateInput,
        dateInputNoUTC: this.checkNullDate(dateInputNoUTC) ? null : dateInputNoUTC,
        datePost: this.checkNullDate(datePost) ? null : datePost,
        datePostNoUTC: this.checkNullDate(datePostNoUTC) ? null : datePostNoUTC,
      }),
    );
  };

  onOwners = (currentOwners) => {
    this.setState({ currentOwners });
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range: value });
  };

  onStatusChange = (value) => {
    this.setState({ statusValue: value }, () => StorageService.setItem('searchtransactions-dropdown-status', value));
  };

  onTransactionChange = (value) => {
    this.setState({ transactionValue: value });
  };

  onChequeNumberChange = (e) => {
    this.setState({ chequeNumber: e.target.value });
  };

  onTransactionDetailChange = (e) => {
    this.setState({ transactionDetail: e.target.value });
  };

  ifBlocked = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    if (val === 'blocked') {
      return true;
    }
    return false;
  };

  buildToolbar = () => {
    const {
      statusValue,
      transactionValue,
      chequeNumber,
      transactionDetail,
      isNeedAdditionalFilter,
      isSearchInHistory,
      owners,
      currentOwners,
      selectedItems,
      isTransactionAmountLimitExceeded,
    } = this.state;
    const { translate } = this.props;
    return (
      <Row type="flex" justify="start" gutter={16} xs={24} lg={24} span={24}>
        <Col xs={24} lg={24} xl={21} span={24} className="GridTransactions-fieldsCol">
          <Col xs={24} lg={24} xl={14} span={24}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(owners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={currentOwners}
              onSave={this.onOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange
              // shortFormat
              onRange={this.setRange}
              blocked={this.ifBlocked()}
            />
          </Col>

          <Col xs={24} lg={12} xl={4} span={24} className="lg-mt-10">
            <Row type="flex" align="middle" justify="start">
              <Col span={24}>
                {checkElement('searchtransactions-dropdown-status', this.currentArray) && (
                  <Col>
                    <Select
                      value={statusValue}
                      onChange={(value) => this.onStatusChange(value)}
                      placeholder="Статус"
                      style={{ width: '100%' }}
                    >
                      <Option value="0">все статусы</Option>
                      <Option value="1">введен</Option>
                      <Option value="2">принят</Option>
                      <Option value="3">отклонён</Option>
                      <Option value="4">удален</Option>
                      <Option value="5">обрабатывается</Option>
                      <Option value="6">заблокирован</Option>
                      <Option value="7">создан</Option>
                      <Option value="8">удержан</Option>
                    </Select>
                  </Col>
                )}
                <Col>
                  <ChequeNumberMaskedInput
                    value={chequeNumber}
                    placeholder="№ чека"
                    onChange={this.onChequeNumberChange}
                  />
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={12} xl={6} className="lg-mt-10">
            <Row>
              <Select value={transactionValue} onChange={this.onTransactionChange} style={{ width: '100%' }}>
                {TRANSACTION_PROPS.map((option, index) => (
                  <Option key={`transactionProps-${index.toString()}`} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Input
                value={transactionDetail}
                placeholder={translate('page.transactions.value')}
                onChange={this.onTransactionDetailChange}
                className="transaction-input"
              />
            </Row>
          </Col>

          <Col span={24} className="GridTransactions-checkboxActionsRow">
            <div className="GridTransactions-checkboxRow">
              <Checkbox
                checked={isNeedAdditionalFilter}
                onChange={() => {
                  this.setState(
                    ({ isNeedAdditionalFilter }) => ({ isNeedAdditionalFilter: !isNeedAdditionalFilter, data: [] }),
                    () => {
                      StorageService.setItem(
                        'searchTransactionAdditionalFilter',
                        `${this.state.isNeedAdditionalFilter}`,
                      );
                    },
                  );
                }}
              />

              <div>{translate('core.toolbar.filterByData')}</div>
            </div>

            <div className="GridTransactions-checkboxRow">
              <Checkbox
                checked={isSearchInHistory}
                onChange={() => {
                  this.setState(
                    ({ isSearchInHistory }) => ({ isSearchInHistory: !isSearchInHistory }),
                    () => {
                      StorageService.setItem('searchTransactionWithHistory', `${this.state.isSearchInHistory}`);
                      this.setState({ data: [] });
                    },
                  );
                }}
              />

              <div>{translate('page.transactions.searchInHistory')}</div>
            </div>

            {checkElement('searchtransactions-transactionWithAdditionalProps', this.currentArray) && (
              <div>
                <AntdButton
                  type="primary"
                  disabled={!selectedItems.length}
                  onClick={() => {
                    this.setState({ dialogVariant: 'additionalProps' });
                  }}
                >
                  {translate('page.transactions.transactionWithAdditionalProps')}
                </AntdButton>
              </div>
            )}

            {checkElement('searchtransactions-expandSearchOptions', this.currentArray) && (
              <div>
                <AntdButton
                  type="primary"
                  onClick={() => {
                    this.setState({ dialogVariant: 'expandSearchOptions' });
                  }}
                  disabled={isSearchInHistory}
                >
                  {translate('page.transactions.expandSearchOptions')}
                </AntdButton>
              </div>
            )}

            {checkElement('searchtransactions-addComment', this.currentArray) && (
              <div>
                <AntdButton
                  type="primary"
                  onClick={() => {
                    this.setState({
                      dialogVariant: 'addComment',
                      dialogStuff: selectedItems.map(({ transactionId }) => transactionId),
                    });
                  }}
                  disabled={!selectedItems.length}
                >
                  {translate('page.transactions.addComment')}
                </AntdButton>
              </div>
            )}

            {checkElement('searchtransactions-reProcessingTransaction', this.currentArray) && (
              <div>
                <AntdButton
                  type="primary"
                  onClick={() => {
                    this.setState({
                      dialogVariant: 'reProcessingTransaction',
                      dialogStuff: { selectedItems: selectedItems },
                    });
                  }}
                  disabled={!selectedItems.length}
                >
                  {translate('page.transactions.reProcessingTransaction')}
                </AntdButton>
              </div>
            )}
          </Col>
        </Col>

        <Col xs={24} lg={24} xl={3} span={24} className="lg-mt-10 GridTransactions-searchActionWrapper">
          {checkElement('terminalTransactions-btn-submit', this.currentArray) && (
            <Button className="sm-w-100" icon="search" primary onClick={this.fetchTransactionList}>
              {translate('owner.search')}
            </Button>
          )}
        </Col>

        {(isSearchInHistory || isTransactionAmountLimitExceeded) && (
          <Col span={24} className="GridTransactions-messages">
            {isSearchInHistory && (
              <div className="GridTransactions-warningMessage">
                {translate('page.transactions.searchInHistWarning')}
              </div>
            )}

            {isTransactionAmountLimitExceeded && (
              <div className="GridTransactions-errorMessage">
                {translate('page.transactions.limitSearchParameters')}
              </div>
            )}
          </Col>
        )}
      </Row>
    );
  };

  getHandlers = () => {
    const { showError, translate } = this.props;
    const { selectedItems } = this.state;

    return [
      {
        title: 'page.transactions.copyTransactionId',
        action: async (props) => {
          await navigator.clipboard.writeText(`${props.dataItem.transactionId}`);
          message.success(translate('page.transactions.transactionNumberIsCopied'), 0.5);
        },
        dropdown: false,
        show: true,
      },
      {
        title: 'page.transactions.editTransaction',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'editTransaction', dialogStuff: dataItem });
        },
        dropdown: false,
        show: checkElement('searchtransactions-editTransaction', this.currentArray),
      },
      {
        title: 'grids.transactions.button-details',
        action: ({ dataItem }) => tabService.addTab({ type: 'details', dataItem: dataItem }),
        dropdown: false,
        show: checkElement('terminalTransactions-transactionIdOnclick', this.currentArray),
      },
      {
        title: 'page.transactions.additionalInfo',
        action: (props) => tabService.addTab({ type: 'additionalInfo', dataItem: props.dataItem }),
        dropdown: false,
        show: checkElement('searchtransactions-additionalInfo', this.currentArray),
      },
      {
        title: 'page.transactions.sendingEmailReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingEmailReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: checkElement('terminalTransactions-sendingEmailReceipt', this.currentArray),
      },

      {
        title: 'page.transactions.sendingViberReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingViberReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: checkElement('terminalTransactions-sendingViberReceipt', this.currentArray),
      },

      {
        title: 'page.transactions.downloadReceipt',
        action: ({ dataItem }) => {
          DownloadReceiptPDFService.downloadReceipt(
            async (isLoading) => {
              await this.setState({ isLoading });
            },
            showError,
            dataItem.transactionId,
          );
        },
        dropdown: false,
        show: checkElement('terminalTransactions-downloadReceipt', this.currentArray),
      },
      // {
      //   title: 'page.transactions.split',
      //   action: ({ dataItem }) => {
      //     this.setState({ dialogVariant: 'splittingTransaction', dialogStuff: dataItem });
      //   },
      //   dropdown: false,
      //   show: true,
      // },
      // {
      //   title: 'page.transactions.mergeTransactions',
      //   action: ({ dataItem }) => {
      //     this.setState({
      //       dialogVariant: 'mergeTransactions',
      //       dialogStuff: {
      //         firstTransactionId: dataItem.transactionId,
      //         secondTransactionId:
      //           selectedItems[0] && selectedItems[0].transactionId !== dataItem.transactionId
      //             ? selectedItems[0].transactionId
      //             : '',
      //       },
      //     });
      //   },
      //   dropdown: false,
      //   show: true,
      //   disabled: selectedItems.length > 1,
      // },
      {
        type: 'divider',
      },
      {
        title: 'page.transactions.rejectTransaction',
        action: ({ dataItem }) => {
          this.setState({
            dialogVariant: 'rejectTransaction',
            dialogStuff: { dataItem: dataItem, selectedItems: selectedItems },
          });
        },
        dropdown: false,
        show: checkElement('searchtransactions-rejectTransaction', this.currentArray),
      },
      {
        title: 'page.transactions.unlockTransaction',
        action: ({ dataItem }) => {
          this.setState({
            dialogVariant: 'unlockTransaction',
            dialogStuff: { dataItem: dataItem, selectedItems: selectedItems },
          });
        },
        dropdown: false,
        show: checkElement('searchtransactions-unlockTransaction', this.currentArray),
      },
    ];
  };

  onAdditionalPropSelected = (selectedAdditionalProps) => {
    const { selectedItems } = this.state;
    tabService.addTab({
      type: 'additionalProps',
      dataItem: {
        selectedAdditionalProps,
        selectedItems,
      },
    });
  };

  renderBaseGridColumn = ({ field, ...props }) => (
    <GridColumn
      field={field}
      title={TRANSACTION_FIELDS_INFO[field].title}
      filter={TRANSACTION_FIELDS_INFO[field].filter}
      {...props}
    />
  );

  render = () => {
    const { isLoading, data, chequeNumberError, dialogVariant, dialogStuff, transactionValue } = this.state;

    const { showError } = this.props;

    if (!checkElement('terminalTransactions', this.currentArray)) {
      return null;
    }

    const isDropdownShow = [
      'terminalTransactions-transactionIdOnclick',
      'terminalTransactions-sendingEmailReceipt',
      'terminalTransactions-sendingViberReceipt',
      'terminalTransactions-downloadReceipt',
    ].some((slug) => checkElement(slug, this.currentArray));

    const informationModalVariants = ['reProcessingTransaction', 'unlockTransaction', 'rejectTransaction'];
    const openInformationModal = informationModalVariants.includes(dialogVariant);

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.fetchTransactionList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          rowRender={this.rowRender}
          name="terminalTransactions"
          fieldForSelection="transactionId"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          nonGqlExportFormatter={this.formatDate}
          filterConstructorStaff={{ fieldsInfo: TRANSACTION_FIELDS_INFO }}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          {this.renderBaseGridColumn({ field: 'ownerName', with: '145' })}
          <GridColumn
            field="transactionId"
            title={TRANSACTION_FIELDS_INFO.transactionId.title}
            filter={TRANSACTION_FIELDS_INFO.transactionId.filter}
            width="130"
            handlers={this.getHandlers()}
            dropdown={isDropdownShow}
          />
          {this.renderBaseGridColumn({ field: 'requestId', with: '112' })}
          {this.renderBaseGridColumn({ field: 'terminalId', with: '124' })}
          {this.renderBaseGridColumn({ field: 'serviceId', with: '110' })}
          {this.renderBaseGridColumn({ field: 'serviceName', with: '93' })}
          {this.renderBaseGridColumn({ field: 'amountTotal', with: '134', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'amountOriginal', with: '180', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'commissionProvider', with: '154', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'commissionClient', with: '171', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'currency', with: '90' })}
          {this.renderBaseGridColumn({ field: 'dateInput', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'datePost', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'dateDeclined', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'dateAccepted', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'account', with: '112' })}
          {this.renderBaseGridColumn({ field: 'comment', with: '125' })}
          {this.renderBaseGridColumn({ field: 'statusName', with: '91' })}
          {this.renderBaseGridColumn({ field: 'paymentType', with: '125' })}
          {this.renderBaseGridColumn({ field: 'operationName', with: '125' })}
          {this.renderBaseGridColumn({ field: 'terminalType', with: '125' })}
        </Grid>
        {chequeNumberError && (
          <AlertModal
            visible={chequeNumberError}
            closeModal={() => {
              this.setState({ chequeNumberError: false });
            }}
          />
        )}
        {dialogVariant === 'additionalProps' && (
          <AdditionalTransactionsPropsModal
            visible={dialogVariant === 'additionalProps'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            onSave={this.onAdditionalPropSelected}
          />
        )}
        {dialogVariant === 'sendingEmailReceipt' && (
          <EmailReceiptsModal
            visible={dialogVariant === 'sendingEmailReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.transactionId}
          />
        )}
        {dialogVariant === 'sendingViberReceipt' && (
          <ViberReceiptModal
            visible={dialogVariant === 'sendingViberReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.transactionId}
          />
        )}
        {dialogVariant === 'expandSearchOptions' && (
          <ExpandSearchOptionsDialog
            visible={dialogVariant === 'expandSearchOptions'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedMainTransactionValue={transactionValue}
            setAdditionalProps={(expandSearchOptions) => {
              this.setState({ expandSearchOptions });
            }}
          />
        )}
        {dialogVariant === 'splittingTransaction' && (
          <SplittingTransactionModal
            visible={dialogVariant === 'splittingTransaction'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'editTransaction' && (
          <EditTransactionModal
            visible={dialogVariant === 'editTransaction'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'mergeTransactions' && (
          <MergeTransactionsModal
            visible={dialogVariant === 'mergeTransactions'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'addComment' && (
          <AddCommentDialog
            visible={dialogVariant === 'addComment'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedTransactionIds={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}

        {openInformationModal && (
          <InformationModal
            visible={openInformationModal}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            dialogVariant={dialogVariant}
            onRefresh={this.fetchTransactionList}
            showError={showError}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridTransactions));
