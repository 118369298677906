import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import ViewReportPeriod from './GridReportsByPeriod';
import PageTabs from '../../../components/tabber/tabber';

class PageReportPeriod extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = props;
    this.state = {
      tabs: [
        {
          title: `${translate('page-nav.nav-reportPeriod')}`,
          content: <ViewReportPeriod />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type }) => {
    const { tabs } = this.state;
    switch (type) {
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageReportPeriod);
