class Validator {
  validate = (translate) => (value) => {
    if (!value) {
      return translate('page.search-lists-sanctioners.error-required');
    }

    const regExp = /^[\sa-zA-Zа-яА-Я%_`'ЁёҐґЄєІіЇїʼ\-]+$/;
    if (!regExp.test(value)) {
      return translate('page.search-lists-sanctioners.error-invalid');
    }
    return '';
  };
}
export default new Validator();
