import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import OTPServicesGrid from './OTPServicesGrid';
import OTPHistoryGrid from './OTPHistoryGrid';

class OTPServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-OTRServices',
          content: <OTPServicesGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'history':
        tabs.push({
          title: 'page.OTPServices.history',
          content: <OTPHistoryGrid />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default OTPServices;
