import React, { PureComponent } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button, message, Radio } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import './SuspendedFinancialTransactionsGrid.scss';
import SuspendedFinancialTransactionsGridService from './SuspendedFinancialTransactionsGrid.service';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { PAYMENT_STATUSES, PAYMENT_STATUSES_DROPDOWN_CONFIG } from './constants';
import CustomInput from '../../../components/Input/Input';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { ApiService, tabService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class SuspendedFinancialTransactionsGridToolbar extends PureComponent {
  currentArray = findArray('SuspendedFinancialTransactions');

  dateChangeCallback = (fieldName) => (e) => {
    const {
      formState: { dateFrom, dateTo },
      onError,
      onChangeFormState,
      translate,
    } = this.props;

    onChangeFormState(fieldName, e.target.value);
    onError(
      'dateError',
      SuspendedFinancialTransactionsGridService.getSubmitErrorsByDate(
        fieldName === 'dateFrom' ? e.target.value : dateFrom,
        fieldName === 'dateTo' ? e.target.value : dateTo,
        translate,
      ),
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reloadAttemptSlug, formErrors } = this.props;
    const { reloadAttemptSlug: prevReloadAttemptSlug } = prevProps;

    if (reloadAttemptSlug !== prevReloadAttemptSlug && !Object.values(formErrors).some((value) => !!value)) {
      this.handleSubmit();
    }
  }

  renderInput = (name, type, isOnlyInteger) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <CustomInput
        type={type}
        formState={formState}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        validators={SuspendedFinancialTransactionsGridService.getValidators(translate)}
        name={name}
        isOnlyInteger={isOnlyInteger}
        isErrorMessageAbsolutePositioned={false}
      />
    );
  };

  onChangeRadio = (e) => {
    const { onChangeFormState } = this.props;

    onChangeFormState('isRecipient', e.target.value);
  };

  handleSubmit = async () => {
    const {
      formState: { dateFrom, dateTo, status, EDRPOU, isRecipient, accountId, IBAN },
      setOperations,
      setLoading,
    } = this.props;

    try {
      await setLoading(true);
      const operations = await api.getPaymentsExternalList({
        account: accountId || null,
        dateStart: getDataByTimezoneOffset(
          new Date(
            new Date(dateFrom).getFullYear(),
            new Date(dateFrom).getMonth(),
            new Date(dateFrom).getDate(),
            0,
            0,
            0,
          ),
        ).toISOString(),
        dateEnd: getDataByTimezoneOffset(
          new Date(new Date(dateTo).getFullYear(), new Date(dateTo).getMonth(), new Date(dateTo).getDate(), 23, 59, 0),
        ).toISOString(),
        inOut: isRecipient,
        iban: IBAN || null,
        edrpo: EDRPOU || null,
        statusId: status || null,
        accountId: null,
      });

      setOperations(operations);
      console.log('operations', operations);
      await setLoading(false);
    } catch (e) {
      const { showError } = this.props;
      await setLoading(false);
      showError(e);
    }
  };

  handlePaymentActionsGridTabOpen = () => {
    const { selectedItems } = this.props;

    tabService.addTab({
      type: 'unlocksHistory',
      paymentIds: selectedItems.map(({ PaymentId }) => PaymentId),
    });
  };

  render() {
    const {
      formState: { dateFrom, dateTo, status, isRecipient },
      formErrors: { dateError },
      translate,
      onChangeFormState,
      formErrors,
      handleDialogOpen,
      selectedItems,
    } = this.props;

    return (
      <div className="SuspendedFinancialTransactionsGridToolbar-toolbar">
        <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFields">
          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarDateFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
              <div className="SuspendedFinancialTransactionsGridToolbar-label">
                {translate('page.changingProfileHistory.dateFrom')}
              </div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="SuspendedFinancialTransactionsGridToolbar-datePicker"
                  onChange={this.dateChangeCallback('dateFrom')}
                  value={dateFrom}
                  min={new Date(2007, 0, 1)}
                />
              </div>
            </div>

            <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
              <div className="SuspendedFinancialTransactionsGridToolbar-label">
                {translate('page.changingProfileHistory.dateTo')}
              </div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="SuspendedFinancialTransactionsGridToolbar-datePicker"
                  onChange={this.dateChangeCallback('dateTo')}
                  value={dateTo}
                  min={new Date(2007, 0, 1)}
                />
              </div>
            </div>

            {dateError && <div className="SuspendedFinancialTransactionsGridToolbar-datePickerError">{dateError}</div>}
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-statusLabel">
              {translate('page.suspendedFinancialTransactions.paymentStatus')}
            </div>

            <div className="SuspendedFinancialTransactionsGridToolbar-status">
              <GridDropdown
                data={PAYMENT_STATUSES.map((item, index) => ({ Id: index, Name: translate(item) }))}
                colConfig={PAYMENT_STATUSES_DROPDOWN_CONFIG}
                selectItems={[status]}
                onSave={(statuses) => {
                  onChangeFormState('status', statuses[0]);
                }}
                isSingle
              />
            </div>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarWideFieldsItem">
            <Radio.Group
              value={isRecipient}
              onChange={this.onChangeRadio}
              className="SuspendedFinancialTransactionsGridToolbar-RadioGroup"
            >
              <Radio key="radio-recipient" value={true}>
                {translate('page.suspendedFinancialTransactions.recipient')}
              </Radio>

              <Radio key="radio-sender" value={false}>
                {translate('page.suspendedFinancialTransactions.sender')}
              </Radio>
            </Radio.Group>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-alignTopLabel">
              {translate('page.suspendedFinancialTransactions.accountId')}
            </div>
            <div className="SuspendedFinancialTransactionsGridToolbar-input">
              {this.renderInput('accountId', 'number', true)}
            </div>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-alignTopLabel">
              {translate('page.suspendedFinancialTransactions.EDRPOUCode')}
            </div>
            <div className="SuspendedFinancialTransactionsGridToolbar-input">
              {this.renderInput('EDRPOU', 'number', true)}
            </div>
          </div>

          <div className="SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem">
            <div className="SuspendedFinancialTransactionsGridToolbar-alignTopLabel">
              {translate('page.suspendedFinancialTransactions.IBan')}
            </div>
            <div className="SuspendedFinancialTransactionsGridToolbar-wideInput">
              {this.renderInput('IBAN', 'default')}
            </div>
          </div>
        </div>

        <div className="SuspendedFinancialTransactionsGridToolbar-searchAction">
          <Button
            type="primary"
            onClick={this.handleSubmit}
            disabled={Object.values(formErrors).some((value) => !!value)}
          >
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        <div className="SuspendedFinancialTransactionsGridToolbar-actions">
          {checkElement('suspendedFinancialTransactions-unblock', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length !== 1 || selectedItems[0].StatusId !== 6}
              onClick={() => {
                handleDialogOpen('unblockDialog');
              }}
            >
              {translate('page.suspendedFinancialTransactions.unblock')}
            </Button>
          )}
          {checkElement('suspendedFinancialTransactions-refuse', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length !== 1 || selectedItems[0].StatusId !== 6}
              onClick={() => {
                handleDialogOpen('blockDialog');
              }}
            >
              {translate('page.suspendedFinancialTransactions.refuse')}
            </Button>
          )}
          {checkElement('suspendedFinancialTransactions-setComment', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length !== 1}
              onClick={() => {
                handleDialogOpen('setCommentDialog');
              }}
            >
              {translate('page.suspendedFinancialTransactions.comment')}
            </Button>
          )}
          {checkElement('suspendedFinancialTransactions-unblockHistory', this.currentArray) && (
            <Button type="primary" disabled={!selectedItems.length} onClick={this.handlePaymentActionsGridTabOpen}>
              {translate('page.suspendedFinancialTransactions.unlocksHistory')}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslate(SuspendedFinancialTransactionsGridToolbar);
