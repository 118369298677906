import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button, Select, Dropdown, Menu, Icon } from 'antd';
import { withTranslate } from '../../contexts/localContext';
import { ApiService, tabService } from '../../services';
import LoadingPanel from '../../components/loader';
import '../reports/ReportsByPeriod/GridReportsByPeriod.scss';
import './viewCabinet.scss';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

const { Option } = Select;

const convertToISOFormat = (date) => formatDate(date, 'yyyy-MM-ddTHH:mm:ss');
const minStart = new Date(
  new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)).setDate(
    new Date().getDate() - 1,
  ),
);
const maxStart = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0));
const minEnd = new Date(
  new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(23, 59, 59, 0)).setDate(
    new Date().getDate() - 1,
  ),
);
const maxEnd = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 0));

class ViewCabinet extends PureComponent {
  currentArray = findArray('monitoringCabinet');

  state = {
    start: maxStart,
    end: maxEnd,
    emailValue: '',
    isLoading: false,
    selectedReport: null,
    selectData: [],
    isValidDatePicker: true,
  };

  componentDidMount() {
    this.initialiseSelect();
    this.initRequest();
  }

  handleMenuClick = ({ key }) => {
    if (key === 'mediators') {
      return this.renderMediatorTab();
    } else return this.renderRefuseAgentTab();
  };

  renderMediatorTab = () => {
    return tabService.addTab({
      type: 'DictionaryMediator',
    });
  };

  renderRefuseAgentTab = () => {
    return tabService.addTab({
      type: 'DictionaryRefusedAgentsGet',
    });
  };

  directories = () => {
    const { translate } = this.props;
    return (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="mediators">{translate('page.monitoringCabinet.tab-agents')}</Menu.Item>
        <Menu.Item key="refusers">{translate('page.monitoringCabinet.tab-refAgents')}</Menu.Item>
      </Menu>
    );
  };

  initialValue = this.props.translate('page.monitoringCabinet.initial-select-value');

  initialiseSelect = () => {
    this.setState({
      selectedReport: this.initialValue,
    });
  };

  initRequest = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const selectData = await api.reportsListGet();
      this.setState({ selectData });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  onStartChange = (e) => {
    // const start = new Date(new Date(e.target.value).setHours(0, 0, 0, 0));
    const { end, selectedReport } = this.state;
    // const newEnd = new Date(new Date(e.target.value).setHours(23, 59, 59, 0));
    this.setState(
      {
        start: e.target.value,
        end:
          selectedReport === 'ReportWithdrawGet' || selectedReport === 'ReportUsersInStopListGet'
            ? end
            : e.target.value,
      },
      () => {
        this.isValidDatePicker();
      },
    );
  };

  onEndChange = (e) => {
    const { start, selectedReport } = this.state;
    // const newStart = new Date(new Date(e.target.value).setHours(0, 0, 0, 0));
    // const end = new Date(new Date(e.target.value).setHours(23, 59, 59, 0));
    this.setState(
      {
        start:
          selectedReport === 'ReportWithdrawGet' || selectedReport === 'ReportUsersInStopListGet'
            ? start
            : e.target.value,
        end: e.target.value,
      },
      this.isValidDatePicker,
    );
  };

  onChangeEmailValue = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onSubmit = () => {
    const { selectedReport, start, end } = this.state;
    const dataItem = {
      start: convertToISOFormat(new Date(new Date(start).setHours(0, 0, 0, 0))),
      end: convertToISOFormat(new Date(new Date(end).setHours(23, 59, 59, 0))),
    };
    switch (selectedReport) {
      case 'ReportUsersInStopListGet':
        return tabService.addTab({
          type: 'ReportUsersInStopListGet',
          dataItem,
        });
      case 'ReportRegularPaymentsGet':
        return tabService.addTab({
          type: 'ReportRegularPaymentsGet',
          dataItem,
        });
      case 'ReportPayerRecipientDataGet':
        return tabService.addTab({
          type: 'ReportPayerRecipientDataGet',
          dataItem,
        });
      case 'ReportNoRecipientDataGet':
        return tabService.addTab({
          type: 'ReportNoRecipientDataGet',
          dataItem,
        });
      case 'ReportMFOPaymentsGet':
        return tabService.addTab({
          type: 'ReportMFOPaymentsGet',
          dataItem,
        });
      case 'ReportRefundPaymentsGet':
        return tabService.addTab({
          type: 'ReportRefundPaymentsGet',
          dataItem,
        });
      case 'ReportWithdrawGet':
        return tabService.addTab({
          type: 'ReportWithdrawGet',
          dataItem,
        });
      case 'ReportOverlimitPaymentsGet':
        return tabService.addTab({
          type: 'ReportOverlimitPaymentsGet',
          dataItem,
        });
      case 'ReportOverlimitTransactionsGet':
        return tabService.addTab({
          type: 'ReportOverlimitTransactionsGet',
          dataItem,
        });
      case 'ReportTerroristsTransactionsGet':
        return tabService.addTab({
          type: 'ReportTerroristsTransactionsGet',
          dataItem,
        });
      default:
        return;
    }
  };

  onChangeSelectedReport = (selectedReport) => {
    this.setState((prevState) => {
      return {
        selectedReport,
        start:
          prevState.selectedReport === 'ReportWithdrawGet' || prevState.selectedReport === 'ReportUsersInStopListGet'
            ? maxStart
            : prevState.start,
        end:
          prevState.selectedReport === 'ReportWithdrawGet' || prevState.selectedReport === 'ReportUsersInStopListGet'
            ? maxEnd
            : prevState.end,
      };
    });
  };

  isValidDatePicker = () => {
    const { selectedReport, start, end } = this.state;
    let isValidDatePicker = true;
    if (selectedReport === 'ReportWithdrawGet' || selectedReport === 'ReportUsersInStopListGet') {
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      isValidDatePicker = diffDays <= 31 ? true : false;
    }
    this.setState({ isValidDatePicker });
  };

  isValidStartPicker = () => {
    const { selectedReport, start } = this.state;
    if (selectedReport === 'ReportWithdrawGet' || selectedReport === 'ReportUsersInStopListGet') return true;
    if (start > maxStart || start < minStart) return false;
    return true;
  };

  isValidEndPicker = () => {
    const { selectedReport, end } = this.state;
    if (selectedReport === 'ReportWithdrawGet' || selectedReport === 'ReportUsersInStopListGet') return true;
    if (end > maxEnd || end < minEnd) return false;
    return true;
  };

  render() {
    const { start, end, isLoading, selectedReport, selectData, isValidDatePicker } = this.state;
    const { translate } = this.props;

    if (!checkElement('monitoringCabinet-content', this.currentArray)) {
      return null;
    }

    return (
      <div className="page-period mon-cab">
        {isLoading && <LoadingPanel />}
        <Row className="main-row mon-cab-row">
          <Col xs={24}>
            <Row type="flex" align="middle" className="row-nowrap">
              <Col xs={24} md={8}>
                <div className="monitor-text-block">
                  <p>{translate('page.monitoringCabinet.field-type')}:</p>
                </div>
              </Col>
              <Col xs={24} md={16}>
                <Select value={selectedReport} onChange={this.onChangeSelectedReport} style={{ width: '100%' }}>
                  {Object.entries(selectData).map(([key, value]) => {
                    return (
                      <Option key={`reportType-${key}`} value={key}>
                        {value}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="main-row" type="flex" justify="space-between" align="middle">
          <Col xs={24} md={10}>
            <Row type="flex" align="middle" className="row-nowrap">
              <Col xs={24} md={8}>
                <p className="monitor-text-block">{translate('grids.comission-sub.validity-period-date-start')}</p>
              </Col>
              <Col xs={24} md={16}>
                <DatePicker
                  format="yyyy.MM.dd"
                  value={start}
                  min={minStart}
                  max={maxStart}
                  onChange={this.onStartChange}
                  onClickOutside={false}
                  valid={isValidDatePicker && this.isValidStartPicker()}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={10}>
            <Row type="flex" align="middle" className="row-nowrap">
              <Col xs={24} md={8}>
                <p className="monitor-text-block">{translate('grids.comission-sub.validity-period-date-end')}</p>
              </Col>
              <Col xs={24} md={16}>
                <DatePicker
                  format="yyyy.MM.dd"
                  value={end}
                  min={minEnd}
                  max={maxEnd}
                  onChange={this.onEndChange}
                  valid={isValidDatePicker && this.isValidEndPicker()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24} className="sub-wrapper main-row btn-wrapper">
          <Col xs={24} md={12}>
            <Button
              type="primary"
              disabled={
                selectedReport === this.initialValue ||
                !isValidDatePicker ||
                !this.isValidStartPicker() ||
                !this.isValidEndPicker()
              }
              onClick={this.onSubmit}
            >
              {translate('page.monitoringCabinet.btn-review')}
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Dropdown overlay={this.directories()} trigger={['click']}>
              <Button>
                {translate('page.monitoringCabinet.report-dictionaries')}
                <Icon type="down" />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslate(ViewCabinet);
