import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import AddNewUser from './AddNewUser';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { getEmailValidator, getLoginValidator, getPhoneValidate, getRequiredValidators } from './validators';

const api = new ApiService();

class AddNewUserContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    allOwners: [],
    selectOwners: [],
    isLoading: false,
    allUserRoles: null,
    showAdditionalInfo: false,
  };

  onShowAdditionalInfo = () => {
    this.setState(({ showAdditionalInfo }) => {
      return {
        showAdditionalInfo: !showAdditionalInfo,
      };
    });
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const allOwners = await api.owners();
      this.setState({ allOwners, selectOwners: allOwners && allOwners.length ? [allOwners[0].OwnerId] : [] });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  fetchAllUserRoles = async () => {
    try {
      await this.setState({ isLoading: true });
      const allUserRoles = await api.getUserAvailableRoles();
      this.setState({ allUserRoles: allUserRoles && allUserRoles.length ? allUserRoles : [], isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  onSubmitValidateField = () => {
    const { formState, onError } = this.props;

    const validators = this.getValidatorsObject();

    return Object.entries(formState).reduce((acc, [key, value]) => {
      const currentValidator = validators[key];
      if (!currentValidator) return acc;
      const errorMessage = currentValidator(value);
      onError(key, errorMessage);
      return acc || errorMessage;
    }, '');
  };

  getValidatorsObject = () => {
    const { translate } = this.props;

    return {
      Login: getLoginValidator(translate),
      FirstName: getRequiredValidators(translate),
      LastName: getRequiredValidators(translate),
      Phone: getPhoneValidate(translate),
      Address: getRequiredValidators(translate),
      Note: getRequiredValidators(translate),
      Email: getEmailValidator(translate),
    };
  };

  async componentDidMount() {
    await this.fetchOwners();
    await this.fetchAllUserRoles();
  }

  render = () => {
    const { allOwners, selectOwners, allUserRoles, showAdditionalInfo, isLoading } = this.state;
    return (
      <>
        <AddNewUser
          {...this.props}
          {...this.state}
          allOwners={allOwners}
          setOwners={(selectedOwners) => {
            this.setState({ selectOwners: selectedOwners });
          }}
          selectOwners={selectOwners}
          allUserRoles={allUserRoles}
          onSubmitValidateField={this.onSubmitValidateField}
          onShowAdditionalInfo={this.onShowAdditionalInfo}
          showAdditionalInfo={showAdditionalInfo}
          validators={this.getValidatorsObject()}
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(AddNewUserContainer);
