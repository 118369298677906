import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, GridColumn, GridToolbar, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Button } from 'antd';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { withTranslate } from '../../../contexts/localContext';
import './dropdown.scss';

const rowHeight = 40;

class DropDownCities extends PureComponent {
  static defaultProps = {
    forNewTerminal: false,
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  lastSelectedIndex = 0;
  selectedItems = [];
  initialSearchValue = '';

  ifDeleted = {};
  initialTitle = 'Выберите город...';
  constructor(props) {
    super(props);
    const { cityId } = props;
    this.state = {
      dataState: {
        take: 40,
        filter: {
          logic: 'and',
          filters: [],
        },
        sort: [],
      },
      skip: 0,
      view: [],
      showPopup: false,
      searchValue: '',
      title: this.initialTitle,
      singleSelected: '',
      isMobile: false,
      response: [],
      selectedID: cityId,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { forNewTerminal } = this.props;
    if (forNewTerminal === false && prevProps.cityId !== this.props.cityId) {
      this.buildTitle();
    }
    if (prevProps.cities !== this.props.cities) {
      this.setState({ response: this.props.cities }, () => this.onInit());
    }
  }

  onInit = async () => {
    const { cityId, forNewTerminal } = this.props;
    let selectedID;
    const { response } = this.state;
    if (response && response.length > 0) {
      if (forNewTerminal) {
        selectedID = response[0].CityId;
      } else {
        selectedID =
          cityId && response.find((el) => Number(el.CityId) === Number(cityId))
            ? response.find((el) => Number(el.CityId) === Number(cityId)).CityId
            : null;
      }
      this.setState({ response, selectedID });
      const { dataState } = this.state;
      await this.onDataStateChange({ data: dataState });
      this.buildTitle();
    }
  };

  onSelectChange = () => {
    const { view, selectedID } = this.state;
    const { onChange } = this.props;
    let selectedArr = [];
    let title;
    selectedArr = view.filter((el) => el.CityId === selectedID).map((el) => el.CityName);
    if (selectedArr.length === 0) {
      title = this.initialTitle;
    } else {
      title = selectedArr.join();
    }
    const arrForCallback = view.filter((el) => el.CityId === selectedID);
    this.setState({ title }, () => this.togglePopupVisibility());
    onChange(arrForCallback[0].CityId, arrForCallback[0].RegionId);
  };

  onDataStateChange = async ({ data }) => {
    this.setState({ dataState: data });
    const filterdView = filterBy(orderBy(this.state.response, data.sort), data.filter);
    this.setState({ view: filterdView });
  };

  togglePopupVisibility = () => {
    this.setState((state) => {
      return {
        showPopup: !state.showPopup,
      };
    });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  onRefuse = () => {
    if (window.innerWidth < 992) {
      this.closePopup();
    }
    this.onClearInput();
  };

  checkPopupVisibility = (showPopup) => {
    if (showPopup) {
      return 'open';
    }
    return '';
  };

  onRowClick = (event) => {
    this.setState({ selectedID: event.dataItem.CityId });
  };

  buildTitle = () => {
    const { view } = this.state;
    const { cityId, forNewTerminal } = this.props;
    let checkValue;
    if (!forNewTerminal) {
      checkValue = cityId;
    } else {
      checkValue = view[0].CityId;
    }
    const test = view.filter((el) => el.CityId === checkValue);
    if (test.length > 0) this.setState({ title: test[0].CityName, selectedID: test[0].CityId });
  };

  renderToolbar = () => {
    return <GridToolbar>{this.renderAdditionalToolbar()}</GridToolbar>;
  };

  renderAdditionalToolbar = () => {
    if (window.innerWidth < 992) {
      return this.renderFooter();
    }
    return;
  };

  onPageChange = (event) => {
    const { orders } = this.state;
    this.setState({
      orders: orders,
      skip: event.page.skip,
    });
  };

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  checkCustomFilterActivity = (field, dataState) => {
    if (!dataState.filter || dataState.filter.filters.length < 1) return;
    for (const elem of dataState.filter.filters) {
      elem.active = this.isFilterActive(field, elem);
      if (dataState.filter.filters.length > 1) {
        dataState.filter.filters.forEach((elem) => {
          if (dataState.filter.filters.find((el) => el.field === field)) {
            elem.active = true;
          }
        });
      }
      return elem.active;
    }
  };

  isFilterActive = (field, elem) => {
    if (elem.field === field) {
      return true;
    }
    return false;
  };

  renderGrid = () => {
    const { dataState, view, skip, isMobile } = this.state;
    // const { translate } = this.props;
    return (
      <Grid
        data={view.slice(skip, skip + 20).map((item) => ({
          ...item,
          selected: item.CityId === this.state.selectedID,
        }))}
        resizable
        skip={skip}
        pageSize={dataState.take}
        filterable={true}
        sortable
        rowHeight={rowHeight}
        onPageChange={this.onPageChange}
        scrollable="virtual"
        filter={dataState.filter}
        sort={dataState.sort}
        total={view.length}
        selectedField="selected"
        onRowClick={this.onRowClick}
        onDataStateChange={this.onDataStateChange}
        className={`dropdown-owner city ${isMobile ? 'mobileMode' : ''}`}
        style={{ height: '220px', border: 'none' }}
      >
        {this.renderToolbar()}
        <GridColumn
          field="CityName"
          title="Город"
          width="250"
          filter="text"
          headerClassName={
            this.isColumnActive('CityName', dataState) || this.checkCustomFilterActivity('CityName', dataState)
              ? 'active'
              : ''
          }
        />

        <GridColumn
          field="Description"
          title="Район"
          width="180"
          filter="text"
          headerClassName={
            this.isColumnActive('Description', dataState) || this.checkCustomFilterActivity('Description', dataState)
              ? 'active'
              : ''
          }
        />

        <GridColumn
          field="RegionName"
          title="Регион"
          width="180"
          filter="text"
          headerClassName={
            this.isColumnActive('RegionName', dataState) || this.checkCustomFilterActivity('RegionName', dataState)
              ? 'active'
              : ''
          }
        />
      </Grid>
    );
  };

  renderFooter = () => {
    const { translate } = this.props;
    return (
      <div className="k-grid-pager owner-popup-footer">
        <Button.Group>
          <Button style={{ backgroundColor: 'rgb(246,246,246)' }} type="default" onClick={this.onSelectChange}>
            {translate('owner.save')}
          </Button>
          <Button style={{ backgroundColor: 'rgb(246,246,246)' }} type="default" onClick={this.closePopup}>
            {translate('owner.cancel')}
          </Button>
        </Button.Group>
      </div>
    );
  };

  render() {
    const { showPopup, title } = this.state;
    const { _width, disabled } = this.props;
    return (
      <div role="button" className="popup-owners-grid-wrapper dropdown-cities" disabled={disabled}>
        <div
          role="button"
          onClick={this.closePopup}
          onKeyPress={this.closePopup}
          className={`popup-overlay ${this.checkPopupVisibility(showPopup)}`}
        />
        <KendoButton
          icon="indent-increase"
          onClick={this.togglePopupVisibility}
          className="owner-btn-toggler"
          style={{ width: _width }}
        >
          {title}
        </KendoButton>
        <div className={`popup-owners-grid ${this.checkPopupVisibility(showPopup)}`}>
          <div role="button" className="outline-subwrapper">
            {this.renderGrid()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslate(DropDownCities));
