import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, message, Row } from 'antd';

import { ApiService, StorageService, tabService } from '../../../services';
import GqlService from '../../../components/grids/gql.service';
import {
  BANK_SERVICES_COLUMN_CONFIG,
  BIN_COLUMN_CONFIG,
  PAN_TYPES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
  SYSTEM_TYPES_COLUMN_CONFIG,
} from '../constants';
import { ALL_GRID_COLUMNS } from './constants';
import CardRoutingGridService from './CardRoutingGridService';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';
import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import UpdateModal from '../UpdateRouteModal';
import AddModal from './AddModal';

import './CardRoutingGrid.styled.scss';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import CellBoolean from '../../account/cellBoolean';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import FetchBankListService from '../fetchBankList.service';

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;
const NullCellBoolean = ({ dataItem, field }) => <td>{dataItem[field] === null ? 'NULL' : dataItem[field]}</td>;

const api = new ApiService();

class CardRoutingGrid extends PureComponent {
  currentArray = findArray('cardRouting');

  state = {
    bins: [],
    selectedBins: [],
    systemTypes: [],
    selectedSystemTypes: [],
    panTypes: [],
    selectedPanTypes: [],
    incomingServiceList: [],
    selectedIncomingServiceList: [],
    outcomingServiceList: [],
    selectedOutcomingServiceList: [],
    banks: [],
    selectedBanks: [],
    cardRoutingList: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    modalVariant: '',
    selectedItems: [],
  };

  getFetchDataCallback = (fetchMethodName, normalizerCallback, dataFieldName) => async () => {
    try {
      const data = await api[fetchMethodName]();
      this.setState({
        [dataFieldName]: normalizerCallback(CardRoutingGridService.mergeNullValueByField(data, dataFieldName)),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchBankList = async () => {
    try {
      const bankList = await FetchBankListService.fetchBankListGQL(0, []);

      this.setState({
        banks: CardRoutingGridService.getExtendedNormalizer(
          'BankName',
          'BankName',
          'BankServiceId',
        )(
          CardRoutingGridService.mergeNullValueByField(
            bankList.map(({ bankName, bankServiceId }) => ({ BankName: bankName, BankServiceId: bankServiceId })),
            'banks',
          ),
        ),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  initializeFetch = async () => {
    await this.setState({ isLoading: true });
    await this.getFetchDataCallback('getBinList', CardRoutingGridService.getNormalizer('BIN', 'BIN'), 'bins')();
    await this.getFetchDataCallback(
      'getSystemTypeList',
      CardRoutingGridService.getExtendedNormalizer('SystemType', 'SystemType'),
      'systemTypes',
    )();
    await this.getFetchDataCallback(
      'getPANTypeList',
      CardRoutingGridService.getExtendedNormalizer('PanType', 'PanType'),
      'panTypes',
    )();
    await this.getFetchDataCallback(
      'getIncomingServiceList',
      CardRoutingGridService.getNormalizer('ServiceId', 'ServiceId'),
      'incomingServiceList',
    )();
    await this.getFetchDataCallback(
      'getOutcomingServiceList',
      CardRoutingGridService.getNormalizer('ServiceId', 'ServiceId'),
      'outcomingServiceList',
    )();
    await this.fetchBankList();
    await this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.initializeFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchRoutingListGQL({ selectIdsString });
    }
  }

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchRoutingListGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchRoutingListGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  getFilterString = (fieldName, valuesList) =>
    valuesList.length ? `{${fieldName}:{in: [${valuesList.join(',')}]}},` : '';

  fetchRoutingListGQL = async (props) => {
    const {
      rowAmount,
      pageValue,
      filtersString,
      sortString,
      selectedBins,
      selectedSystemTypes,
      selectedPanTypes,
      selectedIncomingServiceList,
      selectedOutcomingServiceList,
      selectedBanks,
    } = this.state;

    try {
      this.setState({ isLoading: true });
      const normalizeFilterString = filtersString.split && filtersString.split(`"null"`).join('null');
      const storageColumns = StorageService.getItem('cardRoutingGrid');

      const currentFilterString =
        props && props.selectIdsString
          ? `{and:[${normalizeFilterString}, {bin:{in:[${props.selectIdsString}]}}]}`
          : normalizeFilterString;

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_GRID_COLUMNS);

      const filterStringByToolbar = `${this.getFilterString('bin', selectedBins)} ${this.getFilterString(
        'systemType',
        selectedSystemTypes.map((value) => `${value}`),
      )} ${this.getFilterString(
        'panType',
        selectedPanTypes.map((value) => `${value}`),
      )} ${this.getFilterString('incomingServiceId', selectedIncomingServiceList)} ${this.getFilterString(
        'bankName',
        selectedBanks.map((selectedBanks) => (selectedBanks === 'null' ? 'null' : `"${selectedBanks}"`)),
      )} ${currentFilterString} ${this.getFilterString('outcomingServiceId', selectedOutcomingServiceList)}`;

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{id: ASC}'
      }${filterStringByToolbar.trim() ? `,where:{and:[${filterStringByToolbar}]}` : ''}`;

      const query = `cardRoutingList(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { cardRoutingList, hasPreviousPage, hasNextPage } = CardRoutingGridService.getGQLResponse(data);

      this.setState({ cardRoutingList, hasPreviousPage, hasNextPage, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onFieldsConfigChange = (list) => {
    this.fetchRoutingListGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value, cardRoutingList: [] });
  };

  getSelectCallbackByName = (name) => (value) => {
    this.setState({ [name]: value, cardRoutingList: [], pageValue: 1 });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  deleteRoutes = async () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.cardRoutingRowDelete(selectedItems.map(({ id }) => id));
      message.success(`${translate('page.routingManagement.successfullyDeleted')}`, 2.5);
      this.fetchRoutingListGQL();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const {
      bins,
      selectedBins,
      systemTypes,
      selectedSystemTypes,
      panTypes,
      selectedPanTypes,
      incomingServiceList,
      selectedIncomingServiceList,
      outcomingServiceList,
      selectedOutcomingServiceList,
      banks,
      selectedBanks,
      selectedItems,
    } = this.state;

    return (
      <Row>
        <div className="CardRoutingGrid-searchContent">
          <div className="CardRoutingGrid-selects">
            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectLabel">Bin</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={bins}
                  colConfig={BIN_COLUMN_CONFIG}
                  selectItems={selectedBins}
                  onSave={this.getSelectCallbackByName('selectedBins')}
                  defaultTitle={`${translate('page.routingManagement.select')} bin`}
                  isAllowEmptyList
                />
              </div>
            </div>

            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectWideLabel">System_Type</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={systemTypes}
                  colConfig={SYSTEM_TYPES_COLUMN_CONFIG}
                  selectItems={selectedSystemTypes}
                  onSave={this.getSelectCallbackByName('selectedSystemTypes')}
                  defaultTitle={`${translate('page.routingManagement.select')} System_Type`}
                  isAllowEmptyList
                />
              </div>
            </div>

            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectLabel">PAN_Type</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={panTypes}
                  colConfig={PAN_TYPES_COLUMN_CONFIG}
                  selectItems={selectedPanTypes}
                  onSave={this.getSelectCallbackByName('selectedPanTypes')}
                  defaultTitle={`${translate('page.routingManagement.select')} PAN_Type`}
                  isAllowEmptyList
                />
              </div>
            </div>

            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectWideLabel">Incoming_service_id</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={incomingServiceList}
                  colConfig={SERVICES_COLUMN_CONFIG}
                  selectItems={selectedIncomingServiceList}
                  onSave={this.getSelectCallbackByName('selectedIncomingServiceList')}
                  defaultTitle={`${translate('page.routingManagement.select')} Incoming_service_id`}
                  isAllowEmptyList
                />
              </div>
            </div>

            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectLabel">BANK_NAME</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={banks}
                  colConfig={BANK_SERVICES_COLUMN_CONFIG}
                  selectItems={selectedBanks}
                  onSave={this.getSelectCallbackByName('selectedBanks')}
                  defaultTitle={`${translate('page.routingManagement.select')} BANK_NAME `}
                  isAllowEmptyList
                />
              </div>
            </div>

            <div className="CardRoutingGrid-selectRow">
              <div className="CardRoutingGrid-selectWideLabel">Outcoming_service_id</div>

              <div className="CardRoutingGrid-select">
                <GridDropdown
                  data={outcomingServiceList}
                  colConfig={SERVICES_COLUMN_CONFIG}
                  selectItems={selectedOutcomingServiceList}
                  onSave={this.getSelectCallbackByName('selectedOutcomingServiceList')}
                  defaultTitle={`${translate('page.routingManagement.select')} Outcoming_service_id `}
                  isAllowEmptyList
                />
              </div>
            </div>
          </div>

          <div className="CardRoutingGrid-searchAction">
            <Button type="primary" onClick={this.fetchRoutingListGQL}>
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>

        <div className="CardRoutingGrid-action">
          {checkElement('cardRouting-addItems', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ modalVariant: 'add' });
                }}
              >
                {translate('core.buttonTitles.add')}
              </Button>
            </div>
          )}

          {checkElement('cardRouting-changeItems', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                  this.setState({ modalVariant: selectedItems.length > 1 ? 'massChange' : 'change' });
                }}
              >
                {translate('core.buttonTitles.change')}
              </Button>
            </div>
          )}

          {checkElement('cardRouting-deleteItems', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                  this.setState({ modalVariant: 'delete' });
                }}
              >
                {translate('core.buttonTitles.delete')}
              </Button>
            </div>
          )}

          {checkElement('cardRouting-banks', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({
                    type: 'managementBanks',
                  });
                }}
              >
                {translate('page.routingManagement.banks')}
              </Button>
            </div>
          )}
        </div>
      </Row>
    );
  };

  getHandleExelExportDate = async () => {
    const { cardRoutingList } = this.state;

    return cardRoutingList.map((route) =>
      Object.entries(route).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value || value === 0 ? value : 'NULL' }),
        {},
      ),
    );
  };

  render() {
    const { translate } = this.props;
    const {
      bins,
      systemTypes,
      panTypes,
      incomingServiceList,
      outcomingServiceList,
      banks,
      selectedItems,
      cardRoutingList,
      isLoading,
      pageValue,
      hasNextPage,
      hasPreviousPage,
      modalVariant,
    } = this.state;

    if (!checkElement('cardRouting-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={cardRoutingList}
          onRefresh={this.fetchRoutingListGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="cardRoutingGrid"
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
          isShowSpecificFilterByFieldName="bin"
          onSelect={this.handleSelectionChange}
          getHandleExelExportDate={this.getHandleExelExportDate}
          fieldForSelection="id"
          multiSelected
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="id" title="id" width="100" cell={NullCellBoolean} filter="numeric" />
          <GridColumn
            field="bin"
            title="bin"
            width="100"
            cell={NullCellBoolean}
            filterable={false}
            columnMenu={false}
          />
          <GridColumn field="rangeFrom" title="range_from" width="110" cell={NullCellBoolean} filter="numeric" />
          <GridColumn field="rangeTo" title="range_to" width="100" cell={NullCellBoolean} filter="numeric" />
          <GridColumn
            field="systemType"
            title="system_type"
            width="120"
            filterable={false}
            columnMenu={false}
            cell={NullCellBoolean}
          />
          <GridColumn
            field="incomingServiceId"
            title="incoming_service_id"
            width="180"
            filterable={false}
            columnMenu={false}
            cell={NullCellBoolean}
          />
          <GridColumn
            field="outcomingServiceId"
            title="outcoming_service_id"
            width="180"
            filterable={false}
            columnMenu={false}
            cell={NullCellBoolean}
          />
          <GridColumn field="amountMax" title="amount_max" width="130" cell={NullCellBoolean} filter="numeric" />
          <GridColumn
            field="panType"
            title="pan_type"
            width="100"
            filterable={false}
            columnMenu={false}
            cell={NullCellBoolean}
          />
          <GridColumn
            field="bankName"
            title="bank_name"
            width="120"
            filterable={false}
            columnMenu={false}
            cell={NullCellBoolean}
          />
          <GridColumn field="priority" title="priority" width="90" cell={NullCellBoolean} filter="numeric" />
          <GridColumn field="last" title="last" width="70" cell={WrappedCellBoolean} filter="numeric" />
          <GridColumn field="weight" title="weight" width="90" cell={NullCellBoolean} filter="numeric" />
          <GridColumn field="active" title="active" width="80" cell={WrappedCellBoolean} filter="numeric" />
        </Grid>
        {(modalVariant === 'change' || modalVariant === 'massChange') && (
          <UpdateModal
            variant={modalVariant}
            selectedItems={selectedItems}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            dropdownsData={{ bins, systemTypes, panTypes, incomingServiceList, outcomingServiceList, banks }}
          />
        )}

        {modalVariant === 'add' && (
          <AddModal
            visible={modalVariant === 'add'}
            dropdownsData={{ bins, systemTypes, panTypes, incomingServiceList, outcomingServiceList, banks }}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
          />
        )}

        {modalVariant === 'delete' && (
          <DeleteModal
            title={translate('page.routingManagement.deleting')}
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.deleteRoutes}
            deleteContent={translate('page.routingManagement.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CardRoutingGrid);
