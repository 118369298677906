import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Select, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { tabService, ApiService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
// import '../../screenControl(terminalsControl)/profiles/modalProfile.scss';
import LoadingPanel from '../../../components/loader';

const { Option } = Select;

const api = new ApiService();

class ModalCommands extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = props;
    this.currentArray = findArray('adding-addressed-payment');
    this.state = {
      loading: false,
      isLoading: false,
      isErrorSendComission: false,
      currentCommand: translate('grids.addingAddressedPaymentApplications.select-title'),
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    item: PropTypes.any,
    arrSelected: PropTypes.any,
    update: PropTypes.func.isRequired,
  };

  renderChangeComissionTab = () => {
    this.props.toggleModal();
    return tabService.addTab({
      type: 'changeComission',
      dataItem: this.props.arrSelected[0],
    });
  };

  checkComissionSet = () => {
    const { arrSelected, data } = this.props;
    let isErrorSendComission = false;
    const filterData = data.filter((el) => {
      if (arrSelected.findIndex((selEl) => selEl === el.RequestId) !== -1) return true;
      return false;
    });
    if (filterData.findIndex((el) => el.DealerCommissionsSet === false) !== -1) {
      isErrorSendComission = true;
    }
    this.setState({ isErrorSendComission });
    return filterData.findIndex((el) => el.DealerCommissionsSet === false) === -1;
  };

  sendApplication = async () => {
    const { showError, translate, update, arrSelected, toggleModal } = this.props;
    if (!this.checkComissionSet()) return;
    try {
      this.toggleLoader();
      const request = await api.servicesCRMRequestSend(arrSelected);
      this.setState({ currentCommand: null });
      toggleModal();
      message.success(
        <div className="addingAddressedPaymentApplications--success">
          <p>{translate('grids.addingAddressedPaymentApplications.command-send-success-1')}</p>
          <p>{translate('grids.addingAddressedPaymentApplications.command-send-success-2')}</p>
        </div>,
        2,
        update,
      );

      return request;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  deleteApplication = async () => {
    const { showError, translate, update, arrSelected, toggleModal } = this.props;
    try {
      this.toggleLoader();
      await api.servicesCRMRequestDelete(arrSelected);
      this.setState({ visible: false, currentCommand: null });
      toggleModal();
      message.success(translate('grids.addingAddressedPaymentApplications.command-delete-success'), 2, update);
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  cloneApplication = () => {
    return tabService.addTab({
      type: 'cloneApplication',
      dataItem: this.props.item,
    });
  };

  onChangeCommand = (currentCommand) => {
    this.setState({ currentCommand });
  };

  checkArrayOfApplicationsForStatus = () => {
    const { data, arrSelected } = this.props;
    if (!arrSelected) return true;
    const filterData = data.filter((el) => {
      if (arrSelected.findIndex((selEl) => selEl === el.RequestId) !== -1) return true;
      return false;
    });
    return filterData.findIndex((el) => el.StatusId !== 0) !== -1;
  };

  onSubmitCommand = () => {
    const { currentCommand } = this.state;
    switch (currentCommand) {
      case 'send':
        return this.sendApplication();
      case 'delete':
        return this.deleteApplication();
      case 'changeComission':
        return this.renderChangeComissionTab();
      default:
        return;
    }
  };

  render() {
    const { translate, visible, toggleModal, arrSelected } = this.props;
    const { loading, isLoading, currentCommand, isErrorSendComission } = this.state;
    return (
      <>
        <Modal
          title={translate('grids.addingAddressedPaymentApplications.select-title')}
          visible={visible}
          centered
          onCancel={toggleModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.onSubmitCommand}>
              {translate('page.screenControlTerminals.btn-submit-dsb')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Select
                value={currentCommand}
                onChange={this.onChangeCommand}
                placeholder={translate('grids.addingAddressedPaymentApplications.select-title')}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                  JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
              >
                {checkElement('aap-command-send', this.currentArray) && (
                  <Option value="send" disabled={this.checkArrayOfApplicationsForStatus()}>
                    {translate('grids.addingAddressedPaymentApplications.option-1')}
                  </Option>
                )}
                {checkElement('aap-command-delete', this.currentArray) && (
                  <Option value="delete" disabled={this.checkArrayOfApplicationsForStatus()}>
                    {translate('grids.addingAddressedPaymentApplications.option-2')}
                  </Option>
                )}
                {checkElement('aap-command-changeComission', this.currentArray) && (
                  <Option
                    value="changeComission"
                    disabled={!arrSelected || arrSelected.length > 1 || this.checkArrayOfApplicationsForStatus()}
                  >
                    {translate('grids.addingAddressedPaymentApplications.option-3')}
                  </Option>
                )}
              </Select>
            </Col>
          </Row>
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={24}>
              {isErrorSendComission && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('page.aap-changeComission.modal-commands-error-comNotSet')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCommands);
