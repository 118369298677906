import React from 'react';
import { Col } from 'antd';
import Input from '../../../components/Input';
import { PHONE_CODE } from './constant';

export const renderUserPropertiesInput = (
  name,
  label,
  type,
  isDisabled = false,
  span = 24,
  md = 16,
  maxLength = 150,
  formState,
  onChangeFormState,
  formErrors,
  onError,
  translate,
  validators = {},
) => {
  return (
    <>
      <Col span={24} md={8}>
        {translate(`grids.userProperties.column-${label}`)}
      </Col>

      <Col span={span} md={md}>
        <Input
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          name={name}
          type={type}
          disabled={isDisabled}
          validators={validators}
          checked={formState.Status}
          mask="( 000 ) - 000 - 00 - 00"
          {...(type === 'phone' ? { addonBefore: `+${PHONE_CODE}` } : {})}
          maxLength={maxLength}
        />
      </Col>
    </>
  );
};
