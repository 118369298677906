import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import Grid from '../../components/grids/baseGrid';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';

const api = new ApiService();

class GridAccountDetails extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  state = {
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  buildToolbar = () => {
    const {
      accInfo: { accountId, date },
      translate,
    } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div>
          {`${translate('grids.accountsTurn.toolbar-bill')}`} #{accountId}{' '}
          {`${translate('grids.allEncashments.toolbar-word')}`} {date}
        </div>
      </Col>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <Grid data={data} toolbar={this.buildToolbar()} name="accountsDetail" permissionName="accountmanager">
        <GridColumn field="MovementId" title="grids.accountsDetail.column-payment" width="145" filter="numeric" />

        <GridColumn
          field="WorkDate"
          title="grids.accountsDetail.column-date"
          width="170"
          filter="date"
          format="dd.MM.yyyy"
        />

        <GridColumn field="AccountName" title="grids.accountsTurn.column-name" width="180" />

        <GridColumn field="Amount" title="grids.accountsDetail.column-amount" width="145" filter="numeric" />

        <GridColumn field="Currency" title="grids.transactions.column-currency" width="170" />

        <GridColumn field="Description" title="grids.accountsDetail.column-narrative" width="400" />

        <GridColumn field="UserNamePost" title="grids.accountsDetail.column-performer" width="170" />

        <GridColumn
          field="DatePost"
          title="grids.accountsDetail.column-datePost"
          width="170"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="TypeName" title="grids.accountsDetail.column-category" width="300" />
      </Grid>
    );
  }
}

export default withTranslate(GridAccountDetails);
