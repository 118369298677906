const terminalsByServices = (translate, value) => {
  switch (value) {
    case 'terminalsByService-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'terminalsByService-search':
      return `${translate(`page.adminPanel.btnCreateReport`)}`;
    default:
      return value;
  }
};
export default terminalsByServices;
