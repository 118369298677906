const users = (translate, value) => {
  switch (value) {
    case 'users-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'users-usersBtnAddNewUser':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.addNewUser')}`;
    case 'users-userBtnAddUser':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-newUser')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('core.buttonTitles.add')}`;
    case 'users-userBtnGroupMembership':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-newUser')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('core.buttonTitles.btnMembershipGroup')}`;
    case 'users-userPropertiesBtnEdit':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-userProperties')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('core.buttonTitles.edit')}`;
    case 'users-userPropertiesBtnEditCollectorCode':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-userProperties')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('grids.userProperties.modalChangeCodeCollector.title')}`;
    case 'users-userPropertiesBtnShowAdditionalInfo':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-userProperties')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate('core.buttonTitles.showAdditionalProperties')}`;
    case 'users-isDropdownUserId':
      return `${translate(`page.adminPanel.dropdown-show`)}: ${translate('grids.userList.column-userId')}`;
    case 'users-tabUserProperties':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate('page.dashboard.tab-userProperties')}`;
    case 'users-tabTerminalAcess':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate('page.dashboard.tab-access-terminals')}`;
    case 'users-tabGroupMembership':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate('page.dashboard.tab-group-membership')}`;
    case 'users-tabGroupMembershipEditButton':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate(
        `page.dashboard.tab-group-membership`,
      )}: ${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.edit`)}`;
    case 'users-tabWorkDealers':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate('page.dashboard.tab-workDealers')}`;
    case 'users-tabWorkWithDealersEditButton':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-workDealers')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate(`core.buttonTitles.edit`)}`;
    case 'users-tabTerminalAcessBtnGive':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-workDealers')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate(`core.buttonTitles.issue`)}`;
    case 'users-tabTerminalAcessBtnWithdraw':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-workDealers')}: ${translate(
        'page.adminPanel.type-button',
      )}: ${translate(`core.buttonTitles.revoke`)}`;
    default:
      return value;
  }
};
export default users;
