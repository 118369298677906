class LimitsService {
  limitValidation = (translate) => (value) => {
    if (!value && value !== 0) {
      return '';
    }

    if (value < 1) {
      return `${translate('page.fishkaService.enteredMinNumberError')} 1`;
    }

    if (value > 399999.99) {
      return `${translate('page.fishkaService.enteredMaxNumberError')} 399 999,99`;
    }

    if (!!isNaN(value)) {
      return `${translate('page.fishkaService.invalidDataFormatError')}`;
    }

    if (`${Math.round(value * 1000000000000) / 10000000000}`.indexOf('.') > -1) {
      return `${translate('page.fishkaService.commaPartError')}`;
    }

    return '';
  };
}

export default new LimitsService();
