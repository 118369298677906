import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';

import { ApiService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import findArray from '../../utils/findArrForCurrentPage';
import ModalWhiteListAdd from './modalWhiteListAdd';

const api = new ApiService();

class GridWhiteListAdminAdd extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('whiteListAdmin');
  }

  state = {
    isLoading: false,
    data: [],
    isVisibleModalAdd: false,
    selectedItems: [],
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-whiteListAdminAdd');
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const {
      item: { owners },
    } = this.props;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        HideDeleted: true,
      };
      const data = await api.getTerminalsByOwner(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 16 }}>
        <Button
          type="primary"
          style={{ marginTop: '10px' }}
          onClick={this.toggleModalAdd}
          disabled={!selectedItems.length}
        >
          {translate('grids.availableServices.button-add')}
        </Button>
      </Col>
    );
  };

  toggleModalAdd = () => {
    this.setState(({ isVisibleModalAdd }) => {
      return {
        isVisibleModalAdd: !isVisibleModalAdd,
      };
    });
  };

  closeModalAdd = () => {
    this.setState({ isVisibleModalAdd: false });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render = () => {
    const { isLoading, data, initialSort, isVisibleModalAdd, selectedItems } = this.state;
    const {
      item: { owners },
    } = this.props;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          selectedField="selected"
          toggleLoader={this.toggleLoader}
          name="whiteListAdminAdd"
          permissionName="whiteListAdmin"
          multiSelected={true}
          fieldForSelection="TerminalId"
          initialSort={initialSort}
          onSelect={this.handleSelectionChange}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn field="OwnerName" title="grids.terminalOperabilityChoose.column-ownerName" width="250" />

          <GridColumn
            field="TerminalId"
            title="grids.terminalOperabilityChoose.column-terminalId"
            width="150"
            filter="numeric"
          />

          <GridColumn field="TerminalName" title="grids.terminalOperabilityChoose.column-name" width="250" />

          <GridColumn
            field="NetworkContractName"
            title="grids.terminalOperabilityChoose.column-networkContract"
            width="250"
          />

          <GridColumn field="RegionName" title="grids.terminalOperabilityChoose.column-region" width="250" />

          <GridColumn field="CityName" title="grids.terminalOperabilityChoose.column-city" width="200" />

          <GridColumn field="TerminalAddress" title="grids.terminalOperabilityChoose.column-address" width="250" />

          <GridColumn field="StatusName" title="grids.terminalOperabilityChoose.column-status" width="250" />
        </Grid>
        {isVisibleModalAdd && (
          <ModalWhiteListAdd
            visible={isVisibleModalAdd}
            closeModal={this.closeModalAdd}
            owners={owners}
            selectedItems={selectedItems.map(({ TerminalId }) => TerminalId)}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridWhiteListAdminAdd);
