import { uppercaseFirstLetter } from '../../../utils/uppercaseFirstLetter';

class CommissionsServicesService {
  normalizeCommissionsClientServices(commissionsClientServices) {
    if (!commissionsClientServices || !commissionsClientServices.length) {
      return [];
    }

    const commissionsClientServicesWidthUniqeId = commissionsClientServices.map((commissionsClientService) => ({
      ...commissionsClientService,
      Id: `${commissionsClientService.serviceId}-${commissionsClientService.commissionId}-${commissionsClientService.paymentType}-${commissionsClientService.profileId}`,
    }));

    return commissionsClientServicesWidthUniqeId.length
      ? commissionsClientServicesWidthUniqeId.map((item) =>
          Object.entries(item).reduce((acc, [key, value]) => ({ ...acc, [uppercaseFirstLetter(key)]: value }), {}),
        )
      : [];
  }

  getPercent(countInProcess, countTotal, translate) {
    const percent = Math.round((1 - countInProcess / countTotal) * 100);

    const processedMessagePrefix =
      percent > 5
        ? `${translate('page.commissionsServices.processed')} ${percent}%`
        : `${translate('page.commissionsServices.processedLess')} 5%`;

    return `${processedMessagePrefix} ${translate('page.commissionsServices.processedMessagePostfix')}`;
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.commissionClientServiceList) {
      return { commissionsClientServices: [], hasNextPage: false, hasPreviousPage: false };
    }

    const commissionClientServiceList = data.data.commissionClientServiceList;

    const commissionsClientServices =
      commissionClientServiceList.items && commissionClientServiceList.items.length
        ? this.normalizeCommissionsClientServices(commissionClientServiceList.items)
        : [];

    const pageInfo = commissionClientServiceList.pageInfo;

    return {
      commissionsClientServices,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new CommissionsServicesService();
