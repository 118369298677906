import React, { PureComponent } from 'react';
import { Checkbox, Input } from 'antd';

import './ElementProps.styled.scss';
import { withTranslate } from '../../contexts/localContext';
import ElementPropsService from './ElementProps.service';

const { TextArea } = Input;

class PropValueRow extends PureComponent {
  render() {
    const { valueType, translate, value, setInputValues } = this.props;

    if (valueType === 'bool') {
      const castedValue = `${value}`.toLowerCase() === 'true';

      return (
        <div className="ElementProps-valueRow ElementProps-checkBoxRow">
          <div>{translate('page.profilesMenu.propValue')}</div>
          <Checkbox
            checked={castedValue}
            onChange={() => {
              setInputValues(!castedValue, '');
            }}
          />
        </div>
      );
    }

    if (valueType === 'int') {
      return (
        <div className="ElementProps-valueRow">
          <div>{translate('page.profilesMenu.propValue')}</div>
          <Input
            className="UpdateMenuElementModal-input"
            value={value}
            onChange={({ target: { value } }) => {
              setInputValues(
                value,
                Number.isInteger(+value) ? '' : 'grids.comission-sub.errorMessages.integerRequired',
              );
            }}
          />
        </div>
      );
    }

    if (valueType === 'decimal') {
      return (
        <div className="ElementProps-valueRow">
          <div>{translate('page.profilesMenu.propValue')}</div>
          <Input
            className="UpdateMenuElementModal-input"
            value={value}
            onChange={({ target: { value } }) => {
              setInputValues(value, ElementPropsService.decimalValidate(value));
            }}
          />
        </div>
      );
    }

    return (
      <div className="ElementProps-valueRow">
        <div>{translate('page.profilesMenu.propValue')}</div>
        <TextArea
          value={value}
          onChange={({ target: { value } }) => {
            setInputValues(value, '');
          }}
        />
      </div>
    );
  }
}

export default withTranslate(PropValueRow);
