import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Card, Col, Row } from 'antd';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { renderInputAddNewUser } from './renderInputAddNewUser';
import { ADDITIONAL_INFO_PROPS, BASE_INFO_PROPS } from './constants';
import GridRoles from './GridRoles/GridRoles';

import './AddNewUser.scss';
import { PHONE_CODE } from '../UserProperties/constant';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class AddNewUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectItems: [],
      isLoading: false,
    };
    this.currentArray = findArray('users');
  }

  renderAddNewUserInputByProps = (name, label, type, maxLength) => {
    const { formState, onChangeFormState, formErrors, onError, translate, validators } = this.props;
    return renderInputAddNewUser(
      name,
      label,
      type,
      translate,
      maxLength,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      validators,
    );
  };

  renderBaseInfoFields = () => {
    return BASE_INFO_PROPS.map(({ name, label, type, maxLength }, key) => {
      return (
        <Row className="AddNewUser-row" key={key}>
          {this.renderAddNewUserInputByProps(name, label, type || 'text', maxLength)}
        </Row>
      );
    });
  };

  renderAdditionalInfoFields = () =>
    ADDITIONAL_INFO_PROPS.map(({ name, label, type, maxLength }, key) => {
      return (
        <Row className="AddNewUser-row" key={key}>
          {this.renderAddNewUserInputByProps(name, label, type || 'text', maxLength)}
        </Row>
      );
    });

  onRowClick = (e) => {
    this.setState(({ currentSelectItems }) => ({
      currentSelectItems: currentSelectItems.includes(e.dataItem.RoleId)
        ? currentSelectItems.filter((id) => id !== e.dataItem.RoleId)
        : [...currentSelectItems, e.dataItem.RoleId],
    }));
  };

  onAddNewUser = async () => {
    const { currentSelectItems } = this.state;
    const { onSubmitValidateField, selectOwners } = this.props;
    const formError = onSubmitValidateField();

    if (formError) return;

    try {
      await this.setState({ isLoading: true });
      const { formState } = this.props;
      const model = {
        ...formState,
        Phone: `${PHONE_CODE}${formState.Phone.replace(/\D+/g, '')}`,
        UserId: null,
        OwnerId: selectOwners && selectOwners.length ? selectOwners[0] : selectOwners,
        Roles: [
          ...currentSelectItems.map((RoleId) => {
            return { roleId: RoleId, forDelete: false };
          }),
        ],
      };
      await api.UserPropertiesAndRolesSet(model);
      await window.location.reload();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render = () => {
    const {
      allOwners,
      selectOwners,
      allUserRoles,
      translate,
      onShowAdditionalInfo,
      showAdditionalInfo,
      formErrors,
      setOwners,
    } = this.props;
    const { currentSelectItems, isLoading } = this.state;
    const isDisabledByErrors = !!Object.values(formErrors).filter((item) => item).length;

    return (
      <>
        <Col span={24} lg={24} className="AddNewUser-col">
          <Row type="flex" align="middle" className="Users-ownerDropdownRow">
            <Col span={2} md={2}>
              {translate('page.commissionsServices.dealer')}
            </Col>
            <Col span={23} md={14} className="Users-ownerDropdown">
              <GridDropdownWithDeletedOwners
                data={DropdownNormalizersService.normalizeOwners(allOwners)}
                selectItems={selectOwners}
                onSave={setOwners}
                isSingle
              />
            </Col>

            <Col span={4} md={4}>
              {checkElement('users-userBtnAddUser', this.currentArray) && (
                <Button type="primary" disabled={isDisabledByErrors} onClick={this.onAddNewUser}>
                  {translate('core.buttonTitles.add')}
                </Button>
              )}
            </Col>
          </Row>

          <Row className="AddNewUser-row">
            <Col className="AddNewUser-form" xs={24} sm={24} md={24} lg={24}>
              <Row type="flex" justify="space-between" className="AddNewUser-row">
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Card
                    type="inner"
                    title={translate('grids.userProperties.mainProperties')}
                    className="AddNewUser-card"
                  >
                    {this.renderBaseInfoFields()}
                  </Card>

                  <Card
                    type="inner"
                    title={translate('grids.userProperties.additionalInformation')}
                    className="AddNewUser-card"
                  >
                    {this.renderAdditionalInfoFields()}
                  </Card>
                  {checkElement('users-userBtnGroupMembership', this.currentArray) && (
                    <Button type="primary" className="AddNewUser-groupMembershipBtn" onClick={onShowAdditionalInfo}>
                      {translate('core.buttonTitles.btnMembershipGroup')}
                    </Button>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={11}>
                  {showAdditionalInfo && allUserRoles && (
                    <GridRoles
                      roleList={allUserRoles}
                      onRowClick={this.onRowClick}
                      currentSelectItems={currentSelectItems}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(AddNewUser);
