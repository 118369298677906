import React, { PureComponent } from 'react';
import { Modal, Button, Col, message, Select, Row, Input } from 'antd';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import CustomInput from '../../../../components/Input';

import { withTranslate } from '../../../../contexts/localContext';
import '../NonRiskLimitsGrid.scss';
import ValidationService from './validation.service';
import { ApiService } from '../../../../services';
import { getDataByTimezoneOffset } from '../../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class SetLimitsDialog extends PureComponent {
  state = {
    isLoading: false,
    minLimitTurns: 0,
    minLimitTurnsNight: 0,
    maxLimitTurns: 0,
    maxLimitTurnsNight: 0,
  };

  getLimits = async () => {
    try {
      const { MinLimitTurns, MinLimitTurnsNight, MaxLimitTurns, MaxLimitTurnsNight } =
        await api.terminalsBlockingRulesLimit();
      await this.setState({ isLoading: true });
      this.setState({
        minLimitTurns: MinLimitTurns,
        minLimitTurnsNight: MinLimitTurnsNight,
        maxLimitTurns: MaxLimitTurns,
        maxLimitTurnsNight: MaxLimitTurnsNight,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getTimePickerChangeCallback =
    (fieldName) =>
    ({ target: { value } }) => {
      const {
        onChangeFormState,
        formState: { nightTimeStart, nightTimeEnd },
        onError,
        translate,
      } = this.props;

      onError('global', null);
      onChangeFormState(fieldName, value);
      onError(
        'global',
        ValidationService.nightTimeStartValidator(translate)({ nightTimeStart, nightTimeEnd, [fieldName]: value }),
      );
    };

  onLimitsChange = async () => {
    const { formState, terminalList, getTerminalList, closeModal } = this.props;
    const { nightTimeStart, nightTimeEnd, dailyLimit, nightlyLimit } = formState;
    try {
      await this.setState({ isLoading: true });
      const model = {
        terminalId: terminalList,
        limitTurns: dailyLimit || -1,
        limitTurnsNight: nightlyLimit || -1,
        nightTimeStart: nightTimeStart
          ? getDataByTimezoneOffset(new Date(nightTimeStart)).toISOString()
          : getDataByTimezoneOffset(new Date(2000, 0, 1, 12, -0, 0)).toISOString(),
        nightTimeEnd: nightTimeEnd
          ? getDataByTimezoneOffset(new Date(nightTimeEnd)).toISOString()
          : getDataByTimezoneOffset(new Date(2000, 0, 1, 12, -0, 0)).toISOString(),
      };
      await api.terminalsBlockingRulesChange(model);
      closeModal();
      getTerminalList();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidMount() {
    this.getLimits();
  }

  render() {
    const { isLoading, minLimitTurns, minLimitTurnsNight, maxLimitTurns, maxLimitTurnsNight } = this.state;
    const { translate, visible, closeModal, onError, getIsErrorsExist, formErrors, formState } = this.props;
    const { nightTimeStart, nightTimeEnd } = formState;

    return (
      <Modal
        title={translate('page.nonRiskLimitsGrid.changeLimits')}
        visible={visible}
        onCancel={closeModal}
        className="SetLimitsDialog"
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={this.onLimitsChange}
            disabled={getIsErrorsExist(formErrors)}
            loading={isLoading}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="submit" loading={isLoading} onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        centered
      >
        <Row>
          <Col span={24} className="SetLimitsDialog-actionRow">
            <Col span={24} sm={11} className="SetLimitsDialog-InputRow">
              <Col span={8}>{translate('page.nonRiskLimitsGrid.col-dailyLimit')}</Col>
              <Col span={14} offset={2}>
                <CustomInput
                  type="number"
                  name="dailyLimit"
                  setError={onError}
                  {...this.props}
                  validators={ValidationService.getValidators(
                    translate,
                    minLimitTurns,
                    minLimitTurnsNight,
                    maxLimitTurns,
                    maxLimitTurnsNight,
                  )}
                />
              </Col>
            </Col>
            <Col span={24} sm={{ span: 11, offset: 2 }} className="SetLimitsDialog-InputRow">
              <Col span={8}>{translate('page.nonRiskLimitsGrid.col-nightLimit')}</Col>
              <Col span={14} offset={2}>
                <CustomInput
                  type="number"
                  name="nightlyLimit"
                  setError={onError}
                  {...this.props}
                  validators={ValidationService.getValidators(
                    translate,
                    minLimitTurns,
                    minLimitTurnsNight,
                    maxLimitTurns,
                    maxLimitTurnsNight,
                  )}
                />
              </Col>
            </Col>
          </Col>

          <Col span={24} className="SetLimitsDialog-actionRow">
            <Col span={24} sm={11} className="SetLimitsDialog-InputRow">
              <Col span={8}>{translate('page.nonRiskLimitsGrid.col-nightTimeWith')}</Col>
              <Col span={14} offset={2}>
                <TimePicker
                  className="SetLimitsDialog-timePicker"
                  format="HH:mm"
                  value={nightTimeStart}
                  nowButton={false}
                  cancelButton={false}
                  onChange={this.getTimePickerChangeCallback('nightTimeStart')}
                />
              </Col>
            </Col>
            <Col span={24} sm={{ span: 11, offset: 2 }} className="SetLimitsDialog-InputRow">
              <Col span={8}>{translate('page.nonRiskLimitsGrid.col-nightTimeTo')}</Col>
              <Col span={14} offset={2}>
                <TimePicker
                  className="SetLimitsDialog-timePicker"
                  format="HH:mm"
                  value={nightTimeEnd}
                  nowButton={false}
                  cancelButton={false}
                  onChange={this.getTimePickerChangeCallback('nightTimeEnd')}
                />
              </Col>
            </Col>
          </Col>
          <Col span={24}>
            {formErrors.global && <div className="SetLimitsDialog-globalError">{formErrors.global}</div>}
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default withTranslate(SetLimitsDialog);
