import React, { PureComponent } from 'react';
import { Input, Button, Card, Select, InputNumber as CoreNumberInput, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { EXTENDED_FILTER_OPERATORS } from './constants';
import { withTranslate } from '../../contexts/localContext';

import './FilterConstructorModal.styled.scss';

const { Option } = Select;

class ExpressionBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isValueThrottling: false,
      currentFieldValue: this.props.data.value,
    };
  }

  onFieldChange = (value) => {
    const { updateData, data } = this.props;
    const { isValueThrottling } = this.state;

    this.setState({ currentFieldValue: value });

    if (!isValueThrottling) {
      updateData({ ...data, value });

      this.setState({ isThrottled: true });

      setTimeout(() => {
        this.setState({ isThrottled: false });
        updateData({ ...data, value: this.state.currentFieldValue });
      }, 600);
    }
  };

  getOperatorList = (fieldList) => {
    const {
      data: { field },
    } = this.props;

    const currentSelectedField = fieldList.find(({ key }) => key === field);

    return EXTENDED_FILTER_OPERATORS[currentSelectedField ? currentSelectedField.filter : 'text'];
  };

  renderInputBySelectedField = (fieldList) => {
    const {
      data: { field, operator },
    } = this.props;
    const { currentFieldValue } = this.state;

    const currentSelectedField = fieldList.find(({ key }) => key === field);

    if (!currentSelectedField) {
      return <Input disabled />;
    }

    if (operator === 'anyOf') {
      return (
        <Input
          value={currentFieldValue}
          onChange={({ target: { value } }) => {
            this.onFieldChange(value);
          }}
        />
      );
    }

    const isFieldDisabled =
      operator === 'isnull' || operator === 'isnotnull' || operator === 'empty' || operator === 'nempty';

    if (currentSelectedField.filter === 'numeric') {
      return (
        <CoreNumberInput
          value={currentFieldValue}
          onChange={(value) => {
            this.onFieldChange(value);
          }}
          disabled={isFieldDisabled}
        />
      );
    }

    if (currentSelectedField.filter === 'date') {
      return (
        <DatePicker
          value={currentFieldValue}
          onChange={({ target: { value } }) => {
            this.onFieldChange(value);
          }}
          disabled={isFieldDisabled}
          format="yyyy.MM.dd HH:mm:ss"
        />
      );
    }

    if (currentSelectedField.filter === 'boolean') {
      return (
        <Checkbox
          value={!!currentFieldValue}
          onChange={() => {
            this.onFieldChange(!currentFieldValue);
          }}
          disabled={isFieldDisabled}
        />
      );
    }

    return (
      <Input
        value={currentFieldValue}
        onChange={({ target: { value } }) => {
          this.onFieldChange(value);
        }}
        disabled={isFieldDisabled}
      />
    );
  };

  getIsDateFilter = (fieldList, field) =>
    fieldList && fieldList.length && fieldList.find(({ key }) => field === key).filter === 'date';

  getFieldChangeCallback = (fieldList) => (selectedField) => {
    const defaultValue = this.getIsDateFilter(fieldList, selectedField)
      ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
      : '';

    this.props.updateData({
      ...this.props.data,
      field: selectedField,
      operator: '',
      value: defaultValue,
    });
    this.setState({
      currentFieldValue: defaultValue,
    });
  };

  getOperatorChangeCallback = (fieldList) => (selectedOperator) => {
    const defaultValue = this.getIsDateFilter(fieldList, this.props.data.field)
      ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
      : '';

    this.props.updateData({
      ...this.props.data,
      operator: selectedOperator,
      value: defaultValue,
    });
    this.setState({
      currentFieldValue: defaultValue,
    });
  };

  render() {
    const { fieldsInfo, translate, data, updateData, removeBlock } = this.props;

    const fieldList = Object.entries(fieldsInfo).map(([key, { filter, title }]) => ({ key, filter, title }));
    const operatorList = this.getOperatorList(fieldList);

    return (
      <Card className="FilterConstructorModal-card" type="inner">
        <Select
          className="FilterConstructorModal-select"
          value={data.field}
          onChange={this.getFieldChangeCallback(fieldList)}
          placeholder={translate('page.filterConstructorModal.selectColumnName')}
        >
          {fieldList.map(({ key, title }) => (
            <Option value={key} key={key}>
              {translate(title)}
            </Option>
          ))}
        </Select>

        <Select
          className="FilterConstructorModal-select"
          value={data.operator}
          onChange={this.getOperatorChangeCallback(fieldList)}
          placeholder={translate('page.filterConstructorModal.selectOperator')}
          disabled={!data.field}
        >
          {operatorList.map(({ operator, text }) => (
            <Option value={operator} key={operator}>
              {translate(text)}
            </Option>
          ))}
        </Select>

        <div className="FilterConstructorModal-input">{this.renderInputBySelectedField(fieldList)}</div>

        <Button className="FilterConstructorModal-closeExpressionAction" onClick={removeBlock}>
          <CloseOutlined />
        </Button>
      </Card>
    );
  }
}

export default withTranslate(ExpressionBlock);
