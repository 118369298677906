import { Subject } from 'rxjs';

const subject = new Subject();

const tabService = {
  addTab: (item) => subject.next(item),
  clearTabs: () => subject.next(),
  getTabs: () => subject.asObservable(),
};

export default tabService;
