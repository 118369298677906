const previewApplication = (translate, value) => {
  switch (value) {
    case 'previewApplication-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'previewApplication-search-btn':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.search')}`;

    default:
      return value;
  }
};
export default previewApplication;
