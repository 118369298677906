import React, { PureComponent } from 'react';
import PartnerInfoSenderGrid from './PartnerInfoSenderGrid';
import PageTabs from '../../components/tabber/tabber';

class PartnerInfoSender extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-partnerInfoSender',
          content: <PartnerInfoSenderGrid />,
          closable: false,
        },
      ],
    };
  }

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  };
}

export default PartnerInfoSender;
