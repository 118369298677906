import React, { PureComponent } from 'react';
import DealerCommissionsPaymentTools from './DealerCommissionsPaymentTools';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';

class DealerCommissionsPaymentToolsPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.dashboard.tab-dealerCommissions-paymentTools',
        content: <DealerCommissionsPaymentTools />,
        closable: false,
        key: 0,
      },
    ],
  };
  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}

export default withTranslate(DealerCommissionsPaymentToolsPage);
