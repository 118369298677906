import PropTypes from 'prop-types';
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Button } from 'antd';

/**
 * Toggle detail for grid row
 */
export default class CellLink extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    mobile: PropTypes.bool,
  };

  toggleDetail = (e) => {
    const { dataItem, onChange } = this.props;
    onChange({ dataItem, e, field: 'expanded', value: !dataItem.expanded });
  };

  clickHandler = (e) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(this.props);
    } else {
      this.toggleDetail(e);
    }
  };

  renderLink() {
    const { field, dataItem } = this.props;
    const value = dataItem[field];

    return (
      <Button
        type="link"
        className="lg-p-0 lg-w-100"
        onClick={this.clickHandler}
        style={{ height: 'auto', lineHeight: '1', userSelect: 'auto' }}
      >
        {value}
      </Button>
    );
  }

  render() {
    const { mobile, className, style } = this.props;

    if (mobile) {
      return <>{this.renderLink()}</>;
    }

    return (
      <td className={`${className} lg-p-0`} style={{ ...style, zIndex: 4 }}>
        {this.renderLink()}
      </td>
    );
  }
}
