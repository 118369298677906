import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { withTranslate } from '../../../../contexts/localContext';
import CellValue from './cellValue';
import '../../../createTerminal/dropdown/dropdown.scss';
import './gridRemoteSettings.scss';

const rowHeight = 40;

/*
 * Список ограничений свойств,
 * которые уже есть на интерфейсе
 * страницы, дабы исключить повторы
 * то есть грида будет фильтровать элементы
 * с этими ключами
 */

const restrictions = [
  'skin',
  'scheduler_frequency_connect',
  'scheduler_frequency_update',
  'maintenance_phone_key',
  'receipt_header',
  'receipt_footer',
];

class GridRemoteSettings extends PureComponent {
  static defaultProps = {
    forNewTerminal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataState: {
        take: 40,
        filter: {
          logic: 'and',
          filters: [],
        },
        sort: [],
      },
      skip: 0,
      view: [],
      isMobile: false,
      selectedID: null,
      isLoading: false,
    };
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  componentDidMount() {
    this.onInit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.terminalSettingsExtended !== this.props.terminalSettingsExtended) {
      this.onInit();
    }
  }

  onInit = () => {
    const { terminalSettingsExtended } = this.props;
    const { dataState } = this.state;
    const filteredTerminalSettingsExtended = terminalSettingsExtended
      ? terminalSettingsExtended.filter((el) => !restrictions.includes(el.Key))
      : [];
    this.onDataStateChange({ data: dataState }, filteredTerminalSettingsExtended);
  };

  onDataStateChange = ({ data }, filteredTerminalSettingsExtended) => {
    this.setState({ dataState: data });
    const filterdView = filterBy(orderBy(filteredTerminalSettingsExtended, data.sort), data.filter);
    this.setState({ view: filterdView });
  };

  onRowClick = (event) => {
    this.setState({ selectedID: event.dataItem.Key });
  };

  onPageChange = (event) => {
    const { orders } = this.state;
    this.setState({
      orders: orders,
      skip: event.page.skip,
    });
  };

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  checkCustomFilterActivity = (field, dataState) => {
    if (!dataState.filter || dataState.filter.filters.length < 1) return;
    for (const elem of dataState.filter.filters) {
      elem.active = this.isFilterActive(field, elem);
      if (dataState.filter.filters.length > 1) {
        dataState.filter.filters.forEach((elem) => {
          if (dataState.filter.filters.find((el) => el.field === field)) {
            elem.active = true;
          }
        });
      }
      return elem.active;
    }
  };

  isFilterActive = (field, elem) => {
    if (elem.field === field) {
      return true;
    }
    return false;
  };

  updateGridExample = (Key, Value) => {
    const { updateTerminalsSettings } = this.props;
    const { view } = this.state;
    const resultView = view.map((item) => (item.Key === Key ? { ...item, Value } : item));

    updateTerminalsSettings(resultView);

    this.setState({ view: resultView });
  };

  render() {
    const { dataState, view, skip, isMobile } = this.state;
    const { translate, isEditing, skinsData, terminalId } = this.props;
    return (
      <Grid
        data={view.slice(skip, skip + 20).map((item) => ({
          ...item,
          DefaultValue: String(item.DefaultValue),
          selected: item.Key === this.state.selectedID,
        }))}
        resizable
        skip={skip}
        pageSize={dataState.take}
        filterable={true}
        sortable
        rowHeight={rowHeight}
        onPageChange={this.onPageChange}
        scrollable="virtual"
        filter={dataState.filter}
        sort={dataState.sort}
        total={view.length}
        onRowClick={this.onRowClick}
        onDataStateChange={this.onDataStateChange}
        className={`dropdown-owner city addSets-grid ${isMobile ? 'mobileMode' : ''}`}
        style={{ height: '220px', marginBottom: '20px' }}
        selectedField="selected"
      >
        <GridColumn
          field="Key"
          title={`${translate('grids.transactionDeatails.column-name')}`}
          width="200"
          filter="text"
          headerClassName={
            this.isColumnActive('Key', dataState) || this.checkCustomFilterActivity('Key', dataState) ? 'active' : ''
          }
        />

        <GridColumn
          field="Name"
          title={`${translate('grids.adminPanel.column-description')}`}
          width="300"
          filter="text"
          headerClassName={
            this.isColumnActive('Name', dataState) || this.checkCustomFilterActivity('Name', dataState) ? 'active' : ''
          }
        />

        <GridColumn
          field="Value"
          title={`${translate('grids.transactionDeatails.column-value')}`}
          width="250"
          filter="text"
          headerClassName={
            this.isColumnActive('DefaultValue', dataState) || this.checkCustomFilterActivity('DefaultValue', dataState)
              ? 'active'
              : ''
          }
          cell={(props) => (
            <CellValue
              isEditing={isEditing}
              skinsData={skinsData}
              updateGridExample={this.updateGridExample}
              terminalId={terminalId}
              {...props}
            />
          )}
        />
      </Grid>
    );
  }
}

export default withRouter(withTranslate(GridRemoteSettings));
