import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Input } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellProgress from '../monitorActivity/cellProgress';
import CellBoolean from '../account/cellBoolean';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class GridTerminalCashList extends PureComponent {
  state = {
    isLoading: false,
    data: [],
  };

  CellProgressCashlist = (props) => {
    return <CellProgress {...props} gridName="terminalCashList" />;
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    const { terminalId } = this.props;
    if (!terminalId) return;
    try {
      this.toggleLoader(true);
      const data = await api.getTerminalCashList(terminalId);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    const { translate, terminalId } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="issued-certificates--toolbar">
          <p>{translate('grids.certificates-issued.col-terminalId')}</p>
          <Input value={terminalId} disabled />
        </div>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="terminalCashList"
        permissionName="encashment"
      >
        <GridColumn field="Nominal" title="grids.terminalCashList.column-nominal" width="150" filter="numeric" />

        <GridColumn field="Quantity" title="grids.terminalCashList.column-quantity" width="240" filter="numeric" />

        <GridColumn
          field="Amount"
          title="grids.terminalCashList.column-amount"
          width="150"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CashBoxUsed"
          title="grids.terminalCashList.column-cashBoxUsed"
          width="300"
          filter="numeric"
          cell={this.CellProgressCashlist}
        />

        <GridColumn field="Currency" title="grids.terminalCashList.column-currency" width="150" />

        <GridColumn
          field="IsCash"
          title="grids.encashmentDetailsList.column-isCash"
          width="150"
          filter="boolean"
          cell={WrappedCellBoolean}
        />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalCashList);
