import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { TEMPLATES_DROPDOWN_CONFIG } from '../../../core/constants/configs.const';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

import './TemplateServicesGrid.scss';

const api = new ApiService();

class UpdateModal extends PureComponent {
  state = {
    selectTemplates: [],
  };

  updateServicesTemplate = async () => {
    const { selectedItems, closeModal, translate, onRefresh } = this.props;
    const { selectTemplates } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.setReceiptsTemplate({
        templateId: selectTemplates[0],
        serviceIds: selectedItems,
      });
      closeModal();
      this.setState({ isLoading: false });
      message.success(translate('page.serviceTemplates.updatedSuccessfully'), 2.5);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { translate, visible, closeModal, templateList } = this.props;
    const { selectTemplates, isLoading } = this.state;

    return (
      <Modal
        className="UpdateServicesTemplateDialog"
        title={translate('page.serviceTemplates.serviceTemplateChanging')}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button type="primary" key="change" disabled={!selectTemplates.length} onClick={this.updateServicesTemplate}>
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="TemplateServicesGrid-selectRow">
          <div className="TemplateServicesGrid-selectLabel">{translate('page.serviceTemplates.col-template')}</div>

          <div className="TemplateServicesGrid-select">
            <GridDropdown
              data={templateList}
              colConfig={TEMPLATES_DROPDOWN_CONFIG}
              selectItems={selectTemplates}
              onSave={(selectTemplates) => {
                this.setState({ selectTemplates });
              }}
              defaultTitle={translate('page.serviceTemplates.selectTemplate')}
              isSingle
            />
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
