import React, { PureComponent } from 'react';

import BackupButtonsGrid from './BackupButtonsGrid';
import PageTabs from '../../components/tabber/tabber';
import TemplateAssignments from './TemplateAssignments';
import NewBusinessService from '../screenControl(terminalsControl)/businessServices/newBusinessService/newBusinessService';
import EditBusinessService from '../screenControl(terminalsControl)/businessServices/editBusinessService/editBusinessService';

class BackupButtons extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-backupButtons',
        content: <BackupButtonsGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  onAddTab = ({ type, profileReserveList, dataItem }, key) => {
    const { tabs } = this.state;
    console.log('dataItem', dataItem);
    switch (type) {
      case 'setTemplate':
        tabs.push({
          title: 'grids.profilesMainProfileList.modal-assign-template-title',
          content: <TemplateAssignments profileReserveList={profileReserveList} />,
          closable: true,
          key: key,
        });
        break;
      case 'newTemplate':
        tabs.push({
          title: 'page.backupButtons.tab-new-template',
          content: <NewBusinessService item={dataItem} isBackupButtonsVariant />,
          closable: true,
          key: key,
        });
        break;
      case 'editTemplate':
        tabs.push({
          title: 'page.backupButtons.tab-edit-template',
          content: <EditBusinessService item={dataItem} isBackupButtonsVariant />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default BackupButtons;
