const externalOutgoingPayments = (translate, value) => {
  switch (value) {
    case 'externalOutgoingPayments-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'externalOutgoingPayments-createPayment':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'page.externalOutgoingPayments.createPayment',
      )}`;
    case 'externalOutgoingPayments-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
    case 'externalOutgoingPayments-copy':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.copy')}`;
    case 'externalOutgoingPayments-editPayment':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'page.suspendedFinancialTransactions.col-paymentId',
      )}`;
    default:
      return value;
  }
};

export default externalOutgoingPayments;
