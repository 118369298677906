import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, Select } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import MenuAuditService from './MenuAudit.service';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

import './MenuAuditGrid.styled.scss';
import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const api = new ApiService();
const { Option } = Select;

class MenuAuditGrid extends PureComponent {
  currentArray = findArray('menuAudit');

  state = {
    auditList: [],
    isLoading: false,
    selectedProfile: undefined,
    profileList: [],
    errorMessage: '',
    dateStart: new Date(),
    dateEnd: new Date(),
  };

  componentDidMount() {
    this.fetchMenuProfilesTreeGQL();
  }

  fetchMenuProfilesTreeGQL = async () => {
    try {
      await this.setState({ isLoading: true });
      const data = await api.getByGraphQl(
        `menuProfilesTree(order:{ profileId:ASC}){profileId, name, parentId, level, userId, userName, datePost, version}`,
      );

      await this.setState({
        profileList:
          data && data.data.menuProfilesTree && data.data.menuProfilesTree.length ? data.data.menuProfilesTree : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: MenuAuditService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  getAudit = async () => {
    const { selectedProfile, dateStart, dateEnd, errorMessage } = this.state;

    if (!selectedProfile || !!errorMessage) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const auditList = await api.getAuditMenu({
        profileId: selectedProfile,
        menuId: 0,
        dateStart: getDataByTimezoneOffset(StandardizeTimeForDate(dateStart, true)).toISOString(),
        dateEnd: getDataByTimezoneOffset(StandardizeTimeForDate(dateEnd)).toISOString(),
      });

      this.setState({ isLoading: false, auditList: auditList && auditList.length ? auditList : [] });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { profileList, dateStart, dateEnd, selectedProfile, errorMessage } = this.state;

    return (
      <Col className="MenuAuditGrid-toolbar">
        <div>
          <Select
            value={selectedProfile}
            className="MenuAuditGrid-select"
            placeholder={translate('page.terminalProfiles.dropdown-title')}
            onChange={(selectedProfile) => {
              this.setState({ selectedProfile });
            }}
            filterOption={(input, { props: { children } }) => children.toLowerCase().includes(input.toLowerCase())}
            showSearch
          >
            {profileList.map(({ profileId, name }) => (
              <Option value={profileId} key={profileId}>
                {`${profileId}-${name}`}
              </Option>
            ))}
          </Select>

          <div className="MenuAuditGrid-dateRow">
            <div>
              <div className="MenuAuditGrid-label">{translate('page.changingProfileHistory.dateFrom')}</div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="FishkaServiceHistoryGrid-datePicker"
                  onChange={this.getDateChangeCallback('dateStart')}
                  value={dateStart}
                  min={new Date(2007, 0)}
                />
              </div>
            </div>

            <div>
              <div className="MenuAuditGrid-label">{translate('page.changingProfileHistory.dateTo')}</div>

              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  className="FishkaServiceHistoryGrid-datePicker"
                  onChange={this.getDateChangeCallback('dateEnd')}
                  value={dateEnd}
                  min={new Date(2007, 0)}
                />
              </div>
            </div>
          </div>

          {errorMessage && <div className="MenuAuditGrid-error">{errorMessage}</div>}
        </div>

        <Button type="primary" disabled={!selectedProfile || !!errorMessage} onClick={this.getAudit}>
          {translate('core.buttonTitles.search')}
        </Button>
      </Col>
    );
  };

  render() {
    const { auditList, isLoading } = this.state;
    const { translate } = this.props;

    if (!checkElement('menuAudit-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={auditList}
          onRefresh={this.getAudit}
          isLoading={isLoading}
          name="MenuAuditGrid"
          toolbar={this.buildToolbar()}
        >
          <GridColumn field="ProfileName" title={translate('page.profilesMenu.profileName')} width="240px" />
          <GridColumn field="MenuId" title={translate('page.menuAudit.col-menuNumber')} width="120px" />
          <GridColumn field="MenuName" title={translate('page.menuAudit.col-menu')} width="240px" />
          <GridColumn field="MenuType" title={translate('page.menuAudit.col-menuType')} width="120px" />
          <GridColumn field="PathName" title={translate('page.menuAudit.col-menuRoute')} width="360px" />
          <GridColumn field="Message" title={translate('page.menuAudit.col-log')} width="420px" />
          <GridColumn
            field="DatePost"
            title={translate('page.menuAudit.col-changeDate')}
            width="200px"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn field="UserName" title={translate('page.menuAudit.col-user')} width="200px" />
        </Grid>

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(MenuAuditGrid);
