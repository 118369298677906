export const AVAILABLE_PERIOD_LIST = [
  {
    ...(new Date().getDate() === 1
      ? {}
      : { name: 'grids.terminalOperabilityChoose.current-month', value: 'currentMonth' }),
  },
  {
    name: 'grids.terminalOperabilityChoose.previous-month',
    value: 'previousMonth',
  },
  {
    name: 'grids.terminalOperabilityChoose.before-previous-month',
    value: 'beforePreviousMonth',
  },
];

export const MONTH_DIFFERENCE_BY_ID_MAP = new Map([
  ['currentMonth', 0],
  ['previousMonth', 1],
  ['beforePreviousMonth', 2],
]);

export const MONTH_TRANSLATIONS_MAP = new Map([
  [0, 'core.month.january'],
  [1, 'core.month.february'],
  [2, 'core.month.march'],
  [3, 'core.month.april'],
  [4, 'core.month.may'],
  [5, 'core.month.june'],
  [6, 'core.month.july'],
  [7, 'core.month.august'],
  [8, 'core.month.september'],
  [9, 'core.month.october'],
  [10, 'core.month.november'],
  [11, 'core.month.december'],
]);

export const REPORT_TYPES = [
  {
    name: 'grids.terminalOperabilityChoose.operability',
    value: 'efficiency',
  },
  {
    name: 'grids.terminalOperabilityChoose.technicalOperability',
    value: 'technicalEfficiency',
  },
  {
    name: 'grids.terminalOperabilityChoose.monitoringOperability',
    value: 'monitoring',
  },
];
