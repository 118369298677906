import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { ApiService, StorageService, tabService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import ModalChooseSettings from '../modalChooseSetting';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import GridDropdownWithDeletedOwners from '../../../../components/GridDropdown/GridDropdownWithDeletedOwners';

const api = new ApiService();

class GridSettingsAndEvents extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlSettings');
    this.state = {
      isLoading: false,
      data: [],
      visible: false,
      selectedItems: [],
      showDeleted: false,
      selectedOwners: [],
      allOwners: [],
    };
  }

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentWillUnmount() {
    StorageService.removeItem('checkSelected-screenControlSettings-grid');
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  initial = async () => {
    await this.fetchOwners();
    await this.makeQuery();
  };

  componentDidMount() {
    this.fetchOwners();
  }

  makeQuery = async () => {
    const { showDeleted, selectedOwners } = this.state;

    if (selectedOwners.length < 1) return;

    try {
      this.toggleLoader();
      const model = {
        OwnerId: selectedOwners,
        ShowDeleted: showDeleted,
      };

      const data = await api.terminalListManage(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  onOwners = (selectedOwners) => {
    this.setState({ selectedOwners }, () => {
      this.makeQuery();
    });
  };

  openBanknotesGridTab = () => {
    const { selectedOwners, selectedItems } = this.state;
    tabService.addTab({
      type: 'banknotesGrid',
      dataItem: { owners: selectedOwners, terminals: selectedItems },
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, allOwners, selectedOwners } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={selectedOwners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('screenControlSettings-btn-addRemoteSetting', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px', marginLeft: 0 }}
            onClick={this.toggleModalChoose}
            disabled={!selectedItems || selectedItems.length === 0}
          >
            {translate('grids.settingsAndEvents.btn-add')}
          </Button>
        )}
        {checkElement('screenControlSettings-canConfigBanknotesNominal', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px', marginLeft: 10 }}
            onClick={this.openBanknotesGridTab}
            disabled={!selectedItems || selectedItems.length === 0}
          >
            {translate('page.banknotesRestrictions.tab-banknotesGrid')}
          </Button>
        )}
      </Col>
    );
  };

  getShowProperty = (key) => {
    return checkElement(key, this.currentArray);
  };

  getHandlers = () => {
    return [
      {
        title: 'page.newTerminals.tab-details',
        action: (props) => tabService.addTab({ type: 'propDetails', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-terminalProperties'),
      },
      {
        title: 'page.terminalSettings.addtionalSettings-title',
        action: (props) => tabService.addTab({ type: 'addtionalSettings', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-additionalSettings'),
      },
      {
        title: 'page.terminalSettings.remoteSettings-title',
        action: (props) => tabService.addTab({ type: 'remoteSettings', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-remoteSettings'),
      },
      {
        title: 'page.dashboard.tab-type-events',
        action: (props) => tabService.addTab({ type: 'events', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-terminalsEvents'),
      },
    ];
  };

  closeModalChoose = async () => {
    StorageService.removeItem('checkSelected-screenControlSettings-grid');
    await this.makeQuery();
    this.setState({ visible: false });
  };

  toggleModalChoose = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  showRemoteCallback = () => {
    this.setState(
      ({ showDeleted }) => ({ showDeleted: !showDeleted }),
      () => {
        this.makeQuery();
      },
    );
  };

  render = () => {
    const { isLoading, data, visible, selectedItems, showDeleted } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlSettings-grid"
          permissionName="screenControlSettings"
          toggleLoader={this.toggleLoader}
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectionChange}
          multiSelected
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={showDeleted}
          isShowSpecificFilterByFieldName="TerminalId"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="TerminalId"
            title="grids.terminalsMainProfileList.column-terminalId"
            width="200"
            filter="numeric"
            handlers={this.getHandlers()}
            dropdown={checkElement('screenControlSettings-isShowDropdownTerminalId', this.currentArray)}
            remoteCommands={true}
            checked={false}
          />

          <GridColumn field="AppVersion" title="grids.settingsAndEvents.column-versionPO" width="150" />

          <GridColumn field="OsVersion" title="grids.settingsAndEvents.column-versionOS" width="150" />

          <GridColumn field="TypeName" title="owner.column-type" width="100" />

          <GridColumn field="DesignTypeName" title="grids.createTerminal.column-designType" width="150" />

          <GridColumn field="TerminalName" title="grids.terminals.column-name" width="200" />

          <GridColumn field="FiscalNumber" title="grids.createTerminal.column-fiscalNumber" width="150" />

          <GridColumn field="PlacingName" title="page.terminalSettings.field-placing" width="150" />

          <GridColumn field="TerminalDescription" title="grids.adminPanel.column-description" width="150" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="150" />

          <GridColumn
            field="MonitorCount"
            title="page.terminalSettings.field-monitorCount"
            width="100"
            filter="numeric"
          />

          <GridColumn field="TechnicianName" title="grids.settingsAndEvents.column-technician" width="150" />

          <GridColumn field="Geopoint" title="grids.settingsAndEvents.column-geopoint" width="150" />

          <GridColumn field="Branch" title="grids.terminals.column-branch" width="150" />

          <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />

          <GridColumn field="CityName" title="grids.terminals.column-city" width="150" />

          <GridColumn field="TerminalAddress" title="grids.terminals.column-street" width="150" />

          <GridColumn field="Regime" title="grids.settingsAndEvents.column-workingHours" width="160" />

          <GridColumn
            field="DateLastConnect"
            title="grids.certificates.certificate-date-last-connect"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="DateLastTransaction"
            title="grids.terminals.column-lastConnect"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="LimitTransactions"
            title="grids.settingsAndEvents.column-transactionDayLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="LimitTransactionsNight"
            title="grids.settingsAndEvents.column-transactionNightLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="LimitTurns"
            title="grids.settingsAndEvents.column-turnoverDayLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn field="LimitTurnsNight" title="grids.settingsAndEvents.column-turnoverNightLimit" width="150" />

          <GridColumn field="СommissionProfileName" title="grids.createTerminal.column-profileComissions" width="250" />

          <GridColumn field="Menu2ProfileName" title="grids.settingsAndEvents.column-menuProfile" width="250" />

          <GridColumn field="MainProfileId" title="grids.settingsAndEvents.column-idScreenProfile" width="250" />

          <GridColumn field="MainProfileName" title="grids.terminalsMainProfileList.column-mainProfileId" width="250" />

          <GridColumn field="ProjectName" title="grids.createTerminal.column-project" width="150" />

          <GridColumn field="NetworkContractName" title="grids.createTerminal.column-networkContract" width="150" />

          <GridColumn field="StatusName" title="owner.column-status" width="150" />

          <GridColumn field="StatusDetails" title="grids.settingsAndEvents.column-statusDetails" width="150" />
        </Grid>
        <ModalChooseSettings
          visible={visible}
          closeModal={this.closeModalChoose}
          terminals={selectedItems.map(({ TerminalId }) => TerminalId)}
        />
      </>
    );
  };
}

export default withRouter(withTranslate(GridSettingsAndEvents));
