import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Select, Row, Input, InputNumber, Checkbox } from 'antd';
import { withTranslate } from '../../../../../contexts/localContext';
import LoadingPanel from '../../../../../components/loader';
import '../../../../../components/TerminalsCommissionsGrid/ModalChangeProfile/modalChangeProfile.scss';
import './modalCellValue.scss';

const { Option } = Select;

const { TextArea } = Input;

const stringToBoolean = (string) => {
  if (typeof string === 'boolean') return string;
  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

class ModalCellValue extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    item: PropTypes.objectOf(PropTypes.any),
    toggleModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      item: { Value: value, Key },
      skinsData: selectData,
    } = props;
    this.state = {
      loading: false,
      isLoading: false,
      isDisabled: false,
      value,
      isSkinSelect: Key && Key === 'skin',
      selectData,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  onChangeCheckBox = (e) => {
    this.setState({ value: e.target.checked === true ? 'True' : 'False' });
  };

  onChangeInput = (e) => {
    this.setState({ value: e.target.value });
  };

  onChangeInputNumberSelect = (value) => {
    this.setState({ value });
  };

  renderSelect = () => {
    const { value, selectData } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={value}
        onChange={this.onChangeInputNumberSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {selectData.map(({ Key, Value }) => (
          <Option value={Key} key={`cellValue-number-${Key}-${Value}`}>
            {Value}
          </Option>
        ))}
      </Select>
    );
  };

  renderBlock = () => {
    const {
      item: { Type: type = '' },
    } = this.props;
    const { value, isSkinSelect } = this.state;
    if (isSkinSelect) {
      return this.renderSelect();
    }
    switch (type) {
      case 'bool':
        return <Checkbox checked={stringToBoolean(value)} onChange={this.onChangeCheckBox} />;
      case 'string':
        return <Input value={value} onChange={this.onChangeInput} />;
      case 'memo':
        return <TextArea rows={4} value={value} onChange={this.onChangeInput} />;
      case 'int':
        return <InputNumber min={0} value={value} onChange={this.onChangeInputNumberSelect} />;
      default:
        return <p />;
    }
  };

  onSaveProp = async (propName, value) => {
    const { updateGridExample } = this.props;

    updateGridExample(propName, value);
  };

  render() {
    const { translate, visible, toggleModal, item } = this.props;
    const { loading, isLoading, isDisabled, value } = this.state;
    const propName = item.Key && item.Key;
    return (
      <>
        <Modal
          title={translate('grids.transactionDeatails.column-name')}
          visible={visible}
          centered
          onCancel={toggleModal}
          className="modal-system-encashment addSets-modal-cellVal"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={toggleModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => this.onSaveProp(propName, value)}
              disabled={isDisabled}
            >
              {translate('page.screenControlBusinessServices.button-save')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-name')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <Input disabled value={propName} />
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-value')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderBlock()}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCellValue);
