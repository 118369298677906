export const ADD_SERVICE_TYPES_INITIAL_VALUES = {
  serviceTypeName: '',
  serviceGroup: undefined,
  provider: [],
  serviceTypeDescription: '',
};

export const PROVIDER_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.addServiceByBigTable.provider',
    width: '300',
    isShowOnMobile: true,
  },
];
