class SubBrandServices {
  normalizeBrands(brands) {
    if (!brands || !brands.length) {
      return [];
    }

    return brands.map((brand) => ({
      ...brand,
      Id: brand.BrandId,
    }));
  }

  normalizeMenuProfiles(menuProfiles, selectBrandsMenuProfiles, isMainBrand) {
    if (!menuProfiles || !menuProfiles.length || !selectBrandsMenuProfiles || !selectBrandsMenuProfiles.length) {
      return [];
    }

    const normalizersMenuProfiles = menuProfiles.map((menuProfile) => ({
      ...menuProfile,
      Id: menuProfile.ProfileId,
    }));

    if (isMainBrand) {
      return normalizersMenuProfiles;
    }

    return normalizersMenuProfiles.filter(({ Id }) => selectBrandsMenuProfiles.includes(Id));
  }

  normalizeCommissionProfiles(commissionProfiles) {
    if (!commissionProfiles || !commissionProfiles.length) {
      return [];
    }

    return commissionProfiles.map((commissionProfile) => ({
      ...commissionProfile,
      Id: commissionProfile.ProfileId,
      Name: commissionProfile.ProfileName,
    }));
  }
}

export default new SubBrandServices();
