import { uppercaseFirstLetter } from '../../../utils/uppercaseFirstLetter';

class ServicesBigTableService {
  normalizeDataItems = (data) => {
    return data.length
      ? data.map((item) =>
          Object.entries(item).reduce((acc, [key, value]) => ({ ...acc, [uppercaseFirstLetter(key)]: value }), {}),
        )
      : [];
  };
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.services) {
      return { servicesList: [], hasNextPage: false, hasPreviousPage: false };
    }
    const servicesList = data.data.services;

    const resultServicesList =
      servicesList.items && servicesList.items.length ? this.normalizeDataItems(servicesList.items) : [];

    const pageInfo = servicesList.pageInfo;

    return {
      servicesList: resultServicesList,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };

  getAllItemsGQLResponse = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return this.normalizeDataItems(data);
  };
}
export default new ServicesBigTableService();
