import React from 'react';
import './modeSwitcher.scss';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

// компонент для переключения режимов отображения гридов в мобильной версии
const ModeSwitcher = ({ gridmode, switchMode }) => (
  <Switch
    onClick={switchMode}
    checkedChildren="mobile"
    unCheckedChildren="desktop"
    defaultChecked={gridmode}
    className="grid-mode-toggler"
  />
);

ModeSwitcher.propTypes = {
  gridmode: PropTypes.bool.isRequired,
  switchMode: PropTypes.func.isRequired,
};

export default ModeSwitcher;
