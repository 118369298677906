import React, { PureComponent } from 'react';

import CopyCommission from './CopyCommission';
import CopyCommissionService from './copyCommission.service';
import { INITIAL_VALUES, DISABLE_AVAILABILITY } from './copyCommission.const';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class CopyCommissionContainer extends PureComponent {
  state = {
    isLoading: false,
    isDialogVisible: false,
  };

  toggleVisibleDialog = (value) => {
    this.setState({ isDialogVisible: value });
  };

  onRadioChange = (e) => {
    const { setFormState, setErrorState } = this.props;

    setFormState({ ...INITIAL_VALUES, copyCommissionType: e.target.value });
    setErrorState({});
  };

  checkErrorByName = (value, isFieldActive) => {
    const { translate } = this.props;

    if (isFieldActive) {
      return value.length ? null : translate('page.commissionsServices.dropdownRequiredError');
    }

    return null;
  };

  checkErrors = () => {
    const {
      formState: {
        sourceProfileDropdown,
        sourceServiceDropdown,
        sourcePaymentTypeDropdown,
        targetProfileDropdown,
        targetServiceDropdown,
        targetPaymentTypeDropdown,
        copyCommissionType,
      },
      setErrorState,
      getIsErrorsExist,
    } = this.props;

    const currentErrorsState = {
      sourceProfileDropdown: this.checkErrorByName(sourceProfileDropdown, true),
      sourceServiceDropdown: this.checkErrorByName(sourceServiceDropdown, true),
      sourcePaymentTypeDropdown: this.checkErrorByName(sourcePaymentTypeDropdown, true),
      targetProfileDropdown: this.checkErrorByName(
        targetProfileDropdown,
        !DISABLE_AVAILABILITY[copyCommissionType].profileDropdown,
      ),
      targetServiceDropdown: this.checkErrorByName(
        targetServiceDropdown,
        !DISABLE_AVAILABILITY[copyCommissionType].serviceDropdown,
      ),
      targetPaymentTypeDropdown: this.checkErrorByName(
        targetPaymentTypeDropdown,
        !DISABLE_AVAILABILITY[copyCommissionType].paymentTypeDropdown,
      ),
    };

    setErrorState(currentErrorsState);

    return getIsErrorsExist(currentErrorsState);
  };

  onCopy = async () => {
    const {
      formState: {
        sourceProfileDropdown,
        sourceServiceDropdown,
        sourcePaymentTypeDropdown,
        targetProfileDropdown,
        targetServiceDropdown,
        targetPaymentTypeDropdown,
        isOldCommissionDelete,
      },
      showError,
    } = this.props;

    const isButtonDisabledByErrors = this.checkErrors();

    if (isButtonDisabledByErrors) {
      return;
    }

    try {
      this.setState({ isLoading: true });

      api.postCommissionsClientReferenceCopy({
        sourse: {
          servicesList: sourceServiceDropdown,
          profilesList: sourceProfileDropdown,
          paymentType: sourcePaymentTypeDropdown,
        },
        destination: {
          servicesList: targetServiceDropdown,
          profilesList: targetProfileDropdown,
          paymentType: targetPaymentTypeDropdown,
        },
        withDelete: isOldCommissionDelete,
      });

      setTimeout(() => {
        this.toggleVisibleDialog(true);
        this.setState({ isLoading: false });
      }, 5000);
    } catch (error) {
      showError(error);
      this.setState({ isLoading: false });
    }
  };

  onDropdownChange = (name, value) => {
    const { onChangeFormState, onError } = this.props;
    onChangeFormState(name, value);
    onError(name, null);
  };

  render() {
    const { formState } = this.props;
    const { isLoading, isDialogVisible } = this.state;
    const dropdownSaveCallbacks = CopyCommissionService.getDropdownSaveCallbacks(this.onDropdownChange);

    return (
      <CopyCommission
        formState={formState}
        onRadioChange={this.onRadioChange}
        dropdownSaveCallbacks={dropdownSaveCallbacks}
        isLoading={isLoading}
        onCopy={this.onCopy}
        isDialogVisible={isDialogVisible}
        toggleVisibleDialog={this.toggleVisibleDialog}
        {...this.props}
      />
    );
  }
}

export default withTranslate(CopyCommissionContainer);
