import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import { tabService } from '../../services';
import findArray from '../../utils/findArrForCurrentPage';
import AdditionalTransactionsPropsService from '../../components/AdditionalTransactions/AdditionalTransactionsProps.service';
import { withTranslate } from '../../contexts/localContext';

class gridTerminalTransactionWithAdditionalProps extends PureComponent {
  currentArray = findArray('activitymonitor');
  state = {
    transactions: [],
    isLoading: false,
  };

  componentDidMount() {
    this.initial();
  }

  renderOnclick = (props) => {
    if (checkElement('monitorTerminalTransactions-transactionIdOnclick', this.currentArray))
      return tabService.addTab({ type: 'details', dataItem: props.dataItem });
    else return null;
  };

  initial = async () => {
    const {
      dataItem: { selectedItems, selectedAdditionalProps },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const allProps = await AdditionalTransactionsPropsService.fetchTransactionsProps(
        0,
        [],
        'transactionId, itemName, itemValue',
        `{and:[{transactionId:{in:[${selectedItems
          .map(({ TransactionId }) => TransactionId)
          .join(',')}]}}, {itemName:{in:[${selectedAdditionalProps
          .map((fieldName) => `"${fieldName}"`)
          .join(',')}]}}]}`,
      );

      await this.setState({
        isLoading: false,
        transactions:
          allProps && allProps.length
            ? AdditionalTransactionsPropsService.getTransactionListWithAdditionalProps(allProps, selectedItems)
            : selectedItems,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { transactions, isLoading } = this.state;
    const {
      dataItem: { selectedAdditionalProps },
    } = this.props;

    return (
      <Grid
        data={transactions}
        onRefresh={() => {}}
        toolbar={<></>}
        isLoading={isLoading}
        name="monitorActivityGridTransactionWithAdditionalPropsGrid"
        withoutManageColumnAction
      >
        {[
          <GridColumn field="OwnerName" width="145" title="grids.transactions.column-ownerName" />,
          <GridColumn
            field="TransactionId"
            title="grids.transactions.column-transactionId"
            width="130"
            onClick={(props) => this.renderOnclick(props)}
            filter="numeric"
          />,
          <GridColumn field="RequestId" title="grids.transactions.column-requestId" width="112" filter="numeric" />,
          <GridColumn field="TerminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />,
          <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />,
          <GridColumn field="ServiceName" title="grids.transactions.column-serviceName" width="93" />,
          <GridColumn
            field="AmountTotal"
            title="grids.transactions.column-amountTotal"
            width="134"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="AmountOriginal"
            title="grids.transactions.column-amountOriginal"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="CommissionProvider"
            title="grids.transactions.column-comissionProvider"
            width="154"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="CommissionClient"
            title="grids.transactions.column-comissionClient"
            width="171"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn field="Currency" title="grids.transactions.column-currency" width="90" />,
          <GridColumn
            field="DateInput"
            title="grids.transactions.column-dateInput"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="DatePost"
            title="grids.transactions.column-datePost"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="DateDeclined"
            title="grids.transactions.column-dateDeclined"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="DateAccepted"
            title="grids.transactions.column-dateAccepted"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn field="Account" title="grids.transactions.column-account" width="112" />,
          <GridColumn field="Comment" title="grids.transactions.column-comment" width="125" />,
          <GridColumn field="StatusName" title="grids.transactions.column-status" width="91" />,
          <GridColumn field="PaymentType" title="grids.transactions.column-typePayment" width="120" />,
          ...selectedAdditionalProps.map((filedName) => (
            <GridColumn
              field={filedName}
              title={filedName}
              width="180"
              //кастомна функція для cell яка по суті повторує нативну була додана через те, шо нативна функція не сприймає field з точкою,
              // наприклад 'balance.after', осклільки в ній(в нативній) ще закладена логіка для підколонок, які як раз і використовують "." під час парсингу
              cell={({ field, dataItem }) => <td>{dataItem[field]}</td>}
            />
          )),
        ]}
      </Grid>
    );
  }
}

export default withTranslate(gridTerminalTransactionWithAdditionalProps);
