const menegmentCommissionTerminals = (translate, value) => {
  switch (value) {
    case 'managementCommissionTerminals-grid-OwnerId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-numberDealer')}`;
    case 'managementCommissionTerminals-grid-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dealer')}`;
    case 'managementCommissionTerminals-grid-Address':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-address')}`;
    case 'managementCommissionTerminals-changeProfile':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.commission.btn-toggle')}`;
    case 'managementCommissionTerminals-grid-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-city')}`;
    case 'managementCommissionTerminals-grid-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dateLastConnect')}`;
    case 'managementCommissionTerminals-grid-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-dateLastTransaction')}`;
    case 'managementCommissionTerminals-grid-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-date')}`;
    case 'managementCommissionTerminals-grid-ProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-profile')}`;
    case 'managementCommissionTerminals-profileNameOnclick':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.comissions.services')}`;
    case 'managementCommissionTerminals-grid-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-region')}`;
    case 'managementCommissionTerminals-grid-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'managementCommissionTerminals-grid-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-status')}`;
    case 'managementCommissionTerminals-grid-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-terminal')}`;
    case 'managementCommissionTerminals-grid-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-name')}`;
    case 'managementCommissionTerminals-grid-TypeName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-type')}`;
    case 'managementCommissionTerminals-grid-UserNamePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-terminals.column-executor')}`;
    case 'managementCommissionTerminals-grid-BrandName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.subBrandsTerminals.col-brand')}`;
    case 'managementCommissionTerminals-grid-IsChangeCommissionEnabled':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.subBrandsTerminals.col-changeProfile')}`;
    default:
      return value;
  }
};

export default menegmentCommissionTerminals;
