import React, { PureComponent } from 'react';
import { Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CheckTemplateForm from '../CheckTemplateForm/CheckTemplateFormContainer';
import { INITIAL_VALUES } from '../CheckTemplateForm/constants';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

import '../CheckTemplate.scss';

const api = new ApiService();

class EditingCheckTemplate extends PureComponent {
  state = {
    isLoading: false,
    valuesState: INITIAL_VALUES,
  };

  onValuesStateChange = (valuesState) => {
    this.setState({ valuesState });
  };

  checkUniqueTemplateName = () => {
    const {
      tabData: { templateList },
    } = this.props;
    const {
      valuesState: { templateName, templateNumber },
    } = this.state;

    return templateList.find(
      ({ TemplateName, TemplateId }) => TemplateName === templateName && `${templateNumber}` !== `${TemplateId}`,
    );
  };

  editTemplate = async () => {
    const { translate } = this.props;
    const {
      valuesState: { templateName, description, receipt, templateNumber },
    } = this.state;

    if (this.checkUniqueTemplateName()) {
      message.error(translate('page.templates.mustBeUnique'), 2.5);

      return;
    }

    try {
      await this.setState({ isLoading: true });

      await api.updateReceiptsTemplate({
        templateId: templateNumber,
        templateName,
        description,
        receipt: receipt.replace(/\n/g, '\r\n'),
      });

      await message.success(translate('page.templates.editSuccessfully'), 2.5);
      await this.setState({ isLoading: false });
      await window.location.reload();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  calcDisabled = () => Object.values(this.state.valuesState).some((value) => !value);

  render() {
    const {
      translate,
      tabData: { selectedTemplate },
    } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        <div className="CheckTemplate">
          <Button type="primary" disabled={this.calcDisabled()} onClick={this.editTemplate}>
            {translate('core.buttonTitles.save')}
          </Button>

          <div className="CheckTemplate-content">
            <CheckTemplateForm onValuesStateChange={this.onValuesStateChange} selectedTemplate={selectedTemplate} />
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(EditingCheckTemplate);
