const SuppliersBalance = (translate, value) => {
  switch (value) {
    case 'suppliersBalance-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'suppliersBalance-add':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;
    case 'suppliersBalance-edit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.edit`)}`;
    default:
      return value;
  }
};

export default SuppliersBalance;
