import React, { PureComponent } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import './GridRoles.scss';
import { withTranslate } from '../../../contexts/localContext';
import { getTransformDateString } from '../../../utils/getTransformDateString';

class GridRoles extends PureComponent {
  constructor(props) {
    super(props);
    const { roleList = [] } = props;
    this.state = {
      data: roleList,
    };
  }

  componentDidMount() {
    const { setDefaultItems } = this.props;
    setDefaultItems();
  }

  render = () => {
    const { currentSelectItems = [], onRowClick, translate, headerSelected, handleHeaderSelectionChange } = this.props;
    const { data } = this.state;

    return (
      <Grid
        data={data.map((item) => ({ ...item, selected: currentSelectItems.includes(item.RoleId) }))}
        selectedField="selected"
        total={data.length}
        rowHeight={40}
        onRowClick={onRowClick}
        onSelectionChange={onRowClick}
        fieldForSelection="RoleName"
        onSelect={onRowClick}
        onHeaderSelectionChange={() => {
          handleHeaderSelectionChange(data);
        }}
        multiSelected
        resizable
      >
        <GridColumn field="selected" width="50px" headerSelectionValue={headerSelected} />
        <GridColumn field="RoleName" title={translate('page.groupMembership.col-roleName')} width="500px" />
        <GridColumn field="RoleId" title={translate('page.groupMembership.col-roleId')} width="150px" />
        <GridColumn field="Grantor" title={translate('page.groupMembership.col-roleGrantor')} width="250px" />
        <GridColumn
          field="DatePost"
          title={translate('page.groupMembership.col-datePost')}
          width="250px"
          cell={({ dataItem: { DatePost } }) => <td>{getTransformDateString(DatePost)}</td>}
        />
        <GridColumn field="RoleDescription" title={translate('page.groupMembership.col-description')} width="1800px" />
      </Grid>
    );
  };
}
export default withTranslate(GridRoles);
