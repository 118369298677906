/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import './sortableItemUi.scss';
import ModalChangeImage from './modalChangeImage';

class SortableItemUI extends Component {
  state = {
    visible: false,
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  getBaseItemStyle = (isActive) => ({
    height: 110,
    lineHeight: '108px',
    fontSize: '16px',
    textAlign: 'center',
    outline: 'none',
    border: '1px solid',
    cursor: 'move',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: isActive ? '#27aceb' : '#bfe7f9',
    color: isActive ? '#fff' : '#1494d0',
    borderColor: isActive ? '#27aceb' : '#fff',
    width: '20%',
    position: 'relative',
  });

  toggleModal = () => {
    this.setState((state) => {
      return {
        visible: !state.visible,
      };
    });
  };

  sortItemOverlay = () => {
    const { dropImage, dataItem, translate, makeUnnecessary, isBackupButtonsVariant } = this.props;
    return (
      <Menu>
        <Menu.Item key="0">
          <Button type="default" onClick={this.toggleModal}>
            {translate('page.screenControlBusinessServices.sortable-item-btn-set')}
          </Button>
        </Menu.Item>
        {dataItem.MenuId && (
          <Menu.Item key="1">
            <Button type="default" onClick={() => dropImage(dataItem.VisibleIndex)}>
              {translate('page.screenControlBusinessServices.sortable-item-btn-clear')}
            </Button>
          </Menu.Item>
        )}
        {dataItem.MenuId && !isBackupButtonsVariant && (
          <Menu.Item key="2">
            <Button type="default" onClick={() => makeUnnecessary(dataItem.VisibleIndex)}>
              {translate('page.screenControlBusinessServices.sortable-item-btn-unimportant')}
            </Button>
          </Menu.Item>
        )}
        {/* {isSuperUser && dataItem.MenuId && (
          <Menu.Item key="2">
            <Button type="default" onClick={() => toggleElementLock(dataItem.MenuId)}>
              {dataItem.isHold ?
              translate('page.screenControlBusinessServices.sortable-item-btn-unblock') :
              translate('page.screenControlBusinessServices.sortable-item-btn-block')}
            </Button>
          </Menu.Item>
        )} */}
      </Menu>
    );
  };

  render() {
    const {
      isDisabled,
      isActive,
      style,
      attributes,
      dataItem,
      forwardRef,
      changeImage,
      isSuperUser,
      translate,
      Id,
      isBackupButtonsVariant,
    } = this.props;
    const { visible } = this.state;
    const classNames = ['col-xs-6 col-sm-3'];
    if (isDisabled) {
      classNames.push('k-state-disabled');
    }

    return (
      <>
        <Dropdown overlay={this.sortItemOverlay()} trigger={['click']}>
          <div
            ref={forwardRef}
            {...attributes}
            style={{
              ...this.getBaseItemStyle(isActive),
              ...style,
            }}
            className={classNames.join(' ')}
            // onClick={this.toggleModal}
          >
            {isSuperUser && dataItem.IsHold && (
              <div className="super-user-abs-disabled">
                {translate('page.screenControlBusinessServices.sortable-item-text-disabled')}
              </div>
            )}
            {dataItem.MenuId && (
              <div className="super-user-abs-disabled">
                <span className="super-user-abs-disabled--span">{dataItem.MenuId}</span>
              </div>
            )}
            {dataItem.IconUrl ? (
              <div className="sortable-item-sub-wrap">
                <img className="sortable-item-img" src={dataItem.IconUrl} />
                <p>{dataItem.Caption} </p>
              </div>
            ) : (
              dataItem.Caption
            )}
          </div>
        </Dropdown>
        {visible && (
          <ModalChangeImage
            visible={visible}
            closeModal={this.closeModal}
            dataItem={dataItem}
            isSuperUser={isSuperUser}
            changeImage={changeImage}
            Id={Id}
            isBackupButtonsVariant={isBackupButtonsVariant}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SortableItemUI);
