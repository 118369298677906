import React, { Component } from 'react';
import { Input, Row, Button, Card, Select, Col, InputNumber, Popconfirm, message } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { withTranslate } from '../../contexts/localContext';
import ModalGoogleMap from './modalGoogleMap';
import DropDownCities from '../../pages/screenControl(terminalsControl)/settingsAndEvents/dropdown';
import checkElement from '../../utils/checkElement';
import LoadingPanel from '../loader';
import TerminalDetailsField from './TerminalDetailsField';
import MigrateInventoryNumberModal from './MigrateInventoryNumberModal';
import InfoModal from '../InfoModal';

import './TerminalDetails.scss';
import ModalRegistration from './ModalRegistration/ModalRegistration';
import { ApiService } from '../../services';
import { formatDate } from '@telerik/kendo-intl';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import { ACCESS_TERMINAL_STATUS } from './constants';

const { Option } = Select;
const { TextArea } = Input;

const api = new ApiService();

const convertToISO = (date) => formatDate(getDataByTimezoneOffset(date), 'yyyy.MM.dd HH:mm:ss');

class TerminalDetails extends Component {
  state = {
    showModal: '',
    statusApplication: {},
    isShowStatusApplication: false,
    isLoading: false,
    err: '',
  };

  toggleModal = (name) => {
    this.setState({ showModal: name });
  };
  closeModal = () => {
    this.setState({ showModal: '' });
  };
  choseModal = (name) => {
    if (name === 'registration') {
      return {
        modalTitle: 'page.terminalSettings.modal-registration',
        btnTitle: 'core.buttonTitles.request-registration',
        request: 'terminalRegistrationApplication',
      };
    }
    if (name === 'deregistration') {
      return {
        modalTitle: 'page.terminalSettings.modal-deregistration',
        btnTitle: 'core.buttonTitles.request-registration',
        request: 'terminalUnregistrationApplication',
      };
    }
  };

  validationFieldsIsRequired = (type) => {
    const {
      statusName,
      formState: { businessUnit, addressValue, fiscalNumber },
      translate,
    } = this.props;

    if (type === 'registration') {
      if (statusName !== 'отключен') {
        return message.error(`${translate('page.terminalSettings.terminal-status-turn-on')}`, 2.5);
      }
    } else if (type === 'deregistration') {
      if (statusName !== 'удален') {
        return message.error(`${translate('page.terminalSettings.terminal-status-deleted')}`, 2.5);
      }

      if (!Number.isInteger(fiscalNumber)) {
        return message.error(`${translate('page.terminalSettings.required-field')}
        ${translate('page.terminalSettings.field-fiscalNumber')}!`);
      }
    }

    if (!addressValue.length) {
      return message.error(`${translate('page.terminalSettings.required-field')}
          ${translate('page.terminalSettings.field-address')}!`);
    }
    if (!Number.isInteger(businessUnit)) {
      return message.error(`${translate('page.terminalSettings.required-field')}
       ${translate('page.terminalSettings.field-GOIdentifier')}!`);
    }

    return null;
  };
  onHandleSubmit = (type) => {
    const isValid = this.validationFieldsIsRequired(type);
    if (isValid === null) {
      this.toggleModal(type);
    }
  };

  getStatusApplication = async () => {
    const { terminalId, translate } = this.props;
    try {
      this.setState({ isLoading: true });
      const application = await api.getTerminalRegistrationApplication(terminalId);
      if (application.Uuid && application.Uuid.length) {
        const statusApplication = await api.terminalRegistrationApplicationStatus(terminalId);
        this.setState({
          statusApplication,
          isShowStatusApplication: true,
        });
      } else {
        this.setState({ err: `${translate('page.terminalSettings.err-msg')}` });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  render() {
    const { showModal, statusApplication, isShowStatusApplication, err, isLoading: isOfflineLoading } = this.state;
    const {
      translate,
      callBackForOnConfirmApproveAddress,
      onAddressChange,
      onChangeCityIdCallback,
      onSubmitHandler,
      toggleAdditionalCardVisibility,
      getChangeCallbackByField,
      getChangeCallbackByEventByField,
      onChangeDateCreation,
      getMaxDate,
      onToggleShowApproveAddress,
      permissionsByName,
      generateGOID,
      generateInventoryNumber,
      onAddressInputFocus,
      closeModal,
      migrateInventoryNumber,
      fiscalNumber: FiscalNumber,
      permissionSlugs: {
        showAdditionalSettingsButton,
        updateSettingsButton,
        fiscalizationInventory,
        fiscalNumberInput,
        businessUnitInput,
        inventoryNumberInput,
        registrationButton,
        unregistrationButton,
        getStatusButton,
      },
      formState,
      terminalId,
    } = this.props;
    const {
      isEditing,
      isLoading,
      additionalCardVisibility,
      cityId,
      comissionProfiles,
      mainProfilesList,
      commisionProfileValue,
      mainProfilesListValue,
      statuses,
      statusesValue,
      terminalName,
      addressValue,
      cities,
      geopointValue,
      capacityPrinterPaper,
      capacityCashBox,
      monitorCountValue,
      placingInfo,
      placingInfoValue,
      terminalDescription,
      dateCreation,
      noteValue,
      terminalBranch,
      projectValue,
      terminalProjects,
      networkContractsValue,
      networkContracts,
      isAddressIncorrect,
      showApproveAddress,
      designTypeId,
      designTypes,
      selectMonitorCount,
      fiscalNumber,
      businessUnit,
      successfullyGeneratedIdModalSlug,
      isFiscalNumberAlreadyExist,
      inventoryNumber,
    } = formState;

    const generateModalTitle = `${
      successfullyGeneratedIdModalSlug === 'GO_ID'
        ? translate('page.terminalSettings.field-GOIdentifier')
        : translate('page.terminalSettings.field-inventoryNumber')
    } ${successfullyGeneratedIdModalSlug === 'GO_ID' ? businessUnit : inventoryNumber} ${translate(
      'page.terminalSettings.successfullyAssignedTerminal',
    )}${terminalId}`;

    const isGenerateIdModalShow =
      successfullyGeneratedIdModalSlug === 'InventoryNumber' || successfullyGeneratedIdModalSlug === 'GO_ID';

    return (
      <>
        {(isLoading || isOfflineLoading) && <LoadingPanel />}
        <div className="TerminalDetails">
          <Row
            className="TerminalDetails-row"
            type="flex"
            align="middle"
            gutter={24}
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Row className="TerminalDetails-row" type="flex" align="middle" gutter={24} style={{ flexWrap: 'wrap' }}>
              <p style={{ marginBottom: '0' }}>{translate('page.terminalSettings.header-field-title')}</p>
              <Input value={terminalId} disabled className="newTerminal--header-input" style={{ width: 'auto' }} />
            </Row>

            <div className="TerminalDetails-btnGroup">
              {checkElement(registrationButton, permissionsByName) && (
                <Button type="primary" disabled={isEditing} onClick={() => this.onHandleSubmit('registration')}>
                  {translate('core.buttonTitles.registration')}
                </Button>
              )}
              {checkElement(unregistrationButton, permissionsByName) && (
                <Button type="primary" disabled={isEditing} onClick={() => this.onHandleSubmit('deregistration')}>
                  {translate('core.buttonTitles.deregistration')}
                </Button>
              )}

              {checkElement(updateSettingsButton, permissionsByName) && (
                <Button type="primary" className="TerminalDetails-saveButton" onClick={onSubmitHandler}>
                  {isEditing
                    ? translate('page.screenControlBusinessServices.button-save')
                    : translate('page.screenControlBusinessServices.button-edit')}
                </Button>
              )}
            </div>
          </Row>

          <Row gutter={24}>
            {/* START MAIN ROW */}
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card
                type="inner"
                title={translate('page.terminalSettings.general-settings-title')}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <TerminalDetailsField translateSlug="page.terminalSettings.field-city">
                      <DropDownCities
                        disabled={!isEditing}
                        cityId={cityId}
                        cities={cities}
                        onChange={onChangeCityIdCallback}
                      />
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-comissionProfile">
                      <Select
                        value={commisionProfileValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        onChange={getChangeCallbackByField('commisionProfileValue')}
                        disabled={!isEditing}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                      >
                        {comissionProfiles.map((el) => (
                          <Option value={el.ProfileId} key={`comissionProfiles-${el.ProfileId}`}>
                            {el.ProfileName}
                          </Option>
                        ))}
                      </Select>
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-mainScreenProfile">
                      <Select
                        value={mainProfilesListValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        onChange={getChangeCallbackByField('mainProfilesListValue')}
                        filterOption={(input, option) =>
                          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                        disabled={!isEditing}
                      >
                        {mainProfilesList.map((el) => (
                          <Option key={`mainProfilesList-elem-${el.MainProfileId}`} value={el.MainProfileId}>
                            {el.MainProfileName} - {el.MainProfileId}
                          </Option>
                        ))}
                      </Select>
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-status">
                      <Select
                        value={statusesValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        onChange={getChangeCallbackByField('statusesValue')}
                        disabled={!isEditing}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                      >
                        {statuses
                          .sort(({ StatusId: prevStatusId }, { StatusId: nexStatusId }) =>
                            ACCESS_TERMINAL_STATUS.includes(nexStatusId)
                              ? 1
                              : ACCESS_TERMINAL_STATUS.includes(prevStatusId)
                              ? -1
                              : 0,
                          )
                          .map((el) => (
                            <Option
                              key={`statuses-el-${el.StatusId}-${el.StatusName}`}
                              value={el.StatusId}
                              disabled={!ACCESS_TERMINAL_STATUS.includes(el.StatusId)}
                            >
                              {el.StatusName}
                            </Option>
                          ))}
                      </Select>
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-name">
                      <Input
                        value={terminalName}
                        style={{ width: '50%' }}
                        onChange={getChangeCallbackByEventByField('terminalName')}
                        disabled={!isEditing}
                      />
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-address">
                      <Popconfirm
                        placement="right"
                        style={{ right: '20px' }}
                        title={translate('page.terminalSettings.address-confirm-title')}
                        visible={showApproveAddress}
                        onConfirm={() => {
                          getChangeCallbackByField('showApproveAddress')(false);
                        }}
                        onCancel={callBackForOnConfirmApproveAddress}
                        okText={translate('grids.certificates-issued.onOkText')}
                        cancelText={translate('grids.certificates-issued.onCancelText')}
                      >
                        <Input
                          value={addressValue}
                          disabled={!isEditing}
                          onChange={
                            isAddressIncorrect
                              ? getChangeCallbackByEventByField('addressValue')
                              : getChangeCallbackByField('addressValue')
                          }
                          onFocus={onAddressInputFocus}
                        />
                      </Popconfirm>
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-coordinates">
                      <Input
                        value={geopointValue}
                        style={{ width: '50%' }}
                        onChange={getChangeCallbackByField('geopointValue')}
                        disabled
                      />
                    </TerminalDetailsField>

                    <TerminalDetailsField translateSlug="page.terminalSettings.field-placing">
                      <Select
                        value={placingInfoValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        onChange={getChangeCallbackByField('placingInfoValue')}
                        disabled={!isEditing}
                      >
                        {placingInfo.map((el) => (
                          <Option key={`placingInfo-elem-${el.TypeDictionaryId}-${el.Id}`} value={el.Id}>
                            {el.Value}
                          </Option>
                        ))}
                      </Select>
                    </TerminalDetailsField>
                  </Col>
                </Row>
              </Card>
              {checkElement(showAdditionalSettingsButton, permissionsByName) && (
                <Button
                  style={{ marginTop: '10px' }}
                  className="TerminalDetails-toggleButton"
                  onClick={toggleAdditionalCardVisibility}
                  type="primary"
                >
                  {!additionalCardVisibility
                    ? translate('page.terminalSettings.btn-additionalSettings')
                    : translate('page.terminalSettings.btn-additionalSettings-hide')}
                </Button>
              )}
              {checkElement(getStatusButton, permissionsByName) && (
                <Button type="primary" onClick={this.getStatusApplication} className="TerminalDetails-statusBtn">
                  {translate('core.buttonTitles.get-status')}
                </Button>
              )}
              {isShowStatusApplication && (
                <div
                  className="TerminalDetails-statusApplication"
                  style={{ color: `${statusApplication.status_color}` }}
                >
                  {Object.entries(statusApplication).map(([key, value]) => {
                    return [
                      'status',
                      'action',
                      'request_time',
                      statusApplication['status_code'] === 'ERROR' ? 'error_message' : '',
                    ].includes(key) ? (
                      <div className="TerminalDetails-row">
                        <span className="TerminalDetails-keys">{<b>{key}</b>}:</span>
                        <span className="TerminalDetails-value">
                          {key === 'request_time' ? convertToISO(statusApplication['RequestTimeNoUtc']) : value}
                        </span>
                      </div>
                    ) : null;
                  })}
                </div>
              )}
              {err && err.length && <div style={{ color: 'red' }}>{err}</div>}
            </Col>
            {/* END MAIN ROW */}
            {/* START ADDITIONAL ROW */}
            <Col xs={24} sm={24} md={24} lg={12}>
              {additionalCardVisibility && (
                <>
                  <Card
                    type="inner"
                    title={translate('page.terminalSettings.addtionalSettings-title')}
                    style={{ marginTop: '20px' }}
                  >
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                        <TerminalDetailsField translateSlug="page.terminalSettings.field-printerCapacity">
                          <InputNumber
                            value={capacityPrinterPaper}
                            min={0}
                            max={6000}
                            style={{ width: '50%' }}
                            onChange={getChangeCallbackByField('capacityPrinterPaper')}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-cashboxCapacity">
                          <InputNumber
                            value={capacityCashBox}
                            min={0}
                            // max={3000}
                            style={{ width: '50%' }}
                            onChange={getChangeCallbackByField('capacityCashBox')}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-monitorCount">
                          <Select
                            value={monitorCountValue}
                            onChange={getChangeCallbackByField('monitorCountValue')}
                            disabled={!isEditing}
                          >
                            {selectMonitorCount.map((el) => (
                              <Option key={`selectMonitorCount-el-${el}`} value={el} disabled={el === 0}>
                                {el}
                              </Option>
                            ))}
                          </Select>
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="grids.createTerminal.column-designType">
                          <Select
                            value={designTypeId}
                            onChange={getChangeCallbackByField('designTypeId')}
                            disabled={!isEditing}
                          >
                            {designTypes.map((el) => (
                              <Option key={`designTypes-el-${el.DesignTypeId}`} value={el.DesignTypeId}>
                                {el.Name}
                              </Option>
                            ))}
                          </Select>
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-description">
                          <TextArea
                            value={terminalDescription}
                            style={{ width: '50%' }}
                            onChange={getChangeCallbackByEventByField('terminalDescription')}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-dateOfCreation">
                          <DatePicker
                            value={dateCreation}
                            format="dd.MM.yyyy"
                            min={new Date(2007, 0, 1)}
                            max={getMaxDate()}
                            onChange={onChangeDateCreation}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-notes">
                          <Input
                            value={noteValue}
                            style={{ width: '50%' }}
                            onChange={getChangeCallbackByEventByField('noteValue')}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-branch">
                          <Input
                            value={terminalBranch}
                            style={{ width: '50%' }}
                            onChange={getChangeCallbackByEventByField('terminalBranch')}
                            disabled={!isEditing}
                          />
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-project">
                          <Select
                            value={projectValue}
                            dropdownStyle={{ zIndex: '1054', width: '100%' }}
                            onChange={getChangeCallbackByField('projectValue')}
                            disabled
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch
                          >
                            {terminalProjects.map((el) => (
                              <Option key={`terminalProjects-${el.Id}-${el.Value}`} value={el.Id}>
                                {el.Value}
                              </Option>
                            ))}
                          </Select>
                        </TerminalDetailsField>

                        <TerminalDetailsField translateSlug="page.terminalSettings.field-networkContract">
                          <Select
                            value={networkContractsValue}
                            dropdownStyle={{ zIndex: '1054', width: '100%' }}
                            onChange={getChangeCallbackByField('networkContractsValue')}
                            disabled
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch
                          >
                            {networkContracts.map((el) => (
                              <Option key={`networkContracts-${el.Id}-${el.Value}`} value={el.Id}>
                                {el.Value}
                              </Option>
                            ))}
                          </Select>
                        </TerminalDetailsField>
                      </Col>
                    </Row>
                  </Card>

                  {checkElement(fiscalizationInventory, permissionsByName) && (
                    <Card
                      type="inner"
                      title={translate('page.terminalSettings.fiscalizationInventory-title')}
                      style={{ marginTop: '20px' }}
                    >
                      {checkElement(fiscalNumberInput, permissionsByName) && (
                        <TerminalDetailsField translateSlug="page.terminalSettings.field-fiscalNumber">
                          <Input
                            value={fiscalNumber}
                            style={{ width: '50%' }}
                            onChange={({ target: { value } }) => {
                              getChangeCallbackByField('fiscalNumber')(value.trim());
                            }}
                            disabled={!isEditing || isFiscalNumberAlreadyExist}
                          />
                        </TerminalDetailsField>
                      )}

                      {checkElement(businessUnitInput, permissionsByName) && (
                        <>
                          <TerminalDetailsField translateSlug="page.terminalSettings.field-GOIdentifier">
                            <Input value={businessUnit} style={{ width: '50%' }} disabled />
                          </TerminalDetailsField>

                          <Row className="TerminalDetails-generateIdActionRow">
                            <Button type="primary" disabled={businessUnit || !isEditing} onClick={generateGOID}>
                              {translate('page.terminalSettings.generateID')}
                            </Button>
                          </Row>
                        </>
                      )}

                      {checkElement(inventoryNumberInput, permissionsByName) && (
                        <>
                          <TerminalDetailsField translateSlug="page.terminalSettings.field-inventoryNumber">
                            <Input value={inventoryNumber} style={{ width: '50%' }} disabled />
                          </TerminalDetailsField>

                          <Row className="TerminalDetails-inventoryNumberActionRow">
                            <Button
                              type="primary"
                              disabled={inventoryNumber || !isEditing}
                              onClick={generateInventoryNumber}
                            >
                              {translate('page.terminalSettings.generateInventoryNumber')}
                            </Button>
                            <Button
                              type="primary"
                              disabled={inventoryNumber || !isEditing}
                              onClick={() => {
                                getChangeCallbackByField('successfullyGeneratedIdModalSlug')('migrateInventoryNumber');
                              }}
                            >
                              {translate('page.terminalSettings.migrateInventoryNumber')}
                            </Button>
                          </Row>
                        </>
                      )}
                    </Card>
                  )}
                </>
              )}
            </Col>
            {/* END ADDITIONAL ROW */}
          </Row>
        </div>
        {isGenerateIdModalShow && (
          <InfoModal content={generateModalTitle} closeModal={closeModal} visible={isGenerateIdModalShow} />
        )}
        {
          <ModalGoogleMap
            visible={successfullyGeneratedIdModalSlug === 'ChangeAddress'}
            togglePopup={onAddressChange}
            closeModal={closeModal}
            onChange={getChangeCallbackByField('addressValue')}
            onChangeGeopoint={getChangeCallbackByField('geopointValue')}
            onToggleShowApproveAddress={onToggleShowApproveAddress}
            currentAddress={addressValue}
            currentGeopoint={geopointValue}
            cities={cities}
            cityId={cityId}
          />
        }
        {successfullyGeneratedIdModalSlug === 'migrateInventoryNumber' && (
          <MigrateInventoryNumberModal
            closeModal={closeModal}
            visible={successfullyGeneratedIdModalSlug === 'migrateInventoryNumber'}
            migrateInventoryNumber={migrateInventoryNumber}
            changeInventoryNumber={getChangeCallbackByField('inventoryNumber')}
            terminalId={terminalId}
          />
        )}
        {showModal && showModal.length && (
          <ModalRegistration
            visible={!!showModal.length}
            onClose={this.closeModal}
            modalInfo={this.choseModal(showModal)}
            nameModal={showModal}
            formState={formState}
            fiscalNumber={FiscalNumber}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TerminalDetails);
