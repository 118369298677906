import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { Button, Col } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService } from '../../services';
import ModalDirectors from './ModalDirectors/ModalDirectors';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class DirectorsGrid extends PureComponent {
  constructor() {
    super();
    this.currentArray = findArray('directors');
  }
  state = {
    isLoading: false,
    directors: [],
    selectedItems: [],
    modalType: '',
  };

  fetchDirectors = async () => {
    try {
      this.setState({ isLoading: true });
      const directors = await api.getServicesSuppliersCeo();
      this.setState({ directors, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  onHandleSelected = (selectedItems) => {
    this.setState({ selectedItems });
  };

  showModal = (e) => {
    const { name } = e.target;
    this.setState({ modalType: name });
  };

  createToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;
    return (
      <Col>
        <ButtonGroup className="Beneficiaries-btnGroup" onClick={this.showModal}>
          {checkElement('directors-btnAdd', this.currentArray) && (
            <Button type="primary" name="add">
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('directors-btnChange', this.currentArray) && (
            <Button type="primary" name="change" disabled={!selectedItems.length || selectedItems.length > 1}>
              {translate('core.buttonTitles.change')}
            </Button>
          )}
          {checkElement('directors-btnDelete', this.currentArray) && (
            <Button type="primary" name="delete" disabled={!selectedItems.length}>
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </ButtonGroup>
      </Col>
    );
  };

  componentDidMount() {
    this.fetchDirectors();
  }

  render() {
    const { directors, isLoading, modalType, selectedItems } = this.state;
    if (!checkElement('directors-content', this.currentArray)) return null;
    return (
      <>
        <Grid
          data={directors}
          toolbar={this.createToolbar()}
          name="directorsGrid"
          isLoading={isLoading}
          onRefresh={this.fetchDirectors}
          fieldForSelection="Id"
          multiSelected
          onSelect={this.onHandleSelected}
        >
          <GridColumn field="selected" filterable={false} sortable={false} showAllSelected={true} columnMenu={false} />
          <GridColumn field="Id" title="grids.beneficiaries.col-id" width="150" />
          <GridColumn field="Inn" title="grids.beneficiaries.col-inn" width="150" />
          <GridColumn field="Name" title="grids.beneficiaries.col-fullName" width="350" />
        </Grid>
        {!!modalType.length && (
          <ModalDirectors
            modal={modalType}
            visible={!!modalType.length}
            selectedItems={selectedItems}
            updateData={this.fetchDirectors}
            onClose={() => this.setState({ modalType: '' })}
          />
        )}
      </>
    );
  }
}

export default withTranslate(DirectorsGrid);
