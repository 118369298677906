import React from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';

import Input from '../../components/Input';
import { QuestionCircleFilled } from '@ant-design/icons';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';

export const renderProfileNameField = (formState, onChangeFormState, formErrors, onError, translate) => (
  <>
    <Col span={8} sm={6} className="CommissionProfiles-label">
      {translate('grids.commission-profiles.column-profileName')}
    </Col>
    <Col span={15} sm={17} offset={1}>
      <Input
        formState={formState}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        name="ProfileName"
        tooltipTitle={translate('grids.commission-profiles.tooltipNotifications.profileName')}
        maxLength={55}
      />
    </Col>
  </>
);

export const renderProfileDescriptionField = (formState, onChangeFormState, formErrors, onError, translate) => (
  <>
    <Col span={8} sm={6} className="CommissionProfiles-label">
      {translate('grids.commission-profiles.column-description')}
    </Col>
    <Col span={15} sm={17} offset={1}>
      <Input
        formState={formState}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        name="ProfileDescription"
        tooltipTitle={translate('grids.commission-profiles.tooltipNotifications.profileDescription')}
        maxLength={55}
      />
    </Col>
  </>
);

export const renderProfileDealerVisibilityFields = (
  formState,
  handleRadioChange,
  handleOwnersChange,
  translate,
  owners,
) => {
  return (
    <>
      <Row className="CommissionProfiles-profileVisibilityLabel">
        <Col span={22}>{translate('grids.commission-profiles.profileVisibility')}:</Col>
        <Col span={2}>
          <Tooltip
            trigger="hover"
            title={translate('grids.commission-profiles.tooltipNotifications.profileVisibility')}
            placement="bottom"
          >
            <span className="CommissionProfiles-infoButtonWrapper">
              <QuestionCircleFilled />
            </span>
          </Tooltip>
        </Col>
      </Row>
      <Row className="CommissionProfiles-subRow">
        <Radio.Group
          value={formState.ProfileVisibility}
          onChange={handleRadioChange}
          className="CommissionDetails-radioGroup"
        >
          <Col span={12} className="CommissionDetails-radioLabel">
            <Radio value="All"> {translate('grids.commission-profiles.allDealers')}:</Radio>
          </Col>

          <Col span={12} className="CommissionDetails-radioLabel">
            <Radio value="Manual"> {translate('grids.commission-profiles.manualSetting')}:</Radio>
          </Col>
        </Radio.Group>
      </Row>
      <Row className="CommissionProfiles-subRow">
        <Col span={8} sm={6} className="CommissionProfiles-label">
          {translate('grids.commission-profiles.dealers')}
        </Col>
        <Col span={15} sm={17} offset={1}>
          <GridDropdown
            data={DropdownNormalizersService.normalizeOwners(owners)}
            colConfig={OWNERS_COLUMN_CONFIG}
            disabled={formState.ProfileVisibility === 'All'}
            selectItems={formState.ProfileVisibility === 'Manual' ? formState.SelectedDealers : []}
            onSave={handleOwnersChange}
            defaultTitle={translate('owner.initial-choose')}
          />
        </Col>
      </Row>
    </>
  );
};
