export const BIN_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    width: '240',
    titleTranslateSlug: 'Bin',
    isShowOnMobile: true,
  },
];

export const SYSTEM_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    width: '240',
    titleTranslateSlug: 'System_Type id',
    isShowOnMobile: true,
  },
  {
    fieldName: 'CustomName',
    width: '240',
    titleTranslateSlug: 'System_Type',
    isShowOnMobile: true,
  },
];

export const PAN_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    width: '240',
    titleTranslateSlug: 'PAN_Type id',
    isShowOnMobile: true,
  },
  {
    fieldName: 'CustomName',
    width: '240',
    titleTranslateSlug: 'PAN_Type',
    isShowOnMobile: true,
  },
];

export const SERVICES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    width: '240',
    titleTranslateSlug: 'Service id',
    isShowOnMobile: true,
  },
];

export const BANK_SERVICES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    width: '240',
    titleTranslateSlug: 'Bank name',
    isShowOnMobile: true,
  },
  {
    fieldName: 'BankServiceId',
    width: '240',
    titleTranslateSlug: 'Bank service id',
    isShowOnMobile: true,
  },
];

export const INITIAL_VALUES = {
  bins: [],
  systemTypes: [],
  panTypes: [],
  incomingServiceList: [],
  outcomingServiceList: [],
  banks: [],
  rangeFrom: '',
  rangeTo: '',
  amountMax: '',
  priority: '',
  weight: '',
};

export const CONFIGS = {
  bins: BIN_COLUMN_CONFIG,
  systemTypes: SYSTEM_TYPES_COLUMN_CONFIG,
  panTypes: PAN_TYPES_COLUMN_CONFIG,
  incomingServiceList: SERVICES_COLUMN_CONFIG,
  outcomingServiceList: SERVICES_COLUMN_CONFIG,
  banks: BANK_SERVICES_COLUMN_CONFIG,
};

export const REQUIREMENT_LIST = [
  { name: 'bins', isSelect: true },
  { name: 'systemTypes', isSelect: true },
  { name: 'panTypes', isSelect: true },
  { name: 'incomingServiceList', isSelect: true },
  { name: 'outcomingServiceList', isSelect: true },
  { name: 'banks', isSelect: true },
  { name: 'rangeFrom' },
  { name: 'rangeTo' },
  { name: 'amountMax' },
  { name: 'priority' },
];

export const BANK_LIST_GRID_COLUMNS = ['bankName', 'bankServiceId'];
