import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, message } from 'antd';

import { ApiService, StorageService, tabService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import findArray from '../../../utils/findArrForCurrentPage';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import AddSupplierModal from '../../../components/AddSupplierModal';
import GqlService from '../../../components/grids/gql.service';
import ServiceSuppliersService from './ServiceSuppliers.service';
import { BASE_FIELDS } from './constants';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';

import './ServiceSuppliersGrid.styled.scss';

const api = new ApiService();

class ServiceSuppliersGrid extends PureComponent {
  currentArray = findArray('serviceSuppliers');

  state = {
    supplierList: [],
    isLoading: false,
    dialogVariant: '',
    selectedItems: [],
    showDeleted: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
  };

  componentDidMount() {
    this.fetchSuppliersGQL();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchSuppliersGQL({ selectIdsString });
    }
  }

  iterateFetchSuppliersGQL = async (skip, suppliersList, argumentsString, currentColumns) => {
    try {
      const query = `suppliers(take: 500,skip:${skip},${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { supplierList: currentSuppliersList, hasNextPage } = ServiceSuppliersService.getGQLResponse(data);

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(
            skip + 500,
            [...suppliersList, ...currentSuppliersList],
            argumentsString,
            currentColumns,
          )
        : [...suppliersList, ...currentSuppliersList];
    } catch (e) {
      throw e;
    }
  };

  getGQLStuff = (props) => {
    const { filtersString, sortString } = this.state;

    const storageColumns = StorageService.getItem('ServiceSuppliersGrid');
    const currentColumns =
      (props && props.currentFieldList) ||
      (storageColumns ? GqlService.getColumnsFromStorage(storageColumns) : BASE_FIELDS);

    const currentFiltersString = `${
      props && props.selectIdsString
        ? `{okpo:{in:[${props.selectIdsString
            .split(',')
            .map((id) => `"${id}"`)
            .join(',')}]}},`
        : ''
    }${filtersString || ''}`;

    const argumentsString = `order: ${sortString || '{ supplierId: DESC }'}, ${
      currentFiltersString ? `where:{and:[${currentFiltersString}]},` : ``
    }`;

    return { argumentsString, currentColumns };
  };

  fetchAllSuppliersGQL = async (props) => {
    const { argumentsString, currentColumns } = this.getGQLStuff(props);

    try {
      await this.setState({ isLoading: true });
      const supplierList = await this.iterateFetchSuppliersGQL(0, [], argumentsString, currentColumns);
      await this.setState({ isLoading: false });

      return supplierList;
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getHandleExelExportDate = async () => {
    const { selectIdsString } = this.state;
    return await this.fetchAllSuppliersGQL({ selectIdsString });
  };

  fetchSuppliersGQL = async (props) => {
    const { rowAmount, pageValue } = this.state;
    const { argumentsString, currentColumns } = this.getGQLStuff(props);

    this.setState({ isLoading: true });

    try {
      const query = `suppliers(${argumentsString} take:${rowAmount}, skip:${
        rowAmount * (pageValue - 1)
      }){items{${currentColumns.join(',')}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { supplierList, hasPreviousPage, hasNextPage } = ServiceSuppliersService.getGQLResponse(data);

      this.setState({ supplierList, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchSuppliersGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchSuppliersGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchSuppliersGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="ServiceSuppliersGrid-toolbar">
        {checkElement('serviceSuppliers-create', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'add' });
              }}
            >
              {translate('page.serviceSupplier.createSupplier')}
            </Button>
          </div>
        )}

        {checkElement('serviceSuppliers-delete', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'delete' });
              }}
            >
              {translate('page.serviceSupplier.deleteSupplier')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  onDelete = async () => {
    const { selectedItems, selectIdsString } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.deleteSupplier([...new Set(selectedItems.map(({ supplierId }) => supplierId))]);
      this.setState({ modalVariant: '' });
      message.success(translate(`page.serviceSupplier.technicalPartnerSuccessfulDeleted`), 2.5);
      this.fetchSuppliersGQL({ selectIdsString });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  getHandlers = () => {
    return [
      {
        title: 'page.serviceSupplier.supplierProps',
        action: (props) =>
          tabService.addTab({
            type: 'supplierProps',
            dataItem: { ...props.dataItem },
          }),
        show: checkElement('serviceSuppliers-props', this.currentArray),
      },
      {
        title: 'page.serviceSupplier.treaties',
        action: (props) => tabService.addTab({ type: 'supplierTreaties', dataItem: props.dataItem }),
        show: checkElement('serviceSuppliers-treaties', this.currentArray),
      },
      {
        title: 'page.serviceSupplier.supplierUsers',
        action: (props) => tabService.addTab({ type: 'supplierUsers', dataItem: props.dataItem }),
        show: checkElement('serviceSuppliers-users', this.currentArray),
      },
    ];
  };

  render() {
    const { isLoading, supplierList, modalVariant, pageValue, hasNextPage, hasPreviousPage, selectIdsString } =
      this.state;
    const { translate } = this.props;

    if (!checkElement('serviceSuppliers-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={supplierList}
          isLoading={isLoading}
          onRefresh={() => {
            this.fetchSuppliersGQL({ selectIdsString });
          }}
          name="ServiceSuppliersGrid"
          toolbar={this.buildToolbar()}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          fieldForSelection="supplierId"
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          getHandleExelExportDate={this.getHandleExelExportDate}
          isShowSpecificFilterByFieldName="okpo"
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
          isGQL
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="supplierId"
            title="page.serviceSupplier.col-number"
            width="160"
            filter="numeric"
            handlers={this.getHandlers()}
            dropdown={checkElement('serviceSuppliers-dropdown', this.currentArray)}
          />
          <GridColumn field="name" title="page.serviceSupplier.col-name" width="400" />
          <GridColumn field="okpo" title="page.serviceSupplier.col-EDRPOU" width="160" />
          <GridColumn
            field="treatyNumber"
            title="page.serviceSupplier.col-contractNumber"
            width="180"
            filter="numeric"
          />
          <GridColumn
            field="treatyDate"
            title="page.serviceSupplier.col-contractDate"
            width="120"
            cell={({ dataItem: { treatyDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(treatyDate, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(treatyDate) => getTransformDateString(treatyDate, 'DD.MM.YYYY')}
            columnMenu={false}
          />
          <GridColumn
            field="terminationDate"
            title="page.serviceSupplier.col-terminationDate"
            width="160"
            cell={({ dataItem: { terminationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(terminationDate)}</td>
            )}
            formatFilterCellData={(terminationDate) => getTransformDateString(terminationDate)}
            columnMenu={false}
          />
        </Grid>

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.onDelete}
            deleteContent={translate('page.serviceSupplier.areYouSure')}
          />
        )}

        {modalVariant === 'add' && (
          <AddSupplierModal
            visible={modalVariant === 'add'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={() => {
              this.fetchSuppliersGQL({ selectIdsString });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ServiceSuppliersGrid);
