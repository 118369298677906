import React, { PureComponent } from 'react';
import PageTabs from '../../components/tabber/tabber';
import SubBrendsTerminalsGrid from './SubBrandsTerminalsGrid';
import SubBrandsDictionary from './SubBrandsDictionary';

class SubBrandsTerminals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.subBrandsTerminals.title',
          content: <SubBrendsTerminalsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'subBrandsDictionary':
        tabs.push({
          title: 'page.subBrandsTerminals.directorySubBrands',
          content: <SubBrandsDictionary />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default SubBrandsTerminals;
