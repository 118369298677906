export const DEALER_FIELDS = [
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-percent',
    fieldName: 'DealerProviderPercent',
  },
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-min',
    fieldName: 'DealerProviderMin',
  },
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-max',
    fieldName: 'DealerProviderMax',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-percent',
    fieldName: 'DealerClientPercent',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-min',
    fieldName: 'DealerClientMin',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-max',
    fieldName: 'DealerClientMax',
  },
];
export const PAYMENT_SYSTEM_FIELDS = [
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-percent',
    fieldName: 'PaymentSystemProviderPercent',
  },
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-min',
    fieldName: 'PaymentSystemProviderMin',
  },
  {
    label: 'grids.dealerCommissionsChange.field-dealer-commission-max',
    fieldName: 'PaymentSystemProviderMax',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-percent',
    fieldName: 'PaymentSystemClientPercent',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-min',
    fieldName: 'PaymentSystemClientMin',
  },
  {
    label: 'grids.dealerCommissionsChange.field-client-commission-max',
    fieldName: 'PaymentSystemClientMax',
  },
];

export const SUPPLIERS_COMMISSION_FIELDS = [
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionPercent',
    fieldName: 'SuppliersProviderPercent',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMin',
    fieldName: 'SuppliersProviderMin',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMax',
    fieldName: 'SuppliersProviderMax',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
    fieldName: 'SuppliersProviderAmountTotal',
  },
];
export const SUPPLIERS_CARD_COMMISSION_FIELDS = [
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionPercent',
    fieldName: 'SuppliersProviderPercentForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMin',
    fieldName: 'SuppliersProviderMinForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionMax',
    fieldName: 'SuppliersProviderMaxForCards',
  },
  {
    label: 'page.recalculationTransactions.field-suppliersCommissionCalculationBase',
    fieldName: 'SuppliersProviderAmountTotalForCards',
  },
];

export const MIN_PERCENT = -100;
export const MAX_PERCENT = 100;
export const MIN_VAlUE = -99999.9999;
export const MAX_VAlUE = 99999.9999;

export const INITIAL_VALUES_RECALCULATION_COMMISSIONS = {
  DealerProviderPercent: 0,
  DealerProviderMin: 0,
  DealerProviderMax: 0,
  DealerClientPercent: 0,
  DealerClientMin: 0,
  DealerClientMax: 0,
  PaymentSystemProviderPercent: 0,
  PaymentSystemProviderMin: 0,
  PaymentSystemProviderMax: 0,
  PaymentSystemClientPercent: 0,
  PaymentSystemClientMin: 0,
  PaymentSystemClientMax: 0,
  DealerCommissionsDateStart: new Date(),
  DealerCommissionsDateEnd: new Date(),
  SuppliersProviderPercent: 0,
  SuppliersProviderMin: 0,
  SuppliersProviderMax: 0,
  SuppliersProviderAmountTotal: '[amount_total]',
  SuppliersProviderPercentForCards: 0,
  SuppliersProviderMinForCards: 0,
  SuppliersProviderMaxForCards: 0,
  SuppliersProviderAmountTotalForCards: '[amount_total]',
};

export const RESET_FIELDS_DEALER_COMMISSIONS = {
  DealerProviderPercent: 0,
  DealerProviderMin: 0,
  DealerProviderMax: 0,
  DealerClientPercent: 0,
  DealerClientMin: 0,
  DealerClientMax: 0,
  PaymentSystemProviderPercent: 0,
  PaymentSystemProviderMin: 0,
  PaymentSystemProviderMax: 0,
  PaymentSystemClientPercent: 0,
  PaymentSystemClientMin: 0,
  PaymentSystemClientMax: 0,
};

export const RESET_FIELDS_SUPPLIER_COMMISSIONS = {
  SuppliersProviderPercent: 0,
  SuppliersProviderMin: 0,
  SuppliersProviderMax: 0,
  SuppliersProviderAmountTotal: '[amount_total]',
  SuppliersProviderPercentForCards: 0,
  SuppliersProviderMinForCards: 0,
  SuppliersProviderMaxForCards: 0,
  SuppliersProviderAmountTotalForCards: '[amount_total]',
};

// export const INITIAL_VALUES_SUPPLIER_COMMISSIONS = {};
