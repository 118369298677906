const reportonservice = (translate, value) => {
  switch (value) {
    case 'servicesReport-AmountTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-turn')}`;
    case 'servicesReport-AvgPayment':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.serviceReport.column-avgPayment')}`;
    case 'servicesReport-btn-submit':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'servicesReport-CommissionClient':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-clients')}`;
    case 'servicesReport-CommissionAll':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.cityReport.column-commission')}`;
    case 'servicesReport-QuantityTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.serviceReport.column-quantityPayments')}`;
    case 'servicesReport-ServiceId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminal-events.column-serviceId')}`;
    case 'servicesReport-ServiceName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminals.column-name')}`;
    case 'servicesReport-CommissionProvider':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-owners')}`;
    default:
      return value;
  }
};

export default reportonservice;
