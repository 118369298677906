import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './UpdateModal.scss';
import CustomInput from '../../../components/Input/Input';
import UpdateModalService from './UpdateModal.service';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { CONFIGS, REQUIREMENT_LIST } from '../constants';
import { tabService } from '../../../services';

class UpdateModal extends PureComponent {
  renderInputRow = ({ label, inputComponent }) => {
    return (
      <div className="CardRoutingUpdateModal-inputRow">
        <div className="CardRoutingUpdateModal-label">{label}</div>
        {inputComponent}
      </div>
    );
  };

  renderInput = ({ name }) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;

    return (
      <div className="CardRoutingUpdateModal-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={UpdateModalService.getValidators(translate)}
          name={name}
        />
      </div>
    );
  };

  getSelectChangeCallback = (name) => (selectItems) => {
    const { onChangeFormState } = this.props;

    onChangeFormState(name, selectItems);
  };

  renderSelect = ({ name, label, isShort }) => {
    const { formState, dropdownsData, translate } = this.props;

    return (
      <div className={`ServiceMainProps-input ${isShort ? 'CardRoutingUpdateModal-shortSelect' : ''}`}>
        <GridDropdown
          data={dropdownsData[name]}
          colConfig={CONFIGS[name]}
          selectItems={formState[name]}
          onSave={this.getSelectChangeCallback(name)}
          defaultTitle={`${translate('page.routingManagement.select')} ${label}`}
          isAllowEmptyList
          isSingle
        />
      </div>
    );
  };

  changeRedirect = () => {
    const {
      variant,
      closeModal,
      formState: {
        bins,
        systemTypes,
        panTypes,
        incomingServiceList,
        outcomingServiceList,
        banks,
        rangeFrom,
        rangeTo,
        amountMax,
        priority,
        weight,
      },
      selectedItems,
      changeRouteListByCheckTab,
      dropdownsData,
    } = this.props;

    closeModal();

    if (variant === 'massChange') {
      const valuesListWithNativeKeys = {
        bin: bins[0],
        systemType: systemTypes[0],
        panType: panTypes[0],
        incomingServiceId: incomingServiceList[0],
        outcomingServiceId: outcomingServiceList[0],
        bankName: banks[0],
        rangeFrom,
        rangeTo,
        amountMax,
        priority,
        weight,
      };

      const valuesListWithoutEmptyValues = Object.entries(valuesListWithNativeKeys)
        .filter(([_, value]) => (Array.isArray(value) ? !!value.length : !!value || value === 0))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: key === 'bankName' ? (value === 'null' ? null : value) : UpdateModalService.getParsedValue(value),
          }),
          {},
        );

      tabService.addTab({
        type: 'updateRoutingValidationResult',
        dataItem: {
          dropdownsData: dropdownsData,
          type: 'change',
          changedList: selectedItems.map((selectedItem) => ({ ...selectedItem, ...valuesListWithoutEmptyValues })),
        },
      });

      return;
    }

    const valuesListWithNativeKeys = {
      bin: UpdateModalService.getDropdownValue(bins),
      systemType: UpdateModalService.getDropdownValue(systemTypes),
      panType: UpdateModalService.getDropdownValue(panTypes),
      incomingServiceId: UpdateModalService.getDropdownValue(incomingServiceList),
      outcomingServiceId: UpdateModalService.getDropdownValue(outcomingServiceList),
      bankName: UpdateModalService.getDropdownValue(banks),
      rangeFrom: UpdateModalService.getParsedValue(rangeFrom),
      rangeTo: UpdateModalService.getParsedValue(rangeTo),
      amountMax: UpdateModalService.getParsedValue(amountMax),
      priority,
      weight: UpdateModalService.getParsedValue(weight),
    };

    const singleChangeRoute = {
      ...selectedItems[0],
      ...valuesListWithNativeKeys,
    };

    if (variant === 'change') {
      tabService.addTab({
        type: 'updateRoutingValidationResult',
        dataItem: {
          dropdownsData: dropdownsData,
          type: 'change',
          changedList: [singleChangeRoute],
        },
      });

      return;
    }

    changeRouteListByCheckTab(singleChangeRoute);
  };

  render() {
    const { variant, closeModal, translate, formErrors, formState } = this.props;

    const isRequiredFieldsEmpty =
      (!formState.priority && formState.priority !== 0) || !formState.outcomingServiceList.length;
    const isFormErrorsExist = Object.values(formErrors).some((value) => !!value);

    return (
      <Modal
        title={translate(`page.routingManagement.${variant === 'massChange' ? 'massChangeRoute' : 'changeRoute'}`)}
        visible={variant === 'change' || variant === 'massChange' || variant === 'changeByCheckTab'}
        onCancel={closeModal}
        className="CardRoutingUpdateModal"
        footer={[
          <Button
            type="primary"
            key="add"
            disabled={(isRequiredFieldsEmpty && variant !== 'massChange') || isFormErrorsExist}
            onClick={this.changeRedirect}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderInputRow({ label: 'bins', inputComponent: this.renderSelect({ name: 'bins', label: 'bins' }) })}
        {this.renderInputRow({
          label: 'system_types',
          inputComponent: this.renderSelect({ name: 'systemTypes', label: 'system_types' }),
        })}
        {this.renderInputRow({
          label: 'pan_types',
          inputComponent: this.renderSelect({ name: 'panTypes', label: 'pan_types' }),
        })}
        {this.renderInputRow({
          label: 'incoming_service_list',
          inputComponent: this.renderSelect({ name: 'incomingServiceList', label: 'incoming_service_list' }),
        })}
        {this.renderInputRow({
          label: 'outcoming_service_list',
          inputComponent: this.renderSelect({ name: 'outcomingServiceList', label: 'outcoming_service_list' }),
        })}
        {this.renderInputRow({
          label: 'banks',
          inputComponent: this.renderSelect({ name: 'banks', label: 'banks', isShort: true }),
        })}
        <div className="CardRoutingUpdateModal-flexRow">
          {this.renderInputRow({ label: 'range_from', inputComponent: this.renderInput({ name: 'rangeFrom' }) })}
          {this.renderInputRow({ label: 'range_to', inputComponent: this.renderInput({ name: 'rangeTo' }) })}
        </div>
        <div className="CardRoutingUpdateModal-flexRow">
          {this.renderInputRow({ label: 'priority', inputComponent: this.renderInput({ name: 'priority' }) })}
          {this.renderInputRow({ label: 'weight', inputComponent: this.renderInput({ name: 'weight' }) })}
        </div>
        <div className="CardRoutingUpdateModal-shortRow">
          {this.renderInputRow({ label: 'amount_max', inputComponent: this.renderInput({ name: 'amountMax' }) })}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
