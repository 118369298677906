class MainPropsService {
  normalizeSelectData = (data, fieldId, fieldName) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      Id: dataItem[fieldId],
      Name: dataItem[fieldName],
    }));
  };

  calcIdsString = (ownerTypes) => {
    if (!ownerTypes) {
      return [];
    }

    return ownerTypes.split(',').map((item) => +item);
  };

  requiredValidation = (translate) => (value) => {
    if (!value) {
      return translate('page.commissionsServices.dropdownRequiredError');
    }

    return '';
  };

  integerNumberValidation = (translate) => (value) => {
    if (value === 0) {
      return `${translate('grids.comission-sub.errorMessages.valueMinRequired')} 1`;
    }

    if (!Number.isInteger(value) || `${value}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    return '';
  };

  sumValidation = (translate) => (value) => {
    const requiredError = this.requiredValidation(translate)(value);

    if (requiredError && value !== 0) {
      return requiredError;
    }

    if (`${value}`.match(/[^0-9.]/)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${value}`.includes('.') && `${value}`.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }

    return '';
  };

  differenceSumValidation = ({ minSum, maxSum }, translate) => {
    if (minSum > maxSum) {
      return `${translate('page.serviceMainProps.savingDidNot')} ${translate('page.serviceMainProps.mustNotExceed')}`;
    }

    return '';
  };

  getValidators = (translate) => {
    return {
      serviceName: this.requiredValidation(translate),
      commissionRules: this.requiredValidation(translate),
      sum: this.sumValidation(translate),
      minSum: this.sumValidation(translate),
      maxSum: this.sumValidation(translate),
      orgSum: this.sumValidation(translate),
      unlockingPeriod: this.integerNumberValidation(translate),
    };
  };
}

export default new MainPropsService();
