import React, { PureComponent } from 'react';
import { Button, Col, message, Modal, Row, Select, InputNumber } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ButtonList } from './mocked';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import './FishkaServiceGrid.scss';
import { ApiService } from '../../../services';
import { BUTTON_COLUMN_CONFIG } from './constants';
import FishkaService from './FiskaService.service';

const { Option } = Select;

const api = new ApiService();

class AddCashbackDialog extends PureComponent {
  state = {
    selectCategoryId: null,
    isLoading: false,
    serviceGroups: [],
    selectServices: [],
    services: [],
    cashback: null,
    cashbackError: '',
  };

  handleSelectChange = (value) => {
    this.setState({ selectCategoryId: value, selectServices: [] }, () => {
      this.menuButtonFind(this.state.selectCategoryId);
    });
  };

  menuButtonFind = async (categoryMenuId) => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.getServicesCashbackFishka(categoryMenuId);
      await this.setState({ services: services && services.length ? services : [], isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getServiceGroups = async () => {
    try {
      await this.setState({ isLoading: true });
      const serviceGroups = await api.getServicesGroupForFishka();

      await this.setState({
        serviceGroups: serviceGroups && serviceGroups.length ? serviceGroups : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.getServiceGroups();
  }

  handleSelectServices = (selectedItems) => {
    this.setState({ selectServices: selectedItems });
  };

  onCashbackChange = (value) => {
    const { translate } = this.props;
    this.setState({ cashback: value, cashbackError: '' }, () => {
      this.setState(({ cashback }) => ({ cashbackError: FishkaService.limitValidation(translate)(cashback) }));
    });
  };

  setCashbacks = async () => {
    const { translate } = this.props;
    const { selectServices, cashback } = this.state;

    try {
      await this.setState({ isLoading: true });
      const model = selectServices.map((currentService) => ({
        serviceId: currentService,
        cashbackPercent: cashback,
      }));

      await api.cashbackFishkaAdd(model);

      await message.success(translate(`page.fishkaService.cashbacksAddedSuccessfully`), 2);

      window.location.reload();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { serviceGroups, isLoading, selectCategoryId, selectServices, services, cashback, cashbackError } =
      this.state;
    const { translate, visible, closeModal } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={translate('page.fishkaService.cashbackAdditions')}
        className="AddCashbackDialog"
        footer={[
          <Button
            type="primary"
            key="submit"
            onClick={this.setCashbacks}
            loading={isLoading}
            disabled={!selectCategoryId || !selectServices.length || !!cashbackError || !cashback}
          >
            {translate('core.buttonTitles.create')}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <Row className="AddCashbackDialog-row">
          <Col span={5} sm={6} md={4}>
            {translate('page.fishkaService.serviceGroup')}
          </Col>
          <Col span={18} sm={16} md={18} offset={1}>
            <Select
              defaultValue={translate('page.fishkaService.categoryNotSelected')}
              dropdownStyle={{ zIndex: '1054' }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              onChange={this.handleSelectChange}
              showSearch
              className="CommissionProfiles-select"
            >
              {serviceGroups.map(({ ServiceGroupId, ServiceGroup }) => (
                <Option value={ServiceGroupId} key={`add-profile-modal-${ServiceGroupId}`}>
                  {ServiceGroup}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row className="AddCashbackDialog-row">
          <Col span={5} sm={6} md={4}>
            {translate('page.fishkaService.services')}
          </Col>
          <Col span={18} sm={16} md={18} offset={1}>
            <GridDropdown
              data={FishkaService.normalizeButtons(services)}
              colConfig={BUTTON_COLUMN_CONFIG}
              selectItems={selectServices}
              onSave={this.handleSelectServices}
              defaultTitle={translate('page.fishkaService.selectServices')}
            />
          </Col>
        </Row>

        <Row className="AddCashbackDialog-row">
          <Col span={5} sm={6} md={4}>
            {translate('page.fishkaService.col-cashback')}
          </Col>
          <Col span={18} sm={16} md={18} offset={1}>
            <InputNumber value={cashback} onChange={this.onCashbackChange} className="AddCashbackDialog-numberInput" />
          </Col>
        </Row>

        {!!cashbackError && (
          <div style={{ color: 'red' }} className="AddCashbackDialog-errorMessage">
            {cashbackError}
          </div>
        )}
      </Modal>
    );
  }
}

export default withTranslate(AddCashbackDialog);
