import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class GridAdmin extends Component {
  currentArray = findArray('admin');

  constructor(props) {
    super(props);
    this.state = {
      owners: [],
      currentOwners: [],
      isLoading: false,
      data: [],
    };
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { currentOwners } = this.state;
    if (currentOwners.length < 1) return;

    try {
      this.toggleLoader(true);
      const data = await api.postGetUsersList(currentOwners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };
  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  onOwners = (currentOwners) => {
    this.setState({ currentOwners }, this.makeQuery);
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, currentOwners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(owners)}
          selectItems={currentOwners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
      </Col>
    );
  };

  renderterminalIdOnclick = (props) => {
    return tabService.addTab({ type: 'restrictions', dataItem: props.dataItem });
  };

  render() {
    const { isLoading, data } = this.state;

    if (!checkElement('admin-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        selectedField="selected"
        name="adminPanel"
      >
        <GridColumn
          field="UserId"
          title="№"
          width="200"
          filter="numeric"
          {...(checkElement('admin-limits', this.currentArray)
            ? {
                onClick: (props) => this.renderterminalIdOnclick(props),
              }
            : {})}
        />

        <GridColumn field="Name" title="grids.adminPanel.column-name" width="150" />

        <GridColumn field="OwnerName" title="grids.adminPanel.column-ownerName" width="150" />

        <GridColumn field="FirstName" title="grids.adminPanel.column-firstName" width="150" />

        <GridColumn field="LastName" title="grids.adminPanel.column-lastName" width="150" />

        <GridColumn field="Address" title="grids.adminPanel.column-address" width="145" />

        <GridColumn field="Description" title="grids.adminPanel.column-description" width="145" />

        <GridColumn
          field="Active"
          title="grids.adminPanel.column-active"
          width="200"
          filter="boolean"
          cell={WrappedCellBoolean}
        />

        <GridColumn field="Phone" title="grids.adminPanel.column-phone" width="150" />

        <GridColumn field="Email" title="grids.adminPanel.column-email" width="150" />

        <GridColumn
          field="DatePost"
          title="grids.adminPanel.column-datePost"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(GridAdmin);
