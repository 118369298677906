import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row, Input } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@telerik/kendo-intl';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import DateRange from '../../../components/daterange';
import Grid from '../../../components/grids/baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

class GridContolTerminalEvents extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlSettings');
  }

  state = {
    isLoading: false,
    data: [],
    range: {
      start: null,
      end: null,
    },
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    const { range } = this.state;
    const { terminalId } = this.props;
    if (!range || !terminalId) return;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);
    try {
      this.toggleLoader(true);
      const model = {
        TerminalId: terminalId,
        DateStart: start,
        DateEnd: end,
      };
      const data = await api.terminalEvents(model);
      this.setState({ data: data.filter((el) => this.filterByDatePost(el)) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  changeDateSeconds = (formatedDate) => {
    const date = new Date(formatedDate);
    return date;
  };

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    if (el && el.DatePost) {
      const startDate = new Date(start).setMilliseconds(0);
      const endDate = new Date(end).setMilliseconds(0);
      const changedDate = this.changeDateSeconds(el.DatePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  buildToolbar = () => {
    const { translate, terminalId } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <DateRange onRange={this.setRange} />
          </Col>
          {checkElement('controlTerminalEvents-btn-search', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button
                  className="sm-w-100"
                  icon="search"
                  style={{ marginTop: '10px' }}
                  primary
                  onClick={this.makeQuery}
                >
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
        <div className="issued-certificates--toolbar aligned">
          <p>{translate('grids.certificates-issued.col-terminalId')}</p>
          <Input value={terminalId} disabled />
        </div>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="screenControlTerminalEvents-grid"
        permissionName="screenControlSettings"
      >
        <GridColumn field="EventId" title="grids.terminal-events.column-eventId" width="150" filter="numeric" />

        <GridColumn field="TypeName" title="grids.terminal-events.column-typeName" width="150" />

        <GridColumn field="Operation" title="grids.terminal-events.column-operation" width="150" />

        <GridColumn field="ServiceId" title="grids.terminal-events.column-serviceId" width="150" filter="numeric" />

        <GridColumn
          field="TransactionId"
          title="grids.terminal-events.column-transactionId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="ObjectName" title="grids.terminal-events.column-objectName" width="150" />

        <GridColumn field="ObjectId" title="grids.terminal-events.column-objectId" width="150" filter="numeric" />

        <GridColumn field="Message" title="grids.terminal-events.column-message" width="150" />

        <GridColumn field="ErrorCode" title="grids.terminal-events.column-errorCode" width="150" filter="numeric" />

        <GridColumn field="Account" title="grids.terminal-events.column-account" width="150" />

        <GridColumn
          field="DateInput"
          title="grids.terminal-events.column-dateInput"
          width="150"
          cell={({ dataItem: { DateInput } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DateInput)}</td>
          )}
          formatFilterCellData={(DateInput) => getTransformDateString(DateInput)}
        />

        <GridColumn
          field="DatePost"
          title="grids.terminal-events.column-datePost"
          width="150"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
      </Grid>
    );
  };
}

export default withTranslate(GridContolTerminalEvents);
