import { StorageService } from '../services';

export const getDefaultSelectedOwners = (currentPathName, search) => {
  const userInfo = StorageService.getItem('userInfo');

  const ownerIdFromSearch = new URLSearchParams(search).getAll('ownerId')[0];

  if (ownerIdFromSearch) {
    return [ownerIdFromSearch];
  }

  const ownerIdsFromStorage = userInfo ? StorageService.getItem(`${currentPathName}--${userInfo.UserId}`) : [];

  if (ownerIdsFromStorage && ownerIdsFromStorage.length) {
    return ownerIdsFromStorage;
  }

  return userInfo && userInfo.OwnerId ? [userInfo.OwnerId] : [];
};
