import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';

import checkElement from '../../../utils/checkElement';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import findArray from '../../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';

import './gridTerminalsConfig.styled.scss';

const api = new ApiService();

class GridTerminalsConfig extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlTerminalsConfig');

    this.state = {
      owners: [],
      isLoading: false,
      data: [],
      allOwners: [],
    };
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  makeQuery = async () => {
    const { owners } = this.state;
    const { showError } = this.props;
    if (owners.length < 1) return;

    try {
      this.toggleLoader();
      const data = await api.terminalsHardwareGet(owners);
      this.setState({ data });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { allOwners, owners } = this.state;
    const { translate } = this.props;
    return (
      <Col className="GridTerminalsConfig">
        <div className="GridTerminalsConfig-select">
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(allOwners)}
            selectItems={owners}
            onSave={(owners) => {
              this.setState({ owners });
            }}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isGetDefaultSelectItemsFromStorage
          />
        </div>

        <Button type="primary" onClick={this.makeQuery} disabled={!owners.length}>
          {translate('core.buttonTitles.search')}
        </Button>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;

    if (!checkElement('screenControlTerminalsConfig-grid', this.currentArray)) {
      return <></>;
    }

    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        selectedField="selected"
        toggleLoader={this.toggleLoader}
        name="screenControlTerminalsConfig-grid"
        // permissionName="screenControlTerminalsConfig"
      >
        <GridColumn field="OwnerId" title="grids.profilesMainProfileList.column-ownerId" width="200" filter="numeric" />

        <GridColumn field="OwnerName" title="grids.profilesMainProfileList.modal-create-template-dealer" width="150" />

        <GridColumn
          field="TerminalId"
          title="grids.terminalsMainProfileList.column-terminalId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="TerminalStatusName" title="grids.terminals.column-status" width="150" />

        <GridColumn field="AppVersion" title="grids.settingsAndEvents.column-versionPO" width="150" />

        <GridColumn
          field="DatePost"
          title="grids.profilesMainProfileList.column-dateOfChange"
          width="200"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />

        <GridColumn field="CityName" title="grids.terminals.column-city" width="145" />

        <GridColumn field="Address" title="grids.terminals.column-street" width="145" />

        <GridColumn
          field="BillacceptorFirmware"
          title="grids.screenControlTerminalsConfig.column-billAcceptorFirmware"
          width="200"
        />

        <GridColumn
          field="BillacceptorModel"
          title="grids.screenControlTerminalsConfig.column-billAcceptorModel"
          width="250"
        />

        <GridColumn
          field="BillacceptorPort"
          title="grids.screenControlTerminalsConfig.column-billAcceptorPort"
          width="250"
        />

        <GridColumn
          field="BillacceptorProtocol"
          title="grids.screenControlTerminalsConfig.column-billAcceptorProtocol"
          width="250"
        />

        <GridColumn
          field="BillacceptorSerial"
          title="grids.screenControlTerminalsConfig.column-billAcceptorNumber"
          width="250"
        />

        <GridColumn field="ProcessorModel" title="grids.screenControlTerminalsConfig.column-CPU" width="150" />

        <GridColumn field="Drives" title="grids.screenControlTerminalsConfig.column-ROM" width="150" />

        <GridColumn field="Memories" title="grids.screenControlTerminalsConfig.column-RAM" width="150" />

        <GridColumn field="Networks" title="grids.screenControlTerminalsConfig.column-network" width="150" />

        <GridColumn field="DisplayModel" title="grids.screenControlTerminalsConfig.column-displayModel" width="200" />

        <GridColumn field="DisplayType" title="grids.screenControlTerminalsConfig.column-displayType" width="200" />

        <GridColumn field="Monitors" title="grids.screenControlTerminalsConfig.column-monitorModel" width="200" />

        <GridColumn field="PrinterModel" title="grids.screenControlTerminalsConfig.column-printerModel" width="200" />

        <GridColumn field="PrinterPort" title="grids.screenControlTerminalsConfig.column-printerPort" width="200" />

        <GridColumn
          field="MotherboardModel"
          title="grids.screenControlTerminalsConfig.column-motherboardModel"
          width="200"
        />

        <GridColumn
          field="MotherboardSerial"
          title="grids.screenControlTerminalsConfig.column-motherboardSerialNumber"
          width="200"
        />

        <GridColumn field="TouchModel" title="grids.screenControlTerminalsConfig.column-touchscreenModel" width="200" />

        <GridColumn field="TouchPort" title="grids.screenControlTerminalsConfig.column-touchscreenPort" width="200" />

        <GridColumn
          field="NfcReaderModel"
          title="grids.screenControlTerminalsConfig.column-NFCReaderModel"
          width="200"
        />
      </Grid>
    );
  };
}

export default withRouter(withTranslate(GridTerminalsConfig));
