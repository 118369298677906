import React, { PureComponent } from 'react';
import { Alert, Button, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class DeleteModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteNBUCodes = async () => {
    const { selectedItems, onRefresh, closeModal } = this.props;

    try {
      await this.setState({ isLoading: true });
      await Promise.all(selectedItems.map((item) => this.deleteNBUCode(item)));
      this.setState({ isLoading: false });
      closeModal();
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }

    await this.setState({ isLoading: false });
  };

  deleteNBUCode = async ({ id, f108, otp }) => {
    const query = `serviceOtpEntityDelete(input: { serviceOtpEntity:{id: ${id}, f108: ${
      !f108 && f108 !== 0 ? null : `"${f108}"`
    }, otp: ${otp}} }){serviceOtp { id,f108,otp }}`;

    return await api.mutationByGraphQl(query);
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteNBUCodes}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button
            key="back"
            type="primary"
            onClick={closeModal}
            style={{ margin: variant === 'forbiddenDelete' ? '0 auto' : 'auto' }}
          >
            {translate(variant === 'forbiddenDelete' ? 'core.buttonTitles.ok' : 'core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {variant === 'forbiddenDelete' ? (
          <Alert message={translate('page.OTPServices.invalidDeleteDate')} type="error" showIcon />
        ) : (
          <div style={{ textAlign: 'center' }}>{translate('page.OTPServices.areYouSure')}</div>
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteModal);
