import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Select } from 'antd';

import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const { Option } = Select;
class GridServiceComissions extends PureComponent {
  constructor(props) {
    super(props);
    const { profileId } = props;
    this.state = {
      isLoading: false,
      data: [],
      profileId: profileId,
      dropdownData: [],
    };
    this.currentArray = findArray('commissionClient');
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
    // this.dropdownQuery();
  }

  makeQuery = async () => {
    const { profileId } = this.state;
    const { currentOwnerId } = this.props;

    if (!currentOwnerId) {
      return;
    }

    try {
      this.toggleLoader(true);
      const data = await api.getClientProfileServiceList(profileId);
      const dropdownData = await api.getOwnerProfiles(currentOwnerId);
      this.setState({ data, dropdownData });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  dropdownCallback = async () => {
    const { profileId } = this.state;
    try {
      this.toggleLoader(true);
      const data = await api.getClientProfileServiceList(profileId);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onSelectChange = (value) => {
    this.setState({ profileId: value }, this.dropdownCallback);
  };

  buildToolbar = () => {
    const { profileName, translate } = this.props;
    const { dropdownData } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {checkElement('clientComissionsByServices-select-profile', this.currentArray) && (
          <>
            <span style={{ marginRight: '10px' }}>{translate('grids.comission-services.profile-title')}</span>
            <Select
              defaultValue={profileName}
              className="modal-system-encashment--select"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              onChange={this.onSelectChange}
              style={{ minWidth: '300px' }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              showSearch
            >
              {dropdownData.map((elem, i) => (
                <Option value={elem.ProfileId} key={`service-collector-number-${i.toString()}`}>
                  {elem.ProfileName}
                </Option>
              ))}
            </Select>
          </>
        )}
        {/* <div className="issued-certificates--toolbar">
            <p>{translate('grids.commission-terminals.column-profile')} - <Input style={{ minWidth: '300px' }} value={profileName} disabled /></p>
          </div> */}
      </Col>
    );
  };

  renderCommissionNameOnclick = (props) => {
    return tabService.addTab({
      type: 'comissionDetails',
      dataItem: props.dataItem,
    });
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="clientComissionsByServices"
        permissionName="commissionClient"
      >
        <GridColumn field="ServiceId" title="grids.comission-services.column-serviceId" width="180" filter="numeric" />

        <GridColumn field="ServiceName" title="grids.comission-services.column-service-name" width="270" />

        <GridColumn
          field="CommissionName"
          title="grids.comission-services.column-comission-name"
          width="150"
          onClick={
            checkElement('clientComissionsByServices-commissionNameOnclick', this.currentArray)
              ? (props) => this.renderCommissionNameOnclick(props)
              : null
          }
          // onClick={(props) => this.renderCommissionNameOnclick(props)}
        />

        <GridColumn
          field="ValidDateFrom"
          title="grids.comission-services.column-date-start"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="ValidDateTo"
          title="grids.comission-services.column-date-end"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="Priority" title="grids.comission-services.column-priority" width="150" filter="numeric" />

        <GridColumn field="Rules" title="grids.comission-services.column-rule" width="150" />

        <GridColumn
          field="Active"
          title="grids.comission-services.column-active"
          width="150"
          cell={WrappedCellBoolean}
          filter="boolean"
        />

        <GridColumn
          field="DatePost"
          title="grids.commission-terminals.column-date"
          width="220"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="PaymentTypeName" title="page.commissionsServices.col-paymentType" width="220" />
      </Grid>
    );
  };
}

export default withTranslate(GridServiceComissions);
