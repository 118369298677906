import React, { PureComponent } from 'react';
import { Alert, Button, Col, message, Modal, Row } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { ALL_GRID_COLUMNS } from './constants';
import Input from '../../components/Input';

const api = new ApiService();

class AddNewItemModal extends PureComponent {
  state = {
    isLoading: false,
  };

  addNewItem = async () => {
    const { variant, onRefresh, closeModal, translate, formState } = this.props;
    const { paramName, id } = formState;

    const query =
      variant === 'newItem'
        ? `merchantCallbackItemEntityAdd(input:{itemEntity:{ id: null,  name: "${paramName}"}}){merchantCallbackItem{ id name }}`
        : `merchantCallbackItemEntityUpdate(input:{itemEntity:{ id: ${id},  name: "${paramName}"}}){merchantCallbackItem{ id name }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);

      closeModal();
      await onRefresh();
      this.setState({ isLoading: false });
      message.success(
        translate(
          variant === 'newItem'
            ? 'page.manageMerchantCallbacks.successfullyAddNew'
            : 'page.manageMerchantCallbacks.successfullyEditName',
        ),
        2.5,
      );
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    await this.setState({ isLoading: false });
  };

  validator = (translate) => (value) => {
    const regex = /^[a-zA-Z.]+$/;
    if (!regex.test(value)) return `${translate('page.manageMerchantCallbacks.error-name')}`;
  };

  renderField = (labelTranslateSlug, name, type) => {
    const { formState, onChangeFormState, onError, formErrors, translate } = this.props;

    return (
      <Row className="AddNewItemForm-row">
        <Col span={8} sm={6}>
          {translate(labelTranslateSlug)}
        </Col>
        <Col span={15} sm={17} offset={1}>
          <Input
            formState={formState}
            onChangeFormState={(name, value) => {
              onChangeFormState(name, value);
            }}
            formErrors={formErrors}
            setError={onError}
            type={type}
            name={name}
            validators={{
              [name]: this.validator(translate),
            }}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { translate, variant, closeModal, formState } = this.props;
    const { isLoading } = this.state;
    const { paramName } = formState;
    const { formErrors } = this.props;
    return (
      <Modal
        title={
          variant === 'newItem'
            ? translate('page.manageMerchantCallbacks.addNewParam')
            : translate('page.manageMerchantCallbacks.changeName')
        }
        visible={variant}
        onCancel={closeModal}
        footer={[
          <>
            <Button disabled={!paramName || formErrors.paramName} key="submit" type="primary" onClick={this.addNewItem}>
              {variant === 'newItem'
                ? translate('core.buttonTitles.add')
                : translate('page.manageMerchantCallbacks.button-save')}
            </Button>
            <Button key="back" onClick={closeModal}>
              {translate('owner.cancel')}
            </Button>
          </>,
        ]}
      >
        <div>{this.renderField('page.manageMerchantCallbacks.paramName', 'paramName', 'text')}</div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddNewItemModal);
