const screenControlSettings = (translate, value) => {
  switch (value) {
    case 'screenControlSettings-grid-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'screenControlSettings-grid-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'screenControlSettings-grid-AppVersion':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-versionPO')}`;
    case 'screenControlSettings-grid-OsVersion':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-versionOS')}`;
    case 'screenControlSettings-grid-TypeName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('owner.column-type')}`;
    case 'screenControlSettings-grid-DesignTypeName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.createTerminal.column-designType')}`;
    case 'screenControlSettings-grid-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-name')}`;
    case 'screenControlSettings-grid-PlacingName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('page.terminalSettings.field-placing')}`;
    case 'screenControlSettings-grid-TerminalDescription':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.adminPanel.column-description')}`;
    case 'screenControlSettings-grid-Note':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('page.terminalSettings.field-notes')}`;
    case 'screenControlSettings-grid-MonitorCount':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('page.terminalSettings.field-monitorCount')}`;
    case 'screenControlSettings-grid-TechnicianName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-technician')}`;
    case 'screenControlSettings-grid-Geopoint':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-geopoint')}`;
    case 'screenControlSettings-grid-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-branch')}`;
    case 'screenControlSettings-grid-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminalsMainProfileList.column-regionName')}`;
    case 'screenControlSettings-grid-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-city')}`;
    case 'screenControlSettings-grid-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-street')}`;
    case 'screenControlSettings-grid-Regime':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-workingHours')}`;
    case 'screenControlSettings-grid-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates.certificate-date-last-connect')}`;
    case 'screenControlSettings-grid-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-lastConnect')}`;
    case 'screenControlSettings-grid-LimitTransactions':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-transactionDayLimit')}`;
    case 'screenControlSettings-grid-LimitTransactionsNight':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-transactionNightLimit')}`;
    case 'screenControlSettings-grid-LimitTurns':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-turnoverDayLimit')}`;
    case 'screenControlSettings-grid-LimitTurnsNight':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-turnoverNightLimit')}`;
    case 'screenControlSettings-grid-СommissionProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.createTerminal.column-profileComissions')}`;
    case 'screenControlSettings-grid-Menu2ProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-menuProfile')}`;
    case 'screenControlSettings-grid-MainProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-idScreenProfile')}`;
    case 'screenControlSettings-grid-MainProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminalsMainProfileList.column-mainProfileId')}`;
    case 'screenControlSettings-grid-ProjectName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.createTerminal.column-project')}`;
    case 'screenControlSettings-grid-NetworkContractName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.createTerminal.column-networkContract')}`;
    case 'screenControlSettings-grid-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('owner.column-status')}`;
    case 'screenControlSettings-grid-StatusDetails':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.settingsAndEvents.column-statusDetails')}`;
    case 'screenControlSettings-grid-FiscalNumber':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-fiscalNumber')}`;
    case 'screenControlSettings-newTableLink-terminalProperties':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.newTerminals.tab-details')}`;
    case 'screenControlSettings-newTableLink-additionalSettings':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.terminalSettings.addtionalSettings-title')}`;
    case 'screenControlSettings-newTableLink-remoteSettings':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.terminalSettings.remoteSettings-title')}`;
    case 'screenControlSettings-newTableLink-terminalsEvents':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.tab-type-events')}`;
    case 'screenControlSettings-isShowDropdownTerminalId':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'screenControlSettings-btn-addRemoteSetting':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('grids.settingsAndEvents.btn-add')}`;
    case 'screenControlSettings-canConfigBanknotesNominal':
      return `${translate(`page.adminPanel.type-button`)}:
                                            ${translate('page.banknotesRestrictions.tab-banknotesGrid')}`;

    case 'screenControlSettings-canAddBanknotesNominal':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.banknotesRestrictions.tab-banknotesGrid')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.banknotesRestrictions.addNew')}`;

    case 'screenControlSettings-canDeleteBanknotesNominal':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.banknotesRestrictions.tab-banknotesGrid')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.banknotesRestrictions.delete')}`;

    case 'screenControlSettings-canUpdateBanknotesNominal':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.banknotesRestrictions.tab-banknotesGrid')},
                                  ${translate(`page.adminPanel.type-button`)}:
                                  ${translate('page.banknotesRestrictions.update')}`;

    case 'terminalDetails-btn-showAdditional':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.terminalSettings.btn-additionalSettings')}`;
    case 'screenControlSettings-fiscalizationInventory':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-block`)}:
              ${translate('page.terminalSettings.fiscalizationInventory-title')}`;
    case 'screenControlSettings-fiscalNumberInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-fiscalNumber')}`;
    case 'screenControlSettings-businessUnitInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-GOIdentifier')}`;
    case 'screenControlSettings-inventoryNumberInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-inventoryNumber')}`;
    case 'terminalDetails-btn-updateSettings':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}/${translate(
        'page.screenControlBusinessServices.button-save',
      )}`;
    case 'additionalSettings-btn-updateSettings':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.terminalSettings.addtionalSettings-title')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}/${translate(
        'page.screenControlBusinessServices.button-save',
      )}`;
    case 'remoteSettings-btn-updateSettings':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.terminalSettings.remoteSettings-title')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}/${translate(
        'page.screenControlBusinessServices.button-save',
      )}`;
    case 'controlTerminalEvents-btn-search':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.dashboard.tab-type-events')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'screenControlTerminalEvents-grid-EventId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-eventId')}`;
    case 'screenControlTerminalEvents-grid-TypeName':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-typeName')}`;
    case 'screenControlTerminalEvents-grid-Operation':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-operation')}`;
    case 'screenControlTerminalEvents-grid-ServiceId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-serviceId')}`;
    case 'screenControlTerminalEvents-grid-TransactionId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-transactionId')}`;
    case 'screenControlTerminalEvents-grid-ObjectName':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-objectName')}`;
    case 'screenControlTerminalEvents-grid-ObjectId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-objectId')}`;
    case 'screenControlTerminalEvents-grid-Message':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-message')}`;

    case 'screenControlTerminalEvents-grid-ErrorCode':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-errorCode')}`;

    case 'screenControlTerminalEvents-grid-Account':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-account')}`;

    case 'screenControlTerminalEvents-grid-DateInput':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-dateInput')}`;

    case 'screenControlTerminalEvents-grid-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-datePost')}`;
    default:
      return value;
  }
};

export default screenControlSettings;
