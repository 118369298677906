import { Input as CoreInput, InputNumber as CoreNumberInput, Checkbox } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import MaskedInput from 'antd-mask-input';
const { TextArea } = CoreInput;

export const COMPONENT_TYPE_MAP = new Map([
  ['number', CoreNumberInput],
  ['password', CoreInput.Password],
  ['checkbox', Checkbox],
  ['phone', MaskedInput],
  ['textArea', TextArea],
  ['datePicker', DatePicker],
]);
