import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

const api = new ApiService();

class ModalApprove extends PureComponent {
  state = {
    loading: false,
  };

  static propTypes = {
    dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
    chosen: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
  };

  terminalReboot = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
      };
      const response = await api.terminalReboot(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  terminalRestart = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
      };
      const response = await api.terminalRestart(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  terminalShutOff = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
      };
      const response = await api.terminalShutOff(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  terminalShutOn = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
      };
      const response = await api.terminalShutOn(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  terminalPowerOff = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
        Minutes: 3,
      };
      const response = await api.terminalPowerOff(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  unlockBillAcceptor = async () => {
    const {
      dataItem: { TerminalId },
      closeModal,
      showError,
      translate,
    } = this.props;
    if (!TerminalId) return;

    try {
      this.setState({ loading: true });
      await api.commandBillAcceptorUnlock(TerminalId);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  onHandleOk = () => {
    const { chosen } = this.props;
    switch (chosen) {
      case '1':
        this.terminalReboot();
        break;
      case '2':
        this.terminalRestart();
        break;
      case '3':
        this.terminalShutOff();
        break;
      case '4':
        this.terminalShutOn();
        break;
      case '5':
        this.terminalPowerOff();
        break;
      case '7':
        this.unlockBillAcceptor();
        break;
      default:
        return;
    }
  };

  render() {
    const { closeModal, visible, translate, content } = this.props;
    const { loading } = this.state;
    return (
      <Modal
        title={translate('page.dashboard.confirmation')}
        visible={visible}
        centered
        onOk={this.onHandleOk}
        onCancel={closeModal}
        zIndex={'999999'}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.dashboard.btn-cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={this.onHandleOk}>
            {translate('page.dashboard.btn-ok')}
          </Button>,
        ]}
      >
        <Col md={24}>{content}</Col>
      </Modal>
    );
  }
}

export default withTranslate(ModalApprove);
