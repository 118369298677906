import React, { PureComponent } from 'react';
import { Button, Col, Select, Input } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { SEARCH_TYPES } from './constants';
import { ApiService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';

const { Option } = Select;
const api = new ApiService();

class EasyPayUsers extends PureComponent {
  state = {
    isLoading: false,
    balanceList: [],
    searchType: undefined,
    searchQuery: '',
  };

  fetchAuthorizationBalanceInfo = async () => {
    const { searchType, searchQuery } = this.state;

    if (!searchType || !searchQuery) {
      return;
    }

    try {
      await this.setState({ isLoading: true });

      const balance = await api.authorizationBalanceInfo({
        value: searchQuery,
        searchType: searchType.split('-')[0],
      });

      this.setState({
        isLoading: false,
        balanceList: balance
          ? [
              {
                ...balance,
                SearchType: searchType.split('-')[1],
                SearchQuery: searchQuery,
              },
            ]
          : [],
      });
    } catch (err) {
      const { showError } = this.props;
      showError(err);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { searchType, searchQuery } = this.state;

    return (
      <Col className="EasyPayUsers-toolbar">
        <div className="EasyPayUsers-fieldRow">
          <div className="EasyPayUsers-label">{translate('page.easyPayUsers.searchType')}</div>
          <Select
            value={searchType}
            placeholder={translate('page.easyPayUsers.selectSearchType')}
            className="EasyPayUsers-select"
            onChange={(searchType) => {
              this.setState({ searchType, balanceList: [] });
            }}
          >
            {SEARCH_TYPES.map(({ translateTitle, title, id }) => (
              <Option value={`${id}-${title || translate(translateTitle)}`} key={id}>
                {title || translate(translateTitle)}
              </Option>
            ))}
          </Select>
        </div>

        <div className="EasyPayUsers-fieldRow">
          <div className="EasyPayUsers-shortLabel">{translate('page.easyPayUsers.value')}</div>
          <Input
            placeholder={translate('page.easyPayUsers.query')}
            className="EasyPayUsers-input"
            onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery, balanceList: [] })}
          />
        </div>

        <Button type="primary" disabled={!searchType || !searchQuery} onClick={this.fetchAuthorizationBalanceInfo}>
          {translate('core.buttonTitles.search')}
        </Button>
      </Col>
    );
  };

  render() {
    const { balanceList, isLoading } = this.state;

    return (
      <Grid
        data={balanceList}
        isLoading={isLoading}
        name="EasyPayBalanceGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAuthorizationBalanceInfo}
      >
        <GridColumn field="SearchType" title="page.easyPayUsers.searchType" width="200px" />
        <GridColumn field="SearchQuery" title="page.easyPayUsers.value" width="300px" />
        <GridColumn field="Saldo" title="page.easyPayUsers.saldo" width="140px" />
        <GridColumn field="OverDraft" title="page.easyPayUsers.overdraft" width="160px" />
        <GridColumn field="Current" title="page.easyPayUsers.current" width="160px" />
        <GridColumn
          field="DatePost"
          title="page.easyPayUsers.dateResult"
          width="240px"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
      </Grid>
    );
  }
}

export default withTranslate(EasyPayUsers);
