export const INITIAL_VALUES = {
  commissionPercent: 0,
  commissionMin: 0,
  commissionMax: 0,
  commissionCalculationBase: '[amount_total]',
  commissionCardPercent: 0,
  commissionCardMin: 0,
  commissionCardMax: 0,
  commissionCardCalculationBase: '[amount_total]',
};
