import React, { PureComponent, createContext } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { message } from 'antd';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

const UpdateMenuElementsContext = createContext({});

class UpdateMenuElementsContextContainer extends PureComponent {
  currentArray = findArray('managementMenuProfiles');
  state = {
    isLoading: false,
    profileStaffForUpdating: undefined,
    selectedElements: [],
  };

  handleSetProfileStaffForUpdating = (profileStaffForUpdating) => {
    this.setState({ profileStaffForUpdating });
  };

  getUpdateVisibleProps = (profileId, menuId, isVisible, handleUpdateGird) => async () => {
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const data = await api.getByGraphQl(
        `menuProperties(take: 500,skip: 0,order: {name: ASC}, where:{and: [ {name: {in: ["Visible"]}},{profileId: {in: [${profileId}]}},{menuId:{in: [${menuId}]}}]}){items{name}} `,
      );

      const isExist =
        data &&
        data.data &&
        data.data.menuProperties &&
        data.data.menuProperties.items &&
        data.data.menuProperties.items.length;
      const method = isExist ? api.menuPropertiesUpdate : api.menuPropertiesInsert;

      await method({ profileId, menuId, name: 'Visible', value: isVisible, language: '' });
      handleUpdateGird(isVisible, menuId);
      message.success(translate('page.profilesMenu.propertyUpdatedSuccessfully'), 1.5);
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchChildMenuElements = async (profileId, parentId, handleUpdateGird) => {
    const { defaultSelectedElements = [] } = this.props;

    try {
      const query = `menu(take: 2500, skip: 0,order: [{ profileId: ASC }, { menuId: ASC }], where: {and:[{profileId: {in: [${profileId}]}},{parentId:{ eq:${parentId}}}]}){items{profileId, menuId, name, isVisible, hasChild, refId}pageInfo{hasNextPage, hasPreviousPage}}`;
      await this.setState({ isLoading: true });
      const detailsData = await api.getByGraphQl(query);
      const data =
        detailsData &&
        detailsData.data &&
        detailsData.data.menu &&
        detailsData.data.menu.items &&
        detailsData.data.menu.items.length
          ? detailsData.data.menu.items.map((item) => ({
              ...item,
              parentId,
              id: `${item.profileId}-${item.menuId}`,
              selected: defaultSelectedElements.includes(`${item.profileId}-${item.menuId}`),
            }))
          : [];

      handleUpdateGird({ profileId, parentId }, data);
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  setSelectedElements = (touchedElement) => {
    this.setState(({ selectedElements }) => {
      const isSelectedItemAlreadyExist = selectedElements.some(({ id }) => id === touchedElement.id);

      if (
        (isSelectedItemAlreadyExist && !touchedElement.selected) ||
        (!isSelectedItemAlreadyExist && touchedElement.selected)
      ) {
        return { selectedElements };
      }

      return {
        selectedElements:
          !isSelectedItemAlreadyExist && !touchedElement.selected
            ? [...selectedElements, touchedElement]
            : selectedElements.filter(({ id }) => id !== touchedElement.id),
      };
    });
  };

  putSelectedElements = (selectedElements) => {
    this.setState({ selectedElements });
  };

  handleSetActionWithCheck = async ({
    profileId,
    menuId,
    currentMenuName,
    onSuccessfulUpdateCallback,
    variant,
    isVisible,
  }) => {
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const checkResponse = await api.menuPropertiesCheck({
        profileId,
        menuId,
      });

      if (variant === 'setIsVisible' && isVisible) {
        await this.getUpdateVisibleProps(profileId, menuId, isVisible, onSuccessfulUpdateCallback)();
        await this.setState({ isLoading: false });
        return;
      }

      this.handleSetProfileStaffForUpdating({
        variant,
        title:
          variant === 'deleteElement'
            ? `${translate('page.profilesMenu.modal-title-elementDelete')} ${currentMenuName}`
            : `${translate('page.profilesMenu.changeIsVisibleForElement')} ${currentMenuName}`,
        onPerformActionCallback:
          variant === 'deleteElement'
            ? this.getHandleDelete({ profileId, menuId, onSuccessfulUpdateCallback })
            : this.getUpdateVisibleProps(profileId, menuId, isVisible, onSuccessfulUpdateCallback),
        message: checkResponse.Message,
        areYouSureTitle:
          variant === 'deleteElement'
            ? translate('page.profilesMenu.areYouSureDeletePerformAction')
            : translate('page.profilesMenu.areYouSureDeactivatePerformAction'),
        buttonTitle:
          variant === 'deleteElement' ? translate('core.buttonTitles.delete') : translate('core.buttonTitles.change'),
      });

      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getHandleDelete =
    ({ profileId, menuId, onSuccessfulUpdateCallback }) =>
    async () => {
      const { translate } = this.props;

      try {
        await this.setState({ isLoading: true });
        await api.menuDelete({
          profileId,
          menuId,
        });
        onSuccessfulUpdateCallback();
        message.success(translate('page.profilesMenu.success-elementDeleted'), 2.5);
        await this.setState({ isLoading: false });
      } catch (e) {
        const { showError } = this.props;
        showError(e);
        this.setState({ isLoading: false });
      }
    };

  render() {
    const { children, withSelect, defaultSelectedElements } = this.props;
    const { isLoading, profileStaffForUpdating, selectedElements } = this.state;

    return (
      <UpdateMenuElementsContext.Provider
        value={{
          isLoading,
          fetchChildMenuElements: this.fetchChildMenuElements,
          profileStaffForUpdating,
          handleSetProfileStaffForUpdating: this.handleSetProfileStaffForUpdating,
          updateVisibleProps: this.updateVisibleProps,
          handleSetActionWithCheck: this.handleSetActionWithCheck,
          permissions: {
            isShowElementOptions: checkElement('managementMenuProfiles-isShowElementOptions', this.currentArray),
            updateElementName: checkElement('managementMenuProfiles-updateElementName', this.currentArray),
            createElement: checkElement('managementMenuProfiles-createElement', this.currentArray),
            cloneElement: checkElement('managementMenuProfiles-cloneElement', this.currentArray),
            deleteElement: checkElement('managementMenuProfiles-deleteElement', this.currentArray),
            moveElement: checkElement('managementMenuProfiles-moveElement', this.currentArray),
            redirectElementPropsTab: checkElement('managementMenuProfiles-redirectElementPropsTab', this.currentArray),
          },
          setSelectedElements: this.setSelectedElements,
          putSelectedElements: this.putSelectedElements,
          selectedElements,
          setIsLoading: (isLoading) => {
            this.setState({ isLoading });
          },
          withSelect,
          defaultSelectedElements,
        }}
      >
        {children}
      </UpdateMenuElementsContext.Provider>
    );
  }
}

export default withTranslate(UpdateMenuElementsContextContainer);

export const widthUpdateMenuElementsContext = (Component) => (props) =>
  (
    <UpdateMenuElementsContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </UpdateMenuElementsContext.Consumer>
  );
