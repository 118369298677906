export const INITIAL_VALUES = {
  supplierId: '',
  name: '',
  changeDate: null,
  EDRPOU: '',
  address: '',
  phone: '',
  email: '',
  fax: '',
  webAddress: '',
  description: '',
  region: null,
  isAffiliation: false,
  riskLevel: null,
  director: [],
  beneficiary: [],
};

export const DIRECTORS_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: 'Id',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    width: '360',
    titleTranslateSlug: 'page.serviceSupplier.fullName',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Inn',
    width: '160',
    titleTranslateSlug: 'page.serviceSupplier.ipn',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];

export const PROPS_FIELDS = [
  'datePost',
  'address',
  'email',
  'phone',
  'fax',
  'website',
  'description',
  'regionId',
  'affiliationRar',
  'finMonRiskId',
  'suppliersCeoId',
  'suppliersBeneficiaryId',
];
