const ServiceLimits = (translate, value) => {
  switch (value) {
    case 'serviceLimits-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'serviceLimits-addLimit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'serviceLimits-deleteLimit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
    case 'serviceLimits-changeLimit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    default:
      return value;
  }
};

export default ServiceLimits;
