import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, StorageService } from '../../../services';
import { Button, Row } from 'antd';
import GqlService from '../../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constnatns';
import PaymentTypesDictionaryService from './PaymentTypesDictionaryService';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';
import checkElement from '../../../utils/checkElement';
import EditableGridInput from '../../../components/grids/EditableGridInput';
import findArray from '../../../utils/findArrForCurrentPage';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import CellBoolean from '../../account/cellBoolean';

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const api = new ApiService();

class PaymentTypesDictionaryGrid extends PureComponent {
  currentArray = findArray('dictionaryPaymentTypes');
  state = {
    paymentTypes: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    currentEditRow: null,
  };

  componentDidMount() {
    this.fetchPaymentTypesGQL();
  }

  fetchPaymentTypesGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString } = this.state;

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('paymentTypesDictionaryGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{code: ASC}'
      }${filtersString ? `,where:{and:[${filtersString}]}` : ''}`;

      const query = `paymentTypeList(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { paymentTypes, hasPreviousPage, hasNextPage } = PaymentTypesDictionaryService.getGQLResponse(data);

      this.setState({ paymentTypes, hasPreviousPage, hasNextPage, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onFieldsConfigChange = (list) => {
    this.fetchPaymentTypesGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        currentEditRow: null,
      });

      await this.fetchPaymentTypesGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleAdd = () => {
    const { paymentTypes } = this.state;
    const emptyItem = {
      paymentType: null,
      code: null,
      serviceId: null,
      name: null,
      description: null,
      datePost: new Date(),
      isUsed: false,
      editId: '-1',
    };

    this.setState({
      paymentTypes: [emptyItem, ...paymentTypes],
      currentEditRow: emptyItem,
    });
  };

  updateNBUCodes = async () => {
    const { currentEditRow } = this.state;
    const { paymentType, code, serviceId, name, description, datePost, isUsed, editId } = currentEditRow;

    const query = `${
      editId === '-1' ? 'paymentTypeEntityAdd' : 'paymentTypeEntityUpdate'
    }(input: { paymentTypeEntity:{ paymentType: "${paymentType}", code: ${code}, serviceId: ${
      serviceId || 0
    } , name: "${name}" , description: "${description}" , datePost: "${new Date(
      datePost,
    ).toISOString()}" , isUsed: ${isUsed}} }){paymentTypes { paymentType, code, serviceId, name, description, datePost, isUsed }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchPaymentTypesGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow } = this.state;

    return (
      <Row>
        {checkElement('dictionaryPaymentTypes-addItem', this.currentArray) && (
          <Button type="primary" onClick={this.handleAdd} disabled={currentEditRow}>
            {translate('core.buttonTitles.add')}
          </Button>
        )}

        {checkElement('dictionaryPaymentTypes-saveItem', this.currentArray) && (
          <Button type="primary" disabled={!currentEditRow} onClick={this.updateNBUCodes}>
            {translate('core.buttonTitles.save')}
          </Button>
        )}
      </Row>
    );
  };

  checkboxCell = ({ dataItem, field }) => {
    const { currentEditRow } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return (
      <td align="center" style={{ textAlign: 'center' }} className="OTPServicesGrid-checkboxCell">
        <EditableGridInput
          type={'checkbox'}
          disabled={
            (currentEditRow && currentEditRow.editId !== dataItem.editId) ||
            field === 'Id' ||
            !checkElement('dictionaryPaymentTypes-saveItem', this.currentArray)
          }
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    );
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow } = this.state;
    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return checkElement('dictionaryPaymentTypes-saveItem', this.currentArray) ? (
      <td align="center">
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.editId !== dataItem.editId) || field === 'Id'}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    ) : (
      <td>{currentDataItem[field]}</td>
    );
  };

  render() {
    const { paymentTypes, isLoading, pageValue, hasNextPage, hasPreviousPage } = this.state;

    if (!checkElement('dictionaryPaymentTypes-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={paymentTypes}
        onRefresh={this.fetchPaymentTypesGQL}
        toolbar={() => {}}
        isLoading={isLoading}
        name="paymentTypesDictionaryGrid"
        pageChange={this.getStateSetterByName('pageValue')}
        handleRowAmountChange={this.handleRowAmountChange}
        setFiltersString={this.getStateSetterByName('filtersString')}
        setSortingString={this.getStateSetterByName('sortString')}
        pageValue={pageValue}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onFieldsConfigChange={this.onFieldsConfigChange}
        isGQL
      >
        <GridColumn
          field="code"
          title="page.dictionaryPaymentType.col-code"
          width="80px"
          filter="numeric"
          // cell={this.cellInput}
        />
        <GridColumn
          field="paymentType"
          title="grids.managementPaymentTypes.col-payment-type"
          width="120px"
          // cell={this.cellInput}
        />
        <GridColumn
          field="name"
          title="page.dictionaryPaymentType.col-name"
          width="150px"
          // cell={this.cellInput}
        />
        <GridColumn
          field="description"
          title="page.dictionaryPaymentType.col-description"
          width="400px"
          // cell={this.cellInput}
        />
        <GridColumn
          field="serviceId"
          title="page.dictionaryPaymentType.col-serviceId"
          width="400px"
          // cell={this.cellInput}
        />
        <GridColumn
          field="isUsed"
          title="page.dictionaryPaymentType.col-active"
          width="120px"
          filter="boolean"
          cell={WrappedCellBoolean}
          // cell={this.checkboxCell}
        />
        <GridColumn
          field="datePost"
          title="page.dictionaryPaymentType.col-datePost"
          width="180px"
          cell={({ dataItem: { datePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(datePost)}</td>
          )}
          formatFilterCellData={(datePost) => getTransformDateString(datePost)}
        />
      </Grid>
    );
  }
}

export default withTranslate(PaymentTypesDictionaryGrid);
