import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import MFOTransactionsGrid from './MFOTransactionsGrid';

class MFOTransactionsPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-mfoTransactionsPage',
        content: <MFOTransactionsGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} />;
  }
}

export default MFOTransactionsPage;
