export const ALL_GRID_COLUMNS = [
  'terminalId',
  'appVersion',
  'osVersion',
  'typeName',
  'designTypeName',
  'terminalName',
  'fiscalNumber',
  'placingName',
  'terminalDescription',
  'note',
  'monitorCount',
  'technicianName',
  'geopoint',
  'branch',
  'regionName',
  'cityName',
  'terminalAddress',
  'regime',
  'dateLastConnect',
  'dateLastTransaction',
  'limitTransactions',
  'limitTransactionsNight',
  'limitTurns',
  'limitTurnsNight',
  'commissionProfileName',
  'menuProfileName',
  'mainProfileId',
  'mainProfileName',
  'projectName',
  'networkContractName',
  'statusName',
  'statusDetails',
  'statusId',
];
export const NORMALIZE_FIELD_MAP = new Map([
  ['Menu2ProfileName', 'menuProfileName'],
  ['СommissionProfileName', 'commissionProfileName'],
]);
export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([
  ['menuProfileName', 'Menu2ProfileName'],
  ['commissionProfileName', 'СommissionProfileName'],
]);
