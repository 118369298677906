import React, { PureComponent } from 'react';
import BlockedTransactionsPage from './BlockedTransactionsPage';
import PageTabs from '../../components/tabber/tabber';
import AddNewServices from '../ServiceManagement/ExceptionsFinMonLimit/AddNewServices';
import SearchSanctionsTerrorist from './SearchSanctionsTerrorist/SearchSanctionsTerrorist';
import SUO from './SUO';

class BlockedTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-blockedTransactions',
          content: <BlockedTransactionsPage />,
          closable: false,
          key: '0',
        },
      ],
    };
  }
  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'searchSanctionsTerrorist':
        tabs.push({
          title: 'page.dashboard.tab-search-lists-sanctioners',
          content: <SearchSanctionsTerrorist />,
          closable: true,
          key: key,
        });
        break;

      case 'SUO':
        tabs.push({
          title: 'page.blockedTransactions.SUOList',
          content: <SUO />,
          closable: true,
          key: key,
        });
        break;
    }
  };
  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
  }
}

export default BlockedTransactions;
