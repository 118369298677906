import React, { PureComponent } from 'react';

import UpdateModal from './UpdateModal';
import FormStateContainer from '../../../../components/FormStateContainer';

class UpdateModalContainer extends PureComponent {
  render() {
    const { selectedItem, visible, closeModal, onRefresh } = this.props;

    const {
      СommissionDealer: DealerCommissionPercent,
      MinСommissionDealer: DealerCommissionMin,
      MaxСommissionDealer: DealerCommissionMax,
      DealerCommissionClient: DealerCommissionClientPercent,
      DealerСommissionClientMin: DealerCommissionClientMin,
      DealerСommissionClientMax: DealerCommissionClientMax,
      PaymentSystemСommission: PaymentSystemCommissionPercent,
      PaymentSystemСommissionMin: PaymentSystemCommissionMin,
      PaymentSystemСommissionMax: PaymentSystemCommissionMax,
      PaymentSystemCommissionClient: PaymentSystemCommissionClientPercent,
      PaymentSystemСommissionClientMin: PaymentSystemCommissionClientMin,
      PaymentSystemСommissionClientMax: PaymentSystemCommissionClientMax,
    } = selectedItem;

    return (
      <FormStateContainer
        initialValues={{
          DealerCommissionPercent,
          DealerCommissionMin,
          DealerCommissionMax,
          DealerCommissionClientPercent,
          DealerCommissionClientMin,
          DealerCommissionClientMax,
          PaymentSystemCommissionPercent,
          PaymentSystemCommissionMin,
          PaymentSystemCommissionMax,
          PaymentSystemCommissionClientPercent,
          PaymentSystemCommissionClientMin,
          PaymentSystemCommissionClientMax,
        }}
      >
        <UpdateModal visible={visible} closeModal={closeModal} selectedItem={selectedItem} onRefresh={onRefresh} />
      </FormStateContainer>
    );
  }
}

export default UpdateModalContainer;
