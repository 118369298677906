import React, { PureComponent } from 'react';
import TemplateServicesGrid from './TemplateServicesGrid';
import PageTabs from '../../components/tabber/tabber';

class TemplateServices extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.serviceTemplates.pageName',
        content: <TemplateServicesGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} />;
  }
}

export default TemplateServices;
