import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import ProfileMenuElements from '../../components/ProfileMenuElements';
import { widthUpdateMenuElementsContext } from '../../components/ProfileMenuElements/UpdateMenuElementsContext';
import LoadingPanel from '../../components/loader';

import './NavigationMenuPropGrid.styled.scss';

class MenuElementDialog extends PureComponent {
  componentDidMount() {
    const { fullDefaultSelectedElements, putSelectedElements } = this.props;

    if (fullDefaultSelectedElements.length) {
      putSelectedElements(fullDefaultSelectedElements);
    }
  }

  render() {
    const { visible, closeDialog, translate, isLoading, selectedElements, saveSelectedElements } = this.props;

    return (
      <Modal
        className="NavigationMenuPropGrid-selectProfileDialog"
        title={translate('page.navigationMenuProp.selectElements')}
        visible={visible}
        onCancel={closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            disabled={!selectedElements.length}
            onClick={() => {
              saveSelectedElements(selectedElements);
              closeDialog();
            }}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <ProfileMenuElements dataItem={[{ profileId: 1, parentHistorySlug: '' }]} />
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(widthUpdateMenuElementsContext(MenuElementDialog));
