const menuElementPropDictionary = (translate, value) => {
  switch (value) {
    case 'menuElementPropDictionary-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'menuElementPropDictionary-add':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.add')}`;
    case 'menuElementPropDictionary-change':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.change')}`;
    case 'menuElementPropDictionary-delete':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.delete')}`;
    default:
      return value;
  }
};

export default menuElementPropDictionary;
