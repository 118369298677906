import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';
import { NORMALIZE_RESPONSE_FIELD_MAP } from './constants';

class CreateTerminalListService {
  normalizeTerminalsList(terminalList) {
    return terminalList.length
      ? terminalList.map((item) =>
          Object.entries(item).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [NORMALIZE_RESPONSE_FIELD_MAP.get(key) || uppercaseFirstLetter(key)]: value,
            }),
            {},
          ),
        )
      : [];
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.terminalList) {
      return { terminalListService: [], hasNextPage: false, hasPreviousPage: false };
    }
    const terminalList = data.data.terminalList;

    const terminalListService =
      terminalList.items && terminalList.items.length ? this.normalizeTerminalsList(terminalList.items) : [];

    const pageInfo = terminalList.pageInfo;

    return {
      terminalListService,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}
export default new CreateTerminalListService();
