export const HANDLERS = [
  // {
  //   title:  'page.servicesBigTable.tab-commissions',
  //   type: 'commissions',
  //   webTag: ''
  // },
  {
    title: 'page.servicesBigTable.tab-basic-properties',
    type: 'basicProperties',
    dropdown: false,
    webTag: 'serviceManagement-serviceMainProps',
  },
  {
    title: 'page.servicesBigTable.tab-additional-properties',
    type: 'additionalProperties',
    dropdown: false,
    webTag: 'serviceManagement-serviceAdditionalProps',
  },
  {
    title: 'page.servicesBigTable.tab-additional-limits',
    type: 'additionalLimits',
    dropdown: false,
    webTag: 'serviceManagement-serviceAdditionalLimits',
  },
  {
    title: 'page.servicesBigTable.tab-bank-details',
    type: 'bankDetails',
    dropdown: false,
    webTag: 'serviceManagement-bankDetails',
  },
  {
    title: 'page.serviceEndpoints.title',
    type: 'serviceEndpoints',
    dropdown: false,
    webTag: 'serviceManagement-serviceEndpoints',
  },
  {
    title: 'page.supplierCommissions.tab-supplierCommissions',
    type: 'supplierCommissions',
    dropdown: false,
    webTag: 'serviceManagement-supplierCommissionsSimple',
  },
  {
    title: 'page.supplierCommissions.tab-supplierCommissions-scale',
    type: 'supplierCommissionsScale',
    dropdown: false,
    webTag: 'serviceManagement-supplierCommissionsScale',
  },
  {
    title: 'page.supplierCommissions.tab-commissions-tech-partner',
    type: 'commissionsTechPartner',
    dropdown: false,
    webTag: 'serviceManagement-commissionsTechPartners',
  },
];

export const ALL_COLUMNS_GRID = [
  'serviceId',
  'name',
  'groupName',
  'typeName',
  'supplierName',
  'statusName',
  'originalStatusName',
  'rootServiceId',
  'refId',
  'f108',
  'commissionClient',
  'serviceCharactId',
  'sodName',
  'managerName',
  'salesManagerName',
  'supportManagerName',
  'amountMin',
  'amountMax',
  'f108Description',
  'dateFirstTransaction',
  'dateLastTransaction',
  'psAccountName',
  'transitService',
  'isScale',
];
