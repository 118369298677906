export const recalculationTransaction = (translate, value) => {
  switch (value) {
    case 'recalculationTransactions-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'recalculationTransactions-btnAdd':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.add')}`;
    case 'recalculationTransactions-btnDelete':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.delete')}`;
    case 'recalculationTransactions-btnCreateCommission':
      return `${translate('page.adminPanel.type-button')}: ${translate('core.buttonTitles.create-transaction')}`;
    default:
      return value;
  }
};
