// Бек зберігає профілі з елементами у вигляді зв'язного списку де кожен профіль має лінку на свій батьківський профіль(по суті це
// лінійне представлення дерева). На елементах профілів існують властивості і їх значення. Якщо з'ясовуєтся що властивості не існує в
// поточному профілі, але вона існує в одному з батьківських профілів - вважаєтся що її значення для поточного профілю успадковуются від батьківського
// профіля в якому воно є. Це є умовною угодою Властивість яка справді існує в бд - вважается перевизначеною, властивість якої не існує в бд - але за
// умовною угодою її значення успадковуется від батьківського профілю вважается не визначеною. Оскільки не визначених валстивостей не має в бд, але користувачу
// іх відображати протрібно - фрон вираховує їх згідно умовної угоди.
import React, { PureComponent } from 'react';
import { Button, Input, Row, Select } from 'antd';
import { Button as ModalButton } from '@progress/kendo-react-buttons';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import CellBoolean from '../../account/cellBoolean';
import { UpdateMenuProfileContext } from '../../../components/MenuProfiles';
import MenuProfileDialog from '../../NavigationMenuProp/MenuProfileDialog';
import { PROP_SYSTEM_TYPES } from '../../MenuElementPropDictionary/constants';
import { LANGUAGES } from '../../../components/ElementProps/constants';
import { ApiService, StorageService, tabService } from '../../../services';
import { ALL_GRID_COLUMNS } from './constants';
import NavigationMenuElementsGridService from './NavigationMenuElementsGrid.service';

import './NavigationMenuElementsGrid.styled.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const { Option } = Select;
const api = new ApiService();
const { TextArea } = Input;

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class NavigationMenuElementsGrid extends PureComponent {
  currentArray = findArray('managementMenuProfiles');

  state = {
    type: '',
    selectedProfiles: [],
    selectedSystemType: PROP_SYSTEM_TYPES,
    selectLanguage: LANGUAGES,
    propVariants: [],
    selectedPropVariant: undefined,
    propValue: '',
    elements: [],
    isLoading: false,
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchElementProps();
  }

  fetchElementProps = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo || !userInfo.OwnerId) {
      return [];
    }

    try {
      await this.setState({ isLoading: true });
      const propVariants = await api.getMenuPropertiesKeys(userInfo.OwnerId, false);
      await this.setState({ isLoading: false, propVariants });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getQuery = (isSearchForDefined) => {
    const { selectedProfiles, selectedSystemType, selectLanguage, selectedPropVariant, propValue } = this.state;
    // isDefined - флаг шо каже шукати всі перевизначені властивості, тож ми робимо пошук по будь-якому
    // значенню властивості по профілям і батьківських профілях

    return `order:{ menuId:ASC},where:{and:[{profileId:{in:[${
      isSearchForDefined
        ? // Не має сенсу шукати по 1 профілю для isDefined оскільки там всі значення первизначені
          `${selectedProfiles[0].parentHistorySlug
            .split(',')
            .filter((char) => char !== '1')
            .join(',')},`
        : `${selectedProfiles[0].parentHistorySlug},`
    }${selectedProfiles[0].profileId}]}},{name:{in:["${selectedPropVariant}"]}},${
      isSearchForDefined ? '' : `{value:{eq:"${propValue || ''}"}},`
    }
    {language:{or:[${selectLanguage
      .map((language) => `{contains: "${language}"}`)
      .join(',')}, {eq: ""}]}}, {system:{or:[${selectedSystemType
      .map((type) => `{contains: "${type}"}`)
      .join(',')}, {eq: ""}]}}]}`;
  };

  fetchAllMenuPropsGQL = async () => {
    const { selectedProfiles, selectedSystemType, selectedPropVariant } = this.state;

    if (!selectedProfiles.length || !selectedSystemType.length || !selectedPropVariant) {
      return;
    }

    const argumentsString = this.getQuery();
    const argumentsStringWByDefinedProps = this.getQuery(true);

    try {
      await this.setState({ isLoading: true });
      const props = await this.fetchMenuPropsGQL(0, [], argumentsString);

      if (selectedProfiles[0].profileId === 1) {
        await this.setState({
          isLoading: false,
          elements: NavigationMenuElementsGridService.calcElements(props, [], selectedProfiles),
        });

        return;
      }

      const allDefiniteProps = await this.fetchMenuPropsGQL(0, [], argumentsStringWByDefinedProps);

      await this.setState({
        isLoading: false,
        elements: NavigationMenuElementsGridService.calcElements(props, allDefiniteProps, selectedProfiles),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchMenuPropsGQL = async (skip, elementList, argumentsString) => {
    const query = `menuExtendedProperties(take: 500,skip:${skip},${argumentsString}){items{${ALL_GRID_COLUMNS.join(
      ',',
    )}} pageInfo{hasNextPage, hasPreviousPage}}`;

    try {
      const data = await api.getByGraphQl(query);

      const { props, hasNextPage } = NavigationMenuElementsGridService.getGQLResponse(data);

      return hasNextPage
        ? this.fetchMenuPropsGQL(skip + 500, [...elementList, ...props], argumentsString)
        : [...elementList, ...props];
    } catch (e) {
      throw e;
    }
  };

  buildToolbar = () => {
    const { selectedProfiles, selectedSystemType, selectLanguage, selectedPropVariant, propVariants, propValue } =
      this.state;
    const { translate } = this.props;

    const isDisable = !selectedProfiles.length || !selectedSystemType.length || !selectedPropVariant;

    return (
      <Row className="NavigationMenuElementsGrid-toolbar">
        <div className="NavigationMenuElementsGrid-fields">
          <div className="NavigationMenuElementsGrid-row">
            <div className="NavigationMenuElementsGrid-label">{translate('page.navigationMenuProp.profile')}</div>

            <ModalButton
              className="NavigationMenuElementsGrid-modalButton"
              onClick={() => {
                this.setState({ modalVariant: 'selectProfiles' });
              }}
            >
              {selectedProfiles.length
                ? selectedProfiles.map(({ name }) => name).join(', ')
                : translate('page.navigationMenuProp.selectProfiles')}
            </ModalButton>
          </div>

          <div className="NavigationMenuElementsGrid-row">
            <div className="NavigationMenuElementsGrid-label">{translate('page.profilesMenu.language')}</div>
            <Select
              value={selectLanguage}
              placeholder={translate('page.profilesMenu.selectLanguage')}
              className="NavigationMenuElementsGrid-selectLanguage"
              mode="multiple"
              onChange={(value) => {
                this.setState({ selectLanguage: value, elements: [] });
              }}
            >
              {LANGUAGES.map((language) => (
                <Option value={language} key={language}>
                  {language}
                </Option>
              ))}
            </Select>
          </div>

          <div className="NavigationMenuElementsGrid-row">
            <div className="NavigationMenuElementsGrid-label">{translate('page.profilesMenu.systemType')}</div>
            <Select
              value={selectedSystemType}
              onChange={(selectedSystemType) => this.setState({ selectedSystemType, elements: [] })}
              className="NavigationMenuElementsGrid-selectType"
              mode="multiple"
              placeholder={translate('page.menuElementPropDictionary.selectSystemType')}
            >
              {PROP_SYSTEM_TYPES.map((systemType) => (
                <Option value={systemType} key={systemType}>
                  {systemType}
                </Option>
              ))}
            </Select>
          </div>

          <div className="NavigationMenuElementsGrid-row">
            <div className="NavigationMenuElementsGrid-label">{translate('page.navigationMenuProp.prop')}</div>
            <Select
              value={selectedPropVariant}
              className="NavigationMenuElementsGrid-propValueSelect"
              placeholder={translate('page.navigationMenuProp.selectPropName')}
              onChange={(selectedPropVariant) => {
                this.setState({ selectedPropVariant, elements: [] });
              }}
              filterOption={(input, { props: { children } }) => children.toLowerCase().includes(input.toLowerCase())}
              disabled={!propVariants.length}
              showSearch
            >
              {propVariants.map(({ Key }) => (
                <Option value={Key} key={Key}>
                  {Key}
                </Option>
              ))}
            </Select>
          </div>

          <div className="NavigationMenuElementsGrid-row">
            <div className="NavigationMenuElementsGrid-label">{translate('page.navigationMenuProp.value')}</div>
            <TextArea
              className="NavigationMenuElementsGrid-propValueSelect"
              value={propValue}
              rows={3}
              onChange={(e) => {
                this.setState({ propValue: e.target.value, elements: [] });
              }}
            />
          </div>
        </div>

        <div className="NavigationMenuElementsGrid-action">
          <Button type="primary" disabled={isDisable} onClick={this.fetchAllMenuPropsGQL}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>
      </Row>
    );
  };

  rowRender = (element, { dataItem }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      {
        ...elementStyle,
        className: dataItem.isDefined ? 'NavigationMenuElementsGrid-definitedPropRow' : '',
      },
      children,
    );
  };

  render() {
    const { elements, isLoading, modalVariant, selectedProfiles } = this.state;

    if (!checkElement('managementMenuProfiles-redirectElementTab', this.currentArray)) {
      return <></>;
    }

    return (
      <>
        <Grid
          data={elements}
          onRefresh={this.fetchAllMenuPropsGQL}
          isLoading={isLoading}
          name="NavigationMenuElementsGrid"
          toolbar={this.buildToolbar()}
          rowRender={this.rowRender}
        >
          <GridColumn
            field="menuName"
            title="page.profilesMenu.elementName"
            width="800px"
            {...(checkElement('managementMenuProfiles-redirectElementPropsTab', this.currentArray)
              ? {
                  onClick: ({ dataItem: { menuName, menuId, profileHistorySlug, parentHistorySlug } }) => {
                    tabService.addTab({
                      type: 'menuElementProps',
                      dataItem: {
                        elementName: menuName,
                        profileId: selectedProfiles[0].profileId,
                        menuId,
                        profileHistorySlug,
                        parentHistorySlug,
                      },
                    });
                  },
                }
              : {})}
          />
          <GridColumn field="menuId" title="page.profilesMenu.elementId" width="120px" />
          <GridColumn field="profileId" title="page.profilesMenu.legacyValueProfile" width="140px" />
        </Grid>

        {modalVariant === 'selectProfiles' && (
          <UpdateMenuProfileContext
            withSelect
            isSingle
            defaultSelectedProfiles={selectedProfiles && selectedProfiles.length ? selectedProfiles : []}
          >
            <MenuProfileDialog
              visible={modalVariant === 'selectProfiles'}
              closeDialog={() => {
                this.setState({ modalVariant: '' });
              }}
              saveSelectedProfiles={(selectedProfiles) => {
                this.setState({ selectedProfiles, elements: [] });
              }}
            />
          </UpdateMenuProfileContext>
        )}
      </>
    );
  }
}

export default withTranslate(NavigationMenuElementsGrid);
