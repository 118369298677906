export const ALL_GRID_COLUMNS = [
  'terminalId',
  'name',
  'fiscalNumber',
  'code',
  'regionName',
  'cityName',
  'terminalAddress',
  'designTypeName',
  'commissionProfileName',
  'mainProfileId',
  'mainProfileName',
  'statusName',
  'projectName',
  'networkContractName',
  'datePostNoUTC',
  'branch',
  'note',
];

export const NORMALIZE_FIELD_MAP = new Map([['DatePost', 'datePostNoUTC']]);

export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([['datePostNoUTC', 'DatePost']]);
