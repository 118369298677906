import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, tabService } from '../../../services';
import CellHour from './cellHour';
const api = new ApiService();

const roundNumber = (number) => Math.round(number * 100) / 100;

class GridCommunicationTerminalsPerDay extends Component {
  state = {
    CommunicationTerminalsPerDay: [],
    isLoading: false,
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  communicationTerminalsPerDay = async () => {
    const { ownerId, terminalList, dateStart } = this.props;

    try {
      this.toggleLoader(true);
      const model = {
        ownerId: ownerId,
        terminalList: terminalList,
        dateStart: dateStart,
      };
      const CommunicationTerminalsPerDay = await api.communicationTerminalsPerDay(model);
      this.setState({ CommunicationTerminalsPerDay });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };
  async componentDidMount() {
    await this.communicationTerminalsPerDay();
  }
  createMinutesTab = (hourInfo) => {
    tabService.addTab({
      type: 'minutes',
      hourInfo,
    });
  };

  render = () => {
    const { isLoading, CommunicationTerminalsPerDay } = this.state;
    const { name, permissionName, dateStart } = this.props;
    return (
      <Grid
        data={CommunicationTerminalsPerDay}
        name={name}
        isLoading={isLoading}
        permissionName={permissionName}
        toolbar={<div></div>}
        onRefresh={this.communicationTerminalsPerDay}
      >
        <GridColumn field="OwnerName" width="200" title="grids.terminalOperabilityChoose.column-ownerName" />
        <GridColumn field="TerminalId" title="grids.terminalOperabilityChoose.column-terminalId" width="100" />
        <GridColumn field="TerminalName" title="grids.terminalOperabilityChoose.column-name" width="150" />
        <GridColumn field="Address" title="grids.terminalOperabilityChoose.column-address" width="250" />
        <GridColumn field="CityName" title="grids.terminalOperabilityChoose.column-city" width="150" />
        <GridColumn
          field="NetworkContractName"
          title="grids.terminalOperabilityChoose.column-networkContract"
          width="150"
        />
        <GridColumn field="TerminalBranch" title="grids.communicationTerminalsPerDay.column-branch" width="80" />
        <GridColumn field="TerminalDescription" title="grids.communicationTerminalsPerDay.column-note" width="150" />
        <GridColumn field="StatusName" title="grids.communicationTerminalsPerDay.column-status" width="150" />
        <GridColumn
          field="Hour1"
          title="00-01"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={0} />}
        />
        <GridColumn
          field="Hour2"
          title="01-02"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={1} />}
        />
        <GridColumn
          field="Hour3"
          title="02-03"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={2} />}
        />
        <GridColumn
          field="Hour4"
          title="03-04"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={3} />}
        />
        <GridColumn
          field="Hour5"
          title="04-05"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={4} />}
        />
        <GridColumn
          field="Hour6"
          title="05-06"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={5} />}
        />
        <GridColumn
          field="Hour7"
          title="06-07"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={6} />}
        />
        <GridColumn
          field="Hour8"
          title="07-08"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={7} />}
        />
        <GridColumn
          field="Hour9"
          title="08-09"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={8} />}
        />
        <GridColumn
          field="Hour10"
          title="09-10"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={9} />}
        />
        <GridColumn
          field="Hour11"
          title="10-11"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={10} />}
        />
        <GridColumn
          field="Hour12"
          title="11-12"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={11} />}
        />
        <GridColumn
          field="Hour13"
          title="12-13"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={12} />}
        />
        <GridColumn
          field="Hour14"
          title="13-14"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={13} />}
        />
        <GridColumn
          field="Hour15"
          title="14-15"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={14} />}
        />
        <GridColumn
          field="Hour16"
          title="15-16"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={15} />}
        />
        <GridColumn
          field="Hour17"
          title="16-17"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={16} />}
        />
        <GridColumn
          field="Hour18"
          title="17-18"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={17} />}
        />
        <GridColumn
          field="Hour19"
          title="18-19"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={18} />}
        />
        <GridColumn
          field="Hour20"
          title="19-20"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={19} />}
        />
        <GridColumn
          field="Hour21"
          title="20-21"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={20} />}
        />
        <GridColumn
          field="Hour22"
          title="21-22"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={21} />}
        />
        <GridColumn
          field="Hour23"
          title="22-23"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={22} />}
        />
        <GridColumn
          field="Hour24"
          title="23-00"
          width="80"
          filter="numeric"
          cell={(props) => <CellHour {...props} handleClick={this.createMinutesTab} workDay={dateStart} hour={23} />}
        />
        <GridColumn
          field="AvgValue"
          title="grids.communicationTerminalsPerDay.column-average-day"
          width="100"
          filter="numeric"
          cell={({ dataItem: { AvgValue } }) => <td>{roundNumber(AvgValue)}</td>}
        />
      </Grid>
    );
  };
}
export default withTranslate(GridCommunicationTerminalsPerDay);
