import React from 'react';
import PropTypes from 'prop-types';
import { withTranslate } from '../../contexts/localContext';

const CellEventType = ({ style, field, dataItem, translate, className, mobile }) => {
  const value = dataItem[field];

  const transformValue = () => {
    switch (value) {
      case 'INSERT':
        return translate('grids.whiteListAdmin.operation-type-1');
      case 'DELETE':
        return translate('grids.whiteListAdmin.operation-type-2');
      default:
        return value;
    }
  };

  if (mobile) {
    return (
      <div style={{ ...style }} className={className}>
        <span>{transformValue()}</span>
      </div>
    );
  }

  return (
    <td style={{ ...style }} className={className}>
      <span>{transformValue()}</span>
    </td>
  );
};

CellEventType.defaultProps = {
  mobile: false,
};

CellEventType.propTypes = {
  mobile: PropTypes.bool,
};

export default withTranslate(CellEventType);
