import React, { PureComponent } from 'react';
import { Modal, Button, Alert } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';

class WarningDialog extends PureComponent {
  render() {
    const { translate, type, closeModal, onSave } = this.props;

    return (
      <Modal
        closable={false}
        visible={type}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              closeModal();
              onSave(type);
            }}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <Alert
          message={translate(
            type === 'riskRule'
              ? 'page.serviceMainProps.riskWarningMessage'
              : 'page.serviceMainProps.clientCommissionWarningMessage',
          )}
          type="warning"
          className="alert-business-service"
          style={{
            marginTop: '15px',
          }}
          showIcon
        />
      </Modal>
    );
  }
}

export default withTranslate(WarningDialog);
