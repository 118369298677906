import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import CardRoutingGrid from './CardRoutingGrid';
import CheckCardRouting from './CheckCardRouting';
import BankListGrid from './BankListGrid';

class CardRouting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-routingManagement',
          content: <CardRoutingGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'updateRoutingValidationResult':
        tabs.push({
          title: 'page.routingManagement.checkingAndSavingRoutings',
          content: <CheckCardRouting dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'managementBanks':
        tabs.push({
          title: 'page.routingManagement.managementBanks',
          content: <BankListGrid />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
  }
}

export default CardRouting;
