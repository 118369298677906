import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import './desktop.scss';

export default class DesktopGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  wrapper = React.createRef();

  state = {
    height: '100%',
    skip: 0,
  };

  onResize = () => {
    //  I'm Gonna Show You Crazy
    const div = this.wrapper.current;
    this.setState({ height: div.offsetHeight });
  };

  pageChange = (event) => {
    const { handleGridSkipChange } = this.props;

    handleGridSkipChange(isNaN(event.page.skip) ? 0 : event.page.skip);
  };

  // componentDidUpdate(prevProps) {
  //   const { filter } = this.props;
  //   if (filter !== prevProps.filter) {
  //     console.log('Desktop::componentDidUpdate');
  //   }
  // }

  render() {
    const { skip, height } = this.state;
    const { data, style, children, rowCount, rowHeight, gridSkip, ...props } = this.props;
    // console.log('Current grid state height');
    // console.log(this.state.height);

    return (
      <div ref={this.wrapper} style={{ height: '100%' }}>
        <Grid
          {...props}
          style={{ height: `${height}px`, ...style }}
          rowHeight={rowHeight}
          data={data.slice(gridSkip, gridSkip + rowCount)}
          pageSize={rowCount}
          total={data.length}
          skip={gridSkip}
          scrollable="virtual"
          onPageChange={this.pageChange}
          reorderable
        >
          {children}
        </Grid>
      </div>
    );
  }
}
