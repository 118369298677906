import React, { PureComponent } from 'react';
import { Card, Col, message, Tooltip } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import CopyCommissionsContainerHOC from './CopyCommissionsContainerHOC';
import { withTranslate } from '../../../contexts/localContext';
import {
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
} from '../../../core/constants/configs.const';
import CopyDealerCommissionsButtons from './CopyDealerCommissionsButtons';
import { formatDate } from '@telerik/kendo-intl';
import { ApiService } from '../../../services';

const api = new ApiService();

class CopyCommissionsFromDealer extends PureComponent {
  dealerCommissionsServiceCopy = async () => {
    const {
      fromOwner,
      toOwner,
      fromSelectedServicesList,
      fromSelectedPaymentTypes,
      toSelectedPaymentTypes,
      dateStart,
      toggleLoader,
      translate,
    } = this.props;

    try {
      const model = {
        ownerIdFrom: fromOwner[0],
        ownerIdTo: toOwner,
        serviceIdTo: fromSelectedServicesList,
        paymentTypeFrom: fromSelectedPaymentTypes[0],
        paymentTypeTo: toSelectedPaymentTypes,
        dateStart: formatDate(dateStart, 'yyyy-MM-ddTHH:mm:ss'),
      };
      toggleLoader(true);
      await api.copyDealerServiceCommission(model);
      toggleLoader(false);
      message.success(`${translate('page.dealerCommissionsCopy.msg-success')}`);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      toggleLoader(true);
    }
  };

  render = () => {
    const {
      allOwners,
      fromOwner,
      toOwner,
      paymentTypes,
      servicesList,
      fromSelectedServicesList,
      fromSelectedPaymentTypes,
      toSelectedPaymentTypes,
      handleSelectItems,
      onChangeDate,
      translate,
      dateStart,
      minDate,
    } = this.props;

    const isDisabled = !(
      toSelectedPaymentTypes.length &&
      toOwner.length &&
      fromSelectedPaymentTypes.length &&
      fromOwner.length
    );
    return (
      <Col span={24}>
        <div className="CopyDealerCommissions-title">{translate('page.dealerCommissionsCopy.msg-from-dealer')}</div>
        <Col className="CopyDealerCommissions-toolbar">
          <Col span={6} className="CopyDealerCommissions-label">
            {translate('page.commissionsServices.service')}
          </Col>
          <Col span={18}>
            <GridDropdown
              data={DropdownNormalizersService.normalizeServices(servicesList)}
              colConfig={SERVICES_COLUMN_CONFIG}
              selectItems={fromSelectedServicesList}
              onSave={handleSelectItems('fromSelectedServicesList')}
              defaultTitle={translate('page.commissionsServices.chooseService')}
            />
          </Col>
        </Col>
        <Col className="CopyDealerCommissions-datepicker-row">
          <Col span={6} className="CopyDealerCommissions-datepicker-label">
            {translate('page.dealerCommissionsCopy.date-start')}
          </Col>
          <Col span={18}>
            <Tooltip
              trigger="focus"
              placement="bottom"
              title={translate('page.dealerCommissionsCopy.tooltip-datePicker')}
            >
              <DatePicker
                format="yyyy.MM.dd"
                value={dateStart}
                min={minDate}
                onChange={onChangeDate}
                className="CopyDealerCommissions-datepicker"
              />
            </Tooltip>
          </Col>
        </Col>
        <Col className="CopyDealerCommissions-card-content">
          <Col span={24} md={24} lg={24}>
            <Card
              type="inner"
              className="CopyDealerCommissions-card"
              title={translate('page.dealerCommissionsCopy.card-title-from')}
            >
              <Col span={24} className="CopyDealerCommissions-row">
                <Col span={6} className="CopyDealerCommissions-label">
                  {translate('page.commissionsServices.dealer')}
                </Col>
                <Col span={18} className="CopyDealerCommissions-ownerDropdown">
                  <GridDropdown
                    data={DropdownNormalizersService.normalizeOwners(allOwners)}
                    colConfig={OWNERS_COLUMN_CONFIG}
                    selectItems={fromOwner}
                    onSave={handleSelectItems('fromOwner')}
                    isSingle
                    defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  />
                </Col>
              </Col>
              <Col span={24} className="CopyDealerCommissions-row">
                <Col span={6} className="CopyDealerCommissions-label">
                  {translate('page.commissionsServices.paymentType')}
                </Col>
                <Col span={18}>
                  <GridDropdown
                    data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                    colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                    selectItems={fromSelectedPaymentTypes}
                    onSave={handleSelectItems('fromSelectedPaymentTypes')}
                    isSingle
                    defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                  />
                </Col>
              </Col>
            </Card>
            <Card
              type="inner"
              className="CopyDealerCommissions-card"
              title={translate('page.dealerCommissionsCopy.card-title-to')}
            >
              <Col span={24} className="CopyDealerCommissions-row">
                <Col span={6} className="CopyDealerCommissions-label">
                  {translate('page.commissionsServices.dealer')}
                </Col>
                <Col span={18} className="CopyDealerCommissions-gridDropdown CopyDealerCommissions-gridDropdown--top">
                  <GridDropdown
                    data={DropdownNormalizersService.normalizeOwners(allOwners)}
                    colConfig={OWNERS_COLUMN_CONFIG}
                    selectItems={toOwner}
                    onSave={handleSelectItems('toOwner')}
                    isSingle
                    defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  />
                </Col>
              </Col>
              <Col span={24} className="CopyDealerCommissions-row">
                <Col span={6} className="CopyDealerCommissions-label">
                  {translate('page.commissionsServices.paymentType')}
                </Col>
                <Col span={18} className="CopyDealerCommissions-gridDropdown CopyDealerCommissions-gridDropdown--top">
                  <GridDropdown
                    data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                    colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                    selectItems={toSelectedPaymentTypes}
                    onSave={handleSelectItems('toSelectedPaymentTypes')}
                    defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                  />
                </Col>
              </Col>
            </Card>
          </Col>
        </Col>
        <CopyDealerCommissionsButtons disabled={isDisabled} onClick={this.dealerCommissionsServiceCopy} />
      </Col>
    );
  };
}

export default withTranslate(CopyCommissionsContainerHOC(CopyCommissionsFromDealer));
