import React, { Component } from 'react';
import { Sortable } from '@progress/kendo-react-sortable';
import { withTranslate } from '../../../../contexts/localContext';
import { Button, Input, message, Row, Col } from 'antd';
import { ApiService } from '../../../../services';
import './newBusinessService.scss';
import SortableItemUI from './sortableItemUi';
import LoadingPanel from '../../../../components/loader';
import { BACKUP_TEMPLATE_SELECTED_FIELDS, BUSINESS_TEMPLATE_SELECTED_FIELDS } from './constants';
import AlertModal from '../../../BackupButtons/AlertModal';
const api = new ApiService();

class NewBusinessService extends Component {
  constructor(props) {
    super(props);
    const { item, isBackupButtonsVariant } = props;
    this.selectedFields = isBackupButtonsVariant ? BACKUP_TEMPLATE_SELECTED_FIELDS : BUSINESS_TEMPLATE_SELECTED_FIELDS;
    this.maxAmount = isBackupButtonsVariant ? 10 : 20;

    if (!item[this.selectedFields.Id]) return;
    this.state = {
      data: [],
      isDisabled: false,
      isSuperUser: true,
      elementsShouldBeDisabled: [],
      isLoading: false,
      templateValue: `${item[this.selectedFields.Version]}-${item[this.selectedFields.Name]}`,
      areEmptyElements: false,
      matchedButtonList: [],
      isWithDuplicates: false,
    };
  }

  componentDidMount() {
    this.initRequest();
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  initRequest = async () => {
    const { showError, item, isBackupButtonsVariant } = this.props;
    try {
      this.toggleLoader();
      const currentRequest = isBackupButtonsVariant ? api.mainProfileHoldGet : api.getBusinessServiceFromServices;
      const data = await currentRequest(item[this.selectedFields.Id]);
      this.setState({ loading: false, data }, () => {
        this.checkEmpty();
        this.renderData();
      });
    } catch (err) {
      showError(err);
    } finally {
      this.toggleLoader();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      const emptyElems = this.state.data.filter((el) => !el.MenuId);

      if (emptyElems.length > 0) {
        if (
          emptyElems[emptyElems.length - 1].VisibleIndex !== this.state.data[this.state.data.length - 1].VisibleIndex
        ) {
          this.setState({ areEmptyElements: true });
        } else {
          this.setState({ areEmptyElements: false });
        }
      } else {
        this.setState({ areEmptyElements: false });
      }
    }
  }

  onDragOver = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  onNavigate = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  changeImage = (VisibleIndex, newElem) => {
    const parsedElem = JSON.parse(newElem);
    const { MenuId, MenuName, IconUrl, Icon, Caption, RegionId, CategoryMenuId, ServiceId } = parsedElem;
    this.setState(
      ({ data }) => {
        const newData = data.map((el, index) => {
          if (el.VisibleIndex === VisibleIndex) {
            el.MenuId = MenuId;
            el.MenuName = MenuName;
            // при создании нового!!
            el[this.selectedFields.Id] = 0;
            el.IconUrl = IconUrl;
            el.RegionId = RegionId;
            el.CategoryMenuId = CategoryMenuId;
            el.Icon = Icon;
            el.Caption = Caption;
            el.ServiceId = ServiceId;
            el.IsAddElement = false;
            el.VisibleIndex = index;
          }
          return { ...el, VisibleIndex: index };
        });
        return {
          data: newData,
        };
      },
      () => {
        this.checkEmpty();
        this.renderData();
      },
    );
  };

  toggleElementLock = (MenuId) => {
    this.setState(({ data }) => {
      const newData = data.map((el) => {
        if (el.MenuId === MenuId) {
          el.IsHold = !el.IsHold;
        }
        return el;
      });
      return {
        data: newData,
      };
    });
  };

  dropImage = (VisibleIndex) => {
    this.setState(
      ({ data }) => ({ data: data.filter((el) => el.VisibleIndex !== VisibleIndex) }),
      () => {
        this.setState(
          ({ data }) => ({
            data:
              data.length < this.maxAmount && !data.find(({ IsAddElement }) => IsAddElement)
                ? [
                    ...data,
                    {
                      MenuId: null,
                      Caption: <p>+</p>,
                      IconUrl: null,
                      VisibleIndex: data.length + 1,
                      IsAddElement: true,
                    },
                  ]
                : data,
          }),
          () => {
            this.checkEmpty();
          },
        );
      },
    );
  };

  makeUnnecessary = async (VisibleIndex) => {
    const { showError } = this.props;
    const findElement = this.state.data.find((el) => el.VisibleIndex === VisibleIndex);
    try {
      this.toggleLoader();
      const model = {
        MainProfileHoldId: findElement.MainProfileHoldId,
        MenuId: findElement.MenuId,
      };
      const request = await api.terminalsMainProfileHoldTransfer(model);
      await this.initRequest();
      this.setState({
        isEditing: false,
      });
      return request;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  renderData = () => {
    this.setState((state) => {
      const newData = [...state.data];
      if (newData.length < this.maxAmount && !newData.find(({ IsAddElement }) => IsAddElement)) {
        newData.push({
          MenuId: null,
          Caption: <p>+</p>,
          IconUrl: null,
          VisibleIndex: newData.length + 1,
          IsAddElement: true,
        });
      }
      return {
        data: newData,
      };
    });
  };

  onSubmitHandler = async () => {
    const { elementsShouldBeDisabled, data, templateValue, areEmptyElements } = this.state;
    const { showError, translate, isBackupButtonsVariant } = this.props;

    const newFiltered = elementsShouldBeDisabled.filter((el) => !el.disabled);
    this.setState({ elementsShouldBeDisabled: newFiltered });
    if (areEmptyElements) {
      return message.error(`${translate('page.screenControlBusinessServices.template-error-empty-elements')}`, 2);
    }
    if (newFiltered.length > 0) {
      return message.error('page.screenControlBusinessServices.new-profile-error-not-all-disabled', 2);
    } else {
      try {
        this.toggleLoader();
        const currentReq = isBackupButtonsVariant ? api.mainProfileReserveManage : api.postMainProfileHoldManage;
        const model = {
          [this.selectedFields.Id]: 0,

          Items: data
            .map((el, index) => {
              el.VisibleIndex = index;
              return el;
            })
            .filter((el) => el.IconUrl)
            .slice(0, this.maxAmount),
        };

        if (isBackupButtonsVariant) {
          const errors = await api.mainProfileReserveManageCheck(model);

          if (errors && errors.length) {
            this.setState({
              matchedButtonList: errors.map((item) => {
                const dataItem = data.find(({ MenuId }) => MenuId === item.MenuId) || [];

                return { ...item, ...dataItem };
              }),
            });

            return;
          }
        }

        const response = await currentReq({
          ...model,
          [this.selectedFields.Name]: templateValue,
        });
        this.setState(
          (state) => {
            const newData = state.data.map((el, index) => {
              el.VisibleIndex = index;
              return el;
            });
            return {
              loading: false,
              data: newData,
            };
          },
          () =>
            message.success(`${translate('page.screenControlBusinessServices.new-template-success')}`, 2, () =>
              window.location.reload(true),
            ),
        );
        return response;
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onChangeTemplateValue = (e) => {
    this.setState({ templateValue: e.target.value });
  };

  checkDataLength = () => {
    const { data } = this.state;
    if (data && data.length < 2) return true;
    else return false;
  };

  checkEmpty = () => {
    const { data } = this.state;
    const { translate } = this.props;
    const uniqueArray = [...new Set(data.map(({ MenuId }) => MenuId))];

    if (uniqueArray.length === data.length) {
      this.setState({ isWithDuplicates: false });
      return;
    }

    this.setState({ isWithDuplicates: true }, () => {
      message.error(`${translate('page.screenControlBusinessServices.profile-error-repeated-elements')}`, 2);
    });
  };

  render() {
    const { data, isSuperUser, isLoading, templateValue, matchedButtonList, isWithDuplicates } = this.state;
    const { translate, item, isBackupButtonsVariant } = this.props;

    const isDisabling = this.checkDataLength();
    return (
      <>
        <div className="scrcontrol--wrapper">
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: 10 }}
            align="middle"
            gutter={24}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={16}>
              <Row
                className="modal-change-profile-row for-sub-input"
                type="flex"
                align="middle"
                gutter={24}
                style={{ flexWrap: 'wrap' }}
              >
                <p style={{ marginBottom: '0' }}>
                  {translate('page.screenControlBusinessServices.modal-new-template-input-title')}
                </p>
                <Input value={templateValue} onChange={this.onChangeTemplateValue} />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Row type="flex" justify="end">
                {
                  // data && data.length > 1 &&
                  <Button
                    type="primary"
                    onClick={this.onSubmitHandler}
                    disabled={isDisabling || !templateValue || isWithDuplicates}
                  >
                    {translate('page.screenControlBusinessServices.button-save')}
                  </Button>
                }
              </Row>
            </Col>
          </Row>
          <Sortable
            idField={'VisibleIndex'}
            data={data.slice(0, 20)}
            style={{ marginTop: 10 }}
            itemUI={(props) => (
              <SortableItemUI
                key={`business-service-${props.dataItem.VisibleIndex}`}
                changeImage={this.changeImage}
                dropImage={this.dropImage}
                toggleElementLock={this.toggleElementLock}
                makeUnnecessary={this.makeUnnecessary}
                isSuperUser={isSuperUser}
                Id={item[this.selectedFields.Id]}
                isBackupButtonsVariant={isBackupButtonsVariant}
                {...props}
              />
            )}
            onDragOver={this.onDragOver}
            onNavigate={this.onNavigate}
          />
        </div>
        {isLoading && <LoadingPanel />}
        {!!matchedButtonList.length && (
          <AlertModal
            visible={!!matchedButtonList.length}
            closeModal={() => {
              this.setState({ matchedButtonList: [] });
            }}
            buttonList={matchedButtonList}
          />
        )}
      </>
    );
  }
}

export default withTranslate(NewBusinessService);
