import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button, message } from 'antd';
import { Input } from '@progress/kendo-react-inputs';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import './GridReportsByPeriod.scss';

const api = new ApiService();

class ViewReportPeriod extends PureComponent {
  state = {
    start: new Date(new Date().setHours(0, 0, 0, 0)),
    end: new Date(new Date().setHours(0, 0, 0, 0)),
    emailValue: '',
    isLoading: false,
  };

  componentDidMount() {
    this.handleAuthorizeCheck();
  }

  handleAuthorizeCheck = async () => {
    try {
      await api.authorizeCheck();
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };

  onStartChange = (e) => {
    this.setState({ start: e.target.value });
  };

  onEndChange = (e) => {
    this.setState({ end: e.target.value });
  };

  onChangeEmailValue = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onSubmit = async () => {
    const { start, end, emailValue } = this.state;
    const { showError, translate } = this.props;
    // hours*minutes*seconds*milliseconds
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round((end - start) / oneDay);
    // console.log(diffDays)
    if (!emailValue || emailValue === '') {
      return message.error(`${translate('page.reportPeriod.error-msg-no-email')}`, 2);
    }

    if (emailValue && emailValue !== '' && !validateEmail(emailValue)) {
      return message.error(`${translate('page.reportPeriod.error-msg-incorrect-email')}`, 2);
    }

    if (diffDays < 0 || diffDays > 31) {
      return message.error(`${translate('page.reportPeriod.error-msg-rangeDiff')}`, 2);
    } else {
      try {
        this.toggleLoader();
        const startDate = formatDate(start, 'yyyy-MM-dd HH:MM:ss.SSS');
        const endDate = formatDate(end, 'yyyy-MM-dd HH:MM:ss.SSS');
        const apiDateStart = formatDate(start, `yyyy-MM-dd'T'HH:MM:ss.SSS'Z'`);
        const emailArr = emailValue.split(/[ ,]+/);
        const model = {
          QueryId: 331,
          Parameters: [`Дата Начала|@date_start|'${startDate}'`, `Дата Окончания|@date_end|'${endDate}'`],
          Email: [...emailArr],
          DateStart: apiDateStart,
        };
        await api.postSQLQueryRunEmail(model);
        this.toggleLoader();
        return message.success(`${translate('page.reportPeriod.success-msg')}`, 3);
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const { start, end, emailValue, isLoading } = this.state;
    const { translate } = this.props;
    return (
      <div className="page-period">
        {isLoading && <LoadingPanel />}
        <Row className="main-row" type="flex" justify="space-between" align="middle">
          <Col xs={24} md={10}>
            <Row type="flex" align="middle" className="row-nowrap">
              <p>{translate('page.reportPeriod.dateStart')}</p>
              <DatePicker format="yyyy.MM.dd" value={start} onChange={this.onStartChange} required={true} />
            </Row>
          </Col>
          <Col xs={24} md={10}>
            <Row type="flex" align="middle" className="row-nowrap">
              <p>{translate('page.reportPeriod.dateEnd')}</p>
              <DatePicker format="yyyy.MM.dd" value={end} onChange={this.onEndChange} required={true} />
            </Row>
          </Col>
        </Row>
        <Row className="sub-wrapper main-row first-child">
          <Col xs={24} md={12}>
            <Row type="flex" align="middle" className="row-nowrap">
              <p>Email</p>
              <Input value={emailValue} onChange={this.onChangeEmailValue} />
            </Row>
          </Col>
        </Row>
        <Row className="sub-wrapper main-row">
          <Col xs={24} md={12}>
            <Button
              type="primary"
              disabled={!start || !end || !emailValue || emailValue === ''}
              onClick={this.onSubmit}
            >
              {translate('page.reportPeriod.button-submit')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default withTranslate(ViewReportPeriod);
