import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import Grid from '../../../components/grids/baseGrid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

import DateRange from '../../../components/daterange';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';

import './GridReportServices.styled.scss';

const api = new ApiService();

const aggregate = [
  { field: 'ServiceId', aggregate: 'count', format: 'n0' },
  { field: 'QuantityTotal', aggregate: 'sum', format: 'n0' },
  {
    field: 'AvgPayment',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ AmountTotal, QuantityTotal }) => AmountTotal.sum / QuantityTotal.sum,
  },
  { field: 'AmountTotal', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionClient', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionAll', aggregate: 'sum', format: 'n2' },
  { field: 'PaymentCountWithNfc', aggregate: 'sum', format: 'n0' },
  { field: 'PaymentAmountWithNfc', aggregate: 'sum', format: 'n2' },
  { field: 'TerminalCount', aggregate: 'sum', format: 'n0' },
];

class GridReportServices extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('reportonservice');
  }

  state = {
    data: [],
    isLoading: false,
    ownerId: undefined,
    owners: [],
    currentOwners: [],
    range: undefined,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { range, currentOwners } = this.state;
    if (!range || !currentOwners) return;

    try {
      this.toggleLoader(true);
      const model = {
        OwnerId: currentOwners[0],
        DateStart: range.from,
        DateEnd: range.to,
      };
      const data = await api.serviceReport(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  onOwner = (currentOwners) => {
    console.log('currentOwner', currentOwners);
    // currentOwner = +currentOwner.join('');
    this.setState({ currentOwners }, this.makeQuery);
  };

  onRange = (range) => {
    this.setState({ range });
  };

  componentDidMount() {
    this.fetchOwners();
  }
  filterOwnersByStatus = (owners, isWithDeleted) => {
    return isWithDeleted ? owners : owners.filter((owner) => owner.OwnerStatusId !== 2);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, currentOwners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridReportServices-toolbar">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(owners)}
              selectItems={currentOwners}
              onSave={this.onOwner}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              labelForAdditonalFilterProps={translate('owner.show-delete')}
              filterDropdownData={this.filterOwnersByStatus}
              isSingle
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange onRange={this.onRange} />
          </Col>
          {checkElement('servicesReport-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery}>
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        aggregate={aggregate}
        rowRender={this.rowRender}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="servicesReport"
        permissionName="reportonservice"
        specificFieldsToHide={['PaymentCountWithNfc', 'PaymentAmountWithNfc']}
        specificCheckBoxTitle="NFC"
      >
        <GridColumn
          field="ServiceId"
          title="grids.terminal-events.column-serviceId"
          width="105"
          filter="numeric"
          format="{0:0}"
        />
        <GridColumn field="ServiceName" title="grids.terminals.column-name" width="225" />

        <GridColumn
          field="TerminalCount"
          title="grids.profilesMainProfileList.column-terminalsQuantity"
          filter="numeric"
          width="200"
        />

        <GridColumn
          field="QuantityTotal"
          title="grids.serviceReport.column-quantityPayments"
          width="185"
          filter="numeric"
          format="{0:n0}"
        />

        <GridColumn
          field="PaymentCountWithNfc"
          title="grids.serviceReport.column-paymentCountWithNfc"
          width="165"
          filter="numeric"
        />

        <GridColumn
          field="AvgPayment"
          title="grids.serviceReport.column-avgPayment"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="AmountTotal"
          title="grids.cityReport.column-turnover"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="PaymentAmountWithNfc"
          title="grids.serviceReport.column-paymentAmountWithNfc"
          width="165"
          filter="numeric"
        />

        <GridColumn
          field="CommissionProvider"
          title="grids.dayReport.column-owners"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionClient"
          title="grids.dayReport.column-clients"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionAll"
          title="grids.cityReport.column-commission"
          width="145"
          filter="numeric"
          format="{0:n2}"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridReportServices);
