const techInform = (translate, value) => {
  switch (value) {
    case 'techInform-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-city')}`;
    case 'techInform-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-lastConnect')}`;
    case 'techInform-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-lastTransaction')}`;
    case 'techInform-Name':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-name')}`;
    case 'techInform-StatusDetails':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-details')}`;
    case 'techInform-StatusState':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-state')}`;
    case 'techInform-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.tech-inform.column-street')}`;
    case 'techInform-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.tech-inform.column-terminalId')}`;
    default:
      return value;
  }
};

export default techInform;
