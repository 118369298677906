import React, { Component } from 'react';
import { Row, Col, Select } from 'antd';
import PropTypes from 'prop-types';
import { withTranslate } from '../../../contexts/localContext';
import './changeComission.scss';

const { Option } = Select;

class FixedComission extends Component {
  static defaultProps = {
    data: [],
  };
  static propTypes = {
    data: PropTypes.array,
    onSelectChangeFixed: PropTypes.func.isRequired,
  };

  render() {
    const {
      translate,
      data,
      clientFixedCommissionAmount,
      onSelectChangeFixed,
      dealerFixedCommissionAmount,
      isDisabled,
    } = this.props;
    return (
      <Row className={`fixedComission-wrapper ${isDisabled}`} type="flex" align="middle" gutter={24}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Row type="flex" className="aap-change-sub-row" align="middle">
            <p>
              {translate('grids.addingAddressedPaymentApplications.column-commissionClient')},
              {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
            </p>
            <Select
              value={clientFixedCommissionAmount}
              className="fixedComission--select"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              onChange={onSelectChangeFixed}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              showSearch
            >
              {data.map((el) => {
                return (
                  <Option key={`fixedComission-client-${el.ClientCommissionAmount}`} value={el.ClientCommissionAmount}>
                    {el.ClientCommissionAmount}
                  </Option>
                );
              })}
            </Select>
            {/* <p style={{ marginLeft: '5px' }}>
              
            </p> */}
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Row type="flex" className="aap-change-sub-row" align="middle">
            <p>
              {translate('grids.addingAddressedPaymentApplications.column-commissionDealer')},
              {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
            </p>
            <Select
              value={dealerFixedCommissionAmount}
              disabled
              className="fixedComission--select"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              onChange={onSelectChangeFixed}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              showSearch
            >
              {data.map((el) => {
                return (
                  <Option key={`fixedComission-dealer-${el.DealerCommissionAmount}`} value={el.DealerCommissionAmount}>
                    {el.DealerCommissionAmount}
                  </Option>
                );
              })}
            </Select>
            {/* <p style={{ marginLeft: '5px' }}>
              {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
            </p> */}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslate(FixedComission);
