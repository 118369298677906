export const SERVICE_GROUPS_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.serviceTemplates.col-name',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.serviceTemplates.col-description',
    width: '200',
    isShowOnMobile: true,
  },
];
