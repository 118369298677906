import React, { PureComponent } from 'react';
import { Alert, Button, message, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { ALL_GRID_COLUMNS } from './constants';

const api = new ApiService();

class DeleteModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteErrorCodes = async () => {
    const { selectedItems, onRefresh, closeModal, translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await Promise.all(
        selectedItems.map(({ editId, selected, checked, ...otherErrorCodeProps }) =>
          this.deleteErrorCode(otherErrorCodeProps),
        ),
      );
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.routingManagement.successfullyDeleted'), 2.5);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }

    await this.setState({ isLoading: false });
  };

  getValueByGQLFormat = (key, value) =>
    (value && value.trim() === 'null') || key === 'paymentRedirect' || key === 'authRedirect'
      ? value
      : `"${value ? value.replace(/"/g, '\\"') : ''}"`;

  deleteErrorCode = async (errorCode) => {
    const queryString = Object.entries(errorCode).reduce(
      (acc, [key, value]) => `${acc}${key}:${this.getValueByGQLFormat(key, value)},`,
      '',
    );

    const query = `errorCodeEntityDelete(input: { errorCodeEntity:{${queryString}}}){errorCode { ${ALL_GRID_COLUMNS.join()} }}`;

    return await api.mutationByGraphQl(query);
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteErrorCodes}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button key="back" onClick={closeModal} style={{ margin: variant === 'forbiddenDelete' ? '0 auto' : 'auto' }}>
            {translate(variant === 'forbiddenDelete' ? 'core.buttonTitles.ok' : 'core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {variant === 'forbiddenDelete' ? (
          <Alert message={translate('page.OTPServices.invalidDeleteDate')} type="error" showIcon />
        ) : (
          <div style={{ textAlign: 'center' }}>{translate('page.OTPServices.areYouSure')}</div>
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteModal);
