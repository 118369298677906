import dayjs from 'dayjs';

const OLDEST_YEAR = 1970;

export const getTransformDateString = (dateString, format) => {
  if (!dateString) {
    return '';
  }

  const currentFullYear = new Date(dateString).getFullYear();

  if (currentFullYear === OLDEST_YEAR) {
    return '';
  }

  return dayjs(dateString).format(format || 'DD.MM.YYYY HH:mm:ss');
};
