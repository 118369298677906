class PaymentTypesDictionaryService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.paymentTypeList) {
      return { paymentTypes: [], hasNextPage: false, hasPreviousPage: false };
    }

    const paymentTypes = data.data.paymentTypeList;
    const pageInfo = paymentTypes.pageInfo;

    const resultPaymentTypeList =
      paymentTypes.items && paymentTypes.items.length
        ? paymentTypes.items.map((paymentType) => ({
            ...paymentType,
            editId: paymentType.code,
          }))
        : [];

    return {
      paymentTypes: resultPaymentTypeList,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new PaymentTypesDictionaryService();
