import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import UpdateAndCreateBrandDialog from './UpdateAndCreateBrandDialog';
import CellBoolean from '../../account/cellBoolean';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class SubBrandsDictionary extends PureComponent {
  state = {
    brands: [],
    isLoading: false,
    selectedBrands: [],
    dialogVariant: '',
  };

  componentDidMount = () => {
    this.fetchBrands();
  };

  fetchBrands = async () => {
    try {
      this.setState({ isLoading: true });
      const brands = await api.getTerminalsBrandList();
      this.setState({
        brands: brands && brands.length ? brands : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleSelectionChange = (selectedBrands) => {
    this.setState({ selectedBrands });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedBrands } = this.state;

    return (
      <Row>
        <Button
          type="primary"
          onClick={() => {
            this.setState({ dialogVariant: 'addBrand' });
          }}
        >
          {translate('core.buttonTitles.add')}
        </Button>
        <Button
          type="primary"
          disabled={selectedBrands.length !== 1}
          onClick={() => {
            this.setState({ dialogVariant: 'changeBrand' });
          }}
        >
          {translate('core.buttonTitles.change')}
        </Button>
      </Row>
    );
  };

  render() {
    const { brands, isLoading, dialogVariant, selectedBrands } = this.state;

    return (
      <>
        <Grid
          data={brands}
          onRefresh={this.fetchBrands}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="subBrandsDictionaryGrid"
          fieldForSelection="BrandId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="BrandId" title="page.subBrandsTerminals.col-brandId" width="110" filter="numeric" />
          <GridColumn field="Name" title="page.subBrandsTerminals.col-SubBrandName" width="180" />
          <GridColumn field="MenuProfileName" title="page.subBrandsTerminals.col-defaultMenuProfile" width="260" />
          <GridColumn
            field="IsChangeCommissionEnabled"
            title="page.subBrandsTerminals.col-changeProfile"
            width="260"
            cell={WrappedCellBoolean}
          />
          <GridColumn field="Footer" title="page.subBrandsTerminals.col-terminalFooter" width="1000" />
        </Grid>

        {dialogVariant && (
          <UpdateAndCreateBrandDialog
            visible={dialogVariant}
            onClose={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedBrands={selectedBrands}
            onRefresh={this.fetchBrands}
            variant={dialogVariant}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SubBrandsDictionary);
