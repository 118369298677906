import React, { PureComponent } from 'react';
import { Modal, Button, InputNumber, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import LimitsService from './ServiceLimist.service';

import './ServiceLimitsGrid.scss';

const api = new ApiService();

class ChangeLimitDialog extends PureComponent {
  state = {
    isLoading: false,
    isErrorExist: false,
    limit: null,
    limitError: '',
    isAlertShow: false,
  };

  onLimitChange = (limit) => {
    const { translate } = this.props;

    this.setState({ limitError: '' }, () => {
      this.setState({ limit, limitError: LimitsService.limitValidation(translate)(limit) });
    });
  };

  handleLimitChange = async () => {
    const { isAlertShow, limit } = this.state;
    const { translate, onRefresh, onClose, selectServices } = this.props;

    if (!isAlertShow && limit > 29999.99) {
      this.setState({ isAlertShow: true });
      return;
    }

    try {
      this.setState({ isLoading: true });
      await api.updateServiceLimit(selectServices.map(({ ServiceId }) => ({ ServiceId, dailyLimit: limit })));
      this.setState({ isLoading: false });
      onClose();
      message.success(translate('page.limitsService.successfullyChanged'), 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleRefuse = () => {
    const { isAlertShow } = this.state;
    const { onClose } = this.props;

    if (isAlertShow) {
      this.setState({ isAlertShow: false });
      return;
    }

    onClose();
  };

  render() {
    const { translate, visible, onClose } = this.props;
    const { isLoading, limit, limitError, isAlertShow } = this.state;

    return (
      <Modal
        title={translate('page.limitsService.changeServiceLimits')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleLimitChange}
            loading={isLoading}
            disabled={!limit || limitError}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={this.handleRefuse} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {isAlertShow ? (
          <div className="AddLimitsModal-changeAlert">{`${translate('page.limitsService.youAreSure')} ${limit}?`}</div>
        ) : (
          <>
            <div className="AddLimitsModal-label">{translate('page.limitsService.col-dailyLimit')}:</div>
            <InputNumber value={limit} onChange={this.onLimitChange} className="AddLimitsModal-input" />
            {limitError && <div style={{ color: 'red' }}>{limitError}</div>}
            {isLoading && <LoadingPanel />}
          </>
        )}
      </Modal>
    );
  }
}

export default withTranslate(ChangeLimitDialog);
