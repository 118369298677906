import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import { ApiService } from '../../../services';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import Validator from './validator';
import LoadingPanel from '../../../components/loader';

import './SearchSanctionsTerrorist.scss';

const api = new ApiService();

class SearchSanctionsTerrorist extends PureComponent {
  state = {
    sanctionsList: [],
    searchString: '',
    error: null,
    isLoading: false,
  };

  onHandleChange = ({ target: { value } }) => {
    const { translate } = this.props;

    this.setState({ searchString: value }, () => {
      this.setState({ error: Validator.validate(translate)(this.state.searchString) });
    });
  };

  normalizeSanctionType = (type) => {
    const { translate } = this.props;

    if (type === 'Individual') {
      return translate('page.blockedTransactions.individual');
    }

    if (type === 'LegalEntitie') {
      return translate('page.blockedTransactions.juridicPerson');
    }

    if (type === 'Terrorist') {
      return translate('page.blockedTransactions.terrorist');
    }

    return '';
  };

  normalizeSanctionsList = (sanctionsList) => {
    if (!sanctionsList || !sanctionsList.length) {
      return [];
    }

    return sanctionsList.map((item) => ({ ...item, TypeId: this.normalizeSanctionType(item.TypeId) }));
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { searchString, error } = this.state;

    return (
      <Col span={24} md={14} lg={12} className="SearchSanctionsTerrorist-toolbar">
        <Row type="flex" align="middle">
          <Col span={18} md={20} lg={20} className="SearchSanctionsTerrorist-searchBar">
            <Col span={3} md={2} lg={2}>
              <label>{translate('page.search-lists-sanctioners.full-name')}</label>
            </Col>
            <Col span={20} md={20} lg={20}>
              <Tooltip
                trigger="focus"
                placement="top"
                className="SearchSanctionsTerrorist-tooltip"
                title={translate('page.search-lists-sanctioners.tooltip-title')}
              >
                <div className={`CustomInput ${error ? 'CustomInput--error' : ''} `}>
                  <Input
                    name="SearchBar"
                    value={searchString}
                    onChange={this.onHandleChange}
                    placeholder={translate('page.search-lists-sanctioners.inputPlaceholder')}
                    autoComplete="off"
                    maxLength={255}
                    className={`SearchSanctionsTerrorist-input ${
                      error ? 'SearchSanctionsTerrorist-input-invalid' : ''
                    }`}
                  />
                  {error && (
                    <div className="CustomInput-alarmMessage CustomInput-alarmMessage--absolutePositioned">{error}</div>
                  )}
                </div>
              </Tooltip>
            </Col>
          </Col>
          <Col span={3} md={3} lg={3}>
            <Button type="primary" disabled={error || !searchString} onClick={this.fetchSanctionersList}>
              {translate('core.buttonTitles.search')}
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  fetchSanctionersList = async () => {
    const { translate } = this.props;
    const { searchString } = this.state;

    const error = Validator.validate(translate)(searchString);

    if (error) {
      this.setState({ error });
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const sanctionsList = await api.searchNameBySanction(`%${searchString}%`);
      await this.setState({ sanctionsList: this.normalizeSanctionsList(sanctionsList), isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { sanctionsList, isLoading } = this.state;
    return (
      <>
        <Grid
          data={sanctionsList}
          name="searchSanctionTerroristGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchSanctionersList}
          isLoading={isLoading}
        >
          <GridColumn field="Id" filter="numeric" title="grids.search-lists-sanctioners.column-id" width="220px" />
          <GridColumn field="TypeId" title="grids.search-lists-sanctioners.column-typeId" width="200px" />
          <GridColumn field="NameEn" title="grids.search-lists-sanctioners.column-nameEn" width="200px" />
          <GridColumn field="NameRu" title="grids.search-lists-sanctioners.column-nameRu" width="220px" />
          <GridColumn field="NameUk" title="grids.search-lists-sanctioners.column-nameUk" width="220px" />
          <GridColumn
            field="DateBd"
            filter="date"
            title="grids.search-lists-sanctioners.column-dateBd"
            width="200px"
            format="dd.MM.yyyy"
          />
          <GridColumn
            field="TaxerId"
            filter="numeric"
            title="grids.search-lists-sanctioners.column-taxerId"
            width="270px"
          />
          <GridColumn
            field="PercentMatch"
            filter="numeric"
            title="grids.search-lists-sanctioners.column-percentMatch"
            width="270px"
          />
        </Grid>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(SearchSanctionsTerrorist);
