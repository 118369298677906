import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, message, Select } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService, StorageService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import '../modalProfile.scss';

const api = new ApiService();

const { Option } = Select;

class ModalAssignBusinessService extends PureComponent {
  state = {
    isLoading: false,
    loading: false,
    selected: null,
  };

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    businessServices: PropTypes.arrayOf(PropTypes.any),
    MainProfileIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  assignBusinessService = async () => {
    const { closeModal, showError, update, translate, MainProfileIds } = this.props;
    const { selected } = this.state;
    try {
      this.setState({ loading: true });
      const model = {
        MainProfileHoldId: selected,
        MainProfileIds,
      };
      const response = await api.postMainProfileHoldSet(model);
      closeModal();
      message.success(`${translate('page.screenControlBusinessServices.modal-assign-template-success')}`, 2.5);
      update();
      StorageService.removeItem('selectionListForGrid-screenControlAssignBusinessServices');
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { translate, visible, closeModal, MainProfileIds, businessServices = [] } = this.props;
    const { loading, isLoading } = this.state;
    const defaultTemplateValue = translate('page.screenControlBusinessServices.default-template-value');
    const noSelected = !MainProfileIds || MainProfileIds.length < 1;
    return (
      <>
        <Modal
          title={translate('grids.profilesMainProfileList.modal-assign-template-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!MainProfileIds || MainProfileIds.length < 1}
              loading={loading}
              onClick={this.assignBusinessService}
            >
              {translate('grids.profilesMainProfileList.modal-assign-template-btn-submit')}
            </Button>,
          ]}
        >
          {/* START FIRST SELECT BLOCK */}
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>
                {translate('page.screenControlBusinessServices.modal-assign-template-text')}
              </p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                placeholder={defaultTemplateValue}
                optionFilterProp="children"
                showSearch
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                filterOption={true}
              >
                {businessServices.map((businessService) => (
                  <Option
                    value={`${businessService.MainProfileHoldId}`}
                    key={`assign-business-service-${businessService.MainProfileHoldId}`}
                  >
                    {businessService.MainProfileHoldName} - {businessService.MainProfileHoldVersion}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={24}>
              {noSelected && (
                <p
                  style={{
                    color: 'red',
                    marginBottom: 0,
                    marginTop: '10px',
                  }}
                >
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              )}
            </Col>
          </Row>
          {/* END FIRST SELECT BLOCK */}
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalAssignBusinessService);
