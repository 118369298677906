import React, { PureComponent } from 'react';
import Grid from '../../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, Row, Input, message } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import ModalAdditionalLimits from './ModalAdditionalLimits/ModalAdditionalLimits';

import './AdditionalLimits.scss';
import { ApiService } from '../../../../services';
import FormStateContainer from '../../../../components/FormStateContainer';
import findArray from '../../../../utils/findArrForCurrentPage';
import checkElement from '../../../../utils/checkElement';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import TextArea from 'antd/es/input/TextArea';

const api = new ApiService();
class AdditionalLimits extends PureComponent {
  currentArray = findArray('serviceManagement');
  state = {
    gridData: [],
    typeModal: '',
    isLoading: false,
    selectedItem: [],
  };

  buildToolbar = () => {
    const { selectedItem } = this.state;
    const {
      translate,
      dataItem: { Name, ServiceId },
    } = this.props;
    return (
      <Row span={23} md={17} lg={14}>
        <Row type="flex">
          <Col span={24} md={16} lg={14}>
            <Row type="flex" align="middle" className="AdditionalLimits-row">
              <Col className="AdditionalLimits-label">
                <label>{translate('page.additionalLimits.serviceId')}</label>
              </Col>
              <Col span={16} md={16}>
                <Input value={ServiceId} disabled className="AdditionalLimits-input" />
              </Col>
            </Row>
            <Row type="flex" align="middle" className="AdditionalLimits-row">
              <Col className="AdditionalLimits-label">
                <label>{translate('page.additionalLimits.service-name')}</label>
              </Col>
              <Col span={16} md={16}>
                <TextArea value={Name} disabled autoSize className="AdditionalLimits-input" />
              </Col>
            </Row>
          </Col>
          <Col span={24} md={20} lg={20}>
            <Button.Group onClick={(evt) => this.handleClick(evt)} className="AdditionalLimits-btnGroup">
              {checkElement('serviceManagement-additionalLimitsAdd', this.currentArray) && (
                <Button type="primary" name="addLimits">
                  {translate('core.buttonTitles.add')}
                </Button>
              )}
              {checkElement('serviceManagement-additionalLimitsChange', this.currentArray) && (
                <Button type="primary" name="changeLimits" disabled={!selectedItem.length}>
                  {translate('core.buttonTitles.change')}
                </Button>
              )}
              {checkElement('serviceManagement-additionalLimitsDelete', this.currentArray) && (
                <Button type="primary" name="deleteLimits" disabled={!selectedItem.length}>
                  {translate('core.buttonTitles.delete')}
                </Button>
              )}
            </Button.Group>
          </Col>
        </Row>
      </Row>
    );
  };

  handleClick = (evt) => {
    this.toggleModal(evt.target.name);
  };

  onSelectItem = (selectedItem) => {
    this.setState({ selectedItem });
  };

  toggleModal = (name) => {
    this.setState({ typeModal: name });
  };
  closeModal = () => {
    this.setState({ typeModal: '' });
  };

  fetchAdditionalServiceLimit = async () => {
    const {
      dataItem: { ServiceId },
    } = this.props;
    try {
      await this.setState({ isLoading: true });
      const gridData = await api.getAdditionalServiceLimit(ServiceId);
      await this.setState({ gridData });
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  additionalServiceLimitDelete = async () => {
    const { selectedItem } = this.state;
    const {
      dataItem: { ServiceId },
      translate,
    } = this.props;
    const { Code, AmountMin, AmountMax } = selectedItem[0];

    try {
      const model = {
        ServiceId,
        Code,
        AmountMin,
        AmountMax,
      };
      this.setState({ isLoading: true });
      this.closeModal();
      await api.additionalServiceLimitDelete(model);
      message.success(`${translate('page.additionalLimits.successfulDelete')}`, 2);
      await this.fetchAdditionalServiceLimit();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchAdditionalServiceLimit();
  }

  getInitialValues = () => {
    const { selectedItem, typeModal } = this.state;

    return selectedItem.length && typeModal === 'changeLimits'
      ? {
          AmountMin: selectedItem[0].AmountMin,
          AmountMax: selectedItem[0].AmountMax,
        }
      : { AmountMin: '', AmountMax: '' };
  };

  render() {
    const { gridData, isLoading, typeModal, selectedItem } = this.state;
    const {
      dataItem: { ServiceId },
      translate,
    } = this.props;

    return (
      <>
        <Grid
          data={gridData}
          name="additionalLimitsGrid"
          onRefresh={this.fetchAdditionalServiceLimit}
          fieldForSelection="Name"
          toolbar={this.buildToolbar()}
          onSelect={this.onSelectItem}
          isLoading={isLoading}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="Name" title="grids.additionalLimits.column-payment-type" width="150px" />
          <GridColumn field="AmountMin" title="grids.additionalLimits.column-min" width="150px" />
          <GridColumn field="AmountMax" title="grids.additionalLimits.column-max" width="150px" />
        </Grid>

        {(typeModal === 'addLimits' || typeModal === 'changeLimits') && (
          <FormStateContainer initialValues={this.getInitialValues()}>
            <ModalAdditionalLimits
              closeModal={this.closeModal}
              typeModal={typeModal}
              serviceId={ServiceId}
              selectedItem={selectedItem.length ? selectedItem[0] : []}
              update={this.fetchAdditionalServiceLimit}
              gridData={gridData}
            />
          </FormStateContainer>
        )}

        {typeModal === 'deleteLimits' && (
          <DeleteModal
            modalTitle={translate('page.additionalLimits.modalTitle-deleteLimits')}
            visible={typeModal === 'deleteLimits'}
            closeModal={this.closeModal}
            submitCallback={this.additionalServiceLimitDelete}
            deleteContent={translate('page.additionalLimits.areYouSure')}
          />
        )}
      </>
    );
  }
}
export default withTranslate(AdditionalLimits);
