import React, { PureComponent } from 'react';
import { Button, message, Modal, InputNumber } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import SuppliersBalanceGridService from './SuppliersBalanceGrid.service';

const api = new ApiService();

class ChangeLimitModal extends PureComponent {
  state = {
    isLoading: false,
    limit: null,
    error: '',
  };

  componentDidMount() {
    const {
      dataItem: { Limit },
    } = this.props;

    this.onLimitChange(Limit);
  }

  onLimitChange = (limit) => {
    const { translate } = this.props;
    this.setState({ error: SuppliersBalanceGridService.getValidateLimit(translate)(limit) }, () => {
      this.setState({ limit });
    });
  };

  updateLimit = async () => {
    const { limit } = this.state;
    const {
      dataItem: { ServiceId: serviceId },
      closeModal,
      onRefresh,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.updateServiceBalanceLimit({ serviceId, limit });
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate(`page.suppliersBalance.sumLimitUpdatingSuccessfully`), 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { limit, error, isLoading } = this.state;
    const { translate, dataItem, closeModal } = this.props;

    return (
      <Modal
        visible={dataItem}
        onCancel={closeModal}
        title={translate('page.suppliersBalance.sumLimitChanging')}
        className="ChangeLimitModal"
        footer={[
          <Button type="primary" key="submit" onClick={this.updateLimit} loading={isLoading} disabled={error || !limit}>
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="ChangeLimitModal-row">
          <div className="SuppliersBalanceGrid-label">{translate('page.suppliersBalance.col-sumLimit')}</div>
          <div className="SuppliersBalanceGrid-input">
            <InputNumber value={limit} onChange={this.onLimitChange} />
          </div>
          {error && <div className="SuppliersBalanceGrid-error">{error}</div>}
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ChangeLimitModal);
