import React, { PureComponent } from 'react';
import { Button, message } from 'antd';

import ServiceSuppliersService from '../../../../ServiceSuppliers/ServiceSuppliersGrid/ServiceSuppliers.service';
import { ApiService } from '../../../../../services';
import GridDropdown from '../../../../../components/GridDropdown/GridDropdown';
import { withTranslate } from '../../../../../contexts/localContext';
import ServiceIdField from '../ServiceIdField';
import LoadingPanel from '../../../../../components/loader';
import AddSupplierModal from '../../../../../components/AddSupplierModal';

import './ServiceSupplier.styled.scss';

const api = new ApiService();

class ServiceSupplier extends PureComponent {
  state = {
    isLoading: false,
    supplierList: [],
    selectSupplier: [],
    isDialogOpen: false,
  };

  componentDidMount() {
    this.fetchAllSuppliersGQL();
  }

  iterateFetchSuppliersGQL = async (skip, suppliersList) => {
    try {
      const query = `suppliers(take: 500,skip:${skip}){items{supplierId, name} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { supplierList: currentSuppliersList, hasNextPage } = ServiceSuppliersService.getGQLResponse(data);

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(skip + 500, [...suppliersList, ...currentSuppliersList])
        : [...suppliersList, ...currentSuppliersList];
    } catch (e) {
      throw e;
    }
  };

  fetchAllSuppliersGQL = async () => {
    try {
      await this.setState({ isLoading: true });
      const supplierList = await this.iterateFetchSuppliersGQL(0, []);
      await this.setState({
        isLoading: false,
        supplierList:
          supplierList && supplierList.length
            ? supplierList
                .reduce(
                  (acc, item) => (acc.find((accItem) => item.supplierId === accItem.supplierId) ? acc : [...acc, item]),
                  [],
                )
                .map(({ supplierId, name }) => ({ Id: supplierId, Name: name }))
            : [],
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  onSupplierSave = async () => {
    const { translate, handleUpdateTabPane, currentServiceId } = this.props;
    const { selectSupplier } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.serviceSupplierAdd({
        serviceId: currentServiceId,
        supplierId: selectSupplier[0],
      });
      await message.success(`${translate('page.servicesBigTable.supplierSuccessfullySaved')}`, 2);
      this.setState({
        isLoading: false,
      });
      handleUpdateTabPane();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { supplierList, selectSupplier, isLoading, isDialogOpen } = this.state;
    const { translate, currentServiceId } = this.props;

    return (
      <>
        <ServiceIdField serviceId={currentServiceId} />
        <div className="StepCreateServiceSupplier-fieldRow">
          <div className="StepCreateServiceSupplier-label">{translate('grids.servicesBigTable.column-supplier')}</div>
          <div className="StepCreateServiceSupplier-inputRow">
            <div className="StepCreateServiceSupplier-input">
              <GridDropdown
                data={supplierList}
                colConfig={[
                  {
                    fieldName: 'Id',
                    titleTranslateSlug: 'page.serviceSupplier.col-number',
                    width: '160',
                    filter: 'numeric',
                    isShowOnMobile: true,
                  },
                  {
                    fieldName: 'Name',
                    titleTranslateSlug: 'page.serviceSupplier.col-name',
                    width: '200',
                    isShowOnMobile: true,
                  },
                ]}
                selectItems={selectSupplier}
                onSave={(selectSupplier) => {
                  this.setState({ selectSupplier });
                }}
                defaultTitle={translate('page.serviceSupplier.selectSupplier')}
                isSingle
              />
            </div>

            <div className="StepCreateServiceSupplier-action">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isDialogOpen: true });
                }}
              >
                ...
              </Button>
            </div>
          </div>
        </div>

        <Button type="primary" disabled={!selectSupplier.length} onClick={this.onSupplierSave}>
          {translate('page.servicesBigTable.saveAndNext')}
        </Button>

        {isDialogOpen && (
          <AddSupplierModal
            visible={isDialogOpen}
            closeModal={() => {
              this.setState({ isDialogOpen: false });
            }}
            onRefresh={() => {
              this.fetchAllSuppliersGQL();
            }}
          />
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ServiceSupplier);
