import React, { PureComponent } from 'react';
import { Card, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import RadioButtonGroup from '../RadioButtonGroup';
import { withTranslate } from '../../contexts/localContext';

class ActionBlockRow extends PureComponent {
  render() {
    const { operator, setOperator, addAction, removeAction, translate } = this.props;

    return (
      <Card className="FilterConstructorModal-card FilterConstructorModal-cardActionContent" size="large">
        <RadioButtonGroup
          value={operator}
          onValueChange={(operator) => {
            setOperator(operator);
          }}
          items={[
            { value: 'and', title: 'grid.filterAndLogic' },
            { value: 'or', title: 'grid.filterOrLogic' },
          ]}
        />

        <Button
          onClick={() => {
            addAction('group');
          }}
        >
          {translate('page.filterConstructorModal.addFilterGroup')}
        </Button>
        <Button
          onClick={() => {
            addAction('expression');
          }}
        >
          {translate('page.filterConstructorModal.addExpression')}
        </Button>

        <Button
          className="FilterConstructorModal-closeExpressionAction"
          onClick={() => {
            if (removeAction) {
              removeAction();
            }
          }}
        >
          <CloseOutlined />
        </Button>
      </Card>
    );
  }
}

export default withTranslate(ActionBlockRow);
