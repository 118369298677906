import React, { PureComponent } from 'react';
import { Modal, Button, Alert } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

class AlertModal extends PureComponent {
  render() {
    const { translate, visible, closeModal } = this.props;

    return (
      <Modal
        title={translate('page.backupButtons.error')}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button type="primary" onClick={closeModal} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        <Alert
          message={translate('page.transactions.enterCorrectReceipt')}
          type="error"
          className="alert-business-service"
          style={{
            marginTop: '15px',
          }}
          // description={this.renderDescription()}
          showIcon
        />
      </Modal>
    );
  }
}

export default withTranslate(AlertModal);
