import React, { Component } from 'react';
import Geocode from 'react-geocode';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { withTranslate } from '../../../contexts/localContext';

Geocode.setApiKey('AIzaSyBYB11ozlIxbbsqAwvWfBIuCY3U7nsSoeQ');
Geocode.enableDebug();
Geocode.setLanguage('uk');

const _ = require('lodash');
const { compose, withProps, lifecycle } = require('recompose');
const { SearchBox } = require('react-google-maps/lib/components/places/SearchBox');

const getInitCoordinates = async (initialLocality, showError) => {
  try {
    const response = await Geocode.fromAddress(initialLocality);
    const defaultBounds = await response.results[0].geometry.location;
    // const lat = Number((+defaultBounds.northeast.lat) + (+defaultBounds.southwest.lat)) / 2;
    // const lng = Number((+defaultBounds.northeast.lng) + (defaultBounds.southwest.lng)) / 2;
    const lat = defaultBounds.lat;
    const lng = defaultBounds.lng;
    return { lat, lng };
  } catch (error) {
    showError(error);
  }
};
// AIzaSyASXcFrGBfL7Hqc4-qB50xvKfL1zGIFvoM
const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBYB11ozlIxbbsqAwvWfBIuCY3U7nsSoeQ&v=3.exp&libraries=geometry,drawing,places&language=uk',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    async componentWillMount() {
      const refs = {};
      const { initialLocality, showError } = this.props;
      const initCenter = await getInitCoordinates(initialLocality, showError);
      this.setState({
        bounds: null,
        center: {
          lat: initCenter && initCenter !== undefined ? initCenter.lat : '',
          lng: initCenter && initCenter !== undefined ? initCenter.lng : '',
        },
        zoom: 15,
        markers: [
          {
            position: {
              lat: initCenter && initCenter !== undefined ? initCenter.lat : '',
              lng: initCenter && initCenter !== undefined ? initCenter.lng : '',
            },
          },
        ],
        places: [],
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onMapClick: async (e) => {
          const obj = JSON.parse(JSON.stringify(e.latLng));
          const strForGeopointState = `${obj.lat},${obj.lng}`;
          const { onChange, onChangeGeopoint } = this.props;
          const decodedPlaces = await Geocode.fromLatLng(obj.lat, obj.lng);
          this.setState(
            {
              markers: [{ position: e.latLng }],
              bounds: null,
              center: e.latLng,
              places: [decodedPlaces.results[0]],
            },
            () => {
              if (decodedPlaces && decodedPlaces.results && decodedPlaces.results[0]) {
                onChange(decodedPlaces.results[0]['formatted_address']);
                onChangeGeopoint(strForGeopointState);
                // if (
                //   // 1. тип street_number для корректного отображения адреса!
                //   // decodedPlaces.results[0]['address_components'][0]['types'][0] !== "street_number" ||
                //   // 2. строка не должна содержать латинских симоволов
                //   checkLatinSymbols(decodedPlaces.results[0]['formatted_address'])
                //   ) {
                //   console.log('TerminalDetails::GoogleMap::onMapClick::decodedPlaces.results[0]:: incorrect street number!');
                //   console.log(decodedPlaces.results[0]);
                // }
              }
            },
          );
        },
        onBoundsChanged: async () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
            zoom: 15,
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: async (param = undefined) => {
          const { onChange, onChangeGeopoint } = this.props;
          let places;
          let nextMarkers;
          const bounds = new window.google.maps.LatLngBounds();
          if (param !== undefined) {
            places = param['formatted_address'];
          } else {
            places = refs.searchBox.getPlaces();
            places.forEach((place) => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            nextMarkers = places.map((place) => ({
              position: place.geometry.location,
            }));
          }
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState(
            {
              center: nextCenter,
              markers: nextMarkers,
              places,
              zoom: 15,
            },
            () => {
              if (places && places !== undefined && places[0] !== undefined && places[0]['formatted_address']) {
                const substrAddress = places[0]['formatted_address'].split();
                const finalAddress = [substrAddress[0], substrAddress[1]].join(',');
                onChange(finalAddress);
                const strForGeopointState = `${places[0].geometry.location.lat()},${places[0].geometry.location.lng()}`;
                // console.log('TerminalDetails::googleMap::onPlacesChanged');
                // console.log(strForGeopointState);
                onChangeGeopoint(strForGeopointState);
              }
            },
          );
          refs.map.fitBounds(bounds);
        },
      });
    },
    // async componentDidUpdate(prevProps,prevState) {
    //   const { initialLocality, showError } = this.props;
    //   const initCenter = await getInitCoordinates(initialLocality,showError);
    //   if (prevProps.initialLocality !== initialLocality) {
    //     this.setState({

    //     });
    //   }
    // }
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const { translate } = props;
  return (
    <>
      <GoogleMap
        ref={props.onMapMounted}
        zoom={props.zoom}
        center={props.center}
        onIdle={props.onBoundsChanged}
        onClick={props.onMapClick}
      >
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder={translate('page.terminalSettings.modal-map-search')}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `27px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              left: '30px',
              top: '33px',
            }}
            // value={props.places.map(({ formatted_address }) => formatted_address )}
          />
        </SearchBox>
        {props.markers &&
          props.markers.map((marker, index) => {
            return <Marker key={index} position={marker.position} />;
          })}
      </GoogleMap>
      <ol>
        {props.places
          ? props.places.map(({ place_id, formatted_address }) => <li key={place_id}>{formatted_address}</li>)
          : ''}
      </ol>
    </>
  );
});

class MapContainer extends Component {
  componentDidMount() {}
  getCurrentCity = () => {
    const { cities, cityId } = this.props;
    const currentCity = cities.filter((el) => el.CityId === cityId);
    if (currentCity[0].CityName === 'Київ') {
      return `${currentCity[0].CityName}`;
    } else if (currentCity[0].RegionName !== '' && currentCity[0].Description !== '') {
      return `${currentCity[0].RegionName},${currentCity[0].Description},${currentCity[0].CityName}`;
    } else if (currentCity[0].RegionName !== '' && currentCity[0].Description === '') {
      return `${currentCity[0].RegionName},${currentCity[0].CityName}`;
    } else if (currentCity[0].RegionName === '') {
      return `${currentCity[0].CityName}`;
    }
  };
  render() {
    const { currentAddress, onChange, onChangeGeopoint, showError, translate } = this.props;
    const currentCity = this.getCurrentCity();
    const initialLocality = `${currentCity},${currentAddress}`;
    // console.log('GoogleMapContainer::consts');
    // console.log(initialLocality);
    // console.log(currentAddress);
    return (
      <MapWithASearchBox
        initialLocality={initialLocality}
        onChange={onChange}
        onChangeGeopoint={onChangeGeopoint}
        showError={showError}
        translate={translate}
      />
    );
  }
}

export default withTranslate(MapContainer);
