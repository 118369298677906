class Validator {
  validateNumberFormat = (translate) => (value) => {
    if (`${value}`.match(/[^0-9.,-]/)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }
    return '';
  };

  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  validateFieldsLength = (translate) => (value) => {
    if (!value) {
      return;
    }

    const validateNumberFormat = this.validateNumberFormat(translate)(value);
    if (validateNumberFormat) return validateNumberFormat;
    const regexp = new RegExp(`^-?(0|[1-9]\\d*)(\\.\\d{0,2})?$`);
    const regexpWithComma = new RegExp(`^-?(0|[1-9]\\d*)(\,\\d{0,2})?$`);

    if (!regexp.test(value) && !regexpWithComma.test(value)) {
      return translate('page.commissionsTechnicalPartner.error-fields-length');
    }
  };

  getValidators = (translate) => {
    return {
      ConstAmount: this.validateFieldsLength(translate),
      Percent: this.validateFieldsLength(translate),
      MinAmount: this.validateFieldsLength(translate),
      MaxAmount: this.validateFieldsLength(translate),
    };
  };
}

export default new Validator();
