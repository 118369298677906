import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import Commission from './Commission/CommissionContainer';
import PageTabs from '../../components/tabber/tabber';
import CreateCommission from './CreateCommission/CreateCommissionContainer';
import UpdateCommission from './UpdateCommission/UpdateCommissionContainer';
import CommissionFormMethodsContainer from '../../components/CommisionDetailsForm/CommissionFormMethodsContainer';

class PageCommission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.managementExternalCommissions.commissions',
          content: (
            <CommissionFormMethodsContainer>
              <Commission />
            </CommissionFormMethodsContainer>
          ),
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'createCommission':
        tabs.push({
          title: 'page.comissions.addCommission',
          content: (
            <CommissionFormMethodsContainer>
              <CreateCommission />
            </CommissionFormMethodsContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      case 'editCommission':
        tabs.push({
          title: 'page.comissions.editCommission',
          content: (
            <CommissionFormMethodsContainer>
              <UpdateCommission passedFormState={dataItem} />
            </CommissionFormMethodsContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageCommission);
