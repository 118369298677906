import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import Restrictions from './restrictions';
import GridAdmin from './gridAdmin';

class PageAdmin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.adminPanel.tab-users',
          content: <GridAdmin />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'restrictions':
        tabs.push({
          title: 'page.adminPanel.tab-restrictions',
          content: <Restrictions userInfo={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageAdmin);
