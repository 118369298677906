import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import findArray from '../../utils/findArrForCurrentPage';
import CellBoolean from '../account/cellBoolean';
import ModalAddCheckSending from './modalAddCheckSending';
import ModalEditCheckSending from './modalEditCheckSending';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class GridSendingCheck extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('encashment');
  }

  state = {
    isLoading: false,
    data: [],
    owners: [],
    allOwners: [],
    visible: false,
    isModalEdit: false,
    dataItemEditModal: null,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  makeQuery = async () => {
    const { owners } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const data = await api.collectionReceiptSendInfo(owners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdown
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          colConfig={OWNERS_COLUMN_CONFIG}
          selectItems={owners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />

        <Button type="primary" style={{ margin: '15px 0 10px 0' }} onClick={this.toggleModal}>
          {translate('grids.availableServices.button-add')}
        </Button>
      </Col>
    );
  };

  onDeleteCollector = async (props) => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const request = await api.collectionReceiptSendDelete(Number(props.dataItem.Id));
      this.makeQuery();
      return request;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  toggleModalEdit = (props) => {
    this.setState(({ isModalEdit }) => {
      return {
        dataItemEditModal: props && props !== undefined ? props.dataItem : null,
        isModalEdit: !isModalEdit,
      };
    });
  };

  getHandlers = () => {
    return [
      {
        title: 'grids.profilesMainProfileList.modal-delete-profile-btn-submit',
        action: (props) => this.onDeleteCollector(props),
        dropdown: false,
        show: true,
      },
      {
        title: 'page.screenControlBusinessServices.button-edit',
        action: (props) => this.toggleModalEdit(props),
        dropdown: false,
        show: true,
      },
    ];
  };

  render = () => {
    const { isLoading, data, visible, owners, isModalEdit, dataItemEditModal } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="encashmentSendingCheck"
          permissionName="encashment"
          isShowSpecificFilterByFieldName="TerminalId"
        >
          <GridColumn field="OwnerName" title="grids.terminalOperabilityChoose.column-ownerName" width="150" />

          <GridColumn
            field="TerminalId"
            title="grids.transactions.column-terminalId"
            width="180"
            filter="numeric"
            handlers={this.getHandlers()}
            dropdown={true}
            remoteCommands={false}
          />

          <GridColumn field="UserId" title="grids.allEncashments.column-userId" width="150" />

          <GridColumn field="Emails" title="page.profile.label-email" width="350" />

          <GridColumn
            field="DatePost"
            title="grids.profilesMainProfileList.column-dateOfChange"
            width="200"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="UserNmaePost" title="grids.profilesMainProfileList.column-executor" width="150" />

          <GridColumn field="Active" title="grids.terminals.check-active" width="150" cell={WrappedCellBoolean} />
        </Grid>

        {visible && (
          <ModalAddCheckSending
            visible={visible}
            currentTerminalData={data}
            owners={owners}
            update={this.makeQuery}
            toggleModal={this.toggleModal}
            closeModal={this.closeModal}
          />
        )}
        {isModalEdit && (
          <ModalEditCheckSending
            visible={isModalEdit}
            toggleModal={this.toggleModalEdit}
            update={this.makeQuery}
            dataItemEditModal={dataItemEditModal}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridSendingCheck);
