import React, { PureComponent } from 'react';
import { Card } from 'antd';

import { translateSlugs } from './constants';
import CustomInput from '../../../../components/Input/Input';
import SupplierCommissionsService from './SupplierСommissions.service';
import { withTranslate } from '../../../../contexts/localContext';

import './SimpleSupplierСommissionsForm.styled.scss';

class SupplierCommissions extends PureComponent {
  renderInput = ({ name }) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="SupplierCommissions-inputRow">
        <div className="SupplierCommissions-label">{translate(translateSlugs[name])}</div>
        <div className="SupplierCommissions-input">
          <CustomInput
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            setError={onError}
            validators={SupplierCommissionsService.getValidators(translate)}
            name={name}
          />
        </div>
      </div>
    );
  };

  render() {
    const { translate } = this.props;

    return (
      <div className="SupplierCommissions">
        <Card
          type="inner"
          className="SupplierCommissions-cardContent"
          title={translate('page.recalculationTransactions.modal-title-supplier-commissions')}
        >
          {this.renderInput({ name: 'commissionPercent', isShortLabel: true })}
          {this.renderInput({ name: 'commissionMin', isShortLabel: true })}
          {this.renderInput({ name: 'commissionMax', isShortLabel: true })}
          {this.renderInput({ name: 'commissionCalculationBase', isShortLabel: true })}
        </Card>
        <Card
          type="inner"
          className="SupplierCommissions-cardContent"
          title={translate('page.recalculationTransactions.modal-title-supplier-card-commissions')}
        >
          {this.renderInput({ name: 'commissionCardPercent', isShortLabel: true })}
          {this.renderInput({ name: 'commissionCardMin', isShortLabel: true })}
          {this.renderInput({ name: 'commissionCardMax', isShortLabel: true })}
          {this.renderInput({ name: 'commissionCardCalculationBase', isShortLabel: true })}
        </Card>
      </div>
    );
  }
}

export default withTranslate(SupplierCommissions);
