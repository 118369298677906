import React from 'react';
import { MAX_VAlUE, MIN_VAlUE } from '../../../../components/DealerCommissionForm/constants';

class Validators {
  baseValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (`${value}`.match(/[^0-9.]/)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (value === '0') {
      return translate('page.additionalLimits.error-min-max-value');
    }

    if (value.toString()[0] === '0' && value.toString()[1] !== '.') {
      return translate('page.dealerCommissionsChange.error-starting-with-zero');
    }

    const regexp = new RegExp(`^-?(0|[1-9]\\d*)(\[\.,]\\d{0,2})?$`);

    if (!(regexp.test(value) || value === '')) {
      return translate('page.additionalLimits.error-rounded');
    }

    if (!(this.getParsedValue(value) >= 0.01 && this.getParsedValue(value) <= 9999999.99)) {
      return translate('page.additionalLimits.error-min-max-value');
    }

    return '';
  };

  validationIsBiggest = (translate) => (formData) => {
    const { AmountMin, AmountMax } = formData;
    console.log('AmountMin', AmountMin);
    console.log('AmountMax', AmountMax);

    return this.getParsedValue(AmountMin) > this.getParsedValue(AmountMax)
      ? translate('page.additionalLimits.error-bigger')
      : '';
  };

  getParsedValue = (value) => (value || value === 0 ? +`${value}` : null);

  getObjectValidation = (translate) => {
    return {
      AmountMin: this.baseValidation(translate),
      AmountMax: this.baseValidation(translate),
    };
  };
}
export default new Validators();
