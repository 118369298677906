import {
  PAYMENT_TYPES_COLUMN_CONFIG,
  OWNERS_COLUMN_CONFIG,
  PROFILES_COLUMN_CONFIG,
  COMMISSIONS_COLUMN_CONFIG,
} from '../../../../../core/constants/configs.const';

export const TRANSLATE_SLUGS = {
  owners: 'page.commissionsServices.dealer',
  paymentTypes: 'page.commissionsServices.paymentType',
  profiles: 'page.commissionsServices.profile',
  commissionsList: 'page.commissionsServices.commission',
};

export const CONFIGS = {
  owners: OWNERS_COLUMN_CONFIG,
  paymentTypes: PAYMENT_TYPES_COLUMN_CONFIG,
  profiles: PROFILES_COLUMN_CONFIG,
  commissionsList: COMMISSIONS_COLUMN_CONFIG,
};

export const DEFAULT_TITLES = {
  owners: 'page.dealerCommissions.selectDealer',
  paymentTypes: 'page.commissionsServices.choosePaymentType',
  profiles: 'page.commissionsServices.chooseProfile',
  commissionsList: 'page.commission.selectCommission',
};
