class ValidationService {
  getValidatePayment = (translate) => (value) => {
    if (value < 0.01) {
      return `${translate('grids.comission-sub.errorMessages.paymentMinRequired')} 0.01`;
    }

    if (value > 999999999.99) {
      return `${translate('grids.comission-sub.errorMessages.paymentMaxRequired')} 999 999 999.99`;
    }

    return null;
  };

  getValidateNonRequired = (translate) => (value) => {
    if (!value) {
      return;
    }

    return this.getValidatePayment(translate)(value);
  };

  percentageValidation = (translate) => (value) => {
    if (!value) {
      return;
    }

    if (value < 0.01) {
      return `${translate('grids.comission-sub.errorMessages.percentageMinRequired')} 0.01`;
    }

    if (value > 100) {
      return `${translate('grids.comission-sub.errorMessages.percentageMaxRequired')} 100.0`;
    }

    return null;
  };

  valueValidation = (translate) => (value) => {
    if (!Number.isInteger(value) || `${value}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    if (value < 0.01) {
      return `${translate('grids.comission-sub.errorMessages.valueMinRequired')} 1`;
    }

    if (value > 100) {
      return `${translate('grids.comission-sub.errorMessages.valueMaxRequired')} 100`;
    }

    return null;
  };

  differenceValidation = (maxLimit, minLimit, errorMessage) => {
    if (minLimit - maxLimit > 0) {
      return errorMessage;
    }

    return null;
  };

  checkResultExisting = (ResultFixed, ResultPercent, errorMessage) =>
    (!ResultFixed || ResultFixed === '0') && (!ResultPercent || ResultPercent === '0') ? errorMessage : '';

  getValidators = (translate) => {
    return {
      AmountFrom: this.getValidatePayment(translate),
      AmountTo: this.getValidatePayment(translate),
      ResultFixed: this.getValidateNonRequired(translate),
      ResultPercent: this.percentageValidation(translate),
      CommissionMin: this.getValidateNonRequired(translate),
      CommissionMax: this.getValidateNonRequired(translate),
      Priority: this.valueValidation(translate),
    };
  };
}

export default new ValidationService();
