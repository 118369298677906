import dayjs from 'dayjs';

const getTranslation = (translationCallback) => (slug) => translationCallback(`core.time.abbreviated.${slug}`);

export const getDateDifferenceString = (decreasingDate, deductingDate, translationCallback) => {
  if (!decreasingDate || !deductingDate || !translationCallback) {
    return '';
  }

  const HOURS_ROUND_NUMBER = 24;
  const MINUTES_ROUND_NUMBER = 60;
  const translation = getTranslation(translationCallback);
  const decreasingDateAPI = dayjs(decreasingDate);
  const timeUnits = ['day', 'hour', 'minute', 'second'];
  const {
    day: days,
    hour: hours,
    minute: minutes,
    second: seconds,
  } = timeUnits.reduce(
    (acc, timeUnit) => ({ ...acc, [timeUnit]: decreasingDateAPI.diff(deductingDate, timeUnit) }),
    {},
  );

  if (days) {
    const hoursRestAmount = hours % HOURS_ROUND_NUMBER;
    const hoursRestAmountString = `${hoursRestAmount ? `${hoursRestAmount}${translation('hours')}` : ''}`;

    return `${days}${translation('days')} ${hoursRestAmountString}`;
  }

  if (hours) {
    const minutesRestAmount = minutes % MINUTES_ROUND_NUMBER;
    const minutesRestAmountString = `${minutesRestAmount ? `${minutesRestAmount}${translation('minutes')}` : ''}`;

    return `${hours}${translation('hours')} ${minutesRestAmountString}`;
  }

  if (minutes) {
    return `${minutes}${translation('minutes')}`;
  }

  if (seconds) {
    return `${minutes}${translation('justNow')}`;
  }

  return '';
};
