import React, { PureComponent } from 'react';
import { message } from 'antd';

import FormStateContainer from '../../../../../components/FormStateContainer';
import GeneralProps from './GeneralProps';
import AddServiceService from '../../AddService/AddService.service';
import { ApiService } from '../../../../../services';
import LoadingPanel from '../../../../../components/loader';
import { withTranslate } from '../../../../../contexts/localContext';

const api = new ApiService();

class GeneralPropsContainer extends PureComponent {
  state = {
    serviceTypeList: [],
    initialValues: null,
    isLoading: false,
  };

  componentDidMount() {
    this.initial();
  }

  initial = async () => {
    await this.generateServiceId();
    await this.fetchServiceTypes();
  };

  fetchServiceTypes = async () => {
    try {
      await this.setState({ isLoading: true });
      const serviceTypes = await api.getServicesTypes();

      this.setState({
        serviceTypeList: AddServiceService.normalizeSelectData(serviceTypes, 'TypeId', 'Name'),
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  generateServiceId = async () => {
    try {
      await this.setState({ isLoading: true });
      const currentServiceId = await api.getNextServiceId();
      await this.setState({ initialValues: { id: currentServiceId, name: '', serviceType: [] }, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleSave = async ({ id: serviceId, name, serviceType: [typeId] }) => {
    const { handleInitialUpdateActiveKey, translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.simpleServiceCreate({ serviceId, name, typeId });
      await message.success(`${translate('page.servicesBigTable.basicPropSuccessfullySaved')}`, 2);
      this.setState({ isLoading: false });
      handleInitialUpdateActiveKey(serviceId);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { serviceTypeList, initialValues, isLoading } = this.state;
    const { currentServiceId } = this.props;

    return (
      <>
        {initialValues ? (
          <FormStateContainer initialValues={initialValues}>
            <GeneralProps
              serviceTypeList={serviceTypeList}
              currentServiceId={currentServiceId}
              handleSave={this.handleSave}
              fetchServiceTypes={this.fetchServiceTypes}
            />
          </FormStateContainer>
        ) : (
          <></>
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(GeneralPropsContainer);
