import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, message, Select, Row } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, StorageService } from '../../../services';
// import DropDownProfiles from '../dropdown';
import LoadingPanel from '../../../components/loader';
import './modalChangeProfile.scss';

const api = new ApiService();

const { Option } = Select;

class ModalChangeProfile extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    item: PropTypes.arrayOf(PropTypes.any).isRequired,
    owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    profiles: [],
    selected: null,
    noSelected: false,
  };

  componentDidMount() {
    this.getMainProfileListForModalChangeProfile();
  }

  getMainProfileListForModalChangeProfile = async () => {
    const { owners, showError, item } = this.props;
    if (!item) {
      this.setState({ noSelected: true });
      return;
    }
    if (item.length < 1) {
      this.setState({ noSelected: true });
      return;
    }
    if (!owners || owners.length < 1) return;
    else {
      try {
        this.toggleLoader();
        const profiles = await api.mainProfileListForModalChangeProfile(owners);
        this.setState({ loading: false, profiles });
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  changeProfile = async () => {
    const { selected } = this.state;
    const { item, translate, closeModal, update, name } = this.props;
    if (!item) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: [...new Set(item)],
        MainProfileId: selected,
      };
      const response = await api.changeTerminalsMainProfile(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.screenControlTerminals.change-modal-create-profile-success')}`, 2.5);
      StorageService.setItem(`selectionListForGrid-${name}`, []);
      update();
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  render() {
    const { translate, visible, closeModal, item } = this.props;
    const { loading, profiles, isLoading, noSelected, selected } = this.state;
    const placeholder = translate('page.screenControlBusinessServices.default-profile-value');
    return (
      <>
        <Modal
          title={translate('page.screenControlTerminals.change-profile-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!item || !selected}
              loading={loading}
              onClick={this.changeProfile}
            >
              {translate('page.commission.btn-submit')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>{translate('page.screenControlTerminals.change-profile-text')}</p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                placeholder={placeholder}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                filterOption={(input, option) =>
                  JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="children"
                showSearch
              >
                {profiles.map((profile, i) => (
                  <Option
                    value={`${profile.MainProfileId}`}
                    key={`collector-number-${i.toString()}-${profile.MainProfileId}`}
                  >
                    {profile.MainProfileName} - {profile.MainProfileId}
                  </Option>
                ))}
              </Select>
              {/* <DropDownProfiles profiles={profiles} onChange={this.onSelectChange} /> */}
              {noSelected && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChangeProfile);
