import React, { PureComponent } from 'react';

import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

const api = new ApiService();

class AccessRolesGrid extends PureComponent {
  state = {
    roles: [],
    accessRoles: [],
    isLoading: false,
  };

  fetchRoles = async () => {
    try {
      this.setState({ isLoading: true });
      const roles = await api.getAllRoles();
      this.setState({ roles: roles && roles.length ? roles : [] });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  iterateFetchRoles = async (selectedElements, roles, isInitial) => {
    try {
      const [{ elementKey, elementType }, ...restElements] = selectedElements;

      const currentRoles = await api.UserRolesWtmElements(elementKey, elementType);

      const uniqRoles =
        currentRoles && currentRoles.length
          ? currentRoles.reduce((acc, role) => {
              return acc.find(({ RoleId: accRoleId }) => accRoleId === role.RoleId) ? acc : [...acc, role];
            }, [])
          : [];

      if (isInitial) {
        return restElements.length ? this.iterateFetchRoles(restElements, uniqRoles, false) : uniqRoles;
      }

      const innerJoinedUsers = roles.filter(({ RoleId: accRoleId }) =>
        uniqRoles.find(({ RoleId }) => RoleId === accRoleId),
      );

      return restElements.length ? this.iterateFetchRoles(restElements, innerJoinedUsers, false) : innerJoinedUsers;
    } catch (e) {
      throw e;
    }
  };

  getCommonRoles = async () => {
    const { selectedElements, showError } = this.props;

    try {
      await this.setState({ isLoading: true });

      const accessRoles = await this.iterateFetchRoles(selectedElements, [], true);

      this.setState({ accessRoles, isLoading: false });
    } catch (e) {
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  initData = async () => {
    await this.fetchRoles();
    await this.getCommonRoles();
  };

  componentDidMount() {
    this.initData();
  }

  render() {
    const { accessRoles, isLoading, roles } = this.state;

    return (
      <Grid
        data={roles.filter(({ RoleId }) => accessRoles.find(({ RoleId: AccessRoleId }) => AccessRoleId === RoleId))}
        isLoading={isLoading}
        name="userRolesGrid"
      >
        <GridColumn field="RoleId" title="page.releases.col-roleNumber" width="100" />
        <GridColumn field="RoleName" title="page.releases.col-roleName" width="180" />
        <GridColumn field="ApplicationName" title="page.releases.col-source" width="100" />
        <GridColumn field="RoleDescription" title="page.releases.col-roleDescription" width="1200" />
      </Grid>
    );
  }
}

export default withTranslate(AccessRolesGrid);
