import React, { PureComponent } from 'react';
import { Col, Row, Button, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { mocked } from './mocked';
import DeleteModal from '../../../components/DeleteModal';
import AddCashbackDialog from './AddCashbackDialog';

import './FishkaServiceGrid.scss';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService, tabService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class FishkaServiceGrid extends PureComponent {
  state = {
    cashbacks: [],
    isLoading: false,
    selectedItems: [],
    dialogVariant: '',
  };

  currentArray = findArray('fishkaService');

  getCashbacks = async () => {
    try {
      await this.setState({ isLoading: true });
      const cashbacks = await api.getCashbackFishka();

      await this.setState({ cashbacks: cashbacks && cashbacks.length ? cashbacks : [], isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.getCashbacks();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row className="FishkaServiceGrid-toolbar">
        {checkElement('fishkaService-BtnDelete', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ dialogVariant: 'deleteCashbacks' });
            }}
            disabled={!selectedItems.length}
          >{`${translate('core.buttonTitles.delete')}`}</Button>
        )}

        {checkElement('fishkaService-BtnAdd', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ dialogVariant: 'addCashback' });
            }}
          >{`${translate('core.buttonTitles.add')}`}</Button>
        )}

        {checkElement('fishkaService-historyTabLink', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              tabService.addTab({
                type: 'fishkaServiceHistory',
              });
            }}
          >{`${translate('page.fishkaService.tab-historyGrid')}`}</Button>
        )}
      </Row>
    );
  };

  handleSelectedItemsChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handleDelete = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.cashbackFishkaDelete(selectedItems.map(({ Id }) => Id));
      await message.success(translate('page.fishkaService.removedSuccessfully'), 2);
      await this.setState({ isLoading: false });
      window.location.reload();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { cashbacks, isLoading, dialogVariant, selectedItems } = this.state;
    const { translate } = this.props;

    console.log('Выбранные элементы', selectedItems);

    if (!checkElement('fishkaService-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={cashbacks}
          toolbar={this.buildToolbar()}
          onRefresh={this.getCashbacks}
          name="fishkaServiceGrid"
          isLoading={isLoading}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItemsChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="Id" title="page.fishkaService.col-recordNumber" width="150" filter="numeric" />
          <GridColumn field="ServiceId" title="page.fishkaService.col-serviceID" width="150" filter="numeric" />
          <GridColumn field="ServiceName" title="page.fishkaService.col-serviceName" width="300" />
          <GridColumn field="ServiceGroup" title="page.fishkaService.col-serviceGroup" width="300" />
          <GridColumn field="Status" title="page.fishkaService.col-serviceStatus" width="180" />
          <GridColumn field="CashbackPercent" title="page.fishkaService.col-cashback" width="150" />
          <GridColumn field="UserNamePost" title="page.fishkaService.col-performer" width="300" />
          <GridColumn
            field="DatePost"
            title="page.fishkaService.col-date"
            width="200"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        </Grid>
        {dialogVariant === 'deleteCashbacks' && (
          <DeleteModal
            visible={dialogVariant === 'deleteCashbacks'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            modalTitle={translate('page.fishkaService.deleteModalTitle')}
            deleteContent={translate('page.fishkaService.areYouSure')}
            isLoading={isLoading}
            submitCallback={() => {
              this.handleDelete();
            }}
          />
        )}
        {dialogVariant === 'addCashback' && (
          <AddCashbackDialog
            visible={dialogVariant === 'addCashback'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(FishkaServiceGrid);
