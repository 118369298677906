const terminalsMaintenance = (translate, value) => {
  switch (value) {
    case 'terminalMaintenance-btn-search':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('owner.search')}`;
    case 'terminalMaintenance-grid-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminalMaintenance.column-terminalId')}`;
    case 'terminalMaintenance-grid-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalMaintenance.column-terminalName')}`;
    case 'terminalMaintenance-grid-Region':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminalMaintenance.column-region')}`;
    case 'terminalMaintenance-grid-City':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminalMaintenance.column-city')}`;
    case 'terminalMaintenance-grid-Address':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminalMaintenance.column-address')}`;
    case 'terminalMaintenance-grid-Technician':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminalMaintenance.column-technician')}`;
    case 'terminalMaintenance-grid-MaintenanceDate':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.terminalMaintenance.column-maintenanceDate')}`;
    default:
      return value;
  }
};

export default terminalsMaintenance;
