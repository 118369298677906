import StorageService from './storageService';
import { BASE_API_STRING } from '../core/constants/baseApiString.const';

class OwnerMessagesFetcher {
  isOwnersMessagesFetching = false;

  fetchOwnersMessages = async (axiosInstance) => {
    const refreshToken = StorageService.getItem('refresh_token');
    const accessToken = StorageService.getItem('access_token');

    if (this.isOwnersMessagesFetching) {
      return;
    }

    if (refreshToken && accessToken) {
      this.isOwnersMessagesFetching = true;

      const newMessagesCount = await axiosInstance.post(
        `${BASE_API_STRING}/OwnerMessages/OwnersMessagesCreatedCount`,
        undefined,
        {},
      );

      StorageService.setItem('newMessagesCount', newMessagesCount && newMessagesCount.data ? newMessagesCount.data : 0);

      setTimeout(() => {
        this.isOwnersMessagesFetching = false;
      }, 3000);
    }
  };
}

export default new OwnerMessagesFetcher();
