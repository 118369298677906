import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Carousel, Radio } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import SliderElement from './sliderElement';
import IconNext from './sliderRight.svg';
import IconPrev from './sliderLeft.svg';
import './modalSlider.scss';

const api = new ApiService();

const Right = (props) => {
  const { currentSlide, slideCount, ...arrowProps } = props;
  return <img alt="slider-next" {...arrowProps} src={IconNext} />;
};

const Left = (props) => {
  const { currentSlide, slideCount, ...arrowProps } = props;
  return <img alt="slider-prev" {...arrowProps} src={IconPrev} />;
};

class ModalChangeProfile extends PureComponent {
  static defaultProps = {
    isOwnerPhotos: false,
  };
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    sliderData: PropTypes.arrayOf(PropTypes.any),
    // update: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: false,
      radioValue: this.getInitialRadioValue(),
    };
  }

  getInitialRadioValue() {
    const {
      dataItem: { initialTerminalStatusName, Photos: photos },
    } = this.props;
    const unConsideredPhotos = photos.filter((el) => el.Status === 'NotConsidered');
    if (unConsideredPhotos.length > 0) return null;
    else return initialTerminalStatusName;
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value }, () => this.changeProfile());
  };

  changeProfile = async () => {
    const { radioValue } = this.state;
    const {
      dataItem: { TerminalId },
      closeModal,
      update,
    } = this.props;
    try {
      this.setState({ isLoading: true });
      const model = {
        Terminals: [TerminalId],
        TerminalStatus: radioValue,
      };
      const response = await api.terminalsEstimateUpdate(model);
      // debugger;
      closeModal();
      // message.success(`${translate('page.screenControlTerminals.change-modal-create-profile-success')}`, 2.5);
      // StorageService.setItem(`selectionListForGrid-${name}`, []);
      update();
      return response;
    } catch (err) {
      const { showError } = this.props;
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, sliderData, dataItem, update, toggleModal, isOwnerPhotos } = this.props;
    const { isLoading, radioValue } = this.state;
    return (
      <>
        <Modal
          // title={}
          visible={visible}
          width={300}
          centered
          onOk={() => {}}
          onCancel={closeModal}
          className="modal-slider"
          zIndex={'1053'}
          footer={null}
        >
          <Carousel
            draggable
            // adaptiveHeight
            arrows
            nextArrow={<Right />}
            prevArrow={<Left />}
          >
            {sliderData.map((elem, idx) => {
              return (
                <SliderElement
                  key={`${elem.FileName}-${idx}`}
                  elem={elem}
                  closeModal={closeModal}
                  toggleModal={toggleModal}
                  dataItem={dataItem}
                  update={update}
                />
              );
            })}
          </Carousel>
          {!isOwnerPhotos && (
            <div className="modal-slider-footer">
              <Radio.Group value={radioValue} onChange={this.onChangeRadio} className="modal-slider-radio-group">
                <Radio key="radio-Branded" value="Branded">
                  {translate('grids.adminStockPhotos.grid-status-value-branded')}
                </Radio>
                <Radio key="radio-BrandedByStandart" value="BrandedByStandart">
                  {translate('grids.adminStockPhotos.grid-modal-slider-byStandart')}
                </Radio>
                <Radio key="radio-NotBranded" value="NotBranded">
                  {translate('grids.adminStockPhotos.grid-modal-slider-decline')}
                </Radio>
              </Radio.Group>
            </div>
          )}
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChangeProfile);
