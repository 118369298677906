import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import CustomInput from '../../../components/Input/Input';
import SupplierUserService from './SuppliersUsers.service';

import '../SupplierProps/SupplierProps.styled.scss';

const api = new ApiService();

class UpdateSupplierUserModal extends PureComponent {
  state = {
    email: '',
  };

  renderInputRow = ({ label, inputComponent }) => {
    const { translate } = this.props;

    return (
      <div className="SupplierProps-inputRow">
        <div className="SupplierProps-label">{label ? translate(label) : ''}</div>
        <div className="SupplierProps-input">{inputComponent}</div>
      </div>
    );
  };

  renderInput = (name, type, isHandleDisabled = false, props) => {
    const { translate, formState, formErrors, onError, onChangeFormState } = this.props;

    return (
      <CustomInput
        type={type}
        formState={formState}
        disabled={isHandleDisabled}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        validators={SupplierUserService.getValidators(translate)}
        name={name}
        {...(props ? { ...props } : {})}
      />
    );
  };

  updateUser = async () => {
    const {
      variant,
      supplierId,
      formState: { accountId, email, phone, passwordText },
      onRefresh,
      closeModal,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const method = variant === 'addUser' ? await api.addProviderPortalAccount : api.updateProviderPortalAccount;
      const model =
        variant === 'addUser'
          ? { email, phone: SupplierUserService.getPurePhone(phone), passwordText, mainSupplierId: supplierId }
          : {
              accountId,
              email,
              phone: SupplierUserService.getPurePhone(phone),
              passwordText,
              mainSupplierId: supplierId,
            };
      const successfulMessage = `page.serviceSupplier.${
        variant === 'addUser' ? 'userSuccessfulAdded' : 'userSuccessfulEdited'
      }`;

      await method(model);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate(successfulMessage), 2);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { variant, closeModal, translate, formState, formErrors } = this.props;
    const { isLoading } = this.state;
    const isRequiredFieldEmpty = Object.entries(formState).some(([key, value]) => key !== 'passwordText' && !value);
    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.serviceSupplier.${variant === 'addUser' ? 'addUser' : 'editUser'}`)}
        footer={[
          <Button key="back" type="primary" onClick={this.updateUser} disabled={isRequiredFieldEmpty || isErrorExist}>
            {translate(`core.buttonTitles.${variant === 'addUser' ? 'add' : 'edit'}`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderInputRow({
          label: 'page.serviceSupplier.supplier',
          inputComponent: this.renderInput('supplier', 'text', true),
        })}
        {this.renderInputRow({
          label: 'page.serviceSupplier.col-email',
          inputComponent: this.renderInput('email'),
        })}
        {this.renderInputRow({
          label: 'page.serviceSupplier.col-mobPhone',
          inputComponent: this.renderInput('phone', 'phone', false, {
            mask: '00 ( 000 ) - 000 - 00 - 00',
          }),
        })}
        {this.renderInputRow({
          label: 'page.serviceSupplier.password',
          inputComponent: this.renderInput('passwordText'),
        })}

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateSupplierUserModal);
