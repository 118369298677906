import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';

class PreviewTemplateReceipt extends PureComponent {
  state = {};

  render() {
    const { tabData } = this.props;
    return (
      <div className="PreviewTemplateReceipt-container" dangerouslySetInnerHTML={{ __html: tabData.receiptTemplate }} />
    );
  }
}

export default withTranslate(PreviewTemplateReceipt);
