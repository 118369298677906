const handlingCommissions = (translate, value) => {
  switch (value) {
    case 'handlingСommissions-btn-addCommission':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.commission.btn-add')}`;
    case 'handlingСommissions-btn-deleteCommission':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.commission.btn-delete')}`;
    case 'handlingСommissions-btn-updateCommission':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.commission.btn-edit')}`;
    case 'handlingСommissions-btn-saveCommission':
      return `${translate(`page.adminPanel.type-tab`)} ${translate(`page.comissions.addCommission`)}: ${translate(
        `page.adminPanel.type-button`,
      )} ${translate('page.commission.btn-save')}`;

    case 'handlingСommissions-btn-editCommission':
      return `${translate(`page.adminPanel.type-tab`)} ${translate(`page.comissions.editCommission`)}: ${translate(
        `page.adminPanel.type-button`,
      )} ${translate('page.commission.btn-save')}`;
    default:
      return value;
  }
};

export default handlingCommissions;
