import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../contexts/localContext';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import './GridRoles.scss';

class GridRoles extends PureComponent {
  constructor(props) {
    super(props);
    const { roleList } = props;
    this.state = {
      data: roleList,
    };
  }
  render = () => {
    const { currentSelectItems = [], onRowClick, translate } = this.props;
    const { data } = this.state;
    return (
      <Grid
        className="GridRoles"
        data={data.map((item) => ({ ...item, selected: currentSelectItems.includes(item.RoleId) }))}
        selectedField="selected"
        total={data.length}
        rowHeight={40}
        onRowClick={onRowClick}
        onSelectionChange={onRowClick}
        fieldForSelection="RoleName"
        onSelect={onRowClick}
        multiSelected
        resizable
      >
        <GridColumn field="selected" width="50px" />
        <GridColumn field="RoleName" title={translate('page.groupMembership.col-roleName')} width="400px" />
      </Grid>
    );
  };
}
export default withTranslate(GridRoles);
