import { BANK_LIST_GRID_COLUMNS } from './constants';
import { ApiService } from '../../services';

const api = new ApiService();

class FetchBankListService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.cardRoutingBanks) {
      return { bankList: [], hasNextPage: false };
    }
    const bankInfo = data.data.cardRoutingBanks;
    const pageInfo = bankInfo.pageInfo;

    return {
      bankList:
        bankInfo.items && bankInfo.items.length
          ? bankInfo.items.map(({ bankName, bankServiceId }) => ({
              bankName,
              bankServiceId: bankServiceId || 'null',
              editId: `${bankName}-${bankServiceId}`,
            }))
          : [],
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  fetchBankListGQL = async (skip, accBankList) => {
    const query = `cardRoutingBanks (take: 500,skip:${skip},order:{bankName: ASC}){items{${BANK_LIST_GRID_COLUMNS.join(
      ',',
    )}} pageInfo{hasNextPage, hasPreviousPage}}`;

    try {
      const data = await api.getByGraphQl(query);

      const { bankList, hasNextPage } = this.getGQLResponse(data);

      return hasNextPage
        ? this.fetchBankListGQL(skip + 500, [...accBankList, ...bankList])
        : [...accBankList, ...bankList];
    } catch (e) {
      throw e;
    }
  };
}

export default new FetchBankListService();
