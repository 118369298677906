import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ApiService, StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import ModalStockChangeProfile from '../../adminStockPhotos/modalStockChangeProfile';
// import checkElement from '../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import CellPhotos from '../../adminStockPhotos/cellPhotos';
import CellStockStatus from '../../adminStockPhotos/cellStockStatus';
import '../../adminStockPhotos/gridAdminStockPhotos.scss';

const api = new ApiService();

const { Option } = Select;

const getOwnerId = (item) => {
  const { OwnerId } = item;
  if (OwnerId) return OwnerId;
  else return;
};

class SubGridAdminStockPhotos extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('subAdminStockPhotos');
  }

  photosStatuses = [
    {
      key: 'All',
      value: 'grids.adminStockPhotos.stockStatus-all',
    },
    {
      key: 'Considered',
      value: 'grids.adminStockPhotos.stockStatus-considered',
    },
    {
      key: 'NotConsidered',
      value: 'grids.adminStockPhotos.stockStatus-unconsidered',
    },
    {
      key: 'BrandedByStandart',
      value: 'grids.adminStockPhotos.stockStatus-brandedByStandart',
    },
  ];

  state = {
    isLoading: false,
    data: [],
    owners: [getOwnerId(this.props.item)],
    visible: false,
    selectedPhotoStatus: this.photosStatuses[0].key,
  };

  componentDidMount() {
    // window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-subAdminStockPhotos');
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  makeQuery = async () => {
    const { owners, selectedPhotoStatus } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        DropDownTerminalEstimateStatus: selectedPhotoStatus,
      };
      const data = await api.terminalsPhotosEstimate(model);
      const renderData = this.renderData(data);
      this.setState({ data: renderData });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  onSelectChange = (selectedPhotoStatus) => {
    this.setState({ selectedPhotoStatus }, this.makeQuery);
  };

  buildToolbar = () => {
    const {
      translate,
      item: { OwnerId, OwnerName },
    } = this.props;
    const { selectedPhotoStatus } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="gridAdminStockPhotos-toolbar-row">
          <h2 className="gridAdminStockPhotos-toolbar-h2">{translate('page.adminStockPhotos.toolbar-h2')}:</h2>
          <h2 className="gridAdminStockPhotos-toolbar-h2 owner">
            {OwnerId} - {OwnerName}
          </h2>
        </div>
        <div className="gridAdminStockPhotos-toolbar-row">
          <Button
            type="primary"
            ghost
            className="gridAdminStockPhotos-toolbar-changeStatus"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModal}
          >
            {translate('page.adminStockPhotos.modal-title')}
            <DownOutlined />
          </Button>

          <Select
            className="modal-system-encashment--select gridAdminStockPhotos-toolbar-photosFilter"
            style={{ marginTop: '10px' }}
            value={selectedPhotoStatus}
            onChange={this.onSelectChange}
            dropdownStyle={{ zIndex: '1054', width: '100%' }}
            filterOption={(input, option) =>
              JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            showSearch
          >
            {this.photosStatuses.map((el) => {
              return (
                <Option key={`photosStatuses-${el.key}`} value={el.key}>
                  {translate(`${el.value}`)}
                </Option>
              );
            })}
          </Select>
        </div>
      </Col>
    );
  };

  statusToColor = (arr, terminalStatus, isAlt, elementStyle) => {
    const opacity = isAlt ? '1' : '0.5';
    if (terminalStatus !== 'Active') return { backgroundColor: `rgba(187,187,187,${opacity})` };
    const arrNotConsidered = arr.filter((el) => el.Status === 'NotConsidered').length;
    if (arrNotConsidered > 0) return { background: `rgb(255,220,220)` };
    else return;
  };

  rowRender = (element, props) => {
    const arr = props.dataItem.Photos;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(arr, props.dataItem.oldStatusActivity, props.isAltRow, elementStyle);
    const style = { ...color, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  renderData = (data) => {
    return data.map((el) => {
      const oldElem = { ...el };
      return {
        ...el,
        initialTerminalStatusName: oldElem.TerminalEstimateStatus,
        oldStatusActivity: oldElem.TerminalStatus,
        TerminalStatus: this.statusActivityToGridValue(oldElem.TerminalStatus),
        TerminalEstimateStatus: this.statusToGridValue(oldElem.TerminalEstimateStatus),
      };
    });
  };

  statusToGridValue = (status) => {
    const { translate } = this.props;
    switch (status) {
      case 'NotBranded':
        return `${translate('grids.adminStockPhotos.grid-status-value-notBranded')}`;
      case 'BrandedByStandart':
        return `${translate('grids.adminStockPhotos.grid-status-value-brandedByStandart')}`;
      case 'UnderConsideration':
        return `${translate('grids.adminStockPhotos.grid-status-value-underConsideration')}`;
      case 'Branded':
        return `${translate('grids.adminStockPhotos.grid-status-value-branded')}`;
      default:
        return status;
    }
  };

  statusActivityToGridValue = (status) => {
    const { translate } = this.props;
    switch (status) {
      case 'Active':
        return `${translate('grids.adminStockPhotos.grid-status-value-active')}`;
      case 'NotActive':
        return `${translate('grids.adminStockPhotos.grid-status-value-notActive')}`;
      case 'Disabled':
        return `${translate('grids.adminStockPhotos.grid-status-value-disabled')}`;
      case 'Faulty':
        return `${translate('grids.adminStockPhotos.grid-status-value-faulty')}`;
      case 'Arrested':
        return `${translate('grids.adminStockPhotos.grid-status-value-arrested')}`;
      default:
        return status;
    }
  };

  render() {
    const { isLoading, data, visible, owners } = this.state;
    return (
      <>
        <Grid
          data={data}
          rowHeight={40}
          onRefresh={this.makeQuery}
          rowRender={this.rowRender}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="subAdminStockPhotos"
          permissionName="adminGeneralResults"
          selectedField="selected"
          multiSelected={true}
          fieldForSelection="TerminalId"
          // isShowSpecificTerminalIdFilter={true}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="TerminalId"
            title="grids.adminStockPhotos.column-terminalId"
            width="180"
            filter="numeric"
          />

          <GridColumn field="CityName" title="grids.adminStockPhotos.column-city" width="270" />

          <GridColumn field="RegionName" title="grids.adminStockPhotos.column-region" width="150" />

          <GridColumn field="Address" title="grids.adminStockPhotos.column-address" width="150" />
          <GridColumn
            field="Photos"
            title="grids.adminStockPhotos.column-photo"
            width="150"
            columnMenu={false}
            cell={(props) => <CellPhotos update={this.makeQuery} {...props} />}
            isPhotoCell={true}
          />

          <GridColumn
            field="TerminalEstimateStatus"
            title="grids.adminStockPhotos.column-status"
            width="300"
            cell={(props) => <CellStockStatus {...props} />}
          />

          <GridColumn field="TerminalStatus" title="grids.adminPanel.column-active" width="300" />
        </Grid>
        {visible && (
          <ModalStockChangeProfile
            item={StorageService.getItem('selectionListForGrid-subAdminStockPhotos')}
            name="subAdminStockPhotos"
            owners={owners}
            visible={visible}
            closeModal={this.closeModal}
            toggleModal={this.toggleModal}
            update={this.makeQuery}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SubGridAdminStockPhotos);
