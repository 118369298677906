export const BANKNOTES_GRID_COLUMNS = [
  'datePost',
  'nominal1',
  'nominal2',
  'nominal5',
  'nominal10',
  'nominal20',
  'nominal50',
  'nominal100',
  'nominal200',
  'nominal500',
  'nominal1000',
  'serviceId',
  'serviceName',
  'terminalId',
  'userIdPost',
  'userName',
];
