import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './FieldRow.scss';

class FieldRow extends PureComponent {
  render() {
    const { children, translateLabelSlag, translate } = this.props;

    return (
      <Row className="CommissionDetails-fieldRow">
        <Col span={10} xs={8} className="CommissionDetails-fieldLabel">
          {translate(translateLabelSlag)}
        </Col>
        <Col span={13} xs={15} offset={1}>
          {children}
        </Col>
      </Row>
    );
  }
}

export default withTranslate(FieldRow);
