import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { withTranslate } from '../../contexts/localContext';
import Grid from '../grids/baseGrid';
import GridDropdown from '../GridDropdown/GridDropdown';
import { COMMISSION_CHANGE_TYPE_CONFIG } from '../../core/constants/configs.const';
import { GridColumn } from '@progress/kendo-react-grid';

import './ChangeDealerCommissions.scss';
import ModalAddCommissions from './ModalAddCommissions/ModalAddCommissions';
import FormStateContainer from '../FormStateContainer';
import { INITIAL_VALUES } from './ModalAddCommissions/constans';
import { ApiService } from '../../services';
import { commissionChangeTypes } from '../../core/constants/commissionChangeTypes';

const api = new ApiService();

class ChangeDealerCommissions extends PureComponent {
  state = {
    dateStart: new Date(new Date().setDate(new Date().getDate() + 1)),
    minDate: new Date(),
    defaultDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    paymentTypes: [],
    paymentTypesName: null,
    selectPaymentTypes: [],
    selectedItem: [],
    operationId: [],
    dataForGrid: [],
    requestData: [],
    visible: false,
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  handleButtonClick = (evt) => {
    const typeEvent = evt.target.name;
    switch (typeEvent) {
      case 'add':
        return this.handleToggleModal(true);
      case 'delete':
        return this.removeDealerCommission();
      case 'apply':
        return this.updateServiceCommission();
    }
  };

  handleToggleModal = (value) => {
    this.setState({ visible: value });
  };
  onChangeSelectItems = (name) => (val) => {
    this.setState({ [name]: val }, () => {
      if (name === 'selectPaymentTypes') {
        this.getPaymentTypeNameById(val);
      }
    });
  };

  getPaymentTypeNameById = (paymentTypeIds) => {
    const { paymentTypes } = this.state;
    if (paymentTypeIds.length) {
      const paymentTypesName = paymentTypes.reduce((acc, paymentType) => {
        if (paymentTypeIds.includes(paymentType.PaymentTypeId)) {
          acc.push(paymentType.PaymentType);
        }
        return acc;
      }, []);
      this.setState({ paymentTypesName });
    }
  };

  fetchPaymentType = async () => {
    try {
      this.toggleLoader(true);
      const paymentTypes = await api.getPaymentTypeList();
      await this.setState({ paymentTypes });
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.toggleLoader(false);
    }
  };

  normalize = (data) => {
    if (!data && data.length) return;

    return data.map(({ PaymentType, ...rest }) => {
      const resultRest = Object.entries(rest).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: Number(value) === 0 ? null : value }),
        {},
      );

      return { PaymentType, ...resultRest };
    });
  };

  normalizeValues = (val) => {
    return Object.entries(val).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: +val || null,
      }),
      {},
    );
  };

  normalizeServiceCommission = (dataForGrid) => {
    const { dateStart, operationId, paymentTypes } = this.state;
    const normalizeData = this.normalize(dataForGrid);

    return normalizeData.map(({ PaymentType, ...otherProps }) => ({
      PaymentType: paymentTypes.find(({ PaymentType: PaymentTypeName }) => PaymentTypeName === PaymentType)
        .PaymentTypeId,
      DateStart: dateStart.toISOString(),
      OperationId: operationId[0],
      ...this.normalizeValues(otherProps),
    }));
  };

  updateServiceCommission = async () => {
    const { handleApplyChanges } = this.props;
    const { dataForGrid } = this.state;

    handleApplyChanges(this.normalizeServiceCommission(dataForGrid));
  };

  upDateGrid = async (formData) => {
    const { paymentTypesName } = this.state;
    const { onOperationUpdate } = this.props;

    try {
      if (paymentTypesName === null) return;
      await this.toggleLoader(true);
      await this.setState(
        ({ dataForGrid }) => {
          return {
            dataForGrid: [
              ...dataForGrid.filter(
                ({ PaymentType: prevPaymentType }) =>
                  !paymentTypesName.some((PaymentType) => PaymentType === prevPaymentType),
              ),
              ...paymentTypesName.map((PaymentType) => ({ PaymentType, ...formData })),
            ],
          };
        },
        () => {
          if (onOperationUpdate) {
            onOperationUpdate(this.normalizeServiceCommission(this.state.dataForGrid));
          }
        },
      );
      await this.toggleLoader(false);
      await this.handleToggleModal(false);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    }
  };

  onSelectItems = (selectedItem) => {
    this.setState({ selectedItem });
  };

  removeDealerCommission = () => {
    const { onOperationUpdate } = this.props;
    const { dataForGrid, selectedItem } = this.state;

    const currentDataForGrid = dataForGrid.filter((item) => item.PaymentType !== selectedItem[0].PaymentType);

    this.setState({ dataForGrid: currentDataForGrid });
    onOperationUpdate(this.normalizeServiceCommission(currentDataForGrid));
  };

  onDateChange = (e) => {
    this.setState({ dateStart: e.target.value });
  };

  buildToolbar = () => {
    const { translate, handleApplyChanges, onOperationUpdate } = this.props;
    const { dateStart, minDate, operationId, selectPaymentTypes, selectedItem, defaultDate } = this.state;

    return (
      <Col span={24} md={24} lg={16} className="ChangeDealerCommissions-toolbar">
        <Col className="ChangeDealerCommissions-selects">
          <Col className="ChangeDealerCommissions-row">
            <Col className="ChangeDealerCommissions-label">{translate('page.dealerCommissionsChange.label-date')}</Col>
            <Col className="ChangeDealerCommissions-wrapp">
              <DatePicker
                defaultValue={defaultDate}
                value={dateStart}
                focusedDate={minDate}
                onChange={this.onDateChange}
                min={minDate}
                className="ChangeDealerCommissions-datepicker"
              />
            </Col>
          </Col>
          <Col className="ChangeDealerCommissions-row">
            <Col className="ChangeDealerCommissions-label">
              {translate('page.dealerCommissionsChange.label-type-change')}
            </Col>
            <Col className="ChangeDealerCommissions-wrapp">
              <GridDropdown
                data={commissionChangeTypes.map(({ Id, Name }) => ({ Id, Name: translate(Name) }))}
                colConfig={COMMISSION_CHANGE_TYPE_CONFIG}
                selectItems={operationId}
                onSave={this.onChangeSelectItems('operationId')}
                defaultTitle={`${translate('page.dealerCommissionsChange.selectChange')}`}
                isSingle
              />
            </Col>
          </Col>
        </Col>
        <Col className="ChangeDealerCommissions-btnGroup">
          <Button.Group onClick={(e) => this.handleButtonClick(e)}>
            <Button name="add" type="primary" disabled={onOperationUpdate && !operationId.length}>
              {translate('core.buttonTitles.add')}
            </Button>
            <Button name="delete" type="primary" disabled={!selectedItem.length}>
              {translate('core.buttonTitles.delete')}
            </Button>
            {handleApplyChanges && (
              <Button name="apply" type="primary" disabled={!(operationId.length && selectPaymentTypes.length)}>
                {translate('core.buttonTitles.apply')}
              </Button>
            )}
          </Button.Group>
        </Col>
      </Col>
    );
  };

  componentDidMount() {
    this.fetchPaymentType();
  }

  render() {
    const { dataForGrid, visible, isLoading } = this.state;

    return (
      <>
        <Grid
          data={dataForGrid}
          name="ChangeDealerCommissionGrid"
          toolbar={this.buildToolbar()}
          onRefresh={() => {}}
          fieldForSelection="PaymentType"
          onSelect={this.onSelectItems}
          isLoading={isLoading}
          withOutBaseButtons
        >
          <GridColumn field="selected" width="50px" filterable={false} columnMenu={false} />
          <GridColumn field="PaymentType" title="grids.dealerCommissionsChange.column-payment-type" width="150px" />
          <GridColumn
            field="DealerCommissionPercent"
            title="grids.dealerCommissionsChange.column-dealer-commission"
            width="250px"
          />
          <GridColumn
            field="DealerCommissionMin"
            title="grids.dealerCommissionsChange.column-dealer-commission-min"
            width="250px"
          />
          <GridColumn
            field="DealerCommissionMax"
            title="grids.dealerCommissionsChange.column-dealer-commission-max"
            width="250px"
          />
          <GridColumn
            field="DealerCommissionClientPercent"
            title="grids.dealerCommissionsChange.column-commission-per-client"
            width="250px"
          />
          <GridColumn
            field="DealerCommissionClientMin"
            title="grids.dealerCommissionsChange.column-commission-per-client-min"
            width="250px"
          />
          <GridColumn
            field="DealerCommissionClientMax"
            title="grids.dealerCommissionsChange.column-commission-per-client-max"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionPercent"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionMin"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission-min"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionMax"
            title="grids.dealerCommissionsChange.column-ps-dealer-commission-max"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionClientPercent"
            title="grids.dealerCommissionsChange.column-ps-commission-per-client"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionClientMin"
            title="grids.dealerCommissionsChange.column-ps-commission-per-client-min"
            width="250px"
          />
          <GridColumn
            field="PaymentSystemCommissionClientMax"
            title="grids.dealerCommissionsChange.column-ps-commission-per-client-max"
            width="250px"
          />
        </Grid>

        {visible && (
          <FormStateContainer initialValues={INITIAL_VALUES}>
            <ModalAddCommissions
              visible={true}
              closeModal={this.handleToggleModal}
              onPaymentTypesSave={this.onChangeSelectItems}
              upDateGrid={this.upDateGrid}
              onSubmitValidate={this.onSubmitValidate}
              isLoading={isLoading}
              {...this.props}
              {...this.state}
              key="modalAddCommissions"
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}
export default withTranslate(ChangeDealerCommissions);
