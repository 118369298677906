export const INITIAL_VALUES = {
  Id: 'none',
  Name: '',
  SettlementType: [],
  Contract: '',
  Edrpo: '',
  Mfo: [],
  Iban: '',
  Narrative: '',
};

export const SETTLEMENT_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: 'ID',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.technicalPartners.col-calculation',
    width: '240',
    isShowOnMobile: true,
  },
];

export const MFO_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    titleTranslateSlug: 'page.MFO.col-MFO',
    width: '100',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.MFO.col-name',
    width: '240',
    isShowOnMobile: true,
  },
];
