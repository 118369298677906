import React, { PureComponent } from 'react';

import { withTranslate } from '../../../contexts/localContext';
import UserProperties from './UserProperties';
import { ApiService } from '../../../services';
import { getEmailValidator, getPhoneValidate, getRequiredValidators } from './validators';
import { PHONE_CODE } from './constant';
import { message } from 'antd';

const api = new ApiService();

class UserPropertiesContainer extends PureComponent {
  state = {
    showAdditionalInfo: false,
    isEditing: false,
    dialogVisible: false,
    isLoading: false,
    currentCollectorsCode: '',
  };

  async componentDidMount() {
    await this.getUserProperties();
  }

  setDialogVisible = (dialogVisible) => {
    this.setState({ dialogVisible });
  };

  setShowAdditionalInfo = () => {
    this.setState(({ showAdditionalInfo }) => ({
      showAdditionalInfo: !showAdditionalInfo,
    }));
  };

  onEditingPermitted = async () => {
    this.setState({ isEditing: true });
  };

  getUserProperties = async () => {
    try {
      const { userId, setFormState } = this.props;
      this.setState({ isLoading: true });
      const { CollectorCode, Phone, ...restUserProperties } = await api.userPropertiesGet(userId);
      this.setState({ currentCollectorsCode: CollectorCode });
      const lengthDifference = Phone && Phone.length ? Phone.length - 10 : null;
      setFormState({ ...restUserProperties, Phone: lengthDifference ? Phone.slice(2, 12) : Phone });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  validateFieldOnSubmit = () => {
    const { formState, onError } = this.props;

    const validators = this.getValidatorsObject();

    return Object.entries(formState).reduce((acc, [key, value]) => {
      const currentValidator = validators[key];
      if (!currentValidator) return acc;
      const errorMessage = currentValidator(value);
      onError(key, errorMessage);
      return acc || errorMessage;
    }, '');
  };

  setUserProperties = async () => {
    const formError = this.validateFieldOnSubmit();
    const { formState } = this.props;
    const { FirstName, LastName, Email, Phone } = formState;
    this.showErrorMassage({ FirstName, LastName, Email, Phone });

    if (formError) return;

    try {
      this.setState({ isLoading: true });
      await api.userPropertiesSet({
        ...formState,
        Phone: `${PHONE_CODE}${formState.Phone.replace(/\D+/g, '')}`,
      });
      await window.location.reload();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  showErrorMassage = (formRequireField) => {
    const { translate } = this.props;

    for (const key in formRequireField) {
      const values = formRequireField[key];

      const toLowerCaseFirstLetter = (fieldName) => fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

      if (values === null || values === '') {
        message.error(
          `
      ${translate('page.terminalSettings.error-required-fields')} ${translate(
            'grids.userProperties.column-' + toLowerCaseFirstLetter(key),
          )}`,
          2,
        );

        return;
      }
    }
  };
  getValidatorsObject = () => {
    const { translate } = this.props;

    return {
      FirstName: getRequiredValidators(translate),
      LastName: getRequiredValidators(translate),
      Email: getEmailValidator(translate),
      Phone: getPhoneValidate(translate),
    };
  };

  render = () => {
    return (
      <UserProperties
        {...this.props}
        {...this.state}
        setDialogVisible={this.setDialogVisible}
        setShowAdditionalInfo={this.setShowAdditionalInfo}
        onEditingPermitted={this.onEditingPermitted}
        getUserProperties={this.getUserProperties}
        setUserProperties={this.setUserProperties}
        validators={this.getValidatorsObject()}
      />
    );
  };
}
export default withTranslate(UserPropertiesContainer);
