const SubBrandsTerminals = (translate, value) => {
  switch (value) {
    case 'terminalsSubBrands-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'terminalsSubBrands-changeBrand':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.subBrandsTerminals.changeBrand')}`;
    case 'terminalsSubBrands-dictionary':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.subBrandsTerminals.directorySubBrands')}`;
    default:
      return value;
  }
};

export default SubBrandsTerminals;
