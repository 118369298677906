const cashDept = (translate, value) => {
  switch (value) {
    case 'cashDept-search':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`owner.search`)}`;
    case 'commissionCashDept-grid-DealerCode':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-DealerId')}`;
    case 'commissionCashDept-grid-DealerName':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-DealerName')}`;
    case 'commissionCashDept-grid-Date':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-Date')}`;
    case 'commissionCashDept-grid-BeginBalans':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-startingBalance')}`;
    case 'commissionCashDept-grid-Collection':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-CollectionAmount')}`;
    case 'commissionCashDept-grid-Enrollment':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-AmountCredited')}`;
    case 'commissionCashDept-grid-Lack':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-write-off-shortfall')}`;
    case 'commissionCashDept-grid-Adjustment':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-Adjustments')}`;
    case 'commissionCashDept-grid-EndBalans':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.cashDept.column-BalanceAtTheEnd')}`;
    default:
      return value;
  }
};
export default cashDept;
