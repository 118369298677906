import BaseApiService from './baseApiService';
import { BASE_API_STRING, BASE_GQL_API_STRING, WPS_API_STRING } from '../core/constants/baseApiString.const';

export default class ApiService extends BaseApiService {
  token = (username, password) => {
    const data = { username, password, grant_type: 'password' };
    return this.post(`${BASE_API_STRING}/Security/Token`, data);
  };

  tokenDeviceCode = (model) => {
    return this.post(`${BASE_API_STRING}/Security/Token`, model);
  };

  usersTrustedDevicesAdd = () => {
    return this.post(`${BASE_API_STRING}/Security/UsersTrustedDevicesAdd`);
  };

  usersTrustedDevicesConfirm = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UsersTrustedDevicesConfirm`, model);
  };

  usersTrustedDevicesUseLightLogin = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UsersTrustedDevicesUseLightLogin`, model);
  };

  usersTrustedDevicesClean = () => {
    return this.post(`${BASE_API_STRING}/Security/UsersTrustedDevicesClean`);
  };

  restorePassword = (login, mail) => {
    const data = { login, mail };
    return this.post(`${BASE_API_STRING}/Security/RestorePassword`, data);
  };

  collectionReceiptSendInfo = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionReceiptSendInfo`, model);
  };

  changePasswordUnauthorized = (model) => {
    return this.post(`${BASE_API_STRING}/Security/ChangePasswordUnauthorized`, model);
  };

  owners = () => {
    return this.get(`${BASE_API_STRING}/Owners/Owners`, true);
  };

  // terminals = (owners, state, changed) => {
  //   return this.kendoPost(`/api/Terminal/TerminalList?full=${ changed }`, owners, state);
  // }

  terminalsListActivity = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsListActivity`, model);
  };

  terminalMonitorActivityXml = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalMonitorActivityXml`, model);
  };

  getTerminalSettingsExtendedKeysList = () => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalSettingsExtendedKeysList`, true);
  };

  terminalSettingsExtendedKeyAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalSettingsExtendedKeyAdd`, model);
  };

  transactions = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/FindTransaction`, model);
  };

  serviceReport = (model) => {
    return this.post(`${BASE_API_STRING}/Report/Services`, model);
  };

  dayReport = (model) => {
    return this.post(`${BASE_API_STRING}/Report/Days`, model);
  };

  transactionDetails = (value) => {
    return this.post(`${BASE_API_STRING}/Transaction/TransactionDetailsList`, value);
  };

  accounts = (owners) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/OwnersAccountList`, owners);
  };

  accountLimit = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/LimitList`, model);
  };

  accountTurn = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/TurnMovementList`, model);
  };

  accountDetail = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/MovementList`, model);
  };

  addLimit = (limit) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/AddLimit`, limit);
  };

  // removeLimit = (accountId, workDate) => {
  //   return this.post(`/api/OwnerAccount/RemoveLimit?AccountId=${accountId}&WorkDate=${workDate}`, null);
  // }

  removeLimit = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/RemoveLimit`, model);
  };

  terminalsCashRecalculate = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsCashRecalculate`, model);
  };

  collections = (value) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionsInfo`, value);
  };

  collectionDetails = (state) => {
    return this.kendoGet(`${BASE_API_STRING}/Collection/CollectionsDetails`, state);
  };

  changePassword = (query) => {
    return this.post(`${BASE_API_STRING}/Security/ChangePassword`, query);
  };

  terminalInfo = (ownerId) => {
    return this.get(`${BASE_API_STRING}/Notify/NotifyAboutByOwner?OwnerId=${ownerId}`);
  };

  terminalCertificates = (owner) => {
    return this.get(`${BASE_API_STRING}/Certificate/TerminalsListCertificates?OwnerId=${owner}`);
  };

  terminalCertificatesList = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Certificate/CertificatesList?TerminalId=${terminalId}`);
  };

  terminalEvents = (model) => {
    return this.post(`${BASE_API_STRING}/Events/TerminalsEventsList`, model);
  };

  turnTerminalsReport = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TurnByTerminals`, model);
  };

  terminalsMaintenance = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalMaintenance`, model);
  };

  terminalTransactionlist = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/TransactionsList`, model);
  };

  declineTransaction = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoDecline`, model);
  };

  unholdTransaction = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoUnhold`, model);
  };

  // START remote commands

  collectionRecalculation = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsCashRecalculate`, model);
  };

  terminalReboot = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalReboot`, model);
  };

  terminalRestart = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalRestart`, model);
  };

  terminalShutOff = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalShutOff`, model);
  };

  terminalShutOn = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalShutOn`, model);
  };

  terminalPowerOff = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalPowerOff`, model);
  };

  getCollectors = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Collection/Collectors?TerminalId=${terminalId}`);
  };

  collectionReceiptSendManage = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionReceiptSendManage`, model);
  };

  collectionReceiptSendDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionReceiptSendDelete`, model);
  };

  сollectionsSystem = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionsSystem`, model);
  };

  // END remote commands

  getClientComission = (ownerId) => {
    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionClientTerminalList?OwnerId=${ownerId}`);
  };

  getOwnerProfiles = (ownerId) => {
    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileList?OwnerId=${ownerId}`);
  };

  getClientProfileServiceList = (profileId) => {
    const queryParam = Number.isInteger(profileId) ? `?ProfileId=${profileId}` : '';

    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileServiceList${queryParam}`);
  };

  getComissionServiceDetails = (ComissionId) => {
    const queryParam = Number.isInteger(ComissionId) ? `?CommissionId=${ComissionId}` : `?CommissionId=null`;

    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientList${queryParam}`);
  };

  setCommissionsClientProfile = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileSet`, model);
  };

  certificateInstall = (model) => {
    return this.post(`${BASE_API_STRING}/Certificate/CertificateInstall`, model, 'text');
  };

  getElementsFromBack = (model) => {
    return this.post(`${BASE_API_STRING}/Security/GetElements`, model);
  };

  allEncashments = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionList`, model);
  };

  cashDept = (model) => {
    return this.post(`${BASE_API_STRING}/Report/CashDebt`, model);
  };
  encashmentHistory = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionHistList`, model);
  };

  postEncashmentDetailsList = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionsDetailsList`, model);
  };

  getTerminalCashList = (TerminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalCashList?TerminalId=${TerminalId}`);
  };

  getTechnicInfo = () => {
    return this.get(`${BASE_API_STRING}/Terminal/TechnicInfo`);
  };

  terminalsMainProfileList = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileList`, model);
  };

  mainProfileListForModalChangeProfile = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileList`, model);
  };

  changeTerminalsMainProfile = (owners) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileChange`, owners);
  };

  deleteMainProfile = (mainProfileIds) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileDelete`, mainProfileIds);
  };

  getMainProfileHoldList = () => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/MainProfileHoldList`);
  };

  deleteBusinessServices = (mainHoldIds) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileHoldDelete`, mainHoldIds);
  };

  getProfileFromProfiles = (mainProfileId) => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/MainProfileGet?MainProfileId=${mainProfileId}`);
  };

  getBusinessServiceFromServices = (MainProfileHoldId) => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/MainProfileHoldGet?MainProfileHoldId=${MainProfileHoldId}`);
  };

  // START for modal for changing image micro requests

  getRegions = () => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/RegionGet`);
  };

  getCategories = () => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/CategoryMenuGet`);
  };

  getServicesByOwner = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/OwnerServiceGet`, model);
  };

  findButtonByCriteria = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileButtonFind`, model);
  };

  postMainProfileManage = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileManage`, model);
  };

  postMainProfileHoldManage = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileHoldManage`, model);
  };

  postMainProfileHoldSet = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileHoldSet`, model);
  };

  // END for modal for changing image micro requests

  recountCollection = (model) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionsRecalculate`, model);
  };

  terminalManagement = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalList`, model);
  };

  getTerminalInfo = (TerminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalInfo?TerminalId=${TerminalId}`);
  };

  // START TERMINAL SETTINGS

  getCities = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/Cities`);
  };

  getTerminalStatuses = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/TerminalsStatus`);
  };

  getTerminalPlaces = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/Placings`);
  };

  getTerminalProjects = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/Projects`);
  };

  getNetworkContracts = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/NetworkContracts`);
  };

  CommissionDealers = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/CommissionDealerList`, model);
  };

  updateTerminal = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalUpdate`, model);
  };

  addNewTerminal = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalAdd`, model);
  };

  // getOwnerProfiles = (ownerId) => {
  //   return this.get(`/api/CommissionClient/CommissionsClientProfileList?OwnerId=${ownerId}`);
  // }

  // mainProfileListForModalChangeProfile = (model) => {
  //   return this.post(`/api/TerminalMainProfile/MainProfileList`, model);
  // }

  getTerminalDesignTypes = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/TerminalsDesignTypes`);
  };

  // END TERMINAL SETTINGS

  postSQLQueryRunEmail = (model) => {
    return this.post(`${BASE_API_STRING}/Report/SQLQueryRunEmail`, model);
  };

  // START Admin Requests

  postGetUsersList = (model) => {
    return this.post(`${BASE_API_STRING}/Security/GetUsersList`, model);
  };

  postGetAllElements = (model) => {
    return this.post(`${BASE_API_STRING}/Security/GetAllElements`, model, true);
  };

  postGetWebTagsByUser = (model) => {
    return this.post(`${BASE_API_STRING}/Security/GetWebTagsByUser`, model, true);
  };

  postGetElementsByUser = (model) => {
    return this.post(`${BASE_API_STRING}/Security/GetElementsByUser`, model, true);
  };

  postWtmElementsHide = (model) => {
    return this.post(`${BASE_API_STRING}/Security/WtmElementsHide`, model);
  };

  postWtmFieldHide = (model) => {
    return this.post(`${BASE_API_STRING}/Security/WtmFieldHide`, model);
  };
  //END Admin Requests

  terminalsMainProfileDisableChange = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileDisableChange`, model);
  };

  terminalsMainProfileHoldTransfer = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileHoldTransfer`, model);
  };

  terminalsMainProfileHoldCheck = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileHoldCheck`, model);
  };

  // Dealers Stock
  terminalsPhotosEstimate = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/TerminalsPhotosEstimate`, model);
  };

  terminalPhotoDownload = (ownerId, terminalId, fileName) => {
    return this.get(
      `${BASE_API_STRING}/Promotion/TerminalPhotoDownload?ownerId=${ownerId}&terminalId=${terminalId}&fileName=${fileName}`,
    );
  };

  terminalsEstimateUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/TerminalsEstimateUpdate`, model);
  };

  terminalPhotoDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/TerminalPhotoDelete`, model);
  };

  generalResultsByCategory = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/GeneralResultsByCategory`, model);
  };

  generalResultByOwner = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/GeneralResultByOwner`, model);
  };

  terminalsPhotosHeader = (model) => {
    return this.post(`${BASE_API_STRING}/Promotion/TerminalsPhotosHeader`, model);
  };
  // Dealers Stock

  //START ReportAnalytics
  getTerminalsByOwner = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/DictionaryTerminalList`, model);
  };

  terminalsEfficiencyPeriod = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsEfficiencyPeriod`, model);
  };

  terminalsEfficiencyByDay = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsEfficiencyByDay`, model);
  };

  fixedCommissionsDictionary = () => {
    return this.get(`${BASE_API_STRING}/Services/FixedCommissionsDictionary`);
  };

  //END ReportAnalytics

  //START WhiteListAdmin

  terminalsWhitelistAccountsAuditList = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalsWhitelist/TerminalsWhitelistAccountsAuditList`, model);
  };

  terminalsWhitelistAccountsInsert = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalsWhitelist/TerminalsWhitelistAccountsInsert`, model);
  };

  terminalsWhitelistAccountsList = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalsWhitelist/TerminalsWhitelistAccountsList`, model);
  };

  terminalsWhitelistAccountsDelete = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalsWhitelist/TerminalsWhitelistAccountsDelete`, model);
  };

  terminalsWhitelistService = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/TerminalsWhitelistService`, model);
  };
  //END WhiteListAdmin

  // START OwnerMessages

  ownersMessagesList = () => {
    return this.get(`${BASE_API_STRING}/OwnerMessages/OwnersMessagesList`);
  };

  ownersMessageGet = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerMessages/OwnersMessageGet`, model);
  };

  ownersMessagesCreatedCount = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerMessages/OwnersMessagesCreatedCount`, model);
  };

  ownersMessagesSetRead = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerMessages/OwnersMessagesSetRead`, model);
  };

  ownersMessagesSetUnRead = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerMessages/OwnersMessagesSetUnRead`, model);
  };

  // END OwnerMessages

  // START Adding AdressedPayment

  servicesCRMRequestsList = () => {
    return this.get(`${BASE_API_STRING}/Services/ServicesCRMRequestsList`);
  };

  servicesCRMRequestAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCRMRequestAdd`, model);
  };

  servicesCRMRequestUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCRMRequestUpdate`, model);
  };

  servicesCRMRequestSend = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCRMRequestSend`, model);
  };

  servicesCRMRequestDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCRMRequestDelete`, model);
  };

  validateIban = (iban) => {
    return this.get(`${BASE_API_STRING}/Services/ValidateIban?IBAN=${iban}`);
  };

  validateInn = (inn) => {
    return this.get(`${BASE_API_STRING}/Services/ValidateInn?Inn=${inn}`);
  };

  // START Terminals Configurations

  terminalListManage = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalListManage`, model);
  };

  terminalsPropertiesGet = (ownerId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalsPropertiesGet?OwnerId=${ownerId}`);
  };

  terminalScheduleGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalScheduleGet?TerminalId=${terminalId}`);
  };

  terminalScheduleSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalScheduleSet`, model);
  };

  terminalTechUsersGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalTechUsersGet?TerminalId=${terminalId}`);
  };

  terminalAvailableTechUsersListGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalAvailableTechUsersListGet?TerminalId=${terminalId}`);
  };

  terminalAvailableManagersListGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalAvailableManagersListGet?TerminalId=${terminalId}`);
  };

  terminalAvailableCollectorsListGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalAvailableCollectorsListGet?TerminalId=${terminalId}`);
  };

  terminalTechUsersSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalTechUsersSet`, model);
  };

  terminalRentParamsGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalRentParamsGet?TerminalId=${terminalId}`);
  };

  terminalRentParamsSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalRentParamsSet`, model);
  };

  terminalLimitsGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalLimitsGet?TerminalId=${terminalId}`);
  };

  terminalLimitsSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalLimitsSet`, model);
  };

  terminalAvailableDefaultCollectorsListGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalAvailableDefaultCollectorsListGet?TerminalId=${terminalId}`);
  };

  // START Удалённые настройки

  terminalSettingsGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalSettingsGet?TerminalId=${terminalId}`);
  };

  terminalSettingsSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalSettingsSet`, model);
  };

  terminalSettingsExtendedKeysList = () => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalSettingsExtendedKeysList`);
  };

  terminalSkins = () => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalSkins`);
  };

  terminalSettingsExtendedGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalSettingsExtendedGet?TerminalId=${terminalId}`);
  };

  terminalListSettingsExtendedSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalListSettingsExtendedSet`, model);
  };

  // END Удалённые настройки

  terminalsHardwareGet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsHardwareGet`, model);
  };

  // END Terminals Configurations

  // START Кабинет Финмониторинга

  reportRegularPaymentsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportRegularPaymentsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportUsersInStopListGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportUsersInStopListGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportsListGet = () => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportsListGet`);
  };

  reportPayerRecipientDataGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportPayerRecipientDataGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportNoRecipientDataGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportNoRecipientDataGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportMFOPaymentsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportMFOPaymentsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportRefundPaymentsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportRefundPaymentsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportWithdrawGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportWithdrawGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportOverlimitPaymentsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportOverlimitPaymentsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportOverlimitTransactionsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportOverlimitTransactionsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  reportTerroristsTransactionsGet = (dateFrom, dateTo) => {
    return this.get(`${WPS_API_STRING}/FinMon/ReportTerroristsTransactionsGet?DateFrom=${dateFrom}&DateTo=${dateTo}`);
  };

  dictionaryAgentsGet = () => {
    return this.get(`${WPS_API_STRING}/FinMon/DictionaryAgentsGet`);
  };

  dictionaryRefusedAgentMerge = (model) => {
    return this.post(`${WPS_API_STRING}/FinMon/DictionaryRefusedAgentMerge`, model);
  };

  dictionaryAgentMerge = (model) => {
    return this.post(`${WPS_API_STRING}/FinMon/DictionaryAgentMerge`, model);
  };

  dictionaryAgentDelete = (model) => {
    return this.post(` ${WPS_API_STRING}/FinMon/DictionaryAgentDelete`, model);
  };

  dictionaryRefusedAgentsGet = () => {
    return this.get(`${WPS_API_STRING}/FinMon/DictionaryRefusedAgentsGet`);
  };

  dictionaryRefusedAgentDelete = (model) => {
    return this.post(`${WPS_API_STRING}/FinMon/DictionaryRefusedAgentDelete`, model);
  };

  servicesCRMRequestCommissionsGet = (requestId) => {
    return this.get(`${BASE_API_STRING}/Services/ServicesCRMRequestCommissionsGet?RequestId=${requestId}`);
  };

  servicesCRMRequestCommissionsMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCRMRequestCommissionsMerge`, model);
  };

  reportUsersInStopListCommentAdd = (model) => {
    return this.post(`${WPS_API_STRING}/FinMon/ReportUsersInStopListCommentAdd`, model);
  };

  reportOverlimitTransactionsCommentAdd = (model) => {
    return this.post(`${WPS_API_STRING}/FinMon/ReportOverlimitTransactionsCommentAdd`, model);
  };

  updateTerminalListStatus = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalListStatusUpdate`, model);
  };

  commissionsClientCreate = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientCreate`, model);
  };

  commissionsClientDelete = (commissionsClientId) => {
    return this.delete(
      `${BASE_API_STRING}/CommissionClient/CommissionsClientDelete?commissionsClientId=${commissionsClientId}`,
    );
  };

  commissionsClientUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientUpdate`, model);
  };

  commissionsClientProfileCopy = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileCopy`, model);
  };

  commissionsClientProfileDelete = (profilesIdList) => {
    const queryString = `?commissionsClientProfileIds=${profilesIdList.join(',')}`;

    return this.delete(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileDelete${queryString}`);
  };

  commissionsClientProfileUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientProfileUpdate`, model);
  };

  getProfileDictionary = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/Profiles`, model);
  };

  commissionsClientHistory = (queryString) => {
    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientHistory?${queryString}`);
  };

  communicationTerminalsPerDay = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/CommunicationTerminalsPerDay`, model);
  };
  communicationTerminalsPeriod = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/CommunicationTerminalsPeriod`, model);
  };

  communicationTerminalsPerHour = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/CommunicationTerminalPerHour`, model);
  };
  getPaymentTypeList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/PaymentTypeList`);
  };

  postProfileList = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/Profiles`, model);
  };

  getServiceDictionary = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ServiceList`, model);
  };

  getCommissionsClientServices = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientServices`, model);
  };

  postCommissionsClientReferenceCopy = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientReferenceCopy`, model);
  };

  postCommissionsClientReferenceSet = (model) => {
    return this.post(`${BASE_API_STRING}/CommissionClient/CommissionsClientReferenceSet`, model);
  };

  deleteCommissionsClientReference = (model) => {
    return this.delete(`${BASE_API_STRING}/CommissionClient/CommissionsClientReferenceDelete`, model);
  };
  terminalList = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/DictionaryTerminalList`, model);
  };
  terminalsByServices = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsByServices`, model);
  };

  cashCollectors = (model) => {
    return this.post(`${BASE_API_STRING}/Report/CashCollectors`, model);
  };

  terminalsBusinessUnitGet = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalsBusinessUnitGet?terminalId=${terminalId}`);
  };
  residuesInTerminals = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ResiduesInTerminals`, model);
  };

  inventoryNumberGenerate = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/InventoryNumberGenerate`, model);
  };

  inventoryNumberMigrate = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/InventoryNumberMigrate`, model);
  };

  terminalsWithDeleteStatusGet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsWithDeleteStatusGet`, model);
  };

  commissionsClientReferenceCopyRequests = () => {
    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientReferenceCopyRequests`);
  };

  commissionsClientReferenceCopyResult = (batchId) => {
    return this.get(`${BASE_API_STRING}/CommissionClient/CommissionsClientReferenceCopyResult?batchGuid=${batchId}`);
  };

  terminalsByDays = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsByDays`, model);
  };

  changePasswordByLogin = (model) => {
    return this.post(`${BASE_API_STRING}/Security/ChangePasswordByLogin`, model);
  };

  terminalsTechnicalEfficiencyPeriod = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsTechnicalEfficiencyPeriod`, model);
  };

  terminalsTechnicalEfficiencyByDay = (model) => {
    return this.post(`${BASE_API_STRING}/Report/TerminalsTechnicalEfficiencyByDay`, model);
  };

  userPropertiesGet = (userId) => {
    return this.get(`${BASE_API_STRING}/Security/UserPropertiesGet?userId=${userId}`);
  };

  userPropertiesSet = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UserPropertiesSet`, model);
  };
  userCollectorCode = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UserCollectorCodeSet`, model);
  };
  terminalsAccessListGet = (model) => {
    return this.post(`${BASE_API_STRING}/Security/TerminalsAccessListGet`, model);
  };
  terminalsAccessMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Security/TerminalsAccessMerge`, model);
  };

  getUserRolesByUserId = (userId) => {
    return this.get(`${BASE_API_STRING}/Security/UserRolesByUserId?userId=${userId}`);
  };

  userRoleSet = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UserRolesSet`, model);
  };
  UserPropertiesAndRolesSet = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UserPropertiesAndRolesSet`, model);
  };

  ownersMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnersAclMerge`, model);
  };

  getOwnersAcList = (userId, showDeleted = false, showOnlyAvailable = true) => {
    return this.get(
      `${BASE_API_STRING}/Owners/OwnersAclList?userId=${userId}&showDeleted=${showDeleted}&showOnlyAvailable=${showOnlyAvailable}`,
    );
  };

  getErrorCodes = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ErrorCodes`, model);
  };

  terminalsWithDispenser = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsWithDispenser`, model);
  };

  getDataAvailabilityOfCards = (terminalId) => {
    return this.get(`${BASE_API_STRING}/Terminal/GetDataAvailabilityOfCards?terminalId=${terminalId}`);
  };

  dispenserCollectionsByTerminalForPeriodList = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/DispenserCollectionsByTerminalForPeriodList`, model);
  };

  getAllRoles = () => {
    return this.get(`${BASE_API_STRING}/Security/UserRoles`);
  };

  getUserAvailableRoles = () => {
    return this.get(`${BASE_API_STRING}/Security/UserAvailableRoles`);
  };

  UserRolesWtmElements = (ElementKey, ElementType) => {
    return this.get(
      `${BASE_API_STRING}/Security/UserRolesWtmElements?ElementKey=${ElementKey}&ElementType=${ElementType} `,
      true,
    );
  };

  userRolesWtmElementMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Security/UserRolesWtmElementMerge`, model, true);
  };

  getByGraphQl = (model) => {
    return this.post(`${BASE_GQL_API_STRING}`, { query: `query {${model}}` });
  };

  mutationByGraphQl = (model) => {
    return this.post(`${BASE_GQL_API_STRING}`, { query: `mutation {${model}}` }, true);
  };

  getMainProfileReserveList = () => {
    return this.get(`${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveList`);
  };

  mainProfileReserveDelete = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveDelete`, model);
  };

  mainProfileReserveSet = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveSet`, model);
  };

  mainProfileHoldGet = (mainProfileHoldId) => {
    return this.get(
      `${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveGet?MainProfileReserveId=${mainProfileHoldId}`,
    );
  };

  terminalsMainProfileReserveCheck = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/TerminalsMainProfileReserveCheck`, model);
  };

  mainProfileReserveManage = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveManage`, model);
  };

  terminalsScheduleSet = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsScheduleSet`, model);
  };

  terminalsBlockingRules = (model) => {
    return this.post(`${BASE_API_STRING}/Services/TerminalsBlockingRules`, model);
  };

  terminalsBlockingRulesChange = (model) => {
    return this.post(`${BASE_API_STRING}/Services/TerminalsBlockingRulesChange`, model);
  };

  servicesBlockingRules = () => {
    return this.post(`${BASE_API_STRING}/Services/ServicesBlockingRules`);
  };

  finmonOwnerMfoPayments = (model) => {
    return this.post(`${BASE_API_STRING}/Report/FinmonOwnerMfoPayments`, model);
  };

  servicesBlockingRulesDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ServicesBlockingRulesDelete`, model);
  };

  servicesBlockingRulesAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesBlockingRulesAdd`, model);
  };

  notRiskServices = () => {
    return this.post(`${BASE_API_STRING}/Services/NotRiskServices`, {
      ownerId: [0],
    });
  };

  getCashbackFishka = () => {
    return this.post(`${BASE_API_STRING}/Services/CashbackFishka`);
  };

  getCashbackFishkaHistory = (model) => {
    return this.post(`${BASE_API_STRING}/Services/CashbackFishkaHistory`, model);
  };

  cashbackFishkaAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/CashbackFishkaAdd`, model);
  };

  cashbackFishkaDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/CashbackFishkaDelete`, model);
  };

  terminalsBlockingRulesLimit = (model) => {
    return this.post(`${BASE_API_STRING}/Services/TerminalsBlockingRulesLimit`, model);
  };

  getServicesGroupForFishka = () => {
    return this.post(`${BASE_API_STRING}/Services/ServicesGroupForFishka`);
  };

  getServicesCashbackFishka = (serviceGroupId) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesCashbackFishkaFind`, serviceGroupId);
  };

  getCashDebtByCollectors = (model) => {
    return this.post(`${BASE_API_STRING}/Report/CashDebtByCollectors`, model);
  };

  servicesLimitsDictionary = () => {
    return this.post(`${BASE_API_STRING}/Services/ServicesLimitsDictionary`);
  };
  addServicesLimits = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesLimitsAdd`, model);
  };

  changeServicesLimits = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesLimitsChange`, model);
  };

  removeServicesLimits = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ServicesLimitsDelete`, model);
  };
  managementServices = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetServices`, model);
  };
  getPaymentsExternalList = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalList`, model);
  };
  getPaymentsActionsList = (paymentId) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsActionsList`, paymentId);
  };
  getPaymentsExternalDelete = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalDelete`, model);
  };
  paymentsExternalSetInserted = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalSetInserted`, model);
  };
  paymentsExternalAddComment = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalAddComment`, model);
  };
  mainProfileReserveManageCheck = (model) => {
    return this.post(`${BASE_API_STRING}/TerminalMainProfile/MainProfileReserveManageCheck`, model);
  };
  postActionReportWithPayments = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/ActionReportWithPayments`, model);
  };
  postPaymentComments = (paymentId) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentComments`, paymentId);
  };
  authorizeCheck = () => {
    return this.get(`${BASE_API_STRING}/Security/AuthorizeCheck`);
  };
  NBUCodesList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/NBUCodesList`);
  };

  addPaymentTypeServiceRelationsNBU = (model) => {
    return this.post(`${BASE_API_STRING}/Services/NBUPaymentTypeServiceRelationsAdd`, model);
  };

  getNBUPaymentTypeServiceRelations = () => {
    return this.get(`${BASE_API_STRING}/Services/GetNBUPaymentTypeServiceRelations`);
  };

  deleteNBUPaymentTypeServiceRelations = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/NBUPaymentTypeServiceRelationsDelete`, model);
  };

  getNBUPaymentTypeServiceRelationsHistory = () => {
    return this.get(`${BASE_API_STRING}/Services/GetNBUPaymentTypeServiceRelationsHistory`);
  };

  updateNBUPaymentTypeServiceRelations = (model) => {
    return this.post(`${BASE_API_STRING}/Services/NBUPaymentTypeServiceRelationsUpdate`, model);
  };

  getCollectionReceiptPDF = (collectionId) => {
    return this.post(`${BASE_API_STRING}/Collection/CollectionReceiptPDF`, collectionId);
  };

  getServicesLimits = () => {
    return this.get(`${BASE_API_STRING}/Services/GetServicesLimits`);
  };

  addServiceLimit = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceLimitAdd`, model);
  };

  updateServiceLimit = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceLimitUpdate`, model);
  };

  deleteServiceLimit = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ServiceLimitDelete`, model);
  };

  serviceCommissionsList = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionsList`, model);
  };
  serviceCommissionChangeStatus = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionChangeStatus`, model);
  };

  ownersPaymentTypeList = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnersPaymentTypeList`, model);
  };
  ownersPaymentTypeListAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnerPaymentTypeAdd`, model);
  };
  ownersPaymentTypeListDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnerPaymentTypeDelete`, model);
  };

  copyDealerServiceCommission = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionCopy`, model);
  };

  serviceCommissionUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionUpdate`, model);
  };
  getBrandTerminals = () => {
    return this.get(`${BASE_API_STRING}/Terminal/TerminalsBrandList`);
  };

  getTerminalsBrandList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/TerminalBrands`);
  };

  getMenuProfiles = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/MenuProfiles`);
  };

  terminalsBrandMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsBrandMerge`, model);
  };

  brandMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/TerminalBrandMerge`, model);
  };

  individualsSanctionListUpdate = () => {
    return this.post(`${WPS_API_STRING}/SanctionList/IndividualsSanctionListUpdate`);
  };

  legalEntitiesSanctionListUpdate = () => {
    return this.post(`${WPS_API_STRING}/SanctionList/LegalEntitiesSanctionListUpdate`);
  };

  terroristsSanctionListUpdate = () => {
    return this.post(`${WPS_API_STRING}/SanctionList/TerroristsSanctionListUpdate`);
  };

  getUpdateSanction = (updateId) => {
    return this.post(`${WPS_API_STRING}/SanctionList/CheckUpdateSanction`, updateId);
  };

  paymentsExternalReportByPeriod = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalReportByPeriod`, model);
  };

  getServiceInfo = (serviceId) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceInfo`, serviceId);
  };

  getOwnerTypes = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetOwnerTypes`);
  };

  getServicesTypes = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServicesTypes`);
  };

  getSuppliers = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetSuppliers`);
  };

  getServicesStatuses = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServicesStatuses`);
  };
  getPaymentSystemDictionaryList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetPaymentSystemDictionaryList`);
  };

  servicesBankingDetailsUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesBankingDetailsUpdate`, model);
  };

  servicesBankingDetailsAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServicesBankingDetailsAdd`, model);
  };

  searchNameBySanction = (searchString) => {
    return this.post(`${WPS_API_STRING}/SanctionList/SearchNameBySanction`, JSON.stringify(searchString));
  };

  getUsersManagers = (ownerId) => {
    return this.post(`${BASE_API_STRING}/Dictionary/GetUsersManagers`, ownerId || 0);
  };

  serviceInfoUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceInfoUpdate`, model);
  };
  getAdditionalServiceLimit = (serviceId) => {
    return this.get(`${BASE_API_STRING}/Services/GetAdditionalServiceLimits?serviceId=${serviceId}`);
  };

  additionalServiceLimitMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Services/AdditionalServiceLimitMerge`, model);
  };

  additionalServiceLimitDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/AdditionalServiceLimitDelete`, model);
  };

  getAdditionalPropertiesServices = (serviceId) => {
    return this.post(`${BASE_API_STRING}/Services/GetAdditionalPropertiesServices`, serviceId);
  };

  getServicesPropertiesKeys = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServicesPropertiesKeys`);
  };

  addAdditionalPropertyService = (model) => {
    return this.post(`${BASE_API_STRING}/Services/AdditionalPropertyServiceAdd`, model);
  };

  updateAdditionalPropertyService = (model) => {
    return this.post(`${BASE_API_STRING}/Services/AdditionalPropertyServiceUpdate`, model);
  };

  cloneAdditionalPropertyService = (model) => {
    return this.post(`${BASE_API_STRING}/Services/AdditionalPropertyServiceClone`, model);
  };

  deleteAdditionalPropertyService = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/AdditionalPropertyServiceDelete`, model);
  };

  getEndpointServices = (serviceId) => {
    return this.post(`${BASE_API_STRING}/Services/GetEndpointServices`, serviceId);
  };

  getTransactionsOperations = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTransactionsOperations`);
  };

  addEndpointServices = (model) => {
    return this.post(`${BASE_API_STRING}/Services/EndpointServiceAdd`, model);
  };

  updateEndpointServices = (model) => {
    return this.post(`${BASE_API_STRING}/Services/EndpointServiceUpdate`, model);
  };

  deleteEndpointServices = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/EndpointServiceDelete`, model);
  };

  getServicesBalances = ({ dateStart, dateEnd }) => {
    return this.get(`${BASE_API_STRING}/Services/GetServicesBalances?dateFrom=${dateStart}&dateTo=${dateEnd}`);
  };

  updateServiceBalanceLimit = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceBalanceLimitUpdate`, model);
  };

  getServiceOwners = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServiceOwners`);
  };

  getPsAccount = (id) => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetPsAccounts?customerId=${id}`);
  };

  getServicesBankingDetails = (serviceId) => {
    return this.get(`${BASE_API_STRING}/Services/GetServicesBankingDetails?serviceId=${serviceId}`);
  };

  getSuppliersTreaties = (supplierId) => {
    return this.post(`${BASE_API_STRING}/Dictionary/GetSuppliersTreaties`, supplierId);
  };

  getServicesPaymentsSchedules = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServicesPaymentsSchedules`);
  };

  getServicesGroups = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetServicesGroups`);
  };

  servicesGroupsMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ServicesGroupsMerge`, model);
  };
  servicesGroupsDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Dictionary/ServicesGroupsDelete`, model);
  };

  getAuthorizationTypeDictionaryList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetAuthorizationTypeDictionaryList`);
  };

  getServiceAuthorizationLimits = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetServiceAuthorizationLimits`, model);
  };

  addServiceAuthorizationLimits = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceAuthorizationLimitsAdd`, model);
  };

  updateServiceAuthorizationLimits = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceAuthorizationLimitsUpdate`, model);
  };

  deleteServiceAuthorizationLimits = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ServiceAuthorizationLimitsDelete`, model);
  };

  removeServiceAuthorizationLimits = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ServiceAuthorizationLimitsRemove`, model);
  };

  getActiveServicesForTerminals = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetActiveServicesForTerminals`, model);
  };

  changeServiceMenuStatus = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceMenuChangeStatus`, model);
  };

  checkTransactionsRecalculateEnabled = () => {
    return this.get(`${BASE_API_STRING}/Recalculate/CheckTransactionsRecalculateEnabled`);
  };

  recalculateDealerCommissions = (model) => {
    return this.post(`${BASE_API_STRING}/Recalculate/RecalculateDealerCommissions`, model);
  };

  recalculateSupplierCommissions = (model) => {
    return this.post(`${BASE_API_STRING}/Recalculate/RecalculateSupplierCommissions`, model);
  };

  getServiceCommissionsHistoryList = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionsHistoryList`, model);
  };

  getServices = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/Services`, model);
  };

  getTemplateList = () => {
    return this.get(`${BASE_API_STRING}/Services/GetReceiptsTemplateList`);
  };

  addReceiptsTemplate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ReceiptsTemplateAdd`, model);
  };

  updateReceiptsTemplate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ReceiptsTemplateUpdate`, model);
  };

  deleteReceiptsTemplate = (model) => {
    return this.delete(`${BASE_API_STRING}/Services/ReceiptsTemplateDelete`, model);
  };

  updateReceiptVersion = () => {
    return this.post(`${BASE_API_STRING}/Services/ReceiptVersionUpdate`);
  };

  getNBUServiceRelations = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetNBUServiceRelations`, model);
  };

  getReceiptsTemplateServiceList = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetReceiptsTemplateServiceList`, model);
  };

  setReceiptsTemplate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ReceiptsTemplateSet`, model);
  };

  getTerminalsServicesPaymentTypes = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetTerminalsServicesPaymentTypes`, model);
  };

  terminalsServicesPaymentTypesDelete = (ruleId) => {
    return this.delete(`${BASE_API_STRING}/Services/TerminalsServicesPaymentTypesDelete?ruleId=${ruleId}`, ruleId);
  };

  terminalsServicesPaymentTypesUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/TerminalsServicesPaymentTypesUpdate`, model);
  };

  terminalsServicesPaymentTypesAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/TerminalsServicesPaymentTypesAdd`, model);
  };

  paymentTypes = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetPaymentTypes`);
  };

  getSupportManagers = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetSupportManagers`);
  };

  getServiceCommissionsFutureList = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceCommissionsFutureList`, model);
  };

  deleteServiceCommissionFuture = (model) => {
    return this.delete(`${BASE_API_STRING}/Commissions/ServiceCommissionFutureDelete`, model);
  };

  getSupplierSettlementType = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetSupplierSettlementType`);
  };

  getTransactionPaymentType = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTransactionPaymentType`);
  };

  getSourceBankingDetails = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetSourceBankingDetails`);
  };

  serviceNonCharactCheck = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceNonCharactCheck`, model);
  };

  getBinList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingCardBINs`, true);
  };

  getSystemTypeList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingSystemTypes`, true);
  };

  getPANTypeList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingPanTypes`, true);
  };

  getIncomingServiceList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingIncomingServices`, true);
  };

  getOutcomingServiceList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingOutcomingServices`, true);
  };

  getRoutingBanksList = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/RoutingBanks`, true);
  };

  cardRoutingRowValidate = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/CardRoutingRowValidate`, model);
  };

  cardRoutingRowAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/CardRoutingRowAdd`, model);
  };

  cardRoutingRowUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/CardRoutingRowUpdate`, model);
  };

  cardRoutingRowDelete = (idsList) => {
    return this.delete(`${BASE_API_STRING}/Dictionary/CardRoutingRowDelete`, idsList);
  };

  getNextServiceId = () => {
    return this.get(`${BASE_API_STRING}/Services/GetNextServiceId`);
  };

  getProviders = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetProviders`);
  };

  addProvider = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ProviderAdd`, model);
  };

  addServicesType = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/ServicesTypeAdd`, model);
  };

  addService = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceAdd`, model);
  };

  serviceSupplierCommissionUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceSupplierCommissionUpdate`, model);
  };

  serviceOn = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceOn`, model);
  };

  serviceOff = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceOff`, model);
  };

  serviceNameCheck = (serviceName) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceNameCheck`, serviceName);
  };

  serviceClone = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceClone`, model);
  };

  serviceSupplierCommissionInsert = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceSupplierCommissionInsert`, model);
  };
  servicesCommissionsScaleInsert = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServicesCommissionsScaleInsert`, model);
  };

  servicesCommissionsScale = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServicesCommissionsScale`, model);
  };

  servicesCommissionsScaleUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServicesCommissionsScaleUpdate`, model);
  };

  serviceSupplierCommissionsFuture = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceSupplierCommissionsFuture`, model);
  };

  servicesCommissionsScaleFuture = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServicesCommissionsScaleFuture`, model);
  };

  commissionsTechnicalPartners = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/CommissionsTechnicalPartners`, model);
  };

  getTechnicalPartners = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTechnicalPartners`);
  };

  commissionsTechnicalPartnersInsert = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/CommissionsTechnicalPartnersInsert`, model);
  };

  commissionsTechnicalPartnersUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/CommissionsTechnicalPartnersUpdate`, model);
  };
  commissionsTechnicalPartnersDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Commissions/CommissionsTechnicalPartnersDelete`, model);
  };

  menuProfilesUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/ProfilesUpdate`, model);
  };

  menuProfilesInsert = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/ProfilesInsert`, model);
  };

  menuProfilesDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Menu/ProfilesDelete`, model);
  };

  menuProfileUpdateVersion = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/ProfileUpdateVersion`, model);
  };

  menuUpdateName = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuUpdate`, model);
  };

  menuClone = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuClone`, model);
  };

  menuDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Menu/MenuDelete`, model);
  };

  menuAddElement = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuInsert`, model);
  };

  menuParentUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuParentUpdate`, model);
  };

  menuPropertiesUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuPropertiesUpdate`, model);
  };

  getMenuPropertiesKeys = (ownerId, withService = true) => {
    return this.get(
      `${BASE_API_STRING}/Dictionary/GetMenuPropertiesKeys?ownerId=${ownerId}&whithService=${withService}`,
    );
  };

  menuPropertiesDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuPropertiesDelete`, model);
  };

  menuPropertiesInsert = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuPropertiesInsert`, model);
  };

  getTerminalsProfiles = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/GetTerminalsProfiles`, model);
  };

  terminalsProfilesUpdate = (modal) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsProfilesUpdate`, modal);
  };

  getAuditMenu = (modal) => {
    return this.post(`${BASE_API_STRING}/Menu/GetAuditMenu`, modal);
  };

  getMenuTree = (profileId, menuId) => {
    return this.get(`${BASE_API_STRING}/Menu/GetMenuTree?profileId=${profileId}&menuId=${menuId}`);
  };

  menuPropertiesKeyMerge = (modal) => {
    return this.post(`${BASE_API_STRING}/Dictionary/MenuPropertiesKeyMerge`, modal);
  };

  getReceiptTemplate = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/GetReceiptTemplate`, model);
  };

  authorizationBalanceInfo = (modal) => {
    return this.post(`${BASE_API_STRING}/EasyCard/AuthorizationBalanceInfo`, modal);
  };

  generateReceipts = (modal) => {
    return this.put(`${BASE_API_STRING}/Transaction/GenerateReceipts`, modal);
  };

  sendReceiptsToEmail = (modal) => {
    return this.put(`${BASE_API_STRING}/Transaction/SendReceiptsToEmail`, modal);
  };

  callbackResend = (modal) => {
    return this.post(`${BASE_API_STRING}/Transaction/CallbackResend`, modal);
  };

  getOwnerItems = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnerItems`, model);
  };

  getOwnerBankingAccountDetails = (model) => {
    return this.post(`${BASE_API_STRING}/OwnerAccount/OwnerBankingAccountDetails`, model);
  };

  mergeOwnersItems = (model) => {
    return this.post(`${BASE_API_STRING}/Owners/OwnersItemsMerge`, model);
  };

  terminalRegistrationApplication = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalRegistrationApplication`, model);
  };

  terminalUnregistrationApplication = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalUnregistrationApplication`, model);
  };

  terminalRegistrationApplicationStatus = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalRegistrationApplicationStatus`, model);
  };

  getTerminalRegistrationApplicationAction = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTerminalRegistrationApplicationAction`);
  };

  getTerminalRegistrationApplication = (terminalId) => {
    return this.post(`${BASE_API_STRING}/Terminal/GetTerminalRegistrationApplication`, terminalId);
  };

  getSUOList = () => {
    return this.post(`${WPS_API_STRING}/SanctionList/SuoList`);
  };

  createSUO = (model) => {
    return this.post(`${WPS_API_STRING}/SanctionList/SuoCreate`, model);
  };

  deleteSUO = (SUOId) => {
    return this.delete(`${WPS_API_STRING}/SanctionList/SuoDelete`, SUOId);
  };

  servicesPaymentType = (model) => {
    return this.post(`${BASE_API_STRING}/Report/ServicesPaymentType`, model);
  };

  getMFOs = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetMFOs`);
  };

  addMFO = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/MFOAdd`, model);
  };

  updateMFO = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/MFOUpdate`, model);
  };

  MFORefresh = () => {
    return this.post(`${BASE_API_STRING}/Dictionary/MFORefresh`);
  };

  availableToUsers = (elementKey, elementType) => {
    return this.get(
      `${BASE_API_STRING}/Security/UserListByElementKey?elementKey=${elementKey}&elementType=${elementType}`,
      true,
    );
  };

  getTechnicalPartnerSettlementTypes = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTechnicalPartnerSettlementTypes`);
  };

  technicalPartnerMerge = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/TechnicalPartnerMerge`, model);
  };

  serviceBalanceAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceBalanceAdd`, model);
  };

  serviceBalanceUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceBalanceUpdate`, model);
  };

  deleteSupplier = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/SupplierDelete`, model);
  };

  supplierAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/SupplierAdd`, model);
  };

  getServicesSuppliersCeo = () => {
    return this.get(`${BASE_API_STRING}/Supplier/GetServicesSuppliersCeo`);
  };

  servicesSuppliersCeoAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ServicesSuppliersCeoAdd`, model);
  };
  servicesSuppliersCeoUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ServicesSuppliersCeoUpdate`, model);
  };

  servicesSuppliersCeoDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/ServicesSuppliersCeoDelete`, model);
  };

  getServicesSuppliersBeneficiary = () => {
    return this.get(`${BASE_API_STRING}/Supplier/GetServicesSuppliersBeneficiar`);
  };

  getServiceSuppliersTreaties = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/GetSuppliersTreaties`, model);
  };

  getFinMonRiskList = (model) => {
    return this.get(`${BASE_API_STRING}/Dictionary/FinMonRisk`, model);
  };

  updateSupplierProps = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/SupplierUpdate`, model);
  };

  suppliersTreatiesAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/SuppliersTreatiesAdd`, model);
  };

  suppliersTreatiesUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/SuppliersTreatiesUpdate`, model);
  };

  suppliersTreatiesDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/SuppliersTreatiesDelete`, model);
  };

  getProviderPortalAccount = (supplierId) => {
    return this.post(`${BASE_API_STRING}/Supplier/GetProviderPortalAccount`, supplierId);
  };

  getProviderPortalAccountSupplier = (supplierId) => {
    return this.post(`${BASE_API_STRING}/Supplier/GetProviderPortalAccountSupplier`, supplierId);
  };

  addProviderPortalAccount = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ProviderPortalAccountAdd`, model);
  };

  updateProviderPortalAccount = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ProviderPortalAccountUpdate`, model);
  };

  providerPortalAccountRefAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ProviderPortalAccountRefAdd`, model);
  };

  providerPortalAccountDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/ProviderPortalAccountDelete`, model);
  };

  providerPortalAccountRefDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/ProviderPortalAccountRefDelete`, model);
  };

  menuPropertiesCheck = (model) => {
    return this.post(`${BASE_API_STRING}/Menu/MenuPropertiesCheck`, model);
  };

  sendCollectionReceiptsToEmail = (model) => {
    return this.put(`${BASE_API_STRING}/Collection/SendCollectionReceiptsToEmail`, model);
  };
  servicesSuppliersBeneficiarAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ServicesSuppliersBeneficiarAdd`, model);
  };
  servicesSuppliersBeneficiarUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Supplier/ServicesSuppliersBeneficiarUpdate`, model);
  };
  servicesSuppliersBeneficiarDelete = (model) => {
    return this.delete(`${BASE_API_STRING}/Supplier/ServicesSuppliersBeneficiarDelete`, model);
  };

  fiscalizedTransactionsByDays = (model) => {
    return this.post(`${BASE_API_STRING}/Report/FiscalizedTransactionsByDays`, model);
  };

  fiscalizedTransactionsByOwners = (model) => {
    return this.post(`${BASE_API_STRING}/Report/FiscalizedTransactionsByOwners`, model);
  };

  getTransactionItemsNames = (model) => {
    return this.get(`${BASE_API_STRING}/Dictionary/GetTransactionItemsNames`, model);
  };

  sendReceiptsToViber = (model) => {
    return this.put(`${BASE_API_STRING}/Transaction/SendReceiptsToViber`, model);
  };

  getReceiptsToPdf = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/SendReceiptsToPdf`, model);
  };

  commandBillAcceptorUnlock = (terminalId) => {
    return this.post(`${BASE_API_STRING}/Terminal/CommandBillAcceptorUnlock`, terminalId);
  };

  updateCardRoutingBank = (model) => {
    return this.post(`${BASE_API_STRING}/Dictionary/CardRoutingBankUpdate`, model);
  };

  recalculateTransactions = (model) => {
    return this.post(`${BASE_API_STRING}/Recalculate/RecalculateTransactions`, model);
  };

  recalculateReports = ({ DateFrom, DateTo }) => {
    return this.post(`${BASE_API_STRING}/Recalculate/RecalculateReports?dateFrom=${DateFrom}&dateTo=${DateTo}`);
  };

  recalculateOwnersAccounts = (model) => {
    return this.post(`${BASE_API_STRING}/Recalculate/RecalculateOwnersAccounts`, model);
  };

  getPaymentCustomers = (model) => {
    return this.post(`${WPS_API_STRING}/Dictionary/Customers`, model);
  };

  getPaymentMfoInfo = (model) => {
    return this.post(`${WPS_API_STRING}/Dictionary/MfoInfo`, model);
  };

  getPaymentAccounts = (model) => {
    return this.post(`${WPS_API_STRING}/Dictionary/AccountsFind`, model);
  };

  postPaymentsExternalInserted = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalInserted`, model);
  };

  postPaymentsExternalUpdated = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalUpdated`, model);
  };

  getIban = (model) => {
    return this.post(`${WPS_API_STRING}/Dictionary/GetIban`, model);
  };

  getPaymentsExternalInserted = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentExternalInfo`, model);
  };

  postPaymentsExternalCopy = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalCopy`, model);
  };

  postPaymentsExternalDelete = (model) => {
    return this.post(`${WPS_API_STRING}/PaymentSystem/PaymentsExternalDelete`, model);
  };

  simpleServiceCreate = (model) => {
    return this.post(`${BASE_API_STRING}/Services/SimpleServiceCreate`, model);
  };

  serviceSupplierAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Services/ServiceSupplierAdd`, model);
  };

  getServiceSupplierCommission = (serviceId) => {
    return this.post(`${BASE_API_STRING}/Commissions/ServiceSupplierCommission`, serviceId);
  };

  changePasswordByUser = (modal) => {
    return this.post(`${BASE_API_STRING}/Security/ChangePasswordByUser`, modal);
  };

  getTerminalsTypes = () => {
    return this.post(`${BASE_API_STRING}/Dictionary/TerminalsTypes`);
  };

  divideTransaction = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoDivide`, model);
  };

  mergeTransactions = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoUnion`, model);
  };

  getServicesForTransactionUpdate = () => {
    return this.get(`${BASE_API_STRING}/Dictionary/ServicesForTransactionUpdate`);
  };

  transactionUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/TransactionUpdate`, model);
  };

  transactionCommentAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/TransactionCommentAdd`, model);
  };

  transactionDoUnhold = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoUnhold`, model);
  };

  transactionDoDecline = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/DoDecline`, model);
  };

  transactionsRepayment = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/TransactionsRepayment`, model);
  };

  findTransactionForGraph = (model) => {
    return this.post(`${BASE_API_STRING}/Transaction/FindTransactionForGraph`, model);
  };

  getTerminalsServicesPaymentTypesHistory = (model) => {
    return this.post(`${BASE_API_STRING}/Services/GetTerminalsServicesPaymentTypesHistory`, model);
  };

  getTerminalsListByService = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsListByService`, model);
  };

  terminalsServicesDenominationAdd = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/terminalsServicesDenominationAdd`, model);
  };

  TerminalsServicesDenominationDelete = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsServicesDenominationDelete`, model);
  };

  TerminalsServicesDenominationVersionConfigUpdate = (model) => {
    return this.post(`${BASE_API_STRING}/Terminal/TerminalsServicesDenominationVersionConfigUpdate`, model);
  };
}
