import React, { PureComponent } from 'react';

import ManageMerchantCallbacksGrid from './ManageMerchantCallbacksGrid';
import PageTabs from '../../components/tabber/tabber';
import ManageMerchantParametersGrid from './ManageMerchantParametersGrid';
import { withTranslate } from '../../contexts/localContext';

class ManageMerchantCallbacks extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-manageMerchantCallbacks',
          content: <ManageMerchantCallbacksGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type, callbackId }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'manageMerchantCallbacksDetail':
        tabs.push({
          title: `${translate(`page.manageMerchantCallbacks.detailsTabTitle`)}: ${callbackId}`,
          content: <ManageMerchantParametersGrid callbackId={callbackId} />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(ManageMerchantCallbacks);
