import React, { PureComponent } from 'react';
import { Button, Modal, message, Select } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../GridDropdown/GridDropdown';
import { LANGUAGES, PROPS_CONFIG } from './constants';
import { ApiService } from '../../services';
import ElementPropsService from './ElementProps.service';
import PropValueRow from './PropValueRow';

const { Option } = Select;
const api = new ApiService();

class AddPropModal extends PureComponent {
  state = {
    isLoading: false,
    selectProp: [],
    selectLanguage: '',
    value: '',
    valueType: '',
    error: '',
  };

  renderValueRow = () => {
    const { selectProp, value, valueType } = this.state;

    if (!selectProp.length) {
      return <></>;
    }

    return (
      <PropValueRow
        valueType={valueType}
        setInputValues={(value, error) => {
          this.setState({
            value,
            error,
          });
        }}
        value={value}
      />
    );
  };

  handleAdd = async () => {
    const { profileId, menuId, translate, closeDialog, onRefresh } = this.props;
    const { selectProp, value, selectLanguage, valueType } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.menuPropertiesInsert({
        profileId,
        menuId,
        name: selectProp[0],
        value: ElementPropsService.getValueByType(value, valueType),
        language: selectLanguage || '',
      });
      await this.setState({ isLoading: false });
      closeDialog();
      message.success(translate('page.profilesMenu.propertyAddedSuccessfully'), 2);
      onRefresh();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { visible, closeDialog, translate, allProps } = this.props;
    const { isLoading, selectProp, value, valueType, error } = this.state;
    const isDisable = !selectProp.length || (!value && valueType !== 'bool') || !!error;

    return (
      <Modal
        title={translate('page.profilesMenu.addProps')}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" loading={isLoading} onClick={this.handleAdd} disabled={isDisable}>
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>{translate('page.profilesMenu.propName')}</div>
        <GridDropdown
          data={allProps.map(({ Key, Description, Type }) => ({ Id: Key, Name: Key, Description, Type }))}
          colConfig={PROPS_CONFIG}
          selectItems={selectProp}
          onSave={(selectProp) => {
            this.setState({
              selectProp,
              value: '',
              error: '',
              valueType: allProps.find(({ Key }) => Key === selectProp[0]).Type,
            });
          }}
          defaultTitle={translate('page.profilesMenu.selectProperty')}
          isSingle
        />

        {this.renderValueRow()}

        <div className="ElementProps-languageRow">
          <div>{translate('page.profilesMenu.language')}</div>
          <Select
            placeholder={translate('page.profilesMenu.selectLanguage')}
            onChange={(value) => {
              this.setState({ selectLanguage: value });
            }}
            disabled={valueType !== 'string' && valueType !== 'xml'}
          >
            {LANGUAGES.map((language) => (
              <Option value={language} key={language}>
                {language}
              </Option>
            ))}
          </Select>
        </div>

        {error && <div className="ElementProps-error">{translate(error)}</div>}
      </Modal>
    );
  }
}

export default withTranslate(AddPropModal);
