import React, { PureComponent } from 'react';
import { Checkbox, Col, Row } from 'antd';
import { TimePicker } from '@progress/kendo-react-dateinputs';

import { withTranslate } from '../../contexts/localContext';

import './GraphicWorkingHours.scss';

class GraphicWorkingHours extends PureComponent {
  render() {
    const { data, translate, changeGraphicDataByTime, changeGraphicDataByCheckbox } = this.props;

    return data.map(({ DayOfWeek, DayOff, TurnOn, TurnOff }) => {
      return (
        <Row key={DayOfWeek} className="GraphicWorkingHours-row" gutter={24}>
          <Col xs={24} md={6}>
            <p>{translate(`page.screenControlAddSets.${DayOfWeek}`)}</p>
          </Col>
          <Col md={12}>
            <Row>
              <Col xs={24} md={10}>
                <div style={{ position: 'relative' }}>
                  <TimePicker
                    disabled={DayOff}
                    format="HH:mm"
                    value={TurnOn}
                    onChange={(e) => changeGraphicDataByTime(e, DayOfWeek, 'TurnOn')}
                    nowButton={false}
                    cancelButton={false}
                    valid={new Date(TurnOn) <= new Date(TurnOff)}
                    popupSettings={{
                      popupClass: 'modalGraphicWH-popup-cls',
                    }}
                  />
                </div>
              </Col>
              <Col className="GraphicWorkingHours-divider" xs={24} md={4}>
                <span>-</span>
              </Col>
              <Col xs={24} md={10}>
                <div style={{ position: 'relative' }}>
                  <TimePicker
                    disabled={DayOff}
                    format="HH:mm"
                    value={TurnOff}
                    onChange={(e) => changeGraphicDataByTime(e, DayOfWeek, 'TurnOff')}
                    nowButton={false}
                    cancelButton={false}
                    valid={new Date(TurnOff) >= new Date(TurnOn)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={6}>
            <Checkbox
              onChange={(e) => changeGraphicDataByCheckbox(e, DayOfWeek)}
              checked={DayOff}
              style={{ fontSize: '12px' }}
            >
              {translate('page.screenControlAddSets.checkbox-label')}
            </Checkbox>
          </Col>
        </Row>
      );
    });
  }
}

export default withTranslate(GraphicWorkingHours);
