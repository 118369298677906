import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../../../../contexts/localContext';
import { CONFIGS, DEFAULT_TITLES, TRANSLATE_SLUGS } from './constants';
import GridDropdown from '../../../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../../../services';
import CommissionsService from '../../../../commission/Commission/commissions.service';
import LoadingPanel from '../../../../../components/loader';

import './ClientCommissions.styled.scss';

const api = new ApiService();

class AddClientCommissionsModal extends PureComponent {
  state = {
    isLoading: false,
    paymentTypes: [],
    selectedPaymentTypes: [],
    owners: [],
    selectedOwners: [],
    commissionsList: [],
    selectedCommissions: [],
    profiles: [],
    selectedProfiles: [],
  };

  componentDidMount() {
    this.initial();
  }

  initial = async () => {
    this.setState({ isLoading: true });
    await this.getPaymentType();
    await this.getOwners();
    await this.fetchCommissionsList();
    this.setState({ isLoading: false });
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      await this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
      this.setState({ isLoading: false });
    }
  };

  getOwners = async () => {
    try {
      const owners = await api.owners();

      await this.setState({ owners: DropdownNormalizersService.normalizeOwners(owners) });
    } catch (error) {
      const { showError } = this.props;

      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchCommissionsList = async () => {
    try {
      const commissionsList = await api.getComissionServiceDetails();
      this.setState({ commissionsList: CommissionsService.normalizeCommissions(commissionsList) });
    } catch (error) {
      const { showError } = this.props;

      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchProfiles = async (selectedOwners) => {
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const data = await api.postProfileList({
        ownerId: [...selectedOwners],
      });

      await this.setState({
        profiles: data && data.length ? DropdownNormalizersService.normalizeProfiles(data, translate) : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  getSelectChangeCallback = (name) => (selectedItems) => {
    this.setState({ [name]: selectedItems }, () => {
      if (name === 'selectedOwners') {
        this.fetchProfiles(selectedItems);
      }
    });
  };

  renderSelect = ({ name, selectedItemsField, isDisabled }) => {
    const { translate } = this.props;

    return (
      <div className="StepServiceCreationClientCommissions-inputRow">
        <div className="StepServiceCreationClientCommissions-label">{translate(TRANSLATE_SLUGS[name])}</div>
        <div className="StepServiceCreationClientCommissions-input">
          <GridDropdown
            data={this.state[name]}
            colConfig={CONFIGS[name]}
            selectItems={this.state[selectedItemsField]}
            onSave={this.getSelectChangeCallback(selectedItemsField)}
            defaultTitle={translate(DEFAULT_TITLES[name])}
            disabled={isDisabled}
          />
        </div>
      </div>
    );
  };

  handleAdd = () => {
    const { onAdd, onClose } = this.props;
    const { selectedProfiles, selectedPaymentTypes, selectedCommissions, profiles, paymentTypes, commissionsList } =
      this.state;

    const commissionList = commissionsList
      .filter(({ Id }) => selectedCommissions.includes(Id))
      .map((commission) => ({
        ...commission,
        paymentTypes: paymentTypes
          .filter(({ Id }) => selectedPaymentTypes.includes(Id))
          .map(({ Id, Name }) => ({ PaymentTypeName: Name, PaymentTypeId: Id })),
        profiles: profiles
          .filter(({ Id }) => selectedProfiles.includes(Id))
          .map(({ Id, Name }) => ({ ProfileName: Name, ProfileId: Id })),
      }));

    onAdd(
      commissionList.reduce(
        (acc, { paymentTypes, profiles, CommissionId, ...restCommissionProps }) => [
          ...acc,
          ...paymentTypes
            .reduce(
              (acc, paymentType) => [
                ...acc,
                ...profiles.map((profile) => ({
                  ...profile,
                  ...paymentType,
                  id: `${profile.ProfileId}-${paymentType.PaymentTypeId}`,
                })),
              ],
              [],
            )
            .map((item) => ({ ...item, CommissionId, ...restCommissionProps, id: `${CommissionId}-${item.id}` })),
        ],
        [],
      ),
    );
    onClose();
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const { isLoading, selectedOwners, selectedProfiles, selectedPaymentTypes, selectedCommissions } = this.state;

    const calcActionDisabled =
      !selectedOwners.length || !selectedProfiles.length || !selectedPaymentTypes.length || !selectedCommissions.length;

    return (
      <Modal
        className="StepServiceCreationClientCommissionsModal"
        title={translate('page.servicesBigTable.commissionAdding')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="back" className="modal--add-limit-cancel" onClick={onClose}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
          <Button
            key="submit"
            className="modal--add-limit-set"
            type="primary"
            disabled={calcActionDisabled}
            onClick={this.handleAdd}
          >
            {translate('core.buttonTitles.add')}
          </Button>,
        ]}
      >
        {this.renderSelect({ name: 'owners', selectedItemsField: 'selectedOwners' })}
        {this.renderSelect({
          name: 'profiles',
          selectedItemsField: 'selectedProfiles',
          isDisabled: !selectedOwners.length,
        })}
        {this.renderSelect({ name: 'paymentTypes', selectedItemsField: 'selectedPaymentTypes' })}
        {this.renderSelect({ name: 'commissionsList', selectedItemsField: 'selectedCommissions' })}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddClientCommissionsModal);
