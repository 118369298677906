import React, { PureComponent } from 'react';
import { Card, Form, Input, Button, Row, Col, Icon, message, Checkbox } from 'antd';
import { enc } from 'crypto-js';
import aes from 'crypto-js/aes';

import { withTranslate } from '../../contexts/localContext';
import StorageService from '../../services/storageService';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { createHash } from '../../utils/createHash';
import './profile.scss';

const api = new ApiService();

class PageProfile extends PureComponent {
  constructor(props) {
    super(props);
    // console.log("PageProfile::",props);
    const { isPermittedAutoLogin } = props;
    this.state = {
      loading: false,
      isPermittedAutoLogin: isPermittedAutoLogin,
      isLoading: false,
    };
  }

  getEmail = () => StorageService.getItem('userInfo').Email;

  onSubmit = (e) => {
    const { form, translate } = this.props;
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        console.log('Received error from form: ', err);
        return;
      }

      try {
        this.toggleLoading(true);
        let { password, newpassword, confirmnewpassword } = values;

        const hashedOldPassword = createHash(password);
        const hashedConfirmPassword = createHash(confirmnewpassword);
        const hashedNewPassword = createHash(newpassword);

        if (hashedNewPassword !== hashedConfirmPassword) {
          form.resetFields();
          throw new Error('Ошибка: новый пароль не совпадает с подтверждённым!');
        }

        if (hashedOldPassword === hashedNewPassword) {
          form.resetFields();
          throw new Error('Ошибка: старый и новый пароли совпадают!');
        }

        const query = JSON.stringify({ OldHash: hashedOldPassword, NewHash: hashedNewPassword });
        await api.changePassword(query);
        // console.log(res);
        form.resetFields();
        message.success(`${translate('grids.profile.success')}`, 2);
      } catch (error) {
        const { showError } = this.props;
        showError(error);
      } finally {
        this.toggleLoading(false);
      }
    });
  };

  toggleLoading = (loading) => {
    this.setState({ loading });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onChangeAutoLogin = async (e) => {
    const { showError, changePermissionAutoLogin, translate } = this.props;
    const isPermittedAutoLogin = e.target.checked;
    try {
      this.toggleLoader();
      const DeviceCode = StorageService.getItem('devideCode');
      if (!DeviceCode) return message.error(`${translate('page.profile.error-untrusted')}`, 2);
      const bytes = aes.decrypt(DeviceCode, '123456789');
      const originalCode = bytes.toString(enc.Utf8).replace(/['"«»]/g, '');
      const model = {
        DeviceCode: originalCode,
        UseLightLogin: isPermittedAutoLogin,
      };
      const response = await api.usersTrustedDevicesUseLightLogin(model);
      changePermissionAutoLogin(isPermittedAutoLogin);
      this.setState({ isPermittedAutoLogin });
      return response;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  cleanTrustedDevices = async () => {
    try {
      const { changePermissionAutoLogin, translate } = this.props;
      this.toggleLoader();
      const response = await api.usersTrustedDevicesClean();
      StorageService.removeItem('devideCode');
      changePermissionAutoLogin(false);
      this.setState({ isPermittedAutoLogin: false });
      message.success(`${translate('page.profile.success-unbindedDevices')}`, 2);
      return response;
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const { translate } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, isPermittedAutoLogin, isLoading } = this.state;
    const Item = Form.Item;
    return (
      <div style={{ backgroundColor: '#f0f2f5' }} className="profile-wrapper">
        <Card className="profile-card" title={translate('page.profile.label-profileSettings')}>
          <Card type="inner" title={translate('page.profile.label-generalInformation')}>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col xs={24} sm={8} md={8}>
                <span>{translate('page.profile.label-partnerLogin')}</span>
              </Col>
              <Col xs={24} sm={16} md={16}>
                <h4>User</h4>
              </Col>
            </Row>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col xs={24} sm={8} md={8}>
                <span>{translate('page.profile.label-email')}</span>
              </Col>
              <Col xs={24} sm={16} md={16}>
                <h4>{this.getEmail()}</h4>
              </Col>
            </Row>
          </Card>
          <Card type="inner" style={{ marginTop: '20px' }} title={translate('page.profile.label-changePassword')}>
            <Form onSubmit={this.onSubmit} className="login-form">
              <Item label={translate('page.profile.label-oldPassword')}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('page.profile.label-oldPassword')}
                  />,
                )}
              </Item>
              <Item label={translate('page.profile.label-newPassword')}>
                {getFieldDecorator('newpassword', {
                  rules: [{ required: true, message: 'Please type your new Password!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('page.profile.label-newPassword')}
                  />,
                )}
              </Item>
              <Item label={translate('page.profile.label-confirm')}>
                {getFieldDecorator('confirmnewpassword', {
                  rules: [{ required: true, message: 'Please confirm your new Password!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('page.profile.label-confirm')}
                  />,
                )}
              </Item>
              <Item>
                <Button type="primary" htmlType="submit" loading={loading} className="login-form-button">
                  {translate('page.profile.button-save')}
                </Button>
              </Item>
            </Form>
          </Card>
          <Card type="inner" title={translate('page.profile.card-trustedDevices-title')}>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col xs={24}>
                <Checkbox checked={isPermittedAutoLogin} onChange={this.onChangeAutoLogin}>
                  {translate('page.profile.card-trustedDevices-checkbox')}
                </Checkbox>
              </Col>
              <Col xs={24}>
                <p className="card-trustedDevices-warning">*{translate('page.profile.card-trustedDevices-warning')}</p>
              </Col>
            </Row>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col xs={24}>
                <Button
                  style={{
                    marginTop: '20px',
                  }}
                  type="primary"
                  onClick={this.cleanTrustedDevices}
                >
                  {translate('page.profile.card-trustedDevices-btn')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Card>
        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

const WrappedProfileForm = Form.create({ name: 'profile' })(PageProfile);
export default withTranslate(WrappedProfileForm);
