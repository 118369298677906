import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import MenuProfile from '../../components/MenuProfiles';
import { widthUpdateMenuProfileContext } from '../../components/MenuProfiles/UpdateMenuProfileContext';
import LoadingPanel from '../../components/loader';

import './NavigationMenuPropGrid.styled.scss';

class MenuProfileDialog extends PureComponent {
  render() {
    const { visible, closeDialog, translate, isLoading, selectedProfiles, saveSelectedProfiles } = this.props;

    return (
      <Modal
        className="NavigationMenuPropGrid-selectProfileDialog"
        title={translate('page.navigationMenuProp.selectProfiles')}
        visible={visible}
        onCancel={closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            disabled={!selectedProfiles.length}
            onClick={() => {
              saveSelectedProfiles(selectedProfiles);
              closeDialog();
            }}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <MenuProfile />
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(widthUpdateMenuProfileContext(MenuProfileDialog));
