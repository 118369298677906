import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import FormStateContainer from '../../../components/FormStateContainer';
import { withTranslate } from '../../../contexts/localContext';
import findArray from '../../../utils/findArrForCurrentPage';
import { ApiService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import Grid from '../../../components/grids/baseGrid';
import TechnicalPartnersUpdateDialog from './TechnicalPartnersUpdateDialog';

import './TechnicalPartnersGrid.styled.scss';
import { INITIAL_VALUES } from './constants';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const api = new ApiService();

class TechnicalPartnersGrid extends PureComponent {
  currentArray = findArray('technicalPartners');

  state = {
    isLoading: false,
    technicalPartnerList: [],
    modalVariant: '',
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchTechnicalPartnersList();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="TechnicalPartnersGrid-toolbar">
        {checkElement('technicalPartners-add', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'add' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('technicalPartners-edit', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'edit' });
              }}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          </div>
        )}

        {checkElement('technicalPartners-delete', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  fetchTechnicalPartnersList = async () => {
    try {
      await this.setState({ isLoading: true });
      const technicalPartnerList = await api.getTechnicalPartners();
      this.setState({ technicalPartnerList, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  onDelete = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.technicalPartnerMerge({ ...selectedItems[0], forDelete: true });
      this.setState({ modalVariant: '' });
      message.success(translate(`page.technicalPartners.technicalPartnerSuccessfulDeleted`), 2.5);
      this.fetchTechnicalPartnersList();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, technicalPartnerList, modalVariant, selectedItems } = this.state;
    const { translate } = this.props;

    if (!checkElement('technicalPartners-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={technicalPartnerList}
          isLoading={isLoading}
          onRefresh={this.fetchTechnicalPartnersList}
          name="TechnicalPartnersGrid"
          toolbar={this.buildToolbar()}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          fieldForSelection="Id"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="Id" title="page.technicalPartners.col-id" width="90" filter="numeric" />
          <GridColumn field="Name" title="page.technicalPartners.col-name" width="200" />
          <GridColumn field="SettlementTypeName" title="page.technicalPartners.col-calculation" width="200" />
          <GridColumn field="Contract" title="page.technicalPartners.col-contractNumber" width="200" filter="numeric" />
          <GridColumn field="Edrpo" title="page.technicalPartners.col-EDRPOU" width="100" filter="numeric" />
          <GridColumn field="Mfo" title="page.technicalPartners.col-MFO" width="100" filter="numeric" />
          <GridColumn field="Iban" title="page.technicalPartners.col-IBAN" width="240" />
          <GridColumn field="Narrative" title="page.technicalPartners.col-purposePayment" width="1000" />
        </Grid>

        {(modalVariant === 'add' || modalVariant === 'edit') && (
          <FormStateContainer
            initialValues={
              modalVariant === 'add'
                ? INITIAL_VALUES
                : {
                    Id: selectedItems[0].Id,
                    Name: selectedItems[0].Name,
                    SettlementType: [selectedItems[0].SettlementType],
                    Contract: selectedItems[0].Contract,
                    Edrpo: selectedItems[0].Edrpo,
                    Mfo: [selectedItems[0].Mfo],
                    Iban: selectedItems[0].Iban,
                    Narrative: selectedItems[0].Narrative,
                  }
            }
          >
            <TechnicalPartnersUpdateDialog
              variant={modalVariant}
              closeModal={() => {
                this.setState({ modalVariant: '' });
              }}
              onRefresh={this.fetchTechnicalPartnersList}
            />
          </FormStateContainer>
        )}

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.onDelete}
            deleteContent={translate('page.technicalPartners.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TechnicalPartnersGrid);
