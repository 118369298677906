import React, { PureComponent } from 'react';
import { Modal, Button, message, Input, Alert, Checkbox } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './SubBrandsDictionary.scss';
import { MENU_PROFILE_CONFIG } from '../SubBrandsTerminalsGrid/constants';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import SubBrandsDictionaryService from './SubBrandsDictionary.service';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();
const { TextArea } = Input;

class UpdateAndCreateBrandDialog extends PureComponent {
  state = {
    isLoading: false,
    menuProfiles: [],
    selectMenuProfiles: [],
    subBrandName: '',
    footer: '',
    subBrandNameError: '',
    changeProfileCommission: false,
  };

  componentDidMount() {
    const { variant, selectedBrands } = this.props;

    if (variant === 'changeBrand' && selectedBrands.length) {
      this.setState({
        subBrandName: selectedBrands[0].Name,
        footer: selectedBrands[0].Footer,
        selectMenuProfiles: [selectedBrands[0].MenuProfielId],
        changeProfileCommission: selectedBrands[0].IsChangeCommissionEnabled,
      });
    }

    this.getMenuProfiles();
  }

  getMenuProfiles = async () => {
    this.setState({ isLoading: true });

    try {
      const data = await api.getMenuProfiles();

      this.setState({
        menuProfiles: data && data.length ? SubBrandsDictionaryService.normalizeMenuProfiles(data) : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleSubBrandName = ({ target: { value } }) => {
    this.setState({
      subBrandName: value,
      subBrandNameError: value
        ? value.match(/[^a-zA-Z]/)
          ? 'page.fishkaService.invalidDataFormatError'
          : ''
        : 'page.commissionsServices.dropdownRequiredError',
    });
  };

  onSelectChange = (selectMenuProfiles) => {
    this.setState({ selectMenuProfiles });
  };

  handleUpdateAndAddBrand = async () => {
    const { onClose, onRefresh, selectedBrands, variant, translate } = this.props;
    const { subBrandName, footer, selectMenuProfiles, menuProfiles, changeProfileCommission } = this.state;

    this.setState({ isLoading: true });

    try {
      await api.brandMerge({
        BrandId: variant === 'changeBrand' ? selectedBrands[0].BrandId : null,
        Name: subBrandName,
        Footer: footer,
        MenuProfileId: selectMenuProfiles[0],
        MenuProfileName: menuProfiles.find(({ Id }) => Id === selectMenuProfiles[0]).Name,
        isChangeCommissionEnabled: changeProfileCommission,
      });

      this.setState({
        isLoading: false,
      });
      onClose();
      message.success(
        `${translate(
          variant === 'changeBrand'
            ? 'page.subBrandsTerminals.successfullyChanged'
            : 'page.subBrandsTerminals.successfullyAdded',
        )}`,
        2,
      );
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onHandleChangeInput = (e) => {
    this.setState({ changeProfileCommission: !e.target.value });
  };

  render() {
    const { translate, variant, visible, onClose } = this.props;
    const {
      menuProfiles,
      selectMenuProfiles,
      isLoading,
      subBrandName,
      subBrandNameError,
      footer,
      changeProfileCommission,
    } = this.state;

    return (
      <Modal
        title={translate(
          variant === 'changeBrand' ? 'page.subBrandsTerminals.changeBrand' : 'page.subBrandsTerminals.addBrand',
        )}
        visible={visible}
        onCancel={onClose}
        className="UpdateAndCreateBrandDialog"
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={this.handleUpdateAndAddBrand}
            disabled={!!subBrandNameError || !selectMenuProfiles.length || !subBrandName}
          >
            {translate(variant === 'changeBrand' ? 'core.buttonTitles.change' : 'core.buttonTitles.add')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="UpdateAndCreateBrandDialog-label">{translate('page.subBrandsTerminals.col-SubBrandName')}</div>
        <Input
          maxLength={110}
          onChange={this.handleSubBrandName}
          value={subBrandName}
          disabled={variant === 'changeBrand'}
        />
        {subBrandNameError && <div className="UpdateAndCreateBrandDialog-error">{translate(subBrandNameError)}</div>}

        <div className="UpdateAndCreateBrandDialog-label">{translate('page.subBrandsTerminals.col-profileMenu')}</div>
        <GridDropdown
          data={menuProfiles}
          colConfig={MENU_PROFILE_CONFIG}
          selectItems={selectMenuProfiles}
          onSave={this.onSelectChange}
          defaultTitle={translate('page.subBrandsTerminals.chooseProfileMenu')}
          isSingle
        />

        <div className="UpdateAndCreateBrandDialog-checkboxRow">
          <div className="UpdateAndCreateBrandDialog-label">
            {translate('page.subBrandsTerminals.col-changeProfile')}
          </div>

          <Checkbox
            defaultChecked={changeProfileCommission}
            value={changeProfileCommission}
            onChange={this.onHandleChangeInput}
          />
        </div>

        <div className="UpdateAndCreateBrandDialog-label">
          {translate('page.subBrandsTerminals.col-terminalFooter')}
        </div>
        <TextArea
          value={footer}
          onChange={({ target: { value } }) => {
            this.setState({ footer: value });
          }}
          className="UpdateAndCreateBrandDialog-textArea"
        />

        {variant === 'changeBrand' && (
          <Alert
            message={translate('grids.wallets.confirm.title')}
            description={translate('page.subBrandsTerminals.payAttention')}
            type="warning"
            showIcon
          />
        )}

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateAndCreateBrandDialog);
