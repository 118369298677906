import React from 'react';
import PropTypes from 'prop-types';

import { withTranslate } from '../../contexts/localContext';

const CellMessagesStatus = ({ style, field, dataItem, translate, className, mobile }) => {
  const value = dataItem[field];

  const transformValue = () => {
    switch (value) {
      case 'Новое':
        return translate('grids.messages.status-1');
      case 'Не прочитано':
        return translate('grids.messages.status-2');
      case 'Прочитано':
        return translate('grids.messages.status-3');
      default:
        return value;
    }
  };

  if (mobile) {
    return (
      <div style={{ ...style }} className={className}>
        <span>{transformValue()}</span>
      </div>
    );
  }

  return (
    <td style={{ ...style }} className={className}>
      <span>{transformValue()}</span>
    </td>
  );
};

CellMessagesStatus.defaultProps = {
  mobile: false,
};

CellMessagesStatus.propTypes = {
  mobile: PropTypes.bool,
};

export default withTranslate(CellMessagesStatus);
