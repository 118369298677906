import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'antd';
import { GridHeaderCell } from '@progress/kendo-react-grid';

import './baseHeaderCell.scss';
import { MOBILE_BREAKPOINT } from './constants';

export default class BaseHeaderCell extends GridHeaderCell {
  static defaultProps = {
    showAllSelected: false,
  };

  static propTypes = {
    showAllSelected: PropTypes.bool,
  };

  renderWarningInfoIcon = () => {
    const { warningInfo } = this.props;

    return (
      <Tooltip title={warningInfo} placement="top" trigger={window.innerWidth <= MOBILE_BREAKPOINT ? 'click' : 'hover'}>
        <div className="WarningInfoIconContainer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="WarningInfoIcon">
            <path d="M16 2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2zm0 1.2c7.1 0 12.8 5.7 12.8 12.8S23.1 28.8 16 28.8 3.2 23.1 3.2 16 8.9 3.2 16 3.2zm0 4.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8S17 7.5 16 7.5zm-2.4 6.1v1.2h1.2v7.9h-1.2v1.2H18.4v-1.2h-1.2v-9.1h-3.6z" />
          </svg>
        </div>
      </Tooltip>
    );
  };

  render() {
    const {
      onClick,
      title,
      children,
      filterActivity,
      field,
      showAllSelected,
      headerSelected = false,
      onChangeCallback,
      warningInfo,
      allowWordWrap,
    } = this.props;

    return filterActivity === true ? (
      <div style={{ display: 'flex' }}>
        <button
          // href="src/components/grids/baseHeaderCell/baseHeaderCell#1"
          className="k-link BaseHeaderCell-action"
          onClick={onClick}
          // style={{ display: 'flex' }}
        >
          <div {...(allowWordWrap ? { style: { whiteSpace: 'normal', textAlign: 'left' } } : {})}>
            {field === 'selected' && showAllSelected ? (
              <Checkbox checked={headerSelected} onChange={onChangeCallback} />
            ) : (
              title
            )}
          </div>

          {children}
        </button>
        {warningInfo && this.renderWarningInfoIcon()}
      </div>
    ) : null;
  }
}
