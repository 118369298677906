import React, { PureComponent } from 'react';
import { Row, Button, Input, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import { ApiService } from '../../../../services';
import UpdateModal from './UpdateModal';

import './ServiceEndpoints.scss';
import LoadingPanel from '../../../../components/loader';
import findArray from '../../../../utils/findArrForCurrentPage';
import checkElement from '../../../../utils/checkElement';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';

const api = new ApiService();
const { TextArea } = Input;

class ServiceEndpoints extends PureComponent {
  currentArray = findArray('serviceManagement');

  state = {
    endpoints: [],
    isLoading: false,
    selectedEndpoints: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchServiceEndpoints();
  }

  fetchServiceEndpoints = async () => {
    const {
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;

    try {
      this.setState({ isLoading: true });
      const endpoints = await api.getEndpointServices(ServiceId);
      await this.setState({
        endpoints: endpoints,
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleSelectedItemsChange = (selectedEndpoints) => {
    this.setState({ selectedEndpoints });
  };

  deleteSelectedEndpoint = async () => {
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;
    const { selectedEndpoints } = this.state;

    try {
      await api.deleteEndpointServices({
        serviceId: ServiceId,
        endpointId: selectedEndpoints[0].OperationId,
      });

      this.fetchServiceEndpoints();
      message.success(translate(`page.serviceEndpoints.successfullyDeleted`), 2);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId, Name },
      },
    } = this.props;
    const { selectedEndpoints } = this.state;

    return (
      <Row className="ServiceEndpoints-toolbar">
        <div className="ServiceEndpoints-serviceInfo">
          <div className="ServiceEndpoints-label">{translate('grids.servicesBigTable.column-serviceId')}</div>
          <Input value={ServiceId} disabled />
        </div>

        <div className="ServiceEndpoints-serviceInfo">
          <div className="ServiceEndpoints-label">{translate('grids.servicesBigTable.column-name')}</div>
          <TextArea value={Name} disabled autoSize />
        </div>

        {checkElement('serviceManagement-serviceEndpointsAdd', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'addOperation' });
            }}
          >
            {translate('page.serviceEndpoints.addEndpoint')}
          </Button>
        )}

        {checkElement('serviceManagement-serviceEndpointsChange', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'changeOperation' });
            }}
            disabled={!selectedEndpoints.length}
          >
            {translate('page.serviceEndpoints.changeEndpoint')}
          </Button>
        )}

        {checkElement('serviceManagement-serviceEndpointsDelete', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'deleteOperation' });
            }}
            disabled={!selectedEndpoints.length}
          >
            {translate('page.serviceEndpoints.deleteEndpoint')}
          </Button>
        )}
      </Row>
    );
  };

  render() {
    const { endpoints, isLoading, modalVariant, selectedEndpoints } = this.state;
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;

    return (
      <>
        <Grid
          data={endpoints}
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchServiceEndpoints}
          isLoading={isLoading}
          fieldForSelection="OperationId"
          onSelect={this.handleSelectedItemsChange}
          name="serviceEndpointsGrid"
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="OperationName" title="page.serviceEndpoints.col-operation" width="200" />
          <GridColumn field="Endpoint" title="page.serviceEndpoints.col-endpoint" width="300" />
          <GridColumn field="Endpoint2" title="page.serviceEndpoints.col-endpoint2" width="300" />
          <GridColumn field="Upn" title="page.serviceEndpoints.col-UPN" width="300" />
        </Grid>

        {(modalVariant === 'addOperation' || modalVariant === 'changeOperation') && (
          <UpdateModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItem={selectedEndpoints && selectedEndpoints.length ? selectedEndpoints[0] : null}
            serviceId={ServiceId}
            onRefresh={this.fetchServiceEndpoints}
            endpoints={endpoints}
          />
        )}

        {modalVariant === 'deleteOperation' && (
          <DeleteModal
            visible={modalVariant === 'deleteOperation'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            deleteContent={translate('page.serviceEndpoints.areYouSure')}
            submitCallback={this.deleteSelectedEndpoint}
          />
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ServiceEndpoints);
