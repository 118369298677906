import FieldsValidationService from '../../fieldsValidation.service';

class AddModalService extends FieldsValidationService {
  getBinValidator = (translate) => (binListString) => {
    const bins = binListString.split(',');

    const integerNumberValidationErrorValue = bins.find((value) => !!this.getIntegerNumberValidation(translate)(value));

    if (integerNumberValidationErrorValue) {
      return this.getIntegerNumberValidation(translate)(integerNumberValidationErrorValue);
    }

    if (bins.some((value) => (`${value}`.trim().length < 6 || `${value}`.trim().length > 8) && value !== 'null')) {
      return `${translate('page.routingManagement.binLengthError')}`;
    }

    return '';
  };

  getValidators = (translate) => ({
    bins: this.getBinValidator(translate),
    rangeFrom: this.getIntegerNumberValidation(translate),
    rangeTo: this.getIntegerNumberValidation(translate),
    amountMax: this.getNumberValidation(translate),
    priority: this.validatePriority(translate),
    weight: this.validateWeight(translate),
  });
}

export default new AddModalService();
