import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import ExternalOutgoingPaymentsBalance from './ExternalOutgoingPaymentsBalance';
import SuspendedFinancialTransactionsGrid from './SuspendedFinancialTransactionsGrid';

class ExternalOutgoingPaymentsPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-externalOutgoingPayments',
        content: <SuspendedFinancialTransactionsGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type, paymentId }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'externalOutgoingPayments':
        tabs.push({
          title: `page.externalOutgoingPayments.paymentTab`,
          content: <ExternalOutgoingPaymentsBalance />,
          closable: true,
          key: key,
        });
        break;
      case 'editExternalOutgoingPayments':
        tabs.push({
          title: `№ ${paymentId}`,
          content: <ExternalOutgoingPaymentsBalance id={paymentId} />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default ExternalOutgoingPaymentsPage;
