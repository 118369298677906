class ChangingProfileHistoryService {
  formatDate = (date) => `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`;

  getMinimumSelectedDate = () => new Date(new Date() - 180 * 24 * 60 * 60 * 1000);

  getDifference = (currentDate) => {
    const minimumSelectedDate = this.getMinimumSelectedDate();

    return (
      new Date(
        new Date(minimumSelectedDate).getFullYear(),
        new Date(minimumSelectedDate).getMonth(),
        new Date(minimumSelectedDate).getDate(),
        0,
        0,
        0,
        0,
      ) -
        new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate(),
          0,
          0,
          0,
          0,
        ) >
      0
    );
  };
}

export default new ChangingProfileHistoryService();
