import FieldsValidationService from '../fieldsValidation.service';

class UpdateModalService extends FieldsValidationService {
  getValidators = (translate) => ({
    rangeFrom: this.getIntegerNumberValidation(translate),
    rangeTo: this.getIntegerNumberValidation(translate),
    amountMax: this.getNumberValidation(translate),
    priority: this.validatePriority(translate),
    weight: this.validateWeight(translate),
  });
}

export default new UpdateModalService();
