import React, { PureComponent } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';

class BeneficiaryGrid extends PureComponent {
  render() {
    const { servicesSuppliersBeneficiary, translate } = this.props;

    return (
      <Grid data={servicesSuppliersBeneficiary} resizable>
        <GridColumn field="Id" title="Id" width="120" />
        <GridColumn field="Name" title={translate('page.serviceSupplier.fullName')} width="800" />
        <GridColumn field="Inn" title={translate('page.serviceSupplier.ipn')} width="160" />
      </Grid>
    );
  }
}

export default withTranslate(BeneficiaryGrid);
