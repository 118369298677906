const accountManagement = (translate, value) => {
  switch (value) {
    case 'accountManagement-AccountId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-accountId')}`;
    case 'accountManagement-Active':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.terminals.check-active')}`;
    case 'accountManagement-Amount':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-balance')}`;
    case 'accountManagement-AvailableForUse':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-availableForUse')}`;
    case 'accountManagement-Comments':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-comments')}`;
    case 'accountManagement-Currency':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.collectionDetails.column-currency')}`;
    case 'accountManagement-DatePost':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-date')}`;
    case 'accountManagement-LastDateMovement':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-lastDateMovement')}`;
    case 'accountManagement-Limit':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-limit')}`;
    case 'accountManagement-Limit2':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-dynamic')}`;
    case 'accountManagement-Limit3':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-encashment')}`;
    case 'accountManagement-Manager':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-manager')}`;
    case 'accountManagement-Overdraft':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-overdraft')}`;
    case 'accountManagement-OwnerId':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-ownerId')}`;
    case 'accountManagement-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.accountsLimit.column-owner')}`;
    case 'accountManagement-StatusName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-status')}`;
    case 'accountManagement-tab-type-accountactivity':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate('page.account.tab-activity')}`;
    case 'accountManagement-tab-type-dynamiclimits':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate('page.account.tab-accountsLimit')}`;
    case 'accountManagement-TurnDayTransactionAvg':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-turnDayTransAvg')}`;
    case 'accountManagement-TypeName':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-typeName')}`;
    case 'accountManagement-UserNamePost':
      return `${translate(`page.adminPanel.type-column`)}: ${translate('grids.account.column-performer')}`;
    case 'accountsDetail-Account':
      return `${translate(`page.adminPanel.type-tab`)}:
   ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} : ${translate(
        'grids.account.column-performer',
      )}`;
    case 'accountsDetail-AccountName':
      return `${translate(`page.adminPanel.type-tab`)}:
   ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
    ${translate('grids.accountsTurn.column-name')}`;
    case 'accountsDetail-Amount':
      return `${translate(`page.adminPanel.type-tab`)}:
   ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
    ${translate('grids.accountsDetail.column-amount')}`;
    case 'accountsDetail-Currency':
      return `${translate(`page.adminPanel.type-tab`)}:
     ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
      ${translate('grids.transactions.column-currency')}`;
    case 'accountsDetail-DatePost':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsDetail.column-datePost')}`;
    case 'accountsDetail-Description':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsDetail.column-narrative')}`;
    case 'accountsDetail-MovementId':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
           ${translate('grids.accountsDetail.column-payment')}`;
    case 'accountsDetail-TypeName':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsDetail.column-category')}`;
    case 'accountsDetail-UserNamePost':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsDetail.column-performer')}`;
    case 'accountsDetail-WorkDate':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-details')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsDetail.column-date')}`;
    case 'accountsLimit-AccountId':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.account.column-accountId')}`;
    case 'accountsLimit-addLimit':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-button')} :
          ${translate('grids.accountsLimit.button-setLimit')}`;
    case 'accountsLimit-btn-submit':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-button')} :
          ${translate('owner.search')}`;
    case 'accountsLimit-Currency':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.collectionDetails.column-currency')}`;
    case 'accountsLimit-DatePost':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.account.column-date')}`;
    case 'accountsLimit-Limit':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsLimit.column-limit')}`;
    case 'accountsLimit-onRemoveLimit':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-button')} :
          ${translate('grids.accountsLimit.btn-deleteLimit')}`;
    case 'accountsLimit-OwnerName':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsLimit.column-owner')}`;
    case 'accountsLimit-Percent':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsLimit.column-percent')}`;
    case 'accountsLimit-UserNamePost':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsLimit.column-performer')}`;
    case 'accountsLimit-WorkDate':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-accountsLimit')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsLimit.column-data')}`;
    case 'accountTurn-Account':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.account.column-account')}`;
    case 'accountTurn-AccountName':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsTurn.column-name')}`;
    case 'accountTurn-btn-submit':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-button')} :
          ${translate('owner.search')}`;
    case 'accountTurn-CrTurn':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsTurn.column-costs')}`;
    case 'accountTurn-Currency':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.collectionDetails.column-currency')}`;
    case 'accountTurn-DbTurn':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsTurn.column-profit')}`;
    case 'accountTurn-Saldo':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsTurn.column-remainder')}`;
    case 'accountTurn-WorkDate':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-column')} :
          ${translate('grids.accountsTurn.column-date')}`;
    case 'accountTurn-workDateOnclick':
      return `${translate(`page.adminPanel.type-tab`)}:
          ${translate('page.account.tab-activity')}, ${translate('page.adminPanel.type-navLink')} :
          ${translate('page.account.tab-details')}`;
    default:
      return value;
  }
};

export default accountManagement;
