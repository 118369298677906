import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { SUPPLIER_CONFIG } from './constants';

import '../SupplierProps/SupplierProps.styled.scss';
import './SuppliersUsers.styled.scss';

const api = new ApiService();

class AddSupplierModal extends PureComponent {
  state = {
    selectedSuppliers: [],
    allSuppliers: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchSuppliers();
  }

  fetchSuppliers = async () => {
    try {
      await this.setState({ isLoading: true });
      const suppliers = await api.getSuppliers();
      this.setState({
        allSuppliers:
          suppliers && suppliers.length ? suppliers.map(({ Name, SupplierId }) => ({ Name, Id: SupplierId })) : [],
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  addSupplier = async () => {
    const { selectedAccount, translate, closeModal, onRefresh } = this.props;
    const { selectedSuppliers } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.providerPortalAccountRefAdd({
        accountId: selectedAccount,
        supplierId: selectedSuppliers[0],
      });
      this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(translate('page.serviceSupplier.relatedSupplierAddSuccessful'), 2);
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    const { selectedSuppliers, isLoading, allSuppliers } = this.state;

    return (
      <Modal
        className="AddSupplierModal"
        visible={visible}
        onCancel={closeModal}
        title={translate(`page.serviceSupplier.addRelatedSupplier`)}
        footer={[
          <Button key="back" type="primary" onClick={this.addSupplier} disabled={!selectedSuppliers.length}>
            {translate(`core.buttonTitles.add`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="SupplierProps-inputRow">
          <div className="SupplierProps-label">{translate('page.serviceSupplier.supplier')}</div>
          <div className="SupplierProps-input">
            <GridDropdown
              data={allSuppliers}
              colConfig={SUPPLIER_CONFIG}
              selectItems={selectedSuppliers}
              onSave={(selectedSuppliers) => {
                this.setState({ selectedSuppliers });
              }}
              defaultTitle={translate('page.serviceSupplier.addRelatedSupplier')}
            />
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddSupplierModal);
