import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslate } from '../../../../contexts/localContext';
import { Modal, Button, List } from 'antd';
import './modalCreatedTerminals.scss';

class ModalCreatedTerminals extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    togglePopup: PropTypes.func.isRequired,
    data: PropTypes.array,
  };

  render = () => {
    const { visible, translate, closePopup, data, togglePopup } = this.props;
    return (
      <div className="modal--add-limit">
        {data && data.length > 0 && (
          <Button type="primary" onClick={togglePopup}>
            {translate('page.createNewTerminal.btn-show-created-terminals')}
          </Button>
        )}
        <Modal
          title={translate('page.createNewTerminal.modal-created-terminals-title')}
          visible={visible}
          centered
          onOk={closePopup}
          onCancel={closePopup}
          footer={[
            <Button key="back" className="modal--add-limit-cancel" onClick={closePopup}>
              {translate('grids.accountsLimit.button-cancel')}
            </Button>,
            // <Button key="submit" className="modal--add-limit-set" type="primary" onClick={closePopup}>
            //   {translate('grids.accountsLimit.button-set')}
            // </Button>
          ]}
        >
          {/* <List dataSource={data} /> */}
          <div className="demo-infinite-container">
            <List dataSource={data} renderItem={(item) => <List.Item key={item}>{item}</List.Item>} />
          </div>
        </Modal>
      </div>
    );
  };
}

export default withTranslate(ModalCreatedTerminals);
