import { ApiService } from '../../services';

class DownloadReceiptPDFService {
  api = new ApiService();

  downloadReceipt = async (setLoading, showError, transactionId) => {
    try {
      await setLoading(true);
      const base64Receipt = await this.api.getReceiptsToPdf({
        transactionId,
        receiptType: 'Simple',
      });

      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${base64Receipt}`;
      downloadLink.download = `receipt_${transactionId}.pdf`;
      downloadLink.click();
      await setLoading(false);
    } catch (err) {
      showError(err);
      await setLoading(false);
    }
  };
}

export default new DownloadReceiptPDFService();
