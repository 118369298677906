import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdown from './GridDropdown';
import { StorageService } from '../../services';

class GridDropdownWithDeletedOwners extends PureComponent {
  constructor(props) {
    super(props);

    const pathname = props.history.location.pathname;
    const userId = (StorageService.getItem('userInfo') && StorageService.getItem('userInfo').UserId) || 'Incognito';
    this.currentPathname = `${
      pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname
    }--${userId}--isShowDeletedOwners`;

    this.state = {
      isShowDeletedOwnersOnDropdown:
        props.isGetDefaultSelectItemsFromStorage && StorageService.getItem(this.currentPathname) === 'true',
    };
  }

  render() {
    const { data, selectItems, onSave, isSingle, defaultTitle, isGetDefaultSelectItemsFromStorage, colConfig } =
      this.props;
    const { isShowDeletedOwnersOnDropdown } = this.state;

    return (
      <GridDropdown
        data={isShowDeletedOwnersOnDropdown ? data : data.filter((owner) => owner.OwnerStatusId !== 2)}
        colConfig={colConfig || OWNERS_COLUMN_CONFIG}
        selectItems={selectItems}
        onSave={onSave}
        labelForAdditonalFilterProps="owner.show-delete"
        onCheckboxFilterChange={() => {
          this.setState(
            ({ isShowDeletedOwnersOnDropdown }) => ({
              isShowDeletedOwnersOnDropdown: !isShowDeletedOwnersOnDropdown,
            }),
            () => {
              if (isGetDefaultSelectItemsFromStorage) {
                StorageService.setItem(this.currentPathname, `${this.state.isShowDeletedOwnersOnDropdown}`);
              }
            },
          );
        }}
        isFilterByCheckboxValue={isShowDeletedOwnersOnDropdown}
        isSingle={isSingle}
        defaultTitle={defaultTitle}
        isGetDefaultSelectItemsFromStorage={isGetDefaultSelectItemsFromStorage}
      />
    );
  }
}

export default withRouter(GridDropdownWithDeletedOwners);
