import React, { PureComponent } from 'react';
import { Input as CoreInput, Tooltip } from 'antd';

import './Input.scss';
import { COMPONENT_TYPE_MAP } from './constants';

class TooltipWrapper extends PureComponent {
  render() {
    const { tooltipTitle, children } = this.props;
    if (tooltipTitle) {
      return (
        <Tooltip trigger="focus" title={tooltipTitle} placement="bottom">
          {children}
        </Tooltip>
      );
    }

    return <>{children}</>;
  }
}

class Input extends PureComponent {
  onHandleChange = (e) => {
    const { type, onChangeFormState, validators, setError, name, formState, disableLineBreaks } = this.props;
    if (type === 'checkbox') {
      onChangeFormState(name, !formState[name]);
      return;
    }
    const value = type === 'number' ? e : e.target.value;

    console.log('value103', value);

    if (validators && validators[name]) {
      const { [name]: validateCallback } = validators;
      setError(name, validateCallback(value));
    }

    if (disableLineBreaks && type === 'textArea') {
      if (value.includes('\n')) return;
    }
    onChangeFormState(name, value);
  };

  handleKeyPress = (event) => {
    const { type, disableLineBreaks } = this.props;

    if (disableLineBreaks && type === 'textarea' && event.key === 'Enter') {
      event.preventDefault(); // Предотвращаем добавление новой строки
    }
  };

  render() {
    const {
      formState,
      formErrors,
      name,
      type,
      tooltipTitle,
      isErrorMessageAbsolutePositioned = true,
      ...restInputProps
    } = this.props;
    const { [name]: value } = formState;
    const { [name]: errorMessage } = formErrors;

    const InputComponent = COMPONENT_TYPE_MAP.get(type) || CoreInput;

    return (
      <div className={`CustomInput ${errorMessage ? 'CustomInput--error' : ''}`}>
        <TooltipWrapper tooltipTitle={tooltipTitle}>
          <InputComponent
            value={value}
            checked={value}
            onKeyPress={this.handleKeyPress}
            className="CustomInput-field"
            onChange={this.onHandleChange}
            {...(type === 'hiddenPassword' ? { type: 'password' } : {})}
            {...restInputProps}
          />
        </TooltipWrapper>
        {errorMessage && (
          <div
            className={`CustomInput-alarmMessage ${
              isErrorMessageAbsolutePositioned ? 'CustomInput-alarmMessage--absolutePositioned' : ''
            }`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default Input;
