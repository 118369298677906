import React, { PureComponent } from 'react';
import { Col, Row, Button, Input, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import GridAccessTerminalsNormalizersService from './dropdownNormalizers.service';
import Grid from '../../../components/grids/baseGrid';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../services';

import './AccessTermminals.scss';
import { GET_ACCESS_TYPE_BY_ID, OWNERS_BY_USER_DROPDOWN_CONFIG, TYPE_ACCESS } from './constants';
import CellBoolean from '../../account/cellBoolean';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

class GridAccessTerminals extends PureComponent {
  currentArray = findArray('users');

  state = {
    owners: [],
    selectOwners: [],
    selectTypeAccess: [0],
    isLoading: false,
    terminalList: [],
    selectedItems: [],
  };

  fetchOwners = async () => {
    const { userId } = this.props;
    try {
      await this.setState({ isLoading: true });
      const owners = await api.getOwnersAcList(userId);
      await this.setState(
        {
          owners: owners && owners.length ? owners : [],
          selectOwners: owners && owners.length ? [owners[0].OwnerId] : [],
        },
        () => {
          this.getTerminals();
        },
      );
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  getTerminals = async () => {
    const { selectOwners } = this.state;
    const { userId } = this.props;

    if (!selectOwners || !selectOwners.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const terminalList = await api.terminalsAccessListGet({ ownerId: selectOwners, showDeleted: false, userId });
      await this.setState({ terminalList: GridAccessTerminalsNormalizersService.normalizeTerminals(terminalList) });
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  settingUserAccess = async (isAdd) => {
    const { selectedItems, selectTypeAccess } = this.state;
    const { userId, translate } = this.props;

    await this.setState({ isLoading: true });

    const fieldName = GET_ACCESS_TYPE_BY_ID.get(selectTypeAccess[0]);

    try {
      const model = selectedItems.map(({ TerminalId, OwnerId, IsViewer, IsCollector, IsTechnic, IsPayeer }) => ({
        TerminalId,
        OwnerId,
        UserId: userId,
        IsViewer,
        IsCollector,
        IsTechnic,
        IsPayeer,
        [fieldName]: isAdd,
      }));

      await api.terminalsAccessMerge(model);
      await message.success(
        translate(`page.typeAccessTerminals.${isAdd ? 'accessSuccessfullyEstablished' : 'accessSuccessfullyRevoked'}`),
        2,
      );
      await this.getTerminals();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  dropdownChangeCallback = (name) => (selectedItems) => {
    this.setState({ [name]: selectedItems }, () => {
      if (name === 'selectOwners' && !!this.state.selectOwners.length) {
        this.getTerminals();
      }
    });
  };

  handleSelectedItemsChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate, UserName, LastName } = this.props;
    const { owners, selectOwners, selectTypeAccess, selectedItems } = this.state;

    return (
      <Row className="UsersAccessTerminals-row">
        <Row type="flex" align="middle" className="UsersAccessTerminals-userInfoRow">
          <Col span={24} sm={6} md={3}>
            {translate('page.adminPanel.label-user')}
          </Col>
          <Col span={23} sm={14} className="UsersAccessTerminals-ownerDropdown">
            <Input value={`${UserName} ${LastName}`} disabled />
          </Col>
        </Row>
        <Row type="flex" align="middle" className="UsersAccessTerminals-row">
          <Col span={6} md={3}>
            {translate('page.commissionsServices.dealer')}
          </Col>
          <Col span={23} md={14} className="UsersAccessTerminals-ownerDropdown">
            <GridDropdown
              data={DropdownNormalizersService.normalizeOwners(owners)}
              colConfig={OWNERS_BY_USER_DROPDOWN_CONFIG}
              selectItems={selectOwners}
              onSave={this.dropdownChangeCallback('selectOwners')}
            />
          </Col>
        </Row>
        <Row type="flex" align="middle" className="UsersAccessTerminals-row">
          <Col span={6} md={3}>
            {translate('page.typeAccessTerminals.typeAccess')}
          </Col>
          <Col span={23} md={14} className="Users-ownerDropdown">
            <GridDropdown
              data={GridAccessTerminalsNormalizersService.normalizeTypeAccess(TYPE_ACCESS, translate)}
              selectItems={selectTypeAccess}
              colConfig={GridAccessTerminalsNormalizersService.getTypeAccessConfig(translate)}
              onSave={this.dropdownChangeCallback('selectTypeAccess')}
              isSingle
            />
          </Col>
          {checkElement('users-tabTerminalAcessBtnGive', this.currentArray) && (
            <Button
              disabled={!selectedItems.length}
              type="primary"
              onClick={() => {
                this.settingUserAccess(true);
              }}
            >
              {translate('core.buttonTitles.issue')}
            </Button>
          )}

          {checkElement('users-tabTerminalAcessBtnWithdraw', this.currentArray) && (
            <Button
              disabled={!selectedItems.length}
              type="primary"
              onClick={() => {
                this.settingUserAccess(false);
              }}
            >
              {translate('core.buttonTitles.revoke')}
            </Button>
          )}
        </Row>
      </Row>
    );
  };

  render = () => {
    const { terminalList, isLoading } = this.state;

    return (
      <>
        <Grid
          data={terminalList}
          toolbar={this.buildToolbar()}
          onRefresh={this.getTerminals}
          name="terminalsAccess-grid"
          isLoading={isLoading}
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectedItemsChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="TerminalId" title="grids.terminalsAccess.column-terminalId" width="150" />
          <GridColumn field="Owner" title="grids.terminalsAccess.column-ownerName" width="150" />
          <GridColumn
            field="IsViewer"
            title="grids.terminalsAccess.column-isViewer"
            width="150"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="IsCollector"
            title="grids.terminalsAccess.column-isCollector"
            width="150"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="IsTechnic"
            title="grids.terminalsAccess.column-isTechnic"
            width="150"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="IsPayeer"
            title="grids.terminalsAccess.column-isPayeer"
            width="150"
            cell={WrappedCellBoolean}
          />
          <GridColumn field="TerminalType" title="grids.terminalsAccess.column-terminalType" width="150" />
          <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />
          <GridColumn field="CityName" title="grids.terminalsMainProfileList.column-cityName" width="150" />
          <GridColumn field="TerminalAddress" title="grids.terminalsAccess.column-terminalAddress" width="150" />
          <GridColumn field="TerminalSign" title="grids.terminalsAccess.column-terminalSign" width="150" />
          <GridColumn field="TerminalName" title="grids.terminalsAccess.column-name" width="200" />
          <GridColumn field="Description" title="grids.terminalsAccess.column-description" width="800" />
          <GridColumn field="Branch" title="grids.terminals.column-branch" width="150" />
          <GridColumn field="StatusName" title="grids.terminals.column-status" width="150" />
        </Grid>
      </>
    );
  };
}
export default withTranslate(GridAccessTerminals);
