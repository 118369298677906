import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { formatDate } from '@telerik/kendo-intl';
import { withTranslate } from '../../contexts/localContext';

import Grid from '../../components/grids/baseGrid';

import { ApiService, tabService } from '../../services';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DealerCommissionsService from '../DealerCommissions/dealerCommissions.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
class TerminalsByServices extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('terminalsByServices');
  }
  state = {
    allOwners: [],
    isLoading: false,
    selectOwners: [],
    terminalsList: [],
    selectedItems: [],
    start: new Date(),
    end: new Date(),
    isButtonDisabled: true,
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const allOwners = await api.owners();
      if (allOwners && allOwners.length) {
        await this.setState({ isLoading: false, allOwners });
      }
    } catch (error) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      await showError(error);
    }
  };

  getTerminalsByService = async () => {
    const { selectOwners } = this.state;
    try {
      const model = {
        owners: selectOwners,
      };
      this.toggleLoader(true);
      const terminalsList = await api.terminalList(model);
      this.setState({ terminalsList: terminalsList || [] });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };
  async componentDidMount() {
    await this.fetchOwners();
  }

  dropDownChangeCallback = (name) => (selectItems) => {
    this.setState({ [name]: selectItems }, () => {
      if (name === 'selectOwners') {
        this.getTerminalsByService();
      }
    });
  };
  handlSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };
  addTerminalsByServicesTab = () => {
    const { selectOwners, start, end, selectedItems } = this.state;
    tabService.addTab({
      type: 'terminals',
      model: {
        ownerId: selectOwners,
        dateStart: convertToISOFormat(start),
        dateEnd: convertToISOFormat(end),
        terminalId: selectedItems.map(({ TerminalId }) => TerminalId),
      },
    });
  };

  buildToolbar = () => {
    const { allOwners, selectOwners, start, end, selectedItems } = this.state;
    const { translate } = this.props;

    if (!allOwners || !allOwners.length) {
      return <></>;
    }

    return (
      <Col span={24} xl={17} xxl={15}>
        <Row type="flex" align="middle" style={{ marginBottom: '20px' }}>
          <Col span={3}>{translate('page.commissionsServices.dealer')}</Col>
          <Col span={18}>
            <GridDropdown
              data={DealerCommissionsService.normalizeDealerCommissions(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              onSave={this.dropDownChangeCallback('selectOwners')}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24} md={10}>
            <Col span={4} md={8}>
              <p className="">{translate('grids.comission-sub.validity-period-date-start')}</p>
            </Col>
            <Col span={20} md={16}>
              <DatePicker
                format="yyyy.MM.dd"
                value={start}
                onChange={(e) => {
                  this.dropDownChangeCallback('start')(e.target.value);
                }}
              />
            </Col>
          </Col>
          <Col span={24} md={10}>
            <Col span={4} md={8}>
              <p className="">{translate('grids.comission-sub.validity-period-date-end')}</p>
            </Col>
            <Col span={20} md={16}>
              <DatePicker
                format="yyyy.MM.dd"
                value={end}
                onChange={(e) => {
                  this.dropDownChangeCallback('end')(e.target.value);
                }}
              />
            </Col>
          </Col>
          <Col span={4}>
            {checkElement('terminalsByService-search', this.currentArray) && (
              <Button icon="search" primary disabled={!selectedItems.length} onClick={this.addTerminalsByServicesTab}>
                {translate('core.buttonTitles.createReport')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  render = () => {
    const { terminalsList, isLoading } = this.state;

    if (!checkElement('terminalsByService-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={terminalsList}
          name="terminalsByService-grid"
          toolbar={this.buildToolbar()}
          onRefresh={this.getTerminalsByService}
          isLoading={isLoading}
          multiSelected
          fieldForSelection="TerminalId"
          onSelect={this.handlSelectionChange}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="OwnerId" title="grids.terminalsByServices.column-dealer" width="80px" />
          <GridColumn field="TerminalId" title="grids.terminalsByServices.column-terminalId" width="120px" />
          <GridColumn field="OwnerName" title="grids.terminalsByServices.column-name" width="120px" />
          <GridColumn
            field="NetworkContractName"
            title="grids.terminalsByServices.column-networkContract"
            width="180px"
          />
          <GridColumn field="RegionName" title="grids.terminalsByServices.column-region" width="180px" />
          <GridColumn field="CityName" title="grids.terminalsByServices.column-city" width="180px" />
          <GridColumn field="TerminalAddress" title="grids.terminalsByServices.column-address" width="180px" />
          <GridColumn field="StatusName" title="grids.terminalsByServices.column-status" width="120px" />
        </Grid>
        ;
      </>
    );
  };
}
export default withTranslate(TerminalsByServices);
