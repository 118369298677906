import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Input, InputNumber, Select, Checkbox } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import '../../profiles/modalProfile.scss';
import '../remoteSettings/cellValue/modalCellValue.scss';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

const { TextArea } = Input;

const { Option } = Select;

const stringToBoolean = (string) => {
  if (typeof string === 'boolean') return string;
  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

class ModalChooseSettings extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    noSelected: false,
    // isSkinSelect: Property && Property === 'skin',
    selectData: [],
    selectedProp: {
      Key: null,
      Type: null,
    },
    propValue: null,
    skinsData: [],
  };

  componentDidMount() {
    this.initRequest();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  initRequest = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const selectData = await api.terminalSettingsExtendedKeysList();
      const skinsData = await api.terminalSkins();
      const selectedProp = selectData[0]
        ? { Key: selectData[0].Key, Type: selectData[0].Type }
        : { Key: null, Type: null };
      const propValue = selectData[0] ? selectData[0].DefaultValue : null;
      this.setState({ selectData, selectedProp, propValue, skinsData });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  onChangeCheckBox = (e) => {
    this.setState({ propValue: e.target.checked });
  };

  onChangeInput = (e) => {
    this.setState({ propValue: e.target.value });
  };

  onChangeInputNumberSelect = (propValue) => {
    this.setState({ propValue });
  };

  onChangeSelectPropName = (Key) => {
    const elem = this.state.selectData.find((el) => el.Key === Key);
    this.setState({
      selectedProp: {
        Key,
        Type: elem.Type,
      },
      propValue: elem.DefaultValue,
    });
  };

  renderSelectPropName = () => {
    const {
      selectedProp: { Key },
    } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={Key}
        onChange={this.onChangeSelectPropName}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {this.state.selectData.map(({ Key }) => (
          <Option value={Key} key={`modalchooseSets-number-${Key}`}>
            {Key}
          </Option>
        ))}
      </Select>
    );
  };

  renderSelect = () => {
    const { propValue, skinsData } = this.state;
    return (
      <Select
        className="modal-system-encashment--select"
        dropdownStyle={{ zIndex: '1054', width: '100%' }}
        value={propValue}
        onChange={this.onChangeInputNumberSelect}
        filterOption={(input, option) =>
          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        showSearch
      >
        {skinsData.map(({ Key, Value }) => (
          <Option value={Key} key={`cellValue-number-${Key}-${Value}`}>
            {Value}
          </Option>
        ))}
      </Select>
    );
  };

  renderBlock = () => {
    const {
      selectedProp: { Key, Type },
      propValue,
    } = this.state;
    if (Key === 'skin' || Key === 'skin1') {
      // return <DropdownSkin openModal={openModal} />;
      return this.renderSelect();
    }
    switch (Type) {
      case 'bool':
        return <Checkbox checked={stringToBoolean(propValue)} onChange={this.onChangeCheckBox} />;
      case 'string':
        return <Input value={propValue} onChange={this.onChangeInput} />;
      case 'memo':
        return <TextArea rows={4} value={propValue} onChange={this.onChangeInput} />;
      case 'int':
        return (
          <InputNumber style={{ width: '100%' }} min={0} value={propValue} onChange={this.onChangeInputNumberSelect} />
        );
      default:
        return <p />;
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onSaveProp = async () => {
    const { showError, terminals, closeModal } = this.props;
    const {
      selectedProp: { Key },
      propValue,
    } = this.state;
    try {
      this.toggleLoader();
      const model = {
        TerminalList: terminals,
        SettingsExtended: {
          TerminalId: null,
          Key: Key,
          Value: propValue,
        },
      };

      const response = await api.terminalListSettingsExtendedSet(model);
      closeModal();
      return response;
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    const { loading, isLoading } = this.state;
    return (
      <>
        <Modal
          title={translate('page.addRemoteSettings.choose-setting')}
          visible={visible}
          centered
          // onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              // disabled={!item}
              loading={loading}
              onClick={this.onSaveProp}
            >
              {translate('page.screenControlAddSets.btn-choose')}
            </Button>,
          ]}
        >
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-name')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderSelectPropName()}
            </Col>
          </Row>
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: '15px' }}
            align="middle"
            gutter={24}
          >
            <Col xs={24} sm={24} md={6}>
              <p style={{ marginBottom: '0' }}>{translate('grids.transactionDeatails.column-value')}:</p>
            </Col>
            <Col xs={24} sm={24} md={18}>
              {this.renderBlock()}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalChooseSettings);
