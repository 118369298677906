import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import LoadingPanel from '../loader';

const api = new ApiService();

class LinkElementTargetInfoModal extends PureComponent {
  state = {
    isLoading: false,
    route: '',
  };

  componentDidMount() {
    this.fetchRoute();
  }

  fetchRoute = async () => {
    const {
      profileStaffForUpdating: { refId, profileId },
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const route = await api.getMenuTree(profileId, refId);
      this.setState({
        route:
          route && route.length
            ? route.map(({ MenuId, Name }) => `"${Name}"(${MenuId})`).join(' -> ')
            : translate('page.profilesMenu.pathNotFound'),
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, visible, closeDialog } = this.props;
    const { isLoading, route } = this.state;

    return (
      <Modal
        title={translate('page.profilesMenu.elementPath')}
        visible={visible}
        onCancel={closeDialog}
        footer={[
          <Button onClick={closeDialog} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        {route}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(LinkElementTargetInfoModal);
