import React, { PureComponent } from 'react';
import { Button, Input, Modal, Select, message } from 'antd';

import { ApiService } from '../../../services';
import { menuTemplateLists } from './constants';
import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../loader';

import './CreateMenuElementModal.styled.scss';

const api = new ApiService();

class CreateMenuElementModal extends PureComponent {
  state = {
    selectType: 1,
    inputValue: '',
  };

  onAddNewElement = async () => {
    const { selectType, inputValue } = this.state;
    const {
      profileStaffForUpdating: { profileId, menuId, parentId, onSuccessfulUpdateCallback },
      closeDialog,
      translate,
      handleAddCategory,
    } = this.props;

    try {
      const model = {
        profileId,
        parentId: selectType === 1 ? 0 : selectType === 4 ? parentId : menuId,
        refId: selectType === 4 ? menuId : null,
        name: inputValue,
        type: selectType,
      };
      await this.setState({ isLoading: true });
      const newElement = await api.menuAddElement(model);
      if (selectType === 1) {
        handleAddCategory({ ...newElement, profileId: 1 });
      } else {
        onSuccessfulUpdateCallback({
          ...newElement,
          parentId: selectType === 1 ? 0 : selectType === 4 ? parentId : menuId,
          refId: selectType === 4 ? menuId : null,
        });
      }

      this.setState({ isLoading: false });
      message.success(translate('page.profilesMenu.success-elementAdd'), 2.5);
      closeDialog();
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { inputValue, isLoading, selectType } = this.state;
    const {
      translate,
      visible,
      closeDialog,
      profileStaffForUpdating: { currentMenuName },
    } = this.props;

    return (
      <Modal
        title={`${translate('page.profilesMenu.create-element')} ${currentMenuName}`}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" onClick={this.onAddNewElement} disabled={!inputValue}>
            {translate('core.buttonTitles.create')}
          </Button>,
          <Button onClick={isLoading ? () => {} : closeDialog}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div className="ModalAddNewElement-content">
          <div className="ModalAddNewElement-row">
            <label className="ModalAddNewElement-label">{translate('page.profilesMenu.label-type')}</label>
            <Select
              value={selectType}
              onSelect={(e) => {
                this.setState(({ inputValue }) => ({
                  selectType: e,
                  inputValue: e === 4 ? currentMenuName : inputValue,
                }));
              }}
              className="ModalAddNewElement-select"
            >
              {menuTemplateLists.map(({ label, value }) => (
                <Select.Option value={value} key={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="ModalAddNewElement-row">
            <label className="ModalAddNewElement-label">{translate('page.profilesMenu.label-name')}</label>
            <Input
              type="text"
              value={inputValue}
              onChange={({ target: { value: inputValue } }) => {
                this.setState({ inputValue });
              }}
              disabled={selectType === 4}
            />
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(CreateMenuElementModal);
