import React, { PureComponent } from 'react';

import CheckTemplateAction from './CheckTemplateAction';
import PageTabs from '../../components/tabber/tabber';
import AddingCheckTemplate from './AddingCheckTemplate';
import EditingCheckTemplate from './EditingCheckTemplate';
import PreviewTemplateReceipt from './CheckTemplateAction/PreviewTemplateReceipt';

class CheckTemplate extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-checkTemplate',
        content: <CheckTemplateAction />,
        closable: false,
        key: '0',
      },
    ],
  };

  onAddTab = ({ type, tabData }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'addingTemplate':
        tabs.push({
          title: 'page.templates.addingTemplate',
          content: <AddingCheckTemplate tabData={tabData} />,
          closable: true,
          key: key,
        });
        break;

      case 'editingTemplate':
        tabs.push({
          title: 'page.templates.editingTemplate',
          content: <EditingCheckTemplate tabData={tabData} />,
          closable: true,
          key: key,
        });
        break;
      case 'previewTemplateReceipt':
        tabs.push({
          title: 'page.templates.tab-receipt',
          content: <PreviewTemplateReceipt tabData={tabData} />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default CheckTemplate;
