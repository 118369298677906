import React, { PureComponent } from 'react';
import { Button, Input, message, Modal } from 'antd';
import DeleteModal from '../../../components/DeleteModal';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService, tabService } from '../../../services';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import CheckTemplateForm from '../CheckTemplateForm';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { TEMPLATES_DROPDOWN_CONFIG } from '../../../core/constants/configs.const';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

import './CheckTemplateAction.scss';
import '../CheckTemplate.scss';

const api = new ApiService();

class CheckTemplateAction extends PureComponent {
  currentArray = findArray('manageTemplate');

  state = {
    isLoading: false,
    templateList: [],
    selectedTemplateIds: [],
    isDeleteTemplateDialogOpen: false,
    previewReceiptModal: false,
    receiptTemplate: '',
    updateVersionTemplate: false,
    inputValue: '',
    err: '',
  };

  componentDidMount() {
    this.fetchTemplateList();
  }
  componentDidUpdate(prevProps, prevState) {
    const { inputValue } = this.state;
    const { inputValue: prevInputValue } = prevState;
    if (inputValue !== prevInputValue) {
      this.setState({ err: '' });
    }
  }

  fetchTemplateList = async () => {
    try {
      this.setState({ isLoading: true });

      const templateList = await api.getTemplateList();

      const normalizeTemplateList = DropdownNormalizersService.normalizeTemplateList(templateList);

      this.setState({
        templateList: normalizeTemplateList,
        selectedTemplateIds: normalizeTemplateList.length ? [normalizeTemplateList[0].Id] : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  updateTemplateVersion = async () => {
    const { translate } = this.props;

    try {
      this.setState({ isLoading: true });
      await api.updateReceiptVersion();
      await message.success(translate('page.templates.updatedSuccessfully'), 2.5);
      this.setState({
        isLoading: false,
        updateVersionTemplate: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  deleteTemplate = async () => {
    const { translate } = this.props;
    const { selectedTemplateIds } = this.state;

    try {
      this.setState({ isLoading: true });

      await api.deleteReceiptsTemplate(selectedTemplateIds[0]);
      await message.success(translate('page.templates.deleteSuccessfully'), 2.5);
      this.setState(
        {
          isLoading: false,
          isDeleteTemplateDialogOpen: false,
        },
        () => {
          this.fetchTemplateList();
        },
      );
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchTemplateReceipt = async () => {
    const { selectedTemplateIds, inputValue } = this.state;
    try {
      const model = {
        transactionId: +inputValue,
        receiptTemplateId: selectedTemplateIds[0],
      };
      await this.setState({ isLoading: true });
      const receiptTemplate = await api.getReceiptTemplate(model);
      await this.setState({ receiptTemplate, isLoading: false });
      await this.onCloseModal();
      await tabService.addTab({
        type: 'previewTemplateReceipt',
        tabData: {
          receiptTemplate,
        },
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  openModal = () => {
    this.setState({ previewReceiptModal: true });
  };
  onCloseModal = () => {
    this.setState({ previewReceiptModal: false });
  };

  onHandleChangeValue = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value }, () => this.validIsNum(value));
  };
  validIsNum = (val) => {
    if (!Number.isInteger(+val)) {
      this.setState({ err: 'page.templates.err-msg' });
    }
  };

  render() {
    const { translate } = this.props;
    const {
      isLoading,
      templateList,
      selectedTemplateIds,
      isDeleteTemplateDialogOpen,
      previewReceiptModal,
      inputValue,
      err,
      updateVersionTemplate,
    } = this.state;

    const selectedTemplate = templateList.find(({ Id }) => `${selectedTemplateIds}` === `${Id}`);

    if (!checkElement('manageTemplate-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <div className="CheckTemplate">
          <div className="CheckTemplate-actions">
            {checkElement('manageTemplate-createItem', this.currentArray) && (
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({
                    type: 'addingTemplate',
                    tabData: {
                      templateList: templateList,
                    },
                  });
                }}
              >
                {translate('core.buttonTitles.create')}
              </Button>
            )}

            {checkElement('manageTemplate-editItem', this.currentArray) && (
              <Button
                type="primary"
                onClick={() => {
                  tabService.addTab({
                    type: 'editingTemplate',
                    tabData: {
                      templateList: templateList,
                      selectedTemplate: selectedTemplate,
                    },
                  });
                }}
              >
                {translate('core.buttonTitles.edit')}
              </Button>
            )}

            {checkElement('manageTemplate-deleteItem', this.currentArray) && (
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isDeleteTemplateDialogOpen: true });
                }}
              >
                {translate('core.buttonTitles.delete')}
              </Button>
            )}

            {checkElement('manageTemplate-updateItemVersion', this.currentArray) && (
              <Button type="primary" onClick={() => this.setState({ updateVersionTemplate: true })}>
                {translate('page.templates.updateTemplate')}
              </Button>
            )}

            {checkElement('manageTemplate-previewReceipt', this.currentArray) && (
              <Button type="primary" onClick={this.openModal}>
                {translate('page.templates.btn-receipt')}
              </Button>
            )}
          </div>

          <div className="CheckTemplate-content">
            <div className="CheckTemplateAction-select">
              <GridDropdown
                data={templateList}
                colConfig={TEMPLATES_DROPDOWN_CONFIG}
                selectItems={selectedTemplateIds}
                onSave={(selectedTemplateIds) => {
                  this.setState({ selectedTemplateIds });
                }}
                isSingle
              />
            </div>

            <div className="CheckTemplateAction-templateForm">
              <CheckTemplateForm selectedTemplate={selectedTemplate} isFormDisabled />
            </div>
          </div>
        </div>
        {isDeleteTemplateDialogOpen && (
          <DeleteModal
            visible={isDeleteTemplateDialogOpen}
            closeModal={() => {
              this.setState({ isDeleteTemplateDialogOpen: false });
            }}
            deleteContent={translate('page.templates.areYouSure')}
            submitCallback={this.deleteTemplate}
          />
        )}
        {updateVersionTemplate && (
          <Modal
            title={''}
            visible={updateVersionTemplate}
            onCancel={() => {
              this.setState({ updateVersionTemplate: false });
            }}
            footer={[
              <Button type="primary" onClick={this.updateTemplateVersion}>
                {translate('core.buttonTitles.yes')}
              </Button>,
              <Button
                type="danger"
                onClick={() => {
                  this.setState({ updateVersionTemplate: false });
                }}
              >
                {translate('core.buttonTitles.refuse')}
              </Button>,
            ]}
          >
            <div className="text-center pt-20">{translate('page.templates.update-template-vers')}</div>
          </Modal>
        )}

        {previewReceiptModal && (
          <Modal
            title={translate('page.templates.modal-title')}
            visible={previewReceiptModal}
            onCancel={this.onCloseModal}
            footer={[
              <Button type="primary" onClick={this.fetchTemplateReceipt}>
                {translate('page.templates.btn-receipt')}
              </Button>,
              <Button type="" onClick={this.onCloseModal}>
                {translate('core.buttonTitles.refuse')}
              </Button>,
            ]}
          >
            <div className="CheckTemplateForm-row align-center">
              <div className="Modal-label">{translate('page.templates.label-transaction')}</div>
              <div className="position-relative">
                <Input
                  type="text"
                  value={inputValue}
                  onChange={this.onHandleChangeValue}
                  className={`CheckTemplate-input ${err && err.length ? 'err' : ''}`}
                />
                {err && err.length && <div className="err-msg">{translate(err)}</div>}
              </div>
            </div>
          </Modal>
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(CheckTemplateAction);
