import React, { PureComponent } from 'react';

import FormStateContainer from '../../../../components/FormStateContainer/FormStateContainer';
import { INITIAL_VALUES } from './constants';
import AddService from './AddService';

class AddServiceContainer extends PureComponent {
  render() {
    return (
      <FormStateContainer initialValues={INITIAL_VALUES}>
        <AddService />
      </FormStateContainer>
    );
  }
}

export default AddServiceContainer;
