import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../Input';

import './gridTransactions.scss';
import LoadingPanel from '../../loader';
import { ApiService } from '../../../services';
import { SERVICE_CONFIG } from './constants';
import GridDropdown from '../../GridDropdown/GridDropdown';

const api = new ApiService();

class EditTransactionModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        serviceId: [props.dialogStuff.serviceId],
        serviceIdPrev: [props.dialogStuff.serviceId],
        comment: '',
        account: props.dialogStuff.account,
      },
      formErrors: { account: '' },
      serviceIdList: [],
      serviceIdPrevList: [],
      isLoading: false,
    };
  }

  normalizeSelectData = (data, fieldId, fieldName) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      Id: dataItem[fieldId],
      Name: dataItem[fieldName],
    }));
  };

  componentDidMount() {
    this.getServiceList();
  }

  getServiceList = async () => {
    try {
      await this.setState({ isLoading: true });
      const serviceData = await api.getServicesForTransactionUpdate();

      this.setState({
        serviceIdList: this.normalizeSelectData(serviceData, 'ServiceId', 'ServiceName'),
        serviceIdPrevList: this.normalizeSelectData(serviceData, 'ServiceId', 'ServiceName'),
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    this.setState(({ formState }) => ({ formState: { ...formState, [fieldName]: selectItems } }));
  };

  renderSelect = ({ name, isDisabled }) => {
    const { translate } = this.props;
    const { formState } = this.state;

    return (
      <div className="GridTransactionsSplittingTransactionModal-input">
        <GridDropdown
          data={this.state[`${name}List`]}
          colConfig={SERVICE_CONFIG}
          selectItems={formState[name]}
          onSave={this.getSelectChangeCallback(name)}
          defaultTitle={translate('grids.transactions.column-serviceId')}
          disabled={isDisabled}
          isSingle
        />
      </div>
    );
  };

  accountValidation = (value) => {
    const { translate } = this.props;

    if (!value) {
      return `${translate('page.commissionsServices.dropdownRequiredError')}`;
    }

    return '';
  };

  renderInput = (name, type) => {
    const { formState, formErrors } = this.state;

    return (
      <div className="GridTransactionsSplittingTransactionModal-input">
        <CustomInput
          type={type}
          formState={formState}
          name={name}
          onChangeFormState={(name, value) => {
            this.setState(({ formState }) => ({ formState: { ...formState, [name]: value } }));
          }}
          formErrors={formErrors}
          setError={(name, value) => {
            this.setState(({ formErrors }) => ({ formErrors: { ...formErrors, [name]: value } }));
          }}
          validators={name === 'account' ? { [name]: this.accountValidation } : {}}
        />
      </div>
    );
  };

  saveTransaction = async () => {
    const {
      dialogStuff: { transactionId },
      translate,
      closeModal,
      onRefresh,
    } = this.props;
    const {
      formState: { serviceId, comment, account },
    } = this.state;

    if (serviceId.length === 0) {
      return;
    }

    try {
      await this.setState({ isLoading: true });

      await api.transactionUpdate({
        transactionId: transactionId,
        account: account,
        serviceId: serviceId[0],
      });

      if (comment) {
        await api.transactionCommentAdd({
          transactionId: transactionId,
          comment: comment,
        });
      }

      await message.success(translate('page.transactions.transactionSuccessfullySave'), 2);
      this.setState({ isLoading: false });
      onRefresh();
      closeModal();
    } catch (error) {
      const { showError } = this.props;

      showError(error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { visible, translate, closeModal, dialogStuff } = this.props;
    const { isLoading, formState } = this.state;
    const { serviceId, account } = formState;

    const disableSave = serviceId.length === 0 || !account;

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={translate('page.transactions.editTransactionTitle')}
        onCancel={closeModal}
        footer={[
          <Button type="primary" key="add" onClick={this.saveTransaction} disabled={disableSave}>
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="GridTransactionsExpandSearchOptionModal-row GridTransactionsSplittingTransactionModal-transactionRow">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('grids.transactions.column-transactionId')}
          </div>
          <Input value={dialogStuff.transactionId} disabled />
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.currentClientId')}
          </div>

          {this.renderSelect({ name: 'serviceIdPrev', isDisabled: true })}
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('page.transactions.editClientId')}
          </div>

          {this.renderSelect({ name: 'serviceId' })}
        </div>

        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('grids.transactions.column-account')}
          </div>

          {this.renderInput('account')}
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsSplittingTransactionModal-label">
            {translate('grids.transactions.column-comment')}
          </div>

          {this.renderInput('comment', 'textArea')}
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(EditTransactionModal);
