const activityMonitor = (translate, value) => {
  switch (value) {
    case 'activitymonitor-newCertificate':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.certificates-issued.btn-new')}`;
    case 'monitorActivity-CashBoxUsed':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-сashBoxUsed')}`;
    case 'monitorActivity-MoneyTakerCanceled':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-moneyTakerCanceled')}`;
    case 'monitorActivity-CashCount':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-cashCount')}`;
    case 'monitorActivity-FiscalNumber':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-fiscalNumber')}`;
    case 'monitorActivity-CashSum':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-cashSum')}`;
    case 'monitorActivity-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
               ${translate('grids.terminals.column-city')}`;
    case 'monitorActivity-Note':
      return `${translate(`page.adminPanel.type-column`)}:
               ${translate('page.terminalSettings.field-notes')}`;
    case 'monitorActivity-CoinBoxUsed':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashment.column-coinBoxUsed')}`;
    case 'monitorActivity-CoinBoxQuantity':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashment.column-coinBoxQuantity')}`;
    case 'monitorActivity-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-lastconnect')}`;
    case 'monitorActivity-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-lasttransaction')}`;
    case 'monitorActivity-isShowDropdownTerminalId':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'monitorActivity-Name':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-name')}`;
    // case 'monitorActivity-OwnerId':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-name')}`
    case 'monitorActivity-program-reload':
      return `${translate('page.adminPanel.dropdown-button')}:
                        ${translate('page.dashboard.program-reload')}`;
    case 'monitorActivity-ReceiptsUsed':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-receipts')}`;
    case 'monitorActivity-DesignTypeName':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.createTerminal.column-designType')}`;
    case 'monitorActivity-remoteCommands':
      return `${translate('page.adminPanel.remoteCommands-show')}`;
    case 'monitorActivity-singleCollectionRecalculation':
      return `${translate('page.adminPanel.dropdown-button')}:
                ${translate('page.encashment.tab-singleEncashmentRecalc')}`;
    case 'monitorActivity-StatusDetails':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-details')}`;
    case 'monitorActivity-StatusState':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-state')}`;
    case 'monitorActivity-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-branch')}`;
    case 'monitorActivity-InternetTrafficMonth':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-internet-traffic-month')}`;
    case 'monitorActivity-InternetTrafficYesterday':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-internet-traffic-yesterday')}`;
    case 'monitorActivity-MobileOperator':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-mobile-operator')}`;
    // case 'monitorActivity-StatusState':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.terminals.column-state')}`
    case 'monitorActivity-tab-сertificates-issued':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.certificates.tab-сertificates-issued')}`;
    case 'monitorActivity-tab-type-events':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.dashboard.tab-type-events')}`;
    case 'monitorActivity-tab-type-transaction':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.dashboard.tab-type-transaction')}`;
    case 'monitorActivity-terminal-poweroff':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.dashboard.terminal-poweroff')}`;
    case 'monitorActivity-terminal-reboot':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.terminal-reboot')}`;
    case 'monitorActivity-terminal-shutdown':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.terminal-shutdown')}`;
    case 'monitorActivity-terminal-turnon':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.terminal-turnon')}`;
    case 'monitorActivity-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-street')}`;
    case 'monitorActivity-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminalsMainProfileList.column-terminalId')}`;
    case 'monitorActivity-terminalRecalculation':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.encashment.tab-encashmentRecalcTernminal')}`;
    case 'monitorActivity-terminals-cashrecalculate':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.terminals-cashrecalculate')}`;
    case 'monitorActivity-terminals-unlockBillAcceptor':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.dashboard.terminals-unlockBillAcceptor')}`;
    case 'monitorActivity-TransactionCount':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-paymentsToday')}`;
    case 'monitorActivity-PrevTransactionCount':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-paymentsYesterday')}`;
    case 'monitorActivity-TransactionAmountSum':
      return `${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-turnoversToday')}`;
    case 'monitorActivity-PrevTransactionAmountSum':
      return `${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-turnoversYesterday')}`;
    case 'monitorActivity-DateLastScreenTouch':
      return `${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-activity')}`;
    case 'monitorActivity-DateLastTerminalMaintenance':
      return `${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-lastMaintenance')}`;
    case 'monitorActivity-MobilePhone':
      return `${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-simCardNumber')}`;
    case 'monitorTerminalsCertificatesList-CertificateId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminals.column-turnoversYesterday')}`;
    case 'monitorTerminalsCertificatesList-Code':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-code')}`;
    case 'monitorTerminalsCertificatesList-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-date-post')}`;
    case 'monitorTerminalsCertificatesList-ExpiryDate':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-expiry-date')}`;
    case 'monitorTerminalsCertificatesList-RequestId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-requestId')}`;
    case 'monitorTerminalsCertificatesList-StartDate':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-date-start')}`;
    case 'monitorTerminalsCertificatesList-Status':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-status')}`;
    case 'monitorTerminalsCertificatesList-TerminalId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-terminalId')}`;
    case 'monitorTerminalsCertificatesList-UserNamePost':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.certificates.tab-сertificates-issued')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.certificates-issued.col-user-name-post')}`;

    case 'monitorTransactionDetails-Name':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-transaction-details')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.transactionDeatails.column-name')}`;
    case 'monitorTransactionDetails-Value':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-transaction-details')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.transactionDeatails.column-value')}`;
    case 'terminalEvents-Account':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-account')}`;
    case 'terminalEvents-btn-submit':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('owner.search')}`;
    case 'terminalEvents-DateInput':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-dateInput')}`;
    case 'terminalEvents-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-datePost')}`;
    case 'terminalEvents-ErrorCode':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-errorCode')}`;
    case 'terminalEvents-EventId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-eventId')}`;
    case 'terminalEvents-Message':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-message')}`;
    case 'terminalEvents-ObjectId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-objectId')}`;
    case 'terminalEvents-ObjectName':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-objectName')}`;
    case 'terminalEvents-Operation':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-operation')}`;
    case 'terminalEvents-ServiceId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-serviceId')}`;
    case 'terminalEvents-TransactionId':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-transactionId')}`;
    case 'terminalEvents-TypeName':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-events')},
                        ${translate(`page.adminPanel.type-column`)}:
                        ${translate('grids.terminal-events.column-typeName')}`;
    case 'monitorTerminalTransactions-btn-submit':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('owner.search')}`;
    case 'activitymonitor-transactionWithAdditionalProps':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.transactions.transactionWithAdditionalProps')}`;
    case 'monitorTerminalTransactions-transactionIdOnclick':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('grids.transactions.button-details')}`;
    case 'monitorTerminalTransactions-sendingEmailReceipt':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.transactions.sendingEmailReceipt')}`;
    case 'monitorTerminalTransactions-sendingViberReceipt':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.transactions.sendingViberReceiptCyrillic')}`;
    case 'monitorTerminalTransactions-downloadReceipt':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.dashboard.tab-terminal-transactions')},
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.transactions.downloadReceipt')}`;
    default:
      return value;
  }
};

export default activityMonitor;
