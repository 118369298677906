import React, { PureComponent } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from 'antd';

class PagePanel extends PureComponent {
  render() {
    const { hasPreviousPage, pageChange, pageValue, hasNextPage, isPlacedBottom } = this.props;

    return (
      <div className={`Toolbar-graphqlActionsItem ${isPlacedBottom ? 'Toolbar-bottomPagePanel' : ''}`}>
        <Button
          disabled={!hasPreviousPage}
          onClick={() => {
            if (isPlacedBottom) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            pageChange(pageValue - 1);
          }}
          icon="arrow-chevron-left"
        />
        <Input value={pageValue} className="Toolbar-input" disabled />
        <Button
          disabled={!hasNextPage}
          onClick={() => {
            if (isPlacedBottom) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            pageChange(pageValue + 1);
          }}
          icon="arrow-chevron-right"
        />
      </div>
    );
  }
}

export default PagePanel;
