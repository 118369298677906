import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

class DeleteProfilesModal extends PureComponent {
  render() {
    const { translate, closeModal, visible, commissionName, onCommissionDelete } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        zIndex="1053"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>,

          <Button key="submit" type="danger" ghost onClick={onCommissionDelete}>
            {translate('page.commission.btn-delete')}
          </Button>,
        ]}
      >
        <div style={{ padding: '40px 8px 0', textAlign: 'center' }}>{`${translate(
          'grids.comission-sub.removeCommissionQuestion',
        )} "${commissionName}"?`}</div>
      </Modal>
    );
  }
}

export default withTranslate(DeleteProfilesModal);
