import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import ServiceSuppliersGrid from './ServiceSuppliersGrid';
import SupplierProps from './SupplierProps';
import TreatiesGrid from './TreatiesGrid';
import SuppliersUsers from './SuppliersUsers';
import FormStateContainer from '../../components/FormStateContainer';
import { INITIAL_VALUES } from './SupplierProps/constants';

class ServiceSuppliers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-serviceSupplier',
          content: <ServiceSuppliersGrid />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { translate } = this.props;

    switch (type) {
      case 'supplierProps':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.serviceSupplier.supplierProps')} ${dataItem.name}`,
              content: (
                <FormStateContainer initialValues={INITIAL_VALUES}>
                  <SupplierProps dataItem={dataItem} />
                </FormStateContainer>
              ),
              closable: true,
              key: key,
            },
          ],
        }));
        break;
      case 'supplierTreaties':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.serviceSupplier.treaties')} ${dataItem.name}`,
              content: <TreatiesGrid dataItem={dataItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;
      case 'supplierUsers':
        this.setState(({ tabs }) => ({
          tabs: [
            ...tabs,
            {
              title: `${translate('page.serviceSupplier.supplierUsers')} ${dataItem.name}`,
              content: <SuppliersUsers dataItem={dataItem} />,
              closable: true,
              key: key,
            },
          ],
        }));
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
  };
}

export default withTranslate(ServiceSuppliers);
