import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import DealerCommissionsServices from './DealerCommissionsServices';
import { ApiService } from '../../services';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import LoadingPanel from '../../components/loader';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';

const api = new ApiService();

class DealerCommissionsServicesContainer extends PureComponent {
  state = {
    allOwners: [],
    currentOwners: [],
    paymentTypes: [],
    selectPaymentTypes: [1],
    servicesList: [],
    selectServicesList: [],
    dealerCommissionsList: [],
    headerSelected: [],
    isLoading: false,
  };

  normalizeData = (data) => {
    if (!data && !data.length) return [];

    return data
      .map((item) => ({ Id: `${item.OwnerId}-${item.ServiceId}-${item.PaymentTypeId}`, ...item }))
      .sort(({ ShortName: PrevOwnerName }, { ShortName: NextOwnerName }) =>
        alphabeticalSorting(PrevOwnerName, NextOwnerName),
      );
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const allOwners = await api.owners();
      await this.setState({ allOwners, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchPaymentType = async () => {
    try {
      this.setState({ isLoading: true });
      const paymentTypes = await api.getPaymentTypeList();
      await this.setState({ paymentTypes, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.setState({ isLoading: false });
    }
  };

  updateDataServices = (data) => {
    const Data = data.map(({ ServiceId, ...objProps }) => ({ Id: ServiceId, ServiceId, ...objProps }));

    return DropdownNormalizersService.normalizeDealerCommissionsServices([
      { Id: -1, ServiceId: '', ServiceName: 'Все сервисы' },
      ...Data,
    ]);
  };

  fetchServices = async () => {
    try {
      this.setState({ isLoading: true });
      const data = await api.getServiceDictionary({
        ownerId: [0],
      });
      await this.setState({
        servicesList: data && data.length ? this.updateDataServices(data) : [],
      });
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchServiceCommissionsList = async () => {
    const { currentOwners, selectPaymentTypes, selectServicesList } = this.state;
    try {
      this.setState({ isLoading: true });
      const model = {
        owners: currentOwners,
        paymentTypes: selectPaymentTypes,
        serviceId: selectServicesList && selectServicesList.length ? selectServicesList[0] : '',
        allServices: selectServicesList[0] === -1,
      };
      if (model.serviceId !== '') {
        const dealerCommissionsList = await api.serviceCommissionsList(model);
        this.setState({ dealerCommissionsList: this.normalizeData(dealerCommissionsList), isLoading: false });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  onPaymentTypesSave = (selectPaymentTypes) => {
    this.setState({ selectPaymentTypes });
  };

  onServiceSave = (selectServicesList) => {
    this.setState({ selectServicesList });
  };

  handleSelectOwners = (currentOwners) => {
    this.setState({ currentOwners });
  };

  fetchDataRequests = async () => {
    await this.fetchOwners();
    await this.fetchPaymentType();
    await this.fetchServices();
  };

  componentDidMount() {
    this.fetchDataRequests();
  }

  render = () => {
    const {
      allOwners,
      currentOwners,
      paymentTypes,
      selectPaymentTypes,
      dealerCommissionsList,
      servicesList,
      selectServicesList,
      headerSelected,
      isLoading,
    } = this.state;
    return (
      <>
        <DealerCommissionsServices
          allOwners={allOwners}
          currentOwners={currentOwners}
          paymentTypes={paymentTypes}
          selectPaymentTypes={selectPaymentTypes}
          servicesList={servicesList}
          selectServicesList={selectServicesList}
          onServiceSave={this.onServiceSave}
          dealerCommissionsList={dealerCommissionsList}
          onPaymentTypesSave={this.onPaymentTypesSave}
          fetchServiceCommissionsList={this.fetchServiceCommissionsList}
          handleSelectOwners={this.handleSelectOwners}
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}

export default withTranslate(DealerCommissionsServicesContainer);
