import React, { PureComponent } from 'react';
import { Input } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';

import './StepServiceCreation.styled.scss';

class ServiceIdField extends PureComponent {
  render() {
    const { translate, serviceId } = this.props;

    return (
      <div className="StepServiceCreation-serviceIdRow">
        <div className="StepServiceCreation-serviceIdLabel">{translate('page.serviceMainProps.serviceNumber')}</div>
        <div className="StepServiceCreation-serviceIdInput">
          <Input value={serviceId} disabled />
        </div>
      </div>
    );
  }
}

export default withTranslate(ServiceIdField);
