import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Input, message, Modal } from 'antd';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';

const api = new ApiService();
class ModalRegistration extends PureComponent {
  state = {
    isLoading: false,
    inputValue: '',
  };

  onHandleInputValue = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value });
  };

  onHandleRequest = async () => {
    const {
      modalInfo: { request },
      formState,
      nameModal,
      onClose,
      translate,
      fiscalNumber,
    } = this.props;
    const { inputValue } = this.state;

    try {
      const modelRegister = {
        typeCodeNew: 715,
        name: `ПТКС ${formState.terminalId}`,
        idNew: formState.businessUnit,
        katottg: inputValue,
        address1: formState.currentRegionId.CityName,
        address2: formState.addressValue,
        prroName: `ПТКС ${formState.terminalId}`,
        prroId: formState.terminalId,
        prroPaymentSystemName: 'ВПС"ФІНАНСОВИЙ СВІТ",ТОВ"УКРАЇНСЬКА ПЛАТІЖНА СИСТЕМА"',
      };
      const modelUnregister = {
        typeCodeOld: 715,
        name: `ПТКС ${formState.terminalId}`,
        idOld: formState.businessUnit,
        katottg: inputValue,
        address1: formState.currentRegionId.CityName,
        address2: formState.addressValue,
        prroName: `ПТКС ${formState.terminalId}`,
        prroNumber: formState.fiscalNumber ? formState.fiscalNumber : fiscalNumber,
        prroFiscalNumberOld: formState.fiscalNumber ? formState.fiscalNumber : fiscalNumber,
        prroId: formState.terminalId,
        prroPaymentSystemName: null,
      };

      await this.setState({ isLoading: true });
      await api[request](nameModal === 'registration' ? modelRegister : modelUnregister);
      await this.setState({ isLoading: false });
      await onClose();
      await message.success(`${translate('page.terminalSettings.mgs-success')}`, 2.5);
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const {
      visible,
      translate,
      onClose,
      modalInfo: { modalTitle, btnTitle },
      fiscalNumber,
    } = this.props;
    const { inputValue, isLoading } = this.state;
    return (
      <>
        <Modal
          title={translate(modalTitle)}
          visible={visible}
          onCancel={onClose}
          footer={[
            <Button type="primary" onClick={this.onHandleRequest}>
              {translate(btnTitle)}
            </Button>,
            <Button type="danger" onClick={onClose}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
          ]}
          key="ModalRegistration"
          name="ModalRegistration"
        >
          <div className="ModalRegistration-row">
            <label className="ModalRegistration-label">{translate('page.terminalSettings.modal-label')}</label>
            <Input
              type="text"
              value={inputValue}
              onChange={this.onHandleInputValue}
              className="ModalRegistration-input"
            />
          </div>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalRegistration);
