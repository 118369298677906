import React, { Component } from 'react';
import { Input, Row, Button, Card, Select, Col, message, Popconfirm, InputNumber } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, StorageService } from '../../../services';
import './newTerminal.scss';
import ModalGoogleMap from './modalGoogleMap';
import ModalCreatedTerminals from './modalCreatedTerminals';
import DropDownCities from '../dropdown';
// import DropDownProfiles from '../../screenControl/dropdown';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

const { Option } = Select;
const { TextArea } = Input;

function checkLatinSymbols(str) {
  return /[a-zA-Z]/.test(str);
}

const selectMonitorCount = [0, 1, 2];

class NewTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminalId: 0,
      isLoading: false,
      terminalsQuantity: 1,
      data: null,
      additionalCardVisibility: false,
      isShowMap: false,
      cities: [],
      comissionProfiles: [],
      mainProfilesList: [],
      placingInfo: [],
      cityId: null,
      currentRegionId: null,
      commisionProfileValue: null,
      mainProfilesListValue: null,
      terminalName: '',
      addressValue: '',
      geopointValue: '',
      capacityPrinterPaper: 1000,
      capacityCashBox: 1000,
      monitorCountValue: selectMonitorCount[0],
      placingInfoValue: null,
      terminalDescription: '',
      dateCreation: new Date(),
      dateCreationNoUTC: new Date(),
      noteValue: '',
      terminalBranch: '',
      typeId: null,
      currentUserOwnerId: null,
      showPopupConfirm: false,
      createdTerminals: [],
      isShowCreated: false,
      isAddressIncorrect: false,
      showApproveAddress: false,
      designTypeId: null,
      designTypes: [],
    };
    this.currentArray = findArray('createTerminal');
  }

  componentDidMount() {
    this.initRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { cityId } = this.state;
    if (prevState.cityId !== null && prevState.cityId !== cityId) {
      this.setState({ addressValue: '' });
    }
  }

  onChangeIncorrectAddress = (isAddressIncorrect) => {
    this.setState({ isAddressIncorrect });
  };

  callBackForOnConfirmApproveAddress = () => {
    this.onChangeIncorrectAddress(true);
    this.closeonShowApproveAddress();
  };

  onAddressChange = () => {
    this.setState(({ isShowMap }) => {
      return {
        isShowMap: !isShowMap,
      };
    });
  };

  universalErrorMessage = (field) => {
    const { translate } = this.props;
    return `${translate('page.terminalSettings.universalErrorMessage-field')}
    ${field} ${translate('page.terminalSettings.universalErrorMessage-first')}
    ${translate('page.terminalSettings.universalErrorMessage-second')}.`;
  };

  closeGoogleMap = () => {
    this.setState({ isShowMap: false });
  };

  onChangeMainProfilesListValueCallback = async (currentRegionId) => {
    const { mainProfilesList } = this.state;
    const mainProfilesListValue = await this.findMainProfileListValue(mainProfilesList, currentRegionId);
    this.setState({ mainProfilesListValue });
  };

  onChangeCityIdCallback = (cityId, regionId) => {
    this.setState({ cityId }, () => this.onChangeMainProfilesListValueCallback(regionId));
  };

  findMainProfileListValue = (mainProfilesList, currentRegionId) => {
    const filteredMainProfilesList = mainProfilesList.filter((el) => el.RegionId === currentRegionId);
    if (filteredMainProfilesList.length === 0) {
      return null;
    }
    if (filteredMainProfilesList.length === 1) {
      return filteredMainProfilesList[0].MainProfileId;
    }
    if (filteredMainProfilesList.length > 1) {
      const readOnlyTrueArr = filteredMainProfilesList.filter((el) => el.Readonly === true);
      if (readOnlyTrueArr.length === 0) {
        for (let i = 0; i < filteredMainProfilesList.length; i++) {
          if (
            Number(filteredMainProfilesList[i].MainProfileId) < Number(filteredMainProfilesList[i + 1].MainProfileId)
          ) {
            return filteredMainProfilesList[i].MainProfileId;
          } else {
            return filteredMainProfilesList[i + 1].MainProfileId;
          }
        }
      }
      if (readOnlyTrueArr.length === 1) {
        return readOnlyTrueArr[0].MainProfileId;
      }
      if (readOnlyTrueArr.length > 1) {
        for (let i = 0; i < readOnlyTrueArr.length; i++) {
          if (Number(readOnlyTrueArr[i].MainProfileId) < Number(readOnlyTrueArr[i + 1].MainProfileId)) {
            return readOnlyTrueArr[i].MainProfileId;
          } else {
            return readOnlyTrueArr[i + 1].MainProfileId;
          }
        }
      }
    } else {
      return null;
    }
  };

  getOwnerId = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (userInfo.OwnerId || userInfo.OwnerId === 0) {
      return userInfo.OwnerId;
    }

    try {
      const response = await api.owners();
      return response && response.length ? response[0].OwnerId : null;
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  initRequest = async () => {
    const { showError } = this.props;
    try {
      this.toggleLoader();
      const ownerId = await this.getOwnerId();

      if (!ownerId) {
        this.toggleLoader();
        return;
      }
      const comissionProfiles = await api.getOwnerProfiles(ownerId);
      const mainProfilesList = await api.mainProfileListForModalChangeProfile([ownerId]);
      const citiesRequest = await api.getCities();
      //filter для скрытия города all-in-one
      const cities = await citiesRequest.filter((el) => el.CityId !== 500001);
      const placingInfo = await api.getTerminalPlaces();
      const commisionProfileValue =
        comissionProfiles.find((el) => el.ProfileId === 1546) !== undefined
          ? comissionProfiles.find((el) => el.ProfileId === 1546).ProfileId
          : null;
      const cityId = await cities[0].CityId;
      const currentRegionId =
        (await cities.find((el) => el.CityId === cityId)) !== undefined
          ? cities.find((el) => el.CityId === cityId).RegionId
          : null;
      const mainProfilesListValue = await this.findMainProfileListValue(mainProfilesList, currentRegionId);
      const designTypes = await api.getTerminalDesignTypes();
      // const designTypeId = await designTypes[0].DesignTypeId;
      // let mainProfilesListValue = null;
      // if (mainProfilesListFilter.length > 0) {
      //   mainProfilesListValue = mainProfilesListFilter[0].MainProfileId
      // }

      this.setState({
        comissionProfiles,
        commisionProfileValue,
        mainProfilesListValue,
        mainProfilesList,
        placingInfo,
        cities,
        currentUserOwnerId: ownerId,
        cityId,
        currentRegionId,
        // designTypeId,
        designTypes,
      });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSubmitHandler = async () => {
    const {
      terminalId,
      cityId,
      commisionProfileValue,
      mainProfilesListValue,
      terminalsQuantity,
      terminalName,
      addressValue,
      geopointValue,
      capacityPrinterPaper,
      capacityCashBox,
      monitorCountValue,
      placingInfoValue,
      terminalDescription,
      dateCreationNoUTC,
      dateCreation,
      noteValue,
      terminalBranch,
      currentUserOwnerId,
      designTypeId,
    } = this.state;
    const { showError, translate } = this.props;
    try {
      if (!cityId && cityId == null) {
        this.closePopupConfirm();
        return message.error(
          `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-city')}!`,
          2,
        );
      }
      if (!commisionProfileValue && commisionProfileValue == null) {
        // console.log('NewTerminal::onSubmitHandler::commisionProfileValue');
        // console.log(commisionProfileValue);
        this.closePopupConfirm();
        return message.error(
          `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-comissionProfile')}!`,
          2,
        );
      }

      if (!mainProfilesListValue && mainProfilesListValue == null) {
        this.closePopupConfirm();
        return message.error(
          `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-mainScreenProfile')}!`,
          2,
        );
      } else if (
        capacityPrinterPaper &&
        capacityPrinterPaper !== '' &&
        (capacityPrinterPaper < 0 || capacityPrinterPaper > 6000)
      ) {
        this.closePopupConfirm();
        return message.error(
          this.universalErrorMessage(`${translate('page.terminalSettings.field-printerCapacity')}`),
          4,
        );
      } else if (capacityCashBox && capacityCashBox !== '' && (capacityCashBox < 0 || capacityCashBox > 3000)) {
        this.closePopupConfirm();
        return message.error(
          this.universalErrorMessage(`${translate('page.terminalSettings.field-cashboxCapacity')}`),
          4,
        );
      } else if (terminalName && terminalName !== '' && terminalName.length > 250) {
        this.closePopupConfirm();
        return message.error(this.universalErrorMessage(`${translate('page.terminalSettings.field-name')}`), 4);
      } else if (terminalBranch && terminalBranch !== '' && terminalBranch.length > 120) {
        this.closePopupConfirm();
        return message.error(this.universalErrorMessage(`${translate('page.terminalSettings.field-branch')}`), 4);
      } else if (
        !terminalsQuantity ||
        !isFinite(terminalsQuantity) ||
        terminalsQuantity < 1 ||
        terminalsQuantity > 100
      ) {
        this.closePopupConfirm();
        return message.error(this.universalErrorMessage(`${translate('page.createNewTerminal.terminalsCount')}`), 4);
      }

      // else if (addressValue && checkLatinSymbols(addressValue)) {
      //   this.closePopupConfirm();
      //   return message.error(this.universalErrorMessage(
      //     `${translate('page.terminalSettings.field-address')}`), 4)
      // }
      else if (addressValue && addressValue !== '' && checkLatinSymbols(addressValue)) {
        return message.error(`${translate('page.terminalSettings.latin-address-error')}`, 2);
      } else {
        this.toggleLoader();
        this.closePopupConfirm();
        const model = {
          Terminal: {
            TerminalId: terminalId,
            CityId: cityId,
            StatusId: 2,
            ProfileId: commisionProfileValue,
            MainProfileId: mainProfilesListValue,
            Name: terminalName,
            TerminalAddress: addressValue,
            TerminalAddress2: '',
            DesignTypeId: designTypeId,
            Geopoint: geopointValue,
            CapacityPrinterPaper: capacityPrinterPaper,
            CapacityCashBox: capacityCashBox,
            MonitorCount: monitorCountValue,
            PlacingId: placingInfoValue,
            TerminalDescription: terminalDescription,
            DateCreationNoUTC: dateCreationNoUTC,
            DateCreation: dateCreation,
            Note: noteValue,
            TerminalBranch: terminalBranch,
            TypeId: 1,
            ProjectId: 0,
            NetworkContractId: 0,
            OwnerId: currentUserOwnerId,
          },
          Count: terminalsQuantity,
        };
        const response = await api.addNewTerminal(model);
        this.setState(({ createdTerminals }) => {
          return {
            createdTerminals: [...createdTerminals, ...response],
          };
        }, this.callBackInfo);
        return response;
      }
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  callBackInfo = () => {
    const { translate } = this.props;
    return message.success(`${translate('page.createNewTerminal.success-create')}`, 2, this.toggleCreatedTerminals);
  };

  toggleCreatedTerminals = () => {
    this.setState(({ isShowCreated }) => {
      return {
        isShowCreated: !isShowCreated,
      };
    });
  };

  closeCreatedTerminals = () => {
    this.setState({ isShowCreated: false });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  toggleAdditionalCardVisibility = () => {
    this.setState(({ additionalCardVisibility }) => {
      return {
        additionalCardVisibility: !additionalCardVisibility,
      };
    });
  };

  onChangeCommisionProfileValue = (commisionProfileValue) => {
    this.setState({ commisionProfileValue });
  };

  onChangeMainProfilesListValue = (mainProfilesListValue) => {
    this.setState({ mainProfilesListValue });
  };

  onChangeTerminalName = (e) => {
    this.setState({ terminalName: e.target.value });
  };

  onChangeAddressValue = (addressValue) => {
    this.setState({ addressValue });
  };

  onChangeGeopointValue = (geopointValue) => {
    this.setState({ geopointValue });
  };

  onChangeCapacityPrinterPaper = (capacityPrinterPaper) => {
    this.setState({ capacityPrinterPaper });
  };

  onChangeCapacityCashBox = (capacityCashBox) => {
    this.setState({ capacityCashBox });
  };

  onChangeMonitorCountValue = (monitorCountValue) => {
    this.setState({ monitorCountValue });
  };

  onChangePlacingInfoValue = (placingInfoValue) => {
    this.setState({ placingInfoValue });
  };

  onChangeTerminalDescription = (e) => {
    this.setState({ terminalDescription: e.target.value });
  };

  onChangeDateCreation = (e) => {
    this.setState({
      dateCreation: e.target.value,
      dateCreationNoUTC: e.target.value,
    });
  };

  onChangeNoteValue = (e) => {
    this.setState({ noteValue: e.target.value });
  };

  onChangeTerminalBranch = (e) => {
    this.setState({ terminalBranch: e.target.value });
  };

  onChangeProjectValue = (projectValue) => {
    this.setState({ projectValue });
  };

  onChangeNetworkContractsValue = (networkContractsValue) => {
    this.setState({ networkContractsValue });
  };

  getMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  onChangeQuantity = (terminalsQuantity) => {
    this.setState({ terminalsQuantity });
  };

  closePopupConfirm = () => {
    this.setState({ showPopupConfirm: false });
  };

  togglePopupConfirm = () => {
    this.setState(({ showPopupConfirm }) => {
      return {
        showPopupConfirm: !showPopupConfirm,
      };
    });
  };

  reChangeAddressValue = (e) => {
    this.setState({ addressValue: e.target.value });
  };

  onToggleShowApproveAddress = () => {
    this.setState(({ showApproveAddress }) => {
      return {
        showApproveAddress: !showApproveAddress,
      };
    });
  };

  closeonShowApproveAddress = () => {
    this.setState({ showApproveAddress: false });
  };

  onChangeDesignTypeId = (designTypeId) => {
    this.setState({ designTypeId });
  };

  render() {
    const {
      terminalsQuantity,
      isLoading,
      additionalCardVisibility,
      isShowMap,
      cityId,
      comissionProfiles,
      mainProfilesList,
      commisionProfileValue,
      mainProfilesListValue,
      terminalName,
      addressValue,
      cities,
      geopointValue,
      capacityPrinterPaper,
      capacityCashBox,
      monitorCountValue,
      placingInfo,
      placingInfoValue,
      terminalDescription,
      dateCreation,
      noteValue,
      terminalBranch,
      showPopupConfirm,
      isShowCreated,
      createdTerminals,
      isAddressIncorrect,
      showApproveAddress,
      designTypeId,
      designTypes,
    } = this.state;
    const { translate } = this.props;
    // console.log('CreatrNewTerminal::cityId');
    // console.log(cityId);
    return (
      <>
        <div className="newTerminal--wrapper">
          <Row
            className="newTerminal--header-row no-margin"
            type="flex"
            align="middle"
            gutter={24}
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Row
              className="newTerminal--header-row no-margin secondary"
              type="flex"
              align="middle"
              gutter={24}
              style={{ flexWrap: 'nowrap' }}
            >
              <p>{translate('page.createNewTerminal.terminalsCount')}</p>
              <InputNumber
                className="newTerminal---additional-media-input-class"
                style={{ width: '200px', marginLeft: '10px' }}
                value={terminalsQuantity}
                min={1}
                max={100}
                onChange={this.onChangeQuantity}
              />
              {isFinite(terminalsQuantity) && terminalsQuantity > 1 && (
                <p className="newTerminal--warning-text">
                  {translate('page.createNewTerminal.more-then-one-terminal')}
                </p>
              )}
            </Row>
            <Popconfirm
              placement="right"
              style={{ right: '20px' }}
              title={translate('page.createNewTerminal.confirm-dialog-title')}
              visible={showPopupConfirm}
              onConfirm={this.onSubmitHandler}
              onCancel={this.closePopupConfirm}
              okText={translate('grids.certificates-issued.onOkText')}
              cancelText={translate('grids.certificates-issued.onCancelText')}
            >
              {checkElement('newTerminal-btn-create', this.currentArray) && (
                <Button type="primary" className="newTerminal--btn-save" onClick={this.togglePopupConfirm}>
                  {translate('cell-dropdown.create')}
                </Button>
              )}
            </Popconfirm>
          </Row>
          <Row gutter={24}>
            {/* START MAIN ROW */}
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card
                type="inner"
                className="newTerminal--card w-50"
                title={translate('page.terminalSettings.general-settings-title')}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <Row className="newTerminal--card-row" type="flex" align="middle">
                      <p className="form-text">{translate('page.terminalSettings.field-city')}:</p>
                      <DropDownCities
                        disabled={false}
                        forNewTerminal
                        cities={cities}
                        onChange={this.onChangeCityIdCallback}
                      />
                    </Row>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text">{translate('page.terminalSettings.field-comissionProfile')}:</p>
                      <Select
                        value={commisionProfileValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '50%', marginLeft: '10px' }}
                        onChange={this.onChangeCommisionProfileValue}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                      >
                        {comissionProfiles.map((el) => (
                          <Option value={el.ProfileId} key={`comissionProfiles-${el.ProfileId}`}>
                            {el.ProfileName}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                    <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                      <p className="form-text">{translate('page.terminalSettings.field-mainScreenProfile')}:</p>
                      <Select
                        value={mainProfilesListValue}
                        dropdownStyle={{ zIndex: '1054', width: '100%' }}
                        style={{ width: '50%', marginLeft: '10px' }}
                        onChange={this.onChangeMainProfilesListValue}
                        filterOption={(input, option) =>
                          JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                      >
                        {mainProfilesList.map((el) => (
                          <Option key={`mainProfilesList-elem-${el.MainProfileId}`} value={el.MainProfileId}>
                            {el.MainProfileName} - {el.MainProfileId}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Row>
                {checkElement('newTerminal-show-additional', this.currentArray) && (
                  <Button
                    style={{ marginTop: '10px' }}
                    className="btn-add-toggler"
                    onClick={this.toggleAdditionalCardVisibility}
                    type="primary"
                  >
                    {!additionalCardVisibility
                      ? translate('page.terminalSettings.btn-additionalSettings')
                      : translate('page.terminalSettings.btn-additionalSettings-hide')}
                  </Button>
                )}
              </Row>
              <Row>
                <ModalCreatedTerminals
                  visible={isShowCreated}
                  togglePopup={this.toggleCreatedTerminals}
                  closePopup={this.closeCreatedTerminals}
                  data={createdTerminals}
                />
              </Row>
            </Col>
            {/* END MAIN ROW */}
            {/* START ADDITIONAL ROW */}
            <Col xs={24} sm={24} md={24} lg={12}>
              {additionalCardVisibility && (
                <Card
                  type="inner"
                  className="newTerminal--card w-50"
                  title={translate('page.terminalSettings.addtionalSettings-title')}
                  style={{ marginTop: '20px' }}
                >
                  <Row gutter={24}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      // style={{ paddingLeft: 0, paddingRight: 0 }}
                      style={{ paddingLeft: '6px', paddingRight: '6px' }}
                    >
                      <Row className="newTerminal--card-row" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-printerCapacity')}:</p>
                        <InputNumber
                          className="newTerminal---additional-media-input-class"
                          value={capacityPrinterPaper}
                          min={0}
                          max={6000}
                          style={{ width: '50%' }}
                          onChange={this.onChangeCapacityPrinterPaper}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-cashboxCapacity')}:</p>
                        <InputNumber
                          className="newTerminal---additional-media-input-class"
                          value={capacityCashBox}
                          min={0}
                          max={3000}
                          style={{ width: '50%' }}
                          onChange={this.onChangeCapacityCashBox}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-monitorCount')}:</p>
                        <Select
                          value={monitorCountValue}
                          style={{ width: '50%', marginLeft: '10px' }}
                          onChange={this.onChangeMonitorCountValue}
                        >
                          {selectMonitorCount.map((el) => (
                            <Option key={`selectMonitorCount-el-${el}`} value={el} disabled={el === 0}>
                              {el}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('grids.createTerminal.column-designType')}</p>
                        <Select
                          value={designTypeId}
                          style={{ width: '50%', marginLeft: '10px' }}
                          onChange={this.onChangeDesignTypeId}
                        >
                          {designTypes.map((el) => (
                            <Option key={`designTypes-el-${el.DesignTypeId}`} value={el.DesignTypeId}>
                              {el.Name}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-placing')}:</p>
                        <Select
                          value={placingInfoValue}
                          dropdownStyle={{ zIndex: '1054', width: '100%' }}
                          style={{ width: '50%', marginLeft: '10px' }}
                          onChange={this.onChangePlacingInfoValue}
                        >
                          {placingInfo.map((el) => (
                            <Option key={`placingInfo-elem-${el.TypeDictionaryId}-${el.Id}`} value={el.Id}>
                              {el.Value}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-name')}:</p>
                        <Input
                          value={terminalName}
                          className="newTerminal---additional-media-input-class"
                          style={{ width: '50%' }}
                          onChange={this.onChangeTerminalName}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-address')}:</p>
                        <Popconfirm
                          placement="right"
                          style={{ right: '20px' }}
                          title={translate('page.terminalSettings.address-confirm-title')}
                          visible={showApproveAddress}
                          onConfirm={this.closeonShowApproveAddress}
                          onCancel={this.callBackForOnConfirmApproveAddress}
                          okText={translate('grids.certificates-issued.onOkText')}
                          cancelText={translate('grids.certificates-issued.onCancelText')}
                        >
                          <Input
                            value={addressValue}
                            className="newTerminal---additional-media-input-class"
                            style={{ width: '50%' }}
                            onChange={isAddressIncorrect ? this.reChangeAddressValue : this.onChangeAddressValue}
                            onFocus={isAddressIncorrect ? this.reChangeAddressValue : this.onAddressChange}
                          />
                        </Popconfirm>
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-coordinates')}:</p>
                        <Input
                          className="newTerminal---additional-media-input-class"
                          value={geopointValue}
                          style={{ width: '50%' }}
                          onChange={this.onChangeGeopointValue}
                          disabled
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-description')}:</p>
                        <TextArea
                          className="newTerminal---additional-media-input-class"
                          value={terminalDescription}
                          style={{ width: '50%' }}
                          onChange={this.onChangeTerminalDescription}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-dateOfCreation')}:</p>
                        <DatePicker
                          value={dateCreation}
                          format="dd.MM.yyyy"
                          min={new Date(2007, 0, 1)}
                          max={this.getMaxDate()}
                          onChange={this.onChangeDateCreation}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-notes')}:</p>
                        <Input
                          className="newTerminal---additional-media-input-class"
                          value={noteValue}
                          style={{ width: '50%' }}
                          onChange={this.onChangeNoteValue}
                        />
                      </Row>
                      <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
                        <p className="form-text">{translate('page.terminalSettings.field-branch')}:</p>
                        <Input
                          className="newTerminal---additional-media-input-class"
                          value={terminalBranch}
                          style={{ width: '50%' }}
                          onChange={this.onChangeTerminalBranch}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
            {/* END ADDITIONAL ROW */}
          </Row>
        </div>
        {isLoading && <LoadingPanel />}
        {isShowMap && (
          <ModalGoogleMap
            visible={isShowMap}
            togglePopup={this.onAddressChange}
            closeModal={this.closeGoogleMap}
            onChange={this.onChangeAddressValue}
            onToggleShowApproveAddress={this.onToggleShowApproveAddress}
            onChangeGeopoint={this.onChangeGeopointValue}
            currentAddress={addressValue}
            currentGeopoint={geopointValue}
            cities={cities}
            cityId={cityId}
          />
        )}
      </>
    );
  }
}

export default withTranslate(NewTerminal);
