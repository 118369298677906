import React, { PureComponent, createContext } from 'react';
import { message } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

const UpdateMenuProfileContext = createContext({});

class UpdateMenuProfileContextContainer extends PureComponent {
  currentArray = findArray('managementMenuProfiles');

  state = {
    profileStaffForUpdating: undefined,
    isLoading: false,
    selectedProfiles: this.props.defaultSelectedProfiles || [],
    menuProfilesTree: [],
  };

  componentDidMount() {
    this.fetchMenuProfilesTreeGQL();
  }

  buildTree = (data, profileId = 0, parentHistorySlug = '0') => {
    let tree = [];

    for (let item of data) {
      if (item.parentId === profileId) {
        let details = this.buildTree(data, item.profileId, `${parentHistorySlug},${item.profileId}`);

        if (details.length) {
          item = { ...item, parentHistorySlug, details };
        } else {
          item = { ...item, parentHistorySlug };
        }

        tree = [...tree, item];
      }
    }

    return tree;
  };

  updateMenuProfilesTreeBySelected = (selectedList, data) => {
    return data.map((profile) => ({
      ...profile,
      selected: selectedList.includes(profile.profileId),
      details: profile.details ? this.updateMenuProfilesTreeBySelected(selectedList, profile.details) : [],
    }));
  };

  fetchMenuProfilesTreeGQL = async () => {
    const { selectedProfiles = [] } = this.state;

    const selectedProfilesIDS =
      selectedProfiles && selectedProfiles.length ? selectedProfiles.map(({ profileId }) => profileId) : [];

    try {
      await this.setState({ isLoading: true });
      const data = await api.getByGraphQl(
        `menuProfilesTree(order:{ profileId:ASC}){profileId, name, parentId, level, userId, userName, datePost, version}`,
      );

      const menuProfilesTree = this.buildTree(
        data && data.data.menuProfilesTree.length
          ? data.data.menuProfilesTree.map((item) => ({
              ...item,
              selected: selectedProfilesIDS.includes(item.profileId),
            }))
          : [],
      );

      await this.setState({
        menuProfilesTree: menuProfilesTree,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleSetProfileStaffForUpdating = (profileStaffForUpdating) => {
    this.setState({ profileStaffForUpdating });
  };

  handleUpdateConfiguration = async (profileId, withChilds) => {
    const { translate } = this.props;
    try {
      await this.setState({ isLoading: true });
      await api.menuProfileUpdateVersion({ profileId, withChilds });
      message.success(
        `${translate(
          `page.profilesMenu.${
            withChilds
              ? 'profileWithChildrenConfigurationUpdatedSuccessfully'
              : 'profileConfigurationUpdatedSuccessfully'
          }`,
        )}`,
        2,
      );
      this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  dataToList = (data) =>
    data.reduce(
      (acc, profile) =>
        profile.details && profile.details.length
          ? [...acc, profile, ...this.dataToList(profile.details)]
          : [...acc, profile],
      [],
    );

  setSingleSelected = (profile, isSelected) => {
    this.setState(({ menuProfilesTree }) => {
      const selectedProfilesList = isSelected ? [profile] : [];

      return {
        selectedProfiles: selectedProfilesList,
        menuProfilesTree: this.updateMenuProfilesTreeBySelected(
          selectedProfilesList.map(({ profileId }) => profileId),
          menuProfilesTree,
        ),
      };
    });
  };

  setSelectedProfiles = (dataBySelected, isSelected) => {
    const selectedDataList = this.dataToList(dataBySelected);
    const selectedDataIdList = selectedDataList.map(({ profileId }) => profileId);

    this.setState(({ selectedProfiles, menuProfilesTree }) => {
      const withoutCurrentSelectedProfiles = selectedProfiles.filter(
        ({ profileId }) => !selectedDataIdList.includes(profileId),
      );

      const selectedProfilesList = isSelected
        ? [...withoutCurrentSelectedProfiles, ...selectedDataList]
        : [...withoutCurrentSelectedProfiles];

      return {
        selectedProfiles: selectedProfilesList,
        menuProfilesTree: this.updateMenuProfilesTreeBySelected(
          selectedProfilesList.map(({ profileId }) => profileId),
          menuProfilesTree,
        ),
      };
    });
  };

  render() {
    const { children, withSelect } = this.props;
    const { profileStaffForUpdating, isLoading, selectedProfiles, menuProfilesTree } = this.state;

    return (
      <UpdateMenuProfileContext.Provider
        value={{
          menuProfilesTree: menuProfilesTree,
          setMenuProfileTree: (menuProfilesTree) => {
            this.setState({ menuProfilesTree });
          },
          profileStaffForUpdating,
          handleSetProfileStaffForUpdating: this.handleSetProfileStaffForUpdating,
          handleUpdateConfiguration: this.handleUpdateConfiguration,
          isLoading: isLoading,
          permissions: {
            isShowProfileOptions: checkElement('managementMenuProfiles-isShowProfileOptions', this.currentArray),
            updateProfileName: checkElement('managementMenuProfiles-updateProfileName', this.currentArray),
            addProfile: checkElement('managementMenuProfiles-addProfile', this.currentArray),
            deleteProfile: checkElement('managementMenuProfiles-deleteProfile', this.currentArray),
            updateProfileConfiguration: checkElement(
              'managementMenuProfiles-updateProfileConfiguration',
              this.currentArray,
            ),
            updateProfilesChildsConfiguration: checkElement(
              'managementMenuProfiles-updateProfilesChildsConfiguration',
              this.currentArray,
            ),
            redirectElementTab: checkElement('managementMenuProfiles-redirectElementTab', this.currentArray),
          },
          selectedProfiles: selectedProfiles,
          setSelectedProfiles: this.setSelectedProfiles,
          withSelect: withSelect,
          setIsLoading: (isLoading) => {
            this.setState({ isLoading });
          },
          setSingleSelected: this.setSingleSelected,
          isSingle: this.props.isSingle,
        }}
      >
        {children}
      </UpdateMenuProfileContext.Provider>
    );
  }
}

export default withTranslate(UpdateMenuProfileContextContainer);

export const widthUpdateMenuProfileContext = (Component) => (props) =>
  (
    <UpdateMenuProfileContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </UpdateMenuProfileContext.Consumer>
  );
