import React, { PureComponent } from 'react';
import { formatDate } from '@telerik/kendo-intl';

import { withTranslate } from '../../../contexts/localContext';
import GridTerminalsOperabilityChoose from './gridTerminalsOperabilityChoose';
import PageTabs from '../../../components/tabber/tabber';
import GridTerminalsOperabilityDay from './gridTerminalOperabilityDay';
import GridTerminalsOperabilityEvents from './GridTerminalOperabilityEvents';
import GridCommunicationTerminalsPerDay from './gridCommunicationTerminalsPerDay';
import CommunicationMonitoringPerHour from './CommunicationMonitoringPerHour';
const convertToISOFormat = (date) => formatDate(date, 'dd.MM.yyyy');

class PageTerminalsOperabilityCurrentDay extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = props;
    this.state = {
      tabs: [
        {
          title: `${translate('page.terminalOperabilityDay.tab-operability-choose')}`,
          content: <GridTerminalsOperabilityChoose />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem, model, itemProps, hourInfo }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'day':
        const { WorkDate } = model;
        const titleWorkDate = convertToISOFormat(new Date(WorkDate));
        tabs.push({
          title: `${translate('page.terminalOperabilityPeriod.tab-operaility-main')}
           ${titleWorkDate}`,
          content: <GridTerminalsOperabilityDay dataItem={dataItem} model={model} />,
          closable: true,
          key: key,
        });
        break;
      case 'events':
        const {
          dataItem: { TerminalId },
        } = itemProps;
        tabs.push({
          title: `${translate('page.dashboard.tab-events')} #${TerminalId}`,
          content: (
            <GridTerminalsOperabilityEvents
              itemProps={itemProps}
              permissionName="terminalOperabilityDay"
              gridPermissionName="terminalOperabilityDayEvents"
              searchButtonPerrmissionName="erminalOperabilityDayEvents-btn-submit'"
            />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'communicationMonitoring':
        const { ChooseWorkDate, CommunicationTerminalsPerDay, ownerId, terminalList, dateStart } = model;

        tabs.push({
          title: `${translate('page.dashboard.tab-communicationMonitoring')} - ${convertToISOFormat(
            new Date(ChooseWorkDate),
          )}`,
          content: (
            <GridCommunicationTerminalsPerDay
              itemProps={CommunicationTerminalsPerDay}
              ownerId={ownerId}
              terminalList={terminalList}
              dateStart={dateStart}
              name="communicationTerminalsPerDay-grid"
              permissionName="terminalOperabilityDay"
            />
          ),
          closable: true,
          key: key,
        });
        break;

      case 'minutes':
        const { workDay, TerminalId: terminalId, hour, time } = hourInfo;
        tabs.push({
          title: `${translate('page.dashboard.tab-communicationMonitoring')}  ${time}`,
          content: <CommunicationMonitoringPerHour workDay={workDay} terminalId={terminalId} hour={hour} />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageTerminalsOperabilityCurrentDay);
