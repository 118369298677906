import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class Settlements extends PureComponent {
  currentArray = findArray('dictionaryCities');
  state = {
    citiesList: [],
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getCities = async () => {
    try {
      this.toggleLoader(true);
      const citiesList = await api.getCities();
      this.setState({ citiesList });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentDidMount() {
    this.getCities();
  }

  render = () => {
    const { isLoading, citiesList } = this.state;

    if (!checkElement('dictionaryCities-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={citiesList}
        isLoading={isLoading}
        name="dictionaryCities-grid"
        onRefresh={this.getCities}
        toolbar={<div></div>}
      >
        <GridColumn field="RegionId" title="grids.dictionary.column-regionId" width="145" filter="numeric" />
        <GridColumn field="RegionName" title="grids.dictionary.column-region" width="145" />
        <GridColumn field="CityId" title="grids.dictionary.column-cityId" width="145" filter="numeric" />
        <GridColumn field="CityName" title="grids.dictionary.column-city" width="145" />
        <GridColumn field="Description" title="grids.dictionary.column-district" width="145" />
        <GridColumn
          field="DatePost"
          title="grids.dictionary.column-date-of-change"
          width="145"
          filter="numeric"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
      </Grid>
    );
  };
}
export default withTranslate(Settlements);
