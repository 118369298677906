import React, { PureComponent } from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';

class PageNotFound extends PureComponent {
  goDashboard = () => {
    const { history } = this.props;
    history.push('/pages');
  };

  render() {
    return (
      <>
        <Result
          status="404"
          title="Page not found"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={this.goDashboard}>
              Go dashboard
            </Button>
          }
        />
      </>
    );
  }
}

export default withRouter(PageNotFound);
