import React, { PureComponent } from 'react';
import { withTranslate } from '../../../../../contexts/localContext';
import { Button, message, Modal } from 'antd';
import Input from '../../../../../components/Input';
import GridDropdown from '../../../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../../../core/normalizers/dropdownNormalizers.service';
import { PS_ACCOUNT, SERVICE_OWNERS, TECHNICAL_PARTNERS } from '../../../../../core/constants/configs.const';
import { ApiService } from '../../../../../services';

import Validator from '../Modals/validator';

import './ModalCommissionsTechPartners.scss';
import LoadingPanel from '../../../../../components/loader';

const api = new ApiService();

class ModalCommissionsTechPartners extends PureComponent {
  state = {
    serviceOwners: [],
    selectServiceOwner: [],
    psAccounts: [],
    selectPsAccount: [],
    technicalPartners: [],
    selectTechnicalPartners: [],
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getTechnicalPartners = async () => {
    try {
      await this.toggleLoader(false);
      const technicalPartners = await api.getTechnicalPartners();
      this.setState({ technicalPartners });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  fetchServiceOwners = async () => {
    try {
      await this.toggleLoader(true);
      const serviceOwners = await api.getServiceOwners();
      await this.setState({ serviceOwners });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };
  fetchPsAccount = async (id) => {
    try {
      await this.toggleLoader(true);
      const psAccounts = await api.getPsAccount(id);
      await this.setState({ psAccounts });
      await this.toggleLoader(false);
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  commissionsTechnicalPartnersInsert = async () => {
    const { selectServiceOwner, selectPsAccount, selectTechnicalPartners } = this.state;
    const { commissionsTechnicalPartners } = this.props;
    const {
      formState: { ConstAmount, Percent, MinAmount, MaxAmount },
      dataItem: { ServiceId },
      onClose,
      translate,
    } = this.props;
    try {
      const model = {
        id: -1,
        serviceId: ServiceId,
        technicalPartnerId: selectTechnicalPartners[0],
        constAmount: Validator.getParsedValue(ConstAmount),
        percent: Validator.getParsedValue(Percent),
        minAmount: Validator.getParsedValue(MinAmount),
        maxAmount: Validator.getParsedValue(MaxAmount),
        sodId: selectServiceOwner[0],
        psAccountId: selectPsAccount[0],
      };
      await this.toggleLoader(true);
      await api.commissionsTechnicalPartnersInsert(model);
      await this.toggleLoader(false);
      onClose();
      message.success(`${translate('page.commissionsTechnicalPartner.msg-successes-add')}`, 2.5);
      commissionsTechnicalPartners();
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      message.error(`${translate('page.commissionsTechnicalPartner.msg-error-update')}`, 2.5);
      showError(e);
    }
  };

  fetchData = async () => {
    await this.fetchServiceOwners();
    await this.getTechnicalPartners();
  };

  componentDidMount() {
    this.fetchData();
  }

  onHandleSelectItems = (name) => (selectedItems) => {
    const { serviceOwners } = this.state;

    this.setState({ [name]: selectedItems }, () => {
      if (name === 'selectServiceOwner') {
        const currentOwner = serviceOwners.find(({ SodId }) => SodId === selectedItems[0]);

        if (currentOwner) {
          this.fetchPsAccount(currentOwner.PsCustomerId);
        }
      }
    });
  };

  render() {
    const {
      serviceOwners,
      selectServiceOwner,
      psAccounts,
      selectPsAccount,
      technicalPartners,
      selectTechnicalPartners,
      isLoading,
    } = this.state;
    const { visible, translate, onClose, formState, onChangeFormState, formErrors, onError } = this.props;

    const isDisabled = !!Object.values(formErrors).filter((item) => item).length;

    const isRequiredFields =
      selectServiceOwner.length &&
      selectPsAccount.length &&
      selectTechnicalPartners.length &&
      (!formState.Percent || formState.MinAmount);

    return (
      <>
        <Modal
          title={`${translate('page.commissionsTechnicalPartner.modal-title-add')}`}
          visible={visible}
          onCancel={onClose}
          className="ModalCommissionsTechPartners"
          footer={[
            <Button
              type="primary"
              disabled={isDisabled || !isRequiredFields}
              onClick={this.commissionsTechnicalPartnersInsert}
            >
              {translate('core.buttonTitles.add')}
            </Button>,
            <Button onClick={() => onClose()}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          <div className="ModalCommissionsTechPartners-container">
            <div className="SupplierCommissionsForm-row">
              <div className="ModalCommissionsTechPartners-label">
                {translate('page.commissionsTechnicalPartner.label-techPartners')}
              </div>
              <div className="ModalCommissionsTechPartners-gridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeTechnicalPartners(technicalPartners)}
                  onSave={this.onHandleSelectItems('selectTechnicalPartners')}
                  selectItems={selectTechnicalPartners}
                  colConfig={TECHNICAL_PARTNERS}
                  defaultTitle={translate('page.commissionsTechnicalPartner.dropdown-title-techPartner')}
                  isSingle
                />
              </div>
            </div>
            <div className="SupplierCommissionsForm-row SupplierCommissionsForm-row--nowrapp">
              <div className="ModalCommissionsTechPartners-col">
                <div className="ModalCommissionsTechPartners-row">
                  <div className="ModalCommissionsTechPartners-label">
                    <label>{translate('page.commissionsTechnicalPartner.label-constAmount')} </label>
                  </div>
                  <div className="ModalCommissionsTechPartners-input w-70">
                    <Input
                      type="text"
                      formState={formState}
                      onChangeFormState={(name, value) => onChangeFormState(name, value)}
                      formErrors={formErrors}
                      setError={onError}
                      validators={Validator.getValidators(translate, formState.ConstAmount, 'ConstAmount')}
                      name="ConstAmount"
                      key="ConstAmount"
                    />
                  </div>
                </div>
              </div>
              <div className="ModalCommissionsTechPartners-col">
                <div className="ModalCommissionsTechPartners-row">
                  <div className="ModalCommissionsTechPartners-label text-center">
                    <label>{translate('page.commissionsTechnicalPartner.label-percent')} </label>
                  </div>
                  <div className="ModalCommissionsTechPartners-input w-70">
                    <Input
                      type="text"
                      formState={formState}
                      onChangeFormState={(name, value) => onChangeFormState(name, value)}
                      formErrors={formErrors}
                      setError={onError}
                      validators={Validator.getValidators(translate, formState.Percent, 'Percent')}
                      name="Percent"
                      key="Percent"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="SupplierCommissionsForm-row SupplierCommissionsForm-row--nowrapp">
              <div className="ModalCommissionsTechPartners-label">
                <label>{translate('page.commissionsTechnicalPartner.label-minAmount')} </label>
              </div>
              <div className="ModalCommissionsTechPartners-input">
                <Input
                  type="text"
                  formState={formState}
                  onChangeFormState={(name, value) => onChangeFormState(name, value)}
                  formErrors={formErrors}
                  setError={onError}
                  validators={Validator.getValidators(translate, formState.MinAmount, 'MinAmount')}
                  name="MinAmount"
                  key="MinAmount"
                  disabled={!formState.Percent.length}
                />
              </div>
            </div>
            <div className="SupplierCommissionsForm-row">
              <div className="ModalCommissionsTechPartners-label">
                <label>{translate('page.commissionsTechnicalPartner.label-maxAmount')} </label>
              </div>
              <div className="ModalCommissionsTechPartners-input">
                <Input
                  type="text"
                  formState={formState}
                  onChangeFormState={(name, value) => onChangeFormState(name, value)}
                  formErrors={formErrors}
                  setError={onError}
                  validators={Validator.getValidators(translate, formState.MaxAmount, 'MaxAmount')}
                  name="MaxAmount"
                  key="MaxAmount"
                  disabled={!formState.Percent.length}
                />
              </div>
            </div>
            <div className="SupplierCommissionsForm-row">
              <div className="ModalCommissionsTechPartners-label">{translate('page.bankDetails.service-owner')}</div>
              <div className="ModalCommissionsTechPartners-gridDropdown ModalCommissionsTechPartners-gridDropdown--top">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeServiceOwners(serviceOwners)}
                  colConfig={SERVICE_OWNERS}
                  onSave={this.onHandleSelectItems('selectServiceOwner')}
                  selectItems={selectServiceOwner}
                  defaultTitle={translate('page.bankDetails.dropDown-title.service-owner')}
                  isSingle
                />
              </div>
            </div>
            <div className="SupplierCommissionsForm-row">
              <div className="ModalCommissionsTechPartners-label">{translate('page.bankDetails.check-ps')}</div>
              <div className="ModalCommissionsTechPartners-gridDropdown ModalCommissionsTechPartners-gridDropdown--top">
                <GridDropdown
                  data={DropdownNormalizersService.normalizePsAccount(psAccounts)}
                  onSave={this.onHandleSelectItems('selectPsAccount')}
                  selectItems={selectPsAccount}
                  colConfig={PS_ACCOUNT}
                  defaultTitle={translate('page.bankDetails.dropDown-title.account-ps')}
                  isSingle
                />
              </div>
            </div>
          </div>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalCommissionsTechPartners);
