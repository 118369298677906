import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';

export default class CellFilter extends GridCell {
  render() {
    const { field, dataItem, format, formatCellData } = this.props;
    let value = dataItem[field];
    if (value instanceof Date) {
      value = formatDate(value, format);
      if (value === '01.01.1970 03:00:00') {
        value = '';
      }
    }
    if (typeof value === 'boolean') {
      if (value === true) {
        value = 'true';
      }
      if (value === false) {
        value = 'false';
      }
    }
    return <td style={{ cursor: 'pointer' }}>{formatCellData ? formatCellData(value) : value}</td>;
  }
}
