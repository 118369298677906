import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import AddProfileModal from './AddProfileModal';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class AddProfileModalContainer extends PureComponent {
  constructor(props) {
    const { formState } = props;
    super(props);
    this.state = {
      isOwnerDropdownDisabled: formState.ProfileVisibility === 'All',
      isLoading: false,
    };
  }

  handleSelectChange = (value) => {
    const { onChangeFormState } = this.props;

    onChangeFormState('ClonedProfile', value);
  };

  handleRadioChange = (e) => {
    const { onChangeFormState } = this.props;
    const isOwnerDropdownDisabled = e.target.value === 'All';

    onChangeFormState('ProfileVisibility', e.target.value);
    this.setState({ isOwnerDropdownDisabled });
  };

  handleOwnersChange = (selectedOwners) => {
    const { onChangeFormState } = this.props;

    onChangeFormState('SelectedDealers', selectedOwners);
  };

  getIsSubmitButtonDisabled = () => {
    const {
      formState: { ProfileName, ProfileDescription, ClonedProfile, ProfileVisibility, SelectedDealers },
    } = this.props;

    const isDealerInfoFilled = ProfileVisibility === 'All' || SelectedDealers.length;

    return !(ProfileName && ProfileDescription && ClonedProfile && isDealerInfoFilled);
  };

  handleSubmit = async () => {
    const {
      formState: { ProfileName, ProfileDescription, ClonedProfile, ProfileVisibility, SelectedDealers },
      showError,
      translate,
    } = this.props;

    await this.setState({ isLoading: true });

    const model = {
      ProfileId: ClonedProfile,
      Name: ProfileName,
      Description: ProfileDescription,
      OwnersList: ProfileVisibility === 'All' ? [] : SelectedDealers,
    };

    try {
      await api.commissionsClientProfileCopy(model);
      await message.success(translate('grids.commission-profiles.responseMessages.successfulProfileCreate'), 2);
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, profileList, ...rest } = this.props;
    const { isOwnerDropdownDisabled, isLoading } = this.state;

    return (
      <Modal
        title={translate('grids.commission-profiles.addProfileTitle')}
        visible={visible}
        onCancel={closeModal}
        zIndex="1053"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.getIsSubmitButtonDisabled()}
            loading={isLoading}
            onClick={this.handleSubmit}
          >
            {translate('page.commission.btn-create')}
          </Button>,
        ]}
      >
        <AddProfileModal
          profileList={profileList}
          handleSelectChange={this.handleSelectChange}
          handleRadioChange={this.handleRadioChange}
          handleOwnersChange={this.handleOwnersChange}
          isOwnerDropdownDisabled={isOwnerDropdownDisabled}
          {...rest}
        />
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddProfileModalContainer);
