export const exceptionFinMonLimits = (translate, value) => {
  switch (value) {
    case 'exceptionsFinMonLimit-content':
      return `${translate('page.adminPanel.pageContent')}`;
    case 'exceptionsFinMonLimit-btnAdd':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.add')}`;
    case 'exceptionsFinMonLimit-btnChangeLimits':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.change')}`;
    case 'exceptionsFinMonLimit-btnRemoveLimits':
      return `${translate(`page.adminPanel.type-button`)}:${translate('core.buttonTitles.delete')}`;
    default:
      return value;
  }
};
