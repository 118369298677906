import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import AddCodeModalService from './addCodeModalService';
import { ApiService } from '../../../services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { CODE_COLUMN_CONFIG } from './constants';

const api = new ApiService();

class ChangeCodeModal extends PureComponent {
  state = {
    isLoading: false,
    selectCodes: [],
    codes: [],
    isErrorExist: false,
  };

  componentDidMount() {
    this.getNBUCodes();
  }

  getNBUCodes = async () => {
    const { translate } = this.props;
    try {
      const data = await api.NBUCodesList();

      await this.setState({
        codes: data && data.length ? AddCodeModalService.normalizeNBUCodes(data, translate) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleChange = async () => {
    const { translate, onClose, onRefresh, selectedItems } = this.props;
    const { selectCodes } = this.state;
    this.setState({ isLoading: true });

    try {
      await api.updateNBUPaymentTypeServiceRelations(
        selectedItems.map(({ ServiceId, PaymentType }) => ({
          ServiceId,
          PaymentType,
          F108Code: selectCodes[0],
        })),
      );
      this.setState({ isLoading: true });
      onClose();
      message.success(translate('page.paymentTypeNBUCode.successfullyChanged'), 3);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false, isErrorExist: true });
      showError(error);
    }
  };

  onDropdownSave = (selectCodes) => {
    this.setState({ selectCodes, isErrorExist: false });
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const { codes, selectCodes, isErrorExist, isLoading } = this.state;

    return (
      <Modal
        title={translate('page.paymentTypeNBUCode.changeNBUCode')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleChange}
            loading={isLoading}
            disabled={!selectCodes.length || isErrorExist}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="AddCodeModal-label">{translate('page.paymentTypeNBUCode.activeNBUCodes')}:</div>
        <div className="AddCodeModal-selectWrapper">
          <GridDropdown
            data={codes}
            colConfig={CODE_COLUMN_CONFIG}
            selectItems={selectCodes}
            onSave={this.onDropdownSave}
            defaultTitle={translate('page.paymentTypeNBUCode.selectCode')}
            isSingle
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ChangeCodeModal);
