import React, { PureComponent } from 'react';

class FormStateContainer extends PureComponent {
  constructor(props) {
    const { initialValues } = props;
    super(props);
    this.state = {
      data: initialValues,
      errors: {},
    };
  }

  setValueByName = (name, value) => {
    this.setState(({ data }) => {
      return {
        data: { ...data, [name]: value },
      };
    });
  };

  setFormState = (newFormState) => {
    this.setState({ data: newFormState });
  };

  setError = (name, errorMessage) => {
    this.setState(({ errors }) => {
      return {
        errors: { ...errors, [name]: errorMessage },
      };
    });
  };

  setErrorState = (newErrorState) => {
    this.setState({ errors: newErrorState });
  };

  getIsErrorsExist = (errorsState) => Object.values(errorsState).reduce((acc, item) => item || acc, false);

  render() {
    const { data, errors } = this.state;

    return React.cloneElement(this.props.children, {
      onChangeFormState: this.setValueByName,
      onError: this.setError,
      formState: data,
      formErrors: errors,
      setFormState: this.setFormState,
      setErrorState: this.setErrorState,
      getIsErrorsExist: this.getIsErrorsExist,
    });
  }
}

export default FormStateContainer;
