import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import ChangeLimitModal from './ChangeLimitModal';
import { ApiService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import UpdateBalanceModal from './UpdateBalanceModal';
import FormStateContainer from '../../../components/FormStateContainer';

import './SuppliersBalanceGrid.scss';
import { DEFAULT_UPDATE_INITIAL_VALUES } from './constants';
import CellBoolean from '../../account/cellBoolean';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class SuppliersBalanceGrid extends PureComponent {
  currentArray = findArray('suppliersBalance');

  state = {
    isLoading: false,
    suppliersBalance: [],
    errorMessage: '',
    dateStart: new Date(),
    dateEnd: new Date(),
    currentDataItemLimit: null,
    selectedItems: [],
    updateModalVariant: '',
  };

  componentDidMount() {
    this.getSuppliersBalance();
  }

  onDateChange = ({ target: { value } }) => {
    console.log('value', value);
    this.setState({ dateStart: new Date(value), dateEnd: new Date(value), selectedItems: [] }, () => {
      this.getSuppliersBalance();
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { dateStart, dateEnd, selectedItems } = this.state;

    return (
      <Row>
        <div className="SuppliersBalanceGrid-dateFields">
          <div className="SuppliersBalanceGrid-label">{translate('page.suppliersBalance.period')}</div>

          <div className="SuppliersBalanceGrid-fields">
            <DatePicker format="yyyy.MM.dd" value={dateStart} onChange={this.onDateChange} />
            <DatePicker format="yyyy.MM.dd" value={dateEnd} onChange={this.onDateChange} />
          </div>
        </div>

        <div>
          {checkElement('suppliersBalance-add', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ updateModalVariant: 'addBalance' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('suppliersBalance-edit', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ updateModalVariant: 'editBalance' });
              }}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  getSuppliersBalance = async () => {
    const { dateStart, dateEnd } = this.state;

    try {
      await this.setState({ isLoading: true });

      const suppliersBalance = await api.getServicesBalances({
        dateStart: getDataByTimezoneOffset(StandardizeTimeForDate(dateStart, true)).toISOString(),
        dateEnd: getDataByTimezoneOffset(StandardizeTimeForDate(dateEnd)).toISOString(),
      });

      await this.setState({
        suppliersBalance: suppliersBalance && suppliersBalance.length ? suppliersBalance : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, suppliersBalance, currentDataItemLimit, updateModalVariant, selectedItems } = this.state;

    if (!checkElement('suppliersBalance-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={suppliersBalance}
          toolbar={this.buildToolbar()}
          onRefresh={this.getSuppliersBalance}
          name="suppliersBalanceGrid"
          isLoading={isLoading}
          fieldForSelection="ServiceId"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn
            field="BalanceDay"
            title="page.suppliersBalance.col-balanceOnDate"
            width="160"
            filter="date"
            format="dd.MM.yyyy"
          />
          <GridColumn field="Name" title="page.suppliersBalance.col-name" width="240" />
          <GridColumn field="GateName" title="page.suppliersBalance.col-gateway" width="180" />
          <GridColumn field="GateServices" title="page.suppliersBalance.col-servicesId" width="420" />
          <GridColumn field="Balance" title="page.suppliersBalance.col-sum" width="150" filter="numeric" />
          <GridColumn
            field="DatePost"
            title="page.suppliersBalance.col-lastUpdate"
            width="200"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="Limit"
            title="page.suppliersBalance.col-sumLimit"
            width="150"
            filter="numeric"
            onClick={({ dataItem }) => {
              this.setState({ currentDataItemLimit: dataItem });
            }}
          />
          <GridColumn
            field="IsActive"
            title="page.suppliersBalance.col-isActive"
            width="150"
            cell={WrappedCellBoolean}
          />
        </Grid>

        {currentDataItemLimit && (
          <ChangeLimitModal
            dataItem={currentDataItemLimit}
            closeModal={() => {
              this.setState({ currentDataItemLimit: null });
            }}
            onRefresh={this.getSuppliersBalance}
          />
        )}

        {updateModalVariant && (
          <FormStateContainer
            initialValues={
              updateModalVariant === 'addBalance'
                ? DEFAULT_UPDATE_INITIAL_VALUES
                : {
                    ServiceId: selectedItems[0].ServiceId,
                    Name: selectedItems[0].Name,
                    GateName: selectedItems[0].GateName,
                    GateServicesList: selectedItems[0].GateServices,
                    IsActive: selectedItems[0].IsActive,
                    Limit: selectedItems[0].Limit,
                  }
            }
          >
            <UpdateBalanceModal
              variant={updateModalVariant}
              closeModal={() => {
                this.setState({ updateModalVariant: '' });
              }}
              onRefresh={this.getSuppliersBalance}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(SuppliersBalanceGrid);
