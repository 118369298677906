import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Modal, Row } from 'antd';
import './DealerCommissionsModal.scss';

class DealerCommissionsModal extends PureComponent {
  render = () => {
    const { translate, visible, closeModal } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        zIndex="1053"
        footer={[
          <Button key="back" style={{ margin: '0 auto' }} onClick={closeModal}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        <div className="DealerCommissionsModal">
          <Row className="DealerCommissionsModal-row">
            <p>{translate('grids.dealerCommissions.select-dealer')}</p>
          </Row>
        </div>
      </Modal>
    );
  };
}
export default withTranslate(DealerCommissionsModal);
