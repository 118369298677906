import React, { PureComponent } from 'react';
import { Modal, Button, Col, Row, message, Select } from 'antd';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import './BackupButtons.scss';

const api = new ApiService();

const { Option } = Select;

class ModalAssignBusinessService extends PureComponent {
  state = {
    loading: false,
    selected: null,
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  assignBusinessService = async () => {
    const { closeModal, showError, translate, MainProfileIds } = this.props;
    const { selected } = this.state;
    try {
      this.setState({ loading: true });
      const model = {
        mainProfileReserveId: selected,
        MainProfileIds,
      };
      await api.mainProfileReserveSet(model);
      await message.success(`${translate('page.screenControlBusinessServices.modal-assign-template-success')}`, 2.5);
      await closeModal();
      window.location.reload();
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { translate, visible, closeModal, MainProfileIds, profileReserveList = [] } = this.props;
    const { loading, selected } = this.state;

    const defaultTemplateValue = translate('page.screenControlBusinessServices.default-template-value');
    return (
      <>
        <Modal
          title={translate('grids.profilesMainProfileList.modal-assign-template-title')}
          visible={visible}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!MainProfileIds || MainProfileIds.length < 1 || !selected}
              loading={loading}
              onClick={this.assignBusinessService}
            >
              {translate('grids.profilesMainProfileList.modal-assign-template-btn-submit')}
            </Button>,
          ]}
          centered
        >
          {/* START FIRST SELECT BLOCK */}
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>
                {translate('page.screenControlBusinessServices.modal-assign-template-text')}
              </p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                placeholder={defaultTemplateValue}
                optionFilterProp="children"
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                filterOption={true}
                showSearch
              >
                {profileReserveList.map((profileReserveList) => (
                  <Option
                    value={`${profileReserveList.MainProfileReserveId}`}
                    key={`assign-business-service-${profileReserveList.MainProfileReserveId}`}
                  >
                    {profileReserveList.MainProfileReserveName} - {profileReserveList.MainProfileReserveId}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalAssignBusinessService);
