import { getTransformDateString } from '../../../utils/getTransformDateString';
import { alphabeticalSorting } from '../../../utils/alphabeticalSorting';

class TemplateServicesGridServices {
  normalizeServicesGroup = (servicesGroups) => {
    if (!servicesGroups || !servicesGroups.length) {
      return [];
    }

    return servicesGroups
      .map((servicesGroup) => ({
        ...servicesGroup,
        Id: servicesGroup.GroupId,
        DatePost: getTransformDateString(servicesGroup.DatePost),
      }))
      .sort(({ Name: PrevGroupName }, { Name: NextGroupName }) => alphabeticalSorting(PrevGroupName, NextGroupName));
  };

  normalizeManagersList = (managersList) => {
    if (!managersList || !managersList.length) {
      return [];
    }

    return managersList.map((manager) => ({
      ...manager,
      Id: manager.UserId,
      Name: manager.UserName,
      DatePost: getTransformDateString(manager.DatePost),
    }));
  };
}

export default new TemplateServicesGridServices();
