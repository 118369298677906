import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class RecordDeletionHistory extends PureComponent {
  state = {
    services: [],
    isLoading: false,
    selectedItems: [],
  };

  getHistory = async () => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.getNBUPaymentTypeServiceRelationsHistory();
      await this.setState({
        services:
          services && services.length
            ? services.map((service) => ({
                ...service,
                DatePostTo: getDataByTimezoneOffset(service.DatePostTo),
              }))
            : [],
      });
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  componentDidMount() {
    this.getHistory();
  }

  render() {
    const { services, isLoading } = this.state;

    return (
      <Grid
        data={services}
        onRefresh={this.getHistory}
        toolbar={<></>}
        isLoading={isLoading}
        name="recordDeletionHistoryGrid"
        initialSort={{ field: 'DatePostTo', dir: 'desc' }}
      >
        <GridColumn field="ServiceId" title="page.paymentTypeNBUCode.col-serviceId" width="120" filter="numeric" />
        <GridColumn field="ServiceName" title="page.paymentTypeNBUCode.col-serviceName" width="300" />
        <GridColumn field="ServiceGroupName" title="page.paymentTypeNBUCode.col-serviceGroup" width="300" />
        <GridColumn field="PaymentTypeName" title="page.paymentTypeNBUCode.col-paymentType" width="240" />
        <GridColumn field="F108Code" title="page.paymentTypeNBUCode.col-NBUCode" width="120" />
        <GridColumn
          field="DatePostTo"
          title="page.paymentTypeNBUCode.col-deleteDate"
          width="240"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="UserName" title="page.paymentTypeNBUCode.col-performer" width="180" />
      </Grid>
    );
  }
}

export default RecordDeletionHistory;
