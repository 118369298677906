const suspendedFinancialTransactions = (translate, value) => {
  switch (value) {
    case 'suspendedFinancialTransactions-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'suspendedFinancialTransactions-unblock':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.suspendedFinancialTransactions.unblock')}`;
    case 'suspendedFinancialTransactions-refuse':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.suspendedFinancialTransactions.refuse')}`;
    case 'suspendedFinancialTransactions-setComment':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.suspendedFinancialTransactions.comment')}`;
    case 'suspendedFinancialTransactions-operationsAction':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'page.suspendedFinancialTransactions.paymentActions',
      )}`;
    case 'suspendedFinancialTransactions-unblockHistory':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(
        'page.suspendedFinancialTransactions.unlocksHistory',
      )}`;
    default:
      return value;
  }
};

export default suspendedFinancialTransactions;
