import React, { PureComponent } from 'react';
import { Col, Button, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../../../components/grids/baseGrid';
import { withTranslate } from '../../../../../contexts/localContext';
import AddClientCommissionsModal from './AddClientCommissionsModal';
import ServiceIdField from '../ServiceIdField';
import { ApiService } from '../../../../../services';

const api = new ApiService();

class ClientCommissions extends PureComponent {
  state = {
    selectedItems: [],
    addedCommissions: [],
    isLoading: false,
    isDialogOpen: false,
    selectedCommissions: [],
  };

  onDelete = () => {
    this.setState(({ selectedItems, addedCommissions }) => {
      const selectedItemsIds = selectedItems.map(({ id }) => id);

      return {
        addedCommissions: addedCommissions.filter(({ id }) => !selectedItemsIds.includes(id)),
        selectedItems: [],
      };
    });
  };

  renderToolbar = () => {
    const { translate, currentServiceId } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="StepServiceCreationClientCommissions-toolbar">
        <ServiceIdField serviceId={currentServiceId} />

        <div>
          <Button
            type="primary"
            onClick={() => {
              this.setState({ isDialogOpen: true });
            }}
          >
            {translate('core.buttonTitles.add')}
          </Button>

          <Button type="primary" disabled={!selectedItems.length} onClick={this.onDelete}>
            {translate('core.buttonTitles.delete')}
          </Button>
        </div>
      </Col>
    );
  };

  onAdd = (newAddedCommissions) => {
    this.setState(({ addedCommissions }) => ({
      addedCommissions: addedCommissions.length
        ? [
            ...addedCommissions,
            ...newAddedCommissions.filter(({ id: newId }) => !addedCommissions.find(({ id }) => id === newId)),
          ]
        : newAddedCommissions,
    }));
  };

  iterateSetCommission = async ([firstCommissionItem, ...restCommissionList]) => {
    try {
      await api.postCommissionsClientReferenceSet(firstCommissionItem);

      if (restCommissionList.length) {
        await this.iterateSetCommission(restCommissionList);
      }
    } catch (e) {
      throw e;
    }
  };

  onSaveCommissions = async () => {
    const { addedCommissions } = this.state;
    const { currentServiceId, translate, handleUpdateTabPanel } = this.props;

    const resultCommissionsObject = addedCommissions
      .reduce(
        (acc, { CommissionId, PaymentTypeId, ProfileId }) =>
          acc.find(({ CommissionId: accCommissionId }) => accCommissionId === CommissionId)
            ? acc.map((commission) =>
                commission.CommissionId === CommissionId
                  ? {
                      CommissionId,
                      profilesList: [...commission.profilesList, ProfileId],
                      paymentTypesList: [...commission.paymentTypesList, PaymentTypeId],
                    }
                  : commission,
              )
            : [
                ...acc,
                {
                  CommissionId,
                  profilesList: [ProfileId],
                  paymentTypesList: [PaymentTypeId],
                },
              ],
        [],
      )
      .map(({ CommissionId, profilesList, paymentTypesList }) => ({
        commissionId: CommissionId,
        profilesList: [...new Set(profilesList)],
        paymentTypesList: [...new Set(paymentTypesList)],
        servicesList: [currentServiceId],
      }));

    try {
      await this.setState({ isLoading: true });
      await this.iterateSetCommission(resultCommissionsObject);
      await message.success(`${translate('page.servicesBigTable.commissionsClientSuccessfullySaved')}`, 2);
      this.setState({
        isLoading: false,
      });
      handleUpdateTabPanel();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { addedCommissions, isLoading, isDialogOpen } = this.state;
    const { translate } = this.props;

    return (
      <div className="StepServiceCreationClientCommissions">
        <Grid
          className="StepServiceCreationClientCommissions-grid"
          data={addedCommissions}
          toolbar={this.renderToolbar()}
          isLoading={isLoading}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          name="serviceCreationClientCommissionsGrid"
          fieldForSelection="id"
          multiSelected
          withOutBaseButtons
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            showAllSelected
          />
          <GridColumn field="ProfileName" title="page.commissionsServices.profile" width="120" filter="numeric" />

          <GridColumn field="PaymentTypeName" title="page.commissionsServices.col-paymentTool" width="170" />

          <GridColumn
            field="CommissionId"
            title="page.commissionsServices.col-commissionNumber"
            width="150"
            filter="numeric"
          />

          <GridColumn field="CommissionName" title="page.commissionsServices.col-commissionName" width="240" />
          <GridColumn
            field="TimeFrom"
            title="page.commissionsServices.col-beginningDate"
            width="160"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="TimeTo"
            title="page.commissionsServices.col-expirationDate"
            width="160"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="Active"
            title="page.commissionsServices.col-isEnabled"
            width="120"
            cell={({ dataItem: { Active } }) => (
              <td style={{ textAlign: 'center' }}>
                {Active ? translate('page.commissionsServices.yes') : translate('page.commissionsServices.no')}
              </td>
            )}
            formatFilterCellData={(Active) =>
              Active ? translate('page.commissionsServices.yes') : translate('page.commissionsServices.no')
            }
          />

          <GridColumn field="ClientCommissionRule" title="page.commissionsServices.col-rule" width="420" />

          <GridColumn
            field="DatePost"
            title="page.commissionsServices.col-dateOfChange"
            width="160"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="UserNamePost" title="page.commissionsServices.col-executor" width="140" />
        </Grid>

        <Button
          type="primary"
          disabled={!addedCommissions.length}
          className="StepServiceCreationClientCommissions-action"
          onClick={this.onSaveCommissions}
        >
          {translate('page.servicesBigTable.saveAndEnd')}
        </Button>

        {isDialogOpen && (
          <AddClientCommissionsModal
            visible={isDialogOpen}
            onClose={() => {
              this.setState({ isDialogOpen: false });
            }}
            onAdd={this.onAdd}
          />
        )}
      </div>
    );
  }
}

export default withTranslate(ClientCommissions);
