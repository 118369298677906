import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import findArray from '../../utils/findArrForCurrentPage';
import { ApiService, StorageService } from '../../services';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS, BASE_GRID_COLUMNS } from './constants';
import ManageMerchantCallbacksService from './ManageMerchantCallbacks.service';
import Grid from '../../components/grids/baseGrid';
import { Button, message, Row } from 'antd';
import checkElement from '../../utils/checkElement';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { withTranslate } from '../../contexts/localContext';
import { getTransformDateString } from '../../utils/getTransformDateString';
import DeleteModal from './DeleteModal';
import EditableGridInput from '../../components/grids/EditableGridInput';

import './ManageMerchantCallbacksGrid.styled.scss';
import AddNewItemModal from './AddNewItemModal';
import FormStateContainer from '../../components/FormStateContainer';
import DeleteItemModal from './DeleteItemModal';

const api = new ApiService();

class ManageMerchantParametersGrid extends PureComponent {
  currentArray = findArray('manageMerchantCallbacksGrid');

  state = {
    callbacks: [],
    isLoading: false,
    filtersString: '',
    sortString: '',
    selectedItems: [],
    modalVariant: '',
    addNewItemOpen: '',
    selectedItemsActive: [],
  };

  componentDidMount() {
    this.fetchCallbackGQL();
  }

  fetchCallbackGQL = async (props) => {
    const { filtersString, sortString } = this.state;
    const { callbackId } = this.props;

    try {
      this.setState({ isLoading: true });

      const currentColumns = ['id', 'name'];

      const argumentsString = `take:500, skip:0,order: ${sortString || '{id: ASC}'}${
        filtersString ? `,where:{and:[${filtersString}]}` : ''
      }`;

      const query = `merchantCallbackItems(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const querySet = `merchantCallbackParamSets(take:500, skip:0){items{id, ruleId, paramId} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);
      const dataSet = await api.getByGraphQl(querySet);

      const { setsItems } = ManageMerchantCallbacksService.getGQLResponseItemsSets(dataSet);
      const filterValuesSets = setsItems.filter((item) => item.ruleId === callbackId);

      const { callbacks } = ManageMerchantCallbacksService.getGQLResponseItems(data);

      this.setState({
        callbacks,
        isLoading: false,
        selectedItemsActive: filterValuesSets,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleDelete = () => {
    const { selectedItems } = this.state;

    this.setState({
      modalVariant: selectedItems.find(({ editId }) => editId === '-1') ? 'forbiddenDelete' : 'allowDelete',
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row className="ManageMerchantCallbacksGrid-toolbar">
        {checkElement('manageMerchantCallbacksGrid-addEdit', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addNewItemOpen: 'newItem' });
              }}
            >
              {translate('page.manageMerchantCallbacks.addNewParam')}
            </Button>
          </div>
        )}

        {checkElement('manageMerchantCallbacksGrid-delete', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ addNewItemOpen: 'editItem' });
              }}
            >
              {translate('page.manageMerchantCallbacks.changeName')}
            </Button>
          </div>
        )}

        {checkElement('manageMerchantCallbacksGrid-saveEdit', this.currentArray) && (
          <div className="ManageMerchantCallbacksGrid-buttonWrapper">
            <Button type="primary" disabled={!selectedItems.length} onClick={this.handleDelete}>
              {translate('page.manageMerchantCallbacks.deleteParam')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  onFieldsConfigChange = (list) => {
    this.fetchCallbackGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  changeCheckbox = async (addParToCall, paramId, id) => {
    const { translate, callbackId } = this.props;

    const query = addParToCall
      ? `merchantCallbackSetEntityAdd(input:{setEntity:{id: null, ruleId: ${callbackId}, paramId: ${paramId} }}){merchantCallbackParamSet{  id ruleId, paramId }}`
      : `merchantCallbackSetEntityDelete(input:{setEntity:{ id: ${id}, ruleId: ${callbackId}, paramId: ${paramId}}}){merchantCallbackParamSet{ id ruleId, paramId }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);

      await this.fetchCallbackGQL();
      this.setState({ isLoading: false });
      message.success(
        translate(
          addParToCall
            ? 'page.manageMerchantCallbacks.successfullyAddNewToCallback'
            : 'page.manageMerchantCallbacks.successfullyDeleteItemInCallback',
        ),
        2.5,
      );
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    await this.setState({ isLoading: false });
  };

  getCheckboxCell = ({ dataItem }) => {
    const { selectedItemsActive } = this.state;
    const currentItem = selectedItemsActive.find((item) => item.paramId === dataItem.id);

    return (
      <td align="center" style={{ textAlign: 'center' }} className="ManageMerchantCallbacksGrid-checkboxCell">
        <EditableGridInput
          type={'checkbox'}
          value={selectedItemsActive.some((item) => item.paramId === dataItem.id)}
          onChange={(value) => {
            this.changeCheckbox(value, dataItem.id, currentItem ? currentItem.id : null);
          }}
        />
      </td>
    );
  };

  render() {
    const {
      callbacks,
      isLoading,
      pageValue,
      hasNextPage,
      hasPreviousPage,
      modalVariant,
      selectedItems,
      addNewItemOpen,
    } = this.state;

    if (!checkElement('manageMerchantCallbacksGrid-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={callbacks}
          onRefresh={this.fetchCallbackGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="manageMerchantParametersGrid"
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          fieldForSelection="id"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            className="manageMerchantCallbacksGrid"
          />
          <GridColumn field="id" title="Id" width="150" filter="numeric" />
          <GridColumn field="name" title="Name" width="150" />
          <GridColumn field="isActive" title="IsActive" width="150" cell={this.getCheckboxCell} />
        </Grid>

        {modalVariant && (
          <DeleteItemModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchCallbackGQL}
            selectedItems={selectedItems}
          />
        )}
        {addNewItemOpen && (
          <FormStateContainer
            initialValues={{
              paramName: addNewItemOpen === 'newItem' ? '' : selectedItems[0].name,
              id: addNewItemOpen === 'newItem' ? '' : selectedItems[0].id,
            }}
          >
            <AddNewItemModal
              variant={addNewItemOpen}
              closeModal={() => {
                this.setState({ addNewItemOpen: false });
              }}
              onRefresh={this.fetchCallbackGQL}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(ManageMerchantParametersGrid);
