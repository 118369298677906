import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@progress/kendo-react-grid';
// import { Collapse } from 'antd';
import { CellDate, CellDetails, CellDropdown, CellLink } from '../cells';
import CellPhotos from '../../../pages/adminStockPhotos/cellPhotos';
import './mobile.scss';
import CellStockStatus from '../../../pages/adminStockPhotos/cellStockStatus';

// const { Panel } = Collapse;

export default class VirtualMobileGrid extends React.PureComponent {
  static defaultProps = {
    rowHeight: 36,
    rowCount: 20,
  };

  static propTypes = {
    data: PropTypes.any.isRequired,
    rowHeight: PropTypes.number,
    rowCount: PropTypes.number,
    titles: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }

  grid = React.createRef();

  state = {
    pageStep: 0,
    OffsetY: 0,
    showMobileFilters: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    const OffsetY = this.getOffsetY();
    this.setState({ OffsetY });

    this.setHeight();
    this.setMode();
    this.onScroll();
    // const storage = JSON.parse(localStorage.getItem('monitorActivity'));
    // const domElements = this.itemRef;
    // console.log('storage', storage);
    // const storageItem = JSON.parse(storage.value);
    // console.log(typeof storageItem);
    // console.log(storageItem);
    // console.log('domElements', domElements);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    const { data, mobileMode } = this.props;
    if (data !== prevProps.data) {
      this.setHeight();
    }
    if (mobileMode !== prevProps.mobileMode) {
      this.mobileUpdater();
    }
  }

  mobileUpdater = () => {
    const { triggerFilters } = this.props;
    // console.log(this.props);

    this.setHeight();
    this.setMode();
    triggerFilters();
  };

  onResize = () => {};

  setHeight = () => {
    const { data, rowHeight, titles } = this.props;
    const { mobileMode } = this.props;
    const div = this.grid.current.vs.container.firstChild;
    const items = Object.keys(titles).length;
    // without border = 32, with border = 33
    const padding = data.length * 32;
    const height = Math.max(data.length * rowHeight * items + padding, 600);
    if (mobileMode) {
      div.style.height = `${height}px`;
    } else {
      div.style.height = 'auto';
    }
  };

  getOffsetY = () => {
    const container = this.grid.current.vs.container;

    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = container.getBoundingClientRect();
    return elemRect.top - bodyRect.top;
  };

  onScroll = () => {
    const { OffsetY } = this.state;
    const { rowCount, rowHeight, data, titles } = this.props;

    const rowsHeight = rowCount * rowHeight * titles.length + rowCount * 32;

    const pageStep = Math.min(((window.pageYOffset - OffsetY) / rowsHeight) >> 0, (data.length / rowCount) >> 0);

    this.setState({ pageStep });
  };

  setMode = (mode) => {
    const container = this.grid.current.vs.container;
    const { mobileMode } = this.props;
    const table = container.querySelector('table');
    const tbody = container.querySelector('tbody');
    if (mobileMode) {
      table.style.display = 'block';
      table.style.maxWidth = '100%';
      tbody.style.display = 'block';
    } else {
      table.style.display = 'table';
      table.style.maxWidth = 'none';
      tbody.style.display = 'table-row-group';
    }
  };

  translate = (margin) => {
    const { mobileMode } = this.props;
    if (this.grid.current && mobileMode) {
      const table = this.grid.current.vs.container.querySelector('table');
      table.style.transform = `translateY(${margin}px)`;
    }
  };

  // renderDebug = () => {
  //   const {OffsetY, pageStep} = this.state;
  //   return (<div style={{
  //     height: '60px', width: '180px',
  //     backgroundColor: 'yellowgreen',
  //     position: 'absolute', top: '50px', right: '5px',
  //     zIndex: 999, padding: '5px'
  //   }}>
  //     <p>pageStep: {pageStep}</p>
  //     <p>OffsetY: {OffsetY}</p>
  //   </div>)
  // }

  cellRender = (item, dataItem) => {
    const { cell: Cell, onClick, filter, dropdown, handlers, tooltip, isPhotoCell, isTooltip } = item.props;

    if (filter === 'date') {
      return <CellDate {...item.props} mobile field={item.field} dataItem={dataItem} />;
    }

    if (onClick) {
      return <CellLink {...item.props} mobile field={item.field} dataItem={dataItem} />;
    }

    if (dropdown === true) {
      return <CellDropdown handlers={handlers} {...item.props} field={item.field} dataItem={dataItem} mobile />;
    }

    if (isPhotoCell === true) {
      return <CellPhotos {...item.props} field={item.field} dataItem={dataItem} mobile />;
    }

    if (isTooltip === true && tooltip === true) {
      return <CellStockStatus {...item.props} isTooltip={isTooltip} field={item.field} dataItem={dataItem} mobile />;
    }

    if (tooltip === true) {
      return <CellDetails mobile {...item.props} field={item.field} dataItem={dataItem} />;
    }

    if (!item.props.cell) {
      return dataItem[item.field];
    }

    return <Cell mobile {...item.props} field={item.field} dataItem={dataItem} />;
  };

  checkVisibility = (item, columnTree) => {
    let finalBool = true;
    for (const columnTreeElem of columnTree) {
      console.log('columnTreeElem', columnTreeElem);

      if (item.title === columnTreeElem.text && columnTreeElem.checked === false && columnTreeElem.items) {
        for (const child of columnTreeElem.items) {
          finalBool = false;
        }
      }
      if (columnTreeElem.items) {
        // finalBool = this.checkVisibility(item, columnTreeElem.items);
        for (const child of columnTreeElem.items) {
          if (item.title === child.text && child.checked === false) {
            finalBool = false;
          }
        }
      }

      if (item.title === columnTreeElem.text && columnTreeElem.checked === false) {
        finalBool = false;
      }
    }
    return finalBool;
  };

  renderClassName = (fieldValue) => {
    console.log('fieldValue', fieldValue);
    const { selectedItem } = this.props;
    console.log('selectedItem', selectedItem);
    // console.log(this.props);
    if (selectedItem instanceof Array) {
      if (selectedItem.includes(fieldValue)) {
        return 'row-active';
      } else {
        return '';
      }
    }
    if (Number(selectedItem) === selectedItem) {
      if (fieldValue === selectedItem) {
        return 'row-active';
      } else {
        return '';
      }
    } else {
      return;
    }
  };

  getBorderedClass = () => {
    const { gridName } = this.props;
    switch (gridName) {
      case 'ownerStockPhotos':
      case 'adminStockPhotos':
      case 'subAdminStockPhotos':
      case 'adminGeneralResults':
      case 'ownerStockGeneralResults':
        return 'row-bordered';
      default:
        return '';
    }
  };

  filterTitlesByColumnTree = (titles, columnTree) => {
    const columnTreeFields = columnTree.map((el) => el.field);
    console.log('columnTreeFields', columnTreeFields);
    const filteredTitles = titles.filter((el) => columnTreeFields.indexOf(el.field) !== -1);

    return filteredTitles;
  };

  checkVisibilityItem = (field, columTree) => {
    for (const el of columTree) {
      if (el.field === field) {
        return el.checked;
      }
    }
  };

  rowRender = (trElement, props) => {
    let backgroundColor;
    if (this.props.rowRender) {
      const element = this.props.rowRender(trElement, props);
      backgroundColor = element.props.style.background;
    }

    const { titles, rowHeight, rowRender, mobileMode, onRowClick, fieldForSelect, columnTree } = this.props;
    // console.log(props.dataItem.TerminalId);
    console.log('columnTree', columnTree);
    const filteredTitles = this.filterTitlesByColumnTree(titles, columnTree);
    // console.log('filteredTitles', filteredTitles);
    return (
      <div
        onClick={onRowClick ? (e) => onRowClick(e, props) : null}
        style={{
          background: rowRender ? backgroundColor : this.getIsAltBackground(props.isAltRow),
        }}
        className={`row-mobile ${
          // props.dataItem.TerminalId === selectedItem ? 'row-active' : ''
          this.renderClassName(props.dataItem[fieldForSelect])
        } ${this.getBorderedClass()}`}
      >
        {filteredTitles.map((item, i) => {
          console.log('item', item);

          return (
            <div
              key={`mobile-div-elem-${i.toString()}`}
              style={{ height: `${mobileMode && rowHeight}px` }}
              className={`item ${!this.checkVisibilityItem(item.field, columnTree) ? 'item-disable' : ''} `}
            >
              <span className="left">{item.title}</span>
              <span className="right">{this.cellRender(item, props.dataItem)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getIsAltBackground = (isAltRow) => {
    if (isAltRow) {
      return 'rgba(0, 0, 0, 0.04)';
    }
    return 'transparent';
  };
  // {this.renderRowMobile(props.dataItem, backgroundColor)}

  renderRowMobile = ({ OwnerId }, backgroundColor) => {
    let style = {
      height: '20px',
      backgroundColor,
      // borderBottom: '1px solid #000'
    };
    // if(backgroundColor) {
    //   style = {
    //      backgroundColor
    //   }
    // }
    return (
      <div key={`row-mobile-key-${OwnerId}`} style={style}>
        {OwnerId}
      </div>
    );
  };
  // handlePageChange = (event) => {
  //   this.setState({
  //     skip: event.page.skip,
  //     take: event.page.take,
  //     pageSizeValue: event.target.value ? event.target.value : this.state.pageSizeValue,
  //   });
  // };

  render() {
    const { rowHeight, rowCount, data, children, titles, rowRender, onColumnReorder, className, ...props } = this.props;
    const { pageStep, pageSize, pageSizeValue, skip, take } = this.state;
    const { mobileMode } = this.props;

    const start = Math.max((pageStep - 1) * rowCount, 0);
    const end = Math.min((pageStep + 2) * rowCount, data.length);
    const chunk = data.slice(start, end);

    const totalItems = data.length;
    const offset = start * rowHeight * titles.length + start * 32;
    if (mobileMode) {
      this.translate(offset);
    }

    return (
      <Grid
        {...props}
        ref={this.grid}
        data={mobileMode ? chunk : data}
        onColumnReorder={onColumnReorder}
        className={`baseMobileGrid ${className} ${mobileMode ? 'mobileMode' : 'mob-desc'}`}
        resizable={false}
        scrollable="scrollable"
        rowHeight={rowHeight}
        rowRender={mobileMode ? this.rowRender : rowRender}
        reorderable
      >
        {children}
      </Grid>
    );
  }
}
