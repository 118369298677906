import React, { PureComponent } from 'react';
import { message } from 'antd';

import SupplierProps from './SupplierProps';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import ServiceSuppliersService from '../ServiceSuppliersGrid/ServiceSuppliers.service';
import { PROPS_FIELDS } from './constants';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class SupplierPropsContainer extends PureComponent {
  state = {
    servicesSuppliersCeo: [],
    servicesSuppliersBeneficiary: [],
    regionList: [],
    finMonRiskList: [],
  };

  componentDidMount() {
    this.initialFetchingRequest();
  }

  initialFetchingRequest = async () => {
    await this.setState({ isLoading: true });
    await this.fetchSuppliersDropdownInfo();
    await this.fetchSupplierProps();
    await this.setState({ isLoading: false });
  };

  fetchSuppliersDropdownInfo = async () => {
    try {
      const servicesSuppliersCeo = await api.getServicesSuppliersCeo();
      const servicesSuppliersBeneficiary = await api.getServicesSuppliersBeneficiary();
      const regionList = await api.getRegions();
      const finMonRiskList = await api.getFinMonRiskList();
      this.setState({
        servicesSuppliersCeo,
        servicesSuppliersBeneficiary,
        regionList,
        finMonRiskList,
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  fetchSupplierProps = async () => {
    const {
      dataItem: { supplierId, name, okpo },
      setFormState,
      formState,
    } = this.props;

    try {
      const query = `suppliers(order:{supplierId:ASC},where:{and:[{supplierId:{eq:${supplierId}}}]}, take:50, skip:0){items{${PROPS_FIELDS.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const {
        supplierList: [
          {
            datePost,
            address,
            email,
            phone,
            fax,
            website,
            description,
            regionId,
            affiliationRar,
            finMonRiskId,
            suppliersCeoId,
            suppliersBeneficiaryId,
          },
        ],
      } = ServiceSuppliersService.getGQLResponse(data);

      setFormState({
        ...formState,
        supplierId,
        name,
        changeDate: new Date(datePost),
        EDRPOU: okpo,
        address,
        phone: phone ? phone : '',
        email,
        fax,
        webAddress: website,
        description,
        region: regionId,
        isAffiliation: affiliationRar,
        riskLevel: finMonRiskId,
        director: suppliersCeoId || suppliersCeoId === 0 ? [+suppliersCeoId] : [],
        beneficiary: suppliersBeneficiaryId ? suppliersBeneficiaryId.split(',').map((beneficiary) => +beneficiary) : [],
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  onSave = async () => {
    const {
      formState: {
        supplierId,
        name,
        EDRPOU,
        address,
        phone,
        email,
        fax,
        webAddress,
        description,
        region,
        isAffiliation,
        riskLevel,
        director,
        beneficiary,
      },
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.updateSupplierProps({
        supplierId,
        name,
        address,
        phone,
        fax,
        email,
        www: webAddress,
        description,
        regionId: region,
        affiliationRar: isAffiliation,
        idClient: EDRPOU,
        finMonRiskId: riskLevel || null,
        suppliersCeoId: director.length ? director[0] : null,
        suppliersBeneficiaryId: beneficiary.length ? beneficiary.join(',') : '',
      });

      await message.success(`${translate('page.serviceSupplier.propsSuccessfulSave')}`, 3, () =>
        window.location.reload(),
      );
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { formState, onChangeFormState, formErrors, onError } = this.props;
    const { isLoading, servicesSuppliersCeo, servicesSuppliersBeneficiary, regionList, finMonRiskList } = this.state;

    const isRequiredFieldEmpty = !formState.name || !formState.EDRPOU || !formState.region;
    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <>
        <SupplierProps
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          onError={onError}
          servicesSuppliersCeo={servicesSuppliersCeo}
          servicesSuppliersBeneficiary={servicesSuppliersBeneficiary}
          regionList={regionList}
          isActionDisabled={isRequiredFieldEmpty || isErrorExist}
          finMonRiskList={finMonRiskList}
          onSave={this.onSave}
        />

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SupplierPropsContainer);
