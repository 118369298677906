import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { tabService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import './modalWhiteListControl.scss';

class ModalWhiteListControl extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    owners: PropTypes.arrayOf(PropTypes.any),
  };

  constructor(props) {
    super(props);
    this.currentArray = findArray('whiteListAdmin');
  }

  renderTitle = () => {
    const { translate } = this.props;
    return (
      <div className="modal-whiteListControl-title">
        <h2>{translate('grids.whiteListAdmin.modal-control-h2')}</h2>
        <h3>{translate('grids.whiteListAdmin.modal-control-h3')}</h3>
      </div>
    );
  };

  onDelete = () => {
    const { closeModal, owners } = this.props;
    tabService.addTab({
      type: 'deleteWhiteList',
      dataItem: { owners },
    });
    closeModal();
  };

  onAdd = () => {
    const { closeModal, owners } = this.props;
    tabService.addTab({
      type: 'addWhiteList',
      dataItem: { owners },
    });
    closeModal();
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    return (
      <Modal
        title={this.renderTitle()}
        visible={visible}
        centered
        className="modal-system-encashment"
        zIndex={'1053'}
        onCancel={closeModal}
        footer={[
          checkElement('whiteListAdmin-btn-delete', this.currentArray) && (
            <Button key="back" onClick={this.onDelete}>
              {translate('grids.whiteListAdmin.modal-control-btn-delete')}
            </Button>
          ),
          checkElement('whiteListAdmin-btn-add', this.currentArray) && (
            <Button key="submit" type="primary" onClick={this.onAdd}>
              {translate('grids.whiteListAdmin.modal-control-btn-add')}
            </Button>
          ),
        ]}
      >
        <p></p>
      </Modal>
    );
  }
}

export default withTranslate(ModalWhiteListControl);
