import React, { PureComponent } from 'react';
import GridEncashmentTerminals from './GridEncashmentTerminals';
import PageTabs from '../../components/tabber/tabber';
import GridEncashmentHistory from './GridEncashmentHistory';
import GridTerminalCashList from './GridTerminalCashList';
import GridSendingCheck from './GridSendingCheck';
import { withTranslate } from '../../contexts/localContext';

class PageEncashmentTerminals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.encashment.tab-terminals',
          content: <GridEncashmentTerminals />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'encashmentHistory':
        const { OwnerId, TerminalId } = dataItem;
        tabs.push({
          title: `${translate('page.encashment.tab-encashmentHistory')}`,
          content: <GridEncashmentHistory ownerId={OwnerId} terminalId={TerminalId} />,
          closable: true,
          key: key,
        });
        break;
      case 'terminalCashList':
        const { TerminalId: terminalId } = dataItem;
        tabs.push({
          title: 'page.encashment.tab-terminalCashList',
          content: <GridTerminalCashList terminalId={terminalId} />,
          closable: true,
          key: key,
        });
        break;
      case 'encashmentSendingCheck':
        tabs.push({
          title: 'grids.encashmentSendingCheck.tab-sendCheck-title',
          content: <GridSendingCheck />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageEncashmentTerminals);
