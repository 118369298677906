const createTerminal = (translate, value) => {
  switch (value) {
    case 'createTerminal-btn-create':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.createTerminal.btn-create')}`;
    case 'createTerminal-btn-change-status':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.createTerminal.btn-change-status')}`;
    case 'createTerminal-setSchedule':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.contacts.label-schedule')}`;
    case 'createTerminal-DesignTypeName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-designType')}`;
    case 'createTerminal-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-city')}`;
    case 'createTerminal-Code':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-certificateCode')}`;
    case 'createTerminal-FiscalNumber':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-fiscalNumber')}`;
    case 'createTerminal-CommissionProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-profileComissions')}`;
    case 'createTerminal-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-dateCreation')}`;
    case 'createTerminal-MainProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.profilesMainProfileList.column-profileId')}`;
    case 'createTerminal-MainProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-profileScreen')}`;
    case 'createTerminal-Name':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-name')}`;
    case 'createTerminal-NetworkContractName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-networkContract')}`;
    case 'createTerminal-ProjectName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-project')}`;
    case 'createTerminal-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-region')}`;
    case 'createTerminal-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-status')}`;
    case 'createTerminal-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-address')}`;
    case 'createTerminal-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.createTerminal.column-terminalId')}`;
    case 'createTerminal-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
      ${translate('grids.terminals.column-branch')}`;
    case 'createTerminal-Note':
      return `${translate(`page.adminPanel.type-column`)}:
                 ${translate('page.terminalSettings.field-notes')}`;
    case 'createTerminal-terminalIdOnclick':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.newTerminals.tab-details')}`;
    case 'newTerminal-btn-create':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.newTerminals.tab-createNew')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('cell-dropdown.create')}`;
    case 'newTerminal-show-additional':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.newTerminals.tab-createNew')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('page.terminalSettings.btn-additionalSettings')}`;
    case 'terminalDetails-on-submit':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.newTerminals.tab-details')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('page.screenControlBusinessServices.button-edit')}`;
    case 'terminalDetails-show-additional':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.newTerminals.tab-details')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('page.terminalSettings.btn-additionalSettings')}`;
    case 'createTerminal-fiscalizationInventory':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-block`)}:
              ${translate('page.terminalSettings.fiscalizationInventory-title')}`;
    case 'createTerminal-fiscalNumberInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-fiscalNumber')}`;
    case 'createTerminal-businessUnitInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-GOIdentifier')}`;
    case 'createTerminal-inventoryNumberInput':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
              ${translate(`page.adminPanel.type-field`)}:
              ${translate('page.terminalSettings.field-inventoryNumber')}`;
    case 'terminalDetails-registration':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
        ${translate('page.adminPanel.type-button')}:${translate('core.buttonTitles.registration')}`;
    case 'terminalDetails-unregistration':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
        ${translate('page.adminPanel.type-button')}:${translate('core.buttonTitles.deregistration')}`;
    case 'terminalDetails-status-application':
      return `${translate(`page.adminPanel.type-tab`)}: ${translate('page.newTerminals.tab-details')},
        ${translate('page.adminPanel.type-button')}:${translate('core.buttonTitles.get-status')}`;
    default:
      return value;
  }
};

export default createTerminal;
