import React, { Component } from 'react';
import { Row, Col, Select, InputNumber, Button } from 'antd';
import PropTypes from 'prop-types';
import { withTranslate } from '../../../contexts/localContext';
import './changeComission.scss';

const { Option } = Select;

class IntervalComission extends Component {
  static defaultProps = {
    data: [],
    receivedData: [],
  };
  static propTypes = {
    data: PropTypes.array,
    fixedData: PropTypes.array,
    updateIntervalComission: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data,
      amountMin: 0.01,
      // amountMax: 4999.99
      amountMax: 5000,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.length === 0 && this.props.data !== this.state.data) {
      this.setState({
        data: this.props.data,
        amountMin:
          this.props.data && this.props.data[0] && this.props.data.length > 0 ? this.props.data[0].AmountMin : 0.01,
        amountMax:
          this.props.data && this.props.data[0] && this.props.data.length > 0
            ? this.props.data[0].AmountMax
            : //  4999.99
              5000,
      });
    }
  }

  checkAddBtnDisable = (id) => {
    const { data, amountMax } = this.state;
    const elem = data.find((el) => el.id === id);
    if (id === 1 && data.length > 1) return true;
    if (data.find((el) => el.id === id + 1)) return true;
    if (elem.to >= amountMax || elem.from >= elem.to || data.length >= 6) return true;
    return false;
  };

  onChangeInputs = (value, name, id) => {
    let upData;
    this.setState(
      ({ data }) => {
        const findIdx = data.findIndex((el) => el.id === id);
        const elem = data[findIdx];
        const newElem = {
          ...elem,
          [name]: value,
        };
        upData = [...data.slice(0, findIdx), newElem, ...data.slice(findIdx + 1)];
        return {
          data: upData,
        };
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  onChangeComClient = (comClient, id) => {
    let upData;
    const newDealerComission = comClient * -0.3;
    this.setState(
      ({ data }) => {
        const findIdx = data.findIndex((el) => el.id === id);
        const elem = data[findIdx];
        const newElem = {
          ...elem,
          comClient,
          comDealer: newDealerComission,
        };
        upData = [...data.slice(0, findIdx), newElem, ...data.slice(findIdx + 1)];
        return {
          data: upData,
        };
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  onChangeMinClient = (minComClient, id) => {
    let upData;
    this.setState(
      ({ data }) => {
        const findIdx = data.findIndex((el) => el.id === id);
        const elem = data[findIdx];
        const fixedElem = this.props.fixedData.find((el) => el.ClientCommissionAmount === minComClient);
        const newElem = {
          ...elem,
          minComClient,
          minComDealer: fixedElem.DealerCommissionAmount,
        };
        upData = [...data.slice(0, findIdx), newElem, ...data.slice(findIdx + 1)];
        return {
          data: upData,
        };
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  onChangeTo = (to, id) => {
    let upData;
    this.setState(
      ({ data }) => {
        const findIdx = data.findIndex((el) => el.id === id);
        const elem = data[findIdx];
        /*
         * Если есть следующий ряд с комиссиями,
         * то при изменении элемента "до",
         * я соответственно должен изменить
         * значение элемента "от" в следующем ряду,
         * если таковой ряд конечно же существует
         */

        const newElem = {
          ...elem,
          to,
        };

        if (data[findIdx + 1]) {
          const newNextElem = {
            ...data[findIdx + 1],
            from: to < this.state.amountMax - 0.01 ? to + 0.01 : to,
          };

          upData = [...data.slice(0, findIdx), newElem, newNextElem, ...data.slice(findIdx + 2)];

          return {
            data: upData,
          };
        } else {
          upData = [...data.slice(0, findIdx), newElem, ...data.slice(findIdx + 1)];
          return {
            data: upData,
          };
        }
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  addNewElem = (id) => {
    let upData;
    this.setState(
      ({ data }) => {
        const findElem = data.find((el) => el.id === id);
        const newElem = {
          ...findElem,
          // minComClient: 0,
          // minComDealer: 0,
          id: findElem.id + 1,
          from: findElem.to + 0.01,
          comClient: 0,
          comDealer: 0,
          to: this.state.amountMax,
        };
        upData = [...data, newElem];
        return {
          data: upData,
        };
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  removeElem = (id) => {
    let upData;
    this.setState(
      ({ data }) => {
        const findIndex = data.findIndex((el) => el.id === id);
        if (data[findIndex + 1]) {
          const newNextElem = {
            ...data[findIndex + 1],
            from: data[findIndex - 1].to + 0.01,
            id: data[findIndex].id,
          };
          upData = [...data.slice(0, findIndex), newNextElem, ...data.slice(findIndex + 2)];
          return {
            data: upData,
          };
        } else {
          upData = [...data.slice(0, findIndex), ...data.slice(findIndex + 1)];
          return {
            data: upData,
          };
        }
      },
      () => this.props.updateIntervalComission(upData),
    );
  };

  render() {
    const { translate, fixedData, isDisabled } = this.props;
    const { data, amountMin, amountMax } = this.state;
    return (
      <>
        {data.map((el) => {
          return (
            <Row
              type="flex"
              align="middle"
              key={`interval-comission-row-${el.id}`}
              className={`interval-comission ${isDisabled}`}
            >
              <Col xs={24} lg={8}>
                <Row gutter={24} className="row-md-mt-20">
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Row type="flex" align="middle">
                      <p>{translate('page.aap-changeComission.interval-from')}:</p>
                      <InputNumber
                        min={amountMin}
                        max={amountMax}
                        disabled
                        // className="fixedComission--select"
                        value={el.from}
                        onChange={(from) => this.onChangeInputs(from, 'from', el.id)}
                        precision={2}
                        step="0.1"
                        className={`interval-inputNumber`}
                      />
                      <p className="interval-afterInputText">
                        {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                      </p>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Row type="flex" align="middle">
                      <p>{translate('page.aap-changeComission.interval-to')}:</p>
                      <InputNumber
                        min={amountMin}
                        max={amountMax}
                        // className="fixedComission--select"
                        value={el.to}
                        onChange={(to) => this.onChangeTo(to, el.id)}
                        precision={2}
                        step="0.1"
                        className={`interval-inputNumber ${el.to <= el.from ? 'errorInputNumber' : ''}`}
                      />
                      <p className="interval-afterInputText">
                        {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={16}>
                <Row className="fixedComission-wrapper" type="flex" align="middle" justify="space-between" gutter={24}>
                  <Col xs={24} lg={12}>
                    <Row type="flex" align="middle" justify="space-between" className="row-md-mt-20" gutter={24}>
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <Row type="flex" align="middle" style={{ width: '100%' }}>
                          <p>{translate('grids.addingAddressedPaymentApplications.column-commissionClient')}, %</p>
                          <InputNumber
                            min={0}
                            className="fixedComission--select"
                            value={el.comClient}
                            onChange={(comClient) => this.onChangeComClient(comClient, el.id)}
                            precision={1}
                            step="0.1"
                          />
                          {/* <p className="interval-afterInputText">%</p> */}
                        </Row>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <Row type="flex" align="middle">
                          <p>
                            min,
                            {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                          </p>
                          <Select
                            value={el.minComClient}
                            className="fixedComission--select"
                            dropdownStyle={{ zIndex: '1054', width: '100%' }}
                            onChange={(minComClient) => this.onChangeMinClient(minComClient, el.id)}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch
                          >
                            {fixedData.map((el) => {
                              return (
                                <Option
                                  key={`intervalComission-client-${el.ClientCommissionAmount}`}
                                  value={el.ClientCommissionAmount}
                                >
                                  {el.ClientCommissionAmount}
                                </Option>
                              );
                            })}
                          </Select>
                          {/* <p className="interval-afterInputText">
                            {translate('grids.addingAddressedPaymentApplications.changeComission-currency')}.
                          </p> */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}></Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row type="flex" align="middle">
                  <Col xs={12}>
                    <Button
                      type="primary"
                      shape="circle"
                      disabled={this.checkAddBtnDisable(el.id)}
                      icon="plus"
                      size="large"
                      style={{ marginTop: '10px' }}
                      onClick={() => this.addNewElem(el.id)}
                    />
                  </Col>
                  <Col xs={12}>
                    <Row type="flex" align="middle" justify="end">
                      {el.id !== 1 && (
                        <Button
                          type="primary"
                          shape="circle"
                          icon="minus"
                          size="large"
                          style={{ marginTop: '10px' }}
                          onClick={() => this.removeElem(el.id)}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </>
    );
  }
}

export default withTranslate(IntervalComission);
