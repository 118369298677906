export const MESSAGES_TRANSCRIPTIONS_MAP = new Map([
  ['TRUSTED_DEVICE_NOT_USE_LIGHT_LOGIN', 'errorMessages.deviceLinkedAnotherUser'],
  ['PASSWORD_EXPIRED_ERROR', 'errorMessages.password_expired'],
  ['REFRESH_TOKEN_NOT_FOUND', 'errorMessages.sessionEnd'],
  ['PASSWORD_INVALID', 'errorMessages.userDataInvalid'],
  ['USER_NAME_INVALID', 'errorMessages.userDataInvalid'],
  ['USER_NAME_OR_PASSWORD_INVALID', 'errorMessages.userDataInvalid'],
  ['Empty refresh_token', 'errorMessages.sessionEnd'],
]);

export const FIELD_NAME_BY_LOCALE_MAP = new Map([
  ['uk', 'UA'],
  ['ru', 'RU'],
  ['en', 'EN'],
]);
