import React, { PureComponent } from 'react';
import { Button, Radio, Modal, message, Card } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import CustomInput from '../../../../components/Input/Input';
import UpdateModalService from './updateModalService';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { configs, translateSlugs, defaultTitleTranslateSlugs } from './constants';
import LoadingPanel from '../../../../components/loader';
import { ApiService } from '../../../../services';

import './UpdateModal.scss';
import { INITIAL_VALUES } from './initialValues';

const api = new ApiService();

const formatValue = (value) => {
  if (typeof value === 'string' && value.includes(',')) {
    return value.replace(',', '.');
  }
  return +value;
};

class UpdateModal extends PureComponent {
  state = {
    isLoading: false,
    services: [],
  };

  componentDidMount() {
    const { selectedItems, setFormState, modalVariant } = this.props;

    if (modalVariant === 'updateLimit') {
      setFormState({
        services: [],
        serviceGroups: [],
        authorizationTypes: [],
        transactionMaxLimit: selectedItems[0].TransactionMaxLimit,
        dailyLimit: selectedItems[0].DailyLimit,
        monthlyLimit: selectedItems[0].MonthlyLimit,
        transactionMinLimit: selectedItems[0].TransactionMinLimit,
        dailyLimitPayer: selectedItems[0].DailyLimitPayer,
        monthlyLimitPayer: selectedItems[0].MonthlyLimitPayer,
        transactionMaxLimitPayer: selectedItems[0].TransactionMaxLimitPayer,
        dailyTransactionsCountLimit: selectedItems[0].DailyTransactionsCountLimit,
        monthlyLimitWithoutOtp: selectedItems[0].MonthlyLimitWithoutOtp,
        finMonRecipient: selectedItems[0].FinmonRecepient ? 'true' : 'false',
        finMonPayer: selectedItems[0].FinmonPayer ? 'true' : 'false',
        ownAccount: selectedItems[0].OwnAccount ? 'true' : 'false',
        checkLimitPayer: selectedItems[0].CheckLimitPayer ? 'true' : 'false',
      });
    }

    if (modalVariant === 'addLimit') {
      setFormState({ ...INITIAL_VALUES, ownAccount: 'false', checkLimitPayer: 'true' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { formState, onError, translate } = this.props;
    const { formState: prevFormState } = prevProps;
    const { dailyLimit, monthlyLimit, monthlyLimitWithoutOtp } = formState;
    if (prevFormState !== formState) {
      if (
        formatValue(monthlyLimitWithoutOtp) > formatValue(dailyLimit) ||
        formatValue(monthlyLimitWithoutOtp) > formatValue(monthlyLimit)
      ) {
        onError('global', translate('page.commissionsServices.monthlyLimit'));
      } else {
        onError('global', '');
      }
    }
  }

  renderNumberInput = (name) => {
    const { translate, formState, onChangeFormState, formErrors, onError, modalVariant } = this.props;
    return (
      <div className="UpdateAuthorizationLimitModal-inputRow ">
        {translateSlugs[name] && (
          <div className="UpdateAuthorizationLimitModal-label">{translate(translateSlugs[name])}</div>
        )}
        <div className="UpdateAuthorizationLimitModal-input">
          <CustomInput
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            setError={onError}
            validators={
              modalVariant === 'updateLimits'
                ? UpdateModalService.getValidatorsByMassChange(translate)
                : UpdateModalService.getValidatorsByAdd(translate)
            }
            name={name}
          />
        </div>
      </div>
    );
  };

  renderRadiobuttonRow = (name, isLongLabel) => {
    const { translate, formState, onChangeFormState } = this.props;

    return (
      <div className="UpdateAuthorizationLimitModal-inputRow">
        {translateSlugs[name] && (
          <div
            className={`${
              isLongLabel ? 'UpdateAuthorizationLimitModal-longLabel' : 'UpdateAuthorizationLimitModal-label'
            }`}
          >
            {translate(translateSlugs[name])}:
          </div>
        )}

        <div className="UpdateAuthorizationLimitModal-input UpdateAuthorizationLimitModal-radioInput">
          <Radio.Group
            value={formState[name]}
            onChange={({ target: { value } }) => {
              onChangeFormState(name, value);
            }}
          >
            <Radio key="radio-turnedOff" value={'true'}>
              {translate('core.buttonTitles.yes')}
            </Radio>

            <Radio key="radio-turnedOn" value={'false'}>
              {translate('core.buttonTitles.no')}
            </Radio>
          </Radio.Group>
        </div>
      </div>
    );
  };

  fetchServices = async () => {
    const { formState, returnFetchedServices } = this.props;

    if (!formState.nbuCodes.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });

      const services = await returnFetchedServices(formState.nbuCodes);

      await this.setState({
        services: services,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getOnSelectChange = (name) => async (selectItems) => {
    const { onChangeFormState } = this.props;

    await onChangeFormState(name, selectItems);

    if (name === 'nbuCodes') {
      await onChangeFormState('services', []);
      await this.fetchServices();
    }
  };

  renderSelect = (name) => {
    const { formState, translate, dropdownsData } = this.props;
    const { services } = this.state;

    return (
      <div className="UpdateAuthorizationLimitModal-inputRow">
        <div className="UpdateAuthorizationLimitModal-label">{translate(translateSlugs[name])}</div>
        <div className="UpdateAuthorizationLimitModal-select">
          <GridDropdown
            data={{ ...dropdownsData, services }[name]}
            colConfig={configs[name]}
            selectItems={formState[name]}
            onSave={this.getOnSelectChange(name)}
            defaultTitle={translate(defaultTitleTranslateSlugs[name])}
            isSingle
            disabled={name === 'services' && !formState.nbuCodes.length}
          />
        </div>
      </div>
    );
  };

  renderChangeLimitFields = () => {
    const { translate, formErrors } = this.props;
    const isMobile = window.innerWidth < 460;

    return (
      <>
        <Card type="inner" className="UpdateAuthorizationLimitModal-card">
          <div className="UpdateAuthorizationLimitModal-flexRow">
            {this.renderRadiobuttonRow('finMonPayer')}
            {this.renderRadiobuttonRow(isMobile ? 'finMonRecipient' : 'ownAccount', !isMobile)}
            {this.renderRadiobuttonRow(isMobile ? 'ownAccount' : 'finMonRecipient', isMobile)}
            {this.renderRadiobuttonRow('checkLimitPayer', true)}
          </div>
        </Card>

        <div className="UpdateAuthorizationLimitModal-limitContent">
          <div className="UpdateAuthorizationLimitModal-innerCardWrapper">
            <Card title={translate('page.servicesAuthorizationLimits.recipient')} type="inner">
              {this.renderNumberInput('transactionMaxLimit')}
              {this.renderNumberInput('dailyLimit')}
              {this.renderNumberInput('monthlyLimit')}
              {this.renderNumberInput('dailyTransactionsCountLimit')}
              {this.renderNumberInput('monthlyLimitWithoutOtp')}
              {formErrors && formErrors.global && (
                <div className="UpdateAuthorizationLimitModal-error">{formErrors.global}</div>
              )}
            </Card>
          </div>

          <div className="UpdateAuthorizationLimitModal-innerCardWrapper">
            <Card title={translate('page.servicesAuthorizationLimits.payer')} type="inner">
              {this.renderNumberInput('transactionMaxLimitPayer')}
              {this.renderNumberInput('dailyLimitPayer')}
              {this.renderNumberInput('monthlyLimitPayer')}
            </Card>
          </div>
        </div>

        <Card type="inner" style={{ marginTop: '12px' }}>
          {this.renderNumberInput('transactionMinLimit')}
        </Card>
      </>
    );
  };

  renderAddLimitFields = () => (
    <>
      <Card type="inner" className="UpdateAuthorizationLimitModal-card">
        <div className="UpdateAuthorizationLimitModal-flexRow">
          {this.renderSelect('nbuCodes')}
          {this.renderSelect('services')}
          {this.renderSelect('authorizationTypes')}
        </div>
      </Card>

      {this.renderChangeLimitFields()}
    </>
  );

  calcButtonDisable = () => {
    const { formState, formErrors, modalVariant } = this.props;
    const isEmptyValueExist =
      modalVariant !== 'updateLimits' &&
      Object.entries(formState).some(([key, value]) => {
        return modalVariant === 'addLimit' && value && (value.length || value.length === 0) && key !== 'services'
          ? !value.length
          : !value && value !== 0;
      });

    const isErrorExist = Object.values(formErrors).some((value) => value);

    return isEmptyValueExist || isErrorExist;
  };

  castToNumber = (value) => +`${value}`.replace(',', '.');

  addLimit = async () => {
    const { formState, closeModal, onRefresh, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.addServiceAuthorizationLimits({
        id: null,
        serviceId: formState.services.length ? formState.services[0] : 0,
        authorizationId: formState.authorizationTypes[0],
        f108: formState.nbuCodes[0],
        finmonPayer: formState.finMonPayer === 'true',
        finmonRecepient: formState.finMonRecipient === 'true',
        transactionMinLimit: this.castToNumber(formState.transactionMinLimit),
        transactionMaxLimit: this.castToNumber(formState.transactionMaxLimit),
        dailyLimit: this.castToNumber(formState.dailyLimit),
        monthlyLimit: this.castToNumber(formState.monthlyLimit),
        transactionMaxLimitPayer: this.castToNumber(formState.transactionMaxLimitPayer),
        monthlyLimitPayer: this.castToNumber(formState.monthlyLimitPayer),
        dailyLimitPayer: this.castToNumber(formState.dailyLimitPayer),
        dailyTransactionsCountLimit: this.castToNumber(formState.dailyTransactionsCountLimit),
        monthlyLimitWithoutOtp: this.castToNumber(formState.monthlyLimitWithoutOtp),
        ownAccount: formState.ownAccount === 'true',
        checkLimitPayer: formState.checkLimitPayer === 'true',
      });

      closeModal();
      this.setState({ isLoading: false });
      message.success(translate('page.servicesAuthorizationLimits.limitAddedSuccessfully'), 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  changeLimit = async () => {
    const { formState, closeModal, onRefresh, translate, selectedItems, modalVariant } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.updateServiceAuthorizationLimits(
        selectedItems.map(({ Id, ServiceId, AuthorizationId, F108 }) => ({
          id: Id || (Id === 0 ? 0 : null),
          serviceId: ServiceId || (ServiceId === 0 ? 0 : null),
          authorizationId: AuthorizationId || (AuthorizationId === 0 ? 0 : null),
          f108: F108 || (F108 === 0 ? 0 : null),
          finmonPayer: formState.finMonPayer ? formState.finMonPayer === 'true' : null,
          finmonRecepient: formState.finMonRecipient ? formState.finMonRecipient === 'true' : null,
          ownAccount: formState.ownAccount ? formState.ownAccount === 'true' : null,
          checkLimitPayer: formState.checkLimitPayer ? formState.checkLimitPayer === 'true' : null,
          transactionMinLimit: `${formState.transactionMinLimit}`.trim()
            ? this.castToNumber(formState.transactionMinLimit)
            : null,
          transactionMaxLimit: `${formState.transactionMaxLimit}`.trim()
            ? this.castToNumber(formState.transactionMaxLimit)
            : null,
          dailyLimit: `${formState.dailyLimit}`.trim() ? this.castToNumber(formState.dailyLimit) : null,
          monthlyLimit: `${formState.monthlyLimit}`.trim() ? this.castToNumber(formState.monthlyLimit) : null,
          dailyLimitPayer: `${formState.dailyLimitPayer}`.trim() ? this.castToNumber(formState.dailyLimitPayer) : null,
          monthlyLimitPayer: `${formState.monthlyLimitPayer}`.trim()
            ? this.castToNumber(formState.monthlyLimitPayer)
            : null,
          transactionMaxLimitPayer: `${formState.transactionMaxLimitPayer}`.trim()
            ? this.castToNumber(formState.transactionMaxLimitPayer)
            : null,
          dailyTransactionsCountLimit: `${formState.dailyTransactionsCountLimit}`.trim()
            ? this.castToNumber(formState.dailyTransactionsCountLimit)
            : null,
          monthlyLimitWithoutOtp: `${formState.monthlyLimitWithoutOtp}`.trim()
            ? this.castToNumber(formState.monthlyLimitWithoutOtp)
            : null,
        })),
      );

      closeModal();
      this.setState({ isLoading: false });
      message.success(
        translate(
          modalVariant === 'updateLimit'
            ? 'page.servicesAuthorizationLimits.limitChangedSuccessfully'
            : 'page.servicesAuthorizationLimits.limitsChangedSuccessfully',
        ),
        2,
      );
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getTitle = () => {
    const { modalVariant, translate } = this.props;

    if (modalVariant === 'addLimit') {
      return translate('page.servicesAuthorizationLimits.addingAuthorizationLimit');
    }

    if (modalVariant === 'updateLimit') {
      return translate('page.servicesAuthorizationLimits.changeAuthorizationLimit');
    }

    return translate('page.servicesAuthorizationLimits.massChangeAuthorizationLimit');
  };

  render() {
    const { modalVariant, closeModal, translate } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={modalVariant}
        title={this.getTitle()}
        onCancel={() => closeModal(false)}
        className="UpdateAuthorizationLimitModal"
        footer={[
          <Button
            type="primary"
            disabled={this.calcButtonDisable()}
            loading={isLoading}
            key="add"
            onClick={modalVariant === 'addLimit' ? this.addLimit : this.changeLimit}
          >
            {translate(modalVariant === 'addLimit' ? 'core.buttonTitles.add' : 'core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={closeModal} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="UpdateAuthorizationLimitModal-content">
          {modalVariant === 'addLimit' ? this.renderAddLimitFields() : this.renderChangeLimitFields()}
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
