export const getRequiredValidators = (translate) => (value) => {
  return value ? '' : translate('page.commissionsServices.dropdownRequiredError');
};

export const getValidateFieldLength = (translate) => (value) => {
  return value && value.length >= 6 && value.length <= 50 ? '' : translate('page.addNewUser.fieldLength');
};

export const getLoginValidator = (translate) => (login) => {
  const requiredError = getRequiredValidators(translate)(login);
  if (requiredError) return requiredError;
  const checkFieldLength = getValidateFieldLength(translate)(login);
  if (checkFieldLength) return checkFieldLength;

  return '';
};

export const getEmailValidator = (translate) => (email) => {
  const requiredError = getRequiredValidators(translate)(email);
  if (requiredError) return requiredError;
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? '' : translate('page.userProperties.emailValidateFormat');
};

export const getPhoneValidate = (translate) => (phone) => {
  const requiredError = getRequiredValidators(translate)(phone);
  if (requiredError) return requiredError;

  return phone.replace(/\D+/g, '').length < 10 ? translate('page.userProperties.phoneValidate') : '';
};
