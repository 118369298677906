class SuppliersBalanceGridService {
  getIntegerNumberValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (isNaN(+value) || !Number.isInteger(+value)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    return '';
  };

  getServiceStringValidation = (translate) => (value) => {
    console.log('value', value);

    if (!value) {
      return '';
    }

    if (
      value
        .split(',')
        .some((service) => !service || service.includes('.') || this.getIntegerNumberValidation(translate)(service))
    ) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    return '';
  };

  getValidateLimit = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (isNaN(value)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (`${value}`.includes('.') && `${value}`.split('.')[1].length > 2) {
      return translate('page.finMonLimits.error-two-characters');
    }

    if (+value < -1000000000 || +value > 1000000000) {
      return `${translate('page.suppliersBalance.limitRangeError')}`;
    }

    return '';
  };

  getValidators = (translate) => ({
    ServiceId: this.getIntegerNumberValidation(translate),
    Index: this.getIntegerNumberValidation(translate),
    Limit: this.getValidateLimit(translate),
    GateServicesList: this.getServiceStringValidation(translate),
  });
}

export default new SuppliersBalanceGridService();
