export const ALL_GRID_COLUMNS = [
  'profileId',
  'profileName',
  'parentProfileId',
  'profileLevel',
  'menuId',
  'menuName',
  'serviceId',
  'refId',
  'name',
  'value',
  'language',
  'type',
  'system',
];
