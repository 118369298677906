import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import './DealerCommissionsTerminalsGrid.scss';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

const api = new ApiService();

class UpdateStatusModal extends PureComponent {
  state = {
    isLoading: false,
  };

  handleChange = async (isSetActive) => {
    const { selectedItems, closeModal, onRefresh, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.changeServiceMenuStatus(
        selectedItems.map((id) => ({
          isActive: isSetActive,
          serviceId: id.split('-')[2],
          terminalId: id.split('-')[1],
        })),
      );

      this.setState({ isLoading: false });
      closeModal();
      message.success(`${translate('page.dealerCommissionsTerminals.statusesSuccessfullyChanged')}`, 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={translate('page.servicesAuthorizationLimits.changeStatus')}
        className="DealerCommissionsTerminalsUpdateAuthorizationLimitModal"
        footer={[
          <Button
            type="primary"
            key="activate"
            onClick={() => {
              this.handleChange(true);
            }}
          >
            {translate('page.servicesAuthorizationLimits.activate')}
          </Button>,
          <Button
            key="deactivate"
            onClick={() => {
              this.handleChange(false);
            }}
          >
            {translate('page.servicesAuthorizationLimits.deactivate')}
          </Button>,
        ]}
      >
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateStatusModal);
