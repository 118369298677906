export const INITIAL_VALUES = {
  DealerCommissionPercent: 0,
  DealerCommissionMin: 0,
  DealerCommissionMax: 0,
  DealerCommissionClientPercent: 0,
  DealerCommissionClientMin: 0,
  DealerCommissionClientMax: 0,
  PaymentSystemCommissionPercent: 0,
  PaymentSystemCommissionMin: 0,
  PaymentSystemCommissionMax: 0,
  PaymentSystemCommissionClientPercent: 0,
  PaymentSystemCommissionClientMin: 0,
  PaymentSystemCommissionClientMax: 0,
};
