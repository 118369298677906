const serviceGroupsDictionary = (translate, value) => {
  switch (value) {
    case 'serviceGroupsDictionary-content':
      return `${translate('page.adminPanel.pageContent')}`;
    case 'serviceGroupsDictionary-addItem':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'serviceGroupsDictionary-deleteItem':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
    case 'serviceGroupsDictionary-editItem':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    default:
      return;
  }
};

export default serviceGroupsDictionary;
