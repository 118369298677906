import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

class ServicesAuthorizationLimitsGridService {
  normalizeNBUCodes = (nbuCodes) => {
    if (!nbuCodes || !nbuCodes.length) {
      return [];
    }

    return nbuCodes.map(({ F108Code, ...restProps }) => ({ Id: F108Code, Name: F108Code, ...restProps }));
  };

  normalizeAuthorizationLimits = (authorizationLimits) => {
    if (!authorizationLimits || !authorizationLimits.length) {
      return [];
    }

    return authorizationLimits.map(({ DateFrom, ...restProps }) => ({
      DateFrom: getDataByTimezoneOffset(DateFrom),
      ...restProps,
    }));
  };
}

export default new ServicesAuthorizationLimitsGridService();
