import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../services';
import TemplateServicesGridServices from './templateServicesGrid.services';
import { Button, message, Row } from 'antd';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { MANAGER_COLUMN_CONFIG, TEMPLATES_DROPDOWN_CONFIG } from '../../../core/constants/configs.const';
import { SERVICE_GROUPS_CONFIG } from './constants';
import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import UpdateModal from './UpdateModal';

import './TemplateServicesGrid.scss';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

class TemplateServicesGrid extends PureComponent {
  currentArray = findArray('servicesTemplate');
  state = {
    managers: [],
    selectManagers: [],
    serviceGroups: [],
    selectServiceGroups: [],
    templateList: [],
    selectTemplates: [null],
    isLoading: false,
    templateServiceList: [],
    selectedItems: [],
    visibleDialogType: '',
  };

  getNotAssignedTemplateItem = () => {
    const { translate } = this.props;

    return {
      Id: null,
      Name: translate('page.serviceTemplates.notAssigned'),
      DatePost: null,
    };
  };

  componentDidMount() {
    this.initializeFetch();
  }

  initializeFetch = async () => {
    await this.setState({ isLoading: true });
    await this.fetchTemplateList();
    await this.fetchGroupServices();
    await this.fetchManagers();
    await this.setState({ isLoading: false });
  };

  fetchTemplateList = async () => {
    try {
      const templateList = await api.getTemplateList();

      this.setState({
        templateList: [
          this.getNotAssignedTemplateItem(),
          ...DropdownNormalizersService.normalizeTemplateList(templateList),
        ],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchGroupServices = async () => {
    try {
      const servicesGroups = await api.getServicesGroups();

      this.setState({
        serviceGroups: TemplateServicesGridServices.normalizeServicesGroup(servicesGroups),
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchManagers = async () => {
    try {
      const managers = await api.getSupportManagers();

      this.setState({
        managers: TemplateServicesGridServices.normalizeManagersList(managers),
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchReceiptsTemplateServiceList = async () => {
    const { selectManagers, selectServiceGroups, selectTemplates } = this.state;

    try {
      await this.setState({ isLoading: true });
      const templateServiceList = await api.getReceiptsTemplateServiceList({
        groupIds: selectServiceGroups,
        supportManagerIds: selectManagers,
        templateIds: selectTemplates.map((template) => (template === null ? -1 : template)),
      });

      this.setState({
        templateServiceList:
          !templateServiceList || !templateServiceList.length
            ? []
            : templateServiceList.map((templateService) => ({
                ...templateService,
                Id: `${templateService.ServiceId}-${templateService.TemplateId}`,
              })),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  deleteServicesTemplateRelation = async () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.setReceiptsTemplate({
        templateId: 0,
        serviceIds: selectedItems.map(({ ServiceId }) => ServiceId),
      });

      this.setState({ isLoading: false });
      message.success(translate('page.serviceTemplates.deletedSuccessfully'), 2.5);
      this.fetchReceiptsTemplateServiceList();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleSelectByName = (name) => (selectItems) => {
    this.setState({ [name]: selectItems, authorizationLimits: [] });
  };

  buildToolbar = () => {
    const {
      managers,
      selectManagers,
      serviceGroups,
      selectServiceGroups,
      templateList,
      selectTemplates,
      selectedItems,
    } = this.state;
    const { translate } = this.props;

    return (
      <Row className="TemplateServicesGrid-toolbar">
        <div className="TemplateServicesGrid-searchContent">
          <div className="TemplateServicesGrid-selects">
            <div className="TemplateServicesGrid-selectRow">
              <div className="TemplateServicesGrid-selectLabel">
                {translate('page.serviceTemplates.col-serviceGroup')}
              </div>

              <div className="TemplateServicesGrid-select">
                <GridDropdown
                  data={serviceGroups}
                  colConfig={SERVICE_GROUPS_CONFIG}
                  selectItems={selectServiceGroups}
                  onSave={this.handleSelectByName('selectServiceGroups')}
                  defaultTitle={translate('page.serviceTemplates.selectServiceGroup')}
                />
              </div>
            </div>

            <div className="TemplateServicesGrid-selectRow">
              <div className="TemplateServicesGrid-selectLabel">{translate('page.serviceTemplates.col-template')}</div>

              <div className="TemplateServicesGrid-select">
                <GridDropdown
                  data={templateList}
                  colConfig={TEMPLATES_DROPDOWN_CONFIG}
                  selectItems={selectTemplates}
                  onSave={this.handleSelectByName('selectTemplates')}
                  defaultTitle={translate('page.serviceTemplates.selectTemplate')}
                />
              </div>
            </div>

            <div className="TemplateServicesGrid-selectRow">
              <div className="TemplateServicesGrid-selectLabel">{translate('page.serviceTemplates.col-manager')}</div>

              <div className="TemplateServicesGrid-select">
                <GridDropdown
                  data={managers}
                  colConfig={MANAGER_COLUMN_CONFIG}
                  selectItems={selectManagers}
                  onSave={this.handleSelectByName('selectManagers')}
                  defaultTitle={translate('page.serviceMainProps.chooseManager')}
                />
              </div>
            </div>
          </div>

          <div className="TemplateServicesGrid-searchAction">
            <Button type="primary" onClick={this.fetchReceiptsTemplateServiceList}>
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>

        <div className="TemplateServicesGrid-actions">
          {checkElement('servicesTemplate-updateButton', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ visibleDialogType: 'update' });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('servicesTemplate-deleteButton', this.currentArray) && (
            <Button
              disabled={selectedItems.length !== 1}
              type="primary"
              onClick={() => {
                this.setState({ visibleDialogType: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { translate } = this.props;
    const { templateServiceList, isLoading, visibleDialogType, templateList, selectedItems } = this.state;

    if (!checkElement('servicesTemplate-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={templateServiceList}
          name="templateServicesGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchReceiptsTemplateServiceList}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItems}
          isLoading={isLoading}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            columnMenu={false}
            sortable={false}
            showAllSelected={true}
          />
          <GridColumn field="ServiceId" title="grids.services.column-service-id" width="120" filter="numeric" />
          <GridColumn field="ServiceName" title="grids.services.column-service-name" width="150" />
          <GridColumn field="GroupName" title="page.serviceTemplates.col-serviceGroup" width="150" />
          <GridColumn field="SupportManagerName" title="page.serviceTemplates.col-manager" width="240" />
          <GridColumn field="TemplateName" title="page.serviceTemplates.col-template" width="150" />
          <GridColumn
            field="DatePost"
            title="page.templates.datePost"
            width="150"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn field="UserNamePost" title="page.limitsService.col-performer" width="150" />
        </Grid>

        {visibleDialogType === 'update' && (
          <UpdateModal
            visible={visibleDialogType === 'update'}
            closeModal={() => {
              this.setState({ visibleDialogType: '' });
            }}
            templateList={templateList}
            selectedItems={selectedItems.map(({ ServiceId }) => ServiceId)}
            onRefresh={this.fetchReceiptsTemplateServiceList}
          />
        )}
        {visibleDialogType === 'delete' && (
          <DeleteModal
            modalTitle={translate('page.serviceTemplates.serviceTemplateDeleting')}
            visible={visibleDialogType === 'delete'}
            closeModal={() => {
              this.setState({ visibleDialogType: '' });
            }}
            submitCallback={this.deleteServicesTemplateRelation}
            deleteContent={translate('page.serviceTemplates.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(TemplateServicesGrid);
