import React, { PureComponent } from 'react';

import FormStateContainer from '../../../../components/FormStateContainer';
import { INITIAL_VALUES_SUPPLIER_COMMISSIONS } from '../SupplierCommissionForm/constants';
import SupplierCommission from './SupplierCommission';
import { ApiService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class SupplierCommissionContainer extends PureComponent {
  state = {
    isLoading: false,
    initialValues: null,
    isUpdating: false,
  };

  componentDidMount() {
    this.fetchSupplierCommission();
  }

  fetchSupplierCommission = async () => {
    const {
      dataItem: { ServiceId },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const commission = await api.getServiceSupplierCommission(ServiceId);

      const parsedCommission = commission && commission[0];

      await this.setState({
        isLoading: false,
        isUpdating: !!commission,
        initialValues: parsedCommission
          ? {
              SuppliersProviderPercent: parsedCommission.SupplierProviderPercent,
              SuppliersProviderMin: parsedCommission.SupplierProviderMin,
              SuppliersProviderMax: parsedCommission.SupplierProviderMax,
              SuppliersProviderAmountTotal: parsedCommission.SupplierProviderAmountTotal,
              SuppliersProviderPercentForCards: parsedCommission.SupplierCardProviderPercent,
              SuppliersProviderMinForCards: parsedCommission.SupplierCardProviderMin,
              SuppliersProviderMaxForCards: parsedCommission.SupplierCardProviderMax,
              SuppliersProviderAmountTotalForCards: parsedCommission.SupplierCardProviderAmountTotal,
            }
          : INITIAL_VALUES_SUPPLIER_COMMISSIONS,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { dataItem } = this.props;
    const { initialValues, isLoading, isUpdating } = this.state;

    return (
      <>
        {initialValues ? (
          <FormStateContainer initialValues={initialValues}>
            <SupplierCommission dataItem={dataItem} isUpdating={isUpdating} />
          </FormStateContainer>
        ) : (
          <></>
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SupplierCommissionContainer);
