import React, { PureComponent } from 'react';
import { message } from 'antd';

import CreateCommission from './CreateCommission';
import CommissionDetailsFormService from '../../../components/CommisionDetailsForm/services/commissionDetailsFormService';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class CommissionDetailsFormContainer extends PureComponent {
  state = {
    isLoading: false,
  };

  handleCreateCommission = async () => {
    const { showError, formState, validateOnSubmit, translate, handleValidateAllField } = this.props;

    const {
      TimeFrom,
      TimeTo,
      AmountTo,
      AmountFrom,
      ResultFixed,
      ResultPercent,
      CommissionMin,
      CommissionMax,
      ValidDateFrom,
      ValidDateTo,
      Priority,
      Active,
    } = formState;

    await this.setState({ isLoading: true });

    const validationMessage = await handleValidateAllField();

    if (validationMessage) {
      message.error(translate('grids.comission-sub.errorMessages.formWasNotSubmitted'), 3);
      this.setState({ isLoading: false });
      return;
    }

    const errorMessage = validateOnSubmit();

    if (errorMessage) {
      message.error(errorMessage, 3);
      this.setState({ isLoading: false });
      return;
    }

    const model = {
      CommissionId: null,
      Name: CommissionDetailsFormService.getCommissionName(formState),
      TimeFrom: getDataByTimezoneOffset(TimeFrom),
      TimeTo: getDataByTimezoneOffset(TimeTo),
      AmountTo: AmountTo,
      AmountFrom: AmountFrom,
      FixedCommission: ResultFixed || 0,
      PercentCommission: ResultPercent || 0,
      CommissionMin,
      CommissionMax,
      Priority,
      ValidFrom: getDataByTimezoneOffset(ValidDateFrom),
      ValidTo: getDataByTimezoneOffset(ValidDateTo),
      Active,
    };

    try {
      const { CommissionName } = await api.commissionsClientCreate(model);

      await message.success(
        `${translate('grids.comission-sub.successCreateCommissionMessagePrefix')} "${CommissionName}" ${translate(
          'grids.comission-sub.successCreateCommissionMessagePostfix',
        )}`,
        5,
      );
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <CreateCommission handleCreateCommission={this.handleCreateCommission} {...this.props} />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(CommissionDetailsFormContainer);
