import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService, tabService } from '../../../services';
import Grid from '../../../components/grids/baseGrid';
import './NonRiskServicesGrid.scss';
import { withTranslate } from '../../../contexts/localContext';
import DeleteModal from '../../../components/DeleteModal';
import SetServicesDialog from './SetServicesDialog';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class NonRiskServicesGrid extends PureComponent {
  currentArray = findArray('nonRiskLimits');

  state = {
    servicesList: [],
    isLoading: false,
    selectedItems: [],
    modalVariant: '',
  };

  getServicesList = async () => {
    try {
      await this.setState({ isLoading: true });
      const servicesList = await api.servicesBlockingRules();

      await this.setState({
        servicesList: servicesList && servicesList.length ? servicesList : [],
      });
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidMount() {
    this.getServicesList();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row className="NonRiskServicesGrid-toolbar">
        <Col span={24} className="NonRiskServicesGrid-actionsPanel">
          {checkElement('nonRiskLimits-nonRiskServicesButtonAdd', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'addServicesMoadal' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('nonRiskLimits-nonRiskServicesButtonDelete', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'deleteModal' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  onDelete = async () => {
    const { selectedItems } = this.state;

    try {
      await api.servicesBlockingRulesDelete(selectedItems.map(({ ServiceId }) => ServiceId));
      await this.getServicesList();
      await this.setState({ modalVariant: '' });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { servicesList, isLoading, modalVariant } = this.state;
    const { translate } = this.props;

    return (
      <>
        <Grid
          data={servicesList}
          onRefresh={this.getServicesList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          fieldForSelection="ServiceId"
          onSelect={this.handleSelectionChange}
          name="NonRiskServicesGrid"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="ServiceId" title="grids.services.column-service-id" width="120" filter="numeric" />
          <GridColumn field="ServiceName" title="grids.services.column-service-name" width="400" />
          <GridColumn field="ServiceGroup" title="grids.services.column-service-group" width="150" />
          <GridColumn field="StatusName" title="page.commissionsServices.col-status" width="150" />
          <GridColumn field="Supplier" title="grids.services.column-supplier" width="300" />
          <GridColumn field="UnlockingPeriod" title="page.nonRiskLimitsGrid.col-unlockingPeriod" width="200" />
        </Grid>
        {modalVariant === 'deleteModal' && (
          <DeleteModal
            visible={modalVariant === 'deleteModal'}
            translate={translate}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.onDelete}
            messageSuccess={translate('page.nonRiskLimitsGrid.selectedServicesSuccessfullyRemoved')}
            hideSuccessfulMessage={false}
            deleteContent={translate('page.nonRiskLimitsGrid.areYouSure')}
          />
        )}
        {modalVariant === 'addServicesMoadal' && (
          <SetServicesDialog
            visible={modalVariant === 'addServicesMoadal'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            getServicesList={this.getServicesList}
          />
        )}
      </>
    );
  }
}

export default withTranslate(NonRiskServicesGrid);
