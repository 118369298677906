import React, { Component } from 'react';
import { Row, Col, Tooltip } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './FormRow.scss';

class FormRow extends Component {
  renderChildren = () => {
    const { tooltipTitleTranslateSlug, children, translate } = this.props;

    if (tooltipTitleTranslateSlug) {
      return (
        <Tooltip trigger="focus" title={translate(tooltipTitleTranslateSlug)} placement="bottom">
          {children}
        </Tooltip>
      );
    }
    return <>{children}</>;
  };

  render() {
    const { labelTranslateSlug, translate, isFieldRequired, labelColumnLarge, fieldColumnLarge } = this.props;

    return (
      <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
        <Col sm={24} lg={labelColumnLarge || 15}>
          <p className="createApplication-elem-label">
            {translate(labelTranslateSlug)}:
            {isFieldRequired && <span className="createApplication-elem-required">*</span>}
          </p>
        </Col>

        <Col sm={24} lg={fieldColumnLarge || 9}>
          {this.renderChildren()}
        </Col>
      </Row>
    );
  }
}

export default withTranslate(FormRow);
