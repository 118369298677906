import React, { PureComponent } from 'react';

import FishkaServiceGrid from './FishkaServiceGrid';
import FishkaServiceHistoryGrid from './FishkaServiceHistoryGrid';
import PageTabs from '../../components/tabber/tabber';

class FishkaService extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-fishkaService',
        content: <FishkaServiceGrid />,
        closable: false,
      },
    ],
  };

  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'fishkaServiceHistory':
        tabs.push({
          title: 'page.fishkaService.tab-historyGrid',
          content: <FishkaServiceHistoryGrid />,
          closable: true,
          key: key,
        });
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default FishkaService;
