import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { Tooltip, Button } from 'antd';
import './cellDetails.scss';
/**
 * Terminal details Cell
 */
export default class CellDetails extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  render() {
    const { field, dataItem, className, style, mobile } = this.props;
    // test values -- delete before commit!
    const value = dataItem[field];
    if (mobile) {
      return (
        <Tooltip
          title={value}
          placement="left"
          trigger={['click']}
          className="tooltip-details"
          // getTooltipContainer={() => this.ref.current}
        >
          <Button type="link" className="lg-p-0 lg-w-100">
            {value}
          </Button>
        </Tooltip>
      );
    }
    return (
      <td className={`${className}`} style={{ ...style, zIndex: 4, position: 'relative' }} ref={this.ref}>
        <Tooltip
          title={value}
          placement="left"
          trigger="click"
          className="tooltip-details"
          // getTooltipContainer={() => this.ref.current}
        >
          {value}
        </Tooltip>
      </td>
    );
  }
}
