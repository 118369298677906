import React, { PureComponent } from 'react';

import { StorageService } from '../../services';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';

const ELEMENT_LIST_DICTIONARY = {
  Button: 'Кнопка',
  Grid: 'Таблица',
  NewTableLink:
    'Новая динамическая вкладка/Ссылка на новую динамическую вкладку/Вктивность которая открывает новую динамичискую вкладку',
  Other: 'Другое',
  Label: 'Подпись',
};

const aggregate = [{ field: 'ElementId', aggregate: 'count', format: 'n0' }];

class AllElements extends PureComponent {
  state = {
    isLoading: false,
    elementsList: [],
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    this.getElements();
    this.setState({ isLoading: false });
  }

  getElements = () => {
    const userPermissionsList = StorageService.getItem('userInfo').userPermissionsList;
    const backConfig = StorageService.getItem('backConfig');
    const parentIdList = userPermissionsList.filter(({ parentId }) => parentId).map(({ parentId }) => parentId);

    const elementsListByPermissionsList = userPermissionsList.map(({ menuId, webTag, name, parentId }) => {
      const parentNode = userPermissionsList.find(({ menuId }) => parentId === menuId);

      return {
        ElementClass: parentIdList.includes(menuId) ? 'Раздел' : 'Страница',
        ElementName: webTag,
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: menuId,
        Description: name,
        ParentId: parentId || '-',
        ParentName: parentNode ? parentNode.webTag : '-',
      };
    });

    const elementsListByConfig = backConfig
      .reduce((acc, { Elements, WebTag }) => {
        const parentNode = userPermissionsList.find(({ webTag }) => webTag === WebTag);
        return [
          ...acc,
          ...Elements.map((item) => ({
            ...item,
            ParentName: WebTag,
            ParentId: parentNode ? parentNode.menuId : '-',
          })),
        ];
      }, [])
      .map(({ ElementTypeName, ElementName, ElementTypeId, ElementId, ParentName, ParentId }) => ({
        ElementClass: ELEMENT_LIST_DICTIONARY[ElementTypeName],
        ElementName,
        ElementType: ElementTypeName,
        TypeId: ElementTypeId,
        ElementIdType: 'ElementId',
        ElementId,
        Description: '',
        ParentName,
        ParentId,
      }));

    const fieldsList = backConfig
      .reduce((acc, { Elements }) => {
        const gridsElements = Elements.filter(({ ElementTypeId }) => ElementTypeId === 1);
        const fieldsList = gridsElements.length
          ? gridsElements.reduce(
              (acc, { Fields, ElementName, ElementId }) => [
                ...acc,
                ...Fields.map((item) => ({ ...item, ParentId: ElementId, ParentName: ElementName })),
              ],
              [],
            )
          : [];
        return [...acc, ...fieldsList];
      }, [])
      .map(({ FieldName, FieldId, ParentName, ParentId }) => ({
        ElementClass: 'Поля таблицы',
        ElementName: FieldName,
        ElementType: '-',
        TypeId: '-',
        ElementIdType: 'FieldId',
        ElementId: FieldId,
        Description: '',
        ParentName,
        ParentId,
      }));

    this.setState({ elementsList: [...elementsListByPermissionsList, ...elementsListByConfig, ...fieldsList] });
  };

  getElements1 = () => {
    const userPermissionsList = StorageService.getItem('userInfo').userPermissionsList;
    const backConfig = StorageService.getItem('backConfig');
    const parentIdList = userPermissionsList.filter(({ parentId }) => parentId).map(({ parentId }) => parentId);

    const elementsListByPermissionsList = userPermissionsList.map(({ menuId, webTag, name, parentId }) => {
      const parentNode = userPermissionsList.find(({ menuId }) => parentId === menuId);

      return {
        ElementClass: parentIdList.includes(menuId) ? 'Раздел' : 'Страница',
        ElementName: webTag,
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: menuId,
        Description: name,
        ParentId: parentId || '-',
        ParentName: parentNode ? parentNode.webTag : '-',
      };
    });

    const elementsListByConfig = backConfig
      .reduce((acc, { Elements, WebTag }) => {
        const parentNode = userPermissionsList.find(({ webTag }) => webTag === WebTag);
        return [
          ...acc,
          ...Elements.map((item) => ({
            ...item,
            ParentName: WebTag,
            ParentId: parentNode ? parentNode.menuId : '-',
          })),
        ];
      }, [])
      .map(({ ElementTypeName, ElementName, ElementTypeId, ElementId, ParentName, ParentId }) => ({
        ElementClass: ELEMENT_LIST_DICTIONARY[ElementTypeName],
        ElementName,
        ElementType: ElementTypeName,
        TypeId: ElementTypeId,
        ElementIdType: 'ElementId',
        ElementId,
        Description: '',
        ParentName,
        ParentId,
      }));

    const fieldsList = backConfig
      .reduce((acc, { Elements }) => {
        const gridsElements = Elements.filter(({ ElementTypeId }) => ElementTypeId === 1);
        const fieldsList = gridsElements.length
          ? gridsElements.reduce(
              (acc, { Fields, ElementName, ElementId }) => [
                ...acc,
                ...Fields.map((item) => ({ ...item, ParentId: ElementId, ParentName: ElementName })),
              ],
              [],
            )
          : [];
        return [...acc, ...fieldsList];
      }, [])
      .map(({ FieldName, FieldId, ParentName, ParentId }) => ({
        ElementClass: 'Поля таблицы',
        ElementName: FieldName,
        ElementType: '-',
        TypeId: '-',
        ElementIdType: 'FieldId',
        ElementId: FieldId,
        Description: '',
        ParentName,
        ParentId,
      }));

    const elementsList = [...elementsListByPermissionsList, ...elementsListByConfig, ...fieldsList];

    const dictionary = elementsList
      .filter(({ ElementId }) => ElementId === 196 || ElementId === 156)
      .filter(({ ElementClass }) => ElementClass === 'Раздел');

    const pages = elementsList
      .filter(({ ParentId }) => dictionary.map(({ ElementId }) => ElementId).includes(ParentId) || ParentId === '-')
      .filter(({ ElementClass }) => ElementClass === 'Страница' || ElementClass === 'Раздел')
      .filter(({ ElementName }) => ElementName === 'screenControlSettings' || ElementName === 'createTerminal');

    const elements = elementsList
      .filter(({ ParentId }) => pages.map(({ ElementId }) => ElementId).includes(ParentId))
      .filter(({ ElementIdType }) => ElementIdType === 'ElementId')
      .filter(
        ({ ElementName }) =>
          ElementName === 'createTerminal-fiscalizationInventory' ||
          ElementName === 'createTerminal-fiscalNumberInput' ||
          ElementName === 'createTerminal-businessUnitInput' ||
          ElementName === 'createTerminal-inventoryNumberInput' ||
          ElementName === 'screenControlSettings-fiscalizationInventory' ||
          ElementName === 'screenControlSettings-fiscalNumberInput' ||
          ElementName === 'screenControlSettings-businessUnitInput' ||
          ElementName === 'screenControlSettings-inventoryNumberInput',
      );
    // const fields = elementsList
    //   .filter(({ ParentId }) => elements.map(({ ElementId }) => ElementId).includes(ParentId))
    //   .filter(({ ElementClass }) => ElementClass === 'Поля таблицы');

    console.log('dictionary', dictionary);
    console.log('pages', pages);
    console.log('elements', elements);
    // console.log('fields', fields);

    console.log('res', [...dictionary, ...pages, ...elements]);
  };

  render() {
    const { isLoading } = this.state;

    const test = [
      {
        ElementClass: 'Раздел',
        ElementName: 'screenControlTerminals',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 156,
        Description: 'WTM Страница Управление — терминалы',
        ParentId: 153,
        ParentName: 'screenControl',
      },
      {
        ElementClass: 'Раздел',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 196,
        Description: 'экран Настройки управления',
        ParentId: 156,
        ParentName: 'screenControlTerminals',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'createTerminal',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 160,
        Description: 'WTM Нові термінали',
        ParentId: '-',
        ParentName: '-',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 209,
        Description: 'экран Настройки управления',
        ParentId: 156,
        ParentName: 'screenControlTerminals',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 210,
        Description: 'экран Настройки управления',
        ParentId: 156,
        ParentName: 'screenControlTerminals',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 211,
        Description: 'экран Настройки управления',
        ParentId: 156,
        ParentName: 'screenControlTerminals',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 212,
        Description: 'экран Настройки управления',
        ParentId: 156,
        ParentName: 'screenControlTerminals',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 197,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 198,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 199,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 200,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 201,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 202,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 203,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 204,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 205,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 206,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Страница',
        ElementName: 'screenControlSettings',
        ElementType: 'webTag',
        TypeId: 0,
        ElementIdType: 'menuId',
        ElementId: 207,
        Description: 'экран Настройки управления',
        ParentId: 196,
        ParentName: 'screenControlSettings',
      },
      {
        ElementClass: 'Другое',
        ElementName: 'createTerminal-businessUnitInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 223,
        Description: '',
        ParentName: 'createTerminal',
        ParentId: 160,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'createTerminal-fiscalizationInventory',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 221,
        Description: '',
        ParentName: 'createTerminal',
        ParentId: 160,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'createTerminal-fiscalNumberInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 222,
        Description: '',
        ParentName: 'createTerminal',
        ParentId: 160,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'createTerminal-inventoryNumberInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 232,
        Description: '',
        ParentName: 'createTerminal',
        ParentId: 160,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'screenControlSettings-businessUnitInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 226,
        Description: '',
        ParentName: 'screenControlSettings',
        ParentId: 196,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'screenControlSettings-fiscalizationInventory',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 224,
        Description: '',
        ParentName: 'screenControlSettings',
        ParentId: 196,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'screenControlSettings-fiscalNumberInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 225,
        Description: '',
        ParentName: 'screenControlSettings',
        ParentId: 196,
      },
      {
        ElementClass: 'Другое',
        ElementName: 'screenControlSettings-inventoryNumberInput',
        ElementType: 'Other',
        TypeId: 6,
        ElementIdType: 'ElementId',
        ElementId: 233,
        Description: '',
        ParentName: 'screenControlSettings',
        ParentId: 196,
      },
    ];

    return (
      <Grid
        data={test}
        isLoading={isLoading}
        name="allElementsGrid"
        toolbar={<div></div>}
        onRefresh={() => {}}
        aggregate={aggregate}
      >
        <GridColumn field="ElementClass" title="ElementClass" width="145" />
        <GridColumn field="ElementName" title="ElementName" width="145" />
        <GridColumn field="ElementType" title="ElementType" width="145" />
        <GridColumn field="TypeId" title="TypeId" width="145" />
        <GridColumn field="ElementIdType" title="ElementIdType" width="145" />
        <GridColumn field="ElementId" title="ElementId" width="145" />
        <GridColumn field="Description" title="Description" width="145" />
        <GridColumn field="ParentName" title="ParentName" width="145" />
        <GridColumn field="ParentId" title="ParentId" width="145" />
      </Grid>
    );
  }
}

export default AllElements;
