const addingAdressedPayment = (translate, value) => {
  switch (value) {
    case 'addingAddressedPaymentApplications-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'addingAddressedPaymentApplications-RequestId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-requestId')}`;
    case 'addingAddressedPaymentApplications-RequestNumber':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-requestNumber')}`;
    case 'addingAddressedPaymentApplications-UserName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-userId')}`;
    case 'addingAddressedPaymentApplications-Name':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-name')}`;
    case 'addingAddressedPaymentApplications-SupplierNarrative':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-supplierNarrative')}`;
    case 'addingAddressedPaymentApplications-Amount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-amount')}`;
    case 'addingAddressedPaymentApplications-CommissionClient':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-commissionClient')}`;
    case 'addingAddressedPaymentApplications-CommissionDealer':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-commissionDealer')}`;
    case 'addingAddressedPaymentApplications-Iban':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-iban')}`;
    case 'addingAddressedPaymentApplications-Okpo':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-okpo')}`;
    case 'addingAddressedPaymentApplications-SupplierName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-supplierName')}`;
    case 'addingAddressedPaymentApplications-SupplierBank':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-supplierBank')}`;
    case 'addingAddressedPaymentApplications-AdditionalParameters':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-additionalParameters')}`;
    case 'addingAddressedPaymentApplications-SubmenuName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-submenuName')}`;
    case 'addingAddressedPaymentApplications-Terminals':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-terminalId')}`;
    case 'addingAddressedPaymentApplications-ServiceReplaceName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-serviceReplaceName')}`;
    case 'addingAddressedPaymentApplications-AdditionalInfo':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-additionalInfo')}`;
    case 'addingAddressedPaymentApplications-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-statusId')}`;
    case 'addingAddressedPaymentApplications-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-datePost')}`;
    case 'addingAddressedPaymentApplications-Email':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-email')}`;
    case 'addingAddressedPaymentApplications-Phone':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.addingAddressedPaymentApplications.column-phone')}`;
    case 'aap-create-app':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.addingAddressedPaymentApplications.btn-create-app')}`;
    case 'aap-clone-app':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.addingAddressedPaymentApplications.btn-clone-app')}`;
    case 'aap-edit-tab':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.addAdressPayment.tab-edit')}`;
    case 'aap-edit-btn':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.addAdressPayment.tab-edit')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('page.screenControlBusinessServices.button-edit')}`;
    case 'aap-commands':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.addingAddressedPaymentApplications.select-title')}`;
    case 'aap-command-send':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.addingAddressedPaymentApplications.select-title')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.addingAddressedPaymentApplications.option-1')} `;
    case 'aap-command-delete':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.addingAddressedPaymentApplications.select-title')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.addingAddressedPaymentApplications.option-2')} `;

    case 'aap-changeComission-submit':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.addAdressPayment.tab-changeComission')},
              ${translate('page.adminPanel.type-button')}:
              ${translate('page.screenControlTerminals.btn-submit-dsb')}`;

    case 'aap-command-changeComission':
      return `${translate(`page.adminPanel.type-dropdown`)}:
              ${translate('grids.addingAddressedPaymentApplications.select-title')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.addingAddressedPaymentApplications.option-3')} `;

    default:
      return value;
  }
};

export default addingAdressedPayment;
