class TransactionsWithCashbackService {
  getMinimumSelectedDate = () => new Date(2024, 6, 1, 0, 0, 0, 0);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const roundedStartDate = new Date(
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
      new Date(startDate).getDate(),
      0,
      0,
      0,
      0,
    );

    const roundedEndDate = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate(),
      23,
      59,
      59,
      59,
    );

    if (roundedStartDate - roundedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 59);

    if (
      minimumSelectedDate - roundedStartDate > 0 ||
      minimumSelectedDate - roundedEndDate > 0 ||
      roundedStartDate - nowDate > 0 ||
      roundedEndDate - nowDate > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.transactionsCashBack) {
      return { transactions: [], hasNextPage: false, hasPreviousPage: false };
    }
    const transactionInfo = data.data.transactionsCashBack;

    const transactions = transactionInfo.items && transactionInfo.items.length ? transactionInfo.items : [];

    const pageInfo = transactionInfo.pageInfo;

    return {
      transactions,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new TransactionsWithCashbackService();
