const ManagementErrorCodes = (translate, value) => {
  switch (value) {
    case 'managementErrorCodes-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'managementErrorCodes-update':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.save`)}`;
    case 'managementErrorCodes-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.delete`)}`;
    case 'managementErrorCodes-add':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;
    default:
      return value;
  }
};

export default ManagementErrorCodes;
