import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Checkbox } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { withTranslate } from '../../../../../contexts/localContext';
import LoadingPanel from '../../../../../components/loader';

import './modalGraphicWorkingHours.scss';
import { SHORT_STRING_BY_DAY_MAP } from './constants';
import { formatDateNumber } from '../../../../../utils/formatDateNumber';
import GraphicWorkingHours from '../../../../../components/GraphicWorkingHours';

const convertToISOFormat = (date) => formatDate(date, 'yyyy-MM-ddTHH:mm:ss');

class ModalGraphicWorkingHours extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateTerminalSchedules: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { data: graphicData } = props;
    this.state = {
      loading: false,
      isLoading: false,
      graphicData,
      isDisabledBtnChoose: false,
    };
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  changeGraphicDataByCheckbox = (e, day) => {
    const { getDefaultDate } = this.props;
    const checked = e.target.checked;
    this.setState(({ graphicData }) => {
      const newGraphicData = graphicData.map(({ DayOfWeek, TurnOff, TurnOn, DayOff }) => {
        if (DayOfWeek === day) {
          return {
            TurnOff: getDefaultDate(TurnOff),
            TurnOn: getDefaultDate(TurnOn),
            DayOff: checked,
            DayOfWeek,
          };
        }

        return {
          TurnOff,
          TurnOn,
          DayOff,
          DayOfWeek,
        };
      });

      console.log('newGraphicData', newGraphicData);
      return {
        graphicData: newGraphicData,
      };
    });
  };

  changeGraphicDataByTime = (e, day, propName) => {
    const value = e.target.value;
    this.setState(({ graphicData }) => {
      const elemIdx = graphicData.findIndex((el) => el.DayOfWeek === day);
      const elem = graphicData[elemIdx];
      const newElem = {
        ...elem,
        [propName]: value,
      };
      return {
        graphicData: [...graphicData.slice(0, elemIdx), newElem, ...graphicData.slice(elemIdx + 1)],
      };
    }, this.checkDisabling);
  };

  checkDisabling = () => {
    this.setState(({ graphicData }) => {
      return {
        isDisabledBtnChoose:
          graphicData.findIndex((el) => new Date(el.TurnOn) > new Date(el.TurnOff)) === -1 ? false : true,
      };
    });
  };

  getTimeByDate = (date) => {
    return `${formatDateNumber(new Date(date).getHours())}:${formatDateNumber(new Date(date).getMinutes())}`;
  };

  getGraphicsValue = (stringifiedGraphicData) =>
    stringifiedGraphicData
      .filter(({ DayOff }) => !DayOff)
      .map(
        ({ DayOfWeek, TurnOn, TurnOff }) =>
          `${SHORT_STRING_BY_DAY_MAP.get(DayOfWeek)}:${this.getTimeByDate(TurnOn)}-${this.getTimeByDate(TurnOff)}`,
      )
      .join(';');

  updateTerminalSchedules = () => {
    const { updateTerminalSchedules, closeModal, onChangeGraphicsValue } = this.props;
    const { graphicData } = this.state;
    const stringifiedGraphicData = graphicData.map((el) => {
      return {
        ...el,
        TurnOn: convertToISOFormat(el.TurnOn),
        TurnOff: convertToISOFormat(el.TurnOff),
      };
    });
    onChangeGraphicsValue(this.getGraphicsValue(stringifiedGraphicData));
    updateTerminalSchedules(stringifiedGraphicData);
    closeModal();
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    const { loading, isLoading, graphicData, isDisabledBtnChoose } = this.state;
    console.log('graphicData', graphicData);

    return (
      <>
        <Modal
          title={translate('grids.settingsAndEvents.column-workingHours')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment modal-create-profile"
          id={'modal_GraphicWorkingHours'}
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={isDisabledBtnChoose}
              loading={loading}
              onClick={this.updateTerminalSchedules}
            >
              {translate('page.screenControlAddSets.btn-choose')}
            </Button>,
          ]}
        >
          {/* START RENDER ROWS */}
          <GraphicWorkingHours
            data={graphicData}
            changeGraphicDataByTime={this.changeGraphicDataByTime}
            changeGraphicDataByCheckbox={this.changeGraphicDataByCheckbox}
          />
          {/* END ROW 1 */}

          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalGraphicWorkingHours);
