import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService, StorageService } from '../../../services';
import { Button, Col, Row } from 'antd';
import { ALL_GRID_COLUMNS } from './constants';
import GqlService from '../../../components/grids/gql.service';
import AddNewServicesService from './addNewServices.service';
import ModalFinMonLimits from './ModalFinMonLimits/ModalFinMonLimits';

import './ExceptionsFinMonLimit.scss';

const api = new ApiService();

class AddNewServices extends PureComponent {
  state = {
    data: [],
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    selectedItems: [],
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    visible: false,
    isLoading: false,
  };
  fetchAllServicesList = async (props) => {
    const { rowAmount, pageValue, sortString, filtersString } = this.state;
    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('addNewServicesGrid');
      const currentColumn =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns) : ALL_GRID_COLUMNS);
      const argumentString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order:${
        sortString || '{ serviceId: ASC }'
      },where:{and:[{statusId: {neq: 0}},${filtersString}]}`;
      const query = `servicesList(${argumentString}){items{${currentColumn.join(
        ',',
      )}}pageInfo{hasNextPage, hasPreviousPage}}`;
      const data = await api.getByGraphQl(query);
      const { servicesListService, hasNextPage, hasPreviousPage } = AddNewServicesService.getGQLResponse(data);
      this.setState({ data: servicesListService, hasNextPage, hasPreviousPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };
  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };
  setSortingString = (sortString) => {
    this.setState({ sortString });
  };
  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  openModal = (modal) => {
    this.setState({ visible: true, modalName: modal });
  };
  closeModal = () => {
    this.setState({ visible: false });
  };

  buildToolbar = () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;
    return (
      <Col className="Exceptions-toolbar">
        <Button type="primary" disabled={!selectedItems.length} onClick={() => this.openModal('isAddModal')}>
          {translate('core.buttonTitles.add')}
        </Button>
      </Col>
    );
  };
  async componentDidMount() {
    await this.fetchAllServicesList();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchAllServicesList({ selectIdsString });
    }
  }
  render = () => {
    const { data, isLoading, hasNextPage, hasPreviousPage, pageValue, selectedItems, visible, modalName } = this.state;
    return (
      <>
        <Grid
          data={data}
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchAllServicesList}
          fieldForSelection="ServiceId"
          name="addNewServicesGrid"
          handleRowAmountChange={this.handleRowAmountChange}
          pageChange={this.pageChange}
          pageValue={pageValue}
          setFiltersString={this.setFiltersString}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          setSortingString={this.setSortingString}
          onSelect={this.handleSelectionChange}
          isLoading={isLoading}
          multiSelected
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="ServiceId" filter="numeric" title="grids.addNewServices.column-serviceId" width="200px" />
          <GridColumn field="ServiceName" title="grids.addNewServices.column-serviceName" width="200px" />
          <GridColumn field="Status" title="grids.addNewServices.column-status" width="200px" />
        </Grid>
        {visible && (
          <ModalFinMonLimits
            visible={visible}
            modalName={modalName}
            closeModal={this.closeModal}
            selectedItems={selectedItems}
            // update={this.fetchAllServicesList}
          />
        )}
      </>
    );
  };
}
export default withTranslate(AddNewServices);
