const commissionProfiles = (translate, value) => {
  switch (value) {
    case 'сommissionManagementProfiles-grid-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'сommissionManagementProfiles-grid-ProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-profileId')}`;
    case 'сommissionManagementProfiles-grid-ProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-profileName')}`;
    case 'сommissionManagementProfiles-grid-Version':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-version')}`;
    case 'сommissionManagementProfiles-grid-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-updateDate')}`;
    case 'сommissionManagementProfiles-grid-IsForAllOwners':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-profileVisibility')}`;
    case 'сommissionManagementProfiles-grid-Description':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-description')}`;
    case 'сommissionManagementProfiles-addProfile':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.commission.btn-add')}`;
    case 'сommissionManagementProfiles-deleteProfile':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.commission.btn-delete')}`;
    case 'сommissionManagementProfiles-changeDealerVisibility':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'grids.commission-profiles.column-profileVisibility',
      )}`;
    case 'сommissionManagementProfiles-changeProfileDescription':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'grids.commission-profiles.column-description',
      )}`;
    case 'сommissionManagementProfiles-changeProfileName':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate(
        'grids.commission-profiles.column-profileName',
      )}`;
    default:
      return value;
  }
};

export default commissionProfiles;
