import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';

import './InfoModal.scss';

class InfoModal extends PureComponent {
  render() {
    const { translate, visible, closeModal, content, modalTitle } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={modalTitle || ''}
        zIndex="1053"
        footer={[
          <Button key="submit" type="primary" onClick={closeModal} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
      >
        <div className={`InfoModal-content ${modalTitle ? '' : 'TerminalDetails--increasedTopGap'}`}>{content}</div>
      </Modal>
    );
  }
}

export default withTranslate(InfoModal);
