import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row, Input } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';

import { ApiService } from '../../../services';

import './CardsAvailability.scss';

const api = new ApiService();

// const mocked = {
//   Available: 510,
//   Rejected: 89,
//   Issued: 121,
// };

class DispenserTerminalsGrid extends PureComponent {
  state = {
    isLoading: false,
    availabilityOfCards: [],
  };

  componentDidMount() {
    this.fetchAvailabilityOfCards();
  }

  fetchAvailabilityOfCards = async () => {
    const { terminalId } = this.props;

    this.setState({ isLoading: true });
    try {
      const availabilityOfCards = await api.getDataAvailabilityOfCards(terminalId);

      if (availabilityOfCards && availabilityOfCards.length) {
        this.setState({ availabilityOfCards });
      }
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { terminalId, translate } = this.props;

    return (
      <Row className="CardsAvailability-toolbar">
        <Col span={18} md={12} className="CardsAvailability-row">
          <Col span={8} md={6}>
            {translate('page.terminalSettings.header-field-title')}
          </Col>
          <Col span={16} md={18}>
            <Input value={terminalId} disabled />
          </Col>
        </Col>
      </Row>
    );
  };

  render() {
    const { availabilityOfCards, isLoading } = this.state;

    return (
      <Grid
        data={availabilityOfCards}
        name="CardsAvailabilityGrid"
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        onRefresh={this.fetchAvailabilityOfCards}
      >
        <GridColumn
          field="Available"
          title="page.dispenserCollection.col-readyForDelivery"
          width="160"
          filter="numeric"
        />

        <GridColumn field="Rejected" title="page.dispenserCollection.col-rejected" width="150" filter="numeric" />

        <GridColumn field="Issued" title="page.dispenserCollection.col-issued" width="110" filter="numeric" />
      </Grid>
    );
  }
}

export default withTranslate(DispenserTerminalsGrid);
