const DealerCommissionsHistory = (translate, value) => {
  switch (value) {
    case 'dealerCommissionsHistory-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    default:
      return value;
  }
};

export default DealerCommissionsHistory;
