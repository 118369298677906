import React, { PureComponent } from 'react';
import { Button, message, Row } from 'antd';

import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG, PAYMENT_TYPES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import CellBoolean from '../../account/cellBoolean';
import UpdateModal from './UpdateModal';

import './DealerCommissionsExpectedChangesGrid.scss';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

const WrappedCellBoolean = (props) => (
  <CellBoolean {...props} className="DealerCommissionsExpectedChangesGrid-centredCell" />
);

class DealerCommissionsExpectedChangesGrid extends PureComponent {
  currentArray = findArray('dealerCommissionsExpectedChanges');
  state = {
    owners: [],
    selectOwners: [],
    paymentTypes: [],
    selectPaymentTypes: [],
    expectedChangesList: [],
    isLoading: false,
    modalVariant: '',
    selectedItems: [],
  };

  componentDidMount() {
    this.initializeFetch();
  }

  initializeFetch = async () => {
    await this.setState({ isLoading: true });
    await this.fetchPaymentTypes();
    await this.fetchOwners();
    await this.setState({ isLoading: false });
  };

  fetchExpectedChangesList = async () => {
    const { selectOwners, selectPaymentTypes } = this.state;

    if (!selectOwners.length || !selectPaymentTypes.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });

      const expectedChangesList = await api.getServiceCommissionsFutureList({
        owners: selectOwners,
        paymentTypes: selectPaymentTypes,
        allServices: true,
      });

      this.setState({
        expectedChangesList:
          expectedChangesList && expectedChangesList.length
            ? expectedChangesList.map((expectedChange) => ({
                ...expectedChange,
                Id: `${expectedChange.OwnerId}_${expectedChange.ServiceId}_${expectedChange.PaymentTypeId}_${expectedChange.DateStart}`,
              }))
            : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  deleteExpectedChanges = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.deleteServiceCommissionFuture(
        selectedItems.map(({ OwnerId, ServiceId, PaymentTypeId, DateStart }) => ({
          OwnerId,
          ServiceId,
          paymentType: PaymentTypeId,
          DateStart,
        })),
      );
      this.setState({ modalVariant: '' });
      message.success(`${translate('page.dealerCommissionsExpectedChanges.successfulDelete')}`, 2.5);
      await this.fetchExpectedChangesList();
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      const owners = await api.owners();
      this.setState({
        owners: owners && owners.length ? DropdownNormalizersService.normalizeOwners(owners) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchPaymentTypes = async () => {
    try {
      const paymentTypes = await api.getPaymentTypeList();

      this.setState({
        paymentTypes: DropdownNormalizersService.normalizePaymentTypes(paymentTypes),
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getHandleSelectByName = (name) => (selectItems) => {
    this.setState({ [name]: selectItems, expectedChangesList: [] });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { paymentTypes, selectPaymentTypes, owners, selectOwners, selectedItems } = this.state;

    return (
      <Row className="DealerCommissionsExpectedChangesGrid-toolbar">
        <div className="DealerCommissionsExpectedChangesGrid-searchContent">
          <div className="DealerCommissionsExpectedChangesGrid-selects">
            <div className="DealerCommissionsExpectedChangesGrid-selectRow">
              <div className="DealerCommissionsExpectedChangesGrid-selectLabel">
                {translate('page.commissionsServices.dealer')}
              </div>

              <div className="DealerCommissionsExpectedChangesGrid-select">
                <GridDropdown
                  data={owners}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={selectOwners}
                  onSave={this.getHandleSelectByName('selectOwners')}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  isGetDefaultSelectItemsFromStorag
                />
              </div>
            </div>

            <div className="DealerCommissionsExpectedChangesGrid-selectRow">
              <div className="DealerCommissionsExpectedChangesGrid-selectLabel">
                {translate('page.commissionsServices.paymentType')}
              </div>

              <div className="DealerCommissionsExpectedChangesGrid-select">
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  selectItems={selectPaymentTypes}
                  onSave={this.getHandleSelectByName('selectPaymentTypes')}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                />
              </div>
            </div>
          </div>

          <div className="DealerCommissionsExpectedChangesGrid-searchAction">
            <Button
              type="primary"
              onClick={this.fetchExpectedChangesList}
              disabled={!selectOwners.length || !selectPaymentTypes.length}
            >
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>

        <div className="DealerCommissionsExpectedChangesGrid-actions">
          {checkElement('dealerCommissionsExpectedChange-updateItem', this.currentArray) && (
            <Button
              type="primary"
              disabled={selectedItems.length !== 1}
              onClick={() => {
                this.setState({ modalVariant: 'update' });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('dealerCommissionsExpectedChange-deleteItem', this.currentArray) && (
            <Button
              disabled={!selectedItems.length}
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { expectedChangesList, isLoading, modalVariant, selectedItems } = this.state;
    const { translate } = this.props;

    if (!checkElement('dealerCommissionsExpectedChange-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={expectedChangesList}
          name="dealerCommissionsExpectedChangesGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchExpectedChangesList}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItems}
          isLoading={isLoading}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            columnMenu={false}
            sortable={false}
            showAllSelected={true}
          />
          <GridColumn
            field="OwnerId"
            title="grids.dealerCommissionServices.column-dealerId"
            width="120px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn field="OwnerName" title="grids.dealerCommissionServices.column-dealerName" width="200px" />
          <GridColumn
            field="ServiceId"
            title="grids.dealerCommissionServices.column-serviceId"
            width="120px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn field="ServiceName" title="grids.dealerCommissionServices.column-serviceName" width="200px" />
          <GridColumn
            field="ServiceGroupName"
            title="grids.dealerCommissionServices.column-service-group"
            width="200px"
          />
          <GridColumn field="SupplierName" title="grids.dealerCommissionServices.column-supplier" width="160px" />
          <GridColumn
            field="ServiceRule"
            title="grids.dealerCommissionServices.column-service-description-rule"
            width="200px"
          />
          <GridColumn
            field="DateStart"
            title="grids.dealerCommissionServices.column-commission-start-date"
            width="270px"
            cell={({ dataItem: { DateStart } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateStart)}</td>
            )}
            formatFilterCellData={(DateStart) => getTransformDateString(DateStart)}
          />
          <GridColumn
            field="TransitService"
            title="grids.dealerCommissionServices.column-transit-feature"
            width="100px"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="СommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="MinСommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission-min"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="MaxСommissionDealer"
            title="grids.dealerCommissionServices.column-dealer-commission-max"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="DealerCommissionClient"
            title="grids.dealerCommissionServices.column-commission-client"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="DealerСommissionClientMin"
            title="grids.dealerCommissionServices.column-commission-client-min"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="DealerСommissionClientMax"
            title="grids.dealerCommissionServices.column-commission-client-max"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommission"
            title="grids.dealerCommissionServices.column-ps-dealer-commission"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionMin"
            title="grids.dealerCommissionServices.column-ps-dealer-commission-min"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionMax"
            title="grids.dealerCommissionServices.column-ps-dealer-commission-max"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemCommissionClient"
            title="grids.dealerCommissionServices.column-ps-commission-client"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMin"
            title="grids.dealerCommissionServices.column-ps-commission-client-min"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn
            field="PaymentSystemСommissionClientMax"
            title="grids.dealerCommissionServices.column-ps-commission-client-max"
            width="250px"
            className="DealerCommissionsExpectedChangesGrid-centredCell"
          />
          <GridColumn field="PaymentType" title="grids.dealerCommissionServices.column-payment-type" width="180px" />
          <GridColumn
            field="DatePost"
            title="grids.dealerCommissionServices.column-commission-change-date"
            width="240px"
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
          />
          <GridColumn field="UserPost" title="grids.dealerCommissionServices.column-performer" width="180px" />
          <GridColumn
            field="ServiceStatusName"
            title="grids.dealerCommissionServices.column-service-status-dealer"
            width="250px"
          />
        </Grid>

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            modalTitle={translate('page.dealerCommissionsExpectedChanges.deletingExpectedChanges')}
            deleteContent={translate('page.dealerCommissionsExpectedChanges.areYouSure')}
            submitCallback={this.deleteExpectedChanges}
          />
        )}

        {modalVariant === 'update' && (
          <UpdateModal
            selectedItem={selectedItems[0]}
            visible={modalVariant === 'update'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchExpectedChangesList}
          />
        )}
      </>
    );
  }
}

export default withTranslate(DealerCommissionsExpectedChangesGrid);
