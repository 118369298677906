import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import './style.scss';

class DeleteBanknotesSettingsModal extends PureComponent {
  render() {
    const { translate, closeModal, visible, onBanknotesDelete } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        zIndex="1053"
        className="banknotesSettingsModalDelete"
        style={{ top: '100px', width: '590px' }}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.commission.btn-back')}
          </Button>,

          <Button
            key="submit"
            type="danger"
            ghost
            onClick={() => {
              onBanknotesDelete();
              closeModal();
            }}
          >
            {translate('page.commission.btn-delete')}
          </Button>,
        ]}
      >
        <div style={{ padding: '40px 8px 0', textAlign: 'center' }}>
          {translate('page.banknotesRestrictions.deleteTitle')}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(DeleteBanknotesSettingsModal);
