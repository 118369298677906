import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Select, Radio } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService, tabService, StorageService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
// import DropDownProfiles from '../../dropdown';
import '../modalProfile.scss';
import './modalCreateProfile.scss';

const api = new ApiService();
const { Option } = Select;

class ModalCreateNewProfile extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    owners: PropTypes.arrayOf(PropTypes.any).isRequired,
    isAdminRule: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { owners } = props;
    if (!owners || owners.length < 1) return;
    this.state = {
      loading: false,
      isLoading: false,
      selected: null,
      profiles: [],
      additionalOwnerId: 1,
      radioValue: 0,
      fullOwnersFromStorage: [],
    };
  }

  // getOptions = () => {
  //   const { additionalOwnerId } = this.state;
  //   return [
  //     { label: 'Системный профиль', value: 1 },
  //     { label: 'Персональный профиль', value: additionalOwnerId }
  //   ];
  // }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const { owners } = this.props;
    if (!owners || owners.length < 1) {
      this.setState({ additionalOwnerId: null });
      return;
    }

    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({ additionalOwnerId: 1, fullOwnersFromStorage: owners });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
      this.getProfiles();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { isAdminRule } = this.props;
    const { additionalOwnerId, radioValue } = this.state;
    if (prevState.radioValue !== radioValue && radioValue === 0) {
      this.setState({ additionalOwnerId: 1 });
    }
    if (isAdminRule === true && additionalOwnerId !== null && prevState.additionalOwnerId !== additionalOwnerId) {
      this.getProfiles();
    }
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  getOwnerId = async () => {
    const userInfo = StorageService.getItem('userInfo');
    if (userInfo.OwnerId || userInfo.OwnerId === 0) {
      return userInfo.OwnerId;
    }

    try {
      await this.setState({ isLoading: true });
      const response = await api.owners();

      this.setState({ isLoading: false });
      return response && response.length ? response[0].OwnerId : null;
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  calculateOwnerId = async () => {
    const { isAdminRule } = this.props;
    const { additionalOwnerId } = this.state;
    if (isAdminRule !== false) {
      return additionalOwnerId;
    }

    return await this.getOwnerId();
  };

  getProfiles = async () => {
    const { showError, owners } = this.props;
    if (!owners || owners.length < 1) return;
    else {
      try {
        this.toggleLoader();
        const ownerId = await this.calculateOwnerId();
        const profiles = await api.mainProfileListForModalChangeProfile([ownerId]);
        this.setState({ loading: false, profiles });
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  onChangeOwnerId = (additionalOwnerId) => {
    this.setState({ additionalOwnerId });
  };

  createProfile = () => {
    const { selected, profiles, additionalOwnerId } = this.state;
    const { closeModal, isAdminRule } = this.props;
    const currentOwnerId = profiles.find((el) => el.MainProfileId === Number(selected));
    let { OwnerId, MainProfileName, MainProfileHoldId, RegionId, MainProfileReserveId } = currentOwnerId;
    if (isAdminRule) {
      OwnerId = additionalOwnerId;
    }
    closeModal();
    tabService.addTab({
      type: 'newProfile',
      dataItem: {
        selected,
        OwnerId,
        MainProfileName,
        MainProfileHoldId,
        RegionId,
        isAdminRule,
        MainProfileReserveId,
      },
    });
  };

  onChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  render() {
    const { translate, visible, closeModal, owners, isAdminRule } = this.props;
    const { loading, isLoading, selected, profiles, additionalOwnerId, radioValue, fullOwnersFromStorage } = this.state;
    const defaultProfileValue = translate('page.screenControlBusinessServices.default-profile-value');
    return (
      <>
        <Modal
          title={translate('grids.profilesMainProfileList.modal-create-profile-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment modal-create-profile"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!selected || !owners || owners.length < 1}
              loading={loading}
              onClick={this.createProfile}
            >
              {translate('grids.profilesMainProfileList.modal-create-profile-btn-submit')}
            </Button>,
          ]}
        >
          {isAdminRule === true && (
            <Radio.Group defaultValue={0} onChange={this.onChangeRadio}>
              <Row
                className="modal-change-profile-row radio-wrapper"
                type="flex"
                style={{ flexWrap: 'wrap', marginBottom: '10px' }}
                align="middle"
                gutter={24}
                justify="space-between"
              >
                <Col style={{ marginTop: '10px' }} xs={24} lg={12}>
                  <Radio key="radio-Системный профиль" value={0}>
                    {translate('grids.profilesMainProfileList.modal-create-template-system')}
                  </Radio>
                </Col>
                <Col className="col-last" style={{ marginTop: '10px' }} xs={24} lg={12}>
                  <Radio key={'radio-Персональный профиль'} value={1}>
                    {translate('grids.profilesMainProfileList.modal-create-template-custom')}
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          )}
          {isAdminRule && (
            <Row
              className="modal-change-profile-row"
              type="flex"
              style={{ flexWrap: 'wrap', marginBottom: '10px' }}
              align="middle"
              gutter={24}
            >
              <Col xs={24} sm={24} md={8}>
                <p style={{ marginBottom: '0' }}>
                  {translate('grids.profilesMainProfileList.modal-create-template-dealer')}
                </p>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <Select
                  className="modal-system-encashment--select"
                  dropdownStyle={{ zIndex: '1054', width: '100%' }}
                  value={additionalOwnerId}
                  onChange={this.onChangeOwnerId}
                  filterOption={(input, option) =>
                    JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  disabled={radioValue === 0}
                >
                  {fullOwnersFromStorage.map((owner) => (
                    <Option value={owner.OwnerId} key={`owner-number-${owner.OwnerName}-${owner.OwnerId}`}>
                      {owner.OwnerName}
                    </Option>
                  ))}
                </Select>
                {/* <DropDownProfiles profiles={profiles} onChange={this.onSelectChange} /> */}
              </Col>
            </Row>
          )}
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>{translate('page.screenControlTerminals.change-profile-text')}</p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                placeholder={defaultProfileValue}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                filterOption={(input, option) =>
                  JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="children"
                showSearch
              >
                {profiles.map((profile) => (
                  <Option
                    value={`${profile.MainProfileId}`}
                    key={`profile-number-${profile.MainProfileName}-${profile.MainProfileId}`}
                  >
                    {profile.MainProfileName} - {profile.MainProfileId}
                  </Option>
                ))}
              </Select>
              {/* <DropDownProfiles profiles={profiles} onChange={this.onSelectChange} /> */}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCreateNewProfile);
