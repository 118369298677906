import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import { Button } from '@progress/kendo-react-buttons';
import Grid from '../../components/grids/baseGrid';
import DateRange from '../../components/daterange';
import CellRemoveLimit from './cellRemoveLimit';
import ModalAddLimit from './addLimit';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

const WrappedCellRemoveLimit = (props) => <CellRemoveLimit {...props} />;

class GridAccountLimit extends PureComponent {
  constructor(props) {
    super(props);
    const {
      item: { AccountId },
    } = this.props;
    if (!AccountId) return;
    this.currentArray = findArray('accountmanager');
  }

  componentDidMount() {
    this.makeQuery();
  }

  state = {
    isLoading: false,
    data: [],
    range: {
      start: null,
      end: null,
    },
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { range } = this.state;
    const {
      item: { AccountId },
    } = this.props;
    try {
      this.toggleLoader(true);
      const model = {
        AccountId: AccountId,
        DateStart: range.start,
        DateEnd: range.end,
      };
      const data = await api.accountLimit(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  buildToolbar = () => {
    const {
      translate,
      item,
      item: { OwnerName, AccountId },
    } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="issued-certificates--toolbar">
          <div style={{ marginLeft: '5px' }}>
            {`${translate('grids.accountsTurn.toolbar-bill')}`} #{AccountId} - {OwnerName}
          </div>
        </div>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <DateRange onRange={this.setRange} />
            {checkElement('accountsLimit-addLimit', this.currentArray) && <ModalAddLimit item={item} />}
          </Col>
          {checkElement('accountsLimit-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button
                  className="sm-w-100"
                  icon="search"
                  style={{ marginTop: '10px' }}
                  primary
                  onClick={this.makeQuery}
                >
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { data, isLoading } = this.state;
    return (
      <Grid
        data={data}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        onRefresh={this.makeQuery}
        name="accountsLimit"
        permissionName="accountmanager"
      >
        <GridColumn
          width="80"
          filterable={false}
          cell={checkElement('accountsLimit-onRemoveLimit', this.currentArray) ? WrappedCellRemoveLimit : null}
          // cell={WrappedCellRemoveLimit}
          columnMenu={false}
        />

        <GridColumn
          field="WorkDate"
          title="grids.accountsLimit.column-data"
          filter="date"
          format="dd.MM.yyyy"
          width="150"
        />

        <GridColumn field="OwnerName" title="grids.accountsLimit.column-owner" width="150" />

        <GridColumn field="AccountId" title="grids.account.column-accountId" filter="numeric" width="150" />

        <GridColumn field="Limit" title="grids.accountsLimit.column-limit" filter="numeric" width="150" />

        <GridColumn field="Percent" title="grids.accountsLimit.column-percent" filter="numeric" width="150" />
        <GridColumn field="Currency" title="grids.collectionDetails.column-currency" width="145" />

        <GridColumn field="UserNamePost" title="grids.accountsLimit.column-performer" width="150" />

        <GridColumn
          field="DatePost"
          title="grids.account.column-date"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
          width="150"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridAccountLimit);
