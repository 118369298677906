import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row, Input, Checkbox, message } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@telerik/kendo-intl';
import { ApiService, StorageService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import DateRange from '../../../../components/daterange';
import Grid from '../../../../components/grids/baseGrid';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import { getTransformDateString } from '../../../../utils/getTransformDateString';
import FormStateContainer from '../../../../components/FormStateContainer';
import ModalAddNewBanknotesSettings from '../banknotesModals/ModalAddNewBanknotesSettings';
import DeleteBanknotesSettingsModal from '../banknotesModals/ModalDeleteBanknotesSettings';

import { BANKNOTES_GRID_COLUMNS } from './constants';
import ManageBanknotesGQLService from './helper';
import { selectDataServicesConfig } from '../banknotesModals/constants';
import BanknotesDropdownNormalizersService from '../banknotesModals/helper';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import './style.scss';
import gqlService from '../../../../components/grids/gql.service';
import { lowerCaseFirstLetter } from '../../../../utils/lowerCaseFirstLetter';
import { getDataByTimezoneOffset } from '../../../../utils/getDataByTimezoneOffset';

const INITIAL_VALUES_ADD = {
  selectDataServices: [],
  selectDataNominalData: [],
};

const api = new ApiService();

class GridBanknotes extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlSettings');
  }

  state = {
    isLoading: false,
    data: [
      {
        DatePost: '',
        Nominal1: false,
        Nominal2: false,
        Nominal5: false,
        Nominal10: false,
        Nominal20: false,
        Nominal50: false,
        Nominal100: false,
        Nominal200: false,
        Nominal500: true,
        Nominal1000: false,
        ServiceId: 0,
        ServiceName: '',
        TerminalId: 0,
        UserIdPost: 0,
        UserName: '',
      },
    ],
    visibleAddNewBanknotesSettings: false,
    visibleDeleteBanknotesSettings: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectDataServicesData: [],
    selectDataServices: [],
    selectedBanknotes: [],
    changeConfig: false,
  };

  async componentDidMount() {
    await this.initRequestServiceDictionary();

    await this.fetchCallbackGQL();
  }

  initRequestServiceDictionary = async () => {
    const { showError, dataItem } = this.props;
    try {
      this.setState({ isLoading: true });
      const selectDataServices = await api.getServiceDictionary({ ownerId: dataItem.owners });

      this.setState({ selectDataServicesData: selectDataServices });
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchCallbackGQL = async (props) => {
    const {translate}= this.props
    const { rowAmount, pageValue, filtersString, sortString, selectDataServices, selectDataServicesData } = this.state;
    try {
      this.setState({ isLoading: true });

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order: ${
        sortString || '{ terminalId: ASC }'
      },where:{and:[{terminalId:{in:[${this.props.dataItem.terminals
        .map((item) => item.TerminalId)
        .join(',')}]}} , ${filtersString}]}`;

      const argumentsStringService = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order: ${
        sortString || '{ terminalId: ASC }'
      },where:{and:[{terminalId:{in:[${this.props.dataItem.terminals
        .map((item) => item.TerminalId)
        .join(',')}]}},{serviceId:{in:[${selectDataServices.join(',')}]}} , ${filtersString}]}`;

      const storageColumns = StorageService.getItem('banknotesGrid');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? gqlService.getColumnsFromStorage(storageColumns) : BANKNOTES_GRID_COLUMNS);

      const query = `terminalServiceDenomination(${
        selectDataServices.length === 0 ? argumentsString : argumentsStringService
      }){items{${currentColumns
        .filter((item) => item !== 'serviceName')
        .join(',')}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { banknotesData, hasPreviousPage, hasNextPage } = ManageBanknotesGQLService.getGQLResponse(
        data,
        selectDataServicesData,
        translate,
      );

      this.setState({ data: banknotesData, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };

  getHandleExelExportDate = async () => {
    return await this.state.data;
  };

  onFieldsConfigChange = (list) => {
    this.fetchCallbackGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
        currentEditRow: null,
      });

      await this.fetchCallbackGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  onServices = (selectDataServices) => {
    this.setState({ selectDataServices }, () => {
      this.fetchCallbackGQL();
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectDataServicesData, selectDataServices, selectedBanknotes, data, changeConfig } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {selectDataServicesData.length !== 0 && (
          <GridDropdown
            data={BanknotesDropdownNormalizersService.normalizeService(selectDataServicesData, translate,true)}
            colConfig={selectDataServicesConfig}
            selectItems={selectDataServices}
            onSave={this.onServices}
            defaultTitle={translate('page.commissionsServices.chooseService')}
          />
        )}
        {checkElement('screenControlSettings-canAddBanknotesNominal', this.currentArray) && (
          <Button
            className="sm-w-100"
            icon="add"
            style={{ marginTop: '10px' }}
            primary
            onClick={() => this.setState({ visibleAddNewBanknotesSettings: true })}
          >
            {translate('page.banknotesRestrictions.addNew')}
          </Button>
        )}
        {checkElement('screenControlSettings-canDeleteBanknotesNominal', this.currentArray) && (
          <Button
            icon="delete"
            style={{ marginTop: '10px' }}
            type="primary"
            disabled={selectedBanknotes.length === 0}
            onClick={() => this.setState({ visibleDeleteBanknotesSettings: true })}
          >
            {translate('page.banknotesRestrictions.delete')}
          </Button>
        )}
        {checkElement('screenControlSettings-canUpdateBanknotesNominal', this.currentArray) && (
          <Button
            icon="refresh"
            className={`ant-btn ant-btn-primary ${changeConfig ? 'changeRules' : ''}`}
            style={{ marginTop: '10px' }}
            type="primary"
            onClick={this.updateBanknotesConfig}
            disabled={data.length === 0 && !changeConfig}
          >
            {translate('page.banknotesRestrictions.update')}
          </Button>
        )}
      </Col>
    );
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  onSelect = (selectedBanknotes) => {
    this.setState({ selectedBanknotes });
  };

  updateBanknotesConfig = async () => {
    const { translate, dataItem } = this.props;
    try {
      this.setState({ isLoading: true });
      const terminalIds = dataItem.terminals.map((item) => item.TerminalId);

      await api.TerminalsServicesDenominationVersionConfigUpdate([...new Set(terminalIds)]);
      this.fetchCallbackGQL();
      message.success(`${translate(`page.banknotesRestrictions.successUpdate`)}`, 2.5);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  deleteBanknotesSettings = async () => {
    const { selectedBanknotes } = this.state;
    const { translate } = this.props;

    try {
      this.setState({ isLoading: true });

      await api.TerminalsServicesDenominationDelete(
        selectedBanknotes.map((item) => ({ terminalId: item.terminalId, serviceId: item.serviceId })),
      );
      this.fetchCallbackGQL();
      message.success(`${translate(`page.banknotesRestrictions.successDelete`)}`, 2.5);
      this.setState({ changeConfig: true });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render = () => {
    const { isLoading, data, pageValue, hasNextPage, hasPreviousPage, selectDataServicesData } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.fetchCallbackGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="banknotesGrid"
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageChange={this.getStateSetterByName('pageValue')}
          onFieldsConfigChange={this.onFieldsConfigChange}
          getHandleExelExportDate={this.getHandleExelExportDate}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          isGQL
          selectedField="selected"
          onSelect={this.onSelect}
          fieldForSelection="customId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="terminalId" title="grids.transactions.column-terminalId" width="150" />

          <GridColumn field="serviceId" title="grids.terminal-events.column-serviceId" width="150" filter="numeric" />

          <GridColumn
            field="userIdPost"
            title="grids.businessServices.column-userIdPost"
            width="180"
            filter="numeric"
          />
          <GridColumn
            field="datePost"
            title="grids.businessServices.column-datePost"
            width="150"
            cell={({ dataItem: { datePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(getDataByTimezoneOffset(datePost))}</td>
            )}
            formatFilterCellData={(datePost) => getTransformDateString(getDataByTimezoneOffset(datePost))}
          />

          <GridColumn field="serviceName" title="grids.reportUsersInStopListGet.column-serviceName" width="300" />

          <GridColumn field="userName" title="page.changingProfileHistory.col-executor" width="200" />
          <GridColumn
            field="nominal1"
            title="page.banknotesRestrictions.nominal1"
            width="80"
            cell={({ dataItem: { nominal1 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal1} checked={nominal1} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal2"
            title="page.banknotesRestrictions.nominal2"
            width="80"
            cell={({ dataItem: { nominal2 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal2} checked={nominal2} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal5"
            title="page.banknotesRestrictions.nominal5"
            width="80"
            cell={({ dataItem: { nominal5 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal5} checked={nominal5} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal10"
            title="page.banknotesRestrictions.nominal10"
            width="100"
            cell={({ dataItem: { nominal10 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal10} checked={nominal10} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal20"
            title="page.banknotesRestrictions.nominal20"
            width="100"
            cell={({ dataItem: { nominal20 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal20} checked={nominal20} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal50"
            title="page.banknotesRestrictions.nominal50"
            width="100"
            cell={({ dataItem: { nominal50 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal50} checked={nominal50} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />

          <GridColumn
            field="nominal100"
            title="page.banknotesRestrictions.nominal100"
            width="100"
            cell={({ dataItem: { nominal100 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal100} checked={nominal100} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />
          <GridColumn
            field="nominal200"
            title="page.banknotesRestrictions.nominal200"
            width="100"
            cell={({ dataItem: { nominal200 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal200} checked={nominal200} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />
          <GridColumn
            field="nominal500"
            title="page.banknotesRestrictions.nominal500"
            width="100"
            cell={({ dataItem: { nominal500 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal500} checked={nominal500} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />
          <GridColumn
            field="nominal1000"
            title="page.banknotesRestrictions.nominal1000"
            width="100"
            cell={({ dataItem: { nominal1000 } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox defaultChecked={nominal1000} checked={nominal1000} disabled style={{ marginTop: '4px' }} />
              </td>
            )}
          />
        </Grid>

        {this.state.visibleAddNewBanknotesSettings && (
          <FormStateContainer initialValues={INITIAL_VALUES_ADD}>
            <ModalAddNewBanknotesSettings
              visible={this.state.visibleAddNewBanknotesSettings}
              closeModal={() => this.setState({ visibleAddNewBanknotesSettings: false })}
              terminals={this.props.dataItem.terminals.map((item) => item.TerminalId)}
              owners={this.props.dataItem.owners}
              refetch={() => {
                this.fetchCallbackGQL();
                this.setState({ changeConfig: true });
              }}
              selectDataServicesData={selectDataServicesData}
            />
          </FormStateContainer>
        )}

        {this.state.visibleDeleteBanknotesSettings && (
          <DeleteBanknotesSettingsModal
            visible={this.state.visibleDeleteBanknotesSettings}
            closeModal={() => this.setState({ visibleDeleteBanknotesSettings: false })}
            onBanknotesDelete={this.deleteBanknotesSettings}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridBanknotes);
