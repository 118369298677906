import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button, Input, Popconfirm, Modal } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import './gridIssuedCertificates.scss';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

class GridIssuedCertificates extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray(props.currentPage);
  }

  state = {
    isLoading: false,
    data: [],
    visible: false,
    popupOkVisibility: false,
    afterCancelPopup: false,
    newCode: '',
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  openPopupOkVisibility = () => {
    this.setState({ popupOkVisibility: true });
  };

  makeQuery = async () => {
    const { terminalId } = this.props;
    if (!terminalId) return;
    try {
      this.toggleLoader(true);
      const data = await api.terminalCertificatesList(terminalId);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  onCancelAfterCancel = () => {
    this.setState({ afterCancelPopup: false });
  };

  renderPopupOk = () => {
    const ReInstall = this.checkLastCertificateExpiringDate();
    return Modal.info({
      content: (
        <div>
          <p>{this.renderPopupOkMessage(ReInstall)}</p>
        </div>
      ),
      onOk() {},
    });
  };

  renderPopupOkMessage = (value) => {
    const { translate } = this.props;
    const { newCode } = this.state;
    if (value === true) return `${translate('grids.certificates-issued.popupOkTrue')}`;
    return ` ${newCode}`;
  };

  checkLastCertificateExpiringDate = () => {
    const { data } = this.state;
    if (!data) return;
    let bufferValue;
    // eslint-disable-next-line array-callback-return
    data.filter((elem) => {
      for (let i = 0; i < data.length; i++) {
        if (elem.ExpiryDate > data[i].ExpiryDate) {
          bufferValue = elem.ExpiryDate;
        } else {
          bufferValue = data[i].ExpiryDate;
        }
      }
    });
    const dt = new Date();
    const diff = bufferValue - dt;
    const bufferValueToDate = parseInt(Math.floor(diff / 60000 / 1440));
    // Условие будет отрабатывать когда разница будет меньше или равна 7 дням
    if (bufferValueToDate > 0 && bufferValueToDate <= 7) return true;
    else return false;
  };

  onConfirm = () => {
    this.getNewCertificate();
  };

  getNewCertificate = async () => {
    const ReInstall = this.checkLastCertificateExpiringDate();
    const { terminalId: TerminalId, showError } = this.props;
    if (!TerminalId) return;
    try {
      this.toggleLoader(true);
      const model = {
        TerminalId,
        ReInstall,
      };
      const response = await api.certificateInstall(model);
      this.toggleVisibility();
      this.setState({ newCode: response }, () => {
        this.renderPopupOk();
        this.makeQuery();
      });
    } catch (error) {
      showError(error);
    } finally {
      this.onCancel();
      this.toggleLoader(false);
    }
  };

  toggleVisibility = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  buildToolbar = () => {
    const { translate, terminalId, version, currentPage } = this.props;
    const { visible, afterCancelPopup } = this.state;
    const ReInstall = this.checkLastCertificateExpiringDate();
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="issued-certificates--toolbar">
          <p>{translate('grids.certificates-issued.col-terminalId')}</p>
          <Input value={terminalId} disabled />
        </div>
        {checkElement(`${currentPage}-newCertificate`, this.currentArray) && (
          <Button style={{ margin: '10px 0 0 0' }} type="primary" onClick={this.toggleVisibility}>
            {translate('grids.certificates-issued.btn-new')}
          </Button>
        )}
        <Popconfirm
          visible={visible}
          title={this.renderPopupTitle(ReInstall)}
          onConfirm={this.onConfirm}
          onCancel={() => this.onCancelHandler(ReInstall)}
          okText={translate('grids.certificates-issued.onOkText')}
          cancelText={translate('grids.certificates-issued.onCancelText')}
        />
        <Popconfirm
          visible={afterCancelPopup}
          title={translate('grids.certificates-issued.popupConfirmFalse')}
          onConfirm={this.onConfirm}
          onCancel={this.onCancelAfterCancel}
          okText={translate('grids.certificates-issued.onOkText')}
          cancelText={translate('grids.certificates-issued.onCancelText')}
        />
      </Col>
    );
  };

  onCancelHandler = (value) => {
    if (value === true) {
      this.toggleVisibility();
      this.setState({ afterCancelPopup: true });
    }
    return this.onCancel();
  };

  renderPopupTitle = (value) => {
    const { translate } = this.props;
    if (value === true) return `${translate('grids.certificates-issued.popupConfirmTitleOk')}`;
    else return `${translate('grids.certificates-issued.popupConfirmFalse')}`;
  };

  checkVersion = (version) => {
    const stableTerminalVersion = 3.1;
    if (version === null) return false;
    const value = parseFloat(version);
    if (value >= stableTerminalVersion) return true;
    return false;
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="terminalsCertificatesList"
        permissionName="certificates"
      >
        <GridColumn field="CertificateId" title="grids.certificates-issued.col-certificateId" width="150" />

        <GridColumn field="Code" title="grids.certificates-issued.col-code" width="240" />

        <GridColumn field="RequestId" title="grids.certificates-issued.col-requestId" width="140" />

        <GridColumn field="TerminalId" title="grids.certificates-issued.col-terminalId" width="150" />

        <GridColumn
          field="StartDate"
          title="grids.certificates-issued.col-date-start"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="ExpiryDate"
          title="grids.certificates-issued.col-expiry-date"
          width="150"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="Status" title="grids.certificates-issued.col-status" width="150" />

        <GridColumn field="UserNamePost" title="grids.certificates-issued.col-user-name-post" width="145" />

        <GridColumn
          field="DatePost"
          title="grids.certificates-issued.col-date-post"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridIssuedCertificates);
