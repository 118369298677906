import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService, StorageService } from '../../services';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, Select } from 'antd';

import './PreviewApplicationGrid.scss';

import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constants';
import PreviewApplicationService from './previewApplicationService';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { formatDate } from '@telerik/kendo-intl';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const { Option } = Select;

const api = new ApiService();

const convertToISO = (date) => formatDate(getDataByTimezoneOffset(date), 'yyyy.MM.dd HH:mm:ss');

class PreviewApplicationGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('previewApplication');
  }
  state = {
    data: [],
    selectedItems: [],
    owners: [],
    statusApplication: {},
    chosenStatusApplication: '',
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const owners = await api.owners();
      await this.setState({ owners });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchStatusApplication = async () => {
    try {
      this.setState({ isLoading: true });
      const statusApplication = await api.getTerminalRegistrationApplicationAction();
      this.setState({ statusApplication, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  terminalRegistrationApplicationsGQL = async (props) => {
    const { filtersString, rowAmount, pageValue, selectedItems, chosenStatusApplication, sortString } = this.state;
    const currentFilterString =
      props && props.selectIdsString
        ? `{and:[${filtersString}, {terminalId:{in:[${props.selectIdsString}]}}]}`
        : filtersString;

    try {
      const storageColumns = StorageService.getItem('PreviewStatusApplicationGrid');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns) : ALL_GRID_COLUMNS);
      const argumentsString = `take:${rowAmount},skip:${rowAmount * (pageValue - 1)},order:${
        sortString || '{prroId: ASC}'
      },where:{and:[{ownerId:{in:[${selectedItems}]}},{action: {in:[${chosenStatusApplication
        .split(',')
        .map((item) => `"${item}"`)}]}},  ${currentFilterString}]}`;

      const query = `terminalRegistrationApplications(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;
      this.setState({ isLoading: true });
      const data = await api.getByGraphQl(query);
      const { terminalRegistrationApplicationsService, hasNextPage, hasPreviousPage } =
        PreviewApplicationService.getGQLResponse(data);
      this.setState({ data: terminalRegistrationApplicationsService, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  handleOwnersChange = (selectedItems) => {
    this.setState({ selectedItems });
  };
  onHandleSelect = (target) => {
    this.setState({ chosenStatusApplication: `${target}` });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }
      await this.terminalRegistrationApplicationsGQL({ selectIdsString });
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.terminalRegistrationApplicationsGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.terminalRegistrationApplicationsGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };
  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };
  filterOwnersByStatus = (owners, isWithDeleted) => {
    return isWithDeleted ? owners : owners.filter((owner) => owner.OwnerStatusId !== 2);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, selectedItems, statusApplication, chosenStatusApplication } = this.state;
    return (
      <Col className="PreviewApplicationGrid-toolbar">
        <div className="PreviewApplicationGrid-row">
          <div className="PreviewApplicationGrid-col">
            <div className="PreviewApplicationGrid-row">
              <div className="PreviewApplicationGrid-label">{translate('grids.commission-profiles.dealers')}</div>
              <div className="PreviewApplicationGrid-dropdown">
                <GridDropdownWithDeletedOwners
                  data={DropdownNormalizersService.normalizeOwners(owners)}
                  selectItems={selectedItems}
                  onSave={this.handleOwnersChange}
                  defaultTitle={translate('owner.initial-choose')}
                  isGetDefaultSelectItemsFromStorage
                />
              </div>
            </div>
            <div className="PreviewApplicationGrid-row">
              <div className="PreviewApplicationGrid-label">
                {translate('page.previewStatusApplicationGrid.label-type')}
              </div>
              <Select
                defaultValue={[]}
                onChange={this.onHandleSelect}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                mode="multiple"
                placeholder={translate('page.previewStatusApplicationGrid.default-title')}
                className="PreviewApplicationGrid-select"
              >
                {Object.keys(statusApplication).map((item) => (
                  <Option key={item} value={statusApplication[item]}>
                    {statusApplication[item]}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {checkElement('previewApplication-search-btn', this.currentArray) && (
            <Button type="primary" onClick={this.terminalRegistrationApplicationsGQL}>
              {translate('core.buttonTitles.search')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  fetchInitData = async () => {
    await this.fetchOwners();
    await this.fetchStatusApplication();
  };

  componentDidMount() {
    this.fetchInitData();
  }

  render() {
    const { data, pageValue, hasNextPage, hasPreviousPage } = this.state;

    if (!checkElement('previewApplication-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={data}
        toolbar={this.buildToolbar()}
        onRefresh={this.terminalRegistrationApplicationsGQL}
        name="PreviewStatusApplicationGrid"
        handleRowAmountChange={this.handleRowAmountChange}
        pageValue={pageValue}
        setFiltersString={this.setFiltersString}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        setSortingString={this.setSortingString}
        // onFieldsConfigChange={this.onFieldsConfigChange}
        onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
        onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
        isGQL
        isShowSpecificFilterByFieldName="TerminalId"
      >
        <GridColumn field="OwnerName" title="grids.previewStatusApplicationGrid.col-name" width="250px" />
        <GridColumn field="PrroId" title="grids.previewStatusApplicationGrid.col-terminalId" width="150px" />
        <GridColumn field="Uuid" title="grids.previewStatusApplicationGrid.col-application-id" width="150px" />
        <GridColumn field="Status" title="grids.previewStatusApplicationGrid.col-status-application" width="150px" />
        <GridColumn field="Action" title="grids.previewStatusApplicationGrid.col-type-application" width="150px" />
        <GridColumn
          field="RequestTime"
          format="dd.MM.yyy HH:mm:ss"
          filter="date"
          title="grids.previewStatusApplicationGrid.col-date-application"
          width="150px"
          cell={({ dataItem: { RequestTime } }) => <td style={{ textAlign: 'center' }}>{convertToISO(RequestTime)}</td>}
        />
        <GridColumn field="ErrorMessage" title="grids.previewStatusApplicationGrid.col-err-msg" width="150px" />
        <GridColumn
          field="PrroFiscalNumberNew"
          title="grids.previewStatusApplicationGrid.col-fiscla-number"
          width="190px"
        />
      </Grid>
    );
  }
}

export default withTranslate(PreviewApplicationGrid);
