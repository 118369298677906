import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../../components/grids/baseGrid';
import { ApiService, tabService } from '../../../../services';
import {
  AGGREGATE_GRID_CONFIG,
  BASE_GRID_CONFIG,
  PERMISSION_BY_REPORT_TYPE_MAP,
  REQUEST_METHOD_BY_REPORT_TYPE_MAP,
} from './constants';
import CellProgress from '../../../monitorActivity/cellProgress';
import LoadingPanel from '../../../../components/loader';
import { withTranslate } from '../../../../contexts/localContext';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';

const api = new ApiService();

class gridTerminalsPeriod extends Component {
  state = {
    isLoading: false,
    data: [],
  };

  componentDidMount() {
    this.getTerminalsByPeriod();
  }

  getTerminalsByPeriod = async () => {
    const { dateStart, dateEnd, terminalList, ownerId, reportType } = this.props;
    const requestMethod = REQUEST_METHOD_BY_REPORT_TYPE_MAP.get(reportType) || api.terminalsEfficiencyPeriod;

    this.setState({ isLoading: true });

    try {
      const data = await requestMethod({
        dateStart,
        dateEnd,
        ...(reportType === 'monitoring' ? { terminalList } : { terminalId: terminalList }),
        terminalId: terminalList,
        ownerId: reportType === 'monitoring' ? ownerId : [ownerId],
      });

      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  getAggregate = () => {
    const { reportType } = this.props;

    const baseAggregate = new Array(31).fill(0).map((_, index) => ({
      field: `Day${index + 1}`,
      aggregate: 'average',
      format: 'n2',
    }));

    return [
      ...baseAggregate,
      { field: reportType === 'monitoring' ? 'AvgValue' : 'WorkTotal', aggregate: 'average', format: 'n2' },
    ];
  };

  getWorkDate = (field, dateStart) => {
    const convertedField = parseInt(field.match(/\d+/), 10);
    const firstDay = new Date(dateStart);
    const returnDate = new Date(firstDay.setDate(convertedField));
    const daysInMonth = new Date(firstDay.getFullYear(), firstDay.getMonth(), 0).getDate();
    if (convertedField > daysInMonth) return null;
    else return returnDate;
  };

  createDayTab = (props) => {
    const { dateStart, ownerId, reportType } = this.props;
    if (!checkElement('terminalOperabilityPeriod-createDayTab', findArray('terminalOperabilityPeriod'))) return null;

    const requestDate = this.getWorkDate(props.field, dateStart);

    if (!requestDate) {
      return;
    }

    return tabService.addTab({
      type: 'day',
      dataItem: props.dataItem,
      model: { OwnerId: ownerId, ReportType: reportType },
      field: props.field,
      workDate: requestDate,
    });
  };

  createMonitoringTabDay = (day) => {
    const { dateStart, ownerId, terminalList } = this.props;
    const year = new Date(dateStart).getFullYear();
    const month = new Date(dateStart).getMonth();
    const fullDate = new Date(year, month, day, 0, 0, 0, 0);
    tabService.addTab({
      type: 'monitoringDay',
      dayInfo: {
        fullDate: fullDate,
        ownerId: ownerId,
        terminalList: terminalList,
      },
    });
  };

  getDayCell =
    (dayNumber) =>
    ({ dataItem: { [`Day${dayNumber}`]: fieldValue } }) =>
      (
        <td
          onClick={() => this.createMonitoringTabDay(dayNumber)}
          style={{ textAlign: 'center', color: '#1890ff', cursor: 'pointer' }}
        >
          {Math.round(fieldValue * 100) / 100}
        </td>
      );

  renderColumn = () => {
    const { reportType } = this.props;

    const dayInfoGridConfig = new Array(31).fill(0).map((_, index) => ({
      fieldName: `Day${index + 1}`,
      titleSlug: `${index + 1}`,
      width: '80',
      filter: 'numeric',
      onClick: reportType !== 'monitoring' ? (props) => this.createDayTab(props) : null,
      cell: reportType === 'monitoring' ? this.getDayCell(index + 1) : null,
    }));

    const rawColumnConfig = [...BASE_GRID_CONFIG, ...dayInfoGridConfig, ...AGGREGATE_GRID_CONFIG];

    return rawColumnConfig
      .filter(({ permission }) => !permission || permission.includes(reportType))
      .map(({ fieldName, titleSlug, width, filter, onClick, cell }) => (
        <GridColumn
          field={fieldName}
          title={titleSlug}
          width={width}
          filter={filter}
          {...(fieldName === 'WorkTotal' ? { cell: (props) => <CellProgress {...props} /> } : {})}
          {...(onClick ? { onClick } : {})}
          {...(cell ? { cell } : {})}
        />
      ));
  };

  render() {
    const { reportType } = this.props;
    const { data, isLoading } = this.state;

    return (
      <>
        <Grid
          data={data}
          toolbar={<div></div>}
          aggregate={this.getAggregate()}
          permissionName="terminalOperabilityPeriod"
          name={PERMISSION_BY_REPORT_TYPE_MAP.get(reportType) || 'terminalOperabilityPeriod'}
        >
          {this.renderColumn()}
        </Grid>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(gridTerminalsPeriod);
