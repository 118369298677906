export const mocked = [
  {
    RecordNumber: 1,
    CategoryName: 'Category1',
    CategoryId: 1,
    ServiceGroup: 'Group1',
    ServiceName: 'Service1',
    ButtonId: 1,
    ServiceId: 1,
    Cashback: 'Cashback1',
    Performer: 'Performer1',
    Date: new Date().toISOString(),
  },
  {
    RecordNumber: 2,
    CategoryName: 'Category2',
    CategoryId: 2,
    ServiceGroup: 'Group2',
    ServiceName: 'Service2',
    ButtonId: 2,
    ServiceId: 2,
    Cashback: 'Cashback2',
    Performer: 'Performer2',
    Date: new Date().toISOString(),
  },
  {
    RecordNumber: 3,
    CategoryName: 'Category3',
    CategoryId: 3,
    ServiceGroup: 'Group3',
    ServiceName: 'Service3',
    ButtonId: 3,
    ServiceId: 3,
    Cashback: 'Cashback3',
    Performer: 'Performer3',
    Date: new Date().toISOString(),
  },
];

export const ButtonList = [
  {
    menuId: 1,
    serviceName: 'Category1',
    serviceId: 4,
  },
  {
    menuId: 2,
    serviceName: 'Category2',
    serviceId: 5,
  },
  {
    menuId: 3,
    serviceName: 'Category3',
    serviceId: 6,
  },
];
