import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { DAY_CASES_TRANSCRIPTIONS_MAP, ExcessTokenLogin } from './constants';

const api = new ApiService();

class ExpiredPasswordInfoModal extends PureComponent {
  state = {
    content: 'warningMessage',
  };

  navigateToFormUrl() {
    const { history, onCloseModal, numberDraysBeforePasswordExpires, dialogStuff } = this.props;

    if (numberDraysBeforePasswordExpires < 0) {
      history.push(`${dialogStuff}`);
    } else {
      history.push(`${history.location.pathname}?login=${ExcessTokenLogin}`);
    }

    onCloseModal();
  }

  getDialogSubmitCallback = () => {
    const { content } = this.state;

    if (content === 'warningMessage') {
      this.navigateToFormUrl();
    }
  };

  handleClose = () => {
    const { onCloseModal, numberDraysBeforePasswordExpires } = this.props;

    const onClose = !numberDraysBeforePasswordExpires || numberDraysBeforePasswordExpires > 0;

    if (onClose) {
      onCloseModal();
    }
  };

  getContent = () => {
    const { numberDraysBeforePasswordExpires, translate } = this.props;

    const dayCase = translate(
      `${DAY_CASES_TRANSCRIPTIONS_MAP.get(+numberDraysBeforePasswordExpires) || 'core.wordCases.day.many'}`,
    );

    switch (true) {
      case numberDraysBeforePasswordExpires > 0:
        return `${translate(
          'page.expiredPasswordInfoModal.passwordWillExpirePrefix',
        )} ${numberDraysBeforePasswordExpires} ${dayCase}. ${translate(
          'page.expiredPasswordInfoModal.passwordWillExpirePostfix',
        )}`;

      case numberDraysBeforePasswordExpires < 0:
        return translate('page.expiredPasswordInfoModal.passwordWillExpire');

      case numberDraysBeforePasswordExpires === 0:
        return translate('page.expiredPasswordInfoModal.passwordWillExpireSoon');

      default:
        return translate('page.expiredPasswordInfoModal.passwordWillExpireSoon');
    }
  };

  render() {
    const { translate, onCloseModal, numberDraysBeforePasswordExpires } = this.props;

    return (
      <Modal
        visible
        closable={!numberDraysBeforePasswordExpires || numberDraysBeforePasswordExpires > 0}
        onCancel={this.handleClose}
        title={translate('page.expiredPasswordInfoModal.passwordChange')}
        zIndex="1053"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => this.getDialogSubmitCallback()}
            style={{ margin: '0 auto' }}
          >
            {translate('core.buttonTitles.ok')}
          </Button>,

          <Button
            type="primary"
            disabled={numberDraysBeforePasswordExpires === -1}
            onClick={onCloseModal}
            style={{ margin: '0 auto' }}
          >
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>{this.getContent()}</div>
      </Modal>
    );
  }
}

export default withTranslate(ExpiredPasswordInfoModal);
