import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import './modalGoogleMap.scss';
import GoogleMapComponent from './googleMap';

class ModalGoogleMap extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    // toggleModal: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { currentAddress, currentGeopoint } = props;
    this.state = {
      currentAddress,
      currentGeopoint,
    };
  }

  getInitialGeopoint = () => {};

  onGeopointChange = (currentGeopoint) => {
    if (currentGeopoint && currentGeopoint !== '') {
      this.setState({ currentGeopoint });
    }
  };

  onGoogleMapChangeAddress = (currentAddress) => {
    if (currentAddress) {
      const arrFromStr = currentAddress.split(',');
      const shortAddress = [arrFromStr[0], arrFromStr[1]].join('');
      this.setState({ currentAddress: shortAddress });
    }
  };

  onOkHandler = () => {
    const { currentAddress, currentGeopoint } = this.state;
    if (!currentAddress || currentAddress === '' || !currentGeopoint || currentGeopoint === '') return;
    else {
      const { onChange, closeModal, onChangeGeopoint, onToggleShowApproveAddress } = this.props;
      onChange(currentAddress);
      onChangeGeopoint(currentGeopoint);
      closeModal();
      onToggleShowApproveAddress();
    }
  };

  render() {
    const { visible, closeModal, translate, currentAddress, cities, cityId } = this.props;
    return (
      <>
        <Modal
          title={translate('page.terminalSettings.modal-map-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment modal-google-map"
          // zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.screenControlBusinessServices.modal-set-btn-back-btn')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              // disabled={isDisabledButton}
              // onClick={() => changeImage(dataItem.RowIndex, buttonValue)}
              onClick={this.onOkHandler}
            >
              {translate('page.screenControlBusinessServices.modal-set-btn-submit-btn')}
            </Button>,
          ]}
        >
          <GoogleMapComponent
            currentAddress={currentAddress}
            onChange={this.onGoogleMapChangeAddress}
            cities={cities}
            cityId={cityId}
            onChangeGeopoint={this.onGeopointChange}
          />
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalGoogleMap);
