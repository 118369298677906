import React, { PureComponent } from 'react';
import { Button } from 'antd';

import { withTranslate } from '../../contexts/localContext';

import './RadioButtonGroup.styled.scss';

class RadioButtonGroup extends PureComponent {
  render() {
    const { items, value, onValueChange, translate } = this.props;

    return (
      <div className="CustomRadioButtonGroupContainer">
        {items.map(({ value: itemValue, title }) => (
          <Button
            type={itemValue === value ? 'primary' : 'default'}
            onClick={() => {
              onValueChange(itemValue);
            }}
          >
            {translate(title)}
          </Button>
        ))}
      </div>
    );
  }
}

export default withTranslate(RadioButtonGroup);
