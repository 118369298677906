class SUOService {
  getTaxerIdValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (isNaN(+value) || !Number.isInteger(+value)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }
    return '';
  };

  getValidators = (translate) => ({
    TaxerId: this.getTaxerIdValidation(translate),
  });
}

export default new SUOService();
