import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';

const api = new ApiService();

const statuses = ['all', 'active', 'disabled', 'arested', 'deleted', 'testing'];

class GridTechInform extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      statusValue: statuses[0],
    };
  }

  componentDidMount() {
    this.makeQuery();
  }

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '0.5' : '0.25';
    switch (value) {
      case 'активный':
        return `rgba(92, 184, 92, ${opacity})`;
      case 'неактивный':
        return `rgba(240, 173, 78, ${opacity})`;
      case 'неисправен':
      case 'арестован':
        return `rgba(217, 83, 79, ${opacity})`;
      case 'тестируется':
        return `rgba(91, 192, 222, ${opacity})`;
      case 'отключен':
      case 'удален':
        return `rgba(217, 83, 79, ${opacity})`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.StatusState;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    try {
      this.toggleLoader(true);
      const data = await api.getTechnicInfo();
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => (
    <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
      <p></p>
    </Col>
  );

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        rowRender={this.rowRender}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        selectedField="selected"
        toggleLoader={this.toggleLoader}
        name="techInform"
        permissionName="tech-inform"
      >
        {/* <GridColumn
          title="grids.tech-inform.column-terminal"
        > */}
        <GridColumn field="TerminalId" title="grids.tech-inform.column-terminalId" width="150" filter="numeric" />
        <GridColumn field="Name" title="grids.tech-inform.column-name" width="250" />
        <GridColumn field="CityName" title="grids.tech-inform.column-city" width="200" />
        <GridColumn field="TerminalAddress" title="grids.tech-inform.column-street" width="250" />
        {/* </GridColumn> */}

        {/* <GridColumn
          title="grids.tech-inform.column-status"
        > */}
        <GridColumn
          field="DateLastConnect"
          title="grids.tech-inform.column-lastConnect"
          width="210"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn
          field="DateLastTransaction"
          title="grids.tech-inform.column-lastTransaction"
          width="210"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="StatusState" title="grids.tech-inform.column-state" width="145" />
        <GridColumn field="StatusDetails" title="grids.tech-inform.column-details" width="120" />
        {/* </GridColumn> */}
      </Grid>
    );
  };
}

export default withTranslate(GridTechInform);
