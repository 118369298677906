import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Checkbox, Button, Row, Col } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './modalConfirmDeviceCode.scss';
import LoadingPanel from '../../../components/loader';
import { ApiService, StorageService } from '../../../services';
import aes from 'crypto-js/aes';
const api = new ApiService();

class ModalConfirmDeviceCode extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleNavigation: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    loading: false,
    codeValue: '',
    isTrusted: false,
    access_token: StorageService.getItem('access_token'),
  };

  onChangeCodeValue = (e) => {
    this.setState({ codeValue: e.target.value });
  };

  onChangeIsTrusted = (e) => {
    this.setState({ isTrusted: e.target.checked });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  onConfirmHandler = async () => {
    try {
      const { codeValue, isTrusted, access_token } = this.state;
      const { handleNavigation } = this.props;
      this.setState({ loading: true });
      const model = {
        ConfirmCode: codeValue,
        SaveCode: isTrusted,
      };
      StorageService.setItem('access_token', access_token);
      const res = await api.usersTrustedDevicesConfirm(model);
      if (isTrusted) {
        const hash = aes.encrypt(JSON.stringify(res.DeviceCode), '123456789').toString();
        StorageService.setItem('devideCode', hash);
      }
      handleNavigation();
    } catch (error) {
      StorageService.removeItem('access_token');
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { isLoading, loading, codeValue, isTrusted } = this.state;
    const { visible, translate, closeModal } = this.props;
    // console.log(this.state.access_token)
    return (
      <>
        <Modal
          title={translate('login.modal-confirm-title')}
          visible={visible}
          centered
          className="modal-system-encashment"
          zIndex={'1053'}
          onCancel={closeModal}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              disabled={!codeValue || codeValue === ''}
              onClick={this.onConfirmHandler}
            >
              {translate('page.dashboard.btn-ok')}
            </Button>,
          ]}
        >
          <Row
            style={{
              margin: '10px 0',
            }}
          >
            <Row className="modal-confirm-code-subrow" type="flex" align="middle">
              <Col md={6} sm={24}>
                <p>{translate('login.modal-confirm-enter-code')}:</p>
              </Col>
              <Col md={18} sm={24}>
                <Input value={codeValue} onChange={this.onChangeCodeValue} />
              </Col>
            </Row>
            <Checkbox
              style={{
                marginTop: '10px',
              }}
              checked={isTrusted}
              onChange={this.onChangeIsTrusted}
            >
              {translate('login.modal-confirm-checkbox-text')}
            </Checkbox>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalConfirmDeviceCode);
