import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';
import { ApiService, StorageService } from '../../../services';
import GqlService from '../../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constants';
import OTPHistoryService from './OTPHistoryService';
import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';
import CellBoolean from '../../account/cellBoolean';

import './OTPHistoryGrid.scss';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class OTPHistoryGrid extends PureComponent {
  state = {
    history: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    errorMessage: '',
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
  };

  onFieldsConfigChange = (list) => {
    this.fetchNBUCodesWithOTPHistoryGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  fetchNBUCodesWithOTPHistoryGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString, dateStart, dateEnd } = this.state;

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('OTPServicesHistoryGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns) : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{f108: ASC}'
      },where:{and:[{datePostTo:{gte: "${getDataByTimezoneOffset(
        StandardizeTimeForDate(dateStart, true),
      ).toISOString()}"}}, {datePostTo:{lte: "${getDataByTimezoneOffset(
        StandardizeTimeForDate(dateEnd),
      ).toISOString()}"}}, ${filtersString}]}`;

      const query = `servicesOtpHistory(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { history, hasPreviousPage, hasNextPage } = OTPHistoryService.getGQLResponse(data);

      this.setState({ history, hasPreviousPage, hasNextPage, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
        currentEditRow: null,
      });

      await this.fetchNBUCodesWithOTPHistoryGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: OTPHistoryService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { errorMessage, dateStart, dateEnd } = this.state;

    return (
      <Row className="OTPHistoryToolbar-toolbar">
        <div className="OTPHistoryToolbar-dateRow">
          <div className="OTPHistoryToolbar-label">{translate('page.changingProfileHistory.dateFrom')}</div>
          <div className="OTPHistoryToolbar-datePicker">
            <DatePicker
              format="yyyy.MM.dd"
              className="OTPHistoryToolbar-datePicker"
              value={dateStart}
              min={OTPHistoryService.getMinimumSelectedDate()}
              onChange={this.getDateChangeCallback('dateStart')}
            />
          </div>
        </div>
        <div className="OTPHistoryToolbar-dateRow">
          <div className="OTPHistoryToolbar-label">{translate('page.changingProfileHistory.dateTo')}</div>
          <div className="OTPHistoryToolbar-datePicker">
            <DatePicker
              format="yyyy.MM.dd"
              className="OTPHistoryToolbar-datePicker"
              value={dateEnd}
              min={OTPHistoryService.getMinimumSelectedDate()}
              onChange={this.getDateChangeCallback('dateEnd')}
            />
          </div>
        </div>
        <div className="OTPHistoryToolbar-ActionRow">
          <Button type="primary" onClick={this.fetchNBUCodesWithOTPHistoryGQL} disabled={!!errorMessage}>
            {translate('page.changingProfileHistory.btn-view')}
          </Button>
        </div>
        {errorMessage && <div className="OTPHistoryToolbar-error">{errorMessage}</div>}
      </Row>
    );
  };

  render() {
    const { history, isLoading, pageValue, hasNextPage, hasPreviousPage } = this.state;

    return (
      <Grid
        data={history}
        onRefresh={this.fetchNBUCodesWithOTPHistoryGQL}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="OTPServicesHistoryGrid"
        pageChange={this.getStateSetterByName('pageValue')}
        handleRowAmountChange={this.handleRowAmountChange}
        setFiltersString={this.getStateSetterByName('filtersString')}
        setSortingString={this.getStateSetterByName('sortString')}
        pageValue={pageValue}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onFieldsConfigChange={this.onFieldsConfigChange}
        isGQL
      >
        <GridColumn field="f108" title="page.OTPServices.col-NBU" width="150" />
        <GridColumn
          field="datePostTo"
          title="page.OTPServices.col-date"
          width="150"
          cell={({ dataItem: { datePostTo } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(getDataByTimezoneOffset(datePostTo))}</td>
          )}
          columnMenu={false}
        />
        <GridColumn field="userIdPost" title="page.OTPServices.col-performer" width="150" />
        <GridColumn field="otp" title="page.OTPServices.col-OTPCode" width="150" cell={WrappedCellBoolean} />
      </Grid>
    );
  }
}

export default withTranslate(OTPHistoryGrid);
