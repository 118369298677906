import React, { PureComponent } from 'react';

import SuspendedFinancialTransactionsGrid from './SuspendedFinancialTransactionsGrid';
import PageTabs from '../../components/tabber/tabber';
import PaymentActionsGrid from './SuspendedFinancialTransactionsGrid/PaymentActionsGrid';
import UnlocksHistoryGrid from './SuspendedFinancialTransactionsGrid/UnlocksHistoryGrid';

class SuspendedFinancialTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-suspendedFinancialTransactions',
          content: <SuspendedFinancialTransactionsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, paymentId, paymentIds }, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'paymentActions':
        tabs.push({
          title: 'page.suspendedFinancialTransactions.paymentActions',
          content: <PaymentActionsGrid paymentId={paymentId} />,
          closable: true,
          key: key,
        });
        break;

      case 'unlocksHistory':
        tabs.push({
          title: 'page.suspendedFinancialTransactions.unlocksHistory',
          content: <UnlocksHistoryGrid paymentIds={paymentIds} />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default SuspendedFinancialTransactions;
