import { ApiService } from '../../services';

const api = new ApiService();

class terminalsDetailsServices {
  getRequestData = async (terminalId) => {
    const data = await api.getTerminalInfo(terminalId);
    const currentUserOwnerId = await data[0].OwnerId;
    const comissionProfiles = await api.getOwnerProfiles(currentUserOwnerId);
    const mainProfilesList = await api.mainProfileListForModalChangeProfile([currentUserOwnerId]);
    const statuses = await api.getTerminalStatuses();
    const citiesRequest = await api.getCities();
    const cities = await citiesRequest.filter((el) => el.CityId !== 500001);
    const placingInfo = await api.getTerminalPlaces();
    const terminalProjects = await api.getTerminalProjects();
    const networkContracts = await api.getNetworkContracts();
    const designTypes = await api.getTerminalDesignTypes();
    const cityId = data[0].CityId;
    const fiscalNumber =
      data[0].FiscalNumber && Number.isInteger(+data[0].FiscalNumber) ? Math.abs(data[0].FiscalNumber) : '';

    return {
      data,
      comissionProfiles,
      mainProfilesList,
      statuses,
      placingInfo,
      terminalProjects,
      networkContracts,
      cityId,
      commisionProfileValue: data[0].ProfileId,
      mainProfilesListValue: data[0].MainProfileId,
      statusesValue: data[0].StatusId,
      terminalName: data[0].Name || '',
      addressValue: data[0].TerminalAddress,
      cities,
      geopointValue: data[0].Geopoint || '',
      capacityPrinterPaper: data[0].CapacityPrinterPaper,
      capacityCashBox: data[0].CapacityCashBox,
      monitorCountValue: data[0].MonitorCount,
      placingInfoValue: data[0].PlacingId,
      terminalDescription: data[0].TerminalDescription,
      dateCreation: new Date(data[0].DateCreation),
      dateCreationNoUTC: new Date(data[0].DateCreationNoUTC),
      noteValue: data[0].Note || '',
      terminalBranch: data[0].TerminalBranch || '',
      projectValue: data[0].ProjectId,
      networkContractsValue: data[0].NetworkContractId,
      typeId: data[0].TypeId,
      currentUserOwnerId,
      currentRegionId: cities.find(({ CityId }) => CityId === cityId) || null,
      designTypeId: data[0].DesignTypeId || null,
      designTypes,
      fiscalNumber,
      isFiscalNumberAlreadyExist: !!fiscalNumber,
      businessUnit: data[0].BusinessUnit,
      inventoryNumber: data[0].InventoryNumber,
    };
  };

  getUniversalErrorMessage = (translateCallback, field) => {
    return `${translateCallback('page.terminalSettings.universalErrorMessage-field')}
    ${translateCallback(field)} ${translateCallback('page.terminalSettings.universalErrorMessage-first')}
    ${translateCallback('page.terminalSettings.universalErrorMessage-second')}.`;
  };

  checkLatinSymbols = (str) => {
    return /[a-zA-Z]/.test(str);
  };

  getError = (
    translateCallback,
    {
      cityId,
      addressValue,
      mainProfilesListValue,
      commisionProfileValue,
      placingInfoValue,
      capacityPrinterPaper,
      capacityCashBox,
      terminalName,
      terminalBranch,
      statusesValue,
      dateCreation,
      fiscalNumber,
    },
  ) => {
    if (!Number.isInteger(cityId)) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-city')}!`;
    }

    if (!addressValue) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-address')}!`;
    }

    if (this.checkLatinSymbols(addressValue)) {
      return translateCallback('page.terminalSettings.latin-address-error');
    }

    if (!Number.isInteger(mainProfilesListValue)) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-mainScreenProfile')}!`;
    }

    if (!Number.isInteger(commisionProfileValue)) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-mainScreenProfile')}!`;
    }

    if (!Number.isInteger(statusesValue)) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-status')}!`;
    }

    if (!placingInfoValue) {
      return `${translateCallback('page.terminalSettings.error-required-fields')}
          ${translateCallback('page.terminalSettings.field-placing')}!`;
    }

    if (!Number.isInteger(capacityPrinterPaper) || capacityPrinterPaper < 0 || capacityPrinterPaper > 6000) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-printerCapacity');
    }

    if (!Number.isInteger(capacityPrinterPaper) || capacityCashBox < 0 || capacityCashBox > 3000) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-cashboxCapacity');
    }

    if (!dateCreation) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-dateOfCreation');
    }

    if (terminalName.length > 250) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-name');
    }

    if (terminalBranch.length > 120) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-branch');
    }

    if (fiscalNumber && !Number.isInteger(+fiscalNumber)) {
      return this.getUniversalErrorMessage(translateCallback, 'page.terminalSettings.field-fiscalNumber');
    }

    if (fiscalNumber && `${fiscalNumber}`.length !== 10) {
      return `${translateCallback('page.terminalSettings.fiscalFormatError')}`;
    }

    return null;
  };
}

export default new terminalsDetailsServices();
