export const BASE_INFO_PROPS = [
  {
    name: 'Login',
    label: 'login',
  },
  {
    name: 'FirstName',
    label: 'firstName',
    type: '',
    maxLength: 100,
  },
  {
    name: 'LastName',
    label: 'lastName',
    type: '',
    maxLength: 100,
  },
  {
    name: 'Status',
    label: 'status',
    type: 'checkbox',
  },
];

export const ADDITIONAL_INFO_PROPS = [
  {
    name: 'Company',
    label: 'company',
    maxLength: 100,
  },
  {
    name: 'Address',
    label: 'address',
  },
  {
    name: 'Note',
    label: 'note',
  },
  {
    name: 'Email',
    label: 'email',
    maxLength: 100,
  },
  {
    name: 'Phone',
    label: 'phone',
    type: 'phone',
  },
  {
    name: 'IM',
    label: 'im',
    maxLength: 100,
  },
];
