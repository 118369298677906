import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import ViewCabinet from './viewCabinet';
import GridReportUsersInStopListGet from './gridReportUsersInStopListGet';
import GriReportRegularPaymentsGet from './gridReportRegularPaymentsGet';
import PageTabs from '../../components/tabber/tabber';
import GriReportPayerRecipientDataGet from './gridReportPayerRecipientDataGet';
import GridReportNoRecipientDataGet from './gridReportNoRecipientDataGet';
import GridReportMFOPaymentsGet from './gridReportMFOPaymentsGet';
import GridReportRefundPaymentsGet from './gridReportRefundPaymentsGet';
import GridReportWithdrawGet from './gridReportWithdrawGet';
import GridReportOverlimitTransactionsGet from './gridReportOverlimitTransactionsGet';
import GridReportOverlimitPaymentsGet from './gridReportOverlimitPaymentsGet';
import GridDictionaryAgentsGet from './gridDictionaryAgentsGet';
import GridDictionaryRefusedAgentsGet from './gridDictionaryRefusedAgentsGet';
import GridReportTerroristsTransactionsGet from './gridReportTerroristsTransactionsGet';

class PageMonitoringCabinet extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.monitoringCabinet.tab-main',
          content: <ViewCabinet />,
          //   content: <GridReportUsersInStopListGet item={{
          //     start: "2021-09-14T00:00:00",
          //     end: "2021-09-14T23:59:59"
          // }} />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'ReportUsersInStopListGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-FO_Perelik',
          )}`,
          content: <GridReportUsersInStopListGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportRegularPaymentsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-regularity',
          )}`,
          content: <GriReportRegularPaymentsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportPayerRecipientDataGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-FO_PayerData',
          )}`,
          content: <GriReportPayerRecipientDataGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportNoRecipientDataGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-FO_NoPurpose',
          )}`,
          content: <GridReportNoRecipientDataGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportMFOPaymentsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportMFOPaymentsGet',
          )}`,
          content: <GridReportMFOPaymentsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportRefundPaymentsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportRefundPaymentsGet',
          )}`,
          content: <GridReportRefundPaymentsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportWithdrawGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportWithdrawGet',
          )}`,
          content: <GridReportWithdrawGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportOverlimitPaymentsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportOverlimitPaymentsGet',
          )}`,
          content: <GridReportOverlimitPaymentsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportOverlimitTransactionsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportOverlimitTransactionsGet',
          )}`,
          content: <GridReportOverlimitTransactionsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'ReportTerroristsTransactionsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-title')}: ${translate(
            'page.monitoringCabinet.tab-reportTerroristsTransactionsGet',
          )}`,
          content: <GridReportTerroristsTransactionsGet item={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'DictionaryMediator':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-dictionary')} ${translate(
            'page.monitoringCabinet.tab-agents',
          )}`,
          content: <GridDictionaryAgentsGet />,
          closable: true,
          key: key,
        });
        break;
      case 'DictionaryRefusedAgentsGet':
        tabs.push({
          title: `${translate('page.monitoringCabinet.report-dictionary')} ${translate(
            'page.monitoringCabinet.tab-refAgents',
          )}`,
          content: <GridDictionaryRefusedAgentsGet />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageMonitoringCabinet);
