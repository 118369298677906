import { NOT_ALLOWED_FILTERS_BY_GAL } from '../components/grids/filters/constants';

export const formatFiterOperators = (isGQL, filterOperators) => {
  if (!isGQL) {
    return filterOperators;
  }

  const { date, numeric, text } = filterOperators;

  return {
    ...filterOperators,
    date: date.filter(({ operator }) => !NOT_ALLOWED_FILTERS_BY_GAL.find((item) => item === operator)),
    numeric: numeric.filter(({ operator }) => !NOT_ALLOWED_FILTERS_BY_GAL.find((item) => item === operator)),
    text: text.filter(({ operator }) => !NOT_ALLOWED_FILTERS_BY_GAL.find((item) => item === operator)),
  };
};
