export const INITIAL_VALUES = {
  Key: '',
  Name: '',
  Type: '',
  DefaultValue: '',
};

export const TYPE_COLUMN_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'TypesData',
    width: '250',
    isShowOnMobile: true,
  },
];

export const TypesData = [
  { Name: 'Int', Id: 'int' },
  { Name: 'String', Id: 'string' },
  { Name: 'Bool', Id: 'bool' },
];

export const BOOLEAN_COLUMN_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'BooleanData',
    width: '250',
    isShowOnMobile: true,
  },
];

export const BooleanData = [
  { Name: 'True', Id: 'true' },
  { Name: 'False', Id: 'false' },
];
