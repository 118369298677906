import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, message, Select, Row } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { formatDate } from '@telerik/kendo-intl';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';
import './modalSystemEncashment.scss';

const api = new ApiService();

const { Option } = Select;

class ModalSystemEncashment extends PureComponent {
  static propTypes = {
    dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
    showModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
  };

  state = {
    loading: false,
    isLoading: false,
    collectors: [],
    selected: null,
    dateValue: new Date(),
  };

  componentDidMount() {
    this.getCollectors();
  }

  getCollectors = async () => {
    const {
      dataItem: { TerminalId },
      showError,
    } = this.props;
    if (!TerminalId) return;
    try {
      this.toggleLoader();
      const response = await api.getCollectors(TerminalId);
      const result = response.Collectors;
      console.log(result);
      this.setState({ loading: false, collectors: result });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  collectionSystem = async () => {
    const { selected, dateValue } = this.state;
    const {
      translate,
      dataItem: { TerminalId },
      closeModal,
      showError,
    } = this.props;
    const formattedDate = formatDate(dateValue, 'yyyy.MM.ddTHH:mm:ss');
    if (selected === null) {
      message.error(`${translate('page.dashboard.choose-enc')}`, 2);
      return;
    }
    if (!TerminalId) return;
    try {
      this.setState({ loading: true });
      const model = {
        TerminalId: TerminalId,
        CollectorId: selected,
        DateInput: formattedDate,
      };
      const response = await api.сollectionsSystem(model);
      this.setState({ loading: false });
      closeModal();
      message.success(`${translate('page.dashboard.message-success')}`, 2.5);
      return response;
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onSelectChange = (value) => {
    this.setState({ selected: value });
  };

  onDateChange = (e) => {
    this.setState({ dateValue: e.target.value });
  };

  render() {
    const { translate, visible, closeModal } = this.props;
    const { loading, collectors, isLoading, dateValue } = this.state;
    return (
      <Modal
        title={translate('page.dashboard.system-encashment')}
        visible={visible}
        centered
        onOk={this.collectionRecalculation}
        onCancel={closeModal}
        className="modal-system-encashment"
        zIndex={'1053'}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('page.dashboard.btn-cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={this.collectionSystem}>
            {translate('page.dashboard.btn-encashment')}
          </Button>,
        ]}
      >
        <Row gutter={24}>
          <Col sm={24} md={12}>
            <Select
              placeholder="Инкассатор"
              className="modal-system-encashment--select"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              onChange={this.onSelectChange}
            >
              {collectors.map((collector, i) => (
                <Option value={collector.Id} key={`collector-number-${i.toString()}`}>
                  {collector.Name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col sm={24} md={12}>
            <DatePicker style={{ width: '100%' }} onChange={this.onDateChange} value={dateValue} />
          </Col>
        </Row>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ModalSystemEncashment);
