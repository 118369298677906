import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Input } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import ModalUnholdTransaction from './UnHoldFinancialTransactionModal';
import ModalDeclineTransaction from './DeclineFinanicialTransactionModal';
import Grid from '../../components/grids/baseGrid';

import './FinancialTransactionGrid.scss';

const api = new ApiService();

class GridTransactionDetails extends PureComponent {
  state = {
    data: [],
    popupBlock: false,
    popupUnblock: true,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { hist, transactionId } = this.props;
    if (!hist && !transactionId) return;
    try {
      this.toggleLoader(true);
      const value = {
        TransactionId: transactionId,
        Hist: true,
      };
      const data = await api.transactionDetails(value);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  toggleUnblockPopup = () => {
    const { popupUnblock } = this.state;
    this.setState({ popupUnblock: !popupUnblock });
  };

  onCancelBlockPopup = () => {
    this.setState({ popupBlock: false });
  };

  renderToolbar = () => {
    const { translate, transactionId, itemTest: item } = this.props;
    // if (item && item.StatusName && item.StatusName === 'заблокирован') {
    //   return (
    //     <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
    //       <ModalDeclineTransaction item={item} />
    //       <ModalUnholdTransaction item={item} />
    //     </Col>
    //   );
    // } else
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="issued-certificates--toolbar">
          <p>{translate('grids.terminal-events.column-transactionId')}</p>
          <Input value={transactionId} disabled className="FinancialTransactionDetails-input" />
        </div>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          isLoading={isLoading}
          toolbar={this.renderToolbar()}
          name="financialTransactionDetailsGrid"
        >
          <GridColumn field="Name" title="grids.transactionDeatails.column-name" />
          <GridColumn field="Value" title="grids.transactionDeatails.column-value" />
        </Grid>
      </>
    );
  };
}

export default withTranslate(GridTransactionDetails);
