import React, { PureComponent } from 'react';
import { Button, Modal, message } from 'antd';

import PropValueRow from '../../components/ElementProps/PropValueRow';
import { withTranslate } from '../../contexts/localContext';
import ElementPropsService from '../../components/ElementProps/ElementProps.service';
import { ApiService } from '../../services';

const api = new ApiService();

class ChangePropValueModal extends PureComponent {
  state = {
    isLoading: false,
    value: '',
    error: '',
    updatedProp: [],
  };

  componentDidMount() {
    const { selectedItems } = this.props;

    if ([...new Set(selectedItems.map(({ value }) => value))].length === 1) {
      this.setState({ value: selectedItems[0].value });
    }
  }

  renderValueRow = () => {
    const { value } = this.state;
    const { selectedItems } = this.props;

    return (
      <PropValueRow
        valueType={selectedItems[0].type}
        setInputValues={(value, error) => {
          this.setState({
            value,
            error,
          });
        }}
        value={value}
      />
    );
  };

  processUpdateProp = async ({ isDefinition, profileId, menuId, name, language, type }) => {
    const { value } = this.state;

    try {
      const method = isDefinition ? api.menuPropertiesUpdate : api.menuPropertiesInsert;

      await method({
        profileId,
        menuId,
        name: name,
        value: ElementPropsService.getValueByType(value, type),
        language: language,
      });

      this.setState(({ updatedProp }) => ({
        updatedProp: [...updatedProp, `${profileId}:${menuId}:${name}${language ? `:${language}` : ''}`],
      }));
    } catch (e) {
      throw e;
    }
  };

  buildError = (e, prop) => {
    const { translate } = this.props;

    const { profileId, menuId, name, language } = prop;

    return {
      ...e,
      data: e.data
        ? {
            ...e.data,
            Message: e.data.Message
              ? `${translate('page.navigationMenuProp.successfulUpdatedList')} ${this.state.updatedProp.join(
                  ';',
                )} <br/> ${translate('page.navigationMenuProp.prop')} ${profileId}:${menuId}:${name}${
                  language ? `:${language}` : ''
                } ${translate('page.navigationMenuProp.errorByUpdated')} ${e.data.Message}`
              : '',
          }
        : null,
    };
  };

  updateProps = async () => {
    const { selectedItems, closeDialog, onRefresh, translate } = this.props;
    let isErrorWhileUpdating = false;

    await this.setState({ isLoading: true });

    for (const prop of selectedItems) {
      try {
        await this.processUpdateProp(prop);
      } catch (e) {
        const { showError } = this.props;
        isErrorWhileUpdating = true;
        showError(this.buildError(e, prop));

        this.setState({ isLoading: false });
        break;
      }
    }

    if (!isErrorWhileUpdating) {
      await this.setState({ isLoading: false });
      closeDialog();
      message.success(`${translate('page.navigationMenuProp.successfulUpdated')}`, 2);
      onRefresh();
    }
  };

  render() {
    const { translate, closeDialog, selectedItems, visible } = this.props;
    const { value, isLoading, error, updatedProp } = this.state;

    return (
      <Modal
        title={translate('page.profilesMenu.updateProps')}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            onClick={this.updateProps}
            disabled={(!value && selectedItems[0].type !== 'bool') || !!error}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {isLoading ? (
          <div className="NavigationMenuPropGrid-progress">
            {translate(`page.routingManagement.processCompletedAt`)}{' '}
            <b>{Math.round((updatedProp.length / selectedItems.length) * 100)}%</b>
          </div>
        ) : (
          this.renderValueRow()
        )}

        {error && <div className="ElementProps-error">{translate(error)}</div>}
      </Modal>
    );
  }
}

export default withTranslate(ChangePropValueModal);
