import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import AllElements from './AllElements';
import PageTabs from '../../components/tabber/tabber';

class ChangingProfileHistoryPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'Все элементы',
          content: <AllElements />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} />;
  }
}

export default withTranslate(ChangingProfileHistoryPage);
