import React, { PureComponent } from 'react';
import { Modal, Button, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

const api = new ApiService();
const { TextArea } = Input;

class SetCommentDialog extends PureComponent {
  state = {
    comment: '',
    isLoading: false,
  };

  onChangeEventInput = (e) => {
    this.setState({ comment: e.target.value });
  };

  onSubmit = async () => {
    const { translate, onClose, selectedItems, refetch } = this.props;
    const { comment } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.paymentsExternalAddComment({ comments: comment, paymentId: selectedItems[0].PaymentId });
      await this.setState({ isLoading: false });
      await onClose();
      message.success(`${translate(`page.suspendedFinancialTransactions.commentSuccessfullyModified`)}`, 2);
      refetch();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, visible, onClose } = this.props;
    const { comment, isLoading } = this.state;

    return (
      <Modal
        title={translate('page.suspendedFinancialTransactions.addComment')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.onSubmit} loading={isLoading}>
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="SuspendedFinancialTransactionsModal-label">
          {translate('page.suspendedFinancialTransactions.comment')}
        </div>
        <TextArea
          placeholder={translate('page.suspendedFinancialTransactions.comment')}
          rows={3}
          value={comment}
          onChange={this.onChangeEventInput}
          maxLength={150}
        />
      </Modal>
    );
  }
}

export default withTranslate(SetCommentDialog);
