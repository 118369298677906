export const BRANDS_COLUMN_CONFIG = [
  {
    fieldName: 'BrandId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.subBrandsTerminals.col-brand',
    width: '130',
    isShowOnMobile: true,
  },
  {
    fieldName: 'MenuProfileName',
    titleTranslateSlug: 'page.subBrandsTerminals.col-profileMenu',
    width: '220',
  },
];

export const MENU_PROFILE_CONFIG = [
  {
    fieldName: 'ProfileId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.subBrandsTerminals.col-profileMenu',
    width: '350',
    isShowOnMobile: true,
  },
];

export const MENU_PROFILE_COMMISSIONS_CONFIG = [
  {
    fieldName: 'ProfileId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.subBrandsTerminals.col-commissionProfile',
    width: '350',
    isShowOnMobile: true,
  },
];
