import React, { PureComponent } from 'react';
import { Button, Modal, Input, message, Select } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import MenuElementPropService from './MenuElementProp.service';
import { PROP_SYSTEM_TYPES, PROP_TYPES } from './constants';
import { ApiService } from '../../services';

import './MenuElementPropDictionaryGrid.styled.scss';

const { Option } = Select;
const { TextArea } = Input;
const api = new ApiService();

class MenuElementPropUpdateModal extends PureComponent {
  state = {
    isLoading: false,
    propNameValueValidationError: '',
    propNameValue: '',
    type: undefined,
    systemType: undefined,
    desc: '',
  };

  componentDidMount() {
    const { selectProp, isAddLogic } = this.props;

    if (!isAddLogic) {
      this.setState({ type: selectProp.Type, systemType: selectProp.System.split(','), desc: selectProp.Description });
    }
  }

  handleUpdate = async () => {
    const { closeDialog, onRefresh, translate, isAddLogic, selectProp } = this.props;
    const { propNameValue, type, systemType, desc: description } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.menuPropertiesKeyMerge({
        key: isAddLogic ? propNameValue : selectProp.Key,
        type,
        description,
        system: systemType.join(),
        forDelete: false,
      });
      message.success(
        translate(
          isAddLogic
            ? 'page.menuElementPropDictionary.propertyAddedSuccessfully'
            : 'page.menuElementPropDictionary.propertyChangedSuccessfully',
        ),
        2,
      );
      await this.setState({ isLoading: false });
      closeDialog();
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, visible, isAddLogic, selectProp, closeDialog } = this.props;
    const { isLoading, propNameValueValidationError, propNameValue, type, systemType, desc } = this.state;

    const isDisabled =
      propNameValueValidationError || (!propNameValue && isAddLogic) || !type || !systemType || !systemType.length;

    return (
      <Modal
        title={
          isAddLogic
            ? translate('page.profilesMenu.addProps')
            : `${translate('page.profilesMenu.updateProps')} ${selectProp.Key}`
        }
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" loading={isLoading} onClick={this.handleUpdate} disabled={isDisabled}>
            {translate(isAddLogic ? 'core.buttonTitles.add' : 'core.buttonTitles.change')}
          </Button>,
          <Button onClick={isLoading ? () => {} : closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {isAddLogic && (
          <>
            <div>{translate('page.profilesMenu.propName')}</div>
            <Input
              onChange={({ target: { value } }) => {
                this.setState({
                  propNameValue: value,
                  propNameValueValidationError: MenuElementPropService.validatePropKey(value),
                });
              }}
            />
          </>
        )}

        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 50%' }}>
            <div>{translate('page.menuElementPropDictionary.type')}</div>
            <Select
              value={type}
              onChange={(type) => this.setState({ type })}
              className="MenuElementPropDictionaryGrid-select"
              placeholder={translate('page.menuElementPropDictionary.selectType')}
            >
              {PROP_TYPES.map((type) => (
                <Option value={type} key={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ flex: '1 1 50%' }}>
            <div>{translate('page.profilesMenu.systemType')}</div>
            <Select
              value={systemType}
              onChange={(systemType) => this.setState({ systemType })}
              className="MenuElementPropDictionaryGrid-select"
              mode="multiple"
              placeholder={translate('page.menuElementPropDictionary.selectSystemType')}
            >
              {PROP_SYSTEM_TYPES.map((systemType) => (
                <Option value={systemType} key={systemType}>
                  {systemType}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div>{translate('page.menuElementPropDictionary.description')}</div>
        <TextArea
          maxLength={500}
          value={desc}
          onChange={({ target: { value: desc } }) => {
            this.setState({ desc });
          }}
        />
        {propNameValueValidationError && (
          <div className="MenuElementPropDictionaryGrid-error">{translate(propNameValueValidationError)}</div>
        )}
      </Modal>
    );
  }
}

export default withTranslate(MenuElementPropUpdateModal);
