import React, { PureComponent } from 'react';
import { formatDate } from '@telerik/kendo-intl';
import { Row, Col, Tooltip, Button } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import './CommunicationMonitoringPerHour.scss';

const api = new ApiService();
class CommunicationMonitoringPerHour extends PureComponent {
  state = {
    communicationMonitoringPerHour: [],
    isLoading: false,
    minuteInfo: [],
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getDataPerHour = async () => {
    const { workDay, terminalId, hour } = this.props;
    try {
      const model = {
        terminalId: terminalId,
        workDate: formatDate(workDay, 'dd.MM.yyyy'),
        hour: hour,
      };
      const communicationMonitoringPerHour = await api.communicationTerminalsPerHour(model);
      this.toggleLoader(true);
      this.setState({ communicationMonitoringPerHour });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  parsObjectForMinutes = (communicationMonitoringPerHour) => {
    if (!communicationMonitoringPerHour.length) return [];

    return Object.entries(communicationMonitoringPerHour[0])
      .filter(([key]) => key.indexOf('Minute') > -1)
      .map(([key, value]) => ({
        minute: parseInt(key.slice(-2), 10),
        active: value,
      }));
  };

  async componentDidMount() {
    await this.getDataPerHour();
  }

  render = () => {
    const { communicationMonitoringPerHour } = this.state;
    const { workDay, terminalId, hour, translate } = this.props;
    const minuteInfo = this.parsObjectForMinutes(communicationMonitoringPerHour);

    return (
      <Col span={24}>
        <Row className="CommunicationMonitoringPerHour-container">
          <div className="CommunicationMonitoringPerHour-terminalNumber">
            <Button disabled>{`${translate(
              'grids.communicationTerminalsPerHour.terminalNumber',
            )} ${terminalId}`}</Button>
          </div>

          <div className="CommunicationMonitoringPerHour-fieldRow">
            {minuteInfo.map(({ minute, active }) => (
              <Tooltip title={`${workDay} - ${hour}:${minute < 10 ? `0${minute}` : minute}`}>
                <div
                  className={`CommunicationMonitoringPerHour-fieldCol ${active === true ? 'bg-green' : 'bg-red'}`}
                ></div>
              </Tooltip>
            ))}
          </div>
        </Row>
      </Col>
    );
  };
}
export default withTranslate(CommunicationMonitoringPerHour);
