import React, { PureComponent } from 'react';
import { Modal, Button, Col, Row, Input } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';

import '../NonRiskServicesGrid.scss';
import { SERVICES_COLUMN_CONFIG } from '../../../../core/constants/configs.const';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../../services';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class SetServicesDialog extends PureComponent {
  state = {
    isLoading: false,
    selectServices: [],
    services: [],
  };

  onServiceSave = (selectServices) => {
    this.setState({ selectServices });
  };

  getServices = async () => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.notRiskServices();

      await this.setState({
        services:
          services && services.length
            ? DropdownNormalizersService.normalizeServices(services).filter(({ Id }) => Id !== 0)
            : [],
      });
      await this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  servicesBlockingRulesAdd = async () => {
    const { selectServices } = this.state;
    const { getServicesList, closeModal } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.servicesBlockingRulesAdd({ serviceId: selectServices, unlockingPeriod: 24 });
      await getServicesList();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidMount() {
    this.getServices();
  }

  render() {
    const { isLoading, selectServices, services } = this.state;
    const { translate, visible, closeModal } = this.props;

    return (
      <>
        <Modal
          title={translate('page.nonRiskLimitsGrid.addingService')}
          visible={visible}
          onCancel={closeModal}
          className="SetServicesDialog"
          footer={[
            <Button
              type="primary"
              key="back"
              onClick={this.servicesBlockingRulesAdd}
              disabled={!selectServices || !selectServices.length}
              loading={isLoading}
            >
              {translate('core.buttonTitles.add')}
            </Button>,
            <Button key="submit" loading={isLoading} onClick={closeModal}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
          ]}
          centered
        >
          <Row className="SetServicesDialog-row">
            <Col span={8} sm={6} md={5} className="CommissionsServices-label">
              {translate('page.commissionsServices.service')}
            </Col>
            <Col span={14} sm={16} md={17} offset={1}>
              <GridDropdown
                data={services}
                colConfig={SERVICES_COLUMN_CONFIG}
                selectItems={selectServices}
                onSave={this.onServiceSave}
                defaultTitle={translate('page.commissionsServices.chooseService')}
              />
            </Col>
          </Row>

          <Row className="SetServicesDialog-row">
            <Col span={8} sm={6} md={5} className="CommissionsServices-label">
              {translate('page.nonRiskLimitsGrid.unlockPeriod')}
            </Col>
            <Col span={14} sm={16} md={17} offset={1}>
              <Input value={24} disabled />
            </Col>
          </Row>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SetServicesDialog);
