import React, { PureComponent } from 'react';
import { Input, message } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import { ApiService } from '../../../../services';

import '../AddService/AddService.styled.scss';
import './UpdateServiceTypeModal.styled.scss';

const api = new ApiService();

class AddNewProvider extends PureComponent {
  state = {
    provider: '',
    errors: '',
    isLoading: false,
  };

  componentDidMount() {
    const { changeAddAction } = this.props;

    changeAddAction(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { changeAddAction, cancelActionSlug, handleCancel, addActionSlug } = this.props;
    const { provider } = this.state;
    const { provider: prevProvider } = prevState;
    const { cancelActionSlug: prevCancelActionSlug, addActionSlug: prevAddActionSlug } = prevProps;

    if (provider !== prevProvider) {
      changeAddAction(!provider);
      this.setState({ errors: '' });
    }

    if (prevCancelActionSlug !== cancelActionSlug) {
      handleCancel();
    }

    if (addActionSlug !== prevAddActionSlug) {
      this.handleAdd();
    }
  }

  handleAdd = async () => {
    const { providers, changeAddAction, showError, fetchProviders, translate, handleCancel } = this.props;
    const { provider } = this.state;

    await this.setState({ isLoading: true });
    const isDuplicate = providers.includes(provider);

    if (isDuplicate) {
      changeAddAction(true);
      this.setState({ errors: 'page.addServiceByBigTable.providerNameAlreadyExists', isLoading: false });
      return;
    }

    try {
      await api.addProvider({
        name: provider,
        address: '',
        phone: '',
        fax: '',
        email: '',
        website: '',
        description: '',
      });
      this.setState({ isLoading: false });
      fetchProviders();
      handleCancel();
      message.success(`${translate('page.addServiceByBigTable.providerAddedSuccessfully')}`, 2.5);
    } catch (e) {
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate } = this.props;
    const { provider, isLoading, errors } = this.state;

    return (
      <>
        <div className="AddServiceByBigTable-inputRow AddServiceTypeByBigTableModal-inputRow">
          <div className="AddServiceByBigTable-label">{translate('page.serviceMainProps.nameService')}</div>
          <div className="AddServiceByBigTable-input">
            <Input
              onChange={({ target: { value: provider } }) => {
                this.setState({ provider });
              }}
              value={provider}
            />
          </div>
        </div>

        {errors && <div className="AddServiceTypeByBigTableModal-error">{translate(errors)}</div>}
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(AddNewProvider);
