import React from 'react';
import { STATUS_COLORS } from './constants';

import './CheckCardRouting.styled.scss';

const StatusCell = ({ dataItem: { status } }) => {
  const getColorByStatus = () => STATUS_COLORS.get(status) || 'darkgrey';

  return <td style={{ backgroundColor: getColorByStatus() }} className="CheckCardRoutingProcessDialog-statusCell" />;
};

export default StatusCell;
