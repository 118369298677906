import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';
import Grid from '../../components/grids/baseGrid';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import findArray from '../../utils/findArrForCurrentPage';
import ModalEmptyData from './modalEmptyData';

const api = new ApiService();

class GridReportRefundPaymentsGet extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('monitoringCabinet');
  }

  static propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
  };

  state = {
    data: [],
    isLoading: false,
    visible: false,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  makeQuery = async () => {
    const {
      item,
      item: { start, end },
      showError,
    } = this.props;
    if (!item) return;
    try {
      this.toggleLoader();
      const data = await api.reportRefundPaymentsGet(start, end);
      let visible = false;
      if (data && data.length === 0) {
        visible = true;
      }
      this.setState({ data, visible });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  buildToolbar = () => {
    return <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}></Col>;
  };

  render = () => {
    const { isLoading, data, visible } = this.state;
    const {
      item: { start, end },
      translate,
    } = this.props;
    if (visible) {
      return (
        <ModalEmptyData
          visible={visible}
          dateStart={start}
          dateEnd={end}
          toggleModal={this.toggleModal}
          text={translate('page.monitoringCabinet.modal-reportRefundPaymentsGet-text')}
        />
      );
    }
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="reportRefundPaymentsGet"
        permissionName="monitoringCabinet"
        withOutBaseButtons
      >
        <GridColumn field="PId" title="grids.reportRefundPaymentsGet.column-pId" width="150" filter="numeric" />

        <GridColumn
          field="TransactionId"
          title="grids.terminal-events.column-transactionId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="HId" title="grids.reportRefundPaymentsGet.column-hId" width="150" filter="numeric" />

        <GridColumn field="Narrative" title="grids.accountsDetail.column-narrative" width="150" />

        <GridColumn
          field="WorkDate"
          title="grids.reportUsersInStopListGet.column-dataWork"
          width="145"
          filter="date"
          format="dd.MM.yyyy"
        />
      </Grid>
    );
  };
}

export default withTranslate(GridReportRefundPaymentsGet);
