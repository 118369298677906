export const TYPE_ACCESS = [
  { title: 'page.typeAccessTerminals.typeAccessReview', id: 0 },
  { title: 'page.typeAccessTerminals.typeAccessCollector', id: 1 },
  { title: 'page.typeAccessTerminals.typeAccessTechnician', id: 2 },
];

export const GET_ACCESS_TYPE_BY_ID = new Map([
  [0, 'IsViewer'],
  [1, 'IsCollector'],
  [2, 'IsTechnic'],
]);

export const OWNERS_BY_USER_DROPDOWN_CONFIG = [
  {
    fieldName: 'OwnerId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerName',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '240',
    isShowOnMobile: true,
  },
];
