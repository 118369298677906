import React, { PureComponent } from 'react';
import { Button, Modal, Input, Row, Select, Checkbox } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ADDITIONAL_TRANSACTION_PROPS, DEFAULT_EXPAND_SEARCH_OPTIONS } from './constants';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import GridDropdown from '../../GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService, StorageService } from '../../../services';

import './gridTransactions.scss';
import LoadingPanel from '../../loader';

const { Option } = Select;
const api = new ApiService();

class ExpandSearchOptionsDialog extends PureComponent {
  state = {
    ...DEFAULT_EXPAND_SEARCH_OPTIONS,
    isLoading: false,
    paymentTypes: [],
    operations: [],
    terminalTypes: [],
  };

  updateByLocalStorage = () => {
    const { selectedMainTransactionValue } = this.props;

    const propsByStorageJSON = StorageService.getItem('transactionsExpandSearchOptions');

    if (!propsByStorageJSON) {
      return;
    }

    const { additionalDetailName, additionalDetailValue, paymentTypes, operationTypes, terminalTypes, hasComment } =
      JSON.parse(propsByStorageJSON);

    const isCurrentTransactionPropDisabled =
      !selectedMainTransactionValue || selectedMainTransactionValue === ADDITIONAL_TRANSACTION_PROPS[0];

    this.setState({
      currentTransactionProp: isCurrentTransactionPropDisabled
        ? ADDITIONAL_TRANSACTION_PROPS[0]
        : additionalDetailName || ADDITIONAL_TRANSACTION_PROPS[0],
      currentTransactionPropValue: isCurrentTransactionPropDisabled ? '' : additionalDetailValue || '',
      selectedPaymentTypes: paymentTypes ? [paymentTypes] : [],
      selectedOperations: operationTypes || undefined,
      selectTerminalTypes: terminalTypes || undefined,
      isNoComment: hasComment === false,
    });
  };

  componentDidMount() {
    this.updateByLocalStorage();
    this.initial();
  }

  initial = async () => {
    await this.setState({ isLoading: true });
    await this.getPaymentType();
    await this.fetchOperations();
    await this.fetchTerminalTypes();
    await this.setState({ isLoading: false });
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      this.setState({
        paymentTypes:
          data && data.length
            ? data.map((paymentType) => ({
                ...paymentType,
                Id: paymentType.PaymentTypeCode,
                Name: paymentType.PaymentType,
              }))
            : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    } finally {
    }
  };

  fetchOperations = async () => {
    try {
      const operations = await api.getTransactionsOperations();

      this.setState({ operations });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchTerminalTypes = async () => {
    try {
      const terminalTypes = await api.getTerminalsTypes();

      this.setState({ terminalTypes });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  onPropsAdd = () => {
    const {
      currentTransactionProp,
      currentTransactionPropValue,
      selectedPaymentTypes,
      selectedOperations,
      selectTerminalTypes,
      isNoComment,
    } = this.state;
    const { selectedMainTransactionValue, translate, setAdditionalProps, closeModal } = this.props;

    if (
      selectedMainTransactionValue !== ADDITIONAL_TRANSACTION_PROPS[0] &&
      selectedMainTransactionValue === currentTransactionProp
    ) {
      this.setState({ error: translate('page.transactions.transactionPropNotMatch') });
      return;
    }

    setAdditionalProps({
      additionalDetailName: currentTransactionProp === ADDITIONAL_TRANSACTION_PROPS[0] ? null : currentTransactionProp,
      additionalDetailValue: currentTransactionPropValue || null,
      paymentTypes: selectedPaymentTypes[0] || '',
      operationTypes: selectedOperations || '',
      terminalTypes: selectTerminalTypes || '',
      hasComment: isNoComment ? false : null,
    });

    StorageService.setItem(
      'transactionsExpandSearchOptions',
      JSON.stringify({
        additionalDetailName:
          currentTransactionProp === ADDITIONAL_TRANSACTION_PROPS[0] ? null : currentTransactionProp,
        additionalDetailValue: currentTransactionPropValue || null,
        paymentTypes: selectedPaymentTypes[0] || '',
        operationTypes: selectedOperations || '',
        terminalTypes: selectTerminalTypes || '',
        hasComment: isNoComment ? false : null,
      }),
    );
    closeModal();
  };

  render() {
    const { visible, translate, closeModal, selectedMainTransactionValue } = this.props;
    const {
      currentTransactionProp,
      currentTransactionPropValue,
      paymentTypes,
      selectedPaymentTypes,
      isLoading,
      selectedOperations,
      operations,
      selectTerminalTypes,
      terminalTypes,
      isNoComment,
      error,
    } = this.state;

    const isCurrentTransactionPropDisabled =
      !selectedMainTransactionValue || selectedMainTransactionValue === ADDITIONAL_TRANSACTION_PROPS[0];

    return (
      <Modal
        className="GridTransactionsExpandSearchOptionModal"
        visible={visible}
        title={translate('page.transactions.addSearchParameters')}
        onCancel={closeModal}
        footer={[
          <Button type="primary" key="add" onClick={this.onPropsAdd} disabled={!!error}>
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="GridTransactionsExpandSearchOptionModal-prop">
          <div>
            <Select
              value={currentTransactionProp}
              onChange={(currentTransactionProp) => {
                this.setState({ currentTransactionProp, error: '' });
              }}
              style={{ width: '100%' }}
              disabled={isCurrentTransactionPropDisabled}
            >
              {ADDITIONAL_TRANSACTION_PROPS.map((option, index) => (
                <Option key={`transactionProps-${index.toString()}`} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Input
              value={currentTransactionPropValue}
              placeholder={translate('page.transactions.value')}
              onChange={({ target: { value: currentTransactionPropValue } }) => {
                this.setState({ currentTransactionPropValue });
              }}
              className="transaction-input"
              disabled={isCurrentTransactionPropDisabled}
            />
          </div>
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsExpandSearchOptionModal-label">
            {translate('grids.transactions.column-typePayment')}
          </div>

          <GridDropdown
            data={paymentTypes}
            colConfig={[
              ...PAYMENT_TYPES_COLUMN_CONFIG,
              {
                fieldName: 'PaymentTypeCode',
                width: '70',
                titleTranslateSlug: 'page.dictionaryPaymentType.col-code',
              },
            ]}
            selectItems={selectedPaymentTypes}
            onSave={(selectedPaymentTypes) => {
              this.setState({ selectedPaymentTypes });
            }}
            defaultTitle={translate('page.commissionsServices.choosePaymentType')}
            isSingle
            isAllowEmptyList
          />
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsExpandSearchOptionModal-label">
            {translate('page.transactions.operation')}
          </div>

          <Select
            value={selectedOperations}
            onChange={(selectedOperations) => {
              this.setState({ selectedOperations });
            }}
            style={{ width: '100%' }}
            placeholder={translate('page.transactions.selectOperation')}
            allowClear
          >
            {operations.map(({ OperationId, Name }) => (
              <Option key={OperationId} value={OperationId}>
                {Name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row">
          <div className="GridTransactionsExpandSearchOptionModal-label">
            {translate('page.transactions.terminalType')}
          </div>

          <Select
            value={selectTerminalTypes}
            onChange={(selectTerminalTypes) => {
              this.setState({ selectTerminalTypes });
            }}
            style={{ width: '100%' }}
            placeholder={translate('page.transactions.selectTerminalType')}
            allowClear
          >
            {terminalTypes.map(({ SystemCode, TypeName, Description }) => (
              <Option key={SystemCode} value={SystemCode}>
                {`${TypeName}-${Description}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="GridTransactionsExpandSearchOptionModal-row GridTransactionsExpandSearchOptionModal-checkboxRow">
          <div className="GridTransactionsExpandSearchOptionModal-checkboxLabel">
            {translate('page.transactions.noComment')}
          </div>

          <Checkbox
            checked={isNoComment}
            onChange={() => {
              this.setState(({ isNoComment }) => ({ isNoComment: !isNoComment }));
            }}
          />
        </div>

        <Button
          type="ghost"
          onClick={() => {
            this.setState({ ...DEFAULT_EXPAND_SEARCH_OPTIONS });
          }}
        >
          {translate('core.buttonTitles.clear')}
        </Button>

        {error && <div className="GridTransactionsExpandSearchOptionModal-error">{error}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ExpandSearchOptionsDialog);
