import { formatDate } from '@telerik/kendo-intl';
import { getDataByTimezoneOffset } from '../../../../utils/getDataByTimezoneOffset';

class additionalSettingsService {
  getIsTimePickerValid(nightTimeStart, nightTimeEnd) {
    const isNightTimeStartValid =
      nightTimeStart && new Date(nightTimeStart).getHours() >= 18 && new Date(nightTimeStart).getHours() <= 23;

    const isNightTimeEndValid =
      nightTimeEnd && new Date(nightTimeEnd).getHours() >= 0 && new Date(nightTimeEnd).getHours() <= 8;

    return isNightTimeStartValid && isNightTimeEndValid;
  }

  getCustomDateByTimezoneOffset = (date) =>
    getDataByTimezoneOffset(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date(date).getHours(),
        new Date(date).getMinutes(),
        0,
      ),
    );

  getRequestData({
    techUser,
    managers,
    managerUserId,
    defaulCollectorsData,
    defaultCollectorUserId,
    terminalId,
    terminalSchedules,
    isAutoCollection,
    renter,
    amount,
    documentNumber,
    dailyLimit,
    documentDate,
    currency,
    nightlyLimit,
    nightTimeEnd,
    radioValue,
    nightTimeStart,
  }) {
    const managerUserName =
      managers.find((el) => el.UserId === managerUserId) !== undefined
        ? managers.find((el) => el.UserId === managerUserId).ManagerUserName
        : null;

    const defaultCollectorUserName =
      defaulCollectorsData.find((el) => el.UserId === defaultCollectorUserId) !== undefined
        ? defaulCollectorsData.find((el) => el.UserId === defaultCollectorUserId).DefaulCollectorsName
        : null;

    const sheduleModel = {
      TerminalId: terminalId,
      WithoutSchedule: !radioValue,
      TerminalSchedules: terminalSchedules.map((terminalSchedule) => ({
        ...terminalSchedule,
        TurnOn: new Date(this.getCustomDateByTimezoneOffset(terminalSchedule.TurnOn)),
        TurnOff: new Date(this.getCustomDateByTimezoneOffset(terminalSchedule.TurnOff)),
      })),
    };

    const techUsersModel = {
      TerminalId: terminalId,
      TechUserId: techUser.UserId,
      TechUserName: techUser.UserName,
      ManagerUserId: managerUserId,
      ManagerUserName: managerUserName,
      DefaultCollectorUserId: defaultCollectorUserId,
      DefaultCollectorUserName: defaultCollectorUserName,
      AutoCollection: isAutoCollection,
    };

    const terminalRentModel = {
      TerminalId: terminalId,
      Renter: renter,
      DocumentNumber: documentNumber,
      DocumentDate: formatDate(documentDate, 'yyyy-MM-ddTHH:mm:ssZ'),
      Amount: amount,
      Currency: currency,
    };

    const terminalLimitsModel = {
      TerminalId: terminalId,
      DailyLimit: dailyLimit,
      NightlyLimit: nightlyLimit,
      NightTimeStart: new Date(this.getCustomDateByTimezoneOffset(nightTimeStart)),
      NightTimeEnd: new Date(this.getCustomDateByTimezoneOffset(nightTimeEnd)),
    };

    return { sheduleModel, techUsersModel, terminalRentModel, terminalLimitsModel };
  }

  getDefaultDate = () => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
}

export default new additionalSettingsService();
