export const FILTER_OPERATORS = {
  text: [
    {
      text: 'grid.filterContainsOperator',
      operator: 'contains',
    },
    {
      text: 'grid.filterNotContainsOperator',
      operator: 'doesnotcontain',
    },
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
    },
    {
      text: 'grid.filterNotEqOperator',
      operator: 'neq',
    },
    {
      text: 'grid.filterStartsWithOperator',
      operator: 'startswith',
    },
    {
      text: 'grid.filterEndsWithOperator',
      operator: 'endswith',
    },
    {
      text: 'grid.notEmpty',
      operator: 'nempty',
    },
    {
      text: 'grid.empty',
      operator: 'empty',
    },
    {
      text: 'grid.filterIsNullOperator',
      operator: 'isnull',
    },
    {
      text: 'grid.filterIsNotNullOperator',
      operator: 'isnotnull',
    },
    // {
    //   text: 'grid.filterIsEmptyOperator',
    //   operator: 'isempty',
    // },
    // {
    //   text: 'grid.filterIsNotEmptyOperator',
    //   operator: 'isnotempty',
    // },
  ],
  numeric: [
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
    },
    {
      text: 'grid.filterNotEqOperator',
      operator: 'neq',
    },
    {
      text: 'grid.filterGteOperator',
      operator: 'gte',
    },
    {
      text: 'grid.filterGtOperator',
      operator: 'gt',
    },
    {
      text: 'grid.filterLteOperator',
      operator: 'lte',
    },
    {
      text: 'grid.filterLtOperator',
      operator: 'lt',
    },
    {
      text: 'grid.filterIsNullOperator',
      operator: 'isnull',
    },
    {
      text: 'grid.filterIsNotNullOperator',
      operator: 'isnotnull',
    },
  ],
  date: [
    // {
    //   text: 'grid.filterEqOperator',
    //   operator: 'eq',
    // },
    // {
    //   text: 'grid.filterNotEqOperator',
    //   operator: 'neq',
    // },
    // {
    //   text: 'grid.filterAfterOrEqualOperator',
    //   operator: 'gte',
    // },
    {
      text: 'grid.filterAfterOperator',
      operator: 'gt',
    },
    {
      text: 'grid.filterBeforeOperator',
      operator: 'lt',
    },
    // {
    //   text: 'grid.filterBeforeOrEqualOperator',
    //   operator: 'lte',
    // },
    {
      text: 'grid.filterIsNullOperator',
      operator: 'isnull',
    },
    {
      text: 'grid.filterIsNotNullOperator',
      operator: 'isnotnull',
    },
  ],
  boolean: [
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
    },
  ],
};

export const EXTENDED_FILTER_OPERATORS = {
  ...FILTER_OPERATORS,
  text: [
    ...FILTER_OPERATORS.text,
    {
      text: 'grid.anyOf',
      operator: 'anyOf',
    },
  ],
  numeric: [
    ...FILTER_OPERATORS.numeric,
    {
      text: 'grid.anyOf',
      operator: 'anyOf',
    },
  ],
};
