import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const CellBoolean = ({ field, dataItem, className, style, inverted }) => {
  const value = inverted ? !dataItem[field] : dataItem[field];
  return (
    <td className={className} style={style} align="center">
      <Checkbox defaultChecked={value} checked={value} disabled style={{ marginTop: '4px' }} />
    </td>
  );
};

CellBoolean.defaultProps = {
  inverted: false,
};

CellBoolean.propTypes = {
  dataItem: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.string.isRequired,
};

export default CellBoolean;
