import React, { PureComponent } from 'react';
import { Button, Tooltip, Col, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import ServicesAuthorizationLimitsGridService from './servicesAuthorizationLimitsGrid.service';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { AUTHORIZATION_TYPE_COLUMN_CONFIG, CODE_NBU_COLUMN_CONFIG, SERVICES_COLUMN_CONFIG } from './constants';
import CellBoolean from '../../account/cellBoolean';
import UpdateModal from './UpdateModal';
import UpdateStatusModal from './UpdateStatusModal';

import './ServicesAuthorizationLimitsGrid.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class ServicesAuthorizationLimitsGrid extends PureComponent {
  currentArray = findArray('authorizationServicesLimitsGrid');

  state = {
    isLoading: false,
    services: [],
    nbuCodes: [],
    authorizationTypes: [],
    selectedServices: [],
    selectedNBU: [],
    selectedAuthorizationTypes: [],
    authorizationLimits: [],
    selectedItems: [],
    updateModalVariant: '',
    isChangeStatusModalOpen: false,
    isDeleteModalOpen: false,
    isShowDeletedLimits: false,
  };

  componentDidMount() {
    this.initializeFetchCallback();
  }

  initializeFetchCallback = async () => {
    await this.setState({ isLoading: true });
    await this.fetchNBUCodes();
    await this.fetchAuthorizationTypes();
    await this.setState({ isLoading: false });
  };

  fetchNBUCodes = async () => {
    try {
      const nbuCodes = await api.NBUCodesList();
      await this.setState({
        nbuCodes: ServicesAuthorizationLimitsGridService.normalizeNBUCodes(nbuCodes),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  returnFetchedServices = async (selectedNBU) => {
    const services = await api.getNBUServiceRelations({ f108Codes: selectedNBU });

    return DropdownNormalizersService.normalizeServices(services);
  };

  fetchServices = async (selectedNBU) => {
    if (!selectedNBU.length) {
      return;
    }

    try {
      const services = await api.getNBUServiceRelations({ f108Codes: selectedNBU });

      await this.setState({
        services: DropdownNormalizersService.normalizeServices(services),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchAuthorizationTypes = async () => {
    try {
      const authorizationTypes = await api.getAuthorizationTypeDictionaryList();
      await this.setState({
        authorizationTypes,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  handleSelectOwnersByName = (name) => (selectItems) => {
    this.setState({ [name]: selectItems, authorizationLimits: [] }, async () => {
      if (name === 'selectedNBU') {
        await this.setState({ selectedServices: [], selectedAuthorizationTypes: [], isLoading: true });
        await this.fetchServices(this.state.selectedNBU);
        await this.setState({ isLoading: false });
      }

      if (name === 'selectedServices') {
        this.setState({ selectedAuthorizationTypes: [] });
      }
    });
  };

  buildToolbar = () => {
    const {
      services,
      nbuCodes,
      authorizationTypes,
      selectedServices,
      selectedNBU,
      selectedAuthorizationTypes,
      selectedItems,
    } = this.state;
    const { translate } = this.props;

    return (
      <Col className="ServicesAuthorizationLimitsGrid-toolbar">
        <div className="ServicesAuthorizationLimitsGrid-searchContent">
          <div className="ServicesAuthorizationLimitsGrid-selects">
            <div className="ServicesAuthorizationLimitsGrid-selectRow">
              <div className="ServicesAuthorizationLimitsGrid-selectLabel">
                {translate('page.servicesAuthorizationLimits.col-nbuCode')}
              </div>

              <div className="ServicesAuthorizationLimitsGrid-select">
                <GridDropdown
                  data={nbuCodes}
                  colConfig={CODE_NBU_COLUMN_CONFIG}
                  selectItems={selectedNBU}
                  onSave={this.handleSelectOwnersByName('selectedNBU')}
                  defaultTitle={translate('page.servicesAuthorizationLimits.selectCode')}
                />
              </div>
            </div>

            <div className="ServicesAuthorizationLimitsGrid-selectRow">
              <div className="ServicesAuthorizationLimitsGrid-selectLabel">
                {translate('page.commissionsServices.service')}
              </div>

              <div className="ServicesAuthorizationLimitsGrid-select">
                <GridDropdown
                  data={services}
                  colConfig={SERVICES_COLUMN_CONFIG}
                  selectItems={selectedServices}
                  onSave={this.handleSelectOwnersByName('selectedServices')}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                  disabled={!selectedNBU.length}
                />
              </div>
            </div>

            <div className="ServicesAuthorizationLimitsGrid-selectRow">
              <div className="ServicesAuthorizationLimitsGrid-selectLabel">
                {translate('page.servicesAuthorizationLimits.authorizationType')}
              </div>

              <div className="ServicesAuthorizationLimitsGrid-select">
                <GridDropdown
                  data={authorizationTypes}
                  colConfig={AUTHORIZATION_TYPE_COLUMN_CONFIG}
                  selectItems={selectedAuthorizationTypes}
                  onSave={this.handleSelectOwnersByName('selectedAuthorizationTypes')}
                  defaultTitle={translate('page.servicesAuthorizationLimits.selectAuthorizationType')}
                />
              </div>
            </div>
          </div>

          <div className="ServicesAuthorizationLimitsGrid-searchAction">
            <Button
              type="primary"
              disabled={!selectedServices.length || !selectedNBU.length || !selectedAuthorizationTypes.length}
              onClick={this.fetchAuthorizationLimits}
            >
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>

        <div className="ServicesAuthorizationLimitsGrid-actions">
          {checkElement('authorizationServicesLimitsGrid-addItem', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ updateModalVariant: 'addLimit' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('authorizationServicesLimitsGrid-changeItem', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ updateModalVariant: selectedItems.length > 1 ? 'updateLimits' : 'updateLimit' });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('authorizationServicesLimitsGrid-changeStatusItem', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({
                  isChangeStatusModalOpen: true,
                });
              }}
            >
              {translate('page.servicesAuthorizationLimits.changeStatus')}
            </Button>
          )}

          {checkElement('authorizationServicesLimitsGrid-deleteItem', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({
                  isDeleteModalOpen: true,
                });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  fetchAuthorizationLimits = async () => {
    const { selectedServices, selectedAuthorizationTypes, isShowDeletedLimits } = this.state;

    if (!selectedServices.length || !selectedAuthorizationTypes.length) {
      return;
    }

    try {
      this.setState({ isLoading: true });

      const authorizationLimits = await api.getServiceAuthorizationLimits({
        servicesList: selectedServices,
        authorizationIdList: selectedAuthorizationTypes,
        withDeleted: isShowDeletedLimits,
      });

      await this.setState({
        authorizationLimits: ServicesAuthorizationLimitsGridService.normalizeAuthorizationLimits(authorizationLimits),
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  toggleShowDeletedCallback = () => {
    this.setState(
      ({ isShowDeletedLimits }) => ({ isShowDeletedLimits: !isShowDeletedLimits }),
      () => {
        this.fetchAuthorizationLimits();
      },
    );
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  renderTooltipBoolean = ({ field, dataItem }) => {
    const { nbuCodes } = this.state;

    const value = dataItem[field];
    const currentCodeNBUItem = nbuCodes && nbuCodes.length ? nbuCodes.find(({ Id }) => Id === value) : null;
    const tooltipTitle = currentCodeNBUItem ? currentCodeNBUItem.Description : '';

    return (
      <td>
        <Tooltip title={tooltipTitle}>
          <div>{value}</div>
        </Tooltip>
      </td>
    );
  };

  deleteSelectedLimits = async () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.removeServiceAuthorizationLimits(selectedItems.map(({ Id }) => Id));
      this.setState({ isDeleteModalOpen: false });
      message.success(translate('page.servicesAuthorizationLimits.limitsStatusDeleteSuccessfully'), 2);
      this.fetchAuthorizationLimits();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate } = this.props;
    const {
      isLoading,
      authorizationLimits,
      updateModalVariant,
      nbuCodes,
      authorizationTypes,
      selectedItems,
      isChangeStatusModalOpen,
      isShowDeletedLimits,
      isDeleteModalOpen,
    } = this.state;

    if (!checkElement('authorizationServicesLimitsGrid-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={authorizationLimits}
          name="servicesAuthorizationLimitsGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchAuthorizationLimits}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItems}
          isLoading={isLoading}
          showRemoteLabel="showDeletedLimits"
          isRemoteShow={isShowDeletedLimits}
          showRemoteCallback={this.toggleShowDeletedCallback}
          multiSelected
          allowWordWrap
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            columnMenu={false}
            sortable={false}
            showAllSelected={true}
          />
          <GridColumn
            field="F108"
            title="page.servicesAuthorizationLimits.col-nbuCode"
            width="150"
            cell={this.renderTooltipBoolean}
          />
          <GridColumn field="ServiceId" title="grids.servicesBigTable.column-serviceId" width="150" filter="numeric" />
          <GridColumn field="ServiceName" title="page.serviceMainProps.nameService" width="200" />
          <GridColumn field="ServiceStatusName" title="page.serviceMainProps.status" width="150" />
          <GridColumn
            field="AuthorizationName"
            title="page.servicesAuthorizationLimits.col-authorization"
            width="150"
          />
          <GridColumn
            field="OwnAccount"
            title="page.servicesAuthorizationLimits.col-ownAccount"
            width="260"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="FinmonRecepient"
            title="page.servicesAuthorizationLimits.col-finMonRecipient"
            width="260"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="FinmonPayer"
            title="page.servicesAuthorizationLimits.col-finMonPayer"
            width="240"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="TransactionMaxLimitPayer"
            title="page.servicesAuthorizationLimits.col-transactionLimitPayerPhone"
            width="180"
            filter="numeric"
          />
          <GridColumn
            field="DailyLimitPayer"
            title="page.servicesAuthorizationLimits.col-dailyLimitPayerPhone"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MonthlyLimitPayer"
            title="page.servicesAuthorizationLimits.col-monthlyLimitPayerPhone"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="TransactionMaxLimit"
            title="page.servicesAuthorizationLimits.col-transactionLimitRecipient"
            width="180"
            filter="numeric"
          />
          <GridColumn
            field="DailyLimit"
            title="page.servicesAuthorizationLimits.col-dailyLimitRecipient"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="MonthlyLimit"
            title="page.servicesAuthorizationLimits.col-monthlyLimitRecipient"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="CheckLimitPayer"
            title="page.servicesAuthorizationLimits.col-checkLimitPayer"
            width="180"
            cell={WrappedCellBoolean}
          />
          <GridColumn
            field="MonthlyLimitWithoutOtp"
            title="page.servicesAuthorizationLimits.col-monthlyLimitWithOutOtp"
            width="200"
          />
          <GridColumn
            field="TransactionMinLimit"
            title="page.servicesAuthorizationLimits.col-amountMin"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="DailyTransactionsCountLimit"
            title="page.servicesAuthorizationLimits.col-dailyTransactionsCountLimit"
            width="180"
            filter="numeric"
          />
          <GridColumn
            field="IsActive"
            title="page.servicesAuthorizationLimits.col-limitStatus"
            cell={WrappedCellBoolean}
            width="160"
          />
          <GridColumn
            field="DateFrom"
            title="page.commissionsServices.col-dateOfChange"
            width="150"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn field="UserName" title="page.commissionsServices.col-executor" width="150" />
        </Grid>

        {updateModalVariant && (
          <UpdateModal
            modalVariant={updateModalVariant}
            closeModal={() => {
              this.setState({ updateModalVariant: '' });
            }}
            dropdownsData={{ nbuCodes, authorizationTypes }}
            returnFetchedServices={this.returnFetchedServices}
            onRefresh={this.fetchAuthorizationLimits}
            selectedItems={selectedItems}
          />
        )}

        {isChangeStatusModalOpen && (
          <UpdateStatusModal
            visible={isChangeStatusModalOpen}
            closeModal={() => {
              this.setState({ isChangeStatusModalOpen: false });
            }}
            deleteContent={translate('page.servicesAuthorizationLimits.areYouSure')}
            selectedItems={selectedItems}
            onRefresh={this.fetchAuthorizationLimits}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            modalTitle={translate('page.servicesAuthorizationLimits.deleteLimits')}
            visible={isDeleteModalOpen}
            closeModal={() => {
              this.setState({ isDeleteModalOpen: false });
            }}
            submitCallback={this.deleteSelectedLimits}
            deleteContent={translate('page.servicesAuthorizationLimits.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ServicesAuthorizationLimitsGrid);
