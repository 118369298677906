import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col } from 'antd';

import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdown from '../../components/GridDropdown/GridDropdown';

const api = new ApiService();

const statuses = ['all', 'active', 'disabled', 'arested', 'deleted', 'testing'];

class GridTerminalCertificates extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('certificates');
  }

  state = {
    owners: [],
    selectedOwners: [],
    isLoading: false,
    data: [],
    statusValue: statuses[0],
  };

  componentDidMount() {
    this.fetchOwners();
  }

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({
        owners:
          owners && owners.length
            ? DropdownNormalizersService.normalizeOwners([
                {
                  OwnerId: 0,
                  OwnerType: '-',
                  ShortName: 'Все диллеры',
                  OwnerStatus: 'Арестован',
                  OwnerAddress: 'м.Київ. вул. Межигірська, 82а',
                  OwnerStatusId: 3,
                  OwnerTypeId: 2,
                },
                ...owners,
              ])
            : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  rowRender = (element, props) => {
    const date = new Date();
    const { style: elementStyle, children } = element.props;
    const diffLessWeek = props.dataItem.CertExpiryDate - date;
    const bufferValueToDate = parseInt(Math.floor(diffLessWeek / 60000 / 1440));
    if (bufferValueToDate > 0 && bufferValueToDate <= 7) {
      const color = 'rgba(255, 194, 132, .8)';
      const style = { background: `${color}`, ...elementStyle };
      return React.cloneElement(element, { style }, children);
    }
    if (props.dataItem.CertExpiryDate < date) {
      const color = 'rgba(	255, 83, 73, .2)';
      const style = { background: `${color}`, ...elementStyle };
      return React.cloneElement(element, { style }, children);
    }
    return element;
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { selectedOwners } = this.state;

    if (!selectedOwners.length) return;

    try {
      this.toggleLoader(true);
      const data = await api.terminalCertificates(selectedOwners[0]);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwnersSelect = (selectedOwners) => {
    this.setState({ selectedOwners }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, selectedOwners } = this.state;

    if (!owners || !owners.length) {
      return <></>;
    }

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdown
          data={owners}
          colConfig={OWNERS_COLUMN_CONFIG}
          selectItems={selectedOwners}
          onSave={this.onOwnersSelect}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
          isSingle
        />
      </Col>
    );
  };

  renderTerminalIdOnclick = (props) => tabService.addTab({ type: 'issued', dataItem: props.dataItem });

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        rowRender={this.rowRender}
        isLoading={isLoading}
        name="terminalsCertificates"
        permissionName="certificates"
      >
        <GridColumn
          field="CertStartDate"
          title="grids.certificates.certificate-start-date"
          width="240"
          filter="date"
          format="dd.MM.yyyy"
        />

        <GridColumn
          field="CertExpiryDate"
          title="grids.certificates.certificate-expiry-date"
          width="240"
          filter="date"
          format="dd.MM.yyyy"
        />

        <GridColumn
          field="TerminalId"
          title="grids.certificates.certificate-terminalId"
          width="140"
          onClick={
            checkElement('terminalsCertificates-terminalIdOnclick', this.currentArray)
              ? (props) => this.renderTerminalIdOnclick(props)
              : null
          }
          // onClick={(props) => this.renderTerminalIdOnclick(props)}
          filter="numeric"
        />

        <GridColumn field="TypeName" title="grids.certificates.certificate-type-name" width="150" />

        <GridColumn field="CityName" title="grids.certificates.certificate-city-name" width="150" />

        <GridColumn field="Address" title="grids.certificates.certificate-address" width="150" />

        <GridColumn field="TerminalName" title="grids.certificates.certificate-terminal-name" width="150" />

        <GridColumn
          field="DateLastConnect"
          title="grids.certificates.certificate-date-last-connect"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="DateLastTransaction"
          title="grids.certificates.certificate-date-last-transaction"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="DatePost"
          title="grids.certificates.certificate-date-post"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn field="StatusName" title="grids.certificates.certificate-status-name" width="150" />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalCertificates);
