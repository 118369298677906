import React, { PureComponent } from 'react';
import FormStateContainer from '../../components/FormStateContainer';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import AddPayment from './AddPayment';
import { INITIAL_VALUES } from './AddPayment/constants';

const api = new ApiService();

class ExternalOutgoingPaymentsBalance extends PureComponent {
  state = {
    formValues: null,
  };

  componentDidMount() {
    const { id } = this.props;
    if (!id) {
      this.setState({ formValues: INITIAL_VALUES });
    } else this.getPayment(id);
  }

  getPayment = async (id) => {
    try {
      const data = await api.getPaymentsExternalInserted(id);
      const {
        PaymentId,
        Amount,
        AAccountId,
        BMfo,
        ACustomerId,
        BAccount,
        BName,
        BIdClient,
        Number,
        Narrative,
        DatePost,
        SupplierId,
        Comments,
        ExtendedData,
        PayerId,
        PayerName,
        BudgetCode,
        StatusId,
        Iban,
      } = data;
      const formStateData = {
        paymentId: PaymentId,
        amount: Amount,
        aAccountId: [AAccountId],
        bMfo: [BMfo],
        aName: [ACustomerId],
        bAccount: BAccount,
        bName: BName,
        bIdClient: BIdClient,
        number: Number,
        narrative: Narrative,
        datePost: DatePost,
        approve: true,
        signature: '',
        autoNumber: false,
        supplierId: SupplierId,
        comments: Comments,
        typeId: 0,
        extendedData: ExtendedData,
        payerId: PayerId,
        payerName: PayerName,
        budgetCode: BudgetCode,
        iban: Iban,
        StatusId: StatusId,
      };
      this.setState({ formValues: formStateData });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  render() {
    const { formValues } = this.state;
    if (formValues === null) {
      return <></>;
    }
    const { id } = this.props;

    return (
      <FormStateContainer initialValues={formValues}>
        <AddPayment isEdit={!!id} />
      </FormStateContainer>
    );
  }
}

export default withTranslate(ExternalOutgoingPaymentsBalance);
