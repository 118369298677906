const navigationMenuPropGrid = (translate, value) => {
  switch (value) {
    case 'navigationMenuProp-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'navigationMenuProp-delete':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.delete')}`;
    case 'navigationMenuProp-change':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.change')}`;
    default:
      return value;
  }
};
export default navigationMenuPropGrid;
