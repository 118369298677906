class fishkaServiceHistoryGridService {
  getMinimumSelectedDate = () => new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    if (minimumSelectedDate - startDate > 0 || minimumSelectedDate - endDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    const SIX_MONTH_AGO_BY_END_DATE = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth() - 6,
      new Date(endDate).getDate(),
    );

    if (SIX_MONTH_AGO_BY_END_DATE - startDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new fishkaServiceHistoryGridService();
