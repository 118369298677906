export const INITIAL_VALUES = {
  serviceId: '',
  serviceName: '',
  serviceDescription: '',
  commissionRules: '',
  limitType: [],
  provider: [0],
  status: [],
  serviceType: [],
  transitService: [],
  isTransitServiceActive: false,
  sum: '',
  minSum: '',
  maxSum: '',
  orgSum: '',
  isRiskService: false,
  isClientCommission: false,
  isAddressPayment: false,
  unlockingPeriod: '',
  bankDetails: [],
  isBasicServiceAllowed: false,
  basicService: [],
  categoryManager: [],
  supportManager: [],
  engagementManager: [],
  dateChange: null,
  createDate: null,
  paymentTool: [],
};
