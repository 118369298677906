import React, { PureComponent } from 'react';
import { Col, Row } from 'antd';

import Input from '../Input';
import { getExistValueValidator } from './validators';

import './ExpiredPasswordInfoModal.scss';

class PasswordRecoveryForm extends PureComponent {
  state = {};
  renderField = (labelTranslateSlug, name, type) => {
    const { formState, onChangeFormState, onError, formErrors, translate, onDefaultInputChange } = this.props;

    return (
      <Row className="PasswordRecoveryForm-row">
        <Col span={8} sm={6}>
          {translate(labelTranslateSlug)}
        </Col>
        <Col span={15} sm={17} offset={1}>
          <Input
            formState={formState}
            onChangeFormState={(name, value) => {
              onDefaultInputChange();
              onChangeFormState(name, value);
            }}
            formErrors={formErrors}
            setError={onError}
            validators={{
              [name]: getExistValueValidator(translate),
            }}
            type={type}
            name={name}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { formErrors } = this.props;
    return (
      <>
        {this.renderField('page.expiredPasswordInfoModal.newPassword', 'newPassword', 'password')}
        {this.renderField('page.expiredPasswordInfoModal.repeat', 'repeat', 'password')}
        {formErrors.oldNewPasswordsMatchError && (
          <div style={{ color: 'red' }}>{formErrors.oldNewPasswordsMatchError}</div>
        )}
        {formErrors.newPasswordNotMatchConfirmed && (
          <div style={{ color: 'red' }}>{formErrors.newPasswordNotMatchConfirmed}</div>
        )}
      </>
    );
  }
}

export default PasswordRecoveryForm;
