import React, { PureComponent } from 'react';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService, tabService } from '../../services';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col } from 'antd';
import DeleteModal from '../../components/DeleteModal';
import ModalCreateBusinessService from '../screenControl(terminalsControl)/businessServices/modalCreateBusinessService/modalCreateBusinessService';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

export class BackupButtonsGrid extends PureComponent {
  currentArray = findArray('backupButtons');

  state = {
    profileReserveList: [],
    isLoading: false,
    selectedItems: [],
    currentDialog: '',
  };

  componentDidMount() {
    this.getMainProfileReserveList();
  }

  getMainProfileReserveList = async () => {
    try {
      this.setState({ isLoading: true });
      const profileReserveList = await api.getMainProfileReserveList();
      this.setState({ profileReserveList });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, profileReserveList } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {checkElement('backupButtons-deleteTemplate', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ currentDialog: 'deleteTemplates' });
            }}
          >
            {translate('grids.profilesMainProfileList.modal-delete-template-title')}
          </Button>
        )}

        {checkElement('backupButtons-createTemplate', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            disabled={!profileReserveList.length}
            onClick={() => {
              this.setState({ currentDialog: 'addTemplate' });
            }}
          >
            {translate('grids.profilesMainProfileList.modal-create-template-title')}
          </Button>
        )}

        {checkElement('backupButtons-assignTemplate', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={() => {
              tabService.addTab({
                type: 'setTemplate',
                profileReserveList: profileReserveList,
              });
            }}
            disabled={!profileReserveList.length}
          >
            {translate('grids.profilesMainProfileList.modal-assign-template-title')}
          </Button>
        )}
      </Col>
    );
  };

  onDelete = async () => {
    const { selectedItems } = this.state;

    this.setState({ currentDialog: '' });
    this.setState({ isLoading: true });
    try {
      if (!selectedItems.length) {
        return;
      }

      const model = selectedItems.map(({ MainProfileReserveId }) => MainProfileReserveId);
      await api.mainProfileReserveDelete(model);
      await this.getMainProfileReserveList();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onCloseModal = () => {
    this.setState({ currentDialog: '' });
  };

  onEditTabOpen = (props) => {
    return tabService.addTab({
      type: 'editTemplate',
      dataItem: { ...props.dataItem },
    });
  };

  render() {
    const { translate } = this.props;
    const { profileReserveList, isLoading, currentDialog } = this.state;

    if (!checkElement('backupButtons-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={profileReserveList}
          onRefresh={this.getMainProfileReserveList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="backupButtonsGrid"
          fieldForSelection="MainProfileReserveId"
          onSelect={this.handleSelectionChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="MainProfileReserveId"
            title="page.backupButtons.col-templateNumber"
            width="120"
            filter="numeric"
          />
          <GridColumn
            field="MainProfileReserveName"
            title="page.backupButtons.col-backupButtonTemplate"
            width="240"
            onClick={checkElement('backupButtons-editTemplate', this.currentArray) ? this.onEditTabOpen : null}
          />
          <GridColumn
            field="MainProfileReserveVersion"
            title="page.backupButtons.col-templateVersion"
            width="160"
            filter="numeric"
          />
          <GridColumn
            field="MainProfileCount"
            title="page.backupButtons.col-numberOfProfiles"
            width="200"
            filter="numeric"
          />
          <GridColumn
            field="DatePost"
            title="grids.businessServices.column-datePost"
            width="180"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="UserIdName" title="grids.businessServices.column-userIdName" width="180" />
        </Grid>
        {currentDialog === 'deleteTemplates' && (
          <DeleteModal
            visible={currentDialog === 'deleteTemplates'}
            closeModal={this.onCloseModal}
            submitCallback={this.onDelete}
            deleteContent={translate('grids.profilesMainProfileList.modal-delete-template-title')}
          />
        )}
        {currentDialog === 'addTemplate' && (
          <ModalCreateBusinessService
            data={profileReserveList}
            visible={currentDialog === 'addTemplate'}
            closeModal={this.onCloseModal}
            fieldsForSelection={{
              Id: 'MainProfileReserveId',
              Name: 'MainProfileReserveName',
              Version: 'MainProfileReserveVersion',
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(BackupButtonsGrid);
