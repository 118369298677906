import React, { PureComponent } from 'react';
import { Col, Card, Input, Row } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import findArray from '../../utils/findArrForCurrentPage';
import './gridMessages.scss';
import './singleMessage.scss';
import LoadingPanel from '../../components/loader';

const api = new ApiService();

const { TextArea } = Input;

class GridSingleMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('messages');
  }
  state = {
    isLoading: false,
    subject: '',
    text: '',
    link: '',
  };

  componentDidMount() {
    this.makeQuery();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    try {
      this.toggleLoader(true);
      const {
        item: { MessageId },
      } = this.props;
      const model = {
        MessageId,
      };
      const data = await api.ownersMessageGet(model);
      const request = await api.ownersMessagesSetRead(model);
      this.setState({
        subject: data.Subject,
        text: data.Body,
        link: data.Link,
      });
      return request;
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    return <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}></Col>;
  };

  statusToColor = (value) => {
    switch (value) {
      case 1:
      case 2:
        return 'bold';
      default:
        return 'normal';
    }
  };

  truncate = (str, length) => {
    if (str.length <= length) return str;
    else return `${str.slice(0, length)}`;
  };

  render() {
    const { isLoading, subject, text, link } = this.state;
    const { translate } = this.props;
    const subjectTrunced = this.truncate(subject, 100);
    const textTrunced = this.truncate(text, 1000);
    return (
      <Card
        type="inner"
        className="newTerminal--card w-50 singleMessage-card"
        // title={translate('page.terminalSettings.general-settings-title')}
        style={{ marginTop: '20px' }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <Row className="newTerminal--card-row mt-10" type="flex" align="middle">
              <p className="form-text">{translate('grids.messages.column-subject')}:</p>
              <TextArea maxLength={100} disabled style={{ height: '100px' }} value={subjectTrunced} />
            </Row>
            <Row className="newTerminal--card-row mt-20" type="flex" align="middle">
              <p className="form-text">{translate('grids.messages.column-text')}:</p>
              <TextArea maxLength={1000} style={{ height: '100px' }} value={textTrunced} disabled />
            </Row>
            <Row className="newTerminal--card-row mt-20" type="flex" align="middle">
              <p className="form-text">{translate('grids.messages.field-link')}:</p>
              <a className="singleMessage--link" href={link}>
                {link}
              </a>
            </Row>
          </Col>
        </Row>
        {isLoading && <LoadingPanel />}
      </Card>
    );
  }
}

export default withTranslate(GridSingleMessage);
