class TemplateTerminalsGridService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.customTemplateRef) {
      return { templateTerminals: [], hasNextPage: false, hasPreviousPage: false };
    }

    const templateTerminals = data.data.customTemplateRef;
    const pageInfo = templateTerminals.pageInfo;

    const resultTemplateTerminals =
      templateTerminals.items && templateTerminals.items.length
        ? templateTerminals.items.map((templateTerminal) => ({
            ...templateTerminal,
            editId: `${templateTerminal.terminalId}-${templateTerminal.templateId}-${templateTerminal.ownerId}`,
          }))
        : [];

    return {
      templateTerminals: resultTemplateTerminals,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}

export default new TemplateTerminalsGridService();
