import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Select } from 'antd';

import { ApiService, StorageService, tabService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import { AVAILABLE_PERIOD_LIST, MONTH_DIFFERENCE_BY_ID_MAP, MONTH_TRANSLATIONS_MAP, REPORT_TYPES } from './constants';
import { getCustomDate } from '../../../../utils/getCustomDate';
import { getLastMonthTimeByDate } from '../../../../utils/getLastMonthTimeByDate';

import './gridTerminalsOperabilityChoose.scss';
import GridDropdownWithDeletedOwners from '../../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();
const { Option } = Select;
const convertToISOFormat = (date) => formatDate(date, 'yyyy-MM-ddTHH:mm:ssZ');

class GridTerminalsOperability extends Component {
  currentArray = findArray('terminalOperabilityPeriod');

  state = {
    isLoading: false,
    data: [],
    owners: [],
    selectedAvailablePeriodId: AVAILABLE_PERIOD_LIST[0].value,
    reportType: REPORT_TYPES[0].value,
    selectedItems: [],
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getTerminals = async () => {
    const { owners } = this.state;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        HideDeleted: true,
      };
      const data = await api.getTerminalsByOwner(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentWillUnmount() {
    StorageService.removeItem('checkSelected-terminalOperabilityPeriodChoose');
  }

  onMonthSelectChange = (selectedAvailablePeriodId) => {
    this.setState({ selectedAvailablePeriodId });
  };

  onOperabilityValueChange = (reportType) => {
    this.setState({ reportType });
  };

  getDateBySelectedPeriod = () => {
    const { selectedAvailablePeriodId } = this.state;
    const monthDifference = MONTH_DIFFERENCE_BY_ID_MAP.get(selectedAvailablePeriodId) || 0;

    return getCustomDate({ customDayOfMonth: 1, customDateMonth: new Date().getMonth() - monthDifference });
  };

  onPushToTerminalsPeriodGrid = async () => {
    const { owners, reportType, selectedItems } = this.state;
    const { translate } = this.props;
    const startDate = this.getDateBySelectedPeriod();

    try {
      this.toggleLoader(true);
      await tabService.addTab({
        type: 'terminalsPeriod',
        terminalsPeriodProps: {
          ownerId: owners.length ? owners[0] : null,
          terminalList: selectedItems.map(({ TerminalId }) => TerminalId),
          dateStart: convertToISOFormat(startDate),
          dateEnd: convertToISOFormat(getLastMonthTimeByDate(startDate)),
          currentMonth: translate(MONTH_TRANSLATIONS_MAP.get(startDate.getMonth()) || 'core.month.january'),
          reportType: reportType,
        },
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedAvailablePeriodId, reportType, selectedItems, allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 16 }}>
        <div className="terminalsOperability-dropdown-wrapper">
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(allOwners)}
            selectItems={owners}
            onSave={this.onOwners}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isGetDefaultSelectItemsFromStorage
          />
        </div>
        <div className="terminalsOperability-select-wrapper">
          {checkElement('terminalOperabilityPeriodChoose-isMonthDropdown', this.currentArray) && (
            <Select
              value={selectedAvailablePeriodId}
              onChange={this.onMonthSelectChange}
              className="modal-system-encashment--select terminalsOperability-select month"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              showSearch={false}
            >
              {AVAILABLE_PERIOD_LIST.map(({ value, name }) => {
                return (
                  <Option key={name} value={value}>
                    {translate(name)}
                  </Option>
                );
              })}
            </Select>
          )}

          {checkElement('terminalOperabilityPeriodChoose-isOperabilityDropdown', this.currentArray) && (
            <Select
              value={reportType}
              onChange={this.onOperabilityValueChange}
              className="modal-system-encashment--select terminalsOperability-select operability"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              showSearch={false}
            >
              {REPORT_TYPES.map(({ value, name }) => {
                return (
                  <Option key={name} value={value}>
                    {translate(name)}
                  </Option>
                );
              })}
            </Select>
          )}
          {checkElement('terminalOperabilityPeriodChoose-btn-search', this.currentArray) && (
            <Button
              className="sm-w-100 btn-search"
              icon="search"
              primary
              onClick={this.onPushToTerminalsPeriodGrid}
              disabled={!selectedItems.length}
            >
              {translate('owner.search')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.getTerminals);
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.getTerminals}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        toggleLoader={this.toggleLoader}
        name="terminalOperabilityPeriodChoose"
        permissionName="terminalOperabilityPeriod"
        fieldForSelection="TerminalId"
        onSelect={this.handleSelectionChange}
        multiSelected
      >
        <GridColumn
          field={'selected'}
          width="50px"
          filterable={false}
          sortable={false}
          showAllSelected={true}
          columnMenu={false}
        />

        <GridColumn field="OwnerName" title="grids.terminalOperabilityChoose.column-ownerName" width="250" />

        <GridColumn
          field="TerminalId"
          title="grids.terminalOperabilityChoose.column-terminalId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="TerminalName" title="grids.terminalOperabilityChoose.column-name" width="250" />

        <GridColumn
          field="NetworkContractName"
          title="grids.terminalOperabilityChoose.column-networkContract"
          width="250"
        />

        <GridColumn field="RegionName" title="grids.terminalOperabilityChoose.column-region" width="250" />

        <GridColumn field="CityName" title="grids.terminalOperabilityChoose.column-city" width="200" />

        <GridColumn field="TerminalAddress" title="grids.terminalOperabilityChoose.column-address" width="250" />

        <GridColumn field="StatusName" title="grids.terminalOperabilityChoose.column-status" width="250" />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalsOperability);
