import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Select, Row, Col, Input, Alert } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { StorageService, ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import './modalWhiteListAdd.scss';

const api = new ApiService();

const { Option } = Select;

const { TextArea } = Input;

class ModalWhiteLisAdd extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  formRef = React.createRef();

  state = {
    serviceValue: null,
    services: [],
    isLoading: false,
    phoneAccountValue: null,
    cardValue: null,
    commentValue: null,
    errorText: null,
    noSelected: false,
  };

  componentDidMount() {
    this.getServices();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { serviceValue } = this.state;
    if (prevState.serviceValue !== serviceValue && serviceValue !== null) {
      this.setState({
        phoneAccountValue: null,
        cardValue: null,
      });
    }
  }

  getServices = async () => {
    const { owners, translate } = this.props;
    // const selectionListForGrid = StorageService.getItem('selectionListForGrid-whiteListAdminAdd');
    //
    // if (!selectionListForGrid || selectionListForGrid.length < 1) {
    //   this.setState({
    //     noSelected: true,
    //     errorText: `${translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}`,
    //   });
    //   return;
    // }
    //
    if (!owners || owners.length < 1) return;
    try {
      const model = {
        OwnerId: owners,
      };
      this.toggleLoader();
      const services = await api.terminalsWhitelistService(model);
      this.setState({ services });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  onSelectChangeService = (serviceValue) => {
    this.setState({ serviceValue });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  checkCardValue = (cardValue) => {
    const itemsArr = cardValue.split(/[,]+/);
    const { translate } = this.props;
    const trimmedElems = itemsArr.map((el) => parseInt(el.split(' ').join('')));
    const errorElem = trimmedElems.find((el) => el.toString().length !== 16 || !isFinite(el));
    let errorText = null;
    if (errorElem !== undefined) {
      errorText = `${translate('page.whiteList.modal-add-account-error')}`;
    }
    this.setState({ errorText });
    return errorText;
  };

  checkPhoneValue = (phoneAccountValue) => {
    const itemsArr = phoneAccountValue.split(/[,]+/);
    const { translate } = this.props;
    const trimmedElems = itemsArr.map((el) => el.split(' ').join(''));
    const regExp = /^380\d{3}\d{2}\d{2}\d{2}$/;
    const errorElem = trimmedElems.find((el) => String(el).match(regExp) === null);
    let errorText = null;
    if (errorElem !== undefined) {
      errorText = `${translate('page.whiteList.modal-add-card-error')}`;
    }
    this.setState({ errorText });
    return errorText;
  };

  onSubmit = async () => {
    const { showError, closeModal, selectedItems } = this.props;
    const { phoneAccountValue, cardValue, commentValue, serviceValue } = this.state;
    // const selectionListForGrid = StorageService.getItem('selectionListForGrid-whiteListAdminAdd');
    let errorText = null;
    if (cardValue) errorText = this.checkCardValue(cardValue);
    if (phoneAccountValue) errorText = this.checkPhoneValue(phoneAccountValue);
    if (!errorText) {
      try {
        this.toggleLoader();
        const model = {
          TerminalId: selectedItems,
          ServiceId: serviceValue,
          PhoneAccounts: phoneAccountValue ? phoneAccountValue : '',
          CardAccounts: cardValue ? cardValue : '',
          Comment: commentValue,
        };
        const response = await api.terminalsWhitelistAccountsInsert(model);
        closeModal();
        window.location.reload(true);
        return response;
      } catch (error) {
        showError(error);
      } finally {
        this.toggleLoader();
      }
    } else return;
  };

  defaultServiceValue = () => {
    const { translate } = this.props;
    return translate('page.screenControlBusinessServices.default-service-value');
  };

  onChangePhoneAccountValue = (e) => {
    this.setState({ phoneAccountValue: e.target.value });
  };

  onChangeCardAccountValue = (e) => {
    this.setState({ cardValue: e.target.value });
  };

  onChangeCommentValue = (e) => {
    this.setState({ commentValue: e.target.value });
  };

  findAccountBind = (prop) => {
    const { serviceValue, services } = this.state;
    const elem = services.find((el) => el.ServiceId === serviceValue);
    /* возвращаю true,
      когда есть привязка к телефону,
      открываю первый Input, иначе второй
    */
    if (elem && elem[prop] === true) return true;
    else return false;
  };

  render() {
    const { services, isLoading, phoneAccountValue, cardValue, commentValue, errorText, serviceValue } = this.state;
    const { translate, visible, closeModal } = this.props;
    const isAccountBindToPhone = this.findAccountBind('PhoneAccounts');
    const isAccountBindtoCard = this.findAccountBind('CardAccounts');
    // проверка на пустые значения
    const isDisabled = !commentValue || !serviceValue || (!phoneAccountValue && !cardValue);
    return (
      <Modal
        title={`${translate('page.whiteList.modal-add-title')}`}
        visible={visible}
        centered
        className="modal-system-encashment"
        zIndex={'1053'}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('grids.certificates-issued.onCancelText')}
          </Button>,
          <Button key="submit" type="primary" disabled={isDisabled} onClick={this.onSubmit}>
            {translate('grids.certificates-issued.onOkText')}
          </Button>,
        ]}
      >
        <Row
          className="modal-add-whiteList-row"
          type="flex"
          align="middle"
          style={{ flexWrap: 'wrap', marginTop: '10px' }}
        >
          <Col xs={24} md={8}>
            <p>{translate('page.whiteList.modal-add-select-text')}</p>
          </Col>
          <Col xs={24} md={16}>
            <Select
              className="modal-system-encashment--select"
              placeholder={this.defaultServiceValue()}
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              onChange={this.onSelectChangeService}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {services.map((service) => (
                <Option value={service.ServiceId} key={`service-number-${service.ServiceId}-${service.ServiceName}`}>
                  {service.ServiceId} - {service.ServiceName}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        {isAccountBindToPhone && (
          <Row
            className="modal-add-whiteList-row"
            type="flex"
            align="middle"
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Col xs={24} md={8}>
              <p>{translate('page.whiteList.modal-add-input-text-1')}</p>
            </Col>
            <Col xs={24} md={16}>
              <Input value={phoneAccountValue} onChange={this.onChangePhoneAccountValue} required />
            </Col>
            <Col xs={24}>
              <p className="input-warning">{translate('page.whiteList.modal-add-input-warning-1')}</p>
            </Col>
          </Row>
        )}

        {isAccountBindtoCard && (
          <Row
            className="modal-add-whiteList-row"
            type="flex"
            align="middle"
            style={{ flexWrap: 'wrap', marginTop: '10px' }}
          >
            <Col xs={24} md={8}>
              <p>{translate('page.whiteList.modal-add-input-text-2')}</p>
            </Col>
            <Col xs={24} md={16}>
              <Input value={cardValue} onChange={this.onChangeCardAccountValue} required />
            </Col>
            <Col xs={24}>
              <p className="input-warning">{translate('page.whiteList.modal-add-input-warning-2')}</p>
            </Col>
          </Row>
        )}
        <Row
          className="modal-add-whiteList-row"
          type="flex"
          align="middle"
          style={{ flexWrap: 'wrap', marginTop: '10px' }}
        >
          <Col xs={24} md={8}>
            <p>{translate('page.whiteList.modal-add-input-text-3')}</p>
          </Col>
          <Col xs={24}>
            <TextArea rows={2} maxLength={150} value={commentValue} onChange={this.onChangeCommentValue} required />
          </Col>
        </Row>
        {errorText && <Alert message={errorText} type="error" style={{ marginTop: '10px' }} showIcon />}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withRouter(withTranslate(ModalWhiteLisAdd));
