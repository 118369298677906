import React, { PureComponent } from 'react';
import { Button } from 'antd';

import { withTranslate } from '../../../../../contexts/localContext';
import { TRANSLATE_SLUGS } from './constants';
import CustomInput from '../../../../../components/Input/Input';
import GeneralPropsService from './GeneralProps.service';
import GridDropdown from '../../../../../components/GridDropdown/GridDropdown';
import { CONFIGS, defaultTitleTranslateSlugs } from '../../AddService/constants';
import UpdateServiceTypeModal from '../../UpdateServiceTypeModal/UpdateServiceTypeModal';

import './GeneralProps.styled.scss';

class GeneralProps extends PureComponent {
  state = {
    isDialogOpen: false,
  };

  renderInput = ({ name, type, isDisabled }) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="GeneralServiceProps-fieldRow">
        {TRANSLATE_SLUGS[name] && <div className="GeneralServiceProps-label">{translate(TRANSLATE_SLUGS[name])}</div>}
        <div className="GeneralServiceProps-input">
          <CustomInput
            type={type}
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            setError={onError}
            validators={GeneralPropsService.getValidators(translate)}
            name={name}
            onPressEnter={(e) => {
              e.preventDefault();
            }}
            disabled={isDisabled}
          />
        </div>
      </div>
    );
  };

  render() {
    const { isDialogOpen } = this.state;
    const {
      translate,
      formErrors,
      formState,
      serviceTypeList,
      onChangeFormState,
      currentServiceId,
      handleSave,
      fetchServiceTypes,
    } = this.props;
    const isErrorExist = Object.values(formErrors).some((errorMessage) => !!errorMessage);
    const isValueEmpty = Object.values(formState).some((value) => (Array.isArray(value) ? !value.length : !value));

    return (
      <>
        {this.renderInput({ name: 'id', isDisabled: currentServiceId })}
        {this.renderInput({ name: 'name', type: 'textArea' })}
        <div className="GeneralServiceProps-fieldRow">
          <div className="GeneralServiceProps-label">{translate('page.serviceMainProps.typeService')}</div>
          <div className="GeneralServiceProps-selectRow">
            <div className="GeneralServiceProps-input">
              <GridDropdown
                data={serviceTypeList}
                colConfig={CONFIGS.serviceType}
                selectItems={formState.serviceType}
                onSave={(selectServiceType) => {
                  onChangeFormState('serviceType', selectServiceType);
                }}
                defaultTitle={translate(defaultTitleTranslateSlugs.serviceType)}
                isSingle
              />
            </div>

            <div className="GeneralServiceProps-serviceTypeAction">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isDialogOpen: true });
                }}
              >
                ...
              </Button>
            </div>
          </div>
        </div>
        <Button
          type="primary"
          disabled={isErrorExist || isValueEmpty}
          onClick={() => {
            handleSave(formState);
          }}
        >
          {translate('page.servicesBigTable.saveAndNext')}
        </Button>

        <UpdateServiceTypeModal
          visible={isDialogOpen}
          serviceTypeList={serviceTypeList.map(({ Name }) => Name)}
          closeModal={() => {
            this.setState({ isDialogOpen: false });
          }}
          fetchServiceTypes={fetchServiceTypes}
        />
      </>
    );
  }
}

export default withTranslate(GeneralProps);
