import React, { Component } from 'react';
import { message } from 'antd';

import findArray from '../../utils/findArrForCurrentPage';
import terminalsDetailsServices from './terminalsDetails.services';
import TerminalDetails from './TerminalDetails';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';

const api = new ApiService();

class TerminalDetailsContainer extends Component {
  constructor(props) {
    super(props);
    const {
      item: { TerminalId },
    } = props;

    this.state = {
      terminalId: TerminalId,
      isEditing: false,
      isLoading: false,
      data: null,
      additionalCardVisibility: false,
      cities: [],
      comissionProfiles: [],
      mainProfilesList: [],
      statuses: [],
      placingInfo: [],
      terminalProjects: [],
      cityId: null,
      commisionProfileValue: null,
      mainProfilesListValue: null,
      statusesValue: '',
      terminalName: '',
      addressValue: '',
      geopointValue: '',
      capacityPrinterPaper: '',
      capacityCashBox: '',
      monitorCountValue: null,
      placingInfoValue: null,
      terminalDescription: '',
      dateCreation: null,
      dateCreationNoUTC: null,
      noteValue: '',
      terminalBranch: '',
      projectValue: '',
      networkContractsValue: '',
      networkContracts: [],
      typeId: null,
      currentUserOwnerId: null,
      currentRegionId: null,
      isAddressIncorrect: false,
      showApproveAddress: false,
      designTypeId: null,
      designTypes: [],
      selectMonitorCount: [0, 1, 2],
      fiscalNumber: '',
      businessUnit: '',
      successfullyGeneratedIdModalSlug: '',
      isFiscalNumberAlreadyExist: false,
      inventoryNumber: null,
      allOwners: [],
    };
  }

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const allOwners = await api.owners();
      this.setState({ allOwners, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.initRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { cityId } = this.state;
    if (prevState.cityId !== null && prevState.cityId !== cityId) {
      this.setState({ addressValue: '' });
    }
  }

  callBackForOnConfirmApproveAddress = () => {
    this.setState({ showApproveAddress: false, isAddressIncorrect: true });
  };

  onChangeCityIdCallback = (cityId, regionId) => {
    this.setState({ cityId }, () => this.onChangeMainProfilesListValueCallback(regionId));
  };

  onChangeMainProfilesListValueCallback = async (currentRegionId) => {
    const { mainProfilesList } = this.state;
    const mainProfilesListValue = await this.findMainProfileListValue(mainProfilesList, currentRegionId);
    this.setState({ mainProfilesListValue });
  };

  sortByMainProfileId = ({ MainProfileId: prevMainProfileId }, { MainProfileId: nextMainProfileId }) =>
    prevMainProfileId - nextMainProfileId;

  findMainProfileListValue = (mainProfilesList, currentRegionId) => {
    const filteredMainProfilesList = mainProfilesList.filter(({ RegionId }) => RegionId === currentRegionId);

    if (!filteredMainProfilesList.length) {
      return null;
    }

    const readOnlyProfilesList = filteredMainProfilesList.filter(({ Readonly }) => Readonly);

    if (readOnlyProfilesList.length) {
      return readOnlyProfilesList.sort(this.sortByMainProfileId)[0].MainProfileId;
    }

    return filteredMainProfilesList.sort(this.sortByMainProfileId)[0].MainProfileId;
  };

  initRequest = async () => {
    const { showError } = this.props;
    const { terminalId } = this.state;
    if (!terminalId) return;

    try {
      await this.setState({ isLoading: true });
      const { ...newStateData } = await terminalsDetailsServices.getRequestData(terminalId);
      this.setState({ ...newStateData });
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSubmitHandler = async () => {
    const {
      isEditing,
      terminalId,
      cityId,
      commisionProfileValue,
      mainProfilesListValue,
      statusesValue,
      terminalName,
      addressValue,
      geopointValue,
      capacityPrinterPaper,
      capacityCashBox,
      monitorCountValue,
      placingInfoValue,
      terminalDescription,
      dateCreationNoUTC,
      dateCreation,
      noteValue,
      terminalBranch,
      projectValue,
      networkContractsValue,
      typeId,
      currentUserOwnerId,
      designTypeId,
      fiscalNumber,
      businessUnit,
      inventoryNumber,
    } = this.state;
    const { showError, translate } = this.props;

    if (!isEditing) {
      this.setState(({ isEditing }) => {
        return {
          isEditing: !isEditing,
        };
      });

      return;
    }

    const errorMessage = terminalsDetailsServices.getError(translate, {
      cityId,
      addressValue,
      mainProfilesListValue,
      commisionProfileValue,
      placingInfoValue,
      capacityPrinterPaper,
      capacityCashBox,
      terminalName,
      terminalBranch,
      statusesValue,
      dateCreation,
      fiscalNumber,
    });

    if (errorMessage) {
      message.error(errorMessage, 2);

      return;
    }

    try {
      this.setState({ isLoading: true });

      const model = {
        TerminalId: terminalId,
        CityId: cityId,
        ProfileId: commisionProfileValue,
        MainProfileId: mainProfilesListValue,
        StatusId: statusesValue,
        Name: terminalName,
        TerminalAddress: addressValue,
        TerminalAddress2: '',
        Geopoint: geopointValue,
        CapacityPrinterPaper: capacityPrinterPaper,
        CapacityCashBox: capacityCashBox,
        MonitorCount: monitorCountValue,
        PlacingId: placingInfoValue,
        TerminalDescription: terminalDescription,
        DateCreationNoUTC: dateCreationNoUTC,
        DateCreation: dateCreation,
        Note: noteValue,
        DesignTypeId: designTypeId,
        TerminalBranch: terminalBranch,
        ProjectId: projectValue,
        NetworkContractId: networkContractsValue,
        TypeId: typeId,
        OwnerId: currentUserOwnerId,
        FiscalNumber: fiscalNumber || null,
        BusinessUnit: businessUnit,
        inventoryNumber: inventoryNumber,
      };
      const response = await api.updateTerminal(model);
      await message.success(`${translate('page.terminalSettings.success-update')}`, 2, () => window.location.reload());
      this.setState({ isEditing: false });
      return response;
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  generateInventoryNumber = async () => {
    const { terminalId } = this.state;
    const { showError } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await api.inventoryNumberGenerate(parseInt(terminalId));

      this.setState({ inventoryNumber: response }, () => {
        this.setState({ successfullyGeneratedIdModalSlug: 'InventoryNumber' });
      });
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }

    return null;
  };

  generateGOID = async () => {
    const { terminalId } = this.state;
    const { showError } = this.props;
    try {
      await this.setState({ isLoading: true });
      const response = await api.terminalsBusinessUnitGet(terminalId);
      this.setState({ businessUnit: response }, () => {
        this.setState({ successfullyGeneratedIdModalSlug: 'GO_ID' });
      });
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }

    return null;
  };

  toggleAdditionalCardVisibility = () => {
    this.setState(({ additionalCardVisibility }) => {
      return {
        additionalCardVisibility: !additionalCardVisibility,
      };
    });
  };

  getChangeCallbackByField = (fieldName) => (value) => {
    this.setState({ [fieldName]: value });
  };

  getChangeCallbackByEventByField = (fieldName) => (e) => {
    this.setState({ [fieldName]: e.target.value });
  };

  onChangeDateCreation = (e) => {
    this.setState({
      dateCreation: e.target.value,
      dateCreationNoUTC: e.target.value,
    });
  };

  getMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  onToggleShowApproveAddress = () => {
    this.setState(({ showApproveAddress }) => {
      return {
        showApproveAddress: !showApproveAddress,
      };
    });
  };

  migrateInventoryNumber = async (terminalIdFrom) => {
    const { terminalId } = this.state;

    return await api.inventoryNumberMigrate({
      terminalIdFrom,
      terminalIdTo: terminalId,
    });
  };

  render() {
    const {
      permissionSlugs: { permissionName, ...otherPermissions },
      item: { TerminalId, StatusName },
    } = this.props;

    const { isAddressIncorrect, fiscalNumber } = this.state;
    const onAddressInputFocus = isAddressIncorrect
      ? this.getChangeCallbackByEventByField('addressValue')
      : (e) => {
          e.target.blur();
          this.getChangeCallbackByField('successfullyGeneratedIdModalSlug')('ChangeAddress');
        };

    return (
      <TerminalDetails
        callBackForOnConfirmApproveAddress={this.callBackForOnConfirmApproveAddress}
        onAddressChange={this.onAddressChange}
        onChangeCityIdCallback={this.onChangeCityIdCallback}
        onSubmitHandler={this.onSubmitHandler}
        toggleAdditionalCardVisibility={this.toggleAdditionalCardVisibility}
        getChangeCallbackByField={this.getChangeCallbackByField}
        getChangeCallbackByEventByField={this.getChangeCallbackByEventByField}
        onChangeDateCreation={this.onChangeDateCreation}
        getMaxDate={this.getMaxDate}
        onToggleShowApproveAddress={this.onToggleShowApproveAddress}
        permissionsByName={findArray(permissionName)}
        formState={this.state}
        generateGOID={this.generateGOID}
        permissionSlugs={otherPermissions}
        generateInventoryNumber={this.generateInventoryNumber}
        onAddressInputFocus={onAddressInputFocus}
        closeModal={() => {
          this.getChangeCallbackByField('successfullyGeneratedIdModalSlug')('');
        }}
        migrateInventoryNumber={this.migrateInventoryNumber}
        terminalId={TerminalId}
        statusName={StatusName}
        fiscalNumber={fiscalNumber}
      />
    );
  }
}

export default withTranslate(TerminalDetailsContainer);
