import React, { Component } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Radio, Col, Row, Button } from 'antd';
import { TimePicker } from '@progress/kendo-react-dateinputs';

import './NewSchedule.scss';
import { SHORT_STRING_BY_DAY_MAP } from '../../screenControl(terminalsControl)/settingsAndEvents/additionalSettings/modalGraphicWorkingHours/constants';
import GraphicWorkingHours from '../../../components/GraphicWorkingHours';
import additionalSettingsService from '../../screenControl(terminalsControl)/settingsAndEvents/additionalSettings/additionalSettings.service';
import { ApiService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import LoadingPanel from '../../../components/loader';
import NewScheduleService from './NewScheduleService';

const api = new ApiService();

class NewSchedule extends Component {
  state = {
    isLoading: false,
    errorMessage: '',
  };

  onChangeRadioValue = ({ target: { value } }) => {
    const { onChangeFormState } = this.props;
    this.setState({ errorMessage: '' });
    onChangeFormState('scheduleSettings', value);
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const { onChangeFormState } = this.props;
    const defaultDaysScheduleData = [];
    SHORT_STRING_BY_DAY_MAP.forEach((_, value) => {
      defaultDaysScheduleData.push({
        DayOfWeek: value,
        DayOff: false,
        TurnOn: NewScheduleService.getDefaultStartDate(),
        TurnOff: NewScheduleService.getDefaultEndDate(),
      });
    });

    onChangeFormState('daysScheduleData', defaultDaysScheduleData);
    this.setState({ isLoading: false });
  }

  onChangeTime = ({ target: { value } }, day, propName) => {
    const {
      formState: { daysScheduleData },
      onChangeFormState,
    } = this.props;
    this.setState({ errorMessage: '' });
    onChangeFormState(
      'daysScheduleData',
      daysScheduleData.map((item) => (item.DayOfWeek === day ? { ...item, [propName]: value } : item)),
    );
  };

  changeGraphicDataByCheckbox = ({ target: { checked } }, day) => {
    const {
      formState: { daysScheduleData },
      onChangeFormState,
    } = this.props;
    this.setState({ errorMessage: '' });
    onChangeFormState(
      'daysScheduleData',
      daysScheduleData.map(({ DayOfWeek, TurnOff, TurnOn, DayOff }) => ({
        TurnOff: DayOfWeek === day ? additionalSettingsService.getDefaultDate() : TurnOff,
        TurnOn: DayOfWeek === day ? additionalSettingsService.getDefaultDate() : TurnOn,
        DayOff: DayOfWeek === day ? checked : DayOff,
        DayOfWeek,
      })),
    );
  };

  onWeekScheduleChange =
    (fieldName) =>
    ({ target: { value } }) => {
      const {
        formState: { weeksScheduleData },
        onChangeFormState,
      } = this.props;
      this.setState({ errorMessage: '' });
      onChangeFormState('weeksScheduleData', { ...weeksScheduleData, [fieldName]: value });
    };

  renderWeekSchedule = () => {
    const {
      formState: { weeksScheduleData },
    } = this.props;

    const { from, to } = weeksScheduleData;

    console.log('weeksScheduleData', weeksScheduleData);

    return (
      <Row className="NewSchedule-weekSchedule">
        <Col xs={24} md={10}>
          <div style={{ position: 'relative' }}>
            <TimePicker
              format="HH:mm"
              value={from}
              onChange={this.onWeekScheduleChange('from')}
              nowButton={false}
              cancelButton={false}
              valid={new Date(from) <= new Date(to)}
            />
          </div>
        </Col>
        <Col xs={24} md={4} className="NewSchedule-divider">
          <span>-</span>
        </Col>
        <Col xs={24} md={10}>
          <div style={{ position: 'relative' }}>
            <TimePicker
              format="HH:mm"
              value={to}
              onChange={this.onWeekScheduleChange('to')}
              nowButton={false}
              cancelButton={false}
              valid={new Date(to) >= new Date(from)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  getModelByGlobal = () => {
    const {
      formState: {
        weeksScheduleData: { from, to },
      },
      selectItems,
    } = this.props;
    const modelByGlobal = [];

    SHORT_STRING_BY_DAY_MAP.forEach((_, value) => {
      modelByGlobal.push({
        DayOfWeek: value,
        DayOff: false,
        TurnOn: getDataByTimezoneOffset(new Date(from)).toISOString(),
        TurnOff: getDataByTimezoneOffset(new Date(to)).toISOString(),
      });
    });

    return selectItems.map((terminalId) => ({
      terminalId,
      withoutSchedule: false,
      terminalSchedules: modelByGlobal,
    }));
  };

  getModelByLocal = () => {
    const {
      formState: { daysScheduleData },
      selectItems,
    } = this.props;

    return selectItems.map((terminalId) => ({
      terminalId,
      withoutSchedule: false,
      terminalSchedules: daysScheduleData.map(({ TurnOn, TurnOff, ...restScheduleProps }) => ({
        TurnOn: getDataByTimezoneOffset(new Date(TurnOn)).toISOString(),
        TurnOff: getDataByTimezoneOffset(new Date(TurnOff)).toISOString(),
        ...restScheduleProps,
      })),
    }));
  };

  verifySchedules = () => {
    const {
      formState: {
        weeksScheduleData: { from, to },
        scheduleSettings,
        daysScheduleData,
      },
    } = this.props;

    if (scheduleSettings === 'global') {
      return NewScheduleService.verifyWeekSchedule(from, to);
    }

    return NewScheduleService.verifyDaysSchedule(daysScheduleData);
  };

  terminalsScheduleSet = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        formState: { scheduleSettings },
      } = this.props;

      const errorMessage = this.verifySchedules();
      this.setState({ errorMessage });

      if (!errorMessage) {
        const model = scheduleSettings === 'global' ? this.getModelByGlobal() : this.getModelByLocal();

        await api.terminalsScheduleSet(model);
        window.location.reload();
      }
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      formState: { scheduleSettings, daysScheduleData },
      translate,
    } = this.props;
    const { isLoading, errorMessage } = this.state;

    return (
      <>
        <div className="NewSchedule">
          <Radio.Group value={scheduleSettings} onChange={this.onChangeRadioValue} className="NewSchedule-radio">
            <Radio value={'local'} className="NewSchedule-radioItem">
              {translate('page.newTerminals.daysSchedule')}
            </Radio>
            <Radio value={'global'} className="NewSchedule-radioItem">
              {translate('page.newTerminals.wholeWeekSchedule')}
            </Radio>
          </Radio.Group>

          {daysScheduleData.length && scheduleSettings === 'local' ? (
            <GraphicWorkingHours
              data={daysScheduleData}
              changeGraphicDataByTime={this.onChangeTime}
              changeGraphicDataByCheckbox={this.changeGraphicDataByCheckbox}
            />
          ) : (
            this.renderWeekSchedule()
          )}

          {errorMessage && <div className="NewSchedule-error">{translate(errorMessage)}</div>}

          <Button
            type="primary"
            className="NewSchedule-action"
            onClick={this.terminalsScheduleSet}
            disabled={!!errorMessage}
          >
            {translate('core.buttonTitles.save')}
          </Button>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(NewSchedule);
