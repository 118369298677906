import React, { PureComponent } from 'react';
import ServiceLimitsGrid from './ServiceLimitsGrid/ServiceLimitsGrid';
import PageTabs from '../../components/tabber/tabber';

class ServiceLimits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.limitsService.tabTitle',
          content: <ServiceLimitsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} />;
  }
}

export default ServiceLimits;
