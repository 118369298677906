import React, { PureComponent } from 'react';
import { Button, Modal, Input, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';

import './UpdateMenuElementModal.styled.scss';

const api = new ApiService();

class UpdateMenuElement extends PureComponent {
  state = {
    isLoading: false,
    inputValue: '',
    currentMenuName: '',
  };

  componentDidMount() {
    const { profileStaffForUpdating } = this.props;

    if (!profileStaffForUpdating) {
      return;
    }

    const { variant, ...restProfileStaffForUpdating } = profileStaffForUpdating;

    if (variant === 'changeName') {
      this.setState({ inputValue: restProfileStaffForUpdating.currentMenuName });
      return;
    }

    if (variant === 'cloneElement') {
      this.setState({
        inputValue: restProfileStaffForUpdating.currentMenuName,
        currentMenuName: restProfileStaffForUpdating.currentMenuName,
      });
    }
  }

  getStuffByVariant = () => {
    const {
      profileStaffForUpdating: { variant, ...restProfileStaffForUpdating },
      translate,
    } = this.props;
    const { inputValue } = this.state;

    if (variant === 'changeName') {
      return {
        title: translate('page.profilesMenu.modal-title-change'),
        buttonTitle: 'core.buttonTitles.change',
        method: api.menuUpdateName,
        successfulMessage: 'page.profilesMenu.elementNameChangedSuccessfully',
        inputLabel: 'page.profilesMenu.newElementName',
        methodModel: {
          profileId: restProfileStaffForUpdating.profileId,
          name: inputValue,
          menuId: restProfileStaffForUpdating.menuId,
          refId: restProfileStaffForUpdating.refId,
        },
      };
    }

    return {
      title: `${translate('page.profilesMenu.clone-element')} ${restProfileStaffForUpdating.currentMenuName}`,
      buttonTitle: 'page.profilesMenu.clone',
      method: api.menuClone,
      successfulMessage: 'page.profilesMenu.elementClonedSuccessfully',
      inputLabel: 'page.profilesMenu.elementName',
      methodModel: {
        profileId: restProfileStaffForUpdating.profileId,
        name: inputValue,
        cloneId: restProfileStaffForUpdating.menuId,
        parentId: restProfileStaffForUpdating.parentId,
      },
    };
  };

  renderModalContent = () => {
    const { inputValue } = this.state;
    const { profileStaffForUpdating, translate } = this.props;
    const { inputLabel } = this.getStuffByVariant();

    if (!profileStaffForUpdating) {
      return <></>;
    }

    return (
      <>
        <div className="UpdateMenuElementModal-inputLabel">{translate(inputLabel)}</div>
        <Input
          value={inputValue}
          onChange={({ target: { value: inputValue } }) => this.setState({ inputValue })}
          className="MenuProfilesModal-input"
        />
      </>
    );
  };

  handleActionSubmit = async () => {
    const { inputValue } = this.state;
    const {
      profileStaffForUpdating: { onSuccessfulUpdateCallback, variant },
      closeDialog,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const { method, methodModel, successfulMessage } = this.getStuffByVariant();
      const methodResponse = await method(methodModel);
      onSuccessfulUpdateCallback(
        variant === 'cloneElement' ? { ...methodResponse, parentId: methodModel.parentId } : inputValue,
      );
      message.success(translate(successfulMessage), 2.5);
      closeDialog();
      this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const {
      closeDialog,
      translate,
      visible,
      profileStaffForUpdating: { variant },
    } = this.props;

    const { isLoading, inputValue, currentMenuName } = this.state;
    const { title, buttonTitle } = this.getStuffByVariant();
    const isDisabledByClone = variant === 'cloneElement' && currentMenuName === inputValue;

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            disabled={!inputValue || isDisabledByClone}
            onClick={this.handleActionSubmit}
          >
            {translate(buttonTitle)}
          </Button>,
          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderModalContent()}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateMenuElement);
