import React, { PureComponent } from 'react';
import { Alert, Button, message, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { ALL_GRID_COLUMNS } from './constants';

const api = new ApiService();

class DeleteItemModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteCallbacks = async () => {
    const { selectedItems, onRefresh, closeModal, translate } = this.props;
    const query = `merchantCallbackItemEntityDelete(input:{itemEntity:{ id: ${selectedItems[0].id},  name: "${selectedItems[0].name}"}}){merchantCallbackItem{ id name }}`;

    try {
      await this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.manageMerchantCallbacks.successfullyDeleted'), 2.5);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;

      if (
        e &&
        e.data &&
        e.data.errors &&
        e.data.errors[0] &&
        e.data.errors[0].extensions &&
        e.data.errors[0].extensions.message === 'FK_VIOLATION_ERROR: Item has relation'
      ) {
        showError({
          data: {
            Message: translate('page.manageMerchantCallbacks.FK_VIOLATION_ERROR'),
          },
        });
      } else {
        showError(e);
      }
      closeModal();
      this.setState({ isLoading: false });
    }

    await this.setState({ isLoading: false });
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteCallbacks}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button key="back" onClick={closeModal} style={{ margin: variant === 'forbiddenDelete' ? '0 auto' : 'auto' }}>
            {translate(variant === 'forbiddenDelete' ? 'core.buttonTitles.ok' : 'core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>{translate('page.manageMerchantCallbacks.areYouSure')}</div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteItemModal);
