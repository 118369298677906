class AddCodeModalService {
  normalizeNBUCodes(nbuCodes, translate) {
    if (!nbuCodes.length) {
      return [];
    }

    return nbuCodes.map(({ F108Code, Description, IsActive }) => ({
      Id: F108Code,
      Name: Description,
      Active: IsActive ? translate('page.paymentTypeNBUCode.active') : translate('page.paymentTypeNBUCode.notActive'),
    }));
  }
}

export default new AddCodeModalService();
