import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import dayjs from 'dayjs';
import { Button } from '@progress/kendo-react-buttons';

import DateRange from '../../../components/daterange';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';

import { AGGREGATE } from './common.const';
import { getCustomDate } from '../../../utils/getCustomDate';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';

import './GridReportTerminalsMaintenance.styled.scss';

const api = new ApiService();

const NINETY_DAYS_VALUE = 90;
const NINETY_DAYS_TO_MILLISECONDS = NINETY_DAYS_VALUE * 24 * 60 * 60 * 1000;

class GridReportTerminalsMaintenance extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('terminalMaintenance');
    this.currentDate = new Date();
  }

  state = {
    data: [],
    isLoading: false,
    owners: [],
    allOwners: [],
    range: undefined,
    isError: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const { range, owners } = this.state;

    if (!range || owners.length < 1) return;

    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        DateStart: getDataByTimezoneOffset(range.start).toISOString(),
        DateEnd: getDataByTimezoneOffset(range.end).toISOString(),
      };
      const data = await api.terminalsMaintenance(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.makeQuery);
  };

  checkError = (range) => {
    const { start, end } = range;
    const todaysStartTime = getCustomDate();
    const todaysEndTime = getCustomDate({ customTime: { h: 23, m: 59, s: 59, ms: 999 } });
    const isMinStartDateExceeded = dayjs(todaysStartTime).diff(start, 'day', true) > NINETY_DAYS_VALUE;
    const isMaxStartDateExceeded = dayjs(todaysEndTime).diff(start, 'day', true) < 0;
    const isStartDateBiggestEndDate = start - end > 0;
    const isMaxEndDateExceeded = dayjs(todaysEndTime).diff(end, 'day', true) < 0;

    this.setState({
      isError: isMinStartDateExceeded || isMaxStartDateExceeded || isMaxEndDateExceeded || isStartDateBiggestEndDate,
    });
  };

  onRange = (range) => {
    this.checkError(range);

    this.setState({ range });
  };
  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { isError, allOwners, owners } = this.state;

    const minData = new Date(new Date() - NINETY_DAYS_TO_MILLISECONDS);

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridReportTerminalsMaintenance-toolbar">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <GridDropdownWithDeletedOwners
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              selectItems={owners}
              onSave={this.onOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              isGetDefaultSelectItemsFromStorage
            />
            <DateRange
              onRange={this.onRange}
              minStartDate={minData}
              maxStartDate={this.currentDate}
              minEndDate={minData}
              maxEndDate={this.currentDate}
            />
          </Col>
          {checkElement('terminalMaintenance-btn-search', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery} disabled={isError}>
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render() {
    const { isLoading, data } = this.state;

    return (
      <Grid
        data={data}
        aggregate={AGGREGATE}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="terminalMaintenance-grid"
        permissionName="terminalMaintenance"
      >
        <GridColumn
          field="TerminalId"
          title="grids.terminalMaintenance.column-terminalId"
          width="145"
          filter="numeric"
        />

        <GridColumn field="TerminalName" title="grids.terminalMaintenance.column-terminalName" width="145" />

        <GridColumn field="Region" title="grids.terminalMaintenance.column-region" width="145" />

        <GridColumn field="City" title="grids.terminalMaintenance.column-city" width="145" />

        <GridColumn field="Address" title="grids.terminalMaintenance.column-address" width="145" />

        <GridColumn field="Technician" title="grids.terminalMaintenance.column-technician" width="145" />

        <GridColumn
          field="MaintenanceDate"
          title="grids.terminalMaintenance.column-maintenanceDate"
          width="150"
          cell={({ dataItem: { MaintenanceDate } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(MaintenanceDate)}</td>
          )}
          formatFilterCellData={(MaintenanceDate) => getTransformDateString(MaintenanceDate)}
        />
      </Grid>
    );
  }
}

export default withTranslate(GridReportTerminalsMaintenance);
