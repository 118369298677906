import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';

import './SuspendedFinancialTransactionsGrid.scss';
import { ApiService } from '../../../services';

const api = new ApiService();

class RefuseDialog extends PureComponent {
  state = {
    isLoading: false,
  };

  onSubmit = async () => {
    const { translate, onClose, selectedItems, refetch } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.getPaymentsExternalDelete({ paymentId: selectedItems[0].PaymentId });
      await this.setState({ isLoading: false });
      await onClose();
      message.success(
        `${translate(`page.suspendedFinancialTransactions.payment`)} ${selectedItems[0].PaymentId} ${translate(
          `page.suspendedFinancialTransactions.statusDeniedSuccessfully`,
        )}`,
        2,
      );
      refetch();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, visible, onClose, selectedItems } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.suspendedFinancialTransactions.deleteOperation')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.onSubmit} loading={isLoading}>
            {translate('core.buttonTitles.yes')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.no')}
          </Button>,
        ]}
      >
        <div className="SuspendedFinancialTransactionsModal-caption">
          {translate('page.suspendedFinancialTransactions.doYouWantBlock')} {selectedItems[0].PaymentId}?
        </div>
      </Modal>
    );
  }
}

export default withTranslate(RefuseDialog);
