import React, { PureComponent } from 'react';
import { Button, message, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { testData } from './constants';
import { ApiService, tabService } from '../../../services';
import AddCodeModal from './AddCodeModal';
import ChangeCodeModal from './ChangeCodeModal';

import './PaymentTypeNBUCodeGrid.scss';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class PaymentTypeNBUCodeGrid extends PureComponent {
  currentArray = findArray('NBUServicesRelations');

  state = {
    services: [],
    isLoading: false,
    selectedItems: [],
    dialogVisible: '',
  };

  componentDidMount() {
    this.getServices();
  }

  getServices = async () => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.getNBUPaymentTypeServiceRelations();
      await this.setState({ services: services && services.length ? this.normalizeServices(services) : [] });
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  normalizeServices = (services) => {
    if (!services || !services.length) {
      return [];
    }

    return services.map((service) => ({
      ...service,
      Id: `${service.ServiceId}-${service.PaymentType}`,
      DatePost: getDataByTimezoneOffset(service.DatePost),
    }));
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handleDeleteServices = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.deleteNBUPaymentTypeServiceRelations([
        ...selectedItems.map(({ ServiceId, PaymentType, F108Code }) => ({ ServiceId, PaymentType, F108Code })),
      ]);
      this.getServices();
      message.success(translate('page.paymentTypeNBUCode.successfullyDelete'), 2);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  renderTooltip = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row>
        <div className="PaymentTypeNBUCodeGrid">
          {checkElement('NBUServicesRelations-add', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogVisible: 'addService' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('NBUServicesRelations-history', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab({
                  type: 'recordDeletionHistory',
                });
              }}
            >
              {translate('page.paymentTypeNBUCode.history')}
            </Button>
          )}
          {checkElement('NBUServicesRelations-delete', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ dialogVisible: 'deleteService' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
          {checkElement('NBUServicesRelations-change', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogVisible: 'changeService' });
              }}
              disabled={!selectedItems.length}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  render() {
    const { services, isLoading, dialogVisible, selectedItems } = this.state;
    const { translate } = this.props;

    if (!checkElement('NBUServicesRelations-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={services}
          onRefresh={this.getServices}
          toolbar={this.renderTooltip()}
          isLoading={isLoading}
          name="paymentTypeNBUCodeGrid"
          fieldForSelection="Id"
          onSelect={this.handleSelectionChange}
          initialSort={{ field: 'DatePost', dir: 'desc' }}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="ServiceId" title="page.paymentTypeNBUCode.col-serviceId" width="120" filter="numeric" />
          <GridColumn field="ServiceName" title="page.paymentTypeNBUCode.col-serviceName" width="300" />
          <GridColumn field="ServiceGroupName" title="page.paymentTypeNBUCode.col-serviceGroup" width="300" />
          <GridColumn field="PaymentTypeName" title="page.paymentTypeNBUCode.col-paymentType" width="240" />
          <GridColumn field="F108Code" title="page.paymentTypeNBUCode.col-NBUCode" width="120" />
          <GridColumn
            field="DatePost"
            title="page.paymentTypeNBUCode.col-addDate"
            width="180"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn field="UserName" title="page.paymentTypeNBUCode.col-performer" width="180" />
        </Grid>

        {dialogVisible === 'addService' && (
          <AddCodeModal
            visible={dialogVisible === 'addService'}
            onClose={() => {
              this.setState({ dialogVisible: '' });
            }}
            onRefresh={this.getServices}
            services={services}
          />
        )}

        {dialogVisible === 'deleteService' && (
          <DeleteModal
            visible={dialogVisible === 'deleteService'}
            closeModal={() => {
              this.setState({ dialogVisible: '' });
            }}
            submitCallback={this.handleDeleteServices}
            deleteContent={translate('page.paymentTypeNBUCode.areYouSure')}
          />
        )}

        {dialogVisible === 'changeService' && (
          <ChangeCodeModal
            visible={dialogVisible === 'changeService'}
            onClose={() => {
              this.setState({ dialogVisible: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.getServices}
          />
        )}
      </>
    );
  }
}

export default withTranslate(PaymentTypeNBUCodeGrid);
