const certificates = (translate, value) => {
  switch (value) {
    case 'certificates-newCertificate':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')}, 
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.certificates-issued.btn-new')}`;
    case 'terminalsCertificates-Address':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-address')}`;
    case 'terminalsCertificates-CertExpiryDate':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-expiry-date')}`;
    case 'terminalsCertificates-CertStartDate':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-start-date')}`;
    case 'terminalsCertificates-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-city-name')}`;
    case 'terminalsCertificates-DateLastConnect':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-date-last-connect')}`;
    case 'terminalsCertificates-DateLastTransaction':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-date-last-transaction')}`;
    case 'terminalsCertificates-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-date-post')}`;
    case 'terminalsCertificates-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-status-name')}`;
    case 'terminalsCertificates-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-terminalId')}`;
    case 'terminalsCertificates-terminalIdOnclick':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.certificates.tab-сertificates-issued')}`;
    case 'terminalsCertificates-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-terminal-name')}`;
    case 'terminalsCertificates-TypeName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates.certificate-type-name')}`;
    case 'terminalsCertificatesList-CertificateId':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-certificateId')}`;
    case 'terminalsCertificatesList-Code':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-code')}`;
    case 'terminalsCertificatesList-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-date-post')}`;
    case 'terminalsCertificatesList-ExpiryDate':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-expiry-date')}`;
    case 'terminalsCertificatesList-RequestId':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-requestId')}`;
    case 'terminalsCertificatesList-StartDate':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-date-start')}`;
    case 'terminalsCertificatesList-Status':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-status')}`;
    case 'terminalsCertificatesList-TerminalId':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-terminalId')}`;
    case 'terminalsCertificatesList-UserNamePost':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.certificates.tab-сertificates-issued')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.certificates-issued.col-user-name-post')}`;
    default:
      return value;
  }
};

export default certificates;
