import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';

import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { getCustomDate } from '../../utils/getCustomDate';
import { formatDate } from '@telerik/kendo-intl';

const api = new ApiService();

const minStart = getCustomDate({
  customDateFullYear: new Date().getFullYear() - 2,
  customDayOfMonth: new Date().getDate() - 1,
});

//ToDo повернути обмеження в один рік після тесту
const maxStart = getCustomDate({
  customDayOfMonth: new Date().getDate() - 1,
});
const dayBefore = maxStart;
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');

class MFOTransactionsGrid extends PureComponent {
  currentArray = findArray('mfoTransactions');

  state = {
    isLoading: false,
    transactionsList: [],
    searchType: undefined,
    searchQuery: '',
    errorMessage: '',
    start: dayBefore,
    dialogVariant: '',
    selectedItems: [],
  };

  onDateChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  onSelectItems = (selectedItem) => {
    this.setState({ selectedItems: selectedItem });
  };

  getDataTransactionsList = async () => {
    const { start } = this.state;
    await this.setState({ isLoading: true });

    try {
      const data = await api.finmonOwnerMfoPayments({ dateFrom: convertToISOFormat(start) });
      await this.setState({ transactionsList: data, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { errorMessage, start } = this.state;

    return (
      <Row>
        <Col>
          <Row type="flex" align="middle" justify="flex-start" className="CashCollectors-dateRow">
            <Col span={24} md={16} lg={8}>
              <Row type="flex" align="middle" justify="space-between">
                <Col span={4} md={6}>
                  <p>{translate('grids.comission-sub.validity-period-date-start')}</p>
                </Col>
                <Col span={20} md={18}>
                  <DatePicker
                    format="yyyy.MM"
                    min={minStart}
                    max={maxStart}   
                    value={start}
                    onChange={(e) => {
                      this.onDateChange(e, 'start');
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={4}>
              <Button type="primary" disabled={errorMessage} onClick={this.getDataTransactionsList}>
                {translate('page.blockedTransactions.createReport')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const { transactionsList, isLoading } = this.state;

    if (!checkElement('mfoTransactions-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={transactionsList}
          isLoading={isLoading}
          name="mfoTransactionsGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.getDataTransactionsList}
        >
          <GridColumn field="OwnerId" title="page.workDealers.ownerId" width="100px" />
          <GridColumn field="OwnerName" title="page.mfoTransactions.ownerName" width="230px" />
          <GridColumn field="EDRPO" title="page.mfoTransactions.edrpo" width="240px" />
          <GridColumn
            field="DateAccepted"
            title="page.mfoTransactions.dateAccepted"
            width="240px"
            cell={({ dataItem: { DateAccepted } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateAccepted)}</td>
            )}
            formatFilterCellData={(DateAccepted) => getTransformDateString(DateAccepted)}
          />
          <GridColumn field="TransactionId" title="grids.terminal-events.column-transactionId" width="180px" />
          <GridColumn field="AmountTotal" title="grids.wallets.column-amount" width="160px" />
          <GridColumn field="CardPan" title="page.mfoTransactions.cardPan" width="180px" />
          <GridColumn field="FinMonRecipient" title="page.mfoTransactions.finMonRecipient" width="180px" />
          <GridColumn field="RecipientId" title="page.mfoTransactions.recipientId" width="240px" />
          <GridColumn field="RecipientFullName" title="page.mfoTransactions.recipientFullName" width="160px" />
        </Grid>
      </>
    );
  }
}

export default withTranslate(MFOTransactionsGrid);
