import React, { PureComponent } from 'react';
import { Modal, Button, Row, Col, Input, message } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';

import './ModalFinMonLimits.scss';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import ValidatorService from './validators';

const api = new ApiService();
class ModalFinMonLimits extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedItems } = props;
    this.state = {
      inputValue: '',
      formError: null,
      currentService: selectedItems && selectedItems.length ? selectedItems.map(({ ServiceId }) => ServiceId) : [],
      isLoading: false,
    };
  }

  onHandleChange = (e) => {
    const { translate } = this.props;
    const { value } = e.target;
    const validateResult = ValidatorService.validateInput(translate)(value);
    this.setState({ formError: '' });
    if (validateResult === false) {
      return;
    }
    this.setState({ inputValue: value, formError: validateResult });
  };

  serviceFinMonLimits = async () => {
    const { currentService, inputValue } = this.state;
    const { modalName, closeModal, update, translate } = this.props;
    try {
      this.setState({ isLoading: true });
      const model = {
        serviceIds: currentService,
        amountMax: inputValue,
      };

      if (modalName === 'isAddModal') {
        await api.addServicesLimits(model);
        closeModal();
        await message.success(`${translate('page.finMonLimits.messageAddLimits')}`, 1.5);
        window.location.reload();
      } else if (modalName === 'isChangeModal') {
        await api.changeServicesLimits(model);
        closeModal();
        message.success(`${translate('page.finMonLimits.messageChangeLimits')}`, 2.5);
        update();
      }
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  removeFinMonLimits = async () => {
    const { currentService } = this.state;
    const { translate, closeModal, update } = this.props;
    try {
      this.setState({ isLoading: true });
      await api.removeServicesLimits(currentService);
      this.setState({ isLoading: false });
      closeModal();
      message.success(`${translate('page.finMonLimits.messageRemoveLimits')}`);
      update();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };

  getTitleModal = (nameModal, translate) => {
    switch (nameModal) {
      case 'isAddModal':
        return `${translate('page.finMonLimits.modalAddFinMonLimits')}`;
      case 'isChangeModal':
        return `${translate('page.finMonLimits.modalChangeFinMonLimits')}`;
      case 'isRemoveModal':
        return `${translate('page.finMonLimits.modalRemoveFinMonLimitsTitle')}`;
      default:
        return;
    }
  };

  renderModalContent = (modal, translate) => {
    const { inputValue, formError } = this.state;
    if (modal === 'isAddModal' || modal === 'isChangeModal') {
      return (
        <>
          <Row type="flex" align="middle">
            <Col span={10} md={10} xl={10}>
              {translate('page.finMonLimits.maxAmount')}
            </Col>
            <Col span={14} md={14} xl={14}>
              <Input
                value={inputValue}
                name="MaxSum"
                onChange={(e) => {
                  this.onHandleChange(e);
                }}
              />
              {formError && <div style={{ color: 'red' }}>{formError}</div>}
            </Col>
          </Row>
        </>
      );
    } else if (modal === 'isRemoveModal') {
      return (
        <Row className="ModalFinMonLimits-row">
          <Col>{translate('page.finMonLimits.modalRemoveFinMonLimits')}</Col>
        </Row>
      );
    }
  };

  renderButton = (modalName, translate) => {
    const { isLoading, formError } = this.state;
    console.log('err', formError);
    switch (modalName) {
      case 'isAddModal':
        return (
          <Button
            key="submit"
            type="primary"
            disabled={formError}
            loading={isLoading}
            onClick={this.serviceFinMonLimits}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        );
      case 'isChangeModal':
        return (
          <Button
            key="submit"
            type="primary"
            disabled={formError}
            loading={isLoading}
            onClick={this.serviceFinMonLimits}
          >
            {translate('core.buttonTitles.change')}
          </Button>
        );
      case 'isRemoveModal':
        return (
          <Button key="submit" type="primary" loading={isLoading} onClick={this.removeFinMonLimits}>
            {translate('core.buttonTitles.delete')}
          </Button>
        );
    }
  };

  render = () => {
    const { visible, closeModal, modalName, translate } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Modal
          title={this.getTitleModal(modalName, translate)}
          visible={visible}
          onCancel={closeModal}
          footer={[
            this.renderButton(modalName, translate),
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
          ]}
        >
          {this.renderModalContent(modalName, translate)}
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  };
}
export default withTranslate(ModalFinMonLimits);
