import React, { PureComponent } from 'react';

import NonRiskLimitsGrid from './NonRiskLimitsGrid';
import NonRiskServicesGrid from './NonRiskServicesGrid';
import PageTabs from '../../components/tabber/tabber';

class NonRiskLimits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-nonRiskLimits',
          content: <NonRiskLimitsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = (type, key) => {
    const { tabs } = this.state;

    switch (type) {
      case 'nonRiskServices':
        tabs.push({
          title: 'page.nonRiskLimitsGrid.tab-nonRiskServices',
          content: <NonRiskServicesGrid />,
          closable: true,
          key: key,
        });
        break;

      default:
        console.warn('Unknown tab type');
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default NonRiskLimits;
