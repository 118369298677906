import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import SubBrandServices from './SubBrand.services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { BRANDS_COLUMN_CONFIG, MENU_PROFILE_COMMISSIONS_CONFIG, MENU_PROFILE_CONFIG } from './constants';
import LoadingPanel from '../../../components/loader';

import './SubBrandsTerminalsGrid.scss';

const api = new ApiService();

class ChangeBrandModal extends PureComponent {
  state = {
    isLoading: false,
    brands: [],
    selectBrands: [],
    menuProfiles: [],
    selectMenuProfiles: [],
    commissionProfiles: [],
    selectCommissionProfiles: [],
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    await this.getBrands();
    await this.getCommissionProfiles();
  };

  getBrands = async () => {
    this.setState({ isLoading: true });

    try {
      const data = await api.getTerminalsBrandList();

      await this.setState({ brands: data && data.length ? SubBrandServices.normalizeBrands(data) : [] });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getMenuProfiles = async (selectBrands) => {
    const { brands } = this.state;
    this.setState({ isLoading: true });

    try {
      const data = await api.getMenuProfiles();

      const selectBrandsMenuProfiles =
        brands && brands.length && selectBrands && selectBrands.length
          ? brands.filter(({ Id }) => selectBrands.includes(Id)).map(({ MenuProfielId }) => MenuProfielId)
          : [];

      const menuProfiles =
        data && data.length
          ? SubBrandServices.normalizeMenuProfiles(
              data,
              selectBrandsMenuProfiles,
              selectBrands.length && selectBrands.includes(0),
            )
          : [];

      this.setState({
        menuProfiles,
        selectMenuProfiles:
          menuProfiles && menuProfiles.length && selectBrands.length && !selectBrands.includes(0)
            ? [menuProfiles[0].Id]
            : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getCommissionProfiles = async () => {
    const { selectedOwners } = this.props;

    this.setState({ isLoading: true });

    try {
      const data = await api.getProfileDictionary({ ownerId: selectedOwners });

      await this.setState({
        commissionProfiles: data && data.length ? SubBrandServices.normalizeCommissionProfiles(data) : [],
      });

      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getOnDropdownSave = (fieldName) => (value) => {
    this.setState({ [fieldName]: value }, () => {
      if (fieldName === 'selectBrands') {
        const { selectBrands } = this.state;
        this.setState({ selectMenuProfiles: [] });
        this.getMenuProfiles(selectBrands);
      }
    });
  };

  changeTerminalsProps = async () => {
    const { onClose, onRefresh, selectedTerminals, translate } = this.props;
    const { selectBrands, selectMenuProfiles, selectCommissionProfiles } = this.state;

    try {
      this.setState({ isLoading: true });
      await api.terminalsBrandMerge(
        selectedTerminals.map(({ TerminalId }) => ({
          TerminalId,
          BrandId: selectBrands[0],
          MenuProfileId: selectMenuProfiles[0],
          CommissionProfileId:
            selectCommissionProfiles && selectCommissionProfiles.length ? selectCommissionProfiles[0] : null,
        })),
      );
      this.setState({ isLoading: false });
      onClose();
      message.success(`${translate('page.subBrandsTerminals.successfullyApplied')}`, 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const {
      isLoading,
      brands,
      selectBrands,
      menuProfiles,
      selectMenuProfiles,
      commissionProfiles,
      selectCommissionProfiles,
    } = this.state;

    console.log('daisabled', !selectBrands.includes(0));

    return (
      <Modal
        title={translate('page.subBrandsTerminals.changeBrand')}
        visible={visible}
        onCancel={onClose}
        className="ChangeBrandModal"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.changeTerminalsProps}
            loading={isLoading}
            disabled={!selectMenuProfiles.length || !selectBrands.length}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="ChangeBrandModal-content">
          <div className="ChangeBrandModal-actionWrapper">
            <div className="ChangeBrandModal-label">{translate('page.subBrandsTerminals.col-brand')}</div>
            <GridDropdown
              data={brands}
              colConfig={BRANDS_COLUMN_CONFIG}
              selectItems={selectBrands}
              onSave={this.getOnDropdownSave('selectBrands')}
              defaultTitle={translate('page.subBrandsTerminals.chooseBrand')}
              isSingle
            />
          </div>

          <div className="ChangeBrandModal-actionWrapper">
            <div className="ChangeBrandModal-label">{translate('page.subBrandsTerminals.col-profileMenu')}</div>
            <GridDropdown
              data={menuProfiles}
              colConfig={MENU_PROFILE_CONFIG}
              selectItems={selectMenuProfiles}
              onSave={this.getOnDropdownSave('selectMenuProfiles')}
              defaultTitle={translate('page.subBrandsTerminals.chooseProfileMenu')}
              disabled={selectBrands.length && !selectBrands.includes(0)}
              isSingle
            />
          </div>

          <div className="ChangeBrandModal-actionWrapper">
            <div className="ChangeBrandModal-label">{translate('page.subBrandsTerminals.col-commissionProfile')}</div>
            <GridDropdown
              data={commissionProfiles}
              colConfig={MENU_PROFILE_COMMISSIONS_CONFIG}
              selectItems={selectCommissionProfiles}
              onSave={this.getOnDropdownSave('selectCommissionProfiles')}
              defaultTitle={translate('page.subBrandsTerminals.chooseCommissionProfile')}
              isSingle
            />
          </div>
          {isLoading && <LoadingPanel />}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ChangeBrandModal);
