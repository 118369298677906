import React, { PureComponent } from 'react';
import { Alert, Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';

class ActionInfoModal extends PureComponent {
  handlePerformAction = () => {
    const {
      profileStaffForUpdating: { onPerformActionCallback },
      closeDialog,
    } = this.props;

    closeDialog();
    onPerformActionCallback();
  };

  render() {
    const {
      translate,
      closeDialog,
      visible,
      profileStaffForUpdating: { message, title, buttonTitle, areYouSureTitle },
    } = this.props;

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={closeDialog}
        footer={[
          <Button onClick={this.handlePerformAction} type="primary">
            {buttonTitle}
          </Button>,
          <Button onClick={closeDialog}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <Alert message={`${areYouSureTitle}${message ? ` ${message}` : ''}`} type="warning" showIcon />
      </Modal>
    );
  }
}

export default withTranslate(ActionInfoModal);
