const technicalPartners = (translate, value) => {
  switch (value) {
    case 'technicalPartners-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'technicalPartners-add':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;
    case 'technicalPartners-edit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.edit`)}`;
    case 'technicalPartners-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.delete`)}`;
    default:
      return value;
  }
};

export default technicalPartners;
