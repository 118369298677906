import React, { PureComponent } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button, Col, Row, Input } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn } from '@progress/kendo-react-grid';
import collectionHistoryGridService from './collectionHistoryGrid.service';

import './CollectionHistoryGrid.scss';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

class CollectionHistoryGrid extends PureComponent {
  state = {
    isLoading: false,
    collectionHistoryList: [],
    dateStart: null,
    dateEnd: null,
    errorMessage: '',
  };

  componentDidMount() {
    this.setState(
      {
        dateStart: this.formatDateByType('dateStart', new Date()),
        dateEnd: this.formatDateByType('dateEnd', new Date()),
      },
      () => {
        this.fetchCollectionHistoryList();
      },
    );
  }

  fetchCollectionHistoryList = async () => {
    const { showError, translate, terminalId } = this.props;
    const { dateStart, dateEnd } = this.state;

    await this.setState({ isLoading: true });

    const model = {
      terminalList: [terminalId],
      dateStart: formatDate(dateStart, 'yyyy.MM.ddTHH:mm:ss'),
      dateEnd: formatDate(dateEnd, 'yyyy.MM.ddTHH:mm:ss'),
    };

    const errorMessage = collectionHistoryGridService.getSubmitErrorsByDate(dateStart, dateEnd, translate);

    if (errorMessage) {
      this.setState({ errorMessage, isLoading: false });
      return;
    }

    try {
      const collectionHistoryList = await api.dispenserCollectionsByTerminalForPeriodList(model);

      this.setState({
        collectionHistoryList: collectionHistoryList && collectionHistoryList.length ? collectionHistoryList : [],
      });
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  formatDateByType = (fieldName, dateValue) => {
    const differenceUnit = fieldName === 'dateStart' ? 0 : 1;

    return new Date(
      new Date(
        new Date(dateValue).getFullYear(),
        new Date(dateValue).getMonth(),
        new Date(dateValue).getDate() + differenceUnit,
      ) - differenceUnit,
    );
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: collectionHistoryGridService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  renderToolbar = () => {
    const { translate, terminalId } = this.props;
    const { dateStart, dateEnd, errorMessage } = this.state;
    return (
      <Row className="CollectionHistoryGrid-toolbar">
        <Col span={24} sm={12} md={8} className="CollectionHistoryGrid-dateRow">
          <Col span={6}>{translate('page.changingProfileHistory.dateFrom')}</Col>

          <Col span={18}>
            <DatePicker
              format="yyyy.MM.dd"
              className="CollectionHistoryGrid-datePicker"
              onChange={this.getDateChangeCallback('dateStart')}
              value={dateStart}
              min={collectionHistoryGridService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>

        <Col span={24} sm={12} md={8} className="CollectionHistoryGrid-dateRow">
          <Col span={6}>{translate('page.changingProfileHistory.dateTo')}</Col>

          <Col span={18}>
            <DatePicker
              format="yyyy.MM.dd"
              className="CollectionHistoryGrid-datePicker"
              onChange={this.getDateChangeCallback('dateEnd')}
              value={dateEnd}
              min={collectionHistoryGridService.getMinimumSelectedDate()}
            />
          </Col>
        </Col>

        <Col span={24} md={8} className="CollectionHistoryGrid-ActionRow">
          <Button type="primary" onClick={this.fetchCollectionHistoryList} disabled={!!errorMessage}>
            {translate('page.changingProfileHistory.btn-view')}
          </Button>
        </Col>
        <Col span={24}>{errorMessage && <div className="CollectionHistoryGrid-error">{errorMessage}</div>}</Col>
        <Col span={18} md={12} className="CollectionHistoryGrid-inputRow">
          <Col span={8} md={6}>
            {translate('page.terminalSettings.header-field-title')}
          </Col>
          <Col span={16} md={18}>
            <Input value={terminalId} disabled />
          </Col>
        </Col>
      </Row>
    );
  };

  render() {
    const { collectionHistoryList, isLoading } = this.state;

    return (
      <Grid
        data={collectionHistoryList}
        toolbar={this.renderToolbar()}
        isLoading={isLoading}
        name="collectionHistoryGrid"
        onRefresh={this.fetchCollectionHistoryList}
      >
        <GridColumn
          field="DispenserCollectionId"
          title="grids.allEncashments.column-collectionId"
          width="170"
          filter="numeric"
        />

        <GridColumn
          field="WorkDate"
          title="grids.allEncashments.column-datePostNoUTC"
          width="170"
          filter="numeric"
          cell={({ dataItem: { WorkDate } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(WorkDate)}</td>
          )}
          formatFilterCellData={(WorkDate) => getTransformDateString(WorkDate)}
        />

        <GridColumn
          field="Available"
          title="page.dispenserCollection.col-readyForDelivery"
          width="180"
          filter="numeric"
        />

        <GridColumn field="Rejected" title="page.dispenserCollection.col-rejected" width="170" filter="numeric" />

        <GridColumn field="Issued" title="page.dispenserCollection.col-issued" width="130" filter="numeric" />

        <GridColumn field="Loaded" title="page.dispenserCollection.col-downloaded" width="180" filter="numeric" />

        <GridColumn field="UserName" title="page.dispenserCollection.col-collectorsName" width="180" />
      </Grid>
    );
  }
}

export default withTranslate(CollectionHistoryGrid);
