import React, { PureComponent } from 'react';
import { Button, message, Select } from 'antd';

import CustomInput from '../../../../components/Input/Input';
import AddServiceService from '../AddService/AddService.service';
import { withTranslate } from '../../../../contexts/localContext';
import { PROVIDER_TYPES_COLUMN_CONFIG } from './constants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';

import '../AddService/AddService.styled.scss';
import './UpdateServiceTypeModal.styled.scss';

const { Option } = Select;
const api = new ApiService();

class AddNewServiceType extends PureComponent {
  state = {
    isLoading: false,
    error: '',
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    const { onChangeFormState } = this.props;

    onChangeFormState(fieldName, selectItems);
  };

  componentDidMount() {
    const { changeAddAction } = this.props;

    changeAddAction(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { formState, changeAddAction, cancelActionSlug, handleCancel, addActionSlug } = this.props;
    const {
      formState: prevFormState,
      cancelActionSlug: prevCancelActionSlug,
      addActionSlug: prevAddActionSlug,
    } = prevProps;

    if (formState !== prevFormState) {
      const isEmptyValue = Object.entries(formState).some(
        ([key, value]) => key !== 'serviceTypeDescription' && (key === 'provider' ? !value.length : !value),
      );

      changeAddAction(isEmptyValue);
      this.setState({ error: '' });
    }

    if (prevCancelActionSlug !== cancelActionSlug) {
      handleCancel();
    }

    if (addActionSlug !== prevAddActionSlug) {
      this.handleAdd();
    }
  }

  handleAdd = async () => {
    const {
      serviceTypeList,
      changeAddAction,
      showError,
      translate,
      handleCancel,
      fetchServiceTypes,
      formState: { serviceTypeName, serviceGroup, provider, serviceTypeDescription },
    } = this.props;

    await this.setState({ isLoading: true });
    const isDuplicate = serviceTypeList.includes(serviceTypeName);

    if (isDuplicate) {
      changeAddAction(true);
      this.setState({ errors: 'page.addServiceByBigTable.serviceTypeNameAlreadyExists', isLoading: false });
      return;
    }

    try {
      await api.addServicesType({
        typeId: 0,
        name: serviceTypeName,
        groupId: serviceGroup,
        providerId: provider[0],
        description: serviceTypeDescription,
      });
      this.setState({ isLoading: false });
      fetchServiceTypes();
      handleCancel();
      message.success(`${translate('page.addServiceByBigTable.serviceTypeAddedSuccessfully')}`, 2.5);
    } catch (e) {
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      translate,
      onChangeFormState,
      formState,
      formErrors,
      onError,
      serviceGroups,
      providers,
      dialogVariantChange,
    } = this.props;

    const { isLoading, errors } = this.state;

    return (
      <>
        <div>
          <div className="AddServiceByBigTable-inputRow AddServiceTypeByBigTableModal-inputRow">
            <div className="AddServiceByBigTable-label">{translate('page.serviceMainProps.nameService')}</div>
            <div className="AddServiceByBigTable-input">
              <CustomInput
                formState={formState}
                onChangeFormState={onChangeFormState}
                formErrors={formErrors}
                setError={onError}
                validators={AddServiceService.getValidators(translate)}
                name="serviceTypeName"
              />
            </div>
          </div>

          <div className="AddServiceByBigTable-rowWithAction">
            <div className="AddServiceByBigTable-inputRow AddServiceTypeByBigTableModal-inputRow">
              <div className="AddServiceByBigTable-label">{translate('page.serviceTemplates.col-serviceGroup')}</div>
              <div className="AddServiceByBigTable-input">
                <Select
                  style={{ width: '100%' }}
                  onChange={this.getSelectChangeCallback('serviceGroup')}
                  value={formState.serviceGroup}
                  placeholder={translate('page.serviceTemplates.selectServiceGroup')}
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  optionFilterProp="children"
                  showSearch
                >
                  {serviceGroups.map(({ Id, Name }) => (
                    <Option value={Id} key={Id}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="AddServiceByBigTable-action">
              <Button
                type="primary"
                onClick={() => {
                  dialogVariantChange('newGroup');
                }}
              >
                ...
              </Button>
            </div>
          </div>

          <div className="AddServiceByBigTable-rowWithAction">
            <div className="AddServiceByBigTable-inputRow AddServiceTypeByBigTableModal-inputRow">
              <div className="AddServiceByBigTable-label">{translate('page.addServiceByBigTable.provider')}</div>
              <div className="AddServiceByBigTable-input">
                <GridDropdown
                  data={providers}
                  colConfig={PROVIDER_TYPES_COLUMN_CONFIG}
                  selectItems={formState.provider}
                  onSave={this.getSelectChangeCallback('provider')}
                  defaultTitle={translate('page.addServiceByBigTable.selectProvider')}
                  isSingle
                />
              </div>
            </div>

            <div className="AddServiceByBigTable-action">
              <Button
                type="primary"
                onClick={() => {
                  dialogVariantChange('newProvider');
                }}
              >
                ...
              </Button>
            </div>
          </div>

          <div className="AddServiceByBigTable-inputRow AddServiceTypeByBigTableModal-inputRow">
            <div className="AddServiceByBigTable-label">{translate('page.addServiceByBigTable.description')}</div>
            <div className="AddServiceByBigTable-input">
              <CustomInput
                type="textArea"
                formState={formState}
                onChangeFormState={onChangeFormState}
                formErrors={formErrors}
                setError={onError}
                validators={AddServiceService.getValidators(translate)}
                name="serviceTypeDescription"
              />
            </div>
          </div>

          {errors && <div className="AddServiceTypeByBigTableModal-error">{translate(errors)}</div>}
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(AddNewServiceType);
