import React from 'react';

class Normalizer {
  normalizeData(obj = {}, data) {
    return [obj, ...data];
  }

  normalizedServicesWithParams(obj = {}, services) {
    if (!(services && services.length)) return [];

    const servicesWithAllServices = [obj, ...services];

    return servicesWithAllServices.reduce((acc, service) => {
      if (service.StatusId !== 4) {
        return [...acc, service];
      }
      return acc;
    }, []);
  }
}

export default new Normalizer();
