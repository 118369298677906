// Бек зберігає профілі з елементами у вигляді зв'язного списку де кожен профіль має лінку на свій батьківський профіль(по суті це
// лінійне представлення дерева). На елементах профілів існують властивості і їх значення. Якщо з'ясовуєтся що властивості не існує в
// поточному профілі, але вона існує в одному з батьківських профілів - вважаєтся що її значення для поточного профілю успадковуются від батьківського
// профіля в якому воно є. Це є умовною угодою Властивість яка справді існує в бд - вважается перевизначеною, властивість якої не існує в бд - але за
// умовною угодою її значення успадковуется від батьківського профілю вважается не визначеною. Оскільки не визначених валстивостей не має в бд, але користувачу
// іх відображати протрібно - фрон вираховує їх згідно умовної угоди.

import React, { PureComponent } from 'react';
import { Alert, Button, Input, message, Row, Select } from 'antd';
import { Button as ModalButton } from '@progress/kendo-react-buttons';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import MenuProfileDialog from './MenuProfileDialog';
import MenuElementDialog from './MenuElementDialog';
import { UpdateMenuProfileContext } from '../../components/MenuProfiles';
import { UpdateMenuElementsContext } from '../../components/ProfileMenuElements';
import NavigationMenuPropsService from './NavigationMenuProps.service';
import { ApiService, StorageService } from '../../services';
import { ALL_GRID_COLUMNS } from './constants';
import ChangePropValueModal from './ChangePropValueModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';

import './NavigationMenuPropGrid.styled.scss';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import GqlService from '../../components/grids/gql.service';

const api = new ApiService();
const { Option } = Select;
const { TextArea } = Input;

class NavigationMenuPropGrid extends PureComponent {
  currentArray = findArray('navigationMenuProp');

  state = {
    isLoading: false,
    props: [],
    propVariants: [],
    selectedProfiles: [],
    selectedElements: [],
    selectedPropVariant: undefined,
    modalVariant: '',
    propValue: '',
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    selectIdsString: undefined,
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchElementProps();
  }

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    this.setState({
      selectIdsString: selectIdsString
        ? selectIdsString
            .split(',')
            .map((item) => (item === `\"\"` ? item : `"${item.trim()}"`))
            .join()
        : undefined,
    });

    this.fetchResultMenuPropsGQL({
      selectIdsString: selectIdsString
        ? selectIdsString
            .split(',')
            .map((item) => (item === `\"\"` ? item : `"${item.trim()}"`))
            .join()
        : undefined,
    });
  };

  fetchElementProps = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo || !userInfo.OwnerId) {
      return [];
    }

    try {
      await this.setState({ isLoading: true });
      const propVariants = await api.getMenuPropertiesKeys(userInfo.OwnerId, false);
      await this.setState({ isLoading: false, propVariants });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getArgumentString = (isDefined, props) => {
    const { filtersString, sortString, selectedProfiles, selectedElements, selectedPropVariant, propValue } =
      this.state;

    // isDefined - флаг шо каже шукати всі перевизначені властивості, тож ми робимо пошук по будь-якому значенню
    // властивості по вибранним елементам і (профілям і батьківських профілях)
    const currentFilterString =
      !props || !props.selectIdsString
        ? filtersString
        : `{and:[${filtersString},{serviceId:{in:[${props.selectIdsString}]}}]}`;

    const storageColumns = StorageService.getItem('navigationMenuPropGrid');

    const currentColumns =
      (props && props.currentFieldList) ||
      (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns)] : ALL_GRID_COLUMNS);

    const baseProfileList = selectedProfiles
      .map(({ parentHistorySlug, profileId }) => `${parentHistorySlug},${profileId}`)
      .reduce((acc, item) => `${acc},${item}`, ``)
      .split(',')
      .filter(Boolean)
      .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);

    // Не має сенсу шукати по 1 профілю для isDefined оскільки там всі значення первизначені
    const profileList = isDefined ? baseProfileList.filter((char) => char !== '1') : baseProfileList;

    const value =
      !isDefined && propValue && propValue.trim() !== '%'
        ? propValue.includes('%')
          ? `{value:{or:[${propValue
              .split('%')
              .filter(Boolean)
              .reduce((acc, item) => `${acc}{contains:"${item}"},`, '')}]}},`
          : `{value:{eq:"${propValue}"}},`
        : '';

    const argumentsString = `take:500, skip:0,order:${
      sortString || '[{ profileId: ASC }, { menuId: ASC }]'
    },where:{and:[{profileId:{in:[${profileList.join()}]}},{menuId:{in:[${selectedElements
      .map(({ menuId }) => menuId)
      .join()}]}},{name:{in:["${selectedPropVariant}"]}},${value}${currentFilterString}]}`;

    return { argumentsString, currentColumns };
  };

  fetchMenuPropsGQL = async (skip, currentColumns, argumentsString, propList) => {
    const query = `menuExtendedProperties(${argumentsString}){items{${currentColumns.join(
      ',',
    )}} pageInfo{hasNextPage, hasPreviousPage}}`;

    try {
      const data = await api.getByGraphQl(query);

      const { props, hasNextPage } = NavigationMenuPropsService.getGQLResponse(data);

      return hasNextPage
        ? this.fetchMenuPropsGQL(skip + 500, currentColumns, argumentsString, [...propList, ...props])
        : [...propList, ...props];
    } catch (e) {
      throw e;
    }
  };

  fetchResultMenuPropsGQL = async (props) => {
    const { selectedProfiles, selectedElements, selectedPropVariant } = this.state;

    if (!selectedProfiles.length || !selectedElements.length || !selectedPropVariant) {
      return;
    }

    this.setState({ isLoading: true });

    const { argumentsString, currentColumns } = this.getArgumentString(false, props);
    const { argumentsString: definedArgumentString } = this.getArgumentString(true, props);

    try {
      const props = await this.fetchMenuPropsGQL(0, currentColumns, argumentsString, []);
      const allDefinedProps = await this.fetchMenuPropsGQL(0, currentColumns, definedArgumentString, []);

      this.setState({
        props: NavigationMenuPropsService.calcPropBySelectedElements(
          props,
          selectedElements,
          selectedProfiles,
          allDefinedProps,
        ),
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const {
      selectedProfiles,
      selectedElements,
      selectedPropVariant,
      propVariants,
      propValue,
      selectIdsString,
      selectedItems,
    } = this.state;
    const { translate } = this.props;

    return (
      <Row>
        <div className="NavigationMenuPropGrid-search">
          <div>
            <div className="NavigationMenuPropGrid-row">
              <div className="NavigationMenuPropGrid-row NavigationMenuPropGrid-fieldRow">
                <div className="NavigationMenuPropGrid-label">{translate('page.navigationMenuProp.profile')}</div>

                <ModalButton
                  className="NavigationMenuPropGrid-modalButton"
                  onClick={() => {
                    this.setState({ modalVariant: 'selectProfiles' });
                  }}
                >
                  {selectedProfiles.length
                    ? selectedProfiles.map(({ name }) => name).join(', ')
                    : translate('page.navigationMenuProp.selectProfiles')}
                </ModalButton>
              </div>

              <div className="NavigationMenuPropGrid-row NavigationMenuPropGrid-fieldRow">
                <div className="NavigationMenuPropGrid-label">{translate('page.navigationMenuProp.element')}</div>

                <ModalButton
                  className="NavigationMenuPropGrid-modalButton"
                  onClick={() => {
                    this.setState({ modalVariant: 'selectElements' });
                  }}
                >
                  {selectedElements.length
                    ? selectedElements.map(({ name, profileId }) => `${profileId}-${name}`).join(', ')
                    : translate('page.navigationMenuProp.selectElements')}
                </ModalButton>
              </div>
            </div>
            <div className="NavigationMenuPropGrid-row NavigationMenuPropGrid-textAreaRow">
              <div className="NavigationMenuPropGrid-row NavigationMenuPropGrid-fieldRow">
                <div className="NavigationMenuPropGrid-label">{translate('page.navigationMenuProp.prop')}</div>

                <Select
                  value={selectedPropVariant}
                  className="NavigationMenuPropGrid-propValueSelect"
                  placeholder={translate('page.navigationMenuProp.selectPropName')}
                  onChange={(selectedPropVariant) => {
                    this.setState({ selectedPropVariant, props: [] });
                  }}
                  filterOption={(input, { props: { children } }) =>
                    children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={!propVariants.length}
                  showSearch
                >
                  {propVariants.map(({ Key }) => (
                    <Option value={Key} key={Key}>
                      {Key}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="NavigationMenuPropGrid-row NavigationMenuPropGrid-fieldRow">
                <div className="NavigationMenuPropGrid-label">{translate('page.navigationMenuProp.value')}</div>

                <TextArea
                  className="NavigationMenuPropGrid-textArea"
                  value={propValue}
                  rows={3}
                  onChange={(e) => {
                    this.setState({ propValue: e.target.value, props: [] });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="NavigationMenuPropGrid-searchAction">
            <Button
              type="primary"
              onClick={() => {
                this.fetchResultMenuPropsGQL({ selectIdsString });
              }}
              disabled={!selectedProfiles.length || !selectedElements.length || !selectedPropVariant}
            >
              {translate('core.buttonTitles.search')}
            </Button>
          </div>
        </div>
        <div>
          {checkElement('navigationMenuProp-change', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'change' });
              }}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}

          {checkElement('navigationMenuProp-delete', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </div>

        {/*{hasNextPage && (*/}
        {/*  <div className="NavigationMenuPropGrid-alert">*/}
        {/*    <Alert message={translate('page.navigationMenuProp.alert')} type="warning" showIcon />*/}
        {/*  </div>*/}
        {/*)}*/}
      </Row>
    );
  };

  deleteProp = async () => {
    const { translate } = this.props;
    const { selectedItems, selectIdsString } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.menuPropertiesDelete(
        selectedItems.map(({ language, name, value, profileId, menuId }) => ({
          language,
          name,
          value,
          profileId,
          menuId,
        })),
      );
      await this.setState({ isLoading: false });
      message.success(`${translate('page.profilesMenu.propertyDeletedSuccessfully')}`, 2);
      this.fetchResultMenuPropsGQL({ selectIdsString });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  rowRender = (element, { dataItem }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      {
        ...elementStyle,
        className: dataItem.isDefinition ? 'NavigationMenuPropGrid-definitedPropRow' : '',
      },
      children,
    );
  };

  render() {
    const { props, isLoading, modalVariant, selectedProfiles, selectedElements, selectIdsString, selectedItems } =
      this.state;
    const { translate } = this.props;

    if (!checkElement('navigationMenuProp-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={props}
          onRefresh={() => {
            this.fetchResultMenuPropsGQL({ selectIdsString });
          }}
          isLoading={isLoading}
          name="navigationMenuPropGrid"
          toolbar={this.buildToolbar()}
          fieldForSelection="id"
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          rowRender={this.rowRender}
          isShowSpecificFilterByFieldName="serviceId"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="profileId"
            title={translate('page.profilesMenu.profileId')}
            width="120px"
            filter="numeric"
          />
          <GridColumn field="profileName" title={translate('page.profilesMenu.profileName')} width="240px" />
          <GridColumn
            field="parentProfileId"
            title={translate('page.navigationMenuProp.parentProfileId')}
            width="200px"
            filter="numeric"
          />
          <GridColumn field="legacyProfileId" title="page.profilesMenu.legacyValueProfile" width="140px" />
          <GridColumn
            field="profileLevel"
            title={translate('page.navigationMenuProp.level')}
            width="120px"
            filter="numeric"
          />
          <GridColumn field="menuId" title={translate('page.profilesMenu.elementId')} width="120px" filter="numeric" />
          <GridColumn field="menuName" title={translate('page.profilesMenu.elementName')} width="180px" />
          <GridColumn
            field="serviceId"
            title={translate('page.navigationMenuProp.serviceId')}
            width="120px"
            filter="numeric"
          />
          <GridColumn field="refId" title={translate('page.navigationMenuProp.link')} width="160px" filter="numeric" />
          <GridColumn field="name" width="240px" title="page.profilesMenu.propName" />
          <GridColumn field="value" width="1000px" title="page.profilesMenu.propValue" />
          <GridColumn field="language" width="80px" title="page.profilesMenu.language" />
        </Grid>

        {modalVariant === 'selectProfiles' && (
          <UpdateMenuProfileContext
            withSelect
            defaultSelectedProfiles={selectedProfiles && selectedProfiles.length ? selectedProfiles : []}
          >
            <MenuProfileDialog
              visible={modalVariant === 'selectProfiles'}
              closeDialog={() => {
                this.setState({ modalVariant: '' });
              }}
              saveSelectedProfiles={(selectedProfiles) => {
                this.setState({ selectedProfiles, props: [] });
              }}
            />
          </UpdateMenuProfileContext>
        )}

        {modalVariant === 'selectElements' && (
          <UpdateMenuElementsContext
            withSelect
            defaultSelectedElements={
              selectedElements && selectedElements.length ? selectedElements.map(({ id }) => id) : []
            }
          >
            <MenuElementDialog
              visible={modalVariant === 'selectElements'}
              closeDialog={() => {
                this.setState({ modalVariant: '' });
              }}
              saveSelectedElements={(selectedElements) => {
                this.setState({ selectedElements, props: [] });
              }}
              fullDefaultSelectedElements={selectedElements}
            />
          </UpdateMenuElementsContext>
        )}

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.deleteProp}
            deleteContent={translate('page.profilesMenu.areYouSureDeleteElementProps')}
          />
        )}
        {modalVariant === 'change' && (
          <ChangePropValueModal
            visible={modalVariant === 'change'}
            closeDialog={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={() => {
              this.fetchResultMenuPropsGQL({ selectIdsString });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslate(NavigationMenuPropGrid);
