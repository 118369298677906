import React, { PureComponent } from 'react';

import FormStateContainer from '../../../../components/FormStateContainer';
import AddModal from './AddModal';
import { INITIAL_VALUES } from '../../constants';

class AddModalContainer extends PureComponent {
  render() {
    return (
      <FormStateContainer initialValues={{ ...INITIAL_VALUES, bins: '' }}>
        <AddModal {...this.props} />
      </FormStateContainer>
    );
  }
}

export default AddModalContainer;
