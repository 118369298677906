import React, { PureComponent } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG, PAYMENT_TYPES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

import '../DealerCommissionsPaymentTools.scss';

const api = new ApiService();

class ModalPaymentTools extends PureComponent {
  constructor(props) {
    super(props);
  }
  state = {
    currentOwners: [],
    paymentTypes: [],
    selectPaymentTypes: [],
    isLoading: false,
  };

  handleSelectedOwner = (currentOwners) => {
    this.setState({ currentOwners });
  };

  onPaymentTypesSave = (selectPaymentTypes) => {
    this.setState({ selectPaymentTypes });
  };

  createButtons = () => {
    const { currentOwners, selectPaymentTypes } = this.state;
    const { translate, closeModal, showModal } = this.props;
    const btnGroup = [];
    if (showModal === 'add') {
      return [
        <Button
          type="primary"
          disabled={!(currentOwners.length && selectPaymentTypes.length)}
          onClick={this.onAddOrDeleteOwnerPaymentTool}
        >
          {translate('core.buttonTitles.add')}
        </Button>,
        <Button key="back" onClick={closeModal}>
          {translate('page.commission.btn-back')}
        </Button>,
      ];
    }
    if (showModal === 'remove') {
      return [
        <Button key="submit" type="primary" onClick={this.onAddOrDeleteOwnerPaymentTool}>
          {translate('core.buttonTitles.delete')}
        </Button>,
        <Button key="back" onClick={closeModal}>
          {translate('page.commission.btn-back')}
        </Button>,
      ];
    }
    return btnGroup;
  };

  fetchPaymentType = async () => {
    try {
      this.setState({ isLoading: true });
      const paymentTypes = await api.getPaymentTypeList();
      await this.setState({ paymentTypes, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.setState({ isLoading: false });
    }
  };

  createModalContent = () => {
    const { currentOwners, paymentTypes, selectPaymentTypes } = this.state;
    const { allOwners, translate, showModal } = this.props;

    if (showModal === 'add') {
      return (
        <div className="ModalPaymentTool-content">
          <Row className="DealerCommissionsPaymentTools-row">
            <Col span={24} sm={14} lg={14} className="DealerCommissionsPaymentTools-row">
              <Col className="DealerCommissionsPaymentTools-label">{translate('page.commissionsServices.dealer')}</Col>
              <Col className="DealerCommissionsPaymentTools-ownerDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeOwners(allOwners)}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={currentOwners}
                  onSave={this.handleSelectedOwner}
                  isSingle
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col span={24} sm={14} lg={14} className="DealerCommissionsPaymentTools-row">
              <Col className="DealerCommissionsPaymentTools-label">
                {translate('page.commissionsServices.paymentType')}
              </Col>
              <Col className="DealerCommissionsPaymentTools-ownerDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                  colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                  selectItems={selectPaymentTypes}
                  onSave={this.onPaymentTypesSave}
                  isSingle
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                />
              </Col>
            </Col>
          </Row>
        </div>
      );
    }

    if (showModal === 'remove') {
      return (
        <Row className="ModalPaymentTool-row">
          {translate('page.dealerCommissionsPaymentTools.modalRemovePaymentTool')}
        </Row>
      );
    }
  };
  handleModel = () => {
    const { selectedItems } = this.props;

    return selectedItems.map(({ OwnerId, PaymentTypeId }) => ({
      OwnerId,
      PaymentType: PaymentTypeId,
      forDelete: true,
    }));
  };

  onAddOrDeleteOwnerPaymentTool = async () => {
    const { currentOwners, selectPaymentTypes } = this.state;
    const { showModal, update, closeModal } = this.props;

    try {
      this.setState({ isLoading: true });

      if (showModal === 'add') {
        const model = [
          {
            ownerId: currentOwners[0],
            paymentType: selectPaymentTypes[0],
            forDelete: false,
          },
        ];
        await api.ownersPaymentTypeListAdd(model);
      }
      if (showModal === 'remove') {
        const model = [...this.handleModel()];
        await api.ownersPaymentTypeListDelete(model);
      }
      this.setState({ isLoading: false });
      update(currentOwners);
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchPaymentType();
  }

  render() {
    const { translate, showModal, closeModal } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Modal
          title={translate('page-nav.nav-commissionsDealer-paymentTools')}
          visible={showModal}
          footer={this.createButtons()}
          onCancel={closeModal}
          className="ModalPaymentTool"
        >
          {this.createModalContent()}
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(ModalPaymentTools);
