import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import BeneficiariesGrid from './BeneficiariesGrid';

class Beneficiaries extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-beneficiaries',
          content: <BeneficiariesGrid />,
          closable: false,
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} />;
  };
}
export default withTranslate(Beneficiaries);
