import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import EditableGridInput from '../../../components/grids/EditableGridInput';

const api = new ApiService();

class AuthorizationsDictionaryGrid extends PureComponent {
  currentArray = findArray('authorizationsDictionary');

  state = {
    authorizationTypes: [],
    currentEditRow: null,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchAuthorizationTypes();
  }

  fetchAuthorizationTypes = async () => {
    try {
      this.setState({ isLoading: true, currentEditRow: null, authorizationTypes: [] });
      const authorizationTypes = await api.getAuthorizationTypeDictionaryList();
      await this.setState({
        authorizationTypes:
          authorizationTypes && authorizationTypes.length
            ? [...authorizationTypes].map((authorizationType) => ({
                ...authorizationType,
                EditId: authorizationType.Id,
              }))
            : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow } = this.state;

    return (
      <Row>
        {checkElement('authorizationsDictionary-addEditRow', this.currentArray) && (
          <Button type="primary" disabled={!!currentEditRow} onClick={this.handleAdd}>
            {translate('core.buttonTitles.add')}
          </Button>
        )}

        {checkElement('authorizationsDictionary-saveEditRow', this.currentArray) && (
          <Button type="primary" disabled={!currentEditRow} onClick={this.updateAuthorization}>
            {translate('core.buttonTitles.save')}
          </Button>
        )}
      </Row>
    );
  };

  updateAuthorization = async () => {
    const { currentEditRow } = this.state;
    const { Id, Name, ServiceId, EditId } = currentEditRow;
    const query = `${
      EditId === '-1' ? 'clientAuthorizationTypeEntityAdd' : 'clientAuthorizationTypeEntityUpdate'
    }(input: { clientAuthorizationTypeEntity:{id: ${EditId === '-1' ? 0 : Id}, name: ${
      !Name && Name !== 0 ? null : `"${Name}"`
    }, serviceId: ${
      !ServiceId && ServiceId !== 0 ? null : ServiceId
    }} }){clientAuthorizationType { id,name,serviceId }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchAuthorizationTypes();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  handleAdd = () => {
    const { authorizationTypes } = this.state;
    const emptyItem = { Id: '', Name: '', ServiceId: '', EditId: '-1' };

    this.setState({
      authorizationTypes: [emptyItem, ...authorizationTypes],
      currentEditRow: emptyItem,
    });
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow } = this.state;
    const currentDataItem = currentEditRow && currentEditRow.EditId === dataItem.EditId ? currentEditRow : dataItem;

    return checkElement('authorizationsDictionary-saveEditRow', this.currentArray) ? (
      <td align="center">
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.EditId !== dataItem.EditId) || field === 'Id'}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    ) : (
      <td>{currentDataItem[field]}</td>
    );
  };

  render() {
    const { authorizationTypes, isLoading } = this.state;

    if (!checkElement('authorizationsDictionary-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={authorizationTypes}
        name="authorizationsDictionaryGrid"
        toolbar={() => {}}
        onRefresh={this.fetchAuthorizationTypes}
        isLoading={isLoading}
        fieldForSelection="Id"
      >
        <GridColumn field="Id" title="page.authorizationsDictionary.col-id" width="150" filter="numeric" />
        <GridColumn field="Name" title="page.authorizationsDictionary.col-name" width="240" />
        <GridColumn
          field="ServiceId"
          title="page.authorizationsDictionary.col-serviceId"
          width="200"
          filter="numeric"
        />
      </Grid>
    );
  }
}

export default withTranslate(AuthorizationsDictionaryGrid);
