import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import ElementProps from '../../components/ElementProps';
import NavigationMenuElementsGrid from './NavigationMenuElementsGrid';

class ManagementMenuPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-menuElementNavigation',
          content: <NavigationMenuElementsGrid />,
          closable: false,
        },
      ],
    };
  }
  onAddTab = ({ type, dataItem }, key) => {
    const { translate } = this.props;
    const { tabs } = this.state;

    switch (type) {
      case 'menuElementProps':
        tabs.push({
          title: `${translate('page.profilesMenu.elementProps')} ${dataItem.elementName}`,
          content: <ElementProps {...dataItem} />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onAddTab={this.onAddTab} onRemoveTab={this.updateTabs} />;
  };
}
export default withTranslate(ManagementMenuPage);
