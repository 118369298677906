export const beneficiaries = (translate, value) => {
  switch (value) {
    case 'beneficiaries-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'beneficiaries-btnAdd':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'beneficiaries-btnChange':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    case 'beneficiaries-btnDelete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
  }
};
