import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';

class DealerCommissionsHistoryService {
  getMinimumSelectedDate = () => StandardizeTimeForDate(new Date(2007, 0, 1), true);

  getSubmitErrorsByDate = (startDate, endDate, translate) => {
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();

    if (minimumSelectedDate - normalizedStartDate > 0 || minimumSelectedDate - normalizedEndDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    const FIVE_EAR_AGO_BY_END_DATE = StandardizeTimeForDate(
      new Date(
        new Date(normalizedEndDate).getFullYear() - 5,
        new Date(normalizedEndDate).getMonth(),
        new Date(normalizedEndDate).getDate(),
      ),
      true,
    );

    if (FIVE_EAR_AGO_BY_END_DATE - normalizedStartDate > 0) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new DealerCommissionsHistoryService();
