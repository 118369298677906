import React, { PureComponent } from 'react';
import { Row, Button, Select } from 'antd';

import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../services';
import { BASE_SERVICES_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

import './TerminalsActiveServicesGrid.styled.scss';

const api = new ApiService();

const { Option } = Select;

class TerminalsActiveServicesGrid extends PureComponent {
  currentArray = findArray('TerminalsActiveServices');

  state = {
    terminals: [],
    isLoading: false,
    services: [],
    selectedServices: [],
    regionList: [],
    selectedRegion: undefined,
    cityList: [],
    selectedCities: [],
  };

  componentDidMount() {
    this.fetchSelectData();
  }

  fetchSelectData = async () => {
    await this.setState({ isLoading: true });
    await this.fetchServiceList();
    await this.fetchCityList();
    await this.setState({ isLoading: false });
  };

  fetchCityList = async () => {
    const { showError } = this.props;

    try {
      const cities = await api.getCities();
      const regionList =
        cities && cities.length
          ? cities.reduce(
              (acc, { RegionId, RegionName }) =>
                acc.find(({ RegionId: uniqRegionId }) => uniqRegionId === RegionId)
                  ? acc
                  : [...acc, { RegionId, RegionName }],
              [],
            )
          : [];

      this.setState({
        cityList:
          cities && cities.length ? cities.map((city) => ({ ...city, Id: city.CityId, Name: city.CityName })) : [],
        regionList,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchServiceList = async () => {
    const { showError } = this.props;

    try {
      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      this.setState({
        services: DropdownNormalizersService.normalizeServices(
          services && services.length ? services.filter(({ Status }) => Status === 'Активный') : [],
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getTerminals = async () => {
    const { showError } = this.props;
    const { selectedRegion, selectedServices, selectedCities } = this.state;

    await this.setState({ isLoading: true });

    try {
      const terminals = await api.getTerminalsListByService({
        serviceId: selectedServices[0],
        regionId: selectedRegion,
        cityId: selectedCities && selectedCities.length ? selectedCities[0] : null,
      });

      this.setState({
        terminals,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const { services, selectedServices, regionList, selectedRegion, cityList, selectedCities } = this.state;
    const { translate } = this.props;

    return (
      <Row className="TerminalsActiveServicesGrid-toolbar">
        <div className="TerminalsActiveServicesGrid-fields">
          <div className="TerminalsActiveServicesGrid-fieldRow">
            <div className="TerminalsActiveServicesGrid-label">{translate('page.commissionsServices.service')}</div>
            <div className="TerminalsActiveServicesGrid-select">
              <GridDropdown
                data={services}
                colConfig={BASE_SERVICES_COLUMN_CONFIG.filter(({ fieldName }) => fieldName !== 'Status')}
                selectItems={selectedServices}
                defaultTitle={translate('page.commissionsServices.chooseService')}
                onSave={(selectedServices) => {
                  this.setState({ selectedServices });
                }}
                isSingle
              />
            </div>
          </div>

          <div className="TerminalsActiveServicesGrid-fieldRow">
            <div className="TerminalsActiveServicesGrid-label">{translate('page.terminalsActiveServices.region')}</div>
            <div className="TerminalsActiveServicesGrid-nativeSelect">
              <Select
                value={selectedRegion}
                placeholder={translate('page.terminalsActiveServices.selectRegion')}
                onChange={(selectedRegion) => {
                  this.setState({ selectedRegion, selectedCities: [] });
                }}
                optionFilterProp="children"
                showSearch
                filterOption
              >
                {regionList.map(({ RegionId, RegionName }) => (
                  <Option value={RegionId} key={`regionSelect-${RegionId}`}>
                    {RegionName}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="TerminalsActiveServicesGrid-fieldRow">
            <div className="TerminalsActiveServicesGrid-label">{translate('page.terminalsActiveServices.city')}</div>
            <div className="TerminalsActiveServicesGrid-select">
              <GridDropdown
                data={cityList.filter(({ RegionId }) => RegionId === selectedRegion)}
                colConfig={[
                  {
                    fieldName: 'Name',
                    titleTranslateSlug: 'page.terminalsActiveServices.city',
                    width: '140',
                    isShowOnMobile: true,
                  },
                  {
                    fieldName: 'Description',
                    titleTranslateSlug: 'page.terminalsActiveServices.district',
                    width: '240',
                    isShowOnMobile: true,
                  },
                ]}
                selectItems={selectedCities}
                defaultTitle={translate('page.terminalsActiveServices.selectCity')}
                onSave={(selectedCities) => {
                  this.setState({ selectedCities });
                }}
                disabled={!selectedRegion}
                isAllowEmptyList
                isSingle
              />
            </div>
          </div>
        </div>

        <div className="TerminalsActiveServicesGrid-action">
          <Button type="primary" disabled={!selectedServices.length} onClick={this.getTerminals}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>
      </Row>
    );
  };

  render() {
    const { terminals, isLoading } = this.state;

    if (!checkElement('terminalsActiveServices-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={terminals}
        name="TerminalsActiveServicesGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.getTerminals}
        isLoading={isLoading}
      >
        <GridColumn
          field="TerminalId"
          title="grids.terminalsMainProfileList.column-terminalId"
          width="180"
          filter="numeric"
        />
        <GridColumn field="TerminalName" title="grids.terminalsMainProfileList.column-terminalName" width="270" />
        <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />
        <GridColumn field="CityName" title="grids.terminalsMainProfileList.column-cityName" width="150" />
        <GridColumn field="TerminalAdress" title="grids.terminalsMainProfileList.column-terminalAddress" width="150" />
        <GridColumn field="TerminalSign" title="grids.terminalsAccess.column-terminalSign" width="150" />
        <GridColumn field="TerminalRegime" title="grids.settingsAndEvents.column-workingHours" width="160" />
        <GridColumn
          field="LastConnect"
          title="grids.terminals.column-lastTransaction"
          width="220"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn
          field="LastTransaction"
          title="grids.terminals.column-lastconnect"
          width="210"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(TerminalsActiveServicesGrid);
