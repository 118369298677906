import React, { PureComponent } from 'react';
import { Alert, Button, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class DeleteModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteTerminalTemplate = async () => {
    const {
      selectedItem: { ownerId, terminalId, templateId },
      onRefresh,
      closeModal,
    } = this.props;

    try {
      await this.setState({ isLoading: true });

      const query = `customTemplateRefEntityDelete(input: { customTemplateRefEntity:{ ownerId: ${ownerId}, terminalId: ${terminalId}, templateId: ${templateId}} }){customTemplateRef { ownerId,terminalId,templateId }}`;
      await api.mutationByGraphQl(query);
      this.setState({ isLoading: false });
      closeModal();
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteTerminalTemplate}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button
            key="back"
            type="primary"
            onClick={closeModal}
            style={{ margin: variant === 'forbiddenDelete' ? '0 auto' : 'auto' }}
          >
            {translate(variant === 'forbiddenDelete' ? 'core.buttonTitles.ok' : 'core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {variant === 'forbiddenDelete' ? (
          <Alert message={translate('page.OTPServices.invalidDeleteDate')} type="error" showIcon />
        ) : (
          <div style={{ textAlign: 'center' }}>{translate('page.OTPServices.areYouSure')}</div>
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteModal);
