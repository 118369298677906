import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import MenuElementPropUpdateModal from './MenuElementPropUpdateModal';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import { ApiService, StorageService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class MenuElementPropDictionaryGrid extends PureComponent {
  currentArray = findArray('menuElementPropDictionary');

  state = {
    props: [],
    selectProps: [],
    isLoading: false,
    dialogVariant: '',
  };

  componentDidMount() {
    this.fetchElementProps();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectProps } = this.state;

    return (
      <Col>
        {checkElement('menuElementPropDictionary-add', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ dialogVariant: 'add' });
            }}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        )}

        {checkElement('menuElementPropDictionary-change', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectProps.length}
            onClick={() => {
              this.setState({ dialogVariant: 'change' });
            }}
          >
            {translate('core.buttonTitles.change')}
          </Button>
        )}

        {checkElement('menuElementPropDictionary-delete', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectProps.length}
            onClick={() => {
              this.setState({ dialogVariant: 'delete' });
            }}
          >
            {translate('core.buttonTitles.delete')}
          </Button>
        )}
      </Col>
    );
  };

  fetchElementProps = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo || !userInfo.OwnerId) {
      return [];
    }

    try {
      await this.setState({ isLoading: true });
      const props = await api.getMenuPropertiesKeys(userInfo.OwnerId, false);
      await this.setState({ isLoading: false, props });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  deleteProp = async () => {
    const { translate } = this.props;
    const { selectProps } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.menuPropertiesKeyMerge({
        key: selectProps[0].Key,
        type: selectProps[0].Type,
        description: selectProps[0].Description,
        system: selectProps[0].systemType,
        forDelete: true,
      });
      message.success(translate('page.menuElementPropDictionary.propertyDeletedSuccessfully'), 2);
      await this.setState({ isLoading: false });
      this.fetchElementProps();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { props, isLoading, dialogVariant, selectProps } = this.state;
    const { translate } = this.props;

    if (!checkElement('menuElementPropDictionary-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={props}
          isLoading={isLoading}
          onRefresh={this.fetchElementProps}
          name="MenuElementPropDictionaryGrid"
          toolbar={this.buildToolbar()}
          fieldForSelection="Key"
          onSelect={(selectProps) => {
            this.setState({ selectProps });
          }}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="Key" width="300px" title="page.profilesMenu.propName" />
          <GridColumn field="Type" width="80px" title="page.menuElementPropDictionary.type" />
          <GridColumn field="Description" width="800px" title="page.menuElementPropDictionary.description" />
          <GridColumn field="System" width="120px" title="page.profilesMenu.systemType" />
          <GridColumn
            field="DatePost"
            width="140px"
            title="page.menuElementPropDictionary.changeDate"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
        </Grid>

        {(dialogVariant === 'add' || dialogVariant === 'change') && (
          <MenuElementPropUpdateModal
            visible={dialogVariant === 'add' || dialogVariant === 'change'}
            isAddLogic={dialogVariant === 'add'}
            selectProp={selectProps[0]}
            closeDialog={() => {
              this.setState({ dialogVariant: '' });
            }}
            onRefresh={this.fetchElementProps}
          />
        )}

        {dialogVariant === 'delete' && (
          <DeleteModal
            visible={dialogVariant === 'delete'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            modalTitle={`${translate('page.menuElementPropDictionary.deleteProp')} ${selectProps[0].Key}`}
            submitCallback={this.deleteProp}
            deleteContent={translate('page.menuElementPropDictionary.areYouSure')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(MenuElementPropDictionaryGrid);
