import React, { PureComponent } from 'react';
import GridTechInform from './gridTechInform';
import PageTabs from '../../components/tabber/tabber';

export default class PageTechInform extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-techInform',
          content: <GridTechInform />,
          closable: false,
          key: '0',
        },
      ],
    };
  }
  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}
