import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Col, Modal } from 'antd';

import './ModalAddCommissions.scss';
import GridDropdown from '../../GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import LoadingPanel from '../../loader';
import DealerCommissionForm from '../../DealerCommissionForm/DealerCommissionForm';
import DealerCommissionFormService from '../../DealerCommissionForm/dealerCommissionForm.service';

class ModalAddCommissions extends PureComponent {
  onSubmit = () => {
    const { formState, upDateGrid, onError, translate } = this.props;
    const globalError = DealerCommissionFormService.globalValidate(translate)(formState);
    onError('global', globalError);
    if (globalError) return;

    upDateGrid(
      Object.entries(formState).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: DealerCommissionFormService.getParsedValue(value) }),
        {},
      ),
    );
  };

  componentDidUpdate = (prevProps) => {
    const { formState, setErrorState, formErrors } = this.props;
    const { formState: prevFormState } = prevProps;

    if (!prevFormState || formState !== prevFormState) {
      setErrorState({
        ...formErrors,
        global: '',
      });
    }
  };

  render() {
    const {
      visible,
      closeModal,
      translate,
      formState,
      onChangeFormState,
      formErrors,
      onError,
      onPaymentTypesSave,
      paymentTypes,
      selectPaymentTypes,
      isLoading,
      setErrorState,
    } = this.props;
    const isDisabledByErrors = !!Object.values(formErrors).filter((item) => item).length;

    return (
      <>
        <Modal
          visible={visible}
          title={translate('page.dealerCommissionsChange.modal-title')}
          onCancel={() => closeModal(false)}
          footer={[
            <Button
              type="primary"
              disabled={!selectPaymentTypes.length || isDisabledByErrors}
              key="add"
              onClick={() => this.onSubmit(formState, onError)}
            >
              {translate('core.buttonTitles.add')}
            </Button>,
            <Button key="back" onClick={() => closeModal(false)}>
              {translate('core.buttonTitles.refuse')}
            </Button>,
          ]}
          className="ModalAddCommissions"
        >
          <DealerCommissionForm
            formState={formState}
            formErrors={formErrors}
            onChangeFormState={onChangeFormState}
            onError={onError}
            setErrorState={setErrorState}
          />
          <Col span={24} className="ModalAddCommissions-selectRow">
            <Col className="ModalAddCommissions-label">{translate('page.commissionsServices.paymentType')}</Col>
            <Col className="ModalAddCommissions-select">
              <GridDropdown
                data={DropdownNormalizersService.normalizePaymentTypes(paymentTypes)}
                colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                selectItems={selectPaymentTypes}
                onSave={onPaymentTypesSave('selectPaymentTypes')}
                defaultTitle={translate('page.commissionsServices.choosePaymentType')}
              />
            </Col>
          </Col>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(ModalAddCommissions);
