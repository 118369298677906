const CheckTemplateAction = (translate, value) => {
  switch (value) {
    case 'manageTemplate-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'manageTemplate-createItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.create`)}`;
    case 'manageTemplate-editItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.edit`)}`;
    case 'manageTemplate-deleteItem':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`core.buttonTitles.delete`)}`;
    case 'manageTemplate-previewReceipt':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`page.templates.btn-receipt`)}`;
    case 'manageTemplate-updateItemVersion':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`page.templates.updateTemplate`)}`;
    default:
      return value;
  }
};

export default CheckTemplateAction;
