import React, { PureComponent } from 'react';

import './CommissionsServices.scss';
import { Checkbox } from 'antd';

class CustomSelectedCell extends PureComponent {
  render() {
    const {
      dataItem: { Id },
      selectionChange,
      selectedItems,
    } = this.props;

    return (
      <td className="CustomSelectedCell">
        <Checkbox
          checked={selectedItems.map(({ Id }) => Id).includes(Id)}
          onChange={() => {
            selectionChange(Id);
          }}
        />
      </td>
    );
  }
}

export default CustomSelectedCell;
