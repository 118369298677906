import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, Row } from 'antd';
import ModalFinMonLimits from './ModalFinMonLimits/ModalFinMonLimits';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { ApiService, tabService } from '../../../services';

import './ExceptionsFinMonLimit.scss';

const api = new ApiService();

class ExceptionsFinMonLimit extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('exceptionsFinMonLimit');
  }
  state = {
    servicesLimits: [],

    isLoading: false,
    visible: false,
    modalName: '',
    selectedItems: [],
  };

  servicesLimitsDictionary = async () => {
    try {
      this.setState({ isLoading: true });
      const servicesLimits = await api.servicesLimitsDictionary();
      this.setState({ servicesLimits });
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  openModal = (modal) => {
    this.setState({ visible: true, modalName: modal });
  };
  closeModal = () => {
    this.setState({ visible: false });
  };

  async componentDidMount() {
    await this.servicesLimitsDictionary();
  }
  addServices = () => {
    return tabService.addTab({
      type: 'addNewServices',
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row className="Exceptions-toolbar">
        <Col className="Exceptions-controlBtn">
          {checkElement('exceptionsFinMonLimit-btnAdd', this.currentArray) && (
            <Button type="primary" onClick={this.addServices}>
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('exceptionsFinMonLimit-btnChangeLimits', this.currentArray) && (
            <Button type="primary" disabled={!selectedItems.length} onClick={() => this.openModal('isChangeModal')}>
              {translate('core.buttonTitles.change')}
            </Button>
          )}
          {checkElement('exceptionsFinMonLimit-btnRemoveLimits', this.currentArray) && (
            <Button type="primary" disabled={!selectedItems.length} onClick={() => this.openModal('isRemoveModal')}>
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { isLoading, servicesLimits, visible, modalName, selectedItems } = this.state;
    if (!checkElement('exceptionsFinMonLimit-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={servicesLimits}
          name="exceptionsFinMonLimitGrid"
          toolbar={this.buildToolbar(modalName)}
          onRefresh={this.servicesLimitsDictionary}
          onSelect={this.handleSelectedItems}
          fieldForSelection="ServiceId"
          multiSelected
          isLoading={isLoading}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
            title="#"
          />
          <GridColumn field="ServiceId" filter="numeric" title="grids.finMonLimits.column-serviceId" width="150px" />
          <GridColumn field="ServiceName" title="grids.finMonLimits.column-serviceName" width="180px" />
          <GridColumn field="SupportManager" title="grids.finMonLimits.column-supportManager" width="250px" />
          <GridColumn field="AmountMax" filter="numeric" title="grids.finMonLimits.column-amountMax" width="200px" />
          <GridColumn field="ServiceStatus" title="grids.finMonLimits.column-serviceStatus" width="150px" />
        </Grid>

        {visible && (
          <ModalFinMonLimits
            visible={visible}
            modalName={modalName}
            closeModal={this.closeModal}
            selectedItems={selectedItems}
            update={this.servicesLimitsDictionary}
          />
        )}
      </>
    );
  }
}
export default withTranslate(ExceptionsFinMonLimit);
