import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { ApiService, StorageService, tabService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import ModalChooseSettings from '../modalChooseSetting';
import GqlService from '../../../../components/grids/gql.service';
import { ALL_GRID_COLUMNS, NORMALIZE_FIELD_MAP } from './constans';
import { lowerCaseFirstLetter } from '../../../../utils/lowerCaseFirstLetter';
import SettingsAndEventsService from './settingsAndEventsService';
import GridDropdownWithDeletedOwners from '../../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
const api = new ApiService();

class GridSettingsAndEvents extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlSettings');
    this.state = {
      isLoading: false,
      data: [],
      visible: false,
      selectedItems: [],
      showDeleted: false,
      rowAmount: '50',
      pageValue: 1,
      filtersString: '',
      sortString: '',
      hasNextPage: false,
      hasPreviousPage: false,
      defaultSelectItems: [],
      selectIdsString: undefined,
      selectedOwners: [],
      allOwners: [],
    };
  }

  componentWillUnmount() {
    StorageService.removeItem('checkSelected-screenControlSettings-grid');
  }

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchTerminalEvents({ selectIdsString });
    }
  }

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
    // this.fetchTerminalEvents();
  }

  // makeQuery = async () => {
  //   const { showDeleted, selectedOwners } = this.state;
  //
  //   if (selectedOwners.length < 1) return;
  //
  //   try {
  //     this.toggleLoader();
  //     const model = {
  //       OwnerId: selectedOwners,
  //       ShowDeleted: showDeleted,
  //     };
  //
  //     const data = await api.terminalListManage(model);
  //     this.setState({ data });
  //   } catch (error) {
  //     const { showError } = this.props;
  //     showError(error);
  //   } finally {
  //     this.toggleLoader();
  //   }
  // };

  fetchTerminalEvents = async (props) => {
    const { rowAmount, pageValue, sortString, filtersString, showDeleted, selectedOwners } = this.state;

    const currentFilterString =
      (!props && showDeleted) || (props && !props.selectIdsString && showDeleted)
        ? filtersString
        : `{and:[${filtersString}
      ${props && props.selectIdsString ? `, {terminalId:{in:[${props.selectIdsString}]}}` : ``}${
            !showDeleted ? `,{statusId:{neq:7}}` : ``
          }
    ]}`;

    this.toggleLoader(true);
    try {
      const storageColumns = StorageService.getItem('screenControlSettings-grid');
      const currentColumn =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP) : ALL_GRID_COLUMNS);
      const argumentString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order:${
        sortString || '{ terminalId: ASC }'
      },where:{and: [{ownerId:{in: [${selectedOwners}]}}, ${currentFilterString}]} `;

      const query = `terminalManagementList(${argumentString}){items{${currentColumn.join(
        ',',
      )}}pageInfo{hasNextPage, hasPreviousPage} }`;

      const data = await api.getByGraphQl(query);
      const { terminalEventListService, hasPreviousPage, hasNextPage } = SettingsAndEventsService.getGQLResponse(data);
      this.setState({ data: terminalEventListService, hasPreviousPage, hasNextPage });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchTerminalEvents({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };
  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchTerminalEvents();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onOwners = (selectedOwners) => {
    this.setState({ selectedOwners }, this.fetchTerminalEvents);
  };

  onFieldsConfigChange = (list) => {
    this.fetchTerminalEvents({
      currentFieldList:
        list && list.length
          ? list
              .map((field) => NORMALIZE_FIELD_MAP.get(field) || lowerCaseFirstLetter(field))
              .filter((field) => field !== 'selected')
          : null,
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, selectedOwners, allOwners } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={selectedOwners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('screenControlSettings-btn-addRemoteSetting', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px', marginLeft: 0 }}
            onClick={this.toggleModalChoose}
            disabled={!selectedItems || selectedItems.length === 0}
          >
            {translate('grids.settingsAndEvents.btn-add')}
          </Button>
        )}
      </Col>
    );
  };

  getShowProperty = (key) => {
    return checkElement(key, this.currentArray);
  };

  getHandlers = () => {
    return [
      {
        title: 'page.newTerminals.tab-details',
        action: (props) => tabService.addTab({ type: 'propDetails', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-terminalProperties'),
      },
      {
        title: 'page.terminalSettings.addtionalSettings-title',
        action: (props) => tabService.addTab({ type: 'addtionalSettings', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-additionalSettings'),
      },
      {
        title: 'page.terminalSettings.remoteSettings-title',
        action: (props) => tabService.addTab({ type: 'remoteSettings', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-remoteSettings'),
      },
      {
        title: 'page.dashboard.tab-type-events',
        action: (props) => tabService.addTab({ type: 'events', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('screenControlSettings-newTableLink-terminalsEvents'),
      },
    ];
  };

  closeModalChoose = async () => {
    StorageService.removeItem('checkSelected-screenControlSettings-grid');
    await this.fetchTerminalEvents();
    this.setState({ visible: false });
  };

  toggleModalChoose = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  showRemoteCallback = () => {
    this.setState(
      ({ showDeleted }) => ({ showDeleted: !showDeleted }),
      () => {
        this.fetchTerminalEvents();
      },
    );
  };

  render = () => {
    const {
      isLoading,
      data,
      visible,
      selectedItems,
      showDeleted,
      hasNextPage,
      hasPreviousPage,
      pageValue,
      selectIdsString,
    } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={() => {
            this.fetchTerminalEvents({ selectIdsString });
          }}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="screenControlSettings-grid"
          permissionName="screenControlSettings"
          toggleLoader={this.toggleLoader}
          fieldForSelection="TerminalId"
          onSelect={this.handleSelectionChange}
          handleRowAmountChange={this.handleRowAmountChange}
          pageChange={this.pageChange}
          pageValue={pageValue}
          setFiltersString={this.setFiltersString}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          setSortingString={this.setSortingString}
          onFieldsConfigChange={this.onFieldsConfigChange}
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
          normalizeGQLFieldsMap={NORMALIZE_FIELD_MAP}
          multiSelected
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={showDeleted}
          isShowSpecificFilterByFieldName="TerminalId"
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="TerminalId"
            title="grids.terminalsMainProfileList.column-terminalId"
            width="200"
            filter="numeric"
            handlers={this.getHandlers()}
            dropdown={checkElement('screenControlSettings-isShowDropdownTerminalId', this.currentArray)}
            remoteCommands={true}
            checked={false}
          />

          <GridColumn field="AppVersion" title="grids.settingsAndEvents.column-versionPO" width="150" />

          <GridColumn field="OsVersion" title="grids.settingsAndEvents.column-versionOS" width="150" />

          <GridColumn field="TypeName" title="owner.column-type" width="100" />

          <GridColumn field="DesignTypeName" title="grids.createTerminal.column-designType" width="150" />

          <GridColumn field="TerminalName" title="grids.terminals.column-name" width="200" />

          <GridColumn field="FiscalNumber" title="grids.createTerminal.column-fiscalNumber" width="150" />

          <GridColumn field="PlacingName" title="page.terminalSettings.field-placing" width="150" />

          <GridColumn field="TerminalDescription" title="grids.adminPanel.column-description" width="150" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="150" />

          <GridColumn
            field="MonitorCount"
            title="page.terminalSettings.field-monitorCount"
            width="100"
            filter="numeric"
          />

          <GridColumn field="TechnicianName" title="grids.settingsAndEvents.column-technician" width="150" />

          <GridColumn field="Geopoint" title="grids.settingsAndEvents.column-geopoint" width="150" />

          <GridColumn field="Branch" title="grids.terminals.column-branch" width="150" />

          <GridColumn field="RegionName" title="grids.terminalsMainProfileList.column-regionName" width="150" />

          <GridColumn field="CityName" title="grids.terminals.column-city" width="150" />

          <GridColumn field="TerminalAddress" title="grids.terminals.column-street" width="150" />

          <GridColumn field="Regime" title="grids.settingsAndEvents.column-workingHours" width="160" />

          <GridColumn
            field="DateLastConnect"
            title="grids.certificates.certificate-date-last-connect"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="DateLastTransaction"
            title="grids.terminals.column-lastConnect"
            width="250"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="LimitTransactions"
            title="grids.settingsAndEvents.column-transactionDayLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="LimitTransactionsNight"
            title="grids.settingsAndEvents.column-transactionNightLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="LimitTurns"
            title="grids.settingsAndEvents.column-turnoverDayLimit"
            width="150"
            filter="numeric"
          />

          <GridColumn field="LimitTurnsNight" title="grids.settingsAndEvents.column-turnoverNightLimit" width="150" />

          <GridColumn field="СommissionProfileName" title="grids.createTerminal.column-profileComissions" width="250" />

          <GridColumn field="Menu2ProfileName" title="grids.settingsAndEvents.column-menuProfile" width="250" />

          <GridColumn field="MainProfileId" title="grids.settingsAndEvents.column-idScreenProfile" width="250" />

          <GridColumn field="MainProfileName" title="grids.terminalsMainProfileList.column-mainProfileId" width="250" />

          <GridColumn field="ProjectName" title="grids.createTerminal.column-project" width="150" />

          <GridColumn field="NetworkContractName" title="grids.createTerminal.column-networkContract" width="150" />

          <GridColumn field="StatusName" title="owner.column-status" width="150" />

          <GridColumn field="StatusDetails" title="grids.settingsAndEvents.column-statusDetails" width="150" />
        </Grid>
        <ModalChooseSettings
          visible={visible}
          closeModal={this.closeModalChoose}
          terminals={selectedItems.map(({ TerminalId }) => TerminalId)}
        />
      </>
    );
  };
}

export default withRouter(withTranslate(GridSettingsAndEvents));
