import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import CustomInput from '../../components/Input/Input';
import PartnerInfoSenderService from './PartnerInfoSender.service';
import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../../components/loader';
import { ApiService } from '../../services';

import './PartnerInfoSenderGrid.styled.scss';

const api = new ApiService();

class EditingOwnersModel extends PureComponent {
  state = {
    isIBANInvalid: false,
    isLoading: false,
  };

  renderInputRow = ({ label, inputComponent }) => {
    return (
      <div className="EditingOwnersModel-inputRow">
        <div className="EditingOwnersModel-label">{label}</div>
        {inputComponent}
      </div>
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isIBANInvalid } = this.state;
    const { formState } = this.props;
    const { formState: prevFormState } = prevProps;

    if (formState['PartnerInfo.Account'] !== prevFormState['PartnerInfo.Account'] && isIBANInvalid) {
      this.setState({ isIBANInvalid: false });
    }
  }

  renderInput = ({ name }) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;

    return (
      <div className="EditingOwnersModel-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={PartnerInfoSenderService.getValidators(translate)}
          name={name}
        />
      </div>
    );
  };

  editOwnersItems = async () => {
    const { formState, closeModal, onRefresh, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      if (formState['PartnerInfo.Account']) {
        await api.validateIban(formState['PartnerInfo.Account']);
      }

      const { OwnerId, ...restOwnerItems } = formState;

      await api.mergeOwnersItems(
        Object.entries(restOwnerItems).map(([key, value]) => ({
          ownerId: OwnerId,
          key,
          value: value || null,
          forDelete: false,
        })),
      );

      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.partnerInfoSenderGrid.dealerPropertiesChangedSuccessfully'), 2);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;

      if (e.data && e.data.Message !== 'INVALID_IBAN') {
        showError(e);
      }

      this.setState({ isLoading: false, isIBANInvalid: e.data && e.data.Message === 'INVALID_IBAN' });
    }
  };

  render() {
    const { visible, closeModal, translate, formErrors } = this.props;
    const { isLoading, isIBANInvalid } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        className="EditingOwnersModel"
        title={translate('page.partnerInfoSenderGrid.editDealerProperties')}
        footer={[
          <Button
            key="back"
            type="primary"
            disabled={formErrors['PartnerInfo.Id'] || isIBANInvalid}
            onClick={this.editOwnersItems}
          >
            {translate('core.buttonTitles.edit')}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerInfoNamePUMB'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Name.PUMB' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerInfoNameRaiff'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Name.Raiff' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerId'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Id' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerAccount'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Account' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerInfoCountryPUMB'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Country.PUMB' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerInfoCity'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.City' }),
          })}
          {this.renderInputRow({
            label: translate('page.partnerInfoSenderGrid.col-partnerInfoAddress'),
            inputComponent: this.renderInput({ name: 'PartnerInfo.Address' }),
          })}
        </div>

        {isIBANInvalid && <div className="EditingOwnersModel-error">{translate('page.bankDetails.error-iban')}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(EditingOwnersModel);
