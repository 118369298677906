import React, { PureComponent } from 'react';
import { INITIAL_VALUES } from '../constants';
import FormStateContainer from '../../../components/FormStateContainer';
import UpdateModal from './UpdateModal';

class UpdateModalContainer extends PureComponent {
  checkArr = (value) => (!value && value !== 0 ? (value === null ? ['null'] : []) : [value]);

  checkValue = (value) => (value === null ? 'null' : value);

  render() {
    const { selectedItems, variant } = this.props;

    const resultInitialValues =
      variant === 'change' || variant === 'changeByCheckTab'
        ? {
            bins: this.checkArr(!selectedItems[0].bin && selectedItems[0].bin !== 0 ? null : `${selectedItems[0].bin}`),
            systemTypes: this.checkArr(selectedItems[0].systemType),
            panTypes: this.checkArr(selectedItems[0].panType),
            incomingServiceList: this.checkArr(selectedItems[0].incomingServiceId),
            outcomingServiceList: this.checkArr(selectedItems[0].outcomingServiceId),
            banks: this.checkArr(selectedItems[0].bankName),
            rangeFrom: this.checkValue(selectedItems[0].rangeFrom),
            rangeTo: this.checkValue(selectedItems[0].rangeTo),
            amountMax: this.checkValue(selectedItems[0].amountMax),
            priority: this.checkValue(selectedItems[0].priority),
            weight: this.checkValue(selectedItems[0].weight),
          }
        : INITIAL_VALUES;

    return (
      <FormStateContainer initialValues={resultInitialValues}>
        <UpdateModal {...this.props} />
      </FormStateContainer>
    );
  }
}

export default UpdateModalContainer;
