import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { ApiService } from '../../../services';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import { formatDate } from '@telerik/kendo-intl';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

import './RemainingTerminals.scss';
import { getTransformDateString } from '../../../utils/getTransformDateString';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy-MM-ddTHH:mm:ss');

class RemainingTerminals extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('remainingTerminals');
  }
  state = {
    allOwners: [],
    remainingTerminals: [],
    selectOwners: [],
    isLoading: false,
    date: new Date(),
    residuesInTerminals: [],
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  fetchOwners = async () => {
    try {
      this.setState({ isLoading: true });
      const allOwners = await api.owners();
      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  getResiduesInTerminals = async () => {
    const { selectOwners, date } = this.state;
    try {
      const model = {
        ownerId: selectOwners,
        date: `${convertToISOFormat(date)}.000Z`,
      };
      this.toggleLoader(true);
      const residuesInTerminals = await api.residuesInTerminals(model);
      this.setState({ residuesInTerminals });
      console.log(residuesInTerminals);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };
  dropDownChangeCallback = (name) => (selectItems) => {
    this.setState({ [name]: selectItems });
  };
  onHandleChange = (e) => {
    this.setState({ date: e.target.value });
  };

  async componentDidMount() {
    await this.fetchOwners();
  }
  buildToolbar = () => {
    const { allOwners, selectOwners, date } = this.state;
    const { translate } = this.props;

    return (
      <Col span={24} xl={16} xxl={14}>
        <Row style={{ marginBottom: '15px' }}>
          <Col span={18}>
            <GridDropdown
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
              onSave={this.dropDownChangeCallback('selectOwners')}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Row>
        <Row>
          <Col span={18} lg={10}>
            <DateTimePicker value={date} onChange={this.onHandleChange} className="RemainingTerminals-dateTimePicker" />
          </Col>

          <Col span={24} lg={{ span: 6, offset: 1 }} offset={0}>
            {checkElement('residuesInTerminals-search', this.currentArray) && (
              <Button icon="search" primary onClick={this.getResiduesInTerminals}>
                {translate('owner.search')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    );
  };
  render = () => {
    const { residuesInTerminals, isLoading } = this.state;
    if (!checkElement('residuesInTerminals-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={residuesInTerminals}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          onRefresh={this.getResiduesInTerminals}
          name="residuesInTerminals-grid"
        >
          <GridColumn field="OwnerId" title="grids.residuesInTerminals.column-ownerId" width="120px" />
          <GridColumn field="OwnerName" title="grids.residuesInTerminals.column-ownerName" width="100px" />
          <GridColumn field="TerminalId" title="grids.residuesInTerminals.column-terminalId" width="140px" />
          <GridColumn field="TypeName" title="grids.residuesInTerminals.column-typeName" width="80px" />
          <GridColumn field="CityName" title="grids.residuesInTerminals.column-cityName" width="80px" />
          <GridColumn field="TerminalAddress" title="grids.residuesInTerminals.column-address" width="100px" />
          <GridColumn field="TerminalName" title="grids.residuesInTerminals.column-name" width="150px" />
          <GridColumn
            field="DateLastCollection"
            title="grids.residuesInTerminals.column-dateLastCollection"
            width="170px"
            cell={({ dataItem: { DateLastCollection } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastCollection, 'DD.MM.YYYY HH:mm')}</td>
            )}
            formatFilterCellData={(DateLastCollection) =>
              getTransformDateString(DateLastCollection, 'DD.MM.YYYY HH:mm')
            }
          />
          <GridColumn field="CapacityCashBox" title="grids.residuesInTerminals.column-capacityCashBox" width="210px" />
          <GridColumn field="Currency" title="grids.residuesInTerminals.column-currency" width="100px" />
          <GridColumn field="CashSum" title="grids.residuesInTerminals.column-cashSum" width="80px" />
          <GridColumn
            field="DateLastConnect"
            title="grids.residuesInTerminals.column-dateLastConnect"
            width="220px"
            cell={({ dataItem: { DateLastConnect } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastConnect, 'DD.MM.YYYY HH:mm')}</td>
            )}
            formatFilterCellData={(DateLastConnect) => getTransformDateString(DateLastConnect, 'DD.MM.YYYY HH:mm')}
          />
          <GridColumn
            field="DateLastTransaction"
            title="grids.residuesInTerminals.column-dateLastTransaction"
            width="180px"
            cell={({ dataItem: { DateLastTransaction } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastTransaction, 'DD.MM.YYYY HH:mm')}</td>
            )}
            formatFilterCellData={(DateLastTransaction) =>
              getTransformDateString(DateLastTransaction, 'DD.MM.YYYY HH:mm')
            }
          />
          <GridColumn field="Status" title="grids.residuesInTerminals.column-status" width="100px" />
        </Grid>
      </>
    );
  };
}
export default withTranslate(RemainingTerminals);
