import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import DealerCommissionForm from '../../../../components/DealerCommissionForm';
import DealerCommissionFormService from '../../../../components/DealerCommissionForm/dealerCommissionForm.service';

import './UpdateModal.scss';
import { commissionChangeTypes } from '../../../../core/constants/commissionChangeTypes';
import { COMMISSION_CHANGE_TYPE_CONFIG } from '../../../../core/constants/configs.const';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import LoadingPanel from '../../../../components/loader';
import { ApiService } from '../../../../services';

const api = new ApiService();

class UpdateModal extends PureComponent {
  state = {
    isLoading: false,
    selectedCommissionChangeType: [],
  };

  changeExpectedChange = async () => {
    const {
      translate,
      formState,
      onError,
      selectedItem: { OwnerId, ServiceId, DateStart, ServiceStatusId, PaymentTypeId },
      closeModal,
      onRefresh,
    } = this.props;
    const { selectedCommissionChangeType } = this.state;

    const {
      DealerCommissionPercent,
      DealerCommissionMin,
      DealerCommissionMax,
      DealerCommissionClientPercent,
      DealerCommissionClientMin,
      DealerCommissionClientMax,
      PaymentSystemCommissionPercent,
      PaymentSystemCommissionMin,
      PaymentSystemCommissionMax,
      PaymentSystemCommissionClientPercent,
      PaymentSystemCommissionClientMin,
      PaymentSystemCommissionClientMax,
    } = Object.entries(formState).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: DealerCommissionFormService.getParsedValue(value) }),
      {},
    );

    const globalError = DealerCommissionFormService.globalValidate(translate)(formState);
    onError('global', globalError);

    if (globalError) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      await api.serviceCommissionUpdate([
        {
          ownerId: OwnerId,
          serviceId: ServiceId,
          dateStart: DateStart,
          statusId: ServiceStatusId,
          paymentType: PaymentTypeId,
          operationId: selectedCommissionChangeType[0],
          dealerCommissionPercent: +DealerCommissionPercent,
          dealerCommissionMin: +DealerCommissionMin,
          dealerCommissionMax: +DealerCommissionMax,
          dealerCommissionClientPercent: +DealerCommissionClientPercent,
          dealerCommissionClientMin: +DealerCommissionClientMin,
          dealerCommissionClientMax: +DealerCommissionClientMax,
          paymentSystemCommissionPercent: +PaymentSystemCommissionPercent,
          paymentSystemCommissionMin: +PaymentSystemCommissionMin,
          paymentSystemCommissionMax: +PaymentSystemCommissionMax,
          paymentSystemCommissionClientPercent: +PaymentSystemCommissionClientPercent,
          paymentSystemCommissionClientMin: +PaymentSystemCommissionClientMin,
          paymentSystemCommissionClientMax: +PaymentSystemCommissionClientMax,
        },
      ]);
      await this.setState({ isLoading: false });
      closeModal();
      message.success(`${translate('page.dealerCommissionsExpectedChanges.successfulChanges')}`, 2.5);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { visible, translate, closeModal, formState, formErrors, onChangeFormState, onError, setErrorState } =
      this.props;
    const { selectedCommissionChangeType, isLoading } = this.state;

    const isDisabledByErrors = !!Object.values(formErrors).some((item) => item);

    return (
      <Modal
        visible={visible}
        title={translate('page.dealerCommissionsExpectedChanges.changingExpectedChanges')}
        onCancel={closeModal}
        footer={[
          <Button
            type="primary"
            disabled={isDisabledByErrors || !selectedCommissionChangeType.length}
            onClick={this.changeExpectedChange}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        className="DealerCommissionsExpectedChangesUpdateModal"
      >
        <div className="DealerCommissionsExpectedChangesUpdateModal-select">
          <GridDropdown
            data={commissionChangeTypes.map(({ Id, Name }) => ({ Id, Name: translate(Name) }))}
            colConfig={COMMISSION_CHANGE_TYPE_CONFIG}
            selectItems={selectedCommissionChangeType}
            onSave={(selectedCommissionChangeType) => {
              this.setState({ selectedCommissionChangeType });
            }}
            defaultTitle={`${translate('page.dealerCommissionsChange.selectChange')}`}
            isSingle
          />
        </div>

        <DealerCommissionForm
          formState={formState}
          formErrors={formErrors}
          onChangeFormState={onChangeFormState}
          onError={onError}
          setErrorState={setErrorState}
        />

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateModal);
