import PropTypes from 'prop-types';
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../contexts/localContext';
import { formatDate } from '@telerik/kendo-intl';
import { ZonedDate } from '@progress/kendo-date-math';
import '@progress/kendo-date-math/tz/Europe/Kiev';
import { Button } from 'antd';

/**
 * Cell date formater
 */

class CellDate extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  clickHandler = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(this.props);
    }
    return;
  };

  renderData = () => {
    const { field, dataItem, format } = this.props;
    const value = dataItem[field];
    let dt;

    if (value instanceof Date && !isNaN(value)) {
      const zonedDate = ZonedDate.fromLocalDate(value, 'Europe/Kiev');
      dt = formatDate(zonedDate, format);

      if (!+new Date(value)) {
        dt = '';
      }
    } else {
      dt = '';
    }

    return dt;
  };

  render() {
    const { mobile, onClick, className, style } = this.props;
    if (mobile) {
      if (onClick) {
        return (
          <Button className="lg-p-0 lg-w-100" type="link" onClick={this.clickHandler}>
            {this.renderData()}
            {/* {dt} */}
          </Button>
        );
      }
      return <>{this.renderData()}</>;
      // return (<>{dt}</>);
    }

    if (onClick) {
      return (
        <td className={`${className} lg-p-0`} style={{ ...style, zIndex: 4 }} onClick={this.clickHandler}>
          <Button className="lg-p-0 lg-w-100" type="link">
            {this.renderData()}
            {/* {dt} */}
          </Button>
        </td>
      );
    }

    return (
      <td className={className} style={{ ...style, zIndex: 4 }}>
        {this.renderData()}
      </td>
    );
    // return (<td>{dt}</td>);
  }
}

export default withTranslate(CellDate);
