class AddServiceService {
  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  integerNumberValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    return '';
  };

  getValidators = (translate) => {
    return {
      serviceId: this.integerNumberValidation(translate),
    };
  };

  normalizeSelectData = (data, fieldId, fieldName) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      Id: dataItem[fieldId],
      Name: dataItem[fieldName],
    }));
  };
}

export default new AddServiceService();
