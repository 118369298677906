class CardRoutingService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.cardRoutingList) {
      return { cardRoutingList: [], hasNextPage: false, hasPreviousPage: false };
    }

    const cardRoutingList = data.data.cardRoutingList;
    const pageInfo = cardRoutingList.pageInfo;

    return {
      cardRoutingList: cardRoutingList.items,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };

  parseField = (value) => (value || value === 0 ? value : value === null ? 'null' : undefined);

  getExtendedNormalizer = (firstFieldName, secondFieldName, thirdFieldName) => (dataList) => {
    if (!dataList || !dataList.length) {
      return [];
    }

    return dataList.map((dataItem) => ({
      ...dataItem,
      Id: this.parseField(dataItem[firstFieldName]),
      Name: this.parseField(dataItem[secondFieldName]),
      [thirdFieldName || 'CustomName']: this.parseField(dataItem[thirdFieldName || 'Name']),
    }));
  };

  getNormalizer = (firstFieldName, secondFieldName) => (dataList) => {
    if (!dataList || !dataList.length) {
      return [];
    }

    return dataList.map((dataItem) => ({
      ...dataItem,
      Id: this.parseField(dataItem[firstFieldName]),
      Name: this.parseField(dataItem[secondFieldName]),
    }));
  };

  mergeNullValueByField = (data, fieldName) => {
    if (fieldName === 'systemTypes') {
      return [
        ...data,
        {
          SystemType: null,
          Name: null,
        },
      ];
    }

    if (fieldName === 'panTypes') {
      return [
        ...data,
        {
          PanType: null,
          Name: null,
        },
      ];
    }

    if (fieldName === 'banks') {
      return [
        ...data,
        {
          BankServiceId: null,
          BankName: null,
        },
      ];
    }

    return data;
  };
}

export default new CardRoutingService();
