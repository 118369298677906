import React, { PureComponent } from 'react';
import { Button, Col, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import { withTranslate } from '../../../contexts/localContext';
import FormStateContainer from '../../../components/FormStateContainer';
import { DOCUMENT_TYPE_TRANSLATION_OBJECT } from './constants';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import AddSUODialog from './AddSUODialog';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

import './SUO.styled.scss';

const api = new ApiService();

class SUOGrid extends PureComponent {
  currentArray = findArray('blockedTransactions');

  state = {
    SUOList: [],
    isLoading: false,
    selectedItems: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchSUO();
  }

  getSUONormalizers = (user, translate) => ({
    ...user,
    Document:
      !user.DocumentType || user.DocumentType === 'None' || !DOCUMENT_TYPE_TRANSLATION_OBJECT[user.DocumentType]
        ? ''
        : `${translate(`page.blockedTransactions.${DOCUMENT_TYPE_TRANSLATION_OBJECT[user.DocumentType]}`)}: ${
            user.DocumentNumber
          }`,
  });

  fetchSUO = async () => {
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const SUOList = await api.getSUOList();
      this.setState({
        SUOList: SUOList && SUOList.length ? SUOList.map((user) => this.getSUONormalizers(user, translate)) : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="SUOGrid-toolbar">
        {checkElement('blockedTransactions-addSUO', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ modalVariant: 'add' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('blockedTransactions-deleteSUO', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'delete' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  deleteSUO = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.deleteSUO(selectedItems[0].EntryId);

      this.setState({
        isLoading: false,
        modalVariant: '',
      });
      message.success(translate('page.blockedTransactions.SUODeletedSuccessfully'), 2.5);
      this.fetchSUO();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  onSelect = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { SUOList, isLoading, modalVariant } = this.state;
    const { translate } = this.props;

    return (
      <>
        <Grid
          data={SUOList}
          isLoading={isLoading}
          onRefresh={this.fetchSUO}
          name="SUOGrid"
          toolbar={this.buildToolbar()}
          onSelect={this.onSelect}
          fieldForSelection="EntryId"
          allowWordWrap
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="EntryId" title="page.blockedTransactions.col-number" width="80" />
          <GridColumn field="NameUk" title="page.blockedTransactions.col-fullName" width="400" />
          <GridColumn field="TaxerId" title="page.blockedTransactions.col-INN" width="160" filter="numeric" />
          <GridColumn
            field="DateBd"
            title="page.blockedTransactions.col-birthday"
            width="160"
            filter="numeric"
            cell={({ dataItem: { DateBd } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateBd, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(DateBd) => getTransformDateString(DateBd, 'DD.MM.YYYY')}
          />
          <GridColumn
            field="Document"
            title="page.blockedTransactions.col-identityDocument"
            width="360"
            filter="numeric"
          />
          <GridColumn
            field="DocumentIssueDate"
            title="page.blockedTransactions.col-documentDate"
            width="120"
            filter="numeric"
            cell={({ dataItem: { DocumentIssueDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DocumentIssueDate, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(DocumentIssueDate) => getTransformDateString(DocumentIssueDate, 'DD.MM.YYYY')}
          />
          <GridColumn
            field="DocumentIssuingAuthority"
            title="page.blockedTransactions.col-issuingAuthority"
            width="400"
            filter="numeric"
          />
          <GridColumn
            field="Residence"
            title="page.blockedTransactions.col-placeResidence"
            width="600"
            filter="numeric"
          />
        </Grid>

        {modalVariant === 'add' && (
          <FormStateContainer
            initialValues={{
              NameUk: '',
              TaxerId: '',
              DateBd: new Date(),
              DocumentType: undefined,
              DocumentNumber: '',
              DocumentIssueDate: new Date(),
              DocumentIssuingAuthority: '',
              Residence: '',
            }}
          >
            <AddSUODialog
              visible={modalVariant === 'add'}
              closeModal={() => {
                this.setState({ modalVariant: '' });
              }}
              onRefresh={this.fetchSUO}
            />
          </FormStateContainer>
        )}

        {modalVariant === 'delete' && (
          <DeleteModal
            visible={modalVariant === 'delete'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            submitCallback={this.deleteSUO}
            deleteContent={translate('page.blockedTransactions.areYouSureDelete')}
          />
        )}
      </>
    );
  }
}

export default withTranslate(SUOGrid);
