import React, { PureComponent } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Modal, Button, Row, Col, Collapse, Typography, Result } from 'antd';

import { StorageService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import warning from '../../../pages/warning.svg';
import { FIELD_NAME_BY_LOCALE_MAP, MESSAGES_TRANSCRIPTIONS_MAP } from './constants';
import { widthExpiredPasswordDialogContext } from '../../../contexts/ExpiredPasswordDialogContext';
import { widthLoginContext } from '../../../contexts/LoginContext';
import { widthErrorsCodesContext } from '../../../contexts/ErrorCodesContext';

import './dialogError.scss';

const { Panel } = Collapse;
const { Title } = Typography;

class DialogError extends PureComponent {
  onOk = () => {
    const {
      error: { callback: onClose, core: errorCore },
      history,
      setLoginContentStateIndex,
      setNumberDraysBeforePasswordExpires,
    } = this.props;

    const { search } = history.location;

    const oldPassword = search.split('oldPassword=')[1];

    if (oldPassword) {
      onClose();
      return;
    }

    if (errorCore && errorCore.customAcceptCallback) {
      errorCore.customAcceptCallback();
      onClose();
      return;
    }

    onClose();

    const isErrorMessageExist =
      errorCore && errorCore.response && errorCore.response.data && errorCore.response.data.Message;

    if (isErrorMessageExist && errorCore.response.data.Message === 'PASSWORD_EXPIRED_ERROR') {
      StorageService.clean();
      setLoginContentStateIndex(0);
      history.push('/login');

      return;
    }

    if (
      isErrorMessageExist &&
      (errorCore.response.data.Message === 'USER_NAME_INVALID' ||
        errorCore.response.data.Message === 'PASSWORD_INVALID')
    ) {
      // StorageService.removeItem('devideCode');
      StorageService.clean();
      // setLoginContentStateIndex(0);
      history.push('/login');

      return;
    }

    if (isErrorMessageExist && errorCore.response.data.Message === 'REFRESH_TOKEN_NOT_FOUND') {
      StorageService.clean();
      setLoginContentStateIndex(0);
      history.push('/login');

      return;
    }

    if (errorCore && errorCore.response && (errorCore.response.status === 401 || errorCore.response.status === 403)) {
      StorageService.clean();
      setLoginContentStateIndex(0);
      history.push('/login');

      return;
    }

    if (!StorageService.getItem('access_token') || !StorageService.getItem('refresh_token')) {
      StorageService.clean();
      setLoginContentStateIndex(0);
      history.push('/login');

      return;
    }
  };

  getException = () => {
    const {
      error: { core: errorResponse },
    } = this.props;

    if (errorResponse instanceof Error) {
      const stackTrace = errorResponse.stack ? errorResponse.stack.split('\n').slice(1).join('\n') : '';

      if (
        errorResponse &&
        errorResponse.response &&
        errorResponse.response.data &&
        errorResponse.response.data.Message
      ) {
        return {
          Message: errorResponse.response.data.Message,
          Source: 'Js error',
          StackTrace: stackTrace,
        };
      }

      if (StorageService.getItem('refresh_token') === null) {
        return {
          Message: 'REFRESH_TOKEN_NOT_FOUND',
          Source: 'Js error',
          StackTrace: stackTrace,
        };
      }

      return {
        Message: errorResponse && errorResponse.message,
        Source: 'Js error',
        StackTrace: stackTrace,
      };
    }

    return errorResponse && errorResponse.data;
  };

  getMessage = (exception) => {
    const { translate, errorsCodes } = this.props;
    if (exception && exception.Login && exception.ErrorCode) {
      return `${translate('page.addUser.notAllowedRoleErrorPrefix')} ${exception.Login} ${translate(
        'page.addUser.notAllowedRoleErrorPostfix',
      )}<br/>${exception.ErrorCode}`;
    }

    if (exception && exception.errors && exception.errors.length) {
      return exception.errors.reduce(
        (acc, err) =>
          (err.message ? `${acc}; ${err.message}` : `${acc}; ${translate('errorMessages.unknownError')}`).slice(2),
        '',
      );
    }

    if (!exception || !exception.Message) {
      return translate('errorMessages.unknownError');
    }

    if (MESSAGES_TRANSCRIPTIONS_MAP.get(exception.Message)) {
      return translate(MESSAGES_TRANSCRIPTIONS_MAP.get(exception.Message));
    }

    const errorsMessageFromErrorsCodes = errorsCodes.find(({ Code }) => Code === exception.Message);

    if (errorsMessageFromErrorsCodes) {
      const locale = StorageService.getItem('locale');
      return errorsMessageFromErrorsCodes[locale ? FIELD_NAME_BY_LOCALE_MAP.get(locale || 'ru') : 'RU'];
    }

    return exception.Message;
  };

  render() {
    const {
      translate,
      error,
      setLoginContentStateIndex,
      error: { callback: onClose },
      history: {
        location: { pathname },
      },
    } = this.props;

    const isErrorMessageExist =
      error.core && error.core.response && error.core.response.data && error.core.response.data.Message;

    const status =
      error.core && error.core.status
        ? error.core.status
        : error.core && error.core.response && error.core.response.status;

    const isAuthorizationException =
      (isErrorMessageExist && error.core.response.data.Message === 'REFRESH_TOKEN_NOT_FOUND') ||
      status === 401 ||
      status === 403 ||
      !StorageService.getItem('access_token') ||
      !StorageService.getItem('refresh_token');

    if (isAuthorizationException && pathname !== '/login') {
      StorageService.clean();
      setLoginContentStateIndex(0);
      onClose();

      return <Redirect to="/login" />;
    }

    const exception = this.getException();

    return (
      <>
        <Modal
          centered
          title={translate('profile.confirm.title')}
          visible={error}
          zIndex={'1054'}
          className="modal-error"
          onOk={this.onOk}
          onCancel={this.onOk}
          footer={[
            <Button
              key="submit"
              className="modal-profile--submit"
              type="primary"
              onClick={this.onOk}
              style={{ width: '100%', backgroundColor: '#ffffff', color: '#1890ff' }}
            >
              Ok
            </Button>,
          ]}
        >
          {window.innerWidth > 991 ? (
            <Result status="500" style={{ padding: '7px' }} />
          ) : (
            <img style={{ width: '70px', color: 'red' }} alt="warning" src={warning} />
          )}
          <Row>
            <Col>
              <Title level={4}>{translate('messageDialog.label-message')}</Title>
            </Col>
            <Col>
              <p dangerouslySetInnerHTML={{ __html: this.getMessage(exception) }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Title level={4}>{translate('messageDialog.label-exception')}</Title>
            </Col>
            <Col>
              <p>{exception && exception.Source}</p>
            </Col>
          </Row>
          <Collapse bordered={false} expandIconPosition="right">
            <Panel
              style={{ borderBottom: 'none' }}
              header={
                <Title style={{ marginLeft: '-16px' }} level={4}>
                  {translate('messageDialog.label-trace')}
                </Title>
              }
            >
              <pre style={{ maxHeight: '150px' }}>{exception && exception.StackTrace}</pre>
            </Panel>
          </Collapse>
        </Modal>
      </>
    );
  }
}

export default withRouter(
  widthErrorsCodesContext(widthExpiredPasswordDialogContext(withTranslate(widthLoginContext(DialogError)))),
);
