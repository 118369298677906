import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import ManagementPaymentTypes from './ManagmentPaymentTypes';
import ManagementPaymentTypesHistory from './ManagmentPaymentTypesHistory';

class ManagementPaymentTypesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-managementPaymentTypes',
          content: <ManagementPaymentTypes />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'history':
        tabs.push({
          title: `${translate('page.managementPaymentTypes.changesHistory')}`,
          content: <ManagementPaymentTypesHistory />,
          closable: true,
          key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}
export default withTranslate(ManagementPaymentTypesPage);
