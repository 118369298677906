import React, { PureComponent } from 'react';
import { message } from 'antd';

import UpdateCommission from './UpdateCommission';
import CommissionDetailsFormService from '../../../components/CommisionDetailsForm/services/commissionDetailsFormService';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class CommissionDetailsFormContainer extends PureComponent {
  state = {
    isLoading: false,
  };

  async componentDidMount() {
    const { passedFormState, setFormState, handleValidateAllField } = this.props;

    if (passedFormState) {
      await setFormState(passedFormState);
      await handleValidateAllField();
    }
  }

  async componentDidUpdate(prevProps) {
    const { passedFormState: prevPassedFormState } = prevProps;
    const { passedFormState, setFormState, handleValidateAllField } = this.props;

    if (
      prevPassedFormState &&
      passedFormState &&
      prevPassedFormState.CommissionName !== passedFormState.CommissionName
    ) {
      await setFormState(passedFormState);
      await handleValidateAllField();
    }
  }

  handleUpdateCommission = async () => {
    const { showError, formState, validateOnSubmit, translate } = this.props;

    const {
      TimeFrom,
      TimeTo,
      AmountTo,
      AmountFrom,
      ResultFixed,
      ResultPercent,
      CommissionMin,
      CommissionMax,
      ValidDateFrom,
      ValidDateTo,
      Priority,
      Active,
    } = formState;

    await this.setState({ isLoading: true });

    const errorMessage = validateOnSubmit();

    if (errorMessage) {
      message.error(errorMessage, 3);
      this.setState({ isLoading: false });
      return;
    }

    const model = {
      CommissionId: formState.CommissionId,
      Name: CommissionDetailsFormService.getCommissionName(formState),
      TimeFrom: getDataByTimezoneOffset(TimeFrom),
      TimeTo: getDataByTimezoneOffset(TimeTo),
      AmountTo: AmountTo,
      AmountFrom: AmountFrom,
      FixedCommission: ResultFixed || 0,
      PercentCommission: ResultPercent || 0,
      CommissionMin,
      CommissionMax,
      Priority,
      ValidFrom: getDataByTimezoneOffset(ValidDateFrom),
      ValidTo: getDataByTimezoneOffset(ValidDateTo),
      Active,
    };

    try {
      const { CommissionName } = await api.commissionsClientUpdate(model);
      await message.success(
        `${translate('grids.comission-sub.successEditCommissionMessagePrefix')} "${CommissionName}" ${translate(
          'grids.comission-sub.successEditCommissionMessagePostfix',
        )}`,
        5,
      );
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <UpdateCommission handleUpdateCommission={this.handleUpdateCommission} {...this.props} />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(CommissionDetailsFormContainer);
