import { MaskedInput } from 'antd-mask-input';
import React, { PureComponent } from 'react';

import { withTranslate } from '../../../contexts/localContext';

class ChequeNumberMaskedInput extends PureComponent {
  state = {
    numberMask: '00000-0000000-00000',
  };
  handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      const cursorPosition = event.currentTarget.selectionStart;
      if (cursorPosition === 14 || cursorPosition === 13 || cursorPosition === 12) {
        this.setState({ numberMask: '00000-0000000-00000' });
      }
    }
    if (event.key === 'ArrowRight') {
      const cursorPosition = event.currentTarget.selectionStart;
      if (cursorPosition === 10) {
        this.setState({ numberMask: '00000-0000-00000' });
        return;
      }
      if (cursorPosition === 11) {
        this.setState({ numberMask: '00000-00000-00000' });
        return;
      }
      if (cursorPosition === 12) {
        this.setState({ numberMask: '00000-000000-00000' });
      }
    }
  };
  render() {
    const { value, placeholder, onChange } = this.props;

    return (
      <MaskedInput
        value={value}
        style={{ width: '100%', marginTop: '10px' }}
        placeholder={placeholder}
        className="transaction-input"
        onChange={onChange}
        onKeyDown={this.handleKeyDown}
        mask={this.state.numberMask}
      />
    );
  }
}

export default withTranslate(ChequeNumberMaskedInput);
