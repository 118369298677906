import React, { PureComponent } from 'react';
import { Button, Card, message } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { translateSlugs, defaultTitleTranslateSlugs, CONFIGS } from './constants';
import CustomInput from '../../../../components/Input/Input';
import AddServiceService from './AddService.service';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';
import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import UpdateServiceTypeModal from '../UpdateServiceTypeModal';
import ChangeDealerCommissions from '../../../../components/ChangeDealerCommissions/ChangeDealerCommissions';
import { getCustomDate } from '../../../../utils/getCustomDate';
import { getDataByTimezoneOffset } from '../../../../utils/getDataByTimezoneOffset';
import SupplierCommissions from '../SimpleSupplierСommissionsForm';

import './AddService.styled.scss';

const api = new ApiService();

class AddService extends PureComponent {
  constructor(props) {
    super(props);
    this.requestStatus = React.createRef();

    this.requestStatus.current = {
      createService: undefined,
      supplierCommission: undefined,
      dealerCommission: undefined,
    };

    this.state = {
      isLoading: false,
      serviceTypeList: [],
      transitServiceList: [],
      dialogVariant: '',
      disableBlockList: [],
    };
  }

  componentDidMount() {
    this.initializeCallback();
  }

  initializeCallback = async () => {
    await this.setState({ isLoading: true });
    await this.getCurrentServiceId();
    await this.fetchServiceTypes();
    await this.fetchOwners();
    await this.setState({ isLoading: false });
  };

  getCurrentServiceId = async () => {
    const { onChangeFormState } = this.props;
    try {
      const currentServiceId = await api.getNextServiceId();
      await onChangeFormState('serviceId', currentServiceId);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchServiceTypes = async () => {
    try {
      const serviceTypes = await api.getServicesTypes();

      this.setState({ serviceTypeList: AddServiceService.normalizeSelectData(serviceTypes, 'TypeId', 'Name') });
    } catch (error) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      const data = await api.owners();
      await this.setState({ transitServiceList: DropdownNormalizersService.normalizeOwners(data) });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getSelectChangeCallback = (fieldName) => (selectItems) => {
    const { onChangeFormState } = this.props;

    onChangeFormState(fieldName, selectItems);
  };

  renderInput = ({ name, isShortLabel }) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="AddServiceByBigTable-inputRow">
        {translateSlugs[name] && (
          <div className={`${isShortLabel ? 'AddServiceByBigTable-shortLabel' : 'AddServiceByBigTable-label'}`}>
            {translate(translateSlugs[name])}
          </div>
        )}
        <div className="AddServiceByBigTable-input">
          <CustomInput
            type={name === 'isTransitService' ? 'checkbox' : 'default'}
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            setError={onError}
            validators={AddServiceService.getValidators(translate)}
            name={name}
          />
        </div>
      </div>
    );
  };

  renderSelect = ({ name, isDisabled }) => {
    const { formState, translate } = this.props;

    return (
      <div className="AddServiceByBigTable-inputRow">
        <div className="AddServiceByBigTable-label">{translate(translateSlugs[name])}</div>
        <div className="AddServiceByBigTable-input">
          <GridDropdown
            data={this.state[`${name}List`]}
            colConfig={CONFIGS[name]}
            selectItems={formState[name]}
            onSave={this.getSelectChangeCallback(name)}
            defaultTitle={translate(defaultTitleTranslateSlugs[name])}
            disabled={isDisabled}
            isSingle
          />
        </div>
      </div>
    );
  };

  handleAddService = async () => {
    const {
      formState: { commissionMin, commissionMax, commissionCardMin, commissionCardMax, serviceName },
      translate,
    } = this.props;

    if (AddServiceService.getParsedValue(commissionMin) - AddServiceService.getParsedValue(commissionMax) > 0) {
      message.error(translate('page.addServiceByBigTable.supplierCommissionsDifferenceError'), 2.5);
      return;
    }

    if (AddServiceService.getParsedValue(commissionCardMin) - AddServiceService.getParsedValue(commissionCardMax) > 0) {
      message.error(translate('page.addServiceByBigTable.supplierCardCommissionsDifferenceError'), 2.5);
      return;
    }

    await this.setState({ isLoading: true });

    if (!this.requestStatus.current || this.requestStatus.current.createService !== 'successful') {
      try {
        const isUnique = await api.serviceNameCheck(serviceName);

        if (!isUnique) {
          message.error(translate(`page.addServiceByBigTable.specifiedServiceNameAlreadyUse`), 3);
          this.setState({ isLoading: false });
          return;
        }
      } catch (e) {
        const { showError } = this.props;
        this.setState({ isLoading: false });
        showError(e);
        return;
      }
    }

    const currentServiceId = await this.handleAddCoreService();

    await this.handleAddSupplierCommission(currentServiceId);

    await this.handleAddServiceCommission(currentServiceId);

    await this.setState({ isLoading: false });

    if (
      this.requestStatus.current &&
      Object.values(this.requestStatus.current).every((requestStatus) => requestStatus === 'successful')
    ) {
      await message.success(translate(`page.addServiceByBigTable.serviceSuccessfullyCreated`), 2);
      window.location.reload();
    } else {
      this.setState({
        disableBlockList: this.requestStatus.current
          ? Object.entries(this.requestStatus.current)
              .filter(([_, value]) => value === 'successful')
              .map(([key, _]) => key)
          : [],
      });
    }
  };

  handleAddCoreService = async () => {
    const {
      formState: { serviceId, serviceName, serviceType, transitService, isTransitService },
      translate,
      onChangeFormState,
    } = this.props;

    if (this.requestStatus.current && this.requestStatus.current.createService === 'successful') {
      return;
    }

    try {
      const currentServiceId = await api.addService({
        serviceId,
        typeId: serviceType[0],
        name: serviceName,
        description: '',
        mnemonics: '',
        amount: null,
        amountMin: null,
        amountMax: null,
        amountOrig: null,
        currency: 'UAH',
        statusId: 2,
        paymentProfileId: null,
        moneyTransfer: true,
        supplierId: null,
        commissionClient: null,
        transitService: isTransitService,
        transitOwnerId: isTransitService ? transitService[0] : null,
      });

      this.requestStatus.current = { ...this.requestStatus.current, createService: 'successful' };
      await onChangeFormState('serviceId', currentServiceId);
      return currentServiceId;
    } catch (e) {
      const { showError } = this.props;
      this.requestStatus.current = { ...this.requestStatus.current, createService: 'failed' };
      showError({
        ...e,
        data: e.data
          ? {
              ...e.data,
              Message: e.data.Message
                ? `${translate('page.addServiceByBigTable.serviceError')}: ${e.data.Message}`
                : '',
            }
          : null,
      });
      await this.setState({ isLoading: false });
    }
  };

  handleAddSupplierCommission = async (serviceIdFromProps) => {
    const {
      formState: {
        commissionPercent,
        commissionMin,
        commissionMax,
        commissionCalculationBase,
        commissionCardPercent,
        commissionCardMin,
        commissionCardMax,
        commissionCardCalculationBase,
        serviceId,
      },
      translate,
    } = this.props;

    const isRequestAllow =
      this.requestStatus.current &&
      this.requestStatus.current.createService === 'successful' &&
      (!this.requestStatus.current.supplierCommission || this.requestStatus.current.supplierCommission === 'failed');

    if (!isRequestAllow) {
      return;
    }

    try {
      await api.serviceSupplierCommissionUpdate({
        serviceId: serviceIdFromProps || serviceId,
        dateStart: getDataByTimezoneOffset(
          getCustomDate({
            customDayOfMonth: new Date().getDate() + 1,
          }),
        ).toISOString(),
        supplierProviderAmountTotal: commissionCalculationBase,
        supplierProviderPercent: AddServiceService.getParsedValue(commissionPercent),
        supplierProviderMin: AddServiceService.getParsedValue(commissionMin),
        supplierProviderMax: AddServiceService.getParsedValue(commissionMax),
        supplierCardProviderAmountTotal: commissionCardCalculationBase,
        supplierCardProviderPercent: AddServiceService.getParsedValue(commissionCardPercent),
        supplierCardProviderMin: AddServiceService.getParsedValue(commissionCardMin),
        supplierCardProviderMax: AddServiceService.getParsedValue(commissionCardMax),
      });

      this.requestStatus.current = { ...this.requestStatus.current, supplierCommission: 'successful' };
    } catch (e) {
      const { showError } = this.props;
      this.requestStatus.current = { ...this.requestStatus.current, supplierCommission: 'failed' };
      showError({
        ...e,
        data: e.data
          ? {
              ...e.data,
              Message: e.data.Message
                ? `${translate('page.addServiceByBigTable.supplierCommissionError')}: ${e.data.Message}`
                : '',
            }
          : null,
      });
      await this.setState({ isLoading: false });
    }
  };

  handleAddServiceCommission = async (serviceIdFromProps) => {
    const {
      formState: { commissionOperations, serviceId },
      translate,
    } = this.props;

    const isRequestAllow =
      this.requestStatus.current &&
      this.requestStatus.current.createService === 'successful' &&
      (!this.requestStatus.current.dealerCommission || this.requestStatus.current.dealerCommission === 'failed');

    if (!isRequestAllow) {
      return;
    }

    const model = commissionOperations.map((rules) => ({
      ...rules,
      ownerId: 0,
      serviceId: serviceIdFromProps || serviceId,
      statusId: 2,
    }));

    try {
      await api.serviceCommissionUpdate(model);

      this.requestStatus.current = { ...this.requestStatus.current, dealerCommission: 'successful' };
    } catch (e) {
      const { showError } = this.props;
      this.requestStatus.current = { ...this.requestStatus.current, dealerCommission: 'failed' };
      showError({
        ...e,
        data: e.data
          ? {
              ...e.data,
              Message: e.data.Message
                ? `${translate('page.addServiceByBigTable.serviceCommissionError')}: ${e.data.Message}`
                : '',
            }
          : null,
      });
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, dialogVariant, serviceTypeList, disableBlockList } = this.state;
    const { translate, onChangeFormState, formState, formErrors, onError } = this.props;

    const isErrorExist = Object.values(formErrors).some((errorMessage) => !!errorMessage);
    const isValueEmpty = Object.entries(formState).some(([key, value]) => {
      if (key === 'commissionOperations' || key === 'serviceType') {
        return !value.length;
      }

      if (key === 'transitService') {
        return formState.isTransitService && !value.length;
      }

      return key !== 'isTransitService' && !value && value !== 0;
    });

    return (
      <div className="AddServiceByBigTable">
        <Card type="inner" className="AddServiceByBigTable-card">
          {disableBlockList.includes('createService') && <div className="AddServiceByBigTable-overlay" />}

          {this.renderInput({ name: 'serviceId' })}
          {this.renderInput({ name: 'serviceName' })}
          <div className="AddServiceByBigTable-rowWithAction">
            {this.renderSelect({ name: 'serviceType' })}
            <div className="AddServiceByBigTable-action">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dialogVariant: 'addServiceType' });
                }}
              >
                ...
              </Button>
            </div>
          </div>
          <div className="AddServiceByBigTable-rowWithAction">
            {this.renderSelect({ name: 'transitService', isDisabled: !formState.isTransitService })}
            <div className="AddServiceByBigTable-action">{this.renderInput({ name: 'isTransitService' })}</div>
          </div>
        </Card>

        <Card type="inner" className="AddServiceByBigTable-card">
          {disableBlockList.includes('supplierCommission') && <div className="AddServiceByBigTable-overlay" />}

          <SupplierCommissions
            formState={formState}
            onChangeFormState={onChangeFormState}
            formErrors={formErrors}
            onError={onError}
          />
        </Card>

        <div className="AddServiceByBigTable-addServiceCommission">
          {disableBlockList.includes('dealerCommission') && <div className="AddServiceByBigTable-overlay" />}

          <ChangeDealerCommissions
            onOperationUpdate={(operations) => {
              onChangeFormState('commissionOperations', operations);
            }}
          />
        </div>

        <Button type="primary" disabled={isErrorExist || isValueEmpty} onClick={this.handleAddService}>
          {translate('core.buttonTitles.save')}
        </Button>

        {isLoading && <LoadingPanel />}

        {dialogVariant === 'addServiceType' && (
          <UpdateServiceTypeModal
            visible={dialogVariant === 'addServiceType'}
            serviceTypeList={serviceTypeList.map(({ Name }) => Name)}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            fetchServiceTypes={async () => {
              await this.setState({ isLoading: true });
              await this.fetchServiceTypes();
              await this.setState({ isLoading: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default withTranslate(AddService);
