import { OWNERS_COLUMN_CONFIG } from '../../../../core/constants/configs.const';

export const INITIAL_VALUES = {
  serviceId: '',
  serviceName: '',
  serviceType: [],
  transitService: [],
  isTransitService: false,
  commissionPercent: 0,
  commissionMin: 0,
  commissionMax: 0,
  commissionCalculationBase: '[amount_total]',
  commissionCardPercent: 0,
  commissionCardMin: 0,
  commissionCardMax: 0,
  commissionCardCalculationBase: '[amount_total]',
  commissionOperations: [],
};

export const translateSlugs = {
  serviceId: 'page.serviceMainProps.serviceNumber',
  serviceName: 'page.serviceMainProps.nameService',
  serviceType: 'page.serviceMainProps.typeService',
  transitService: 'page.serviceMainProps.transitService',
};

export const defaultTitleTranslateSlugs = {
  serviceType: 'page.serviceMainProps.selectTypeService',
  transitService: 'page.dealerCommissions.selectDealer',
};

const SERVICE_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.serviceMainProps.typeService',
    width: '140',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.serviceMainProps.col-description',
    width: '200',
  },
];

export const CONFIGS = {
  serviceType: SERVICE_TYPES_COLUMN_CONFIG,
  transitService: OWNERS_COLUMN_CONFIG,
};
