import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import './contacts.scss';
import { Card } from 'antd';
import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';

const api = new ApiService();

class PageContacts extends PureComponent {
  state = {
    isLoading: false,
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  render() {
    const { translate } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="page-contacts">
        <Card type="inner" title={translate('page.contacts.main-title')}>
          <p className="mt-10 mb-10">{translate('page.contacts.label-title')}</p>
          <ul>
            <li>
              <p className="mb-10">
                {/* <span className="k-icon k-i-clock"></span> */}
                <span>{translate('page.contacts.label-schedule')}:</span>
                <span className="ml-5">{translate('page.contacts.label-scheduleInfo1')}</span>
              </p>
            </li>
            <li>
              <p className="mb-10">
                {/* <img alt="phone" src="/images/phone.svg" /> */}
                <span>{translate('page.contacts.label-phone')}</span>
                <a className="ml-5" href="tel:0800604561">
                  0 800 60 30 03
                </a>
                {/* <i>{translate('page.contacts.label-free')}</i> */}
              </p>
            </li>
            <li>
              <p className="mb-10">
                {/* <span className="k-icon k-i-email"></span> */}
                <span>{translate('page.contacts.label-email')}</span>
                <a className="ml-5" href="mailto:support@easypay.ua">
                  support@easypay.ua
                </a>
                {/* ,
                <a className="ml-5" href="mailto:sales@easypay.ua">sales@easypay.ua</a> */}
              </p>
            </li>
            <li>
              <p className="mb-10">
                {/* <img alt="phone" src="/images/viber.svg" /> */}
                <span>{translate('page.contacts.label-viber')}</span>
                {/* <a href="viber://add?number=%2B380675249649">067 524 9649</a> */}
                <a className="ml-5" href="viber://chat?number=+380675249649">
                  067 52 49 649
                </a>
                <span className="ml-5">{translate('page.contacts.label-viber-time')}</span>
              </p>
            </li>
            <li>
              <p className="mb-10">
                {/* <img alt="phone" src="/images/viber.svg" /> */}
                <span>{translate('page.contacts.label-tech-master')}</span>
                {/* <a href="viber://add?number=%2B380675249649">067 524 9649</a> */}
                <a className="ml-5" href="tel:+0800604561">
                  0 800 60 45 61
                </a>
              </p>
            </li>
            <li>
              <p className="mb-10">
                {/* <img alt="phone" src="/images/viber.svg" /> */}
                <span>{translate('page.contacts.label-address')}</span>
                {/* <a href="viber://add?number=%2B380675249649">067 524 9649</a> */}
                <span className="ml-5">{translate('page.contacts.label-location')}</span>
              </p>
            </li>
          </ul>
          {isLoading && <LoadingPanel />}
        </Card>
      </div>
    );
  }
}

export default withTranslate(PageContacts);
