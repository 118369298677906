const terminalOperabilityPeriod = (translate, value) => {
  switch (value) {
    case 'terminalOperabilityPeriodChoose-selected':
    case 'terminalOperabilityDayChoose-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'terminalOperabilityPeriodChoose-OwnerName':
    case 'terminalOperabilityDayChoose-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-ownerName')}`;
    case 'terminalOperabilityPeriodChoose-TerminalId':
    case 'terminalOperabilityDayChoose-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-terminalId')}`;
    case 'terminalOperabilityPeriodChoose-TerminalName':
    case 'terminalOperabilityDayChoose-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-name')}`;
    case 'terminalOperabilityPeriodChoose-NetworkContractName':
    case 'terminalOperabilityDayChoose-NetworkContractName':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-networkContract')}`;
    case 'terminalOperabilityPeriodChoose-RegionName':
    case 'terminalOperabilityDayChoose-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
          ${translate('grids.terminalOperabilityChoose.column-region')}`;
    case 'terminalOperabilityPeriodChoose-CityName':
    case 'terminalOperabilityDayChoose-CityName':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-city')}`;
    case 'terminalOperabilityPeriodChoose-TerminalAddress':
    case 'terminalOperabilityDayChoose-TerminalAddress':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-address')}`;
    case 'terminalOperabilityPeriodChoose-StatusName':
    case 'terminalOperabilityDayChoose-StatusName':
      return `${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-status')}`;
    case 'terminalOperabilityPeriodChoose-isMonthDropdown':
      return `${translate(`page.adminPanel.custom-dropdown-show`)}:
        ${translate('grids.terminalOperabilityChoose.dropdown-month')}`;
    case 'terminalOperabilityDayChoose-isDateDropdown':
      return `${translate(`page.adminPanel.custom-dropdown-show`)}:
          ${translate('grids.terminalOperabilityChoose.dropdown-day')}`;
    case 'terminalOperabilityPeriodChoose-isOperabilityDropdown':
    case 'terminalOperabilityDayChoose-isOperabilityDropdown':
      return `${translate(`page.adminPanel.custom-dropdown-show`)}:
          ${translate('grids.terminalOperabilityChoose.dropdown-operability')}`;
    case 'terminalOperabilityPeriodChoose-btn-search':
    case 'terminalOperabilityDayChoose-btn-search':
      return `${translate(`page.adminPanel.type-button`)}:
        ${translate('owner.search')}`;
    case 'terminalOperabilityPeriod-OwnerName':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-ownerName')}`;
    case 'terminalOperabilityPeriod-TerminalId':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-terminalId')}`;
    case 'terminalOperabilityPeriod-TerminalName':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-name')}`;
    case 'terminalOperabilityPeriod-Address':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-address')}`;
    case 'terminalOperabilityPeriod-CityName':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-city')}`;
    case 'terminalOperabilityPeriod-NetworkContractName':
      return `${translate('page.adminPanel.type-tab')}:
          ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
          ${translate(`page.adminPanel.type-column`)}:
          ${translate('grids.terminalOperabilityChoose.column-networkContract')}`;
    case 'terminalOperabilityPeriod-StatusName':
      return `${translate('page.adminPanel.type-tab')}:
        ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
        ${translate(`page.adminPanel.type-column`)}:
        ${translate('grids.terminalOperabilityChoose.column-status')}`;
    case 'terminalOperabilityPeriod-Day1':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                1`;
    case 'terminalOperabilityPeriod-Day2':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                2`;
    case 'terminalOperabilityPeriod-Day3':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                3`;
    case 'terminalOperabilityPeriod-Day4':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                4`;
    case 'terminalOperabilityPeriod-Day5':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                5`;
    case 'terminalOperabilityPeriod-Day6':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                6`;
    case 'terminalOperabilityPeriod-Day7':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                7`;
    case 'terminalOperabilityPeriod-Day8':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                8`;
    case 'terminalOperabilityPeriod-Day9':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                9`;
    case 'terminalOperabilityPeriod-Day10':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                10`;
    case 'terminalOperabilityPeriod-Day11':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                11`;
    case 'terminalOperabilityPeriod-Day12':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                12`;
    case 'terminalOperabilityPeriod-Day13':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                13`;
    case 'terminalOperabilityPeriod-Day14':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                14`;
    case 'terminalOperabilityPeriod-Day15':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                15`;
    case 'terminalOperabilityPeriod-Day16':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                16`;
    case 'terminalOperabilityPeriod-Day17':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                17`;
    case 'terminalOperabilityPeriod-Day18':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                18`;
    case 'terminalOperabilityPeriod-Day19':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                19`;
    case 'terminalOperabilityPeriod-Day20':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                20`;
    case 'terminalOperabilityPeriod-Day21':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                21`;
    case 'terminalOperabilityPeriod-Day22':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                22`;
    case 'terminalOperabilityPeriod-Day23':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                23`;
    case 'terminalOperabilityPeriod-Day24':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                24`;
    case 'terminalOperabilityPeriod-Day25':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                25`;
    case 'terminalOperabilityPeriod-Day26':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                26`;
    case 'terminalOperabilityPeriod-Day27':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                27`;
    case 'terminalOperabilityPeriod-Day28':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                28`;
    case 'terminalOperabilityPeriod-Day29':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                29`;
    case 'terminalOperabilityPeriod-Day30':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                30`;
    case 'terminalOperabilityPeriod-Day31':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                31`;
    case 'terminalOperabilityPeriod-WorkTotal':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.operability')}`;
    case 'terminalOperabilityPeriod-Branch':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-branch')}`;
    case 'terminalOperabilityPeriod-Note':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('page.terminalSettings.field-notes')}`;
    case 'terminalOperabilityPeriod-createDayTab':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operaility-main')},
                ${translate(`page.adminPanel.type-navLink`)}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')} `;
    case 'terminalOperabilityPeriodDay-TerminalId':
    case 'terminalOperabilityDay-TerminalId':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.column-terminalId')} `;
    case 'terminalOperabilityPeriodDay-Branch':
    case 'terminalOperabilityDay-Branch':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminals.column-branch')} `;
    case 'terminalOperabilityPeriodDay-Note':
    case 'terminalOperabilityDay-note':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('page.terminalSettings.field-notes')} `;
    case 'terminalOperabilityPeriodDay-TerminalName':
    case 'terminalOperabilityDay-TerminalName':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.column-name')} `;
    case 'terminalOperabilityPeriodDay-Address':
    case 'terminalOperabilityDay-Address':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.column-address')} `;
    case 'terminalOperabilityPeriodDay-StatusName':
    case 'terminalOperabilityDay-StatusName':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.column-status')} `;
    case 'terminalOperabilityPeriodDay-CityName':
    case 'terminalOperabilityDay-CityName':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.column-city')} `;
    case 'terminalOperabilityPeriodDay-Hour00':
    case 'terminalOperabilityDay-Hour00':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:00-01`;
    case 'terminalOperabilityPeriodDay-Hour01':
    case 'terminalOperabilityDay-Hour01':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:01-02`;
    case 'terminalOperabilityPeriodDay-Hour02':
    case 'terminalOperabilityDay-Hour02':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:02-03`;
    case 'terminalOperabilityPeriodDay-Hour03':
    case 'terminalOperabilityDay-Hour03':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:03-04`;
    case 'terminalOperabilityPeriodDay-Hour04':
    case 'terminalOperabilityDay-Hour04':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:04-05`;
    case 'terminalOperabilityPeriodDay-Hour05':
    case 'terminalOperabilityDay-Hour05':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:05-06`;
    case 'terminalOperabilityPeriodDay-Hour06':
    case 'terminalOperabilityDay-Hour06':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:06-07`;
    case 'terminalOperabilityPeriodDay-Hour07':
    case 'terminalOperabilityDay-Hour07':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:07-08`;
    case 'terminalOperabilityPeriodDay-Hour08':
    case 'terminalOperabilityDay-Hour08':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:08-09`;
    case 'terminalOperabilityPeriodDay-Hour09':
    case 'terminalOperabilityDay-Hour09':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:09-10`;
    case 'terminalOperabilityPeriodDay-Hour10':
    case 'terminalOperabilityDay-Hour10':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:10-11`;
    case 'terminalOperabilityPeriodDay-Hour11':
    case 'terminalOperabilityDay-Hour11':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:11-12`;
    case 'terminalOperabilityPeriodDay-Hour12':
    case 'terminalOperabilityDay-Hour12':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:12-13`;
    case 'terminalOperabilityPeriodDay-Hour13':
    case 'terminalOperabilityDay-Hour13':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:13-14`;
    case 'terminalOperabilityPeriodDay-Hour14':
    case 'terminalOperabilityDay-Hour14':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:14-15`;
    case 'terminalOperabilityPeriodDay-Hour15':
    case 'terminalOperabilityDay-Hour15':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:15-16`;
    case 'terminalOperabilityPeriodDay-Hour16':
    case 'terminalOperabilityDay-Hour16':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:16-17`;
    case 'terminalOperabilityPeriodDay-Hour17':
    case 'terminalOperabilityDay-Hour17':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:17-18`;
    case 'terminalOperabilityPeriodDay-Hour18':
    case 'terminalOperabilityDay-Hour18':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:18-19`;
    case 'terminalOperabilityPeriodDay-Hour19':
    case 'terminalOperabilityDay-Hour19':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:19-20`;
    case 'terminalOperabilityPeriodDay-Hour20':
    case 'terminalOperabilityDay-Hour20':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:20-21`;
    case 'terminalOperabilityPeriodDay-Hour21':
    case 'terminalOperabilityDay-Hour21':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:21-22`;
    case 'terminalOperabilityPeriodDay-Hour22':
    case 'terminalOperabilityDay-Hour22':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:22-23`;
    case 'terminalOperabilityPeriodDay-Hour23':
    case 'terminalOperabilityDay-Hour23':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:23-00`;
    case 'terminalOperabilityPeriodDay-NaTotal':
    case 'terminalOperabilityDay-NaTotal':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityDay.totalRegime')}`;
    case 'terminalOperabilityPeriodDay-WorkTotal':
    case 'terminalOperabilityDay-WorkTotal':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminalOperabilityChoose.operability')}`;
    case 'terminalOperabilityPeriodDay-createEventsTab':
    case 'terminalOperabilityDay-createEventsTab':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.terminalOperabilityPeriod.tab-operability-day')},
                ${translate(`page.adminPanel.type-navLink`)}:
                ${translate('page.dashboard.tab-events')}`;
    case 'terminalOperabilityPeriodEvents-EventId':
    case 'terminalOperabilityDayEvents-EventId':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-eventId')}`;
    case 'terminalOperabilityPeriodEvents-TypeName':
    case 'terminalOperabilityDayEvents-TypeName':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-typeName')}`;
    case 'terminalOperabilityPeriodEvents-Operation':
    case 'terminalOperabilityDayEvents-Operation':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-operation')}`;
    case 'terminalOperabilityPeriodEvents-ServiceId':
    case 'terminalOperabilityDayEvents-ServiceId':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-serviceId')}`;
    case 'terminalOperabilityPeriodEvents-TransactionId':
    case 'terminalOperabilityDayEvents-TransactionId':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-transactionId')}`;
    case 'terminalOperabilityPeriodEvents-ObjectName':
    case 'terminalOperabilityDayEvents-ObjectName':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-objectName')}`;
    case 'terminalOperabilityPeriodEvents-ObjectId':
    case 'terminalOperabilityDayEvents-ObjectId':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-objectId')}`;
    case 'terminalOperabilityPeriodEvents-Message':
    case 'terminalOperabilityDayEvents-Message':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-message')}`;
    case 'terminalOperabilityPeriodEvents-ErrorCode':
    case 'terminalOperabilityDayEvents-ErrorCode':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-errorCode')}`;
    case 'terminalOperabilityPeriodEvents-Account':
    case 'terminalOperabilityDayEvents-Account':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-account')}`;
    case 'terminalOperabilityPeriodEvents-DateInput':
    case 'terminalOperabilityDayEvents-DateInput':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-dateInput')}`;
    case 'terminalOperabilityPeriodEvents-DatePost':
    case 'terminalOperabilityDayEvents-DatePost':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate(`page.adminPanel.type-column`)}:
                ${translate('grids.terminal-events.column-datePost')}`;
    case 'terminalOperabilityPeriodEvents-btn-submit':
    case 'terminalOperabilityDayEvents-btn-submit':
      return `${translate('page.adminPanel.type-tab')}:
                ${translate('page.dashboard.tab-events')},
                ${translate('page.adminPanel.type-button')}:
                ${translate('owner.search')}`;
    case 'OwnerId':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.commission-terminals.column-numberDealer',
      )}`;
    case 'TerminalId':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.terminalOperabilityChoose.column-terminalId',
      )}`;
    case 'OwnerName':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.terminalOperabilityChoose.column-ownerName',
      )}`;
    case 'Address':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.terminalOperabilityChoose.column-address',
      )}`;
    case 'CityName':
      return `${translate('page.adminPanel.type-column')}:${translate('grids.terminalOperabilityChoose.column-city')}`;
    case 'NetworkContractName':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.terminalOperabilityChoose.column-networkContract',
      )}`;
    case 'TerminalBranch':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.communicationTerminalsPerDay.column-branch',
      )}`;
    case 'TerminalDescription':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.communicationTerminalsPerDay.column-note',
      )}`;
    case 'StatusName':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.communicationTerminalsPerDay.column-status',
      )}`;
    case 'Day1':
      return `${translate('page.adminPanel.type-column')}:1`;
    case 'Day2':
      return `${translate('page.adminPanel.type-column')}:2`;
    case 'Day3':
      return `${translate('page.adminPanel.type-column')}:3`;
    case 'Day4':
      return `${translate('page.adminPanel.type-column')}:4`;
    case 'Day5':
      return `${translate('page.adminPanel.type-column')}:5`;
    case 'Day6':
      return `${translate('page.adminPanel.type-column')}:6`;
    case 'Day7':
      return `${translate('page.adminPanel.type-column')}:7`;
    case 'Day8':
      return `${translate('page.adminPanel.type-column')}:8`;
    case 'Day9':
      return `${translate('page.adminPanel.type-column')}:9`;
    case 'Day10':
      return `${translate('page.adminPanel.type-column')}:10`;
    case 'Day11':
      return `${translate('page.adminPanel.type-column')}:11`;
    case 'Day12':
      return `${translate('page.adminPanel.type-column')}:12`;
    case 'Day13':
      return `${translate('page.adminPanel.type-column')}:13`;
    case 'Day14':
      return `${translate('page.adminPanel.type-column')}:14`;
    case 'Day15':
      return `${translate('page.adminPanel.type-column')}:15`;
    case 'Day16':
      return `${translate('page.adminPanel.type-column')}:16`;
    case 'Day17':
      return `${translate('page.adminPanel.type-column')}:17`;
    case 'Day18':
      return `${translate('page.adminPanel.type-column')}:18`;
    case 'Day19':
      return `${translate('page.adminPanel.type-column')}:19`;
    case 'Day20':
      return `${translate('page.adminPanel.type-column')}:20`;
    case 'Day21':
      return `${translate('page.adminPanel.type-column')}:21`;
    case 'Day22':
      return `${translate('page.adminPanel.type-column')}:22`;
    case 'Day23':
      return `${translate('page.adminPanel.type-column')}:23`;
    case 'Day24':
      return `${translate('page.adminPanel.type-column')}:24`;
    case 'Day25':
      return `${translate('page.adminPanel.type-column')}:25`;
    case 'Day26':
      return `${translate('page.adminPanel.type-column')}:26`;
    case 'Day27':
      return `${translate('page.adminPanel.type-column')}:27`;
    case 'Day28':
      return `${translate('page.adminPanel.type-column')}:28`;
    case 'Day29':
      return `${translate('page.adminPanel.type-column')}:29`;
    case 'Day30':
      return `${translate('page.adminPanel.type-column')}:30`;
    case 'Day31':
      return `${translate('page.adminPanel.type-column')}:31`;
    case 'AvgValue':
      return `${translate('page.adminPanel.type-column')}:${translate(
        'grids.communicationTerminalsPerDay.column-average-day',
      )}`;
    //communication terminals per day
    case 'Hour1':
      return `${translate(`page.adminPanel.type-column`)}:00-01`;
    case 'Hour2':
      return `${translate(`page.adminPanel.type-column`)}:01-02`;
    case 'Hour3':
      return `${translate(`page.adminPanel.type-column`)}:02-03
`;
    case 'Hour4':
      return `${translate(`page.adminPanel.type-column`)}:03-04
`;
    case 'Hour5':
      return `${translate(`page.adminPanel.type-column`)}:04-05
`;
    case 'Hour6':
      return `${translate(`page.adminPanel.type-column`)}:05-06
`;
    case 'Hour7':
      return `${translate(`page.adminPanel.type-column`)}:06-07
`;
    case 'Hour8':
      return `${translate(`page.adminPanel.type-column`)}:07-08
`;
    case 'Hour9':
      return `${translate(`page.adminPanel.type-column`)}:08-09
`;
    case 'Hour10':
      return `${translate(`page.adminPanel.type-column`)}:09-10
`;
    case 'Hour11':
      return `${translate(`page.adminPanel.type-column`)}:10-11
`;
    case 'Hour12':
      return `${translate(`page.adminPanel.type-column`)}:11-12
`;
    case 'Hour13':
      return `${translate(`page.adminPanel.type-column`)}:12-13
`;
    case 'Hour14':
      return `${translate(`page.adminPanel.type-column`)}:13-14
`;
    case 'Hour15':
      return `${translate(`page.adminPanel.type-column`)}:14-15
`;
    case 'Hour16':
      return `${translate(`page.adminPanel.type-column`)}:15-16
`;
    case 'Hour17':
      return `${translate(`page.adminPanel.type-column`)}:16-17`;
    case 'Hour18':
      return `${translate(`page.adminPanel.type-column`)}:17-18
`;
    case 'Hour19':
      return `${translate(`page.adminPanel.type-column`)}:18-19
`;
    case 'Hour20':
      return `${translate(`page.adminPanel.type-column`)}:19-20
`;
    case 'Hour21':
      return `${translate(`page.adminPanel.type-column`)}:20-21
`;
    case 'Hour22':
      return `${translate(`page.adminPanel.type-column`)}:21-22
`;
    case 'Hour23':
      return `${translate(`page.adminPanel.type-column`)}:22-23`;
    case 'Hour24':
      return `${translate(`page.adminPanel.type-column`)}:23-24`;
    default:
      return value;
  }
};

export default terminalOperabilityPeriod;
