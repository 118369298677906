import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import { getTransformDateString } from '../../utils/getTransformDateString';
import FormStateContainer from '../../components/FormStateContainer';
import AddProfileModal from './AddProfileModal';
import DeleteModal from '../../components/DeleteModal';
import ChangeProfileNameModal from './ChangeProfileNameModal';
import ChangeProfileDescriptionModal from './ChangeProfileDescriptionModal';
import ChangeProfileVisibilityModal from './ChangeProfileVisibilityModal';
import { INITIAL_VALUES } from './AddProfileModal/initialValues.const';
import checkElement from '../../utils/checkElement';
import { getProfileVisibilityCell } from './cells';
import findArray from '../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';

import './CommissionProfiles.scss';

const api = new ApiService();

class CommissionProfilesGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('сommissionManagementProfiles');
  }

  state = {
    isLoading: false,
    data: [],
    selectOwners: [1],
    visibleDialog: '',
    currentDataItem: null,
    owners: [],
    selectedItems: [],
  };

  async componentDidMount() {
    await this.fetchOwners();
    await this.getProfilesList();
  }

  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const owners = await api.owners();

      await this.setState({ owners });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getProfilesList = async () => {
    const { selectOwners } = this.state;

    if (!selectOwners.length) return;
    try {
      this.toggleLoader(true);
      const data = await api.getOwnerProfiles(selectOwners[0]);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (selectOwners) => {
    this.setState({ selectOwners }, this.getProfilesList);
  };

  filterOwnersByStatus = (owners, isWithDeleted) => {
    return isWithDeleted ? owners : owners.filter((owner) => owner.OwnerStatusId !== 2);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectOwners, owners, selectedItems } = this.state;

    return (
      <Row>
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(owners)}
            selectItems={selectOwners}
            onSave={this.onOwners}
            isGetDefaultSelectItemsFromStorage
            isSingle
          />
        </Col>
        <Col span={24} className="CommissionProfiles-actionsPanel">
          {checkElement('сommissionManagementProfiles-addProfile', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ visibleDialog: 'addProfileModal' });
              }}
              className="CommissionProfiles-button"
            >
              {translate('page.commission.btn-add')}
            </Button>
          )}

          {checkElement('сommissionManagementProfiles-deleteProfile', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              className="CommissionProfiles-button"
              onClick={() => {
                this.setState({ visibleDialog: 'deleteProfilesModal' });
              }}
            >
              {translate('page.commission.btn-delete')}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  handleColClick = ({ dataItem }, openingDialogSlug) => {
    this.setState({ currentDataItem: dataItem }, () => {
      this.setState({ visibleDialog: openingDialogSlug });
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { translate } = this.props;
    const { isLoading, data, visibleDialog, currentDataItem, owners, selectedItems } = this.state;
    const selectedItemNamesString = selectedItems.length
      ? selectedItems.map(({ ProfileName }) => ProfileName).join()
      : '';

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.getProfilesList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="сommissionManagementProfiles-grid"
          permissionName="сommissionManagementProfiles"
          fieldForSelection="ProfileId"
          onSelect={this.handleSelectionChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="ProfileId"
            title="grids.commission-profiles.column-profileId"
            width="120"
            filter="numeric"
          />
          <GridColumn
            field="ProfileName"
            title="grids.commission-profiles.column-profileName"
            width="160"
            onClick={
              checkElement('сommissionManagementProfiles-changeProfileName', this.currentArray)
                ? (props) => {
                    this.handleColClick(props, 'changeProfileNameModal');
                  }
                : null
            }
          />
          <GridColumn field="Version" title="grids.commission-profiles.column-version" width="120" filter="numeric" />
          <GridColumn
            field="DatePost"
            title="grids.commission-profiles.column-updateDate"
            width="160"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn
            field="IsForAllOwners"
            title="grids.commission-profiles.column-profileVisibility"
            width="160"
            cell={getProfileVisibilityCell(
              this.handleColClick,
              translate,
              checkElement('сommissionManagementProfiles-changeDealerVisibility', this.currentArray),
            )}
            formatFilterCellData={(IsForAllOwners) =>
              IsForAllOwners === 'true'
                ? translate('grids.commission-profiles.visibleEveryone')
                : translate('grids.commission-profiles.limited')
            }
          />
          <GridColumn
            field="Description"
            title="grids.commission-profiles.column-description"
            width="400"
            onClick={
              checkElement('сommissionManagementProfiles-changeProfileDescription', this.currentArray)
                ? (props) => {
                    this.handleColClick(props, 'changeProfileDescriptionModal');
                  }
                : null
            }
          />
        </Grid>
        {visibleDialog === 'addProfileModal' && (
          <FormStateContainer initialValues={INITIAL_VALUES}>
            <AddProfileModal
              visible={visibleDialog === 'addProfileModal'}
              closeModal={() => {
                this.setState({ visibleDialog: '' });
              }}
              profileList={data}
              owners={owners}
            />
          </FormStateContainer>
        )}
        {visibleDialog === 'deleteProfilesModal' && (
          <DeleteModal
            visible={visibleDialog === 'deleteProfilesModal'}
            closeModal={() => {
              this.setState({ visibleDialog: '' });
            }}
            submitCallback={async () => {
              try {
                this.toggleLoader(true);
                await api.commissionsClientProfileDelete(selectedItems.map(({ ProfileId }) => ProfileId));
                window.location.reload();
              } catch (e) {
                const { showError } = this.props;
                showError(e);
              } finally {
                this.toggleLoader(false);
              }
            }}
            deleteContent={`${translate(
              'grids.commission-profiles.deleteProfileQuestion',
            )} ${selectedItemNamesString}?`}
            messageSuccess={translate('grids.commission-profiles.responseMessages.successfulDeleteProfiles')}
          />
        )}
        {visibleDialog === 'changeProfileNameModal' && currentDataItem && currentDataItem.ProfileName && (
          <FormStateContainer
            initialValues={{
              ...currentDataItem,
            }}
          >
            <ChangeProfileNameModal
              visible={visibleDialog === 'changeProfileNameModal'}
              closeModal={() => {
                this.setState({ visibleDialog: '' });
              }}
            />
          </FormStateContainer>
        )}
        {visibleDialog === 'changeProfileDescriptionModal' && currentDataItem && currentDataItem.Description && (
          <FormStateContainer initialValues={{ ...currentDataItem, ProfileDescription: currentDataItem.Description }}>
            <ChangeProfileDescriptionModal
              visible={visibleDialog === 'changeProfileDescriptionModal'}
              closeModal={() => {
                this.setState({ visibleDialog: '' });
              }}
            />
          </FormStateContainer>
        )}
        {visibleDialog === 'changeProfileVisibilityModal' && currentDataItem && currentDataItem.Description && (
          <FormStateContainer
            initialValues={{
              ...currentDataItem,
              SelectedDealers: currentDataItem.Owners.split(',').map((item) => +item),
              ProfileVisibility: currentDataItem.IsForAllOwners ? 'All' : 'Manual',
            }}
          >
            <ChangeProfileVisibilityModal
              visible={visibleDialog === 'changeProfileVisibilityModal'}
              closeModal={() => {
                this.setState({ visibleDialog: '' });
              }}
              owners={owners}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(CommissionProfilesGrid);
