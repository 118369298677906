import React, { PureComponent } from 'react';
import { Col, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import Grid from '../../components/grids/baseGrid';
import { OWNER_ITEM_KEYS } from './constants';
import UpdateCustomizationModal from './UpdateCustomizationModal';
import EditingOwnersModel from './EditingOwnersModel';
import FormStateContainer from '../../components/FormStateContainer';

import './PartnerInfoSenderGrid.styled.scss';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

class PartnerInfoSenderGrid extends PureComponent {
  currentArray = findArray('partnerInfoSender');
  state = {
    isLoading: false,
    ownersWithItems: [],
    modalVariant: '',
    selectedItems: [],
    showAll: false,
  };

  componentDidMount() {
    this.fetchOwnerItems();
  }

  fetchOwners = async () => {
    const { showAll } = this.state;

    try {
      const owners = await api.owners();
      return owners && owners.length
        ? showAll
          ? owners
          : owners.filter(({ OwnerStatusId }) => OwnerStatusId !== 2)
        : [];
    } catch (error) {
      throw error;
    }
  };

  fetchOwnerItems = async () => {
    await this.setState({ isLoading: true });
    try {
      const owners = await this.fetchOwners();
      const rawOwnerIds = await api.getOwnerItems({
        ownerIds: owners.map(({ OwnerId }) => OwnerId),
        keys: OWNER_ITEM_KEYS,
      });

      this.setState({
        isLoading: false,
        ownersWithItems:
          rawOwnerIds && rawOwnerIds.length
            ? owners
                .map((owner) => {
                  const ownersItems = rawOwnerIds.filter(
                    ({ OwnerId, Key }) => OwnerId === owner.OwnerId && OWNER_ITEM_KEYS.includes(Key),
                  );

                  return ownersItems && ownersItems.length
                    ? {
                        ...owner,
                        ...ownersItems.reduce(
                          (acc, { Key, Value }) => ({ ...acc, [Key.replace(/\./g, '')]: Value }),
                          {},
                        ),
                        isCustomized: true,
                      }
                    : owner;
                })
                .sort(({ isCustomized: prevIsCustomized }, { isCustomized: nextIsCustomized }) =>
                  prevIsCustomized ? -1 : nextIsCustomized ? 1 : 0,
                )
            : owners,
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="PartnerInfoSenderGrid-toolbar">
        {checkElement('partnerInfoSender-customize', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'customize' });
              }}
            >
              {translate('page.partnerInfoSenderGrid.customize')}
            </Button>
          </div>
        )}

        {checkElement('partnerInfoSender-edit', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={selectedItems.length !== 1}
              onClick={() => {
                this.setState({ modalVariant: 'edit' });
              }}
            >
              {translate('core.buttonTitles.edit')}
            </Button>
          </div>
        )}

        {checkElement('partnerInfoSender-removeCustomization', this.currentArray) && (
          <div>
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ modalVariant: 'removeCustomization' });
              }}
            >
              {translate('page.partnerInfoSenderGrid.removeCustomization')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  onSelect = (selectedItems) => {
    this.setState({ selectedItems });
  };

  showRemoteCallback = () => {
    this.setState(
      ({ showAll }) => ({ showAll: !showAll }),
      () => {
        this.fetchOwnerItems();
      },
    );
  };

  rowRender = (element, { dataItem }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      {
        ...elementStyle,
        className: dataItem.isCustomized ? 'PartnerInfoSenderGrid-rowCustomise' : '',
      },
      children,
    );
  };

  render() {
    const { ownersWithItems, showAll, isLoading, modalVariant, selectedItems } = this.state;

    if (!checkElement('partnerInfoSender-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={ownersWithItems}
          isLoading={isLoading}
          onRefresh={this.fetchOwnerItems}
          name="PartnerInfoSenderGrid"
          toolbar={this.buildToolbar()}
          onSelect={this.onSelect}
          fieldForSelection="OwnerId"
          showRemoteLabel="showAllOwners"
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={showAll}
          rowRender={this.rowRender}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="OwnerId" title="page.partnerInfoSenderGrid.col-ownerId" width="110" filter="numeric" />
          <GridColumn field="OwnerName" title="page.partnerInfoSenderGrid.col-ownerName" width="140" />
          <GridColumn field="OwnerStatus" title="page.partnerInfoSenderGrid.col-ownerStatus" width="140" />
          <GridColumn field="OwnerType" title="page.partnerInfoSenderGrid.col-ownerType" width="120" />
          <GridColumn
            field="PartnerInfoNamePUMB"
            title="page.partnerInfoSenderGrid.col-partnerInfoNamePUMB"
            width="210"
          />
          <GridColumn
            field="PartnerInfoNameRaiff"
            title="page.partnerInfoSenderGrid.col-partnerInfoNameRaiff"
            width="250"
          />
          <GridColumn
            field="PartnerInfoId"
            title="page.partnerInfoSenderGrid.col-partnerId"
            width="150"
            filter="numeric"
          />
          <GridColumn
            field="PartnerInfoAccount"
            title="page.partnerInfoSenderGrid.col-partnerAccount"
            width="170"
            filter="numeric"
          />
          <GridColumn
            field="PartnerInfoCountryPUMB"
            title="page.partnerInfoSenderGrid.col-partnerInfoCountryPUMB"
            width="210"
          />
          <GridColumn field="PartnerInfoCity" title="page.partnerInfoSenderGrid.col-partnerInfoCity" width="150" />
          <GridColumn
            field="PartnerInfoAddress"
            title="page.partnerInfoSenderGrid.col-partnerInfoAddress"
            width="240"
          />
        </Grid>

        {(modalVariant === 'customize' || modalVariant === 'removeCustomization') && (
          <UpdateCustomizationModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedOwners={selectedItems}
            onRefresh={this.fetchOwnerItems}
          />
        )}

        {modalVariant === 'edit' && (
          <FormStateContainer
            initialValues={{
              OwnerId: selectedItems[0].OwnerId,
              'PartnerInfo.Name.PUMB': selectedItems[0].PartnerInfoNamePUMB || '',
              'PartnerInfo.Name.Raiff': selectedItems[0].PartnerInfoNameRaiff || '',
              'PartnerInfo.Id': selectedItems[0].PartnerInfoId || '',
              'PartnerInfo.Account': selectedItems[0].PartnerInfoAccount || '',
              'PartnerInfo.Country.PUMB': selectedItems[0].PartnerInfoCountryPUMB || '',
              'PartnerInfo.City': selectedItems[0].PartnerInfoCity || '',
              'PartnerInfo.Address': selectedItems[0].PartnerInfoAddress || '',
            }}
          >
            <EditingOwnersModel
              visible={modalVariant === 'edit'}
              closeModal={() => {
                this.setState({ modalVariant: '' });
              }}
              onRefresh={this.fetchOwnerItems}
            />
          </FormStateContainer>
        )}
      </>
    );
  }
}

export default withTranslate(PartnerInfoSenderGrid);
