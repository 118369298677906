import React, { PureComponent } from 'react';
import { Select, Button, Input } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';

import './ServiceMainProps.scss';
import CustomInput from '../../../../components/Input/Input';
import MainPropsService from './MainProps.service';
import { CONFIGS, translateSlugs, defaultTitleTranslateSlugs } from './contants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import WarningDialog from './WarningDialog';

const { Option } = Select;

class ServiceMainProps extends PureComponent {
  tabUniqId = Math.random();

  renderInput = ({ name, type, isDisable, className, props }) => {
    const { translate, formState, handleChangeFormState, formErrors, onError } = this.props;

    return (
      <div className={`ServiceMainProps-inputRow ${className || ''}`}>
        {translateSlugs[name] && <div className="ServiceMainProps-label">{translate(translateSlugs[name])}</div>}
        <div className="ServiceMainProps-input">
          <CustomInput
            type={type}
            formState={formState}
            disabled={isDisable}
            onChangeFormState={handleChangeFormState}
            formErrors={formErrors}
            setError={onError}
            validators={MainPropsService.getValidators(translate)}
            name={name}
            {...(props ? { ...props } : {})}
          />
        </div>
      </div>
    );
  };

  renderSelect = ({ name, isDisabled, className }) => {
    const { getSelectChangeCallback, formState, translate, dropdownsData } = this.props;

    return (
      <div className={`ServiceMainProps-inputRow ${className || ''}`}>
        <div className="ServiceMainProps-label">{translate(translateSlugs[name])}</div>
        <div className="ServiceMainProps-input">
          <GridDropdown
            data={dropdownsData[name]}
            colConfig={CONFIGS[name]}
            selectItems={formState[name]}
            onSave={getSelectChangeCallback(name)}
            defaultTitle={translate(defaultTitleTranslateSlugs[name])}
            isSingle={!['limitType', 'paymentTool'].includes(name)}
            disabled={isDisabled}
          />
        </div>
      </div>
    );
  };

  renderEmptyChangeDate = () => {
    const { translate } = this.props;

    return (
      <div className="ServiceMainProps-inputRow ServiceMainProps-middleGap TopAlignedGridDropdown">
        <div className="ServiceMainProps-label">{translate(translateSlugs.dateChange)}</div>
        <div className="ServiceMainProps-input">
          <Input disabled value={translate('page.serviceMainProps.noValue')} />
        </div>
      </div>
    );
  };

  renderNativeSelect = ({ name, className }) => {
    const { getSelectChangeCallback, formState, translate, dropdownsData } = this.props;

    return (
      <div className={`ServiceMainProps-inputRow ${className || ''}`}>
        <div className="ServiceMainProps-label">{translate(translateSlugs[name])}</div>
        <div className="ServiceMainProps-input" style={{ position: 'relative' }} id={`${name}-${this.tabUniqId}`}>
          <Select
            mode={['limitType'].includes(name) ? 'multiple' : 'default'}
            style={{ width: '100%' }}
            onChange={getSelectChangeCallback(name)}
            value={formState[name]}
            placeholder={translate(defaultTitleTranslateSlugs[name])}
            getPopupContainer={() => document.getElementById(`${name}-${this.tabUniqId}`)}
          >
            {dropdownsData[name].map(({ Id, Name }) => (
              <Option value={Id} key={Id}>
                {Name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  render() {
    const { translate, formState, formErrors, onSave, warningDialogType, setWarningDialogType, isStepServiceCreation } =
      this.props;

    return (
      <>
        <div className="ServiceMainProps">
          <div className="ServiceMainProp-content">
            <div className="ServiceMainProps-row">{this.renderInput({ name: 'serviceId', isDisable: true })}</div>
            <div className="ServiceMainProps-row">
              {this.renderInput({ name: 'serviceName', type: 'textArea', props: { autoSize: true } })}
            </div>
            <div className="ServiceMainProps-row">
              {this.renderInput({
                name: 'serviceDescription',
                type: 'textArea',
              })}
            </div>
            <div className="ServiceMainProps-row">{this.renderInput({ name: 'commissionRules' })}</div>
            <div className="ServiceMainProps-row">{this.renderNativeSelect({ name: 'limitType' })}</div>
            <div className="ServiceMainProps-row">{this.renderSelect({ name: 'serviceType' })}</div>
            <div className="ServiceMainProps-row">{this.renderSelect({ name: 'provider' })}</div>
            <div className="ServiceMainProps-row">{this.renderNativeSelect({ name: 'status' })}</div>

            <div className="ServiceMainProps-flexRow ServiceMainProps-selectWithCheckboxRow">
              <div className="ServiceMainProps-row ">
                {this.renderSelect({
                  name: 'transitService',
                  isDisabled: !formState.isTransitServiceActive,
                  className: 'ServiceMainProps-narrowGap ServiceMainProps-middleGap',
                })}
              </div>

              {this.renderInput({
                name: 'isTransitServiceActive',
                type: 'checkbox',
              })}
            </div>

            <div className="ServiceMainProps-flexRow">
              <div className="ServiceMainProps-narrowRow">
                {this.renderInput({ name: 'sum', type: 'number', className: 'ServiceMainProps-middleGap' })}
              </div>
              {this.renderInput({
                name: 'isClientCommission',
                type: 'checkbox',
              })}
            </div>

            <div className="ServiceMainProps-flexRow">
              <div className="ServiceMainProps-narrowRow">
                {this.renderInput({ name: 'minSum', type: 'number', className: 'ServiceMainProps-middleGap' })}
              </div>

              {this.renderInput({
                name: 'isRiskService',
                type: 'checkbox',
              })}
            </div>

            <div className="ServiceMainProps-flexRow">
              <div className="ServiceMainProps-narrowRow">
                {this.renderInput({ name: 'maxSum', type: 'number', className: 'ServiceMainProps-middleGap' })}
              </div>

              {this.renderInput({
                name: 'isAddressPayment',
                type: 'checkbox',
              })}
            </div>

            <div className="ServiceMainProps-flexRow">
              <div className="ServiceMainProps-narrowRow">
                {this.renderInput({ name: 'orgSum', type: 'number', className: 'ServiceMainProps-middleGap' })}
              </div>

              <div className="ServiceMainProps-exNarrowRow">
                {this.renderInput({
                  name: 'unlockingPeriod',
                  type: 'number',
                  isDisable: !formState.isRiskService,
                })}
              </div>
            </div>

            <div className="ServiceMainProps-row">{this.renderNativeSelect({ name: 'bankDetails' })}</div>

            <div className="ServiceMainProps-flexRow ServiceMainProps-selectWithCheckboxRow">
              <div className="ServiceMainProps-row">
                {this.renderSelect({
                  name: 'basicService',
                  isDisabled: !formState.isBasicServiceAllowed,
                  className: 'ServiceMainProps-middleGap TopAlignedGridDropdown',
                })}
              </div>

              {this.renderInput({
                name: 'isBasicServiceAllowed',
                type: 'checkbox',
              })}
            </div>

            <div className="ServiceMainProps-row">
              {this.renderSelect({ name: 'categoryManager', className: 'TopAlignedGridDropdown' })}
            </div>
            <div className="ServiceMainProps-row">
              {this.renderSelect({ name: 'supportManager', className: 'TopAlignedGridDropdown' })}
            </div>
            <div className="ServiceMainProps-row">
              {this.renderSelect({ name: 'engagementManager', className: 'TopAlignedGridDropdown' })}
            </div>

            <div className="ServiceMainProps-flexRow">
              {formState.dateChange
                ? this.renderInput({
                    name: 'dateChange',
                    type: 'datePicker',
                    isDisable: true,
                    className: 'ServiceMainProps-middleGap',
                  })
                : this.renderEmptyChangeDate()}

              {this.renderInput({
                name: 'createDate',
                type: 'datePicker',
              })}
            </div>

            <div className="ServiceMainProps-row">
              {this.renderSelect({ name: 'paymentTool', className: 'TopAlignedGridDropdown' })}
            </div>

            <div className="ServiceMainProps-row">
              <Button
                type="primary"
                onClick={() => {
                  onSave();
                }}
                disabled={Object.values(formErrors).some((value) => value)}
              >
                {translate(isStepServiceCreation ? 'page.servicesBigTable.saveAndNext' : 'core.buttonTitles.save')}
              </Button>
            </div>
          </div>
        </div>

        <WarningDialog
          type={warningDialogType}
          onSave={onSave}
          closeModal={() => {
            setWarningDialogType('');
          }}
        />
      </>
    );
  }
}

export default withTranslate(ServiceMainProps);
