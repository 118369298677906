import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../../components/GridDropdown/GridDropdown';

import Grid from '../../components/grids/baseGrid';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService, tabService } from '../../services';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@telerik/kendo-intl';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
class TerminalsByDay extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('terminalsByDays');
  }
  state = {
    isLoading: false,
    allOwners: [],
    selectOwners: [],
    selectedItems: [],
    start: new Date(),
    end: new Date(),
    terminalsList: [],
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const allOwners = await api.owners();
      if (allOwners && allOwners.length) {
        await this.setState({ isLoading: false, allOwners });
      }
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };
  dropDownChangeCallback = (name, e) => {
    this.setState({ [name]: e.target.value });
  };
  handlerSelectOwners = (name) => (selectItems) => {
    this.setState({ [name]: selectItems }, this.getTerminalsByDays);
  };
  selectedChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  getTerminalsByDays = async () => {
    try {
      await this.toggleLoader(true);
      const { selectOwners } = this.state;
      const model = {
        owners: selectOwners,
      };
      const terminalsList = await api.terminalList(model);
      this.setState({ terminalsList: terminalsList || [] });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  addTerminalsByDaysTab = () => {
    const { selectOwners, start, end, selectedItems } = this.state;
    tabService.addTab({
      type: 'terminalsByDays',
      dayInfo: {
        ownerId: selectOwners,
        dateStart: convertToISOFormat(start),
        dateEnd: convertToISOFormat(end),
        terminalId: selectedItems.map(({ TerminalId }) => TerminalId),
      },
    });
  };

  buildToolbar = () => {
    const { allOwners, selectOwners, selectedItems, start, end } = this.state;
    const { translate } = this.props;

    if (!allOwners || !allOwners.length) {
      return <></>;
    }

    return (
      <Col span={24} xl={17} xxl={15}>
        <Row type="flex" align="middle" style={{ marginBottom: '15px' }}>
          <Col span={3}>{translate('page.commissionsServices.dealer')}</Col>
          <Col span={18}>
            <GridDropdown
              data={DropdownNormalizersService.normalizeOwners(allOwners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              onSave={this.handlerSelectOwners('selectOwners')}
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={24} md={10}>
            <Col span={4} md={8}>
              <p>{translate('grids.comission-sub.validity-period-date-start')}</p>
            </Col>
            <Col span={20} md={16}>
              <DatePicker
                format="yyyy.MM.dd"
                value={start}
                onChange={this.dropDownChangeCallback.bind(this, 'start')}
              />
            </Col>
          </Col>
          <Col span={24} md={10}>
            <Col span={4} md={8}>
              <p>{translate('grids.comission-sub.validity-period-date-end')}</p>
            </Col>
            <Col span={20} md={16}>
              <DatePicker format="yyyy.MM.dd" value={end} onChange={this.dropDownChangeCallback.bind(this, 'end')} />
            </Col>
          </Col>
          <Col span={4}>
            {checkElement('terminalsByDays-createReport', this.currentArray) && (
              <Button icon="search" primary disabled={!selectedItems.length} onClick={this.addTerminalsByDaysTab}>
                {translate('core.buttonTitles.createReport')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    );
  };
  async componentDidMount() {
    await this.fetchOwners();
  }

  render = () => {
    const { isLoading, terminalsList } = this.state;

    if (!checkElement('terminalsByDays-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={terminalsList}
          name="terminalsByDays-grid"
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          multiSelected
          fieldForSelection="TerminalId"
          onSelect={this.selectedChange}
          onRefresh={this.getTerminalsByDays}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn field="OwnerId" title="grids.terminalsByServices.column-dealer" width="200" />
          <GridColumn field="TerminalId" title="grids.terminalsByServices.column-terminalId" width="200" />
          <GridColumn field="OwnerName" title="grids.terminalsByServices.column-name" width="200" />
          <GridColumn
            field="NetworkContractName"
            title="grids.terminalsByServices.column-networkContract"
            width="200"
          />
          <GridColumn field="RegionName" title="grids.terminalsByServices.column-region" width="200" />
          <GridColumn field="CityName" title="grids.terminalsByServices.column-city" width="200" />
          <GridColumn field="TerminalAddress" title="grids.terminalsByServices.column-address" width="200" />
          <GridColumn field="StatusName" title="grids.terminalsByServices.column-status" width="200" />
        </Grid>
      </>
    );
  };
}
export default withTranslate(TerminalsByDay);
