const releases = (translate, value) => {
  switch (value) {
    case 'releasePage-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'releasePage-releaseNewElements':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.releases.releaseNewElements')}`;
    case 'releasePage-choosingRoles':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.releases.associateSelectedRoles')}`;
    case 'releasePage-accessRole':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.releases.access-role')}`;
    case 'releasePage-accessUsers':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.releases.access-users')}`;
    case 'releasePage-associateSelectedRoles':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.releases.selectAssignRoles')}`;
    default:
      return value;
  }
};

export default releases;
