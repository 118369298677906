import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Col, message } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { ApiService, StorageService, tabService } from '../../services';
import Grid from '../../components/grids/baseGrid';
import CellProgress from '../monitorActivity/cellProgress';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import CellProgressEncashment from './cellProgressEncashment';
import RecalculationModal from './RecalculationModal';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';

import './EncashmentTerminals.styled.scss';
import { withRouter } from 'react-router-dom';

const api = new ApiService();

const WrappedCellProgressEncashment = (props) => <CellProgressEncashment {...props} />;

const aggregate = [
  { field: 'TerminalId', aggregate: 'count', format: 'n0' },
  { field: 'CashBoxQuantity', aggregate: 'sum', format: 'n0' },
];

class GridEncashmentTerminals extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('encashment');
  }

  state = {
    owners: [],
    selectedOwners: [],
    isLoading: false,
    data: [],
    initialSort: { field: 'LastCollection', dir: 'desc' },
    initialFilters: [
      {
        field: 'StatusName',
        operator: 'contains',
        value: 'неактивный',
      },
    ],
    currentSelectTerminalInfo: null,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.handleAuthorizeCheck();
    this.initialize();
  }

  handleAuthorizeCheck = async () => {
    try {
      await api.authorizeCheck();
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };

  initialize = async () => {
    await this.fetchOwners();
    await this.makeQuery();
  };

  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const owners = await api.owners();
      await this.setState({
        owners,
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  makeQuery = async () => {
    const { selectedOwners } = this.state;
    if (selectedOwners.length < 1) return;

    try {
      this.toggleLoader(true);
      const data = await api.collections(selectedOwners);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  selectOwners = (selectedOwners) => {
    this.setState({ selectedOwners }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedOwners, owners } = this.state;

    return (
      <Col
        xs={{ order: 2, span: 24 }}
        lg={{ order: 1, span: 13 }}
        style={{ marginTop: '8px' }}
        className="GridEncashmentTerminals-toolbar"
      >
        <div className="GridEncashmentTerminals-dropdownWrapper">
          <GridDropdown
            data={DropdownNormalizersService.normalizeOwners(owners)}
            colConfig={OWNERS_COLUMN_CONFIG}
            selectItems={selectedOwners}
            onSave={this.selectOwners}
            isGetDefaultSelectItemsFromStorage
          />
        </div>
        <Button
          type="primary"
          style={{
            margin: '15px 0 10px 0',
          }}
          onClick={() => tabService.addTab({ type: 'encashmentSendingCheck' })}
        >
          {translate('grids.encashmentSendingCheck.tab-sendCheck-title')}
        </Button>
      </Col>
    );
  };

  getShowProperty = (key) => {
    return checkElement(key, this.currentArray);
  };

  getHandlers = () => {
    return [
      {
        title: 'page.encashment.tab-encashmentHistory',
        action: (props) => tabService.addTab({ type: 'encashmentHistory', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('terminalsEncashment-tab-encashmentHistory'),
      },
      {
        title: 'page.encashment.tab-terminalCashList',
        action: (props) => tabService.addTab({ type: 'terminalCashList', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('terminalsEncashment-tab-terminalCashList'),
      },
      {
        title: 'page.encashment.tab-encashmentRecalcTernminal',
        action: ({ dataItem }) => {
          this.setState({ currentSelectTerminalInfo: dataItem });
        },
        dropdown: false,
        show: this.getShowProperty('terminalsEncashment-terminalRecalculation'),
      },
    ];
  };

  handleRecalculation = async () => {
    const { currentSelectTerminalInfo } = this.state;
    const { translate } = this.props;

    try {
      this.setState({ currentSelectTerminalInfo: null });
      await this.toggleLoader(true);
      await api.terminalsCashRecalculate({
        OwnerId: currentSelectTerminalInfo.OwnerId,
        TerminalId: currentSelectTerminalInfo.TerminalId,
      });
      message.success(`${translate('grids.encashment.text-terminal-cash-recalc-popup-success')}`, 2.5);
      await this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.toggleLoader(false);
    }
  };

  render = () => {
    const { isLoading, data, currentSelectTerminalInfo } = this.state;
    const { translate } = this.props;

    if (!checkElement('encashment-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="terminalsEncashment"
          permissionName="encashment"
          isShowSpecificFilterByFieldName="TerminalId"
        >
          <GridColumn field="OwnerName" title="grids.accountsLimit.column-owner" width="150" />

          <GridColumn
            field="TerminalId"
            title="grids.transactions.column-terminalId"
            width="134"
            filter="numeric"
            handlers={this.getHandlers()}
            dropdown={!!checkElement('terminalsEncashment-isShowDropdown', this.currentArray)}
          />

          <GridColumn field="TypeName" title="grids.wallets.column-type" width="150" />

          <GridColumn field="RegionName" title="grids.encashment.column-region" width="150" />

          <GridColumn field="CityName" title="grids.terminals.column-city" width="150" />

          <GridColumn field="Branch" title="grids.terminals.column-branch" width="150" />

          <GridColumn field="TerminalAddress" title="grids.terminals.column-street" width="150" />

          <GridColumn field="TerminalName" title="grids.terminals.column-name" width="150" />

          <GridColumn
            field="LastCollection"
            title="grids.encashment.column-lastEncashment"
            width="240"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
            // cell={CellDate}
          />

          <GridColumn field="CashBoxCapacity" title="grids.encashment.column-capacity" width="150" filter="numeric" />

          <GridColumn
            field="CashBoxQuantity"
            title="grids.encashment.column-cashQuantity"
            width="100"
            filter="numeric"
          />

          <GridColumn
            field="CashBoxUsed"
            title="grids.terminals.column-сashBoxUsed"
            width="100"
            filter="numeric"
            cell={CellProgress}
          />

          <GridColumn
            field="CoinBoxUsed"
            title="grids.encashment.column-coinBoxUsed"
            width="200"
            filter="numeric"
            cell={WrappedCellProgressEncashment}
          />

          <GridColumn
            field="CoinBoxQuantity"
            title="grids.encashment.column-coinBoxQuantity"
            width="200"
            filter="numeric"
          />

          <GridColumn
            field="CoinBoxRatio"
            title="grids.encashment.column-coinBoxRatio"
            width="200"
            filter="numeric"
            cell={WrappedCellProgressEncashment}
          />

          <GridColumn field="StatusName" title="owner.column-status" width="145" />

          <GridColumn field="StatusDetails" title="grids.encashment.column-dateNextCollection" width="100" />

          <GridColumn field="CollectorName" title="grids.encashment.column-collector" width="100" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="145" />
        </Grid>

        {currentSelectTerminalInfo && (
          <RecalculationModal
            visible={currentSelectTerminalInfo}
            closeModal={() => {
              this.setState({ currentSelectTerminalInfo: null });
            }}
            submitCallback={this.handleRecalculation}
            deleteContent={`${translate('grids.encashment.text-terminal-cash-recalc-popup-title')} ${
              currentSelectTerminalInfo.TerminalId
            }?`}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridEncashmentTerminals));
