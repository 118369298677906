import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Select } from 'antd';
import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellProgress from '../monitorActivity/cellProgress';
import './gridAdminGeneralResults.scss';

const WrappedCellProgress = (props) => <CellProgress {...props} />;

const api = new ApiService();

const { Option } = Select;

const sortByRating = (a, b) => {
  return a.Rating - b.Rating;
};

class GridAdminGeneralResults extends Component {
  dealerCategories = [
    {
      key: 'LessThan50',
      value: 'page.adminGeneralResults.lessThan50',
    },
    {
      key: 'From51To100',
      value: 'page.adminGeneralResults.from51To100',
    },
    {
      key: 'From101To200',
      value: 'page.adminGeneralResults.from101To200',
    },
    {
      key: 'MoreThan201',
      value: 'page.adminGeneralResults.moreThan201',
    },
  ];

  state = {
    isLoading: false,
    data: [],
    owners: [],
    visible: false,
    selectedDealerCategory: this.dealerCategories[0].key,
  };

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  makeQuery = async () => {
    const { selectedDealerCategory } = this.state;
    try {
      this.toggleLoader(true);
      const model = {
        DealerCategory: selectedDealerCategory,
      };
      const data = await api.generalResultsByCategory(model);
      const renderData = data.sort(sortByRating);
      this.setState({ data: renderData });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onSelectChange = (selectedDealerCategory) => {
    this.setState({ selectedDealerCategory }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedDealerCategory } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="gridAdminStockPhotos-toolbar-row">
          <h2 className="gridAdminStockPhotos-toolbar-h2">{translate('page.adminGeneralResults.toolbar-h2')}</h2>
        </div>
        <div className="gridAdminStockPhotos-toolbar-row">
          <Select
            className="modal-system-encashment--select gridAdminStockPhotos-toolbar-photosFilter results"
            style={{ marginTop: '10px' }}
            value={selectedDealerCategory}
            onChange={this.onSelectChange}
            dropdownStyle={{ zIndex: '1054', width: '100%' }}
            filterOption={(input, option) =>
              JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            showSearch
          >
            {this.dealerCategories.map((el) => {
              return (
                <Option key={`dealerCategories-${el.key}`} value={el.key}>
                  {translate(`${el.value}`)}
                </Option>
              );
            })}
          </Select>
        </div>
      </Col>
    );
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        rowHeight={20}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="adminGeneralResults"
        permissionName="adminGeneralResults"
      >
        <GridColumn field="Rating" title="grids.adminGeneralResults.column-rating" width="180" filter="numeric" />

        <GridColumn
          field="OwnerName"
          title="grids.adminGeneralResults.column-ownerName"
          width="270"
          onClick={(props) => tabService.addTab({ type: 'photos', dataItem: props.dataItem })}
        />

        <GridColumn
          field="BrandedByStandartPercent"
          title="grids.adminGeneralResults.column-brandedByStandartPercent"
          width="150"
          filter="numeric"
          cell={WrappedCellProgress}
        />

        <GridColumn
          field="BrandedPercent"
          title="grids.adminGeneralResults.column-brandedPercent"
          width="150"
          filter="numeric"
          cell={WrappedCellProgress}
        />

        <GridColumn
          field="GiftsCount"
          title="grids.adminGeneralResults.column-giftsCount"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="TerminalsWithPhotoCount"
          title="grids.adminGeneralResults.column-terminalsWithPhotoCount"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="TerminalsConsideredCount"
          title="grids.adminGeneralResults.column-terminalsConsideredCount"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="TerminalsNotConsideredCount"
          title="grids.adminGeneralResults.column-terminalsNotConsideredCount"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="BrandedByStandartCount"
          title="grids.adminGeneralResults.column-brandedByStandartCount"
          width="150"
          filter="numeric"
        />

        <GridColumn
          field="BrandedCount"
          title="grids.adminGeneralResults.column-brandedCount"
          width="150"
          filter="numeric"
        />
      </Grid>
    );
  }
}

export default withTranslate(GridAdminGeneralResults);
