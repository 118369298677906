import React, { PureComponent } from 'react';
import { Modal, Button, Input } from 'antd';
import { formatDate } from '@telerik/kendo-intl';

import { withTranslate } from '../../../contexts/localContext';

import './SuspendedFinancialTransactionsGrid.scss';

const { TextArea } = Input;

class CommentHistoryDialog extends PureComponent {
  render() {
    const { translate, visible, onClose, commentsHistory, currentPaymentId } = this.props;

    return (
      <Modal
        title={`${translate('page.suspendedFinancialTransactions.commentHistory')} ${currentPaymentId}`}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="submit" type="primary" onClick={onClose} style={{ margin: '0 auto' }}>
            {translate('core.buttonTitles.ok')}
          </Button>,
        ]}
        className="CommentHistoryDialog"
      >
        {commentsHistory && commentsHistory.length ? (
          [...commentsHistory].map(({ UserNamePost, DatePost, Comments }) => (
            <div className="CommentHistoryDialog-block">
              <div className="CommentHistoryDialog-blockItem">
                <b>{translate('page.suspendedFinancialTransactions.col-performer')}:</b> {UserNamePost}
              </div>
              <div className="CommentHistoryDialog-blockItem">
                <b>{translate('page.suspendedFinancialTransactions.date')}:</b>{' '}
                {formatDate(new Date(DatePost), 'dd.MM.yyyy HH:mm:ss')}
              </div>
              <div className="CommentHistoryDialog-blockItem">
                <b>{translate('page.suspendedFinancialTransactions.comment')}:</b>
                <TextArea value={Comments} disabled />
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center' }}>{translate('page.suspendedFinancialTransactions.noComment')}</div>
        )}
      </Modal>
    );
  }
}

export default withTranslate(CommentHistoryDialog);
