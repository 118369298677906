export const ALL_GRID_COLUMNS = [
  'ownerName',
  'terminalId',
  'collectionId',
  'regionName',
  'cityName',
  'branch',
  'address',
  'terminalName',
  'userName',
  'datePost',
  'amount',
  'amountActually',
  'amountBills',
  'amountDifference',
  'currency',
  'active',
  'userNameClose',
  'userId',
  'userNameExec',
  'countCash',
  'notes',
  'qrClose',
];

export const NORMALIZE_FIELD_MAP = new Map([
  ['Note', 'notes'],
  ['city_name', 'cityName'],
  ['City_name', 'cityName'],
]);

export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([['notes', 'Note']]);
