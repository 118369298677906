export const PROPS_COLUMN_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.additionalServiceProps.col-prop',
    width: '200',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.additionalServiceProps.col-description',
    width: '300',
    isShowOnMobile: true,
  },
];
