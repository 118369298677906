/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Carousel, Row, Col } from 'antd';
// import Snowfall from 'react-snowfall'
import { withTranslate } from '../../contexts/localContext';
import './login.scss';
import DialogLogin from './dialogLogin';
import DialogRestore from './dialogRestore';
import { VERSION } from '../../environments/version';
import Logo from '../logo-light.svg';
import { widthLoginContext } from '../../contexts/LoginContext';
import { StorageService } from '../../services';
import menus from '../menus';
const { Item } = Menu;
const { Header, Footer, Content } = Layout;

class PageLogin extends PureComponent {
  toDialogRestore = () => {
    const { setLoginContentStateIndex: setContentStateIndex } = this.props;
    setContentStateIndex(1);
  };

  toDialogLogin = () => {
    const { setLoginContentStateIndex: setContentStateIndex } = this.props;
    setContentStateIndex(0);
  };

  changeLocale = ({ key }) => {
    const { changeLocale } = this.props;
    changeLocale(key);
  };

  componentDidMount() {
    const refreshToken = StorageService.getItem('refresh_token');

    if (refreshToken) {
      this.handleNavigate();
      return;
    }

    const { loginContentStateIndex: contentStateIndex } = this.props;

    this.slider.goTo(contentStateIndex);
  }

  componentDidUpdate(prevProps) {
    const { loginContentStateIndex: contentStateIndex } = this.props;
    const { loginContentStateIndex: prevContentStateIndex } = prevProps;

    if (contentStateIndex !== prevContentStateIndex) {
      this.slider.goTo(contentStateIndex);
    }
  }

  navigate(path) {
    const { history } = this.props;
    history.push(path);
  }

  handleNavigate = () => {
    const backConfig = StorageService.getItem('backConfig');
    const { history } = this.props;
    const { search } = history.location;

    const oldPassword = search.split('oldPassword=')[1];

    if (oldPassword) {
      return;
    }

    if (!backConfig || !backConfig.length) {
      this.navigate('/pages/contacts');
      return;
    }

    if (backConfig.some(({ WebTag }) => WebTag === 'dashboard')) {
      this.navigate('/pages');
      return;
    }

    const pathList = menus.filter(({ requirePermission }) =>
      backConfig.some(({ WebTag }) => requirePermission === WebTag),
    );

    if (!pathList || !pathList.length) {
      this.navigate('/pages/contacts');
      return;
    }

    this.navigate(pathList[0].route);
  };

  render = () => {
    const { translate } = this.props;

    return (
      <Layout className="page-login">
        {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Snowfall snowflakeCount={550} />
        </div> */}
        <Header>
          <img src={Logo} alt="EasyPay" />
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['UA']} onClick={this.changeLocale}>
            <Item key="uk">UA</Item>
            <Item key="ru">RU</Item>
            <Item key="en">EN</Item>
          </Menu>
        </Header>

        <Content>
          <div className="login-carousel-wrapper">
            {/* <img alt="ChristmasHat" src={ChristmasHat} className="christmas-hat-img" /> */}
            <Carousel dots={false} ref={(c) => (this.slider = c)} effect="fade">
              <div>
                <DialogLogin
                  onAction={this.toDialogRestore}
                  handleNavigate={this.handleNavigate}
                  navigate={this.navigate}
                />
              </div>
              <div>
                <DialogRestore onAction={this.toDialogLogin} />
              </div>
              {/* <div>
                <DialogChangePassword login={login} onAction={this.next} />
              </div> */}
            </Carousel>
          </div>
        </Content>

        <Footer>
          <Row type="flex">
            <Col md={12} sm={24}>
              {/*ver: {VERSION['tag']}-{VERSION['distance']}-{VERSION['hash']}*/}
            </Col>
            <Col md={12} sm={24}>
              <p>
                <span>
                  Easypay ©{new Date().getFullYear()} {translate('login.made-with')}
                </span>
                <span className="k-icon k-i-heart" />
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    );
  };
}

export default withRouter(withTranslate(widthLoginContext(PageLogin)));
