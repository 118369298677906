import React, { PureComponent } from 'react';
import { Button, message, Modal, Select } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './TechnicalPartnersGrid.styled.scss';
import CustomInput from '../../../components/Input/Input';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { BooleanData, TypesData } from './constants';
import RemoteTerminalSettingsService from './RemoteTerminalSettings.service';

const api = new ApiService();

const { Option } = Select;

class RemoteTerminalSettingsAddDialog extends PureComponent {
  state = {
    isLoading: false,
    settlementTypes: [],
    MFOList: [],
  };

  renderInput = ({ name, isANumber = false, type }) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <div className="TechnicalPartnersGrid-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={RemoteTerminalSettingsService.getValidators(translate, isANumber)}
          name={name}
          type={type}
        />
      </div>
    );
  };

  renderInputRow = ({ label, inputComponent, className }) => {
    const { translate } = this.props;

    return (
      <div className={`TechnicalPartnersGrid-inputRow ${className || ''}`}>
        <div className="TechnicalPartnersGrid-label">{translate(label)}</div>
        {inputComponent}
      </div>
    );
  };

  returnDefaultValueByType = (type) => {
    const { formState } = this.props;
    switch (type) {
      case 'int':
        return `${formState.DefaultValue}`;
      case 'string':
        return formState.DefaultValue;
      case 'bool':
        return formState.DefaultValue;
      default:
        return '';
    }
  };

  renderValueByType = (type) => {
    const { translate, formState, onChangeFormState } = this.props;
    switch (type) {
      case 'int':
        return this.renderInputRow({
          label: 'page.remoteTerminalSettings.col-defaultValue',
          inputComponent: this.renderInput({
            name: 'DefaultValue',

            isANumber: true,
            type: 'number',
          }),
        });
      case 'string':
        return this.renderInputRow({
          label: 'page.remoteTerminalSettings.col-defaultValue',
          inputComponent: this.renderInput({ name: 'DefaultValue', type: 'string' }),
        });
      case 'bool':
        return this.renderInputRow({
          label: 'page.remoteTerminalSettings.col-defaultValue',
          inputComponent: (
            <Select
              style={{ width: '100%' }}
              onChange={(item) => {
                onChangeFormState('DefaultValue', item);
              }}
              value={formState.DefaultValue}
              placeholder={translate('page.remoteTerminalSettings.select-defaultValue')}
            >
              {BooleanData.map(({ Id, Name }) => (
                <Option value={Id} key={Id}>
                  {Name}
                </Option>
              ))}
            </Select>
          ),
          className: 'TechnicalPartnersGrid-settlementTypeSelect',
        });
      default:
        return this.renderInputRow({
          label: 'page.remoteTerminalSettings.col-defaultValue',
          inputComponent: this.renderInput({ name: 'DefaultValue', key: 'DefaultValue-string-default' }),
        });
    }
  };

  terminalSettingsExtendedKeyAdd = async () => {
    const {
      formState: { Key, Name, Type },
      onRefresh,
      closeModal,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api.terminalSettingsExtendedKeyAdd({
        Key,
        Name,
        Type: Type,
        DefaultValue: this.returnDefaultValueByType(Type),
      });

      this.setState({ isLoading: false });
      await closeModal();
      message.success(translate(`page.remoteTerminalSettings.remoteTerminalSettingsSuccessfulAdded`), 2.5);
      await onRefresh();
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });

      showError(error);
    }
  };

  render() {
    const { variant, closeModal, translate, formState, onChangeFormState, formErrors, setError } = this.props;
    const { isLoading } = this.state;
    const isAddAction = variant === 'add';
    const isEmptyValueExist =
      !formState.Key || !formState.Name || formState.Type.length === 0 || !formState.DefaultValue;

    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.remoteTerminalSettings.addDialogTitle`)}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={this.terminalSettingsExtendedKeyAdd}
            disabled={isEmptyValueExist || isErrorExist}
          >
            {translate(`core.buttonTitles.add`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        className="TechnicalPartnersGrid"
      >
        <div>
          {this.renderInputRow({
            label: 'page.remoteTerminalSettings.col-key',
            inputComponent: this.renderInput({ name: 'Key' }),
          })}

          {this.renderInputRow({
            label: 'page.remoteTerminalSettings.col-name',
            inputComponent: this.renderInput({ name: 'Name', type: 'textArea' }),
          })}
          {this.renderInputRow({
            label: 'page.remoteTerminalSettings.col-type',
            inputComponent: (
              <Select
                style={{ width: '100%' }}
                onChange={(item) => {
                  onChangeFormState('Type', item);
                  onChangeFormState('DefaultValue', '');
                }}
                value={formState.Type}
                placeholder={translate('page.remoteTerminalSettings.selectType')}
              >
                {TypesData.map(({ Id, Name }) => (
                  <Option value={Id} key={Id}>
                    {Name}
                  </Option>
                ))}
              </Select>
            ),
            className: 'TechnicalPartnersGrid-settlementTypeSelect',
          })}
          {this.renderValueByType(formState.Type)}
          {isLoading && <LoadingPanel />}
        </div>
      </Modal>
    );
  }
}

export default withTranslate(RemoteTerminalSettingsAddDialog);
