import React, { PureComponent } from 'react';

import ServicesAuthorizationLimitsGrid from './ServicesAuthorizationLimitsGrid';
import PageTabs from '../../components/tabber/tabber';

class ServicesAuthorizationLimits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-servicesAuthorizationLimits',
          content: <ServicesAuthorizationLimitsGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} />;
  }
}

export default ServicesAuthorizationLimits;
