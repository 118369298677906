import React, { PureComponent } from 'react';

import ActionBlockRow from './ActionBlockRow';
import ExpressionBlock from './ExpressionBlock';
import { generateGUID } from '../../utils/generateGUID';

import './FilterConstructorModal.styled.scss';

class FilterActionBlock extends PureComponent {
  renderParentComponent = () => {
    const { data, updateData, removeAction } = this.props;

    return (
      <ActionBlockRow
        operator={data.logic}
        setOperator={(operator) => {
          updateData({ ...data, logic: operator });
        }}
        removeAction={removeAction}
        addAction={(type) => {
          updateData({
            ...data,
            filters: [
              ...data.filters,
              type === 'expression'
                ? {
                    field: '',
                    operator: '',
                    value: '',
                    id: generateGUID(),
                  }
                : {
                    logic: 'and',
                    filters: [
                      {
                        field: '',
                        operator: '',
                        value: '',
                        id: generateGUID(),
                      },
                    ],
                    id: generateGUID(),
                  },
            ],
          });
        }}
      />
    );
  };

  getUpdateChildActionDate = (guid) => (currentData) => {
    const { data, updateData } = this.props;

    updateData({ ...data, filters: data.filters.map((item) => (item.id === guid ? currentData : item)) });
  };

  getRemoveChildAction = (guid) => () => {
    const { data, updateData } = this.props;

    updateData({ ...data, filters: data.filters.filter((item) => item.id !== guid) });
  };

  render() {
    const { data, fieldsInfo } = this.props;

    return (
      <>
        <div>{this.renderParentComponent()}</div>
        {data.filters.length ? (
          <ul className="FilterConstructorModal-treeListWrapper">
            {data.filters.map((item) => (
              <li className="FilterConstructorModal-treeActionListItemWrapper" key={item.id}>
                <div className="FilterConstructorModal-treeActionListItemWrapperVerticalLink" />

                <div className="FilterConstructorModal-treeActionWrapper">
                  <div className="FilterConstructorModal-treeActionWrapperHorizontalLink" />

                  {item.logic ? (
                    <FilterActionBlock
                      data={item}
                      updateData={this.getUpdateChildActionDate(item.id)}
                      removeAction={this.getRemoveChildAction(item.id)}
                      fieldsInfo={fieldsInfo}
                    />
                  ) : (
                    <ExpressionBlock
                      data={item}
                      updateData={this.getUpdateChildActionDate(item.id)}
                      removeBlock={this.getRemoveChildAction(item.id)}
                      fieldsInfo={fieldsInfo}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default FilterActionBlock;
