const PartnerInfoSender = (translate, value) => {
  switch (value) {
    case 'partnerInfoSender-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'partnerInfoSender-customize':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`page.partnerInfoSenderGrid.customize`)}`;
    case 'partnerInfoSender-edit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.edit`)}`;
    case 'partnerInfoSender-removeCustomization':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(
        `page.partnerInfoSenderGrid.removeCustomization`,
      )}`;
    default:
      return value;
  }
};

export default PartnerInfoSender;
