import React, { PureComponent } from 'react';
import AuthorizationsDictionaryGrid from './AuthorizationsDictionaryGrid';
import PageTabs from '../../components/tabber/tabber';

class AuthorizationsDictionary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-authorizationsDirectory',
          content: <AuthorizationsDictionaryGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}

export default AuthorizationsDictionary;
