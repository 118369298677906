const monitoringCommissionServices = (translate, value) => {
  switch (value) {
    case 'monitoringCommissionServices-pushToCommissions':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.commissionsServices.col-commissionName')}`;
    case 'monitoringCommissionServices-searchCommission':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.search')}`;
    case 'monitoringCommissionServices-deleteCommission':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.delete')}`;
    case 'monitoringCommissionServices-addCommission':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.add')}`;
    case 'monitoringCommissionServices-copyCommission':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('core.buttonTitles.copy')}`;
    case 'monitoringCommissionServices-grid-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'monitoringCommissionServices-grid-ServiceId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-serviceNumber')}`;
    case 'monitoringCommissionServices-grid-ProfileId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.commission-profiles.column-profileId')}`;
    case 'monitoringCommissionServices-grid-ProfileName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.profile')}`;
    case 'monitoringCommissionServices-grid-ServiceName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-serviceName')}`;
    case 'monitoringCommissionServices-grid-CommissionId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-commissionNumber')}`;
    case 'monitoringCommissionServices-grid-CommissionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-commissionName')}`;
    case 'monitoringCommissionServices-grid-DateStart':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-beginningDate')}`;
    case 'monitoringCommissionServices-grid-DateEnd':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-expirationDate')}`;
    case 'monitoringCommissionServices-grid-Active':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-isEnabled')}`;
    case 'monitoringCommissionServices-grid-ClientCommissionRule':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-rule')}`;
    case 'monitoringCommissionServices-grid-Priority':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-priority')}`;
    case 'monitoringCommissionServices-grid-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-dateOfChange')}`;
    case 'monitoringCommissionServices-grid-PaymentType':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-paymentTool')}`;
    case 'monitoringCommissionServices-grid-UserNamePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.commissionsServices.col-executor')}`;
    default:
      return value;
  }
};

export default monitoringCommissionServices;
