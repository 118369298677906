class RemoteTerminalSettingsService {
  getKEYValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }
    const regex = /^[a-zA-Z._]+$/;

    if (!regex.test(value)) return `${translate('page.remoteTerminalSettings.getKEYValidation-onlyLat')}`;

    if (value.length > 150) {
      return `${translate('page.remoteTerminalSettings.getKEYValidation-maxLength')}`;
    }

    return '';
  };

  getNameValidation = (translate) => (value) => {
    if (!value) {
      return '';
    }

    if (value.length > 256) {
      return `${translate('page.remoteTerminalSettings.getNameValidation-maxLength')}`;
    }

    return '';
  };

  getDefaultValueValidation = (translate, isANumber) => (value) => {
    if (!value) {
      return '';
    }

    const regexString = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/;

    if (isANumber) {
      const regex = /^\d+$/;
      if (!regex.test(value)) return `${translate('page.templates.err-msg')}`;
    } else if (!regexString.test(value)) return `${translate('page.remoteTerminalSettings.getDefaultValueValidation-onlyLat')}`;

    return '';
  };

  getValidators = (translate, isANumber) => ({
    Key: this.getKEYValidation(translate),
    Name: this.getNameValidation(translate),
    DefaultValue: this.getDefaultValueValidation(translate, isANumber),
  });
}

export default new RemoteTerminalSettingsService();
