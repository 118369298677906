import React, { PureComponent } from 'react';

import TechnicalPartnersGrid from './TechnicalPartnersGrid';
import PageTabs from '../../components/tabber/tabber';

class PageReportServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-technicalPartners',
          content: <TechnicalPartnersGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}

export default PageReportServices;
