const screenControlBusinessServices = (translate, value) => {
  switch (value) {
    case 'screenControlBusinessServices-btn-assign':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.profilesMainProfileList.modal-assign-template-title')}`;
    case 'screenControlBusinessServices-btn-create':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.profilesMainProfileList.modal-create-template-title')}`;
    case 'screenControlBusinessServices-btn-delete':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('grids.profilesMainProfileList.modal-delete-template-title')}`;
    case 'screenControlBusinessServices-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-datePost')}`;
    case 'screenControlBusinessServices-MainProfileHoldId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-mainProfileHoldId')}`;
    case 'screenControlBusinessServices-link-edit':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.screenControlBusinessServices.tab-edit-template')}`;
    case 'screenControlBusinessServices-MainProfileCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-mainProfileCount')}`;
    case 'screenControlBusinessServices-MainProfileHoldName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-mainProfileHoldName')}`;
    case 'screenControlBusinessServices-MainProfileHoldVersion':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-mainProfileHoldVersion')}`;
    case 'screenControlBusinessServices-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    case 'screenControlBusinessServices-UserIdName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.businessServices.column-userIdName')}`;
    case 'screenControlBusinessServicesEditBusinessService-btn-submit':
      return `${translate('page.adminPanel.type-tab')}
              ${translate('page.screenControlBusinessServices.tab-edit-template')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.screenControlBusinessServices.button-edit')}`;
    default:
      return value;
  }
};

export default screenControlBusinessServices;
