import React, { PureComponent } from 'react';

import FormStateContainer from '../../../../components/FormStateContainer';

import UpdateModal from './UpdateModal';
import { INITIAL_VALUES } from './initialValues';

class UpdateModalContainer extends PureComponent {
  render() {
    return (
      <FormStateContainer initialValues={INITIAL_VALUES}>
        <UpdateModal {...this.props} />
      </FormStateContainer>
    );
  }
}

export default UpdateModalContainer;
