import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Tooltip, InputNumber, Input, Radio, message } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import '../../addingAdressedPayment/createApplication/createApplication.scss';
import '../modalAddAgent/modalAddAgent.scss';

const { TextArea } = Input;

const api = new ApiService();

class ModalAddRefusalAgent extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateGrid: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    agentName: null,
    edrpou: null,
    isErrorEDRPOU: false,
    inn: null,
    isErrorInn: false,
    radioValue: 'edrpou',
    agentAddress: null,
    bossName: null,
    comment: null,
  };

  onChangeEventInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  validateINN = async () => {
    const { showError } = this.props;
    const { inn } = this.state;
    if (String(inn).length !== 10) return;
    else {
      try {
        this.toggleLoader();
        const request = await api.validateInn(inn);
        this.setState({ isErrorInn: false });
        return request;
      } catch (error) {
        showError(error);
        this.setState({ isErrorInn: true });
      } finally {
        this.toggleLoader();
      }
    }
  };

  validateEDRPOU = async () => {
    const { showError } = this.props;
    const { edrpou } = this.state;
    if (String(edrpou).length !== 8) return;
    else {
      try {
        this.toggleLoader();
        const request = await api.validateInn(edrpou);
        this.setState({ isErrorEDRPOU: false });
        return request;
      } catch (error) {
        showError(error);
        this.setState({ isErrorEDRPOU: true });
      } finally {
        this.toggleLoader();
      }
    }
  };

  onChangeEDRPOU = (edrpou) => {
    this.setState({ edrpou }, this.validateEDRPOU);
  };

  onChangeINN = (inn) => {
    this.setState({ inn }, this.validateINN);
  };

  onChangeRadio = (e) => {
    this.setState({ radioValue: e.target.value }, () => this.nullifyPrevRadioInput(e.target.value));
  };

  nullifyPrevRadioInput = (value) => {
    let key = value === 'inn' ? 'edrpou' : 'inn';
    this.setState({ [key]: null });
  };

  universalErrorMessage = (field) => {
    const { translate } = this.props;
    return `${translate('page.terminalSettings.universalErrorMessage-field')}
    ${field} ${translate('page.terminalSettings.universalErrorMessage-first')}
    ${translate('page.terminalSettings.universalErrorMessage-second')}.`;
  };

  onSubmitHandler = async () => {
    const {
      edrpou,
      isErrorEDRPOU,
      inn,
      isErrorInn,
      agentName: Name,
      agentAddress: Address,
      comment: Comment,
      bossName: BossName,
    } = this.state;
    const { translate, showError, updateGrid, toggleModal } = this.props;
    const Edrpo = inn ? inn : edrpou;
    if ((edrpou && edrpou.length < 8) || (edrpou && isErrorEDRPOU)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-radio-EDRPOU-label'))}`,
        2,
      );
    }

    if ((inn && inn.length < 10) || (inn && isErrorInn)) {
      return message.error(
        `${this.universalErrorMessage(translate('page.adressedPaymentCreate.field-radio-INN-label'))}`,
        2,
      );
    } else {
      try {
        this.toggleLoader();
        const model = {
          AgentId: null,
          Name,
          Edrpo,
          BossName,
          Address,
          Comment,
          DateRefused: null,
        };
        await api.dictionaryRefusedAgentMerge(model);
        updateGrid();
        toggleModal();
      } catch (error) {
        showError(error);
      } finally {
        this.toggleLoader();
      }
    }
  };

  render() {
    const { translate, visible, toggleModal } = this.props;
    const { isLoading, agentName, edrpou, inn, radioValue, agentAddress, bossName, comment } = this.state;
    const isDisabledBtnCreate = !agentName || (!edrpou && !inn) || !agentAddress || !comment || !bossName;
    return (
      <Modal
        title={translate('grids.dictionaryAgentsGet.btn-add-refusal')}
        visible={visible}
        centered
        onOk={toggleModal}
        onCancel={toggleModal}
        className="modal-add-agent"
        // zIndex={'1053'}
        footer={[
          <Button key="back" onClick={toggleModal}>
            {translate('page.adressedPaymentCreate.btn-add-agent-no')}
          </Button>,
          <Button key="submit" type="primary" disabled={isDisabledBtnCreate} onClick={this.onSubmitHandler}>
            {translate('page.adressedPaymentCreate.btn-add-agent-yes')}
          </Button>,
        ]}
      >
        <div className="createApplication--wrapper">
          <div className="createApplication--subwrapper">
            <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
              <Col sm={24} lg={15}>
                <p className="createApplication-elem-label">
                  {translate('grids.dictionaryAgentsGet.column-name')}:
                  <span className="createApplication-elem-required">*</span>
                </p>
              </Col>
              <Col sm={24} lg={9}>
                <Tooltip
                  trigger={['focus']}
                  title={translate('grids.dictionaryAgentsGet.field-name-placeholder')}
                  placement="bottom"
                >
                  <Input
                    placeholder={translate('grids.dictionaryAgentsGet.field-name-placeholder')}
                    value={agentName}
                    name="agentName"
                    onChange={this.onChangeEventInput}
                    maxLength={150}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row className="createApplication--row space--between" align="middle" type="flex" gutter={24}>
              <Col sm={24} lg={8}>
                <p className="createApplication-elem-label">
                  {translate('page.adressedPaymentCreate.radio-label')}:
                  <span className="createApplication-elem-required">*</span>
                </p>
              </Col>
              <Col sm={24} lg={16}>
                <Radio.Group value={radioValue} onChange={this.onChangeRadio} className="createApplication-radio-group">
                  <Row className="createApplication-radio-group-row" align="middle" type="flex" gutter={24}>
                    <Col sm={24} lg={12}>
                      <Row className="createApplication-radio-group-subrow" align="middle" type="flex" gutter={24}>
                        <Radio value="edrpou">
                          <p className="createApplication-elem-label">
                            {translate('page.adressedPaymentCreate.field-radio-EDRPOU-label')}:
                          </p>
                        </Radio>
                        <Tooltip
                          trigger={['focus']}
                          title={translate('page.adressedPaymentCreate.field-radio-EDRPOU-placeholder')}
                          placement="bottom"
                        >
                          <InputNumber
                            placeholder={translate('page.adressedPaymentCreate.field-radio-EDRPOU-placeholder')}
                            maxLength={8}
                            min={0}
                            value={edrpou}
                            onChange={this.onChangeEDRPOU}
                            disabled={radioValue === 'inn'}
                          />
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col sm={24} lg={12}>
                      <Row className="createApplication-radio-group-subrow" align="middle" type="flex" gutter={24}>
                        <Radio value="inn">
                          <p className="createApplication-elem-label">
                            {translate('page.adressedPaymentCreate.field-radio-INN-label')}:
                          </p>
                        </Radio>
                        <Tooltip
                          trigger={['focus']}
                          title={translate('page.adressedPaymentCreate.field-radio-INN-placeholder')}
                          placement="bottom"
                        >
                          <InputNumber
                            placeholder={translate('page.adressedPaymentCreate.field-radio-INN-placeholder')}
                            maxLength={10}
                            min={0}
                            value={inn}
                            onChange={this.onChangeINN}
                            disabled={radioValue === 'edrpou'}
                          />
                        </Tooltip>
                      </Row>
                    </Col>
                  </Row>
                </Radio.Group>
              </Col>
            </Row>
            <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
              <Col sm={24} lg={15}>
                <p className="createApplication-elem-label">
                  {translate('grids.dictionaryAgentsGet.field-agentAddress-title')}:
                  <span className="createApplication-elem-required">*</span>
                </p>
              </Col>
              <Col sm={24} lg={9}>
                <Tooltip
                  trigger={['focus']}
                  title={translate('grids.dictionaryAgentsGet.field-agentAddress-placeholder')}
                  placement="bottom"
                >
                  <Input
                    placeholder={translate('grids.dictionaryAgentsGet.field-agentAddress-placeholder')}
                    value={agentAddress}
                    name="agentAddress"
                    onChange={this.onChangeEventInput}
                    maxLength={150}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
              <Col sm={24} lg={15}>
                <p className="createApplication-elem-label">
                  {translate('grids.dictionaryAgentsGet.column-bossName')}:
                  <span className="createApplication-elem-required">*</span>
                </p>
              </Col>
              <Col sm={24} lg={9}>
                <Tooltip
                  trigger={['focus']}
                  title={translate('grids.dictionaryAgentsGet.field-bossName-placeholder')}
                  placement="bottom"
                >
                  <Input
                    placeholder={translate('grids.dictionaryAgentsGet.field-bossName-placeholder')}
                    value={bossName}
                    name="bossName"
                    onChange={this.onChangeEventInput}
                    maxLength={150}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row className="createApplication--row" align="middle" type="flex" gutter={24}>
              <Col sm={24} lg={15}>
                <p className="createApplication-elem-label">
                  {translate('grids.transactions.column-comment')}:
                  <span className="createApplication-elem-required">*</span>
                </p>
              </Col>
              <Col sm={24} lg={9}>
                <Tooltip
                  trigger={['focus']}
                  title={translate('grids.dictionaryAgentsGet.field-comment-placeholder')}
                  placement="bottom"
                >
                  <TextArea
                    placeholder={translate('grids.dictionaryAgentsGet.field-comment-placeholder')}
                    value={comment}
                    rows={3}
                    name="comment"
                    onChange={this.onChangeEventInput}
                    maxLength={1000}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ModalAddRefusalAgent);
