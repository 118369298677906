export const testData = [
  {
    ServiceId: 1455,
    ServiceName: 'Банк ПУМБ поповнення карток',
    PaymentType: 'Cash',
    CodeNBU: '06',
    CreateDate: '2023-03-01T16:41:15.703Z',
    Performer: 'Кулька Лариса',
  },
  {
    ServiceId: 1455,
    ServiceName: 'Банк ПУМБ поповнення карток',
    PaymentType: 'VCash',
    CodeNBU: '56',
    CreateDate: '2023-02-27T16:41:15.703Z',
    Performer: 'Кулька Лариса',
  },
  {
    ServiceId: 1455,
    ServiceName: 'Банк ПУМБ поповнення карток',
    PaymentType: 'PosCard',
    CodeNBU: '56',
    CreateDate: '2023-02-24T16:41:15.703Z',
    Performer: 'Кулька Лариса',
  },
  {
    ServiceId: 1455,
    ServiceName: 'Банк ПУМБ поповнення карток',
    PaymentType: 'PCash',
    CodeNBU: '06',
    CreateDate: '2023-02-22T16:41:15.703Z',
    Performer: 'Кулька Лариса',
  },
  {
    ServiceId: 734,
    ServiceName: 'Універсалл Банк (поповнення карток)',
    PaymentType: 'Cash',
    CodeNBU: '56',
    CreateDate: '2023-02-20T16:41:15.703Z',
    Performer: 'Барабуля Степан',
  },
  {
    ServiceId: 734,
    ServiceName: 'Універсалл Банк (поповнення карток)',
    PaymentType: 'VCash',
    CodeNBU: '06',
    CreateDate: '2023-02-18T16:41:15.703Z',
    Performer: 'Барабуля Степан',
  },
  {
    ServiceId: 734,
    ServiceName: 'Універсалл Банк (поповнення карток)',
    PaymentType: 'MasterPass',
    CodeNBU: '56',
    CreateDate: '2023-02-16T16:41:15.703Z',
    Performer: 'Барабуля Степан',
  },
];

export const CODE_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '400',
    isShowOnMobile: true,
  },

  {
    fieldName: 'Active',
    titleTranslateSlug: 'page.paymentTypeNBUCode.active',
    width: '100',
    isShowOnMobile: true,
  },
];

export const NBU_CODES = [
  {
    NBUId: 60,
    NBUName: 'First Code',
  },
  {
    NBUId: 56,
    NBUName: 'Second Code',
  },
  {
    NBUId: 6,
    NBUName: 'Third Code',
  },
];
