import React, { PureComponent } from 'react';
import { Input } from 'antd';

import { ApiService, StorageService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { withTranslate } from '../../../contexts/localContext';
import translateElements from '../translateElements';
import ElementsMatrixGrid from '../../../components/ElementsMatrixGrid';

import './restrictions.scss';

const api = new ApiService();

function checkLatinSymbols(str) {
  return /[a-zA-Z]/.test(str);
}

class Restrictions extends PureComponent {
  state = {
    isLoading: false,
    selectedWebtag: '',
    resultCheckedElements: [],
    formatWebTagList: [],
    allElements: [],
    formatElementList: [],
  };

  componentDidMount() {
    this.initQuery();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedWebtag } = this.state;

    if (selectedWebtag && prevState.selectedWebtag !== selectedWebtag) {
      this.initQuery();
    }
  }

  // updateBackConfig = () => {
  //   try {
  //     this.toggleLoader(true);
  //     const backEndConfig = await api.getElementsFromBack({ Elements: [] });
  //     StorageService.setItem('backConfig', backEndConfig);
  //   } catch (error) {
  //     const { showError } = this.props;
  //     showError(error);
  //   } finally {
  //     this.toggleLoader(false);
  //   }
  // };

  componentWillUnmount = async () => {
    try {
      this.toggleLoader(true);
      const backEndConfig = await api.getElementsFromBack({ Elements: [] });
      StorageService.setItem('backConfig', backEndConfig);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  onChangeSelectedWebTag = (selectedWebtag) => {
    this.setState({ selectedWebtag });
  };

  initQuery = async () => {
    const {
      userInfo: { UserId },
    } = this.props;
    const { selectedWebtag, allElements: prevAllElements } = this.state;

    if (!UserId) return;
    try {
      this.toggleLoader(true);
      const resultCheckedWebTags = await api.postGetWebTagsByUser(UserId);
      const allElements = prevAllElements.length ? prevAllElements : await api.postGetAllElements({ Elements: [] });
      const checkedWebTagList =
        resultCheckedWebTags && resultCheckedWebTags.length
          ? resultCheckedWebTags.map(({ webTag: checkedWebTag, ...otherWebTag }) => {
              const currentTagInfo = allElements.find(({ WebTag }) => WebTag === checkedWebTag);

              return {
                ...otherWebTag,
                webTag: checkedWebTag,
                checked: true,
                Elements: currentTagInfo ? currentTagInfo.Elements : [],
              };
            })
          : [];
      let unCheckedWebTagList = allElements
        .map(({ WebTag, ...otherElementProps }) => ({ ...otherElementProps, webTag: WebTag, checked: false }))
        .filter(({ webTag }) => !checkedWebTagList.find(({ webTag: checkedWebTag }) => checkedWebTag === webTag));

      const formatWebTagList = [...checkedWebTagList, ...unCheckedWebTagList]
        .filter(({ Elements, webTag }) => Elements && webTag)
        .reduce((acc, item) => (acc.find((accItem) => accItem.webTag === item.webTag) ? [...acc] : [...acc, item]), []);

      await this.setInitialSelected(allElements);

      const resultCheckedElements = await api.postGetElementsByUser({
        SuserId: UserId,
        Elements: [''],
      });

      this.setState(
        {
          formatWebTagList,
          allElements,
          resultCheckedElements,
        },
        () => this.updateElements(selectedWebtag, allElements, resultCheckedElements),
      );
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  setInitialSelected = (response) => {
    this.setState(({ selectedWebtag }) => {
      if (!selectedWebtag) {
        return {
          selectedWebtag: response[0] ? response[0].WebTag : null,
        };
      }
    });
  };

  getCheckedElementsByGrid = (isChecked, selectedTag, allElements) => {
    const { translate } = this.props;
    const checkedWebTag = allElements.find(({ WebTag }) => WebTag === selectedTag);
    return checkedWebTag
      ? checkedWebTag.Elements.map(({ ElementName, ...otherElementProps }) => {
          return {
            ...otherElementProps,
            ElementName,
            checked: isChecked,
            controlTag: selectedTag,
            translatedElementName: translateElements(translate, `${ElementName}`, selectedTag),
          };
        })
      : [];
  };

  getCheckedFieldsByElements = (isChecked, elementsList) => {
    const { translate } = this.props;

    return elementsList.reduce((acc, { ElementName, Fields, ElementTypeName, controlTag }) => {
      if (ElementTypeName !== 'Grid' || !Fields.length) {
        return acc;
      }
      const customFields = Fields.map(({ FieldName, ...otherFieldProps }) => ({
        ...otherFieldProps,
        gridName: ElementName,
        checked: isChecked,
        FieldName,
        ElementName: `${ElementName}-${FieldName}`,
        translatedElementName: translateElements(translate, `${ElementName}-${FieldName}`, controlTag),
        ElementTypeName: 'Column',
      }));

      return [...acc, ...customFields];
    }, []);
  };

  updateElements = (selectedWebtag, allElements, resultCheckedElements) => {
    let checkedElementsBySelectWebTag = this.getCheckedElementsByGrid(true, selectedWebtag, resultCheckedElements);
    let uncheckedElementsBySelectWebTag = this.getCheckedElementsByGrid(false, selectedWebtag, allElements).filter(
      ({ ElementName }) =>
        !checkedElementsBySelectWebTag.find(
          ({ ElementName: checkedElementName }) => ElementName === checkedElementName,
        ),
    );

    let checkedFieldsByElementsList = this.getCheckedFieldsByElements(true, checkedElementsBySelectWebTag);
    let uncheckedFieldsByElementsList = this.getCheckedFieldsByElements(
      false,
      this.getCheckedElementsByGrid(false, selectedWebtag, allElements),
    ).filter(
      ({ ElementName }) =>
        !checkedFieldsByElementsList.find(({ ElementName: checkedElementName }) => ElementName === checkedElementName),
    );

    const formatElementList = [
      ...checkedElementsBySelectWebTag,
      ...uncheckedElementsBySelectWebTag,
      ...checkedFieldsByElementsList,
      ...uncheckedFieldsByElementsList,
    ].filter((el) => !checkLatinSymbols(el.translatedElementName));

    this.setState({ formatElementList });
  };

  onElementsGridRowClick = (dataItem) => {
    const {
      userInfo: { UserId },
    } = this.props;
    const type = dataItem.ElementTypeName === 'Column' ? 'FieldId' : 'ElementId';

    const { showError } = this.props;
    if (!UserId) return;
    const { checked } = dataItem;
    try {
      this.setState({ isLoading: true });
      const model = {
        SUserId: UserId,
        [`${type}s`]: [dataItem[type]],
        Hide: checked,
      };
      const requestMethod = type === 'ElementId' ? api.postWtmElementsHide : api.postWtmFieldHide;
      requestMethod(model);
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, formatWebTagList, formatElementList } = this.state;
    const { userInfo, translate } = this.props;

    const { FirstName, LastName } = userInfo;

    return (
      <>
        <div className="input-user-wrapper">
          <div className="input-user-subwrapper">
            <p>{translate('page.adminPanel.label-user')}:</p>
            <Input value={`${FirstName} ${LastName}`} disabled />
          </div>
        </div>

        <ElementsMatrixGrid
          formatWebTagList={formatWebTagList}
          formatElementList={formatElementList}
          onChangeSelectedWebTag={this.onChangeSelectedWebTag}
          onElementsGridRowClick={this.onElementsGridRowClick}
        />

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(Restrictions);
