import React, { PureComponent } from 'react';
import { Modal, Button, Col, Row, Select } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { tabService } from '../../../../services';
import '../modalProfile.scss';
import LoadingPanel from '../../../../components/loader';

const { Option } = Select;

class ModalCreateBusinessService extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = this.props;
    if (!data || data.length < 1) return;
    this.state = {
      loading: false,
      isLoading: false,
      selected: null,
      data,
    };
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  onSelectChange = (selected) => {
    this.setState({ selected });
  };

  createProfile = () => {
    const { selected, data } = this.state;
    const { closeModal, fieldsForSelection } = this.props;

    const currentElem = data.find((el) => el[fieldsForSelection.Id] === Number(selected));

    closeModal();
    tabService.addTab({
      type: 'newTemplate',
      dataItem: { ...currentElem },
    });
  };

  render() {
    const { translate, visible, closeModal, fieldsForSelection } = this.props;
    const { loading, isLoading, selected, data } = this.state;
    const defaultValue = translate('page.screenControlBusinessServices.default-template-value');
    return (
      <>
        <Modal
          title={translate('grids.profilesMainProfileList.modal-create-template-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button key="submit" type="primary" disabled={!selected} loading={loading} onClick={this.createProfile}>
              {translate('grids.profilesMainProfileList.modal-create-profile-btn-submit')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <p style={{ marginBottom: '0' }}>{translate('grids.businessServices.modal-create-template-text')}</p>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Select
                className="modal-system-encashment--select"
                placeholder={defaultValue}
                dropdownStyle={{ zIndex: '1054', width: '100%' }}
                onChange={this.onSelectChange}
                // filterOption={(input, option) =>
                //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                filterOption={true}
                optionFilterProp="children"
                showSearch
              >
                {data.map((service) => (
                  <Option
                    value={`${service[fieldsForSelection.Id]}`}
                    key={`profile-number-${service[fieldsForSelection.Name]}-${service[fieldsForSelection.Id]}`}
                  >
                    {service[fieldsForSelection.Name]} - {service[fieldsForSelection.Version]}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalCreateBusinessService);
