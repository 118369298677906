const managementMenuProfiles = (translate, value) => {
  switch (value) {
    case 'managementMenuProfiles-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'managementMenuProfiles-isShowProfileOptions':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('page.profilesMenu.profileName')}`;
    case 'managementMenuProfiles-updateProfileName':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.profilesMenu.btn-change-name')}`;
    case 'managementMenuProfiles-addProfile':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.profilesMenu.btn-add-profile')}`;
    case 'managementMenuProfiles-deleteProfile':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.profilesMenu.btn-delete-profile')}`;
    case 'managementMenuProfiles-updateProfileConfiguration':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.profilesMenu.btn-update-configuration')}`;
    case 'managementMenuProfiles-updateProfilesChildsConfiguration':
      return `${translate(`page.adminPanel.dropdown-button`)}:
                          ${translate('page.profilesMenu.btn-update-config-child')}`;
    case 'managementMenuProfiles-redirectElementTab':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.profilesMenu.show-element-menu')}`;
    case 'managementMenuProfiles-isShowElementOptions':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('page.profilesMenu.elementName')}`;
    case 'managementMenuProfiles-updateElementName':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.profilesMenu.change-name-element')}`;
    case 'managementMenuProfiles-createElement':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.profilesMenu.btn-create-element')}`;
    case 'managementMenuProfiles-cloneElement':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.profilesMenu.clone-element')}`;
    case 'managementMenuProfiles-deleteElement':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.profilesMenu.delete-element')}`;
    case 'managementMenuProfiles-moveElement':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.dropdown-button`)}:
                        ${translate('page.profilesMenu.moveElement')}`;
    case 'managementMenuProfiles-redirectElementPropsTab':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.profileElementsTab')}:
                        ${translate(`page.adminPanel.type-navLink`)}:
                        ${translate('page.profilesMenu.elementProps')}`;
    case 'managementMenuProfiles-elementPropAdd':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.elementProps')}:
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.add')}`;
    case 'managementMenuProfiles-elementPropUpdate':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.elementProps')}:
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.change')}`;
    case 'managementMenuProfiles-elementPropDelete':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.profilesMenu.elementProps')}:
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.delete')}`;
    default:
      return value;
  }
};

export default managementMenuProfiles;
