class GeneralPropsService {
  getParsedValue = (value) => (value || value === 0 ? +`${value}`.replace(',', '.') : null);

  integerNumberValidation = (translate) => (value) => {
    const normalizedValue = this.getParsedValue(value);

    if (isNaN(normalizedValue)) {
      return `${translate('page.serviceMainProps.invalidValueFormat')}`;
    }

    if (!Number.isInteger(normalizedValue) || `${normalizedValue}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    return '';
  };

  getValidators = (translate) => {
    return {
      id: this.integerNumberValidation(translate),
    };
  };
}

export default new GeneralPropsService();
