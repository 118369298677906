import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../../components/Input/Input';
import SuppliersBalanceGridService from './SuppliersBalanceGrid.service';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

import './SuppliersBalanceGrid.scss';

const api = new ApiService();
class UpdateBalanceModal extends PureComponent {
  state = {
    isLoading: false,
  };

  renderInput = ({ name, type }) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;

    return (
      <div className="UpdateBalanceModal-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={SuppliersBalanceGridService.getValidators(translate)}
          name={name}
          type={type}
        />
      </div>
    );
  };

  renderInputRow = ({ label, inputComponent }) => {
    return (
      <div className="UpdateBalanceModal-inputRow">
        <div className="UpdateBalanceModal-label">{label}</div>
        {inputComponent}
      </div>
    );
  };

  getUpdateBalance = (isAddBalanceModal) => async () => {
    const { formState, closeModal, onRefresh, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      await api[isAddBalanceModal ? 'serviceBalanceAdd' : 'serviceBalanceUpdate'](
        Object.entries(formState).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: typeof value === 'boolean' ? value : value || null,
          }),
          {},
        ),
      );

      await this.setState({
        isLoading: false,
      });
      closeModal();
      message.success(
        translate(
          `page.suppliersBalance.${isAddBalanceModal ? 'balanceSuccessfullyAdded' : 'balanceSuccessfullyEdit'}`,
        ),
        2.5,
      );
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { variant, closeModal, translate, formState, formErrors } = this.props;
    const isAddBalanceModal = variant === 'addBalance';
    const { isLoading } = this.state;
    const isRequiredFieldsEmpty =
      !formState.Name || (isAddBalanceModal && !formState.ServiceId && formState.ServiceId !== 0);
    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.suppliersBalance.${variant}`)}
        footer={[
          <Button
            key="back"
            type="primary"
            disabled={isRequiredFieldsEmpty || isErrorExist}
            onClick={this.getUpdateBalance(isAddBalanceModal)}
          >
            {translate(`core.buttonTitles.${isAddBalanceModal ? 'add' : 'edit'}`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {isAddBalanceModal &&
          this.renderInputRow({
            label: translate('page.suppliersBalance.serviceId'),
            inputComponent: this.renderInput({ name: 'ServiceId', type: 'number' }),
          })}
        {this.renderInputRow({
          label: translate('page.suppliersBalance.col-name'),
          inputComponent: this.renderInput({ name: 'Name' }),
        })}
        {this.renderInputRow({
          label: translate('page.suppliersBalance.col-isActive'),
          inputComponent: this.renderInput({ name: 'IsActive', type: 'checkbox' }),
        })}
        {this.renderInputRow({
          label: translate('page.suppliersBalance.col-gateway'),
          inputComponent: this.renderInput({ name: 'GateName' }),
        })}
        {this.renderInputRow({
          label: translate('page.suppliersBalance.col-servicesId'),
          inputComponent: this.renderInput({ name: 'GateServicesList' }),
        })}
        {isAddBalanceModal &&
          this.renderInputRow({
            label: translate('page.suppliersBalance.col-index'),
            inputComponent: this.renderInput({ name: 'Index', type: 'number' }),
          })}
        {isAddBalanceModal &&
          this.renderInputRow({
            label: translate('page.suppliersBalance.col-sumLimit'),
            inputComponent: this.renderInput({ name: 'Limit', type: 'number' }),
          })}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateBalanceModal);
