import React, { PureComponent } from 'react';

import UpdateMenuElementModal from './UpdateMenuElementModal';
import CreateMenuElementModal from './CreateMenuElementModal';
import MoveMenuElementModal from './MoveMenuElementModal';
import LinkElementTargetInfoModal from './LinkElementTargetInfoModal';
import ActionInfoModal from './ActionInfoModal';
import { withTranslate } from '../../contexts/localContext';
import ProfileMenuElementsGrid from './ProfileMenuElementsGrid';
import { ApiService } from '../../services';
import LoadingPanel from '../loader';
import { widthUpdateMenuElementsContext } from './UpdateMenuElementsContext';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import { Button } from '@progress/kendo-react-buttons';

import './ProfileMenuElements.scss';

const api = new ApiService();

class ProfileMenuElements extends PureComponent {
  state = {
    parentMenuElements: [],
    isLoading: false,
  };

  fetchParentMenuElements = async () => {
    const { dataItem, setIsLoading: setIsLoadingByContext, defaultSelectedElements = [] } = this.props;

    try {
      const query = `menu(take: 500, skip: 0,order: [{ profileId: ASC }, { menuId: ASC }],  where: {and:[ {profileId: {in: [${dataItem
        .map(({ profileId }) => profileId)
        .join()}]}, parentId: {in: [0]} }]}) {items{ profileId, menuId, name, isVisible, hasChild, refId }pageInfo
        {hasNextPage, hasPreviousPage}
      }`;
      await this.setState({ isLoading: true });
      setIsLoadingByContext(true);
      const data = await api.getByGraphQl(query);
      const items = data.data.menu.items;
      await this.setState({
        parentMenuElements:
          items && items.length
            ? items.map((item) => ({
                ...item,
                parentId: 0,
                id: `${item.profileId}-${item.menuId}`,
                selected: defaultSelectedElements.includes(`${item.profileId}-${item.menuId}`),
                parentHistorySlug: `0,${item.menuId}`,
                profileHistorySlug: `${
                  dataItem.find(({ profileId }) => profileId === item.profileId).parentHistorySlug
                },${item.profileId}`,
              }))
            : [],
        isLoading: false,
      });
      setIsLoadingByContext(false);
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      setIsLoadingByContext(false);
      showError(e);
    }
  };

  handleAddCategory = (newMenuItem) => {
    const { dataItem } = this.props;

    const normalizedMenuItem = newMenuItem
      ? Object.entries(newMenuItem).reduce((acc, [key, value]) => ({ ...acc, [lowerCaseFirstLetter(key)]: value }), {})
      : {};

    this.setState(({ parentMenuElements }) => ({
      parentMenuElements: [
        ...parentMenuElements,
        {
          ...normalizedMenuItem,
          parentHistorySlug: `0,${normalizedMenuItem.menuId}`,
          profileHistorySlug: `${dataItem[0].parentHistorySlug},${dataItem[0].profileId}`,
        },
      ],
    }));
  };

  async componentDidMount() {
    await this.fetchParentMenuElements();
  }

  render() {
    const { isLoading: isLoadingFromProps, profileStaffForUpdating, handleSetProfileStaffForUpdating } = this.props;
    const { parentMenuElements, isLoading } = this.state;

    return (
      <>
        <Button icon="refresh" className="MenuElementsGrid-refresh" onClick={this.fetchParentMenuElements} />
        <ProfileMenuElementsGrid
          data={parentMenuElements}
          handleChangeCurrentLayer={(newLayer) => {
            this.setState({ parentMenuElements: newLayer });
          }}
          setLoading={async (isLoading) => {
            await this.setState({ isLoading });
          }}
          isRoot
        />
        {(isLoading || isLoadingFromProps) && <LoadingPanel />}
        {!!profileStaffForUpdating &&
          (profileStaffForUpdating.variant === 'changeName' || profileStaffForUpdating.variant === 'cloneElement') && (
            <UpdateMenuElementModal
              visible={
                !!profileStaffForUpdating &&
                (profileStaffForUpdating.variant === 'changeName' || profileStaffForUpdating.variant === 'cloneElement')
              }
              closeDialog={() => {
                handleSetProfileStaffForUpdating(undefined);
              }}
              profileStaffForUpdating={profileStaffForUpdating}
            />
          )}

        {!!profileStaffForUpdating && profileStaffForUpdating.variant === 'insertElement' && (
          <CreateMenuElementModal
            visible={!!profileStaffForUpdating && profileStaffForUpdating.variant === 'insertElement'}
            closeDialog={() => {
              handleSetProfileStaffForUpdating(undefined);
            }}
            profileStaffForUpdating={profileStaffForUpdating}
            handleAddCategory={this.handleAddCategory}
          />
        )}

        {!!profileStaffForUpdating && profileStaffForUpdating.variant === 'moveElement' && (
          <MoveMenuElementModal
            visible={!!profileStaffForUpdating && profileStaffForUpdating.variant === 'moveElement'}
            closeDialog={() => {
              handleSetProfileStaffForUpdating(undefined);
            }}
            profileStaffForUpdating={profileStaffForUpdating}
            fetchParentMenuElements={this.fetchParentMenuElements}
          />
        )}
        {!!profileStaffForUpdating && profileStaffForUpdating.variant === 'showElementPath' && (
          <LinkElementTargetInfoModal
            visible={!!profileStaffForUpdating && profileStaffForUpdating.variant === 'showElementPath'}
            closeDialog={() => {
              handleSetProfileStaffForUpdating(undefined);
            }}
            profileStaffForUpdating={profileStaffForUpdating}
          />
        )}

        {!!profileStaffForUpdating &&
          (profileStaffForUpdating.variant === 'deleteElement' ||
            profileStaffForUpdating.variant === 'setIsVisible') && (
            <ActionInfoModal
              profileStaffForUpdating={profileStaffForUpdating}
              visible={
                !!profileStaffForUpdating &&
                (profileStaffForUpdating.variant === 'deleteElement' ||
                  profileStaffForUpdating.variant === 'setIsVisible')
              }
              closeDialog={() => {
                handleSetProfileStaffForUpdating(undefined);
              }}
            />
          )}
      </>
    );
  }
}

export default withTranslate(widthUpdateMenuElementsContext(ProfileMenuElements));
