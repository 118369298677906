const DalerCommissionsTerminals = (translate, value) => {
  switch (value) {
    case 'dealerCommissionsTerminals-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dealerCommissionsTerminals-chngAction':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(
        `page.dealerCommissionsTerminals.applyChanges`,
      )}`;
    default:
      return value;
  }
};

export default DalerCommissionsTerminals;
