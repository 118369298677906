import React, { PureComponent } from 'react';
import { Button, Col, message, Tooltip } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../grids/baseGrid';
import DeleteModal from '../DeleteModal/DeleteModal';
import AddPropModal from './AddPropModal';
import UpdatePropsModal from './UpdatePropsModal';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

import './ElementProps.styled.scss';

const api = new ApiService();

const CellTooltip = ({ dataItem: { name, detail, isRoot } }) => (
  <td>
    <Tooltip
      title={<div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: `${detail}` }} />}
      placement="top"
    >
      <div style={{ cursor: 'pointer', color: isRoot ? 'black' : 'gray' }}>{name}</div>
    </Tooltip>
  </td>
);

class ElementProps extends PureComponent {
  currentArray = findArray('managementMenuProfiles');

  state = {
    isLoading: false,
    allProps: [],
    elementProps: [],
    selectedItems: [],
    dialogVariant: '',
  };

  componentDidMount() {
    this.getElementsInfo();
  }

  buildToolbar = () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    return (
      <Col>
        {checkElement('managementMenuProfiles-elementPropAdd', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ dialogVariant: 'add' });
            }}
          >
            {translate('core.buttonTitles.add')}
          </Button>
        )}

        {checkElement('managementMenuProfiles-elementPropUpdate', this.currentArray) && (
          <Button
            type="primary"
            disabled={selectedItems.length !== 1}
            onClick={() => {
              this.setState({ dialogVariant: 'update' });
            }}
          >
            {translate('core.buttonTitles.change')}
          </Button>
        )}

        {checkElement('managementMenuProfiles-elementPropDelete', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ dialogVariant: 'delete' });
            }}
          >
            {translate('core.buttonTitles.delete')}
          </Button>
        )}
      </Col>
    );
  };

  fetchElementPropsGQL = async () => {
    const { profileId, menuId } = this.props;
    const { profileHistorySlug } = this.props;

    try {
      const data = await api.getByGraphQl(
        `menuProperties(take: 500,skip: 0,order: {profileId: ASC}, where:{and: [ {profileId: {in: [${profileHistorySlug}]}},{menuId:{in: [${menuId}]}}]}){items{name, isVisible, language, system, type, value, profileId} pageInfo{hasNextPage, hasPreviousPage}} `,
      );

      return data &&
        data.data &&
        data.data.menuProperties &&
        data.data.menuProperties.items &&
        data.data.menuProperties.items.length
        ? data.data.menuProperties.items
            .map(({ name, language, profileId: profileIdFromData, ...otherProps }) => ({
              id: `${name}-${language}`,
              name,
              language,
              isRoot: `${profileIdFromData}` === `${profileId}`,
              profileId: profileIdFromData,
              ...otherProps,
            }))
            .reduce((acc, currentProp) => {
              const duplicate = acc.find(({ id }) => id === currentProp.id);

              if (!duplicate) {
                return [...acc, currentProp];
              }

              if (duplicate.profileId === profileId) {
                return currentProp.profileId === profileId ? [...acc, currentProp] : acc;
              }

              return profileHistorySlug.indexOf(`${currentProp.profileId}`) >
                profileHistorySlug.indexOf(`${duplicate.profileId}`)
                ? acc.map((item) => (item.id === duplicate.id ? currentProp : item))
                : [...acc, currentProp];
            }, [])
        : [];
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchElementPropsDetail = async () => {
    const userInfo = StorageService.getItem('userInfo');

    if (!userInfo || !userInfo.OwnerId) {
      return [];
    }

    try {
      return await api.getMenuPropertiesKeys(userInfo.OwnerId);
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getElementsInfo = async () => {
    await this.setState({ isLoading: true });
    const propsByElement = await this.fetchElementPropsGQL();
    const allProps = await this.fetchElementPropsDetail();
    await this.setState({
      allProps: allProps,
      elementProps: propsByElement.length
        ? propsByElement.map(({ name, value, ...otherProps }) => {
            const propWithDetails =
              name === 'ServiceId'
                ? allProps.find(({ Key }) => Key === `${name}.${value}`)
                : allProps.find(({ Key }) => Key === name);
            return { ...otherProps, name, value, detail: (propWithDetails && propWithDetails.Description) || '' };
          })
        : [],
      isLoading: false,
    });
  };

  deleteProp = async () => {
    const { profileId, menuId, translate } = this.props;
    const { selectedItems } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.menuPropertiesDelete(
        selectedItems.map(({ language, name, value }) => ({ language, name, value, profileId, menuId })),
      );
      await this.setState({ isLoading: false });
      message.success(`${translate('page.profilesMenu.propertyDeletedSuccessfully')}`, 2);
      this.getElementsInfo();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { profileId, menuId, translate } = this.props;
    const { elementProps, isLoading, dialogVariant, allProps, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={elementProps}
          onRefresh={this.getElementsInfo}
          isLoading={isLoading}
          name="MenuElementsGrid"
          fieldForSelection="id"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          toolbar={this.buildToolbar()}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn field="name" width="240px" title="page.profilesMenu.propName" cell={CellTooltip} />
          <GridColumn field="value" width="1000px" title="page.profilesMenu.propValue" />
          <GridColumn field="language" width="80px" title="page.profilesMenu.language" />
          <GridColumn field="system" width="130px" title="page.profilesMenu.systemType" />
        </Grid>
        {dialogVariant === 'delete' && (
          <DeleteModal
            visible={dialogVariant === 'delete'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            submitCallback={this.deleteProp}
            deleteContent={translate('page.profilesMenu.areYouSureDeleteElementProps')}
          />
        )}
        {dialogVariant === 'add' && (
          <AddPropModal
            visible={dialogVariant === 'add'}
            closeDialog={() => {
              this.setState({ dialogVariant: '' });
            }}
            allProps={allProps.filter(({ Key }) => !Key.includes('ServiceId.'))}
            profileId={profileId}
            menuId={menuId}
            onRefresh={this.getElementsInfo}
          />
        )}
        {dialogVariant === 'update' && (
          <UpdatePropsModal
            visible={dialogVariant === 'update'}
            closeDialog={() => {
              this.setState({ dialogVariant: '' });
            }}
            currentProfileId={profileId}
            menuId={menuId}
            onRefresh={this.getElementsInfo}
            {...selectedItems[0]}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ElementProps);
