import React, { PureComponent } from 'react';
import { Button, Modal, Input, Card } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';

import './Services.scss';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class CloneServiceModal extends PureComponent {
  state = {
    isLoading: false,
    successfullyClonedResult: '',
    serviceName: '',
    duplicateError: '',
  };

  handleCloneServices = async () => {
    const { translate, selectedItem } = this.props;
    const { serviceName } = this.state;

    await this.setState({ isLoading: true });

    try {
      const isUnique = await api.serviceNameCheck(serviceName);

      if (!isUnique) {
        this.setState({
          isLoading: false,
          duplicateError: translate(`page.addServiceByBigTable.specifiedServiceNameAlreadyUse`),
        });
        return;
      }

      const result = await api.serviceClone({
        serviceIdClone: selectedItem.ServiceId,
        newServiceName: serviceName,
      });

      await this.setState({ isLoading: false, successfullyClonedResult: result });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
      return;
    }
  };

  renderCloneServiceForm = () => {
    const { translate, selectedItem } = this.props;
    const { serviceName } = this.state;

    return (
      <>
        <div>{translate('page.serviceMainProps.nameService')}</div>
        <Input
          value={serviceName}
          onChange={({ target: { value } }) => {
            this.setState({ serviceName: value, duplicateError: '' });
          }}
          className="CloneServiceModal-input"
        />
        <Card
          type="inner"
          title={`${translate('page.servicesBigTable.fromService')} "${selectedItem.ServiceId} - ${
            selectedItem.Name
          }" ${translate('page.servicesBigTable.parametersWillCloned')}`}
        >
          <ul>
            <li>{translate('page.servicesBigTable.tab-basic-properties')}</li>
            <li>{translate('page.servicesBigTable.tab-additional-properties')}</li>
            <li>Endpoints</li>
            <li>{translate('page.servicesBigTable.tab-bank-details')}</li>
            <li>{translate('page.servicesBigTable.tab-additional-limits')}</li>
            <li>{translate('page.recalculationTransactions.modal-title-supplier-commissions')}</li>
            <li>{translate('page.serviceTemplates.col-template')}</li>
            <li>{translate('grids.servicesBigTable.column-commissionClient')}</li>
            <li>{translate('page.servicesBigTable.codeNBU')}</li>
          </ul>
        </Card>
      </>
    );
  };

  handleClose = () => {
    const { closeModal, onRefresh } = this.props;
    const { successfullyClonedResult } = this.state;

    if (successfullyClonedResult) {
      onRefresh();
    }

    closeModal();
  };

  render() {
    const { translate, visible, selectedItem } = this.props;
    const { isLoading, successfullyClonedResult, serviceName, duplicateError } = this.state;

    return (
      <Modal
        className="CloneServiceModal"
        visible={visible}
        title={`${translate('page.servicesBigTable.cloneService')} ${selectedItem.ServiceId}`}
        onCancel={this.handleClose}
        footer={
          successfullyClonedResult
            ? [
                <Button
                  type="primary"
                  key="add"
                  onClick={this.handleClose}
                  loading={isLoading}
                  style={{ margin: '0 auto' }}
                >
                  {translate('core.buttonTitles.ok')}
                </Button>,
              ]
            : [
                <Button
                  type="primary"
                  key="add"
                  onClick={this.handleCloneServices}
                  loading={isLoading}
                  disabled={!serviceName || duplicateError}
                >
                  {translate('page.servicesBigTable.clone')}
                </Button>,
                <Button key="back" onClick={this.handleClose} loading={isLoading}>
                  {translate('core.buttonTitles.refuse')}
                </Button>,
              ]
        }
      >
        {successfullyClonedResult ? (
          <>
            <div className="CloneServiceModal-successfullyClonedLatterMessage">
              {translate('page.servicesBigTable.letterMessage')}
            </div>
            <div className="CloneServiceModal-successfullyClonedWrapper">
              <div dangerouslySetInnerHTML={{ __html: `<table>${successfullyClonedResult}</table>` }} />
            </div>
          </>
        ) : (
          this.renderCloneServiceForm()
        )}
        {duplicateError && <div className="CloneServiceModal-error">{duplicateError}</div>}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(CloneServiceModal);
