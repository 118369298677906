import { BASE_SERVICES_COLUMN_CONFIG } from '../../../core/constants/configs.const';

export const CODE_NBU_COLUMN_CONFIG = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.servicesAuthorizationLimits.col-code',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.servicesAuthorizationLimits.col-purposePayment',
    width: '240',
  },
];

export const AUTHORIZATION_TYPE_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.authorizationsDictionary.col-name',
    width: '332',
    isShowOnMobile: true,
  },
];

export const SERVICES_COLUMN_CONFIG = [
  ...BASE_SERVICES_COLUMN_CONFIG,
  {
    fieldName: 'Supplier',
    titleTranslateSlug: 'page.commissionsServices.col-provider',
    width: '240',
  },
  {
    fieldName: 'ServiceGroupName',
    titleTranslateSlug: 'page.commissionsServices.col-group',
    width: '120',
  },
];
