import React, { PureComponent } from 'react';

import { INITIAL_VALUES } from './initialValues';
import ValidationService from './services/validation.service';
import { withTranslate } from '../../contexts/localContext';

class CommissionFormMethodsContainer extends PureComponent {
  state = {
    data: INITIAL_VALUES,
    errors: {},
  };

  setValueByName = (name, value) => {
    this.setState(({ data }) => {
      if (name === 'ResultPercent' && !value) {
        return {
          data: { ...data, [name]: value, CommissionMin: 0, CommissionMax: 0 },
        };
      }

      return {
        data: { ...data, [name]: value },
      };
    });
  };

  setFormState = (newFormState) => {
    this.setState({ data: newFormState });
  };

  setError = (name, errorMessage) => {
    this.setState(({ errors }) => {
      return {
        errors: { ...errors, [name]: errorMessage },
      };
    });
  };

  validateOnSubmit = () => {
    const { translate } = this.props;
    const {
      data: {
        TimeFrom,
        TimeTo,
        AmountTo,
        AmountFrom,
        CommissionMin,
        CommissionMax,
        ValidDateFrom,
        ValidDateTo,
        ResultFixed,
        ResultPercent,
      },
    } = this.state;

    return (
      ValidationService.differenceValidation(
        TimeTo,
        TimeFrom,
        translate('grids.comission-sub.errorMessages.differenceCommissionDate'),
      ) ||
      ValidationService.differenceValidation(
        AmountTo,
        AmountFrom,
        translate('grids.comission-sub.errorMessages.differenceCommissionPaymentLimit'),
      ) ||
      ValidationService.differenceValidation(
        CommissionMax,
        CommissionMin,
        translate('grids.comission-sub.errorMessages.differenceCommissionLimit'),
      ) ||
      ValidationService.differenceValidation(
        ValidDateTo,
        ValidDateFrom,
        translate('grids.comission-sub.errorMessages.differenceValidityPeriodDate'),
      ) ||
      ValidationService.checkResultExisting(
        ResultFixed,
        ResultPercent,
        translate('grids.comission-sub.errorMessages.resultEmpty'),
      )
    );
  };

  validateAllField = () => {
    const { translate } = this.props;
    const { data } = this.state;

    return Object.entries(ValidationService.getValidators(translate)).reduce((acc, [fieldName, fieldNameValidator]) => {
      this.setError([fieldName], fieldNameValidator(data[fieldName]));

      return acc || fieldNameValidator(data[fieldName]);
    }, null);
  };

  onCheckboxChange = (e) => {
    this.setValueByName('Active', e.target.checked);
  };

  render() {
    const { data, errors } = this.state;
    const isButtonDisabled = Object.values(errors).reduce((acc, errorMessage) => acc || errorMessage, false);

    return React.cloneElement(this.props.children, {
      onChangeFormState: this.setValueByName,
      onError: this.setError,
      formState: data,
      formErrors: errors,
      isButtonDisabled,
      validateOnSubmit: this.validateOnSubmit,
      setFormState: this.setFormState,
      handleCheckboxChange: this.onCheckboxChange,
      handleValidateAllField: this.validateAllField,
    });
  }
}

export default withTranslate(CommissionFormMethodsContainer);
