import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import RemainingTerminals from './RemainingTerminals';
import PageTabs from '../../components/tabber/tabber';

class RemainingTerminalsPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.dashboard.tab-remainingTerminals',
        content: <RemainingTerminals />,
        closable: false,
      },
    ],
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}
export default withTranslate(RemainingTerminalsPage);
