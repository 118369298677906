import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Input, message, Modal } from 'antd';

import './ModalEncashmentCheck.scss';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import TextArea from 'antd/es/input/TextArea';

const api = new ApiService();

class ModalEncashmentCheck extends PureComponent {
  state = {
    inputValue: '',
    err: '',
    isLoading: false,
  };

  onHandleChangeValue = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value });
  };

  isValidEmail = (email) => {
    const { translate } = this.props;

    const checkEmails = email.includes(' ');

    if (checkEmails && !email.includes(';')) {
      this.setState({ err: `${translate('page.encashment.err-not-comma')}` });
      return;
    }

    const emails = email.split(';');
    for (const item of emails) {
      const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(item.trim());
      if (!isValid) {
        this.setState({ err: `${translate('page.encashment.err-not-valid')}` });
        return isValid;
      }
    }
    return null;
  };

  onHandleSubmit = () => {
    const { inputValue } = this.state;
    const validEmail = this.isValidEmail(inputValue);
    if (validEmail === null) {
      this.sendCollectionReceiptsToEmail();
    }
  };

  sendCollectionReceiptsToEmail = async () => {
    const { inputValue } = this.state;
    const {
      onClose,
      dataItem: { collectionId, terminalId },
      translate,
    } = this.props;
    try {
      const model = {
        collectionId,
        terminalId,
        emails: inputValue
          .split(';')
          .map((value) => value.trim())
          .join(','),
      };
      this.setState({ isLoading: true });
      await api.sendCollectionReceiptsToEmail(model);
      message.success(translate('page.encashment.successfulSendCheck'), 2);
      this.setState({ isLoading: false });
      await onClose();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { inputValue } = this.state;
    const { inputValue: prevInputValue } = prevState;
    if (inputValue !== prevInputValue) {
      this.setState({ err: '' });
    }
  }

  render() {
    const { inputValue, err, isLoading } = this.state;
    const { translate, visible, onClose } = this.props;
    return (
      <>
        <Modal
          title={translate('page.encashment.tab-encashment-check-email')}
          visible={visible}
          onCancel={() => onClose()}
          footer={[
            <Button type="primary" disabled={!!err.length} onClick={this.onHandleSubmit}>
              {translate('core.buttonTitles.send-check')}
            </Button>,
            <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          <div className="ModalEncashmentCheck-row">
            <div className="ModalEncashmentCheck-input">
              <div>{translate('page.encashment.enterTheEmailAddresses')}</div>
              <TextArea
                value={inputValue}
                onChange={this.onHandleChangeValue}
                className={err && err.length ? 'input-err' : ''}
              />
              {err && err.length && <div className="ModalEncashmentCheck-err-msg">{err}</div>}
            </div>
          </div>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalEncashmentCheck);
