import React, { PureComponent } from 'react';
import { Button, Modal, message, Alert } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import PropValueRow from './PropValueRow';
import ElementPropsService from './ElementProps.service';
import { ApiService } from '../../services';

const api = new ApiService();

class UpdatePropsModal extends PureComponent {
  state = {
    isLoading: false,
    value: '',
    error: '',
    checkResult: null,
  };

  componentDidMount() {
    const { value } = this.props;

    this.setState({ value });
  }

  renderValueRow = () => {
    const { value } = this.state;
    const { type } = this.props;

    return (
      <PropValueRow
        valueType={type}
        setInputValues={(value, error) => {
          this.setState({
            value,
            error,
          });
        }}
        value={value}
      />
    );
  };

  checkOperation = async () => {
    const { currentProfileId: profileId, menuId } = this.props;

    try {
      await this.setState({ isLoading: true });
      const checkResponse = await api.menuPropertiesCheck({
        profileId,
        menuId,
      });

      await this.setState({ isLoading: false, checkResult: checkResponse });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  updateProp = async () => {
    const { currentProfileId, menuId, translate, closeDialog, onRefresh, name, language, isRoot, type } = this.props;
    const { value } = this.state;

    try {
      await this.setState({ isLoading: true });
      const method = isRoot ? api.menuPropertiesUpdate : api.menuPropertiesInsert;
      await method({
        profileId: currentProfileId,
        menuId,
        name: name,
        value: ElementPropsService.getValueByType(value, type),
        language: language,
      });
      await this.setState({ isLoading: false });
      closeDialog();
      message.success(translate('page.profilesMenu.propertyUpdatedSuccessfully'), 2);
      onRefresh();
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  renderWarningQuestion = () => {
    const {
      checkResult: { Message },
    } = this.state;
    const { translate } = this.props;

    return (
      <Alert
        message={`${translate('page.profilesMenu.areYouSureDeactivatePerformAction')}${Message ? ` ${Message}` : ''}`}
        type="warning"
        showIcon
      />
    );
  };

  render() {
    const { translate, closeDialog, type, visible, name } = this.props;
    const { value, isLoading, error, checkResult } = this.state;

    const isNeedCheck = name === 'Visible' && !value;

    return (
      <Modal
        title={`${translate('page.profilesMenu.updateProps')} ${name}`}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            onClick={isNeedCheck && !checkResult ? this.checkOperation : this.updateProp}
            disabled={(!value && type !== 'bool') || !!error}
          >
            {translate('core.buttonTitles.save')}
          </Button>,

          <Button onClick={closeDialog} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {checkResult ? this.renderWarningQuestion() : this.renderValueRow()}

        {error && <div className="ElementProps-error">{translate(error)}</div>}
      </Modal>
    );
  }
}

export default withTranslate(UpdatePropsModal);
