import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import LoadingPanel from '../../components/loader';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';

import './PartnerInfoSenderGrid.styled.scss';

const api = new ApiService();

class UpdateCustomizationModal extends PureComponent {
  state = {
    isLoading: false,
  };

  customizeOwners = async () => {
    const { selectedOwners, onRefresh, closeModal, translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const ownersAdditionsInfo = await api.getOwnerBankingAccountDetails(selectedOwners.map(({ OwnerId }) => OwnerId));

      const resultOwners = selectedOwners.map((owner) => {
        const ownerAdditionalInfo = ownersAdditionsInfo.find(({ OwnerId }) => OwnerId === owner.OwnerId) || {};

        return { ...owner, ...ownerAdditionalInfo };
      });

      const ownersItems = resultOwners
        .map(({ OwnerId, OwnerName, EDRPO, IBAN, OwnerAddress }) => [
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Name.PUMB',
            value: OwnerName,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Name.Raiff',
            value: OwnerName,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Id',
            value: EDRPO || null,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Account',
            value: IBAN || null,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Country.PUMB',
            value: OwnerAddress.split(';')[0] || null,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.City',
            value: OwnerAddress.split(';')[1] || null,
            forDelete: false,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Address',
            value: OwnerAddress.split(';')[2] || null,
            forDelete: false,
          },
        ])
        .flatMap((ownerItems) => ownerItems);

      await api.mergeOwnersItems(ownersItems);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.partnerInfoSenderGrid.customizedSuccessfully'), 2);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  removeCustomizationOwners = async () => {
    const { selectedOwners, onRefresh, closeModal, translate } = this.props;

    try {
      await this.setState({ isLoading: true });

      const ownersItems = selectedOwners
        .map(({ OwnerId }) => [
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Name.PUMB',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Name.Raiff',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Id',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Account',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Country.PUMB',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.City',
            value: null,
            forDelete: true,
          },
          {
            ownerId: OwnerId,
            key: 'PartnerInfo.Address',
            value: null,
            forDelete: true,
          },
        ])
        .flatMap((ownerItems) => ownerItems);

      await api.mergeOwnersItems(ownersItems);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.partnerInfoSenderGrid.customizationSuccessfullyRemoved'), 2);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { variant, closeModal, translate } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={variant === 'customize' || variant === 'removeCustomization'}
        onCancel={closeModal}
        title={
          variant === 'customize'
            ? translate('page.partnerInfoSenderGrid.customizeOwner')
            : translate('page.partnerInfoSenderGrid.removeCustomizationOwner')
        }
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={variant === 'customize' ? this.customizeOwners : this.removeCustomizationOwners}
          >
            {variant === 'customize'
              ? translate('page.partnerInfoSenderGrid.customize')
              : translate('page.partnerInfoSenderGrid.removeCustomization')}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="PartnerInfoSenderCustomiseDialog-content">
          {variant === 'customize'
            ? translate('page.partnerInfoSenderGrid.areYouSureCustomize')
            : translate('page.partnerInfoSenderGrid.areYouSureRemoveCustomization')}
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(UpdateCustomizationModal);
