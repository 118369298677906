import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Select } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { ApiService, StorageService, tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import './gridTerminalsOperabilityChoose.scss';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();

const convertToISOFormat = (date) => formatDate(date, 'yyyy-MM-ddTHH:mm:ssZ');
const convertToFormat = (date) => formatDate(date, 'yyyy-MM-dd');

const operabilities = [
  {
    name: 'grids.terminalOperabilityChoose.operability',
    value: 'efficiency',
  },
  {
    name: 'grids.terminalOperabilityChoose.technicalOperability',
    value: 'technicalEfficiency',
  },
  {
    name: 'grids.terminalOperabilityChoose.monitoringOperability',
    value: 'monitoring',
  },
];
const { Option } = Select;

class GridTerminalsOperability extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90);
    this.maxDate = new Date().setHours(23, 59, 59, 999);
    this.currentArray = findArray('terminalOperabilityDay');
  }

  state = {
    isLoading: false,
    data: [],
    owners: [],
    operabilityValue: operabilities[0].value,
    selectedItems: [],
    workDate: new Date(),
    CommunicationTerminalsPerDay: [],
    terminalList: [],
    allOwners: [],
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getTerminals = async () => {
    const { owners } = this.state;
    const { showError } = this.props;
    if (owners.length < 1) return;
    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        HideDeleted: true,
      };
      const data = await api.getTerminalsByOwner(model);
      this.setState({ data });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  componentWillUnmount() {
    StorageService.removeItem('checkSelected-terminalOperabilityDayChoose');
  }

  onOperabilityValueChange = (operabilityValue) => {
    this.setState({ operabilityValue });
  };

  makeQuery = () => {
    const { owners, operabilityValue, workDate, selectedItems } = this.state;
    const WorkDate = convertToISOFormat(workDate);

    tabService.addTab({
      type: 'day',
      model: {
        OwnerId: owners,
        TerminalId: selectedItems.map(({ TerminalId }) => TerminalId),
        WorkDate,
        ReportType: operabilityValue,
      },
    });
  };

  addCommunicationMonitoringTab = async () => {
    const { owners, operabilityValue, workDate, CommunicationTerminalsPerDay, selectedItems } = this.state;
    const WorkDate = convertToISOFormat(workDate);
    const date = new Date(workDate).getDate();
    const mouth = new Date(workDate).getMonth();
    const year = new Date(workDate).getFullYear();
    const minDate = convertToFormat(new Date(year, mouth, date));

    try {
      this.toggleLoader(true);
      await tabService.addTab({
        type: 'communicationMonitoring',
        model: {
          ChooseWorkDate: WorkDate,
          ReportType: operabilityValue,
          CommunicationTerminalsPerDay,
          ownerId: owners.length ? owners[0] : null,
          terminalList: selectedItems.map(({ TerminalId }) => TerminalId),
          dateStart: minDate,
        },
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  onClickByActionType = () => {
    const { operabilityValue } = this.state;
    if (operabilityValue === 'monitoring') {
      this.addCommunicationMonitoringTab();
    } else {
      this.makeQuery();
    }
  };

  onWorkDateChange = (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ workDate: value });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { workDate, operabilityValue, selectedItems, allOwners, owners } = this.state;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 16 }}>
        <div className="terminalsOperability-dropdown-wrapper">
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(allOwners)}
            selectItems={owners}
            onSave={this.onOwners}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isGetDefaultSelectItemsFromStorage
          />
        </div>
        <div className="terminalsOperability-select-wrapper">
          {checkElement('terminalOperabilityDayChoose-isDateDropdown', this.currentArray) && (
            <DatePicker
              onClickOutside={false}
              value={workDate}
              className="modal-system-encashment--select terminalsOperability-select date-picker"
              onChange={this.onWorkDateChange}
              format="yyyy.MM.dd"
              min={new Date(this.minDate)}
              max={new Date(this.maxDate)}
            />
          )}

          {checkElement('terminalOperabilityDayChoose-isOperabilityDropdown', this.currentArray) && (
            <Select
              value={operabilityValue}
              onChange={this.onOperabilityValueChange}
              className="modal-system-encashment--select terminalsOperability-select operability"
              dropdownStyle={{ zIndex: '1054', width: '100%' }}
              showSearch={false}
            >
              {operabilities.map(({ value, name }) => (
                <Option key={name} value={value}>
                  {translate(name)}
                </Option>
              ))}
            </Select>
          )}
          {checkElement('terminalOperabilityDayChoose-btn-search', this.currentArray) && (
            <Button
              className="sm-w-100 btn-search"
              icon="search"
              primary
              onClick={this.onClickByActionType}
              disabled={!selectedItems.length || workDate < new Date(this.minDate) || workDate > new Date(this.maxDate)}
            >
              {translate('owner.search')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.getTerminals);
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render = () => {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        onRefresh={this.getTerminals}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        toggleLoader={this.toggleLoader}
        name="terminalOperabilityDayChoose"
        permissionName="terminalOperabilityDay"
        fieldForSelection="TerminalId"
        onSelect={this.handleSelectionChange}
        multiSelected
      >
        <GridColumn
          field={'selected'}
          width="50px"
          filterable={false}
          sortable={false}
          showAllSelected={true}
          columnMenu={false}
        />

        <GridColumn field="OwnerName" title="grids.terminalOperabilityChoose.column-ownerName" width="250" />

        <GridColumn
          field="TerminalId"
          title="grids.terminalOperabilityChoose.column-terminalId"
          width="150"
          filter="numeric"
        />

        <GridColumn field="TerminalName" title="grids.terminalOperabilityChoose.column-name" width="250" />

        <GridColumn
          field="NetworkContractName"
          title="grids.terminalOperabilityChoose.column-networkContract"
          width="250"
        />

        <GridColumn field="RegionName" title="grids.terminalOperabilityChoose.column-region" width="250" />

        <GridColumn field="CityName" title="grids.terminalOperabilityChoose.column-city" width="200" />

        <GridColumn field="TerminalAddress" title="grids.terminalOperabilityChoose.column-address" width="250" />

        <GridColumn field="StatusName" title="grids.terminalOperabilityChoose.column-status" width="250" />
      </Grid>
    );
  };
}

export default withTranslate(GridTerminalsOperability);
