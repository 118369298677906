export const ALL_GRID_COLUMNS = [
  'id',
  'bin',
  'rangeFrom',
  'rangeTo',
  'systemType',
  'incomingServiceId',
  'outcomingServiceId',
  'amountMax',
  'panType',
  'bankName',
  'priority',
  'last',
  'weight',
  'active',
];
