import React, { PureComponent } from 'react';
import { Button, message } from 'antd';

import ChangeDealerCommissions from '../../../../../components/ChangeDealerCommissions/ChangeDealerCommissions';
import { withTranslate } from '../../../../../contexts/localContext';
import ServiceIdField from '../ServiceIdField';
import { ApiService } from '../../../../../services';
import LoadingPanel from '../../../../../components/loader';

import './OwnerCommissions.styled.scss';

const api = new ApiService();

class OwnerCommissions extends PureComponent {
  state = {
    operations: [],
    isLoading: false,
  };

  handleApplyChanges = async () => {
    const { operations } = this.state;
    const { translate, currentServiceId, handleUpdateTabPane } = this.props;

    try {
      await this.setState({ isLoading: true });

      const model = operations.map((rules) => ({
        ...rules,
        ownerId: 0,
        serviceId: currentServiceId,
        statusId: 2,
      }));

      await api.serviceCommissionUpdate(model);
      await message.success(`${translate('page.servicesBigTable.changesDealerCommissionSuccessfullySaved')}`, 2.5);
      this.setState({ isLoading: false });
      handleUpdateTabPane();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, currentServiceId } = this.props;
    const { operations, isLoading } = this.state;

    return (
      <div className="StepServiceCreationOwnerCommissions">
        <ServiceIdField serviceId={currentServiceId} />

        <ChangeDealerCommissions
          onOperationUpdate={(operations) => {
            this.setState({ operations });
          }}
        />

        <Button
          type="primary"
          disabled={!operations.length}
          className="StepServiceCreationOwnerCommissions-action"
          onClick={this.handleApplyChanges}
        >
          {translate('page.servicesBigTable.saveAndNext')}
        </Button>

        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(OwnerCommissions);
