import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import PageTabs from '../../../components/tabber/tabber';
import RecalculationTransactionsContainer from './RecalculationTransactionsContainer';
import RecalculationTransactionsFormStateContainer from '../../../components/FormStateContainer';
import { INITIAL_VALUES_RECALCULATION_COMMISSIONS } from './constants';

class RecalculationTransactionsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-recalculation-transactions',
          content: (
            <RecalculationTransactionsFormStateContainer initialValues={INITIAL_VALUES_RECALCULATION_COMMISSIONS}>
              <RecalculationTransactionsContainer />
            </RecalculationTransactionsFormStateContainer>
          ),
          closable: false,
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} />;
  };
}
export default withTranslate(RecalculationTransactionsPage);
