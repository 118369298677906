import PropTypes from 'prop-types';
import React from 'react';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox, Col, Input, Row, Select, Tooltip } from 'antd';
import { Button } from '@progress/kendo-react-buttons';

import ModeSwitcher from '../../modeSwitcher';
import { StorageService } from '../../../services';
import { SpecificTerminalIdFilter } from '../filters';
import { ROW_AMOUNTS } from './constants';
import PagePanel from '../PagePanel';

const { Option } = Select;

class Toolbar extends GridToolbar {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onRefresh: PropTypes.func,
    onExport: PropTypes.func,
    clearFilters: PropTypes.func,
    isFilter: PropTypes.func,
    triggerFilters: PropTypes.func,
    specificFieldsToHide: PropTypes.array,
  };

  triggerFilters = () => {
    const { triggerFilters, gridmode } = this.props;
    if (gridmode === true) {
      triggerFilters();
    }
  };

  onRefresh = () => {
    const { onRefresh, cleanSelected } = this.props;
    onRefresh();
    cleanSelected();
  };

  onClearFilters = () => {
    const { clearFilters, name, dataState } = this.props;
    clearFilters();
    if (StorageService.getItem(`fs-${name}`)) {
      const storageCopy = StorageService.getItem(`fs-${name}`);
      storageCopy.filter = [];
      StorageService.setItem(`fs-${name}`, storageCopy);
    } else {
      const obj = {
        sort: dataState.sort,
        filter: [],
      };
      StorageService.setItem(`fs-${name}`, obj);
    }
  };
  baseButtons = () => {
    const {
      onExport,
      children,
      toggleDialog,
      isFilter,
      switchMode,
      gridmode,
      translate,
      isRemoteShow,
      showRemoteLabel,
      showRemoteCallback,
      withOutBaseButtons,
      withoutManageColumnAction,
      onFilterConstructorOpen,
    } = this.props;

    if (withOutBaseButtons) {
      return;
    }

    // for transaction toolbar
    if (children && children.type === Row) {
      return (
        <Col className="sm-p-0 RowBaseButtons" span={24}>
          <Row type="flex" align="middle" justify="end" className="mob-space-between">
            {showRemoteCallback && (
              <Col
                className={`basegrid-toolbar-remoteTerminals ${
                  isRemoteShow ? 'basegrid-toolbar-remoteTerminals--active' : ''
                }`}
              >
                {showRemoteLabel
                  ? translate(`core.toolbar.${showRemoteLabel}`)
                  : translate('core.toolbar.showRemoteTerminals')}
                <Checkbox checked={isRemoteShow} onChange={showRemoteCallback} />
              </Col>
            )}

            <Tooltip title={translate(`core.toolbar.exportExel`)} placement="top">
              <Button icon="file-excel" onClick={onExport} />
            </Tooltip>

            {window.innerWidth < 991 && gridmode && (
              <Button onClick={this.triggerFilters}>
                <span className="k-icon k-i-filter"></span>
                <span className="k-icon k-i-arrow-overflow-down"></span>
              </Button>
            )}
            <ModeSwitcher gridmode={gridmode} switchMode={switchMode} />

            <Tooltip
              title={translate(`core.toolbar.${onFilterConstructorOpen ? 'filterConstructor' : 'resetFiltering'}`)}
              placement="top"
            >
              <div style={{ marginLeft: '5px' }}>
                <button
                  disabled={!onFilterConstructorOpen && !isFilter()}
                  className="k-button"
                  onClick={onFilterConstructorOpen ? onFilterConstructorOpen : this.onClearFilters}
                >
                  <span className={`k-icon ${onFilterConstructorOpen ? 'k-i-filter' : 'k-i-filter-clear'}`}></span>
                </button>
              </div>
            </Tooltip>

            {/* {window.innerWidth > 991 &&
              <button className="k-button" onClick={toggleDialog}>
                <span className="k-icon k-i-gear k-i-cog k-i-custom"></span>
              </button>
            } */}
            {!gridmode && !withoutManageColumnAction && (
              <Tooltip title={translate(`core.toolbar.adjustingTable`)} placement="top">
                <button className="k-button" onClick={toggleDialog}>
                  <span className="k-icon k-i-gear k-i-cog k-i-custom"></span>
                </button>
              </Tooltip>
            )}
            <Tooltip title={translate(`core.toolbar.updatingData`)} placement="top">
              <Button icon="refresh" onClick={this.onRefresh} className="toolbar-btn-refresh" />
            </Tooltip>
          </Row>
        </Col>
      );
    }
    return (
      <Col
        xs={{ order: 1, span: 24 }}
        sm={{ order: 1 }}
        md={{ order: 1 }}
        lg={{ order: 2, span: 8 }}
        xl={{ order: 2, span: 8 }}
      >
        <Row type="flex" align="middle" justify="end" className="mob-space-between ColBaseButtons">
          {showRemoteCallback && (
            <Col
              className={`basegrid-toolbar-remoteTerminals ${
                isRemoteShow ? 'basegrid-toolbar-remoteTerminals--active' : ''
              }`}
            >
              {showRemoteLabel
                ? translate(`core.toolbar.${showRemoteLabel}`)
                : translate('core.toolbar.showRemoteTerminals')}
              <Checkbox checked={isRemoteShow} onChange={showRemoteCallback} />
            </Col>
          )}
          <div>
            <Tooltip title={translate(`core.toolbar.exportExel`)} placement="top">
              <Button icon="file-excel" onClick={onExport} />
            </Tooltip>
          </div>

          <ModeSwitcher gridmode={gridmode} switchMode={switchMode} />

          {window.innerWidth < 991 && gridmode && (
            <div>
              <Button onClick={this.triggerFilters}>
                <span className="k-icon k-i-filter"></span>
                <span className="k-icon k-i-arrow-overflow-down"></span>
              </Button>
            </div>
          )}

          <Tooltip
            title={translate(`core.toolbar.${onFilterConstructorOpen ? 'filterConstructor' : 'resetFiltering'}`)}
            placement="top"
          >
            <div>
              <button
                className="k-button"
                disabled={!onFilterConstructorOpen && !isFilter()}
                onClick={onFilterConstructorOpen ? onFilterConstructorOpen : this.onClearFilters}
              >
                <span className={`k-icon ${onFilterConstructorOpen ? 'k-i-filter' : 'k-i-filter-clear'}`}></span>
              </button>
            </div>
          </Tooltip>

          {/* {window.innerWidth > 991 &&
            <button className="k-button" onClick={toggleDialog}>
              <span className="k-icon k-i-gear k-i-cog k-i-custom"></span>
            </button>
          } */}
          {!gridmode && !withoutManageColumnAction && (
            <div>
              <Tooltip title={translate(`core.toolbar.adjustingTable`)} placement="top">
                <button className="k-button" onClick={toggleDialog}>
                  <span className="k-icon k-i-gear k-i-cog k-i-custom"></span>
                </button>
              </Tooltip>
            </div>
          )}
          <div>
            <Tooltip title={translate(`core.toolbar.updatingData`)} placement="top">
              <Button icon="refresh" onClick={this.onRefresh} className="toolbar-btn-refresh" />
            </Tooltip>
          </div>
        </Row>
      </Col>
    );
  };

  baseToolbar = (content) => {
    const {
      isShowSpecificFilterByFieldName,
      onChangeViaSpecificFilterByFieldName,
      onClearViaSpecificFilterFieldName,
      data,
      serverData,
      isGQL,
    } = this.props;

    return (
      <div className="k-header k-grid-toolbar">
        <Row type="flex" align="top" justify="space-between" gutter={16}>
          {content}
          <Col xs={24} order={2}>
            {this.renderSpecificFieldsToHideCheckBox()}
          </Col>
        </Row>
        {isShowSpecificFilterByFieldName && (
          <SpecificTerminalIdFilter
            onChange={onChangeViaSpecificFilterByFieldName}
            fieldName={isShowSpecificFilterByFieldName}
            onClear={onClearViaSpecificFilterFieldName}
            data={data}
            serverData={serverData}
            isGQL={isGQL}
          />
        )}
      </div>
    );
  };

  renderSpecificFieldsToHideCheckBox = () => {
    const { specificFieldsToHide, specificCheckBoxTitle, onChangeCheckBoxSpecificFields, isShowSpecificFields } =
      this.props;
    if (specificFieldsToHide.length < 1) return null;
    else
      return (
        <Checkbox
          checked={isShowSpecificFields}
          onChange={onChangeCheckBoxSpecificFields}
          style={{ marginTop: '10px' }}
        >
          {specificCheckBoxTitle}
        </Checkbox>
      );
  };

  render() {
    const {
      name,
      children,
      translate,
      handleRowAmountChange,
      pageChange,
      pageValue,
      isGQL,
      hasNextPage,
      hasPreviousPage,
      data,
    } = this.props;

    if (children && children.type === Col) {
      // single mode
      return this.baseToolbar(
        <>
          {children}
          {this.baseButtons()}
          {isGQL && (
            <Col span={24} order={3} className="Toolbar-graphqlActions">
              <div className="Toolbar-graphqlActionsItem">
                <div>{translate('toolbar.numberOfElements')}</div>
                <Input value={(data && data.length) || 0} className="Toolbar-numberInput" disabled />
                <Select className="Toolbar-select" onChange={handleRowAmountChange} defaultValue={50}>
                  {ROW_AMOUNTS.map((amount) => (
                    <Option value={amount} key={amount}>
                      {amount}
                    </Option>
                  ))}
                </Select>
              </div>

              <PagePanel
                hasPreviousPage={hasPreviousPage}
                pageChange={pageChange}
                pageValue={pageValue}
                hasNextPage={hasNextPage}
              />
            </Col>
          )}
        </>,
      );
    }

    // mode for transaction toolbar
    if (children && children.type === Row) {
      return this.baseToolbar(
        <Col span={24}>
          <Row type="flex" justify="start" gutter={24}>
            <Col xl={{ order: 1, span: 21 }} xs={{ order: 2, span: 24 }} className="only-lg-flex">
              {children}
              {window.innerWidth > 992 && window.innerWidth < 1200 && (
                <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 3 }} xl={{ order: 2, span: 3 }}>
                  {this.baseButtons()}
                </Col>
              )}
            </Col>
            {(window.innerWidth < 992 || window.innerWidth > 1200) && (
              <Col
                xs={{ order: 1, span: 24 }}
                lg={{ order: 2, span: 3 }}
                xl={{ order: 2, span: 3 }}
                style={{ height: '100%' }}
              >
                {this.baseButtons()}
              </Col>
            )}

            {isGQL && (
              <Col span={24} order={3} offset={1} className="Toolbar-graphqlActions">
                <div className="Toolbar-graphqlActionsItem">
                  <div>{translate('toolbar.numberOfElements')}</div>
                  <Input value={(data && data.length) || 0} className="Toolbar-numberInput" disabled />
                  <Select className="Toolbar-select" onChange={handleRowAmountChange} defaultValue={50}>
                    {ROW_AMOUNTS.map((amount) => (
                      <Option value={amount} key={amount}>
                        {amount}
                      </Option>
                    ))}
                  </Select>
                </div>

                <PagePanel
                  hasPreviousPage={hasPreviousPage}
                  pageChange={pageChange}
                  pageValue={pageValue}
                  hasNextPage={hasNextPage}
                />
              </Col>
            )}
          </Row>
        </Col>,
      );
    }

    // array mode
    if (children && children instanceof Array) {
      return this.baseToolbar(
        <>
          {children.map((item, index) => (
            <Col
              xs={{ order: 2, span: 24 }}
              sm={{ order: 2 }}
              md={{ order: 1, span: 14 }}
              lg={{ order: 1, span: 14 }}
              xl={{ order: 1, span: 12 }}
              {...item.props}
              key={`toolbar-${name}-${index + 1}`}
            />
          ))}
          {this.baseButtons()}
        </>,
      );
    }

    // mode for regular toolbar
    return this.baseToolbar(
      <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.baseButtons()}
      </Col>,
    );
  }
}

export default Toolbar;
