export const ALL_GRID_COLUMNS = [
  'code',
  'nameRu',
  'nameUk',
  'nameEn',
  'nameDe',
  'typeId',
  'paymentRedirect',
  'authRedirect',
];
