import React, { PureComponent } from 'react';
import { formatDate } from '@telerik/kendo-intl';
import { CloseOutlined, DeleteOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ApiService } from '../../../../../services';
import axios from 'axios';
import LoadingPanel from '../../../../../components/loader';
// import IconClose from './closeIcon.svg';
// import IconInfo from './infoIcon.svg';
// import IconDownload from './downloadIcon.svg';
// import IconThrash from './thrashIcon.svg';
import './sliderElement.scss';
import { withTranslate } from '../../../../../contexts/localContext';

const api = new ApiService();

class SliderComponent extends PureComponent {
  state = {
    visibleDeleteSpan: false,
    visibleInfoBlock: false,
    visibleFooter: false,
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  toggleVisibleDeleteSpan = () => {
    this.setState(({ visibleDeleteSpan }) => {
      return {
        visibleDeleteSpan: !visibleDeleteSpan,
      };
    });
  };

  toggleVisibleFooter = () => {
    this.setState(({ visibleFooter }) => {
      return {
        visibleFooter: !visibleFooter,
      };
    });
  };

  toggleInfoBlock = () => {
    this.setState(({ visibleInfoBlock }) => {
      return {
        visibleInfoBlock: !visibleInfoBlock,
      };
    });
  };

  getHeightClass = () => {
    const { visibleInfoBlock } = this.state;
    if (visibleInfoBlock) return 'full';
    else return '';
  };

  reformatDate = (initDate) => {
    const date = new Date(initDate);
    return formatDate(date, 'HH:mm:ss dd.MM.yyyy');
  };

  download = (FileName, Url) => {
    axios({
      url: Url, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', FileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  renderInfoBlock = (elem) => {
    const { visibleInfoBlock } = this.state;
    const { translate } = this.props;
    this.reformatDate(elem.DatePost);
    return (
      <div className={`sliderElem-info ${this.getHeightClass()}`}>
        {visibleInfoBlock && (
          <>
            <div className="sliderElem-info-abs"></div>
            <div className="sliderElem-info-row first">{elem.FileName}</div>
            <div className="sliderElem-info-row">
              <span className="bold">{translate('grids.adminStockPhotos.modal-slider-add')}:</span>
              <span>{this.reformatDate(elem.DatePost)}</span>
            </div>
            <div className="sliderElem-info-row">
              <span className="bold">{translate('grids.adminStockPhotos.modal-slider-size')}:</span>
              <span>{elem.Size}kB</span>
            </div>
          </>
        )}
      </div>
    );
  };

  onDeleteImage = async (FileName) => {
    // toggleModal, closeModal
    const {
      dataItem: { OwnerId, TerminalId },
      update,
    } = this.props;
    try {
      this.toggleLoader(true);
      const model = {
        OwnerId,
        TerminalId,
        FileName,
      };
      const data = await api.terminalPhotoDelete(model);
      update();
      return data;
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getUnconsideredHeaderClassName = () => {
    const {
      dataItem: { initialTerminalStatusName },
    } = this.props;
    switch (initialTerminalStatusName) {
      case 'NotBranded':
        return 'notBranded';
      case 'BrandedByStandart':
        return 'brandedByStandart';
      case 'UnderConsideration':
        return 'underConsideration';
      case 'Branded':
        return 'branded';
      default:
        return;
    }
  };

  renderHeaderClassName = () => {
    const { elem } = this.props;
    if (elem.Status === 'Considered') return 'considered';
    else return this.getUnconsideredHeaderClassName();
  };

  getIconClassName = () => {
    const { dataItem } = this.props;
    if (dataItem.initialTerminalStatusName !== 'UnderConsideration') return 'white';
    else return 'black';
  };

  render() {
    const { elem, closeModal, toggleModal, translate } = this.props;
    const { visibleDeleteSpan, isLoading } = this.state;
    return (
      <>
        <div className="slide-elem-wrapper" key={elem.FileName}>
          {this.renderInfoBlock(elem)}
          <div className={`modal-slider--header ${this.renderHeaderClassName()}`}>
            <div className="modal-slider--header-left">
              {/* <img alt="close-btn" src={IconClose} onClick={closeModal} /> */}
              <CloseOutlined className={`modal-slider-icon ${this.getIconClassName(elem)}`} onClick={closeModal} />
            </div>
            <div className="modal-slider--header-right">
              <div className="thrash-block">
                {/* <img
                  alt="delete-btn"
                  src={IconThrash}
                  onClick={this.toggleVisibleDeleteSpan}
                /> */}
                <DeleteOutlined
                  className={`modal-slider-icon ${this.getIconClassName(elem)}`}
                  onClick={this.toggleVisibleDeleteSpan}
                />
                {visibleDeleteSpan && (
                  <span
                    onClick={() => {
                      this.onDeleteImage(elem.FileName);
                      toggleModal();
                    }}
                  >
                    {translate('grids.profilesMainProfileList.modal-delete-profile-btn-submit')}?
                  </span>
                )}
              </div>
              {/* <img
                alt="download-btn"
                src={IconDownload}
                onClick={() => this.download(elem.FileName, elem.Url)}
              /> */}
              <DownloadOutlined
                className={`modal-slider-icon ${this.getIconClassName(elem)}`}
                onClick={() => this.download(elem.FileName, elem.Url)}
              />
              {/* <img alt="info-btn" src={IconInfo} onClick={this.toggleInfoBlock} /> */}
              <InfoCircleOutlined
                style={{ marginLeft: '10px' }}
                className={`modal-slider-icon ${this.getIconClassName(elem)}`}
                onClick={this.toggleInfoBlock}
              />
            </div>
          </div>
          <div className="slider-wrap">
            <img src={elem.Url} alt="stockSlider-elem" />
          </div>
          {isLoading && <LoadingPanel />}
        </div>
      </>
    );
  }
}

export default withTranslate(SliderComponent);
