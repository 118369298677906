// Бек зберігає профілі з елементами у вигляді зв'язного списку де кожен профіль має лінку на свій батьківський профіль(по суті це
// лінійне представлення дерева). На елементах профілів існують властивості і їх значення. Якщо з'ясовуєтся що властивості не існує в
// поточному профілі, але вона існує в одному з батьківських профілів - вважаєтся що її значення для поточного профілю успадковуются від батьківського
// профіля в якому воно є. Це є умовною угодою Властивість яка справді існує в бд - вважается перевизначеною, властивість якої не існує в бд - але за
// умовною угодою її значення успадковуется від батьківського профілю вважается не визначеною. Оскільки не визначених валстивостей не має в бд, але користувачу
// іх відображати протрібно - фрон вираховує їх згідно умовної угоди.
class NavigationMenuElementsGridService {
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.menuExtendedProperties) {
      return { props: [], hasNextPage: false };
    }
    const menuExtendedProperties = data.data.menuExtendedProperties;

    const props =
      menuExtendedProperties.items && menuExtendedProperties.items.length ? menuExtendedProperties.items : [];

    const pageInfo = menuExtendedProperties.pageInfo;

    return {
      props: props,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  calcElements = (props, allDefiniteProps, selectProfiles) => {
    //props - властивості з шуканим значенням що існують у цільовому профілі або в його батьк профілях
    //allDefiniteProps - властивості з будь-яким значенням що існують у цільовому профілі або в його батьк профілях
    const profileHistory = `${selectProfiles[0].parentHistorySlug},${selectProfiles[0].profileId}`;

    //Знаходимо дублікати у массиві перевизначених властивостей
    //знаходимо дублікати адже властивість може бути визначена у декількох батьківських профілях, ми вибираємо той батьківський профіль,
    //що знаходится найнижче, тобто коли ми не знаходимо значень - ми починаємо підійматись вгору від профіля по дереву батьківських профілів доки не
    //знайдемо профіль де ця властивіть перевизначена
    //тут відбувается змагання дублікатів profileHistory - згадувалось строка з номером профіля і всіх його батьк профілів
    // чим далі профіль в строці тим нижче він у дереві, тобто строка '0,1,7,15' фактично означає 0->1->7->15 де 0 - корньовий
    //логічно що дублікат профіля 7 виграє у дублікату профіля 1, адже він знаходится нижче по дереву і ближче до цільового профіля(в нашому випадку наприклад 15)
    const uniqueDefiniteProps = allDefiniteProps.reduce(
      (acc, prop) =>
        acc.some(({ menuId }) => menuId === prop.menuId)
          ? acc.map((item) =>
              item.menuId === prop.menuId &&
              profileHistory.indexOf(`${prop.profileId}`) > profileHistory.indexOf(`${item.profileId}`)
                ? prop
                : item,
            )
          : [...acc, prop],
      [],
    );

    const calculatedElementsByProps = props
      //додаемо ознаку чи визначена властивість, вона визначена якщо має такий же номер профілю як і цільовий(профіль по якому ми шукаємо)
      .map((prop) => ({ ...prop, isDefined: prop.profileId === selectProfiles[0].profileId }))
      //знаходимо дублікати адже властивість може бути визначена у декількох батьківських профілях, ми вибираємо той батьківський профіль,
      //що знаходится найнижче, тобто коли ми не знаходимо значень - ми починаємо підійматись вгору від профіля по дереву батьківських профілів доки не
      //знайдемо профіль де ця властивіть перевизначена
      //тут відбувается змагання дублікатів profileHistory - згадувалось строка з номером профіля і всіх його батьк профілів
      // чим далі профіль в строці тим нижче він у дереві, тобто строка '0,1,7,15' фактично означає 0->1->7->15 де 0 - корньовий
      //логічно що дублікат профіля 7 виграє у дублікату профіля 1, адже він знаходится нижче по дереву і ближче до цільового профіля(в нашому випадку наприклад 15)
      .reduce(
        (acc, prop) =>
          acc.some(({ menuId }) => menuId === prop.menuId)
            ? acc.map((item) =>
                item.menuId === prop.menuId &&
                profileHistory.indexOf(`${prop.profileId}`) > profileHistory.indexOf(`${item.profileId}`)
                  ? prop
                  : item,
              )
            : [...acc, prop],
        [],
      )
      //властивість може бути не знайдена не тільки тому що його не має(тобто воно не визначена і тоді шукаємо в батьк профілях) а й тому що воно є -
      // але має інакше значення ніж те - по якому ми шукаємо. Щоб уникнути цього багу ми знаходимо всі перевизначені властивості по вибранним елементам і профілям
      //перевіряемо чи є наша властивіть в списку перевизначених, якшо не має - ми можемо бути точно впевнені - шо ця властивіть не визначена і впевнено його брати з
      //батьківських профілів
      .filter(
        ({ menuId, profileId }) =>
          !uniqueDefiniteProps.some(
            ({ menuId: definiteMenuId, profileId: definiteProfileId }) =>
              menuId === definiteMenuId && definiteProfileId !== profileId,
          ),
      );

    return calculatedElementsByProps && calculatedElementsByProps.length
      ? calculatedElementsByProps.map((element) => ({
          ...element,
          profileHistorySlug: profileHistory,
        }))
      : [];
  };
}

export default new NavigationMenuElementsGridService();
