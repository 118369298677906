import React, { PureComponent } from 'react';
import { Button, message, Modal, Select } from 'antd';

import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import CustomInput from '../../../components/Input/Input';
import SUOService from './SUO.service';
import { DOCUMENT_TYPE_TRANSLATION_OBJECT } from './constants';
import LoadingPanel from '../../../components/loader';

import './SUO.styled.scss';

const api = new ApiService();
const { Option } = Select;

class AddSUODialog extends PureComponent {
  state = {
    isLoading: false,
  };

  renderInputRow = ({ isLongLabel, label, inputComponent }) => {
    return (
      <div className="AddSUODialog-inputRow">
        <div className={isLongLabel ? 'AddSUODialog-longLabel' : 'AddSUODialog-label'}>{label}</div>
        {inputComponent}
      </div>
    );
  };

  renderInput = ({ name, type }) => {
    const { formState, onChangeFormState, formErrors, onError, translate } = this.props;

    return (
      <div className="AddSUODialog-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          setError={onError}
          validators={SUOService.getValidators(translate)}
          name={name}
          type={type}
        />
      </div>
    );
  };

  getWithZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  createSUO = async () => {
    const {
      formState: { DocumentIssueDate, DateBd, DocumentType, ...restFormState },
      closeModal,
      onRefresh,
      translate,
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const isSuccess = await api.createSUO({
        ...restFormState,
        DocumentType: DocumentType || 'None',
        DateBd: `${new Date(DateBd).getFullYear()}-${this.getWithZero(
          new Date(DateBd).getMonth() + 1,
        )}-${this.getWithZero(new Date(DateBd).getDate())}`,
        DocumentIssueDate: `${new Date(DocumentIssueDate).getFullYear()}-${this.getWithZero(
          new Date(DocumentIssueDate).getMonth() + 1,
        )}-${this.getWithZero(new Date(DocumentIssueDate).getDate())}`,
      });

      this.setState({
        isLoading: false,
      });

      if (!isSuccess) {
        message.error(translate('page.blockedTransactions.SUOAddedFailed'), 2.5);
        return;
      }

      closeModal();
      message.success(translate('page.blockedTransactions.SUOAddedSuccessfully'), 2.5);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { visible, closeModal, translate, formState, onChangeFormState, formErrors } = this.props;
    const { isLoading } = this.state;

    const isRequiredFieldsEmpty = !formState.NameUk || !formState.TaxerId;
    const isErrorExist = Object.values(formErrors).some(Boolean);

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={translate('page.blockedTransactions.addUserInfo')}
        className="AddSUODialog"
        footer={[
          <Button key="back" type="primary" disabled={isRequiredFieldsEmpty || isErrorExist} onClick={this.createSUO}>
            {translate('core.buttonTitles.add')}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div>
          {this.renderInputRow({
            label: translate('page.blockedTransactions.col-fullName'),
            inputComponent: this.renderInput({ name: 'NameUk' }),
          })}

          {this.renderInputRow({
            label: translate('page.blockedTransactions.col-INN'),
            inputComponent: this.renderInput({ name: 'TaxerId' }),
          })}

          <div className="AddSUODialog-inputsRow">
            {this.renderInputRow({
              label: translate('page.blockedTransactions.col-birthday'),
              inputComponent: this.renderInput({ name: 'DateBd', type: 'datePicker' }),
            })}
            {this.renderInputRow({
              label: translate('page.blockedTransactions.col-documentDate'),
              inputComponent: this.renderInput({ name: 'DocumentIssueDate', type: 'datePicker' }),
            })}
          </div>

          {this.renderInputRow({
            isLongLabel: true,
            label: translate('page.blockedTransactions.documentType'),
            inputComponent: (
              <Select
                value={formState.DocumentType}
                className="AddSUODialog-input"
                onChange={(value) => {
                  onChangeFormState('DocumentType', value);
                }}
              >
                {Object.entries(DOCUMENT_TYPE_TRANSLATION_OBJECT).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {translate(`page.blockedTransactions.${value}`)}
                  </Option>
                ))}
              </Select>
            ),
          })}

          {this.renderInputRow({
            isLongLabel: true,
            label: translate('page.blockedTransactions.col-identityDocument'),
            inputComponent: this.renderInput({ name: 'DocumentNumber' }),
          })}

          {this.renderInputRow({
            label: translate('page.blockedTransactions.col-issuingAuthority'),
            inputComponent: this.renderInput({ name: 'DocumentIssuingAuthority' }),
          })}

          {this.renderInputRow({
            label: translate('page.blockedTransactions.col-placeResidence'),
            inputComponent: this.renderInput({ name: 'Residence' }),
          })}
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddSUODialog);
