class ValidationService {
  limitValidation = (translate, minValue, maxValue) => (value) => {
    if (!value && value !== 0) {
      return null;
    }

    if (!Number.isInteger(value) || `${value}`.indexOf('.') > -1) {
      return `${translate('grids.comission-sub.errorMessages.integerRequired')}`;
    }

    if (value < minValue) {
      return `${translate('page.nonRiskLimitsGrid.enteredMinNumberError')} ${minValue}`;
    }

    if (value > maxValue) {
      return `${translate('page.nonRiskLimitsGrid.enteredMaxNumberError')} ${maxValue}`;
    }

    return null;
  };

  getNightTimeDate = (nightTime) => {
    if (!nightTime) {
      return null;
    }

    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0 <= new Date(nightTime).getHours() && new Date(nightTime).getHours() <= 17
        ? new Date().getDate() + 1
        : new Date().getDate(),
      new Date(nightTime).getHours(),
      new Date(nightTime).getMinutes(),
      0,
      0,
    );
  };

  getNightLimitDate = (hour) =>
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0 <= hour && hour <= 17 ? new Date().getDate() + 1 : new Date().getDate(),
      hour,
      0,
      0,
      0,
    );

  nightTimeStartValidator =
    (translate) =>
    ({ nightTimeStart, nightTimeEnd }) => {
      const nightTimeStartDate = this.getNightTimeDate(nightTimeStart);
      const nightTimeEndDate = this.getNightTimeDate(nightTimeEnd);
      const nightTimeStartLimitDate = this.getNightLimitDate(18);
      const nightTimeEndLimitDate = this.getNightLimitDate(8);

      if (!nightTimeStartDate && !nightTimeEndDate) {
        return null;
      }

      if (
        (nightTimeStartDate && +nightTimeStartLimitDate - +nightTimeStartDate > 0) ||
        (nightTimeStartDate && +nightTimeEndLimitDate - +nightTimeStartDate < 0) ||
        (nightTimeEndDate && +nightTimeStartLimitDate - +nightTimeEndDate > 0) ||
        (nightTimeEndDate && +nightTimeEndLimitDate - +nightTimeEndDate < 0)
      ) {
        return translate('page.nonRiskLimitsGrid.nightTimeLimitError');
      }

      if (nightTimeStartDate && nightTimeEndDate && nightTimeEndDate - nightTimeStartDate < 0) {
        return translate('page.nonRiskLimitsGrid.nightTimeLimitDifferentError');
      }

      return null;
    };

  getValidators = (translate, minLimitTurns, minLimitTurnsNight, maxLimitTurns, maxLimitTurnsNight) => ({
    dailyLimit: this.limitValidation(translate, minLimitTurns, maxLimitTurns),
    nightlyLimit: this.limitValidation(translate, minLimitTurnsNight, maxLimitTurnsNight),
  });
}

export default new ValidationService();
