class CopyCommissionService {
  getDropdownsSaveCallback = (saveToStateCallback, sourceDropdownName, targetDropdownName) => (profileList) => {
    saveToStateCallback(sourceDropdownName, profileList);

    if (targetDropdownName) {
      saveToStateCallback(targetDropdownName, profileList);
    }
  };

  getDropdownSaveCallbacks = (saveToStateCallback) => {
    return {
      SERVICE_TO_SERVICE_TYPE: {
        sourceProfileDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'sourceProfileDropdown'),
        sourceServiceDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourceServiceDropdown',
          'targetServiceDropdown',
        ),
        sourcePaymentTypeDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourcePaymentTypeDropdown',
          'targetPaymentTypeDropdown',
        ),
        targetProfileDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'targetProfileDropdown'),
        targetServiceDropdownSave: () => {},
        targetPaymentTypeDropdownSave: () => {},
      },

      PROFILE_TO_PROFILE_TYPE: {
        sourceProfileDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourceProfileDropdown',
          'targetProfileDropdown',
        ),
        sourceServiceDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'sourceServiceDropdown'),
        sourcePaymentTypeDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourcePaymentTypeDropdown',
          'targetPaymentTypeDropdown',
        ),
        targetProfileDropdownSave: () => {},
        targetServiceDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'targetServiceDropdown'),
        targetPaymentTypeDropdownSave: () => {},
      },

      PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE: {
        sourceProfileDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourceProfileDropdown',
          'targetProfileDropdown',
        ),
        sourceServiceDropdownSave: this.getDropdownsSaveCallback(
          saveToStateCallback,
          'sourceServiceDropdown',
          'targetServiceDropdown',
        ),
        sourcePaymentTypeDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'sourcePaymentTypeDropdown'),
        targetProfileDropdownSave: () => {},
        targetServiceDropdownSave: () => {},
        targetPaymentTypeDropdownSave: this.getDropdownsSaveCallback(saveToStateCallback, 'targetPaymentTypeDropdown'),
      },
    };
  };
}

export default new CopyCommissionService();
