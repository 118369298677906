import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';

import { ApiService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import ModalChangeProfile from './ModalChangeProfile';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdown from '../GridDropdown/GridDropdown';
import CellBoolean from '../../pages/account/cellBoolean';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class GridTerminalComissions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray(props.permissionName);
  }

  state = {
    isLoading: false,
    data: [],
    selectedItems: [],
    owners: [],
    selectedOwners: [],
    visible: false,
    isMultiSelectShow: true,
  };

  componentDidMount() {
    this.fetchOwners();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      this.setState({
        owners:
          owners && owners.length
            ? DropdownNormalizersService.normalizeOwners([
                {
                  OwnerId: 0,
                  OwnerType: '-',
                  ShortName: 'Все диллеры',
                  OwnerStatus: 'Арестован',
                  OwnerAddress: 'м.Київ. вул. Межигірська, 82а',
                  OwnerStatusId: 3,
                  OwnerTypeId: 2,
                },
                ...owners,
              ])
            : [],
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  makeQuery = async () => {
    const { selectedOwners } = this.state;
    if (!selectedOwners.length) return;

    try {
      this.toggleLoader(true);
      const data = await api.getClientComission([selectedOwners]);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwnersSelect = (selectedOwners) => {
    this.setState({ selectedOwners }, this.makeQuery);
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { owners, selectedOwners, selectedItems } = this.state;
    const { buttonPermission } = this.getPermissionByName();

    if (!owners || !owners.length) {
      return <></>;
    }

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdown
          data={owners}
          colConfig={OWNERS_COLUMN_CONFIG}
          selectItems={selectedOwners}
          onSave={this.onOwnersSelect}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
          isSingle
        />
        {checkElement(buttonPermission, this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            disabled={!selectedItems || !selectedItems.length}
            onClick={this.toggleModal}
          >
            {translate('page.commission.btn-toggle')}
          </Button>
        )}
      </Col>
    );
  };

  pushToExternalManagementCommissionServicesPage = (cellProps) => {
    const { history } = this.props;
    const {
      dataItem: { ProfileId, OwnerId },
    } = cellProps;

    return history.push({
      pathname: '/pages/managementCommissions/services',
      state: { ProfileId, OwnerId, PaymentType: 1 },
    });
  };

  openClientCommissionServicesTab = (cellProps) => {
    const {
      dataItem: { OwnerId },
    } = cellProps;

    return tabService.addTab({
      type: 'profile',
      dataItem: { ...cellProps.dataItem, currentOwnerId: OwnerId },
    });
  };

  renderOnclickProfileName = (cellProps) => {
    const { permissionName } = this.props;
    return permissionName === 'commissionClient'
      ? this.openClientCommissionServicesTab(cellProps)
      : this.pushToExternalManagementCommissionServicesPage(cellProps);
  };

  getPermissionByName = () => {
    const { permissionName } = this.props;

    return permissionName === 'commissionClient'
      ? {
          gridPermission: 'clientComissionsByTerminals',
          tabPermission: 'clientComissionsByTerminals-profileNameOnclick',
          buttonPermission: 'clientComissionsByTerminals-change-profile',
        }
      : {
          gridPermission: 'managementCommissionTerminals-grid',
          tabPermission: 'managementCommissionTerminals-profileNameOnclick',
          buttonPermission: 'managementCommissionTerminals-changeProfile',
        };
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render = () => {
    const { isLoading, data, visible, selectedOwners, selectedItems } = this.state;
    const { permissionName } = this.props;
    const { tabPermission, gridPermission } = this.getPermissionByName();

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name={gridPermission}
          fieldForSelection="TerminalId"
          permissionName={permissionName}
          onSelect={this.handleSelectionChange}
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="OwnerId"
            title="grids.commission-terminals.column-numberDealer"
            width="100"
            filter="numeric"
          />

          <GridColumn field="OwnerName" title="grids.commission-terminals.column-dealer" width="120" filter="numeric" />

          <GridColumn
            field="TerminalId"
            title="grids.commission-terminals.column-terminal"
            width="180"
            filter="numeric"
          />

          <GridColumn field="TypeName" title="grids.commission-terminals.column-type" width="150" />

          <GridColumn field="RegionName" title="grids.commission-terminals.column-region" width="150" />

          <GridColumn field="CityName" title="grids.commission-terminals.column-city" width="150" />

          <GridColumn field="Address" title="grids.commission-terminals.column-address" width="150" />

          <GridColumn field="TerminalName" title="grids.commission-terminals.column-name" width="150" />

          <GridColumn
            field="ProfileName"
            title="grids.commission-terminals.column-profile"
            width="270"
            onClick={
              checkElement(tabPermission, this.currentArray) ? (props) => this.renderOnclickProfileName(props) : null
            }
          />

          <GridColumn field="StatusName" title="grids.commission-terminals.column-status" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.commission-terminals.column-date"
            width="220"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="UserNamePost" title="grids.commission-terminals.column-executor" width="150" />

          <GridColumn
            field="DateLastConnect"
            title="grids.commission-terminals.column-dateLastConnect"
            width="220"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="DateLastTransaction"
            title="grids.commission-terminals.column-dateLastTransaction"
            width="220"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn field="BrandName" title="page.subBrandsTerminals.col-brand" width="120" />
          <GridColumn
            field="IsChangeCommissionEnabled"
            title="page.subBrandsTerminals.col-changeProfile"
            width="260"
            cell={WrappedCellBoolean}
          />
        </Grid>
        {visible && (
          <ModalChangeProfile
            selectedTerminals={selectedItems}
            data={data}
            selectedOwners={selectedOwners}
            visible={visible}
            closeModal={this.closeModal}
            toggleModal={this.toggleModal}
            update={this.makeQuery}
            isIgnoreChangeCommissionEnabled={permissionName === 'ManagementCommissionTerminals'}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridTerminalComissions));
