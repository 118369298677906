import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import GridWhiteListAdmin from './gridWhiteListAdmin';
import GridWhiteListAdminDelete from './gridWhiteListAdminDelete';
import GridWhiteListAdminAdd from './gridWhiteListAdminAdd';
import PageTabs from '../../components/tabber/tabber';

class PageWhiteListAdmin extends PureComponent {
  constructor(props) {
    super(props);
    const { translate } = props;
    this.state = {
      tabs: [
        {
          title: `${translate('page.whiteList.tab-main')}`,
          content: <GridWhiteListAdmin />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'deleteWhiteList':
        tabs.push({
          title: `${translate('page.whiteList.tab-delete-1')}`,
          content: <GridWhiteListAdminDelete item={dataItem} />,
          closable: true,
          key,
        });
        break;
      case 'addWhiteList':
        tabs.push({
          title: `${translate('page.whiteList.tab-add')}`,
          content: <GridWhiteListAdminAdd item={dataItem} />,
          closable: true,
          key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;

    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageWhiteListAdmin);
