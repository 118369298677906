import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row } from 'antd';
import { withTranslate } from '../../../../../contexts/localContext';
import '../../../profiles/modalProfile.scss';
import LoadingPanel from '../../../../../components/loader';

class ModalApplySettings extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    // update: PropTypes.func.isRequired,
    item: PropTypes.arrayOf(PropTypes.any).isRequired,
    // owners: PropTypes.arrayOf(PropTypes.any).isRequired
  };

  state = {
    loading: false,
    isLoading: false,
    noSelected: false,
  };

  componentDidMount() {
    const { item } = this.props;
    if (!item || item.length < 1) {
      this.setState({ noSelected: true });
      return;
    }
  }

  // checkElementReadOnlyProperty = () => {
  //   const { fullData, item } = this.props;
  //   let newData = [];
  //   if (!item || item.length < 1) {
  //     newData = [];
  //   }
  //   else {
  //     for (const fullDataElem of fullData) {
  //       for (const itemElem of item) {
  //         if (fullDataElem && fullDataElem.MainProfileId === itemElem
  //           && fullDataElem.Readonly === true) {
  //           newData.push(fullDataElem.MainProfileName);
  //         }
  //       }
  //     }
  //   }
  //   return newData;
  // }

  // getMainProfileListForModalChangeProfile = () => {
  //   const { owners, item } = this.props;
  //   if (!item) {
  //     this.setState({ noSelected: true })
  //     return;
  //   }

  //   const disabledToDeleteElems = this.checkElementReadOnlyProperty();
  //   if (disabledToDeleteElems.length > 0) {
  //     this.setState({ isDisabledForChange: true, disabledToDeleteElems });
  //     return;
  //   }

  //   if (!owners || owners.length < 1) return;
  // }

  // deleteProfile = async () => {
  //   const { item, translate, closeModal, update, name } = this.props;
  //   if (!item) return;
  //   try {
  //     this.setState({ loading: true });
  //     const response = await api.deleteMainProfile(item);
  //     this.setState({ loading: false });
  //     closeModal();
  //     message.success(`${translate('grids.profilesMainProfileList.modal-delete-profile-success')}`, 2.5);
  //     StorageService.setItem(`selectionListForGrid-${name}`, []);
  //     update();
  //     return response;
  //   }
  //   catch (err) {
  //     const { showError } = this.props;
  //     showError(err);
  //   }
  //   finally {
  //     this.setState({ loading: false });
  //   }
  // }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  render() {
    const { translate, visible, closeModal, item } = this.props;
    const { loading, isLoading, noSelected } = this.state;
    return (
      <>
        <Modal
          title={'Применить удалённые настройки'}
          visible={visible}
          centered
          // onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment"
          zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.commission.btn-back')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!item}
              loading={loading}
              // onClick={this.deleteProfile}
            >
              {translate('page.screenControlTerminals.btn-submit-dsb')}
            </Button>,
          ]}
        >
          <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
            <Col xs={24} sm={24} md={24}>
              {noSelected && (
                <p style={{ color: 'red', marginBottom: 0 }}>
                  {translate('grids.profilesMainProfileList.modal-delete-profile-restriction-2')}
                </p>
              )}
            </Col>
          </Row>
          {isLoading && <LoadingPanel />}
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalApplySettings);
