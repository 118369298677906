import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ApiService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellProgress from '../monitorActivity/cellProgress';
import '../adminGeneralResults/gridAdminGeneralResults.scss';
import './gridOwnerStockGeneralResults.scss';

const WrappedCellProgress = (props) => <CellProgress {...props} />;

const api = new ApiService();

const sortByRating = (a, b) => {
  return a.Rating - b.Rating;
};

class GridOwnerStockGeneralResults extends Component {
  state = {
    isLoading: false,
    data: [],
    visible: false,
  };

  counter = 1;

  componentDidMount() {
    this.makeQuery();
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };
  // unlock in future
  initialTransform(arr) {
    const userInfo = StorageService.getItem('userInfo');
    if (!userInfo.OwnerId && userInfo.OwnerId !== 0) return [];
    const findedIdx = arr.findIndex((el) => el.OwnerId === userInfo.OwnerId);
    console.log(findedIdx);
    if (findedIdx === -1) return arr;
    const findElem = arr[findedIdx];
    /*модифицирую массив, убирая найденный элемент,
     ранее записав его в переменную*/

    const modifiedArr = [...arr.slice(0, findedIdx), ...arr.slice(findedIdx + 1)];
    return [findElem, ...modifiedArr.sort(sortByRating)];
  }

  makeQuery = async () => {
    const userInfo = StorageService.getItem('userInfo');
    if (!userInfo.OwnerId && userInfo.OwnerId !== 0) return;

    try {
      this.toggleLoader(true);
      const model = {
        OwnerId: userInfo.OwnerId,
      };
      const data = await api.generalResultByOwner(model);
      const renderData = this.initialTransform(data).map((el) => {
        const BrandedByStandartPercent =
          el.TerminalsCount && el.TerminalsCount !== 0
            ? ((el.BrandedByStandartCount * 100) / el.TerminalsCount).toFixed(0)
            : 0;
        const BrandedPercent =
          el.TerminalsCount && el.TerminalsCount ? ((el.BrandedCount * 100) / el.TerminalsCount).toFixed(0) : 0;
        return {
          ...el,
          BrandedByStandartPercent,
          BrandedPercent,
        };
      });
      this.setState({ data: renderData });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <div className="gridAdminStockPhotos-toolbar-row">
          <h2 className="gridAdminStockPhotos-toolbar-h2">{translate('page.adminGeneralResults.toolbar-h2')}</h2>
        </div>
        <div className="gridAdminStockPhotos-toolbar-row">
          <Button className="ownerStockPhotos-toolbar-changeStatus" style={{ marginTop: '10px' }}>
            <Link to="/pages/stock/ownerStockPhotos">{translate('grids.adminGeneralResults.btn-my-photos')}</Link>
          </Button>
        </div>
      </Col>
    );
  };

  renderColor = (value) => {
    if (value === 1 || value === 2 || value === 3) return { background: 'rgb(181,248,170)' };
    else return;
  };

  rowRender = (element, props) => {
    const value = props.dataItem.Rating;
    const { style: elementStyle, children } = element.props;
    const color = this.renderColor(value);
    const style = { ...color, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <Grid
        data={data}
        rowHeight={20}
        rowRender={this.rowRender}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="ownerStockGeneralResults"
        permissionName="ownerStockGeneralResults"
      >
        <GridColumn field="Rating" title="grids.adminGeneralResults.column-rating" width="180" filter="numeric" />

        <GridColumn
          field="OwnerName"
          title="grids.adminGeneralResults.column-ownerName"
          width="270"
          // onClick={(props) =>
          //   tabService.addTab({ type: 'photos', dataItem: props.dataItem })}
        />

        <GridColumn
          field="BrandedByStandartPercent"
          title="grids.adminGeneralResults.column-brandedByStandartPercent"
          width="150"
          filter="numeric"
          cell={WrappedCellProgress}
        />

        <GridColumn
          field="BrandedPercent"
          title="grids.adminGeneralResults.column-brandedPercent"
          width="150"
          filter="numeric"
          cell={WrappedCellProgress}
        />

        <GridColumn
          field="GiftsCount"
          title="grids.adminGeneralResults.column-giftsCount"
          width="150"
          filter="numeric"
        />
      </Grid>
    );
  }
}

export default withTranslate(GridOwnerStockGeneralResults);
