const searchtransactions = (translate, value) => {
  switch (value) {
    case 'terminalTransactions-btn-submit':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'searchtransactions-transactionWithAdditionalProps':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.transactionWithAdditionalProps')}`;
    case 'searchtransactions-expandSearchOptions':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.expandSearchOptions')}`;
    case 'searchtransactions-addComment':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.addComment')}`;
    case 'searchtransactions-reProcessingTransaction':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.reProcessingTransaction')}`;
    case 'searchtransactions-printCheckRequest':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.dashboard.tab-transaction-details')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.requestPrintCheck')}`;
    case 'searchtransactions-paymentInstruction':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.dashboard.tab-transaction-details')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.paymentInstruction')}`;
    case 'searchtransactions-reSendingCallback':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.dashboard.tab-transaction-details')},
              ${translate(`page.adminPanel.type-button`)}:
              ${translate('page.transactions.reSendingCallback')}`;
    case 'searchtransactions-dropdown-status':
      return `${translate('page.adminPanel.type-dropdown')}:
              ${translate('owner.column-status')}`;
    case 'terminalTransactions-transactionIdOnclick':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('grids.transactions.button-details')}`;
    case 'terminalTransactions-sendingEmailReceipt':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.sendingEmailReceipt')}`;
    case 'terminalTransactions-sendingViberReceipt':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.sendingViberReceiptCyrillic')}`;
    case 'terminalTransactions-downloadReceipt':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.downloadReceipt')}`;
    case 'searchtransactions-editTransaction':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.editTransaction')}`;
    case 'searchtransactions-additionalInfo':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.additionalInfo')}`;
    case 'searchtransactions-rejectTransaction':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.rejectTransaction')}`;
    case 'searchtransactions-unlockTransaction':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.transactions.unlockTransaction')}`;
    case 'terminalTransactions':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'transactionDetails-Name':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.dashboard.tab-transaction-details')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.transactionDeatails.column-name')}`;
    case 'transactionDetails-Value':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.dashboard.tab-transaction-details')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.transactionDeatails.column-value')}`;
    default:
      return value;
  }
};

export default searchtransactions;
