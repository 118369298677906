import React, { PureComponent } from 'react';
import { Button, Col } from 'antd';
import { withTranslate } from '../../../contexts/localContext';

class CopyDealerCommissionsButtons extends PureComponent {
  render() {
    const { translate } = this.props;
    return (
      <Col className="CopyDealerCommissions-footer">
        <Button type="primary" {...this.props}>
          {translate('core.buttonTitles.copy')}
        </Button>
      </Col>
    );
  }
}
export default withTranslate(CopyDealerCommissionsButtons);
