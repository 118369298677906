import React, { Component } from 'react';
import { Sortable } from '@progress/kendo-react-sortable';
import { withTranslate } from '../../../../contexts/localContext';
import { Button, Input, message, Row, Col } from 'antd';
import { ApiService } from '../../../../services';
import './editProfile.scss';
import SortableItemUI from './sortableItemUi';
import checkElement from '../../../../utils/checkElement';
import findArray from '../../../../utils/findArrForCurrentPage';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.currentArray = findArray('screenControlProfiles');
    const {
      item: { MainProfileName, OwnerId },
    } = props;
    if (!MainProfileName || !OwnerId) return;
    this.state = {
      data: [],
      reservedData: [],
      isDisabled: false,
      isSuperUser: false,
      elementsShouldBeDisabled: [],
      isLoading: false,
      templateValue: MainProfileName,
      isEditing: false,
      isRepeatedElem: false,
    };
  }

  componentDidMount() {
    this.initRequest();
  }

  toggleLoader = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  };

  initRequest = async () => {
    const {
      showError,
      item: { MainProfileId },
    } = this.props;
    try {
      this.toggleLoader();
      const data = await api.getProfileFromProfiles(MainProfileId);
      this.setState(
        {
          loading: false,
          data: data && data.length ? data.filter(({ IsReserve }) => !IsReserve) : [],
          reservedData: data && data.length ? data.filter(({ IsReserve }) => IsReserve) : [],
        },
        () => {
          this.renderData();
        },
      );
    } catch (err) {
      showError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.checkDisability();
    }
  }

  onDragOver = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  onNavigate = (event) => {
    this.setState({
      data: event.newState,
    });
  };

  changeImage = (RowIndex, newElem) => {
    const parsedElem = JSON.parse(newElem);
    const { MenuId, MenuName, IconUrl, Icon, Caption } = parsedElem;
    this.setState(({ data }) => {
      const newData = data.map((el) => {
        if (el.RowIndex === RowIndex) {
          el.MenuId = MenuId;
          el.MenuName = MenuName;
          el.IconUrl = IconUrl;
          el.Icon = Icon;
          el.Caption = Caption;
        }
        return el;
      });
      return {
        data: newData,
      };
    });
  };

  toggleElementLock = (MenuId) => {
    this.setState(({ data }) => {
      const newData = data.map((el) => {
        if (el.MenuId === MenuId) {
          el.IsHold = !el.IsHold;
        }
        return el;
      });
      return {
        data: newData,
      };
    });
  };

  dropImage = (RowIndex) => {
    this.setState(({ data }) => {
      let newData;
      if (data.length > 20) {
        newData = data.filter((el) => el.RowIndex !== RowIndex);
      } else {
        newData = data.map((el) => {
          if (el.RowIndex === RowIndex) {
            el.IconUrl = null;
            el.Caption = <p>+</p>;
          }
          return el;
        });
      }
      return {
        data: newData,
      };
    });
  };

  getDisabledFieldInsteadOfRole = () => {
    const { isSuperUser } = this.state;
    if (!isSuperUser) {
      return 'disabled';
    } else {
      return '';
    }
  };

  renderData = () => {
    this.setState((state) => {
      const newData = [...state.data];
      while (newData.length < 20) {
        newData.push({
          MenuId: null,
          Caption: <p>+</p>,
          IconUrl: null,
          RowIndex: newData.length + 1,
        });
      }
      return {
        data: newData,
      };
    });
  };

  checkDisability = () => {
    const { data } = this.state;
    let isDisabled = false;
    let isRepeatedElem = false;
    const arrayofMenuIds = data.filter((el) => el.MenuId !== null).map((el) => el.MenuId);
    const uniqueArray = Array.from(new Set(arrayofMenuIds));
    if (arrayofMenuIds.length !== uniqueArray.length) {
      isRepeatedElem = true;
      isDisabled = true;
    } else {
      isRepeatedElem = false;
      isDisabled = false;
    }

    if (data.filter((el) => el.IconUrl === null).length > 0) {
      isDisabled = true;
      isRepeatedElem = false;
    }

    this.setState({ isDisabled, isRepeatedElem }, () => this.showErrorRepeatedElems(isRepeatedElem));
  };

  showErrorRepeatedElems = (isRepeatedElem) => {
    const { translate } = this.props;
    if (isRepeatedElem === true) {
      return message.error(`${translate('page.screenControlBusinessServices.profile-error-repeated-elements')}`, 2);
    }
  };

  onSubmitHandler = () => {
    const { elementsShouldBeDisabled } = this.state;
    const { translate } = this.props;
    const newFiltered = elementsShouldBeDisabled.filter((el) => !el.disabled);
    this.setState({ elementsShouldBeDisabled: newFiltered });
    if (newFiltered.length > 0) {
      return message.error(translate('page.screenControlBusinessServices.new-profile-error-not-all-disabled'), 2);
    } else {
      this.setState(({ isEditing }) => {
        return {
          isEditing: !isEditing,
        };
      }, this.saveEditedProfile);
    }
  };

  saveEditedProfile = async () => {
    const {
      showError,
      item: { MainProfileId, MainProfileHoldId, RegionId, OwnerId, MainProfileReserveId },
      translate,
    } = this.props;
    const { data, isEditing, templateValue, reservedData } = this.state;
    if (!isEditing) {
      try {
        this.toggleLoader();
        const model = {
          OwnerId,
          MainProfileId,
          MainProfileHoldId,
          MainProfileName: templateValue,
          MainProfileReserveId,
          RegionId,
          Items: [...data, ...reservedData].map((el, index) => {
            el.RowIndex = index;
            return el;
          }),
        };
        const response = await api.postMainProfileManage(model);
        this.setState(
          (state) => {
            const newData = state.data.map((el, index) => {
              el.RowIndex = index;
              return el;
            });
            return {
              loading: false,
              data: newData,
            };
          },
          () =>
            message.success(`${translate('page.screenControlBusinessServices.edit-profile-success')}`, 2, () =>
              window.location.reload(true),
            ),
        );
        return response;
      } catch (err) {
        showError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onChangeTemplateValue = (e) => {
    this.setState({ templateValue: e.target.value });
  };

  render() {
    const { data, isDisabled, isSuperUser, isLoading, templateValue, isEditing } = this.state;
    const { translate, item } = this.props;
    return (
      <>
        <div className="scrcontrol--wrapper">
          <Row
            className="modal-change-profile-row"
            type="flex"
            style={{ flexWrap: 'wrap', marginTop: 10 }}
            align="middle"
            gutter={24}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={16}>
              <Row
                className="modal-change-profile-row for-sub-input"
                type="flex"
                align="middle"
                gutter={24}
                style={{ flexWrap: 'wrap' }}
              >
                <p style={{ marginBottom: '0' }}>
                  {translate('page.screenControlProfiles.modal-new-profile-input-title')}
                </p>
                <Input onChange={this.onChangeTemplateValue} value={templateValue} disabled={!isEditing} />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Row type="flex" justify="end">
                {checkElement('screenControlProfilesEditProfile-btn-save', this.currentArray) &&
                  data &&
                  data.length > 1 && (
                    <Button
                      type="primary"
                      onClick={this.onSubmitHandler}
                      disabled={isEditing && (isDisabled || !templateValue)}
                    >
                      {!isEditing
                        ? translate('page.screenControlBusinessServices.button-edit')
                        : translate('page.screenControlBusinessServices.button-save')}
                    </Button>
                  )}
              </Row>
            </Col>
          </Row>
          <Sortable
            className={isEditing ? '' : 'sortable-component-disabled'}
            idField={'RowIndex'}
            disabledField={'IsHold'}
            data={data.slice(0, 20)}
            style={{ marginTop: 10 }}
            itemUI={(props) => (
              <SortableItemUI
                key={`${props.dataItem.MenuId}-${props.dataItem.RowIndex}`}
                changeImage={this.changeImage}
                isEditing={isEditing}
                toggleModal={this.toggleModal}
                dropImage={this.dropImage}
                toggleElementLock={this.toggleElementLock}
                isSuperUser={isSuperUser}
                item={item}
                {...props}
              />
            )}
            onDragOver={this.onDragOver}
            onNavigate={this.onNavigate}
          />
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(EditProfile);
