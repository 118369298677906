const dispenserCollection = (translate, value) => {
  switch (value) {
    case 'dispenserCollection-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dispenserCollection-dropdown':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('grids.createTerminal.column-terminalId')}`;
    case 'dispenserCollection-dispenserCollectionHistory-link':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.dispenserCollection.dispenserCollectionHistory-tab')}`;
    case 'dispenserCollection-cardsAvailability-link':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.dispenserCollection.cardsAvailability-tab')}`;
    default:
      return value;
  }
};

export default dispenserCollection;
