const NBUServicesRelations = (translate, value) => {
  switch (value) {
    case 'NBUServicesRelations-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'NBUServicesRelations-add':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.add')}`;
    case 'NBUServicesRelations-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.delete')}`;
    case 'NBUServicesRelations-history':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('page.paymentTypeNBUCode.history')}`;
    case 'NBUServicesRelations-change':
      return `${translate(`page.adminPanel.type-button`)}: ${translate('core.buttonTitles.change')}`;
    default:
      return value;
  }
};

export default NBUServicesRelations;
