import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Row, Alert } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import './modalEmptyData.scss';

class ModalEmptyData extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    dateStart: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
  };

  render() {
    const { translate, visible, toggleModal, dateStart, dateEnd, text } = this.props;
    return (
      <Modal
        title={translate('grids.wallets.confirm.title')}
        visible={visible}
        centered
        onOk={toggleModal}
        onCancel={toggleModal}
        className="modal-empty-data"
        zIndex={'1053'}
        footer={[
          <Button style={{ width: '100%' }} key="submit" type="primary" onClick={toggleModal}>
            {translate('grids.certificates-issued.onOkText')}
          </Button>,
        ]}
      >
        <Row className="modal-change-profile-row" type="flex" style={{ flexWrap: 'wrap' }} align="middle" gutter={24}>
          <Col xs={24}>
            <Alert
              message={translate('grids.wallets.confirm.title')}
              // description={`${text} [${translate('page.monitoringCabinet.modal-from-text')} ${dateStart} ${translate(
              //   'page.monitoringCabinet.modal-to-text',
              // )} ${dateEnd}]`}
              description={text}
              type="error"
              showIcon
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default withTranslate(ModalEmptyData);
