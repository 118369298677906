import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Col, Input, Row } from 'antd';

import './WorkWIthDealers.scss';
import GridWorkDealers from '../GridWorkDealers/GridWorkDealers';
import { ApiService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
const { TextArea } = Input;

const api = new ApiService();

class WorkWithDealers extends PureComponent {
  constructor(props) {
    super(props);
    const { defaultSelectItems } = props;
    this.currentArray = findArray('users');
    this.state = {
      currentSelectItems: [],
      defaultSelectItems: defaultSelectItems,
      headerSelected: false,
    };
  }

  onRowClick = (e) => {
    this.setState(({ currentSelectItems }) => {
      return {
        currentSelectItems: currentSelectItems.includes(e.dataItem.OwnerId)
          ? currentSelectItems.filter((id) => id !== e.dataItem.OwnerId)
          : [...currentSelectItems, e.dataItem.OwnerId],
      };
    });
  };

  handleHeaderSelectionChange = (data) => {
    this.setState(
      ({ headerSelected }) => {
        return {
          headerSelected: !headerSelected,
        };
      },
      () => {
        this.setAllItemsSelected(data);
      },
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { owners } = this.props;
    const { currentSelectItems, headerSelected } = this.state;
    const { currentSelectItems: prevCurrentSelectItems } = prevState;
    if (owners && currentSelectItems.length !== prevCurrentSelectItems.length) {
      this.setState({ headerSelected: owners.length === currentSelectItems.length });
    }
  }

  setAllItemsSelected = (data) => {
    this.setState(({ headerSelected }) => ({
      currentSelectItems: headerSelected ? [...data.map(({ OwnerId }) => OwnerId)] : [],
    }));
  };

  setDefaultItems = () => {
    const { defaultSelectItems = [] } = this.props;
    this.setState({ currentSelectItems: defaultSelectItems });
  };

  ownersAclMerge = async () => {
    const { currentSelectItems, defaultSelectItems } = this.state;
    const { userId, setIsLoading } = this.props;

    const dealersForDelete = defaultSelectItems
      .filter((OwnerId) => !currentSelectItems.includes(OwnerId))
      .map((OwnerId) => ({ userId, OwnerId, forDelete: true }));

    const model = [
      ...currentSelectItems.map((OwnerId) => ({ userId, OwnerId, forDelete: false })),
      ...dealersForDelete,
    ];

    try {
      await setIsLoading(true);
      await api.ownersMerge(model);
      await window.location.reload();
      await setIsLoading(false);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await setIsLoading(false);
    }
  };

  render = () => {
    const {
      translate,
      owners,
      defaultSelectItems,
      dataItem: { FirstName, LastName },
    } = this.props;
    const { currentSelectItems, headerSelected } = this.state;
    return (
      <Col span={24} md={24} lg={24} xl={24} className="WorkWithDealers-content">
        <div className="WorkWithDealers-toolbar">
          <Row type="flex" align="middle" className="WorkWithDealers-headerUserRow">
            <div className="WorkWithDealers-userLabel">{translate('page.adminPanel.label-user')}</div>
            <Input value={`${FirstName} ${LastName}`} disabled />
          </Row>

          {checkElement('users-tabWorkWithDealersEditButton', this.currentArray) && (
            <Button type="primary" className="WorkWithDealers-btnOnSave" onClick={this.ownersAclMerge}>
              {translate('core.buttonTitles.save')}
            </Button>
          )}
        </div>

        <Row className="WorkWithDealers-row">
          {defaultSelectItems && owners && (
            <GridWorkDealers
              dealers={owners}
              onRowClick={this.onRowClick}
              currentSelectItems={currentSelectItems}
              setDefaultItems={this.setDefaultItems}
              headerSelected={headerSelected}
              handleHeaderSelectionChange={this.handleHeaderSelectionChange}
            />
          )}
        </Row>
      </Col>
    );
  };
}
export default withTranslate(WorkWithDealers);
