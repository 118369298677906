export const PHONE_CODE = '38';

export const BASE_INFO_PROPS = [
  {
    name: 'Login',
    label: 'login',
    isDisabled: true,
  },
  {
    name: 'CollectorCode',
    label: 'codeCollector',
    type: 'number',
    isDisabled: true,
    inputProps: {
      span: 18,
      md: 11,
    },
    rowProps: {
      type: 'flex',
      align: 'middle',
    },
  },
  {
    name: 'FirstName',
    label: 'name',
    maxLength: 100,
  },
  {
    name: 'LastName',
    label: 'lastName',
    maxLength: 100,
  },
  {
    name: 'Status',
    label: 'status',
    type: 'checkbox',
  },
];

export const ADDITIONAL_INFO_PROPS = [
  {
    name: 'Company',
    label: 'organization',
    maxLength: 100,
  },
  {
    name: 'Address',
    label: 'address',
  },
  {
    name: 'Note',
    label: 'description',
  },
  {
    name: 'Email',
    label: 'email',
    maxLength: 100,
  },
  {
    name: 'Phone',
    label: 'phone',
    type: 'phone',
  },
  {
    name: 'IM',
    label: 'im',
    maxLength: 100,
  },
];

export const COLLECTOR_FIELDS = [
  {
    name: 'NewCodeCollector',
    label: 'newCodeCollector',
  },
  {
    name: 'RepeatCodeCollector',
    label: 'repeatCodeCollector',
  },
];

export const COLLECTOR_GLOBAL_ERRORS = [
  'newCodeCollectorsNotMatchConfirmed',
  'newCodeCollectorsMoreThanEight',
  'oldCodeCollectorMustNotMatchNewCodeCollector',
];
