import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { Button } from 'antd';
import { withTranslate } from '../../contexts/localContext';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import { tabService } from '../../services';

class CellThemeMessage extends GridCell {
  static defaultProps = {
    mobile: false,
  };

  static propTypes = {
    mobile: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentArray = findArray('messages');
  }

  renderOnClick = () => {
    const { dataItem } = this.props;
    if (checkElement('create-tab-singleMessage', this.currentArray))
      return tabService.addTab({
        type: 'singleMessage',
        dataItem,
      });
    else return null;
  };

  getFontWeight = () => {
    const { dataItem } = this.props;
    const statusId = dataItem.StatusId;
    switch (statusId) {
      case 1:
      case 2:
        return 'bold';
      default:
        return 'normal';
    }
  };
  render() {
    const { dataItem, field, mobile, style, className } = this.props;
    const value = dataItem[field];

    if (mobile) {
      return (
        <Button
          type="link"
          className="lg-p-0 lg-w-100"
          onClick={this.renderOnClick}
          style={{ height: 'auto', lineHeight: '1', userSelect: 'auto' }}
        >
          <span
            style={{
              fontWeight: this.getFontWeight(),
              color: '#ffffff',
              fontSize: '13px',
              cursor: 'pointer',
            }}
          >
            {value}
          </span>
        </Button>
      );
    }

    return (
      <td style={{ ...style }} className={className}>
        <Button
          type="link"
          className="lg-p-0 lg-w-100"
          onClick={this.renderOnClick}
          style={{ height: 'auto', lineHeight: '1', userSelect: 'auto' }}
        >
          <span
            style={{
              fontWeight: this.getFontWeight(),
              color: '#1890ff',
              fontSize: '13px',
              cursor: 'pointer',
            }}
          >
            {value}
          </span>
        </Button>
      </td>
    );
  }
}

export default withTranslate(CellThemeMessage);
