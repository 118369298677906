export const initialValues = {
  UserId: 0,
  OwnerId: 0,
  Login: '',
  FirstName: '',
  LastName: '',
  Status: false,
  Company: '',
  Address: '',
  Note: '',
  Email: '',
  Phone: '',
  Im: '',
};
