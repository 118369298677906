import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { ApiService, StorageService, tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import CellComissionDealer from '../cellComissionDealer';
import ModalCloneApplication from '../modalCloneApplication';
import ModalCommands from '../modalCommands';

import './gridApplications.scss';

const WrappedCellComissionDealer = (props) => <CellComissionDealer {...props} />;

const api = new ApiService();

class GridApplications extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('adding-addressed-payment');
    this.state = {
      isLoading: false,
      data: [],
      visible: false,
      isVisibleClone: false,
      isVisibleCommands: false,
      selectedItems: [],
    };
  }

  toggleVisibleClone = () => {
    this.setState(({ isVisibleClone }) => {
      return {
        isVisibleClone: !isVisibleClone,
      };
    });
  };

  toggleVisibleCommands = () => {
    this.setState(({ isVisibleCommands }) => {
      return {
        isVisibleCommands: !isVisibleCommands,
      };
    });
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.makeQuery();
  }

  checkApplicationForStatus = (id) => {
    const elem = this.state.data.find((el) => el.RequestId === id);
    if (elem === undefined) return true;
    if (elem.StatusId !== 0) return true;
    return false;
  };

  translateStatus = (el) => {
    const { translate } = this.props;
    switch (el.StatusId) {
      case 0:
        return translate('grids.addingAddressedPaymentApplications.status-0');
      case 1:
        return translate('grids.addingAddressedPaymentApplications.status-1');
      case 2:
        return translate('grids.addingAddressedPaymentApplications.status-2');
      case 3:
        return translate('grids.addingAddressedPaymentApplications.status-3');
      case 4:
        return translate('grids.addingAddressedPaymentApplications.status-4');
      case 5:
        return translate('grids.addingAddressedPaymentApplications.status-5');
      case 6:
        return translate('grids.addingAddressedPaymentApplications.status-6');
      default:
        return null;
    }
  };

  makeQuery = async () => {
    const { showError, translate } = this.props;
    try {
      this.toggleLoader();
      const data = await api.servicesCRMRequestsList();
      // !!! пока возвращается признак, перезаписываю комиссию с клиента!
      const mappedData = data.map((el) => {
        return {
          ...el,
          StatusName: this.translateStatus(el),
          CommissionClient:
            el.DealerCommissionsSet === true
              ? translate('page.aap-changeComission.com-dealer-true')
              : translate('page.aap-changeComission.com-dealer-false'),
        };
      });

      this.setState({ data: mappedData });
    } catch (error) {
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    StorageService.removeItem('selectionListForGrid-addingAddressedPaymentApplications');
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  renderTab = () => {
    return tabService.addTab({
      type: 'createApplication',
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        {checkElement('aap-create-app', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px', marginLeft: 0 }} onClick={this.renderTab}>
            {translate('grids.addingAddressedPaymentApplications.btn-create-app')}
          </Button>
        )}
        {checkElement('aap-clone-app', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px', marginLeft: '10px' }}
            onClick={this.toggleVisibleClone}
            disabled={!(selectedItems.length === 1)}
          >
            {translate('grids.addingAddressedPaymentApplications.btn-clone-app')}
          </Button>
        )}

        {checkElement('aap-commands', this.currentArray) && (
          <Button type="primary" onClick={this.toggleVisibleCommands} disabled={!selectedItems.length}>
            {translate('grids.addingAddressedPaymentApplications.select-title')}
          </Button>
        )}
      </Col>
    );
  };

  onCancelHandler = () => {
    this.setState({ visible: false });
  };

  renderEditTab = ({ dataItem }) => {
    if (!checkElement('aap-edit-tab', this.currentArray)) return null;
    return tabService.addTab({
      type: 'editApplication',
      dataItem,
    });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render = () => {
    const { isLoading, data, isVisibleClone, isVisibleCommands, selectedItems } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          selectedField="selected"
          toggleLoader={this.toggleLoader}
          name="addingAddressedPaymentApplications"
          permissionName="adding-addressed-payment"
          multiSelected={true}
          fieldForSelection="RequestId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="RequestId"
            title="grids.addingAddressedPaymentApplications.column-requestId"
            width="200"
            filter="numeric"
            checked={false}
            onClick={this.renderEditTab}
          />

          <GridColumn
            field="RequestNumber"
            title="grids.addingAddressedPaymentApplications.column-requestNumber"
            width="150"
            filter="numeric"
          />

          <GridColumn field="Name" title="grids.addingAddressedPaymentApplications.column-name" width="150" />

          <GridColumn
            field="SupplierNarrative"
            title="grids.addingAddressedPaymentApplications.column-supplierNarrative"
            width="150"
          />

          <GridColumn field="UserName" title="grids.addingAddressedPaymentApplications.column-userId" width="250" />

          <GridColumn
            field="Amount"
            title="grids.addingAddressedPaymentApplications.column-amount"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="CommissionClient"
            title="grids.addingAddressedPaymentApplications.column-commissionClient"
            width="350"
            cell={WrappedCellComissionDealer}
            filterable={false}
            sortable={false}
          />

          <GridColumn field="Iban" title="grids.addingAddressedPaymentApplications.column-iban" width="200" />

          <GridColumn field="Okpo" title="grids.addingAddressedPaymentApplications.column-okpo" width="150" />

          <GridColumn
            field="SupplierName"
            title="grids.addingAddressedPaymentApplications.column-supplierName"
            width="150"
          />

          <GridColumn
            field="SupplierBank"
            title="grids.addingAddressedPaymentApplications.column-supplierBank"
            width="150"
          />

          <GridColumn
            field="AdditionalParameters"
            title="grids.addingAddressedPaymentApplications.column-additionalParameters"
            width="200"
          />

          <GridColumn
            field="SubmenuName"
            title="grids.addingAddressedPaymentApplications.column-submenuName"
            width="200"
          />

          <GridColumn
            field="Terminals"
            title="grids.addingAddressedPaymentApplications.column-terminalId"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="ServiceReplaceName"
            title="grids.addingAddressedPaymentApplications.column-serviceReplaceName"
            width="250"
          />

          <GridColumn
            field="AdditionalInfo"
            title="grids.addingAddressedPaymentApplications.column-additionalInfo"
            width="250"
          />

          <GridColumn field="StatusName" title="grids.addingAddressedPaymentApplications.column-statusId" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.addingAddressedPaymentApplications.column-datePost"
            width="200"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="Phone" title="grids.addingAddressedPaymentApplications.column-phone" width="150" />

          <GridColumn field="Email" title="grids.addingAddressedPaymentApplications.column-email" width="150" />
        </Grid>
        {isVisibleClone && (
          <ModalCloneApplication
            visible={isVisibleClone}
            toggleModal={this.toggleVisibleClone}
            selectedItem={selectedItems[0]}
          />
        )}
        {isVisibleCommands && (
          <ModalCommands
            visible={isVisibleCommands}
            toggleModal={this.toggleVisibleCommands}
            data={data}
            arrSelected={selectedItems.map(({ RequestId }) => RequestId)}
            update={this.makeQuery}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridApplications));
