import React from 'react';

const LoadingPanel = ({ mobileMode }) => (
  <div className={`k-loading-mask ${mobileMode && 'mobileMode--mask'}`}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image" />
    <div className="k-loading-color" />
  </div>
);

export default LoadingPanel;
