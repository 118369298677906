import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
const api = new ApiService();
const aggregate = [
  {
    field: 'TerminalCount',
    aggregate: 'average',
    format: 'n2',
  },
  {
    field: 'TerminalAvgPaymentCount',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ QuantityTotal, TerminalCount }) => QuantityTotal.sum / TerminalCount.average,
  },
  {
    field: 'QuantityTotal',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'AvgPayment',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ AmountTotal, QuantityTotal }) => AmountTotal.sum / QuantityTotal.sum,
  },
  {
    field: 'AmountTotal',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'CommissionProvider',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'CommissionClient',
    aggregate: 'sum',
    format: 'n2',
  },
  {
    field: 'TerminalAvgProfit',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ CommissionProvider, CommissionClient, TerminalCount }) =>
      (CommissionProvider.sum + CommissionClient.sum) / TerminalCount.average,
  },
  {
    field: 'TerminalAvgTurn',
    aggregate: 'count',
    format: 'n2',
    countCallback: ({ AmountTotal, TerminalCount }) => AmountTotal.sum / TerminalCount.average,
  },
];

class ReportTerminalsByDays extends PureComponent {
  state = {
    isLoading: false,
    terminalsByDays: [],
  };
  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };
  getReportTerminalsByDays = async () => {
    const { ownerId, terminalId, dateStart, dateEnd } = this.props;
    try {
      await this.toggleLoader(true);
      const model = {
        ownerId: ownerId,
        terminalId: terminalId,
        dateStart: dateStart,
        dateEnd: dateEnd,
      };
      const terminalsByDays = await api.terminalsByDays(model);
      this.setState({ terminalsByDays });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  async componentDidMount() {
    await this.getReportTerminalsByDays();
  }

  render = () => {
    const { isLoading, terminalsByDays } = this.state;
    return (
      <>
        <Grid
          data={terminalsByDays}
          name="reportTerminalsByDays-grid"
          isLoading={isLoading}
          onRefresh={this.getReportTerminalsByDays}
          aggregate={aggregate}
          toolbar={<div></div>}
        >
          <GridColumn
            field="WorkDate"
            title="grids.reportTerminalsByDays.column-date"
            filter="date"
            format="dd.MM.yyyy"
            width="180"
          />
          <GridColumn field="TerminalCount" title="grids.reportTerminalsByDays.column-terminalCount" width="180" />
          <GridColumn field="TerminalAvgTurn" title="grids.reportTerminalsByDays.column-terminalAvg" width="180" />
          <GridColumn field="TerminalAvgProfit" title="grids.reportTerminalsByDays.column-avgProfit" width="180" />
          <GridColumn
            field="TerminalAvgPaymentCount"
            title="grids.reportTerminalsByDays.column-terminalAvgPaymentCount"
            width="180"
          />
          <GridColumn field="QuantityTotal" title="grids.reportTerminalsByDays.column-quantityTotal" width="180" />
          <GridColumn field="AvgPayment" title="grids.reportTerminalsByDays.column-avgPayment" width="180" />
          <GridColumn field="AmountTotal" title="grids.reportTerminalsByDays.column-amountTotal" width="180" />
          <GridColumn
            field="CommissionProvider"
            title="grids.reportTerminalsByDays.column-commissionProvider"
            width="180"
          />
          <GridColumn
            field="CommissionClient"
            title="grids.reportTerminalsByDays.column-commissionClient"
            width="180"
          />
        </Grid>
      </>
    );
  };
}
export default withTranslate(ReportTerminalsByDays);
