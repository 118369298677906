import React, { PureComponent } from 'react';

import { withTranslate } from '../../contexts/localContext';
import Users from './Users';
import PageTabs from '../../components/tabber/tabber';
import UserProperties from './UserProperties';
import FormStateContainer from '../../components/FormStateContainer';
import { INITIAL_VALUES } from './UserProperties/initialValues';
import GridAccessTerminals from './AccessTerminals';
import GroupMembershipContainer from './GroupMembership/GroupMembershipContainer';
import WorkWithDealersContainer from './WorkWithDealers/WorkWithDealersContainer';
import AddNewUserContainer from './AddNewUser/AddNewUserContainer';
import { initialValues } from './AddNewUser/initialValues';

class UsersPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page.dashboard.tab-users',
        content: <Users />,
        closable: false,
      },
    ],
  };

  onAddTab = ({ type, dataItem, userInfo }, key) => {
    const { tabs } = this.state;
    const { UserId, Name, LastName, FirstName } = dataItem;
    const { ownerId } = userInfo;

    switch (type) {
      case 'userProperties':
        tabs.push({
          title: 'page.dashboard.tab-userProperties',
          content: (
            <FormStateContainer initialValues={INITIAL_VALUES}>
              <UserProperties userId={UserId} />
            </FormStateContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      case 'membership':
        const { UserId: idUser } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-group-membership',
          content: <GroupMembershipContainer userId={idUser} userName={FirstName} LastName={LastName} />,
          closable: true,
          key: key,
        });
        break;
      case 'workDealers':
        const { UserId: userID } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-workDealers',
          content: (
            <WorkWithDealersContainer userId={userID} dataItem={dataItem} userName={FirstName} LastName={LastName} />
          ),
          closable: true,
          key: key,
        });
        break;
      case 'accessTerminals':
        const { UserId: userId } = dataItem;
        tabs.push({
          title: 'page.dashboard.tab-access-terminals',
          content: <GridAccessTerminals userId={userId} ownerId={ownerId} UserName={FirstName} LastName={LastName} />,
          closable: true,
          key: key,
        });
        break;
      case 'addNewUser':
        tabs.push({
          title: 'page.dashboard.tab-newUser',
          content: (
            <FormStateContainer initialValues={initialValues}>
              <AddNewUserContainer />
            </FormStateContainer>
          ),
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
    }
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render = () => {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  };
}

export default withTranslate(UsersPage);
