export const INITIAL_VALUES = {
  accountId: 'initial',
  email: '',
  phone: '',
  passwordText: '',
};

export const SUPPLIER_CONFIG = [
  {
    fieldName: 'Id',
    titleTranslateSlug: 'page.serviceSupplier.col-number',
    width: '130',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    width: '240',
    titleTranslateSlug: 'page.serviceSupplier.col-name',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];
