import { uppercaseFirstLetter } from '../../../utils/uppercaseFirstLetter';

class AddNewServicesService {
  normalizeService(services) {
    return services.length
      ? services.map((item) =>
          Object.entries(item).reduce(
            (acc, [key, val]) => ({
              ...acc,
              [uppercaseFirstLetter(key)]: val,
            }),
            {},
          ),
        )
      : [];
  }
  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.servicesList) {
      return { servicesListService: [], hasNextPage: false, hasPreviousPage: false };
    }
    const servicesList = data.data.servicesList;

    const servicesListService =
      servicesList.items && servicesList.items.length ? this.normalizeService(servicesList.items) : [];
    const pageInfo = servicesList.pageInfo;

    return {
      servicesListService,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };
}
export default new AddNewServicesService();
