const serviceManagement = (translate, value) => {
  switch (value) {
    case 'serviceManagement-addService':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.servicesBigTable.addService')}`;
    case 'serviceManagement-turnOnService':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.servicesBigTable.onService')}`;
    case 'serviceManagement-turnOffService':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.servicesBigTable.offService')}`;
    case 'serviceManagement-cloneService':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.servicesBigTable.cloneService')}`;
    case 'serviceManagement-serviceCreation':
      return `${translate('page.adminPanel.type-button')}:
                        ${translate('page.servicesBigTable.stepServiceCreation')}`;
    case 'serviceManagement-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'serviceManagement-dropDownServiceId':
      return `${translate(`page.adminPanel.dropdown-show`)}:
                        ${translate('grids.servicesBigTable.column-serviceId')}`;
    case 'serviceManagement-serviceAdditionalLimits':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.servicesBigTable.tab-additional-limits')}`;
    case 'serviceManagement-serviceMainProps':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.servicesBigTable.tab-basic-properties')}`;
    case 'serviceManagement-bankDetails':
      return `${translate('page.adminPanel.type-navLink')}:
        ${translate('page.servicesBigTable.tab-bank-details')}`;
    case 'serviceManagement-serviceAdditionalProps':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.servicesBigTable.tab-additional-properties')}`;
    case 'serviceManagement-additionalLimitsAdd':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-limits')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.add')}`;
    case 'serviceManagement-additionalLimitsChange':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-limits')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.change')}`;
    case 'serviceManagement-additionalLimitsDelete':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-limits')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.delete')}`;
    case 'serviceManagement-serviceAdditionalPropsAdd':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-properties')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.additionalServiceProps.addProps')}`;
    case 'serviceManagement-serviceAdditionalPropsChange':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-properties')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.additionalServiceProps.changeProps')}`;
    case 'serviceManagement-serviceAdditionalPropsDelete':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-properties')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.additionalServiceProps.deleteProps')}`;
    case 'serviceManagement-serviceAdditionalPropsClone':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.servicesBigTable.tab-additional-properties')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.additionalServiceProps.cloneByService')}`;
    case 'serviceManagement-serviceEndpoints':
      return `${translate('page.adminPanel.type-navLink')}:
                        ${translate('page.serviceEndpoints.cyrillicTitle')}`;
    case 'serviceManagement-serviceEndpointsAdd':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.serviceEndpoints.cyrillicTitle')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.serviceEndpoints.cyrillicAddEndpoint')}`;
    case 'serviceManagement-serviceEndpointsChange':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.serviceEndpoints.cyrillicTitle')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.serviceEndpoints.cyrillicChangeEndpoint')}`;
    case 'serviceManagement-serviceEndpointsDelete':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.serviceEndpoints.cyrillicTitle')},
                        ${translate(`page.adminPanel.type-button`)}:
                        ${translate('page.serviceEndpoints.cyrillicDeleteEndpoint')}`;
    case 'serviceManagement-supplierCommissionsSimple':
      return `${translate('page.adminPanel.type-navLink')}:
        ${translate('page.supplierCommissions.tab-supplierCommissions')}`;
    case 'serviceManagement-supplierCommissionsScale':
      return `${translate('page.adminPanel.type-navLink')}:
        ${translate('page.supplierCommissions.tab-supplierCommissions-scale')}`;
    case 'serviceManagement-commissionsTechPartners':
      return `${translate('page.adminPanel.type-navLink')}:
        ${translate('page.supplierCommissions.tab-commissions-tech-partner')}`;
  }
};

export default serviceManagement;
