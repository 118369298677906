import React, { PureComponent } from 'react';
import { withTranslate } from '../../../contexts/localContext';
import { Button, Modal, Input, message } from 'antd';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';

import './ModalBeneficiares.scss';

const api = new ApiService();

class ModalBeneficiaries extends PureComponent {
  state = {
    fullName: '',
    inn: '',
    isLoading: false,
    err: '',
  };

  isValidNum = (val) => {
    const { translate } = this.props;
    if (!Number.isInteger(+val) || val[val.length - 1] === ' ') {
      this.setState({
        err: `${translate('page.beneficiaries.invalid-format')}`,
      });
    }
    if (val.length > 10) {
      this.setState({
        err: `${translate('page.beneficiaries.err-max-length')}`,
      });
    }
  };

  renderModalContent = (modalType) => {
    const { inn, fullName, err } = this.state;
    const { translate, selectedItems } = this.props;
    switch (modalType) {
      case 'add':
        return (
          <>
            <div className="ModalBeneficiaries-row">
              <div className="ModalBeneficiaries-label">{translate('page.beneficiaries.full-name')}</div>
              <Input
                type="text"
                value={fullName}
                name="fullName"
                onChange={this.onHandleChange}
                className="ModalBeneficiaries-input"
              />
            </div>
            <div className="ModalBeneficiaries-row">
              <div className="ModalBeneficiaries-label">{translate('page.beneficiaries.inn')}</div>
              <div className="position-relative">
                <Input
                  type="text"
                  value={inn}
                  name="inn"
                  onChange={this.onHandleChange}
                  className="ModalBeneficiaries-input"
                />
                {err && err.length && <div className="err-msg">{err}</div>}
              </div>
            </div>
          </>
        );
      case 'change':
        return (
          <>
            <div className="ModalBeneficiaries-row">
              <div className="ModalBeneficiaries-label">{translate('page.beneficiaries.beneficiary-id')}</div>
              <Input
                type="text"
                value={selectedItems && selectedItems.length ? selectedItems[0].Id : ''}
                disabled
                className="ModalBeneficiaries-input"
              />
            </div>
            <div className="ModalBeneficiaries-row">
              <div className="ModalBeneficiaries-label">{translate('page.beneficiaries.full-name')}</div>
              <Input
                type="text"
                value={fullName}
                name="fullName"
                onChange={this.onHandleChange}
                className="ModalBeneficiaries-input"
              />
            </div>
            <div className="ModalBeneficiaries-row">
              <div className="ModalBeneficiaries-label">{translate('page.beneficiaries.inn')}</div>
              <div className="position-relative">
                <Input
                  type="text"
                  value={inn}
                  name="inn"
                  onChange={this.onHandleChange}
                  className="ModalBeneficiaries-input"
                />
                {err && err.length && <div className="err-msg">{translate(err)}</div>}
              </div>
            </div>
          </>
        );
      case 'delete':
        return `${translate('page.beneficiaries.delete')}`;
      default:
        break;
    }
  };

  renderBtn = (modalType) => {
    const { translate, onClose } = this.props;
    const { fullName, inn, err } = this.state;
    switch (modalType) {
      case 'add':
        return [
          <Button
            type="primary"
            disabled={!(fullName.length && inn.length) || err.length}
            onClick={this.addBeneficiary}
          >
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
        ];
      case 'change':
        return [
          <Button
            type="primary"
            disabled={!(fullName.length && inn.length) || err.length}
            onClick={this.changeBeneficiary}
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
        ];
      case 'delete':
        return [
          <Button type="danger" onClick={this.deleteBeneficiary}>
            {translate('core.buttonTitles.delete')}
          </Button>,
          <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
        ];
    }
  };

  showMessage = (messageSuccess) => {
    const { translate } = this.props;
    return message.success(`${translate(messageSuccess)}`, 2);
  };

  getModalTitle = (modalType) => {
    const { translate } = this.props;
    switch (modalType) {
      case 'add':
        return `${translate('page.beneficiaries.add-beneficiary')}`;
      case 'change':
        return `${translate('page.beneficiaries.change-beneficiary')}`;
      case 'delete':
        return `${translate('page.beneficiaries.delete-beneficiary')}`;
    }
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    if (value[0] === ' ') {
      return;
    }
    this.setState({ [name]: value }, () => {
      if (name === 'inn') {
        this.isValidNum(value);
      }
    });
  };

  addBeneficiary = async () => {
    const { fullName, inn } = this.state;
    const { updateData, onClose } = this.props;
    try {
      const model = {
        Id: 0,
        Name: fullName,
        Inn: inn,
      };
      await this.setState({ isLoading: true });
      await api.servicesSuppliersBeneficiarAdd(model);
      await this.setState({ isLoading: false });
      await updateData();
      await onClose();
      await this.showMessage('page.beneficiaries.msg-add');
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  changeBeneficiary = async () => {
    const { selectedItems, updateData, onClose } = this.props;
    const { fullName, inn } = this.state;
    try {
      const model = {
        Id: selectedItems[0].Id,
        Name: fullName,
        Inn: inn,
      };
      await this.setState({ isLoading: true });
      await api.servicesSuppliersBeneficiarUpdate(model);
      await this.setState({ isLoading: false });
      await updateData();
      await onClose();
      await this.showMessage('page.beneficiaries.msg-changed');
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  deleteBeneficiary = async () => {
    const { selectedItems, updateData, onClose } = this.props;
    try {
      const model = selectedItems.map(({ Id, Name, Inn }) => ({
        Id,
        Name,
        Inn,
      }));

      this.setState({ isLoading: true });
      await api.servicesSuppliersBeneficiarDelete(model);
      await updateData();
      await onClose();
      await this.showMessage('page.beneficiaries.msg-deleted');
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };
  componentDidMount() {
    const { modal, selectedItems } = this.props;
    if (modal === 'change') this.setState({ fullName: selectedItems[0].Name, inn: selectedItems[0].Inn });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { inn } = this.state;
    if (inn !== prevState.inn) {
      this.setState({ err: '' });
    }
  }
  render() {
    const { visible, modal, onClose } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Modal title={this.getModalTitle(modal)} visible={visible} onCancel={onClose} footer={this.renderBtn(modal)}>
          {this.renderModalContent(modal)}
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ModalBeneficiaries);
