import React, { PureComponent } from 'react';
import { Row, Button, Input, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../../contexts/localContext';
import Grid from '../../../../components/grids/baseGrid';
import CellBoolean from '../../../account/cellBoolean';
import ServicesAdditionalPropsService from './ServicesAdditionalProps.service';
import UpdateModal from './UpdateModal';
import { ApiService } from '../../../../services';
import CloneForServiceModal from './CloneForServiceModal';

import './ServicesAdditionalProps.scss';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import findArray from '../../../../utils/findArrForCurrentPage';
import checkElement from '../../../../utils/checkElement';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();
const { TextArea } = Input;

const WrappedCellBoolean = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class ServicesAdditionalProps extends PureComponent {
  currentArray = findArray('serviceManagement');

  state = {
    additionalProps: [],
    isLoading: false,
    selectedItems: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchAdditionalPropertiesServices();
  }

  fetchAdditionalPropertiesServices = async () => {
    const {
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;

    try {
      this.setState({ isLoading: true });
      const additionalProps = await api.getAdditionalPropertiesServices(ServiceId);
      await this.setState({
        additionalProps: ServicesAdditionalPropsService.normalizeCurrentProps(additionalProps),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId, Name },
      },
    } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row className="ServiceAdditionalProps-toolbar">
        <div className="ServiceAdditionalProps-serviceInfo">
          <div className="ServiceAdditionalProps-label">{translate('grids.servicesBigTable.column-serviceId')}</div>
          <Input value={ServiceId} disabled />
        </div>
        <div className="ServiceAdditionalProps-serviceInfo">
          <div className="ServiceAdditionalProps-label">{translate('grids.servicesBigTable.column-name')}</div>
          <TextArea value={Name} disabled autoSize />
        </div>
        {checkElement('serviceManagement-serviceAdditionalPropsClone', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'cloneFromService' });
            }}
          >
            {translate('page.additionalServiceProps.cloneByService')}
          </Button>
        )}

        {checkElement('serviceManagement-serviceAdditionalPropsAdd', this.currentArray) && (
          <Button
            type="primary"
            onClick={() => {
              this.setState({ modalVariant: 'addProps' });
            }}
          >
            {translate('page.additionalServiceProps.addProps')}
          </Button>
        )}

        {checkElement('serviceManagement-serviceAdditionalPropsChange', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ modalVariant: 'changeProps' });
            }}
          >
            {translate('page.additionalServiceProps.changeProps')}
          </Button>
        )}

        {checkElement('serviceManagement-serviceAdditionalPropsDelete', this.currentArray) && (
          <Button
            type="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              this.setState({ modalVariant: 'deleteProps' });
            }}
          >
            {translate('page.additionalServiceProps.deleteProps')}
          </Button>
        )}
      </Row>
    );
  };

  handleSelectedItemsChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  deleteSelectedProps = async () => {
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;
    const { selectedItems } = this.state;

    try {
      await api.deleteAdditionalPropertyService({
        serviceId: ServiceId,
        name: selectedItems[0].Name,
      });

      this.fetchAdditionalPropertiesServices();
      message.success(translate(`page.additionalServiceProps.propertyDeletedSuccessfully`), 2);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { additionalProps, isLoading, modalVariant, selectedItems } = this.state;
    const {
      translate,
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;

    return (
      <>
        <Grid
          data={additionalProps}
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchAdditionalPropertiesServices}
          isLoading={isLoading}
          fieldForSelection="Id"
          onSelect={this.handleSelectedItemsChange}
          name="servicesAdditionalPropsGrid"
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="Name" title="page.additionalServiceProps.col-name" width="300px" />
          <GridColumn field="Value" title="page.additionalServiceProps.col-value" width="800px" />
          <GridColumn
            field="SaveToTransaction"
            title="page.additionalServiceProps.col-saveForTransaction"
            cell={WrappedCellBoolean}
            width="200"
          />
          <GridColumn field="Description" title="page.additionalServiceProps.col-desc" width="200px" />
        </Grid>
        {(modalVariant === 'addProps' || modalVariant === 'changeProps') && (
          <UpdateModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItem={selectedItems && selectedItems.length ? selectedItems[0] : null}
            serviceId={ServiceId}
            onRefresh={this.fetchAdditionalPropertiesServices}
            additionalProps={additionalProps}
          />
        )}
        {modalVariant === 'cloneFromService' && (
          <CloneForServiceModal
            currentServiceId={ServiceId}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            visible={modalVariant === 'cloneFromService'}
            onRefresh={this.fetchAdditionalPropertiesServices}
          />
        )}
        {modalVariant === 'deleteProps' && (
          <DeleteModal
            visible={modalVariant === 'deleteProps'}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            deleteContent={translate('page.additionalServiceProps.areYouSure')}
            submitCallback={this.deleteSelectedProps}
          />
        )}
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(ServicesAdditionalProps);
