const reportbyday = (translate, value) => {
  switch (value) {
    case 'dayReport-AmountTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-turn')}`;
    case 'dayReport-AvgPayment':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-pay')}`;
    case 'dayReport-btn-submit':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'dayReport-CommissionClient':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-clients')}`;
    case 'dayReport-CommissionProvider':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-owners')}`;
    case 'dayReport-QuantityTotal':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-payments')}`;
    case 'dayReport-TerminalAvgPaymentCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-payCount')}`;
    case 'dayReport-TerminalAvgProfit':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-revenue')}`;
    case 'dayReport-TerminalAvgTurn':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-avgTurn')}`;
    case 'dayReport-TerminalCount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-terminals')}`;
    case 'dayReport-WorkDate':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-date')}`;
    case 'dayReport-Commission':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.dayReport.column-commission')}`;
    default:
      return value;
  }
};

export default reportbyday;
